import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import { MemoSimpleTabHeaderComponent, TabPanelComponent } from '../../../../components/index.js';
import Header from '../components/header/index.jsx';
import TermDetail from './detail/index.jsx';
import TermDefinition from './definition/index.jsx';
import LinkedAssets from './linkedAssets/index.jsx';
import Measure from './measure/index.jsx';
import Sidebar from '../components/sidebar/index.jsx';

//Constant
import appConstants from '../../../../constants/appConstants.js';

//Styles
import LayoutStyles from '../../../../layouts/style.jsx';
import TermDetailStyle from './style.jsx';

// Import Actions
import { getDetailRequest, updateProperty, updateDetailRequest } from '../../../../redux/reducer/termReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';
import { deleteTermRequest } from '../../../../redux/reducer/semanticReducer.js';
import { checkPermission, semanticsBreadcrumb } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';


function Term(props) {
    /**
     * Define Props and Get Redux Value
     */
    const { classes } = props;
    const { termId } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const selectedCategory = state?.category || {};
    const selectedGlossary = state?.glossary || {};


    /**
     * Define UseSelector
     */
    const { tree } = useSelector(({ semantic }) => semantic);
    const detail = useSelector(({ term }) => term.detail);
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [collapseOpen, setCollapseOpen] = useState(true);

    /**
     * Handle Collapse Event
     */
    const onCollapseChange = () => {
        setCollapseOpen((collapseOpen) => !collapseOpen);
    };


    /**
     * Get Term Detail
     */
    useEffect(() => {
        dispatch(getDetailRequest(termId));
        // dispatch(getGlossaryRequest());
    }, []);

    /**
     * on Handle Change
     * @param {*} property
     * @param {*} value
     */
    const handleOnChange = (property, value) => {
        const requestParams = {
            id: termId,
            [property]: value
        };
        dispatch(updateProperty(requestParams));
        dispatch(updateDetailRequest(requestParams));
    };


    /**
     * BreadCrumb Action
     */

    const onBreadCurumAction = (item) => {
        if (item.type === "root") {
            dispatch(navigate({ path: 'settings.semantics.domains.root', state: {}, params: [] }));
        } else if (item.type === "glossary") {
            dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: selectedGlossary }, params: [item.id] }));
        } else {
            dispatch(navigate({ path: 'settings.semantics.domains.category', state: { glossary: selectedGlossary, category: selectedCategory }, params: [item.glossary_id, item.id] }));
        }
    };

    /**
     * Preare Component Data
     * @param {*} type
     * @returns
     */
    const prepareData = (type) => {
        switch (type) {
            case 'header':
                return {
                    id: detail.id,
                    status: detail.status || "Pending",
                    derived_type: detail.derived_type || "Text",
                    name: detail.name || ""
                };
            default:
                return {
                    description: detail.description,
                    id: detail.id,
                    synonyms: detail.synonyms,
                    contains: detail.contains,
                    reference: detail.reference || 'NA'
                };
        }
    };

    /**
     * onDelete
     */
    const onDelete = () => {
        if (!detail.category) {
            dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: selectedGlossary }, params: [detail.glossary_id || detail.glossary] }));
        } else {
            dispatch(navigate({ path: 'settings.semantics.domains.category', state: { glossary: selectedGlossary, category: selectedCategory }, params: [detail.glossary_id, detail.category || detail.category_id] }));
        }
        dispatch(deleteTermRequest(detail.id));
    };

    const breadCrumbs = useMemo(() => semanticsBreadcrumb(tree, selectedGlossary, selectedCategory?.id, detail), [tree, selectedGlossary, selectedCategory?.id, detail]);


    return (
        <Grid container className={classes.glossaryContainer}>
            <Grid container spacing={3} className={`${classes.glossaryListContainer}`}>
                <Grid item sx={{ flex: '0 0 auto', width: '300px' }}>
                    <Sidebar
                        collapseChange={onCollapseChange}
                        collapseState={!collapseOpen}
                    />
                </Grid>
                <Grid item className={`${classes.glossaryList} ${collapseOpen && "sideBarClose"}`}>
                    <Grid container className={classes.attributesDetailContainer}>
                        <Header
                            isFilter
                            actitonType="delete"
                            searchDetail={false}
                            status
                            data={prepareData("header")}
                            dataType
                            breadcrumbs={breadCrumbs}
                            handleOnChange={handleOnChange}
                            onBreadCurumAction={(item) => onBreadCurumAction(item)}
                            onDelete={() => onDelete()}
                            semanticsPermission={semanticsPermission}
                        />
                        <Grid item xs={12}>
                            <TermDetail data={prepareData('detail')} semanticsPermission={semanticsPermission} />
                        </Grid>
                        <Grid item xs={12} className={classes.tabsSection}>
                            <Grid
                                container
                                wrap="nowrap"
                                className={classes.tabHeaderContainer}
                            >
                                <MemoSimpleTabHeaderComponent
                                    className={classes.tabsHeader}
                                    tabStyle={'inline'}
                                    tabIndex={tabIndex}
                                    tabList={appConstants.tabs.termTabs}
                                    onTabChange={(newValue) => setTabIndex(newValue)}
                                />
                            </Grid>
                            <Grid className={classes.tabsBodySection}>
                                <Grid className={classes.tabsBody}>
                                    {
                                        tabIndex === 0 &&
                                        <TabPanelComponent value={0} index={tabIndex}>
                                            <TermDefinition semanticsPermission={semanticsPermission} />
                                        </TabPanelComponent>
                                    }
                                    {
                                        tabIndex === 1 &&
                                        <TabPanelComponent value={1} index={tabIndex}>
                                            <Measure semanticsPermission={semanticsPermission} />
                                        </TabPanelComponent>
                                    }
                                    {
                                        tabIndex === 2 &&
                                        <TabPanelComponent value={2} index={tabIndex}>
                                            <LinkedAssets semanticsPermission={semanticsPermission} />
                                        </TabPanelComponent>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Term.defaultProps = {
    classes: {}
};

// prop types
Term.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...TermDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Term);