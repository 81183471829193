import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Tooltip, IconButton } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Components
import { TextBoxComponent, MemoSimpleTabHeaderComponent, LoaderButtonComponent, TabPanelComponent } from '../../../components/index.js';
import Scheduler from '../../../components/scheduler/index.jsx';
import Properties from '../components/properties/index.jsx';
import Filters from '../components/filters/index.jsx';
import Preview from '../components/preview/index.jsx';

// Import Icons
import { CalendarIcon } from '../../../assets/svg/index.js';

//  Import Styles
import NewReportStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import featureConstants from '../../../constants/featureConstants.js';

// Import Actions
import { updateProperties, clearState, createReportRequest, getReportByIdRequest, updateReportRequest } from '../../../redux/reducer/reportsReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';

// Import Helpers
import { validateEmails, checkPermission } from '../../../helpers/appHelpers.js';


function CustomReports(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: report_id } = useParams();

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [scheduler, setScheduler] = useState(null);

    /**
     * Redux Store
     */
    const { detail, saveLoading } = useSelector(({ reports }) => reports);
    const { permission } = useSelector((state) => state.auth);
    const reportsPermission = checkPermission(permission, featureConstants.home.reports);
    const { searchableMeasuresList } = useSelector((state) => state.baseMeasure, shallowEqual);

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('validateEmails', (value) => {
            return validateEmails(value);
        });
        return () => {
            dispatch(clearState());
        };
    }, [dispatch]);


    /**
     * Get Report by Id
     */
    useEffect(() => {
        if (report_id && report_id !== "new") {
            dispatch(getReportByIdRequest(report_id));
        }
    }, [report_id]);

    /**
     * Updated the measure filters when selected assets changes
     */
    useEffect(() => {
        const measures = [];
        if (detail?.assets?.length && detail?.filters?.measures?.length > 0 && searchableMeasuresList && searchableMeasuresList.length) {
            for (const measure of detail.filters.measures) {
                const index = searchableMeasuresList.findIndex((item) => item.id === measure.id);
                if (index < 0) {
                    continue;
                }
                measures.push(measure);
            }
            const requestParams = { "filters": { ...detail.filters, measures } };
            dispatch(updateProperties(requestParams));
        }
        else if (detail?.filters?.measures?.length > 0 && detail?.assets?.length === 0) {
            dispatch(updateProperties({ "filters": { ...detail.filters, measures } }));
        }
    }, [detail?.assets, searchableMeasuresList]);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value, sub_property = null) => {
        if (sub_property) {
            value = {
                ...detail.properties,
                [property]: value
            };
            property = sub_property;
        }
        const requestParams = {
            [property]: value
        };
        dispatch(updateProperties(requestParams));
    };

    /**
     * Back to Reports Page
     */
    const onCancel = () => {
        dispatch(navigate({ path: 'home.reports', state: {}, params: [] }));
    };

    /**
     * Save Report
     */
    const saveReport = () => {
        if (detail.id) {
            dispatch(updateReportRequest(detail));
        } else {
            dispatch(createReportRequest(detail));
        }
    };


    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = (event) => {
        event.stopPropagation();
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, isMeasure: false, isReport: true, reportId: report_id });
    };

    return (
        <Fragment>
            <ValidatorForm className="w-100" onSubmit={() => saveReport()}>
                <Grid item xs={12} className={classes.newWidgetContainer}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item className={classes.newWidgetHeader}>
                            <Grid className={classes.titleContainer}>
                                <TextBoxComponent
                                    variant="standard"
                                    noOutline
                                    value={detail.name || ''}
                                    placeholder="New Report"
                                    validators={['required']}
                                    errorMessages={[appConstants.errorMessages.nameRequired]}
                                    onBlur={(event) => onChange("name", event.target.value)}
                                    valOnChange
                                    disabled={!reportsPermission?.is_edit}
                                />
                            </Grid>
                            <TextBoxComponent
                                fullWidth
                                variant="standard"
                                noOutline
                                value={detail.description || ''}
                                placeholder="Enter your description here"
                                onChange={(event) => onChange("description", event.target.value)}
                                disabled={!reportsPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item className="ddflex alignCenter mt-15">
                            {
                                report_id !== "new" &&
                                <Tooltip title="Schedule">
                                    <span>
                                        <IconButton
                                            className="CalendarIcon mr-15"
                                            onClick={(event) => openScheduler(event)}>
                                            <CalendarIcon active={false} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            }
                            {
                                reportsPermission?.is_edit &&
                                <LoaderButtonComponent
                                    size={'small'}
                                    isLoading={saveLoading}
                                    disabled={!reportsPermission?.is_edit}
                                >
                                    {appConstants.save}
                                </LoaderButtonComponent>
                            }
                            <Button variant="outlined" color="primary" size="small" className="ml-2" onClick={() => onCancel()}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.bodyContainer}>
                        <Grid className={classes.prviewContainer}>
                            <Preview detail={detail} onChange={onChange} reportsPermission={reportsPermission} />
                        </Grid>
                        <Grid className={classes.assetConatiner}>
                            <Grid
                                container
                                wrap="nowrap"
                                className={classes.tabHeaderContainer}
                            >
                                <MemoSimpleTabHeaderComponent
                                    className={classes.tabsHeader}
                                    tabStyle={'inline'}
                                    tabIndex={tabIndex}
                                    tabList={appConstants.tabs.customReportTabs}
                                    onTabChange={(newValue) => setTabIndex(newValue)}
                                />
                            </Grid>
                            <Grid className={classes.tabsBodySection}>
                                <Grid className={classes.tabsBody}>
                                    <TabPanelComponent value={0} index={tabIndex}>
                                        <Properties detail={detail} onChange={onChange} reportsPermission={reportsPermission} />
                                    </TabPanelComponent>
                                    <TabPanelComponent value={1} index={tabIndex}>
                                        <Filters detail={detail} onChange={onChange} reportsPermission={reportsPermission} />
                                    </TabPanelComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                scheduler?.open &&
                <Scheduler
                    scheduleProps={scheduler}
                    onClose={() => setScheduler({ open: false, anchorElement: null, isAsset: false, isMeasure: false, isReport: true, reportId: report_id })}
                    disabled={!reportsPermission?.is_edit} />
            }
        </Fragment>
    );
}

// default props
CustomReports.defaultProps = {
    classes: {}
};

// prop types
CustomReports.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewReportStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CustomReports);