import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import BannerSection from './banner.jsx';
import BannerSectionNew from './bannernew.jsx';
import LogoAdd from './logoAdd.jsx';
import { TextBoxComponent } from '../../../../components/index.js';

//  Import Styles
import FeaturesStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

//Constants
import appConstants from '../../../../constants/appConstants.js';

function Features(props) {

    /**
     * Define Props
     */
    const { classes, data, handleThemeChange, handleImageUpload, handleBannerImages, banner_list, delete_banner, editPermission } = props;

    /**
     * Copyright change handler function
     * @param {*} property
     * @param {*} value
     */
    const handleThemeEvent = (property, value) => {
        if ((property === "copyright" || property === "app_title") && value?.toString()?.trim()?.length === 0) {
            return;
        }
        handleThemeChange({ ...data, [property]: value });
    };

    return (
        <Grid item xs={12} className={classes.FeaturesContainer}>
            <ValidatorForm onSubmit={() => { }}>
                <LogoAdd data={data} handleThemeChange={handleThemeChange} handleImageUpload={handleImageUpload} editPermission={editPermission} />
                <Grid className={classes.logoSection}>
                    <Grid className={classes.headerConatiner}>
                        <Typography variant="h5" className="">
                            {appConstants.labels.theme.copyrightInfo}
                        </Typography>
                    </Grid>
                    <Grid className={classes.bodySection}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="body1" className={`pb5`} >
                                    {appConstants.labels.theme.copyrightLabel}
                                </Typography>
                                <Typography variant="body1" className={`${classes.textSecondary} mb-2`}>
                                    {appConstants.labels.theme.copyrightInfoDesc}
                                </Typography>
                                <Grid item xs={10}>
                                    <TextBoxComponent
                                        id="copyright"
                                        name={'copyright'}
                                        variant="standard"
                                        placeholder={appConstants.labels.theme.enterCopyright}
                                        value={data.copyright}
                                        disabled={!editPermission}
                                        onChange={(event) => handleThemeEvent(event.target.name, event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} className={classes.titleContainer}>
                                <Typography variant="body1" className={`pb5`} >
                                    {appConstants.labels.theme.titleHeader}
                                </Typography>
                                <Typography variant="body1" className={`${classes.textSecondary} mb-2`}>
                                    {appConstants.labels.theme.titleHeaderDesc}
                                </Typography>
                                <Grid item xs={6} className="titleField">
                                    <TextBoxComponent
                                        id="app_title"
                                        name={'app_title'}
                                        variant="standard"
                                        placeholder={appConstants.labels.theme.titleHeaderLine}
                                        value={data.app_title}
                                        disabled={!editPermission}
                                        onChange={(event) => handleThemeEvent(event.target.name, event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    banner_list.length <= 3 ? <BannerSection data={data} handleBannerImages={handleBannerImages} banner_list={banner_list} delete_banner={delete_banner} editPermission={editPermission} /> :
                        <BannerSectionNew data={data} handleBannerImages={handleBannerImages} banner_list={banner_list} delete_banner={delete_banner} editPermission={editPermission} />
                }
            </ValidatorForm>
        </Grid>
    );
}

// default props
Features.defaultProps = {
    classes: {},
    data: {},
    handleThemeChange: () => { },
    handleImageUpload: () => { },
    handleBannerImages: () => { },
    banner_list: [],
    delete_banner: () => { },
    editPermission: false
};

// prop types
Features.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleThemeChange: PropTypes.func,
    handleImageUpload: PropTypes.func,
    handleBannerImages: PropTypes.func,
    banner_list: PropTypes.array,
    delete_banner: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...FeaturesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Features);