import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

// Import Private Layout
import { ExtensionLayout } from "../layouts";

// Import Helpers
import { getCookie } from "../helpers/appHelpers.js";

// Routes the page with authentication by the user
function ExtensionRoute({ component, ...props }) {

  /**
   * Get Login Status
   */
  const loggeInStatus = getCookie('token');

  return loggeInStatus !== '' ? <ExtensionLayout component={component} {...props} /> : <Navigate to={{ pathname: "/extension", state: { from: props.location } }} />;
}

// Define Props Types
ExtensionRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string
};

export default ExtensionRoute;