// Default Imports
import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Import Helpers

//Import Styles
import style from "./style.jsx";
import palette from '../../assets/theme/palette.js';

const LinearProgressComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, value, percentage } = props;

    return (
        <Grid className={`${classes.linearContainer} linearContainer`}>
            <Grid className={classes.progressBar}>
                <LinearProgress
                    value={value ? parseInt(value) : value}
                    variant="determinate"
                    sx={
                        {
                            '& .MuiLinearProgress-bar': {
                                background: '#F295B6'
                            },
                            '&.MuiLinearProgress-root': {
                                backgroundColor: `${palette.secondary.main}08 !important`
                            }
                        }
                    } />
            </Grid>
            <Typography variant="body1" className="valuesText pl-1">
                {percentage ? `${value}%` : value}
            </Typography>
        </Grid>
    );
};

/**
 * Define Component Props
 */
LinearProgressComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    percentage: PropTypes.bool
};

/**
 * Define Default Props
 */
LinearProgressComponent.defaultProps = {
    classes: {},
    value: 0,
    size: 0,
    showPercentage: true,
    showValue: false,
    circularFont: 10,
    isDisabled: false,
    noLink: true,
    percentage: 0
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(LinearProgressComponent, areEqual));