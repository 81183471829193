import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    detail: {},
    linkAssets: [],
    searchableTermsList: [],
    mappedTermsList: [],
    mappedTermsListFilter: [],
    termsList: []
};

const termSlice = createSlice({
    name: 'term',
    initialState: { ...initialState },
    reducers: {
        getTermsRequest(state) {
            state.isLoading = true;
        },
        getTermsSuccess(state, action) {
            if (action.payload.requested_data) {
                state.searchableTermsList = action.payload.data || [];
            } else {
                state.termsList = action.payload.data || [];
            }
            state.isLoading = false;
        },
        getTermsFailure(state) {
            state.isLoading = false;
        },
        getDetailRequest(state) {
            state.isLoading = true;
            state.detail = {};
        },
        getDetailSuccess(state, action) {
            state.isLoading = false;
            state.detail = action.payload.data || {};
        },
        getDetailFailure(state) {
            state.isLoading = false;
        },
        updateDetailRequest(state, action) {
            state.termsList = state.termsList.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        item,
                        ...action.payload
                    };
                }
                return item;
            });
        },
        updateDetailSuccess() { },
        updateDetailFailure() { },
        updateProperty(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        getLinkedAssetRequest(state) {
            state.isLoading = true;
        },
        getLinkedAssetSuccess(state, action) {
            state.isLoading = false;
            const linkAssets = action.payload.data || [];
            for (const asset of linkAssets) {
                asset.isTermApproval = true;
            }
            state.linkAssets = linkAssets;
        },
        getLinkedAssetFailure() { },
        linkAssetRequest() { },
        linkAssetSuccess(state, action) {
            let linkAssets = action.payload.attributes || [];
            linkAssets = linkAssets.map((item) => {
                return {
                    ...item,
                    name: item.attributeName
                };
            });
            state.linkAssets = [...state.linkAssets, ...linkAssets];
        },
        linkAssetFailure() { },
        unLinkAssetRequest() { },
        unLinkAssetSuccess(state, action) {
            if (!action.payload.data) {
                return;
            }
            const index = state.linkAssets.findIndex(
                (asset) => (asset.id === action.payload.data.attribute_meta_id)
            );
            if (index > -1) {
                state.linkAssets.splice(index, 1);
            }
        },
        updateApprovedTerm(state, action) {
            if (!action.payload.data) {
                return;
            }
            const index = state.linkAssets.findIndex(
                (asset) => (asset.attribute_id === action.payload.data.attribute_id)
            );
            if (action.payload.data.is_approved) {
                if (index > -1) {
                    const linkedAsset = state.linkAssets[index];
                    linkedAsset.term_approval_id =
                        action.payload.data.term_approval_id;
                    linkedAsset.term_id = action.payload.data.term_id;
                    state.linkAssets.splice(index, 1, { ...linkedAsset });
                }
            }

            if (!action.payload.data.is_approved) {
                if (index > -1) {
                    state.linkAssets.splice(index, 1);
                }
            }
        },
        unLinkAssetFailure() { },
        deleteTermRequest() { },
        deleteTermSuccess(state, action) {
            state.termsList = state.termsList.filter((i) => i.id !== action.payload.data);
        },
        deleteTermFailure() { },
        updatePattern(state, action) {
            const patterns = [...state.detail.pattern];
            let pattern = action.payload || {};
            pattern = {
                name: pattern.name,
                id: pattern.id,
                count: pattern.count || 0,
                sql_pattern: pattern.properties.sql_pattern,
                posix_pattern: pattern.properties.posix_pattern,
                is_positive: pattern.is_positive
            };
            const index = patterns.findIndex((obj) => obj.id === pattern.id);
            if (index > -1) {
                patterns[index] = pattern;
            } else {
                patterns.push({ ...pattern });
            }
            state.detail = {
                ...state.detail,
                pattern: [...patterns]
            };
        },
        deletePattern(state, action) {
            const patterns = [...state.detail.pattern];
            const index = patterns.findIndex((obj) => obj.id === action.payload);
            patterns.splice(index, 1);
            state.detail = {
                ...state.detail,
                pattern: [...patterns]
            };
        },
        getMappedTermsListRequest() { },
        getMappedTermsListSuccess(state, action) {
            state.mappedTermsList = action.payload?.data ?? [];
        },
        getMappedTermsListFailure(state) {
            state.mappedTermsList = [];
        },
        getMappedTermsListFilterRequest() { },
        getMappedTermsListFilterSuccess(state, action) {
            state.mappedTermsListFilter = action.payload?.data ?? [];
        },
        getMappedTermsListFilterFailure(state) {
            state.mappedTermsListFilter = [];
        }
    }
});

export const {
    getDetailRequest,
    getDetailSuccess,
    getDetailFailure,
    updateDetailRequest,
    updateDetailSuccess,
    updateDetailFailure,
    updateProperty,
    getLinkedAssetRequest,
    getLinkedAssetSuccess,
    getLinkedAssetFailure,
    linkAssetRequest,
    linkAssetSuccess,
    linkAssetFailure,
    unLinkAssetRequest,
    unLinkAssetSuccess,
    unLinkAssetFailure,
    deleteTermRequest,
    deleteTermSuccess,
    deleteTermFailure,
    updatePattern,
    deletePattern,
    getTermsRequest,
    getTermsSuccess,
    getTermsFailure,
    getMappedTermsListRequest,
    getMappedTermsListSuccess,
    getMappedTermsListFailure,
    getMappedTermsListFilterRequest,
    getMappedTermsListFilterSuccess,
    getMappedTermsListFilterFailure,
    updateApprovedTerm
} = termSlice.actions;
export default termSlice.reducer;