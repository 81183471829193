// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Measure
 * @returns
 */
async function get(params) {
    const queryParams = [];
    for (const param of Object.keys(params)) {
        if (params[param]) {
            queryParams.push(`${param}=${params[param]}`);
        }
    }
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : "";
    const response = await getRequest(`${urlConstants.baseMeasure.url}${queryString}`);
    return response;
}

/**
 * Create Measure
 * @param {*} params
 * @returns
 */
async function create(params) {
    const response = await postRequest(`${urlConstants.baseMeasure.url}`, params);
    return response;
}

/**
 * update Measure
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.baseMeasure.url}${id}/`, params);
    return response;
}

/**
 * Delete Measure
 * @param {*} id
 * @returns
 */
async function remove(id) {
    const response = await deleteRequest(`${urlConstants.baseMeasure.url}${id}/`);
    return response;
}


/**
 * Get Measure Detail
 * @param {*} id
 * @returns
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.baseMeasure.url}${id}/`);
    return response;
}

/**
 * Get active patterns
 * @returns
 */
async function getActivePatterns() {
    const response = await getRequest(`${urlConstants.baseMeasure.get_active_patterns}`);
    return response;
}

/**
 * Get Measure Dashboard Status
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getMeasureDashStatus() {
    const response = await getRequest(`${urlConstants.baseMeasure.getDashStatus}`);
    return response;
}

/**
 * Get Measures Name List
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getMeasuresNameList(params) {
    const response = await postRequest(`${urlConstants.baseMeasure.getNames}`, params);
    return response;
}

/**
 * Get Measures Name List
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getAutoMeasure(params) {
    const response = await postRequest(`${urlConstants.baseMeasure.getAutoMeasure}`, params);
    return response;
}


/**
 * Export All Service Functions
 */
export const baseMeasureService = {
    create, get, update, remove, detail, getActivePatterns, getMeasureDashStatus, getMeasuresNameList, getAutoMeasure
};