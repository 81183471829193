import React from "react";
import PropTypes from "prop-types";

// Import Public Layout
import { PublicLayout } from "../layouts";

// Routes the page without any authentication by the user
const PublicRoute = ({ component, ...props }) => (
  <PublicLayout component={component} {...props} />
);

// Define Props Types
PublicRoute.propTypes = {
  component: PropTypes.elementType
};

export default PublicRoute;