import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

//  Import Styles
import TopContainerStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { ChipComponent, MemoTextBoxComponent } from '../../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Actions
import { updateProperty, updateDetailRequest } from '../../../../../redux/reducer/termReducer';
import { useDispatch } from 'react-redux';

function TermDetail(props) {
    /**
     * Define Props
     */
    const { classes, data, semanticsPermission } = props;
    const dispatch = useDispatch();
    const { termId } = useParams();

    /**
     * Handle OnChange
     * @param {*} property
     * @param {*} value
     */
    const handleOnChange = (property, value) => {
        let newVal = value;
        if (property === "synonyms") {
            newVal = data.synonyms ? [...data.synonyms] : [];
            newVal.push(value);
        } else if (property === "contains") {
            newVal = data.contains ? [...data.contains] : [];
            newVal.push(value);
        }
        const requestParams = {
            [property]: newVal,
            id: termId
        };
        dispatch(updateProperty(requestParams));
        dispatch(updateDetailRequest(requestParams));
    };

    /**
     * Handle Delete
     * @param {*} property
     * @param {*} value
     */
    const handleOnDelete = (property, value) => {
        const requestParams = {
            [property]: value,
            id: termId
        };
        dispatch(updateProperty(requestParams));
        dispatch(updateDetailRequest(requestParams));
    };

    return (
        <Grid container className={classes.topContainer}>
            <Grid item xs={12} className="description mb-3">
                <Typography
                    variant="body1"
                    align="left"
                    className={`${classes.textSecondary} pb-1`}
                >
                    Please provide Business Definition
                </Typography>
                <ValidatorForm className="w-100" onSubmit={() => { }}>
                    <MemoTextBoxComponent
                        name="description"
                        fullWidth
                        multiline
                        variant="standard"
                        placeholder="Enter text"
                        minRows={1}
                        maxRows={3}
                        value={data.description || ""}
                        onChange={(event) => handleOnChange("description", event.target.value)}
                        disabled={!semanticsPermission?.is_edit}
                    />
                </ValidatorForm>
            </Grid>
            <Grid item xs={4} className="pr-3">
                <Grid item className="dflex alignCenter mb-1">
                    <Typography variant="body1" className={classes.listTitle}>
                        Synonyms
                    </Typography>
                    <ValidatorForm className="w-100" onSubmit={() => { }}>
                            <ChipComponent
                                data={data.synonyms || []}
                                placeholder="Add Synonyms"
                                add={semanticsPermission?.is_edit}
                                editable={semanticsPermission?.is_edit}
                                onChipAdd={(value) => handleOnChange("synonyms", value)}
                                handleChipEvent={(chip) => handleOnDelete("synonyms", chip)}
                                addType="text"
                                limit={1}
                                chipClass="info"
                                noId
                                disable_link
                            />
                    </ValidatorForm>
                </Grid>
            </Grid>
            <Grid item xs={4} className="pl-3 pr-3">
                <Grid item className="dflex alignCenter mb-1">
                    <Typography variant="body1" className={classes.listTitle}>
                        Contains
                    </Typography>
                    <ValidatorForm className="w-100" onSubmit={() => { }}>
                            <ChipComponent
                                data={data.contains || []}
                                placeholder="Add Contains"
                                add={semanticsPermission?.is_edit}
                                editable={semanticsPermission?.is_edit}
                                onChipAdd={(value) => handleOnChange("contains", value)}
                                handleChipEvent={(chip) => handleOnDelete("contains", chip)}
                                addType="text"
                                limit={2}
                                chipClass="info"
                                noId
                                disable_link
                            />
                    </ValidatorForm>
                </Grid>
            </Grid>
            <Grid item xs={4} className="pl-3">
                <Grid item className="dflex alignCenter mb-1">
                    <Typography variant="body1" className={classes.listTitle}>
                        Reference
                    </Typography>
                    <Typography variant="body1" className={`${classes.listValue} linkText`}>
                        {data.reference || 'NA'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
TermDetail.defaultProps = {
    classes: {},
    data: {},
    semanticsPermission: {}
};

// prop types
TermDetail.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    semanticsPermission: PropTypes.object
};


/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...TopContainerStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(TermDetail, areEqual));