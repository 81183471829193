import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    details: {},
    token: {}
};

const organizationSlice = createSlice({
    name: 'organization',
    initialState: { ...initialState },
    reducers: {
        getOrganizationDetailRequest(state) {
            state.isLoading = true;
        },
        getOrganizationDetailSuccess(state, action) {
            state.details = action.payload?.data ?? {};
            state.isLoading = false;
        },
        getOrganizationDetailFailure(state) {
            state.isLoading = false;
        },
        generateTokenRequest() { },
        generateTokenSuccess(state, action) {
            state.token = action.payload?.data ?? {};
        },
        generateTokenFailure() { },
        getTokenRequest() { },
        getTokenSuccess(state, action) {
            state.token = action.payload?.data ?? {};
        },
        getTokenFailure() { },
        updateOrganizationRequest() { },
        updateOrganizationSuccess() { },
        updateOrganizationFailure() { }
    }
});

export const { getOrganizationDetailRequest, getOrganizationDetailSuccess, getOrganizationDetailFailure, generateTokenRequest,
    generateTokenSuccess, generateTokenFailure, getTokenRequest, getTokenSuccess, getTokenFailure, updateOrganizationRequest,
    updateOrganizationSuccess, updateOrganizationFailure
} = organizationSlice.actions;
export default organizationSlice.reducer;