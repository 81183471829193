import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { AutoCompleteComponent, MemoTextBoxComponent } from '../../../../components';

// Constants
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../../helpers/appHelpers.js';

function InviteUsersCard(props) {

    /**
     * Define Props
     */
    const { classes, handleInviteUser } = props;
    const [inviteUser, setInviteUser] = useState({
        email: "",
        role: null
    });

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { roles } = useSelector((state) => state.security, shallowEqual);

    /**
     * Handle Value Change
     * @param {*} key
     * @param {*} value
     */
    const handleChange = (key, value) => {
        const eInviteUser = { ...inviteUser };
        eInviteUser[key] = value;
        setInviteUser(eInviteUser);
    };

    /**
     * Invite New Users
     * @param {*} event
     */
    const inviteNewUser = () => {
        handleInviteUser(inviteUser);
        setInviteUser({
            email: "",
            role: null
        });
    };

    return (
        <Grid className={classes.usersCard}>
            <Typography variant="body1" className={classes.cardTitle}>
                {appConstants.labels.security.inviteUsers}
            </Typography>
            <ValidatorForm className="w-100" onSubmit={() => inviteNewUser(event)}>
                <Grid container
                    justifyContent={'space-between'}
                    wrap="nowrap"
                    sx={
                        {
                            '& .textBoxContainer': {
                                width: '100%'
                            }
                        }
                    }>
                    <MemoTextBoxComponent
                        name="email"
                        fullWidth
                        placeholder={appConstants.labels.authentication.email}
                        variant="standard"
                        size="medium"
                        value={inviteUser.email || ''}
                        validators={['required', 'isEmail']}
                        errorMessages={
                            [
                                setRequiredErrorMessage(appConstants.labels.authentication.email),
                                appConstants.errorMessages.invalidMail
                            ]
                        }
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <AutoCompleteComponent
                        className="pl-2 mr-4"
                        name="role"
                        fullWidth
                        selectedValue={inviteUser?.role?.label || ""}
                        data={roles || []}
                        onChange={(event, newValue) => handleChange('role', newValue)}
                        placeholder={appConstants.labels.security.selectRole}
                        validators={['required']}
                        errorMessages={[setRequiredErrorMessage(appConstants.labels.security.selectRole)]}
                        freeSolo={false}
                    />
                    <Grid item className="pl-2">
                        <Button type={'submit'} color="primary" variant="contained" size="small" disableElevation>
                            {appConstants.labels.security.invite}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
}

// default props
InviteUsersCard.defaultProps = {
    classes: {},
    handleInviteUser: () => { }
};

// prop types
InviteUsersCard.propTypes = {
    classes: PropTypes.object,
    handleInviteUser: PropTypes.func
};

export default InviteUsersCard;