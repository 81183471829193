import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, IconButton } from '@mui/material';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';


//Import Icons
import { CloseIcon } from '../../assets/svg/index.js';

// Constants
import appConstants from '../../constants/appConstants.js';

// Import Components
import { TableComponent } from '../../components/index';
import LogViewer from './components/logViewer/index.jsx';


// Import Reducers
import { getExecutionLogsRequest, triggerTaskRequest, updateExecutionLogs, clearExecutionLogs } from '../../redux/reducer/logsReducer';
import { navigate } from '../../redux/reducer/navigationReducer';
import { checkPermission, permissionHeaders } from '../../helpers/appHelpers.js';
import featureConstants from '../../constants/featureConstants.js';


function Logs(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { connection_id } = useParams();
    const reactNavigation = useNavigate();
    const location = useLocation();
    const searchControllerRef = useRef();

    /**
     * Get Redux Values
     */
    const { list: executionLogs, stat, searchConfig, isLoading, isLoadMore, connection_has_license, connection_is_active } = useSelector((state) => state.logs.executionLogs, shallowEqual);
    const { list: connectionList } = useSelector((state) => state.connection, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);
    const [searchParams, setSearchParams] = useSearchParams();
    let queue_id = searchParams.get("queue_id");
    if (location?.state?.filter) {
        queue_id = location?.state?.item_id;
    }

    const defaultSearchData = {
        "name": "",
        "start_date": "",
        "end_date": "",
        "duration": "",
        "state": ""
    };


    /**
     * Define State
     */
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [sorting, setSorting] = useState({
        sortBy: "",
        orderBy: "asc"
    });
    const [subTableSorting, setSubTableSorting] = useState({
        sortBy: "",
        orderBy: "asc",
        queueId: ""
    });
    const [logView, setLogViewer] = useState({
        open: false,
        anchorElement: null,
        taskId: "",
        errorMessage: "",
        errorDetail: "",
        logInfo: null
    });
    const [isClearFilter, setClearFilter] = useState(false);

    /**
     * Get Logs
     */
    const getLogs = (params, clear = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        const requestParams = {
            ...searchConfig,
            search: searchData,
            ...sorting,
            ...params,
            isRefresh: true,
            connection_id
        };
        dispatch(getExecutionLogsRequest({ params: requestParams, token, clear }));
    };

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };


    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (!executionLogs.length) {
            const requestParams = {
                ...searchConfig,
                search: {
                    ...searchConfig.search,
                    queue_id
                },
                offset: 0,
                isRefresh: true,
                connection_id
            };
            if (queue_id) {
                setClearFilter(true);
                removeSearchParam();
            }
            getLogs(requestParams);
        }
        return (() => {
            dispatch(clearExecutionLogs());
        });
    }, []);


    /**
     * Clear Search Filters
     */
    const clearFilters = () => {
        setSearchData({ ...defaultSearchData });
        setClearFilter(false);
        const requestParams = {
            ...searchConfig,
            offset: 0,
            isRefresh: true,
            connection_id
        };
        getLogs(requestParams, true);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        if (key === 'status' && value === "All") {
            search_by[key] = "";
        }
        setSearchData(search_by);
        getLogs({ search: search_by, offset: 0 }, true);
    };

    /**
     * Handle Close LogViewer
     */
    const onCloseLogViewer = () => {
        setLogViewer({
            open: false,
            anchorElement: null,
            taskId: "",
            errorMessage: "",
            errorDetail: "",
            logInfo: null
        });
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy, item) => {

        if (item) {
            switch (sortBy) {
                case 'start':
                    sortBy = 'start_time';
                    break;
                case 'end':
                    sortBy = 'end_time';
                    break;
                case 'durationText':
                    sortBy = 'duration';
                    break;
                default:
                    break;
            }
            setSubTableSorting({
                sortBy,
                orderBy,
                queueId: item.id
            });
        } else {
            const requestData = {
                sortBy, orderBy, offset: 0
            };
            getLogs({ ...requestData }, true);
            setSorting({
                sortBy,
                orderBy
            });
        }
    };

    /**
     * Bind Accordian Table
     */
    const onClickAccordian = (selectedItem, isOpen, queueDetailFilters = null) => {
        const requestParams = {
            id: selectedItem.id,
            isOpen,
            queueDetailFilters
        };
        dispatch(updateExecutionLogs(requestParams));
    };

    /**
     * Handle Action Event
     */
    const onClickActions = (selectedItem, actionType, event, parentItemId) => {
        if (actionType === "error_log" && selectedItem?.error_message) {
            setLogViewer({
                open: true,
                anchorElement: event.target,
                taskId: selectedItem.id,
                errorMessage: selectedItem.error_message,
                errorDetail: selectedItem.error_log,
                logInfo: selectedItem.task_config,
                queueId: parentItemId
            });
        }
        if (actionType === "run" && selectedItem?.id) {
            const status = ["Running", "Pending", "UpForRetry"];
            if (status.indexOf(selectedItem?.status) < 0) {
                const requestParams = {
                    task_id: parentItemId ? selectedItem.id : null,
                    queue_id: !parentItemId ? selectedItem.id : parentItemId,
                    type: selectedItem.type
                };
                dispatch(triggerTaskRequest(requestParams));
            }
        }
    };

    /**
     * Handle Action Event
     */
    const onCellClick = (key, data) => {
        let status = null;
        let selectedQueue = null;
        switch (key) {
            case "name":
                if (data.asset_id) {
                    dispatch(navigate({ path: 'assets.root', state: {}, params: [data.asset_id] }));
                } else {
                    if (data.name === "user_activity") {
                        dispatch(navigate({ path: 'settings.semantics.security.root', state: { "tabname": "activity" } }));
                    } else {
                        const measure_id = data?.queue_details[0]?.measure_id;
                        if (measure_id) {
                            const isAssetLevel = data.asset_id && !data.attribute_id;
                            const isMeasureLevel = !data.asset_id;
                            const state = { asset_id: data.asset_id, attribute_id: data.attribute_id, showEdit: true, isAssetLevel, isMeasureLevel, prevUrl: true };
                            dispatch(navigate({ path: 'measure.detail', state: state, params: [measure_id] }));
                        } else {
                            reactNavigation(`/home/measures`);
                        }
                    }
                }
                break;
            case "attribute_name":
                selectedQueue = executionLogs.find((item) => item.id === data.queue_id);
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [selectedQueue?.asset_id, data.attribute_id] }));
                break;
            case "measure_name":
                if (data?.measure_name) {
                    selectedQueue = executionLogs.find((item) => item.id === data.queue_id);
                    if (selectedQueue?.asset_id) {
                        const isAssetLevel = Boolean(!(data.attribute_id || selectedQueue?.attribute_id) && selectedQueue?.asset_id);
                        const state = { asset_id: selectedQueue.asset_id, attribute_id: data.attribute_id || selectedQueue?.attribute_id, isMeasureLevel: false, isAssetLevel, prevUrl: true };
                        dispatch(navigate({ path: 'measure.detail', state: state, params: [data.measure_id] }));
                    } else {
                        const measure_id = selectedQueue?.queue_details[0]?.measure_id;
                        const state = { asset_id: data?.asset_id, attribute_id: data?.attribute_id, isMeasureLevel: true, isAssetLevel: false, prevUrl: true };
                        dispatch(navigate({ path: 'measure.detail', state: state, params: [measure_id] }));
                    }
                }
                break;
            case "total_tasks":
                status = "";
                onClickAccordian(data, true, status ? { status } : null);
                break;
            case "failed_tasks":
                status = "Failed";
                onClickAccordian(data, true, status ? { status } : null);
                break;
            case "completed_tasks":
                status = "Completed";
                onClickAccordian(data, true, status ? { status } : null);
                break;
            default:
                status = null;
                break;
        }
    };

    /**
     * Handle subtable sorting
     * @param {*} data
     * @param {*} queueId
     * @param {*} sortBy
     * @param {*} orderBy
     * @returns
     */
    const filterSubTable = (data, queueId, sortBy, orderBy) => {
        const queueIndex = data.findIndex((item) => item.id === queueId);
        if (queueIndex > -1) {
            const queue = Object.assign({}, data[queueIndex]);
            const filtered_queue_details = _.orderBy(queue.filtered_queue_details, [sortBy], [orderBy]);
            queue.filtered_queue_details = JSON.parse(JSON.stringify(filtered_queue_details));
            data.splice(queueIndex, 1, queue);
        }
        return data;
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = () => {
        if (!isLoading && isLoadMore) {
            getLogs({ offset: searchConfig.offset + searchConfig.limit });
        }
    };

    /**
     * Handle Log Refresh
     * @param {*} event
     */
    const onClickRefresh = () => {
        getLogs({ offset: 0 }, true);
    };

    /**
     * Handle Close
     * @param {*} event
     */
    const onClose = () => {
        dispatch(clearExecutionLogs());
        reactNavigation(-1);
    };

    /**
     * Filter Execution Logs using UseMemo
     */
    let filterLogs = Object.assign([], executionLogs);
    if (subTableSorting?.queueId) {
        filterLogs = filterSubTable(filterLogs, subTableSorting.queueId, subTableSorting.sortBy, subTableSorting.orderBy);
    }

    let selectedConnection = connectionList.find((list) => list.id === connection_id);
    if ((!selectedConnection || (selectedConnection && !selectedConnection?.name)) && stat?.name) {
        selectedConnection = {
            connection_id,
            name: stat?.name,
            description: stat?.description
        };
    }
    const tableOptions = [...appConstants.tableOptions.logs, { type: "refresh", customFunction: () => onClickRefresh() }, { type: "clear", customFunction: clearFilters, isClearFilter: isClearFilter }];

    /**
     * Get Asset Level Headers
     * @returns
     */
    const getAssetLevelLogsHeader = () => {
        const a_headers = [...appConstants.table.executionLogsJobHeader];
        if (connection_has_license && connection_is_active) {
            a_headers.push(
                {
                    key: 'actions',
                    name: 'Actions',
                    width: 'auto',
                    actions: [{ type: 'run', tooltip: true, tooltipText: 'Run' }]
                }
            );
        }
        return a_headers;
    };

    /**
     * Get Attribute Level Headers
     * @returns
     */
    const getAttributeLevelLogsHeader = () => {
        const a_headers = [...appConstants.table.executionLogsTaskHeader];
        if (connection_has_license && connection_is_active) {
            a_headers.push(
                {
                    key: 'actions',
                    name: 'Actions',
                    width: '5%',
                    actions: [
                        { type: 'error_log', tooltip: true, tooltipText: 'View Error Log' },
                        { type: 'run', tooltip: true, tooltipText: 'Run' }
                    ]
                }
            );
        }
        return a_headers;
    };


    return (
        <Grid container className={classes.container}>
            <Grid
                container
                justifyContent="space-between"
                alignItems={'flex-start'}
                className={classes.dialogHeader}
            >
                <Grid item xs={6}>
                    <Grid item className="dflex alignCenter">
                        <Typography variant="h2" className="capitalize mr-3 pb5">
                            {selectedConnection?.name ?? "Name"}
                        </Typography>
                    </Grid>
                    <Typography variant="body1">
                        {selectedConnection?.description ?? "Description"}
                    </Typography>
                </Grid>

                <Grid item xs={6} className={classes.actions}>
                    <Grid className="dflex alignCenter flexEnd">
                        <IconButton className="" onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} className="pt-2">
                <Grid className={classes.usersCard}>
                    <Typography variant="body1" className={classes.cardTitle}>
                        {appConstants.labels.logs.status}
                    </Typography>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <Typography variant="body1" className={classes.cardText} onClick={() => onHandleSearchEvent("status", "")}>
                                {stat?.Total || 0}
                            </Typography>
                            <Typography variant="body1" className={classes.cardValue}>
                                {appConstants.labels.logs.total}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className={classes.cardText} onClick={() => onHandleSearchEvent("status", "Pending")}>
                                {stat?.Pending || 0}
                            </Typography>
                            <Typography variant="body1" className={classes.cardValue}>
                                {appConstants.labels.logs.pending}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className={classes.cardText} onClick={() => onHandleSearchEvent("status", "Running")}>
                                {stat?.Running || 0}
                            </Typography>
                            <Typography variant="body1" className={classes.cardValue}>
                                {appConstants.labels.logs.running}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className={classes.cardText} onClick={() => onHandleSearchEvent("status", "Completed")}>
                                {stat?.Completed || 0}
                            </Typography>
                            <Typography variant="body1" className={classes.cardValue}>
                                {appConstants.labels.logs.completed}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="pt-2 executionLogsTable">
                <TableComponent
                    styleType="striped"
                    headers={permissionHeaders(getAssetLevelLogsHeader(), overviewPermission)}
                    data={filterLogs || []}
                    searchData={searchData}
                    onHandleSearchEvent={onHandleSearchEvent}
                    options={tableOptions}
                    haveSubTable
                    subTable={{ "headers": permissionHeaders(getAttributeLevelLogsHeader(), overviewPermission), "rows": "filtered_queue_details", height: "200px", ...subTableSorting }}
                    subTableColSpan={appConstants.table.executionLogsJobHeader.length + 3}
                    onClickActions={onClickActions}
                    onSubTableActions={onClickActions}
                    onClickAccordian={onClickAccordian}
                    onCellClick={onCellClick}
                    NoResultText="No Logs Found"
                    isLoading={isLoading}
                    sortBy={sorting.sortBy}
                    orderBy={sorting.orderBy}
                    onClickSorting={onClickSorting}
                    onClickSubTableSorting={onClickSorting}
                    height="calc(100vh - 300px)"
                    stickyHeader
                    onScrollEnd={onScrollEvent}
                />
            </Grid>
            {
                logView &&
                <LogViewer
                    open={logView.open}
                    anchorElement={logView.anchorElement}
                    errorMessage={logView.errorMessage}
                    errorDetail={logView.errorDetail}
                    taskId={logView.taskId}
                    onClose={onCloseLogViewer}
                />
            }
        </Grid>
    );
}

// default props
Logs.defaultProps = {
    classes: {}
};

// prop types
Logs.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Logs);