const style = (theme) => ({
    connectorForm: {
        padding: "24px 34px 0px",
        boxShadow: '0px 0px 4px #00000014',
        marginBottom: 20,
        background: `${theme.palette.primary.contrastText}`,
        width: '100%',
        height: 'calc(100% - 20px)',
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px !important'
        },
        '& .Mui-expanded, .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionDetails-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        },
        '& .actionsContainer': {
            position: 'sticky',
            bottom: '0px',
            paddingBottom: '24px',
            background: '#fff'
        },
        overflowY: 'auto'
    },
    connectorFormContainer: {
        padding: 24
    },
    connectorIcon: {
        marginRight: '16px !important',
        '& .connectorCardImg': {
            height: '40px !important'
        },
        '& svg': {
            fontSize: '36px !important',
            width: '36px !important',
            height: '36px !important'
        },
        '& img': {
            height: 45
        }
    },
    formContainer: {
        marginTop: '35px !important',
        // marginLeft: '-50px !important',
        '& .MuiGrid-grid-xs-6': {
            // paddingLeft: '90px !important'
        }
    }
});
export default style;