import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { nanoid } from "nanoid";
import { TableRow, TableCell, IconButton, Tooltip, Table, Grid, Typography, TableBody, Button, FormHelperText } from "@mui/material";

//Import Components
import { ValueEditorComponent, ChipComponent, SelectComponent, AutoCompleteComponent, ColorPickerComponent } from "../index";

// Validation

// Import Images
import { CheckIcon, CloseIcon2 } from "../../assets/svg";
import palette from "../../assets/theme/palette";
import { getFileColumns, setRequiredErrorMessage } from "../../helpers";


const AddTableBodyRowComponent = (props) => {

    /**
     * Define Props
     */
    const {
        classes, headers, haveSubTable, onClickActions, completeNewRowData,
        parentDetail, selectComponentList, defaultAddRowData, onChangeAddRowData,
        disableAddRowActions, isDirectRow, className, rowDraggable } = props;

    /**
     * Define State
     */
    const [newRowData, setNewRowData] = useState();
    const [selectedHeader, setSelectedHeader] = useState();

    /**
     * Define Use Effects
     */
    useEffect(() => {
        /**
         * Setting initial Data
         */
        let initialData = {};
        for (const field of headers.filter((header) => header.key !== 'actions')) {
            if (field.datatype === 'array') {
                initialData[field.key] = [];
            } else if (field.key === 'color') {
                initialData[field.key] = palette.secondary.main;
            } else if (field.datatype === 'text') {
                initialData[field.key] = '';
            } else if (field.datatype === 'integer') {
                initialData[field.key] = 1;
            }
            if (field.defaultValue) {
                initialData[field.key] = field.defaultValue;
            }
        }
        if (defaultAddRowData) {
            initialData = { ...defaultAddRowData };
        }
        setNewRowData(initialData);
    }, [headers, defaultAddRowData]);

    /**
     * Handle Component Events
     * @param {*} key
     * @param {*} value
     */
    const onHandleComponentEvent = (header, event) => {
        if (!(header && event)) {
            return;
        }

        let componentType = header.component;
        componentType = header.getComponentType ? header.getComponentType(newRowData, header) : componentType;
        if (componentType === "button" && header?.actionType) {
            switch (header?.actionType) {
                case 'file_upload':
                    document.getElementById("addRowfileUploader").click();
                    setSelectedHeader(header);
                    return;
                case 'api_headers':
                    if (header?.onClick) {
                        header?.onClick(event, header, newRowData, null, true);
                    }
                    setSelectedHeader(header);
                    return;
                default:
                    break;
            }
        }
        if (componentType === "button") {
            return;
        }
        let value = (event instanceof Object) && !Array.isArray(event) ? event.target.value : event;
        if (componentType === "textbox") {
            if (header?.allow_space === false) {
                value = value.replace(/\s+/g, '');
            }
        }
        setNewRowData((newRowData) => {
            const data = { ...newRowData, [header.key]: value };
            if (onChangeAddRowData) {
                onChangeAddRowData(header.key, value, data);
            }
            return data;
        });
    };

    /**
     * Handle file upload
     */
    const handleSelectFile = (event, item, header) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            if (header?.includeColumns) {
                getFileColumns(
                    selectedFile, { delimiter: item?.delimiter }
                ).then((response) => {
                    const isCSV = selectedFile?.name?.toLowerCase().endsWith("csv");
                    let delimiter = response?.delimiter;
                    if (isCSV) {
                        delimiter = response?.delimiter || ',';
                    } else if (selectedFile?.name?.toLowerCase().endsWith("tsv")) {
                        delimiter = response?.delimiter || 'tab';
                    } else if (selectedFile?.name?.toLowerCase().endsWith("txt")) {
                        delimiter = response?.delimiter || ',';
                    }
                    const properties = {
                        ...item.properties,
                        delimiter: delimiter,
                        columns: response?.columns ? [...response.columns] : []
                    };
                    const columns = properties.columns || [];
                    const inputFields = [];
                    for (const column of columns) {
                        inputFields.push({ id: nanoid(), name: column });
                    }
                    setNewRowData((newRowData) => {
                        const data = {
                            ...newRowData,
                            sourceRequired: "",
                            source: selectedFile.name,
                            delimiter: delimiter,
                            columns: inputFields || [],
                            selectedFile,
                            properties
                        };
                        if (onChangeAddRowData) {
                            onChangeAddRowData(header.key, data, newRowData);
                        }
                        return data;
                    });
                });
            } else {
                setNewRowData((newRowData) => {
                    const data = {
                        ...newRowData,
                        sourceRequired: "",
                        source: selectedFile.name,
                        selectedFile
                    };
                    if (onChangeAddRowData) {
                        onChangeAddRowData(header.key, data, newRowData);
                    }
                    return data;
                });
            }
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Handle Adding new Chip in the Chip Component
     * @param {*} value
     * @param {*} key
     */
    const handleChipAdd = (value, header) => {
        setNewRowData((newRowData) => {
            const data = { ...newRowData };
            if (header.valueKey) {
                value = { [header.valueKey]: value };
            }
            value = newRowData[header.key] ? [...newRowData[header.key], value] : [value];
            data[header.key] = value;
            if (onChangeAddRowData) {
                onChangeAddRowData(header.key, value, data);
            }
            return data;
        });
    };

    /**
     * Handle Deleting Chip in the Chip Component
     * @param {*} value
     * @param {*} key
     */
    const handleChipDelete = (value, key) => {
        const updatedChips = newRowData[key].filter((chip) => chip !== value);
        setNewRowData((newRowData) => {
            const data = { ...newRowData, [key]: updatedChips };
            if (onChangeAddRowData) {
                onChangeAddRowData(key, updatedChips, data);
            }
            return data;
        });
    };

    /**
     * Bind Cell Component
     * @param {*} item
     * @returns
     */
    const bindCellComponent = (header) => {
        if (header.datatypeRestrict) {
            const targetHeader = headers.find((item) => item.key === header.dataTypeKey);
            const targetDefault = targetHeader?.defaultValue ? targetHeader.defaultValue : "";
            const dataValue = newRowData && header.dataTypeKey in newRowData ? newRowData[header.dataTypeKey] : header?.defaultValue;
            if ((dataValue && dataValue !== header.allowDatatype) || (!dataValue && targetDefault !== header.allowDatatype)) {
                return (
                    <Typography>
                        NA
                    </Typography>
                );
            }
        }
        let componentType = header.component;
        componentType = header.getComponentType ? header.getComponentType(newRowData, header) : componentType;
        switch (componentType) {
            case 'textbox':
                const inputProps = { className: header?.className ?? null };
                if (header.validate) {
                    inputProps.validators = header.validators || ['required'];
                    inputProps.errorMessages = header.errorMessages || [setRequiredErrorMessage(header.name)];
                }
                if (header.isVisible && !header.isVisible(newRowData)) {
                    return (
                        <Typography>
                            {"NA"}
                        </Typography>
                    );
                }
                return (
                    <ValueEditorComponent
                        noOutline
                        variant="standard"
                        label={header.componentLabel}
                        value={newRowData?.[header.key]}
                        type={header.datatype || newRowData[header.componentKey] || "integer"}
                        onChange={(event) => onHandleComponentEvent(header, event, header)}
                        name={header.name}
                        isRequired={header.isRequired}
                        placeholder={header.placeholder}
                        {...inputProps}
                    />
                );
            case 'button':
                if (header.isVisible && !header.isVisible(newRowData)) {
                    return (
                        <Typography>
                            {"NA"}
                        </Typography>
                    );
                }
                return (
                    <Grid container direction="column" className={"btnValidator"}>
                        <Grid item xs={12}>
                            <Button
                                className={header.className || ""}
                                variant="outlined"
                                size="small"
                                style={{ width: header.isFullWidth ? `100%` : null }}
                                onClick={(event) => onHandleComponentEvent(header, event)}
                                disabled={header.isDisabled && header.isDisabled(newRowData, header)}
                            >
                                {header.showValue && newRowData && header.key in newRowData && newRowData[header.key] ? newRowData[header.key] : header.label}
                            </Button>
                        </Grid>
                        {
                            (header.validate && newRowData && header.btnValidationMessageKey && newRowData[header.btnValidationMessageKey])
                            &&
                            <FormHelperText error>
                                {newRowData[header.btnValidationMessageKey]}
                            </FormHelperText>
                        }
                    </Grid>
                );
            case 'chips':
                let listData = selectComponentList[header.key] || [];
                if (header.listInputColumn && newRowData) {
                    listData = newRowData[header.listInputColumn] || [];
                }
                if (header.getListInputData) {
                    listData = header.getListInputData(newRowData);
                    listData = listData || [];
                }
                let addType = header.chipAddType || "text";
                if (header?.getAddType) {
                    addType = header?.getAddType(newRowData);
                }
                listData = listData || [];
                addType = addType || "text";
                return (
                    <ChipComponent
                        chipClass={`${header.className}`}
                        data={newRowData?.[header.key] ?? []}
                        labelKey={header.valueKey || ""}
                        limit={header.limit || 1}
                        editable={!header.isNotEditable}
                        onChipAdd={(value) => handleChipAdd(value, header)}
                        onChipRemove={(selectedChip) => handleChipDelete(selectedChip, header.key)}
                        onChipSelect={(selectedChip, event) => selectedChip && handleChipAdd(selectedChip, header, event)}
                        add={header.isAdd}
                        addType={addType}
                        showNA={header.showNA}
                        disableInput={header.disableInput || (header.isDisabled && header.isDisabled(newRowData, header))}
                        addLimit={header.addLimitCount || null}
                        addText={header.addText || null}
                        className={header.className}
                        haveColor={header.haveColor}
                        isChipDelete={header.chipDelete}
                        enableLink={header.enableLink}
                        availableList={header.getListInputData ? header.getListInputData(newRowData) : listData}
                        handleChipEvent={(event) => onHandleComponentEvent(header, event)}
                    />
                );
            case 'subtagchips':
                const subChips = [...selectComponentList[header.key]];
                const index = subChips.findIndex((data) => data.id === parentDetail);
                if (index >= 0) {
                    subChips.splice(index, 1);
                }
                return (
                    <ChipComponent
                        chipClass={`${header.className}`}
                        data={newRowData?.[header.key] || []}
                        labelKey={header.valueKey || ""}
                        limit={header.limit || 1}
                        editable={!header.isNotEditable}
                        onChipAdd={(value) => handleChipAdd(value, header.key)}
                        onChipRemove={(selectedChip) => handleChipDelete(selectedChip, header.key)}
                        add={header.isAdd}
                        addType={header.chipAddType || "text"}
                        availableList={subChips || []}
                        handleChipEvent={(event) => onHandleComponentEvent(header, event)}
                    />
                );
            case 'color':
                return <ColorPickerComponent value={newRowData?.[header.key]} onChange={(value) => onHandleComponentEvent(header, value)} />;
            case 'select':
                if (header.isVisible && !header.isVisible(newRowData)) {
                    return (
                        <Typography>
                            {"NA"}
                        </Typography>
                    );
                }
                return (
                    <SelectComponent
                        className={`${classes.outlineNone} outlineNone ${header?.className ?? null}`}
                        value={newRowData?.[header.key] || header.defaultValue || ""}
                        onSelectChange={(value) => onHandleComponentEvent(header, value)}
                        isDisabled={header.isDisabled && header.isDisabled(newRowData, header)}
                        list={selectComponentList[header.key] || []}
                        propertyName={header.componentKey}
                        placeholder={header.placeholder || ""}
                        displayPropertyName={header.selectComponentKey} />
                );
            case 'autocomplete':
                return (
                    <AutoCompleteComponent
                        selectedValue={newRowData?.[header.key] || ""}
                        data={selectComponentList[header.key] || []}
                        fullWidth
                        variant="standard"
                        onChange={
                            (event, newValue) => {
                                let value = newValue;
                                if (value) {
                                    value = newValue[header.componentKey] || newValue[header.key] || newValue;
                                }
                                onHandleComponentEvent(header, value);
                            }
                        }
                        placeholder={header.placeholder}
                        create={header.createable}
                        labelKey={header.componentKey}
                        customOption
                    />
                );
            default:
                return (
                    <span />
                );
        }
    };

    /**
     * Handle creating or cancelling application
     */
    const handleActionsClick = (data, actionName, event) => {
        onClickActions(data, actionName, event, parentDetail);
        completeNewRowData();
    };

    /**
     * Bind Cell Actions
     */
    const bindCellActions = (actions) => {
        return (
            actions && actions.map((item, index) => {
                let action = null;
                switch (item.type) {
                    case 'create':
                    case 'save':
                        action = (<CheckIcon key={`${item.type}-${index}`} />);
                        break;
                    case 'cancel':
                        action = (<CloseIcon2 key={`${item.type}-${index}`} />);
                        break;
                    default:
                        action = null;
                        break;
                }
                return action ? (
                    <Tooltip key={`tooltip-${index}`} title={item.type} arrow>
                        <IconButton
                            key={`icon-${index}`}
                            aria-label="Cancel"
                            color="inherit"
                            className={'p5'}
                            type={item.actionType || "button"}
                            disabled={disableAddRowActions}
                            onClick={(event) => item.actionType !== "submit" && handleActionsClick(newRowData, item.type, event)}
                        >
                            {action}
                        </IconButton>
                    </Tooltip>
                ) : null;
            })
        );
    };

    /**
     * Render Cell
     * @param {*} item
     * @param {*} index
     * @returns
     */
    const renderCell = (header) => {
        if (header.key === 'actions') {
            return bindCellActions(header.actions);
        }
        return bindCellComponent(header);
    };

    if (isDirectRow) {
        return (
            <TableRow className={className}>
                {
                    headers && headers.map((header, index) => (
                        <TableCell key={index} style={{ width: header.width || '', textAlign: header.align || 'left', padding: 10 }}>
                            <Grid className={`dflex alignCenter ${header.align && "justifyCenter"}`}>
                                {renderCell(header, index)}
                            </Grid>
                        </TableCell>
                    ))
                }
                <input
                    accept="application/JSON,.csv,.tsv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: 'none' }}
                    id={`addRowfileUploader`}
                    type="file"
                    onChange={(event) => handleSelectFile(event, newRowData, selectedHeader)}
                />
            </TableRow>
        );
    }

    return (
        <TableRow className={className}>
            <TableCell colSpan={haveSubTable ? headers.length + 1 : headers.length} sx={{ padding: '0 !important' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            {
                                rowDraggable &&
                                <TableCell />
                            }
                            {
                                headers && headers.map((header, index) => (
                                    <TableCell key={index} style={{ width: header.width || '', textAlign: header.align || 'left', padding: 10 }}>
                                        <Grid className={`dflex alignCenter ${header.align && "justifyCenter"}`}>
                                            {renderCell(header, index)}
                                        </Grid>
                                    </TableCell>
                                ))
                            }
                            {
                                haveSubTable ?
                                    <TableCell sx={{ padding: '0px 24px !important' }} /> :
                                    null
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>
    );
};

/**
 * Set Component Props
 */
AddTableBodyRowComponent.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    haveSubTable: PropTypes.bool,
    disableAddRowActions: PropTypes.bool,
    onClickActions: PropTypes.func,
    completeNewRowData: PropTypes.func,
    parentDetail: PropTypes.string,
    selectComponentList: PropTypes.object,
    defaultAddRowData: PropTypes.object,
    onChangeAddRowData: PropTypes.func,
    isDirectRow: PropTypes.bool,
    className: PropTypes.string,
    rowDraggable: PropTypes.bool
};

/**
 * Set Default Values
 */
AddTableBodyRowComponent.defaultProps = {
    classes: [],
    headers: [],
    haveSubTable: false,
    disableAddRowActions: false,
    completeNewRowData: () => { },
    onClickActions: () => { },
    selectComponentList: {},
    defaultAddRowData: {},
    onChangeAddRowData: () => { },
    isDirectRow: false,
    className: null,
    rowDraggable: false
};

export default AddTableBodyRowComponent;