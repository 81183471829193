import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { PopOverComponent, TableComponent } from '../index';

//  Import Styles
import PatternAddStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helpers
import appConstants from '../../constants/appConstants.js';
import { PlusIcon } from '../../assets/svg';
import { nanoid } from 'nanoid';


function LookupAPIHeaders(props) {
    const { classes, open, anchorElement, headers, onClose, onSave } = props;
    const defaultHeader = {
        id: nanoid(),
        key: "",
        value: ""
    };
    const apiTableHeaders = [
        { key: 'key', name: 'Key', datatype: 'text', placeholder: 'Enter the key', component: 'textbox', applyHeaderStyle: true, validate: true, validators: ['required'], errorMessages: ['Key is required'] },
        { key: 'value', name: 'Value', datatype: 'text', placeholder: 'Enter the value', component: 'textbox', applyHeaderStyle: true, validate: true, validators: ['required'], errorMessages: ['Value is required'] },
        {
            key: 'actions',
            name: 'Actions',
            width: '5%',
            applyHeaderStyle: true,
            actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }]
        }
    ];

    /**
     * Define State
     */
    const [apiHeaders, setApiHeaders] = useState([JSON.parse(JSON.stringify(defaultHeader))]);

    /**
     * Define useEffect
     */
    useEffect(() => {
        if (headers?.length > 0) {
            setApiHeaders([...headers]);
        }
        return () => {
            setApiHeaders([defaultHeader]);
        };
    }, [headers]);

    const onAddNewHeader = () => {
        const headers = JSON.parse(JSON.stringify(apiHeaders));
        const newHeader = JSON.parse(JSON.stringify(defaultHeader));
        headers.splice(0, 0, { ...newHeader });
        setApiHeaders([...headers]);
    };

    /**
     * Handle Change
     * @param {*} property
     * @param {*} value
     */
    const onCompnentEvent = useCallback((key, value, item) => {
        const headers = JSON.parse(JSON.stringify(apiHeaders));
        const index = headers.findIndex((header) => header.id === item.id);
        item[key] = value;
        if (index > -1) {
            headers.splice(index, 1, item);
            setApiHeaders([...headers]);
        }
    }, [apiHeaders]);

    /**
     * Handle Adding or Deleting Application
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = useCallback((item) => {
        const headers = JSON.parse(JSON.stringify(apiHeaders));
        const index = headers.findIndex((header) => header.id === item.id);
        if (index > -1) {
            apiHeaders.splice(index, 1);
            if (apiHeaders?.length > 0) {
                setApiHeaders([...apiHeaders]);
                return;
            }
            setApiHeaders([JSON.parse(JSON.stringify(defaultHeader))]);
        }
    }, [apiHeaders]);

    return (
        <PopOverComponent
            anchorEl={anchorElement}
            open={open}
        >
            <Grid container className={classes.modalContainer}>
                <ValidatorForm onSubmit={() => onSave(apiHeaders)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}>
                                <Grid item>
                                    <Typography variant="h5">
                                        {"Add Headers"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={"Add Header"} arrow>
                                        <IconButton className={'p5'}
                                            onClick={() => onAddNewHeader()}
                                        >
                                            <PlusIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.tableContainer}>
                            <TableComponent
                                headers={apiTableHeaders}
                                styleType={null}
                                stickyHeader
                                data={apiHeaders || []}
                                onCompnentEvent={onCompnentEvent}
                                onClickActions={handleTableActions}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="mr-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={onClose}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </PopOverComponent>
    );
}

// default props
LookupAPIHeaders.defaultProps = {
    classes: {},
    open: false,
    anchorElement: null,
    headers: [],
    onClose: () => { },
    onSave: () => { }
};

// prop types
LookupAPIHeaders.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorElement: PropTypes.object,
    headers: PropTypes.array,
    onClose: PropTypes.func,
    onSave: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...PatternAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LookupAPIHeaders);