const style = (theme) => ({
    sidebarContainer: {
        paddingRight: '0px',
        paddingLeft: 0,
        borderRight: `1px solid ${theme.palette.greyshades.darkgrey}40`,
        position: 'relative',
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        width: 300,
        boxSizing: 'border-box',
        minHeight: 'calc(100vh - 257px)',
        height: '100%',
        backgroundColor: theme.palette.greyshades.lightbackground,
        '&.collapsedSidebar': {
            transform: 'translateX(-288px)',
            backgroundColor: '#fff',
            '& .MuiTreeView-root': {
                visibility: 'hidden'
            }
        },
        '& .MuiTreeItem-root': {
            textAlign: 'left',
            '& .IconText': {
                display: 'flex',
                alignItems: 'center',
                '& .FolderIcon': {
                    width: '24px',
                    height: '24px',
                    marginRight: '8px'
                },
                '& .FileIcon2': {
                    width: '20px',
                    height: '20px',
                    marginRight: '8px'
                }
            },
            '& .ChevRightIcon:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiTreeView-root': {
            maxWidth: 300,
            overflowX: 'hidden',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 268px)',
            padding: '20px 10px 16px 16px',
            '& .Mui-selected': {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                '&.Mui-focused': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                    }
                }
            }
        },
        '& .MuiTreeItem-content': {
            padding: '8px',
            maxWidth: 'calc(100% - 24px)'
        }
    },
    iconBg: {
        backgroundColor: '#ffffff',
        right: '-13px',
        top: 45,
        padding: 5,
        width: 26,
        height: 26,
        borderRadius: '50%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 8px #0000001a',
        cursor: 'pointer',
        transition: 'all 0.5s',
        '&.collapsed': {
            right: '-14px',
            transform: 'rotate(180deg)'
        },
        '&:hover .ChevLeftIcon path': {
            fill: theme.palette.secondary.main
        }
    },
    treeItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiIconButton-root': {
            padding: 0
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    options: {
        padding: '6px 0px !important'
    },
    addContainer: {
        background: '#fff',
        boxShadow: '0px 3px 8px #0000000f',
        borderRadius: '6px',
        padding: '24px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        '& .domainCard': {
            boxShadow: 'none !important',
            background: '#fdfdfd'
        },
        '& .MuiGrid-grid-xs-3': {
            maxWidth: '100%'
        },
        '& .MuiFormControl-root': {
            marginBottom: 18
        },
        '& .MuiIconButton-root:hover': {
            '& .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .closeBtn': {
            width: '18px',
            height: '18px'
        }
    }
});
export default style;