import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Checkbox, FormControlLabel } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import { AutoCompleteComponent, TextBoxComponent, SelectComponent, NumberInputComponent } from '../../../../components/index.js';
import GroupBySelector from '../groupBy/index.jsx';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

// Import Action
import { getReportsAssetsRequest, getReportsColumnsRequest, resetReportStateValues } from '../../../../redux/reducer/reportsReducer';

// Import Icons
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


function Properties(props) {

    /**
     * Define Props
     */
    const { classes, detail, onChange, reportsPermission } = props;
    const dispatch = useDispatch();
    const propertyControllerRef = useRef();

    /**
     * Redux Store to get values
     */
    const { reports_assets, reports_columns, assetsLoading, columnsLoading } = useSelector((state) => state.reports);


    /**
     * Get Report Assets Data
     */
    useEffect(() => {
        if (!reports_assets || !reports_assets.length) {
            dispatch(getReportsAssetsRequest());
        }
    }, [dispatch]);

    /**
     * Get Reports Columns by Assets Data
     */
    useEffect(() => {
        if (detail?.assets?.length) {
            if (propertyControllerRef && propertyControllerRef.current) {
                propertyControllerRef.current.abort();
            }
            propertyControllerRef.current = new AbortController();
            const token = { signal: propertyControllerRef?.current?.signal };

            const params = {
                'assets': detail?.assets ?? []
            };
            dispatch(getReportsColumnsRequest({ params, token }));
        } else {
            dispatch(resetReportStateValues("report_columns"));
        }
    }, [detail.assets]);

    return (
        <Grid item xs={12} className={classes.PropertiesContainer}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="body1" className="pb5">
                        Reporting Table
                    </Typography>
                    <AutoCompleteComponent
                        id="form_text_assets"
                        name={'assets'}
                        variant="outlined"
                        fullWidth
                        placeholder={"Select Reporting Table"}
                        disableCloseOnSelect
                        disableClearable
                        customOption
                        data={reports_assets}
                        compareKey={'id'}
                        multiple
                        selectedValue={detail?.assets ?? []}
                        onChange={
                            (event, value) =>
                                onChange('assets', value)
                        }
                        limitTags={1}
                        loading={assetsLoading}
                        renderType={'reportMap'}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        disabled={!reportsPermission?.is_edit || assetsLoading}
                        select_all
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" className="pb5">
                        Attributes
                    </Typography>
                    <AutoCompleteComponent
                        id="form_text_columns"
                        name={'Attributes'}
                        variant="outlined"
                        fullWidth
                        placeholder={"Select Attributes"}
                        disableCloseOnSelect
                        disableClearable
                        data={reports_columns}
                        multiple
                        selectedValue={detail?.columns ?? []}
                        onChange={
                            (event, value) =>
                                onChange('columns', value)
                        }
                        limitTags={1}
                        loading={columnsLoading}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        disabled={!reportsPermission?.is_edit || columnsLoading}
                        select_all
                        draggable
                    />
                </Grid>
                {
                    reports_columns && reports_columns.length > 0 &&
                    <GroupBySelector
                        data={detail?.group_by ?? []}
                        columns={reports_columns || []}
                        onChange={(format, value) => onChange(format, value)}
                        reportsPermission={reportsPermission}
                    />
                }
                <Grid item xs={12} className={classes.usersBox}>
                    <Typography variant="body1" className="pb5">
                        Users
                    </Typography>
                    <TextBoxComponent
                        name={'users'}
                        sx={{ minHeight: '100px' }}
                        placeholder={"Enter email address"}
                        multiline
                        minRows="8"
                        value={detail?.users ?? []}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        validators={['required', 'validateEmails']}
                        errorMessages={['this field is required', 'The email format is not valid']}
                        disabled={!reportsPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" className="pb5">
                        Report Format
                    </Typography>
                    <SelectComponent
                        fullWidth={false}
                        variant="outlined"
                        size="small"
                        value={detail.format}
                        list={appConstants.dropdowns.reportFormat}
                        onSelectChange={(value) => onChange('format', value)}
                        disabled={!reportsPermission?.is_edit}
                    />
                </Grid>
                {
                    detail.format === 'PDF' &&

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={detail.properties?.fit_to_width || false}
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                    onChange={() => onChange("fit_to_width", !detail.properties?.fit_to_width, "properties")}
                                />
                            }
                            label={
                                <Typography variant="body1">
                                    {"Fit to Width"}
                                </Typography>
                            }
                        />
                    </Grid>

                }
                <Grid item xs={12}>
                    <Grid className="dflex alignCenter">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={detail.properties?.override || false}
                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                    onChange={() => onChange("override", !detail.properties?.override, "properties")}
                                />
                            }
                            label={
                                <Typography variant="body1">
                                    {"Override Limit"}
                                </Typography>
                            }
                        />
                        {
                            detail.properties?.override &&
                            <NumberInputComponent
                                name="row_count"
                                label={"Preview Row Limit"}
                                placeholder={"Preview Row Limit"}
                                variant="standard"
                                size="medium"
                                value={detail?.properties?.row_count || 100}
                                onChange={(value) => onChange("row_count", value, "properties")}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Properties.defaultProps = {
    classes: {},
    detail: {},
    reportsPermission: {},
    onChange: () => { }
};

// prop types
Properties.propTypes = {
    classes: PropTypes.object,
    detail: PropTypes.object,
    reportsPermission: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Properties);