const style = (theme) => ({
    toggleContainer: {
        '& .MuiButtonBase-root': {
            borderRadius: 0,
            textTransform: 'capitalize',
            padding: '2.2px 16px !important',
            border: 'none !important',
            background: theme.palette.greyshades.lightgrey,
            fontWeight: 400,
            fontSize: theme.palette.headers.body1.size,
            fontFamily: theme.palette.headers.body1.fontFamily,
            color: `${theme.palette.headers.body1.color} !important`,
            '&:hover': {
                background: `${theme.palette.greyshades.grey} !important`
            },
            '&.Mui-selected,.Mui-selected:hover': {
                background: `${theme.palette.secondary.main} !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                '&:hover': {
                    background: `${theme.palette.secondary.main} `,
                    color: `${theme.palette.primary.contrastText} !important`
                }
            }
        }
    }
});
export default style;