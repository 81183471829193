import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Dialog, Grid } from '@mui/material';

/**
 * This component allows user to crop selected image
 * @param {*} props
 * @returns
 */
function ImageCropper(props) {
    /**
     * Define Props
     */
    const { imageToCrop, onImageCropped, open, onClose, aspect, loadUncroppedImage } = props;
    const [croppedImage, setCroppedImage] = useState(null);
    const [imageRef, setImageRef] = useState(null);
    const [cropConfig, setCropConfig] = useState({
        unit: '%',
        x: 15,
        y: 15,
        width: 50,
        height: 50
    });


    useEffect(() => {
        if (!aspect && imageToCrop) {
            setCropConfig({
                unit: '%',
                x: 15,
                y: 15,
                width: 50,
                height: 50
            });
        }
    }, [imageToCrop, aspect]);

    /**
     * Data URL To Image
     */
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], filename, { type: mime });
        return croppedImage;
    };

    /**
     * This function creates cropped image from the source image
     * @param {*} sourceImage
     * @param {*} cropConfig
     * @param {*} fileName
     * @returns
     */
    function getCroppedImage(sourceImage, cropConfig, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width * pixelRatio;
        canvas.height = cropConfig.height * pixelRatio;
        const ctx = canvas.getContext('2d');

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            canvas.toBlob((blob) => {
                // returning an error
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }

                // creating a Object URL representing the Blob object given
                const croppedImageUrl = window.URL.createObjectURL(blob);

                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const cropImageFile = dataURLtoFile(reader.result, fileName);
                    resolve({ cropImageFile, croppedImageUrl });
                };
            }, 'image/jpg');
        });
    }

    /**
     * This functions gets cropped image and returns it to parent component
     * @param {*} crop
     */
    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const { cropImageFile, croppedImageUrl } = await getCroppedImage(
                imageRef,
                crop,
                'croppedImage.jpg'
            );

            setCroppedImage(cropImageFile);
            onImageCropped(cropImageFile, croppedImageUrl);
        } else if (loadUncroppedImage) {
            onImageCropped(null, null);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Grid container className="p-2" spacing={3}>
                <Grid item xs={12}>
                    <ReactCrop
                        src={imageToCrop}
                        crop={cropConfig}
                        ruleOfThirds
                        onImageLoaded={(imageRef) => setImageRef(imageRef)}
                        onChange={(cropConfig) => setCropConfig(cropConfig)}
                    />
                </Grid>
                <Grid item xs={5}>
                    <img alt="" src={croppedImage} />
                </Grid>
                <Grid item xs={12} align="right">
                    <Button onClick={onClose} size="small" variant="outlined" color="primary">
                        Close
                    </Button>
                    <Button onClick={() => { cropImage(cropConfig); onClose(); }} size="small" variant="contained" color="primary" className="ml-1" disableElevation>
                        Crop
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}

// default props
ImageCropper.defaultProps = {
    imageToCrop: '',
    onImageCropped: () => { },
    onClose: () => { },
    open: false,
    aspect: 1 / 1,
    freeTransform: false,
    width: 140,
    height: 0,
    loadUncroppedImage: false
};

// prop types
ImageCropper.propTypes = {
    imageToCrop: PropTypes.string,
    onImageCropped: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    aspect: PropTypes.number,
    loadUncroppedImage: PropTypes.bool
};

export default ImageCropper;