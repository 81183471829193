import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, FormControlLabel, Checkbox, TableSortLabel, Typography, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
//import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


const TableHeaderRowComponent = (props) => {

    /**
     * Define Props
     */
    const { headers, haveCheckBox, haveSubTable, defaultHeaderCheckBoxValue, parentDetail, sortBy, orderBy,
        onClickSorting, onClickCheckbox, disableActions, textTransform, rowDraggable } = props;

    /**
     * Handle Checkbox Event
     */
    const handleCheckBoxClick = (event, selectedItem) => {
        onClickCheckbox(event.target.checked, selectedItem, parentDetail);
    };

    const getHeaderLabel = (header) => {
        let headerLabel = header.name;
        if (header.headerTooltipText) {
            headerLabel = (
                <Tooltip title={header.headerTooltipText}>
                    <Typography component={"h6"} variant="h6">
                        {header.name}
                    </Typography>
                </Tooltip>
            );
        }
        return headerLabel;
    };

    return (
        <TableRow key="tableHeaderRow">
            {
                haveCheckBox &&
                <TableCell key={`headerRowCell-checkbox`} className={"tableChecbox"}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={defaultHeaderCheckBoxValue}
                                disabled={disableActions}
                                onChange={(event) => handleCheckBoxClick(event, null)}
                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                checkedIcon={<CheckBoxOutlinedIcon />} />
                        }
                    />
                </TableCell>
            }

            {
                rowDraggable &&
                <TableCell key={`headerRowCell-drag`} />

            }
            {
                headers && headers.map((header, index) => {
                    const sortingKey = header.sortKey ? header.sortKey : header.key;
                    return (
                        <TableCell key={`headerRowCell-${index}`} sx={{ textAlign: header.align || 'left' }} className={`${!textTransform ? 'capitalizeUnset' : ''}${header.sticky ? ' stickyColumn' : ''}`} style={{ minWidth: header.applyHeaderStyle && header.minWidth ? header.minWidth : null, width: header.applyHeaderStyle || header.width ? header.width : null }}>
                            <Typography variant="h6">
                                {
                                    header.sorting ?
                                        <TableSortLabel
                                            key={`headerRowLabel-${index}`}
                                            active={sortBy === sortingKey}
                                            direction={orderBy}
                                            onClick={() => onClickSorting(sortingKey, orderBy === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {getHeaderLabel(header)}
                                        </TableSortLabel>
                                        : getHeaderLabel(header)
                                }
                            </Typography>
                        </TableCell>
                    );
                })
            }
            {haveSubTable && <TableCell key={`headersub-checkbox`} />}
        </TableRow>
    );
};

/**
 * Set Component Props
 */
TableHeaderRowComponent.propTypes = {
    headers: PropTypes.array,
    haveCheckBox: PropTypes.bool,
    haveSubTable: PropTypes.bool,
    defaultHeaderCheckBoxValue: PropTypes.bool,
    sortBy: PropTypes.string,
    orderBy: PropTypes.string,
    parentDetail: PropTypes.string,
    onClickSorting: PropTypes.func,
    onClickCheckbox: PropTypes.func,
    disableActions: PropTypes.bool,
    textTransform: PropTypes.bool,
    rowDraggable: PropTypes.bool
};


/**
 * Set Default Values
 */
TableHeaderRowComponent.defaultProps = {
    headers: [],
    haveCheckBox: true,
    haveSubTable: false,
    defaultHeaderCheckBoxValue: false,
    sortBy: "",
    orderBy: "",
    parentDetail: "",
    onClickSorting: () => { },
    onClickCheckbox: () => { },
    disableActions: false,
    textTransform: true,
    rowDraggable: false
};

export default TableHeaderRowComponent;