import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import _ from 'lodash';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

function ToggleComponent(props) {

    /**
     * Define Props
     */
    const { classes, data, selectedValue, disbleToggleValue, handleChange, isDisabled, disableOnSelectedValue } = props;

    return (
        <Grid className={`${classes.toggleContainer} toggleContainer`}>
            {
                data.length &&
                <ToggleButtonGroup
                    color="primary"
                    value={selectedValue || ''}
                    exclusive
                    onChange={(event, value) => handleChange(value)}
                >
                    {
                        data.map((item, index) => (
                            <ToggleButton key={`toggle-btn-${index}-${item.value}`} value={item.value} disabled={disbleToggleValue === item.value || isDisabled || (disableOnSelectedValue && item.value === selectedValue)}>
                                {item.name}
                            </ToggleButton>
                        ))
                    }
                </ToggleButtonGroup>
            }
        </Grid>
    );
}

// default props
ToggleComponent.defaultProps = {
    classes: {},
    data: [],
    disabled: false,
    selectedValue: "",
    disbleToggleValue: "",
    handleChange: () => { },
    isDisabled: false,
    disableOnSelectedValue: true
};

// prop types
ToggleComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    selectedValue: PropTypes.string,
    disbleToggleValue: PropTypes.string,
    disableOnSelectedValue: PropTypes.bool,
    handleChange: PropTypes.func,
    isDisabled: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.selectedValue, nextProps.selectedValue) && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export Default Component
 */
export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ToggleComponent);

/**
 * Export Component With Memo
 */
export const MemoToggleComponent = withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(ToggleComponent, areEqual));