const style = (theme) => ({
    editContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '30px 30px 0px',
        height: 'calc(100vh - 90px)',
        overflowY: 'auto',
        '& .ruleInlineTextfield': {
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #ffffff00'
            },
            '& .MuiFormControl-root': {
                minWidth: 400
            },
            '& .textSecondary .MuiInput-input': {
                color: theme.palette.greyshades.darkgrey,
                marginTop: 5
            },
            '& .MuiInput-input': {
                fontSize: '15px !important',
                boxSizing: 'border-box'
            }
        }
    },
    header: {
        paddingTop: 20
    },
    ruleGreyContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: 24,
        marginTop: '15px !important',
        minHeight: '144px'
    }
});
export default style;