const style = (theme) => ({
    domainCardContainer: {
        marginTop: '6px !important',
        paddingBottom: 10
    },
    domainCard: {
        boxShadow: '0px 2px 8px #0000000f',
        borderRadius: '6px',
        height: '100%',
        '& .descriptionText': {
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .detail .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .DeleteIcon': {
            width: 20,
            height: 19
        },
        '& .cameraIcon': {
            visibility: 'hidden',
            opacity: 0
        },
        '&:hover': {
            '& .cameraIcon': {
                visibility: 'visible',
                transition: 'all 0.5s',
                opacity: 1
            }
        }
    },
    imgContainer: {
        height: 160,
        display: 'flex',
        alignItems: 'center',
        background: "#e9eef1",
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
        '&.defaultImg img': {
            height: '140px',
            objectFit: 'contain !important'
        },
        '&.uploadedImg img': {
            height: '160px !important'
        },
        '& img': {
            height: '145px',
            objectFit: 'fill',
            width: '100%',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px'
        }
    },
    detail: {
        padding: '16px 20px 18px !important',
        '& .CameraIcon:hover path': {
            fill: `${theme.palette.secondary.main} !important`
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    domainImgInput: {
        display: 'none'
    },
    title: {
        width: 'calc(100% - 45px)',
        '& .MuiInput-input': {
            fontFamily: `${theme.palette.titleFont} !important`,
            fontSize: `${theme.palette.headers.h5.size} !important`,
            fontWeight: 500
        }
    },
    description: {
        '& .MuiInput-input': {
            fontFamily: theme.palette.textFont,
            fontSize: '15px !important',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            color: `${theme.palette.greyshades.darkgrey} !important`
        }
    }
});
export default style;