import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Components
import { ToggleComponent } from '../../../index.js';

//  Import Styles
import Style from "./style.jsx";

// Constants
import appConstants from '../../../../constants/appConstants.js';

function RuleConnectors(props) {

    /**
     * Define Props
     */
    const { classes, selectedCondition, isNotEnable, enableOrCondition, id, editRule, propertyPermission, isDisabled } = props;

    /**
     * Check if Or Condition Enable
     */
    const disbleToggleValue = enableOrCondition ? '' : appConstants.toggle.ruleSelector[1].value;

    return (
        <Grid className={`${classes.ruleSelectorContainer} dflex`}>
            <ToggleComponent
                data={appConstants.toggle.ruleSelectorNot}
                selectedValue={isNotEnable === true ? appConstants.toggle.ruleSelectorNot[0].value : ''}
                handleChange={() => editRule('not', !isNotEnable, id)}
                disableOnSelectedValue={false}
                isDisabled={!propertyPermission?.is_edit || isDisabled} />
            <ToggleComponent
                data={appConstants.toggle.ruleSelector}
                selectedValue={selectedCondition}
                disbleToggleValue={disbleToggleValue}
                handleChange={(value) => editRule('connector', value, id)}
                isDisabled={!propertyPermission?.is_edit || isDisabled} />
        </Grid>
    );
}

// default props
RuleConnectors.defaultProps = {
    classes: {},
    selectedCondition: appConstants.toggle.ruleSelector[0].value,
    enableOrCondition: false,
    isNotEnable: false,
    isDisabled: false,
    id: '',
    editRule: () => { },
    propertyPermission: {}
};

// prop types
RuleConnectors.propTypes = {
    classes: PropTypes.object,
    selectedCondition: PropTypes.string,
    isNotEnable: PropTypes.bool,
    enableOrCondition: PropTypes.bool,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    editRule: PropTypes.func,
    propertyPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme)
    }),
    { withTheme: true }
)(RuleConnectors);