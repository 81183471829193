// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

// Import Component
import { AutoCompleteComponent } from '../../../index';

//Import Styles
import style from './style.jsx';


const TableSubComponent = (props) => {
    /**
     * Define Props
     */
    const { headers, subComponentList, subComponentOnChange, classes } = props;

    const renderElement = (header) => {
        switch (header.subComponentType) {
            case 'autocomplete':
                const options = subComponentList?.options ?? [];
                return (<AutoCompleteComponent
                    selectedValue={header[header.subComponentKey] || ""}
                    options={options || []}
                    onChange={(event, newValue) => subComponentOnChange(header, newValue)}
                />);
            default:
                break;
        }
    };

    return (
        <TableRow className={classes.searchHeader}>
            {
                headers.map((header, index) =>
                    <TableCell key={`search_cell_${index}`}>
                        {
                            header.subComponent &&
                            <Grid>
                                {renderElement(header)}
                            </Grid>
                        }
                    </TableCell>
                )
            }
        </TableRow>
    );
};

/**
 * Define Component Props
 */
TableSubComponent.propTypes = {
    headers: PropTypes.array,
    subComponentList: PropTypes.object,
    subComponentOnChange: PropTypes.func,
    classes: PropTypes.object
};

/**
 * Set Default Values
 */
TableSubComponent.defaultProps = {
    headers: [],
    subComponentList: {},
    subComponentOnChange: () => { }
};

export default withStyles((theme) => ({
    ...style(theme)
}))(TableSubComponent);