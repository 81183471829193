import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Components
import ProfileDetail from './profileDetail/index.jsx';
import { ChangePasswordPopover } from '../../../components/index.js';


//  Import Styles
import EditProfileStyle from "../style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images

function EditProfile(props) {
    const { classes } = props;

    /**
     * Define State
     */
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState({
        open: false,
        anchorEl: null,
        data: null
    });

    /**
     * Open change password dialog
     */
    const showChangePasswordDialog = (event, user) => {
        setOpenChangePasswordDialog({
            open: true,
            anchorEl: event.target,
            data: user
        });
    };

    /**
     * Close change password dialog
     */
    const closeChangePasswordDialog = () => {
        setOpenChangePasswordDialog({
            open: false,
            anchorEl: null,
            data: null
        });
    };

    return (
        <Grid container className={classes.editProfilePageContainer}>
            <ProfileDetail onShowChangePassword={(event, user) => showChangePasswordDialog(event, user)} />
            {
                openChangePasswordDialog.anchorEl &&
                <ChangePasswordPopover {...openChangePasswordDialog} onClose={() => closeChangePasswordDialog()} />
            }
        </Grid>
    );
}

// default props
EditProfile.defaultProps = {
    classes: {}
};

// prop types
EditProfile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...EditProfileStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(EditProfile);