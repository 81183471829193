import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import DropBox from '../../../../DropBox/index.jsx';
import SelectedFields from '../../../../SelectedFields/index.jsx';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../../../../layouts/style.jsx';


function SunBurst(props) {
    const { classes, data, onChange } = props;

    /**
     * Get Detail
     * @param {*} item
     * @param {*} type
     * @returns
     */
    const getDetail = (item) => {
        item.query = item.column;
        item.alias_name = item.name;
        item.type = item.datatype === "text" || item.datatype === "date" ? "dimension" : "measure";
        return item;
    };

    /**
     * OnDrop
     * @param {*} item
     */
    const onDrop = (item, index = null) => {
        item = { ...getDetail(item) };
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        if (index !== null) {
            levels[index] = { ...item };
        } else {
            levels.push({ ...item });
        }
        properties.levels = levels;
        onChange(properties);
    };

    /**
     * On Delete Item
     * @param {*} dropType
     * @param {*} index
     */
    const onDeleteItem = (index) => {
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        levels.splice(index, 1);
        properties.levels = levels;
        onChange(properties);
    };


    const getLevels = (levels) => {
        return {
            level1: levels[0] ? [levels[0]] : [],
            level2: levels[1] ? [levels[1]] : [],
            level3: levels[2] ? [levels[2]] : []
        };
    };

    const levels = useMemo(() => getLevels(data?.levels), [data?.levels]);

    return (
        <Grid className={classes.nonLimitDragContainer}>
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    Level 1
                </Typography>
            </Grid>
            <SelectedFields data={levels.level1 || []}
                onDelete={() => onDeleteItem(0)}
                accept={["dimension"]}
                onDrop={(item) => onDrop(item, 0)} />
            {
                !levels.level1.length &&
                <DropBox accept={["dimension"]} onDrop={(item) => onDrop(item)} />
            }
            {
                levels.level1.length > 0 &&
                <Fragment>
                    <Grid item className={classes.dargField}>
                        <Typography variant="h6" className="pb5">
                            Level 2
                        </Typography>
                    </Grid>
                    <SelectedFields data={levels.level2 || []}
                        onDelete={() => onDeleteItem(1)}
                        accept={["dimension"]}
                        onDrop={(item) => onDrop(item, 1)} />
                    {
                        !levels.level2.length &&
                        <DropBox accept={["dimension"]} onDrop={(item) => onDrop(item)} />
                    }
                </Fragment>
            }
            {
                levels.level2.length > 0 &&
                <Fragment>
                    <Grid item className={classes.dargField}>
                        <Typography variant="h6" className="pb5">
                            Level 3
                        </Typography>
                    </Grid>
                    <SelectedFields data={levels.level3 || []}
                        onDelete={() => onDeleteItem(2)}
                        accept={["dimension"]}
                        onDrop={(item) => onDrop(item, 2)} />
                    {
                        !levels.level3.length &&
                        <DropBox accept={["dimension"]} onDrop={(item) => onDrop(item)} />
                    }
                </Fragment>
            }

        </Grid>
    );
}

// default props
SunBurst.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { }
};

// prop types
SunBurst.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SunBurst);