import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { SearchComponent } from '../../../../../components';
import { ClearFilter } from '../../../../../assets/svg';

function SearchableFilterItems(props) {

    /**
     * Define Props
     */
    const { classes, type, header, default_open, data, options, field_key, handleCheckBoxClick, checkboxList, customAccordionSelected,
        handleCustomChange, children, onClear } = props;

    /**
     * Define State
     */
    const [expanded, setExpanded] = useState(default_open ? header : null);
    const [search, setSearch] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);

    /**
     * Handle Accordion
     * @param {*} panel
     */
    const handleChange = (panel) => {
        panel = expanded !== panel ? panel : null;
        setExpanded(panel);
    };

    const sortSelected = (arr1, arr2) => {
        // Create a custom comparison function
        function customCompare(a, b) {
            // Get the indices of items a and b in arr2, default to a large number if not found
            const indexA = arr2.indexOf(a.id);
            const indexB = arr2.indexOf(b.id);
            return indexB - indexA || a.id.localeCompare(b.id);
        }

        // Sort arr1 using the custom comparison function
        arr1.sort(customCompare);

        // Now arr1 is rearranged based on arr2 with items from arr2 at the top
        return arr1;
    };

    useEffect(() => {
        // Filter the options array based on the 'name' property and limit to a maximum of 10 results
        const sortedOptions = [...options];
        const newOptions = sortSelected(sortedOptions, data);
        const filteredOptions = newOptions
            .filter((option) =>
                option.name.toLowerCase().includes(search.toLowerCase())
            )
            .slice(0, 10);

        setSearchOptions(filteredOptions);
    }, [search, options, data]);


    return (
        <Grid className={`${classes.sideBarItem} sideBarItem`}>
            {
                default_open &&
                <Accordion expanded={expanded === header} onChange={() => handleChange(header)}>
                    <AccordionSummary
                        expandIcon={expanded === header ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="title"
                    >
                        <Typography variant="h5" className="fw-500">
                            {header}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item className={`sidebarItemBody ${classes.sidebarItemBody}`}>
                            {children}
                            {
                                checkboxList ?
                                    options.map((option) => (
                                        <FormControlLabel
                                            key={option[field_key]}
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                                    checked={_.findIndex(data, (item) => item === option[field_key]) >= 0}
                                                    onChange={() => handleCheckBoxClick(option, type, field_key)}
                                                />
                                            }
                                            label={
                                                <Typography variant="body1">
                                                    <Tooltip arrow title={option.name}>
                                                        <span>
                                                            {option.name}
                                                        </span>
                                                    </Tooltip>
                                                </Typography>
                                            }
                                        />
                                    ))
                                    :
                                    null
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                !default_open &&
                <Accordion expanded={customAccordionSelected === header} onChange={() => handleCustomChange(header)}>
                    <AccordionSummary
                        expandIcon={customAccordionSelected === header ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="title"
                    >
                        <Typography variant="h5" className="fw-500">
                            {header}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item className={`sidebarItemBody ${classes.sidebarItemBody}`}>
                            <Grid container alignItems="center">
                                <Grid item xs={11}>
                                    <SearchComponent
                                        placeholder={"Search"}
                                        value={search}
                                        onChange={(value) => setSearch(value)} />
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        data.length > 0 &&
                                        <IconButton className={classes.pattenDelete} onClick={() => onClear(type)}>
                                            <ClearFilter />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                            {children}
                            {
                                checkboxList ?
                                    searchOptions.map((option) => (
                                        <FormControlLabel
                                            key={option[field_key]}
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                                    checked={_.findIndex(data, (item) => item === option[field_key]) >= 0}
                                                    onChange={() => handleCheckBoxClick(option, type, field_key)}
                                                />
                                            }
                                            label={
                                                <Grid className="dflex alignCenter">
                                                    <Typography variant="body1" noWrap className="checkBoxText">
                                                        <Tooltip arrow title={option.name}>
                                                            <span>
                                                                {option.name}
                                                            </span>
                                                        </Tooltip>
                                                    </Typography>
                                                    {
                                                        option.count &&
                                                        <Typography variant="body1" sx={{ textOverflow: 'unset !important' }}>
                                                            {`(${option.count})`}
                                                        </Typography>
                                                    }
                                                </Grid>
                                            }
                                        />
                                    ))
                                    :
                                    null
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </Grid>
    );
}

// default props
SearchableFilterItems.defaultProps = {
    classes: {},
    type: '',
    header: '',
    default_open: false,
    options: [],
    data: [],
    field_key: 'id',
    handleCheckBoxClick: () => { },
    checkboxList: false,
    customAccordionSelected: "",
    handleCustomChange: () => { },
    onClear: () => { }
};

// prop types
SearchableFilterItems.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    type: PropTypes.string,
    default_open: PropTypes.bool,
    header: PropTypes.string,
    options: PropTypes.array,
    data: PropTypes.array,
    field_key: PropTypes.string,
    handleCheckBoxClick: PropTypes.func,
    checkboxList: PropTypes.bool,
    customAccordionSelected: PropTypes.string,
    handleCustomChange: PropTypes.func,
    onClear: PropTypes.func
};

export default SearchableFilterItems;