import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

// Import Styles
import style from './style.jsx';

// Import Components
import { IconButton } from '@mui/material';

// Import Images
import { ColorDropIcon } from '../../assets/svg/index.js';


function ColorPicker(props) {

    /**
     * Define Props
     */
    const { classes, value, disabled, onChange } = props;

    /**
     * Define State
     */
    const [color, setColor] = useState(value);

    /**
     * OnChange Color
     * @param {*} value
     */
    const onChangeColor = (value) => {
        setColor(value);
    };

    /**
     * OnBlur
     * @description Pass Callback to Parent Component
     */
    const onBlur = () => {
        if (value !== color) {
            onChange(color);
        }
    };

    /**
     * Set Default value state
     */
    useEffect(() => {
        setColor(value || '#000');
    }, [value]);

    return (
        <IconButton disableRipple className={classes.colorPicker} onMouseLeave={() => onBlur()}>
            <ColorDropIcon dropColor={color} />
            {
                !disabled &&
                <input className="typeColor" type="color" value={color || "#000"} onChange={(event) => onChangeColor(event?.target?.value)} />
            }
        </IconButton>
    );
}

/**
 * Define Component Props
 */
ColorPicker.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

/**
 * Define Default Values
 */
ColorPicker.defaultProps = {
    classes: {},
    value: "#000",
    onChange: () => { },
    disabled: false
};

export default withStyles(style)(ColorPicker);