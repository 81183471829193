import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

//  Import Styles
import ChartStyle from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { CountChartComponent } from '../../../../../../components/index.js';

function MeasureChart(props) {

    const { classes, data, ruleCategory, name } = props;

    return (
        <Grid className={classes.measureChart}>
            <Typography variant="h5" className="capitalize">
                Last 7 days
            </Typography>
            <Grid className={classes.chartContainer}>
                <CountChartComponent
                    data={data || []}
                    ruleName={name}
                    ruleCategory={ruleCategory}
                />
            </Grid>
        </Grid>
    );
}

// default props
MeasureChart.defaultProps = {
    classes: {},
    data: [],
    name: ''
};

// prop types
MeasureChart.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    name: PropTypes.string,
    ruleCategory: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...ChartStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(MeasureChart);