const style = (theme) => ({
    widgetItem: {
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        paddingBottom: 10,
        marginBottom: '15px !important',
        alignItems: 'flex-start !important',
        '& .MuiChip-label': {
            textTransform: 'capitalize'
        }
    },
    widgetListIcon: {
        background: `${theme.palette.secondary.main}10`,
        width: '40px',
        height: '40px !important',
        flexShrink: 0,
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px !important',
        '& svg': {
            width: '22px',
            height: '22px',
            fill: `${theme.palette.secondary.main}`
        }
    },
    linkBtnText: {
        '& .MuiButton-root': {
            color: `${theme.palette.secondary.main} !important`,
            marginRight: 6
        }
    },
    latesIssuesContainer: {
        height: 'calc(100% - 15px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingRight: 8
    }
});

export default style;