import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Import Components
import CommentsTimeLine from "./timeline.jsx";

//  Import Styles
import LayoutStyles from '../../../layouts/style.jsx';
import Style from './style.jsx';

function CommentsList(props) {

    const { classes,
        data,
        handlePinMessage,
        handleDeleteMessage,
        handleCreateMessage,
        attributes,
        users_list,
        permission,
        formFields,
        actions,
        singleLine
    } = props;
    return (
        <Grid container className={classes.conversationList}>
            {
                data.map((item, index) =>
                    <CommentsTimeLine
                        key={index}
                        data={item}
                        attributes={attributes}
                        handlePinMessage={handlePinMessage}
                        handleDeleteMessage={handleDeleteMessage}
                        handleCreateMessage={handleCreateMessage}
                        users_list={users_list}
                        permission={permission}
                        formFields={formFields}
                        actions={actions}
                        singleLine={singleLine}
                    />
                )
            }
        </Grid>
    );
}

// default props
CommentsList.defaultProps = {
    classes: {},
    data: [],
    attributes: [],
    handlePinMessage: () => { },
    handleDeleteMessage: () => { },
    handleCreateMessage: () => { },
    users_list: [],
    permission: {},
    formFields: {},
    actions: [],
    singleLine: false
};

// prop types
CommentsList.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    attributes: PropTypes.array,
    handlePinMessage: PropTypes.func,
    handleDeleteMessage: PropTypes.func,
    handleCreateMessage: PropTypes.func,
    users_list: PropTypes.array,
    permission: PropTypes.object,
    formFields: PropTypes.object,
    actions: PropTypes.array,
    singleLine: PropTypes.bool
};


export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme),
        ...Style(theme)
    }),
    { withTheme: true }
)((CommentsList));