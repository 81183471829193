const style = (theme) => ({
    issueNumber: {
        background: '#fff',
        fontWeight: 600,
        // padding: "14px 8px",
        fontSize: `${theme.palette.headers.body1.size} !important`,
        marginRight: 24,
        color: theme.palette.primary.main,
        width: '80px',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerContainerInner: {
        padding: '30px !important',
        paddingBottom: '5px !important',
        borderBottom: `1px solid ${theme.palette.greyshades.lightgrey}`,
        boxShadow: '0px -1px 5px #0000001c',
        background: '#F1F4F7',
        position: 'relative',
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                },
                '& .fillStroke': {
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .EyeIcon': {
            '& .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    headerContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 9,
        paddingTop: '24px',
        background: theme.palette.greyshades.lightbackground,
        '& .CloseIcon': {
            width: '25px',
            height: '25px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        '& input': {
            fontFamily: `${theme.palette.headers.h1.fontFamily} !important`,
            fontSize: `${theme.palette.headers.h3.size}px !important`,
            fontWeight: 500
        },
        '& .ListUser': {
            minWidth: 'auto !important'
        },
        '& .avatarList .mb-1': {
            marginBottom: '0px !important'
        },
        '& .EyeIcon2, .CloseIcon': {
            '&:hover ': {
                fill: theme.palette.secondary.main
            }
        },
        '& .EyeIcon': {
            '&:hover path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .ShareIcon': {
            '&:hover #Path_2797': {
                fill: theme.palette.secondary.main
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .textBoxContainer ': {
            width: '70%'
        }
    },
    datasetName: {
        display: 'flex',
        alignItems: 'center',
        '& svg, svg path': {
            width: 17,
            fill: theme.palette.secondary.main,
            flexShrink: '0'
        },
        '& .catalog-cardTitle': {
            cursor: 'pointer'
        }
    },
    closeIssues: {
        position: 'absolute !important',
        right: '-27px',
        top: '-27px',
        background: `${theme.palette.greyshades.darkgrey} !important`,
        borderRadius: '4px !important',
        padding: '3px !important',
        '& svg': {
            fill: '#fff !important'
        }
    }
});
export default style;