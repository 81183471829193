import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Import Images
import TableIcon from '../../assets/img/widget/Tables.png';
import ViewIcon from '../../assets/img/widget/Views.png';
import QueryIcon from '../../assets/img/widget/Query.png';
import ReportsIcon from '../../assets/img/widget/Reports.png';
import AssetTypeIcon from '../../assets/img/widget/Assets Type.png';
import TotalAttributeTerm from '../../assets/img/widget/Total Attributes by Terms.png';
import TotalAttributeTag from '../../assets/img/widget/Total Attributes by Tags.png';
import Domain from '../../assets/img/widget/Domain.png';
import DomainAssetType from '../../assets/img/widget/Domain by Asset Type.png';
import ApplicationAssetType from '../../assets/img/widget/Assets by Application by asset type.png';
import Topics from '../../assets/img/widget/Topics.png';
import LastestTopic from '../../assets/img/widget/Most latest Topic.png';
import TopUsers from '../../assets/img/widget/Top 5 Users.png';
import LastestComment from '../../assets/img/widget/Lastest Comments.png';
import MostSearch from '../../assets/img/widget/Most Searchs.png';
import MostRatedAssets from '../../assets/img/widget/Most Rated Assets.png';
import Distribution from '../../assets/img/widget/Distribution.png';
import OverallQuality from '../../assets/img/widget/Overall Qulaity.png';
import Score from '../../assets/img/widget/Score.png';
import OrganizationScore from '../../assets/img/widget/Organizational Score.png';
import Dimensions from '../../assets/img/widget/Dimensions.png';
import Organization from '../../assets/img/widget/Organization.png';
import DQScore from '../../assets/img/widget/DQScore.png';
import Issues from '../../assets/img/widget/Issues.png';
import Alerts from '../../assets/img/widget/Alerts.png';
import HealthAlertsbyType from '../../assets/img/widget/Health Alerts by type.png';
import Historical from '../../assets/img/widget/Historical.png';
import LastAlerts from '../../assets/img/widget/Last 5 Alerts.png';
import LastIssues from '../../assets/img/widget/Last 5 Issues.png';
import AlertsCategory from '../../assets/img/widget/Alerts by Category.png';
import IssueCategory from '../../assets/img/widget/Issues by Status Over Priority.png';
import TopHealthAlerts from '../../assets/img/widget/Top 10 Alerts with Health Assets.png';
import TopAssetIssue from '../../assets/img/widget/Top 10 Asstes with Issues.png';
import Domains from '../../assets/img/widget/Domains.png';
import Tags from '../../assets/img/widget/Tags.png';
import Terms from '../../assets/img/widget/Terms.png';
import Application from '../../assets/img/widget/Application.png';
import Bar from '../../assets/img/widget/Bar.png';
import Area from '../../assets/img/widget/area.png';
import Polar from '../../assets/img/widget/Polar.png';
import Line from '../../assets/img/widget/Line.png';
import Table from '../../assets/img/widget/Table.png';
import Hierarchy from '../../assets/img/widget/Org.png';
import CustomDashboard from '../../assets/img/dashboard/New_Dashboard.png';


function WidgetIcon(props) {

    /**
     * Define Props
     */
    const { widgetType } = props;

    /**
     * Connectors Data
     */
    const widgetIcons = [
        {
            icon: <img src={TableIcon} alt="widget" />,
            type: "Tables"
        },
        {
            icon: <img src={ViewIcon} alt="widget" />,
            type: "Views"
        },
        {
            icon: <img src={QueryIcon} alt="widget" />,
            type: "Query"
        },
        {
            icon: <img src={ReportsIcon} alt="widget" />,
            type: 'Reports'
        },
        {
            icon: <img src={AssetTypeIcon} alt="widget" />,
            type: 'Asset Type'
        },
        {
            icon: <img src={TotalAttributeTerm} alt="widget" />,
            type: 'Total Attributes by Terms'
        },
        {
            icon: <img src={TotalAttributeTag} alt="widget" />,
            type: "Total Attributes by Tags"
        },
        {
            icon: <img src={Domain} alt="widget" />,
            type: "Domain"
        },
        {
            icon: <img src={DomainAssetType} alt="widget" />,
            type: "Domains by Asset Type"
        },
        {
            icon: <img src={ApplicationAssetType} alt="widget" />,
            type: "Assets by Application over asset type"
        },
        {
            icon: <img src={Topics} alt="widget" />,
            type: "Topics"
        },
        {
            icon: <img src={LastestTopic} alt="widget" />,
            type: "Most Latest Topics"
        },
        {
            icon: <img src={TopUsers} alt="widget" />,
            type: "Top 5 Users"
        },
        {
            icon: <img src={LastestComment} alt="widget" />,
            type: "Latest Comments"
        },
        {
            icon: <img src={MostSearch} alt="widget" />,
            type: "Most Searches"
        },
        {
            icon: <img src={MostRatedAssets} alt="widget" />,
            type: "Most Rated Assets"
        },
        {
            icon: <img src={Distribution} alt="widget" />,
            type: "Distribution"
        },
        {
            icon: <img src={OverallQuality} alt="widget" />,
            type: "Overall Quality"
        }, {
            icon: <img src={Score} alt="widget" />,
            type: "Score"
        },
        {
            icon: <img src={OrganizationScore} alt="widget" />,
            type: "Organization Score"
        },
        {
            icon: <img src={Dimensions} alt="widget" />,
            type: "Dimensions"
        },
        {
            icon: <img src={Organization} alt="widget" />,
            type: "Organization"
        },
        {
            icon: <img src={DQScore} alt="widget" />,
            type: "DQ Score"
        },
        {
            icon: <img src={Issues} alt="widget" />,
            type: "Issues"
        },
        {
            icon: <img src={Alerts} alt="widget" />,
            type: "Alerts"
        },
        {
            icon: <img src={HealthAlertsbyType} alt="widget" />,
            type: "Health Alerts by Type"
        },
        {
            icon: <img src={Historical} alt="widget" />,
            type: "Historical"
        }, {
            icon: <img src={LastAlerts} alt="widget" />,
            type: "Last 5 Alerts"
        }, {
            icon: <img src={LastIssues} alt="widget" />,
            type: "Last 5 Issues"
        }, {
            icon: <img src={AlertsCategory} alt="widget" />,
            type: "Alerts by category over status"
        }, {
            icon: <img src={IssueCategory} alt="widget" />,
            type: "Issue by Status over priority"
        }, {
            icon: <img src={TopHealthAlerts} alt="widget" />,
            type: "Top 10 Assets with Health Alerts"
        }, {
            icon: <img src={TopAssetIssue} alt="widget" />,
            type: "Top 10 Assets with Issues  by Status"
        }, {
            icon: <img src={Domains} alt="widget" />,
            type: "Domains"
        }, {
            icon: <img src={Tags} alt="widget" />,
            type: "Tags"
        }, {
            icon: <img src={Terms} alt="widget" />,
            type: "Term"
        }, {
            icon: <img src={Application} alt="widget" />,
            type: "Application"
        }, {
            icon: <img src={Line} alt="widget" />,
            type: "Line"
        },
        {
            icon: <img src={Area} alt="widget" />,
            type: "Area"
        },
        {
            icon: <img src={Polar} alt="widget" />,
            type: "Polar"
        },
        {
            icon: <img src={Bar} alt="widget" />,
            type: "Vertical Bar"
        },
        {
            icon: <img src={Bar} alt="widget" />,
            type: "Horizontal Bar"
        },
        {
            icon: <img src={Table} alt="widget" />,
            type: "Table"
        }, {
            icon: <img src={Hierarchy} alt="widget" />,
            type: "hierachy"
        }
    ];


    /**
     * Get Icon
     * @returns
     */
    const bindIcon = () => {
        const data = widgetIcons.find((data) => data.type.toLowerCase() === widgetType.toLowerCase());
        if (data) { return data.icon; }
        return <img src={CustomDashboard} alt="custom" />;
    };


    return (
        bindIcon()
    );
}

/**
 * Define Component Props
 */
WidgetIcon.propTypes = {
    widgetType: PropTypes.string
};

/**
 * Define Default Values
 */
WidgetIcon.defaultProps = {
    widgetType: ''
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.widgetType, nextProps.widgetType);
}

export default (React.memo(WidgetIcon, areEqual));