import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid } from '@mui/material';

//  Import Components
import RolesListHeader from './components/header.jsx';
import AccordionHeader from './components/accordionHeader.jsx';
import AccordionBody from './components/accordionBody.jsx';
import { useSelector } from 'react-redux';
import { checkPermission } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';

function RolesList(props) {

    /**
     * Define Props
     */
    const { classes, data, roles, permission, handleCheckBox, handleAddRule, handleUpdateUser, handleDeleteUser } = props;
    const [expanded, setExpanded] = React.useState('Settings');
    const { permission: permissionAccess } = useSelector((state) => state.auth);
    const securityPermission = checkPermission(permissionAccess, featureConstants.settings.security);

    /**
     * Handle Accordion Change
     * @param {*} panel
     * @returns
     */
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : true);
    };

    /**
     * Bind Sub Features List
     * @param {*} data
     * @returns
     */
    const bindSubFeatures = (sub_features, parentIndex) => {
        /*
         * const checkViewStatus = (current_data, current_role, status, newPermission) => {
         *     const viewFeature = sub_features.filter((sub) => sub.sub_feature === `View ${current_data.name}`);
         *     handleCheckBox(current_data, current_role, status, newPermission, viewFeature.length ? viewFeature[0].id : null);
         * };
         */
        const featuresDom = sub_features.map((feature, index) => {
            const active_roles = roles.map((role) => {
                const tRoles = { ...role };
                if (permission[tRoles.id]) {
                    const aPermission = permission[tRoles.id].filter((i) => i.feature === feature.id);
                    if (aPermission.length > 0 && (aPermission[0].is_delete || aPermission[0].is_view || aPermission[0].is_edit || aPermission[0].is_none)) {
                        tRoles.role_permission_id = aPermission[0].id ? aPermission[0].id : null;
                    }
                    if (aPermission.length > 0 && aPermission[0].is_delete) {
                        tRoles.is_delete = true;
                    }
                    if (aPermission.length > 0 && aPermission[0].is_view) {
                        tRoles.is_view = true;
                    }
                    if (aPermission.length > 0 && aPermission[0].is_edit) {
                        tRoles.is_edit = true;
                    }
                    if (aPermission.length > 0 && aPermission[0].is_none) {
                        tRoles.is_none = true;
                    }
                }
                return tRoles;
            });
            feature.roles = active_roles;
            return (
                <AccordionBody key={`sub-features-list-${parentIndex}-${index}`} classes={classes} data={feature} handleCheckBox={handleCheckBox} securityPermission={securityPermission} permission={permission} />
            );
        });
        return featuresDom;
    };

    /**
     * Bind Features List
     * @returns
     */
    const bindFeatures = () => {
        const featuresDom = Object.keys(data).map((feature, index) => {
            return (
                <Accordion
                    defaultExpanded
                    key={`feature-list-${index}`}
                    expanded={expanded === feature}
                    onChange={handleChange(feature)}
                >
                    <AccordionHeader classes={classes} data={{ name: feature, index: index, id: data[feature].id }} />
                    {bindSubFeatures(data[feature], index)}
                </Accordion>
            );
        });
        return featuresDom;
    };

    return (
        <Grid className={classes.rolesListContainer}>
            <RolesListHeader classes={classes} roles={roles} handleAddRule={handleAddRule} handleUpdateUser={handleUpdateUser} handleDeleteUser={handleDeleteUser} securityPermission={securityPermission} />
            {bindFeatures()}
        </Grid>
    );
}

// default props
RolesList.defaultProps = {
    classes: {},
    data: {},
    roles: [],
    permission: {},
    handleCheckBox: () => { },
    handleAddRule: () => { },
    handleUpdateUser: () => { },
    handleDeleteUser: () => { }
};

// prop types
RolesList.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    roles: PropTypes.array,
    permission: PropTypes.object,
    handleCheckBox: PropTypes.func,
    handleAddRule: PropTypes.func,
    handleUpdateUser: PropTypes.func,
    handleDeleteUser: PropTypes.func
};

export default RolesList;