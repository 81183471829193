import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { Button, Grid, Menu, Typography } from '@mui/material';

//  Import Components
import { MemoTextBoxComponent } from '../textBox/index.js';

//  Import Styles
import PatternAddStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helpers
import { ValidatorForm } from 'react-material-ui-form-validator';
import appConstants from '../../constants/appConstants.js';


function AddEnum(props) {

    /**
     * Define Props
     */

    const { classes, open, anchorEl, selectedEnum, handleClose, handleUpdateEnum } = props;
    /**
     * Define State
     */
    const [enumValue, setEnum] = useState({ name: "", count: 0 });

    /**
     * Define useEffect
     */

    useEffect(() => {
        if (selectedEnum) {
            setEnum({ ...selectedEnum });
        }
    }, [selectedEnum]);

    /**
     * Handle Change
     * @param {*} property
     * @param {*} value
     */
    const handleChange = useCallback((property, value) => {
        setEnum((enumValue) => ({ ...enumValue, [property]: value }));
    }, [enumValue]);

    return (
        <Grid container className={classes.landinPageContainer}>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={
                    {
                        'aria-labelledby': 'basic-button'
                    }
                }
            >
                <ValidatorForm onSubmit={() => handleUpdateEnum(enumValue)} noValidate>
                    <Grid container className={classes.enumerationAddContainer} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {selectedEnum ? "Edit Enum" : "Add Enum"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MemoTextBoxComponent
                                name="name"
                                fullWidth
                                label={
                                    <span className="requiredLabel">
                                        Name
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                variant="standard"
                                size="medium"
                                value={enumValue.name || ""}
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.nameRequired]}
                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="mr-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={handleClose}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Menu>
        </Grid>
    );
}

// default props
AddEnum.defaultProps = {
    classes: {},
    open: false,
    anchorEl: null,
    selectedEnum: {},
    handleClose: () => { },
    handleUpdateEnum: () => { }
};

// prop types
AddEnum.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    selectedEnum: PropTypes.object,
    handleClose: PropTypes.func,
    handleUpdateEnum: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.selectedEnum, nextProps.selectedEnum) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...PatternAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(AddEnum, areEqual));