// Import API Helper
import { getRequest, putRequest, postRequest, deleteRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Detail
 */
async function detail(params) {
    const response = await getRequest(`${urlConstants.version.url}${params}/`);
    return response;
}

/**
 * Update
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.version.url}${id}/`, params);
    return response;
}

/**
 * Favorite
 */
async function favorite(params) {
    const response = await postRequest(`${urlConstants.favorite.url}`, params);
    return response;
}

/**
 * Alerts
 */
async function alerts(params) {
    const response = await getRequest(`${urlConstants.drift.assetAlerts}${params}/`);
    return response;
}


/**
 * Share
 */
async function share(params) {
    const form_data = new FormData();
    Object.keys(params).map((param) =>
        form_data.append(param, params[param])
    );
    const response = await postRequest(urlConstants.notifications.url, form_data, true);
    return response;
}

/**
 * Approve terms
 */
async function approve_term(params) {
    const response = await postRequest(urlConstants.semantic.term_approval.url, params);
    return response;
}

/**
 * Delete approved terms
 */
async function delete_approved_term(params) {
    const endpoint = `${urlConstants.semantic.term_approval.url}${params.id}/${params.query_params}`;
    const response = await deleteRequest(endpoint, params);
    return response;
}

/**
 * Get asset job status
 */
async function get_asset_status(asset_id) {
    const endpoint = `${urlConstants.asset.get_status}${asset_id}/`;
    const response = await getRequest(endpoint);
    return response;
}

/**
 * Version History List for Asset
 */
async function getVersionHistoryList(id, limit, offset, measureId = "") {
    const measure_param = measureId ? `&measure_id=${measureId}` : "";
    const response = await getRequest(`${urlConstants.versionHistory.url}?asset_id=${id}${measure_param}&offset=${offset}&limit=${limit}`);
    return response;
}


/**
 * Export All Service Functions
 */
export const versionService = {
    detail, update, favorite, share, alerts, approve_term, delete_approved_term, get_asset_status, getVersionHistoryList
};