import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

//  Import Components
import { Grid, IconButton, Typography } from '@mui/material';
import {
    AddPatternComponent,
    NoResultComponent
} from '../../../../../../../components/index.js';
import PreviewComponent from '../../../../../../asset/properties/previewComponent/index.jsx';

// Import Images
import { DeleteIcon, PlusIcon } from '../../../../../../../assets/svg/index.js';
import { BarChart } from '@mui/icons-material';

//  Import Styles
import PatternListStyle from './style.jsx';
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Constants
import appConstants from '../../../../../../../constants/appConstants.js';

// Import Actions
import {
    createMeasureRequest,
    updateMeasureRequest,
    deleteMeasureRequest
} from '../../../../../../../redux/reducer/baseMeasureReducer';
import { deletePattern } from '../../../../../../../redux/reducer/termReducer';

function PatternList(props) {
    const { classes, patterns, semanticsPermission } = props;
    const dispatch = useDispatch();
    const { termId } = useParams();

    /**
     * Define State
     */

    const [showPatternEdit, setShowPatternEdit] = useState(false);
    const [selectedPattern, setSelectedPattern] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);

    /**
     * Handle Click
     * @description select existing pattern to edit
     * @param {*} event
     * @param {*} pattern
     * @param {*} index
     */
    const handleClick = (sPattern = null) => {
        if (sPattern) {
            setSelectedPattern({
                ...sPattern,
                pattern: {
                    posix_pattern: sPattern.posix_pattern,
                    sql_pattern: sPattern.sql_pattern
                }
            });
        }
        setShowPatternEdit(true);
    };

    /**
     * Handle Close Dialog
     */
    const handleClose = () => {
        setSelectedPattern(null);
        setShowPatternEdit(false);
    };

    /**
     * Handle Update Pattern
     * @description Update Pattern Edit/Delete
     * @param {*} updatedPattern
     * @param {*} type
     * @param {*} index
     */
    const handleUpdatePattern = (updatedPattern) => {
        const requestParams = {
            ...updatedPattern,
            type: 'pattern',
            category: '',
            level: 'term',
            term: termId,
            sql_pattern: updatedPattern?.pattern?.sql_pattern ?? updatedPattern.sql_pattern,
            posix_pattern: updatedPattern?.pattern?.posix_pattern ?? updatedPattern.posix_pattern,
            properties: {
                sql_pattern: updatedPattern?.pattern?.sql_pattern ?? updatedPattern.sql_pattern,
                posix_pattern: updatedPattern?.pattern?.posix_pattern ?? updatedPattern.posix_pattern
            }
        };
        if (!requestParams.id) {
            dispatch(createMeasureRequest(requestParams));
        } else {
            dispatch(updateMeasureRequest(requestParams));
        }
        handleClose();
    };

    /**
     * Delete Pattern
     * @param {*} patternId
     */
    const deleteItem = (patternId) => {
        dispatch(deletePattern(patternId));
        dispatch(deleteMeasureRequest(patternId));
    };

    /**
     * Handle Chart Preview
     */
    const handlePreview = () => {
        const patternList = patterns.map((pattern) => {
            return {
                ...pattern,
                count: pattern.count || 0,
                is_valid: "is_valid" in pattern ? pattern.is_valid : true,
                pattern: {
                    sql_pattern: pattern.sql_pattern,
                    posix_pattern: pattern.posix_pattern
                }
            };
        });
        setOpenPreview({ open: !openPreview?.open ?? false, data: patternList });
    };

    return (
        <React.Fragment>
            <Grid
                container
                justifyContent={'space-between'}
                alignItems="baseline"
            >
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.datasetProperties.pattern}
                </Typography>
                <Grid className="dflex spaceBetween">
                    {
                        patterns && patterns.length > 0 &&
                        <IconButton
                            onClick={handlePreview}
                            className={classes.chartIconClass}
                        >
                            <BarChart className="chartIcon" />
                        </IconButton>
                    }
                    {
                        semanticsPermission?.is_edit && (
                            <IconButton
                                className={`${classes.PlusIcon} p0`}
                                onClick={() => handleClick(null)}
                            >
                                <PlusIcon />
                            </IconButton>
                        )
                    }
                </Grid>
            </Grid>
            <Typography variant="body1" align="left">
                {appConstants.labels.datasetProperties.patternDesc}
            </Typography>
            <Grid item xs={12} className={classes.valuesPatternList}>
                {
                    patterns.map((item, index) => (
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            key={`pattern-${index}`}
                            className="mb-1"
                            wrap="nowrap"
                        >
                            <Typography
                                variant="body1"
                                className="listItem"
                                onClick={
                                    () =>
                                        semanticsPermission?.is_edit &&
                                        handleClick(item)
                                }
                                align="left"
                            >
                                {item.name || ''}
                            </Typography>
                            <Grid item className={classes.patternValuesRt}>
                                <Grid className={`${classes.pattenVelues} ${item.is_positive ? 'valid' : 'invalid'}`}
                                >
                                    {item.count || 0}
                                </Grid>
                                {
                                    semanticsPermission?.is_edit && (
                                        <IconButton
                                            className={classes.pattenDelete}
                                            onClick={() => deleteItem(item.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                }
                            </Grid>
                        </Grid>
                    ))
                }
                {
                    !patterns.length && (
                        <NoResultComponent
                            height="180px"
                            title="No Patterns Discovered"
                        />
                    )
                }
            </Grid>
            {
                showPatternEdit && (
                    <AddPatternComponent
                        selectedPattern={selectedPattern}
                        open={showPatternEdit}
                        handleClose={() => handleClose()}
                        handleUpdatePattern={(pattern) => handleUpdatePattern(pattern)}
                    />
                )
            }
            {
                openPreview && openPreview.open &&
                <PreviewComponent
                    open={openPreview.open}
                    handleClosePreview={() => handlePreview()}
                    type={'Pattern'}
                    data={openPreview.data}
                    validKey={'is_positive'}
                    removeFailedRows
                />
            }
        </React.Fragment>
    );
}

// default props
PatternList.defaultProps = {
    classes: {},
    patterns: [],
    semanticsPermission: {}
};

// prop types
PatternList.propTypes = {
    classes: PropTypes.object,
    patterns: PropTypes.array,
    semanticsPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return (
        _.isEqual(prevProps.patterns, nextProps.patterns) &&
        _.isEqual(prevProps.classes, nextProps.classes)
    );
}

export default withStyles(
    (theme) => ({
        ...PatternListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(PatternList, areEqual));