import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Grid } from '@mui/material';


// import Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, children, setCarouselRef, slide_settings, featureSlider, variableWidth, handleDrag } = props;

    /**
     * Handle Before Change
     */
    const handleBeforeChange = () => {
        handleDrag(true);
    };

    /**
     * Handle After Change
     */
    const handleAfterChange = () => {
        handleDrag(false);
    };


    /**
     * Define Slider Default Settings
     */
    const settings = {
        dots: Boolean(slide_settings.dots),
        infinite: true,
        slidesToShow: slide_settings.slidesToShow ? slide_settings.slidesToShow : 1,
        slidesToScroll: 1,
        speed: slide_settings?.speed ? slide_settings?.speed : 200,
        lazyLoad: !variableWidth,
        swipeToSlide: false,
        initialSlide: slide_settings.initialSlide ? slide_settings.initialSlide : 0,
        fade: Boolean(slide_settings.fade),
        autoplay: Boolean(slide_settings.autoplay),
        autoplaySpeed: 7000,
        variableWidth: Boolean(variableWidth)
    };

    return (
        <Grid className={`sliderGrid ${featureSlider ? `${classes.FeatureSlider} slider` : null}`}>
            <Slider
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
                onSwipe={handleBeforeChange}
                ref={(slider) => setCarouselRef(slider)}
                {...settings} >
                {children}
            </Slider>
        </Grid>
    );
};

// default props
SliderComponent.defaultProps = {
    classes: {},
    setCarouselRef: () => { },
    slide_settings: {},
    featureSlider: false,
    variableWidth: false,
    handleDrag: () => { }
};

SliderComponent.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    setCarouselRef: PropTypes.func,
    slide_settings: PropTypes.object,
    featureSlider: PropTypes.bool,
    variableWidth: PropTypes.bool,
    handleDrag: PropTypes.func
};

export default SliderComponent;