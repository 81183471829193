const style = (theme) => ({
    generalPageContainer: {
        padding: '24px 30px 30px'
    },
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px'
    },
    subHeaderConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '10px 24px'
    },
    bodyContainer: {
        padding: 24,
        paddingBottom: 6,
        '& svg.CalendarIcon path, svg.CalendarIcon circle, svg.CalendarIcon ellipse': {
            fill: `${theme.palette.greyshades.darkgrey} !important`
        },
        '& .calendarIcon:hover': {
            '& circle, ellipse, path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .anomalyInput .textBoxContainer': {
            maxWidth: 200,
            '& input[type="number"]': {
                MozAppearance: 'auto !important',
                WebkitAppearance: 'auto !important'
            },
            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                WebkitAppearance: 'auto !important',
                margin: 0
            }
        },
        '& .switch': {
            margin: '0 !important'
        },
        '& .reporting': {
            marginTop: "0 !important",
            '& .MuiGrid-item': {
                display: "flex !important",
                alignItems: "center !important",
                paddingTop: "30px !important",
                '& .preview .checkboxContainer': {
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: "center !important",
                    flexWrap: "nowrap",
                    marginBottom: '-5px'
                },
                '&.previewType .selectComponent > div': {
                    minWidth: "270px !important"
                },
                '&.failedrowsType .selectComponent > div': {
                    minWidth: "200px !important"
                }, '&.autocompleteTB .MuiAutocomplete-root': {
                    minWidth: "200px !important"
                },
                '& .textBoxContainer': {
                    width: '100%'
                },
                '& .selectComponent': {
                    width: '100%'
                }
            }
        },
        '& .profileInput': {
            display: "flex",
            alignItems: "flex-end",
            "& div": {
                minWidth: "130px !important"
            },
            '& .textLabel': {
                marginLeft: 8
            }
        },
        '& .profileDescriptionContainer': {
            marginBottom: 20
        },
        '& .apiTextBtns': {
            '& .MuiButton-text': {
                alignSelf: 'end'
            }
        }
    },
    CalendarIcon: {
        '&:hover': {
            '& svg.CalendarIcon circle, svg.CalendarIcon ellipse, svg.CalendarIcon path': {
                fill: `${theme.palette.secondary.main} !important`
            },
            background: 'red'
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 6px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& fieldset': {
            borderColor: 'transparent !important'
        },
        '& .Mui-focused fieldset': {
            border: 'none !important'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 8,
            marginRight: 8
        },
        '& .aboveTxt': {
            minWidth: 75
        }
    },
    scoringValuePicker: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        justifyContent: 'space-between'
    },
    colorPicker: {
        padding: '6px 15px',
        background: theme.palette.greyshades.lightgrey,
        position: 'relative',
        '& svg': {
            width: '14px',
            height: '20px'
        },
        '& .typeColor': {
            width: 22,
            border: 'none',
            height: 22,
            display: 'block',
            opacity: 0,
            position: 'absolute',
            top: '6px',
            right: '11px'
        }
    },
    subContent: {
        '& .textBoxContainer, .selectComponent': {
            width: '100%'
        }
    },
    notifyToggleBtn: {
        '& .MuiToggleButtonGroup-grouped': {
            height: '36px'
        }
    },
    notifciationItems: {
        '& .MuiFormControlLabel-root': {
            minWidth: '132px'
        }
    },
    importContainer: {
        '& .containerInner': {
            padding: 30,
            height: '100Vh',
            '& .dropContainer': {
                border: `2px dashed ${theme.palette.greyshades.lightgrey}`,
                background: theme.palette.greyshades.lightbackground,
                height: `calc(100% - 60px)`,
                '& .UploadIcon': {
                    width: '45px',
                    height: '45px'
                }
            },
            '& .chooseFileBtn': {
                color: '#222',
                border: `1px solid ${theme.palette.greyshades.darkgrey}`
            },
            '& .importList': {
                height: `calc(100% - 60px)`,
                alignItems: 'baseline'
            },
            '& .headerRow, .bodyRow': {
                display: 'flex'
            },
            '& .headerCell, .bodyCell': {
                flex: 1,
                padding: '8px 10px'
            },
            '& .importTable': {
                paddingTop: 15
            }
        }
    },
    copyData: {
        position: 'relative',
        background: theme.palette.greyshades.lightestgrey,
        border: `1px solid ${theme.palette.greyshades.lightgrey}40`,
        borderRadius: '0px 4px 4px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 9px',
        height: 36,
        maxWidth: '100%',
        '& .copyIcon': {
            position: 'absolute',
            right: 0,
            background: '#fff',
            height: '34px',
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0px 4px 4px 0px',
            cursor: 'pointer',
            '&:hover ': {
                '& #Path_2746': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTypography-root': {
            lineHeight: '15px'
        },
        '& .password': {
            "-webkit-text-security": "disc !important"
        }
    },
    syncIcon: {
        height: 16,
        width: 16,
        color: theme.palette.greyshades.grey,
        padding: 0
    }
});
export default style;