const style = (theme) => ({
    connectorDetailTop: {
        paddingBottom: '24px !important',
        paddingRight: 30
    },
    connectorDetailBody: {
        background: theme.palette.primary.contrastText,
        padding: '25px 0px 24px 30px',
        boxShadow: '0px 0px 4px #00000014'
    },
    connectorDetailHeadlt: {
        display: 'flex',
        alignItems: 'center',
        '& .searchBox ': {
            padding: '2px 10px',
            marginBottom: '20px !important'
        }
    },
    connectorDetailHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            minWidth: 120
        }
    },
    connectorDetailAction: {
        '& svg path, svg circle, svg ellipse': {
            fill: `${theme.palette.greyshades.darkgrey} !important`
        },
        '& .MuiIconButton-root:hover': {
            '& .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .syncicon': {
            width: "40px",
            height: "40px",
            transform: "rotate(90deg)"
        }
    },
    connectorIcon: {
        '& .connectorCardImg': {
            height: '40px !important'
        },
        '&.teradata .connectorCardImg': {
            height: '25px !important'
        },
        marginRight: '16px !important',
        '& svg': {
            fontSize: '36px !important',
            width: '36px !important',
            height: '36px !important'
        }
    },
    tableButtons: {
        paddingRight: 30,
        paddingTop: '20px !important'
    },
    connectionTableDetails: {
        paddingTop: '0px !important'
    },
    connectionTableSearch: {
        paddingRight: 30
    },
    assetTableContainer: {
        height: 'calc(100vh - 328px)',
        overflowY: 'auto',
        paddingRight: 22,
        '& .MuiPaper-root': {
            height: 'calc(100vh - 375px) !important',
            overflow: 'auto',
            '&.searchBox': {
                height: 'auto !important'
            }
        },
        '& .queryInputContainer': {
            "& .MuiOutlinedInput-input": {
                background: "#fff !important"
            },
            '& .MuiFormHelperText-root.Mui-error': {
                margin: "5px !important"
            }
        },
        '& .MuiInput-underline:before': {
            borderBottom: "0px solid #E5E7EB !important"
        },
        '& .MuiFormHelperText-root.Mui-error': {
            height: "15px !important"
        },
        '& .MuiTableRow-root .MuiTableCell-root:nth-child(2)': {
            paddingLeft: '5px !important'
            // fontWeight: 'bold'
        }
    },
    sortSelected: {
        marginTop: '0px !important',
        '& .MuiTypography-root': {
            paddingLeft: '0px !important'
        }, '& .MuiInput-input': {
            paddingRight: '30px !important',
            fontSize: '14px !important'
        }
    },
    connectorFormTile: {
        width: "100%",
        '& .MuiTypography-h3 .MuiInput-input': {
            fontSize: `${theme.palette.headers.h3.size}px !important`,
            fontWeight: `${theme.palette.headers.h3.weight} !important`,
            fontFamily: `${theme.palette.headers.h3.fontFamily} !important`
        },
        '& .MuiTypography-body1 .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            fontWeight: `${theme.palette.headers.body1.weight} !important`,
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`

        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        }
    },
    toggleSwitch: {
        display: "flex",
        " & label": {
            margin: 0,
            marginRight: "-5px !important"
        }
    },
    popoverContainer: {
        '& .MuiMenuItem-root': {
            padding: '10px 20px',
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`,
                '& svg path': {
                    fill: theme.palette.secondary.main
                }
            },
            '& svg': {
                marginRight: '10px',
                width: "18px",
                height: "18px"
            }
        }
    }
});
export default style;