import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDrag, useDrop } from 'react-dnd';

// Import Component
import FieldComponents from './fieldComponents.jsx';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Actions
import { updateFieldProperty, createFieldPropertyRequest, updateFieldPropertyRequest, groupCollapse, updateGroupCollapseRequest } from '../../../redux/reducer/fieldPropertyReducer';


function FieldGroupCard(props) {

    /**
     * Define Props
     */
    const { item, index, moveCard } = props;
    const dispatch = useDispatch();
    const { id: asset } = useParams();
    const ref = useRef(null);

    /**
     * Redux Store
     */
    const { fields } = useSelector(({ fieldProperty }) => fieldProperty, shallowEqual);


    /**
     * Get Group Fields
     * @param {*} groupId
     * @returns
     */
    const getGroupFields = (groupId) => {
        return fields.filter((field) => field.group_id === groupId).sort((a, b) => a.order - b.order);
    };

    /**
     * Handle OnChange
     * @param {*} field
     * @param {*} value
     */
    const onChange = (field, value) => {
        const requestParams = {
            id: field.id,
            custom_field: field.custom_field,
            value,
            asset
        };
        dispatch(updateFieldProperty(requestParams));
        if (field.id) {
            dispatch(updateFieldPropertyRequest(requestParams));
        } else {
            dispatch(createFieldPropertyRequest(requestParams));
        }
    };

    /**
     * Handle Accordian
     */
    const onHandleAccordian = () => {
        const requestParams = {
            id: item.id,
            collapse: !item.collapse
        };
        dispatch(groupCollapse(requestParams));
        dispatch(updateGroupCollapseRequest(requestParams));
    };

    /**
     * Drag
     */
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 1 : 1;
    drag(drop(ref));
    return (
        <Accordion ref={ref} style={{ opacity }} data-handler-id={handlerId} expanded={item.collapse} onChange={onHandleAccordian}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" className="capitalize">
                    {item.name || "Uncategorized"}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={8}>
                    {
                        getGroupFields(item.id).map((field, index) =>
                            <Grid key={`field_${index}`} item className="fieldItems">
                                <Typography variant="h6" className="capitalize">
                                    {field.name}
                                </Typography>
                                <Typography variant="body1" className="pt-1 mb-1">
                                    {field.description}
                                </Typography>
                                <FieldComponents field={field} onChangeValue={(value) => onChange(field, value)} />
                            </Grid>
                        )
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

// default props
FieldGroupCard.defaultProps = {
    item: {},
    index: null,
    moveCard: () => { }
};

// prop types
FieldGroupCard.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    moveCard: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FieldGroupCard);