const style = () => ({
    termsPageContainer: {
        padding: '20px 0px 0px',
        '& .MuiTableCell-head:first-child': {
            paddingLeft: '12px !important'
        },
        '& .MuiTableCell-head:last-child': {
            // paddingRight: '0px !important'
        },
        '& .MuiTableCell-root': {
            '& .MuiSelect-select': {
                paddingLeft: '0px !important'
            }
        }
    },
    termsTableContainer: {
        overflowY: 'auto'
    }
});
export default style;