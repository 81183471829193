import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Components
import Scheduler from '../../../components/scheduler/index.jsx';
import {
    ToggleComponent,
    LoaderButtonComponent,
    SelectComponent
} from '../../../components/index.js';
import TableComponent from '../../../components/tables/index.jsx';
import AssetHeader from './header.jsx';
import DialogComponent from '../../../components/dialogBox/index.jsx';

// Import Icons
import { PlusIcon } from '../../../assets/svg/index.js';

//  Import Styles
import AssetStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Helpers
import { checkPermission, permissionHeaders } from '../../../helpers/appHelpers.js';

// Import Constants
import appConstants from '../../../constants/appConstants.js';
import featureConstants from '../../../constants/featureConstants.js';

// Import Actions & Reducers
import {
    connectorAssetRequest, connectorAttributeRequest, connectorAssetReducerSelectUpdate, connectorAssetReducerSortingUpdate,
    connectorAssetReducerSearchUpdate, loadMoreAssets, connectorAssetReducerValueUpdate, assetCreateRequest, assetDeleteRquest,
    setSelectedConnectionId, onChangeView, addQuery, toggleConnectionAsset, selectAttributeRequest
} from '../../../redux/reducer/connectorReducer';
import { updateConnectionState } from '../../../redux/reducer/connectionReducer';
import { updateAssetRequest, updateAssetPropertyRequest } from '../../../redux/reducer/assetReducer.js';
import LogViewer from '../../logs/components/logViewer/index.jsx';
import { displyAlert } from '../../../redux/reducer/alertReducer.js';

function Asset(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryMode = appConstants.toggle.tableAndQuery[1].name;
    const pipelineMode = appConstants.toggle.tableQueryAndPipeline[2].name;
    const connections_filter_by = appConstants.connections_filter_by;
    const { id: connection_id, type: connection_type } = useParams();
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [scheduler, setScheduler] = useState({
        open: false,
        anchorElement: null,
        connectionId: connection_id,
        asset: {},
        isAsset: true
    });
    const [logView, setLogViewer] = useState({
        open: false,
        anchorElement: null,
        errorMessage: ""
    });
    const [selectedView, setSelectedView] = useState(appConstants.toggle.tableAndQuery[0]);
    const [searchData, setSearchData] = useState({});
    const [asset_sort, setAssetSort] = useState(connections_filter_by[1]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { connectorsList, data: assetData, sortBy, orderBy, isLoading, saveIsLoading, view, filterData: filterAssetData } = useSelector((state) => state.connector.asset, shallowEqual);

    /**
     * Get Permission
     */
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const { isConnectionChanged } = useSelector((state) => state.connection, shallowEqual);
    const connectionPermission = checkPermission(permission, featureConstants.settings.connections);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);

    /**
     * Set View
     */
    useEffect(() => {
        const selectedView = view && connection_id in view ? view[connection_id] : appConstants.toggle.tableQueryAndPipeline[0].value;
        const selectedValue = appConstants.toggle.tableQueryAndPipeline.find((obj) => obj.name === selectedView) || appConstants.toggle.tableQueryAndPipeline[0];
        setSelectedView({ ...selectedValue });
    }, []);
    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(setSelectedConnectionId(connection_id));
        const data = connectorsList[connection_id];
        if (!data || (data && data.length <= 0) || isConnectionChanged) {
            const requestData = {
                connection_id,
                view: selectedView.name
            };
            dispatch(connectorAssetRequest(requestData));
            if (isConnectionChanged) {
                dispatch(updateConnectionState());
            }
        }
    }, [dispatch, connection_id]);

    /**
     * Get Asset List Table Properties
     * @returns
     */
    const getTableProperties = () => {
        let requiredSubTable = true;
        let tableHeader = appConstants.table.databaseTableHeader;

        switch (connection_type.toLowerCase()) {
            case appConstants.connectionTypes.tableau.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.TableauTableHeader;
                break;
            case appConstants.connectionTypes.dbt.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.DbtTableHeader;
                break;
            case appConstants.connectionTypes.fivetran.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.FivetranTableHeader;
                break;
            case appConstants.connectionTypes.teradata.value:
                tableHeader = appConstants.table.TeradataTableHeader;
                break;
            case appConstants.connectionTypes.airflow.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.airflowTableHeader;
                break;
            case appConstants.connectionTypes.talend.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.talendTableHeader;
                break;
            case appConstants.connectionTypes.powerbi.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.PowerBiTableHeader;
                break;
            case appConstants.connectionTypes.adf.value:
                requiredSubTable = false;
                tableHeader = appConstants.table.AdfTableHeader;
                break;
            default:
                break;
        }

        return { requiredSubTable, tableHeader };
    };


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        dispatch(connectorAssetReducerSearchUpdate({ searchFilters: search_by, connection_id, view: selectedView.name }));
    };


    /**
     * Handle Checkbox Click
     * @param {*} isChecked
     * @param {*} item
     * @param {*} isHeader
     */
    const onClickCheckbox = (isChecked, item, parentData, isHeader) => {
        if (item && item.is_connected && !item.is_delete) {
            dispatch(updateAssetPropertyRequest({ id: item.id, is_active: !item.is_selected }));
        }

        const requestData = {
            isChecked, item, parentData, isHeader, connection_id, view: selectedView.name, searchFilters: searchData
        };
        dispatch(connectorAssetReducerSelectUpdate(requestData));

        // select or un-select attribute
        if (parentData && isHeader && !item) {
            dispatch(selectAttributeRequest({ assetId: parentData, attributeName: "", attributeData: item, is_bulk_upadate: true, is_selected: isChecked, connection_id: connection_id }));
        }
        else if (parentData && item) {
            const selectedAsset = assetData?.find((item) => item.id === parentData && item.is_connected);
            if (selectedAsset) {
                dispatch(selectAttributeRequest({ assetId: parentData, attributeName: item.column_name, is_selected: isChecked, connection_id, attributeData: item }));
            }
        }
    };

    /**
     * Handle view change
     * @param {*} value
     */
    const onViewChange = (value) => {
        if (selectedView && selectedView.value === value || !value || isLoading) {
            return;
        }
        const selectedMode = appConstants.toggle.tableQueryAndPipeline.find((item) => item.value === value);
        setSelectedView(selectedMode);
        setSearchData({});
        dispatch(onChangeView({ connection_id, view: selectedMode.name }));
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy, connection_id, view: selectedView.name
        };
        dispatch(connectorAssetReducerSortingUpdate(requestData));
    };

    /**
     * Confirm Dialog for Delete Asset
     * @param {*} data
     */
    const onDeleteAssetAction = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.assetDeleteMessage,
            data
        });
    };

    /**
     * Open Schedule Modal
     * @param {*} data
     */
    const openScheduleModal = (event, selectedItem) => {
        event.stopPropagation();
        setScheduler({
            open: true,
            anchorElement: event.target,
            connectionId: connection_id,
            asset: selectedItem,
            isAsset: true
        });
    };

    /**
     * Handle Action Event
     * @param {*} selectedItem
     * @param {*} actionName
     */
    const onClickActions = (selectedItem, actionName, event) => {
        switch (actionName) {
            case "delete":
                onDeleteAssetAction(selectedItem);
                break;
            case "schedule":
                openScheduleModal(event, selectedItem);
                break;
            case "error_log":
                if (selectedItem.error?.length <= 0) {
                    return;
                }
                setLogViewer({
                    open: true,
                    anchorElement: event.target,
                    errorMessage: selectedItem.error
                });
                break;
            default:
                break;

        }
    };

    /**
     * Handle Close LogViewer
     */
    const onCloseLogViewer = () => {
        setLogViewer({
            open: false,
            anchorElement: null,
            errorMessage: ""
        });
    };

    /**
     * Bind Accordian Table
     * @param {*} selectedItem
     * @param {*} rowIndex
     */
    const onClickAccordian = (selectedItem, isOpen) => {
        if (isOpen && selectedItem.asset_type !== queryMode && !selectedItem.attributes) {
            let schema = selectedItem.schema || "";
            if (selectedItem.database && connection_type?.toLowerCase() === appConstants.connectionTypes.teradata.value) {
                schema = selectedItem.database;
            }
            const requestData = {
                connection_id,
                name: selectedItem.name,
                database: selectedItem.database,
                schema: schema,
                asset_id: selectedItem.id
            };
            dispatch(connectorAttributeRequest(requestData));
        }
        dispatch(toggleConnectionAsset({ id: selectedItem.id, isOpen }));
    };

    /**
     * Get Filter Data By Search
     * @param {*} fitlerData
     * @returns
     */
    const filterDataBySearch = (fitlerData, includeAlreadySelected = false) => {
        const filters = [];
        for (const key of Object.keys(searchData)) {
            if (searchData[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            fitlerData = fitlerData.filter((item) => {
                if (includeAlreadySelected && item.is_selected) {
                    return true;
                }
                return filters.every((key) => {
                    let value = typeof item[key] === 'number' ? item[key].toString() : item[key];
                    value = value !== null ? value : "";
                    if ((key === "row_count" || key === "watermark_columns") && 'na'.includes(searchData[key].toLowerCase()) && !item[key]) {
                        return true;
                    }
                    return typeof value === 'string' && value.toLowerCase().includes(searchData[key].toLowerCase());
                });
            });
        }
        return fitlerData;
    };

    /**
     * Calculate Total Selected Asset and Attributes
     */
    const getStatistics = (connection_id, stat_data) => {
        let data = stat_data[connection_id];
        data = data && data.length > 0 ? data : [];
        data = filterDataBySearch(data, true);

        if (selectedView && selectedView.name === queryMode) {
            data = data.filter((item) => item.asset_type === queryMode);
        } else {
            data = data.filter((item) => item.asset_type !== queryMode);
        }
        const selectedAsset = _.filter(data, { is_selected: true });
        const selectedAttribute = selectedAsset.reduce((counter, obj) => {
            if (obj.attributes) {
                counter += _.filter(obj.attributes, { is_selected: true }).length;
            } else {
                counter += obj.selected_attributes || obj.column_count;
            }
            return counter;
        }, 0);
        return {
            tot_assets: selectedAsset.length,
            tot_attributes: selectedAttribute
        };
    };
    const asset_statistics = useMemo(() => getStatistics(connection_id, connectorsList), [connection_id, connectorsList, selectedView]);

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = () => {
        dispatch(loadMoreAssets({ connection_id, view: selectedView.name, searchFilters: searchData }));
    };

    /**
     * Handle Figer Print Change
     * @param {*} value
     */
    const onCompnentEvent = (key, value, item, event) => {
        const requestData = {
            id: item.id,
            value: {
                key,
                value
            },
            connection_id
        };
        dispatch(connectorAssetReducerValueUpdate(requestData));
        if (key === "is_incremental" && value) {
            const selectedItem = { ...item };
            selectedItem[key] = value;
            openScheduleModal(event, selectedItem);
        }
    };

    /**
     * On Filter Change
     */
    const onTableFilterChange = (value) => {
        setAssetSort(value);
        const { tableHeader } = getTableProperties();
        const requestData = {
            sortBy: connections_filter_by.slice(-2).includes(value) ? "is_selected" : tableHeader[0].key,
            orderBy: value === connections_filter_by[1] ? "desc" : "asc",
            connection_id,
            view: selectedView.name
        };
        dispatch(connectorAssetReducerSortingUpdate(requestData));
    };

    /**
     * Get Selected Asset Config Properties
     * @param {*} asset
     * @returns
     */
    const getSaveAssetProperty = (asset) => {
        const queryMode = appConstants.toggle.tableAndQuery[1].name;
        switch (connection_type?.toLowerCase()) {
            case appConstants.connectionTypes.tableau.value:
                return {
                    "id": asset.view_id || '',
                    "name": asset.name || '',
                    "type": asset.asset_type,
                    "site_id": asset.site_id || '',
                    "site_name": asset.site_name || '',
                    "workbook_id": asset.workbook_id || '',
                    "workbook_name": asset.workbook_name || '',
                    "project_id": asset.project_id || '',
                    "project_name": asset.project_name || '',
                    "owner_id": asset.owner_id || '',
                    "owner_name": asset.owner_name || '',
                    "createdAt": asset.createdAt || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.powerbi.value:
                return {
                    "name": asset.name || '',
                    "report_id": asset.report_id || '',
                    "type": asset.asset_type,
                    "workspace": asset.workspace || '',
                    "workspace_id": asset.workspace_id || '',
                    "dataset_id": asset.dataset_id || '',
                    "reportType": asset.reportType || '',
                    "embedUrl": asset.embedUrl || '',
                    "webUrl": asset.webUrl || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.snowflake.value:
            case appConstants.connectionTypes.redshift.value:
            case appConstants.connectionTypes.redshift_spectrum.value:
            case appConstants.connectionTypes.mssql.value:
            case appConstants.connectionTypes.oracle.value:
            case appConstants.connectionTypes.postgresql.value:
            case appConstants.connectionTypes.databricks.value:
            case appConstants.connectionTypes.hive.value:
            case appConstants.connectionTypes.synapse.value:
                if (asset.asset_type === "Pipeline") {
                    return {
                        "workflow_id": asset.workflow_id || '',
                        "name": asset.name || '',
                        "asset_type": asset.asset_type || '',
                        "type": asset.type,
                        "created_by": asset.created_by || '',
                        "total_tasks": asset.total_tasks || '',
                        "created_at": asset.created_at || '',
                        "format": asset.format || '',
                        "is_valid": Boolean(asset.asset_type !== queryMode)
                    };
                }
            /* falls through */
            case appConstants.connectionTypes.bigquery.value:
            case appConstants.connectionTypes.db2.value:
            case appConstants.connectionTypes.saphana.value:
            case appConstants.connectionTypes.athena.value:
            case appConstants.connectionTypes.emr_spark.value:
                return {
                    "database": asset.database,
                    "schema": asset.schema || '',
                    "row_count": asset.row_count || 0,
                    "column_count": asset.column_count || 0,
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.denodo.value:
                let schema = asset.schema || '';
                if (schema?.length > 0 && asset.asset_type === queryMode) {
                    schema = "";
                }
                return {
                    "database": asset.database,
                    "schema": schema || '',
                    "row_count": asset.row_count || 0,
                    "column_count": asset.column_count || 0,
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.dbt.value:
                return {
                    "id": asset.model_id || '',
                    "name": asset.name || '',
                    "type": asset.asset_type,
                    "job_id": asset.job_id || '',
                    "job_name": asset.job_name || '',
                    "account_id": asset.account_id || '',
                    "project_id": asset.project_id || '',
                    "project_name": asset.project_name || '',
                    "environment_id": asset.environment_id || '',
                    "database": asset.database || '',
                    "schema": asset.schema || '',
                    "owner_name": asset.owner || '',
                    "runGeneratedAt": asset.runGeneratedAt || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.adf.value:
                return {
                    "name": asset.name || '',
                    "id": asset.id || '',
                    "asset_type": asset.asset_type || '',
                    "description": asset.description || '',
                    "etag": asset.etag || '',
                    "folder": asset.folder || '',
                    "activity_count": asset.activity_count,
                    "last_publish_time": asset.last_publish_time || '',
                    "data_factory": asset.data_factory || '',
                    "resource_group": asset.resource_group || '',
                    "subscription_id": asset.subscription_id || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.fivetran.value:
                return {
                    "id": asset.connector_id || '',
                    "name": asset.name || '',
                    "group_id": asset.group_id || '',
                    "connector_id": asset.connector_id || '',
                    "schema": asset.schema || '',
                    "service": asset.service || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.teradata.value:
                return {
                    "name": asset.name || '',
                    "asset_type": asset.asset_type || '',
                    "type": asset.type,
                    "created_by": asset.created_by || '',
                    "created_at": asset.created_at || '',
                    "database": asset.database,
                    "schema": (asset.asset_type !== queryMode ? asset.database : "") || '',
                    "row_count": asset.row_count || 0,
                    "column_count": asset.column_count || 0,
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.airflow.value:
                return {
                    "dag_id": asset.dag_id,
                    "name": asset.name || '',
                    "asset_type": asset.asset_type || '',
                    "type": asset.type,
                    "description": asset.description || '',
                    "fileloc": asset.fileloc || '',
                    "next_dagrun": asset.next_dagrun || '',
                    "is_active": asset.is_active || '',
                    "schedule_interval_type": asset.schedule_interval_type || '',
                    "schedule_interval_value": asset.schedule_interval_value || '',
                    "tags": asset.tags || '',
                    "is_valid": Boolean(asset.asset_type !== queryMode)
                };
            case appConstants.connectionTypes.talend.value:
                return {
                    "id": asset.pipeline_id,
                    "name": asset.name,
                    "asset_type": asset.asset_type || '',
                    "type": asset.type,
                    "is_valid": Boolean(asset.asset_type !== queryMode),
                    "workspace_id": asset.workspace_id,
                    "environment_id": asset.environment_id,
                    "workspace_name": asset.workspace_name,
                    "environment_name": asset.environment_name
                };
            default:
                return {};
        }
    };

    /**
     * Save Selected Assets
     */
    const saveSelectedAssets = () => {
        const tableTypes = ["base table", "table", "view"];
        let data = connectorsList[connection_id];
        data = data && data.length > 0 ? data : [];
        const selectedAsset = data.filter((item) => item.is_selected && !item.is_connected && item.name?.length > 0);
        if (selectedAsset.length) {
            const prepare_req_data = selectedAsset.map((item) => {
                const fingerprintColumns = item.watermark_columns ? item.watermark_columns.split(",") : [];
                let fingerprintColumn = item.watermark;
                if (!fingerprintColumn && fingerprintColumns && fingerprintColumns.length) {
                    fingerprintColumn = fingerprintColumns[0];
                }
                const schedule = item.schedule ? Object.assign({}, item.schedule) : null;
                if (schedule?.schedule_method === "event") {
                    schedule.target_connection = schedule.target_connection?.id ?? "";
                    schedule.target_asset = schedule.target_asset?.id ?? "";
                }
                let asset_type = item.asset_type || '';
                asset_type = (tableTypes?.includes(asset_type?.toLowerCase())) ? asset_type?.toUpperCase() : asset_type;
                return {
                    "id": item.id,
                    "name": item.name,
                    "description": item.description || '',
                    "technical_name": item.name,
                    "type": asset_type,
                    "view_type": item.view_type || '',
                    "watermark": fingerprintColumn || '',
                    "run_now": true,
                    "days_percentage": item.days_percentage || { "type": "days", "value": "1" },
                    "properties": getSaveAssetProperty(item),
                    "schedule": schedule,
                    "is_incremental": item?.is_incremental ?? false,
                    "incremental_config": item?.incremental_config ?? {},
                    "primary_key": "",
                    "attributes": item.attributes || [],
                    "query": item.query ?? ""
                };
            });

            if (prepare_req_data.length) {
                const isDepthRequired = prepare_req_data.some((item) =>
                    item?.is_incremental && (_.isEmpty(item?.incremental_config) || !item?.incremental_config?.depth.value || !item?.incremental_config?.depth.type || !item.schedule)
                );
                const request_data = {
                    "connection_id": connection_id,
                    "assets": prepare_req_data,
                    "selectedAsset": selectedAsset
                };
                if (isDepthRequired) {
                    dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Depth and Time are Missing!' } }));
                } else {
                    dispatch(assetCreateRequest(request_data));
                }
            }
        }

        const updatedAssets = _.filter(data, { asset_type: "Query", is_updated: true });
        if (updatedAssets.length) {
            const prepare_req_data = updatedAssets.map((item) => {
                return {
                    "id": item.id,
                    "name": item.name,
                    "schedule": item?.schedule ?? null,
                    "query": item.query ?? ""
                };
            });
            const isDepthRequired = prepare_req_data.some((item) =>
                item?.is_incremental && (_.isEmpty(item?.incremental_config) || !item?.incremental_config?.depth.value || !item?.incremental_config?.depth.type || !item.schedule)
            );
            const request_data = {
                id: connection_id,
                type: prepare_req_data,
                connectionParams: {
                    connection_id,
                    hasNewAssets: (selectedAsset.length > 0)
                }
            };
            if (isDepthRequired) {
                dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Depth and Time are Missing!' } }));
            } else {
                dispatch(updateAssetRequest(request_data));
            }
        }
    };

    /**
     * Filter ViewTypes based on connections
     */

    const filteredViewType = () => {
        if ((appConstants.connectionTypes[connection_type.toLowerCase()]?.notQueryTable)) {
            return [appConstants.toggle.viewTypes[1]];
        }
        return appConstants.toggle.viewTypes;
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(assetDeleteRquest({ id: showDialog.data.id, type: (type === "purge"), connection_id: connection_id }));
        dialogCancelEventHandle();
    };

    /**
     * Add new query
     */
    const addNewQuery = () => {
        if (assetData && assetData.filter((item) => (item.query?.trim().length === 0) || item.name?.trim().length === 0).length > 0) {
            return;
        }
        const requestParams = {
            connection_id,
            view: selectedView.name
        };
        dispatch(addQuery(requestParams));
    };

    /**
     * Get Total Tables, Views and Attributes Count
     * @param {*} connection_id
     * @param {*} stat_data
     * @returns
     */
    const getTotalConnectorAssetStatistics = () => {
        if (selectedView && selectedView.name !== queryMode) {
            let data = connectorsList[connection_id] || [];
            data = data && data.length > 0 ? data : [];
            data = filterDataBySearch(data);

            switch (connection_type.toLowerCase()) {
                case appConstants.connectionTypes.tableau.value:
                    const tot_sheet = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'sheet'; }).length;
                    const tot_dashboard = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'dashboard'; }).length;
                    return `Total ${tot_sheet || 0} Sheet and ${tot_dashboard || 0} Dashboard`;
                case appConstants.connectionTypes.dbt.value:
                    const tot_models = data.length;
                    return `Total ${tot_models || 0} Models`;
                case appConstants.connectionTypes.adf.value:
                    const tot_pipelines = data.length;
                    return `Total ${tot_pipelines || 0} ${tot_pipelines < 2 ? 'Pipeline' : 'Pipelines'}`;
                case appConstants.connectionTypes.redshift_spectrum.value:
                    const tot_ext_tables = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'external table'; }).length;
                    const tot_queries = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'query'; }).length;
                    const tot_ext_attribute = data.reduce((counter, obj) => {
                        counter += obj.column_count;
                        return counter;
                    }, 0);
                    return `Total - ${tot_ext_tables || 0} External tables, ${tot_queries || 0} Query and ${tot_ext_attribute || 0} Attributes`;
                case appConstants.connectionTypes.airflow.value:
                    const tot_dags = data.length;
                    return `Total ${tot_dags || 0} Dags`;
                case appConstants.connectionTypes.talend.value:
                    const totalPipelines = data.length;
                    return `Total ${totalPipelines || 0} Pipelines`;
                case appConstants.connectionTypes.powerbi.value:
                    const tot_sheets = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'reports'; }).length;
                    const tot_dashboards = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'dashboard'; }).length;
                    return `Total ${tot_sheets || 0} Report and ${tot_dashboards || 0} Dashboard`;
                default:
                    const tot_tables = _.filter(data, (datum) => { return (datum.asset_type.toLowerCase() === 'base table' || datum.asset_type.toLowerCase() === 'table'); }).length;
                    const tot_views = _.filter(data, (obj) => { return obj.asset_type.toLowerCase() === 'view'; }).length;
                    const tot_attributes = data.reduce((counter, obj) => {
                        counter += obj.column_count;
                        return counter;
                    }, 0);
                    return `Total ${tot_tables || 0} Table, ${tot_views || 0} View and ${tot_attributes || 0} Attributes`;
            }
        }
        return null;
    };

    /**
     * Get Assets Statistics Header Fragment
     * @returns
     */
    const getStatsHeaderByConnectionType = () => {
        switch (connection_type.toLowerCase()) {
            case appConstants.connectionTypes.tableau.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Asset `}
                        {' Selected'}
                    </Typography>
                );
            case appConstants.connectionTypes.dbt.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Models `}
                        {' Selected'}
                    </Typography>
                );
            case appConstants.connectionTypes.adf.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} ${asset_statistics.tot_assets < 2 ? 'Pipeline' : 'Pipelines'}`}
                        {' Selected'}
                    </Typography>
                );
            case appConstants.connectionTypes.fivetran.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Connectors `}
                        {' Selected'}
                    </Typography>
                );
            case appConstants.connectionTypes.airflow.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Dags `}
                        {' Selected'}
                    </Typography>
                );
            case appConstants.connectionTypes.powerbi.value:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Asset `}
                        {' Selected'}
                    </Typography>
                );
            default:
                return (
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {`${asset_statistics.tot_assets || 0} Datasets `}
                        {selectedView?.name !== queryMode && `and ${asset_statistics.tot_attributes || 0} Attributes`}
                        {' Selected'}
                    </Typography>
                );

        }
    };

    /**
     * Get Assets Header Fragment
     * @returns
     */
    const getTableViewOptionByConnectionType = () => {
        switch (connection_type.toLowerCase()) {
            case appConstants.connectionTypes.tableau.value:
            case appConstants.connectionTypes.dbt.value:
            case appConstants.connectionTypes.fivetran.value:
            case appConstants.connectionTypes.airflow.value:
            case appConstants.connectionTypes.talend.value:
            case appConstants.connectionTypes.powerbi.value:
            case appConstants.connectionTypes.adf.value:
                return null;
            case appConstants.connectionTypes.databricks.value:
                return (
                    <React.Fragment>
                        {
                            selectedView?.name === queryMode && connectionPermission?.is_edit ?
                                <Tooltip title="Add New Query" arrow>
                                    <IconButton className="p5 mr-15" id="add-connector" onClick={addNewQuery}>
                                        <PlusIcon />
                                    </IconButton>
                                </Tooltip>
                                : null
                        }
                        <ToggleComponent data={appConstants.toggle.tableQueryAndPipeline} selectedValue={selectedView?.value ?? ""} handleChange={(value) => onViewChange(value)} isDisabled={isLoading} />
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        {
                            selectedView?.name === queryMode && connectionPermission?.is_edit ?
                                <Tooltip title="Add New Query" arrow>
                                    <IconButton className="p5 mr-15" id="add-connector" onClick={addNewQuery}>
                                        <PlusIcon />
                                    </IconButton>
                                </Tooltip>
                                : null
                        }
                        <ToggleComponent data={appConstants.toggle.tableAndQuery} selectedValue={selectedView?.value ?? ""} handleChange={(value) => onViewChange(value)} isDisabled={isLoading} />
                    </React.Fragment>
                );
        }
    };

    /**
     * Get Assets Footer Stats Fragment
     * @returns
     */
    const getFooterStatsConnectionType = () => {
        const total_connector_asset_stats = getTotalConnectorAssetStatistics();

        return (
            <Grid>
                {
                    !isLoading && total_connector_asset_stats && selectedView?.name !== queryMode &&
                    <Typography
                        variant="body1"
                        className={`${classes.textSecondary} noWrap mr-2`}
                    >
                        {total_connector_asset_stats}
                    </Typography>
                }
            </Grid>
        );

    };


    /**
     * Get Asset List Table
     * @returns
     */
    const getTable = () => {
        const { requiredSubTable, tableHeader } = getTableProperties();
        let newTableHeader = [...tableHeader];
        if ([appConstants.connectionTypes.snowflake.value, appConstants.connectionTypes.mssql.value, appConstants.connectionTypes.databricks.value].indexOf(connection_type.toLowerCase()) === -1) {
            newTableHeader = tableHeader.filter((item) => item.key !== 'is_incremental');
        }
        const assetDetails = assetData.map((item) => {
            return {
                ...item,
                is_incremental: !item.is_selected ? false : item.is_incremental
            };
        });
        if (requiredSubTable) {
            return (
                <TableComponent
                    headers={permissionHeaders(newTableHeader, connectionPermission, true)}
                    haveCheckBox
                    stickyHeader
                    disableActions={!connectionPermission?.is_edit}
                    data={assetDetails}
                    defaultHeaderCheckBoxValue={filterAssetData && filterAssetData.filter((item) => item.is_selected === true).length === filterAssetData.length}
                    sortBy={sortBy || newTableHeader[0].key}
                    orderBy={orderBy}
                    haveSubTable
                    subTable={{ "headers": appConstants.table.databaseTableAttributeHeader, "rows": "attributes", height: "200px" }}
                    subTableColSpan={newTableHeader.length + 2}
                    subTableHaveCheckbox
                    onClickSorting={onClickSorting}
                    onClickCheckbox={onClickCheckbox}
                    onClickActions={onClickActions}
                    onClickAccordian={onClickAccordian}
                    onCompnentEvent={onCompnentEvent}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onScrollEnd={onScrollEvent}
                    isLoading={isLoading}
                    height="calc(100vh - 375px)"
                    NoResultText="No data found"
                    searchData={{ ...searchData }}
                    options={appConstants.tableOptions.asset}
                />
            );
        }

        return (
            <TableComponent
                headers={permissionHeaders(newTableHeader, connectionPermission, true)}
                haveCheckBox
                disableActions={!connectionPermission?.is_edit}
                data={assetDetails}
                stickyHeader
                defaultHeaderCheckBoxValue={filterAssetData && filterAssetData.filter((item) => item.is_selected === true).length === filterAssetData.length}
                sortBy={sortBy || newTableHeader[0].key}
                orderBy={orderBy}
                onClickSorting={onClickSorting}
                onClickCheckbox={onClickCheckbox}
                onClickActions={onClickActions}
                onClickAccordian={onClickAccordian}
                onCompnentEvent={onCompnentEvent}
                onHandleSearchEvent={onHandleSearchEvent}
                onScrollEnd={onScrollEvent}
                isLoading={isLoading}
                height="calc(100vh - 375px)"
                NoResultText="No data found"
                searchData={{ ...searchData }}
                options={appConstants.tableOptions.asset}
            />
        );
    };

    return (
        <Fragment>
            <ValidatorForm onSubmit={() => saveSelectedAssets()}>
                <Grid className={classes.connectorDetail}>
                    <Grid item xs={12} className={classes.connectorDetailBody}>
                        <Grid container spacing={3}>
                            <AssetHeader connectionPermission={connectionPermission} />
                            <Grid item xs={12} className={classes.connectionTableDetails}>
                                <Grid item xs={12} className={classes.connectionTableSearch}>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Grid item className={classes.connectorDetailHeader}>
                                            {getStatsHeaderByConnectionType()}
                                            {
                                                selectedView?.name !== queryMode ?
                                                    <SelectComponent
                                                        name={'asset_sort'}
                                                        fullWidth={false}
                                                        className={`${classes.sortSelected} mr-15`}
                                                        variant="standard"
                                                        list={connections_filter_by}
                                                        value={asset_sort}
                                                        onSelectChange={(value) => { onTableFilterChange(value); }}
                                                        validators={['required']}
                                                    />
                                                    : null
                                            }
                                            {getTableViewOptionByConnectionType()}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.assetTableContainer}>
                                    {
                                        (selectedView?.name !== queryMode && selectedView?.name !== pipelineMode) &&
                                        getTable()
                                    }
                                    {
                                        selectedView?.name === queryMode &&
                                        <TableComponent
                                            stickyHeader
                                            headers={permissionHeaders(appConstants.table.queryTableHeader, connectionPermission, true)}
                                            haveCheckBox
                                            disableActions={!connectionPermission?.is_edit}
                                            data={assetData}
                                            defaultHeaderCheckBoxValue={filterAssetData && filterAssetData.filter((item) => item.is_selected === true).length === filterAssetData.length}
                                            sortBy={sortBy || appConstants.table.queryTableHeader[0].key}
                                            orderBy={orderBy}
                                            onClickSorting={onClickSorting}
                                            haveSubTable
                                            selectComponentList={{ 'view_type': filteredViewType() }}
                                            subTable={{ component: "query", isPermissionDisabled: !connectionPermission?.is_edit, componentProps: { className: "queryInputContainer", placeholder: "Enter Query Here", connection_id }, "headers": [], "rows": "attributes", height: "200px" }}
                                            subTableColSpan={appConstants.table.queryTableHeader.length + 2}
                                            onClickActions={onClickActions}
                                            onClickCheckbox={onClickCheckbox}
                                            onClickAccordian={onClickAccordian}
                                            onCompnentEvent={onCompnentEvent}
                                            onSubTableCompnentEvent={onCompnentEvent}
                                            onHandleSearchEvent={onHandleSearchEvent}
                                            onScrollEnd={onScrollEvent}
                                            isLoading={isLoading}
                                            height="calc(100vh - 415px)"
                                            NoResultText="No data found"
                                            searchData={searchData}
                                            options={appConstants.tableOptions.asset}
                                        />
                                    }
                                    {
                                        selectedView?.name === pipelineMode &&
                                        <TableComponent
                                            headers={permissionHeaders(appConstants.table.pipelineTableHeader, connectionPermission, true)}
                                            haveCheckBox
                                            stickyHeader
                                            disableActions={!connectionPermission?.is_edit}
                                            data={assetData}
                                            defaultHeaderCheckBoxValue={filterAssetData && filterAssetData.filter((item) => item.is_selected === true).length === filterAssetData.length}
                                            sortBy={sortBy || appConstants.table.pipelineTableHeader[0].key}
                                            orderBy={orderBy}
                                            onClickSorting={onClickSorting}
                                            onClickCheckbox={onClickCheckbox}
                                            onClickActions={onClickActions}
                                            onClickAccordian={onClickAccordian}
                                            onCompnentEvent={onCompnentEvent}
                                            onHandleSearchEvent={onHandleSearchEvent}
                                            onScrollEnd={onScrollEvent}
                                            isLoading={isLoading}
                                            height="calc(100vh - 415px)"
                                            searchData={{ ...searchData }}
                                            options={appConstants.tableOptions.asset}
                                        />
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.tableButtons}>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid>
                                        {getFooterStatsConnectionType()}
                                    </Grid>
                                    <Grid>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className="mr-2"
                                            onClick={() => navigate(-1)}>
                                            {appConstants.labels.connector.Cancel}
                                        </Button>
                                        {
                                            connectionPermission?.is_edit &&
                                            <LoaderButtonComponent
                                                type={'submit'}
                                                size={'small'}
                                                isLoading={saveIsLoading}
                                                disabled={isLoading}
                                            >
                                                {appConstants.labels.connector.connect}
                                            </LoaderButtonComponent>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        showDialog.open &&
                        <DialogComponent
                            open={showDialog.open}
                            title={showDialog.title}
                            message={showDialog.message}
                            data={showDialog.data}
                            optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                            onCancel={dialogCancelEventHandle}
                            onConfirm={dialogConfirmEventHandle}
                            onOptionDialogButtonClick={dialogConfirmEventHandle} />
                    }
                </Grid>
            </ValidatorForm>
            {
                scheduler?.open &&
                <Scheduler scheduleProps={scheduler} onClose={() => setScheduler({ open: false, anchorElement: null, connectionId: connection_id, asset: {} })} category="asset" disabled={!overviewPermission?.is_edit} />
            }
            {
                logView &&
                <LogViewer
                    open={logView.open}
                    anchorElement={logView.anchorElement}
                    errorMessage={logView.errorMessage}
                    onClose={onCloseLogViewer}
                />
            }
        </Fragment>
    );
}

// default props
Asset.defaultProps = {
    classes: {}
};

// prop types
Asset.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AssetStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Asset);