import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TableSortLabel, Typography, Tooltip } from "@mui/material";

const HeaderRowComponent = (props) => {

    /**
     * Define Props
     */
    const { headers, sortBy, orderBy, onClickSorting } = props;


    const getHeaderLabel = (header) => {
        let headerLabel = header.name;
        if (header.headerTooltipText) {
            headerLabel = (
                <Tooltip title={header.headerTooltipText}>
                    <Typography component={"h6"} variant="h6">
                        {header.name}
                    </Typography>
                </Tooltip>
            );
        }
        return headerLabel;
    };

    return (
        <TableRow key="tableHeaderRow">
            {
                headers && headers.map((header, index) => {
                    const sortingKey = header.sortKey ? header.sortKey : header.key;
                    return (
                        <TableCell key={`headerRowCell-${index}`} sx={{ textAlign: header.align || 'left' }}>
                            <Typography variant="h6">
                                {
                                    header.sorting ?
                                        <TableSortLabel
                                            key={`headerRowLabel-${index}`}
                                            active={sortBy === sortingKey}
                                            direction={orderBy}
                                            onClick={() => onClickSorting(sortingKey, orderBy === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {getHeaderLabel(header)}
                                        </TableSortLabel>
                                        : getHeaderLabel(header)
                                }
                            </Typography>
                        </TableCell>
                    );
                })
            }
        </TableRow>
    );
};

/**
 * Set Component Props
 */
HeaderRowComponent.propTypes = {
    headers: PropTypes.array,
    sortBy: PropTypes.string,
    orderBy: PropTypes.string,
    onClickSorting: PropTypes.func
};


/**
 * Set Default Values
 */
HeaderRowComponent.defaultProps = {
    headers: []
};

export default HeaderRowComponent;