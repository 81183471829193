import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getMetricDetailRequest, getMetricDetailSuccess, getMetricDetailFailure, getComparisonMetricRequest,
    getComparisonMetricSuccess, getComparisonMetricFailure
} from '../reducer/metricReducer';
import { navigate } from '../reducer/navigationReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { metricService } from '../service';

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';


/**
 * Get Domains
 * @returns
 */
const getDetail = (action$) => action$.pipe(
    ofType(getMetricDetailRequest),
    mergeMap(({ payload }) => {
        return from(metricService.detail(payload)).pipe(
            concatMap((res) => { return of(getMetricDetailSuccess(res)); }),
            catchError((error) => {
                const errorMsg = error;
                if (validate404Error(errorMsg)) {
                    return of(getMetricDetailFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
                }
                return of(getMetricDetailFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
            })
        );
    })
);


/**
 * Get Comparison Metric
 * @returns
 */
const getComparisonMetric = (action$) => action$.pipe(
    ofType(getComparisonMetricRequest),
    mergeMap(({ payload }) => {
        return from(metricService.getComparisonMetric(payload)).pipe(
            concatMap((res) => { return of(getComparisonMetricSuccess({ data: res?.data, payload: payload })); }),
            catchError(() => {
                return of(getComparisonMetricFailure(payload));
            })
        );
    })
);

// Export All Domains Functions
export const metricEpic = [getDetail, getComparisonMetric];