import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Button, Dialog, Fade, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import AddRuleStyle from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import QueryBuilderComponent from '../../../../../../components/queryBuilder/index.jsx';
import Header from './header/index.jsx';
import { LoaderButtonComponent, QueryComponent, ToggleComponent, LoaderComponent } from '../../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../../constants/appConstants.js';

// Import Action
import { updateMeasureDetail, createMeasureRequest, updateMeasureRequest, clearMeasureDetailState } from '../../../../../../redux/reducer/baseMeasureReducer';
import { checkPermission } from '../../../../../../helpers/appHelpers.js';
import featureConstants from '../../../../../../constants/featureConstants.js';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function RuleAdd(props) {

    /**
     * Define Props
     */
    const { classes, closeMeasureAdd, open, semanticsPermission } = props;
    const dispatch = useDispatch();
    const { termId } = useParams();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { detail: data, isLoading, saveIsLoading } = useSelector((state) => state.baseMeasure);
    const { detail: selectedTerm } = useSelector((state) => state.term);
    const { permission } = useSelector((state) => state.auth);
    const propertyPermission = checkPermission(permission, featureConstants.assets.properties);
    const { properties, ...rest } = data;

    /**
     * Edit Measure
     * @param {*} key
     * @param {*} value
     */
    const editMeasure = (key, value) => {
        dispatch(updateMeasureDetail({ [key]: value }));
    };

    /**
     * Change Measure Category
     * @param {*} category
     */
    const changeMeasureCategory = (category) => {
        editMeasure("category", category);
        const query = category === appConstants.toggle.conditionalAndQuery[1].value ? appConstants.defaultSemanticQuery : "";
        editMeasure("query", query);
    };


    /**
     * Save Measure
     */
    const saveMeasure = (event) => {
        event.preventDefault();
        const requestData = {
            ...data,
            "term": termId,
            "type": "semantic",
            "level": "term"
        };
        if (!requestData.id) {
            dispatch(createMeasureRequest(requestData));
        } else {
            dispatch(updateMeasureRequest(requestData));
        }
    };

    /**
     * Handle Close Dialog
     */
    const handleDialogClose = () => {
        closeMeasureAdd();
        dispatch(clearMeasureDetailState());
    };
    return (
        <Dialog
            fullScreen
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            {
                !isLoading &&
                <ValidatorForm onSubmit={(event) => saveMeasure(event)} noValidate>
                    <Grid
                        container
                        className={`${classes.editContainer} editContainer`}
                    >
                        <Header
                            data={{ ...rest }}
                            closeMeasureAdd={handleDialogClose}
                            editMeasure={editMeasure}
                            semanticsPermission={semanticsPermission}
                        />
                        <Grid item xs={12}>
                            <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                                <Grid item>
                                    <ToggleComponent
                                        data={appConstants.toggle.semanticMeasureCategory}
                                        selectedValue={data?.category ?? appConstants.toggle.semanticMeasureCategory[0].value}
                                        handleChange={(selectedValue) => changeMeasureCategory(selectedValue)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            !data.is_default && data.category === appConstants.toggle.conditionalAndQuery[0].value &&
                            <Grid item xs={12} className={classes.ruleGreyContainer}>
                                <QueryBuilderComponent
                                    data={properties || {}}
                                    attributes={[]}
                                    isNew={!data.id}
                                    editMeasure={editMeasure}
                                    semanticRule
                                    semanticDatatype={selectedTerm?.derived_type || "Text"}
                                    propertyPermission={propertyPermission}
                                />
                            </Grid>
                        }
                        {
                            data?.category === appConstants.toggle.conditionalAndQuery[1].value &&
                            <Grid item xs={12} className={classes.ruleGreyContainer}>
                                <QueryComponent
                                    name="query"
                                    data={data?.query ?? ""}
                                    onChange={editMeasure}
                                    disabled={!propertyPermission?.is_edit}
                                    enableValidate
                                    isAggregateQuery={data?.is_aggregation_query ?? false}
                                    onCheckAggregateQuery={(value) => editMeasure("is_aggregation_query", value)}
                                    measureProperties={properties}
                                    isValidate
                                    editMeasure={editMeasure}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid className="dflex alignCenter flexEnd p-3">
                        <LoaderButtonComponent
                            size={'small'}
                            isLoading={saveIsLoading}>
                            {appConstants.save}
                        </LoaderButtonComponent>
                        <Button className="ml-2" variant="outlined" color="primary" size="small" onClick={() => handleDialogClose()}>
                            {appConstants.cancel}
                        </Button>
                    </Grid>
                </ValidatorForm>
            }

            {isLoading && <LoaderComponent />}
        </Dialog>
    );
}

// default props
RuleAdd.defaultProps = {
    classes: {},
    closeMeasureAdd: () => { },
    semanticsPermission: {}
};

// prop types
RuleAdd.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    closeMeasureAdd: PropTypes.func,
    semanticsPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AddRuleStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(RuleAdd);