import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, FormLabel } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Components
import QueryBuilderComponent from '../queryBuilder/index.jsx';
import { TextBoxComponent } from '../textBox/index.js';
import { SwitchComponent, AutoCompleteComponent, PopOverComponent } from '../index';

//  Import Styles
import PatternAddStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helpers
import appConstants from '../../constants/appConstants.js';
import { validatePattern } from '../../helpers/appHelpers.js';


function AddPattern(props) {

    /**
     * Define Props
     */
    const {
        classes, selectedItem, onClose, onSave, connectionType,
        anchorEl, valueType, title
    } = props;

    /**
     * Define State
     */
    const [pattern, setPattern] = useState(null);

    /**
     * Load pattern
     */
    const loadPattern = (selectedItem) => {
        const length = selectedItem ? Object.keys(selectedItem).length : 0;
        if (length > 0) {
            setPattern(selectedItem);
        } else {
            if (valueType === "value") {
                setPattern({
                    "name": "",
                    "technical_name": "",
                    "count": 0,
                    "value_count": 0,
                    "is_valid": false,
                    "is_default": false,
                    "is_user_defined": true
                });
            } else {
                setPattern({
                    "name": "",
                    "technical_name": "",
                    "posix_pattern": "",
                    "sql_pattern": "",
                    "count": 0,
                    "value_count": 0,
                    "is_valid": false,
                    "is_default": false,
                    "is_user_defined": true
                });
            }
        }
    };


    /**
     * Define useEffect
     */
    useEffect(() => {
        loadPattern(selectedItem);
    }, [selectedItem]);

    /**
     * Handle Change
     * @param {*} property
     * @param {*} value
     */

    const handleChange = useCallback((property, value) => {
        let updatedPattern = JSON.parse(JSON.stringify(pattern));
        if (property === 'posix_pattern' || property === 'sql_pattern') {
            const patternValue = updatedPattern?.pattern ?? {};
            patternValue[property] = value;
            updatedPattern = { ...updatedPattern, pattern: { ...patternValue } };
        } else {
            updatedPattern = { ...updatedPattern, [property]: value };
        }
        setPattern({ ...updatedPattern });
    }, [pattern]);

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('regexValid', (value) => {
            return validatePattern(value);
        });
    }, []);

    /**
     * Save pattern
     * @param {*} pattern Pattern
     */
    const savePattern = (pattern) => {
        if (!("enum_value" in pattern)) {
            pattern = { ...pattern, enum_value: pattern.name, value_count: 0 };
        }
        onSave(pattern);
    };

    return (
        <PopOverComponent
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            className={classes.addPatternDialog}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
        >
            <ValidatorForm onSubmit={() => savePattern(pattern)} noValidate>
                {
                    pattern &&
                    <Grid container className={classes.patternAddContainer} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteComponent
                                name="name"
                                variant="standard"
                                selectedValue={pattern.name || ""}
                                data={[]}
                                onChange={(event, data) => handleChange(event.target.name, data)}
                                placeholder=""
                                freeSolo
                                autoSelect
                                label={
                                    <span className="requiredLabel">
                                        Name
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.nameRequired]}
                                disabled={pattern.is_default}
                            />
                        </Grid>
                        {
                            (
                                valueType?.toLowerCase() === "pattern" && !pattern.is_default &&
                                (!connectionType || (connectionType && (connectionType.toLowerCase() !== appConstants.connectionTypes.mssql.value)))
                            ) &&
                            <Grid item xs={12}>
                                <TextBoxComponent
                                    name="posix_pattern"
                                    fullWidth
                                    label={
                                        <span className="requiredLabel">
                                            Posix Pattern
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    variant="standard"
                                    size="medium"
                                    value={pattern?.pattern?.posix_pattern || ""}
                                    validators={['required', 'regexValid']}
                                    errorMessages={[appConstants.errorMessages.valueRequired, appConstants.errorMessages.patternNotValid]}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    disabled={pattern.is_default}
                                />
                            </Grid>
                        }
                        {
                            (
                                valueType?.toLowerCase() === "pattern" && !pattern.is_default &&
                                (!connectionType || (connectionType && (connectionType.toLowerCase() === appConstants.connectionTypes.mssql.value)))
                            ) &&
                            <Grid item xs={12}>
                                <Typography variant="body2" className="pb-2 fw-500" >
                                    SQL Pattern
                                </Typography>
                                <QueryBuilderComponent
                                    data={pattern?.pattern?.sql_pattern ?? {}}
                                    isNew={Boolean(!selectedItem)}
                                    editMeasure={(type, value) => handleChange('sql_pattern', value)}
                                    isPattern
                                    isDisabled={pattern.is_default || false}
                                    propertyPermission={{ "is_edit": !pattern.is_default }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.switchContainer}>
                            <FormLabel>
                                Is Valid
                            </FormLabel>
                            <SwitchComponent
                                size="small"
                                checked={pattern.is_valid}
                                handleChange={(value) => handleChange("is_valid", value)}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="mr-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={onClose}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </ValidatorForm>
        </PopOverComponent>
    );
}

// default props
AddPattern.defaultProps = {
    classes: {},
    anchorEl: null,
    selectedItem: null,
    onClose: () => { },
    onSave: () => { },
    activePatterns: [],
    connectionType: "",
    valueType: false,
    title: ""
};

// prop types
AddPattern.propTypes = {
    classes: PropTypes.object,
    anchorEl: PropTypes.node,
    selectedItem: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    connectionType: PropTypes.string,
    valueType: PropTypes.string,
    title: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...PatternAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AddPattern);