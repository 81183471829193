// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Create New Glossary
 * @param {*} params
 * @returns
 */
async function createGlossary(params) {
    const form_data = new FormData();
    for (const param of Object.keys(params)) {
        if (params[param]) {
            form_data.append(param, params[param]);
        }
    }
    const response = await postRequest(urlConstants.semantic.glossary.url, form_data, true);
    return response;
}

/**
 * Get Glossary
 * @returns
 */
async function getGlossary() {
    const response = await getRequest(urlConstants.semantic.glossary.url);
    return response;
}

/**
 * Update Glossary
 * @param {*} glossaryId
 * @param {*} params
 * @returns
 */
async function updateGlossary(glossaryId, params) {
    const response = await putRequest(`${urlConstants.semantic.glossary.url}${glossaryId}/`, params);
    return response;
}


/**
 * Delete Glossary
 * @param {*} glossaryId
 * @returns
 */
async function deleteGlossary(glossaryId) {
    const response = await deleteRequest(`${urlConstants.semantic.glossary.url}${glossaryId}/`);
    return response;
}


/**
 * Update Theme Logo
 * @param {*} params
 * @returns
 */
async function updateGlossaryImage(params) {
    const form_data = new FormData();
    form_data.append("image", params.image);
    const response = await putRequest(`${urlConstants.semantic.glossary.image}${params.id}/`, form_data, true);
    return response;
}

/**
 * Get Statistics
 * @param {*} params
 * @returns
 */
async function getStatistics(params) {
    const queryParams = [];
    for (const param of Object.keys(params)) {
        if (params[param]) {
            queryParams.push(`${param}=${params[param]}`);
        }
    }
    const queryString = queryParams.join('&');
    const response = await getRequest(`${urlConstants.semantic.glossary.statistics}?${queryString}`);
    return response;
}


/**
 * Get Statistics
 * @param {*} params
 * @returns
 */
async function getDetail(id) {
    const response = await getRequest(`${urlConstants.semantic.glossary.url}${id}/`);
    return response;
}

/**
 * Create New Category
 * @param {*} params
 * @returns
 */
async function createCategory(params) {
    const response = await postRequest(`${urlConstants.semantic.category.url}`, params);
    return response;
}

/**
 * Create New Term
 * @param {*} params
 * @returns
 */
async function updateCategory(id, params) {
    const response = await putRequest(`${urlConstants.semantic.category.url}${id}/`, params);
    return response;
}

/**
 * Create New Term
 * @param {*} params
 * @returns
 */
async function deleteCategory(id) {
    const response = await deleteRequest(`${urlConstants.semantic.category.url}${id}/`);
    return response;
}


/**
 * Create New Term
 * @param {*} params
 * @returns
 */
async function createTerm(params) {
    const response = await postRequest(urlConstants.semantic.terms.url, params);
    return response;
}
/**
 * Create New Term
 * @param {*} params
 * @returns
 */
async function updateTerm(id, params) {
    const response = await putRequest(`${urlConstants.semantic.terms.url}${id}/`, params);
    return response;
}

/**
 * Create New Term
 * @param {*} params
 * @returns
 */
async function deleteTerm(id) {
    const response = await deleteRequest(`${urlConstants.semantic.terms.url}${id}/`);
    return response;
}

/**
 * Get Category Detail
 * @param {*} id
 * @returns
 */
async function getCategoryDetail(id) {
    const response = await getRequest(`${urlConstants.semantic.category.url}${id}/`);
    return response;
}

/**
 * Get Tree
 * @returns
 */
async function getTree() {
    const response = await getRequest(urlConstants.semantic.glossary.tree);
    return response;
}

/**
 * Get Tree
 * @returns
 */
async function getDomainList() {
    const response = await getRequest(urlConstants.semantic.glossary.domainList);
    return response;
}

/**
 * Get DomainListFilter
 * @returns
 */
async function getDomainListFilter() {
    const response = await getRequest(urlConstants.semantic.glossary.domainListFilter);
    return response;
}

/**
 * Export All Service Functions
 */
export const semanticService = {
    createGlossary, getGlossary, deleteGlossary, updateGlossary, updateGlossaryImage, getStatistics, getDetail, createCategory,
    updateCategory, deleteCategory, createTerm, updateTerm, deleteTerm, getCategoryDetail, getTree, getDomainList, getDomainListFilter
};