import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

//import Componetns
import ImageCropper from '../../../../components/imageCrop/ImageCropper.jsx';
import { LoaderComponent, PreviewComponent, SliderComponent } from '../../../../components/index';

//import Styles
import AttachmentStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//import Images
import { PhotoAdd, ChevLeftIcon, ChevRightIcon, DeleteIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { createAttachmentRequest, getAttachmentRequest, removeAttachmentRequest } from '../../../../redux/reducer/issuesReducer';


function Attachment(props) {

    /**
     * Define Props
     */
    const { classes, disabled } = props;
    const dispatch = useDispatch();
    const containerRef = useRef();

    /**
     * Define State
     */
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);
    const [carouselRef, setCarouselRef] = useState(null);
    const [openPreview, setOpenPreview] = useState({
        open: false,
        imageURL: ''
    });
    const [dragging, setDragging] = useState(false);

    /**
     * Define Redux Store
     */
    const { id: issueId, attachments } = useSelector((state) => state.issues);
    const { user } = useSelector((state) => state.auth);

    /**
     * Handle Carosel Event
     * @param {*} slider
     */
    const setCarouselReference = (slider) => {
        setCarouselRef(slider);
    };


    //img upload
    const handleImgUpload = () => {
        document.getElementById('domainImage').click();
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * onClose Image Upload
     */
    const onClose = () => {
        setImageDialog(false);
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage) => {
        const requestParams = {
            issue: issueId,
            url: croppedImage,
            organization: user.organization.id,
            created_by: user.id
        };
        dispatch(createAttachmentRequest(requestParams));
        onClose();
    };

    /**
     * Get Attachment
     */
    useEffect(() => {
        if (issueId) {
            dispatch(getAttachmentRequest(issueId));
        }
    }, [issueId]);

    const width = containerRef.current ? containerRef.current.clientWidth : 0;

    /**
     * Hangle Preview Attachement
     */
    const handlePreview = (event, url) => {
        if (dragging) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            setOpenPreview({ open: !openPreview?.open ?? false, imageURL: url });
        }
    };

    /**
     * Handle Remove Attachment
     * @param {*} id
     */
    const removeAttachment = (id) => {
        dispatch(removeAttachmentRequest(id));
    };

    return (
        <Grid className={classes.attachmentContaier} ref={containerRef}>
            <Grid className="dflex alignFlexStart spaceBetween">
                <Typography variant="h5">
                    Attachments
                </Typography>
                <Grid sx={{ display: 'none !important' }}>
                    <input
                        accept="image/*"
                        id="domainImage"
                        type="file"
                        onChange={handleSelectFile}
                    />
                </Grid>
                {
                    !disabled &&
                    <Tooltip arrow title="Upload Image">
                    <IconButton onClick={handleImgUpload}>
                        <PhotoAdd />
                    </IconButton>
                    </Tooltip>
                }
            </Grid>
            <Grid className={classes.attachmentImg}>
                {
                    attachments.length ?
                        <React.Fragment>
                            {
                                attachments.length >= 2 &&
                                <Grid item className="sliderArrows">
                                    <IconButton onClick={() => carouselRef.slickPrev()}>
                                        <ChevLeftIcon />
                                    </IconButton>
                                    <IconButton onClick={() => carouselRef.slickNext()}>
                                        <ChevRightIcon />
                                    </IconButton>
                                </Grid>
                            }
                            <SliderComponent setCarouselRef={setCarouselReference} featureSlider variableWidth handleDrag={(drag) => setDragging(drag)}>
                                {
                                    width > 0 && attachments.map((attachment, index) =>
                                        <Grid className="imgWrapper" onClick={(event) => { handlePreview(event, attachment.url); }} key={`attachment_${index}`}>
                                            {
                                                !attachment.issue_image && !disabled &&
                                                <IconButton className={classes.deleteIcon} onClick={(event) => { removeAttachment(attachment.id); event.stopPropagation(); }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                            <img style={{ width: `${width}px` }} key={`attachment_${index}`} src={attachment.url} alt="Attachment Img" className="attachmentImg" />
                                        </Grid>
                                    )
                                }

                            </SliderComponent>
                        </React.Fragment> :
                        <Grid className={classes.loader}>
                            <LoaderComponent loaderType="attachment" height="calc(100vh - 505px)" />
                        </Grid>
                }

            </Grid>
            {
                openImageDialog && (
                    <ImageCropper
                        open={openImageDialog}
                        onClose={(image) => onClose(image)}
                        imageToCrop={imageToCrop}
                        aspect={16 / 9}
                        onImageCropped={
                            (croppedImage) =>
                                updateCropImage(croppedImage)
                        }
                        freeTransform
                        width={100}
                        height={100}
                    />
                )
            }
            {
                openPreview && openPreview.open &&
                <PreviewComponent
                    open={openPreview.open}
                    handleClosePreview={handlePreview}
                    imageURL={openPreview.imageURL}
                />
            }
        </Grid >
    );
}

// default props
Attachment.defaultProps = {
    classes: {},
    disabled: false
};

// prop types
Attachment.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...AttachmentStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Attachment);