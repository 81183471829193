import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButton, ToggleButtonGroup, Typography, IconButton, Button, Tooltip } from '@mui/material';
import { BarChart, Timeline } from '@mui/icons-material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

// Import Component
import { CountChartComponent, AutoCompleteComponent } from '../index';

// Import Image
import { DangerIcon } from '../../assets/svg';
import MeasuresStyle from './style.jsx';

// Import Constant
import appConstants from '../../constants/appConstants';
import { updateMeasureType } from '../../redux/reducer/measureReducer';


function MeasureQualityHistory(props) {

    /**
     * Define Props
     */
    const { classes, data, onMarkAsResolved, onChangeFilter, highlightIndex, length_enum_dropdown,
        length_enum_history, behavioral_history, onHandleValueChange, handleBehaviourlEvent } = props;

    /**
     * Define State
     */
    const [chartType, setChartType] = useState('line');

    const dispatch = useDispatch();

    const measureType = useSelector((state) => state.measure.measureType);

    return (
        <Fragment>
            {
                data.name !== "length" && data.name !== "pattern" &&
                <Grid container justifyContent={'space-between'} alignItems="center" className={'mb-24'}>
                    {
                        data.category !== "comparison" ?
                        <Grid item xs={6}>
                            {
                                data.history_data && data.history_data.length > 0 && data.history_data[data.history_data.length - 1].status && data.history_data[data.history_data.length - 1].status?.toLowerCase() !== appConstants.drift.ok &&
                                <Grid className={`${classes.failedText} ${data.history_data[data.history_data.length - 1]?.status?.toLowerCase() ?? 'ok'}`}>
                                    <DangerIcon />
                                    <Grid className={'failedTextDesc'}>
                                        <Typography variant="body1" className="fw-500 title" align="left">
                                            {data.history_data[data.history_data.length - 1].message ? data.history_data[data.history_data.length - 1].message : `${data.name + " value percentage exceed the threshold limit"}`}
                                        </Typography>
                                        <Typography variant="body1" align="left">
                                            {`Automatically detected drift/anomalous using ${data.name} count and % change`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid> :
                        <Grid item xs={4}>
                            {
                                data.history_data && data.history_data.length > 0 && data.history_data[data.history_data.length - 1].status && data.history_data[data.history_data.length - 1].status?.toLowerCase() !== appConstants.drift.ok &&
                                <Grid className={`${classes.failedText} ${data.history_data[data.history_data.length - 1]?.status?.toLowerCase() ?? 'ok'}`}>
                                    <DangerIcon />
                                    <Grid className={'failedTextDesc'}>
                                        <Typography variant="body1" className="fw-500 title" align="left">
                                            {data.history_data[data.history_data.length - 1].message ? data.history_data[data.history_data.length - 1].message : `${data.name + " value percentage exceed the threshold limit"}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    <Grid item className="dflex alignCenter">
                        {
                            length_enum_dropdown && length_enum_history && length_enum_dropdown.length > 0 && data.category !== "comparison" &&
                            <Grid item className="dflex alignCenter mr-2" style={{ minWidth: "200px" }}>
                                <AutoCompleteComponent
                                    name="Value"
                                    value={(data?.base_measure_name ? data?.base_measure_name : data?.measure_name) || ''}
                                    data={length_enum_dropdown}
                                    size={'small'}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event, newValue) => onHandleValueChange(newValue)}
                                    placeholder="Select value"
                                />
                            </Grid>
                        }
                        {
                            length_enum_dropdown && length_enum_history && length_enum_dropdown.length > 0 && data.category === "comparison" &&
                            <Grid item className="dflex alignCenter mr-2" style={{ minWidth: "500px" }}>
                                <Tooltip title={(data?.base_measure_name ? data?.base_measure_name : data?.measure_name) || ''}>
                                    <AutoCompleteComponent
                                        name="Value"
                                        value={(data?.base_measure_name ? data?.base_measure_name : data?.measure_name) || ''}
                                        data={length_enum_dropdown}
                                        size={'small'}
                                        variant="outlined"
                                        renderType={'comparison'}
                                        fullWidth
                                        onChange={(event, newValue) => onHandleValueChange(newValue)}
                                        placeholder="Select value"
                                    />
                                </Tooltip>
                            </Grid>
                        }
                        {
                            behavioral_history && behavioral_history.length > 0 &&
                            <Grid item className="dflex alignCenter flexEnd mr-2" style={{ minWidth: "200px" }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    size="small"
                                    onClick={handleBehaviourlEvent}
                                >
                                    {'View Category'}
                                </Button>
                            </Grid>
                        }
                        {
                            data.name === "Volume" &&
                            <Grid item className="dflex alignCenter mr-2">
                            <ToggleButtonGroup
                                value={measureType || ''}
                                exclusive
                                onChange={(event, newValue) => dispatch(updateMeasureType(newValue))}
                                aria-label="text alignment"
                                className={classes.daysFilter}
                            >
                                <ToggleButton
                                    value={"Total Rows"}
                                    aria-label="left aligned"
                                >
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        Value
                                    </Typography>
                                </ToggleButton>
                                <ToggleButton
                                    value={"%Growth"}
                                    aria-label="centered"
                                >
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        Percentage
                                    </Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            </Grid>
                        }
                        <Grid item className="dflex alignCenter mr-2">
                            <IconButton
                                className={`${classes.chartIconClass} chartIconClass`}
                                key="chartType"
                                color="inherit"
                                aria-label="Chart Type"
                                onClick={() => { setChartType(chartType === 'bar' ? 'line' : 'bar'); }}
                            >
                                {
                                    chartType === 'bar' ?
                                        <Tooltip title="Line chart">
                                            <Timeline className="chartIcon" />
                                        </Tooltip> :
                                        <Tooltip title="Bar chart">
                                            <BarChart className="chartIcon" />
                                        </Tooltip>
                                }
                            </IconButton>
                        </Grid>
                        <ToggleButtonGroup
                            value={data.dayFilter || 7}
                            exclusive
                            onChange={(event, value) => onChangeFilter(value)}
                            aria-label="text alignment"
                            className={classes.daysFilter}
                        >
                            <ToggleButton
                                value={7}
                                aria-label="left aligned"
                            >
                                <Typography variant="body2" className={classes.textSecondary}>
                                    7d
                                </Typography>
                            </ToggleButton>
                            <ToggleButton
                                value={14}
                                aria-label="centered"
                            >
                                <Typography variant="body2" className={classes.textSecondary}>
                                    14d
                                </Typography>
                            </ToggleButton>
                            <ToggleButton
                                value={30}
                                aria-label="right aligned"
                            >
                                <Typography variant="body2" className={classes.textSecondary}>
                                    30d
                                </Typography>
                            </ToggleButton>
                            <ToggleButton
                                value={"All"}
                                aria-label="right aligned"
                            >
                                <Typography variant="body2" className={classes.textSecondary}>
                                    All
                                </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            }
            <Grid>
                <CountChartComponent
                    ruleName={data.name}
                    ruleCategory={data.category}
                    rule={data}
                    data={data.history_data || []}
                    onMarkAsResolved={onMarkAsResolved}
                    chartType={chartType}
                    highlightIndex={highlightIndex}
                    measureType={measureType}
                />
            </Grid>
        </Fragment>

    );
}

// default props
MeasureQualityHistory.defaultProps = {
    classes: {},
    data: {},
    highlightIndex: -1,
    length_enum_history: [],
    length_enum_dropdown: [],
    behavioral_history: [],
    onMarkAsResolved: () => { },
    onChangeFilter: () => { },
    onHandleValueChange: () => { },
    handleBehaviourlEvent: () => { }
};

// prop types
MeasureQualityHistory.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    highlightIndex: PropTypes.number,
    length_enum_dropdown: PropTypes.array,
    length_enum_history: PropTypes.array,
    behavioral_history: PropTypes.array,
    onMarkAsResolved: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onHandleValueChange: PropTypes.func,
    handleBehaviourlEvent: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...MeasuresStyle(theme)
    }),
    { withTheme: true }
)(React.memo(MeasureQualityHistory, areEqual));