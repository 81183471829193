import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Components
import { AutoCompleteComponent, ChipComponent, DialogComponent } from '../../../../../components/index.js';

//Import Icons
import { CloseIcon, CopyIcon, DeleteIcon, EditIcon, NoNotificationIcon, NotificationIcon, PlayIcon2 } from '../../../../../assets/svg/index.js';
import { PauseCircleOutlineRounded } from '@mui/icons-material';

// Import Helpers
import { toRound } from '../../../../../helpers/appHelpers.js';

// Import Actions
import { getDimensionRequest } from '../../../../../redux/reducer/dimensionReducer';
import { deleteMeasureRequest, updateMeasureRequest, deleteAssetMeasureRequest, getMeasureRunStatusSuccess } from '../../../../../redux/reducer/measureReducer.js';
import { updateMetricProperty, updateMeasureRunStatus } from '../../../../../redux/reducer/metricReducer';
import { navigate } from '../../../../../redux/reducer/navigationReducer.js';

// Import Service
import { scheduleService } from '../../../../../redux/service/scheduleService.js';
import { measureService } from '../../../../../redux/service/measureService.js';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';
import AlertShare from '../../../../asset/overview/components/alerts/alertShare.jsx';

// import Styles
import LayoutStyles from '../../../../../layouts/style.jsx';
import { displyAlert } from '../../../../../redux/reducer/alertReducer.js';


function QualityHeader(props) {

    /**
     * Define Props
     */
    const {
        classes, data, handleMeasureQualityDialog, editMeasure, showEdit, propertiesPermission,
        publishPermission, approvePermission, takeScreenshot, alertScreenShot, measureCategory,
        selectedMeasures, onChangeMeasure, theme
    } = props;
    const dispatch = useDispatch();
    let statusChecker = null;


    /**
     * Redux Store
     */
    const searchableDimensionList = useSelector((state) => state.dimension.searchableDimensionList, shallowEqual);
    const selectedAttribute = useSelector((state) => state.attribute.detail, shallowEqual);

    /**
     * Define State
     */
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.measureDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        handleMeasureQualityDialog();
        if (data.level === "measure") {
            dispatch(deleteAssetMeasureRequest({ id: data.measure_id, type: (type === "purge") }));
        } else {
            dispatch(deleteMeasureRequest({ id: data.measure_id, type: (type === "purge") }));
        }
        dialogCancelEventHandle();
    };

    /**
     * Prepare Dimension
     * @param {*} dimensionId
     * @returns
     */
    const prepareDimension = (dimensionId) => {
        return dimensionId ? searchableDimensionList.filter((data) => data.id === dimensionId) : [];
    };

    const onChangeNotificationAlert = () => {
        const requestParams = {
            is_notification: !data.is_notification
        };
        dispatch(updateMeasureRequest({ ...requestParams, id: data.measure_id }));
        dispatch(updateMetricProperty(requestParams));
    };

    /**
     * Get Dimension List
     */
    useEffect(() => {
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
    }, [dispatch]);


    /**
     * Navigate to Specific asset
     */
    const navigation = () => {
        if (data.attribute_id) {
            dispatch(navigate({ path: 'assets.attributeProperties', state: { section: "measure" }, params: [data.asset_id, data.attribute_id] }));
        } else {
            dispatch(navigate({ path: 'assets.properties', state: { section: "measure" }, params: [data.asset_id] }));
        }
        handleMeasureQualityDialog();
    };

    const checkIsPositive = (selectedData) => {
        let isPositive = selectedData?.is_positive || false;
        const technicalName = selectedData?.technical_name || "";
        if (appConstants.profile.frequencyMeasures.indexOf(technicalName) > -1) {
            let selectedItem = null;
            const measureName = selectedData?.measure_name || "";
            switch (technicalName) {
                case appConstants.profile.frequencyMeasures[0]:
                    selectedItem = selectedAttribute?.universal_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[1]:
                    selectedItem = selectedAttribute?.short_universal_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[2]:
                    selectedItem = selectedAttribute?.user_defined_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[3]:
                    selectedItem = selectedAttribute?.length_distribution?.find((item) => item.length_value?.toString() === measureName?.toString());
                    break;
                case appConstants.profile.frequencyMeasures[4]:
                    selectedItem = selectedAttribute?.value_distribution?.find((item) => item.enum_value?.toString() === measureName?.toString());
                    break;
                default:
                    selectedItem = null;
                    break;
            }
            isPositive = selectedItem?.is_valid || false;
        }
        return isPositive;
    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (status) => {
        if (status === 'Completed') {
            scheduleService.trigger({ "measure_id": data.id || data.measure_id, job_type: "measure" }).then(() => {
                dispatch(updateMeasureRunStatus({ data: { run_status: "Pending" } }));
                const measureStatus = [{ id: data.id || data.measure_id, status: "Pending" }];
                dispatch(getMeasureRunStatusSuccess({ data: measureStatus }));
            }).catch(() => { });
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data.connection_id || data.connection] }));
        }
    };

    /**
     * Check measure dag running status
     * @param {*} data
     * @returns
     */
    const checkStatus = (data) => {
        if (data.run_status === 'Completed') {
            return;
        }
        measureService.getMeasureStatus(data.id).then((res) => {
            dispatch(updateMeasureRunStatus(res.data));
        });
    };

    /**
     *
     * Define Use Effects for dag run status checker
     */
    useEffect(() => {
        if (data.run_status && !statusChecker && data.level === "measure") {
            statusChecker = setInterval(() => checkStatus(data), appConstants.statusCheckInterval);
        }
        return () => {
            if (statusChecker) {
                clearInterval(statusChecker);
                statusChecker = null;
            }
        };
    }, [data.run_status]);

    /**
     * Copy the asset id to clip board
     */
    const copyToClipBoard = () => {
        navigator.clipboard.writeText(data.id || data.measure_id);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };


    return (
        <ValidatorForm onSubmit={() => { }} className={classes.dialogHeader}>
            <Grid
                container
                justifyContent="space-between"
                alignItems={'baseline'}
            >
                <Grid item xs={6}>
                    <Typography variant="body1" className="capitalize mr-2 pb5 pointer" onClick={() => data.asset_id && navigation()}>
                        {data.attribute_name || ''}
                    </Typography>
                    <Grid item className="dflex alignCenter">
                        <Typography variant="h2" className="capitalize mr-3 pb5 pointer" onClick={() => data.asset_id && navigation()}>
                            {measureCategory ? `${measureCategory} - ` : ""}
                            {data.name || ''}
                        </Typography>
                        {
                            data.show_measure_name &&
                            <Typography variant="h2" className="capitalize mr-2 pb5">
                                {data.measure_name || ''}
                            </Typography>
                        }
                        <Grid
                            item
                            className={`${classes.countChips} ${classes.muiChip} dflex`}
                        >
                            {
                                (data.id || data.measure_id) &&
                                <Tooltip title={"Copy Measure Id"}>
                                    <IconButton className="copyIcon" onClick={() => copyToClipBoard()}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                data.allow_score &&
                                <ChipComponent chipClass="grey sm mr-15" toolTipName={"Weightage"} data={[data.weightage || 0]} />
                            }
                            {
                                data.allow_score &&
                                <ChipComponent chipClass="success sm mr-15" toolTipName={"Valid Records"} data={[(checkIsPositive(data) ? data.valid_count : data.invalid_count) || 0]} />
                            }
                            {
                                data.allow_score &&
                                <ChipComponent chipClass="danger sm mr-15" toolTipName={"Invalid Records"} data={[(checkIsPositive(data) ? data.invalid_count : data.valid_count) || 0]} />
                            }
                            {
                                data.allow_score &&
                                <Fragment>
                                    <ChipComponent chipClass="grey sm mr-15" toolTipName="Total Records" data={[data.total_count || 0]} />
                                    <ChipComponent chipClass="success sm mr-15" toolTipName={`Score ${data.score}`} data={[`${toRound(data.score)}%`]} />
                                </Fragment>
                            }
                            {
                                data.enable_pass_criteria && data.result &&
                                <Grid className={classes.passFailContainer}>
                                    <ChipComponent chipClass={`${data.result === 'Pass' ? 'success' : 'danger'} sm mr-15`} data={[data.result]} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Typography variant="body1">
                        {data.description || ""}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.actions}>
                    <Grid className="dflex alignCenter flexEnd">
                        {
                            selectedMeasures?.length > 0 &&
                            <Grid item className="dflex alignCenter mr-2" style={{ minWidth: "200px" }}>
                                <AutoCompleteComponent
                                    name="Measure"
                                    value={data?.name ?? ''}
                                    data={selectedMeasures || []}
                                    size={'small'}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event, newValue) => onChangeMeasure(newValue)}
                                    placeholder="Select measure"
                                />
                            </Grid>
                        }
                        <Grid item className={classes.dimensionChip}>
                            <ChipComponent
                                data={prepareDimension(data.dimension_id)}
                                labelKey="name"
                                haveColor
                            />
                        </Grid>
                        {
                            showEdit && (propertiesPermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit) &&
                            <Tooltip title="Edit Measure">
                                <IconButton onClick={() => editMeasure()}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            data.level === "measure" && showEdit && (propertiesPermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit) &&
                            <Tooltip title={data?.run_status === 'Completed' ? "Run Now" : data?.run_status ?? "Pending"}>
                                <span>
                                    <IconButton onClick={() => handleJobTrigger(data?.run_status)} disabled={!data.id && !data.measure_id}>
                                        {
                                            data?.run_status === 'Completed' ?
                                                <PlayIcon2 />
                                                : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />
                                        }
                                    </IconButton>
                                </span>
                            </Tooltip>
                        }
                        {
                            showEdit && (propertiesPermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit) &&
                            <Tooltip title={data.is_notification ? 'Mute Notification' : 'Unmute Notification'}>
                                <IconButton onClick={() => onChangeNotificationAlert()} >
                                    {
                                        data.is_notification ?
                                            <NotificationIcon /> :
                                            <NoNotificationIcon />
                                    }
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            !data.is_default && propertiesPermission?.is_edit &&
                            <Tooltip title="Delete Measure">
                                <IconButton onClick={() => onDelete()}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            data && data?.history_data && data?.history_data?.length > 0 &&
                            <Grid item onClick={(event) => { event.stopPropagation(); }}>
                                <AlertShare takeScreenshot={takeScreenshot} alertScreenShot={alertScreenShot} data={data} />
                            </Grid>
                        }
                        <IconButton className="" onClick={() => handleMeasureQualityDialog()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    showDialog.open &&
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle} />
                }
            </Grid>
        </ValidatorForm >
    );
}

// default props
QualityHeader.defaultProps = {
    classes: {},
    data: {},
    showEdit: true,
    handleMeasureQualityDialog: () => { },
    editMeasure: () => { },
    propertiesPermission: {},
    publishPermission: {},
    approvePermission: {},
    alertScreenShot: {},
    takeScreenshot: () => { },
    measureCategory: "",
    selectedMeasures: [],
    onChangeMeasure: () => { },
    theme: {}
};

// prop types
QualityHeader.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    showEdit: PropTypes.bool,
    handleMeasureQualityDialog: PropTypes.func,
    editMeasure: PropTypes.func,
    propertiesPermission: PropTypes.object,
    publishPermission: PropTypes.object,
    approvePermission: PropTypes.object,
    alertScreenShot: PropTypes.object,
    takeScreenshot: PropTypes.func,
    measureCategory: PropTypes.string,
    selectedMeasures: PropTypes.array,
    onChangeMeasure: PropTypes.func,
    theme: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.thumbnail, nextProps.thumbnail) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.alertScreenShot, nextProps.alertScreenShot);
}

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(QualityHeader, areEqual));