import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography, FormLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

//  Import Styles
import RuleHeaderStyle from './style.jsx';
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Import Components
import {
    TextBoxComponent,
    SwitchComponent,
    NumberInputComponent,
    AutoCompleteComponent
} from '../../../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../../../constants/appConstants';

// Import Images
import { CloseIcon } from '../../../../../../../assets/svg/index.js';

function RuleHeader(props) {

    /**
     * Define Rules
     */
    const { data, closeMeasureAdd, editMeasure, classes, semanticsPermission } = props;

    /**
     * Get Dimension List
     */
    const { searchableDimensionList } = useSelector((state) => state.dimension);

    const sorted_dimension = [...searchableDimensionList].sort((a, b) => a.name.localeCompare(b.name));

    const createDimensionName = (dimension_id) => {
        const selected_dimension = searchableDimensionList.find((obj) => obj.id === dimension_id);
        return selected_dimension?.name || '';
    };


    return (

        <Grid item xs={12} key={'rule-header-component'}>
            <Grid container justifyContent={'space-between'} className="mb-2">
                <Grid item>
                    <Typography variant="h4" className="pb5">
                        {data.id ? 'Edit a Measure' : 'Create a Measure'}
                    </Typography>
                    <Typography variant="body1">
                        Custom Measure
                    </Typography>
                </Grid>
                <Grid item>
                    <SwitchComponent size="small" disabled={!semanticsPermission?.is_edit} label={'Active'} checked={data.is_active || false} handleChange={(value) => editMeasure('is_active', value)} />
                    <IconButton
                        className={classes.closeIcon}
                        onClick={closeMeasureAdd}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={4}>
                    <TextBoxComponent
                        name="name"
                        fullWidth
                        variant="standard"
                        label={
                            <span className="requiredLabel">
                                Measure Name
                                <span className="requiredstar">
                                    *
                                </span>
                            </span>
                        }
                        value={data.name}
                        onChange={(event) => editMeasure(event.target.name, event.target.value)}
                        validators={['required']}
                        errorMessages={[appConstants.errorMessages.nameRequired]}
                        disabled={data.is_default || !semanticsPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={8} className="pl-8">
                    <TextBoxComponent
                        name="description"
                        label="Description"
                        fullWidth
                        variant="standard"
                        value={data.description}
                        onChange={(event) => editMeasure(event.target.name, event.target.value)}
                        disabled={data.is_default || !semanticsPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutoCompleteComponent
                        freeSolo={false}
                        name="dimension"
                        variant="standard"
                        fullWidth
                        selectedValue={createDimensionName(data?.dimension) ?? 'null'}
                        data={sorted_dimension || []}
                        placeholder="Select Dimension"
                        onChange={(event, newValue) => editMeasure('dimension', newValue?.id)}
                    />
                </Grid>
                <Grid item xs={8} className="dflex pl-8 alignCenter">
                    {
                        data.category !== "behavioral" &&
                        <Grid className={`mr-4 dflex alignCenter ${classes.weightageContainer}`}>
                            <FormLabel className="pr-1">
                                Weightage
                            </FormLabel>
                            <NumberInputComponent
                                value={data?.weightage ?? 100}
                                valOnChange
                                onChange={(value) => editMeasure('weightage', value)}
                                validators={['required', 'minNumber:1', 'maxNumber:100']}
                                errorMessages={['Weightage is required', 'Min weightage is 1', 'Max weightage is 100']}
                                min="1"
                                max="100"
                                integeronly={false}
                                disabled={!semanticsPermission?.is_edit || !data.allow_score}
                            />
                        </Grid>
                    }

                    <Grid className="mr-4">
                        <SwitchComponent size="small" label={'Valid'} disabled={!semanticsPermission?.is_edit} checked={data.is_positive || false} handleChange={(value) => editMeasure('is_positive', value)} />
                    </Grid>
                    <Grid className="mr-4">
                        <SwitchComponent size="small" label={'Scoring'} disabled={!semanticsPermission?.is_edit} checked={data.allow_score || false} handleChange={(value) => editMeasure('allow_score', value)} />
                    </Grid>
                    <Grid>
                        <SwitchComponent size="small" label={'Monitor'} disabled={!semanticsPermission?.is_edit} checked={data.is_drift_enabled || false} handleChange={(value) => editMeasure('is_drift_enabled', value)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
RuleHeader.defaultProps = {
    data: {},
    closeRuleEdit: () => { },
    editMeasure: () => { },
    semanticsPermission: {}
};

// prop types
RuleHeader.propTypes = {
    data: PropTypes.object,
    closeMeasureAdd: PropTypes.func,
    editMeasure: PropTypes.func,
    classes: PropTypes.object,
    semanticsPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...RuleHeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(RuleHeader, areEqual));