import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { Grid } from '@mui/material';
import DOMPurify from 'dompurify';

//  Import Styles
import PreviewStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

// import Actions
import { runQueryRequest } from '../../../../../redux/reducer/usageReducer';


function PreviewQueryResult(props) {

    /**
     * Define Props
     */
    const { classes, asset_id, query } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { query_result } = useSelector((state) => state.usage);

    /**
     * Html Clean
     */
    const cleanHtmlContant = (c_query) => {
        const htmlEntities = /&(lt|gt);/;
        const isHtmlEntityUsed = htmlEntities.test(c_query);
        if (isHtmlEntityUsed) {
            return c_query.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        }
        return c_query;
    };

    /**
     * Get Usage
     */
    useEffect(() => {
        if (asset_id && query) {
            let f_query = DOMPurify.sanitize(query, { USE_PROFILES: { html: false } });
            f_query = cleanHtmlContant(f_query);
            dispatch(runQueryRequest({ asset_id, "query": f_query }));
        }
    }, [asset_id]);


    /**
     * Prepare Filter PreviewQueryResult Result
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = typeof (item[key]) === "number" ? item[key].toString() : item[key];
                    if (typeof (value) === 'string' && !value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };


    /**
     * Set Search Data
     */
    useEffect(() => {
        const headers = query_result?.headers ?? [];
        if (headers && headers.length) {
            const searchHeaders = {};
            for (const header of headers) {
                searchHeaders[header.name] = "";
            }
            setSearchData({ ...searchHeaders });
        }
    }, [query_result]);


    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(query_result?.data ?? [], searchData, sorting.sortBy, sorting.orderBy), [query_result?.data ?? [], searchData, sorting.sortBy, sorting.orderBy]);


    return (
        <Grid item xs={12} className={classes.PreviewContainer} >
            <TableComponent
                headers={query_result?.headers ?? []}
                stickyHeader
                data={filterData}
                title={appConstants.labels.usage.preview}
                options={appConstants.tableOptions.download}
                searchData={searchData}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                styleType="striped"
                onHandleSearchEvent={onHandleSearchEvent}
                onClickSorting={onClickSorting}
                NoResultText="No Records Found"
                height="41vh"
                isLoading={query_result.isLoading}
                exportParams={
                    {
                        data: filterData || [],
                        fileName: "PreviewQueryResult.csv",
                        headers: query_result?.headers?.map((obj) => obj.key) || []
                    }
                }
                textTransform={false}
            />
        </Grid>
    );
}

// default props
PreviewQueryResult.defaultProps = {
    classes: {},
    asset_id: '',
    query: ''
};

// prop types
PreviewQueryResult.propTypes = {
    classes: PropTypes.object,
    asset_id: PropTypes.string,
    query: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...PreviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(PreviewQueryResult);