import React, { useEffect, useMemo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Grid, IconButton, Typography, Button, Slide, FormHelperText } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TemplateCard from '../templateCard/index.jsx';
import { SearchComponent, TextBoxComponent, SelectComponent, LoaderComponent } from '../../../../../../components/index.js';

//  Import Styles
import Style from '../../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';


// Import Images
import { CloseIcon, SearchIcon } from '../../../../../../assets/svg/index.js';


// Import Constant
import appConstants from '../../../../../../constants/appConstants';

// Import Actions
import { getWidgetListRequest, createWidgetRequest } from '../../../../../../redux/reducer/dashboardReducer';
import { navigate } from '../../../../../../redux/reducer/navigationReducer.js';


function ManageWidgets(props) {

    const { classes, closeDrawer } = props;
    const dispatch = useDispatch();
    const { list, widgetList, widgetLoading } = useSelector(({ dashboard }) => dashboard);

    /**
     * Define State
     */
    const [searchBox, openSearchBox] = useState(false);
    const [search, setSearch] = useState("");
    const [isAddWidget, setAddWidget] = useState(false);
    const [widgetDetail, setWidgetDetail] = useState({
        name: "", dashboard: ""
    });
    const [isFormSubmit, setFormSubmit] = useState(false);

    /**
     * Reset Form
     */
    const resetForm = () => {
        setWidgetDetail({ name: "", dashboard: "" });
    };

    /**
     * Handle Search
     */
    const handleSearch = () => {
        openSearchBox(!searchBox);
    };

    /**
     * Get Widget List
     */
    useEffect(() => {
        if (!widgetList.length) {
            dispatch(getWidgetListRequest());
        }
    }, []);

    /**
     * Handle New Widget
     * @param {*} type
     */
    const handleNewWidget = (type = "existing") => {
        if (type === "new") {
            resetForm();
        }
        setAddWidget(!isAddWidget);
    };

    /**
     * Create New Widget
     */
    const createWidget = () => {
        setFormSubmit(true);
        if (widgetDetail.dashboard) {
            setFormSubmit(false);
            const widget = widgetList.find((widget) => widget.id === widgetDetail.widget);
            const requestParams = {
                ...widgetDetail,
                default_template: widget.default_template,
                is_template: false,
                is_default: false,
                type: widget.default_template ? "defaultWidget" : "customWidget"
            };
            dispatch(createWidgetRequest(requestParams));
            handleNewWidget();
            closeDrawer();
        }

    };

    /**
     * Handle OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setWidgetDetail({
            ...widgetDetail,
            [property]: value
        });
    };

    /**
     * Select Widget
     * @param {*} widget
     */
    const onSelectWidget = (widget) => {
        setWidgetDetail({
            ...widgetDetail,
            name: widget.name,
            widget: widget.id
        });
        handleNewWidget();
    };

    /**
     * Get Filtered Widgets
     * @param {*} list
     * @param {*} search
     * @returns
     */
    const getFilterWidgets = (list, search) => {
        if (search.trim()) {
            list = list.filter((data) => data.name.toLowerCase().includes(search.toLowerCase()));
        }
        return list;
    };

    const filterWidgets = useMemo(() => getFilterWidgets(widgetList, search), [widgetList, search]);

    return (
        <Grid item xs={12} className={classes.dashboardOption}>
            {
                !isAddWidget ?
                    <Fragment>
                        <Grid item xs={12} className={classes.top}>
                            <Grid item xs={12} className={classes.header}>
                                <Grid className="dflex alignCenter spaceBetween pb5">
                                    <Typography variant="h2" className="">
                                        Manage Widget
                                    </Typography>
                                    <IconButton onClick={closeDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Typography variant="body1">
                                    {appConstants.labels.dashboard.manageWidget}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.body}>
                                <Grid className="dflex alignCenter spaceBetween pb-1">
                                    <Typography variant="h5" className="">
                                        Choose widget from below
                                    </Typography>
                                    <Grid className="dflex alignCenter">
                                        <Collapse orientation="horizontal" in={searchBox}>
                                            <SearchComponent placeholder="Search" value={search} onChange={(value) => setSearch(value)} />
                                        </Collapse>
                                        {
                                            !searchBox &&
                                            <IconButton className={`${classes.searchHeader} p5`} onClick={handleSearch}>
                                                {/* {!searchBox ? <SearchIcon /> : <CloseIcon />} */}
                                                {!searchBox && <SearchIcon />}
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className={classes.cardContainer}>
                                    {
                                        widgetLoading &&
                                        <LoaderComponent />
                                    }
                                    {
                                        filterWidgets.map((item, index) =>
                                            <TemplateCard key={`widget_${index}`} type="widget" data={item} onSelect={(widget) => onSelectWidget(widget)} />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.footer}>
                            <Typography variant="body1">
                                or
                            </Typography>
                            <Button variant="outlined" color="primary" size="small" className="ml-2" onClick={() => dispatch(navigate({ path: 'widget.root', state: {}, params: [] }))}>
                                Create on your own
                            </Button>
                        </Grid>
                    </Fragment> :
                    <Slide direction="left" in={isAddWidget}>
                        <Grid item xs={12}>
                            <Grid item xs={12} className={classes.top}>
                                <Grid item xs={12} className={`${classes.header} pb-1`}>
                                    <Grid className="dflex alignCenter spaceBetween pb5">
                                        <Typography variant="h2" className="">
                                            Create a Widget
                                        </Typography>
                                        <IconButton onClick={closeDrawer}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.body}>
                                    <Grid className={classes.formContainer}>
                                        <ValidatorForm onSubmit={() => createWidget()} noValidate>
                                            <Grid item xs={12}>
                                                <TextBoxComponent
                                                    label="Widget Name"
                                                    variant="standard"
                                                    className="mt-1"
                                                    value={widgetDetail.name || ""}
                                                    onChange={(event) => onChange("name", event.target.value)}
                                                    validators={['required']}
                                                    errorMessages={[appConstants.errorMessages.nameRequired]}
                                                    valOnChange
                                                />
                                            </Grid>
                                            <Grid item xs={12} className="pt-3 pb-2">
                                                <SelectComponent
                                                    value={widgetDetail.dashboard || ""}
                                                    onSelectChange={(value) => onChange('dashboard', value)}
                                                    label="Dashboard"
                                                    variant="standard"
                                                    list={list}
                                                    name="dashboard"
                                                    propertyName="id"
                                                    displayPropertyName="name"
                                                    noOutline />
                                                {
                                                    isFormSubmit && !widgetDetail.dashboard &&
                                                    <FormHelperText error>
                                                        Dashoard is required
                                                    </FormHelperText>
                                                }
                                            </Grid>
                                            <Grid item xs={12} className={`dflex alignCenter spaceBetween pt-2`}>
                                                <Button variant="outlined" color="primary" size="small" onClick={handleNewWidget}>
                                                    Back
                                                </Button>
                                                <Button variant="contained" type="submit" color="primary" size="small" disableElevation>
                                                    Create Widget
                                                </Button>
                                            </Grid>
                                        </ValidatorForm>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Slide>
            }
        </Grid >
    );
}

// default props
ManageWidgets.defaultProps = {
    classes: {},
    closeDrawer: () => { }
};

// prop types
ManageWidgets.propTypes = {
    classes: PropTypes.object,
    closeDrawer: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ManageWidgets);