const style = (theme) => ({
    description: {
        marginBottom: "10px !important",
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #ffffff00 !important'
        }
    },
    topSection: {
        '& .chipContainer': {
            width: '100%'
        }
    },
    valuesPatternList: {
        marginTop: '15px !important',
        maxHeight: '246px',
        overflowY: 'auto',
        paddingRight: '10px',
        '& .MuiTypography-root.listItem': {
            cursor: 'pointer',
            paddingRight: 8,
            width: '100%',
            padding: '4px 0px',
            marginRight: 8,
            '&:hover': {
                transition: '0.5s all',
                background: theme.palette.greyshades.lightestgrey,
                paddingLeft: '5px !important'
            }
        }
    },
    pattenValues: {
        padding: '4px 12px',
        borderRadius: '3px',
        fontSize: 15,
        minWidth: 43,
        fontWeight: 500,
        textAlign: 'center',
        cursor: "pointer",
        '&.valid': {
            color: `${theme.palette.colorThemes.low} !important`,
            background: `${theme.palette.colorThemes.low}30`
        },
        '&.invalid': {
            color: `${theme.palette.colorThemes.high} !important`,
            background: `${theme.palette.colorThemes.high}30`
        },
        '&.disabled': {
            color: `#B0BAC9 !important`,
            background: `#B0BAC930`
        }
    },
    deleteIcon: {
        marginLeft: '12px !important',
        padding: '0px !important',
        '& svg:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    chartIconClass: {
        marginRight: '5px !important',
        padding: '4px !important',
        width: '32px',
        height: '32px',
        '& .chartIcon': {
            fill: `${theme.palette.greyshades.darkgrey} !important`
        },
        '&:last-child': {
            marginRight: '0px !important'
        },
        "&.chartIcon": {
            background: `${theme.palette.greyshades.lightgrey} !important`,
            borderRadius: "4px !important"
        },
        '& svg': {
            fill: `#9faec3 !important`,
            '& path': {
                fill: `#9faec3 !important`
            },
            '&:hover': {
                '& path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '&.selected': {
            '& svg': {
                '& path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        }
    },
    addIcon: {
        padding: '4px !important',
        width: '32px',
        height: '32px',
        '& .PlusIcon:hover': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    valuesListView: {
        minHeight: 150,
        maxHeight: 375,
        overflow: "hidden",
        overflowY: "auto",
        '& .actionContainer': {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        },

        '& .itemContainer': {
            width: "100%",
            marginRight: 8,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            '& .expandIcon': {
                padding: 4,
                paddingRight: 8,
                maxWidth: 26,
                '&.hide': {
                    visibility: "hidden"
                },
                '& .MuiSvgIcon-root': {
                    height: 20,
                    width: 20
                }
            },
            '& .drilldownView': {
                position: "relative",
                width: "100%",
                maxHeight: 28,
                '& .title': {
                    cursor: 'pointer',
                    width: '100%',
                    padding: '4px 4px 4px 8px',
                    background: "transparent",
                    position: "relative",
                    '&:hover': {
                        transition: '0.5s all',
                        paddingLeft: '12px !important'
                    }
                },
                '& .MuiLinearProgress-root': {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: 0,
                    left: 0,
                    '& .MuiLinearProgress-bar': {
                        zIndex: 'inherit'
                    }
                },
                '&:hover': {
                    '& .MuiLinearProgress-root': {
                        background: '#E5E7EB70'
                    }
                }
            },
            '&.subItem': {
                paddingLeft: 25,
                '& .expandIcon': {
                    visibility: "hidden"
                }
            }
        },
        "& div.plusminus": {
            position: "relative",
            width: "12px",
            height: "12px",
            cursor: "pointer",
            "&.active": {
                "&:before": {
                    transform: "translatey(-50%) rotate(-90deg)",
                    opacity: 0
                },
                "&:after": {
                    transform: "translatey(-50%) rotate(0)"
                }
            },
            "&:before, &:after": {
                content: "''",
                display: "block",
                backgroundColor: "#9faec3",
                position: "absolute",
                top: "50%;",
                left: 0,
                transition: ".35s",
                width: "100%",
                height: "2px"
            },
            "&:before": {
                transform: "translatey(-50%)"
            },
            "&:after": {
                transform: "translatey(-50%) rotate(90deg)"
            }
        }
    },
    profileBarChartView: {
        maxHeight: 300,
        overflow: "hidden",
        overflowY: "auto",
        "&.hasheight": {
            overflowY: "hidden"
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '22px !important',
        '& .MuiOutlinedInput-root': {
            maxWidth: 130,
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '6.5px 8px',
            textAlign: 'center',
            fontSize: 15
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        },
        '& .rangeLabel': {
            textTransform: "capitalize",
            marginRight: 12,
            color: "rgba(0, 0, 0, 0.38)",
            fontWeight: 400,
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontSize: theme.palette.headers.body1.size,
            fontStyle: theme.palette.headers.body1.style,
            textDecoration: theme.palette.headers.body1.textDecoration
        }
    },
    dataContainer: {
        position: "relative",
        minHeight: '180px',
        '& .profileChart': {
           '& svg path': {
                width: "100%"
           },
           '& svg text': {
            textTransform: 'none !important'
            }
        },
        '& .noResulteContainer': {
            position: "absolute",
            top: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            background: "white"
        }
    },
    statisticsBarChartView: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        "&.hasheight": {
            overflowY: "hidden"
        }
    },
    tooltip: {
        backgroundColor: "#fff !important",
        color: "#222 !important",
        whiteSpace: "pre-line",
        maxWidth: "200px !important",
        fontSize: '13px !important',
        fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
        marginBottom: "2px !important",
        lineHeight: "2 !important"
    },
    tooltipPopper: {
        padding: "8px !important",
        backgroundColor: "#fff !important",
        boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 10px !important"
    },
    titleContainer: {
        backgroundColor: "#fff !important",
        display: "flex",
        "& label.MuiFormControlLabel-root": {
            marginLeft: "5px !important",
            marginRight: "0px !important",
            marginBottom: "2px !important"

        }
    }
});
export default style;