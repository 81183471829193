const style = () => ({
    patternAddContainer: {
        padding: 24,
        minWidth: 400,
        maxWidth: 800,
        marginRight: 50
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    addPatternDialog: {
        '& .MuiPaper-root': {
            maxWidth: 800,
            marginRight: 40
        }
    }
});
export default style;