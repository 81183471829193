const style = () => ({
    ApplicationDetailPageContainer: {
        paddingTop: 10,
        '& .applicationDetailTitle': {
            paddingBottom: 15
        },
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    leftContent: {
        paddingRight: 50,
        display: 'flex',
        flexDirection: 'column !important',
        // justifyContent: 'space-between',
        textAlign: 'left'
    },
    applicationsPageContainer: {
        paddingBotton: '32px',
        '& .loaderContainer': {
            paddingLeft: '32px'
        }
    },
    tabsRootContainer: {
        background: '#fff',
        padding: '24px 30px 30px',
        '& .btnTabsInline  .MuiTab-root': {
            marginRight: '1px',
            borderRadius: '0px'
        },
        '& .btnTabsInline.MuiTabs-root': {
            minHeight: '30px'
        },
        '& .cardsSection': {
            '& .loaderContainer': {
                paddingLeft: '24px'
            }
        },
        '& .tableHeaderGrid': {
            marginBottom: '0px !important'
        }
    },
    addUser: {
        '& .addUserGrid': {
            marginTop: '20px'
        }
    },
    tabsHeaderSection: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    tabsBodySection: {
        '& .MuiPaper-root': {
            overflowX: 'auto'
        }
    }
});
export default style;