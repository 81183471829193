const HomeStyle = (theme) => ({
    widgetHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .NotitleWidget': {
            position: 'absolute',
            right: 0,
            top: '2px'
        },
        '& .rightActions': {
            zIndex: 2
        },
        '& .toggleContainer': {},
        '& .MuiTypography-root': {
            '&:first-letter': {
                textTransform: 'uppercase'
            }
        }
    },
    widgetBody: {
        paddingTop: '15px',
        height: 'calc(100% - 35px)',
        '&.NotitleWidgetBody': {
            height: '100% !important',
            paddingTop: '0px !important',
            '&.loadingWidget': {
                display: 'flex',
                alignItems: 'center'
            }
        },
        '& .noResultIcon': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            '& svg': {
                width: '75px',
                height: '70.652px',
                fill: `${theme.palette.greyshades.darkgrey}50`
            }
        },
        '& .loaderContainer ': {
            marginLeft: '0px !important',
            marginTop: '0px !important'
        }
    },
    widgetContainer: {
        background: '#fff',
        padding: '16px 16px 16px 16px',
        border: `1.5px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 3,
        position: 'relative',
        height: '100%',
        alignContent: 'baseline',
        '&.showDrag': {
            border: `1.5px solid ${theme.palette.secondary.main}`,
            '& .widgetExtraOption': {
                marginRight: '0px',
                transition: 'all 0.5s'
            }
        },
        '& .widgetOptions': {
            opacity: 0,
            visibiltity: 'hidden',
            transition: 'all 0.5s',
            '&.showoptions': {
                opacity: 1,
                visibiltity: 'visible',
                transition: 'all 0.5s'
            },
            '& .FilterIcon2': {
                fill: `${theme.palette.secondary.main} !important`
            },
            '& .MoreIcon path.fillPath, & .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            },
            '& .ShareIcon': {
                width: '17px',
                height: '17px'
            },
            '& .SettingsIcon': {
                width: '17.727px',
                height: '17.131px',
                '& path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .widgetExtraOption': {
            marginRight: '-40px',
            transition: 'all 0.5s'
        }
    },
    popoverComponent: {
        minWidth: 100,
        '& .MuiListItemButton-root': {
            padding: '3px 16px'
        }
    }
});

export default HomeStyle;