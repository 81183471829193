const style = (theme) => ({
    listItem: {
        padding: '8px 16px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        borderTop: `none !important`,
        height: 48,
        '&:nth-child(even)': {
            // background: '#eef1f52e'
        },
        '& .textBoxContainer': {
            marginLeft: '30px',
            maxWidth: '80px',
            minWidth: '38px',
            '& .MuiOutlinedInput-input': {
                padding: '4.5px 14px !important'
            }
        },
        '@media only screen and (max-width: 1400px)': {
            '& .textBoxContainer': {
                marginLeft: '12px'
            }
        }
    },
    colorPicker: {
        width: '20px',
        height: '24px',
        borderRadius: 30,
        position: 'relative',
        '& .typeColor': {
            opacity: 0,
            display: 'block',
            width: '20px',
            height: '24px',
            border: 'none',
            position: 'absolute',
            top: 0,
            zIndex: 99
        }
    },
    colorPickerComponent: {
        display: 'inline-flex',
        alignItems: 'center',
        textTransform: 'uppercase'
    },
    cellItem: {
        padding: '0px 12px',
        whiteSpace: 'nowrap',
        '&:first-child': {
            paddingLeft: '0px !important',
            minWidth: 130,
            '& .MuiTypography-root': {
               textTransform: 'capitalize'
            }
        },
        '&:last-child': {
            paddingLeft: '0px !important'
        },
        '& .MuiIconButton-root.active svg': {
            fill: '#2E384D'
        },
        '& .MuiIconButton-root svg': {
            width: '25px',
            height: '25px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        '& .selectComponent': {
            minWidth: '166px',
            maxWidth: '166px'
        },
        '& #demo-simple-select': {
            paddingRight: '12px !important'
        },
        '& .MuiSelect-select': {
            paddingLeft: '0px !important'
        }
    }
});
export default style;