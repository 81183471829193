import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Componetns
import MeasureAdd from './add/index.jsx';
import { TableComponent } from '../../../../../components/index.js';

// Import Styles
import RulesStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Action
import {
    getMeasureRequest,
    updateMeasureProperty,
    updateMeasureRequest,
    getMeasureDetailRequest,
    deleteMeasureRequest,
    clearMeasureList
} from '../../../../../redux/reducer/baseMeasureReducer';
import { getDimensionRequest } from '../../../../../redux/reducer/dimensionReducer';

function Measures(props) {
    /**
     * Define Props
     */
    const { classes, semanticsPermission } = props;
    const { termId } = useParams();
    const [openMeasureAdd, setOpenMeasureAdd] = useState(false);
    const [searchData, setSearchData] = useState({
        name: '',
        description: '',
        category: '',
        dimension: '',
        is_active: 'All'
    });
    const [sorting, setSorting] = useState({
        orderBy: 'asc',
        sortBy: ''
    });
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { measures, isLoading } = useSelector((state) => state.baseMeasure);
    const { searchableDimensionList } = useSelector((state) => state.dimension);

    /**
     * Get Measures Data
     */
    useEffect(() => {
        if (termId) {
            dispatch(clearMeasureList());
            const requestParams = {
                term: termId,
                type: 'semantic'
            };
            dispatch(getMeasureRequest(requestParams));
        }
        return () => {
            dispatch(clearMeasureList());
        };
    }, [termId]);

    /**
     * Get Dimensions List
     */
    useEffect(() => {
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
        return () => {
            dispatch(clearMeasureList());
        };
    }, [dispatch]);

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy,
            orderBy
        };
        setSorting(requestData);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestParams = {
            [key]: value,
            id: item.id
        };
        dispatch(updateMeasureProperty(requestParams));
        if (key === 'dimension') {
            value = value.length ? value[0].id : null;
        }
        requestParams[key] = value;
        dispatch(updateMeasureRequest(requestParams));
    };

    /**
     * Measure Add Popup
     */
    const handleMeasureAdd = () => {
        setOpenMeasureAdd(!openMeasureAdd);
    };

    /**
     *  Edit Measure
     */
    const editMeasure = (item) => {
        dispatch(getMeasureDetailRequest(item.id));
        setOpenMeasureAdd(true);
    };

    /**
     * Click Actions
     * @param {*} item
     */
    const onClickActions = (item) => {
        dispatch(deleteMeasureRequest(item.id));
    };

    /**
     * Prepare Filter Measures
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterMeasures = (
        data,
        searchFilters,
        dimensions,
        sortBy,
        orderBy
    ) => {
        const uuidRegex =
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        let filterData = [...data].map((obj) => {
            let dimension = obj.dimension || [];
            if (uuidRegex.test(obj.dimension)) {
                dimension = dimensions.find(
                    (dimension) => dimension.id === obj.dimension
                );
                dimension = dimension ? [{ ...dimension }] : [];
            } else {
                dimension =
                    typeof dimension === 'string'
                        ? JSON.parse(dimension)
                        : dimension;
            }

            return {
                ...obj,
                dimension
            };
        });
        const booleanFields = ['is_active'];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (
                (booleanFields.includes(key) && searchFilters[key] !== 'All') ||
                (searchFilters[key] !== '' && !booleanFields.includes(key))
            ) {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (booleanFields.includes(key)) {
                        const value = Boolean(searchFilters[key] === 'Active');
                        if (item[key] !== value) {
                            return false;
                        }
                    } else {
                        let value = item[key] || "";
                        if (key === 'dimension') {
                            if (!value) {
                                value = [];
                            }
                            value = value.length ? value[0].name : '';
                        }
                        if (
                            !value
                                .toLowerCase()
                                .includes(searchFilters[key].toLowerCase())
                        ) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Measures using UseMemo
     */
    const filterMeasures = useMemo(
        () =>
            prepareFilterMeasures(
                measures,
                searchData,
                searchableDimensionList,
                sorting.sortBy,
                sorting.orderBy
            ),
        [
            measures,
            searchData,
            searchableDimensionList,
            sorting.sortBy,
            sorting.orderBy
        ]
    );

    const measureTableOptions = [
        { type: 'search', customFunction: null },
        { type: 'download', customFunction: null },
        { type: 'columns', customFunction: null },
        { type: 'add', customFunction: handleMeasureAdd }
    ];
    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: true,
            width: '25%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name',
            customFunction: editMeasure,
            clickable: true
        },
        {
            key: 'description',
            name: 'Description',
            sorting: true,
            tooltip: true,
            width: '20%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'description',
            customFunction: editMeasure,
            clickable: true
        },
        {
            key: 'is_active',
            name: 'Active',
            width: '5%',
            sorting: true,
            tooltip: true,
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            isPermissionDisabled: !semanticsPermission?.is_edit,
            searchKey: 'is_active'
        },
        {
            key: 'category',
            name: 'Category',
            width: '5%',
            sorting: true,
            tooltip: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'category'
        },
        {
            key: 'dimension',
            name: 'Dimension',
            sorting: true,
            tooltip: true,
            width: '10%',
            component: 'chips',
            isAdd: true,
            chipAddType: 'autocomplete',
            addLimitCount: 1,
            valueKey: 'name',
            haveColor: true,
            isNotEditable: !semanticsPermission?.is_edit,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'dimension',
            downloadArrayKey: "name"
        },
        { key: 'actions', width: '5%', name: 'Actions', actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }] }
    ];

    const permissionOptions = (options) => {
        const filteredOption = [...options];
        if (!semanticsPermission?.is_edit) {
            const index = filteredOption.findIndex(
                (option) => option.type === 'add'
            );
            filteredOption.splice(index, 1);
        }
        return filteredOption;
    };

    const permissionActions = (actions) => {
        const filteredAction = [...actions];
        if (!semanticsPermission?.is_edit) {
            const index = filteredAction.findIndex(
                (option) => option.key === 'actions'
            );
            filteredAction.splice(index, 1);
        }
        return filteredAction;
    };

    return (
        <Grid container className={classes.rulesContainer}>
            <Grid item xs={12}>
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        headers={permissionActions(tableHeaders)}
                        options={permissionOptions(measureTableOptions)}
                        title={'Measures'}
                        data={filterMeasures || []}
                        sortBy={sorting.sortBy}
                        orderBy={sorting.orderBy}
                        onClickSorting={onClickSorting}
                        styleType="striped"
                        searchData={searchData}
                        onCompnentEvent={onCompnentEvent}
                        onClickActions={onClickActions}
                        onHandleSearchEvent={onHandleSearchEvent}
                        selectComponentList={{ dimension: searchableDimensionList }}
                        NoResultText="No Measures Found"
                        height="calc(300px)"
                        isLoading={isLoading}
                        exportParams={
                            {
                                fileName: "term_measures.csv",
                                titleCaseHeader: true
                            }
                        }
                        stickyHeader
                    />
                </ValidatorForm>
            </Grid>
            <Grid item xs={12} className="mt5">
                <MeasureAdd
                    open={openMeasureAdd}
                    attributes={[]}
                    closeMeasureAdd={handleMeasureAdd}
                    semanticRule
                    semanticsPermission={semanticsPermission}
                />
            </Grid>
        </Grid>
    );
}

// prop types
Measures.propTypes = {
    classes: PropTypes.object,
    semanticsPermission: PropTypes.object
};

// default props
Measures.defaultProps = {
    classes: {},
    attributes: [],
    attribute_id: '',
    asset_id: '',
    semanticsPermission: {}
};

export default withStyles(
    (theme) => ({
        ...RulesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Measures);