import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { Grid, Switch, Typography, IconButton, Tooltip } from '@mui/material';
import { AppIntegrationIcon } from '../../../components/index';

//  Import Styles
import CardStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Action
import { createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';
import { navigate } from '../../../redux/reducer/navigationReducer';
import { updateChannel } from '../../../redux/reducer/defaultReducer';

// Import Helpers
import { AppIntegration, checkPermission } from '../../../helpers/index';

// import Constant
import appConstants from '../../../constants/appConstants';

// Import Images
import { DeleteIcon, EditIcon } from '../../../assets/svg/index';
import featureConstants from '../../../constants/featureConstants';

function Card(props) {
    /**
     * Define Props
     */
    const { classes, data, onDelete } = props;
    const dispatch = useDispatch();
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * Get Callback
     * @param {*} response
     */
    const getCallback = (response, type) => {
        if (type === "edit") {
            const requestParams = {
                id: data.channel.integration_id,
                channel: data.id,
                config: response.result,
                update_type: "config"
            };
            dispatch(updateConfigurationRequest(requestParams));
        } else {
            const requestParams = {
                config: response.result,
                channel: data.id,
                type: "collaboration",
                channelName: data.type
            };
            dispatch(createConfigrationRequest(requestParams));
        }
    };

    /**
     * Get App Info
     */
    const getAppInfo = (type = "new") => {
        const integration = new AppIntegration(data.type);
        integration.authenticate().then((response) => {
            getCallback(response, type);
        });
    };


    /**
     * Navigate to App
     */
    const navigateApp = () => {
        if (data.channel.integration_id) {
            dispatch(navigate({ path: 'settings.integration.detail', state: { channel: data }, params: [data.technical_name.toLowerCase(), data.channel.integration_id] }));
        } else {
            if (appConstants.appIntegration.includes(data.type)) {
                getAppInfo();
            } else {
                dispatch(navigate({ path: 'settings.integration.new', state: { channel: data }, params: [data.technical_name.toLowerCase()] }));
            }
        }
    };

    /**
     * Reset Config
     */
    const resetConfig = () => {
        if (appConstants.appIntegration.includes(data.type)) {
            getAppInfo("edit");
        } else {
            navigateApp();
        }
    };

    /**
     * Handle Toggle Change
     * @param {*} value
     */
    const handleToggleChange = (value) => {
        const requestParams = {
            id: data.channel.integration_id,
            channel: data.id,
            is_active: value
        };
        dispatch(updateChannel(requestParams));
        dispatch(updateConfigurationRequest(requestParams));
    };

    return (
        <Grid
            className={`${classes.card} card ${!data.channel.is_active ? 'deactive' : null}`}
        >
            {
                data.channel.integration_id &&
                <Grid container alignItems={"center"} justifyContent={"space-between"} className={classes.actions}>
                    <Grid item>
                        {
                            integrationPermission?.is_edit &&
                            <Switch
                                size="small"
                                checked={data.channel.is_active}
                                onChange={(event) => handleToggleChange(event.target.checked)}
                            />
                        }
                    </Grid>
                    <Grid item>
                        {
                            integrationPermission?.is_edit &&
                            <Tooltip
                                arrow
                                title={appConstants.labels.tooltips.edit}
                            >
                                <IconButton onClick={() => resetConfig()}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            integrationPermission?.is_edit &&
                            <Tooltip
                                arrow
                                title={appConstants.labels.tooltips.delete}
                            >
                                <IconButton onClick={() => onDelete()} className={classes.deleteIcon}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            }
            <Grid className={classes.imgContainer} onClick={() => integrationPermission?.is_edit && navigateApp()}>
                <AppIntegrationIcon type={data.technical_name} />
            </Grid>
            <Grid onClick={() => integrationPermission?.is_view && navigateApp()}>
                <Typography
                    variant="h6"
                    align="center"
                    className="pb-1 pt-3"
                >
                    {data.name}
                </Typography>
                <Typography variant="body1" align="center" className={classes.cardDesc}>
                    {data.description}
                </Typography>
            </Grid>
        </Grid >
    );
}

// default props
Card.defaultProps = {
    classes: {},
    onDelete: () => { }
};

// prop types
Card.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onDelete: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...CardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Card, areEqual));