import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions
import {
    connectionDetailRequest, connectionDetailSuccess, connectionDetailFailure, connectionCreateRequest, connectionCreateSuccess, connectionCreateFailure,
    connectionUpdateRequest, connectionUpdateSuccess, connectionUpdateFailure, connectionDeleteRequest, connectionDeleteSuccess, connectionDeleteFailure,
    connectionListSuccess, connectionListFailure, connectionListRequest, connectionCopyRequest, connectionCopySuccess, connectionCopyFailure,
    getCatalogConnectionsListRequest, getCatalogConnectionsListSuccess, getCatalogConnectionsListFailure, getDBSchemasListRequest,
    getDBSchemasListSuccess, getDBSchemasListFailure, connectionSchemaRemoveRequest, connectionSchemaRemoveSuccess, connectionSchemaRemoveFailure,
    getSearchConnectionsRequest, getSearchConnectionsSuccess, getSearchConnectionsFailure,
    getPipelineInfoRequest, getPipelineInfoSuccess, getPipelineInfoFailure, getConnectionsRequest, getConnectionsSuccess, getConnectionsFailure, getAdfFactoryDetailsRequest, getAdfFactoryDetailsSuccess, getAdfFactoryDetailsFailure
} from '../reducer/connectionReducer';

// Import Reducers
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Service
import { connectionService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Connection List
 * @param {*} action$
 * @returns
 */
const list = (action$) => action$.pipe(
    ofType(connectionListRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.list(payload)).pipe(
            concatMap((res) => of(connectionListSuccess(res))),
            catchError((error) => of(connectionListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Actions
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(connectionCreateRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.create(payload)).pipe(
            concatMap((res) => {
                const data = res.data;
                return of(
                    connectionCreateSuccess({ ...res, request_data: payload }),
                    displyAlert({ 'type': data.is_valid ? 'success' : 'error', 'message': data.is_valid ? appConstants.successMessages.connection : data.validation_message, 'delay': 5000 }),
                    navigate({ path: 'connector.edit', params: [payload.type, data.connection_id], state: {} })
                );
            }),
            catchError((error) => {
                if (error?.response?.data?.data?.is_duplicate) {
                    return of(connectionCreateFailure(), displyAlert({ 'type': 'error', 'message': appConstants.errorMessages.duplicateConnection }));
                }
                return of(connectionCreateFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Detail Actions
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(connectionDetailRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.detail(payload)).pipe(
            concatMap((res) => of(connectionDetailSuccess({ ...res, request_data: payload }))),
            catchError((error) => of(connectionDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(connectionUpdateRequest),
    mergeMap(({ payload: { id, page_type, show_assets, ...rest } }) => {
        return from(connectionService.update(id, rest)).pipe(
            concatMap((res) => {
                const data = res.data;
                if (data && data.is_valid && !show_assets) {
                    return of(
                        displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }),
                        connectionUpdateSuccess({ ...res, page_type, validation_required: rest.validation_required })
                    );
                }
                if (data && data.is_valid && show_assets) {
                    return of(
                        connectionUpdateSuccess({ ...res, page_type, validation_required: rest.validation_required }),
                        navigate({ path: 'connector.asset', state: {}, params: [data.type, data.connection_id] })
                    );
                }
                return of(
                    connectionUpdateSuccess({ ...res, page_type, validation_required: rest.validation_required }),
                    displyAlert({ 'type': 'error', 'message': { message: data.validation_message }, 'delay': 5000 })
                );
            }),
            catchError((error) => {
                if (error?.response?.data?.data?.is_duplicate) {
                    return of(connectionUpdateFailure(), displyAlert({ 'type': 'error', 'message': appConstants.errorMessages.duplicateConnection }));
                }
                return of(connectionUpdateFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
    ofType(connectionDeleteRequest),
    mergeMap(({ payload: { id, purge } }) => {
        return from(connectionService.remove(id, purge)).pipe(
            concatMap(() => of(connectionDeleteSuccess(id), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
            catchError((error) => of(connectionDeleteFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Copy Actions
 * @param {*} action$
 * @returns
 */
const copy = (action$) => action$.pipe(
    ofType(connectionCopyRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.copy(payload)).pipe(
            concatMap((res) => {
                return of(connectionCopySuccess({ ...res, request_data: payload }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.copy }));
            }),
            catchError((error) => of(connectionCopyFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Connections List for Catalog Search
 * @returns
 */
const getCatalogConnectionList = (action$) => action$.pipe(
    ofType(getCatalogConnectionsListRequest),
    mergeMap(() => {
        return from(connectionService.getCatalogConnectionList()).pipe(
            concatMap((res) => { return of(getCatalogConnectionsListSuccess(res)); }),
            catchError((error) => { return of(getCatalogConnectionsListFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Database and Schema
 * @param {*} action$
 * @returns
 */
const dbs_schemas = (action$) => action$.pipe(
    ofType(getDBSchemasListRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.dbs_schemas(payload)).pipe(
            concatMap((res) => of(getDBSchemasListSuccess({ ...res, request_data: payload }))),
            catchError((error) => of(getDBSchemasListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Remove Schema
 * @param {*} action$
 * @returns
 */
const remove_schema = (action$) => action$.pipe(
    ofType(connectionSchemaRemoveRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.remove_schema(payload)).pipe(
            concatMap(() => of(connectionSchemaRemoveSuccess(), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
            catchError((error) => of(connectionSchemaRemoveFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Search Connections List
 * @returns
 */
const getConnectionList = (action$) => action$.pipe(
    ofType(getSearchConnectionsRequest),
    mergeMap(() => {
        return from(connectionService.getConnectionList()).pipe(
            concatMap((res) => { return of(getSearchConnectionsSuccess(res)); }),
            catchError((error) => { return of(getSearchConnectionsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get Pipeline Info
 * @param {*} action$
 * @returns
 */
const pipelineInfo = (action$) => action$.pipe(
    ofType(getPipelineInfoRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.pipelineInfo(payload)).pipe(
            concatMap((res) => of(getPipelineInfoSuccess({ ...res, request_data: payload }))),
            catchError((error) => of(getPipelineInfoFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Connection List
 * @returns
 */
const getConnections = (action$) => action$.pipe(
    ofType(getConnectionsRequest),
    mergeMap(({ payload: { requestParams, token } }) => {
        return from(connectionService.getConnections(requestParams, token)).pipe(
            concatMap((res) => { return of(getConnectionsSuccess({ params: requestParams, data: res.data ?? [] })); }),
            catchError(() => { return of(getConnectionsFailure()); })
        );
    })
);

/**
 * Get ADF Factories
 * @param {*} action$
 * @returns
 */
const adf_factories = (action$) => action$.pipe(
    ofType(getAdfFactoryDetailsRequest),
    mergeMap(({ payload }) => {
        return from(connectionService.adf_factories(payload)).pipe(
            concatMap((res) => of(getAdfFactoryDetailsSuccess({ ...res, request_data: payload }))),
            catchError((error) => of(getAdfFactoryDetailsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

// Export All Epic Functions
export const connectionEpic = [list, detail, create, update, remove, copy, getCatalogConnectionList, dbs_schemas, remove_schema, getConnectionList, pipelineInfo, getConnections, adf_factories];