// Default Imports
import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';


//Import Helpers
import { toRound } from '../../helpers/appHelpers';

//Import Styles
import style from "./style.jsx";
import palette from '../../assets/theme/palette';

const CircularScoreComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, value, size, showPercentage, circularFont, showValue, isDisabled, noLink, thickness, color, defaultCusrsor } = props;

    /**
     * Redux selector function to get Quadrant Settings from redux store
     */
    const { scoring } = useSelector((state) => state.configurations.general);

    /**
     * Define the color based on the score
     * @param {*} value
     * @returns
     */
    const getColor = (value) => {
        const { values, quadrant } = scoring;
        const QuadSettig = values[quadrant - 2];
        for (let i = 0; i < QuadSettig?.length; i++) {
            if (Math.ceil(value) >= QuadSettig[i].from && Math.ceil(value) <= QuadSettig[i].to) {
                return QuadSettig[i].color;
            }
        }
        return palette.secondary.main;
    };
    return (
        <Grid className={`${classes.circularContainer} circularContainer`}>
            <Tooltip title={`${value}%`}>
                <Grid className={classNames(classes.progressContainer, noLink ? classes.noPointer : null)} >
                    <CircularProgress className={`${classes.progressbackground} progressbackground`} size={size} thickness={thickness} variant="determinate" value={100} />
                    <CircularProgress style={{ color: color ? color : getColor(value) }} size={size} thickness={thickness} variant="determinate" value={parseFloat(value)} />
                    {
                        showValue &&
                        <div style={{ fontSize: circularFont ? circularFont : 12 }} className={`textValue ${isDisabled ? classes.nullText : classes.value} ${defaultCusrsor ? 'defaultCursor' : ''}`}>
                            {showPercentage ? `${toRound(value)}%` : `${toRound(value)}`}
                        </div>
                    }
                </Grid>
            </Tooltip>
        </Grid>
    );
};

/**
 * Define Component Props
 */
CircularScoreComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.number,
    showPercentage: PropTypes.bool,
    showValue: PropTypes.bool,
    circularFont: PropTypes.number,
    isDisabled: PropTypes.bool,
    noLink: PropTypes.bool,
    thickness: PropTypes.number,
    color: PropTypes.string,
    defaultCusrsor: PropTypes.bool
};

/**
 * Define Default Props
 */
CircularScoreComponent.defaultProps = {
    classes: {},
    value: 0,
    size: 40,
    showPercentage: true,
    showValue: false,
    circularFont: 10,
    isDisabled: false,
    noLink: true,
    color: "",
    defaultCusrsor: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(CircularScoreComponent, areEqual));