// Import API Helper
import { postRequest, getRequest, putRequest, deleteRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Create
 */
async function create(params) {
    const response = await postRequest(urlConstants.conversation.url, params);
    return response;
}

/**
 * List
 */
async function list(type, id, limit, offset) {
    const response = await getRequest(`${urlConstants.conversation.url}?${type}=${id}&limit=${limit}&offset=${offset}`);
    return response;
}

/**
 * Update
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.conversation.url}${id}/`, params);
    return response;
}

/**
 * Remove
 */
async function remove(id) {
    const response = await deleteRequest(`${urlConstants.conversation.url}${id}/`);
    return response;
}

/**
 * Get Featured Comments
 * @param {*} type
 * @param {*} id
 * @returns
 */
async function get_featured(type, id) {
    const response = await getRequest(`${urlConstants.conversation.featured}?${type}=${id}`);
    return response;
}

/**
 * Export All Service Functions
 */
export const conversationService = {
    create, list, update, remove, get_featured
};