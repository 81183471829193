const style = (theme) => ({
    conversationList: {
        '& .MuiTimelineDot-root': {
            backgroundColor: '#ffffff',
            border: `3px solid ${theme.palette.greyshades.darkgrey}`,
            margin: '0px !important',
            '&.pinnedTimeLine': {
                border: `3px solid ${theme.palette.greyshades.darkgrey} !important`
            },
            '&.error': {
                border: `3px solid ${theme.palette.greyshades.darkgrey} !important`
            },
            '&.warning': {
                border: `3px solid ${theme.palette.greyshades.darkgrey} !important`
            }
        },
        '& .MuiTimelineConnector-root': {
            width: '4px',
            backgroundColor: theme.palette.greyshades.grey
        },
        '& .MuiTimelineContent-root': {
            padding: '10px 16px'
        },
        '& .MuiTimeline-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    },
    conversationConainer: {
        boxShadow: theme.palette.boxShadow2,
        borderRadius: 4,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        padding: 20,
        position: 'relative',
        overflow: 'hidden',
        marginTop: 20,
        '&.endorsed': {
            '&:after': {
                background: `${theme.palette.componentColors.success} !important`
            }
        },
        '& p': {
            margin: '0px'
        },
        '& .replyContainer': {
            padding: '8px 16px 16px 16px',
            borderLeft: `5px solid ${theme.palette.greyshades.lightgrey}`,
            '&:not(:first-child)': {
                paddingTop: '0px !important'
            },
            '& .commentText': {
                padding: '5px 0px 0px!important',
                '& asset': {
                    color: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .replyOverallContainer': {
            borderTop: `1px solid ${theme.palette.greyshades.lightgrey}`,
            padding: '20px 20px 5px',
            marginTop: 12,
            '& .nameAvatarReply': {
                marginTop: '2px !important'
            }
        },
        '& mention, mention a': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .commentText': {
            padding: '12px 0px 2px',
            '& asset': {
                color: `${theme.palette.secondary.main} !important`
            }
        },
        '& .replycommentText': {
            paddingBottom: '5px'
        },
        '& .replyBtn': {
            visibilty: 'hidden',
            transition: 'all 0.5s',
            opacity: 0
        },
        '&:hover .replyBtn': {
            visibilty: 'visible',
            transition: 'all 0.5s',
            opacity: 1
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    contentContainer: {
        '&.singleLine': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .commentsDiv': {
                order: '-1'
            },
            '& .nameAvatar': {
                flexShrink: 0,
                flexWrap: 'nowrap',
                alignItems: 'center',
                paddingLeft: '15px',
                marginTop: '0px !important'
            }
        }
    },
    conversationHeader: {
        '& .nameAvatar': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MoreIcon': {
            width: '22px',
            height: '22px'
        }
    },
    nameAvatar: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px !important',
        '& .MuiAvatar-root': {
            width: 26,
            height: 26
        }
    },
    replyInput: {
        marginTop: 16,
        '& .textBoxContainer': {
            flex: 1
        }
    },
    nameText: {
        marginLeft: '12.5px !important'
    },
    replyEditor: {
        marginTop: 5,
        '& .SimpleMentionEditor_editor__MOmkb': {
            marginBottom: 45
        }
    },
    replyRoot: {
        '& .SimpleMentionEditor_editor__MOmkb': {
            margin: '10px 0px !important'
        }
    }
});
export default style;