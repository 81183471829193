import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import ListStyle from '../style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Components
import Result from './listTabs/result.jsx';
import ComparisonResult from './listTabs/comparisonResult.jsx';
import FailedRows from './listTabs/failedRows.jsx';
import MeasureLogs from './listTabs/measure_logs.jsx';
import { SimpleTabHeaderComponent, TabPanelComponent } from '../../../../../components/index.js';

//Constant
import appConstants from '../../../../../constants/appConstants.js';

function List(props) {

    /**
     * Define Rules
     */
    const { classes, selectedAlert, propertiesPermission, measureId, dayFilter, scrollRef } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState("");
    const [tabHeaders, setTabHeaders] = useState([appConstants.tabs.propertiesMeasuresTabs.result, appConstants.tabs.propertiesMeasuresTabs.measureLogs]);

    const versionDetail = useSelector((state) => state.version.detail, shallowEqual);
    const metricDetail = useSelector((state) => state.metric.detail, shallowEqual);
    const { detail: measureDetail } = useSelector((state) => state.measure);
    const { reporting } = useSelector((state) => state.configurations.general, shallowEqual);

    /**
     * Load Tab headers
     */
    const loadTabHeaders = () => {
        let hasFailedRows = false;
        const selectedMeasure = measureDetail.id === metricDetail?.measure_id ? measureDetail : null;

        hasFailedRows = (
            selectedMeasure && reporting?.show_preview &&
            selectedMeasure.is_active && metricDetail?.history_data?.length > 0 &&
            measureDetail?.category !== "comparison"
        );
        const tabHeaders = [appConstants.tabs.propertiesMeasuresTabs.result, appConstants.tabs.propertiesMeasuresTabs.measureLogs];
        if (hasFailedRows) {
            tabHeaders.splice(1, 0, appConstants.tabs.propertiesMeasuresTabs.preview);
        }
        setTabHeaders([...tabHeaders]);
    };

    /**
     * Reset Tab Index
     */
    const resetTabIndex = () => {
        setTabIndex(0);
    };

    /**
     * Define Load Headers
     */
    useEffect(() => {
        if (measureDetail?.id && metricDetail?.measure_id && versionDetail) {
            loadTabHeaders();
        }

        return () => {
            resetTabIndex();
        };
    }, [versionDetail, metricDetail?.measure_id, measureDetail?.id, measureDetail]);

    /**
     * Reset Tab Index When dayfilter change
     */
    useEffect(() => {
        if (tabIndex !== 0 && dayFilter) {
            resetTabIndex();
        }
    }, [dayFilter]);

    /**
     * Get Selected Items
     * @param {*} metricDetail
     */

    const getSelectedItemInput = (metricDetail) => {
        let selectedInput = "";
        const selectedMeasure = measureDetail.id === metricDetail?.measure_id ? measureDetail : null;
        if (appConstants.tabs.failedRowInactiveMeasures.previewFilter.indexOf(selectedMeasure?.technical_name) > -1) {
            selectedInput = metricDetail?.measure_name ?? "";
        }
        setSelectedItem(selectedInput);
    };

    useEffect(() => {
        if (metricDetail?.measure_name) {
            getSelectedItemInput(metricDetail);
        }
    }, [metricDetail?.measure_name]);

    return (
        <Grid item xs={12} className={classes.listContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid
                    container
                    wrap="nowrap"
                    className={classes.tabHeaderContainer}
                >
                    <SimpleTabHeaderComponent
                        className={classes.tabsHeader}
                        tabStyle={'inline'}
                        tabIndex={tabIndex}
                        tabList={tabHeaders}
                        onTabChange={(newValue) => setTabIndex(newValue)}
                    />
                </Grid>
                <Grid className={classes.tabsBodySection}>
                    <Grid className={classes.tabsBody}>
                        <TabPanelComponent value={0} index={tabIndex}>
                            {
                                measureDetail?.category !== "comparison" ?
                                    (<Result selectedAlert={selectedAlert} propertiesPermission={propertiesPermission} />)
                                    : (<ComparisonResult selectedAlert={selectedAlert} propertiesPermission={propertiesPermission} />)
                            }
                        </TabPanelComponent>
                        <TabPanelComponent value={1} index={tabIndex}>
                            {(tabHeaders?.length === 3 && tabIndex === 1) && <FailedRows scrollRef={scrollRef} measureId={metricDetail?.measure_id} selectedValue={selectedItem} />}
                        </TabPanelComponent>
                        <TabPanelComponent value={tabHeaders?.length === 2 ? 1 : 2} index={tabIndex}>
                            <MeasureLogs measureId={measureId} />
                        </TabPanelComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
List.defaultProps = {
    classes: {},
    selectedAlert: "",
    propertiesPermission: {},
    measureId: "",
    dayFilter: 7,
    scrollRef: null
};

// prop types
List.propTypes = {
    classes: PropTypes.object,
    selectedAlert: PropTypes.string,
    propertiesPermission: PropTypes.object,
    measureId: PropTypes.string,
    dayFilter: PropTypes.number,
    scrollRef: PropTypes.element
};

export default withStyles(
    (theme) => ({
        ...ListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(List);