const style = () => ({
    rightContainer: {
        position: 'relative'
    },
    widgetListIcon: {
        marginRight: '15px !important',
        '& .textValue': {
            fontSize: '13px !important'
        }
    },
    progressBar: {
        minWidth: '100px'
    },
    rightContainerInner: {
        padding: 30,
        background: '#f9fafb',
        position: 'absolute',
        top: '-64px',
        right: '-16px',
        height: 'calc(100% + 82px)',
        zIndex: 1,
        '& .MuiGrid-item': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }
    },
    widgetItemDetail: {
        '& .MuiTypography-root': {
            wordBreak: 'break-word'
        }
    }
});

export default style;