import { createSlice } from '@reduxjs/toolkit';

// Define initial state for user reducer
const initialState = {
    isLoading: false,
    isUserDetailEdited: false,
    detail: {},
    users: [],
    usersStats: [],
    thumbnail_list: [],
    users_list: []
};

// create user slice for redux state
const userSlice = createSlice({
    name: 'user',
    initialState: { ...initialState },
    reducers: {
        getUsersRequest(state) {
            state.isLoading = true;
        },
        getUsersSuccess(state, action) {
            state.isLoading = false;
            const users = action.payload.data || [];
            const usersList = users.map((user) => {
                return {
                    ...user,
                    name: `${user.first_name || ""} ${user.last_name || ""}`
                };
            });
            state.users = usersList;
            if (state.users_list.length <= 0) {
                state.users_list = usersList;
            }

        },
        getUsersFailure(state) {
            state.isLoading = false;
        },
        getUsersThumbnailRequest(state) {
            state.isLoading = true;
        },
        getUsersThumbnailSuccess(state, action) {
            state.thumbnail_list = action.payload.data ? action.payload.data : [];
            state.isLoading = false;
        },
        getUsersThumbnailFailure(state) {
            state.isLoading = false;
        },
        getUserDetailRequest(state) {
            state.isLoading = true;
            state.isUserDetailEdited = false;
        },
        getUserDetailSuccess(state, action) {
            state.detail = action.payload.data ? action.payload.data : {};
            state.isLoading = false;
        },
        getUserDetailFailure(state) {
            state.isLoading = false;
        },
        updateUserDetail(state, action) {
            state.detail = { ...state.detail, ...action.payload };
            state.isUserDetailEdited = true;
        },
        updateUserDetailRequest(state) {
            state.isLoading = true;
        },
        updateUserDetailSuccess(state) {
            state.isLoading = false;
            state.isUserDetailEdited = false;
        },
        updateUserDetailFailure(state) {
            state.isLoading = false;
        },
        updateUserList(state, action) {
            const userId = action.payload.id;
            const index = state.users.findIndex((user) => user.id === userId);
            state.users[index] = { ...state.users[index], ...action.payload };
        },
        deleteUserRequest() { },
        deleteUserSuccess(state, action) {
            const index = state.users.findIndex((user) => user.id === action.payload);
            const deleteUser = state.users[index];
            state.users.splice(index, 1);
            const searchUserIndex = state.users_list.findIndex((user) => user.id === action.payload);
            state.users_list.splice(searchUserIndex, 1);
            const thumbIndex = state.thumbnail_list.findIndex((user) => user.id === action.payload);
            state.thumbnail_list.splice(thumbIndex, 1);

            state.usersStats = state.usersStats.map((user) => {
                if (user.is_active === deleteUser.is_active) {
                    user.count -= 1;
                }
                return user;
            });
        },
        deleteUserFailure() { },
        getUsersStatsRequest(state) {
            state.isLoading = true;
        },
        getUsersStatsSuccess(state, action) {
            state.usersStats = action.payload.data;
            state.isLoading = false;
        },
        getUsersStatsFailure(state) {
            state.isLoading = false;
        },
        inviteNewUserRequest(state) {
            state.isLoading = true;
        },
        inviteNewUserSuccess(state) {
            if (state.usersStats.length === 1) {
                state.usersStats.push({ is_active: false, count: 0 });
            }
            state.usersStats = state.usersStats.map((user) => {
                if (!user.is_active) {
                    user.count += 1;
                }
                return user;
            });
            state.isLoading = false;
        },
        inviteNewUserFailure(state) {
            state.isLoading = false;
        },
        updateUserPreferenceRequest() { },
        updateUserPreferenceSuccess() { },
        updateUserPreferenceFailure() { }
    }
});

export const { getUserDetailRequest, getUserDetailSuccess, getUserDetailFailure, updateUserDetailSuccess, updateUserDetailFailure,
    updateUserDetailRequest, updateUserDetail, getUsersThumbnailRequest, getUsersThumbnailSuccess, getUsersThumbnailFailure, getUsersStatsRequest,
    getUsersStatsSuccess, getUsersStatsFailure, inviteNewUserRequest, inviteNewUserSuccess, inviteNewUserFailure, getUsersRequest,
    getUsersSuccess, getUsersFailure, updateUserList, deleteUserRequest, deleteUserSuccess, deleteUserFailure,
    updateUserPreferenceRequest, updateUserPreferenceSuccess, updateUserPreferenceFailure } = userSlice.actions;
export default userSlice.reducer;