import qs from 'querystring';
import url from 'url';

import { config } from '../../config/appIntegrationConfig.js';
class Slack {

    oauth2(config) {
        return new Promise((resolve) => {
            const params = {
                user_scope: config.scope,
                client_id: config.clientId,
                redirect_uri: config.redirectUri
            };
            const url = `${config.url}?${qs.stringify(params)}`;
            resolve({ url: url, config: config });
        });
    }


    openPopup({ url, config }) {
        return new Promise((resolve) => {
            const width = config.width || 600;
            const height = config.height || 800;
            const options = {
                width: width,
                height: height,
                top: window.screenY + ((window.outerHeight - height) / 2.5),
                left: window.screenX + ((window.outerWidth - width) / 2)
            };
            const popup = window.open(url, '_blank', qs.stringify(options, ','));
            if (url === 'about:blank') {
                popup.document.body.innerHTML = 'Loading...';
            }
            resolve({ window: popup, config: config });
        });
    }


    pollPopup({ window, config, requestToken }) {
        return new Promise((resolve, reject) => {
            const redirectUri = url.parse(config.redirectUri);
            const redirectUriPath = `${redirectUri.host}${redirectUri.pathname}`;
            if (requestToken) {
                window.location = `${config.authorizationUrl}?${qs.stringify(requestToken)}`;
            }
            const polling = setInterval(() => {
                if (!window || window.closed) {
                    clearInterval(polling);
                }
                try {
                    const popupUrlPath = `${window.location.host}${window.location.pathname}`;
                    if (popupUrlPath === redirectUriPath) {
                        if (window.location.search || window.location.hash) {
                            const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
                            const hash = qs.parse(window.location.hash.substring(1).replace(/[\\/$]/, ''));
                            const params = Object.assign({}, query, hash);

                            if (params.error) {
                                reject({ error: params.error });


                            } else {
                                resolve({ oauthData: params, config: config, window: window, interval: polling });
                            }
                        } else {
                            reject({ error: 'OAuth redirect has occurred but no query or hash parameters were found.' });
                        }
                    }
                } catch (error) {
                    /*
                     * Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
                     * A hack to get around same-origin security policy errors in Internet Explorer.
                     */
                }
            }, 10);
        });
    }


    getAccessToken({ oauthData, config, window, interval }) {
        return new Promise((resolve, reject) => {
            const data = Object.assign({}, oauthData, config);
            const params = {
                client_id: data.clientId,
                client_secret: data.secret,
                code: data.code,
                redirect_uri: config.redirectUri,
                grant_type: config.grantType
            };
            const url = `${config.authenticationUrl}?${qs.stringify(params)}`;
            const requestOptions = {
                method: 'POST',
                redirect: 'follow'
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    result = result || {};
                    if (result.ok) {
                        const data = {
                            access_token: result.authed_user.access_token,
                            authed_user: result.authed_user,
                            app_id: result.app_id,
                            team: result.team
                        };
                        resolve({ window: window, interval, result: data });
                    } else {
                        reject(result);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    closePopup({ window, interval, result }) {
        return new Promise((resolve) => {
            clearInterval(interval);
            window.close();
            resolve({ result: result });
        });
    }


    authenticate() {
        return this.oauth2(config.slack)
            .then(this.openPopup)
            .then(this.pollPopup)
            .then(this.getAccessToken)
            .then(this.closePopup);
    }

    getChannels(accessToken) {
        return new Promise((resolve, reject) => {
            const form_data = new FormData();
            form_data.append("token", accessToken);
            form_data.append("types", "private_channel,public_channel");
            form_data.append("limit", 1000);
            const requestOptions = {
                "content-type": "application/x-www-form-urlencoded",
                method: 'POST',
                redirect: 'follow',
                body: form_data
            };
            const url = config.slack.channelsUrl;

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    result = result || {};
                    if (result.ok) {
                        const channels = result.channels.filter((data) => data.is_channel).map((channel) => {
                            return {
                                id: channel.id,
                                name: channel.name
                            };
                        });

                        resolve({ result: channels });
                    } else {
                        reject(result);
                    }
                })
                .catch((error) => reject(error));
        });
    }
}

// const slack = new Slack();
export default Slack;