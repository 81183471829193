import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  attributesLoading: false,
  tags: [],
  searchableTagsList: [],
  subTags: [],
  attributes: [],
  detail: {},
  quality: [],
  qualityIsLoading: false,
  subTagsLoading: false,
  searchTags: [],
  tagsFilter: [],
  searchableTagsFilterList: []
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState: { ...initialState },
  reducers: {
    getTagsRequest(state) {
      state.isLoading = true;
    },
    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tags = action.payload.map((item) => {
        return { ...item, subtags: item.subtags ? item.subtags : [] };
      });
      const newSearchableTagsList = [];
      state.tags.forEach((item) => {
        newSearchableTagsList.push({
          name: item.name,
          id: item.id,
          color: item.color,
          count: item?.count ?? 0
        });
        if (item.subtags.length > 0) {
          item.subtags.forEach((subItem) => {
            newSearchableTagsList.push({
              name: `${item.name}.${subItem.name}`,
              id: subItem.id,
              color: item.color,
              count: subItem?.count ?? 0
            });
          });
        }
      });
      state.searchableTagsList = [...newSearchableTagsList];
    },
    getTagsFailure() { },
    getTagByIdRequest(state) {
      state.isLoading = true;
    },
    getTagByIdSuccess(state, action) {
      const { data } = action.payload;
      state.detail = data || {};
      state.isLoading = false;
    },
    getTagByIdFailure(state) {
      state.isLoading = false;
    },
    createTagRequest() { },
    createTagSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const newSearchableTagsList = [...state.searchableTagsList];
      if (data.parent) {
        const tagsList = [...state.tags];
        const index = tagsList.findIndex((tag) => tag.id === data.parent);
        tagsList[index].subtags.unshift(data);
        const listindex = state.searchableTagsList.findIndex((list) => list.id === data.parent);
        newSearchableTagsList.splice((listindex + 1), 0, {
          name: `${state.searchableTagsList[listindex].name}.${data.name}`,
          id: data.id,
          color: data.color,
          count: data?.count ?? 0
        });
      } else {
        state.tags.unshift({ ...data, subtags: [] });
        newSearchableTagsList.unshift({
          name: data.name,
          id: data.id,
          color: data.color,
          count: data?.count ?? 0
        });
      }
      state.searchableTagsList = [...newSearchableTagsList];
      state.searchTags = newSearchableTagsList;
    },
    createTagFailure() { },
    updateTagRequest() { },
    updateTagSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const tagsList = [...state.tags];
      if (data.parent) {
        const parentIndex = state.tags.findIndex((tag) => tag.id === data.parent);
        const childIndex = tagsList[parentIndex].subtags.findIndex((sub) => sub.id === data.id);
        tagsList[parentIndex].subtags[childIndex] = { ...tagsList[parentIndex].subtags[childIndex], ...data };
      } else {
        const index = state.tags.findIndex((tag) => tag.id === data.id);
        tagsList[index] = { ...data, subtags: tagsList[index].subtags };
      }
      state.tags = [...tagsList];
    },
    updateTagFailure() { },
    deleteTagRequest() { },
    deleteTagSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const tagsList = [...state.tags];
      if (data.parent_id) {
        const parentIndex = state.tags.findIndex((tag) => tag.id === data.parent_id);
        const childIndex = tagsList[parentIndex].subtags.findIndex((sub) => sub.id === data.id);
        tagsList[parentIndex].subtags.splice(childIndex, 1);
      } else {
        const index = tagsList.findIndex((tag) => tag.id === data.id);
        tagsList.splice(index, 1);
      }
      state.tags = [...tagsList];
    },
    deleteTagFailure() { },
    getTagsSettingListRequest(state) {
      state.isLoading = true;
    },
    toggleTag(state, action) {
      const index = state.tags.findIndex((tag) => tag.id === action.payload.id);
      if (index > -1) {
        state.tags[index].isOpen = action.payload.isOpen;
      }
    },
    getSubTagsRequest(state) {
      state.subTagsLoading = true;
    },
    getSubTagsSuccess(state, action) {
      const { data } = action.payload;
      state.subTags = data || [];
      state.subTagsLoading = false;
    },
    getSubTagsFailure(state) {
      state.subTagsLoading = false;
    },
    getTagAttributesRequest(state) {
      state.attributesLoading = true;
    },
    getTagAttributesSuccess(state, action) {
      const { data } = action.payload;
      state.attributes = data || [];
      state.attributesLoading = false;
    },
    getTagAttributesFailure(state) {
      state.isLoading = false;
    },
    getTagsQualityRequest(state, action) {
      if (action.payload.clear) {
        state.qualityIsLoading = true;
      }
    },
    getTagsQualitySuccess(state, action) {
      const { clear, accordian } = action.payload;
      const data = action.payload.data || [];
      if (clear) {
        state.quality = data || [];
      } else {
        if (accordian && Object.keys(accordian).length) {
          const { attribute_id } = accordian;
          const index = state.quality.findIndex((data) => data.id === attribute_id);
          state.quality[index].isLoading = false;
          state.quality[index].children = data;
        }
      }
      state.qualityIsLoading = false;
    },
    getTagsQualityFailure(state) {
      state.qualityIsLoading = false;
    },
    collapseAccordian(state, action) {
      const { attribute_id, value } = action.payload;
      const index = state.quality.findIndex((data) => data.id === attribute_id);
      if (value && !state.quality[index]?.children?.length) {
        state.quality[index].isLoading = true;
      }
      state.quality[index].isOpen = value;
    },
    clearTagDetails(state) {
      state.detail = {};
      state.subTags = [];
      state.attributes = [];
      state.quality = [];
    },
    getSearchTagListRequest() { },
    getSearchTagListSuccess(state, action) {
      state.searchTags = action.payload;
    },
    getSearchTagListFailure() { },
    updateTagOrderRequest() {},
    updateTagOrderSuccess(state, action) {
      state.tags = action.payload;
    },
    updateTagOrderFailure() { },
    getTagsFilterRequest(state) {
      state.isLoading = true;
    },
    getTagsFilterSuccess(state, action) {
      state.isLoading = false;
      state.tagsFilter = action.payload.map((item) => {
        return { ...item, subtags: item.subtags ? item.subtags : [] };
      });
      const newSearchableTagsFilterList = [];
      state.tagsFilter.forEach((item) => {
        newSearchableTagsFilterList.push({
          name: item.name,
          id: item.id,
          color: item.color,
          count: item?.count ?? 0
        });
        if (item.subtags.length > 0) {
          item.subtags.forEach((subItem) => {
            newSearchableTagsFilterList.push({
              name: `${item.name}.${subItem.name}`,
              id: subItem.id,
              color: item.color,
              count: subItem?.count ?? 0
            });
          });
        }
      });
      state.searchableTagsFilterList = [...newSearchableTagsFilterList];
    },
    getTagsFilterFailure() { },
    updateSubTagOrderRequest() {},
    updateSubTagOrderSuccess(state, action) {
      state.tags = action.payload;
    },
    updateSubTagOrderFailure() { }
  }
});

export const { getTagsRequest, getTagsSuccess, getTagsFailure,
  createTagRequest, createTagSuccess, createTagFailure,
  deleteTagRequest, deleteTagSuccess, deleteTagFailure,
  updateTagRequest, updateTagSuccess, updateTagFailure,
  getTagByIdRequest, getTagByIdSuccess, getTagByIdFailure,
  getTagAttributesRequest, getTagAttributesSuccess, getTagAttributesFailure,
  getTagsQualityRequest, getTagsQualitySuccess, getTagsQualityFailure,
  getSubTagsRequest, getSubTagsSuccess, getSubTagsFailure,
  toggleTag, collapseAccordian, clearTagDetails, getSearchTagListRequest, getSearchTagListSuccess,
  updateTagOrderRequest, updateTagOrderSuccess, updateTagOrderFailure,
  getSearchTagListFailure, getTagsFilterRequest, getTagsFilterSuccess, getTagsFilterFailure, updateSubTagOrderRequest, updateSubTagOrderSuccess, updateSubTagOrderFailure
} = tagsSlice.actions;
export default tagsSlice.reducer;