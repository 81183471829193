const featureConstants = {
    settings: {
        general: {
            name: "Settings",
            feature: "General"
        },
        connections: {
            name: "Settings",
            feature: "Connections"
        },
        semantics: {
            name: "Settings",
            feature: "Semantics"
        },
        quality: {
            name: "Settings",
            feature: "Quality"
        },
        integration: {
            name: "Settings",
            feature: "Integration"
        },
        security: {
            name: "Settings",
            feature: "Security"
        },
        theme: {
            name: "Settings",
            feature: "Theme"
        },
        importExport: {
            name: "Settings",
            feature: "ImportExport"
        }
    },
    home: {
        dashboard: {
            name: "Home",
            feature: "Dashboard"
        },
        issues: {
            name: "Home",
            feature: "Issues"
        },
        alerts: {
            name: "Home",
            feature: "Alerts"
        },
        measures: {
            name: "Home",
            feature: "Measures"
        },
        reports: {
            name: "Home",
            feature: "Reports"
        }
    },
    assets: {
        overview: {
            name: "Assets",
            feature: "Overview"
        },
        preview: {
            name: "Assets",
            feature: "Preview"
        },
        properties: {
            name: "Assets",
            feature: "Properties"
        },
        usage: {
            name: "Assets",
            feature: "Usage"
        },
        conversations: {
            name: "Assets",
            feature: "Conversations"
        },
        publish: {
            name: "Assets",
            feature: "Publish"
        },
        approve: {
            name: "Assets",
            feature: "Approve"
        }
    }
};

export default featureConstants;