import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Typography,
    Button,
    Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { PopOverComponent } from '../../../../components';
import style from './style.jsx';
import { CopyIcon } from '../../../../assets/svg';
import { getDetailedLogRequest } from '../../../../redux/reducer/logsReducer';
import { LoaderComponent } from '../../../../components/index';


function LogViewer(props) {
    /**
     * Define Props
     */
    const { classes, open, anchorElement, onClose, taskId, errorMessage, errorDetail } = props;

    /**
     * Define State
     */
    const [isDetailedView, setDetailedView] = useState(false);
    const [detailedError, setDetailedError] = useState("");

    /**
     * Define Redux Props
     */
    const dispatch = useDispatch();
    const requestController = useRef();
    const { isTaskLoading, errorDetail: detailedErrorMessage } = useSelector((state) => state.logs.executionLogs);

    useEffect(() => {
        requestController?.current?.abort();
        setDetailedView(false);
        setDetailedError(errorDetail?.length > 0 ? errorDetail : "");
    }, [open, errorDetail]);

    useEffect(() => {
        if (detailedErrorMessage?.length > 0) {
            setDetailedError(detailedErrorMessage);
        }
    }, [detailedErrorMessage]);

    /**
     * Copy the error message to clipboard
     */
    const copyToClipBoard = useCallback(() => {
        if (isTaskLoading) {
            return;
        }

        let message = "";
        if (isDetailedView && detailedError?.length > 0) {
            message = detailedError || "";
        } else {
            message = errorMessage || "";
        }

        if (message?.length <= 0 || !navigator.clipboard) {
            return;
        }
        navigator.clipboard?.writeText(message);
    }, [isDetailedView, isTaskLoading, errorMessage, detailedError]);

    const onClickDetailedView = useCallback((isDetailView) => {
        if (isDetailView && detailedError?.length === 0 && requestController) {
            requestController?.current?.abort();
            requestController.current = new AbortController();
            const token = { signal: requestController?.current?.signal };
            dispatch(getDetailedLogRequest({ taskId, token }));
        }
        setDetailedView(isDetailView);
    }, [taskId, isDetailedView, detailedError]);


    return (
        <PopOverComponent
            open={open}
            anchorEl={anchorElement}
            onClose={() => onClose()}
        >
            <Grid
                container
                direction="column"
                wrap="nowrap"
                className={classes.logViewerContainer}
            >
                <Grid item xs={12} className="mb-1">
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant="h4">
                                {'Error Log'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title={"Copy"}>
                                <Grid className="copyIcon" onClick={() => copyToClipBoard()}>
                                    <CopyIcon />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mb-1">
                    {
                        !isTaskLoading &&
                        <Typography className={classes.errorMessage}>
                            {isDetailedView && detailedError?.length > 0 ? detailedError : errorMessage}
                        </Typography>
                    }
                    {
                        isDetailedView && isTaskLoading &&
                        <Grid className={classes.loader}>
                            <LoaderComponent loaderType={"circular"} height={50} />
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            disableElevation
                            size="small"
                            className={classes.tabBtn}
                            variant="outlined"
                            color="primary"
                            onClick={() => onClickDetailedView(!isDetailedView)}
                        >
                            {isDetailedView ? "Hide Detailed Log" : "View Detailed Log"}
                        </Button>
                        <Button
                            disableElevation
                            size="small"
                            color="primary"
                            onClick={() => onClose()}
                            style={{ marginLeft: '10px' }}
                            variant="outlined"
                            className={
                                classNames(
                                    classes.cancelBtn,
                                    classes.tabBtn
                                )
                            }
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </PopOverComponent>
    );
}

LogViewer.defaultProps = {
    classes: {},
    onClose: () => { },
    open: false,
    anchorElement: null,
    errorDetail: "",
    errorMessage: "",
    taskId: ""
};

LogViewer.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    anchorElement: PropTypes.object,
    errorDetail: PropTypes.string,
    errorMessage: PropTypes.string,
    taskId: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...style(theme)
    }),
    { withTheme: true }
)(LogViewer);