// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Dashboard
 * @returns
 */
async function getDashboard() {
    const response = await getRequest(`${urlConstants.dashboard.url}`);
    return response;
}

/**
 * Get Dashboard Detail
 * @param {*} dashboardId
 * @returns
 */
async function getDashboardDetail(dashboardId, cancellationToken) {
    const response = await getRequest(`${urlConstants.dashboard.url}${dashboardId}/`, cancellationToken);
    return response;
}

/**
 * Create Dashboard
 * @param {*} params
 * @returns
 */
async function createDashboard(params) {
    const response = await postRequest(`${urlConstants.dashboard.url}`, params);
    return response;
}

/**
 * Update Dashboard
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function updateDashboard(id, params) {
    const response = await putRequest(`${urlConstants.dashboard.url}${id}/`, params);
    return response;
}

/**
 * Get Widget Detail
 * @param {*} params
 * @returns
 */
async function getWidgetdetail(id, params) {
    const response = await postRequest(`${urlConstants.widget.getWidget}${id}/`, params);
    return response;
}

/**
 * Get Widget List
 * @returns
 */
async function getWidgetList() {
    const response = await getRequest(urlConstants.widget.url);
    return response;
}

/**
 * Create Widget
 * @param {*} params
 * @returns
 */
async function createWidget(params) {
    const response = await postRequest(urlConstants.widget.url, params);
    return response;
}

/**
 * Update Widget
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function updateWidget(id, params) {
    const response = await putRequest(`${urlConstants.widget.url}${id}/`, params);
    return response;
}

/**
 * Delete Widget
 * @param {*} id
 * @returns
 */
async function deleteWidget(id) {
    const response = await deleteRequest(`${urlConstants.widget.url}${id}/`);
    return response;
}

/**
 * Update Layout
 * @param {*} params
 * @returns
 */
async function updateLayout(params) {
    const response = await postRequest(urlConstants.widget.updateLayout, params);
    return response;
}


/**
 * Update Layout
 * @param {*} params
 * @returns
 */
async function applyFilter(params) {
    const response = await postRequest(urlConstants.dashboard.applyFilter, params);
    return response;
}

/**
 * Delete Dashboard
 * @param {*} id
 * @returns
 */
async function deleteDashboard(id) {
    const response = await deleteRequest(`${urlConstants.dashboard.url}${id}/`);
    return response;
}

/**
 * Export All Service Functions
 */
export const dashboardService = {
    getDashboard, getDashboardDetail, createDashboard, updateDashboard, getWidgetdetail, getWidgetList, createWidget, updateLayout,
    updateWidget, applyFilter, deleteWidget, deleteDashboard
};