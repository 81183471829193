import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { DonutChartComponent } from '../../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';


function AlertsMetric(props) {

    const { data, color, classes } = props;

    return (
        <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item className="pl-2">
                    <Grid >
                        <Typography variant="h2">
                            {data.high || 0}
                        </Typography>
                        <Typography variant="body1">
                            High
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid >
                        <Typography variant="h2">
                            {data.medium || 0}
                        </Typography>
                        <Typography variant="body1">
                            Medium
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid>
                        <Typography variant="h2">
                            {data.low || 0}
                        </Typography>
                        <Typography variant="body1">
                            Low
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className="relative">
                    <Grid className={classes.chartContainer}>
                        <DonutChartComponent data={
                            [
                                {
                                    value: data.high || 0,
                                    itemStyle: {
                                        color: color[0]
                                    }
                                },
                                {
                                    value: data.medium || 0,
                                    itemStyle: {
                                        color: color[1]
                                    }
                                },
                                {
                                    value: data.low || 0,
                                    itemStyle: {
                                        color: color[2]
                                    }
                                }
                            ]
                        } />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AlertsMetric.defaultProps = {
    data: {},
    color: []
};

// prop types
AlertsMetric.propTypes = {
    data: PropTypes.object,
    color: PropTypes.array,
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AlertsMetric);