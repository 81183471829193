import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import NoResultStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images

// import NoResultImg from '../../assets/img/no_result.png';
import appConstants from '../../constants/appConstants.js';
import { NoResultIcon } from '../../assets/svg/index.js';

function NoResultComponent(props) {
    const { classes, height, title, noresultSize } = props;

    return (
        <Grid container justifyContent={"center"} alignItems="center" sx={{ height: height }} className={`${classes.noResulteContainer} noResulteContainer`} direction="column">
            <NoResultIcon noresultSize={noresultSize} />
            <Typography variant="body1" className={`${classes.textSecondary} mt-1 fs-16`}>
                {title}
            </Typography>
        </Grid>
    );
}

// default props
NoResultComponent.defaultProps = {
    classes: {},
    title: appConstants.errorMessages.noresult
};

// prop types
NoResultComponent.propTypes = {
    classes: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    noresultSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles(
    (theme) => ({
        ...NoResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(NoResultComponent);