const style = () => ({
    colorPicker: {
        width: 22,
        height: 22,
        borderRadius: 30,
        position: 'relative',
        padding: '0px !important',
        '& .typeColor': {
            width: 22,
            border: 'none',
            height: 22,
            display: 'block',
            opacity: 0,
            position: 'absolute'
        }
    }
});

export default style;