// Default Imports
import React, { useEffect } from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


//Import Styles
import style from "./style.jsx";

const ContinuousSliderComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, value, onSliderValueChange, minMax } = props;

    /**
     * Redux selector function to get Quadrant Settings from redux store
     */
    const { scoring } = useSelector((state) => state.configurations.general);

    /**
     * Define State
     */
    const [sliderValue, setSliderValue] = React.useState(value || [0, 100]);

    /**
     * Update State Value
     */
    useEffect(() => {
        setSliderValue(value);
    }, [value]);

    /**
     * Define the color based on the score
     * @param {*} value
     * @returns
     */
    const getColor = (value) => {
        const { values, quadrant } = scoring || {};
        if (values?.length > 0) {
            const min_value = value[0];
            const max_value = value[1];
            const total_colors = Math.ceil((max_value - min_value) / (100 / quadrant));
            const color_codes = values[quadrant - 2]?.map((color) => color.color);
            const active_colors = [];
            for (let i = 0; i < total_colors; i++) {
                if (min_value < (100 / quadrant)) {
                    active_colors.push(color_codes[i]);
                } else {
                    active_colors.push(color_codes[Math.floor(min_value / (100 / quadrant)) + i]);
                }
            }
            if (active_colors.length === 1) {
                active_colors.push(active_colors[0]);
            }
            return active_colors.toString();
        }
        return '#54DF9A , #54DF9A';
    };

    return (
        <Grid container className="rangeSlider">
            <Grid item className={classes.continuousSlider}>
                <Typography
                    variant="body1"
                    className="fw-500 pl5"
                    align="center"
                >
                    {`${sliderValue[0]} - ${sliderValue[1]}`}
                </Typography>
                <Slider
                    sx={
                        {
                            '& .MuiSlider-track': {
                                background: `linear-gradient(45deg, ${getColor(sliderValue)}) !important`
                            }
                        }
                    }
                    value={sliderValue}
                    onChange={(event, value) => setSliderValue(value)}
                    onChangeCommitted={() => onSliderValueChange(sliderValue)}
                    min={minMax[0]}
                    max={minMax[1]}
                    color="secondary"
                />
            </Grid>
        </Grid>
    );
};

// default props
ContinuousSliderComponent.defaultProps = {
    classes: {},
    value: [0, 100],
    onSliderValueChange: () => { },
    minMax: [0, 100]
};

ContinuousSliderComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.array,
    onSliderValueChange: PropTypes.func,
    minMax: PropTypes.array
};

export default withStyles((theme) => ({
    ...style(theme)
}))(ContinuousSliderComponent);