// Default Imports
import React, { Fragment, useMemo, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Import Constants

// Import Styles
import style from "./style.jsx";
import { useExpanded, useGroupBy, useTable } from 'react-table';
import { CssBaseline, Grid, TablePagination, Tooltip } from '@mui/material';

const MultiDimHeatMap = (props) => {
    const { classes, data, headers, chartProperties: { properties: { yAxis, groupBy } }, chartData } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const heatMapConfig = groupBy[0];
    const colors = heatMapConfig?.color_range || [];

    const tableHeaders = useMemo(() => headers, []);
    const tableRows = useMemo(() => data, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns: tableHeaders, data: tableRows }, useGroupBy, useExpanded);

    const getColorRange = (left, right, parts) => {
        const result = [];
        const delta = (right - left) / (parts - 1);
        while (left < right) {
            result.push(left);
            left += delta;
        }
        result.push(right);
        return result;
    };

    const colorRange = getColorRange(chartData?.min || 0, chartData?.max || 999999, heatMapConfig?.step_count || 5);

    const getHeatmapColor = (value) => {
        if (!yAxis.map((elem) => elem.name).includes(value?.props?.column?.Header) && (value?.props?.value || value?.props?.value === 0)) {
            let colorIndex = colorRange.findIndex((elem) => elem >= value?.props?.value);
            colorIndex = colorIndex > 0 ? colorIndex - 1 : colorIndex;
            if (colorIndex >= 0) {
                return colors[colorIndex] || 'unset';
            }
            return 'unset';
        }
    };

    const getCellStyle = (value) => {
        let style = {};
        if (yAxis.map((elem) => elem.name).includes(value?.props?.column?.Header)) {
            style = {
                minWidth: '40px',
                fontWeight: 600,
                fontSize: '15px'
            };
        } else {
            style = {
                textAlign: 'center'
            };
        }
        if (heatMapConfig?.show_heatmap) {
            style = {
                ...style,
                color: yAxis.map((elem) => elem.name).includes(value?.props?.column?.Header) ? 'unset' : '#FFFFFF',
                backgroundColor: yAxis.map((elem) => elem.name).includes(value?.props?.column?.Header) ? '#ECEFF2' : getHeatmapColor(value),
                textAlign: yAxis.map((elem) => elem.name).includes(value?.props?.column?.Header) ? 'left' : 'center'
            };
        }
        return style;
    };

    /**
     * Handle Change Page
     * @param {*} event
     * @param {*} newPage
     */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /**
     * Handle Row Change
     * @param {*} event
     */
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const getTableData = (data, page, rowsPerPage) => {
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const dataList = useMemo(() => getTableData(rows, page, rowsPerPage), [rows, page, rowsPerPage]);

    return (
        <Fragment>
            {
                heatMapConfig?.show_heatmap &&
                <Grid container spacing={0} style={{ paddingBottom: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item>
                        {chartData?.min ?? 0}
                    </Grid>
                    <Grid item style={{ display: 'flex', flexWrap: 'wrap', padding: '0 12px' }}>
                        {
                            colors.slice(0, heatMapConfig?.step_count || 5).map((item, index) => (
                                <div key={index} style={{ backgroundColor: item, padding: 12 }} />
                            ))
                        }
                    </Grid>
                    <Grid item>
                        {chartData?.max ?? 999999}
                    </Grid>
                </Grid>
            }
            <div className={`${classes.multiDimChartContainer} ${heatMapConfig?.show_heatmap ? classes.multiDimHeatmapContainer : ''}`}>
                <CssBaseline />
                <Table {...getTableProps}>
                    <TableHead>
                        {
                            headerGroups.map((headerGroup, index) => (
                                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, cIndex) => (
                                            <Tooltip key={cIndex} title={column?.render('Header').length && column?.render('Header')} arrow>
                                                <TableCell {...column.getHeaderProps()} style={{ fontSize: '15px', minWidth: 40, fontWeight: 600, background: '#ECEFF2', textAlign: 'center' }}>
                                                    {column.render('Header')}
                                                </TableCell>
                                            </Tooltip>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {
                            dataList.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <TableRow key={i} {...row.getRowProps()} style={{ background: heatMapConfig?.show_heatmap || i % 2 === 0 ? '#FFFFFF' : '#f2f6fa' }}>
                                        {
                                            row.cells.map((cell, cIndex) => {
                                                return (
                                                    <Tooltip key={cIndex} title={cell.render('Cell')?.props?.value} arrow>
                                                        <TableCell {...cell.getCellProps()} style={getCellStyle(cell.render('Cell'))}>
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    </Tooltip>
                                                );
                                            })
                                        }
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Fragment>
    );

};

/**
 * Define Prop Types
 */
MultiDimHeatMap.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.array,
    classes: PropTypes.object,
    chartProperties: PropTypes.object,
    chartData: PropTypes.object
};

/**
 * Set Default Values
 */
MultiDimHeatMap.defaultProps = {
    data: [],
    headers: [],
    classes: {},
    chartProperties: {},
    chartData: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(MultiDimHeatMap, areEqual));