import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, Typography, Tooltip } from '@mui/material';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

function FilterItems(props) {

    /**
     * Define Props
     */
    const { classes, type, header, default_open, data, options, field_key, handleCheckBoxClick, checkboxList, customAccordionSelected,
        handleCustomChange, children, showCount } = props;

    /**
     * Define State
     */
    const [expanded, setExpanded] = useState(default_open ? header : null);

    /**
     * Handle Accordion
     * @param {*} panel
     */
    const handleChange = (panel) => {
        panel = expanded !== panel ? panel : null;
        setExpanded(panel);
    };

    return (
        <Grid className={`${classes.sideBarItem} sideBarItem`}>
            {
                default_open &&
                <Accordion expanded={expanded === header} onChange={() => handleChange(header)}>
                    <AccordionSummary
                        expandIcon={expanded === header ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="title"
                    >
                        <Typography variant="h5" className="fw-500">
                            {header}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item className={`sidebarItemBody ${classes.sidebarItemBody}`}>
                            {children}
                            {
                                checkboxList ?
                                    options.map((option) => (
                                        <FormControlLabel
                                            key={option[field_key]}
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                                    checked={_.findIndex(data, (item) => item === option[field_key]) >= 0}
                                                    onChange={() => handleCheckBoxClick(option, type, field_key)}
                                                />
                                            }
                                            label={
                                                <Typography variant="body1">
                                                    {option.name}
                                                </Typography>
                                            }
                                        />
                                    ))
                                    :
                                    null
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                !default_open &&
                <Accordion expanded={customAccordionSelected === header} onChange={() => handleCustomChange(header)}>
                    <AccordionSummary
                        expandIcon={customAccordionSelected === header ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="title"
                    >
                        <Typography variant="h5" className="fw-500">
                            {header}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item className={`sidebarItemBody ${classes.sidebarItemBody}`}>
                            {children}
                            {
                                checkboxList ?
                                    options.map((option) => (
                                        <FormControlLabel
                                            key={option[field_key]}
                                            control={
                                                <Checkbox
                                                    color="secondary"
                                                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                    checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                                    checked={_.findIndex(data, (item) => item === option[field_key]) >= 0}
                                                    onChange={() => handleCheckBoxClick(option, type, field_key)}
                                                />
                                            }
                                            label={
                                                <Grid className="dflex alignCenter">
                                                    <Tooltip title={option.name} placement="bottom">
                                                        <Typography variant="body1" noWrap className="checkBoxText">
                                                            {option.name}
                                                        </Typography>
                                                    </Tooltip>
                                                    {
                                                        showCount && option.count &&
                                                        <Typography variant="body1" sx={{ textOverflow: 'unset !important' }}>
                                                            {`(${option.count})`}
                                                        </Typography>
                                                    }
                                                </Grid>
                                            }
                                        />
                                    ))
                                    :
                                    null
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </Grid>
    );
}

// default props
FilterItems.defaultProps = {
    classes: {},
    type: '',
    header: '',
    default_open: false,
    options: [],
    data: [],
    field_key: 'id',
    handleCheckBoxClick: () => { },
    checkboxList: false,
    customAccordionSelected: "",
    handleCustomChange: () => { },
    showCount: true
};

// prop types
FilterItems.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    type: PropTypes.string,
    default_open: PropTypes.bool,
    header: PropTypes.string,
    options: PropTypes.array,
    data: PropTypes.array,
    field_key: PropTypes.string,
    handleCheckBoxClick: PropTypes.func,
    checkboxList: PropTypes.bool,
    customAccordionSelected: PropTypes.string,
    handleCustomChange: PropTypes.func,
    showCount: PropTypes.bool
};

export default FilterItems;