// Import API Helper
import { deleteRequest, putRequest, postRequest, getRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";


/**
 * Create Measure
 */
async function create(params) {
    const response = await postRequest(`${urlConstants.measure.url}`, params);
    return response;
}


/**
 * Update Measure
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.measure.url}${id}/`, params);
    return response;
}

/**
 * Get Measure
 */
async function get(query, cancellationToken) {
    const response = await getRequest(`${urlConstants.measure.url}${query}`, cancellationToken);
    return response;
}

/**
 * Get Measure Detail
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.measure.url}${id}/`);
    return response;
}

/**
 * Remove Measure
 */
async function remove(id, purge) {
    const response = await deleteRequest(`${urlConstants.measure.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Get Quality Measures
 */
async function getQualityMeasures() {
    const response = await getRequest(`${urlConstants.measure.getDefaultMeasure} `);
    return response;
}

/**
 * Get Quality Rules
 * @params {*} params
 */
async function getQualityRules(params) {
    const response = await postRequest(`${urlConstants.measure.getMeasureRules} `, params);
    return response;
}

/**
 * Add / Update Attribute Level Pattern
 */
async function enums(params) {
    const response = await postRequest(`${urlConstants.measure.enums}`, params);
    return response;
}

/**
 * Get Asset Measures
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getAssetMeasures(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.measure.assetMeasure}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Update Asset Measure
 */
async function updateAssetMeasure(params) {
    const response = await postRequest(`${urlConstants.measure.updateAssetMeasure}`, params);
    return response;
}

/**
 * Get Failed Rows
 */
async function getMeasurePreview(params) {
    const response = await putRequest(`${urlConstants.measure.getMeasurePreview}`, params);
    return response;
}


/**
 * Export Asset Measure
 */
async function exportAssetMeasure(params) {
    const fileName = params.fileName || "export_measure.csv";
    const response = await postRequest(urlConstants.measure.exportAssetMeasure, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Export Asset Measure
 */
async function syncProfileMeasures() {
    const response = await postRequest(urlConstants.measure.syncProfileMeasure);
    return response;
}

/**
 * Validate Measure
 */
async function validateMeasure(params) {
    const response = await postRequest(`${urlConstants.measure.validateMeasure}`, params);
    return response;
}

/**
 * Add / Update Attribute Level Pattern
 */
async function update_custom_measure() {
    const response = await postRequest(`${urlConstants.measure.updateCustomMeasure}`);
    return response;
}

/**
 * Get Measure Statistics
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getMeasureStatistics(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.measure.measureStatistics}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Measure Status
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getMeasureStatus(measureId) {
    const response = await getRequest(`${urlConstants.measure.getStatus}${measureId}/`);
    return response;
}

/**
 * Export Failed Rows
 */
async function exportFailedRows(params) {
    const fileName = "failed_rows.csv";
    const response = await postRequest(urlConstants.measure.exportFailedRows, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Validate Measure
 */
async function getComparisonMeasure(params) {
    const response = await postRequest(urlConstants.measure.getComparisonMeasure, params);
    return response;
}

/**
 * Validate Measure
 */
async function getMeasureRunStatus() {
    const response = await getRequest(urlConstants.measure.getMeasureRunStatus);
    return response;
}

/**
 * Export All Service Functions
 */
export const measureService = {
    remove, update, create, get, getQualityMeasures, getQualityRules, detail,
    enums, getAssetMeasures, updateAssetMeasure, getMeasurePreview, exportAssetMeasure,
    syncProfileMeasures, validateMeasure, update_custom_measure, getMeasureStatistics, getMeasureStatus, exportFailedRows,
    getComparisonMeasure, getMeasureRunStatus
};