import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Import Private Layout
import { PrivateLayout, DialogueLayout } from "../layouts";

// Import Helpers
import { getCookie } from "../helpers/appHelpers.js";

// Import Component
import License from "../components/license/index.jsx";

// Routes the page with authentication by the user
function PrivateRoute({ component, ...props }) {

  /**
   * Get Login Status
   */
  const loggeInStatus = getCookie('token');
  const logginUser = useSelector(({ auth }) => auth.user);

  const getLayout = () => {
    return logginUser?.license?.is_active ? <PrivateLayout component={component} {...props} /> : <DialogueLayout component={License} {...props} />;
  };

  return loggeInStatus !== '' ? getLayout() : <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
}

// Define Props Types
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string
};

export default PrivateRoute;