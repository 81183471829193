import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';

//import Componetns
import { NumberInputComponent, ValueEditorComponent } from '../../../../../../../components/index.js';

//import Styles
import LengthAndRangeStyle from "./style.jsx";
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../../../constants/appConstants.js';


function LengthAndRange(props) {

    /**
     * Define Props
     */
    const { classes, data, onChange, semanticsPermission } = props;

    return (
        <React.Fragment>
            {/* Length */}
            <Grid item xs={12}>
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.datasetProperties.length}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.propertiesDescription}`}
                    align="left"
                >
                    {
                        appConstants.labels.datasetProperties
                            .lengthDesc
                    }
                </Typography>
                <Grid item className={classes.rangeFields}>
                    <NumberInputComponent
                        id="length-min-ntb"
                        name={'minLength'}
                        value={data.min_length || ""}
                        onChange={(value) => onChange("min_length", value)}
                        fullWidth={false}
                        placeholder={
                            appConstants.labels.datasetProperties
                                .min
                        }
                        variant="outlined"
                        size="small"
                        disabled={!semanticsPermission?.is_edit}
                    />
                    {' '}
                    <span className="rangeHypen">
                        -
                    </span>
                    <NumberInputComponent
                        id="length-max-ntb"
                        name={'maxLength'}
                        value={data.max_length || ""}
                        onChange={(value) => onChange("max_length", value)}
                        fullWidth={false}
                        placeholder={
                            appConstants.labels.datasetProperties
                                .max
                        }
                        variant="outlined"
                        size="small"
                        disabled={!semanticsPermission?.is_edit}
                    />
                </Grid>
                {/* Length */}

                {/* Range */}
                <Grid item xs={12} className="mt-5">
                    <Typography variant="h5" align="left">
                        {
                            appConstants.labels.datasetProperties
                                .range
                        }
                    </Typography>
                    <Typography
                        variant="body1"
                        className={`${classes.propertiesDescription}`}
                        align="left"
                    >
                        {
                            appConstants.labels.datasetProperties
                                .rangeDesc
                        }
                    </Typography>
                    <Grid item className={classes.rangeFields}>
                        <ValueEditorComponent placeholder={
                            appConstants.labels
                                .datasetProperties.min
                        }
                            value={data.min_value || ""}
                            onChange={(event) => onChange("min_value", event.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={!semanticsPermission?.is_edit} />
                        {' '}
                        <span className="rangeHypen">
                            -
                        </span>
                        <ValueEditorComponent placeholder={
                            appConstants.labels
                                .datasetProperties.max
                        }
                            value={data.max_value || ""}
                            onChange={(event) => onChange("max_value", event.target.value)}
                            type={data.datatype || ""}
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={!semanticsPermission?.is_edit} />
                    </Grid>
                </Grid>
                {/* Range */}
            </Grid>
        </React.Fragment>
    );
}

// default props
LengthAndRange.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { },
    semanticsPermission: {}
};

// prop types
LengthAndRange.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func,
    semanticsPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...LengthAndRangeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(LengthAndRange, areEqual));