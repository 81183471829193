import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography, Button } from '@mui/material';

//import Styles
import GeneralStyle from "../../../../style.jsx";
import LayoutStyles from '../../../../../../layouts/style.jsx';

function ImportErrors(props) {

    const { onClose, type } = props;

    // Redux Store
    const errors = useSelector(({ importExport }) => importExport.errors);

    const errorDesc = `The ${type.toLowerCase()} information has been update successfully, except for the following`;
    return (
        <Grid>
            <Typography variant="h6" className="mb-2">
                {errorDesc}
            </Typography>
            {
                errors.map((error, index) =>
                    <Typography key={`import_error_${index}`}>
                        {error}
                    </Typography>
                )
            }
            <Grid item xs={12} className="importListAction mt-2" align="right">
                <Button disableElevation variant="outlined" size="small" onClick={() => onClose()}>
                    Close
                </Button>
            </Grid>
        </Grid>
    );
}

// default props
ImportErrors.defaultProps = {
    onClose: () => { },
    type: ''
};

// prop types
ImportErrors.propTypes = {
    onClose: PropTypes.func,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ImportErrors);