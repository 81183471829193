import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import Components
import AlertSlideCard from './card.jsx';
import { SliderComponent } from '../../../../../components/index.js';

// Image Images
import { ChevLeftIcon, ChevRightIcon, DangerIcon } from '../../../../../assets/svg/index.js';

//Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

function SlideTemplateComponent(props) {

    /**
     * Define Props
     */
    const { alert_data, sliderTitle, classes } = props;
    const containerRef = useRef();

    /**
     * Set State
     */
    const [carouselRef, setCarouselRef] = useState(null);
    const [dragging, setDragging] = useState(false);

    /**
     * Handle Carosel Event
     * @param {*} slider
     */
    const setCarouselReference = (slider) => {
        setCarouselRef(slider);
    };

    /**
     * Set Width
     */
    const width = containerRef.current ? containerRef.current.clientWidth - 85 : 0;

    return (
        <Grid className={classes.alertsCardContainer} ref={containerRef}>
            <Grid
                container
                justifyContent={'space-between'}
                alignItems="center"
                item
                align="right"
            >

                <Typography variant="h6" className="pb-1 pl5 dflex alignCenter">
                    <DangerIcon />
                    <span className="pl5">
                        {sliderTitle}
                        (
                        {alert_data.length ? alert_data.length : 0}
                        )
                    </span>
                </Typography>
                <Grid item>
                    <IconButton onClick={() => carouselRef.slickPrev()}>
                        <ChevLeftIcon />
                    </IconButton>
                    <IconButton onClick={() => carouselRef.slickNext()}>
                        <ChevRightIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <SliderComponent setCarouselRef={setCarouselReference} featureSlider variableWidth handleDrag={(drag) => setDragging(drag)}>
                {
                    alert_data &&
                    alert_data.length > 0 &&
                    width > 0 &&
                    alert_data.map((slide_item, index) => (
                        <AlertSlideCard
                            style={{ width: `${width}px` }}
                            key={index}
                            index={index}
                            slide_item={slide_item}
                            dragging={dragging}
                        />
                    ))
                }
            </SliderComponent>
        </Grid >
    );
}

// default props
SlideTemplateComponent.defaultProps = {
    alert_data: [],
    sliderTitle: "",
    classes: {}
};

// prop types
SlideTemplateComponent.propTypes = {
    alert_data: PropTypes.array,
    sliderTitle: PropTypes.string,
    classes: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.alert_data, nextProps.alert_data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(SlideTemplateComponent, areEqual));