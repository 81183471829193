const style = (theme) => ({
    recentQueries: {
        marginTop: 30
    },
    topContainer: {

    },
    blueContainer: {
        background: `${theme.palette.secondary.main}08`,
        // background: `${theme.palette.secondary.main}08`,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 4,
        padding: '2px 15px 15px',
        height: '100%',
        maxHeight: '350px',
        minHeight: '300px',
        '& .byroleNumber': {
            color: theme.palette.secondary.main,
            paddingBottom: 5,
            fontWeight: 400
        },
        '& .btnTabsInline ': {
            width: '100%'
        },
        '& .loaderContainer  ': {
            height: 280
        },
        '& .MuiTab-root': {
            fontSize: `${theme.palette.headers.h5.size}px !important`
        }
    },
    daysFilter: {
        background: '#fff',
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    },
    tabsBody: {
        '& .MuiBox-root': {
            padding: '14px 0px 0px'
        }
    },
    queryTabsContanier: {
        overflowY: 'auto',
        height: '280px',
        paddingRight: '10px',
        '& .listItem:last-child': {
            marginBottom: '0px !important'
        }
    },
    countValue: {
        display: 'inline-flex',
        background: `${theme.palette.secondary.main}30`,
        padding: '4px 12px',
        borderRadius: '4px'
    }
});
export default style;