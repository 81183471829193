const style = (theme) => ({
    shareDialog: {
        '& .MuiPaper-root': {
            overflow: 'hidden'
        }
    },
    shareContainer: {
        padding: '30px 0px 30px 30px',
        '& .CloseIcon': {
            width: '30px',
            height: '30px'
        },
        '& .closeButton': {
            width: 35,
            height: 35,
            padding: 5
        }
    },
    shareBodyContainer: {
        maxHeight: `calc(100vh - 160px)`,
        overflowY: 'auto',
        paddingRight: '30px'
    },
    shareContainerImg: {
        width: '100%',
        objectFit: 'fill',
        height: '200px',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        objectPosition: 'top'

    },
    copyDatasetShare: {
        background: theme.palette.greyshades.lightestgrey,
        border: `1px solid ${theme.palette.greyshades.lightgrey}40`,
        borderRadius: '0px 4px 4px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 9px',
        height: 36,
        maxWidth: 'calc(100% - 100px)',
        '& .datasetIcon img': {
            width: 20,
            height: 20,
            marginRight: 8,
            marginBottom: '-1px'
        },
        '& .copyIcon': {
            background: '#fff',
            height: '34px',
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0px 4px 4px 0px',
            minWidth: '30px',
            cursor: 'pointer',
            '&:hover ': {
                '& #Path_2746': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .datasetIcon ': {
            display: 'flex'
        },
        '& .MuiTypography-root': {
            lineHeight: '15px'
        }
    }
});
export default style;