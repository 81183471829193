import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Dialog, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

//  Import Styles
import ListStyle from '../style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Images
import { CloseIcon } from '../../../../../assets/svg/index.js';

// Import Components
import { TableComponent } from '../../../../../components/index';

// Import Redux
import { setBehaviourDetailByKey } from '../../../../../redux/reducer/metricReducer';
import { permissionHeaders } from '../../../../../helpers/appHelpers.js';

function BehaviourlList(props) {

    /**
     * Define Props
     */
    const { classes, open, handleBehaviourlList, selectedKey, propertiesPermission } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });

    /**
     * Define Redux
     */
    const { behavioral_history } = useSelector((state) => state.metric);

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(behavioral_history || [], searchData, sorting.sortBy, sorting.orderBy), [behavioral_history || [], searchData, sorting.sortBy, sorting.orderBy]);

    /**
     * Change Bechaviour Key
     * @param {*} params
     */
    const selectBehaviourKey = (params) => {
        dispatch(setBehaviourDetailByKey(params?.measure_name));
        handleBehaviourlList();
    };

    // Behaviour Table Header
    const behaviourQualityTable = [
        {
            key: 'measure_name', name: 'Context', sorting: true, tooltip: true, width: "30%", isSearch: true,
            searchComponent: "text", searchKey: "measure_name", clickable: true, customFunction: selectBehaviourKey,
            removeCasing: true
        },
        {
            key: 'last_runs',
            name: 'Last Result',
            align: 'center',
            component: "drift runs"
        }
    ];

    return (
        <Grid item xs={12} className={classes.listContainer}>
            <Dialog
                open={open}
                maxWidth={"lg"}
                fullWidth
                onClose={handleBehaviourlList}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid className={classes.behaviourContainer}>
                    <Grid className="dflex alignCenter spaceBetween closeContainer">
                        {/* <Typography variant="h5" className="">
                            Context History
                        </Typography> */}
                        <IconButton onClick={handleBehaviourlList}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={classes.behaviouralTable}>
                        <TableComponent
                            title="Context History"
                            styleType="striped"
                            options={[{ type: "search", customFunction: null }]}
                            headers={permissionHeaders(behaviourQualityTable, propertiesPermission)}
                            data={filterData || []}
                            NoResultText="No Category Found"
                            searchData={searchData}
                            sortBy={sorting.sortBy}
                            orderBy={sorting.orderBy}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickSorting={onClickSorting}
                            highlightIndex={filterData.findIndex((i) => i.measure_name === selectedKey)}
                            stickyHeader
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
BehaviourlList.defaultProps = {
    classes: {},
    open: false,
    selectedKey: '',
    handleBehaviourlList: () => { },
    propertiesPermission: {}

};

// prop types
BehaviourlList.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    selectedKey: PropTypes.string,
    handleBehaviourlList: PropTypes.func,
    propertiesPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(BehaviourlList);