import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

//  Import Styles
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { updateUserPreference } from '../../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../../redux/reducer/userReducer';

// Import Helpers
import { prepareUpdateUserPreference, getUserPreference } from '../../../../../helpers/appHelpers';

function TalendRuns(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "talend_runs", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "talend_runs", "sorting");

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "talend_runs", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        filterData = filterData.map((obj) => {
            let duration = "";
            if (obj.startTimestamp && obj.finishTimestamp) {
                const startDate = new Date(obj.startTimestamp);
                const endDate = new Date(obj.finishTimestamp);
                const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
                duration = Math.round(seconds);
            }
            return {
                ...obj,
                duration: `${duration} S`
            };
        });
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    let value = item[key] || "";
                    value = typeof (item[key]) === "number" ? value.toString() : value;
                    if (typeof (value) === 'string' && !value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], searchData, sorting?.sortBy ?? "", sorting?.orderBy ?? "asc"), [data || [], searchData, sorting?.sortBy ?? "", sorting?.orderBy ?? "asc"]);

    return (
        <Grid item xs={12} className={classes.PreviewContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <TableComponent
                    title="Runs"
                    headers={appConstants.table.talendRunsListHeader}
                    stickyHeader
                    data={filterData}
                    options={appConstants.tableOptions.common}
                    searchData={searchData}
                    sortBy={sorting?.sortBy ?? ''}
                    orderBy={sorting?.orderBy ?? 'asc'}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onClickSorting={onClickSorting}
                    styleType="striped"
                    NoResultText="No Records Found"
                    height="calc(100vh - 450px)"
                    userPreferenceColumns={columns || []}
                    onColumnChange={(columns) => onColumnsChange(columns)}
                />
            </Grid>
        </Grid>
    );
}

// default props
TalendRuns.defaultProps = {
    classes: {},
    data: []
};

// prop types
TalendRuns.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export As Memo Component
 */

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(TalendRuns, areEqual));