import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Typography, IconButton, MenuItem, Tooltip } from '@mui/material';

// Import Component
import { PopOverComponent } from '../../../../../components/index.js';
import MeasureFilterSaveTemplate from './saveTemplate.jsx';


//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Icons
import { MoreIcon, ScoreIcon, MonitoringIcon } from '../../../../../assets/svg/index.js';
import CloseIcon from '@mui/icons-material/Close';


function MeasureFilter(props) {


    /**
     * Define Props
     */
    const { classes, onSaveTemplatePreference, customTemplates, selectedTemplate } = props;
    const defaultTypes = [
        { label: "All", description: "All enabled Measures across Scoring and Monitoring" },
        { label: "Scoring", description: "Scoring enabled Measures", icon: <ScoreIcon /> },
        { label: "Monitoring", description: "Monitoring enabled Measures", icon: <MonitoringIcon /> }
    ];


    // Define State
    const [openMenuAnchorEl, setMenuOption] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [template, setTemplate] = useState("All");
    const [templates, setTemplates] = useState([...defaultTypes]);

    /** Update selected template view */
    useEffect(() => {
        setTemplate(selectedTemplate);
    }, [selectedTemplate]);


    /**
     * Handle Filter Option
     * @param {*} event
     */
    const handleMenuOptions = (event) => {
        setMenuOption(event.currentTarget);
    };

    /**
     * On Close Menu
     */
    const onCloseMenu = () => {
        setMenuOption(null);
    };


    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }
        setTemplate(value);
        onSaveTemplatePreference("template", value);
    };

    /**
     * On Save Template
     * @param {*} filter
     */
    const onSaveTemplate = (filter) => {
        const value = {
            template: filter.name,
            templateIcon: filter.icon
        };
        onSaveTemplatePreference("newTemplate", value);
        setTemplate(filter.name);
        setOpenDialog(false);
    };

    /**
     * Map Templates
     * @param {*} templates
     */
    const mapTemplates = (templates) => {
        const newTemplates = templates.map((template) => {
            return {
                customTemplate: true,
                label: template.template,
                icon: template.templateIcon !== null ? <img src={require(`../../../../../assets/img/templateIcons/icon${template.templateIcon}.png`)} alt="" /> : null
            };
        });
        setTemplates([...newTemplates, ...defaultTypes]);
    };

    /**
     * Delete Template
     * @param {*} index
     */
    const deleteTemplate = (index) => {
        const template = [...templates];
        template.splice(index, 1);
        setTemplates([...template]);
        onSaveTemplatePreference("delete_template", { index });
        setTemplate("All");
    };

    /**
     * Map Template
     */
    useEffect(() => {
        if (customTemplates && customTemplates.length) {
            mapTemplates(customTemplates);
        }
    }, [customTemplates]);
    const currentTemplate = templates.find((item) => item.label === template);

    return (
        <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
            <Grid item className={classes.filter}>
                <Typography variant="body1" className={classes.textSecondary}>
                    {currentTemplate?.description || ""}
                </Typography>
            </Grid>
            <Grid item className={classes.filter}>
                <ToggleButtonGroup
                    color="secondary"
                    value={template || 'All'}
                    exclusive
                    aria-label="text alignment"
                    onChange={(event, value) => handleFilterOnChange(value)}
                >
                    {
                        templates.map((data, index) =>
                            <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                <Tooltip title={data.label} arrow>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {data.label}
                                    </Typography>
                                </Tooltip>
                                {
                                    data.customTemplate &&
                                    <IconButton className="deleteIcon" onClick={(event) => { event.stopPropagation(); deleteTemplate(index); }}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                            </ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
                <IconButton className="p5" onClick={handleMenuOptions}>
                    <MoreIcon />
                </IconButton>
                {
                    openMenuAnchorEl &&
                    <PopOverComponent anchorEl={openMenuAnchorEl} onClose={onCloseMenu}>
                        <Grid className={classes.popoverDiv}>
                            <MenuItem onClick={() => { setOpenDialog(true); onCloseMenu(); }}>
                                {"Save View"}
                            </MenuItem>
                        </Grid>
                    </PopOverComponent>
                }
                <MeasureFilterSaveTemplate
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    onSaveTemplate={onSaveTemplate}
                />
            </Grid>
        </Grid>
    );
}

// default props
MeasureFilter.defaultProps = {
    classes: {},
    onSaveTemplatePreference: () => { },
    customTemplates: [],
    selectedTemplate: "All"
};

// prop types
MeasureFilter.propTypes = {
    classes: PropTypes.object,
    onSaveTemplatePreference: PropTypes.func,
    customTemplates: PropTypes.array,
    selectedTemplate: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(MeasureFilter);