const appConstants = {
    localStorageKey: 'dqlabs',
    save: "Save",
    cancel: "Cancel",
    defaultSchemName: "",
    header: {
        settings: 'Settings',
        connector: 'Connection',
        catalog: 'Explore',
        domains: 'Explore',
        asset: 'Overview',
        myprofile: 'Profile'
    },
    notifications_type: {
        invite_user: 'invite_user'
    },
    connectionTypes: {
        tableau: {
            name: "Tableau",
            value: "tableau"
        },
        snowflake: {
            name: "Snowflake",
            value: "snowflake"
        },
        mssql: {
            name: "Mssql",
            value: "mssql"
        },
        redshift: {
            name: "Redshift",
            value: "redshift",
            disableLink: true
        },
        redshift_spectrum: {
            name: "Redshift Spectrum",
            value: "redshift_spectrum",
            disableLink: true
        },
        denodo: {
            name: "Denodo",
            value: "denodo",
            disableLink: true
        },
        dbt: {
            name: "Dbt",
            value: "dbt"
        },
        adf: {
            name: "Azure Data Factory",
            value: "adf"
        },
        oracle: {
            name: "Oracle",
            value: "oracle",
            disableLink: true
        },
        postgresql: {
            name: "Postgresql",
            value: "postgresql",
            disableLink: true
        },
        databricks: {
            name: "Databricks",
            value: "databricks",
            disableLink: true
        },
        bigquery: {
            name: "Bigquery",
            value: "bigquery",
            disableLink: true
        },
        db2: {
            name: "DB2",
            value: "db2"
        },
        fivetran: {
            name: "Fivetran",
            value: "fivetran"
        },
        saphana: {
            name: "SapHana",
            value: "saphana",
            disableLink: true
        },
        athena: {
            name: "Athena",
            value: "athena",
            disableLink: true,
            notQueryTable: true
        },
        teradata: {
            name: "Teradata",
            value: "teradata",
            disableLink: true
        },
        synapse: {
            name: "Synapse",
            value: "synapse",
            disableLink: true
        },
        airflow: {
            name: "Airflow",
            value: "airflow"
        },
        emr_spark: {
            name: "EMR Spark",
            value: "emr_spark",
            disableLink: true,
            notQueryTable: true
        },
        talend: {
            name: "Talend",
            value: "talend"
        },
        powerbi: {
            name: "PowerBI",
            value: "powerbi"
        },
        hive: {
            name: "Hive",
            value: "hive",
            disableLink: true
        }
    },
    healthStatistics: ["duplicates count"],
    frequencyMeasures: ["length", "pattern", "enum", "regular expressions", "long_pattern", "short_pattern"],
    catalog_sort_by: [
        {
            id: 'name',
            name: 'Name'
        },
        {
            id: 'dqscore',
            name: 'DQ Score'
        },
        {
            id: 'ratings',
            name: 'Ratings'
        },
        {
            id: 'alerts',
            name: 'Alerts'
        },
        {
            id: 'issues',
            name: 'Issues'
        },
        {
            id: 'connection_type',
            name: 'Connection Type'
        },
        {
            id: 'created_date',
            name: 'Created Date'
        },
        {
            id: 'modified_date',
            name: 'Modified Date'
        }
    ],
    connections_sort_by: [
        {
            id: 'name',
            name: 'Name'
        },
        {
            id: 'type',
            name: 'Connection Type'
        },
        {
            id: 'created_date',
            name: 'Created Date'
        },
        {
            id: 'modified_date',
            name: 'Modified Date'
        }
    ],
    filter_by: [
        {
            id: 'alerts',
            name: 'With Alerts'
        },
        {
            id: 'without_alerts',
            name: 'Without Alerts'
        },
        {
            id: 'issues',
            name: 'With Issues'
        },
        {
            id: 'without_issues',
            name: 'Without Issues'
        },
        {
            id: 'conversations',
            name: 'With Conversations'
        },
        {
            id: 'failed_pipelines',
            name: 'With Pipeline Failure'
        },
        {
            id: 'favorites',
            name: 'My Favorites'
        },
        {
            id: 'running',
            name: 'Running Jobs'
        }
    ],
    drift: {
        high: 'critical',
        medium: 'medium',
        low: 'low',
        ok: 'ok'
    },
    drift_priority: [
        { "id": "High", "name": 'High' },
        { "id": "Medium", "name": 'Medium' },
        { "id": "Low", "name": 'Low' }
    ],
    drift_condition: [
        {
            "id": "isGreaterThan",
            "name": "Is Greater Than"
        },
        { "id": "isLessThan", "name": "Is Less Than" },
        { "id": "isGreaterThanOrEqualTo", "name": "Is Greater Than or Equal To" },
        { "id": "isLessThanOrEqualTo", "name": "Is Less Than or Equal To" },
        { "id": "isEqualTo", "name": "Is Equal To" },
        { "id": "isBetween", "name": "Is Between" },
        { "id": "isNotBetween", "name": "Is Not Between" }
    ],
    popover: {
        save: 'Save',
        cancel: 'Cancel'
    },
    dialogBox: {
        delete: 'Confirm Delete',
        connectionDeleteMessage: 'Are you sure to delete this connection?',
        assetDeleteMessage: 'Are you sure to delete this asset?',
        imageDeleteMessage: 'Are you sure to delete this Image?',
        userDeleteMessage: 'Are you sure to delete this user?',
        measureDeleteMessage: 'Are you sure to delete this measure?',
        purge: 'Purge & Delete',
        deleteSchedule: 'Are you sure to delete the current schedule?',
        roleDeleteMessage: 'Are you sure to delete this role?',
        dimensionDeleteMessage: 'Are you sure to delete this dimension?',
        referenceDeleteMessage: 'Are you sure to delete this reference <type>?',
        applicationDeleteMessage: 'Are you sure to delete this application?',
        tagsDeleteMessage: 'Are you sure to delete this tag?',
        widgetDeleteMessage: 'Are you sure to delete this widget?',
        dashboardDeleteMessage: 'Are you sure to delete this dashboard?',
        channelDeleteMessage: 'Are you sure to delete this channel?',
        updateMeasureMessage: 'Are you sure want to apply the changes?',
        fieldDeleteMessage: 'Are you sure to delete this field?',
        issueDeleteMessage: 'Are you sure to delete this Issue?',
        schemaRemoveMessage: 'Are you sure to remove this schema and related assets?',
        reportDeleteMessage: 'Are you sure to delete this report?',
        noDetailedLogMessage: 'Cannot find the detailed log for this task.',
        depthUpdate: 'Confirm Update',
        depthUpdateMessage: 'Changing the depth will reset measure alerts and anamoly, Are you sure to change the depth of this asset?'
    },
    dropdowns: {
        assetFilter: ['All', 'Selected'],
        dimension: ['dimension'],
        reportFormat: ["PDF", 'CSV'],
        reportDateRange: [
            {
                name: 'Today',
                value: 'today'
            },
            {
                name: 'Yesterday',
                value: '1'
            },
            {
                name: '3 Days',
                value: '3'
            },
            {
                name: '7 Days',
                value: '7'
            },
            {
                name: '14 Days',
                value: '14'
            },
            {
                name: '30 Days',
                value: '30'
            },
            {
                name: 'Last Run',
                value: 'last_run'
            },
            {
                name: 'All',
                value: 'all'
            }
        ],
        aggregation: [
            {
                name: 'Count',
                value: 'count'
            },
            {
                name: 'Group',
                value: 'group'
            }
        ]
    },
    toggle: {
        tableAndQuery: [
            {
                name: 'Table',
                value: 'table'
            },
            {
                name: 'Query',
                value: 'query'
            }
        ],
        viewTypes: [
            {
                name: 'Table',
                value: 'Table'
            },
            {
                name: 'View',
                value: 'View'
            }
        ],
        tableQueryAndPipeline: [
            {
                name: 'Table',
                value: 'table'
            },
            {
                name: 'Query',
                value: 'query'
            },
            {
                name: 'Pipeline',
                value: 'pipeline'
            }
        ],
        usersAndRoles: [
            {
                name: 'Users',
                value: 'users',
                featureName: 'Users',
                subFeatureName: 'View Users',
                restricted: true
            },
            {
                name: 'Roles',
                value: 'roles',
                featureName: 'Roles',
                subFeatureName: 'View Roles',
                restricted: true
            },
            {
                name: 'Activity',
                value: 'activity',
                featureName: 'Roles',
                subFeatureName: 'View activity',
                restricted: true
            }
        ],
        conditionalAndQuery: [
            {
                name: 'Conditional',
                value: 'conditional'
            },
            {
                name: 'Query',
                value: 'query'
            },
            {
                name: 'Behavioral',
                value: 'behavioral'
            },
            {
                name: 'Lookup',
                value: 'lookup'
            }
        ],
        semanticMeasureCategory: [
            {
                name: 'Conditional',
                value: 'conditional'
            },
            {
                name: 'Query',
                value: 'query'
            }
        ],
        semanticConditional: [
            {
                name: 'Conditional',
                value: 'conditional'
            }
        ],
        connectionMeasureCategory: [
            {
                name: 'Query',
                value: 'query'
            },
            {
                name: 'Comparison',
                value: 'comparison'
            }
        ],
        ruleSelector: [
            {
                name: 'AND',
                value: 'and'
            },
            {
                name: 'OR',
                value: 'or'
            }
        ],
        ruleSelectorNot: [
            {
                name: 'NOT',
                value: 'not'
            }
        ],
        usageTab: [
            {
                name: 'Preview',
                value: 'preview'
            },
            {
                name: 'Queries',
                value: 'queries'
            }
        ],
        semanticsTab: ['Domains', 'Applications', 'Tags']
    },
    labels: {
        authentication: {
            copyrights: 'DQLabs, Inc.',
            email: 'Email',
            name: "Name",
            password: 'Password',
            newPassword: 'New Password',
            firstName: 'First Name',
            lastName: 'Last Name',
            confirmPassword: 'Confirm Password',
            login: 'Login',
            submit: 'Submit',
            resetPassword: 'Reset Password',
            changePassword: 'Change Password',
            forgotPassword: 'Forgot Password',
            ssoLogin: 'Single Sign On',
            backtoLogin: 'Back to login',
            sso: 'SSO',
            signingFirstTime: 'Signing in for the first time ?',
            signInWithEmail: 'Sign in with your email',
            enterEmailId: 'Enter Email ID',
            sendMagicLink: 'Send Magic Link',
            magicLink: 'Magic Link',
            validEmailIdEntered:
                'If a valid email has been enterd, you will receive an email with a MagicLink.',
            linkToSignin: 'Please use that link to sign in.',
            ok: 'Ok',
            passPhrase: 'Pass Phrase',
            token: "Token",
            client_secret: 'Client Secret'
        },
        home: {
            welcome: 'Welcome ',
            typeAnyKeywords: 'Type any word to explore',
            globalSearch: 'Type any word to search across all assets'
        },
        settings: {
            settings: 'Settings'
        },
        connection: {
            configure: 'Configure',
            configureDescription: 'Manage existing Connections or add new',
            connector: 'Connectors',
            connectorDescription:
                'Choose from the varied list of industry leading connectors to integrate your data seamlessly.',
            assets: 'Assets',
            searchConnection: 'Search connection',
            sortBy: 'Sort By '
        },
        connector: {
            connectionName: 'Connection Name',
            description: 'Description',
            account: 'Account',
            database: 'Database',
            httppath: 'Http Path',
            warehouse: 'Warehouse',
            schema: 'Schema',
            user: 'User',
            username: 'Username',
            connect: 'Connect',
            validate: 'Validate',
            save: 'Save',
            connectDetail:
                'Search to select and configure the datasets and attribute that you prefer connect',
            Cancel: 'Cancel',
            searchConnector: 'Search connector',
            searchDataset: 'Search Asset',
            authType: "Authentication Type",
            serverName: "Server Name",
            server: "Server",
            port: 'Port',
            databaseName: 'Database Name',
            workspace_name: 'Workspace Name',
            catalogName: 'Catalog Name',
            schemaName: 'Schema Name',
            awsAccessKey: "AWS Access Key",
            awsSecretAccessKey: "AWS Secret Access Key",
            secretName: "Secret Name",
            region: "Region",
            location: "Location",
            host: 'Host',
            authentication_type: 'Authentication Type',
            driver_type: 'Driver Type',
            site: "Default Site",
            personal_access_token_name: "Personal Access Token Name",
            personal_access_token_key: "Personal Access Token Key",
            ssl: 'SSL',
            account_id: "Account ID",
            apiKey: 'API Key',
            api_secret: 'API Secret',
            sid: 'SID',
            web_hook_key: 'Web Hook Key',
            passPharse: 'Pass Pharse',
            enable_web_hook: "Enable Web Hook",
            refresh_token: "Refresh Token",
            user_id: "User Id",
            s3StagingDir: 'S3 Staging Directory',
            workgroup: 'Workgroup',
            clusterID: 'Cluster ID',
            export: 'Fields to push',
            import: 'Fields to pull',
            push_semantic_tags: 'Push Semantic Terms',
            environments: "Environments",
            projects: "Projects",
            workspaces: "Workspaces",
            endpoint: "Endpoint",
            circuit_breaker: 'Circuit Breaker',
            auth_key: "Basic Auth Key",
            tenant_id: 'Tenant ID',
            subscription_id: 'Subscription ID',
            resource_group_name: 'Resource Group',
            data_factory_name: 'Data Factory Name',
            client_id: 'Client ID',
            client_secret: 'Client Secret',
            scope: 'Scope URL',
            workspace_id: 'Workspace ID',
            lineage: 'Enable Lineage',
            snowflake: {
                title: 'Snowflake Connector',
                description:
                    'Please provide the below credentials to connect. Ensure you have a read only permission for the provided service account.',
                authentication_types: ['Username and Password', 'Key Based Authentication']
            },
            redshift: {
                title: 'Configure Redshift Connection',
                description: 'Connect to Redshift. Please have the connections details',
                authentication_type_dd: ['Username/Password', 'Secret Manager']
            },
            redshift_spectrum: {
                title: 'Configure Redshift Spectrum Connection',
                description: 'Connect to Redshift Spectrum. Please have the connections details',
                authentication_type_dd: ['Username/Password', 'Secret Manager']
            },
            msSql: {
                title: 'SQL Connection',
                description: 'Please provide the below credentials to connect. Ensure you have a read only permission for the provided service account.',
                authentication_type_dd: ['Username and Password', 'Windows Authentication']
            },
            mysql: {
                title: 'Configure MySQL Connection',
                description: 'Connect to MySQL. Please have the connections details'
            },
            postgress: {
                title: 'Configure PostgreSQL Connection',
                description: 'Connect to PostgreSQL. Please have the connections details'
            },
            tableau: {
                title: 'Tableau Connection',
                description: 'Connect to Tableau. Please have the connections details',
                authentication_type_dd: ['Username and Password', 'Personal Access Token']
            },
            denodo: {
                title: 'Denodo Connection',
                description: 'Connect to Denodo using JDBC / ODBC. Please have the connections details',
                driver_type: ['jdbc', 'odbc']
            },
            dbt: {
                title: 'dbt Cloud',
                description: 'Connect to dbt Cloud. Please have the connections details'
            },
            adf: {
                title: 'Azure Data Factory',
                description: 'Connect to Azure Data Factory. Please have the connections details'
            },
            oracle: {
                title: 'Configure Oracle Connection',
                description: 'Connect to Oracle. Please have the connections details'
            },
            databricks: {
                title: 'Databricks Connection',
                description: 'Connect to Databricks. Please have the connections details',
                driver_type: ['jdbc', 'odbc']
            },
            bigquery: {
                title: 'Bigquery Connection',
                description: 'Connect to Bigquery. Please have the JSON file'
            },
            db2: {
                title: 'DB2 Connection',
                description: 'Connect to DB2. Please have the connections details'
            },
            fivetran: {
                title: 'Fivetran Connection',
                description: 'Connect to Fivetran. Please have the connections details'
            },
            saphana: {
                title: 'SapHana Connection',
                description: 'Connect to SapHana using JDBC / ODBC. Please have the connections details'
            },
            athena: {
                title: 'Configure AWS Athena Connection',
                description: 'Connect to AWS Athena. Please have the connections details'
            },
            teradata: {
                title: 'Configure Teradata Connection',
                description: 'Connect to Teradata. Please have the connections details',
                driver_type: ['jdbc', 'odbc']
            },
            synapse: {
                title: 'Azure Synapse Connection',
                description: 'Please provide the below credentials to connect. Ensure you have a read only permission for the provided service account.'
            },
            airflow: {
                title: 'Airflow',
                description: 'Airflow™ is a platform created by the community to programmatically author, schedule and monitor workflows.',
                authentication_type_dd: ['Username and Password', 'Basic Key Authentication']
            },
            emr_spark: {
                title: 'Configure AWS EMR Connection',
                description: 'Connect to AWS EMR. Please have the connections details'
            },
            talend: {
                title: 'Talend',
                description: 'Connect to Talend Cloud. Please have the connections details'
            },
            powerbi: {
                title: 'Power BI Connection',
                description: 'Connect to Power BI. Please have the connections details',
                authentication_type_dd: ['Service Principle', 'Master User']
            },
            hive: {
                title: 'Hive Connection',
                description:
                    'Please provide the below credentials to connect. Ensure you have a read only permission for the provided service account.',
                authentication_types: ['Cloudera', 'Standalone']
            }
        },
        datasetOverview: {
            description: 'Description',
            quality: 'Quality',
            domain: 'Domain',
            domainDesc: 'Refers to a logical grouping of related data that has meaning to the business.',
            terms: 'Terms',
            termsDesc: 'Business definition to define taxonomy, provide more context on its purpose.',
            applications: 'Applications',
            applicationsDesc: 'Systems related to this asset. It could be a publishing or consuming system.',
            measureDesc: 'Systems related to this measure. It could be a publishing or consuming system.',
            devConnection: 'Dev Connection',
            datasize: 'Datasize',
            tables: 'Tables',
            type: 'Type',
            datasets: "Datasets",
            columns: 'Attributes',
            rows: 'Rows',
            monitored: 'Monitored',
            featured: 'Featured',
            alerts: 'Alerts',
            identifier: 'Identifier',
            identifierDesc: 'Primary Key or Composite Key to identify duplicates.',
            site: 'Site',
            workbook: 'Workbook',
            project: 'Project',
            owner: 'Owner',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
            databases: 'Databases',
            fields: 'Fields',
            database: 'Database',
            schema: 'Schema',
            last_run_at: "Last Run At",
            model_type: "Type",
            tags: "Tags",
            job_runs: 'Runs',
            service: 'Service',
            tests: "Tests",
            status: "Status",
            format: "Format",
            latest_events: "Latest Events",
            tasks: "Tasks",
            activity: "Activity",
            next_run: "Next Run",
            active: "Active",
            paused: "Paused",
            sub_dag: "Sub DAG",
            schedule: "Schedule",
            workspace_name: "Workspace",
            environment_name: "Environment",
            pipleline_type: "Pipeline Type",
            publisher: "Publisher",
            dataset: "Dataset",
            dataset_configuredBy: "Dataset Configured By",
            datasoure: 'Datasource',
            pages: 'Pages'
        },
        datasetProperties: {
            termDetail:
                'Detailed information about the filed that shall help other users of the platform understand its purpose, use, business context.',
            nullBlankUnique: 'Null/ Blank/ Unique',
            nullBlankUniqueDesc:
                'Metadata definition for field-level profiling to decide whether it can be Nullable Blank or Unique.',
            primary: 'Primary',
            primaryDesc:
                'The primary key is a dataset attribute, or combination of columns, to uniquely identify dataset records.',
            report: 'Report',
            null: 'Null',
            blank: 'Blank',
            unique: 'Unique',
            primaryKey: 'Primary Key',
            off: 'OFF',
            on: 'ON',
            dimension: 'Dimension',
            active: "Active",
            measure: 'Measure',
            monitored: "Monitoring",
            scored: 'Scoring',
            terms: 'Terms',
            termsDesc:
                'Business definition or type to define taxonomy, provide more context on its purpose.',
            applcation: 'Applcation',
            pattern: 'Pattern',
            patternDesc:
                'Reference lists of valid and invalid regular expressions for the attribute.',
            length: 'Length',
            lengthDesc:
                'Minimum and maximum length for the attribute.',
            enumeration: 'Enumeration',
            enumerationDesc:
                'Valid values list for the attribute.',
            range: 'Range',
            rangeDesc:
                'Minimum and maximum range for the attribute.',
            tags: 'Tags',
            tagsDesc:
                'A logical grouping keyword to bring disparate attributes together for reporting.',
            min: 'Min',
            max: 'Max',
            save: 'Save',
            update: 'Update',
            cancel: 'Cancel',
            search: 'Search',
            description: 'Description',
            totalRecords: 'Total',
            validRecords: 'Valid',
            invalidRecords: 'Invalid',
            passed: 'Passed',
            failed: 'Failed',
            columns: 'Schema',
            volume: 'Volume',
            freshness: 'Freshness',
            duplicate: 'Duplicates'
        },
        quality: {
            rules: 'Rules',
            alerts: 'Alerts',
            passed: 'Passed',
            failed: 'Failed',
            columns: 'Columns',
            volume: 'Volume',
            freshness: 'Freshness',
            duplicate: 'Duplicates',
            total: 'Total',
            invalid: 'Invalid',
            export: 'Export Data Quality',
            frequency: "Frequency",
            frequencydesc: "Define Frequency related measures to understand the format and enumeration of data assets.",
            distribution: 'Distribution',
            distributiondesc: 'Define Data Distribution related measures to measure and understand the content of data assets.',
            statistics: 'Statistics',
            statisticsdesc: 'Define Statistics related measures to figure out suitability the data is for its intended applications in data analytics, data science, or machine learning.',
            measuredesc: 'Define Health related measures to measure data reliability or health check.',
            patternsListDesc: "Define Patterns to understand the format of data assets.",
            volumeTypes: ["Total Rows", "%Growth"]
        },
        tooltips: {
            delete: 'Delete',
            edit: 'Edit',
            copy: 'Copy',
            connectionFailed: 'Connection Failed',
            semanticDiscovery: 'Run Semantic Discovery',
            enableSemanticDiscovery: 'Enable Semantic Discovery'
        },
        securityTab: {
            tags: 'Tags',
            tagsDesc:
                'Specify a logical grouping keyword to bring disparate attributes together for reporting.',
            threshold: 'Threshold',
            thresholdDesc:
                'Specify a minimum value to reduce false positive classification.',
            sensitivity: 'Sensitivity',
            properties: 'Properties',
            null: 'Null',
            blank: 'Blank',
            unique: 'Unique',
            primary: 'Primary',
            length: 'Length',
            range: 'Range',
            pattern: 'Pattern',
            enumeration: 'Enumeration',
            rule: 'Rule',
            tagsTabDesc:
                'Specify a logical grouping keyword to bring disparate attributes together for reporting.',
            versionOff: 'Versioning OFF',
            applications: 'Applications',
            applicationsDesc:
                'Configure all systems in the organization irrespective of publishing or consuming systems.',
            qualityMeasures: 'Measures - Health',
            qualityMeasuresDesc:
                'Configure Measures to the platform to perform the better data quality score',
            qualityRules: 'Rules',
            qualityRulesDesc:
                'Validation rules to access the data quality of a field and they allow you to verify whether assets meet certain criteria',
            dimension: 'Dimension',
            dimensionDesc:
                'Configure dimensions to help assess your data quality across assets',
            fields: 'Fields',
            fieldsDesc:
                'Configure fields to help assess your data quality across assets',
            scheduleList: 'Schedule List',
            scheduleListDesc:
                'List of schedule and latest run status.',
            terms: 'Terms',
            termsTabDesc:
                'Specify a logical grouping keyword to bring disparate attributes together for reporting.'
        },
        executionLogs: {
            executionlogs: 'Execution Logs',
            errorLogging: 'Error Logging',
            infoLogging: 'Info Logging',
            libraryErrorLogging: 'Library Error Logging'
        },
        security: {
            users: 'Users',
            usersDescription: 'Manage users across organization and their related permissions for the portal.',
            inviteUsers: 'Invite Users',
            userSearchPlaceholder: 'Users List',
            invite: 'Invite',
            roles: 'Roles',
            rolesDescription: 'Mange roles across organization.',
            rolesSearch: 'Search Feature',
            totalUsers: 'Total users',
            activeUsers: 'Active users',
            inActiveUsers: 'Inactive users',
            selectRole: 'Role'
        },
        logs: {
            status: 'Status',
            total: 'Total',
            all: 'All',
            pending: 'Pending',
            running: 'Running',
            error: 'Error',
            completed: 'Completed'
        },
        profile: {
            editProfile: 'Edit Profile',
            editProfileDesc:
                "Manage your profile using the below fields. Some fields are not editable for SSO users as its directly captured from your organization’s domain account. Please contact your administrator for more editable options.",
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            domain: 'Domain',
            title: 'Title',
            application: 'Application',
            status: 'Status',
            channel: 'Channel',
            organization: 'Organization',
            name: 'Name',
            selectCloudApp: 'Select the cloud app or suite you wish to approve',
            office365: 'Microsoft Office 365',
            office365Detail:
                'OneDrive Word, PowerPoint, Excel, Outlook and more',
            googleGSuite: 'Google G Suite',
            googleGSuiteDetail:
                'Gmail, Hangouts, Calendar, Drive, Docs, Sheets and more',
            slack: 'Slack',
            slackDetail: 'Slack for Enterprise',
            domaincardTitle:
                'Provide a list of Domains. In most cases, these are your enterprise domains.',
            tenantDomain: 'Tenant domain',
            noDomainAdded: 'No domains have been added',
            trackoffice365:
                'To track office 365 access in Azure Reports, provide a Tenant Dictionary ID. Find your tenant ID in the Azure Portal.',
            tenantDictionayId: 'Tenant dictionary ID'
        },
        theme: {
            appTitle: "DQLabs",
            editTheme: 'Edit Theme',
            editThemeDesc:
                'Customize your theme to your own by editing the below fields.',
            logo: 'Logo',
            logoDesc:
                'Change the logo to your branding using your icons, and images. This will appear on the login page.',
            uploadLogo: 'Upload a Logo',
            uploadLogoDesc:
                'You can upload a PNG file (The file size limit is 2MB).',
            uploadLogoDesc2: 'Minimum size 200x200 pixels.',
            upload: 'Upload',
            tagLine: 'Tagline',
            tagLineDesc:
                'Add a Tagline for your branding. This will appear below the logo which is on the login page.',
            enterTagLine: 'Enter a Tagline',
            copyrightInfo: 'Copyright Info',
            copyrightLabel: 'Copyright Text',
            copyrightInfoDesc:
                'Change copyright information. This will appear at the bottom of the login screen with the software version automatically.',
            enterCopyright: 'Enter copyright text',
            banner: 'Banner',
            bannerDesc:
                'This banner will appear on the left side of the login screen. You can add as many slides as you want. It will auto scroll every 3 seconds. You can upload a JPG, PNG, or GIF file (The file size limit is 2 MB).',
            addBanner: 'Add Banner',
            background: 'Background',
            previewTheme: 'Preview Theme',
            styleGuide: 'Style Guide',
            styleGuideDesc: 'Please customize to your style guideline by editing font type, size, color, and button styles.',
            titleHeader: 'Title',
            titleHeaderDesc:
                'Add a Title for your branding. This will appear on the nav bar of the web page.',
            titleHeaderLine: 'Enter the title'
        },
        usage: {
            preview: 'Preview',
            query: 'Query',
            runQuery: 'Run Query',
            selectAttribute: 'Select all attribute',
            search: 'Search',
            topUsers: 'Top 5 Users',
            recentQueries: 'Top Queries',
            savedQueries: 'Saved Queries',
            byRoles: 'By Roles',
            admin: 'Admin',
            user: 'User',
            query_required: 'Please enter query',
            query_name_required: 'Please enter query name',
            validate: "Validate"
        },
        appIntegration: {
            title: 'App Integrations',
            description: 'Configure Integrations settings for collaboration, security sign ons, and application related third party systems.',
            search: 'Search integration',
            selectChannel: 'Select a channel to send alerts',
            selectachannel: 'Select a channel',
            selectTeams: 'Select a Team',
            selectattribute: 'Select Attribute',
            configuremonitoring: 'Configure Monitoring',
            configuremonitoringdesc: 'You can enable the notification of the alerts for various changes',
            enablesschema: 'Enable schema notifications',
            enablesschemadesc: 'Receives alerts for new/renamed/deleted tables or columns. You can exclude specific schemas or regex patterns',
            enableanomaly: 'Enable Anomaly detection notifictaion',
            enableanomalydesc: 'Receives alerts for new/renamed/deleted tables or columns. You can exclude specific schemas or regex patterns',
            monitorfreshness: 'Monitor freshness on all tables',
            monitorfreshnessdesc: 'Receives alerts for new/renamed/deleted tables or columns. You can exclude specific schemas or regex patterns',
            expected: 'Expected',
            actual: 'Actual'
        },
        issues: {
            description: 'Description',
            domains: 'Domains',
            priority: 'Priority',
            status: 'Status',
            owner: 'Owner',
            application: 'Applications',
            assignee: 'Assignee'
        },
        general: {
            description: 'Manage your general settings here',
            semantics: 'Semantics',
            semanticsdesc: 'Enable ON/OFF with a schedule for Semantic data discovery that uses advanced algorithms and machine learning to automate the process of analyzing and classifying attributes based on a semantic data model.',
            storage: 'Storage',
            storagedesc:
                'Specify the amount of time in days that data must be stored according to business requirements.',
            enabled: 'Enabled',
            schedule: 'Schedule',
            noofdays: 'No. of days to store',
            reporting: 'Reporting',
            reportingdesc:
                'Enable ON/OFF to collect data on failed rows (either full or preview data only) around primary key, composite keys and/or all attributes, in case of failure.',
            selecttype: 'Select type',
            failedRowsType: 'Failed rows type',
            previewcount: 'Preview Count',
            showPreview: 'Preview',
            exportGroup: 'Export Group',
            exportCategory: 'Measures',
            versioning: 'Versioning',
            versioningDesc:
                'Enable ON/OFF to version control and gather audit logs and highlight changes.',
            versioningNumber: 'Version Numbering (minor version format)',
            anomaly: 'Anomaly',
            anomalydesc:
                'Specify the amount of runs to look back for measuring the threshold or forecasting. ',
            noofruns: 'Maximum',
            defaultlook: 'Minimum',
            scoringsection: 'Scoring Section',
            scoringsectiondesc:
                'Specify the data quality quadrant range in terms of score, and its related color specification.',
            noofquad: 'No. of Quadrant',
            system: 'System',
            systemdesc:
                'Environment specific details and configuration.',
            othersystem: 'Other System',
            notifications: 'Notifications',
            notificationsDesc:
                'Configuration to show what type of notifications, the severity of notifications (Low, Medium, High), and at what frequency can be sent.',
            alertThreshold: 'Alert Threshold',
            airflowservice: 'Airflow Service Name',
            clientdb: 'Client Db Repository',
            alertScheduleHeading: 'Schedule',
            alertExecutionHeading: 'Execution',
            alerts: 'Alerts',
            issues: 'Issues',
            collaboration: 'Collaboration',
            permissions: 'Permissions',
            auditLogs: 'Audit Logs',
            priority: 'Priority',
            alertsPriority: 'Alert Priority',
            limit: 'Limit',
            frequency: 'Frequency',
            maximumAlerts: 'Maximum Number of Alerts',
            timePeriod: 'Time Interval',
            periodType: 'Period Type',
            alertLevel: 'Level',
            on: 'On',
            numberOfAlerts: 'Number of Notifications',
            export: 'Export',
            exportType: 'Export Type',
            exportFileType: 'Export File Type',
            default_measures: 'Auto Measures',
            measure: 'Measures',
            asset: 'Asset',
            attribute: 'Attribute',
            import: 'Import',
            importMeteData: 'Import Metadata',
            importMeteDataDesc: 'You can upload xlsx or csv file to import metadata. The Maximum file size you can upload is 100MB.',
            importUsers: 'Import Users',
            importUsersDesc: 'You can upload xlsx or csv file to import users. The Maximum file size you can upload is 100MB.',
            importType: 'Import Type',
            importMeasure: 'Import Measure',
            importMeasureDesc: 'You can upload xlsx or csv file to import measure. The Maximum file size you can upload is 100MB.',
            partnerAPI: "API",
            generateToken: "Generate Token",
            revokeToken: "Revoke Token",
            regenerateToken: "Re Generate Token",
            retentionPeriod: "Retention Period",
            reportingSchema: "Reporting Schema",
            reportingDatabase: "Reporting Database",
            profilingSchema: "Profiling Schema",
            profilingDatabase: "Profiling Database",
            delimiter: "Delimiter",
            profile: 'Profile',
            profileDesc: "Configuration of what, when and which data to measure the quality",
            exportRowLimit: "Export Row Limit",
            exportColumnLimit: "Export Column Limit",
            reportingConnection: "Reporting Connection",
            license: "License",
            deactiveLicense: "Your License has been Deactivated. Kindly install new license or contact your administrator",
            expireLicense: "Your License has Expired. Kindly install new license or contact your administrator",
            exportMeasureDesc: "Enable ON to override export of custom Measures with selection of measure",
            exportMetadataDesc: "Export metadata for all assets with selection of asset and attribute",
            exportUsersDesc: "Export all the users with in the organization into an xlsx or csv file",
            measureType: "Measure Type",
            measureLevel: "Measure Level",
            exportMeasureMetadata: "Export metadata of all configurations in the platform, including the audit logs",
            runNow: "Run Now",
            createTempTable: "Create Temp Table"
        },
        integration: {
            email: {
                header: 'Email',
                description: 'Configure Email',
                useSSL: 'Use SSL'
            },
            ad: {
                header: 'Azure AD Connection',
                description: 'Configure Azure AD',
                requestedAuthnContext: "Requested AuthnContext"
            },
            collibra: {
                header: 'Collibra',
                description: 'Data Catalog Platform'
            },
            alation: {
                header: 'Alation API',
                description: 'Connect to Alation. Please have the connections details',
                authentication_type_dd: ['Username and Password', "User Id and Refresh Token"]
            },
            jira: {
                header: 'Jira API',
                description: 'Jira API Integration'
            },
            pingIdentity: {
                header: 'Ping Identity',
                description: 'Configure Ping Identity',
                requestedAuthnContext: "Requested AuthnContext"
            },
            OKTAIdentity: {
                header: 'OKTA',
                description: 'Okta is a customizable, secure, and drop-in solution to add authentication and authorization services to your applications.',
                requestedAuthnContext: "Requested AuthnContext"
            },
            IBMIdentity: {
                header: 'IBM SAML',
                description: 'A cloud-based identity and access management service.',
                requestedAuthnContext: "Requested AuthnContext"
            },
            BigPanda: {
                header: 'Big Panda',
                description: 'An AI/ML-driven alert correlation engine that can help identify incidents in real-time',
                enableWebhook: 'Enable WebHook'
            }
        },
        dashboard: {
            create: 'Increase your abilities to collaborate, enhance domain ownership experience by creating and sharing your own custom dashboards.',
            manageWidget: 'Increase your experience of collaboration by creating custom widgets with your predefined charts, custom filters and your own data that matches your business needs.'
        },
        semanticTab: {
            measureNavigation: "This is a semantic governed measure for"
        },
        userActivity: {
            title: 'User Activity',
            description: 'User Activity Information'
        }
    },
    table: {
        databaseTableHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            { key: 'asset_type', name: 'Type', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'asset_type' },
            { key: 'schema', name: 'Schema', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'schema' },
            {
                key: 'column_count',
                name: 'Attributes',
                sorting: true,
                align: 'center',
                width: '10%',
                type: 'number',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'column_count'
            },
            {
                key: 'row_count',
                sort: true,
                name: 'Rows',
                sorting: true,
                align: 'left',
                width: '10%',
                type: 'number',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'row_count'
            },
            {
                key: 'is_incremental',
                name: 'Incremental',
                width: '5%',
                component: 'checkbox',
                componentKey: 'checkbox',
                isDisabled: (item) => (!item?.is_selected || item?.is_connected || item?.watermark_columns?.length <= 0)
            },
            {
                key: 'watermark_columns',
                name: 'Fingerprint',
                width: '10%',
                component: 'list',
                componentKey: 'watermark',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'watermark_columns'
            },
            {
                key: 'actions',
                name: 'Actions',
                width: '5%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            },
            { key: 'accordion', name: '', width: '1%', align: 'right' }
        ],
        databaseTableAttributeHeader: [
            {
                key: 'column_name',
                name: 'Attribute Name',
                width: '35%',
                component: 'attribute'
            },
            {
                key: 'datatype',
                name: 'Data Type',
                width: '15%'
            },
            { key: 'description', name: 'Description', width: '50%' }
        ],
        queryTableHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                width: '35%',
                component: "textbox",
                datatype: "text",
                placeholder: "Enter asset name",
                validators: ["required"],
                errorMessages: ["Asset name is required"],
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            {
                key: 'description',
                name: 'Description',
                width: '50%',
                component: "textbox",
                datatype: "text",
                placeholder: "",
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'description'
            },
            {
                key: 'asset_type', name: 'Type', width: '10%', isSearch: true,
                searchComponent: 'text',
                searchKey: 'asset_type'
            },
            {
                key: 'view_type',
                name: 'Table Type',
                width: '20%',
                minWidth: "100px",
                isSearch: true,
                component: "select",
                componentKey: 'name',
                searchComponent: 'select',
                selectComponentKey: 'name',
                searchKey: "view_type",
                defaultValue: "All",
                list: ['All', 'Table', 'View'],
                disabledColumn: (data) => data.is_connected
            },
            {
                key: 'actions',
                name: 'Actions',
                width: '5%',
                align: 'center',
                actions: [
                    { type: 'error_log', tooltip: true, tooltipText: 'Error Log', conditionalRenderColumn: "error", showError: (data) => data.error?.length > 0 },
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected" },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            },
            { key: 'accordion', name: '', width: '5%', align: 'center' }
        ],
        pipelineTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'type', name: 'Type', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'type' },
            { key: 'state', name: 'Status', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'state' },
            { key: 'created_by', name: 'Owner', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'created_by' },
            { key: 'created_at', name: 'Created On', width: '15%', type: "date", sorting: true },
            //{ key: 'last_run', name: 'Last Run', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'last_run' },
            {
                key: 'actions',
                name: 'Actions',
                width: '15%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected" },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        snowflakeSubTables: [
            { id: 'attribute', sort: true, label: 'Attribute' },
            { id: 'description', sort: true, label: 'Description' }
        ],
        propertiesTableHeader: [
            {
                key: 'name',
                name: 'Attribute',
                sorting: false,
                tooltip: false,
                width: '25%',
                component: 'attribute'
            },
            {
                key: 'passedRules',
                name: 'Valid',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'rulePassed',
                className: 'success'
            },
            {
                key: 'failedRules',
                name: 'Invalid',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'rulePassed',
                className: 'danger'
            },
            {
                key: 'alerts',
                name: 'Alert',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'alert'
            },
            {
                key: 'dqScore',
                name: 'Score',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'score'
            },
            {
                key: 'derivedType',
                name: 'Derived Type',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'select',
                componentKey: 'name',
                selectComponentKey: 'name'
            },
            {
                key: 'terms',
                name: 'Term',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                limit: 1,
                valueKey: 'name',
                disableInput: false,
                isAdd: true,
                chipAddType: 'autocomplete',
                addLimitCount: 1,
                className: 'termsChips'
            },
            {
                key: 'tags',
                name: 'Tags',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                limit: 1,
                valueKey: 'name',
                disableInput: false,
                isAdd: true,
                chipAddType: 'autocomplete',
                addLimitCount: 1,
                haveColor: true
            },
            {
                key: 'isNull',
                name: 'Null',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'switch'
            },
            {
                key: 'isBlank',
                name: 'Blank',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'switch'
            },
            {
                key: 'isUnique',
                name: 'Unique',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'switch'
            }
        ],
        valuesTabHeader: [
            {
                key: 'name',
                name: 'Attribute',
                sorting: false,
                tooltip: false,
                width: '25%',
                component: 'attribute'
            },
            {
                key: 'minLength',
                name: 'Min Length',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'textbox',
                componentKey: 'derivedType',
                datatype: 'numeric'
            },
            {
                key: 'maxLength',
                name: 'Max Length',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'textbox',
                componentKey: 'derivedType',
                datatype: 'numeric'
            },
            {
                key: 'minValue',
                name: 'Min Range',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'textbox',
                componentKey: 'derivedType'
            },
            {
                key: 'maxValue',
                name: 'Max Range',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'textbox',
                componentKey: 'derivedType'
            },
            {
                key: 'patterns',
                name: 'Pattern',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                valueKey: 'name',
                limit: 2,
                isAdd: true,
                className: 'sm-r secondary',
                disableInput: true
            },
            {
                key: 'enums',
                name: 'Enum',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                valueKey: 'name',
                limit: 2,
                isAdd: true,
                disableInput: true
            }
        ],
        UsersTabHeader: [
            {
                key: 'first_name',
                name: 'First Name',
                sorting: false,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'first_name',
                inlineEdit: true,
                component: 'textbox',
                placeholder: 'First Name',
                datatype: 'text'
            },
            {
                key: 'last_name',
                name: 'Last Name',
                sorting: false,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'last_name',
                inlineEdit: true,
                component: 'textbox',
                placeholder: 'Last Name',
                datatype: 'text'
            },
            {
                key: 'title',
                name: 'Title',
                sorting: false,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'title',
                inlineEdit: true,
                component: 'textbox',
                placeholder: 'Title',
                datatype: 'text'
            },
            {
                key: 'email',
                name: 'Email',
                sorting: false,
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'email',
                removeCasing: true
            },
            {
                key: 'domains',
                name: 'Domain',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                valueKey: 'name',
                limit: 2,
                isAdd: true,
                chipAddType: 'autocomplete',
                haveColor: true,
                isSearch: true,
                searchKey: 'domains'
            },
            {
                key: 'applications',
                name: 'Application',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                valueKey: 'name',
                limit: 2,
                isAdd: true,
                chipAddType: 'autocomplete',
                haveColor: true,
                isSearch: true,
                searchKey: 'applications'
            },
            {
                key: 'role',
                name: 'Role',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'select',
                selectComponentKey: 'name',
                componentKey: 'id',
                searchKey: 'roleName',
                isSearch: true
            },
            {
                key: 'auth_type',
                name: 'Auth Type',
                sorting: false,
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'auth_type',
                showCaps: true
            },
            {
                key: 'last_login',
                name: 'Last Login',
                sorting: false,
                tooltip: false,
                width: '10%',
                isSearch: false,
                type: "date",
                searchKey: 'last_login',
                hideDefault: true
            },
            {
                key: 'created_date',
                name: 'Created Date',
                sorting: false,
                tooltip: false,
                width: '10%',
                isSearch: false,
                type: "date",
                searchKey: 'created_date',
                hideDefault: true
            },
            {
                key: 'is_active',
                name: 'Active',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                searchKey: 'is_active',
                list: ['All', 'Active', 'Inactive']
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }]
            }
        ],
        ApplicationTabHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: false,
                tooltip: false,
                width: '15%',
                isSearch: true,
                inlineEdit: true,
                component: 'textbox',
                placeholder: 'Name',
                datatype: 'text',
                searchKey: 'name',
                isRequired: true
            },
            {
                key: 'description',
                name: 'Description',
                sorting: false,
                tooltip: false,
                width: '25%',
                isSearch: true,
                inlineEdit: true,
                component: 'textbox',
                placeholder: 'Description',
                datatype: 'text',
                searchKey: 'description'
            },
            {
                key: 'type',
                name: 'Type',
                sorting: false,
                tooltip: false,
                width: '5%',
                component: 'chips',
                limit: 1,
                isAdd: true,
                isSearch: true,
                datatype: 'array',
                searchKey: 'type',
                isRequired: true,
                chipProps: {
                    noId: true
                }
            },
            {
                key: 'count',
                name: 'Usage',
                sorting: false,
                tooltip: false,
                width: '5%'
            },
            {
                key: 'linked_asset',
                name: 'Linked Assets',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'chips',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'linked_asset',
                isNotEditable: true,
                isAdd: false,
                addLimitCount: 1,
                valueKey: 'name',
                haveColor: true,
                enableLink: true
            },
            {
                key: 'users',
                name: 'Users',
                sorting: false,
                tooltip: false,
                width: '20%',
                component: 'add_user',
                valueKey: 'name'
            },
            {
                key: 'color',
                name: 'Color',
                sorting: false,
                tooltip: false,
                width: '5%',
                component: 'color',
                disable_link: true
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '5%',
                actions: [
                    { type: 'create', tooltip: true, tooltipText: 'Create' },
                    { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        ScheduleListTabHeader: [
            {
                key: 'name',
                name: 'Name',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'name',
                sorting: true,
                clickable: true
            },
            {
                key: 'connection_name',
                name: 'Connection Name',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'connection_name',
                clickable: true,
                sorting: true
            },
            {
                key: 'connection_type',
                name: 'Connection Type',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'connection_type',
                initalCaps: true,
                sorting: true
            },
            {
                key: 'level',
                name: 'Level',
                tooltip: false,
                width: '7%',
                isSearch: true,
                searchKey: 'level',
                sorting: true,
                initalCaps: true
            },
            {
                key: 'asset_type',
                name: 'Asset Type',
                tooltip: false,
                width: '8%',
                isSearch: true,
                searchKey: 'asset_type',
                sorting: true
            },
            {
                key: 'last_run',
                name: 'Last Run',
                tooltip: false,
                width: '12%',
                type: 'date',
                sorting: true
            },
            {
                key: 'next_run',
                name: 'Next Run',
                tooltip: false,
                width: '12%',
                type: 'date',
                sorting: true
            },
            {
                key: 'recent_run',
                name: 'Recent Run',
                tooltip: false,
                width: '25%',
                component: "recent_runs"
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [{ type: 'schedule', tooltip: true, tooltipText: 'Schedule' }]
            }
        ],
        MeasureScheduleListTabHeader: [
            {
                key: 'measure_name',
                name: 'Measure Name',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'measure_name',
                sorting: true
            },
            {
                key: 'connection_name',
                name: 'Connection Name',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'connection_name'
            },
            {
                key: 'connection_type',
                name: 'Connection Type',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'connection_type'
            },
            {
                key: 'schedule_type',
                name: 'Schedule Type',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'schedule_type'
            },
            {
                key: 'last_run',
                name: 'Last Run',
                tooltip: false,
                width: '15%',
                type: 'date'
            },
            {
                key: 'next_run',
                name: 'Next Run',
                tooltip: false,
                width: '15%',
                type: 'date'
            },
            {
                key: 'recent_run',
                name: 'Recent Run',
                tooltip: false,
                width: '25%',
                component: "recent_runs"
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [{ type: 'schedule', tooltip: true, tooltipText: 'Schedule' }]
            }
        ],
        ReportScheduleListTabHeader: [
            {
                key: 'report_name',
                name: 'Report Name',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'report_name',
                sorting: true
            },
            {
                key: 'schedule_type',
                name: 'Schedule Type',
                tooltip: false,
                width: '10%',
                isSearch: true,
                searchKey: 'schedule_type'
            },
            {
                key: 'last_run',
                name: 'Last Run',
                tooltip: false,
                width: '15%',
                type: 'date'
            },
            {
                key: 'next_run',
                name: 'Next Run',
                tooltip: false,
                width: '15%',
                type: 'date'
            },
            {
                key: 'recent_run',
                name: 'Recent Run',
                tooltip: false,
                width: '25%',
                component: "recent_runs"
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [{ type: 'schedule', tooltip: true, tooltipText: 'Schedule' }]
            }
        ],
        latestRunTabHeader: [
            {
                key: 'category',
                name: 'Category',
                width: 'auto'
            },
            {
                key: 'attribute_name',
                name: 'Attribute',
                width: 'auto'
            },
            {
                key: 'measure_name',
                name: 'Measure',
                width: 'auto'
            },
            {
                key: 'start_time',
                name: 'Start Date',
                width: 'auto',
                type: 'date'
            },
            {
                key: 'end_time',
                name: 'End Date',
                width: 'auto',
                type: 'date'
            },
            {
                key: 'duration',
                name: 'Duration',
                width: 'auto',
                type: 'duration'
            },
            {
                key: 'status',
                name: 'Status',
                width: 'auto'
            }
        ],
        TagsTabHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: false,
                tooltip: true,
                width: '15%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                searchKey: 'name',
                isRequired: true,
                inlineEdit: true,
                placeholder: 'Tag Name'
            },
            {
                key: 'description',
                name: 'Description',
                sorting: false,
                tooltip: true,
                width: '32%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                searchKey: 'description',
                inlineEdit: true,
                placeholder: 'Description'
            },
            {
                key: 'count',
                name: 'Usage',
                sorting: false,
                tooltip: false,
                width: '5%',
                isSearch: false
            },
            {
                key: 'linked_attribute',
                name: 'Linked Attributes',
                sorting: true,
                tooltip: false,
                width: '10%',
                component: 'chips',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'linked_attribute',
                isNotEditable: true,
                isAdd: false,
                addLimitCount: 1,
                valueKey: 'name',
                haveColor: true,
                enableLink: true
            },
            {
                key: 'associated_tags',
                name: 'Associated Tags',
                sorting: false,
                tooltip: false,
                width: '20%',
                component: 'chips',
                valueKey: 'name'
            },
            {
                key: 'users',
                name: 'Users',
                sorting: false,
                tooltip: false,
                width: '15%',
                component: 'add_user',
                valueKey: 'name'
            },
            {
                key: 'color',
                name: 'Color',
                sorting: false,
                tooltip: false,
                width: '5%',
                component: 'color',
                disable_link: true
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [
                    { type: 'create', tooltip: true, tooltipText: 'Create' },
                    { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        TagsSubTableHeader: [
            {
                key: 'name',
                name: '',
                sorting: false,
                tooltip: false,
                width: '15%',
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                isRequired: true,
                placeholder: 'Tag Name'
            },
            {
                key: 'description',
                name: '',
                sorting: false,
                tooltip: false,
                width: '35%',
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                placeholder: 'Description'
            },
            {
                key: 'count',
                name: '',
                sorting: false,
                tooltip: false,
                width: '12%'
            },
            {
                key: 'linked_attribute',
                name: '',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                isNotEditable: true,
                isAdd: false,
                addLimitCount: 1,
                valueKey: 'name',
                haveColor: true,
                enableLink: true
            },
            {
                key: 'associated_tags',
                name: '',
                sorting: false,
                tooltip: false,
                width: '20%',
                component: 'subtagchips',
                limit: 1,
                isAdd: true,
                chipAddType: 'autocomplete',
                isNotEditable: false,
                disableInput: false,
                addLimitCount: 5,
                valueKey: 'name',
                haveColor: true
            },
            {
                key: 'actions',
                name: '',
                sorting: false,
                tooltip: false,
                width: '9%',
                actions: [
                    { type: 'create', tooltip: true, tooltipText: 'Create' },
                    { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        SettingsMeasures: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            {
                key: 'description',
                name: 'Description',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'description'
            },
            {
                key: 'level',
                name: 'Level',
                sorting: true,
                tooltip: false,
                width: '20%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'level'
            },
            {
                key: 'dimension',
                name: 'Dimension',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'chips',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'dimension',
                isNotEditable: false,
                disableInput: false,
                isAdd: true,
                chipAddType: 'autocomplete',
                addLimitCount: 1,
                valueKey: 'name',
                haveColor: true
            },
            {
                key: 'is_positive',
                name: 'Valid',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Valid', 'Invalid'],
                searchKey: 'is_positive'
            },
            {
                key: 'allow_score',
                name: 'Scoring',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'allow_score'
            },
            {
                key: 'is_drift_enabled',
                name: 'Monitoring',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'is_drift_enabled'
            },
            {
                key: 'is_active',
                name: 'Active',
                sorting: true,
                tooltip: false,
                width: '10%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'is_active'
            }
        ],
        SettingsRules: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: false,
                width: '20%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            {
                key: 'description',
                name: 'Description',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'description'
            },
            {
                key: 'datasets',
                name: 'Datasets',
                sorting: true,
                tooltip: false,
                width: '25%',
                component: 'chips',
                limit: 1,
                isAdd: false,
                isSearch: true,
                valueKey: 'name',
                searchComponent: 'text',
                searchKey: 'dataset_name',
                isNotEditable: true
            },
            {
                key: 'type',
                name: 'Category',
                sorting: true,
                tooltip: false,
                width: '15%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'type'
            },
            {
                key: 'dimension',
                name: 'Dimension',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'chips',
                isSearch: false,
                searchComponent: 'text',
                searchKey: 'dimension',
                isNotEditable: false,
                disableInput: false,
                isAdd: true,
                chipAddType: 'autocomplete',
                addLimitCount: 1,
                valueKey: 'name',
                haveColor: true
            },
            {
                key: 'is_positive',
                name: 'Valid',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Valid', 'Invalid'],
                searchKey: 'is_positive'
            },
            {
                key: 'allow_score',
                name: 'Scoring',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'allow_score'
            },
            {
                key: 'is_drift_enabled',
                name: 'Enable Drift',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'is_drift_enabled'
            },
            {
                key: 'is_active',
                name: 'Active',
                sorting: true,
                tooltip: false,
                width: '15%',
                component: 'switch',
                isSearch: true,
                searchComponent: 'select',
                list: ['All', 'Active', 'Inactive'],
                searchKey: 'is_active'
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [
                    { type: 'edit', tooltip: true, tooltipText: 'Edit' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        ExecutionLogsTabHeader: [
            {
                key: 'filename',
                name: 'File Name',
                sorting: false,
                tooltip: false,
                width: '25%'
            },
            {
                key: 'createddate',
                name: 'Created Date',
                sorting: false,
                tooltip: false,
                width: '25%'
            },
            {
                key: 'action',
                name: 'Action',
                sorting: false,
                tooltip: false,
                width: '10%'
            }
        ],
        UsageRecentQueries: [
            {
                key: 'user_name',
                name: 'Queried User',
                sorting: true,
                tooltip: false,
                width: '15%',
                isSearch: true,
                searchKey: 'user_name'
            },
            {
                key: 'role_name',
                name: 'Role',
                sorting: true,
                tooltip: false,
                width: '15%',
                isSearch: true,
                searchKey: 'role_name'
            },
            {
                key: 'start_time',
                name: 'Time Stamp',
                sorting: true,
                tooltip: false,
                width: '10%'
            },
            {
                key: 'runtime',
                name: 'Exection Time(ms)',
                sorting: true,
                tooltip: false,
                width: '10%'
            },
            {
                key: 'query_text',
                name: 'Query',
                sorting: true,
                tooltip: false,
                width: '50%',
                isSearch: true,
                searchKey: 'query_text'
            }
        ],
        UsageSavedQueries: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'name',
                clickable: true
            },
            {
                key: 'query',
                name: 'Query',
                sorting: true,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'query'
            },
            {
                key: 'modified_date',
                name: 'Last Update On',
                sorting: true,
                tooltip: false,
                width: '25%',
                isSearch: true,
                searchKey: 'modified_date',
                type: "date"
            },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [
                    { type: 'run', tooltip: true, tooltipText: 'Run' },
                    { type: 'convert', tooltip: true, tooltipText: 'Convert Rule' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        behavioralTabHeader: [
            {
                key: 'name',
                name: 'Attribute',
                sorting: false,
                tooltip: false,
                width: '25%',
                component: 'attribute'
            },
            {
                key: 'behavioralRules',
                name: 'Behavioral Analysis',
                sorting: false,
                tooltip: false,
                width: '50%',
                component: 'chips',
                valueKey: 'name',
                isAdd: true,
                limit: 10,
                className: 'sm-r secondaryDark',
                disableInput: true,
                chipDelete: true
            }
        ],
        dimensionTabHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: false,
                tooltip: false,
                width: '15%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                searchKey: 'name',
                isRequired: true,
                placeholder: 'Name'
            },
            {
                key: 'description',
                name: 'Description',
                sorting: false,
                tooltip: true,
                width: '60%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                searchKey: 'description',
                placeholder: 'Description'
            },
            {
                key: 'color',
                name: 'Color',
                sorting: false,
                tooltip: false,
                width: '5%',
                component: 'color',
                disable_link: true
            },
            {
                key: 'actions',
                name: 'Action',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [
                    { type: 'create', tooltip: true, tooltipText: 'Create' },
                    { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        alertsTabHeader: [
            {
                key: 'alerts',
                name: 'Alerts',
                sorting: false,
                tooltip: false,
                width: '30%'
            },
            {
                key: 'dataset',
                name: 'Dataset',
                sorting: false,
                tooltip: false,
                width: '15%'
            },
            {
                key: 'attribute',
                name: 'Attribute',
                sorting: false,
                tooltip: false,
                width: '20%'
            },
            {
                key: 'type',
                name: 'Type',
                sorting: false,
                tooltip: false,
                width: '7%'
            },
            {
                key: 'status',
                name: 'Status',
                sorting: false,
                tooltip: false,
                width: '8%'
            },
            {
                key: 'dateTime',
                name: 'DateTime',
                sorting: false,
                tooltip: false,
                width: '20%'
            }
        ],
        rulesTabHeader: [
            {
                key: 'name',
                name: 'Attribute',
                sorting: false,
                tooltip: false,
                width: '25%',
                component: 'attribute'
            },
            {
                key: 'rules',
                name: 'Rules',
                sorting: false,
                tooltip: false,
                width: '50%',
                component: 'chips',
                valueKey: 'name',
                isAdd: true,
                limit: 10,
                className: 'sm-r secondaryDark',
                disableInput: true,
                chipDelete: true
            }
        ],
        termAssetHeader: [
            {
                key: 'connection_name',
                name: 'Connection',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "connection_name",
                clickable: true
            },
            {
                key: 'asset_name',
                name: 'Asset',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "asset_name",
                clickable: true
            },
            {
                key: 'name',
                name: 'Attribute',
                sorting: true,
                width: '40%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "name",
                clickable: true
            },
            {
                key: 'actions',
                name: 'Actions',
                width: '10%',
                align: 'center',
                actions: [{ type: 'link', tooltip: true, tooltipText: 'Link' }]
            }
        ],
        issuesResultsHeader: [
            {
                key: 'datetime',
                name: 'Date Time',
                sorting: true,
                tooltip: true,
                width: '45%'
            },
            { key: 'actual', name: 'Actual', sorting: true, width: '45%' },
            {
                key: 'expected',
                name: 'Expected',
                width: '10%',
                align: 'center'
            },
            { key: 'status', name: 'Status', width: '10%', align: 'center' }
        ],
        issuesWorkLogHeader: [
            { key: 'activity', name: 'Activity', width: '25%' },
            { key: 'status', name: 'Status', width: '15%' },
            { key: 'created_by', name: 'User', width: '20%', component: 'user' },
            { key: 'day', name: 'Date Time', sorting: true, tooltip: true, width: '20%' }
        ],
        propertiesMeasuresTable: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '45%'
            },
            { key: 'score', name: 'Score', sorting: true, width: '45%' },
            {
                key: 'description',
                name: 'Description',
                width: '10%',
                align: 'center'
            },
            { key: 'custom', name: 'Custom', width: '10%', align: 'center' },
            { key: 'active', name: 'Active', width: '10%', align: 'center' },
            {
                key: 'category',
                name: 'Category',
                width: '10%',
                align: 'center'
            },
            {
                key: 'dimension',
                name: 'Dimension',
                width: '10%',
                align: 'center'
            },
            {
                key: 'lastresult',
                name: 'Last Result',
                width: '10%',
                align: 'center'
            }
        ],
        propertiesMeasuresResult: [
            {
                key: 'result',
                name: 'Result',
                sorting: true,
                tooltip: true,
                width: '45%'
            },
            { key: 'priority', name: 'Priority', sorting: true, width: '45%' },
            {
                key: 'expected',
                name: 'Expected',
                width: '10%',
                align: 'center'
            },
            { key: 'actual', name: 'Actual', width: '10%', align: 'center' },
            { key: 'time', name: 'Time', width: '10%', align: 'center' },
            {
                key: 'incident',
                name: 'Incident',
                width: '10%',
                align: 'center'
            }
        ],
        propertiesMeasuresFailedRows: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '45%'
            },
            { key: 'score', name: 'Score', sorting: true, width: '45%' },
            {
                key: 'description',
                name: 'Description',
                width: '10%',
                align: 'center'
            },
            { key: 'custom', name: 'Custom', width: '10%', align: 'center' },
            { key: 'active', name: 'Active', width: '10%', align: 'center' },
            {
                key: 'category',
                name: 'Category',
                width: '10%',
                align: 'center'
            },
            {
                key: 'dimension',
                name: 'Dimension',
                width: '10%',
                align: 'center'
            },
            {
                key: 'lastresult',
                name: 'Last Result',
                width: '10%',
                align: 'center'
            }
        ],
        metricQualityResultTable: [
            { key: 'day', name: 'Date Time', sorting: true, tooltip: true, width: "15%", isSearch: true, searchComponent: "text", searchKey: "day" },
            { key: 'expected', name: 'Expected', width: "15%", isSearch: true, searchComponent: "text", searchKey: "expected" },
            { key: 'value', name: 'Actual', width: "10%", isSearch: true, searchComponent: "text", searchKey: "value" },
            { key: 'message', name: 'Alert', width: "30%", isSearch: true, searchComponent: "text", searchKey: "message" },
            { key: 'status', name: 'Priority', width: "10%", component: "status", disableEvent: true, noBackground: true, isSearch: true, searchComponent: "text", searchKey: "status" },
            { key: 'issue_number', name: 'Issues', width: "10%", component: "issues", searchComponent: "text" },
            { key: 'actions', name: 'Actions', width: "10%", tooltip: true, actions: [{ type: 'drift_mark_resolved' }] }
        ],
        comparisonResultTable: [
            { key: 'technical_name', name: 'Measure', width: "20%", isSearch: true, searchComponent: "text", searchKey: "technical_name" },
            { key: 'day', name: 'Run Time', width: "15%", isSearch: true, searchComponent: "text", searchKey: "day" },
            { key: 'message', name: 'Alert', width: "30%", isSearch: true, searchComponent: "text", searchKey: "message" },
            { key: 'status', name: 'Priority', width: "15%", component: "status", disableEvent: true, noBackground: true, isSearch: true, searchComponent: "text", searchKey: "status" },
            { key: 'issue_number', name: 'Issues', width: "10%", component: "issues", searchComponent: "text" },
            { key: 'actions', name: 'Actions', width: "10%", tooltip: true, actions: [{ type: 'drift_mark_resolved' }] }
        ],
        comparisonResultSubTable: [
            { key: 'source', name: 'Source', width: "25%", isSearch: true, searchComponent: "text", searchKey: "source" },
            { key: 'target', name: 'Target', width: "25%", isSearch: true, searchComponent: "text", searchKey: "target" },
            { key: 'measure_name', name: 'Measure', width: "10%", isSearch: true, searchComponent: "text", searchKey: "measure_name" },
            { key: 'source_count', name: 'Source Count', width: "10%", isSearch: true, searchComponent: "text", searchKey: "source" },
            { key: 'target_count', name: 'Target Count', width: "10%", isSearch: true, searchComponent: "text", searchKey: "target" },
            { key: 'value', name: 'Difference', width: "10%", isSearch: true, searchComponent: "text", searchKey: "value" },
            { key: 'status', name: 'Status', width: "10%", component: "status", disableEvent: true, noBackground: true, isSearch: true, searchComponent: "text", searchKey: "status" }
        ],
        comparisonIssueQualityTable: [
            { key: 'day', name: 'Date Time', width: "15%", isSearch: true, searchComponent: "text", searchKey: "day" },
            { key: 'message', name: 'Alert', width: "auto", isSearch: true, searchComponent: "text", searchKey: "message" },
            { key: 'status', name: 'Priority', width: "auto", component: "status", disableEvent: true, noBackground: true, isSearch: true, searchComponent: "text", searchKey: "status" },
            { key: 'actions', name: 'Actions', width: "auto", tooltip: true, actions: [{ type: 'drift_mark_resolved' }] }
        ],
        issueQualityTable: [
            { key: 'day', name: 'Date Time', sorting: true, tooltip: true, width: "auto", isSearch: true, searchComponent: "text", searchKey: "day" },
            { key: 'expected', name: 'Expected', width: "auto", isSearch: true, searchComponent: "text", searchKey: "expected" },
            { key: 'value', name: 'Actual', width: "auto", isSearch: true, searchComponent: "text", searchKey: "value" },
            { key: 'message', name: 'Alert', width: "auto", isSearch: true, searchComponent: "text", searchKey: "message" },
            { key: 'status', name: 'Priority', width: "auto", component: "status", disableEvent: true, noBackground: true, isSearch: true, searchComponent: "text", searchKey: "status" },
            { key: 'actions', name: 'Actions', width: "auto", tooltip: true, actions: [{ type: 'drift_mark_resolved' }] }
        ],
        statisticsQualityTable: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: "50%", isSearch: true, searchComponent: "text", searchKey: "name" },
            { key: 'count', name: 'Count', sorting: true, width: "25%", isSearch: false, searchComponent: "text", searchKey: "count", type: 'number' },
            { key: 'is_valid', name: 'Valid', sorting: true, width: "25%", isSearch: false, searchComponent: "text", searchKey: "is_valid" }
        ],
        statisticsQualityPatternTable: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: "25%", isSearch: true, searchComponent: "text", searchKey: "name" },
            { key: 'posix_pattern', name: 'Posix Pattern', sorting: true, width: "25%", isSearch: true, searchComponent: "text", searchKey: "posix_pattern" },
            { key: 'sql_pattern', name: 'SQL Pattern', sorting: true, width: "25%", isSearch: true, searchComponent: "text", searchKey: "sql_pattern" },
            { key: 'count', name: 'Count', width: "15%", sorting: true, isSearch: false, searchComponent: "text", searchKey: "count", type: 'number' },
            { key: 'is_valid', name: 'Valid', sorting: true, width: "10%", isSearch: false, searchComponent: "text", searchKey: "is_valid" }
        ],
        statisticsQualityLengthTable: [
            { key: 'name', name: 'Length', sorting: true, tooltip: true, width: "50%", isSearch: true, searchComponent: "text", searchKey: "name" },
            { key: 'count', name: 'Count', sorting: true, width: "25%", isSearch: false, searchComponent: "text", searchKey: "count", type: 'number' },
            { key: 'is_valid', name: 'Valid', sorting: true, width: "25%", isSearch: false, searchComponent: "text", searchKey: "is_valid" }
        ],
        executionLogsJobHeader: [
            {
                key: 'asset_id',
                name: 'Asset ID',
                sorting: true,
                width: 'auto',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "asset_id",
                classes: "assetId",
                hideDefault: true
            },
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '30%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "name",
                clickable: true
            },
            {
                key: 'queue_type',
                name: 'Type',
                sorting: true,
                tooltip: true,
                width: '15%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "queue_type"
            },
            {
                key: 'start',
                name: 'Start Date',
                sorting: true,
                width: '15%',
                searchComponent: 'text',
                searchKey: "start"
            },
            {
                key: 'end',
                name: 'End Date',
                sorting: true,
                tooltip: true,
                width: '15%',
                searchComponent: 'text',
                searchKey: "end"
            },
            {
                key: 'durationText',
                name: 'Duration',
                sorting: true,
                width: '10%'
            },
            {
                key: 'total_tasks',
                name: 'Total Tasks',
                sorting: true,
                width: 'auto',
                clickable: true
            },
            {
                key: 'completed_tasks',
                name: 'Completed Tasks',
                sorting: true,
                width: 'auto',
                clickable: true
            },
            {
                key: 'failed_tasks',
                name: 'Failed Tasks',
                sorting: true,
                width: 'auto',
                clickable: true
            },
            {
                key: 'status',
                name: 'Status',
                sorting: true,
                tooltip: true,
                width: '10%',
                isSearch: true,
                searchComponent: 'select',
                searchKey: "status",
                list: ["All", "Pending", "Running", "Completed", "Failed"]
            },
            {
                key: 'level',
                name: 'Level',
                sorting: true,
                width: '10%',
                isSearch: true,
                searchComponent: 'select',
                searchKey: "level",
                list: ["Asset", "Attribute", "Measure"],
                hideDefault: true
            },
            {
                key: 'trigger_type',
                name: 'Run Type',
                sorting: true,
                width: '10%',
                isSearch: true,
                searchComponent: 'select',
                searchKey: "trigger_type",
                list: ["Manual", "Once", "Scheduled"],
                hideDefault: true
            }
        ],
        executionLogsTaskHeader: [
            {
                key: 'category',
                name: 'Category',
                sorting: true,
                width: 'auto',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "category"
            },
            {
                key: 'attribute_name',
                name: 'Attribute',
                sorting: true,
                width: 'auto',
                clickable: true
            },
            {
                key: 'measure_name',
                name: 'Measure',
                sorting: true,
                width: 'auto',
                clickable: true
            },
            {
                key: 'start',
                name: 'Start Date',
                sorting: true,
                width: 'auto'
            },
            {
                key: 'end',
                name: 'End Date',
                sorting: true,
                width: 'auto'
            },
            {
                key: 'durationText',
                name: 'Duration',
                sorting: true,
                width: 'auto'
            },
            {
                key: 'status',
                name: 'Status',
                sorting: true,
                width: 'auto',
                isSearch: true,
                searchComponent: 'text',
                searchKey: "status"
            }
        ],
        fieldsTableHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: false,
                tooltip: false,
                width: '15%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                searchKey: 'name',
                isRequired: true,
                placeholder: 'Name',
                allow_space: false
            },
            {
                key: 'description',
                name: 'Description',
                sorting: false,
                tooltip: true,
                width: '20%',
                isSearch: true,
                datatype: 'text',
                component: 'textbox',
                inlineEdit: true,
                searchKey: 'description',
                placeholder: 'Description'
            },
            {
                key: 'level',
                name: 'Level',
                sorting: false,
                tooltip: true,
                width: '10%',
                isSearch: true,
                datatype: 'text',
                component: 'select',
                inlineEdit: true,
                searchKey: 'level',
                placeholder: 'Level',
                defaultValue: "Asset",
                disabledColumn: (data) => data?.id?.length > 0
            },
            {
                key: 'data_type',
                name: 'Data Type',
                sorting: false,
                tooltip: true,
                width: '10%',
                isSearch: true,
                datatype: 'text',
                component: 'select',
                inlineEdit: true,
                searchKey: 'data_type',
                placeholder: 'Data Type',
                defaultValue: "Text"
            },
            {
                key: 'options',
                name: 'Options',
                sorting: false,
                tooltip: false,
                width: '10%',
                component: 'chips',
                limit: 1,
                isAdd: true,
                isSearch: true,
                inlineEdit: true,
                datatype: 'array',
                searchKey: 'options',
                isRequired: true,
                datatypeRestrict: true,
                allowDatatype: 'DropDown',
                dataTypeKey: 'data_type'
            },
            {
                key: 'order',
                name: 'Display Order',
                sorting: false,
                tooltip: true,
                width: '10%',
                isSearch: false,
                datatype: 'integer',
                component: 'textbox',
                inlineEdit: true,
                searchKey: 'order',
                placeholder: 'Order'
            },
            {
                key: 'custom_field_tab',
                name: 'Tab',
                sorting: false,
                tooltip: true,
                width: '8%',
                isSearch: true,
                datatype: 'text',
                component: 'autocomplete',
                inlineEdit: true,
                searchKey: 'custom_field_tab',
                placeholder: 'tab',
                createable: true,
                componentKey: 'name',
                datatypeRestrict: true,
                allowDatatype: 'Asset',
                dataTypeKey: 'level'
            },
            {
                key: 'custom_field_group',
                name: 'Group',
                sorting: false,
                tooltip: true,
                width: '6%',
                isSearch: true,
                datatype: 'text',
                component: 'autocomplete',
                inlineEdit: true,
                searchKey: 'custom_field_group',
                placeholder: 'group',
                createable: true,
                componentKey: 'name',
                datatypeRestrict: true,
                allowDatatype: 'Asset',
                dataTypeKey: 'level'
            },
            {
                key: 'actions',
                name: 'Action',
                sorting: false,
                tooltip: false,
                width: '10%',
                actions: [
                    { type: 'create', tooltip: true, tooltipText: 'Create' },
                    { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete' }
                ]
            }
        ],
        importMetadataHeaders: [
            { key: "", name: "Connection Name", disableMapColumn: true, width: '25%' },
            { key: "", name: "Asset Id", width: '25%', disableMapColumn: true },
            { key: "", name: "Asset", disableMapColumn: true, width: '25%' },
            { key: "", name: "Attribute Id", width: '25%', disableMapColumn: true },
            { key: "", name: "Attribute", width: '25%', disableMapColumn: true },
            { key: "", name: "Description", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Status", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Domains", width: '25%', disableMapColumn: false, subComponent: true, data_type: "list", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Application", width: '25%', disableMapColumn: false, subComponent: true, data_type: "list", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Stewards", width: '25%', disableMapColumn: false, subComponent: true, data_type: "list", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Tags", width: '25%', disableMapColumn: false, subComponent: true, data_type: "list", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Term", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Is Null", width: '25%', disableMapColumn: false, subComponent: true, data_type: "boolean", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Is Blank", width: '25%', disableMapColumn: false, subComponent: true, data_type: "boolean", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Is Unique", width: '25%', disableMapColumn: false, subComponent: true, data_type: "boolean", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Is Primary Key", width: '25%', disableMapColumn: false, subComponent: true, data_type: "boolean", subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Min Length", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Max Length", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Min Value", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Max Value", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" }
        ],
        TableauTableHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '23%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            { key: 'asset_type', name: 'Type', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'asset_type' },
            { key: 'site_name', name: 'Site', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'site_name' },
            { key: 'workbook_name', name: 'Workbook', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'workbook_name' },
            { key: 'project_name', name: 'Project', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'project_name' },
            { key: 'owner_name', name: 'Owner', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'owner_name' },
            { key: 'createdAt', name: 'Created At', width: '10%', type: "date", sorting: true },
            { key: 'updatedAt', name: 'Updated At', width: '10%', type: "date", sorting: true },
            {
                key: 'actions',
                name: 'Actions',
                width: '7%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        DbtTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'table_type', name: 'Type', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'type' },
            { key: 'job_name', name: 'Job Name', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'job_name' },
            { key: 'project_name', name: 'Project', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'project_name' },
            { key: 'database', name: 'Database', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'database' },
            { key: 'schema', name: 'Schema', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'schema' },
            { key: 'owner', name: 'Owner', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'owner' },
            { key: 'runGeneratedAt', name: 'Run At', width: '12%', type: "date", sorting: true },
            {
                key: 'actions',
                name: 'Actions',
                width: '8%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        AdfTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '25%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'resource_group', name: 'Resource Group', width: '20%', type: "text", sorting: true },
            { key: 'data_factory', name: 'Data Factory', width: '20%', type: "text", sorting: true },
            { key: 'activity_count', name: 'Activity Count', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'activity_count' },
            {
                key: 'actions',
                name: 'Actions',
                width: '20%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        FivetranTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'service', name: 'Service', sorting: true, tooltip: true, width: '13%', isSearch: true, searchComponent: 'text', searchKey: 'service' },
            { key: 'group_name', name: 'Destination', sorting: true, tooltip: true, width: '13%', isSearch: true, searchComponent: 'text', searchKey: 'group_name' },
            { key: 'connected_by', name: 'Connected By', sorting: true, tooltip: true, width: '13%', isSearch: true, searchComponent: 'text', searchKey: 'connected_by' },
            { key: 'schedule_type', name: 'Schedule Type', sorting: true, tooltip: true, width: '13%', isSearch: true, searchComponent: 'text', searchKey: 'schedule_type' },
            {
                key: 'actions',
                name: 'Actions',
                width: '8%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        TeradataTableHeader: [
            {
                key: 'name',
                name: 'Name',
                sorting: true,
                tooltip: true,
                width: '25%',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'name'
            },
            { key: 'asset_type', name: 'Type', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'asset_type' },
            { key: 'database', name: 'Database', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'schema' },
            {
                key: 'column_count',
                name: 'Attributes',
                sorting: true,
                align: 'center',
                width: '10%',
                type: 'number',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'column_count'
            },
            {
                key: 'row_count',
                sort: true,
                name: 'Rows',
                sorting: true,
                align: 'left',
                width: '10%',
                type: 'number',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'row_count'
            },
            {
                key: 'is_incremental',
                name: 'Incremental',
                width: '5%',
                component: 'checkbox',
                componentKey: 'checkbox',
                isDisabled: (item) => (!item?.is_selected || item?.is_connected || item?.watermark_columns?.length <= 0)
            },
            {
                key: 'watermark_columns',
                name: 'Fingerprint',
                width: '10%',
                component: 'list',
                componentKey: 'watermark',
                isSearch: true,
                searchComponent: 'text',
                searchKey: 'watermark_columns'
            },
            {
                key: 'actions',
                name: 'Actions',
                width: '5%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            },
            { key: 'accordion', name: '', width: '5%', align: 'center' }
        ],
        importUsersHeaders: [
            { key: "", name: "First Name", disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Last Name", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Title", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Email", width: '20%', disableMapColumn: true },
            { key: "", name: "Role", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Status", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Domains", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Application", width: '20%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" }
        ],
        userActivityHeader: [
            { key: 'user', name: 'User', width: '20%', component: 'user', sorting: true, tooltip: false, showUserName: true, isSearch: true, searchComponent: 'text', searchKey: "name" },
            { key: 'last_login', name: 'Last Logged In', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "last_login" },
            { key: 'no_of_logs', name: 'No Of Audit Logs', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "no_of_logs" },
            { key: 'login_count', name: 'Login Count', sorting: true, tooltip: true, width: '12%', isSearch: true, searchComponent: 'text', searchKey: "login_count" },
            { key: 'average_duration', name: 'Avg Duration (mins)', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "average_duration" },
            { key: 'minimum_duration', name: 'Min Duration (mins)', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "minimum_duration" },
            { key: 'maximum_duration', name: 'Max Duration (mins)', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "maximum_duration" }
        ],
        userActivitySubHeader: [
            { key: "start_time", name: "Session Start Time", width: '20%' },
            { key: "end_time", name: "Session End Time", width: '20%' },
            { key: "ip_address", name: "IP Address", width: '20%' },
            { key: "used_browser", name: "Browser", width: '25%' },
            { key: "actions", name: "Audit Information", width: '15%', actions: [{ type: 'view', tooltip: true, tooltipText: 'View' }], align: 'center' }
        ],
        userActivityLogsHeader: [
            { key: 'created_date', name: 'Created Date', width: '15%', sorting: true, tooltip: true, isSearch: false },
            { key: 'connection_name', name: 'Connection', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "connection_name" },
            { key: 'asset_name', name: 'Asset', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "asset_name" },
            { key: 'attribute_name', name: 'Attribute', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "attribute_name" },
            { key: 'audit_text', name: 'Audit Log', sorting: true, tooltip: true, width: '40%', isSearch: true, searchComponent: 'text', searchKey: "audit_text" }
        ],
        importMeasureHeaders: [
            { key: "", name: "Connection Name", disableMapColumn: true, width: '25%' },
            { key: "", name: "Asset Id", width: '25%', disableMapColumn: true },
            { key: "", name: "Asset", disableMapColumn: true, width: '25%' },
            { key: "", name: "Attribute Id", width: '25%', disableMapColumn: true },
            { key: "", name: "Attribute", width: '25%', disableMapColumn: true },
            { key: "", name: "Base Measure Id", disableMapColumn: true, width: '25%' },
            { key: "", name: "Measure Id", disableMapColumn: true, width: '25%' },
            { key: "", name: "Measure Name", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Description", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Status", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Dimension Name", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Domains", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Applications", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Category", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Level", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Query", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Total Records Query", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Failed Rows Query", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Properties", width: '25%', hideDefault: true, disableMapColumn: true, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Default", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Active", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Valid", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Scoring", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" },
            { key: "", name: "Monitor", width: '25%', disableMapColumn: false, subComponent: true, subComponentType: "autocomplete", subComponentKey: "key" }
        ],
        measureLogsHeader: [
            { key: 'created_date', name: 'Date', width: '20%', sorting: true, tooltip: true, isSearch: false },
            { key: 'user_name', name: 'User Name', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "user_name" },
            { key: 'audit_text', name: 'Log', width: '60', sorting: true, tooltip: true, isSearch: true, searchComponent: 'html_text', searchKey: "audit_text" }
        ],
        dbtColumnsListHeader: [
            { key: 'name', name: 'Name', width: '30%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name" },
            { key: 'type', name: 'Data Type', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type" },
            { key: 'description', name: 'Description', width: '25', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "description" },
            { key: 'tests', name: 'Tests', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "tests", clickable: true },
            { key: 'comment', name: 'Comment', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "comment", hideDefault: true }
        ],
        fivetranTableListHeader: [
            { key: 'name_in_source', name: 'Name in Source', width: '40%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name_in_source" },
            { key: 'name_in_destination', name: 'Name in Destination', width: '40%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name_in_destination" },
            { key: "actions", name: "View Columns", width: '20%', actions: [{ type: 'view', tooltip: true, tooltipText: 'View' }], align: 'center' }
        ],
        fivetranColumnsListHeader: [
            { key: 'name_in_source', name: 'Name in Source', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name_in_source" },
            { key: 'name_in_destination', name: 'Name in Destination', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name_in_destination" },
            { key: 'type_in_source', name: 'Type in Source', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type_in_source" },
            { key: 'type_in_destination', name: 'Type in Destination', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type_in_destination" },
            { key: 'is_foreign_key', name: 'Foriegn Key', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "is_foreign_key", type: "boolean" },
            { key: 'is_primary_key', name: 'Primary Key', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "is_primary_key", type: "boolean" }
        ],
        dbtRunsListHeader: [
            { key: 'threadId', name: 'Thread Id', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "threadId" },
            { key: 'runGeneratedAt', name: 'Generated At', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "runGeneratedAt", type: "date" },
            { key: 'runElapsedTime', name: 'Elapsed Time', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "runElapsedTime" },
            { key: 'executionTime', name: 'Execution Time', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "executionTime" },
            { key: 'status', name: 'Status', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "status" },
            { key: 'error', name: 'Error', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "error", hideDefault: true },
            { key: 'compileStartedAt', name: 'Compile Started At', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "compileStartedAt", hideDefault: true, type: "date" },
            { key: 'compileCompletedAt', name: 'Compile Completed At', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "compileCompletedAt", hideDefault: true, type: "date" },
            { key: 'executeStartedAt', name: 'Execute Started At', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "executeStartedAt", hideDefault: true, type: "date" },
            { key: 'executeCompletedAt', name: 'Execute Completed At', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "executeCompletedAt", hideDefault: true, type: "date" }
        ],
        databricksJobsRunsListHeader: [
            { key: 'event_id', name: 'Event Id', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "event_id" },
            { key: 'start_time', name: 'Start Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "start_time", type: "date" },
            { key: 'end_time', name: 'End Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "end_time", type: "date" },
            { key: 'run_time', name: 'Run Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "run_time", type: "date" },
            { key: 'result_state', name: 'Result', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "result_state" },
            { key: 'trigger', name: 'Trigger', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "trigger" },
            { key: 'type', name: 'Type', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type" },
            { key: 'state_message', name: 'Message', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "state_message" }
        ],
        databricksJobstasksListHeader: [
            { key: 'name', name: 'Task Name', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name" },
            { key: 'type', name: 'Type', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type" },
            { key: 'dependsOn', name: 'Dependency', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "dependsOn" },
            { key: 'node_type', name: 'Node Type', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "node_type" },
            { key: 'owner', name: 'Created By', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "owner" }
        ],
        databricksPipelineRunsListHeader: [
            { key: 'update_id', name: 'Update Id', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "update_id" },
            { key: 'start_time', name: 'Start Time', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "start_time", type: "date" },
            { key: 'end_time', name: 'End Time', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "end_time", type: "date" },
            { key: 'cause', name: 'Cause', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "cause" },
            { key: 'state', name: 'State', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "state" }
        ],
        databricksPipelineEventsListHeader: [
            { key: 'event_id', name: 'Event Id', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "event_id" },
            { key: 'start_time', name: 'start Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "start_time", type: "date" },
            { key: 'result_state', name: 'result', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "result_state" },
            { key: 'type', name: 'Type', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type" },
            { key: 'state_message', name: 'Message', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "state_message" },
            { key: 'maturity_level', name: 'Level', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "maturity_level" },
            { key: 'output_rows', name: 'Output Rows', width: '5%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "output_rows" },
            { key: 'upsert_rows', name: 'Upsert Rows', width: '5%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "upsert_rows" },
            { key: 'delete_rows', name: 'Delete Rows', width: '5%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "delete_rows" }
        ],
        adfPipelineRunsListHeader: [
            { key: 'pipeline_run_id', name: 'Run Id', width: '25%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "pipeline_run_id" },
            { key: 'runStart', name: 'Start Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "runStart", type: "date" },
            { key: 'runEnd', name: 'End Time', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "runEnd", type: "date" },
            { key: 'durationInMs', name: 'Duration', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "durationInMs", type: "datetime" },
            { key: 'message', name: 'Message', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "message", hideDefault: true },
            { key: 'invoked_by_type', name: 'Invoked By', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "invoked_by_type" },
            { key: 'status', name: 'Status', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "status" }
        ],
        adfPipelineActivityListHeader: [
            { key: 'name', name: 'Name', width: '20%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name" },
            { key: 'type', name: 'Type', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type" },
            { key: 'description', name: 'Description', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "description" }
        ],
        licenseHeader: [
            { key: 'package_name', name: 'Package Name', width: '20%', component: 'text', sorting: true, tooltip: false, showUserName: true, isSearch: true, searchComponent: 'text', searchKey: "package_name" },
            { key: 'mode', name: 'Mode', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "mode" },
            { key: 'start_date', name: 'Start Date', width: '12%', sorting: true, tooltip: true, isSearch: false, type: "date" },
            { key: 'end_date', name: 'End Date', sorting: true, tooltip: true, width: '12%', isSearch: false, type: "date" },
            { key: 'license_key', name: 'License key', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "license_key" },
            { key: 'duration', name: 'Duration', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "duration" },
            { key: 'status', name: 'Status', width: '12%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "status" }
        ],
        dbtTestsListHeader: [
            { key: 'name', name: 'Name', width: '25%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name" },
            { key: 'columnName', name: 'Column', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "columnName" },
            { key: 'executeCompletedAt', name: 'Completed At', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "executeCompletedAt", type: "date" },
            { key: 'executionTime', name: 'Execution Time', width: '10', sorting: true, tooltip: true, isSearch: false },
            { key: 'status', name: 'Status', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "status" },
            { key: 'language', name: 'Language', width: '5', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "language", hideDefault: true },
            { key: 'state', name: 'State', width: '5', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "state", hideDefault: true },
            { key: 'error', name: 'Error', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "error", hideDefault: true },
            {
                key: 'actions',
                name: 'Actions',
                sorting: false,
                tooltip: false,
                width: '5%',
                actions: [{ type: 'query_view', tooltip: true, tooltipText: 'View Query' }]
            }
        ],
        airflowTableHeader: [
            { key: 'dag_id', name: 'Dag ID', sorting: true, tooltip: true, width: '30%', isSearch: true, searchComponent: 'text', searchKey: 'dag_id' },
            { key: 'description', name: 'Description', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'description' },
            { key: 'owners', name: 'Owners', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'owners' },
            { key: 'tags', name: 'Tags', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'tags' },
            { key: 'next_dagrun', name: 'Next Run', sorting: true, tooltip: true, width: '10%', type: "date" },
            { key: 'schedule_interval_value', name: 'Schedule Interval', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'schedule_interval_value' },
            { key: 'is_subdag', name: 'Sub DAG', sorting: true, tooltip: true, width: '5%', isSearch: true, searchComponent: 'text', searchKey: 'is_subdag' },
            { key: 'is_active', name: 'Active', sorting: true, tooltip: true, width: '5%', isSearch: true, searchComponent: 'text', searchKey: 'is_active' },
            { key: 'is_paused', name: 'Paused', sorting: true, tooltip: true, width: '5%', isSearch: true, searchComponent: 'text', searchKey: 'is_paused' },
            {
                key: 'actions',
                name: 'Actions',
                width: '10%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        airflowRunsListHeader: [
            { key: 'dag_run_id', name: 'Run Id', width: '35%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "dag_run_id" },
            { key: 'state', name: 'State', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "state" },
            { key: 'run_type', name: 'Run Type', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "run_type" },
            { key: 'note', name: 'Note', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "note" },
            { key: 'start_date', name: 'Start Date', width: '15%', sorting: true, tooltip: true, type: "date" },
            { key: 'end_date', name: 'End Date', width: '15%', sorting: true, tooltip: true, type: "date" }
        ],
        airflowTaskListHeader: [
            { key: 'task_id', name: 'Task Id', width: '30%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "task_id" },
            { key: 'pool', name: 'Pool', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "pool" },
            { key: 'owner', name: 'Owner', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "owner" },
            { key: 'queue', name: 'Queue', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "queue" },
            { key: 'operator_name', name: 'Operator', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "operator_name" },
            { key: 'last_run_start_date', name: 'Start Date', width: '10%', sorting: true, tooltip: true, type: "date" },
            { key: 'last_run_end_date', name: 'End Date', width: '10%', sorting: true, tooltip: true, type: "date" },
            { key: 'last_run_duration', name: 'Duration', width: '10%', sorting: true, tooltip: true, type: "float" }
        ],
        airflowSubDAGsHeader: [
            { key: 'dag_id', name: 'Dag ID', sorting: true, tooltip: true, width: '35%', isSearch: true, searchComponent: 'text', searchKey: 'dag_id' },
            { key: 'description', name: 'Description', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'description' },
            { key: 'owners', name: 'Owners', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'owners' },
            { key: 'tags', name: 'Tags', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'tags' },
            { key: 'next_dagrun', name: 'Next Run', sorting: true, tooltip: true, width: '10%', type: "date" },
            { key: 'schedule_interval_value', name: 'Schedule Interval', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'schedule_interval_value' },
            { key: 'is_active', name: 'Active', sorting: true, tooltip: true, width: '5%', isSearch: true, searchComponent: 'text', searchKey: 'is_active' },
            { key: 'is_paused', name: 'Paused', sorting: true, tooltip: true, width: '5%', isSearch: true, searchComponent: 'text', searchKey: 'is_paused' }
        ],
        talendTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'type', name: 'Type', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'type' },
            { key: 'workspace_name', name: 'Workspace Name', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'workspace_name' },
            { key: 'workspace_owner', name: 'Workspace Owner', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'workspace_owner' },
            { key: 'workspace_type', name: 'Workspace Type', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'workspace_type' },
            { key: 'environment_name', name: 'Environment Name', sorting: true, tooltip: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'environment_name' },
            {
                key: 'actions',
                name: 'Actions',
                width: '8%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected", renderAction: (data) => (data?.status?.toLowerCase() !== 'deprecated' && data?.is_selected) },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ],
        talendDatasetListHeader: [
            { key: 'label', name: 'Name', width: '30%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "label" },
            { key: 'trustScore', name: 'Trust Score', width: '15%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "trustScore" },
            { key: 'connection_name', name: 'Connection Name', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "connection_name" },
            { key: 'connection_type', name: 'Connection Type', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "connection_type" },
            { key: 'description', name: 'Description', width: '25', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "description", hideDefault: true },
            { key: 'creator', name: 'Author', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "creator" },
            { key: 'created', name: 'Created', width: '15', sorting: true, tooltip: true, isSearch: false, type: "date" }
        ],
        talendRunsListHeader: [
            { key: 'flowStatus', name: 'Status', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "flowStatus" },
            { key: 'executionType', name: 'Execution Type', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "executionType" },
            { key: 'flow_name', name: 'Task', width: '10%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "flow_name" },
            { key: 'triggerTimestamp', name: 'Trigger Time', width: '10', sorting: true, tooltip: false, isSearch: false, searchComponent: 'text', searchKey: "triggerTimestamp", type: "date" },
            { key: 'duration', name: 'Duration', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "status" },
            { key: 'runProfileName', name: 'Engine', width: '10', sorting: true, tooltip: true, isSearch: false, searchComponent: 'text', searchKey: "runProfileName" },
            { key: 'startTimestamp', name: 'Start Time', width: '10', sorting: true, tooltip: true, isSearch: false, searchComponent: 'text', searchKey: "startTimestamp", hideDefault: true, type: "date" },
            { key: 'finishTimestamp', name: 'End Time', width: '10', sorting: true, tooltip: true, isSearch: false, searchComponent: 'text', searchKey: "finishTimestamp", hideDefault: true, type: "date" },
            { key: 'errorMessage', name: 'Error', width: '10', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "errorMessage", hideDefault: true }
        ],
        talendTaskListHeader: [
            { key: 'flowName', name: 'Name', width: '33.333%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "flowName" },
            { key: 'workspaceName', name: 'Worspace Name', width: '33.3333%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "workspaceName" },
            { key: 'workspaceOwner', name: 'Workspace Owner', width: '33.3333%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "workspace_owner" }
        ],
        PowerBiTableHeader: [
            { key: 'name', name: 'Name', sorting: true, tooltip: true, width: '20%', isSearch: true, searchComponent: 'text', searchKey: 'name' },
            { key: 'workspace', name: 'Workspace', sorting: true, width: '15%', isSearch: true, searchComponent: 'text', searchKey: 'workspace' },
            { key: 'asset_type', name: 'Type', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'asset_type' },
            { key: 'reportType', name: 'BI Report Type', sorting: true, width: '10%', isSearch: true, searchComponent: 'text', searchKey: 'reportType' },
            { key: 'createdBy', name: 'Created By', width: '15', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "createdBy" },
            { key: 'createdDateTime', name: 'Created At', width: '10', sorting: true, tooltip: true, isSearch: false, type: "date" },
            { key: 'modifiedDateTime', name: 'Modified At', width: '10', sorting: true, tooltip: true, isSearch: false, type: "date" },
            {
                key: 'actions',
                name: 'Actions',
                width: '10%',
                align: 'center',
                actions: [
                    { type: 'schedule', tooltip: true, tooltipText: 'Schedule', conditionalRenderColumn: "is_selected" },
                    { type: 'delete', tooltip: true, tooltipText: 'Delete', conditionalRenderColumn: "is_connected" }
                ]
            }
        ]
    },
    tabs: {
        homeTabs: [
            {
                name: 'Dashboard',
                path: 'dashboard',
                active_path: 'home/dashboard$|home$',
                featureName: 'Home',
                subFeatureName: 'Dashboard',
                restricted: true
            },
            {
                name: 'Measures',
                path: 'measures',
                active_path: 'home/measures$',
                featureName: 'Home',
                subFeatureName: 'Measures',
                restricted: true
            },
            {
                name: 'Reports',
                path: 'reports',
                active_path: 'home/reports$',
                featureName: 'Home',
                subFeatureName: 'Reports',
                restricted: true
            },
            {
                name: 'Issues',
                path: 'issues',
                active_path: 'home/issues$',
                featureName: 'Home',
                subFeatureName: 'Issues',
                restricted: true
            },
            {
                name: 'Alerts',
                path: 'alerts',
                active_path: 'home/alerts$',
                featureName: 'Home',
                subFeatureName: 'Alerts',
                restricted: true
            }
        ],
        connectorTabs: [
            'All',
            'Datawarehouse',
            'Datalake',
            'BI',
            'Pipelines',
            'Integrations'
        ],
        catalogTabs: ['Tables', 'Views', 'Query', 'Attributes', 'Reports', 'Pipelines', 'Assets', 'All'],
        settingsTabs: [
            {
                name: 'General',
                path: 'general',
                active_path: 'settings/general|settings$',
                featureName: 'Settings',
                subFeatureName: 'General',
                restricted: true
            },
            {
                name: 'Connections',
                path: 'connections',
                active_path: 'settings/connections',
                featureName: 'Settings',
                subFeatureName: 'Connections',
                restricted: true
            },
            {
                name: 'Semantics',
                path: 'semantics',
                active_path: 'settings/semantics',
                featureName: 'Settings',
                subFeatureName: 'Semantics',
                restricted: true
            },
            {
                name: 'Quality',
                path: 'quality',
                active_path: 'settings/quality',
                featureName: 'Settings',
                subFeatureName: 'Quality',
                restricted: true
            },
            /*
             * {
             *     name: 'Measures',
             *     path: 'measures',
             *     active_path: 'settings/measures',
             *     featureName: 'Settings',
             *     subFeatureName: 'Measures',
             *     restricted: true
             * },
             */
            {
                name: 'Integration',
                path: 'integration',
                active_path: 'settings/integration',
                featureName: 'Settings',
                subFeatureName: 'Integration',
                restricted: true
            },
            {
                name: 'Security',
                path: 'security',
                active_path: 'settings/security',
                featureName: 'Settings',
                subFeatureName: 'Security',
                restricted: true
            },
            {
                name: 'Theme',
                path: 'theme',
                active_path: 'settings/theme',
                featureName: 'Settings',
                subFeatureName: 'Theme',
                restricted: true
            }
        ],
        securityTabs: [
            {
                name: 'Domains',
                path: 'domains',
                active_path: 'settings/semantics/domains',
                featureName: 'Terms',
                subFeatureName: 'View Terms',
                restricted: true
            },
            {
                name: 'Terms',
                path: 'terms',
                active_path: 'settings/semantics/terms',
                featureName: 'Terms',
                subFeatureName: 'View Terms',
                restricted: true
            },
            {
                name: 'Tags',
                path: 'tags',
                active_path: 'settings/semantics/tags',
                featureName: 'Tags',
                subFeatureName: 'View Tags',
                restricted: true
            },
            {
                name: 'Applications',
                path: 'applications',
                active_path: 'settings/semantics/applications',
                featureName: 'Applications',
                subFeatureName: 'View Applications',
                restricted: true
            },
            {
                name: 'Dimensions',
                path: 'dimension',
                active_path: 'settings/semantics/dimension',
                featureName: 'Dimensions',
                subFeatureName: 'View Dimensions',
                restricted: true
            },
            {
                name: 'Fields',
                path: 'fields',
                active_path: 'settings/semantics/fields',
                featureName: 'fields',
                subFeatureName: 'View Fields',
                restricted: false
            }
        ],
        securityDetailTabs: [
            {
                name: 'Definition',
                path: 'definition',
                active_path: 'settings/semantics/.*/term/.*'
            },
            {
                name: 'Assets',
                path: 'assets',
                active_path: 'settings/semantics/.*/term/.*'
            }
        ],
        settingQualityTabs: ["Reliability", "Distribution", "Frequency", "Statistics", "All"],
        settingQualityTabsDesc: [
            "Define Health related measures to measure data reliability or health check.",
            "Define Data Distribution related measures to measure and understand the content of data assets.",
            "Define Frequency related measures to understand the format and enumeration of data assets.",
            "Define Statistics related measures to figure out suitability the data is for its intended applications in data analytics, data science, or machine learning."
        ],
        domainDetailTabs: [
            {
                name: 'Summary',
                path: 'summary',
                active_path: 'summary'
            },
            {
                name: 'Terms',
                path: 'terms',
                active_path: 'terms'
            },
            {
                name: 'Quality',
                path: 'quality',
                active_path: 'quality'
            }
        ],
        domainSummaryTabs: ["Assets", "Standalone Measures"],
        tagsDetailTabs: [
            {
                name: 'Summary',
                path: 'summary',
                active_path: 'summary'
            },
            {
                name: 'Quality',
                path: 'quality',
                active_path: 'quality'
            }
        ],
        applicationDetailTabs: [
            {
                name: 'Summary',
                path: 'summary',
                active_path: 'summary'
            },
            {
                name: 'Quality',
                path: 'quality',
                active_path: 'quality'
            }
        ],
        datasetOverviewTabs: [
            {
                name: 'Overview',
                path: '',
                active_path: 'asset/([A-Za-z0-9-])*$',
                featureName: 'Assets',
                subFeatureName: 'Overview',
                restricted: true
            }
        ],
        assetTabsByConnectionType: [
            {
                name: 'Properties',
                path: 'properties',
                active_path: 'asset/.*/properties',
                featureName: 'Assets',
                subFeatureName: 'Properties',
                restricted: true,
                connectionType: ['snowflake', 'mssql', 'redshift', 'redshift_spectrum', 'denodo', 'oracle', 'postgresql', 'databricks', 'bigquery', 'db2', 'saphana', 'athena', 'teradata', 'synapse', 'emr_spark', 'hive']
            },
            {
                name: 'Usage',
                path: 'usage',
                active_path: 'asset/.*/usage$',
                featureName: 'Assets',
                subFeatureName: 'Usage',
                restricted: true,
                connectionType: ['snowflake', 'mssql', 'redshift', 'redshift_spectrum', 'denodo', 'oracle', 'postgresql', 'bigquery', 'db2', 'saphana', 'athena', 'teradata', 'synapse', 'emr_spark', 'hive', 'databricks']
            },
            {
                name: 'Lineage',
                path: 'lineage',
                active_path: 'asset/.*/lineage$',
                featureName: 'Assets',
                subFeatureName: 'Lineage',
                restricted: true,
                connectionType: ['tableau', 'dbt', 'snowflake', 'mssql', 'redshift', 'redshift_spectrum', 'denodo', 'oracle', 'postgresql', 'databricks', 'bigquery', 'fivetran', 'athena', 'teradata', 'synapse', 'saphana', 'airflow', 'emr_spark', 'adf', 'hive', 'powerbi']
            },
            {
                name: 'Conversation',
                path: 'conversation',
                active_path: 'asset/.*/conversation$',
                featureName: 'Assets',
                subFeatureName: 'Conversations',
                restricted: true,
                connectionType: ['all']
            }
        ],
        profileTabs: [
            {
                name: 'My Profile',
                path: 'editprofile',
                active_path: 'myprofile/editprofile'
            },
            { name: 'Theme', path: 'theme', active_path: 'myprofile/theme' }
        ],
        datasetPropertiesTabs: ['Properties', 'Values', 'Rules', 'Behavioral'],
        rulesTabs: [
            'Health',
            'Distribution',
            'Frequency',
            'Statistics',
            'Business',
            'Behavioral'
        ],
        appIntegrationTabs: ['All', 'Collaboration', 'SSO', 'Apps', 'Libraries'],
        termTabs: ['Definition', 'Measure', "Linked Assets"],
        issuesTabs: ['Details', 'Work Log', 'Comments'],
        measureTabs: ["Result", "Failed Rows"],
        propertiesMeasuresTabs: {
            result: 'Result',
            preview: 'Preview',
            measureLogs: 'Measure Logs'
        },
        failedRowInactiveMeasures: {
            health: ["row_count", "column_count", "freshness", "min_value", "max_value", "min_length", 'max_length'],
            distribution: ['distinct_count', 'duplicate', 'null_count', 'blank_count', 'space_count', 'non_empty', 'zero_values'],
            previewFilter: ['pattern', 'short_pattern', 'long_pattern', 'length', 'enum']
        },
        usegeUserandRoleTabs: ['Top 5 Users', 'Top 5 Roles'],
        widgetTabs: ['Properties', 'Filters'],
        dbtPrevTabs: ['Compiled', 'Source'],
        customReportTabs: ['Properties', 'Filters'],
        asset: {
            profileTabs: ["Distribution", "Frequency", "Pattern", "Statistics"]
        },
        connectionTabs: [
            {
                name: 'Connections',
                path: 'connection',
                active_path: 'settings/connections/connection'
            }, {
                name: 'Schedule',
                path: 'schedule',
                active_path: 'settings/connections/schedule'
            }
        ],
        domainsTabs: [
            {
                name: 'Domains',
                path: 'domains',
                active_path: 'domains',
                featureName: 'Terms',
                subFeatureName: 'View Terms',
                restricted: false
            },
            {
                name: 'Tags',
                path: 'tags',
                active_path: 'tags',
                featureName: 'Tags',
                subFeatureName: 'View Tags',
                restricted: false
            },
            {
                name: 'Applications',
                path: 'applications',
                active_path: 'applications',
                featureName: 'Applications',
                subFeatureName: 'View Applications',
                restricted: false
            }
        ]
    },
    errorMessages: {
        invalidMail: 'email is not valid',
        requriedField: 'is required',
        noresult: 'No results found',
        loading: 'Loading please wait..,',
        nameRequired: 'Name is required',
        valueRequired: 'Value is required',
        messageRequired: 'Message is required',
        passwordPolicy: 'Password requirement policy not met',
        isPasswordMatch: 'Password and Confirm Password Should Match',
        patternNotValid: 'Pattern is invalid',
        domainRequired: 'Please Enter Your Domain',
        duplicateConnection: "Connection with same name already exists",
        licenseKey: 'License Key is required',
        minValue: "Minimum value should not be less than",
        maxValue: "Maximum value should not exceed",
        duplicateRole: "Role name cannot be duplicate"
    },
    successMessages: {
        updated: 'Updated Successfully',
        deleted: 'Deleted Successfully',
        created: 'Created Successfully',
        copy: 'Copied Successfully',
        trigger: "Triggered Successfully",
        killJob: "Job Killed Successfully",
        invited: 'Invited Successfully',
        shared: "Shared Successfully",
        saved: "Saved Successfully",
        mail: "Mail Sent Successfully",
        widgetMove: "Widget Moved Successfully",
        connection: "Connection Created Successfully",
        import: "Imported Successfully",
        license: "License Activated Successfully",
        syncLicense: "License Config Updated Successfully"
    },
    status: ['Pending', 'Ready For Review', 'Verified', 'Deprecated'],
    issueStatus: ['New', 'Inprogress', 'Resolved'],
    priority: ['High', 'Medium', 'Low'],
    propertiesFetchLimit: 50,
    propertyTabHeaders: [
        'Attribute',
        'Valid',
        'Invalid',
        'Alert',
        'Score',
        'Derived Type',
        'Terms',
        'Tags',
        'Null',
        'Blank',
        'Unique'
    ],
    valuesTabHeaders: [
        'Attribute',
        'Min Length',
        'Max Length',
        'Min Range',
        'Max Range',
        'Pattern',
        'Enum'
    ],
    scoreColor: ['#F38080', '#F89662', '#FDAC42', '#A9C56D', '#54DF9A'],
    chartColors: {
        fontSize: 13,
        axisColor: '#DDDEDE',
        labelColor: '#9FAEC3',
        splitLineColor: '#EDEEEE',
        trending: [
            '#997AF1',
            '#F297B7',
            '#FFD778',
            '#B4DBF7',
            'rgb(150,219,120)'
        ]
    },
    schedule: {
        scheduleTypes: [
            {
                name: 'Minutes'
            },
            {
                name: 'Hours'
            },
            {
                name: 'Days'
            },
            {
                name: 'Weeks'
            },
            {
                name: 'Months'
            }
        ],
        weekDays: [
            {
                name: 'Sun',
                value: 'Sunday',
                indexValue: 0
            },
            {
                name: 'Mon',
                value: 'Monday',
                indexValue: 1
            },
            {
                name: 'Tue',
                value: 'Tuesday',
                indexValue: 2
            },
            {
                name: 'Wed',
                value: 'Wednesday',
                indexValue: 3
            },
            {
                name: 'Thu',
                value: 'Thursday',
                indexValue: 4
            },
            {
                name: 'Fri',
                value: 'Friday',
                indexValue: 5
            },
            {
                name: 'Sat',
                value: 'Saturday',
                indexValue: 6
            }
        ],
        TimeZones: [
            {
                name: 'US/Eastern'
            },
            {
                name: 'US/Mountain'
            },
            {
                name: 'US/Pacific'
            },
            {
                name: 'US/Central'
            }
        ],
        jobType: ['Reliability', 'Profile', 'Statistics']
    },
    dataInterval: ['Days'],
    behavioralAggrecationTypes: ['Avg', 'Count', 'Min', 'Max', 'Sum', 'Value'],
    dayInterval: [{ name: "7 days", value: 7 }, { name: "14 days", value: 14 }, { name: "30 days", value: 30 }],
    sliceBy: [
        { name: "Minutes", value: "minute", type: "datetime" },
        { name: "Hours", value: "hour", type: "datetime" },
        { name: "Days", value: "day", type: "date" },
        { name: "Months", value: "month", type: "date" },
        { name: "Years", value: "year", type: "date" }
    ],
    charts: {
        trendingChartSort: [
            { value: 'desc', name: 'Top 5 Attributes' },
            { value: 'asc', name: 'Bottom 5 Attributes' }
        ],
        dimensionChartSort: [
            { value: 'desc', name: 'Top 10 Attributes' },
            { value: 'asc', name: 'Bottom 10 Attributes' }
        ]
    },
    tableOptions: {
        common: [
            { type: 'search', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        download: [
            { type: 'search', customFunction: null },
            { type: 'download', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        useractivity: [
            { type: 'runnow', customFunction: null },
            { type: 'schedule', customFunction: null },
            { type: 'search', customFunction: null },
            { type: 'download', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        savedQuery: [
            { type: 'search', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        logs: [
            { type: 'search', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        measureLogs: [
            { type: 'search', customFunction: null },
            { type: 'columns', customFunction: null }
        ],
        asset: [{ type: 'search', customFunction: null }]
    },
    propertiesComponents: {
        constraint: 'constraint',
        pattern: 'patterns',
        enum: 'enums',
        lengthRange: 'lengthRange',
        measure: 'measure',
        general: 'general',
        section: 'section'
    },
    appIntegration: ["slack"],
    alertTiles: [
        { name: 'High', key: "high" },
        { name: 'Medium', key: "medium" },
        { name: 'Low', key: "low" },
        { name: 'All', key: "all" }
    ],
    general: {
        storage: [{ value: 1, name: '1 Day' }, { value: 2, name: '2 Days' }, { value: 3, name: '3 Days' }, { value: 4, name: '4 Days' }, { value: 5, name: '5 Days' }, { value: 6, name: '6 Days' }, { value: 7, name: '7 Days' }, { value: 8, name: '8 Days' }, { value: 9, name: '9 Days' }, { value: 10, name: '10 Days' }],
        quadrant: [{ value: 2, name: '2 Quadrant' }, { value: 3, name: '3 Quadrant' }, { value: 4, name: '4 Quadrant' }, { value: 5, name: '5 Quadrant' }],
        frequency: ["Hourly", "Daily", "Weekly"],
        priority: ["Low", "Medium", "High"],
        periodType: ["Hours", "Days", "Weeks"],
        alertLevel: ["Asset", "Attribute", "Domain", "Organization"]
    },
    issuesTiles: [
        { name: 'New', key: "new", isSelected: false },
        { name: 'Inprogress', key: "inprogress", isSelected: false },
        { name: 'Resolved', key: "resolved", isSelected: false },
        { name: 'All', key: "all", isSelected: false }
    ],
    measureTiles: [
        { name: 'Auto', key: "is_auto", isSelected: false },
        { name: 'Behavioral', key: "behavioral", isSelected: false },
        { name: 'Comparison', key: "comparison", isSelected: false },
        { name: 'Conditional', key: "conditional", isSelected: false },
        { name: 'Query', key: "query", isSelected: false },
        { name: 'Lookup', key: "lookup", isSelected: false },
        { name: 'All', key: "all", isSelected: false }
    ],
    oldFontFamily: ['Arial, sans-serif', 'Lato, sans-serif', 'Poppins, sans-serif', 'Poppins, sans-serif', 'Tahoma, sans-serif', 'Trebuchet MS, sans-serif', 'Times New Roman, serif', 'Georgia, serif', 'Garamond, serif', 'Courier New, monospace', 'Brush Script MT, cursive'],
    fontFamily: [
        {
            name: 'Lato, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'
        },
        {
            name: 'Roboto, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
        },
        {
            name: 'Lora, serif',
            url: 'https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap'
        },
        {
            name: 'Montserrat, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap'
        },
        {
            name: 'Noto Sans, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap'
        },
        {
            name: 'Nunito, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap'
        },
        {
            name: 'Nunito Sans, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap'
        },
        {
            name: 'Open Sans, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
        },
        {
            name: 'Oswald, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap'
        },
        {
            name: 'Poppins, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap'
        },
        {
            name: 'PT Sans, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,700;1,400&display=swap'
        },
        {
            name: 'Raleway, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap'
        },
        {
            name: 'Roboto, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
        },
        {
            name: 'Slabo, serif',
            url: 'https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap'
        },
        {
            name: 'Source Sans Pro, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'
        },
        {
            name: 'Work Sans, sans-serif',
            url: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap'
        },
        {
            name: 'AvenirNextRegular, sans-serif',
            url: null
        },
        {
            name: 'Arial, sans-serif',
            url: null
        },
        {
            name: 'Verdana, sans-serif',
            url: null
        },
        {
            name: 'Tahoma, sans-serif',
            url: null
        },
        {
            name: 'Trebuchet MS, sans-serif',
            url: null
        },
        {
            name: 'Times New Roman, serif',
            url: null
        },
        {
            name: 'Georgia, serif',
            url: null
        },
        {
            name: 'Garamond, serif',
            url: null
        },
        {
            name: 'Courier New, monospace',
            url: null
        },
        {
            name: 'Brush Script MT, cursive',
            url: null
        }
    ],
    dashboardDateFilter: [
        { name: "All", value: "all" },
        { name: "3d", value: "> current_date at time zone 'UTC' - interval '3 days'" },
        { name: "7d", value: "> current_date at time zone 'UTC' - interval '7 days'" },
        { name: "14d", value: "> current_date at time zone 'UTC' - interval '14 days'" },
        { name: "30d", value: "> current_date at time zone 'UTC' - interval '30 days'" }
    ],
    chartOptions: [
        { name: "Vertical Bar", type: "vertical_bar" },
        { name: "Horizontal Bar", type: "horizontal_bar" },
        { name: "Polar Bar", type: "polar" },
        { name: "Line", type: "line" },
        { name: "Area", type: "area" },
        { name: "Heat Map", type: "heatmap" },
        { name: "Hierachy", type: "hierachy" },
        { name: "Table", type: "table" },
        { name: "Spider", type: "spider" },
        { name: "Count", type: "count" },
        { name: "Gauge", type: "gauge" },
        { name: "Sun Burst", type: "sun_burst" }
    ],
    dimensions: [
        {
            name: "Asset Name",
            column: "version.name",
            datatype: "text",
            aggregationColumn: "count(distinct asset.id)",
            hierachy_table: "hierachy_asset",
            support_column: ["asset.id as asset_id"],
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct asset.id)", type: "measure" }, { query: "version.name", type: "dimension" }],
            table_name: "asset"
        },
        {
            name: "Asset Type",
            column: "upper(asset.type)",
            datatype: "text",
            aggregationColumn: "count(distinct asset.id)",
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct asset.id)", type: "measure" }, { query: "upper(asset.type)", type: "dimension" }],
            table_name: "asset"
        },
        {
            name: "Attribute Name",
            column: "attribute.name",
            datatype: "text",
            table: "attribute",
            aggregationColumn: "count(distinct attribute.attribute_id)",
            hierachy_table: "hierachy_attribute",
            support_column: ["attribute.attribute_id as attribute_id", "asset.id as asset_id"],
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct attribute.attribute_id)", type: "measure" }, { query: "attribute.name", type: "dimension" }],
            table_name: "attribute"
        },
        {
            name: "Attribute Type",
            column: "attribute.derived_type",
            datatype: "text",
            table: "attribute",
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct attribute.attribute_id)", type: "measure" }, { query: "attribute.derived_type", type: "dimension" }],
            table_name: "attribute"
        },
        {
            name: "Connection Type",
            column: "connection.type",
            datatype: "text",
            table: "",
            standaloneColumn: "connection.type",
            isStandaloneSupport: true,
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct connection.id)", type: "measure", standaloneQuery: "count(distinct connection.id)" }, { query: "connection.type", type: "dimension", standaloneQuery: "connection.type" }],
            table_name: ""
        },
        {
            name: "Connection Name",
            column: "connection.name",
            datatype: "text",
            table: "",
            aggregationColumn: "count(distinct connection.id)",
            hierachy_table: "hierachy_connection",
            support_column: ["connection.id as connection_id"],
            standaloneColumn: "connection.name",
            isStandaloneSupport: true,
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct connection.id)", type: "measure", standaloneQuery: "count(distinct connection.id)" }, { query: "connection.name", type: "dimension", standaloneQuery: "connection.name" }],
            table_name: ""
        },
        {
            name: "Schema Name",
            column: "connection.credentials->>'schema'",
            datatype: "text",
            table: "",
            isStandaloneSupport: true,
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count"],
            table_name: ""
        },
        {
            name: "Domain Name",
            column: "domains.technical_name",
            datatype: "text",
            table: "domain",
            aggregationColumn: "count(distinct domains.id)",
            hierachy_table: "hierachy_domain",
            support_column: ["domains.id as domain_id"],
            standaloneColumn: "domains.technical_name",
            exclude_charts: [],
            isStandaloneSupport: true,
            sun_burst_columns: [{ query: "count(distinct domains.id)", type: "measure", standaloneQuery: "count(distinct domains.id)" }, { query: "domains.technical_name", type: "dimension", standaloneQuery: "domains.technical_name" }],
            table_name: "domain"
        },
        {
            name: "Application Name",
            column: "application.name",
            datatype: "text",
            table: "application",
            aggregationColumn: "count(distinct application.id)",
            hierachy_table: "hierachy_application",
            support_column: ["application.id as application_id"],
            standaloneColumn: "application.name",
            exclude_charts: [],
            isStandaloneSupport: true,
            sun_burst_columns: [{ query: "count(distinct application.id)", type: "measure", standaloneQuery: "count(distinct application.id)" }, { query: "application.name", type: "dimension", standaloneQuery: "application.name" }],
            table_name: "application"
        },
        {
            name: "Tag Name",
            column: "tags.display_name",
            datatype: "text",
            table: "tags",
            aggregationColumn: "count(distinct tags.id)",
            hierachy_table: "hierachy_tags",
            support_column: ["tags.id as tag_id"],
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct tags.id)", type: "measure" }, { query: "tags.display_name", type: "dimension" }],
            table_name: "tags"
        },
        {
            name: "Term Name",
            column: "terms.technical_name",
            datatype: "text",
            table: "terms",
            aggregationColumn: "count(distinct terms.id)",
            hierachy_table: "hierachy_terms",
            support_column: ["terms.id as term_id"],
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct terms.id)", type: "measure" }, { query: "terms.technical_name", type: "dimension" }],
            table_name: "terms"
        },
        {
            name: "Measure Name",
            column: "base_measure.name",
            datatype: "text",
            table: "measure",
            hierachy_table: "hierachy_measure",
            standaloneColumn: "base_measure.name",
            support_column: ["measure.id as measure_id", "measure.allow_score as allow_score"],
            aggregationColumn: "count(distinct measure.id)",
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct measure.id)", type: "measure", standaloneQuery: "count(distinct measure.id)" }, { query: "base_measure.name", type: "dimension", standaloneQuery: "base_measure.name" }],
            table_name: "measure"
        },
        {
            name: "Measure Type",
            column: "base_measure.type",
            datatype: "text",
            table: "measure",
            standaloneColumn: "base_measure.type",
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct measure.id)", type: "measure", standaloneQuery: "count(distinct measure.id)" }, { query: "base_measure.type", type: "dimension", standaloneQuery: "base_measure.type" }],
            table_name: "measure"
        },
        {
            name: "Measure Category",
            column: "base_measure.category",
            datatype: "text",
            table: "measure",
            standaloneColumn: "base_measure.category",
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct measure.id)", type: "measure", standaloneQuery: "count(distinct measure.id)" }, { query: "base_measure.category", type: "dimension", standaloneQuery: "base_measure.category" }],
            table_name: "measure"
        },
        {
            name: "Measure Level",
            column: "base_measure.level",
            datatype: "text",
            table: "measure",
            standaloneColumn: "base_measure.level",
            exclude_charts: ["hierachy", "spider", "gauge", "count"],
            sun_burst_columns: [{ query: "count(distinct measure.id)", type: "measure", standaloneQuery: "count(distinct measure.id)" }, { query: "base_measure.level", type: "dimension", standaloneQuery: "base_measure.level" }],
            table_name: "measure"
        },
        {
            name: "Issue Name",
            column: "issues.name",
            datatype: "text",
            table: "issues",
            aggregationColumn: "count(distinct issues.id)",
            support_column: ["issues.id as issue_id"],
            standaloneColumn: "issues.name",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "issues"
        },
        {
            name: "Issue Priority",
            column: "issues.priority",
            datatype: "text",
            table: "issues",
            standaloneColumn: "issues.priority",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count"],
            table_name: "issues"
        },
        {
            name: "Issue Status",
            column: "issues.status",
            datatype: "text",
            table: "issues",
            standaloneColumn: "issues.status",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count"],
            table_name: "issues"
        },
        {
            name: "Alert Priority",
            column: "drift_alert.status",
            datatype: "text",
            table: "drift_alert",
            standaloneColumn: "drift_alert.status",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "drift_alert"
        },
        {
            name: "Dimension Name",
            column: "dimension.name",
            datatype: "text",
            table: "dimension",
            hierachy_table: "hierachy_dimension",
            standaloneColumn: "dimension.name",
            aggregationColumn: "count(distinct dimension.id)",
            exclude_charts: [],
            sun_burst_columns: [{ query: "count(distinct dimension.id)", type: "measure", standaloneQuery: "count(distinct dimension.id)" }, { query: "dimension.name", type: "dimension", standaloneQuery: "dimension.name" }],
            table_name: "dimension"
        },
        {
            name: "Date By Asset",
            column: "date(version.created_date)",
            datatype: "date",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "asset"
        },
        {
            name: "Date By Attribute",
            column: "date(attribute.created_date)",
            datatype: "date",
            table: "attribute",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "attribute"
        },
        {
            name: "Date By Alert",
            column: "date(drift_alert.created_date)",
            datatype: "date",
            table: "drift_alert",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "drift_alert"
        },
        {
            name: "Date By Issues",
            column: "date(issues.created_date)",
            datatype: "date",
            table: "issues",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "issues"
        },
        {
            name: "Date By Measure",
            column: "date(measure.created_date)",
            datatype: "date",
            table: "measure",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst"],
            table_name: "measure"
        },
        {
            name: "Run Date",
            column: "date(metrics.created_date)",
            datatype: "date",
            table: "metrics",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "polar", "count"],
            table_name: "metrics"
        },
        {
            name: "Value",
            column: "CASE WHEN ((base_measure.type = 'frequency' and base_measure.category != 'range') or (base_measure.type = 'custom' and base_measure.category != 'comparison')) THEN metrics.measure_name ELSE 'N/A' END",
            datatype: "text",
            aggregation: "count",
            table: "metrics",
            standaloneColumn: "CASE WHEN ((base_measure.type = 'frequency' and base_measure.category != 'range') or (base_measure.type = 'custom' and base_measure.category != 'comparison')) THEN metrics.measure_name ELSE 'N/A' END",
            exclude_charts: ["hierachy", "sun_burst", "count", 'vertical_bar', 'horizontal_bar', 'polar', 'line', 'area', "spider", "gauge"],
            table_name: "metrics"
        }
    ],
    measures: [
        {
            name: "Score",
            column: "avg(case when scores.score is not null then scores.score else 0 end)",
            datatype: "integer",
            aggregation: "avg",
            table: "scores",
            exclude_charts: ["hierachy", "sun_burst", "count", 'heatmap'],
            table_name: "scores"
        },
        {
            name: "Alerts",
            column: "count(distinct drift_alert.id)",
            datatype: "integer",
            aggregation: "count",
            table: "drift_alert",
            exclude_charts: ["hierachy", "gauge", "sun_burst", "count", 'heatmap'],
            table_name: "drift_alert",
            isStandaloneSupport: true
        },
        {
            name: "Issues",
            column: "count(distinct issues.id)",
            datatype: "integer",
            aggregation: "count",
            table: "issues",
            exclude_charts: ["hierachy", "gauge", "sun_burst", "count", 'heatmap'],
            table_name: "issues",
            isStandaloneSupport: true
        },
        {
            name: "Total Records",
            column: "max(version.row_count)",
            datatype: "integer",
            aggregation: "sum",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count", 'heatmap'],
            table_name: ""
        },
        {
            name: "Invalid Records",
            column: "max(measure_table.max_invalid_rows)",
            datatype: "integer",
            aggregation: "sum",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count", 'heatmap'],
            table_name: ""
        },
        {
            name: "Valid Records",
            column: "max(version.row_count - measure_table.max_invalid_rows)",
            datatype: "integer",
            aggregation: "sum",
            exclude_charts: ["hierachy", "spider", "gauge", "sun_burst", "count", 'heatmap'],
            table_name: ""
        },
        {
            name: "Actual",
            column: "CASE WHEN (base_measure.category != 'comparison' or base_measure.category is NULL) THEN metrics.value ELSE 'N/A' END",
            datatype: "text",
            aggregation: "count",
            table: "metrics",
            standaloneColumn: "CASE WHEN (base_measure.category != 'comparison' or base_measure.category is NULL) THEN metrics.value ELSE 'N/A' END",
            exclude_charts: ["hierachy", "sun_burst", "count", 'vertical_bar', 'horizontal_bar', 'polar', 'line', 'area', "spider", "gauge"],
            isHeatmapSupport: true,
            table_name: "metrics"
        },
        {
            name: "Percentage Change",
            column: "drift_alert.percent_change",
            datatype: "integer",
            aggregation: "sum",
            table: "drift_alert",
            standaloneColumn: "drift_alert.percent_change",
            exclude_charts: ["hierachy", "sun_burst", "count", 'vertical_bar', 'horizontal_bar', 'polar', 'line', 'area', "spider", "gauge"],
            isHeatmapSupport: true,
            table_name: "drift_alert"
        },
        {
            name: "Deviation",
            column: "drift_alert.deviation",
            datatype: "integer",
            aggregation: "sum",
            table: "drift_alert",
            standaloneColumn: "drift_alert.deviation",
            exclude_charts: ["hierachy", "sun_burst", "count", 'vertical_bar', 'horizontal_bar', 'polar', 'line', 'area', "spider", "gauge"],
            isHeatmapSupport: true,
            table_name: "drift_alert"
        }
    ],
    dashobard_filter_by: [
        {
            id: 'alerts',
            name: 'With Alerts'
        },
        {
            id: 'without_alerts',
            name: 'Without Alerts'
        },
        {
            id: 'issues',
            name: 'With Issues'
        },
        {
            id: 'without_issues',
            name: 'Without Issues'
        }
    ],
    healthAlerts: ["Freshness", "Duplicates", "Volume", "Schema"],
    dashboardWigetTypes: {
        verticalBar: 'vertical_bar',
        horizontalBar: 'horizontal_bar',
        hierachy: 'hierachy',
        table: 'table',
        line: 'line',
        area: 'area',
        polar: 'polar',
        spider: 'spider',
        count: 'count',
        gauge: 'gauge',
        sun_burst: 'sun_burst',
        heatmap: 'heatmap'
    },
    measureEditOptions: [
        { name: "Scoring", value: "allow_score" },
        { name: "Monitor", value: "is_drift_enabled" },
        { name: "Active", value: "is_active" },
        { name: "Valid", value: "is_positive" },
        { name: "Domains", value: "domains" },
        { name: "Applications", value: "applications" },
        { name: "Dimension", value: "dimension_id" },
        { name: "Status", value: "status" }
    ],
    versioningOptions: [1, 2, 3, 4],
    defaultSemanticQuery: " SELECT COUNT(*) FROM <table_name> WHERE <attribute> = ''",
    commonDataTypes: ["Boolean", "Date", "DateTime", "DropDown", "Integer", "Numeric", "Text", "Time"],
    commonTabs: ["overview", "properties", "usage", "conversation"],
    tableauDataTypes: [
        {
            values: ['wstr', 'str'],
            type: 'Text'
        },
        {
            values: ['r8', 'integer', 'int'],
            type: 'Integer'
        },
        {
            values: ['numeric', 'float', 'double', 'i8'],
            type: 'Numeric'
        },
        {
            values: ['date', 'datetime', 'dbtimestamp'],
            type: 'Date'
        },
        {
            values: ['time'],
            type: 'Time'
        },
        {
            values: ['boolean'],
            type: 'Bollean'
        }
    ],
    measureStatus: [
        {
            id: 'pending',
            name: 'Pending'
        },
        {
            id: 'readyforreview',
            name: 'Ready For Review'
        },
        {
            id: 'verified',
            name: 'Verified'
        }
    ],
    collibraMapColumns: [
        { name: "Score", matchColumn: "Passing Fraction", id: "score", type: "measure", isDefault: true, priority: true },
        { name: "Passed Rows", matchColumn: "Rows Passed", id: "valid_count", type: "measure", isDefault: true, priority: true },
        { name: "Failed Rows", matchColumn: "Rows Failed", id: "invalid_count", type: "measure", isDefault: true, priority: true },
        { name: "Total Rows", matchColumn: "Loaded Rows", id: "total_count", type: "measure", isDefault: true, priority: true },
        { name: "Threshold", matchColumn: "Threshold", id: "pass_criteria_threshold", type: "measure", isDefault: true, priority: true },
        { name: "Result", matchColumn: "Result", id: "result", type: "measure", priority: false, isDefault: true },
        { name: "Asset Run Complete Date", matchColumn: "Last Sync Date", id: "last_update_date", type: "measure", priority: false, isDefault: true }
    ],
    alationMapColumns: [
        { name: "DQ Score", id: "score", isDefault: true },
        { name: "Passed Rows", id: "valid_count", isDefault: true },
        { name: "Failed Rows", id: "invalid_count", isDefault: true },
        { name: "Total Records", id: "total_count", isDefault: true }
    ],
    datatypes: {
        text: ["text"],
        numeric: ["integer", "numeric"],
        bit: ["bit"]
    },
    profile: {
        description: "Provides critical insights into data that aids in the discovery of data quality issues, risks, and overall trends.",
        basic: "Health",
        basicDescription: "Considers percentage of fields with filled data - including null, unique and zero value.",
        completeness: "Completeness",
        completenessDescription: "Considers percentage of fields with filled data - not including blanks, empty, space or null value.",
        uniqueness: "Uniqueness",
        uniquenessDescription: "Considers the percentage of fields with distinct value (non-null or non-empty).",
        character: "Character",
        characterDescription: "Considers percentage of fields with alphabets (A-Z), digits (0-9), special characters (non alphanumeric).",
        space: "Space",
        spaceDescription: "Considers percentage of fields with string values with whitespace before the value, trailing space, outer space and inner space.",
        numeric: "Numeric",
        numericDescription: "Considers percentage of fields with values with positive or negative numbers or zero values.",
        shortPattern: "Auto Discovered Pattern",
        shortPatternDescription: "Displays the list of auto-discovered data patterns using acronyms such as A for alphabets, N for numeric, S for space and special characters as it is.",
        regularExpressionPattern: "Regular Expressions",
        regularExpressionPatternDescription: "Displays the conditional regular expression-based patterns discovered automatically using business terms or manual configuration.",
        length: "Length Frequency",
        lengthDescription: "Displays how data is distributed across various lengths by showing the number of occurrences for each length.",
        value: "Value Frequency",
        valueDescription: "Displays how data is distributed across various values by showing the number of occurrences for each value.",
        statisticsTitle: "Statistics",
        statisticsDescription: "Describes the overall distribution of the data (shape, center, spread), and any deviations (outliers) from the distribution.",
        statistics: [
            {
                name: "MINIMUM",
                description: "The smallest value in the column.",
                key: "min_value"
            },
            {
                name: "MAXIMUM",
                description: "The largest value in the column.",
                key: "max_value"
            },
            {
                name: "RANGE",
                description: "The range value is the difference of minimum and maximum values of the column.",
                key: "range"
            },
            {
                name: "AVERAGE",
                description: "The average value of values in the column. The mean is the sum of all the values in the data divided by the total number of values in the data.",
                key: "mean"
            },
            {
                name: "SUM",
                description: "The sum of values in the column (aggregation of all values).",
                key: "sum"
            },
            {
                name: "MEDIAN",
                description: "A type of average value, which describes where the center of the data is located. It is the middle value in a data set ordered from low to high.",
                key: "median"
            },
            {
                name: "MODE",
                description: "A type of average value, which describes where most of the data is located. The mode is the value(s) that are the most common in the data.",
                key: "mode"
            },
            {
                name: "KURTOSIS",
                description: "A measure of heaviness of the tails of a probability distribution, defined as the fourth cumulant divided by the square of the variance of the probability distribution.",
                key: "kurtosis"
            },
            {
                name: "SKEWNESS",
                description: "The property of being skew. A measure of the asymmetry of the probability distribution of a real-valued random variable; is the third standardized moment.",
                key: "skewness"
            },
            {
                name: "VARIANCE",
                description: "The measure of how far a set of random numbers are dispersed from the mean.",
                key: "variance"
            },
            {
                name: "STANDARD DEVIATION",
                description: "Measures how far a typical observation is from the average of the data (μ).",
                key: "standard_deviation"
            },
            {
                name: "THE MARGIN OF ERROR",
                description: "The possible range of values under and over the calculated value.",
                key: "margin_of_error"
            },
            {
                name: "QUARTILE",
                description: "A measure of variation that describes how spread out the data is.",
                key: "",
                subKeys: [
                    {
                        name: "Q0",
                        description: "The smallest value in the data.",
                        key: "q0"
                    },
                    {
                        name: "Q1",
                        description: "The value separating the first quarter from the second quarter of the data.",
                        key: "q1"
                    },
                    {
                        name: "Q2",
                        description: "The middle value (median), separating the bottom from the top half.",
                        key: "q2"
                    },
                    {
                        name: "Q3",
                        description: "The value separating the third quarter from the fourth quarter.",
                        key: "q3"
                    },
                    {
                        name: "Q4",
                        description: "The largest value in the data.",
                        key: "q4"
                    }
                ]
            }
        ],
        measureGroups: [
            {
                group: "completeness",
                key: "completeness",
                measures: [
                    {
                        name: "NULL",
                        technical_name: "null_count"
                    },
                    {
                        name: "EMPTY",
                        technical_name: "blank_count"
                    },
                    {
                        name: "SPACE",
                        technical_name: "space_count"
                    },
                    {
                        name: "NON EMPTY",
                        technical_name: "non_empty"
                    }
                ]
            },
            {
                group: "uniqueness",
                key: "uniqueness",
                measures: [
                    {
                        name: "UNIQUE",
                        technical_name: "distinct_count"
                    },
                    {
                        name: "REPEATING",
                        technical_name: "duplicate"
                    }
                ]
            },
            {
                group: "character",
                key: "character_distribution",
                measures: [
                    {
                        name: "ALPHABET",
                        technical_name: "characters_count"
                    },
                    {
                        name: "DIGITS",
                        technical_name: "digits_count"
                    },
                    {
                        name: "ALPHA NUMERIC",
                        technical_name: "alpha_numeric_count"
                    },
                    {
                        name: "SPACE",
                        technical_name: "whitespace_count"
                    },
                    {
                        name: "SPECIAL",
                        technical_name: "special_char_count"
                    }
                ]
            },
            {
                group: "space",
                key: "space_distribution",
                measures: [
                    {
                        name: "LEADING",
                        technical_name: "leading_space"
                    },
                    {
                        name: "TRAILING",
                        technical_name: "trailing_space"
                    },
                    {
                        name: "OUTER",
                        technical_name: "outer_space"
                    },
                    {
                        name: "INNER",
                        technical_name: "inner_space"
                    }
                ]
            },
            {
                group: "numeric",
                key: "numeric_distribution",
                measures: [
                    {
                        name: "POSITIVE",
                        technical_name: "positive_count"
                    },
                    {
                        name: "NEGATIVE",
                        technical_name: "negative_count"
                    },
                    {
                        name: "ZERO",
                        technical_name: "zero_values"
                    }
                ]
            },
            {
                group: "basic_profile",
                key: "basic_profile",
                measures: [
                    {
                        name: "NULL",
                        technical_name: "null_count"
                    },
                    {
                        name: "UNIQUE",
                        technical_name: "distinct_count"
                    },
                    {
                        name: "ZERO",
                        technical_name: "zero_values"
                    },
                    {
                        name: "EMPTY",
                        technical_name: "blank_count"
                    },
                    {
                        name: "SPACE",
                        technical_name: "space_count"
                    }
                ]
            }
        ],
        frequencyMeasures: ['long_pattern', 'short_pattern', 'user_defined_patterns', 'length', 'enum']
    },
    smtpTypes: [{ label: 'Gmail', value: 'gmail' }, { label: 'Outlook', value: 'outlook' }, { label: "Send Grid", value: 'send_grid' }],
    exportPreviewOptions: ["Download Preview Records", "Download All Records"],
    statusCheckInterval: 30000,
    exportGroups: [
        { name: "Individualized", value: "individual" },
        { name: "Measures", value: "measures" },
        { name: "Assets and Measures", value: "assets_and_measures" },
        { name: "Domains", value: "domains" }
    ],
    exportCategory: [
        { name: "All", value: "all" },
        { name: "Auto Measures", value: "auto" },
        { name: "Custom Measure", value: "custom" }
    ],
    assetMeasureNames: [
        { name: "row_count", displayName: "Volume" },
        { name: "column_count", displayName: "Schema" },
        { name: "freshness", displayName: "Freshness" },
        { name: "duplicate_count", displayName: "Duplicates" },
        { name: "pattern", displayName: "Regular Expressions" }
    ],
    lookupTableHeaders: [
        { name: "ref_column", displayName: "Ref Column" },
        { name: "type", displayName: "Type" },
        { name: "mapping_column", displayName: "Mapping Column" },
        { name: "action", displayName: "Action" }
    ],
    aggregationList: [
        { name: 'Sum', value: 'sum', type: 'integer' },
        { name: 'Count', value: 'count', type: 'any' },
        { name: 'Count(Distinct)', value: 'distinct', type: 'any' },
        { name: 'Average', value: 'avg', type: 'integer' },
        { name: 'Min', value: 'min', type: 'integer' },
        { name: 'Max', value: 'max', type: 'integer' },
        { name: 'Median', value: 'median', type: 'integer' },
        { name: 'None', value: 'none', type: 'any' }
    ],
    referenceTypes: [
        { name: "FILE", value: "FILE" },
        { name: "API", value: "API" },
        { name: "TABLE", value: "TABLE" }
    ],
    delimiterTypes: [
        { name: "Comma (,)", value: "," },
        { name: "Semicolon (;)", value: ";" },
        { name: "Pipe (|)", value: "|" },
        { name: "Tab (\\t)", value: "tab" }
    ],
    alationExportProperties: [
        { name: "asset", lable: "Asset" },
        { name: "attribute", lable: "Attribute" },
        { name: "domain", lable: "Domain" }
    ],
    alationExportOptions: [
        { name: "summary", value: "Summary" },
        { name: "measures", value: "Measures" },
        { name: "alerts", value: "Alerts" },
        { name: "issues", value: "Issues" }
    ],
    connections_filter_by: ['All', 'Show Selected', 'Show Unselected'],
    sliceByErrorMessages: {
        minute: {
            errorMessages: ['Required', 'Min Minutes is 1', 'Max Minutes is 60'],
            validators: ['required', 'minNumber:1', 'maxNumber:60']
        },
        hour: {
            errorMessages: ['Required', 'Min Hours is 1', 'Max Hours is 24'],
            validators: ['required', 'minNumber:1', 'maxNumber:24']
        },
        day: {
            errorMessages: ['Required', 'Min Days is 1', 'Max Days is 31'],
            validators: ['required', 'minNumber:1', 'maxNumber:31']
        },
        month: {
            errorMessages: ['Required', 'Min Months is 1', 'Max Months is 12'],
            validators: ['required', 'minNumber:1', 'maxNumber:12']
        },
        year: {
            errorMessages: ['Required', 'Min Years is 1', 'Max Years is 5'],
            validators: ['required', 'minNumber:1', 'maxNumber:5']
        }
    },
    assetStatusFilters: [
        {
            name: 'Pending',
            value: 'Pending'
        },
        {
            name: 'Ready For Review',
            value: 'Ready For Review'
        },
        {
            name: 'Verified',
            value: 'Verified'
        },
        {
            name: 'Deprecated',
            value: 'Deprecated'
        }
    ],
    scoreSuffixes: {
        measure: 'measure_score',
        attribute: 'attribute_score',
        dimension: 'dimension_score',
        tag: 'tag_score',
        domain: 'domain_score',
        asset: 'asset_score',
        application: 'application_score'
    }
};

export default appConstants;