const style = () => ({
    runColumn: {
        width: 25,
        height: 25,
        borderRadius: 25,
        marginRight: 4,
        borderWidth: 2,
        lineHeight: '20px',
        textAlign: 'center',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    incomplete: {
        borderColor: '#F38080'
    },
    complete: {
        borderColor: '#A9C56D'
    },
    flexiContainer: {
        width: '1000px'
    },
    noPointer: {
        cursor: 'default'
    }
});

export default style;