// Import API Helper
import { getRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Domains
 * @returns
 */
async function list() {
  const response = await getRequest(`${urlConstants.semantic.glossary.domains}`);
  return response;
}

/**
 * Get Domain Detail
 */
async function detail(id) {
  const response = await getRequest(`${urlConstants.semantic.glossary.domains}${id}/`);
  return response;
}


/**
 * Get Domain Assets
 */
async function assets(id) {
  const response = await getRequest(`${urlConstants.semantic.glossary.domainAssets}${id}/`);
  return response;
}

/**
 * Get SubDomains
 */
async function subDomains(id) {
  const response = await getRequest(`${urlConstants.semantic.glossary.subDomainList}${id}/`);
  return response;
}

/**
 * Get Terms
 */
async function terms(id) {
  const response = await getRequest(`${urlConstants.semantic.glossary.terms}${id}/`);
  return response;
}

/**
 * Get Attributes
 */
async function attributes(id) {
  const response = await getRequest(`${urlConstants.semantic.glossary.attributes}${id}/`);
  return response;
}

/**
 * Get Quality
 */
async function quality(params) {
  const response = await postRequest(`${urlConstants.semantic.glossary.quality}`, params);
  return response;
}

/**
 * Export All Service Functions
 */
export const domainsService = {
  list,
  detail,
  assets,
  subDomains,
  terms,
  attributes,
  quality
};