import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';

//import Styles
import LookupStyle from "./style.jsx";
// import MultiSelectComponent from "./multiselect.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//  Import Components
import { TableComponent, AutoCompleteComponent } from '../../../../components/index.js';


function LookupComponent(props) {
    /**
     * Define Props
     */
    const { classes, attributes, lookupTableHeaders, lookupTableOptions, inputColumn, lookupOption, tableRows, lookupName, handleCellClickEvent, handleActionsClickEvent, updateLookup, lookupMetadata, userPreferenceColumns, onColumnChange, onHandleSearchEvent } = props;
    const attributeKey = attributes && attributes.map(({ id, name, datatype }) => ({ id, name, datatype }));

    return (
        <Grid container className={classes.lookupContainer}>
            <Grid container m={2} >
                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                    {
                        <Grid className={lookupMetadata && lookupMetadata.type ? classes.boxLabelContainer : classes.boxHiddenLabelContainer}>
                            {lookupMetadata.type}
                        </Grid>
                    }
                    <Grid item xs={3} className={'autocompleteTB'}>
                        <AutoCompleteComponent
                            id="lookup_id"
                            name={'lookup_id'}
                            fullWidth
                            variant="standard"
                            placeholder={"Lookup"}
                            data={lookupName || []}
                            selectedValue={lookupOption ?? ""}
                            label={"Lookup"}
                            onChange={(event, newValue) => updateLookup(newValue)}
                            disableClearable
                        />
                    </Grid>
                </Box>
            </Grid>
            {
                lookupOption &&
                <Grid container m={2} >
                    <TableComponent
                        title={'Parameters'}
                        headers={lookupTableHeaders}
                        styleType={null}
                        stickyHeader
                        data={tableRows || []}
                        options={lookupTableOptions}
                        onColumnChange={onColumnChange}
                        onCompnentEvent={handleCellClickEvent}
                        onClickActions={handleActionsClickEvent}
                        onHandleSearchEvent={onHandleSearchEvent}
                        selectComponentList={
                            {
                                "type": ["Input", "Output"],
                                "lookup": attributeKey,
                                "referance_column": inputColumn
                            }
                        }
                        userPreferenceColumns={userPreferenceColumns}
                    />
                </Grid>
            }
        </Grid>
    );
}

// default props
LookupComponent.defaultProps = {
    classes: {},
    attributes: [],
    lookupTableHeaders: [],
    lookupTableOptions: [],
    tableRows: [],
    lookupName: [],
    inputColumn: [],
    userPreferenceColumns: [],
    lookupOption: "",
    lookupMetadata: {},
    handleActionsClickEvent: () => { },
    handleCellClickEvent: () => { },
    onHandleSearchEvent: () => { },
    onColumnChange: () => { },
    updateLookup: () => { }
};

// prop types
LookupComponent.propTypes = {
    classes: PropTypes.object,
    attributes: PropTypes.array,
    lookupTableHeaders: PropTypes.array,
    lookupTableOptions: PropTypes.array,
    inputColumn: PropTypes.array,
    tableRows: PropTypes.array,
    lookupName: PropTypes.array,
    userPreferenceColumns: PropTypes.array,
    lookupOption: PropTypes.string,
    handleActionsClickEvent: PropTypes.func,
    handleCellClickEvent: PropTypes.func,
    updateLookup: PropTypes.func,
    onColumnChange: PropTypes.func,
    onHandleSearchEvent: PropTypes.func,
    lookupMetadata: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LookupStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LookupComponent);