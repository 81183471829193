import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { BarChart } from '@mui/icons-material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { shallowEqual, useSelector } from 'react-redux';

//import Styles
import ProfileViewStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';
import { PlusIcon, ProfileChartViewIcon, ProfileListViewIcon } from '../../assets/svg/index.js';
import ListView from './components/listView.jsx';
import { NoResultComponent, SwitchComponent, ValueEditorComponent } from '../index.js';
import DraggableBarChart from '../charts/draggableBarChart.jsx';
import appConstants from '../../constants/appConstants.js';
import moment from 'moment';
import { getPatternDrilldownData } from '../../helpers/appHelpers.js';


function ProfileView(props) {
    /**
     * Define Props
     */
    const {
        classes, propertiesPermission, title, description, onAdd, showValue, showToggleViewIcon,
        showAdd, showMeasureLink, enableDelete, onDelete, onItemClick, onItemToggle, chartType,
        chartHeight, showLabel, draggable, onChange, showRangeValues, onClickTrending, data,
        enableDrillDown, small, onToggleRules, isMeasureEnabled
    } = props;

    /**
     * Define State Variables
     */
    const [profileData, setProfileData] = useState([]);
    const [showList, setShowList] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(false);
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);


    /**
     * Load profile data
     */
    const loadProfileData = (data, enableDrillDown, selectedPattern) => {
        if (enableDrillDown) {
            const shortPatterns = [...JSON.parse(JSON.stringify(data))];
            data = getPatternDrilldownData(metadata, shortPatterns);
        }

        if (selectedGroup) {
            for (const pattern of data) {
                pattern.isExpanded = (pattern.name === selectedPattern) ? Boolean(!pattern.isExpanded) : false;
            }
        }
        setProfileData([...data]);
    };

    const getChartIcon = () => {
        let chartIcon = null;
        if (showList) {
            switch (chartType) {
                case "horizontal_bar":
                case "length":
                case "value":
                    chartIcon = <BarChart />;
                    break;
                default:
                    chartIcon = null;
                    break;
            }
        } else {
            chartIcon = <ProfileListViewIcon />;
        }
        return chartIcon;
    };

    /**
     * Handle value change events
     * @param {*} chartType
     * @param {*} key
     * @param {*} value
     */
    const handleValueChange = (chartType, key, value) => {
        if (chartType === "length") {
            key = key === "min" ? "min_length" : "max_length";
        } else {
            key = key === "min" ? "min_value" : "max_value";
        }
        onChange(key, value);
    };


    /**
     * Get min / max range values
     * @param {*} chartType
     * @returns
     */
    const getRangeValues = (chartType) => {
        let minValue = 0;
        let maxValue = 0;
        let min_value = "";
        let max_value = "";
        if (chartType === "length") {
            minValue = parseInt(metadata?.min_length ?? 0) || 0;
            maxValue = parseInt(metadata?.max_length ?? 0) || 0;
            min_value = minValue?.toString() || "";
            max_value = maxValue?.toString() || "";
        } else if (chartType === "value") {
            minValue = metadata?.min_value || "";
            maxValue = metadata?.max_value || "";
            min_value = metadata?.min_value || "";
            max_value = metadata?.max_value || "";
            if (metadata?.derived_type?.toLowerCase() === "integer") {
                minValue = parseInt(minValue || 0);
                maxValue = parseInt(maxValue || 0);
                min_value = minValue?.toString() || "";
                max_value = maxValue?.toString() || "";
            } else if (metadata?.derived_type?.toLowerCase() === "numeric") {
                minValue = parseFloat(minValue || 0);
                maxValue = parseFloat(maxValue || 0);
                min_value = minValue?.toString() || "";
                max_value = maxValue?.toString() || "";
            } else if (metadata?.derived_type?.toLowerCase()?.includes("date")) {
                minValue = 0;
                maxValue = 0;
                min_value = min_value ? min_value : "";
                const isDate = (metadata?.derived_type?.toLowerCase() === "date");
                if (min_value && metadata?.derived_type?.toLowerCase() === "date") {
                    if (!min_value) {
                        min_value = `${min_value} 23:59:58`;
                    }
                }
                max_value = max_value ? max_value : "";
                if (max_value && metadata?.derived_type?.toLowerCase() === "date") {
                    if (!max_value) {
                        max_value = `${max_value} 23:59:58`;
                    }
                }
                if (isDate) {
                    min_value = min_value ? moment(min_value).utc() : "";
                    max_value = max_value ? moment(max_value).utc() : "";
                } else {
                    min_value = min_value ? moment(min_value) : "";
                    max_value = max_value ? moment(max_value) : "";
                }
            } else {
                if (minValue === "NULL") {
                    minValue = "NULL";
                    min_value = "NULL";
                } else if (minValue === "" && maxValue !== "") {
                    minValue = "E";
                    min_value = "E";
                }

                if (minValue === "" && maxValue === "") {
                    minValue = " ";
                    min_value = " ";
                    maxValue = " ";
                    max_value = " ";
                }
            }
        }
        return { minValue, maxValue, min_value, max_value };
    };

    /**
     * Handle on click drill down event
     */
    const onDataDrilldown = useCallback((selectedPattern) => {
        setSelectedGroup(selectedPattern);
        const shortPatterns = [...JSON.parse(JSON.stringify(profileData))];
        for (const pattern of shortPatterns) {
            pattern.isExpanded = (pattern.name === selectedPattern) ? Boolean(!pattern.isExpanded) : false;
        }
        setProfileData([...shortPatterns]);
    }, [metadata, profileData]);

    /**
     * Use Effect to load the initial data
     */
    useEffect(() => {
        if (data) {
            loadProfileData(data, enableDrillDown, selectedGroup);
        }
    }, [data, metadata, enableDrillDown, selectedGroup]);


    const rangeValue = getRangeValues(chartType);
    let datatype = metadata?.derived_type?.toLowerCase() || "";
    if (chartType === "length") {
        datatype = "integer";
    }

    const createChartData = (profileData, rangeValue, dataType) => {
        const maxValue = rangeValue?.maxValue;
        const minValue = rangeValue?.minValue;
        if (dataType === 'chart_view') {
            /*
             * if (profileData.length < maxValue) {
             *     const missing_data = [];
             *     for (let i = profileData.length; i < maxValue; i++) {
             *         missing_data.push({
             *             count: 0,
             *             enum_value: "0",
             *             is_default: true,
             *             is_new: false,
             *             is_valid: true,
             *             length_value: "0",
             *             name: i + 1,
             *             percentage: 0,
             *             value_count: 0
             *         });
             *     }
             *     const newData = [...profileData, ...missing_data];
             *     return newData;
             * }
             * return profileData;
             */

            const min_name = Math.min(...profileData.map((item) => item.name));
            const max_name = Math.max(...profileData.map((item) => item.name));
            const missing_data = [];
            // Check if the minimum value of 'name' is greater than min_val
            if (min_name > minValue) {
                missing_data.push({
                    count: 0,
                    enum_value: "0",
                    is_default: true,
                    is_new: false,
                    is_valid: true,
                    length_value: "0",
                    name: minValue,
                    percentage: 0,
                    value_count: 0
                });
            }
            if (max_name < maxValue) {
                missing_data.push({
                    count: 0,
                    enum_value: "0",
                    is_default: true,
                    is_new: false,
                    is_valid: true,
                    length_value: "0",
                    name: maxValue,
                    percentage: 0,
                    value_count: 0
                });
            }
            const newData = [...profileData, ...missing_data];
            return newData;
        }
        const listData = profileData?.map((item) => {
            if (item.name < minValue || item.name > maxValue) {
                return { ...item, is_valid: false };
            }
            return item;
        });
        return listData;
    };


    const DraggableChartData = createChartData(profileData, rangeValue, 'chart_view');
    const ListChartData = createChartData(profileData, rangeValue, 'list_view');

    return (
        <ValidatorForm onSubmit={() => { }}>
            <Grid container className={classes.topSection}>
                <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'} alignItems="center" direction="row">
                        <Grid item className={classes.titleContainer}>
                            <Typography variant="h6" className="pb5">
                                {title}
                            </Typography>
                            <SwitchComponent size="small" checked={isMeasureEnabled} handleChange={(value) => onToggleRules(value)} />
                        </Grid>
                        <Grid item>
                            {
                                showToggleViewIcon &&
                                <Tooltip title={`${showList ? "Chart" : "List"}`}>
                                    <IconButton onClick={() => setShowList(!showList)} className={classnames(classes.chartIconClass, 'chartIcon selected')} >
                                        {getChartIcon()}
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                showMeasureLink &&
                                <Tooltip title={"Trending"}>
                                    <IconButton onClick={() => onClickTrending()} className={(classnames(classes.addIcon, classes.chartIconClass))} >
                                        <ProfileChartViewIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                showAdd && propertiesPermission?.is_edit &&
                                <Tooltip title={"Add"}>
                                    <IconButton onClick={(event) => onAdd(event)} className={(classnames(classes.addIcon, classes.chartIconClass))}>
                                        <PlusIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.description}>
                        {description}
                    </Typography>
                </Grid>
                {
                    (isMeasureEnabled && showRangeValues && (chartType === "length" || chartType === "value")) &&
                    <Grid item xs={12}>
                        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
                            <Grid item className={classes.rangeFields}>
                                <span className="rangeLabel">
                                    {`${chartType} range`}
                                </span>
                                <ValueEditorComponent placeholder={
                                    appConstants.labels
                                        .datasetProperties.min
                                }
                                    value={rangeValue?.min_value || 0}
                                    onChange={(value) => handleValueChange(chartType, "min", typeof (value) === "string" ? value : value.target.value)}
                                    variant="outlined"
                                    type={datatype}
                                    size="small"
                                    fullWidth={false}
                                    disabled={(chartType === "value" && (!metadata?.has_numeric_values && (metadata?.derived_type?.toLowerCase() === "text" || metadata?.derived_type?.toLowerCase() === "bit"))) || !propertiesPermission?.is_edit}
                                />
                                {' '}
                                <span className="rangeHypen">
                                    -
                                </span>
                                <ValueEditorComponent placeholder={
                                    appConstants.labels
                                        .datasetProperties.max
                                }
                                    value={rangeValue?.max_value || 0}
                                    onChange={(value) => handleValueChange(chartType, "max", typeof (value) === "string" ? value : value.target.value)}
                                    type={datatype}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={false}
                                    disabled={(chartType === "value" && (!metadata?.has_numeric_values && (metadata?.derived_type?.toLowerCase() === "text" || metadata?.derived_type?.toLowerCase() === "bit"))) || !propertiesPermission?.is_edit} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} className={classes.dataContainer}>
                    {
                        profileData?.length > 0 &&
                        <Grid container justifyContent={'flex-start'} alignItems="center">
                            {
                                (showList || !showToggleViewIcon) &&
                                <ListView
                                    inputData={chartType === 'length' ? ListChartData : profileData}
                                    enableDelete={enableDelete}
                                    propertiesPermission={propertiesPermission}
                                    onDelete={onDelete}
                                    onItemClick={(...params) => onItemClick(...params, "list")}
                                    onItemToggle={onItemToggle}
                                    chartType={chartType}
                                    enableDrillDown={enableDrillDown}
                                    onDataDrilldown={(group) => onDataDrilldown(group)}
                                    showValue={showValue}
                                    small={small}
                                />
                            }
                            {
                                !showList && (chartType === "length" || chartType === "value") &&
                                <DraggableBarChart
                                    inputData={chartType === 'length' ? DraggableChartData : profileData}
                                    minValue={rangeValue?.minValue || 0}
                                    maxValue={rangeValue?.maxValue || 0}
                                    chartHeight={chartHeight || 0}
                                    showLabel={showLabel}
                                    editable={draggable}
                                    chartType={chartType}
                                    onItemClick={onItemToggle}
                                    onDragChange={(key, value) => handleValueChange(chartType, key, value)}
                                    enableDrillDown={enableDrillDown}
                                    onDataDrilldown={(group) => onDataDrilldown(group)}
                                    showValue={showValue}
                                />
                            }
                        </Grid>
                    }
                    {(!isMeasureEnabled || !profileData?.length) && <NoResultComponent height="180px" title={!isMeasureEnabled ? "No measures enabled" : "No Data Discovered"} />}
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
ProfileView.defaultProps = {
    classes: {},
    title: "",
    description: "",
    chartType: "horizontal_bar",
    onAdd: () => { },
    onDelete: () => { },
    onItemClick: () => { },
    onItemToggle: () => { },
    onChange: () => { },
    onClickTrending: () => { },
    onToggleRules: () => { },
    showAdd: false,
    enableDelete: false,
    showLabel: false,
    draggable: false,
    showRangeValues: false,
    showMeasureLink: false,
    showToggleViewIcon: true,
    showValue: false,
    enableDrillDown: false,
    isMeasureEnabled: false,
    small: false,
    chartHeight: 0,
    propertiesPermission: {},
    data: []
};

// prop types
ProfileView.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    chartType: PropTypes.string,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onItemClick: PropTypes.func,
    onItemToggle: PropTypes.func,
    onChange: PropTypes.func,
    onClickTrending: PropTypes.func,
    onToggleRules: PropTypes.func,
    showAdd: PropTypes.bool,
    enableDelete: PropTypes.bool,
    showLabel: PropTypes.bool,
    draggable: PropTypes.bool,
    showValue: PropTypes.bool,
    showRangeValues: PropTypes.bool,
    showMeasureLink: PropTypes.bool,
    showToggleViewIcon: PropTypes.bool,
    enableDrillDown: PropTypes.bool,
    isMeasureEnabled: PropTypes.bool,
    small: PropTypes.bool,
    chartHeight: PropTypes.number,
    propertiesPermission: PropTypes.object,
    data: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...ProfileViewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ProfileView);