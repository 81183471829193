const style = (theme) => ({
    statusContainer: {
        width: 11,
        height: 11,
        borderRadius: 3,
        marginRight: 4,
        marginBottom: 4,
        backgroundColor: theme.palette.greyshades.grey
    },
    high: {
        backgroundColor: theme.palette.colorThemes.high
    },
    medium: {
        backgroundColor: theme.palette.colorThemes.medium
    },
    low: {
        backgroundColor: theme.palette.colorThemes.low
    },
    ok: {
        backgroundColor: theme.palette.colorThemes.ok
    }
});

export default style;