const style = (theme) => ({
    groupByRow: {
        paddingBottom: "10px",
        '& .MuiOutlinedInput-root': {
            padding: '5px 9px !important',
            borderRadius: '0px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiSelect-select': {
            padding: '6px !important'
        },
        '& .selectBox': {
            width: "100px"
        }
    }
});

export default style;