import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { shallowEqual, useSelector } from 'react-redux';

//  Import Components
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { SearchComponent, StatusDropDownComponent, SelectComponent } from '../../../../../components/index.js';

//  Import Styles
import HeaderStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Images
import { DeleteIcon, PlusIcon, MenuVerticleIcon } from '../../../../../assets/svg/index.js';
import { checkPermission, getApprovalStatus } from '../../../../../helpers/appHelpers.js';
import featureConstants from '../../../../../constants/featureConstants.js';
import appConstants from '../../../../../constants/appConstants.js';

function Header(props) {

    /**
     * Define Props
     */
    // eslint-disable-next-line no-unused-vars
    const { classes, headerTitle, addClick, handleMoreAction, actitonType, breadcrumbs, searchDetail, status, searchText, onSearchChange, data, isFilter, handleOnChange, filterData, onChangeFilter, type, onDelete, onBreadCurumAction, dataType, semanticsPermission } = props;

    /** Get Datatypes */
    const { datatypes } = useSelector((state) => state.default);
    const statistics = useSelector((state) => state.semantic.statistics);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);

    /**
     * Render Semantic Metric
     * @param {*} metricType
     * @returns
     */
    const renderSemanticMetric = (metricType) => {
        switch (metricType) {
            case 'glossary':
                return `${statistics.glossary || 0} Domains, ${statistics.category || 0} Folders and ${statistics.term || 0} Terms`;
            default:
                return `${statistics.category || 0} Folders and ${statistics.term || 0} Terms`;
        }
    };

    return (
        <Grid item xs={12}>
            <Grid container justifyContent={'space-between'} alignItems="baseline">
                <Grid>
                    {
                        breadcrumbs &&
                        <Grid item className={classes.bredcrumbContainer}>
                            {
                                breadcrumbs.map((breadcrumb, index) =>
                                    <Typography variant="subtitle1" className="item" key={`crumb_${index}`} onClick={() => (index + 1) < breadcrumbs.length && onBreadCurumAction(breadcrumb)}>
                                        {breadcrumb.name || " "}
                                    </Typography>
                                )
                            }
                        </Grid>
                    }
                    {
                        headerTitle &&
                        <Typography variant="h3" className="pb-15">
                            {headerTitle}
                        </Typography>
                    }
                </Grid>
                <Grid item className={classes.actionContainer}>
                    {
                        actitonType === "add" && semanticsPermission?.is_edit &&
                        <Tooltip title={"Add Domain"} arrow>
                            <IconButton className="p5" onClick={() => addClick()}>
                                <PlusIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        actitonType === "more" && semanticsPermission?.is_edit &&
                        <IconButton className="p5" onClick={(event) => handleMoreAction(event)}>
                            <MenuVerticleIcon />
                        </IconButton>
                    }
                    {
                        (status && (!(publishPermission?.is_none && approvePermission?.is_none))) &&
                        <StatusDropDownComponent
                            value={data?.status || "Pending"}
                            onChange={(value) => handleOnChange("status", value)}
                            dropdownValue={getApprovalStatus(publishPermission, approvePermission, data?.status || "Pending")}
                            disabled={data?.status === appConstants.status[3] || (!semanticsPermission?.is_edit && !publishPermission?.is_edit && !approvePermission?.is_edit)}
                            isReview
                        />
                    }
                    {
                        actitonType === "delete" && semanticsPermission?.is_edit &&
                        <IconButton className="p5" sx={{ marginLeft: '10px' }} onClick={() => onDelete()}>
                            <DeleteIcon />
                        </IconButton>
                    }

                </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                    {
                        (isFilter || dataType) &&
                        <Grid className={`${classes.termfilterContainer} pb-1`}>
                            {
                                isFilter &&
                                <Typography variant="h3" className={`${classes.headerTitle} mr-1`} align="left">
                                    {data.name || ""}
                                </Typography>
                            }
                            {
                                dataType &&
                                <SelectComponent
                                    value={data.derived_type || ""}
                                    list={datatypes || []}
                                    noOutline
                                    className={`${classes.outlineNone} outlineNone`}
                                    onSelectChange={(value) => handleOnChange("derived_type", value)}
                                    propertyName="name"
                                    isDisabled={!semanticsPermission?.is_edit}
                                />
                            }
                        </Grid>
                    }
                </Grid>
                {
                    searchDetail &&
                    <Grid container justifyContent={'space-between'}>
                        <SearchComponent placeholder="Search" value={searchText || ""} onChange={(value) => onSearchChange(value)} />
                        <Typography
                            variant="body1"
                            className={classes.textSecondary}
                        >
                            {renderSemanticMetric(type)}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

// default props
Header.defaultProps = {
    classes: {},
    headerTitle: '',
    breadcrumbs: [],
    searchDetail: true,
    status: false,
    searchText: "",
    onSearchChange: () => { },
    handleOnChange: () => { },
    isFilter: false,
    data: {},
    filterData: [],
    onChangeFilter: () => { },
    onDelete: () => { },
    onBreadCurumAction: () => { },
    type: "",
    dataType: false,
    semanticsPermission: {},
    handleMoreAction: () => { },
    handleAction: () => { }
};

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    headerTitle: PropTypes.string,
    actitonType: PropTypes.string,
    addClick: PropTypes.func,
    handleMoreAction: PropTypes.func,
    breadcrumbs: PropTypes.array,
    searchDetail: PropTypes.bool,
    status: PropTypes.bool,
    searchText: PropTypes.string,
    onSearchChange: PropTypes.func,
    handleOnChange: PropTypes.func,
    data: PropTypes.object,
    isFilter: PropTypes.bool,
    filterData: PropTypes.array,
    onChangeFilter: PropTypes.func,
    onDelete: PropTypes.func,
    onBreadCurumAction: PropTypes.func,
    type: PropTypes.string,
    dataType: PropTypes.bool,
    semanticsPermission: PropTypes.object
};


export default withStyles(
    (theme) => ({
        ...HeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Header);