// Import API Helper
import { getRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get User Activity
 * @returns
 */
async function get(days) {
    const response = await getRequest(`${urlConstants.userActivity.url}?days=${days}`);
    return response;
}

/**
 * Get User Activity Job
 * @returns
 */
async function getActivityJobs() {
    const response = await getRequest(`${urlConstants.userActivity.userActivityJobs}`);
    return response;
}

/**
 * Get User Activity Logs
 * @param {*} params
 * @returns
 */
async function getActivityLogs(params) {
    const response = await postRequest(urlConstants.userActivity.url, params);
    return response;
}


/**
 * Export All Service Functions
 */
export const userActivityService = { get, getActivityLogs, getActivityJobs };