
const style = () => ({
    searchHeader: {
        background: '#fff !important',
        '& .MuiFormControl-root, .MuiInputBase-root': {
            width: '100%',
            minWidth: '120px !important'
        }
    }
});
export default style;