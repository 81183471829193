const style = (theme) => ({
    domainCard: {
        borderRadius: 6,
        boxShadow: '0px 1px 8px #00000021',
        background: '#fff',
        height: '100%'
    },
    header: {
        position: 'relative !important',
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    more: {
        display: 'none !important',
        position: 'absolute !important',
        right: 5,
        top: 5,
        padding: '5px !important',
        '& .MoreIcon .fillPath': {
            fill: '#fff'
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    domainCardImg: {
        height: 164,
        width: '100%',
        objectFit: 'cover',
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px'
    },
    body: {
        padding: 20,
        '& .MuiCircularProgress-root': {
            /*
             * width: '42px !important',
             * height: '42px !important'
             */
        },
        '& .MuiCircularProgress-circle': {
            strokeWidth: '4px !important'
        },
        '& .textValue': {
            fontSize: '11px !important',
            color: `${theme.palette.headers.h1.color} !important`
        }
    },
    assetsText: {
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.greyshades.darkgrey} !important`,
        '& span.assetDot': {
            background: theme.palette.greyshades.lightgrey,
            height: '6px',
            width: '6px',
            borderRadius: '30px',
            margin: '5px 10px'
        }
    }
});
export default style;