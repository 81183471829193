import Slack from './apps/slack';
import Teams from './apps/teams';

export class AppIntegration {
    constructor(type) {
        switch (type) {
            case 'teams':
                const teams = new Teams();
                this.app = teams;
                break;
            default:
                const slack = new Slack();
                this.app = slack;
                break;
        }
    }

    authenticate() {
        return new Promise((resolve, reject) => {
            this.app.authenticate().then((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    }

    getTeams(...rest) {
        return new Promise((resolve, reject) => {
            this.app.getTeams(...rest).then((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject({ error });
            });
        });
    }

    getChannels(...rest) {
        return new Promise((resolve, reject) => {
            this.app.getChannels(...rest).then((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    }

    generateToken(...rest) {
        return new Promise((resolve, reject) => {
            this.app.generateToken(...rest).then((response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    }
}