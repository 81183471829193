import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

//  Import Styles
import MoreTablesInfoResultStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { updateUserPreference } from '../../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../../redux/reducer/userReducer';

// Import Helpers
import { prepareUpdateUserPreference, getUserPreference } from '../../../../../helpers/appHelpers';

function MoreTablesInfoComponent(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;
    const dispatch = useDispatch();
    const infoContainerRef = useRef();
    const airflowMoreInfoTabs = [
        { label: "Runs", table: 'airflow_runs', title: 'Runs (Last 20 Runs)', description: "A DAG Run is an object representing an instantiation of the DAG in time." },
        { label: "Tasks", table: 'airflow_tasks', title: "Tasks", description: "A Task is the basic unit of execution in Airflow." }
    ];

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [template, setTemplate] = useState("Runs");

    /**
     * Redux Selector
     */

    const { user } = useSelector((state) => state.auth);

    // Get Selected Filter
    const selectedFilter = airflowMoreInfoTabs.find((item) => item.label === template);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", selectedFilter.table, "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", selectedFilter.table, "sorting");


    /**
     * Scroll Page Based on Tab Selection
     */
    const scrollPage = () => {
        if (infoContainerRef && infoContainerRef.current) {
            infoContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
        }
    };

    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }
        setTemplate(value);
        scrollPage();
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", selectedFilter.table, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, sTemplate, searchFilters, sortBy, orderBy) => {
        let filterData = [];
        switch (sTemplate) {
            case "Runs":
                filterData = data?.runs ?? [];
                break;
            case "Tasks":
                filterData = data?.tasks ?? [];
                break;
            default:
                filterData = data?.sub_dags ?? [];
                break;
        }

        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], template, searchData, sorting?.sortBy ?? '', sorting?.orderBy ?? 'asc'), [data || [], template, searchData, sorting?.sortBy ?? '', sorting?.orderBy ?? 'asc']);

    /**
     * Get More Info
     */
    const bindMoreInfo = () => {
        let header = [];
        switch (template) {
            case "Runs":
                header = appConstants.table.airflowRunsListHeader;
                break;
            case "Tasks":
                header = appConstants.table.airflowTaskListHeader;
                break;
            default:
                header = appConstants.table.airflowSubDAGsHeader;
                break;
        }

        return <TableComponent
            title={selectedFilter.title}
            headers={header}
            stickyHeader
            data={filterData}
            options={appConstants.tableOptions.common}
            searchData={searchData}
            sortBy={sorting?.sortBy ?? ''}
            orderBy={sorting?.orderBy ?? 'asc'}
            onHandleSearchEvent={onHandleSearchEvent}
            onClickSorting={onClickSorting}
            styleType="striped"
            NoResultText="No Records Found"
            height="450px"
            userPreferenceColumns={columns || []}
            onColumnChange={(columns) => onColumnsChange(columns)}
        />;
    };

    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
                <Grid item className={classes.filter}>
                    <Typography variant="body1" className={classes.textSecondary}>
                        {selectedFilter?.description || ""}
                    </Typography>
                </Grid>
                <ToggleButtonGroup
                    color="secondary"
                    value={template || 'Runs'}
                    exclusive
                    aria-label="text alignment"
                    onChange={(event, value) => handleFilterOnChange(value)}
                >
                    {
                        airflowMoreInfoTabs.map((item, index) =>
                            <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={item.label}>
                                <Tooltip title={item.label} arrow>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {item.label}
                                    </Typography>
                                </Tooltip>
                            </ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} className={classes.infoSection} ref={infoContainerRef} >
                <Grid item xs={12} className={classes.PreviewContainer}>
                    <Grid item xs={12} className={classes.tabsSection}>
                        {bindMoreInfo()}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
MoreTablesInfoComponent.defaultProps = {
    classes: {},
    data: {}
};

// prop types
MoreTablesInfoComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object
};

/**
 * Export Component
 */
export default withStyles(
    (theme) => ({
        ...MoreTablesInfoResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(MoreTablesInfoComponent);