import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

// import Components
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import style from '../style.jsx';

const DatePickerComponent = (props) => {
    /**
     * Define Props
     */
    const { value, onChange, format, variant, classes, ...rest } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                PopperProps={
                    {
                        className: classes.datePickerDialog
                    }
                }
                value={value ? moment(value).format(format || "YYYY-MM-DD") : null}
                onChange={
                    (date) => {
                        onChange(date ? moment(date).format(format || "YYYY-MM-DD") : date);
                    }
                }
                renderInput={(params) => <TextField variant={variant} {...params} {...rest} />}
                {...rest}
            />
        </LocalizationProvider>
    );
};


// default props
DatePickerComponent.defaultProps = {
    value: "",
    onChange: () => { },
    label: "",
    format: "YYYY-MM-DD",
    variant: 'standard'
};

// prop types
DatePickerComponent.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    format: PropTypes.string,
    variant: PropTypes.string,
    classes: PropTypes.object
};

export default withStyles(style)(DatePickerComponent);