import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, IconButton } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import { AutoCompleteComponent, SelectComponent } from '../../../../components/index.js';

// Import Icons
import { DeleteIcon, PlusFilledIcon } from '../../../../assets/svg/index.js';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';


function GroupBySelector(props) {

    /**
     * Define Props
     */
    const { classes, data, columns, onChange, reportsPermission } = props;

    /**
     * Handle Group By
     * @param {*} key
     * @param {*} value
     */
    const updateGroupBy = (key, value, index) => {
        const group_by_data = [...data];
        const group_by_property = { ...group_by_data[index] };
        group_by_property[key] = value;
        group_by_data[index] = group_by_property;
        onChange('group_by', group_by_data);
    };

    /**
     * Add New Group By
     */
    const addNewGroupBy = () => {
        const group_by_data = [...data];
        group_by_data.push({ "column": columns[0], "aggregate": "count" });
        onChange('group_by', group_by_data);
    };

    /**
     * Delete Group By
     * @param {*} index
     */
    const deleteGroupBy = (index) => {
        const group_by_data = [...data];
        group_by_data.splice(index, 1);
        onChange('group_by', group_by_data);
    };

    return (
        <Grid item xs={12}>
            <Grid container className="pb5" justifyContent="space-between" alignItems={"center"}>
                <Typography variant="body1">
                    Group By
                </Typography>
                {
                    reportsPermission?.is_edit &&
                    <IconButton onClick={() => addNewGroupBy()}>
                        <PlusFilledIcon />
                    </IconButton>
                }
            </Grid>
            {
                data && data.map((item, index) =>
                    <Grid container wrap="nowrap" key={`group_by_selection_${index}`} className={classes.groupByRow}>
                        <AutoCompleteComponent
                            className="mr-1"
                            id={`form_text_group_by_column_${index}`}
                            name={'column'}
                            variant="outlined"
                            fullWidth
                            placeholder={"Select Column"}
                            disableClearable
                            data={columns || []}
                            selectedValue={item?.column ?? ""}
                            onChange={
                                (event, value) =>
                                    updateGroupBy('column', value, index)
                            }
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={!reportsPermission?.is_edit}
                        />
                        <SelectComponent
                            fullWidth={false}
                            variant="outlined"
                            size="small"
                            propertyName={"value"}
                            className={"selectBox"}
                            list={appConstants.dropdowns.aggregation}
                            value={item?.aggregate}
                            displayPropertyName={'name'}
                            onSelectChange={(value) => updateGroupBy('aggregate', value, index)}
                            disabled={!reportsPermission?.is_edit}
                        />
                        <IconButton onClick={() => deleteGroupBy(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                )
            }
        </Grid>
    );
}

// default props
GroupBySelector.defaultProps = {
    classes: {},
    data: [],
    columns: [],
    reportsPermission: {},
    onChange: () => { }
};

// prop types
GroupBySelector.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    columns: PropTypes.array,
    reportsPermission: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GroupBySelector);