import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getUserDetailRequest, getUserDetailSuccess, getUserDetailFailure, updateUserDetailRequest, updateUserDetailSuccess,
    updateUserDetailFailure, getUsersThumbnailRequest, getUsersThumbnailSuccess, getUsersThumbnailFailure,
    getUsersStatsRequest, getUsersStatsSuccess, getUsersStatsFailure, inviteNewUserRequest, inviteNewUserSuccess, inviteNewUserFailure,
    getUsersRequest, getUsersSuccess, getUsersFailure, deleteUserRequest, deleteUserSuccess, deleteUserFailure,
    updateUserPreferenceRequest, updateUserPreferenceSuccess, updateUserPreferenceFailure

} from '../reducer/userReducer';
import { displyAlert } from "../reducer/alertReducer";
import { updateAuthUserDetails } from "../reducer/authReducer";
import { userService } from "../service";

// Import Constants
import appConstants from "../../constants/appConstants";


/**
 * Get User Minimal Fields
 * @param {*} action$
 * @returns
 */
const thumbnail_list = (action$) => action$.pipe(
    ofType(getUsersThumbnailRequest),
    mergeMap(({ payload }) => {
        return from(userService.thumbnail_list(payload)).pipe(
            concatMap((res) => of(getUsersThumbnailSuccess(res))),
            catchError((error) => of(getUsersThumbnailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Detail actions
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getUserDetailRequest),
    mergeMap(({ payload }) => {
        return from(userService.detail(payload)).pipe(
            concatMap((res) => of(getUserDetailSuccess(res))),
            catchError((error) => of(getUserDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateUserDetailRequest),
    mergeMap(({ payload }) => {
        return from(userService.update(payload)).pipe(
            concatMap((res) => of(updateUserDetailSuccess(res), updateAuthUserDetails({ ...res, type: payload.type }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateUserDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Users Statistics
 * @param {*} action$
 * @returns
 */
const get_users_stats = (action$) => action$.pipe(
    ofType(getUsersStatsRequest),
    mergeMap(({ payload }) => {
        return from(userService.get_users_stats(payload)).pipe(
            concatMap((res) => {
                return of(getUsersStatsSuccess(res));
            }),
            catchError((error) => {
                return of(getUsersStatsFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Invite New User
 * @param {*} action$
 * @returns
 */
const invite_new_user = (action$) => action$.pipe(
    ofType(inviteNewUserRequest),
    mergeMap(({ payload }) => {
        return from(userService.invite_new_user(payload)).pipe(
            concatMap((res) => {
                return of(inviteNewUserSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.invited }), getUsersRequest({ 'search': '' }));
            }),
            catchError((error) => {
                return of(inviteNewUserFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Get Users
 * @param {*} action$
 * @returns
 */
const getUsers = (action$) => action$.pipe(
    ofType(getUsersRequest),
    mergeMap(({ payload: { search } }) => {
        return from(userService.getUsers(search)).pipe(
            concatMap((res) => { return of(getUsersSuccess(res)); }),
            catchError((error) => {
                return of(getUsersFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Delete User
 * @param {*} action$
 * @returns
 */
const deleteUser = (action$) => action$.pipe(
    ofType(deleteUserRequest),
    mergeMap(({ payload: { id, purge } }) => {
        return from(userService.deleteUser(id, purge)).pipe(
            concatMap(() => {
                return of(deleteUserSuccess(id), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }));
            }),
            catchError((error) => {
                return of(deleteUserFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Update User Preference
 * @param {*} action$
 * @returns
 */
const updateUserPreference = (action$) => action$.pipe(
    ofType(updateUserPreferenceRequest),
    mergeMap(({ payload }) => {
        return from(userService.updateUserPreference(payload)).pipe(
            concatMap(() => {return of(updateUserPreferenceSuccess());}),
            catchError((error) => {
                return of(updateUserPreferenceFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

// Export All Epic Functions
export const userEpic = [detail, update, thumbnail_list, get_users_stats, invite_new_user, getUsers, deleteUser, updateUserPreference];