import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { CircularScoreComponent, NoResultComponent } from '../../../../../components/index.js';

// import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';
import { toRound } from '../../../../../helpers/appHelpers.js';

function DQScore(props) {

    const { classes, data, selectedTab } = props;
    const dispatch = useDispatch();
    const reactNavigate = useNavigate();

    /**
     * Redirect
     * @param {*} item
     */
    const redirect = (item) => {
        switch (selectedTab) {
            case 'domain':
                dispatch(navigate({ path: 'domain.detail', state: {}, params: [item.id] }));
                break;
            case 'tag':
                reactNavigate(`/catalog?tag=${item.id}`, { replace: true });
                break;
            case 'application':
                reactNavigate(`/catalog?application=${item.id}`, { replace: true });
                break;
            default:
                reactNavigate(`/catalog?term=${item.id}`, { replace: true });
                break;
        }
    };

    /**
     * Get Average Score
     * @param {*} scores
     * @returns
     */
    const getAverageScore = (scores) => {
        if (scores && scores.length) {
            const score = scores.reduce((a, b) => a + b.score, 0);
            return (score / scores.length).toFixed(1);
        }
        return 0;
    };

    /**
     * Get Avg Score
     */
    const averageScore = useMemo(() => getAverageScore(data), [data]);
    return (
        <Grid item xs={12} className="h-100">
            {
                data && data.length ?
                    <Grid className="h-100">
                        <Grid container className="h-100">
                            <Grid item xs={2} className={classes.leftContainer}>
                                <Grid item>
                                    <Grid item className={`${classes.widgetListIcon} pb-2`} align="center">
                                        <CircularScoreComponent
                                            size={55}
                                            value={averageScore || 0}
                                            showPercentage
                                            noLink
                                            showValue />
                                    </Grid>
                                    <Typography variant="h1" align="center" className="pb-1">
                                        {`${averageScore}%` || `0%`}
                                    </Typography>
                                    <Typography variant="h6" className={`pb-1`} align="center">
                                        Average score
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={10} className="p-3 h-100 overflow-auto">
                                <Grid container spacing={4} className={`${classes.widgetItem}`} alignItems={'center'}>
                                    {
                                        data && data.map((item, index) =>
                                            <Grid key={`${index}_dqscore`} item xs={3} className="dflex alignCenter">
                                                <Grid item className={classes.widgetListIcon}>
                                                    <CircularScoreComponent
                                                        size={55}
                                                        value={item.score || 0}
                                                        showPercentage
                                                        noLink
                                                        showValue />
                                                </Grid>
                                                <Grid item xs={12} className={classNames(classes.widgetItemDetail, "pointer")} onClick={() => redirect(item)}>
                                                    <Typography variant="h2" className="pointer">
                                                        {`${item.score ? toRound(item.score) : '0'}%`}
                                                    </Typography>
                                                    <Typography variant="body1" className={classNames(classes.textSecondary, "pointer")}>
                                                        {item.name || ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> :
                    <NoResultComponent height="150" title="No Data Found" />
            }
        </Grid>
    );
}

// default props
DQScore.defaultProps = {
    classes: {},
    data: [],
    selectedTab: ""
};

// prop types
DQScore.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    selectedTab: ""
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(DQScore, areEqual));