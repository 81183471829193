const style = (theme) => ({
    PreviewContainer: {
        alignContent: 'baseline'
    },
    chartIconClass: {
        backgroundColor: `${theme.palette.greyshades.lightgrey} !important`,
        borderRadius: '7px !important',
        padding: '4px !important',
        width: '32px',
        height: '32px',
        '& .chartIcon': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    daysFilter: {
        background: '#fff',
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    }
});

export default style;