// Import API Helper
import { getRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Initial Data
 * @param {*} params
 * @returns
 */
async function getDefaultData(values) {
    const response = await getRequest(`${urlConstants.default.url}?load=${values}`);
    return response;
}


/**
 * Export All Service Functions
 */
export const defaultService = {
    getDefaultData
};