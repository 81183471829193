import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//  Import Components
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent } from '../../../components/index.js';
import SchemaSelector from '../components/schemaSelector/index.jsx';

//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

/*
 * import Images
 */
import AthenaIcon from '../../../assets/img/connectors/athena.png';


// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import appConstants from '../../../constants/appConstants.js';
import { useDispatch, useSelector } from 'react-redux';
import { clearConnectionState, connectionCreateRequest, connectionDetailRequest, connectionUpdateRequest, getDBSchemasListRequest } from '../../../redux/reducer/connectionReducer.js';
import { Crypto } from '../../../helpers/index.js';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';

// Import Actions

function Athena(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, dbs_schema, saveIsLoading, schemaLoading } = useSelector((state) => state.connection);


    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'athena',
        description: '',
        database: '',
        schema: [],
        awsaccesskey: '',
        awssecretaccesskey: '',
        s3stagingdir: '',
        workgroup: '',
        region: ''
    });

    const {
        id,
        type,
        name,
        description,
        database,
        schema,
        awsaccesskey,
        awssecretaccesskey,
        s3stagingdir,
        workgroup,
        region
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getDBSchemasListRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);

    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.awsaccesskey = encryptDecrypt.decrypt(credentialDetails?.awsaccesskey ?? "");
            credentialDetails.awssecretaccesskey = encryptDecrypt.decrypt(credentialDetails?.awssecretaccesskey ?? "");
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                database,
                s3stagingdir,
                workgroup,
                region,
                schema
            }
        };
        postData.credentials.awsaccesskey = encryptDecrypt.encrypt(awsaccesskey);
        postData.credentials.awssecretaccesskey = encryptDecrypt.encrypt(awssecretaccesskey);
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(null);
    };

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Handle Database Value Selection
     * @param {*} newValue
     */
    const handleSchemaSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, schema: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={AthenaIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.athena.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.athena
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={8} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_awsaccesskey"
                                name={'awsaccesskey'}
                                value={awsaccesskey}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.awsAccessKey}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.awsAccessKey
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <PasswordInputComponent
                                id="form_text_awssecretaccesskey"
                                name={'awssecretaccesskey'}
                                value={awssecretaccesskey}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.awsSecretAccessKey}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.awsSecretAccessKey
                                        )
                                    ]
                                }
                                onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_catalogname"
                                name={'database'}
                                value={database}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.database}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.database
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_s3stagingdir"
                                name={'s3stagingdir'}
                                value={s3stagingdir}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.s3StagingDir}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.s3StagingDir
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_region"
                                name={'region'}
                                value={region}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.region}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.region
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_workgroup"
                                name={'workgroup'}
                                value={workgroup}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.workgroup}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.workgroup
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        {
                            detailById?.is_valid &&
                            <SchemaSelector
                                dbs_schema={dbs_schema || []}
                                schema={schema || []}
                                handleSchemaSelection={handleSchemaSelection}
                                schemaLoading={schemaLoading}
                                detailById={detailById}
                                divSpan={4}
                            />
                        }
                    </Grid>
                    <Grid container spacing={4} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid >
        </Grid >
    );
}

// default props
Athena.defaultProps = {
    classes: {}
};

// prop types
Athena.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Athena);