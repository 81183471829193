import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

//  Import Components
import { LoaderButtonComponent, MemoTextBoxComponent, PasswordInputComponent, SelectComponent } from '../../../components/index.js';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import EmailIcon from '../../../assets/img/apps/email.png';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import { Crypto, checkPermission } from '../../../helpers/index.js';

// Import Actions
import { getConfigurationRequest, createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';
import featureConstants from '../../../constants/featureConstants.js';

function Email(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const defaultConfiguration = {
        host: "",
        port: "",
        user_name: "",
        password: "",
        ssl: false,
        smtp_type: appConstants.smtpTypes[0].value
    };
    const encryptDecrypt = new Crypto();

    /**
     * Define State
     */
    const [checkChanges, setCheckChanges] = useState(false);
    const [formData, setFormData] = useState({ ...defaultConfiguration });

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };

    /**
     * On Save
     */
    const onSave = () => {
        const credentials = formData.smtp_type !== appConstants.smtpTypes[2].value ? {
            user_name: encryptDecrypt.encrypt(formData?.user_name ?? ""),
            password: encryptDecrypt.encrypt(formData?.password ?? "")
        } : {
            apiKey: encryptDecrypt.encrypt(formData?.apiKey ?? "")
        };
        const config = {
            ...formData,
            ...credentials
        };
        const requestParams = {
            config,
            channel: channel.id,
            id: channelId,
            channelName: "email"
        };
        if (!channelId) {
            dispatch(createConfigrationRequest(requestParams));
        } else {
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);


    /**
     * Set FormData
     */
    useEffect(() => {
        if (configuration && channelId) {
            const config = { ...configuration };
            if (config.smtp_type !== appConstants.smtpTypes[2].value) {
                config.user_name = encryptDecrypt.decrypt(config?.user_name ?? "");
                config.password = encryptDecrypt.decrypt(config?.password ?? "");
            } else {
                config.apiKey = encryptDecrypt.decrypt(config?.apiKey ?? "");
            }
            setFormData({ ...config });
        }
    }, [configuration]);

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={EmailIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.email.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.email.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                    autoComplete="off"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <SelectComponent
                                name={'smtp_type'}
                                fullWidth={false}
                                label={
                                    <span className="requiredLabel">
                                        SMTP Server Type
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                variant="standard"
                                value={formData.smtp_type || appConstants.smtpTypes[0].value}
                                onSelectChange={(value) => onChange('smtp_type', value)}
                                list={appConstants.smtpTypes}
                                displayPropertyName="label"
                                propertyName="value"
                                validators={['required']}
                                errorMessages={[`Server Type ${appConstants.errorMessages.requriedField}`]}
                                isDisabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                name="host"
                                value={formData.host || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        SMTP Server
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Server Host ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                name="port"
                                value={formData.port || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Port
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Port ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        {
                            formData.smtp_type !== appConstants.smtpTypes[2].value &&
                            <Fragment>
                                <Grid item xs={5}>
                                    <MemoTextBoxComponent
                                        id="username"
                                        value={formData.user_name || ""}
                                        onChange={(event) => onChange("user_name", event.target.value)}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                Username
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={[`Username ${appConstants.errorMessages.requriedField}`]}
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <PasswordInputComponent
                                        id="password"
                                        value={formData.password || ""}
                                        onChange={(event) => onChange("password", event.target.value)}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                Password
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['passwordRequired']}
                                        errorMessages={[`password ${appConstants.errorMessages.requriedField}`]}
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                            </Fragment>
                        }
                        {
                            formData.smtp_type === appConstants.smtpTypes[2].value &&
                            <Fragment>
                                <Grid item xs={5}>
                                    <MemoTextBoxComponent
                                        value={formData.sender_email || ""}
                                        onChange={(event) => onChange("sender_email", event.target.value)}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                Sender Email
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required', 'isEmail']}
                                        errorMessages={[`Sender Email ${appConstants.errorMessages.requriedField}`, appConstants.errorMessages.invalidMail]}
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <PasswordInputComponent
                                        value={formData.apiKey || ""}
                                        onChange={(event) => onChange("apiKey", event.target.value)}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                API Key
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['passwordRequired']}
                                        errorMessages={[`API Key ${appConstants.errorMessages.requriedField}`]}
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                            </Fragment>
                        }

                        <Grid item xs={5} className={classes.checkBox}>
                            <FormControlLabel
                                control={<Checkbox disabled={!integrationPermission?.is_edit} checked={!((formData.ssl === 'false' || formData.ssl === false))} onChange={(event) => onChange("ssl", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.integration.email.useSSL}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigate(-1)}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            <LoaderButtonComponent
                                disabled={!checkChanges}
                                size="small"
                                type="submit"
                                isLoading={saveLoading}
                            >
                                {appConstants.labels.connector.save}
                            </LoaderButtonComponent>

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Email.defaultProps = {
    classes: {}
};

// prop types
Email.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Email);