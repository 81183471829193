const style = (theme) => ({
    versionContainer: {
        width: 450,
        background: '#fff',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        // position: 'fixed',
        zIndex: 9,
        right: '20px',
        height: '100%',
        boxShadow: '-4px -1px 7px #cccccc3d',
        '& .MuiTimelineItem-root:before': {
            padding: '6px 0px !important',
            flex: '0  !important'
        },
        '& .MuiTimelineConnector-root': {
            width: 5,
            backgroundColor: '#FFEDE8'
        },
        '& .MuiTimelineDot-root': {
            padding: 8,
            margin: 0,
            borderColor: '#FFEDE8'
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        '& .CloseIcon': {
            width: 22,
            height: 22
        },
        '& .closeBtn:hover': {
            '& .CloseIcon': {
                fill: theme.palette.secondary.main
            }
        }
    },
    selectedItem: {
        '& .versionNumber': {
            fontWeight: 600
        },
        '& .MuiTimelineDot-root': {
            borderWidth: '5px',
            borderColor: '#FFAF97',
            padding: 5
            // marginTop:
        },
        '& .contentContainer': {
            background: theme.palette.greyshades.lightestgrey
        }
    },
    versionList: {
        height: 'calc(100% - 48px)',
        overflowY: 'auto',
        '& .contentContainer': {
            padding: '0px 16px 12px 16px',
            borderRadius: 4,
            marginBottom: 10
        },
        '& .MuiTimelineContent-root': {
            padding: '0px 10px'
        }
    },
    versionAvatar: {
        width: '22px !important',
        height: '22px !important',
        marginRight: 6
    }
});

export default style;