import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    exportMetadataRequest, exportMetadataSuccess, exportMetadataFailure, importMetadataRequest, importMetadataSuccess, importMetadataFailure,
    exportMeasureRequest, exportMeasureSuccess, exportMeasureFailure, importMeasureRequest, importMeasureSuccess, importMeasureFailure,
    exportUsersRequest, exportUsersSuccess, exportUsersFailure, importUsersRequest, importUsersSuccess, importUsersFailure
} from '../reducer/importExportReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { importExportService } from '../service';

// Import Constant
import appConstants from '../../constants/appConstants';

/**
 * Export Metadata
 * @returns
 */
const exportMetadata = (action$) => action$.pipe(
    ofType(exportMetadataRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.exportMetadata(payload)).pipe(
            concatMap((res) => { return of(exportMetadataSuccess(res.data)); }),
            catchError((error) => { return of(exportMetadataFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Export Users
 * @returns
 */
const exportUsers = (action$) => action$.pipe(
    ofType(exportUsersRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.exportUsers(payload)).pipe(
            concatMap((res) => { return of(exportUsersSuccess(res.data)); }),
            catchError((error) => { return of(exportUsersFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Import Metadata
 * @returns
 */
const importMetadata = (action$) => action$.pipe(
    ofType(importMetadataRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.importMetadata(payload)).pipe(
            concatMap((res) => {
                if (res.data?.length) {
                    return of(importMetadataSuccess(res));
                }
                return of(importMetadataSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.import }));
            }),
            catchError((error) => { return of(importMetadataFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Export Measure
 * @returns
 */
const exportMeasure = (action$) => action$.pipe(
    ofType(exportMeasureRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.exportMeasure(payload)).pipe(
            concatMap((res) => { return of(exportMeasureSuccess(res)); }),
            catchError((error) => { return of(exportMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Import Measure
 * @returns
 */
const importMeasure = (action$) => action$.pipe(
    ofType(importMeasureRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.importMeasure(payload)).pipe(
            concatMap((res) => {
                if (res.data?.length) {
                    return of(importMetadataSuccess(res));
                }
                return of(importMeasureSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.import }));
            }),
            catchError((error) => { return of(importMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Import Users
 * @returns
 */
const importUsers = (action$) => action$.pipe(
    ofType(importUsersRequest),
    mergeMap(({ payload }) => {
        return from(importExportService.importUsers(payload)).pipe(
            concatMap((res) => {
                if (res.data?.length) {
                    return of(importUsersSuccess(res));
                }
                return of(importUsersSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.import }));
            }),
            catchError((error) => { return of(importUsersFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Import Export Functions
export const importExportEpic = [exportMetadata, importMetadata, exportMeasure, importMeasure, exportUsers, importUsers];