import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, FormHelperText } from '@mui/material';

// Import Component
import { AutoCompleteComponent } from '../../../components/index';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import { DeleteIcon, CheckIcon, CloseIcon2 } from '../../../assets/svg';


function CollibraMappingAddRow(props) {
    /**
     * Define Props
     */
    const { column, onChange, onAction, dqColumns, collibraColumns } = props;

    // Define State
    const [eventSubmit, setEventSubmit] = useState(false);

    /**
     * Trigger Event
     * @param {*} type
     * @returns
     */
    const onTriggerEvent = (type) => {
        setEventSubmit(true);
        if (type === "create" && (!column.dq_column || !column.collibra_column)) {
            return false;
        }
        onAction(type);
    };

    return (

        <Grid container className="pt-2">
            <Grid item xs={5}>
                <AutoCompleteComponent
                    name="Value"
                    variant="standard"
                    value={column?.dq_column ?? ''}
                    data={dqColumns}
                    size={'small'}
                    fullWidth
                    onChange={(event, newValue) => onChange("dq_column", newValue)}
                    placeholder="Select DQ Column"
                    freeSolo
                />
                {
                    !column.dq_column && eventSubmit &&
                    <FormHelperText error>
                        DQ Column is required
                    </FormHelperText>
                }
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5} >
                <AutoCompleteComponent
                    name="Value"
                    variant="standard"
                    value={column?.collibra_column ?? ''}
                    data={collibraColumns}
                    size={'small'}
                    fullWidth
                    onChange={(event, newValue) => onChange("collibra_column", newValue)}
                    placeholder="Select Collibra Column"
                    freeSolo
                />
                {
                    !column.collibra_column && eventSubmit &&
                    <FormHelperText error>
                        Collibra Column is required
                    </FormHelperText>
                }
            </Grid>
            <Grid item xs={1} align="center">
                {
                    column.isNew ?
                        <Fragment>
                            <Tooltip title={"Create"} arrow onClick={() => onTriggerEvent("create")}>
                                <IconButton>
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Cancel"} arrow onClick={() => onTriggerEvent("cancel")}>
                                <IconButton>
                                    <CloseIcon2 />
                                </IconButton>
                            </Tooltip>
                        </Fragment> :
                        <Tooltip title={"Delete"} arrow onClick={() => onTriggerEvent("delete")}>
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                }
            </Grid>
        </Grid>


    );
}

// default props
CollibraMappingAddRow.defaultProps = {
    classes: {},
    column: {},
    onChange: () => { },
    onAction: () => { },
    collibraColumns: [],
    dqColumns: []
};

// prop types
CollibraMappingAddRow.propTypes = {
    column: PropTypes.object,
    onChange: PropTypes.func,
    onAction: PropTypes.func,
    collibraColumns: PropTypes.array,
    dqColumns: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CollibraMappingAddRow);