const style = (theme) => ({
    themeList: {
        marginBottom: 10,
        '& .MuiGrid-root:first-child': {
            flex: 1
        },
        '& .MuiGrid-root': {
            flex: 2
        },
        '& .MuiGrid-root:last-child': {
            flex: 1
        },
        '& .MuiAccordion-root:before': {
            background: 'transparent'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px 20px 0px 32px !important',
            background: theme.palette.greyshades.tablegrey,
            '&.Mui-expanded': {
                minHeight: '48px'
            }
        },
        '& .Mui-expanded, .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px !important'
        },
        '& .PlusIcon': {
            fill: theme.palette.secondary.main
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            left: 11
        }
    },
    headerList: {
        padding: '0px 16px 15px'
    },
    cellItem: {
        padding: '0px 12px',
        whiteSpace: 'nowrap',
        '&:first-child': {
            paddingLeft: '0px !important',
            minWidth: 130,
            '& .MuiTypography-root': {
            }
        },
        '&:last-child': {
            paddingLeft: '0px !important'
        },
        '& .MuiSelect-select': {
            paddingLeft: '0px !important'
        }
    },
    styleGuideAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 24
    }
});
export default style;