const style = (theme) => ({
    domainCardContainer: {
        marginTop: '-5px !important',
        paddingBottom: 10
    },
    domainCard: {
        position: 'relative',
        boxShadow: '0px 3px 8px #0000000f',
        borderRadius: '6px',
        padding: '16px 25px 18px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        minHeight: '186px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        justifyContent: 'center',
        '& .descriptionText': {
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .detail .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .DeleteIcon': {
            width: 20,
            height: 19
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    detail: {
        textAlign: 'center',
        marginTop: '2px !important',
        '& .moreIcon': {
            position: 'absolute',
            right: 10,
            top: 10,
            '&:hover .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    imgContainer: {
        marginBottom: 5,
        '& img, svg': {
            height: 40
        }
    },
    title: {
        fontSize: `${theme.palette.headers.h5.size} !important`,
        marginBottom: '12px !important',
        display: 'inline-flex'
    },
    domainImgInput: {
        display: 'none'
    },
    addtitle: {
        width: 'calc(100% - 45px)',
        marginBottom: 12,
        '& .MuiInput-input': {
            fontFamily: `${theme.palette.textFont} !important`,
            fontSize: '20px !important',
            fontWeight: 400,
            textAlign: 'center'
        }
    },
    description: {
        '& .MuiInput-input': {
            fontFamily: theme.palette.textFont,
            fontSize: '15px !important',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            color: `${theme.palette.greyshades.darkgrey} !important`,
            textAlign: 'center'
        }
    },
    glossaryList: {
        paddingLeft: '54px !important',
        WebkitBoxFlex: 1,
        paddingTop: '0px !important',
        flex: 1,
        transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        marginLeft: 0,
        '&.sideBarClose': {
            paddingLeft: '45px !important',
            transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            marginLeft: '-260px'
        },
        '@media (min-width: 1200px)': {
            '&.sideBarClose': {
                '& .MuiGrid-grid-xs-4': {
                    maxWidth: '25%',
                    flexBasis: '25%'
                }
            },
            '& .MuiGrid-grid-xs-4': {
                maxWidth: '33.333333%',
                flexBasis: '33.333333%'
            }
        },

        '@media (min-width: 1536px)': {
            '&.sideBarClose': {
                '& .MuiGrid-grid-xl-2': {
                    maxWidth: '20%',
                    flexBasis: '20%'
                }
            },
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '25%',
                flexBasis: '25%'
            }
        }
    },
    glossaryListContainer: {
        paddingTop: 0,
        // height: 'calc(100vh - 180px)',
        overflowY: 'auto',
        marginTop: '0px !important',
        flexWrap: 'nowrap'
        /*
         * paddingBottom: '24px',
         * paddingRight: '24px'
         */
    },
    subDomainCardContainer: {
        marginTop: '6px !important',
        paddingBottom: 10
    },
    subDomainCard: {
        boxShadow: '0px 2px 8px #0000000f',
        borderRadius: '6px',
        height: '100%',
        '& .descriptionText': {
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textAlign: 'left',
            color: theme.palette.greyshades.darkgrey
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .detail .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .DeleteIcon': {
            width: 20,
            height: 19
        },
        '& .cameraIcon': {
            visibility: 'hidden',
            opacity: 0
        },
        '&:hover': {
            '& .cameraIcon': {
                visibility: 'visible',
                transition: 'all 0.5s',
                opacity: 1
            }
        }
    },
    subDomainImgContainer: {
        height: 140,
        display: 'flex',
        alignItems: 'center',
        background: "#e9eef1",
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
        '&.defaultImg img': {
            height: '140px',
            objectFit: 'contain !important'
        },
        '&.uploadedImg img': {
            height: '140px !important'
        },
        '& img': {
            height: '140px',
            objectFit: 'fill',
            width: '100%',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px'
        }
    },
    subDomaindetail: {
        padding: '16px 20px 18px !important',
        '& .CameraIcon:hover path': {
            fill: `${theme.palette.secondary.main} !important`
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    subDomainTitle: {
        width: 'calc(100% - 45px)',
        '& .MuiInput-input': {
            fontFamily: `${theme.palette.titleFont} !important`,
            fontSize: `${theme.palette.headers.h5.size} !important`,
            fontWeight: 500
        }
    },
    subDomainDescription: {
        '& .MuiInput-input': {
            fontFamily: theme.palette.textFont,
            fontSize: '15px !important',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            color: `${theme.palette.greyshades.darkgrey} !important`
        }
    }
});
export default style;