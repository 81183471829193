import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withStyles } from '@mui/styles';

//  Import Styles
import styles from '../../layouts/style.jsx';

// Import Components
import Integration from './integration.jsx';
import Email from './email/index.jsx';
import Slack from './slack/index.jsx';
import Teams from './teams/index.jsx';
import AzureActiveDirectory from './azureActiveDirectory/index.jsx';
import Collibra from './collibra/index.jsx';
import Alation from './alation/index.jsx';
import Jira from './jira/index.jsx';
import PingIdentity from './pingIdentity/index.jsx';
import OktaIdentity from './okta/index.jsx';
import IbmSaml from './ibm_saml/index.jsx';
import Bigpanda from './bigpanda/index.jsx';

function AppIntegrationRouter() {
    return (
        <Routes>
            <Route index element={<Integration />} />
            <Route exact path="email" element={<Email />}>
                <Route exact path=":id" element={<Email />} />
                <Route index element={<Email />} />
            </Route>
            <Route exact path="slack/:id" element={<Slack />} />
            <Route exact path="teams" element={<Teams />}>
                <Route exact path=":id" element={<Teams />} />
                <Route index element={<Teams />} />
            </Route>
            <Route exact path="azuread" element={<AzureActiveDirectory />}>
                <Route exact path=":id" element={<AzureActiveDirectory />} />
                <Route index element={<AzureActiveDirectory />} />
            </Route>
            <Route exact path="collibra" element={<Collibra />}>
                <Route exact path=":id" element={<Collibra />} />
                <Route index element={<Collibra />} />
            </Route>
            <Route exact path="alation" element={<Alation />}>
                <Route exact path=":id" element={<Alation />} />
                <Route index element={<Alation />} />
            </Route>
            <Route exact path="jira" element={<Jira />}>
                <Route exact path=":id" element={<Jira />} />
                <Route index element={<Jira />} />
            </Route>
            <Route exact path="ping_identity" element={<PingIdentity />}>
                <Route exact path=":id" element={<PingIdentity />} />
                <Route index element={<PingIdentity />} />
            </Route>
            <Route exact path="okta" element={<OktaIdentity />}>
                <Route exact path=":id" element={<OktaIdentity />} />
                <Route index element={<OktaIdentity />} />
            </Route>
            <Route exact path="ibm_saml" element={<IbmSaml />}>
                <Route exact path=":id" element={<IbmSaml />} />
                <Route index element={<IbmSaml />} />
            </Route>
            <Route exact path="bigpanda" element={<Bigpanda />}>
                <Route exact path=":id" element={<Bigpanda />} />
                <Route index element={<Bigpanda />} />
            </Route>
        </Routes>
    );
}

export default withStyles(
    (theme) => ({
        ...styles(theme)
    }),
    { withTheme: true }
)(AppIntegrationRouter);