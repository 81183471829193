import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Tooltip, Typography } from '@mui/material';

//  Import Styles
import DbtInfoStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { DatabricksRunsComponent } from '../databricksRuns/index.jsx';
import appConstants from '../../../../../constants/appConstants.js';


function DatabricksInfoComponent(props) {

    /**
     * Define Props
     */
    const { classes, versionDetail } = props;
    const databricksInfoTabs = [
        { label: "Runs", description: "Last Runs for the Pipeline" },
        { label: "Events", description: "Events and updates for the last run" }
    ];
    const databricksJobInfoTabs = [
        { label: "Runs", description: "Last Runs for the Job" },
        { label: "Tasks", description: "Tasks defined in the job" }
    ];

    /**
     * Define State
     */
    const [template, setTemplate] = useState("Runs");

    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }
        setTemplate(value);
    };

    /**
     * Get Databricks Info
     */
    const bindDatabricksPipelineInfo = () => {
        switch (template) {
            case "Events":
                return <DatabricksRunsComponent data={versionDetail?.workflow_info?.workflow_events ?? []} headers={appConstants.table.databricksPipelineEventsListHeader} title={template} />;
            default:
                return <DatabricksRunsComponent data={versionDetail?.workflow_info?.last_runs ?? []} headers={appConstants.table.databricksPipelineRunsListHeader} title={template} />;
        }
    };

    /**
     * Get Databricks Info
     */
    const bindDatabricksJobInfo = () => {
        switch (template) {
            case "Tasks":
                return <DatabricksRunsComponent data={versionDetail?.workflow_info?.lineage?.tables ?? []} headers={appConstants.table.databricksJobstasksListHeader} title={template} />;
            default:
                return <DatabricksRunsComponent data={versionDetail?.workflow_info?.workflow_events ?? []} headers={appConstants.table.databricksJobsRunsListHeader} title={template} />;
        }
    };

    // Get Selected Filter
    const selectedFilter = databricksInfoTabs
    .find((item) => item.label === template);

    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
                <Grid item className={classes.filter}>
                    <Typography variant="body1" className={classes.textSecondary}>
                        {selectedFilter?.description || ""}
                    </Typography>
                </Grid>
                {
                    versionDetail?.type.toLowerCase() === "pipeline" &&
                    <ToggleButtonGroup
                        color="secondary"
                        value={template || 'All'}
                        exclusive
                        aria-label="text alignment"
                        onChange={(event, value) => handleFilterOnChange(value)}
                    >
                        {
                            databricksInfoTabs.map((data, index) =>
                                <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                    <Tooltip title={data.label} arrow>
                                        <Typography variant="body2" className={classes.textSecondary}>
                                            {data.label}
                                        </Typography>
                                    </Tooltip>
                                </ToggleButton>
                            )
                        }
                    </ToggleButtonGroup>
                }
                {
                    versionDetail?.type.toLowerCase() === "job" &&
                    <ToggleButtonGroup
                        color="secondary"
                        value={template || 'All'}
                        exclusive
                        aria-label="text alignment"
                        onChange={(event, value) => handleFilterOnChange(value)}
                    >
                        {
                            databricksJobInfoTabs.map((data, index) =>
                                <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                    <Tooltip title={data.label} arrow>
                                        <Typography variant="body2" className={classes.textSecondary}>
                                            {data.label}
                                        </Typography>
                                    </Tooltip>
                                </ToggleButton>
                            )
                        }
                    </ToggleButtonGroup>
                }
            </Grid>
                {
                    versionDetail?.type.toLowerCase() === "pipeline" &&
                    <Grid item xs={12} className={classes.infoSection} >
                        {bindDatabricksPipelineInfo()}
                    </Grid>
                }
                {
                    versionDetail?.type.toLowerCase() === "job" &&
                    <Grid item xs={12} className={classes.infoSection} >
                        {bindDatabricksJobInfo()}
                    </Grid>
                }
        </Grid>
    );
}

// default props
DatabricksInfoComponent.defaultProps = {
    classes: {},
    versionDetail: {}
};

// prop types
DatabricksInfoComponent.propTypes = {
    classes: PropTypes.object,
    versionDetail: PropTypes.object
};


/**
 * Export Component
 */
export default withStyles(
    (theme) => ({
        ...DbtInfoStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DatabricksInfoComponent);