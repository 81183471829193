import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { CircularProgress, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

//  Import Components


//  Import Styles
import BarChartViewStyle from '../style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';
import palette from '../../../assets/theme/palette.js';
import appConstants from '../../../constants/appConstants.js';

function StatisticsPreviewChart(props) {

    /**
     * Define Props
     */
    const { classes, theme, previewData, name } = props;
    const isLoading = useSelector((state) => state.attribute.isLoading || false);

    /**
     * Define Chart Options
     */
    const option = {
        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                show: false
                /*
                 * type: 'cross',
                 * lineStyle: {
                 *     type: 'dashed',
                 *     width: 0
                 * }
                 */
            }
        },
        // X Axis Label Style and content
        xAxis: {
            name: `${name}`,
            nameLocation: "middle",
            nameTextStyle: {
                padding: [30, 0, 0, 0]
            },
            type: 'category',
            data: previewData[name.toLowerCase()],
            splitLine: {
                show: false
            },
            axisLine: {
                show: true,
                onZero: false,
                lineStyle: {
                    color: theme.palette.greyshades.grey
                }
            },
            axisLabel: {
                show: true,
                rotate: 30
            },
            axisTick: {
                show: false,
                alignWithLabel: false
            }
        },
        // Y Axis Label Style and content
        yAxis: {
            name: "COUNT",
            nameLocation: "middle",
            nameTextStyle: {
                padding: [0, 0, 30, 0]
            },
            type: 'value',
            splitLine: {
                show: false
            },
            axisLine: {
                show: true,
                onZero: false,
                lineStyle: {
                    color: theme.palette.greyshades.grey
                }
            },
            axisLabel: {
                show: true
            },
            axisTick: {
                show: false,
                alignWithLabel: false
            },
            max: (value) => {
                return value.max * 2;
            }
        },
        /**
         * Set position of the canvas
         */
        grid: {
            top: 50,
            left: 60,
            right: 0,
            containLabel: false
        },
        // Enable zoom inside the chart
        dataZoom: [
            {
              type: 'inside'
            }
        ],
        // Set Type and Data of the chart
        series: [
            {
                name: 'COUNT',
                type: 'bar',
                barMaxWidth: 10,
                barCategoryGap: '1%',
                emphasis: {
                    itemStyle: {
                        opacity: 'none'
                    }
                },
                data: previewData?.total_count?.map((item) => ({
                    value: item,
                    cursor: 'pointer',
                    itemStyle: {
                        color: '#c8eeff'
                    }
                })) || []
            }
        ]
    };

    return (
        <Grid container justifyContent={'center'} alignItems="center" style={{ minHeight: '400px' }}>
            {
                isLoading ? (
                    <Grid item className={classes.loaderContainer}>
                        <CircularProgress size={24} color="primary" />
                    </Grid>
                ) : (
                    Object.keys(previewData).length > 0 && (
                        <Grid
                            item
                            xs={12}
                            className={classnames(classes.statisticsBarChartView)}
                        >
                            <ReactEcharts
                                option={option}
                                opts={{ renderer: 'svg' }}
                                id="barChart_Id"
                                className={'profileChart'}
                                style={{ height: 400 }}
                            />
                        </Grid>
                    )
                )
            }
        </Grid>
    );
}

// default props
StatisticsPreviewChart.defaultProps = {
    classes: {},
    theme: {},
    previewData: {},
    name: ''
};

// prop types
StatisticsPreviewChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    previewData: PropTypes.object,
    name: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...BarChartViewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StatisticsPreviewChart);