import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions and Reducer
import {
    getVersionRequest, getVersionSuccess, getVersionFailure, updateVersionRequest, updateVersionSuccess, updateVersionFailure,
    updateFavoriteRequest, updateFavoriteSuccess, updateFavoriteFailure, shareRequest, shareSuccess, shareFailure, getVersionAlertsRequest, getVersionAlertsSuccess, getVersionAlertsFailure,
    termApprovalRequest, termApprovalSuccess, termApprovalFailure, deleteApprovedTermSuccess, deleteApprovedTermRequest, deleteApprovedTermFailure,
    getAssetStatusRequest, getAssetStatusFailure, getAssetStatusSuccess, getVersionHistoryRequest, getVersionHistorySuccess, getVersionHistoryFailure, updateVersionDetail,
    getMeasureLogsRequest, getMeasureLogsSuccess, getMeasureLogsFailure
} from '../reducer/versionReducer';
import { displyAlert } from "../reducer/alertReducer";
import { updateAssetDetailInState } from "../reducer/assetReducer";
import { resetUsageDetail } from "../reducer/usageReducer";
import { updateApprovedTerm } from '../reducer/termReducer';
import { navigate } from '../reducer/navigationReducer';


// Import Service
import { versionService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';
import { updateAttributeRunStatus } from '../reducer/attributeReducer';
import { updateMeasuresMetadata } from '../reducer/measureReducer';


/**
 * Detail actions
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getVersionRequest),
    mergeMap(({ payload }) => {
        return from(versionService.detail(payload)).pipe(
            concatMap((res) => of(getVersionSuccess(res), updateAssetDetailInState(res), resetUsageDetail())),
            catchError((error) => {
                const errorMsg = error;
                if (validate404Error(errorMsg)) {
                    return of(getVersionFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
                }
                return of(getVersionFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
            })
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateVersionRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(versionService.update(id, rest)).pipe(
            concatMap((res) => of(updateVersionSuccess(res), updateVersionDetail(res.data?.version_info ?? {}), updateMeasuresMetadata({ id, ...rest }))),
            catchError((error) => of(updateVersionFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Favorite Actions
 * @param {*} action$
 * @returns
 */
const favorite = (action$) => action$.pipe(
    ofType(updateFavoriteRequest),
    mergeMap(({ payload }) => {
        return from(versionService.favorite(payload)).pipe(
            concatMap((res) => of(updateFavoriteSuccess(res))),
            catchError((error) => of(updateFavoriteFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Share Actions
 * @param {*} action$
 * @returns
 */
const share = (action$) => action$.pipe(
    ofType(shareRequest),
    mergeMap(({ payload }) => {
        return from(versionService.share(payload)).pipe(
            concatMap((res) => of(shareSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.shared }))),
            catchError((error) => of(shareFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Alerts
 * @param {*} action$
 * @returns
 */
const alerts = (action$) => action$.pipe(
    ofType(getVersionAlertsRequest),
    mergeMap(({ payload }) => {
        return from(versionService.alerts(payload)).pipe(
            concatMap((res) => of(getVersionAlertsSuccess(res))),
            catchError((error) => of(getVersionAlertsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Approve Terms
 * @param {*} action$
 * @returns
 */
const approveTerms = (action$) => action$.pipe(
    ofType(termApprovalRequest),
    mergeMap(({ payload }) => {
        return from(versionService.approve_term(payload)).pipe(
            concatMap((res) => of(termApprovalSuccess({ data: res.data?.data ?? {} }), updateVersionDetail(res.data?.version_info ?? {}), updateApprovedTerm({ data: res.data?.data ?? {} }))),
            catchError((error) => of(termApprovalFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Approved Term
 * @param {*} action$
 * @returns
 */
const deleteApprovedTerm = (action$) => action$.pipe(
    ofType(deleteApprovedTermRequest),
    mergeMap(({ payload }) => {
        return from(versionService.delete_approved_term(payload)).pipe(
            concatMap((res) => of(deleteApprovedTermSuccess(res))),
            catchError((error) => of(deleteApprovedTermFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Dag Running Status
 * @param {*} action$
 * @returns
 */
const getDagRunningStatus = (action$) => action$.pipe(
    ofType(getAssetStatusRequest),
    mergeMap(({ payload }) => {
        return from(versionService.get_asset_status(payload)).pipe(
            concatMap((res) => of(getAssetStatusSuccess(res.data), updateAttributeRunStatus(res.data))),
            catchError(() => of(getAssetStatusFailure()))
        );
    })
);

/**
 * List actions
 * @param {*} action$
 * @returns
 */
const getVersionHistoryList = (action$) => action$.pipe(
    ofType(getVersionHistoryRequest),
    mergeMap(({ payload: { id, limit, offset } }) => {
        return from(versionService.getVersionHistoryList(id, limit, offset)).pipe(
            concatMap((res) => of(getVersionHistorySuccess({ ...res, offset }))),
            catchError((error) => of(getVersionHistoryFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get measure
 * @returns
 */
 const getMeasureLogs = (action$) => action$.pipe(
    ofType(getMeasureLogsRequest),
    mergeMap(({ payload: { id, measureId, limit, offset } }) => {
        return from(versionService.getVersionHistoryList(id, limit, offset, measureId)).pipe(
            concatMap((res) => { return of(getMeasureLogsSuccess({ data: res.data, offset })); }),
            catchError(() => { return of(getMeasureLogsFailure()); })
        );
    })
);

// Export All Epic Functions
export const versionEpic = [detail, update, favorite, share, alerts, approveTerms, deleteApprovedTerm, getDagRunningStatus, getVersionHistoryList, getMeasureLogs];