const style = (theme) => ({
    tabHeader: {
        // padding: '16px',
        background: '#fff',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.greyshades.lightgrey}`,
        display: 'flex'
    },
    tabsLink: {
        paddingLeft: '15px',
        paddingRight: '15px',
        textDecoration: 'none',
        cursor: 'pointer',
        '& .MuiTypography-root': {
            textDecoration: 'none',
            color: `${theme.palette.headers.body1.color} !important`,
            padding: '15px 16px 10px',
            '&.active': {
                borderBottom: `3px solid ${theme.palette.secondary.main}`,
                color: `${theme.palette.secondary.main} !important`,
                fontWeight: 500
            }
        }
    }
});

export default style;