import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    detailById: {},
    dbs_schema: [],
    pipelineInfo: {},
    adf_factories: {},
    configured_schema: [],
    catalogConnectionsList: [],
    list: [],
    connectionFilters: {
        sortBy: 'name',
        orderBy: 'asc',
        search: "",
        offset: 0,
        limit: 20,
        loadMore: true
    },
    newlyCreatedId: null,
    saveIsLoading: false,
    copyLoading: false,
    schemaLoading: false,
    isConnectionChanged: false,
    searchableConnections: [],
    pipelineLoading: false,
    connections: [],
    connectionCount: 0
};

const connectionSlice = createSlice({
    name: 'connection',
    initialState: { ...initialState },
    reducers: {
        connectionListRequest(state) {
            state.isLoading = true;
        },
        connectionListSuccess(state, action) {
            state.isLoading = false;
            state.list = action.payload.data ? action.payload.data : [];
        },
        connectionListFailure(state) {
            state.isLoading = false;
        },
        connectionDetailRequest(state) {
            state.isLoading = true;
            state.detailById = {};
        },
        connectionDetailSuccess(state, action) {
            state.detailById = action.payload.data ?? {};
            state.isLoading = false;
        },
        connectionDetailFailure(state) {
            state.detailById = {};
            state.isLoading = false;
        },
        connectionCreateRequest(state) {
            state.saveIsLoading = true;
        },
        connectionCreateSuccess(state, action) {
            state.newlyCreatedId = action.payload.data.data?.connection_id;
            state.saveIsLoading = false;
        },
        connectionCreateFailure(state) {
            state.saveIsLoading = false;
        },
        connectionUpdateRequest(state) {
            state.saveIsLoading = true;
            state.isLoading = true;
        },
        connectionUpdateSuccess(state, action) {
            const { page_type, data: { data, connection_id }, validation_required } = action.payload;
            if (validation_required) {
                state.isConnectionChanged = true;
            }
            switch (page_type) {
                case "list":
                    state.connections = state.connections.map((m) => {
                        if (action.payload && m.id === connection_id) {
                            m.is_active = !m.is_active;
                        }
                        return m;
                    });
                    break;
                default:
                    state.detailById = {
                        ...state.detailById,
                        ...data
                    };
                    break;
            }
            state.saveIsLoading = false;
            state.isLoading = false;
        },
        connectionUpdateFailure(state) {
            state.saveIsLoading = false;
        },
        connectionDeleteRequest() { },
        connectionDeleteSuccess(state, action) {
            state.list = state.list.filter((m) => m.id !== action.payload);
            state.connections = state.connections.filter((m) => m.id !== action.payload);
            state.connectionCount -= 1;
        },
        connectionDeleteFailure() { },
        connectionCopyRequest(state) {
            state.copyLoading = true;
        },
        connectionCopySuccess(state, action) {
            const { request_data, data } = action.payload;
            request_data.id = data.connection_id;
            request_data.name = data.connection_name;
            request_data.total_assets = 0;
            request_data.total_attributes = 0;
            state.list = [...state.list, request_data];
            state.connections = [...state.connections, request_data];
            state.connectionCount += 1;
            state.copyLoading = false;
        },
        connectionCopyFailure(state) {
            state.copyLoading = false;
        },
        clearConnectionState(state) {
            state.detailById = {};
            state.newlyCreatedId = null;
            state.dbs_schema = [];
            state.configured_schema = [];
            state.isLoading = false;
            state.saveIsLoading = false;
            state.schemaLoading = false;
        },
        getCatalogConnectionsListRequest() { },
        getCatalogConnectionsListSuccess(state, action) {
            state.catalogConnectionsList = action.payload.data ? action.payload.data : [];
        },
        getCatalogConnectionsListFailure() { },
        updateConnectionState(state) {
            state.isConnectionChanged = false;
        },
        getDBSchemasListRequest(state) {
            state.schemaLoading = true;
            state.dbs_schema = [];
            state.configured_schema = [];
        },
        getDBSchemasListSuccess(state, action) {
            state.dbs_schema = action.payload?.data?.s_schema ?? [];
            state.configured_schema = action.payload?.data?.c_schema ?? [];

            if (state.detailById?.credentials?.schema?.length === 0) {
                state.detailById = {
                    ...state.detailById,
                    credentials: {
                        ...state.detailById.credentials,
                        "schema": state.configured_schema
                    }
                };
            }

            if (state.detailById?.credentials?.workspace?.length === 0) {
                state.detailById = {
                    ...state.detailById,
                    credentials: {
                        ...state.detailById.credentials,
                        "workspace": state.configured_schema
                    }
                };
            }
            state.schemaLoading = false;
        },
        getDBSchemasListFailure(state) {
            state.dbs_schema = [];
            state.configured_schema = [];
            state.schemaLoading = false;
        },
        connectionSchemaRemoveRequest(state) {
            state.schemaLoading = true;
        },
        connectionSchemaRemoveSuccess(state) {
            state.schemaLoading = false;
        },
        connectionSchemaRemoveFailure(state) {
            state.schemaLoading = false;
        },
        connectionUpdateFilters(state, action) {
            state.connectionFilters = {
                ...state.connectionFilters,
                ...action.payload
            };
        },
        getSearchConnectionsRequest() { },
        getSearchConnectionsSuccess(state, action) {
            state.searchableConnections = action.payload.data || [];
        },
        getSearchConnectionsFailure() { },
        getPipelineInfoRequest(state) {
            state.pipelineLoading = true;
        },
        getPipelineInfoSuccess(state, action) {
            state.pipelineInfo = action.payload?.data ?? {};
            state.pipelineLoading = false;
        },
        getPipelineInfoFailure(state) {
            state.pipelineLoading = false;
        },
        getConnectionsRequest(state, action) {
            const clear = action.payload?.clear ?? false;
            state.isLoading = true;
            if (clear) {
                state.connections = [];
                state.connectionFilters.loadMore = true;
            }
        },
        getConnectionsSuccess(state, action) {
            state.connectionFilters = { ...state.connectionFilters, ...action.payload.params };
            state.isLoading = false;
            const connections = action.payload.data.connections || [];
            if ((connections.length < state.connectionFilters.limit)) {
                state.connectionFilters.loadMore = false;
            }
            if (action.payload?.params?.offset === 0) {
                state.connectionCount = action.payload.data.count || 0;
                state.connections = [...connections];
            } else {
                state.connections = [...state.connections, ...connections];
            }
        },
        getConnectionsFailure(state) {
            state.isLoading = false;
        },
        getAdfFactoryDetailsRequest(state) {
            state.adfFactoryLoading = true;
        },
        getAdfFactoryDetailsSuccess(state, action) {
            state.adf_factories = action.payload?.data ?? {};
            state.adfFactoryLoading = false;
        },
        getAdfFactoryDetailsFailure(state) {
            state.adfFactoryLoading = false;
        }
    }
});

export const { connectionListRequest, connectionListSuccess, connectionListFailure, connectionDetailRequest, connectionDetailSuccess, connectionDetailFailure,
    connectionCreateRequest, connectionCreateSuccess, connectionCreateFailure, connectionUpdateRequest, connectionUpdateSuccess, connectionUpdateFailure,
    connectionDeleteRequest, connectionDeleteSuccess, connectionDeleteFailure, clearConnectionState, connectionCopyRequest, connectionCopySuccess,
    connectionCopyFailure, getCatalogConnectionsListRequest, getCatalogConnectionsListSuccess, getCatalogConnectionsListFailure, updateConnectionState,
    getDBSchemasListRequest, getDBSchemasListSuccess, getDBSchemasListFailure, connectionSchemaRemoveRequest, connectionSchemaRemoveSuccess,
    connectionSchemaRemoveFailure, connectionUpdateFilters, getSearchConnectionsRequest, getSearchConnectionsSuccess, getSearchConnectionsFailure,
    getPipelineInfoRequest, getPipelineInfoSuccess, getPipelineInfoFailure, getConnectionsRequest, getConnectionsSuccess, getConnectionsFailure, getAdfFactoryDetailsRequest, getAdfFactoryDetailsSuccess, getAdfFactoryDetailsFailure
} = connectionSlice.actions;
export default connectionSlice.reducer;