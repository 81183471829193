import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getRolesListFailure, getRolesListRequest, getRolesListSuccess, getRolesPermissionFailure, getRolesPermissionRequest,
    getRolesPermissionSuccess, addFeaturetoRoleFailure, addFeaturetoRoleRequest, addFeaturetoRoleSuccess, deleteFeatureFromRoleRequest,
    deleteFeatureFromRoleSuccess, deleteFeatureFromRoleFailure, addNewRoleRequest, addNewRoleSuccess, addNewRoleFailure, getRoleStatsRequest,
    getRoleStatsSuccess, getRoleStatsFailure, updateRoleRequest, updateRoleSuccess, updateRoleFailure, deleteRoleRequest, deleteRoleSuccess, deleteRoleFailure, updateFeaturetoRoleRequest, updateFeaturetoRoleSuccess, updateFeaturetoRoleFailure
} from '../reducer/securityReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { securityService } from '../service';


/**
 * Get Roles List
 * @param {*} action$
 * @returns
 */
const get_roles = (action$) => action$.pipe(
    ofType(getRolesListRequest),
    mergeMap(({ payload }) => {
        return from(securityService.get_roles(payload)).pipe(
            concatMap((res) => {
                return of(getRolesListSuccess(res));
            }),
            catchError((error) => {
                return of(getRolesListFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Get Roles Permission
 * @param {*} action$
 * @returns
 */
const get_roles_permission = (action$) => action$.pipe(
    ofType(getRolesPermissionRequest),
    mergeMap(({ payload }) => {
        return from(securityService.get_roles_permission(payload)).pipe(
            concatMap((res) => {
                return of(getRolesPermissionSuccess(res));
            }),
            catchError((error) => {
                return of(getRolesPermissionFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Add Feature to Role
 * @param {*} action$
 * @returns
 */
const add_feature_roles = (action$) => action$.pipe(
    ofType(addFeaturetoRoleRequest),
    mergeMap(({ payload }) => {
        return from(securityService.add_feature_roles(payload)).pipe(
            concatMap((res) => {
                return of(addFeaturetoRoleSuccess(res));
            }),
            catchError((error) => {
                return of(addFeaturetoRoleFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
 const update_features_roles = (action$) => action$.pipe(
    ofType(updateFeaturetoRoleRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(securityService.update_feature_roles(id, rest)).pipe(
            concatMap((res) => of(updateFeaturetoRoleSuccess({ ...res, requestData: { ...rest } }))),
            catchError(() => of(updateFeaturetoRoleFailure()))
        );
    })
);


/**
 * Delete Feature to Role
 * @param {*} action$
 * @returns
 */
const delete_feature_roles = (action$) => action$.pipe(
    ofType(deleteFeatureFromRoleRequest),
    mergeMap(({ payload }) => {
        return from(securityService.delete_feature_roles(payload)).pipe(
            concatMap(() => {
                return of(deleteFeatureFromRoleSuccess(payload));
            }),
            catchError((error) => {
                return of(deleteFeatureFromRoleFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Add New Role
 * @param {*} action$
 * @returns
 */
const add_new_role = (action$) => action$.pipe(
    ofType(addNewRoleRequest),
    mergeMap(({ payload }) => {
        return from(securityService.add_new_role(payload)).pipe(
            concatMap((res) => {
                return of(addNewRoleSuccess(res));
            }),
            catchError((error) => {
                return of(addNewRoleFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Get Roles Statistics
 * @param {*} action$
 * @returns
 */
const get_roles_stats = (action$) => action$.pipe(
    ofType(getRoleStatsRequest),
    mergeMap(({ payload }) => {
        return from(securityService.get_roles_stats(payload)).pipe(
            concatMap((res) => {
                return of(getRoleStatsSuccess(res));
            }),
            catchError((error) => {
                return of(getRoleStatsFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Update User Basic Details
 * @param {*} action$
 * @returns
 */
 const updateUserDetails = (action$) => action$.pipe(
    ofType(updateRoleRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(securityService.update_user_details(id, rest)).pipe(
            concatMap((res) => { return of(updateRoleSuccess(res.data), displyAlert({ 'type': 'success', 'message': 'Role Name Updated Successfully' })); }),
            catchError((error) => of(updateRoleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
  );


/**
 * Delete User
 * @param {*} action$
 * @returns
 */
 const deleteUser = (action$) => action$.pipe(
    ofType(deleteRoleRequest),
    mergeMap(({ payload }) => {
        return from(securityService.delete_user(payload)).pipe(
            concatMap(() => of(deleteRoleSuccess(payload))),
            catchError((error) => of(deleteRoleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
  );


// Export All Epic Functions
export const securityEpic = [get_roles, get_roles_permission, add_feature_roles, delete_feature_roles, add_new_role, get_roles_stats, updateUserDetails, deleteUser, update_features_roles];