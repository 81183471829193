// Import API Helper
import { postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * Export Metadata
 * @param {*} params
 * @returns
 */
async function exportMetadata(params) {
    const fileName = params.fileName || "export_metadata.xlsx";
    const response = await postRequest(urlConstants.importExport.exportMetadata, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Export Metadata
 * @param {*} params
 * @returns
 */
async function exportUsers(params) {
    const fileName = params.fileName || "export_users.xlsx";
    const response = await postRequest(urlConstants.importExport.exportUsers, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Export Metadata
 * @param {*} params
 * @returns
 */
async function importMetadata(params) {
    const response = await postRequest(urlConstants.importExport.importMetadata, params);
    return response;
}

/**
 * Export Measure
 * @param {*} params
 * @returns
 */
async function exportMeasure(params) {
    const fileName = params.fileName || "export_measure.xlsx";
    const response = await postRequest(urlConstants.importExport.exportMeasure, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Import Metadata
 * @param {*} params
 * @returns
 */
async function importMeasure(params) {
    const response = await postRequest(urlConstants.importExport.importMeasure, params);
    return response;
}

/**
 * Export Users
 * @param {*} params
 * @returns
 */
async function importUsers(params) {
    const response = await postRequest(urlConstants.importExport.importUsers, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const importExportService = {
    exportMetadata, importMetadata, exportMeasure, importMeasure, exportUsers, importUsers
};