import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography, Button, Dialog, IconButton } from '@mui/material';
import Dropzone from 'react-dropzone';
import ImportData from '../importData/index.jsx';
import ImportErrors from '../importErrors/index.jsx';

//import Styles
import GeneralStyle from "../../../../style.jsx";
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../../constants/appConstants.js';
import { CloseIcon, UploadIcon } from '../../../../../../assets/svg/index.js';

// Import Actions
import { setImportData, resetImportData } from '../../../../../../redux/reducer/importExportReducer';
import { getFieldsRequest } from '../../../../../../redux/reducer/fieldsReducer';


function ImportDialog(props) {

    const { classes, open, onClose, type } = props;
    const dispatch = useDispatch();

    // Redux Store
    const fields = useSelector(({ fields }) => fields.list);
    const { errors: importErrors, importLoading } = useSelector(({ importExport }) => importExport);

    /**
     * Define State
     */
    const [fileUpload, setFileUpload] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);

    /**
     * onCloseDialog
     */
    const onCloseDialog = () => {
        setFileUpload(false);
        dispatch(resetImportData());
        onClose();
    };

    /**
     * On Handle Upload
     * @param {*} acceptedFile
     */
    const onHandleUpload = (acceptedFile) => {
        setFileUpload(true);
        const file = acceptedFile[0];
        const fileName = file.name || "";
        const reader = new FileReader();
        reader.onload = (file) => {
            const readedData = file.target.result;
            const wb = XLSX.read(readedData, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let headers = XLSX.utils.sheet_to_json(ws, { header: 1 });
            headers = headers.length ? headers[0] : [];
            const data = XLSX.utils.sheet_to_json(ws);
            dispatch(setImportData({
                headers,
                data,
                fileName
            }));
        };
        reader.readAsArrayBuffer(file);
    };

    /**
     * Get Fields
     */
    useEffect(() => {
        if (!fields.length) {
            dispatch(getFieldsRequest());
        }
    }, []);

    /**
     * Set FormData
     */
    useEffect(() => {
        if (importLoading) {
            setFormSubmit(true);
        }
        if (formSubmit && !importLoading && !importErrors.length) {
            onCloseDialog();
        }
    }, [importLoading]);

    /**
     * Get Title
     * @param {*} type
     * @returns
     */
    const getTitle = (type) => {
        switch (type) {
            case 'Users':
                return appConstants.labels.general.importUsers;
            case 'Measure':
                return appConstants.labels.general.importMeasure;
            default:
                return appConstants.labels.general.importMeteData;
        }
    };

    /**
     * Get Description
     * @param {*} type
     * @returns
     */
    const getDescription = (type) => {
        switch (type) {
            case 'Users':
                return appConstants.labels.general.importUsersDesc;
            case 'Measure':
                return appConstants.labels.general.importMeasureDesc;
            default:
                return appConstants.labels.general.importMeteDataDesc;
        }
    };

    return (
        <Dialog
            className={classes.importContainer}
            open={open}
            fullScreen
            onClose={onCloseDialog}
        >
            <Grid className="containerInner">
                <Grid container justifyContent={"space-between"} className="pb-2">
                    <Grid>
                        <Typography variant="h2" className="pb5">
                            {getTitle(type)}
                        </Typography>
                        <Typography variant="body1">
                            {getDescription(type)}
                        </Typography>
                    </Grid>
                    <IconButton onClick={onCloseDialog} className="p5" sx={{ width: 35, height: 35, '& svg': { width: 24, height: 24 } }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                {
                    !importErrors.length ?
                        <Grid container className="h-100">
                            {
                                !fileUpload ?
                                    <Grid container className="dropContainer" alignItems={"center"} justifyContent="center">
                                        <Dropzone
                                            maxSize={200 * (1024 * 1024)}
                                            accept1={{ 'application/*': ['.csv', '.xlsx'] }}
                                            accept={
                                                {
                                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                                    'text/csv': ['.csv']
                                                }
                                            }
                                            disabled={false}
                                            onDrop={(acceptedFiles) => onHandleUpload(acceptedFiles)}
                                        >
                                            {
                                                ({ getRootProps, getInputProps }) => (
                                                    <Grid align="center"
                                                        {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <UploadIcon />
                                                        <Typography variant="body1" className="pb-2 pt-2">
                                                            Drag & drop file here or
                                                        </Typography>
                                                        <Button className="chooseFileBtn" variant="outlined" size="small" color="secondary">
                                                            Choose file
                                                        </Button>
                                                    </Grid>
                                                )
                                            }
                                        </Dropzone>
                                    </Grid> :
                                    <ImportData type={type} onClose={onCloseDialog} />

                            }
                        </Grid>
                        :
                        <ImportErrors onClose={onCloseDialog} type={type} />
                }


            </Grid>
        </Dialog>
    );
}

// default props
ImportDialog.defaultProps = {
    classes: {},
    open: false,
    onClose: () => { },
    type: ""
};

// prop types
ImportDialog.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ImportDialog);