import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  assetsLoading: false,
  domains: [],
  assets: [],
  detail: {},
  subDomains: [],
  domainLoading: false,
  termsLoading: false,
  terms: [],
  attributes: [],
  attributeLoading: false,
  termList: [],
  quality: [],
  qualityIsLoading: false
};

const domainsSlice = createSlice({
  name: 'domains',
  initialState: { ...initialState },
  reducers: {
    getDomainsRequest(state) {
      state.isLoading = true;
    },
    getDomainsSuccess(state, action) {
      const { data } = action.payload;
      state.domains = data || [];
      state.isLoading = false;
    },
    getDomainsFailure(state) {
      state.isLoading = false;
    },
    getDomainsAssetsRequest(state) {
      state.assetsLoading = true;
      state.assets = [];
    },
    getDomainsAssetsSuccess(state, action) {
      const { data } = action.payload;
      state.assets = data || [];
      state.assetsLoading = false;
    },
    getDomainsAssetsFailure(state) {
      state.isLoading = false;
    },
    getDomainDetailRequest(state) {
      state.isLoading = true;
      state.detail = {};
    },
    getDomainDetailSuccess(state, action) {
      const { data } = action.payload;
      state.detail = data || {};
      state.isLoading = false;
    },
    getDomainDetailFailure(state) {
      state.isLoading = false;
    },
    getSubDomainRequest(state) {
      state.domainLoading = true;
      state.subDomains = [];
    },
    getSubDomainSuccess(state, action) {
      state.subDomains = action.payload.data || [];
      state.domainLoading = false;
    },
    getSubDomainFailure(state) {
      state.domainLoading = false;
    },
    getDomainTermsRequest(state) {
      state.termsLoading = true;
      state.terms = [];
    },
    getDomainTermsSuccess(state, action) {
      state.terms = action.payload.data || [];
      state.termsLoading = false;
    },
    getDomainTermsFailure(state) {
      state.termsLoading = false;
    },
    getDomainAttributesRequest(state) {
      state.attributeLoading = true;
      state.attributes = [];
      state.termList = [];
    },
    getDomainAttributesSuccess(state, action) {
      state.attributeLoading = false;
      state.attributes = action.payload.data?.data?.attributes || [];
      state.termList = action.payload.data?.data?.terms || [];
    },
    getDomainAttributesFailure(state) {
      state.attributeLoading = false;
    },
    getDomainQualityRequest(state, action) {
      if (action.payload.clear) {
        state.qualityIsLoading = true;
      }
    },
    getDomainQualitySucess(state, action) {
      const { clear, accordian } = action.payload;
      const data = action.payload.data || [];
      if (clear) {
        state.quality = data || [];
      } else {
        if (accordian && Object.keys(accordian).length) {
          const { type, asset_id, attribute_id } = accordian;
          const index = state.quality.findIndex((data) => data.id === asset_id);
          if (type === "asset") {
            state.quality[index].isLoading = false;
            state.quality[index].children = data;
          } else {
            const attributes = state.quality[index].children || [];
            const attributeIndex = attributes.findIndex((data) => data.id === attribute_id);
            state.quality[index].children[attributeIndex].isLoading = false;
            state.quality[index].children[attributeIndex].children = data;
          }
        }
      }
      state.qualityIsLoading = false;
    },
    getDomainQualityFailure(state) {
      state.qualityIsLoading = false;
    },
    collapseAccordian(state, action) {
      const { type, asset_id, attribute_id, value } = action.payload;
      const index = state.quality.findIndex((data) => data.id === asset_id);
      if (type === "asset") {
        if (value && !state.quality[index]?.children?.length) {
          state.quality[index].isLoading = true;
        }
        state.quality[index].isOpen = value;
      } else {
        const attributes = state.quality[index].children || [];
        const attributeIndex = attributes.findIndex((data) => data.id === attribute_id);
        if (value && !state.quality[index]?.children[attributeIndex]?.children?.length) {
          state.quality[index].children[attributeIndex].isLoading = true;
        }
        state.quality[index].children[attributeIndex].isOpen = value;
      }
    },
    clearDomainDetails(state) {
      state.detail = {};
      state.subDomains = [];
      state.assets = [];
      state.attributes = [];
      state.termList = [];
      state.quality = [];
    }
  }
});

export const { getDomainsRequest, getDomainsSuccess, getDomainsFailure, getDomainsAssetsRequest, getDomainsAssetsSuccess, getDomainsAssetsFailure,
  getDomainDetailRequest, getDomainDetailSuccess, getDomainDetailFailure, getSubDomainRequest, getSubDomainSuccess, getSubDomainFailure,
  getDomainTermsRequest, getDomainTermsSuccess, getDomainTermsFailure, getDomainAttributesRequest, getDomainAttributesSuccess, getDomainAttributesFailure,
  getDomainQualityRequest, getDomainQualitySucess, getDomainQualityFailure, collapseAccordian, clearDomainDetails } = domainsSlice.actions;
export default domainsSlice.reducer;