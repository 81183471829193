const style = (theme) => ({
    configurationContainer: {
        padding: 16,
        width: 260,
        height: 'calc(100% - 42px)'
    },
    chartLits: {
        marginTop: '-15px !important'
    },
    chartItem: {
        height: '40px',
        width: '40px',
        // margin: 10,
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        outline: `1px solid ${theme.palette.secondary.main}40`,
        borderRadius: '4px',
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        },
        '&:hover': {
            outline: `1px solid ${theme.palette.secondary.main}80`,
            '& svg': {
                fill: `${theme.palette.secondary.main}80`
            }
        },
        '&.active': {
            outline: `2px solid ${theme.palette.secondary.main}`,
            '& svg': {
                fill: theme.palette.secondary.main
            }
        }
    },
    dargField: {
        marginTop: '20px !important'
    },
    dragBox: {
        border: `1px dashed ${theme.palette.greyshades.darkgrey}`,
        background: `${theme.palette.greyshades.tablegrey}80`,
        borderRadius: 4,
        padding: '10px 20px'
    }
});

export default style;