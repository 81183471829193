const style = (theme) => ({
    pageContainer: {
        minHeight: 'calc(100vh - 205px)'
    },
    filter: {
        display: 'flex',
        '& .MuiToggleButton-root': {
            padding: '6px 10px',
            textTransform: 'unset',
            fontWeight: 500,
            lineHeight: 1
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            },
            '& svg': {
                fill: '#fff'
            }
        }
    }
});

export default style;