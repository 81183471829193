import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

//  Import Components
import Add from '../add/index.jsx';
import Card from '../card/index.jsx';
import { LoaderComponent, NoResultComponent } from '../../../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Reducer
import { getGlossaryDetailRequest } from '../../../../../../redux/reducer/semanticReducer.js';

function GlossaryCard(props) {
    /**
     * Define Props
     */
    const { classes, search } = props;
    const dispatch = useDispatch();
    const { id: glossaryId, categoryId } = useParams();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { isAddNewGlossary, isLoading, categories } = useSelector((state) => state.semantic, shallowEqual);


    /**
     * Get Detail Data
     */
    useEffect(() => {
        dispatch(getGlossaryDetailRequest(categoryId ? categoryId : glossaryId));
    }, [categoryId, glossaryId]);


    /**
     * Filter Data
     * @param {*} data
     * @param {*} searchText
     * @returns
     */
    const filterCategory = (data, searchText) => {
        let glossaryData = [...data];
        if (searchText.trim()) {
            glossaryData = glossaryData.filter((obj) => obj.name.toLowerCase().includes(searchText.toLowerCase()) || obj.description && obj.description.toLowerCase().includes(searchText.toLowerCase()));
        }
        return glossaryData;
    };

    const filteredCategory = useMemo(() => filterCategory(categories, search), [categories, search]);


    return (
        <Grid container className={classes.domainCardContainer} spacing={3}>
            {
                isAddNewGlossary &&
                <Add classes={classes} />
            }
            {
                filteredCategory && filteredCategory.map((item, index) => (
                    <Card data={item} key={`category_${index}`} index={index} />
                ))
            }
            {!isLoading && filteredCategory.length < 1 && !isAddNewGlossary && <NoResultComponent height="calc(100vh - 375px)" />}
            {
                isLoading &&
                <Grid sx={{ padding: '0px 30px', width: '100%' }}>
                    <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={3} xlCard={2} cardHeight="149px" loaderType="card" height="auto" />
                </Grid>
            }
        </Grid>
    );
}

// default props
GlossaryCard.defaultProps = {
    classes: {},
    search: ""
};

// prop types
GlossaryCard.propTypes = {
    classes: PropTypes.object,
    search: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GlossaryCard);