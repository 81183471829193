import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';

//  Import Styles
import ConversationStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Components
import { RichTextEditorComponent } from '../../../components/index';
import CommentsList from '../../../components/richTextEditor/timeline/index.jsx';


// Import Actions
import {
    conversationCreateRequest, conversationListRequest, conversationUpdateRequest, conversationDeleteRequest,
    getAttributesTagsListRequest
} from '../../../redux/reducer/conversationReducer.js';

// Import Helpers
import { checkPermission } from '../../../helpers/appHelpers.js';

// Import Constants
import featureConstants from '../../../constants/featureConstants.js';


function Conversation(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { conversation_id } = useParams();

    /**
     * Get Data from Reducers
     */
    const { user } = useSelector((state) => state.auth);
    const { detail: assetDetail } = useSelector((state) => state.version);
    const { users_list } = useSelector((state) => state.user, shallowEqual);
    const { conversationList, filter, attributesTagList } = useSelector((state) => state.conversation);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const conversationPermission = checkPermission(permission, featureConstants.assets.conversations);

    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (assetDetail.asset) {
            const requestData = {
                type: 'asset_id',
                id: assetDetail.asset,
                limit: filter.limit,
                offset: 0
            };
            dispatch(conversationListRequest(requestData));
        }
    }, [dispatch, assetDetail.asset]);

    /**
     * Get Asset and Attribute List
     */
    useEffect(() => {
        if (!attributesTagList || attributesTagList.length === 0) {
            const requestData = {
                all: true
            };
            dispatch(getAttributesTagsListRequest(requestData));
        }
    }, [dispatch]);

    /**
     * Use effect for redirection from overview
     */
    useEffect(() => {
        const element = document.getElementById("private");
        const conversationElement = document.getElementById(`${conversation_id}`);
        if (element && conversationElement) {
            element.scrollTop = (conversationElement.offsetTop + conversationElement.offsetHeight) - conversationElement.clientHeight - 150;
        }
    }, []);

    /**
     * Handle Create Message Event
     * @param {*} formData
     */
    const handleCreateMessage = (formData) => {
        if (formData.id) {
            dispatch(conversationUpdateRequest(formData));
        } else {
            formData.created_by = user.id;
            formData.organization = user.organization.id;
            formData.asset = assetDetail.asset;
            formData.attribute = null;
            formData.level = "asset";
            formData.user = { id: user.id ? user.id : '', avatar: user.avatar ? user.avatar : null, name: `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}` };
            dispatch(conversationCreateRequest(formData));
        }
    };

    /**
     * Handle Pin Message
     * @param {*} formData
     */
    const handlePinMessage = (formData) => {
        dispatch(conversationUpdateRequest(formData));
    };

    /**
     * Handle Delete Message
     * @param {*} message_id
     */
    const handleDeleteMessage = (message_id, parent_id) => {
        dispatch(conversationDeleteRequest({ message_id: message_id, parent_id: parent_id }));
    };

    return (
        <Grid container className={classes.conversation}>
            {
                conversationPermission?.is_edit &&
                <Grid className={classes.textEditorWrapper}>
                    <Grid container item xs={12} className={classes.editorContainer}>
                        <Grid className={classes.avatarImg}>
                            <Avatar src={user.avatar || null} />
                        </Grid>
                        <Grid className={classes.editorFields}>
                            <RichTextEditorComponent
                                formSubmit={handleCreateMessage}
                                attributes={attributesTagList}
                                users_list={users_list}
                                formFields={{ title: true }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <CommentsList
                data={conversationList}
                handlePinMessage={handlePinMessage}
                handleDeleteMessage={handleDeleteMessage}
                handleCreateMessage={handleCreateMessage}
                attributes={attributesTagList}
                users_list={users_list}
                permission={conversationPermission}
                formFields={{ showRating: true }}
                actions={['reply', 'pin', 'edit', 'delete']}
            />
        </Grid>
    );
}

// default props
Conversation.defaultProps = {
    classes: {}
};

// prop types
Conversation.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ConversationStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Conversation);