import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//import Componetns
import { Grid, Typography, IconButton, FormHelperText } from '@mui/material';
import { NumberInputComponent, IntegerInputComponent, SelectComponent, SwitchComponent } from '../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Scheduler from '../../components/scheduler/index.jsx';
import Export from './components/export/index.jsx';
import Import from './components/import/index.jsx';
import ApiIntegration from './components/apiIntegration/index.jsx';
import Profile from './components/profile/index.jsx';
import Notifications from './components/notifications/index.jsx';
import Reports from './components/reports/index.jsx';
import LicenseDetail from './components/license/index.jsx';

//import Styles
import GeneralStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images
import { ColorDropIcon, CalendarIcon } from '../../assets/svg/index.js';

//Import Actions
import { generalSettingsRequest, updateGeneralInReducer, generalUpdateRequest, generalScheduleRequest } from '../../redux/reducer/configurationsReducer';
import { checkPermission, quadrantScoreValidation } from '../../helpers/appHelpers.js';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import featureConstants from '../../constants/featureConstants.js';


function General(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [scheduler, setScheduler] = useState({
        type: "",
        open: false,
        anchorElement: null,
        connectionId: "",
        isAsset: false,
        data: null
    });
    const [quadColorErrorIndex, setQuadColorErrorIndex] = useState(-1);

    /**
     * Redux selector function to get General Settings from redux store
     */
    const { isGeneralChanged, general } = useSelector((state) => state.configurations);
    const { permission } = useSelector((state) => state.auth);
    const generalPermission = checkPermission(permission, featureConstants.settings.general);
    const importExportPermission = checkPermission(permission, featureConstants.settings.importExport);
    const semanticsSchedule = general.semantics.schedule ? JSON.parse(JSON.stringify(general.semantics.schedule)) : null;
    const semantic_next_run = general?.semantic_next_run || null;
    const reportingSchedule = general.reporting.schedule ? JSON.parse(JSON.stringify(general.reporting.schedule)) : null;
    const reporting_next_run = general?.reporting_next_run || null;
    const { semantics, storage, reporting, anomaly, scoring, versioning } = general;

    /**
     * Use Ref to store General Settings for unmount dispatch
     */
    const genralSettings = useRef(general, { ...isGeneralChanged });

    /**
     * Dispatch General Settings request
     */
    useEffect(() => {
        dispatch(generalSettingsRequest());
    }, [dispatch]);


    /**
     * Update General Settings in useRef when reducer updates
     */
    useEffect(() => {
        const newDetails = { ...general };
        newDetails.isGeneralChanged = isGeneralChanged;
        genralSettings.current = newDetails;
    }, [general, isGeneralChanged]);

    /**
     * Update Values in Reducer On Change
     * @param {*} category
     * @param {*} property
     * @param {*} value
     */
    const onChange = (category, property, value, subProperty) => {
        if ((category === 'semantics' || category === 'reporting') && property === 'schedule') {
            setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null });
        }
        const updatedConfig = {
            category: category,
            data: {
                [property]: value
            },
            subProperty
        };
        if (property === "max_store_period_type" && value === "months" && reporting?.max_store_period_limit > 24) {
            updatedConfig.data.max_store_period_limit = 24;
        }
        dispatch(updateGeneralInReducer(updatedConfig));
        if (category === 'quadrant') {
            const quad_index = general.scoring.quadrant;
            const new_val = _.cloneDeep(general.scoring.values);
            new_val[quad_index - 2][property][subProperty] = value;
            if (subProperty === 'to') {
                new_val[quad_index - 2][property + 1].from = value;
            }
            let quadError = quadrantScoreValidation(new_val[quad_index - 2], new_val[quad_index - 2][property], property, true);
            if (quadError) {
                setQuadColorErrorIndex(property);
            } else if (quadrantScoreValidation(new_val[quad_index - 2], null, null, true)) {
                quadError = true;
                setQuadColorErrorIndex(-1);
            }

            if (!quadError) {
                const requestParams = { ...general.scoring, values: new_val };
                dispatch(generalUpdateRequest({ id: general.id, scoring: requestParams }));
            }
        } else {
            const requestParams = { ...general[category], [property]: value };
            if (property === "max_store_period_type" && value === "months" && reporting?.max_store_period_limit > 24) {
                requestParams.max_store_period_limit = 24;
            }
            dispatch(generalUpdateRequest({ id: general.id, [category]: requestParams }));
        }
    };

    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = useCallback((event, type) => {
        event.stopPropagation();
        let scheduleData = null;
        switch (type) {
            case "semantics":
                scheduleData = semanticsSchedule ? { ...semanticsSchedule } : null;
                dispatch(generalScheduleRequest());
                break;
            case "reporting":
                scheduleData = reportingSchedule ? { ...reportingSchedule } : null;
                dispatch(generalScheduleRequest());
                break;
            default:
                scheduleData = null;
                break;
        }
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, connectionId: "", data: scheduleData, type });
    }, [semanticsSchedule, reportingSchedule]);

    /**
     * Delete semantic schedule
     */
    const deleteSchedule = (type) => {
        const updatedConfig = {
            category: type,
            data: {
                schedule: {}
            }
        };
        dispatch(updateGeneralInReducer(updatedConfig));
        setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null });
    };

    return (
        <Fragment>
            <ValidatorForm className="w-100" onSubmit={() => { }}>
                <Grid container spacing={6} className={classes.generalPageContainer}>
                    <Grid item xs={12} className="mb-3">
                        <Typography variant="body1">
                            {appConstants.labels.general.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.semantics}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1">
                                {appConstants.labels.general.semanticsdesc}
                            </Typography>
                            <Grid container className="mt-3">
                                <Grid item xs={6} className="dflex alignCenter">
                                    <SwitchComponent size="small"
                                        handleChange={(value) => onChange('semantics', 'is_active', value)}
                                        checked={semantics.is_active || false}
                                        label={appConstants.labels.general.enabled}
                                        disabled={!generalPermission?.is_edit} />
                                </Grid>
                                {
                                    generalPermission?.is_edit &&
                                    <Grid item xs={6} className="dflex alignCenter">
                                        <IconButton disabled={!semantics.is_active} className={classes.CalendarIcon} onClick={(event) => openScheduler(event, "semantics")}>
                                            <CalendarIcon />
                                        </IconButton>
                                        <Typography variant="body1" className={`pl5`}>
                                            Schedule
                                        </Typography>
                                        <Scheduler scheduleProps={scheduler} generalSchedule onClose={() => setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null })} onScheduledData={(property, value) => onChange('semantics', property, value)} savedProps={scheduler?.data} onDelete={() => deleteSchedule("semantics")} isEventSchedule={false} semantic_next_run={semantic_next_run} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.storage}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1" className="mb-2">
                                {appConstants.labels.general.storagedesc}
                            </Typography>
                            <Grid container className="mt-3">
                                <Grid item xs={6} className="dflex alignCenter">
                                    <NumberInputComponent
                                        name={'storage'}
                                        value={storage.days || 0}
                                        onChange={(value) => onChange('storage', 'days', value)}
                                        fullWidth
                                        variant="standard"
                                        size="small"
                                        id="length-min-ntb"
                                        label={appConstants.labels.general.noofdays}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!generalPermission?.is_edit}
                                    />
                                    <Typography variant="body1" sx={{ marginBottom: '-10', paddingLeft: '2px' }}>
                                        days
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className="dflex alignCenter">
                                    <SwitchComponent size="small"
                                        handleChange={(value) => onChange('reporting', 'show_preview', value)}
                                        checked={reporting.show_preview || false}
                                        label={appConstants.labels.general.showPreview}
                                        disabled={!generalPermission?.is_edit} />
                                </Grid>
                                <Grid item xs={3} className={"previewType"}>
                                    <SelectComponent
                                        fullWidth={false}
                                        label={appConstants.labels.general.previewcount}
                                        variant="standard"
                                        value={reporting.count || "20"}
                                        onSelectChange={(value) => onChange('reporting', 'count', value)}
                                        list={["20", "50", "100", "500", "1000", "5000", "10000"]}
                                        name="preview"
                                        isDisabled={!generalPermission?.is_edit || !reporting?.show_preview}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Reports
                        reporting={reporting}
                        generalPermission={generalPermission}
                        onChange={onChange}
                        deleteSchedule={deleteSchedule}
                        reportingSchedule={reportingSchedule}
                        reporting_next_run={reporting_next_run}
                    />
                    <Profile onChange={onChange} />
                    <Grid item xs={6}>
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.versioning}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1">
                                {appConstants.labels.general.versioningDesc}
                            </Typography>
                            <Grid container className="mt-3">
                                <Grid item xs={6}>
                                    <SwitchComponent size="small"
                                        handleChange={(value) => onChange('versioning', 'is_active', value)}
                                        checked={versioning.is_active || false}
                                        label={appConstants.labels.general.enabled}
                                        disabled={!generalPermission?.is_edit} />
                                </Grid>
                                {
                                    versioning.is_active &&
                                    <Grid item xs={6}>
                                        <SelectComponent
                                            fullWidth={false}
                                            label={appConstants.labels.general.versioningNumber}
                                            variant="standard"
                                            value={versioning.versioning_digit || 1}
                                            onSelectChange={(value) => onChange('versioning', 'versioning_digit', value)}
                                            list={appConstants.versioningOptions}
                                            isDisabled={!generalPermission?.is_edit}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.anomaly}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1">
                                {appConstants.labels.general.anomalydesc}
                            </Typography>
                            <Grid container className="mt-2" spacing={2}>
                                <Grid item xs={4} className="anomalyInput">
                                    <NumberInputComponent
                                        name={'anomaly_minimum'}
                                        value={anomaly.minimum || 0}
                                        onChange={(value) => onChange('anomaly', 'minimum', value)}
                                        fullWidth
                                        variant="standard"
                                        size="small"
                                        id="length-min-ntb"
                                        label={appConstants.labels.general.defaultlook}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!generalPermission?.is_edit}
                                        maxValue={parseInt(anomaly.maximum)}
                                        allowNegative={false}
                                    />
                                </Grid>
                                <Grid item xs={4} className="anomalyInput">
                                    <NumberInputComponent
                                        name={'anomaly_maximum'}
                                        value={anomaly.maximum || 0}
                                        onChange={(value) => onChange('anomaly', 'maximum', value)}
                                        fullWidth
                                        variant="standard"
                                        size="small"
                                        id="length-min-ntb"
                                        label={appConstants.labels.general.noofruns}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={!generalPermission?.is_edit}
                                        minValue={parseInt(anomaly.minimum)}
                                        allowNegative={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.scoringsection}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1">
                                {appConstants.labels.general.scoringsectiondesc}
                            </Typography>
                            <Grid container spacing={6} className="mt-3" alignItems="end">
                                <Grid item xs={3} className="pt-0">
                                    <SelectComponent
                                        fullWidth={false}
                                        label={appConstants.labels.general.noofquad}
                                        variant="standard"
                                        value={scoring.quadrant || 0}
                                        onSelectChange={(value) => onChange('scoring', 'quadrant', value)}
                                        displayPropertyName={'name'}
                                        propertyName={'value'}
                                        list={appConstants.general.quadrant}
                                        name="quadrant"
                                        isDisabled={!generalPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={9} className="pt-0">
                                    <Grid container spacing={2}>
                                        {
                                            scoring.values[scoring.quadrant - 2]?.map((section, index) =>
                                                <Grid item sx={{ flex: 1, maxWidth: 350 }} key={index}>
                                                    <Grid item className={classes.scoringValuePicker}>
                                                        <Grid item className={classes.rangeFields}>
                                                            <Grid container alignItems="center" justifyContent="center" className={`aboveTxt`}>
                                                                <Typography variant="body1" className={`catalog-subTitle ${classes.textSecondary}`}>
                                                                    <span className="fw-500 pr5">
                                                                        {`${index > 0 ? 'Above ' : ''} ${section.from}`}
                                                                    </span>
                                                                </Typography>
                                                                {/* <Grid item>
                                                                    <IntegerInputComponent
                                                                        id="length-min-ntb"
                                                                        name={'from'}
                                                                        value={section.from || 0}
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onChange={(event) => onChange('quadrant', index, parseInt(event.target.value), "from")}
                                                                        disabled
                                                                    />
                                                                </Grid> */}
                                                            </Grid>
                                                            <span className="rangeHypen">
                                                                -
                                                            </span>
                                                            <IntegerInputComponent
                                                                id="length-max-ntb"
                                                                name={'to'}
                                                                value={section.to || 0}
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(event) => onChange('quadrant', index, parseInt(event.target.value), "to")}
                                                                disabled={!generalPermission?.is_edit || (index === scoring.values[scoring.quadrant - 2]?.length - 1)}
                                                            />
                                                        </Grid>
                                                        <Grid className={classes.colorPicker}>
                                                            <ColorDropIcon
                                                                dropColor={section.color}
                                                            />
                                                            {
                                                                generalPermission?.is_edit &&
                                                                <input
                                                                    className="typeColor"
                                                                    type="color"
                                                                    name="color"
                                                                    value={section.color ? section.color : '#000'}
                                                                    onChange={(event) => onChange('quadrant', index, event.target.value, "color")}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        index === quadColorErrorIndex && quadrantScoreValidation(scoring.values[scoring.quadrant - 2], section, index, true) &&
                                                        <FormHelperText error style={{ fontSize: 12, paddingTop: 5 }}>
                                                            {quadrantScoreValidation(scoring.values[scoring.quadrant - 2], section, index)}
                                                        </FormHelperText>
                                                    }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    {
                                        quadColorErrorIndex === -1 && quadrantScoreValidation(scoring.values[scoring.quadrant - 2], null, null, true) &&
                                        <FormHelperText error style={{ fontSize: 12, paddingTop: 5 }}>
                                            {quadrantScoreValidation(scoring.values[scoring.quadrant - 2], null, null)}
                                        </FormHelperText>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h5" className="">
                                {appConstants.labels.general.system}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Typography variant="body1">
                                {appConstants.labels.general.systemdesc}
                            </Typography>
                            <Grid container spacing={6} className="mt-3">
                                <Grid item xs={3} className="pt-0">
                                    <TextBoxComponent
                                        fullWidth={false}
                                        label={appConstants.labels.general.airflowservice}
                                        variant="standard"
                                        name="airflowService"
                                        onChange={(event) => onChange('system', event.target.name, event.target.value)}
                                        value={system.airflowService || ""}
                                        disabled={!generalPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={3} className="pt-0">
                                    <TextBoxComponent
                                        fullWidth={false}
                                        label={appConstants.labels.general.clientdb}
                                        variant="standard"
                                        name="clientDb"
                                        onChange={(event) => onChange('system', event.target.name, event.target.value)}
                                        value={system.clientDb || ""}
                                        disabled={!generalPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={3} className="pt-0">
                                    <TextBoxComponent
                                        fullWidth={false}
                                        label={appConstants.labels.general.othersystem}
                                        variant="standard"
                                        name="otherSystem"
                                        onChange={(event) => onChange('system', event.target.name, event.target.value)}
                                        value={system.otherSystem || ""}
                                        disabled={!generalPermission?.is_edit}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {<Notifications onChange={onChange} />}
                    {
                        importExportPermission?.is_edit &&
                        <Import />
                    }
                    {
                        (importExportPermission?.is_view || importExportPermission?.is_edit) &&
                        <Export />
                    }
                    <ApiIntegration />
                </Grid>
            </ValidatorForm>
            <Grid container spacing={6} className={classes.generalPageContainer}>
                <Grid item xs={12}>
                    <LicenseDetail />
                </Grid>
            </Grid>
        </Fragment>
    );
}

// default props
General.defaultProps = {
    classes: {}
};

// prop types
General.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(General);