const style = () => ({
    cardImg: {
        fontSize: '34px !important',
        marginBottom: '8px',
        marginTop: '-18px'
    },
    connectorCardImg: {
        height: 54,
        maxWidth: '100%',
        objectFit: 'contain',
        '& .restapi': {
            maxWidth: '80%'
        }
    }
});

export default style;