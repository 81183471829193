
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrag } from 'react-dnd';

//  Import Components
import { Grid, Typography, Tooltip } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';


function DragField(props) {
    const { data, type, classes } = props;

    const name = data.name;
    const Box = () => {
        const [, drag] = useDrag(
            () => ({
                type,
                item: { name },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging()
                })
            }),
            [name, type]
        );
        return (
            <Grid className={classes.text} disablePadding ref={drag} data-testid={type}>
                <Tooltip title={data.name}>
                    <Typography className={classes.text} variant="body1" noWrap>
                        {data.name}
                    </Typography>
                </Tooltip>
            </Grid>
        );
    };

    return (
        Box()
    );
}

// default props
DragField.defaultProps = {
    classes: {},
    data: {}
};

// prop types
DragField.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DragField);