import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import {
    getOrganizationDetailRequest, getOrganizationDetailSuccess, getOrganizationDetailFailure, generateTokenRequest,
    generateTokenSuccess, generateTokenFailure, getTokenRequest, getTokenSuccess, getTokenFailure, updateOrganizationRequest,
    updateOrganizationSuccess, updateOrganizationFailure
} from '../reducer/organizationReducer';
import { displyAlert } from "../reducer/alertReducer";
import { organizationService } from '../service';
import appConstants from '../../constants/appConstants';


/**
 * Detail Actions
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getOrganizationDetailRequest),
    mergeMap(({ payload }) => {
        return from(organizationService.detail(payload)).pipe(
            concatMap((res) => of(getOrganizationDetailSuccess(res))),
            catchError((error) => of(getOrganizationDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Generate Token/ Revoke Token
 * @param {*} action$
 * @returns
 */
const generateToken = (action$) => action$.pipe(
    ofType(generateTokenRequest),
    mergeMap(({ payload }) => {
        return from(organizationService.generateToken(payload)).pipe(
            concatMap((res) => of(generateTokenSuccess(res))),
            catchError((error) => of(generateTokenFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Generate Token/ Revoke Token
 * @param {*} action$
 * @returns
 */
const getToken = (action$) => action$.pipe(
    ofType(getTokenRequest),
    mergeMap(() => {
        return from(organizationService.getToken()).pipe(
            concatMap((res) => of(getTokenSuccess(res))),
            catchError((error) => of(getTokenFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Action
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateOrganizationRequest),
    mergeMap(({ payload }) => {
        return from(organizationService.update(payload)).pipe(
            concatMap((res) => of(updateOrganizationSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateOrganizationFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

// Export All Epic Functions
export const organizationEpic = [detail, generateToken, getToken, update];