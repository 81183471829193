import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isLoading: false,
    glossary: [],
    searchableGlossaries: [],
    searchableGlossariesFilter: [],
    isAddNewGlossary: false,
    statistics: {},
    categories: [],
    selectedCategory: {},
    selectedGlossary: {},
    type: "glossary",
    tree: []
};

const semanticSlice = createSlice({
    name: 'semantic',
    initialState: { ...initialState },
    reducers: {
        getGlossaryRequest(state) {
            state.isLoading = true;
            state.glossary = [];
            state.isAddNewGlossary = false;
        },
        getGlossarySuccess(state, action) {
            state.isLoading = false;
            state.glossary = action.payload.data || [];
        },
        getGlossaryFailure(state) {
            state.isLoading = false;
        },
        createGlossaryRequest() { },
        createGlossarySuccess(state, action) {
            state.isAddNewGlossary = false;
            const glossaries = [...state.glossary];
            glossaries.push({ ...action.payload.data });
            state.glossary = [...glossaries];
            state.statistics = {
                ...state.statistics,
                glossary: glossaries.length
            };
            state.searchableGlossaries.push({
                name: action.payload.data.technical_name,
                id: action.payload.data.id,
                count: 0,
                measure_count: 0
            });
            state.searchableGlossaries = state.searchableGlossaries.sort((a, b) => a.name.localeCompare(b.name));
        },
        createGlossaryFailure() { },
        updateGlossaryRequest() { },
        updateGlossarySuccess(state, action) {
            const glossary = action.payload.data || {};
            const index = state.glossary.findIndex((data) => data.id === glossary.id);
            if (index > -1) {
                state.glossary[index] = {
                    ...state.glossary[index],
                    ...glossary
                };
            }
        },
        updateGlossaryFailure() { },
        deleteGlossaryRequest() { },
        deleteGlossarySuccess(state, action) {
            const index = state.glossary.findIndex((glossary) => glossary.id === action.payload);
            state.glossary.splice(index, 1);
            state.statistics = {
                ...state.statistics,
                glossary: state.glossary.length
            };
        },
        deleteGlossaryFailure() { },
        toggleAddGlossary(state, action) {
            state.isAddNewGlossary = !state.isAddNewGlossary;
            state.type = action.payload || "category";
        },
        updateGlossaryImageRequest() { },
        updateGlossaryImageSuccess(state, action) {
            const { id, image } = action.payload.data;
            const index = state.glossary.findIndex((glossary) => glossary.id === id);
            if (index !== -1) {
                state.glossary[index].image = image;
            } else {
                const index = state.categories.findIndex((glossary) => glossary.id === id);
                state.categories[index].image = image;
            }
        },
        updateGlossaryImageFailure() { },
        getStatisticsRequest() { },
        getStatisticsSuccess(state, action) {
            state.statistics = action.payload.data || {};
        },
        getStatisticsFailure() { },
        getGlossaryDetailRequest(state) {
            state.categories = [];
            state.isAddNewGlossary = false;
            state.isLoading = true;
        },
        getGlossaryDetailSuccess(state, action) {
            state.categories = action.payload.data || [];
            state.categories = state.categories.sort((a, b) => {
                const x = a.type.toLowerCase();
                const y = b.type.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            });
            state.selectedCategory = {};
            state.isLoading = false;
        },
        getGlossaryDetailFailure(state) {
            state.categories = [];
            state.isLoading = false;
        },
        createCategoryRequest() { },
        createCategorySuccess(state, action) {
            state.isAddNewGlossary = false;
            const categories = [...state.categories];
            if (action.payload.data.isAdd) {
                categories.push({
                    ...action.payload.data,
                    glossary_id: action.payload.data?.glossary,
                    type: 'category'
                });
                state.categories = [...categories];
            }
            state.statistics = {
                ...state.statistics,
                category: state.statistics.category + 1
            };
            state.searchableGlossaries.push({
                name: action.payload.data.technical_name,
                id: action.payload.data.id,
                count: 0,
                measure_count: 0
            });
            state.searchableGlossaries = state.searchableGlossaries.sort((a, b) => a.name.localeCompare(b.name));
        },
        createCategoryFailure() { },
        updateCategoryRequest() { },
        updateCategorySuccess(state, action) {
            const category = action.payload.data || {};
            const index = state.categories.findIndex((data) => data.id === category.id);
            if (index > -1) {
                state.categories[index] = {
                    ...state.categories[index],
                    ...category
                };
            }
        },
        updateCategoryFailure() { },
        deleteCategoryRequest() { },
        deleteCategorySuccess(state, action) {
            const index = state.categories.findIndex((category) => category.id === action.payload);
            if (index > -1) {
                state.categories.splice(index, 1);
            }
            state.statistics = {
                ...state.statistics,
                category: state.statistics.category - 1
            };
        },
        deleteCategoryFailure() { },
        createTermRequest() { },
        createTermSuccess(state, action) {
            state.isAddNewGlossary = false;
            const categories = [...state.categories];
            if (action.payload.data.isAdd) {
                categories.push({ ...action.payload.data, type: "term" });
            }
            state.categories = [...categories];
            state.selectedCategory = {};
            state.statistics = {
                ...state.statistics,
                term: state.statistics.term + 1
            };
        },
        createTermFailure() { },
        updateTermRequest() { },
        updateTermSuccess(state, action) {
            const category = action.payload.data || {};
            const index = state.categories.findIndex((data) => data.id === category.id);
            if (index > -1) {
                state.categories[index] = {
                    ...state.categories[index],
                    ...category
                };
            }
        },
        updateTermFailure() { },
        deleteTermRequest() { },
        deleteTermSuccess(state, action) {
            const index = state.categories.findIndex((category) => category.id === action.payload);
            state.categories.splice(index, 1);
            state.statistics = {
                ...state.statistics,
                term: state.statistics.term - 1
            };
        },
        deleteTermFailure() { },
        selectCategory(state, action) {
            state.selectedCategory = action.payload || {};
        },
        resetSelectCategory(state) {
            state.selectedCategory = {};
        },
        getCategoryDetailRequest(state) {
            state.selectedCategory = {};
        },
        getTreeRequest() { },
        getTreeSuccess(state, action) {
            state.tree = action.payload.data || [];
        },
        getTreeFailure() { },
        selectType(state, action) {
            state.type = action.payload || "category";
        },
        getDomainListRequest() { },
        getDomainListSuccess(state, action) {
            state.searchableGlossaries = action.payload.data || [];
            state.searchableGlossaries = state.searchableGlossaries.sort((a, b) => a.name.localeCompare(b.name));
        },
        getDomainListFailure() { },
        getDomainListFilterRequest() { },
        getDomainListFilterSuccess(state, action) {
            state.searchableGlossariesFilter = action.payload.data || [];
            state.searchableGlossariesFilter = state.searchableGlossariesFilter.sort((a, b) => a.name.localeCompare(b.name));
        },
        getDomainListFilterFailure() { }
    }
});

export const {
    getGlossaryRequest, getGlossarySuccess, getGlossaryFailure, createGlossaryRequest, createGlossarySuccess, createGlossaryFailure, updateGlossaryRequest,
    updateGlossarySuccess, updateGlossaryFailure, deleteGlossaryRequest, deleteGlossarySuccess, deleteGlossaryFailure, toggleAddGlossary, updateGlossaryImageRequest,
    updateGlossaryImageSuccess, updateGlossaryImageFailure, getStatisticsRequest, getStatisticsSuccess, getStatisticsFailure, getGlossaryDetailRequest, getGlossaryDetailSuccess,
    getGlossaryDetailFailure, createCategoryRequest, createCategorySuccess, createCategoryFailure, updateCategoryRequest, updateCategorySuccess, updateCategoryFailure,
    deleteCategoryRequest, deleteCategorySuccess, deleteCategoryFailure, createTermRequest, createTermSuccess, createTermFailure, selectCategory, deleteTermRequest, deleteTermSuccess,
    deleteTermFailure, updateTermRequest, updateTermSuccess, updateTermFailure, getCategoryDetailRequest, getTreeRequest, getTreeSuccess, getTreeFailure, selectType, resetSelectCategory,
    getDomainListRequest, getDomainListSuccess, getDomainListFailure, getDomainListFilterRequest, getDomainListFilterSuccess, getDomainListFilterFailure
} = semanticSlice.actions;
export default semanticSlice.reducer;