import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import Sidebar from './components/sidebar/index.jsx';
import Header from './components/header/index.jsx';
import GlossaryCard from './domain/index.jsx';

//  Import Styles
import GlossaryStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

import { toggleAddGlossary, getStatisticsRequest } from '../../../redux/reducer/semanticReducer';
import featureConstants from '../../../constants/featureConstants.js';
import { checkPermission } from '../../../helpers/appHelpers.js';


function Glossary(props) {
    const { classes } = props;
    const dispatch = useDispatch();
    const [collapseOpen, setCollapseOpen] = useState(true);
    const [searchText, setSearchText] = useState("");
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);

    /**
     * Add New Glossary
     */
    const addNewGlossary = () => {
        dispatch(toggleAddGlossary());
    };

    /**
     * Handle Collapse Event
     */
    const onCollapseChange = () => {
        setCollapseOpen((collapseOpen) => !collapseOpen);
    };

    /**
     * Get Statistics
     */
    useEffect(() => {
        const requestParams = {
            type: "all"
        };
        dispatch(getStatisticsRequest(requestParams));
    });

    return (
        <Grid container className={classes.glossaryContainer}>
            <Grid container spacing={3} className={`${classes.glossaryListContainer} pt-2`}>
                <Grid item sx={{ flex: '0 0 auto', width: '300px' }}>
                    <Sidebar
                        collapseChange={onCollapseChange}
                        collapseState={!collapseOpen}
                        semanticsPermission={semanticsPermission}
                    />
                </Grid>
                <Grid item className={`${classes.glossaryList} ${collapseOpen && "sideBarClose"}`}>
                    <Header
                        headerTitle="Domains"
                        actitonType="add"
                        type="glossary"
                        searchText={searchText}
                        onSearchChange={(value) => setSearchText(value)}
                        addClick={() => addNewGlossary()}
                        semanticsPermission={semanticsPermission}
                        publishPermission={publishPermission}
                    />
                    <GlossaryCard search={searchText} semanticsPermission={semanticsPermission} />
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Glossary.defaultProps = {
    classes: {}
};

// prop types
Glossary.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GlossaryStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Glossary);