import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// Import Styles
import DatasetPropertiesStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Component
import Header from './header/index.jsx';
import AttributeInfo from './attributeInfo/index.jsx';
import Measures from './measure/index.jsx';
// import AssetLevelChart from './assetLevelChart/index.jsx';

// Import Actions
import { getAttributesDetailRequest, selectAttribute, updateAttributeMetadata, updateAttributeMetadataRequest } from '../../../redux/reducer/attributeReducer';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import { checkPermission } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';
import { getActivePatternsRequest } from '../../../redux/reducer/baseMeasureReducer.js';
import LabelContainer from '../../../components/labelContainer/index.jsx';
import { MemoSimpleTabHeaderComponent } from '../../../components/index.js';
import AttributeProfileInfo from './attributeProfileInfo/index.jsx';
import moment from 'moment';
import AttributesList from './attributesList/index.jsx';


function DatasetProperties(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const measureContainerRef = useRef();
    const requestAbourControllerRef = useRef();
    const { id: asset_id, attribute_id } = useParams();

    /**
     * Define state variables
     */
    const [profileTabIndex, setProfileTabIndex] = useState(0);
    const [selectedMeasureView, setSelectedMeasureView] = useState(location?.state?.measureView || null);

    /**
     * Get Attribute id from Attribute Reducer store
     */
    const { detail, list: attributes } = useSelector((state) => state.attribute);
    const { detail: version_detail } = useSelector((state) => state.version);
    const { permission } = useSelector((state) => state.auth);
    const propertiesPermission = checkPermission(permission, featureConstants.assets.properties);

    /**
     * Scroll to Measure
     */
    useEffect(() => {
        if (location?.state?.measureView) {
            setSelectedMeasureView(location?.state?.measureView || null);
        }
        if (location?.state?.section === "measure") {
            const timer = setTimeout(() => {
                measureContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [location]);

    /**
     * Get Attribute Metadata Details
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        dispatch(selectAttribute(attribute_id || "All"));

        if (requestAbourControllerRef && requestAbourControllerRef.current) {
            requestAbourControllerRef.current.abort();
        }

        requestAbourControllerRef.current = new AbortController();
        const token = { signal: requestAbourControllerRef?.current?.signal };
        if (attribute_id && attribute_id !== 'All') {
            dispatch(getAttributesDetailRequest({ attribute_id, token }));
        }
        dispatch(getActivePatternsRequest());
    }, [attribute_id]);

    // Properties View Type
    const isAssetLevel = attribute_id === "All" || attribute_id === "" || !attribute_id;

    /**
     * Get Attribute Metadata
     * @param {*} type
     * @returns
     */
    const getAttributeMetadata = (type = "constraint") => {
        switch (type) {
            case appConstants.propertiesComponents.constraint:
                return {
                    is_null: detail?.is_null ?? false,
                    is_blank: detail?.is_blank ?? false,
                    is_unique: detail?.is_unique ?? false,
                    is_primary_key: detail?.is_primary_key ?? false
                };
            case appConstants.propertiesComponents.lengthRange:
                let length_frequency = detail?.length_frequency ?? [];
                length_frequency = typeof (length_frequency) === "string" ? JSON.parse(length_frequency) : length_frequency;
                length_frequency = length_frequency.length ? length_frequency : [];
                return {
                    max_length: detail?.max_length ?? null,
                    min_length: detail?.min_length ?? null,
                    min_value: detail?.min_value ?? null,
                    max_value: detail?.max_value ?? null,
                    length_frequency: length_frequency,
                    datatype: detail?.derived_type
                };
            case appConstants.propertiesComponents.pattern:
                let pattern = detail?.patterns ?? [];
                pattern = typeof (pattern) === "string" ? JSON.parse(pattern) : pattern;
                pattern = pattern.length > 0 ? JSON.parse(JSON.stringify(pattern)) : [];
                return pattern;
            case appConstants.propertiesComponents.enum:
                let enumList = detail?.enums ?? [];
                enumList = typeof (enumList) === "string" ? JSON.parse(enumList) : enumList;
                return enumList.length ? enumList : [];
            case appConstants.propertiesComponents.general:
                return {
                    datatype: detail?.datatype?.length > 0 ? detail.datatype : "",
                    dqscore: detail?.dqscore ?? null,
                    alerts: detail?.alerts ?? null,
                    status: detail?.status ?? "Pending",
                    totalRows: detail?.total_rows ?? 0,
                    validRows: detail?.valid_rows ?? 0,
                    invalidRows: detail?.invalid_rows ?? 0,
                    activeMeasures: detail?.active_measures ?? 0,
                    observedMeasures: detail?.observed_measures ?? 0,
                    scoredMeasures: detail?.scored_measures ?? 0,
                    measures: detail?.measures ?? 0
                };
            case appConstants.propertiesComponents.section:
                return {
                    description: detail?.description ?? "",
                    term: detail?.term ?? '',
                    tags: detail?.tags ?? []
                };
            default:
                return {};
        }
    };

    /**
     * Get profile section tabs for the given attribute
     */
    const getProfileTabs = (metadata, selectedTabIndex) => {
        const profileTabs = JSON.parse(JSON.stringify(appConstants.tabs.asset.profileTabs));
        const derivedType = metadata?.derived_type?.toLowerCase() ?? "text";
        const index = appConstants.datatypes.numeric.indexOf(derivedType);
        if (index < 0) {
            const statIndex = appConstants.tabs.asset.profileTabs.findIndex((item) => item === "Statistics");
            if (statIndex > -1) {
                profileTabs.splice(statIndex, 1);
            }
            if (selectedTabIndex === 3) {
                setProfileTabIndex(0);
            }
        }
        return profileTabs;
    };

    /**
     * onToggle advanced profiling
     * @param {*} chartType
     * @param {*} key
     * @param {*} value
     */
    const onToggleAdvancedProfile = (key, value) => {
        const requestParams = {
            [key]: value,
            attribute_id
        };
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
    };


    /**
     * Handle on click filter change event
     * @param {*} type
     */
    const onClickFilterChange = (type) => {
        setSelectedMeasureView(type);
        if (measureContainerRef?.current) {
            measureContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
        }
    };


    return (
        <Grid container className={classes.propertiesContainer} spacing={6}>
            <Grid item xs={12} sx={{ paddingTop: '48px !important' }}>
                <ValidatorForm noValidate onSubmit={() => { }}>
                    {
                        isAssetLevel
                            ? <Header data={version_detail} onClickFilter={(type) => onClickFilterChange(type)} />
                            : <Header data={getAttributeMetadata(appConstants.propertiesComponents.general)} onClickFilter={(type) => onClickFilterChange(type)} />
                    }
                </ValidatorForm>
            </Grid>

            {
                !isAssetLevel &&
                <React.Fragment>
                    <Grid item xs={12} className={"pt-36"}>
                        <LabelContainer label={"Info"}>
                            <Grid item xs={12}>
                                <ValidatorForm noValidate onSubmit={() => { }}>
                                    <AttributeInfo data={getAttributeMetadata(appConstants.propertiesComponents.section)} propertiesPermission={propertiesPermission} />
                                </ValidatorForm>
                            </Grid>
                        </LabelContainer>
                    </Grid>
                    <Grid item xs={12} className={"pt-36"}>
                        <LabelContainer label={"Profile"}
                            showProfileText
                            isAdvanced={detail?.is_advanced_profiling || false}
                            onProfileViewChange={() => onToggleAdvancedProfile("is_advanced_profiling", !detail?.is_advanced_profiling)}
                            profileRunDate={moment(detail?.last_profile_run_date || null)}
                        >
                            <Grid item xs={12}>
                                <Grid container wrap="nowrap" justifyContent={"space-between"} alignItems="center" className={classes.tabHeaderContainer}>
                                    <Grid item className={classes.profileDescription}>
                                        <Typography variant="body1" className={classes.textSecondary}>
                                            {appConstants.profile.description}
                                        </Typography>
                                    </Grid>
                                    {
                                        detail?.active_advanced_measures > 0 &&
                                        <MemoSimpleTabHeaderComponent
                                            tabList={getProfileTabs(detail, profileTabIndex)}
                                            className={classes.tabsHeader}
                                            tabStyle={'blue'}
                                            tabIndex={profileTabIndex}
                                            onTabChange={(newValue) => profileTabIndex !== newValue && setProfileTabIndex(newValue)}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <AttributeProfileInfo
                                    tabIndex={profileTabIndex || 0}
                                    attributes={attributes}
                                    asset_id={asset_id}
                                    attribute_id={attribute_id}
                                />
                            </Grid>
                        </LabelContainer>
                    </Grid>
                </React.Fragment>
            }
            {/* {isAssetLevel && <AssetLevelChart asset_id={asset_id} />} */}
            {isAssetLevel && <AttributesList propertiesPermission={propertiesPermission} />}

            <Grid item xs={12} ref={measureContainerRef} className={`${isAssetLevel && "pt-24"}`}>
                <LabelContainer label={"Measures"}>
                    <Measures
                        attributes={attributes}
                        attribute_id={attribute_id}
                        asset_id={asset_id}
                        isAssetLevel={isAssetLevel}
                        selectedView={selectedMeasureView || null}
                        disableAdd={(isAssetLevel && version_detail?.status === appConstants.status[3]) || (!isAssetLevel && detail?.status === appConstants.status[3])}
                        version_detail={version_detail}
                    />
                </LabelContainer>
            </Grid>
        </Grid>
    );
}

// default props
DatasetProperties.defaultProps = {
    classes: {}
};

// prop types
DatasetProperties.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DatasetPropertiesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DatasetProperties);