import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import Kanbanview from './components/kanbanview/index.jsx';
import { LoaderComponent } from '../../../../components/index.js';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { getDomainAttributesRequest } from '../../../../redux/reducer/domainsReducer';
import { updateAttributeTermRequest } from '../../../../redux/reducer/attributeReducer';

function DomainTerms(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: domain_id } = useParams();

    const [termAttributes, setTermAttributes] = useState([]);
    const [termItems, setTermItems] = useState([]);

    /**
     * Redux Store
     */
    const { attributes, attributeLoading, termList } = useSelector((state) => state.domains);

    /**
     * Get Domain Attributes
     */
    useEffect(() => {
        if (domain_id) {
            setTermAttributes([]);
            setTermItems([]);
            dispatch(getDomainAttributesRequest(domain_id));
        }
    }, [domain_id]);

    /**
     * Set Attributes to local scope
     */
    useEffect(() => {
        if (attributes.length && !termAttributes.length) {
            setTermAttributes([...attributes]);
        }
    }, [attributes, termAttributes]);

    useEffect(() => {
        if (!termItems.length && attributes.length) {
            let termData = [...termList];
            termData = termData.map((term) => {
                const termAttributes = attributes.filter((attribute) => attribute.term_id === term.id);
                return {
                    count: termAttributes.length || 0,
                    ...term
                };
            });
            termData = termData.sort((a, b) => b.count - a.count);
            const unCategoryAttribute = attributes.filter((attribute) => !attribute.term_id);
            const unCategoryTerm = { id: null, name: "Others", technical_name: "", count: unCategoryAttribute.length };
            termData.unshift(unCategoryTerm);
            setTermItems([...termData]);
        }
    }, [termItems, attributes]);


    /**
     * Change Term Status
     * @param {*} data
     * @param {*} term
     */
    const onChange = (data, term) => {
        const attributes = Object.assign([], [...termAttributes]);
        const index = attributes.findIndex((obj) => obj.id === data.id);
        const attribute = Object.assign({}, attributes[index]);
        attribute.term_id = term.id;
        attribute.term_name = term.technical_name;
        attributes[index] = { ...attribute };
        setTermAttributes([...attributes]);
        dispatch(updateAttributeTermRequest({ term: term.id || "", attribute_id: data.attribute_id }));
    };


    /**
     * Prepare TermList
     * @param {*} attributes
     * @param {*} terms
     * @returns
     */
    const prepareTerms = (attributes, terms) => {
        let termList = [...terms];
        termList = termList.map((term) => {
            const termAttributes = attributes.filter((attribute) => attribute.term_id === term.id);
            const score = (termAttributes.reduce((a, b) => a + b.score, 0)) / termAttributes.length;
            return {
                score: score ? score?.toFixed(2) : 0,
                ...term
            };
        });
        return termList;
    };
    const terms = useMemo(() => prepareTerms(termAttributes, termItems), [termAttributes, termItems]);

    return (
        <Grid container className={classes.tabsRootContainer}>
            <Grid item xs={12}>
                <Grid container className={classes.kanbanViewRoot}>
                    {
                        attributeLoading ?
                            <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={3} cardHeight="175px" loaderType="card" height="auto" />
                            :
                            <Kanbanview terms={terms} attributes={termAttributes} onChange={onChange} />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
DomainTerms.defaultProps = {
    classes: {}
};

// prop types
DomainTerms.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DomainTerms);