import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { navigate } from '../../redux/reducer/navigationReducer';

//  Import Styles
import SemanticAppStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Constants
import appConstants from '../../constants/appConstants.js';
import { TableComponent } from '../../components/index.js';

// Import Component

import { ValidatorForm } from 'react-material-ui-form-validator';

// Actions
import { getScheduleListRequest } from '../../redux/reducer/scheduleReducer.js';

// Import Helpers
import { checkPermission, getUserPreference, prepareUpdateUserPreference } from '../../helpers/appHelpers.js';
import featureConstants from '../../constants/featureConstants.js';
import { updateUserPreference } from '../../redux/reducer/authReducer.js';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer.js';
import { useOutletContext } from 'react-router-dom';
import Scheduler from '../../components/scheduler/index.jsx';

function ScheduleList(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();


    /**
     * Define Ref Context
     */
    const scrollRef = useOutletContext();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        name: '',
        connection_name: '',
        connection_type: '',
        level: ''
    });

    const [scheduler, setScheduler] = useState({
        open: false,
        anchorElement: null,
        connectionId: "",
        asset: {},
        isAsset: false,
        isMeasure: false,
        isReport: false
    });

    const sortByType = "name";
    const dispatchType = getScheduleListRequest;
    const tableHeader = appConstants.table.ScheduleListTabHeader;

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { schedule_list, search: searchFilter, loadMore, listLoading } = useSelector((state) => state.schedule, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const measurePermission = checkPermission(permission, featureConstants.home.measures);
    const reportsPermission = checkPermission(permission, featureConstants.home.reports);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "schedule", "columns");


    /**
     * Define Use Effects
     */
    useEffect(() => {
        const requestData = {
            ...searchFilter,
            sortBy: sortByType,
            search_by: searchData,
            offset: 0
        };
        dispatch(dispatchType({ params: requestData, clear: true }));
    }, [dispatch, searchData]);


    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreList = () => {
        if (loadMore && !listLoading) {
            const requestData = {
                ...searchFilter,
                sortBy: sortByType,
                search_by: searchData,
                offset: (searchFilter.offset + searchFilter.limit)
            };
            dispatch(dispatchType({ params: requestData, clear: false }));
        }
    };

    /**
     * Handle Lazy Load
     */
    useEffect(() => {
        if (scrollRef) {
            scrollRef.addEventListener("scroll", loadMoreList);
            return () => scrollRef.removeEventListener("scroll", loadMoreList);
        }
    }, [scrollRef, loadMoreList]);


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };


    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "schedule", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };


    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Open Schedule Modal
     * @param {*} data
     */
    const openScheduleModal = (event, selectedItem) => {
        event.stopPropagation();
        if (selectedItem?.level === 'measure') {
            setScheduler({
                open: true,
                anchorElement: event.target,
                connectionId: selectedItem.connection_id,
                isMeasure: true,
                measureId: selectedItem?.measure_id
            });
        } else if (selectedItem?.level === 'report') {
            setScheduler({
                open: true,
                anchorElement: event.target,
                connectionId: selectedItem.connection_id,
                isReport: true,
                reportId: selectedItem?.report_id
            });
        } else if (selectedItem?.level === 'metadata') {
            setScheduler({
                open: true,
                anchorElement: event.target,
                level: selectedItem.level
            });
        } else if (selectedItem?.level === 'sync') {
            setScheduler({
                open: true,
                anchorElement: event.target,
                level: selectedItem.level,
                connectionId: selectedItem.connection_id
            });
        } else {
            setScheduler({
                open: true,
                anchorElement: event.target,
                connectionId: selectedItem.connection_id,
                asset: { ...selectedItem, is_connected: true, id: selectedItem.asset_id, schedule: JSON.parse(selectedItem.schedule) },
                isAsset: true
            });
        }
    };

    /**
     * Handle Action Event
     * @param {*} selectedItem
     * @param {*} actionName
     */
    const onClickActions = (selectedItem, actionName, event) => {
        openScheduleModal(event, selectedItem);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            ...searchFilter,
            search_by: searchData,
            sortBy, orderBy, offset: 0
        };
        dispatch(dispatchType({ params: requestData, clear: true }));
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * On Cell Click Event
     * @param {*} key
     * @param {*} data
     */
    const onCellClick = (key, data) => {
        switch (key) {
            case "name":
                if ((data.level === 'connection' || data.level === 'asset') && data.asset_id) {
                    dispatch(navigate({ path: 'assets.root', state: {}, params: [data.asset_id] }));
                } else if (data.level === 'measure' && data.measure_id) {
                    const state = { asset_id: data.asset_id, attribute_id: data.attribute_id, isMeasureLevel: !data.asset_id, prevUrl: true };
                    dispatch(navigate({ path: 'measure.detail', state: state, params: [data.measure_id] }));
                } else if (data.level === 'report' && data.report_id) {
                    dispatch(navigate({ path: 'reports.root', state: {}, params: [data.report_id] }));
                }
                break;
            case "connection_name":
                if (data.connection_id) {
                    dispatch(navigate({ path: 'connector.asset', state: {}, params: [data.connection_type, data.connection_id] }));
                }
                break;
            default:
                break;
        }
    };

    /**
     * Check Schedule Permission
     * @param {*} schedulerProps
     * @returns
     */
    const checkSchedulePermission = (schedulerProps) => {
        if (schedulerProps.isAsset && !overviewPermission?.is_edit) {
            return true;
        } else if (schedulerProps.isMeasure && !measurePermission?.is_edit) {
            return true;
        } else if (schedulerProps.isReport && !reportsPermission?.is_edit) {
            return true;
        }
        return false;
    };

    return (
        <Grid container className={classes.appContainer} justifyContent="space-between">
            <Grid item xs={12}>
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        styleType="striped"
                        headers={tableHeader}
                        data={schedule_list || []}
                        searchData={searchData}
                        onHandleSearchEvent={onHandleSearchEvent}
                        options={appConstants.tableOptions.common}
                        title={appConstants.labels.securityTab.scheduleList}
                        description={appConstants.labels.securityTab.scheduleListDesc}
                        NoResultText="No Schedules Found"
                        isLoading={listLoading}
                        height="calc(100vh - 300px)"
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        onClickActions={onClickActions}
                        onClickSorting={onClickSorting}
                        onCellClick={onCellClick}
                        sortBy={searchFilter?.sortBy ?? ''}
                        orderBy={searchFilter?.orderBy ?? 'asc'}
                        stickyHeader
                        onScrollEnd={loadMoreList}
                    />
                </ValidatorForm>
                {
                    scheduler?.open &&
                    <Scheduler
                        scheduleProps={scheduler}
                        onClose={() => setScheduler({ open: false, anchorElement: null, connectionId: "", asset: {} })}
                        onDelete={() => setScheduler({ open: false, anchorElement: null, connectionId: null, asset: {} })}
                        confirmDelete
                        disabled={checkSchedulePermission(scheduler)}
                    />
                }
            </Grid>
        </Grid>
    );
}

// default props
ScheduleList.defaultProps = {
    classes: {}
};

// prop types
ScheduleList.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticAppStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ScheduleList);