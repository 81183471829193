import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

//Styles
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Component
import {
    TableComponent,
    AutoCompleteComponent
} from '../../../../../components/index';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Constant
import appConstants from '../../../../../constants/appConstants';

// Import Actions
import { getAllAttributesRequest } from '../../../../../redux/reducer/attributeReducer';
import {
    getLinkedAssetRequest,
    linkAssetRequest,
    unLinkAssetRequest
} from '../../../../../redux/reducer/termReducer';
import { termApprovalRequest } from '../../../../../redux/reducer/versionReducer.js';
import { navigate } from '../../../../../redux/reducer/navigationReducer.js';


function LinkedAssets(props) {
    /**
     * Define Props
     */
    const { semanticsPermission } = props;
    const dispatch = useDispatch();
    const { termId } = useParams();

    /**
     * Get Redux Store Values
     */
    const organizationAttributes = useSelector(
        ({ attribute }) => attribute.attributes
    );
    const linkAssets = useSelector(({ term }) => term.linkAssets);
    const { detail: termDetail, isLoading } = useSelector((state) => state.term);

    /**
     * Define State
     */
    const [attributes, setAttributes] = useState([]);
    const [isAddOption, setAddOption] = useState(false);
    const [sorting, setSorting] = useState({
        sortBy: '',
        orderBy: 'asc'
    });
    const [searchData, setSearchData] = useState({
        asset_name: '',
        name: ''
    });

    /**
     * Map Attribute aganist the Term
     */
    const linkAttribute = () => {
        if (attributes.length) {
            const requestParams = {
                attributes,
                term_id: termId
            };
            dispatch(linkAssetRequest(requestParams));
            setAttributes([]);
        }
    };

    /**
     * Handle Action Event
     * @param {*} attribute
     */
    const onClickActions = (data, actionName, event, parentData, value) => {
        if (actionName === 'link') {
            const requestParams = {
                id: termId,
                version_id: data.version_id,
                asset_id: data.asset_id,
                attribute_id: data.attribute_id,
                attribute_meta_id: data.id
            };
            dispatch(unLinkAssetRequest(requestParams));
        } else if (actionName === 'accept') {
            const requestParams = {
                asset_id: data.asset_id,
                version_id: data.version_id,
                term_id: data.term_id,
                attribute_id: data.attribute_id,
                is_approved: value
            };
            dispatch(termApprovalRequest(requestParams));
        }
    };

    /**
     * Enable Option
     */
    const enableOption = () => {
        setAddOption(true);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy,
            orderBy
        };
        setSorting(requestData);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Get Organization Attributes
     */
    useEffect(() => {
        if (!organizationAttributes.length) {
            dispatch(getAllAttributesRequest());
        }
        dispatch(getLinkedAssetRequest(termId));
    }, []);

    /**
     * Filter Maped Attributes
     * @param {*} data
     * @param {*} attributeList
     * @param {*} attributes
     * @returns list
     */
    const filterMapedAttributes = (data, attributeList, attributes) => {
        attributeList = [...attributes, ...attributeList];
        attributeList = attributeList.map((attribute) => attribute.id);
        return data.filter((attribute) => attributeList.indexOf(attribute.id) === -1);
    };

    /**
     * Filter Assets
     * @returns
     */
    const filterAssets = (assets, sortBy, orderBy, searchData) => {
        let filteredAssets = assets;
        const filters = [];
        for (const key of Object.keys(searchData)) {
            if (searchData[key] !== '') {
                filters.push(key);
            }
        }
        if (filters.length) {
            filteredAssets = filteredAssets.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (
                        !value
                            .toLowerCase()
                            .includes(searchData[key].toLowerCase())
                    ) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filteredAssets = _.orderBy(filteredAssets, [sortBy], [orderBy]);
        }
        return filteredAssets;
    };

    /**
     * Handle cell click event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const handleCellClickEvent = (key, item) => {
        switch (key) {
            case "connection_name":
                if (!item.connection_id) {
                    return;
                }
                dispatch(navigate({ path: 'connector.asset', state: {}, params: [item.type, item.connection_id] }));
                break;
            case "asset_name":
                if (!item.asset_id) {
                    return;
                }
                dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
                break;
            case "name":
                if (!item.asset_id || !item.attribute_id) {
                    return;
                }
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [item.asset_id, item.attribute_id] }));
                break;
            default:
                break;
        }
    };


    /**
     * Filter Attributes using usememo
     */
    const filterAttributes = useMemo(
        () =>
            filterMapedAttributes(
                organizationAttributes,
                linkAssets,
                attributes
            ),
        [organizationAttributes, linkAssets, attributes]
    );
    const filteredAssets = useMemo(
        () =>
            filterAssets(
                linkAssets,
                sorting.sortBy,
                sorting.orderBy,
                searchData
            ),
        [linkAssets, sorting.sortBy, sorting.orderBy, searchData]
    );


    const tableOptions = [
        { type: 'search', customFunction: null },
        { type: 'download', customFunction: null },
        { type: 'columns', customFunction: null }
    ];

    if (termDetail?.status?.toLowerCase() === "verified") {
        tableOptions.push({ type: 'add', customFunction: enableOption });
    }

    const permissionOptions = (options) => {
        const filteredOption = [...options];
        if (!semanticsPermission?.is_edit) {
            const index = filteredOption.findIndex(
                (option) => option.type === 'add'
            );
            filteredOption.splice(index, 1);
        }
        return filteredOption;
    };

    const permissionActions = (actions) => {
        const filteredAction = [...actions];
        if (!semanticsPermission?.is_edit) {
            const index = filteredAction.findIndex(
                (option) => option.key === 'actions'
            );
            filteredAction.splice(index, 1);
        }
        return filteredAction;
    };

    return (
        <Grid item xs={12}>
            <ValidatorForm onSubmit={() => { }}>
                <Grid container justifyContent="space-between" className="mb-1">
                    {
                        isAddOption && semanticsPermission?.is_edit && (
                            <Grid
                                item
                                sx={
                                    {
                                        '& .MuiInput-input': {
                                            minWidth: '600px !important'
                                        }
                                    }
                                }
                            >
                                <AutoCompleteComponent
                                    variant="standard"
                                    fullWidth
                                    customOption
                                    multiple
                                    labelKey="name"
                                    selectedValue={attributes || []}
                                    data={filterAttributes || []}
                                    onChange={
                                        (event, newValue) =>
                                            setAttributes(newValue)
                                    }
                                    placeholder="Select Attribute"
                                    onBlur={() => linkAttribute()}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                <TableComponent
                    styleType="striped"
                    headers={
                        permissionActions(
                            appConstants.table.termAssetHeader
                        )
                    }
                    sortBy={sorting.sortBy}
                    title={'Linked Assets'}
                    orderBy={sorting.orderBy}
                    data={filteredAssets || []}
                    options={permissionOptions(tableOptions)}
                    onClickSorting={onClickSorting}
                    onClickActions={onClickActions}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onCellClick={handleCellClickEvent}
                    NoResultText="No Items Found"
                    isLoading={isLoading}
                    height="300px"
                    exportParams={
                        {
                            fileName: "link_assets.csv",
                            renameColumn: { "name": "attribute name" },
                            titleCaseHeader: true
                        }
                    }
                    stickyHeader
                />
            </ValidatorForm>
        </Grid>
    );
}

// default props
LinkedAssets.defaultProps = {
    semanticsPermission: {}
};

// prop types
LinkedAssets.propTypes = {
    semanticsPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LinkedAssets);