// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

// import Components
import { Typography, Grid } from '@mui/material';

// Import Constant
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';


// Import Styles
import style from "./style.jsx";

const RadarChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, color, chartProperties, score } = props;

    /**
     * Calculate Average
     * @returns
     */
    const calculateAverage = () => {
        return score?.toFixed(1) || (data.data.reduce((a, b) => parseInt(a) + parseInt(b), 0) / data.data.length).toFixed(1);
    };


    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        radar: {
            indicator: [...data.indicator]
        },
        series: [
            {
                type: 'radar',
                areaStyle: {
                    color: color || "#64AAEF",
                    opacity: 0.4
                },
                data: [
                    {
                        value: data.data || [],
                        name: data.name,
                        itemStyle: { normal: { color: color || "#64AAEF" } }
                    }
                ]
            }
        ],
        tooltip: {
            className: 'radar-tooltip',
            position: 'fixed'
        }
    };

    return (
        <Grid
            style={
                {
                    width: "100%",
                    height: "100%"
                }
            }>
            <ReactEcharts
                option={option}
                opts={{ renderer: "svg" }}
                style={
                    {
                        width: "100%",
                        height: `${chartProperties.showQuality ? 90 : 100}%`
                    }
                }
            />
            {
                chartProperties.showQuality &&
                <Typography align="center" variant="h1">
                    {`${calculateAverage()}%`}
                </Typography>
            }
        </Grid>
    );

};


/**
 * Define Prop Types
 */
RadarChartComponent.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    color: PropTypes.string,
    score: PropTypes.string,
    chartProperties: PropTypes.object
};

/**
 * Set Default Values
 */
RadarChartComponent.defaultProps = {
    data: [],
    color: "",
    chartProperties: {},
    score: null
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(RadarChartComponent, areEqual));