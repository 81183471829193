import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Components
import { Button, Grid } from '@mui/material';
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent } from '../../components/index.js';

// Import Styles
import AuthenticationStyle from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DQLabsLogo from '../../assets/img/dqlabs.png';

// Import Actions
import { loginRequest } from '../../redux/reducer/authReducer.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import { ssoRequestURL } from '../../helpers/apiHelpers.js';
import { Crypto } from '../../helpers/index.js';

// Import Constants
import appConstants from '../../constants/appConstants.js';

// Import Reducers
import { clearAssetByExtentionData } from '../../redux/reducer/extensionReducer.js';

function ExtensionLogin(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const query_site_url = searchParams.get("search_url");

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { isLoading, isLoggedIn } = useSelector((state) => state.auth, shallowEqual);

    /**
     * Define States
     */
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const { email, password } = formData;

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Search Data By URL
     */
    useEffect(() => {
        if (query_site_url) {
            if (query_site_url === "clear_search_key") {
                localStorage.removeItem("extension_search_url");
                dispatch(clearAssetByExtentionData());
            } else {
                localStorage.setItem("extension_search_url", query_site_url);
            }
        }
        removeSearchParam();
    }, [query_site_url]);


    /**
     * Handle Textbox Changes
     */
    const handleChange = useCallback((property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    }, [formData]);

    /**
     * Submit Form
     * @param {*} data
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const encryptDecrypt = new Crypto();
        const requestParams = {
            email: encryptDecrypt.encrypt(formData.email),
            password: encryptDecrypt.encrypt(formData.password),
            isExtension: true
        };
        dispatch(loginRequest(requestParams));
    };

    /**
     * Handle SSO Login
     */
    const handleSSOLogin = () => {
        window.location = ssoRequestURL({ sExtension: true });
    };

    if (isLoggedIn || localStorage.getItem("token")) {
        navigate('/extension/tableau');
        return;
    }

    return (
        <Grid className={classes.extensionContainer}>
            <Grid className={classes.drawerBody}>
                <Grid className={classes.drawerBodyInner} sx={{ height: `calc(100vh - 54px)` }}>
                    <Grid className={classes.loginContainer}>
                        <ValidatorForm noValidate onSubmit={handleSubmit}>
                            <Grid className={`${classes.loginBox}`}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} align="center">
                                        <img
                                            alt="logo"
                                            src={DQLabsLogo}
                                            className={classes.loginLogo}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MemoTextBoxComponent
                                            id="form_text_email"
                                            name={'email'}
                                            value={email}
                                            fullWidth
                                            placeholder={appConstants.labels.authentication.email}
                                            variant="outlined"
                                            size="medium"
                                            validators={['required', 'isEmail']}
                                            valOnChange
                                            errorMessages={
                                                [
                                                    setRequiredErrorMessage(appConstants.labels.authentication.email),
                                                    appConstants.errorMessages.invalidMail
                                                ]
                                            }
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInputComponent
                                            id="form_txt_password"
                                            name={'password'}
                                            value={password}
                                            fullWidth
                                            placeholder={appConstants.labels.authentication.password}
                                            variant="outlined"
                                            size="medium"
                                            validators={['passwordRequired']}
                                            errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.password)]}
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(event, value) => handleChange(event.target.name, value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.mt2}>
                                        <Grid container justifyContent={'space-between'}>
                                            <LoaderButtonComponent
                                                size={'large'}
                                                isLoading={isLoading}>
                                                {appConstants.labels.authentication.login}
                                            </LoaderButtonComponent>
                                            <Grid align="right">
                                                <Button
                                                    variant="text"
                                                    className={classes.linkBtn}
                                                    disableRipple
                                                    onClick={handleSSOLogin}
                                                >
                                                    {appConstants.labels.authentication.sso}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
ExtensionLogin.defaultProps = {
    classes: {}
};

// prop types
ExtensionLogin.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AuthenticationStyle(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(ExtensionLogin);