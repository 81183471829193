import appConstants from '../constants/appConstants.js';
import pathConstants from '../constants/pathConstants.js';
import palette from '../assets/theme/palette';
import _ from 'lodash';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";

/**
 * Get Client Host Origin
 * @returns
 */
export const getClientHostOrigin = () => {
    return window.location.origin;
};

/**
 * Check Permission for Feature Based on Role
 * @param {*} permission
 * @param {*} featue
 */
export const checkPermission = (permission, features) => {
    if (permission.length) {
        const havePermission = permission.find(
            (x) =>
                x.feature.name === features.name &&
                x.feature.sub_feature === features.feature
        );
        return havePermission ? havePermission : {};
    }
    return null;
};

/**
 * Get Header Title
 * @param {*} path
 */
export const getHeaderTitle = (path) => {
    const sPath = path.split('/');
    const pathName = [];
    sPath.forEach((element) => {
        if (appConstants.header[element]) {
            pathName.push(appConstants.header[element]);
        }
    });
    return pathName.join(' / ');
};

/**
 * Convert UTC DateTime to Local DateTime
 * @param {*} datetime
 * @returns
 */
export const convertUTCtoLocalDateTime = (datetime, dateOnly = false) => {
    if (datetime) {
        const date = new Date(datetime);
        if (dateOnly) {
            return moment(date).format('MMM DD YYYY');
        }
        return moment(date).format('MMM DD YYYY hh:mm A');
    }
    return 'NA';
};

/**
 * Get Already Saved User
 */
export const getUser = () => {
    let user = localStorage.getItem(appConstants.localStorageKey);
    if (user) {
        user = JSON.parse(user);
    }
    return user || null;
};

/**
 * Set Cookie
 * @param {*} cookieName
 * @param {*} value
 */
export const setCookie = (cookieName, value) => {
    const now = new Date();
    now.setTime(now.getTime() + 8 * 3600 * 1000);
    document.cookie = `${cookieName}=${value}; expires=${now.toUTCString()}; path=/`;
    localStorage.setItem('token', value);
};

/**
 * Get Cookie
 * @param {*} cookieName
 * @returns
 */
export const getCookie = (cookieName) => {
    const cookie = `${cookieName}=`;
    const decodeCookie = decodeURIComponent(document.cookie);
    const cookieData = decodeCookie.split(';');
    for (let i = 0; i < cookieData.length; i++) {
        const c = cookieData[i].trim();
        if (c.indexOf(cookie) === 0) {
            return c.substring(cookie.length, c.length);
        }
    }
    const lsToken = localStorage.getItem('token');
    return lsToken || '';
};

/**
 * Remove Cookie
 */
export const removeCookie = () => {
    document.cookie.split(';').forEach((c) => {
        document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    localStorage.removeItem('token');
};

/**
 * Get user auth Token
 */
export const getToken = () => {
    const token = getCookie('token');
    if (token) {
        return token;
    }

    const lsToken = localStorage.getItem('token');
    return lsToken || null;
};

/**
 * Get user refresh Token
 */
export const getRefreshToken = () => {
    let token = localStorage.getItem(appConstants.localStorageKey);
    token = JSON.parse(token);
    if (token && token.refresh) {
        return token.refresh;
    }
    return null;
};

/**
 * Build Required Error Message
 */
export const removeToken = () => {
    localStorage.removeItem(appConstants.localStorageKey);
};

/**
 * Handle Required Error Message Content
 * @param {*} errLabel
 * @returns
 */
export const setRequiredErrorMessage = (errLabel) => {
    return (
        errLabel.toLowerCase() + ' ' + appConstants.errorMessages.requriedField
    );
};

/**
 * Check for string and null values and convert them into percentage text.
 * @param {*} value
 * @returns
 */
export const createPercentageText = (value) => {
    let progressText = value ? parseFloat(value) : 0;
    progressText = Math.abs(progressText);
    if (progressText > -1 && progressText < 1) {
        let decimalCount = progressText.toString().split('.');
        decimalCount =
            decimalCount && decimalCount.length
                ? decimalCount[decimalCount.length - 1].length
                : '';
        if (decimalCount <= 2) {
            return value;
        }
    }
    if (Math.round(progressText) !== progressText) {
        progressText = progressText.toString().substring(0, 4);
    }

    return progressText;
};

/**
 * Converts Hex Code to RGB
 * @param {*} code
 * @param {*} format
 * @returns
 */
export const hexToRgb = (code, format) => {
    let r = 0,
        g = 0,
        b = 0;

    // 3 digits
    if (code.length === 4) {
        r = '0x' + code[1] + code[1];
        g = '0x' + code[2] + code[2];
        b = '0x' + code[3] + code[3];

        // 6 digits
    } else if (code.length === 7) {
        r = '0x' + code[1] + code[2];
        g = '0x' + code[3] + code[4];
        b = '0x' + code[5] + code[6];
    }

    if (format === 'rgba') {
        return 'rgba(' + Number(r) + ',' + Number(g) + ',' + Number(b) + ',0.2)';
    }
    return 'rgb(' + Number(r) + ',' + Number(g) + ',' + Number(b) + ')';
};

/**
 * Create Random background colors for respective text/email
 * @param {*} text
 * @param {*} format
 * @returns
 */
export const createRandomColors = (text, format = '') => {
    const colors = palette.randomColors;
    text = text ? text : '';
    const charCodes = text
        .split('')
        .map((char) => char.charCodeAt(0))
        .join('');
    const codes = parseInt(charCodes, 10);
    const colorCode = colors[codes % colors.length];
    if (format !== '') {
        return hexToRgb(colorCode, format);
    }
    return colorCode;
};

/**
 *
 * @param {*} string
 * @returns
 */
const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

/**
 *
 * @param {*} name
 * @returns
 */
export const stringAvatar = (name = '', avatar = '') => {
    if (avatar && avatar !== 'null') {
        return { src: avatar };
    }

    const sName = name.split(' ');
    const firstInitial = sName[0][0];
    const secondInitial = sName.length > 1 ? sName[1][0] : '';

    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: secondInitial ? `${firstInitial}${secondInitial}` : firstInitial
    };
};

/**
 * Convert Number to Comma Separated
 * @param {*} x
 * @returns
 */
export const numberWithCommas = (x, showNa = true) => {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return showNa ? 'NA' : '0';
};

/**
 * Rediect to Page
 * @param {*} path
 * @param {*} state
 * @param {*} needFrontSlash
 */
export const getRedirectPath = (path, params = [], needFrontSlash = false) => {
    let fPath = _.get(pathConstants, path);
    if (needFrontSlash) {
        fPath = fPath.replace(/^\/|\/$/g, '');
    }
    params.forEach((value, index) => {
        fPath = fPath.replace(`param${index + 1}`, value);
    });
    return fPath;
};

/**
 * Get Datatype Initial
 * @param {*} datatype
 * @returns
 */
export const getDataTypeInitial = (datatype = '') => {
    datatype = datatype || '#';
    return datatype[0].toLocaleUpperCase();
};

/**
 * Seconds seconds to proper format
 * @param {*} seconds
 * @returns
 */
export const secondsToDhms = (s) => {
    const minutes = (s / 60).toFixed(1);
    const hours = (s / (60 * 60)).toFixed(1);
    const days = (s / (60 * 60 * 24)).toFixed(1);
    if (minutes < 60) {
        return minutes + ' Min';
    } else if (hours < 24) {
        return hours + ' Hrs';
    }
    return days + ' Days';
};

/**
 * Get Behavioral Types
 * @param {*} attributes
 * @returns types
 */
export const getBehavioralType = (attributes) => {
    let behavioralType = '';
    if (!attributes || (attributes && attributes.length <= 0)) {
        return behavioralType;
    }

    const textAttributes = attributes.filter(
        (attribute) => attribute.data_type.toLowerCase() === 'text'
    );
    const dateAttributes = attributes.filter(
        (attribute) =>
            attribute.data_type.toLowerCase() === 'date' ||
            attribute.data_type.toLowerCase() === 'datetime' ||
            attribute.data_type.toLowerCase() === 'datetimeoffset' ||
            attribute.data_type.toLowerCase() === 'time' ||
            attribute.data_type.toLowerCase() === 'timestamp'
    );
    const numericAttributes = attributes.filter(
        (attribute) =>
            attribute.data_type.toLowerCase() === 'numeric' ||
            attribute.data_type.toLowerCase() === 'integer'
    );
    const categoryColumns = 'C'.repeat(textAttributes.length);
    const numericColumns = 'N'.repeat(numericAttributes.length);
    const timeColumns = 'T'.repeat(dateAttributes.length);
    behavioralType = `${categoryColumns}${numericColumns}${timeColumns}`;
    return behavioralType;
};

/**
 * Convert Number to Round Value
 * @param {*} value
 * @param {*} decimal
 * @returns
 */
export const toRound = (value, precision = 1) => {
    if (value) {
        const multiplier = Math.pow(10, precision || 0);
        if (value > 0 && value < 1) {
            return 1;
        } else if (value > 99 && value < 100) {
            return 99;
        } else if (value === 100 || Number.isInteger(value)) {
            return value;
        }
        return (Math.round(value * multiplier) / multiplier);
    }
    return 0.0;
};

/**
 * Seconds to Date Format
 * @param {*} num
 * @returns
 */
export const dFormatter = (num, detailed = false) => {
    if (num) {
        const seconds = Number(num);
        const d = Math.floor(seconds / (3600 * 24));
        const hour = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const h = (d * 24) + hour;

        let dDisplay = d + "d";
        let hDisplay = h + "h";
        let mDisplay = m + "m";
        let sDisplay = s + "s";
        let h_Display = hour + "h";

        if (detailed) {
            dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
            hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
            h_Display = hour > 0 ? hour + (hour === 1 ? " hour, " : " hours, ") : "";
            mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
            sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        }

        if (d > 3) {
            if (h) {
                return `${dDisplay} ${h_Display} ${mDisplay}`;
            }
            return dDisplay;
        } else if (h > 0 && h <= 95) {
            if (mDisplay) {
                return `${hDisplay} ${mDisplay} ${sDisplay}`;
            }
            return `${hDisplay}`;
        } else if (m > 0 && m <= 59) {
            if (sDisplay) {
                return `${mDisplay} ${sDisplay}`;
            }
            return `${mDisplay}`;
        }

        return sDisplay;
    }
    if (num === 0) {
        return `${num}s`;
    }
    return 'NA';
};

/**
 * Get Number Format Value
 * @param {*} num
 * @param {*} digits
 * @returns
 */
export const nFormatter = (num, ruleName = '', digits = 2) => {
    if (ruleName.toLowerCase() !== 'freshness') {
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'K' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'B' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup
            .slice()
            .reverse()
            .find((item) => {
                return num >= item.value;
            });
        return item
            ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
            : 0;
    }

    dFormatter(num);
};

/**
 * Get alert status colors
 * @param {*} num
 * @param {*} digits
 * @returns
 */
export const getAlertStatusColors = (status) => {
    let color = palette.greyText;
    switch (status.toLowerCase()) {
        case appConstants.priority[0].toLowerCase():
        case appConstants.issueStatus[0].toLowerCase():
            color = palette.colorThemes.high;
            break;
        case appConstants.priority[1].toLowerCase():
        case appConstants.issueStatus[1].toLowerCase():
            color = palette.colorThemes.medium;
            break;
        case appConstants.priority[2].toLowerCase():
        case appConstants.issueStatus[2].toLowerCase():
            color = palette.colorThemes.low;
            break;
        default:
            color = palette.greyText;
            break;
    }
    return color;
};

/**
 * Calculate Minimun Value of Quadrant Slice
 * @param {*} slice
 * @param {*} current
 * @returns
 */
export const calculateMin = (slice, current) => {
    const value = (100 / slice) * current;
    if (value > 0) {
        return Math.floor(value + 1);
    }
    return Math.floor(value);
};

/**
 * Calculate Maximun Value of Quadrant Slice
 * @param {*} slice
 * @param {*} current
 * @returns
 */
export const calculateMax = (slice, current) => {
    const value = (100 / slice) * (current + 1);
    if (value === 99) {
        return Math.floor(value + 1);
    }
    return Math.floor(value);
};

/**
 * Get Work Log Status
 * @param {*} item
 * @returns
 */
export const getWorkLogStatus = (item = {}) => {
    switch (item.log_type) {
        case 'status':
            return `Status changed from ${item.old_status} to ${item.status}`;
        case 'priority':
            return `Priority changed from ${item.old_status} to ${item.status}`;
        default:
            return 'Created an issue';
    }
};

/**
 * Converts bytes to readable size
 */
export const bytesToSize = (bytes) => {
    if (typeof bytes === 'string') {
        bytes = parseInt(bytes);
    }
    if (bytes === 0) {
        return `0 B`;
    }
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
        return `${bytes} ${sizes[i]}`;
    }
    return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
};

/**
 * Handle Conver to Html Event
 */
export const convertHtml = (content) => {
    const contentState = convertFromRaw(JSON.parse(content));
    const options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            if (entityType === 'mention') {
                const { mention } = entity.get('data');
                return {
                    element: 'mention',
                    content: mention.id,
                    attributes: {
                        userid: mention.id
                    }
                };
            }
            if (entityType === '#mention') {
                const { mention } = entity.get('data');
                return {
                    element: 'asset',
                    attributes: {
                        userid: mention.userId
                    }
                };
            }
        }
    };
    return stateToHTML(contentState, options);
};

/**
 * Find Links and make it hyperlink
 * @param {*} comment_text
 * @returns
 */
export const createLinkedComment = (comment_text) => {
    const urlRegex =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    const new_comment = comment_text.replace(urlRegex, (url) => {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
    return new_comment;
};

/**
 * Generate UUID4
 * @returns
 */
export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
};

/**
 * disable polarity and scoring flags for statistical measures
 * @param {*} data The measure
 * @returns <true> if type is statistics or some statistical health measures
 */
export const disableFlags = (data, header) => {
    let isDisabled = !data.is_active;
    if (header.key === "is_active") {
        isDisabled = (data.default_active);
        return isDisabled;
    }
    const scoreFlags = ['is_positive', 'allow_score'];
    if (data.is_active && scoreFlags.indexOf(header.key) > -1) {
        const isHealthStat =
            data.name &&
            appConstants.healthStatistics.indexOf(data.name.toLowerCase()) > -1;
        const isbehavioral = Boolean(
            data?.category?.toLowerCase() === 'behavioral'
        );
        const islookup = Boolean(
            data?.category?.toLowerCase() === 'lookup' && header.key !== 'allow_score'
        );
        isDisabled = (data.disable_scoring || isHealthStat || isbehavioral || islookup);
        const isFrequencyStat =
            data.technical_name && header.key === 'is_positive' &&
            appConstants.frequencyMeasures.indexOf(data.technical_name.toLowerCase()) > -1;
        isDisabled = isDisabled || isFrequencyStat;
    }

    if (!data.allow_score && (header.key === "weightage" || header.key === "pass_criteria_threshold")) {
        return true;
    }

    if (data.is_default === "Yes" && header.defaultDisabled) {
        return true;
    }

    if (data.is_active && data?.category?.toLowerCase() === 'comparison') {
        const allowedKeys = ["is_positive", "allow_score", "is_drift_enabled"];
        if (allowedKeys.includes(header.key)) {
            return true;
        }
    }
    return isDisabled;
};

/**
 * Dynamic Issue Name
 * @returns
 */
export const dynamicIssueName = () => {
    const date = new Date();
    return moment(date).format('MMM DD YYYY hh:mm A');
};

/**
 * Create Element for Font Import
 * @param {*} href
 * @returns
 */
export const createElement = (href) => {
    const linkElement = document.createElement('link');
    linkElement.href = href;
    linkElement.type = 'text/css';
    linkElement.rel = 'stylesheet';
    return linkElement;
};

/**
 * Set Font Familt Import for inital load
 * @param {*} font_family
 */
export const setThemePalette = (font_family) => {
    const { typography } = font_family;
    const fontList = [];
    Object.keys(typography).forEach((fonts) => {
        if (
            fontList.indexOf(typography[fonts].fontFamily) === -1 &&
            typography[fonts].fontFamily
        ) {
            fontList.push(typography[fonts].fontFamily);
        }
    });
    fontList.forEach((font_family) => {
        const setFontFamily = appConstants.fontFamily.find(
            (font) => font.name === font_family
        );
        if (setFontFamily && setFontFamily.url) {
            const linkElement = createElement(setFontFamily.url);
            document.querySelector('head').appendChild(linkElement);
        }
    });
};

/**
 * Change Header Based on Permission
 * @param {*} header
 * @returns
 */
export const permissionHeaders = (header, editPermission, handleActions) => {
    const filteredHeader = [...header];
    if (editPermission && !editPermission.is_edit) {
        const index = filteredHeader.findIndex(
            (option) => option.key === 'actions'
        );
        if (index >= 0 && handleActions) {
            const deleteIndex = filteredHeader[index].actions.indexOf('delete');
            if (deleteIndex !== -1) {
                filteredHeader[index].actions.splice(deleteIndex, 1);
            }
        } else if (index >= 0) {
            filteredHeader.splice(index, 1);
        }
        filteredHeader.forEach((header) => {
            header.isPermissionDisabled = true;
        });
    }
    return filteredHeader;
};

/**
 * Hide Tabs based on Permission
 * @param {*} permissionList
 * @param {*} tabs
 * @returns
 */
export const permissionTabs = (permissionList, tabs) => {
    const filteredTabs = tabs.filter((tab) => {
        if (tab.restricted) {
            return permissionList.find(
                (x) =>
                    x.feature.name === tab.featureName &&
                    x.feature.sub_feature === tab.subFeatureName &&
                    x.is_view
            );
        }
        return true;
    });
    return filteredTabs;
};

/**
 * Get Alias Name
 * @param {*} name
 */
export const getAliasName = (name = '') => {
    return name.replace(/ /g, '_').toLowerCase();
};


/**
 * Get Day Filter Duration
 * @param {*} date
 * @returns
 */
export const getDayFilterDuration = (date) => {
    const duration = moment.duration(moment(new Date()).diff(moment(date)));
    const days = duration.asDays();
    if (days > 15) {
        return 'Last 30 days';
    } else if (days > 3 && days <= 7) {
        return 'Last 7 days';
    } else if (days > 7 && days <= 15) {
        return 'Last 15 days';
    }
    return 'Last 3 days';
};

/**
 * Build Form Data
 * @param {*} formData
 * @param {*} data
 * @param {*} parentKey
 */
export function buildFormData(
    formData,
    data,
    parentKey,
    preserveEmpty = false
) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            if (!preserveEmpty && data[key] !== '') {
                buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}.${key}` : key
                );
            }
        });
    } else {
        const value = data === null ? '' : data;
        if (!preserveEmpty && value !== '') {
            formData.append(parentKey, value);
        }
    }
    return formData;
}

/**
 * Apply Custom Sorting Order
 * @param {*} arr
 * @param {*} desiredOrder
 */
export const applyCustomOrder = (arr, desiredOrder) => {
    const orderForIndexVals = desiredOrder.slice(0).reverse();
    arr.sort((a, b) => {
        const aIndex = -orderForIndexVals.indexOf(a);
        const bIndex = -orderForIndexVals.indexOf(b);
        return aIndex - bIndex;
    });
};

/**
 * Get Widget Tables
 * @param {*} detail
 * @returns
 */
export const getWidgetTables = (detail) => {
    const widgetType = detail.widget_type || "vertical_bar";
    let tables = [];
    if (detail.filter_properties.filters.applications.length) {
        tables.push('application');
    }
    if (detail.filter_properties.filters.domains.length) {
        tables.push('domain');
    }
    if (detail.filter_properties.filters.tags.length) {
        tables.push('tags');
    }
    if (detail.filter_properties.filters.terms.length) {
        tables.push('terms');
    }
    if (detail.filter_properties.filters.dimension?.length) {
        tables.push('dimension');
    }
    if (detail.filter_properties.filters.measure_category?.length || detail.filter_properties.filters.measure_level?.length || detail.filter_properties.filters.measure_type?.length || detail.filter_properties.filters.measure_name?.length) {
        tables.push('measure');
    }
    if (detail.filter_properties.filters.filter_by?.length) {
        if (detail.filter_properties.filters.filter_by.includes("alerts") || detail.filter_properties.filters.filter_by.includes("without_alerts")) {
            tables.push('drift_alert');
        }
        if (detail.filter_properties.filters.filter_by.includes("issues") || detail.filter_properties.filters.filter_by.includes("without_issues")) {
            tables.push('issues');
        }
    }
    const xAxis = detail.properties.xAxis || [];
    const yAxis = detail.properties.yAxis || [];
    const breakDown = detail.properties.breakDown || [];
    const levels = detail.properties.levels || [];
    const groupBy = detail.properties.groupBy || [];
    let selectedTables = [...xAxis, ...yAxis, ...breakDown, ...levels, ...groupBy]
        .filter((obj) => obj.table)
        .map((obj) => obj.table);
    tables = [...new Set([...tables, ...selectedTables])];
    selectedTables = [];
    for (const table of tables) {
        const index = tables.indexOf(table);
        switch (table) {
            case 'tags':
            case 'terms':
                selectedTables.push(table);
                selectedTables.splice(index, 0, 'attribute');
                break;
            case 'drift_alert':
                selectedTables.push(table);
                if (widgetType === "heatmap") {
                    selectedTables.splice(index, 0, 'measure', 'metrics');
                }

                break;
            case 'dimension':
            case 'metrics':
                selectedTables.push(table);
                selectedTables.splice(index, 0, 'measure');
                break;
            default:
                selectedTables.push(table);
                break;
        }
    }
    selectedTables = [...new Set(selectedTables)];
    const sortingOrder = [
        'attribute',
        'application',
        'domain',
        'tags',
        'terms',
        'measure',
        'metrics',
        'dimension',
        'drift_alert',
        'issues'
    ];
    applyCustomOrder(selectedTables, sortingOrder);
    return selectedTables;
};

/**
 * Get LocalStorage Item
 * @param {*} key
 * @param {*} value
 */
export const getLocalStorage = (key) => {
    return localStorage.getItem(key) || '';
};

/**
 * Save To LocalStorage
 * @param {*} key
 * @param {*} value
 */
export const saveLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};

/**
 * Remove LocalStorage
 * @param {*} key
 */
export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
};

/**
 * Check Float value
 * @param {*} value
 * @returns
 */
export const isFloat = (value) => {
    if (
        typeof value === 'number' &&
        !Number.isNaN(value) &&
        !Number.isInteger(value)
    ) {
        return true;
    }
    return false;
};

/**
 * Get ChartData
 * @param {*} data
 * @param {*} chartType
 * @param {*} params
 * @returns
 */
export const getChartData = (data, chartType = "vertical_bar", params = {}) => {
    switch (chartType.toLowerCase()) {
        case 'vertical_bar':
            return params.yAxisLabel ? data.map((obj) => (isFloat(obj[params.yAxisLabel]) ? obj[params.yAxisLabel].toFixed(1) : obj[params.yAxisLabel])) : [];
        case 'horizontal_bar':
            return params.xAxisLabel ? data.map((obj) => (isFloat(obj[params.xAxisLabel]) ? obj[params.xAxisLabel].toFixed(1) : obj[params.xAxisLabel])) : [];
        default:
            return [];
    }
};

/**
 * Get Stacked Data
 * @param {*} data
 * @param {*} uniqueNames
 * @param {*} breakDownLabel
 * @param {*} groupByType
 * @param {*} axisType
 * @returns
 */
export const getStackedData = (data, uniqueNames, breakDownLabel, groupByType, axisType) => {
    const groupData = data.reduce((r, a) => {
        r[a[breakDownLabel]] = r[a[breakDownLabel]] || [];
        r[a[breakDownLabel]].push(a);
        return r;
    }, Object.create(null));
    const stackedData = [];
    for (const key of Object.keys(groupData)) {
        const data = groupData[key];
        const stack = { name: key, series: [] };
        for (const name of uniqueNames) {
            const item = data.find((obj) => obj[groupByType] === name);
            let value = item ? item[axisType] : 0;
            value = isFloat(value) ? value.toFixed(1) : value;
            stack.series.push(value);
        }
        stackedData.push({ ...stack });
    }
    return stackedData;
};

/**
 * Get Support Columns
 * @param {*} data
 * @returns
 */
export const getSupportColumns = (data) => {
    const xAxis = data.properties.xAxis || [];
    const yAxis = data.properties.yAxis || [];
    const breakDown = data.properties.breakDown || [];
    const levels = data.properties.levels || [];
    const columns = [...xAxis, ...yAxis, ...breakDown, ...levels].filter((obj) => obj.support_column && obj.type === "dimension").map((obj) => obj.support_column);
    const supportColumns = [];
    for (const column of columns) {
        for (const data of column) {
            supportColumns.push(data);
        }
    }
    return [...new Set(supportColumns)].map((item) => {
        const standaloneColumn = item.includes("measure") ? { standaloneColumn: item } : {};
        return {
            name: item,
            type: "dimension",
            ...standaloneColumn
        };
    });
};

/**
 * Get the expacted manual threshold
 * @param {*} threshold_constraints
 */
export const getExpectedManualThreshold = (threshold_constraints) => {
    let expected = "";
    if (!threshold_constraints) {
        return expected;
    }

    switch (threshold_constraints.condition) {
        case "isGreaterThan":
            expected = `> ${threshold_constraints.value}`;
            break;
        case "isLessThan":
            expected = `< ${threshold_constraints.value}`;
            break;
        case "isGreaterThanOrEqualTo":
            expected = `>= ${threshold_constraints.value}`;
            break;
        case "isLessThanOrEqualTo":
            expected = `<= ${threshold_constraints.value}`;
            break;
        case "isEqualTo":
            expected = `== ${threshold_constraints.value}`;
            break;
        case "isBetween":
            expected = `${threshold_constraints.value} <> ${threshold_constraints.value2}`;
            break;
        case "isNotBetween":
            expected = `${threshold_constraints.value} </> ${threshold_constraints.value2}`;
            break;
        default:
            expected = "";
            break;
    }
    return expected;
};

/**
 * Validate 404 Error
 * @param {*} error
 * @returns
 */
export const validate404Error = (error) => {
    if (error === 'Not Found') {
        return true;
    }
    return false;
};


const replaceValue = (key, value) => {
    value = value ?? "";
    if (typeof (value) === "object") {
        value = JSON.stringify(value);
    }
    return value;
};

/**
 * Export CSV
 * @param {*} data
 * @param {*} fileName
 */
export const exportCsv = (params = {}) => {
    const data = params.data || [];
    let headers = params?.headers ?? [];
    if (!headers.length && data.length) {
        headers = Object.keys(data[0]);
    }
    const fileName = params.fileName || 'file.csv';
    const renameColumn = params.renameColumn || {};
    const titleCaseHeader = params.titleCaseHeader || false;
    const finalData = [];
    for (const item of data) {
        const rowItem = headers.map((fieldName) =>
            JSON.stringify(item[fieldName], replaceValue)
        );
        finalData.push(rowItem);
    }

    let formatedHeaders = [];
    if (Object.keys(renameColumn).length || titleCaseHeader) {
        for (const header of headers) {
            let headerValue = header;
            if (header in renameColumn) {
                headerValue = renameColumn[header];
            }
            if (titleCaseHeader) {
                headerValue = headerValue.replace("_", " ");
            }
            formatedHeaders.push(headerValue);
        }
    } else {
        formatedHeaders = [...headers];
    }
    const csvData = [[...formatedHeaders], ...finalData].join('\r\n');
    fileDownload(csvData, fileName);
};

/**
 * Get Page Visible and Access based On Connection Type
 * @param {*} connection_type
 * @returns
 */
export const get_connection_type_access = (connection_type, asset_type = null) => {
    const access = {
        table_view_query: false,
        report: false,
        report_type: {
            tableau: false,
            dbt: false,
            fivetran: false,
            databricks: false,
            talend: false,
            adf: false,
            powerbi: false
        },
        attributes: false,
        issues: false,
        alerts: false,
        lineage: false,
        usageTab: {
            "query": false,
            "preview": false,
            "roles": false,
            "users": false
        },
        stats: false
    };

    switch (connection_type?.toLowerCase()) {
        case appConstants.connectionTypes.tableau.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.tableau = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;

        case appConstants.connectionTypes.dbt.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.dbt = true;
            access.stats = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;
        case appConstants.connectionTypes.airflow.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.airflow = true;
            access.stats = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;
        case appConstants.connectionTypes.fivetran.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.fivetran = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;
        case appConstants.connectionTypes.talend.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.talend = true;
            access.stats = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;
        case appConstants.connectionTypes.adf.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.adf = true;
            access.stats = true;
            return access;
        case appConstants.connectionTypes.databricks.value:
            if (asset_type === "Pipeline") {
                access.report = true;
                access.issues = true;
                access.lineage = true;
                access.report_type.databricks = true;
                access.alerts = true;
                access.stats = true;
            } else {
                access.table_view_query = true;
                access.attributes = true;
                access.issues = true;
                access.alerts = true;
                access.usageTab = {
                    "query": true,
                    "preview": true,
                    "roles": true,
                    "users": true
                };
            }
            return access;
        case appConstants.connectionTypes.powerbi.value:
            access.report = true;
            access.issues = true;
            access.lineage = true;
            access.report_type.powerbi = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": false,
                "users": false
            };
            return access;
        default:
            access.table_view_query = true;
            access.attributes = true;
            access.issues = true;
            access.alerts = true;
            access.usageTab = {
                "query": true,
                "preview": true,
                "roles": true,
                "users": true
            };
            return access;
    }
};
/**
 * Get Version Text
 * @param {*} data
 * @returns
 */
export const getVersionText = (data, isExport) => {
    let auditTypeText = "";
    let primaryText = "has been updated by";
    if (!data?.created_by) {
        primaryText = "has been updated";
    }
    const measureCategory = data?.measure_category ?? "";
    let value = data.value;
    if (isExport) {
        if (data.measure_name || data.conversation_name || data.term_name || data.usage_name || data.value) {
            auditTypeText = data.measure_name || data.conversation_name || data.term_name || data.usage_name || data.value;
        }
        primaryText = "has been updated";
    }
    primaryText = !auditTypeText.length ? primaryText : `${auditTypeText} ${primaryText}`;
    switch (data.type.toLowerCase()) {
        case 'create_asset':
            return 'Asset created by';
        case 'update_description':
            return `Description ${primaryText}`;
        case 'update_domain':
            return `Domain ${primaryText}`;
        case 'update_application':
            return `Application ${primaryText}`;
        case 'update_identifier':
            return `Identifier ${primaryText}`;
        case 'run_now':
            return 'Trigger a job by';
        case 'update_status':
            return `Status ${primaryText}`;
        case 'update_null':
            return `Null ${primaryText}`;
        case 'update_max_length':
            return `Max Length ${primaryText}`;
        case 'update_min_length':
            return `Min Length ${primaryText}`;
        case 'update_min_value':
            return `Min Value ${primaryText}`;
        case 'update_max_value':
            return `Max Value ${primaryText}`;
        case 'update_unique':
            return `Unique ${primaryText}`;
        case 'update_blank':
            return `Blank ${primaryText}`;
        case 'update_primary':
            return `Primary ${primaryText}`;
        case 'update_tags':
            return `Tags ${primaryText}`;
        case 'create_schedule':
            return 'Asset Schedule added by';
        case 'edit_schedule':
            return `Asset Schedule ${primaryText}`;
        case 'delete_schedule':
            return `Asset Schedule has been delete by`;
        case 'update_pattern':
            return `Pattern ${primaryText}`;
        case 'update_enum':
            return `Enum ${primaryText}`;
        case 'create_measure':
            value = value && typeof (value) === "string" ? JSON.parse(value) : value;
            value = value ?? {};
            const query = value?.query ? ` ${value?.query}` : "";
            return `measure has been created with query${query} by`;
        case 'edit_measure':
            const subType = data?.sub_type ?? "";
            value = value && typeof (value) === "string" ? JSON.parse(value) : value;
            value = value ?? {};
            if (subType) {
                let propertyName = "";
                switch (subType) {
                    case "is_active":
                        propertyName = "Active Flag";
                        break;
                    case "is_auto":
                        propertyName = "Manual Threshold";
                        break;
                    case "allow_score":
                        propertyName = "Scoring";
                        break;
                    case "is_drift_enabled":
                        propertyName = "Monitoring";
                        break;
                    case "is_positive":
                        propertyName = "Polarity";
                        break;
                    case "status":
                        propertyName = "Status";
                        break;
                    case "properties":
                        propertyName = "Query";
                        if ((measureCategory === "behavioral" || measureCategory === "comparison") && typeof (value) === "object") {
                            value.current_value = JSON.stringify(value?.current_value ?? "");
                            value.prev_value = JSON.stringify(value?.prev_value ?? "");
                        }
                        break;
                    case "override_limit_config":
                        propertyName = "Override Limit Config";
                        break;
                    case "override_limit_row_count":
                        propertyName = "Override Row Limit";
                        if (typeof (value) === "object") {
                            value.current_value = JSON.stringify(value?.current_value ?? "");
                            value.prev_value = JSON.stringify(value?.prev_value ?? "");
                        }
                        break;
                    case "override_limit_column_count":
                        propertyName = "Override Column Limit";
                        if (typeof (value) === "object") {
                            value.current_value = JSON.stringify(value?.current_value ?? "");
                            value.prev_value = JSON.stringify(value?.prev_value ?? "");
                        }
                        break;
                    case "failed_rows_query_config":
                    case "total_records_query_config":
                        propertyName = `${subType === "failed_rows_query_config" ? "Failed Rows" : "Total Records"} Query Config`;
                        break;
                    case "failed_rows_query":
                    case "total_records_query":
                        propertyName = `${subType === "failed_rows_query" ? "Failed Rows" : "Total Records"} Query`;
                        if (typeof (value) === "object") {
                            value.current_value = JSON.stringify(value?.current_value ?? "");
                            value.prev_value = JSON.stringify(value?.prev_value ?? "");
                        }
                        break;
                    case "threshold_constraints":
                        if (typeof (value) === "object") {
                            value.current_value = value?.current_value?.query ?? "";
                            value.current_value = value?.current_value?.replace("<attribute>", "<value>");
                            value.prev_value = value?.prev_value?.query ?? "";
                            value.prev_value = value?.prev_value?.replace("<attribute>", "<value>");
                            if (value.prev_value?.length <= 0) {
                                value.prev_value = "auto threshold";
                            }
                        }
                        propertyName = "Manual Threshold Constrain";
                        break;
                    case "drift_threshold":
                        propertyName = "Threshold values";
                        break;
                    case "domains":
                        return `measure domain have been updated by`;
                    case "application":
                        return `measure application have been updated by`;
                    case "description":
                        return `measure description have been updated by`;
                    default:
                        propertyName = "";
                        break;
                }
                if (
                    subType === "override_limit_config"
                    || subType === "failed_rows_query_config"
                    || subType === "total_records_query_config"
                ) {
                    return `measure property ${propertyName} have been updated by `;
                }
                if (propertyName) {
                    return `measure property ${propertyName} updated to ${value?.current_value ?? ""} from ${value?.prev_value ?? ""} by `;
                }
            }
            return `measure properties have been updated by`;
        case 'delete_measure':
            return `measure deleted by`;
        case 'update_steward_user':
            return `Steward User ${primaryText}`;
        case 'create_conversation':
            return `conversation created by`;
        case 'update_conversation':
            return `conversation updated by`;
        case 'delete_conversation':
            return `conversation deleted by`;
        case 'reply_conversation':
            return `conversation on comment replied by`;
        case 'update_reply_conversation':
            return `conversation on comment updated by`;
        case 'delete_reply_conversation':
            return `conversation on comment deleted by`;
        case 'term_approve':
            return 'term has been approved by';
        case 'term_reject':
            return 'term has been rejected by';
        case 'term_delete':
            return 'term has been removed by';
        case 'update_term':
            return `term ${primaryText}`;
        case 'create_usage':
            return `usage created by`;
        case 'update_usage':
            return `usage updated by`;
        case 'delete_usage':
            return `usage deleted by`;
        case 'primary_columns':
            return `primary_columns ${primaryText}`;
        case 'run_semantic_discovery':
            return 'Trigger a Semantic Discovery by';
        case 'import_metadata':
            return `File has been imported metadata by`;
        case 'import_measure':
            return `File has been imported measure by`;
        case 'reverse_deprecated':
            return `Previously deprecated asset reinstated: Reconfigured in source connection.`;
        case 'Deprecated':
            return `Asset has been deprecated in the source connection`;
        case 'edit_depth':
            return `Depth has been updated`;
        default:
            return "";
    }
};

/**
 * Prepare User Preference Update Data
 * @param {*} user
 * @param {*} sourceKey
 * @param {*} key
 * @param {*} value
 * @returns
 */
export const prepareUpdateUserPreference = (preference, sourceKey, key, value) => {
    const userPreference = preference ? _.cloneDeep(preference) : {};
    if (!userPreference[sourceKey]) {
        userPreference[sourceKey] = {};
    }
    if (!userPreference[sourceKey][key]) {
        userPreference[sourceKey][key] = {};
    }
    userPreference[sourceKey][key] = {
        ...userPreference[sourceKey][key],
        ...value
    };
    const user = getUser();
    user.user_preference = userPreference;
    localStorage.setItem(appConstants.localStorageKey, JSON.stringify(user));
    return userPreference;
};

/**
 * Get User Preference
 * @param {*} preference
 * @param {*} sourceKey
 * @param {*} key
 * @returns
 */
export const getUserPreference = (preference, sourceKey, key, type) => {
    const userPreference = preference ? _.cloneDeep(preference) : {};
    const sourcePreference = userPreference[sourceKey] ?? {};
    const userKeyPreference = sourcePreference && sourcePreference[key];
    return userKeyPreference && userKeyPreference[type];
};

/**
 * Get Duration Text
 * @param {*} duration
 * @returns
 */
export const getDurationText = (duration, defaultNa = true) => {
    let durationText = defaultNa ? "NA" : "";
    if (!duration) {
        return durationText;
    }

    const durationValues = [];
    const durationValue = moment.duration(duration, "seconds");
    const days = durationValue.days();
    const hour = durationValue.hours();
    const minute = durationValue.minutes();
    const seconds = durationValue.seconds();
    const milliseconds = durationValue.milliseconds();
    if (days) {
        durationValues.push(`${days}day${days > 1 ? "s" : ""}`);
    }
    if (hour) {
        durationValues.push(`${hour}hour${hour > 1 ? "s" : ""}`);
    }
    if (minute) {
        durationValues.push(`${minute}min${minute > 1 ? "s" : ""}`);
    }
    if (seconds) {
        durationValues.push(`${seconds}sec${seconds > 1 ? "s" : ""}`);
    }
    if (duration < 1 && milliseconds > 0) {
        durationValues.push(`${parseInt(milliseconds)}ms`);
    }
    durationText = durationValues.length > 0 ? durationValues.join(" ") : "NA";
    if (durationText === "NA") {
        durationText = defaultNa ? "NA" : "";
    }
    return durationText;
};

/**
 * Convert Image Url to Base64
 * @param {*} url
 * @param {*} callBack
 */
export const imageToBase64 = (url, callBack) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        context.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg');
        callBack(dataURL);
    };
    image.src = url;
};

/**
 * Validate Pattern
 * @param {*} value
 * @return Boolean
 */
export const validatePattern = (value) => {
    if (value) {
        try {
            const regex = RegExp(value);
            regex.test("pattern");
            return true;
        } catch (error) {
            return false;
        }
    }
    return true;
};


/**
 * get approval status
 * @param {*} publishPermission
 * @param {*} approvePermission
 */
export const getApprovalStatus = (publishPermission, approvePermission, status) => {
    let options = [...appConstants.status];

    if (publishPermission?.is_edit) {
        options.splice(2, 1);
    }
    if (approvePermission?.is_edit) {
        options = [...appConstants.status];
    }
    if (!publishPermission?.is_edit && !approvePermission?.is_edit) {
        options = [appConstants.status[0]];
    }

    const deprecatedStatus = appConstants.status[3];
    if (status !== deprecatedStatus) {
        const index = options.indexOf(deprecatedStatus);
        if (index > -1) {
            options.splice(index, 1);
        }
    }
    return options;
};

/**
 * Check Domain Avilable for Extension
 */
export const getExtensionDomain = () => {
    return localStorage.getItem('extension_domain');
};


/**
 * Set Domain for Extension
 */
export const setExtensionDomain = (value) => {
    return localStorage.setItem('extension_domain', value);
};

/**
 * Check Asset is Relational One
 * @param {*} type
 * @returns
 */
export const isRelationalAssetType = (type) => {
    const asset_type = ['asset', 'base table', 'query', 'view', 'table', 'external table'];
    if (asset_type.indexOf(type?.toLowerCase()) > -1) {
        return true;
    }
    return false;
};

/**
 * Extract Response
 * @param {*} response
 * @returns
 */
export const extractResponse = (response) => {
    if ((response.data instanceof Object) && "message" in response.data) {
        const data = response.data;
        delete data.message;
        response.data = "data" in data ? data.data : data;
        let more_data = {};
        if ("data" in data) {
            delete data.data;
            more_data = { ...data };
        }
        response.more_data = more_data;
    }
    return response;
};

/**
 * Helper Function for semanticsBreadcrumb
 * @param {*} nestedArray
 * @param {*} selId
 * @returns
 */
function createBreadcrumb(nestedArray, selId) {
    const breadcrumb = [];
    function traverseArray(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === selId) {
                breadcrumb.push({ id: arr[i].id, name: arr[i].name, glossary_id: arr[i].glossary_id, type: arr[i].type || "category" });
                return true;
            } else if (arr[i].children && arr[i].children.length > 0) {
                breadcrumb.push({ id: arr[i].id, name: arr[i].name, glossary_id: arr[i].glossary_id, type: arr[i].type || "category" });
                if (traverseArray(arr[i].children)) {
                    return true;
                }
                breadcrumb.pop();

            }
        }
        return false;
    }

    traverseArray(nestedArray);
    return breadcrumb;
}

/**
 * Create Semantic Breadcrumb
 * @param {*} tree
 * @param {*} glossary
 * @param {*} categories
 * @param {*} terms
 * @returns
 */
export const semanticsBreadcrumb = (tree, glossary, cid, terms) => {
    const selectedDomain = tree.find((item) => item.id === glossary.id);
    if (selectedDomain) {
        const category = selectedDomain.category && typeof (selectedDomain.category) === "string" ? JSON.parse(selectedDomain.category) : [];
        const term = selectedDomain.term && typeof (selectedDomain.term) === "string" ? JSON.parse(selectedDomain.term).map((obj) => {
            return {
                ...obj,
                type: "term"
            };
        }) : [];
        const categoryTerm = term.filter((obj) => obj.parent_id);
        const fullData = [...category, ...categoryTerm];
        const nestedArray = [];
        const createNestedArray = (array, parentId) => {
            const nestedChildren = array
                .filter((selectedDomain) => selectedDomain.parent_id === parentId)
                .map((selectedDomain) => {
                    return {
                        ...selectedDomain,
                        children: createNestedArray(array, selectedDomain.id)
                    };
                });


            return nestedChildren;
        };
        nestedArray.push(...createNestedArray(fullData, selectedDomain.id));
        let selectedId = "";
        if (terms?.id) {
            selectedId = terms.id;
        } else if (cid) {
            selectedId = cid;
        } else {
            selectedId = glossary.id;
        }
        const breadcrumb = createBreadcrumb(nestedArray, selectedId);
        breadcrumb.unshift({ id: glossary.id, name: glossary.name, type: "glossary" });
        breadcrumb.unshift({ name: "Semantics", type: "root" });
        return breadcrumb;
    }
    return [];
};


export const isNumeric = (derivedType) => {
    derivedType = derivedType?.toLowerCase() ?? "text";
    const index = appConstants.datatypes.numeric.indexOf(derivedType);
    return (index >= 0);
};

/**
 * Quadrant Score Validation
 * @param {*} scoreList
 * @param {*} score
 * @param {*} index
 * @returns
 */
export const quadrantScoreValidation = (scoreList = [], score, index, validation = false) => {
    if (score) {
        if ((!score.to && score.to !== 0) || (!score.from && score.from !== 0)) {
            return validation || ("Min or Max value cannot be empty");
        } else if (score.to < score.from) {
            return validation || ("Min Value is greater than Max value");
            /*
             * } else if (scoreList.filter((elem, i) => i !== index).some((elem) => ((elem.from <= score.to && score.to <= elem.to) || (elem.from <= score.from && score.from <= elem.to)))) {
             *     return validation || ("Max or Min Value intersect with other quadrants");
             */
        } else if (score.to >= scoreList[index + 1]?.to) {
            return validation || ("Value should not exceed next quadrant");
        } else if (scoreList.filter((elem, i) => i !== index).some((elem) => elem.color === score.color)) {
            return validation || ("Color intersect with other quadrants");
        }
    }
    /*
     * else {
     *     if (scoreList.reduce((accumulator, elem) => accumulator + (((elem.to - (elem.from ? elem.from - 1 : elem.from))) / 100), 0) !== 1) {
     *         return validation || ("The values should be between 0 and 100 and should not skip any values");
     *     }
     * }
     */
    return null;
};


/**
 * Get drilldown data for short & long pattern
 * @param {*} metadata
 * @param {*} key
 * @returns
 */
export const getPatternDrilldownData = (metadata, shortPatterns) => {
    const inputMetadata = JSON.parse(JSON.stringify(metadata));
    let distributions = inputMetadata?.value_distribution?.length > 0 ? inputMetadata.value_distribution : [];
    distributions = inputMetadata?.universal_patterns?.length > 0 ? inputMetadata.universal_patterns : distributions;
    for (const pattern of shortPatterns) {
        const drilldownData = [];
        let selectedPatterns = distributions?.filter((item) => (item.short_universal_pattern === pattern.name) || (item.pattern === pattern.name));
        if (selectedPatterns?.length > 0) {
            selectedPatterns = _.groupBy(selectedPatterns, (item) => item.universal_pattern || item.pattern);
            const keys = selectedPatterns ? Object.keys(selectedPatterns) : [];
            for (const key of keys) {
                let values = selectedPatterns[key]?.length > 0 ? selectedPatterns[key] : [];
                if (values.length === 1) {
                    values = values.filter((item) => item.name !== pattern.name);
                }
                const totalValues = values?.length || 0;
                const selectedItem = totalValues > 0 ? JSON.parse(JSON.stringify(values[0])) : null;
                let count = 0;
                if (totalValues > 1) {
                    count = _.sumBy(values, (item) => item.value_count);
                } else if (totalValues === 1) {
                    count = selectedItem?.count || 0;
                }
                if (selectedItem) {
                    const sampleValue = selectedItem?.sample_value?.length > 0 ? selectedItem.sample_value : selectedItem.name;
                    drilldownData.push({
                        ...selectedItem,
                        name: selectedItem.universal_pattern,
                        short_universal_pattern: pattern?.name,
                        value_count: count,
                        sample_value: sampleValue || "",
                        type: "universal_patterns",
                        isSubPattern: true,
                        count
                    });
                }
            }
        }
        pattern.subInputData = _.orderBy(drilldownData, ["count"], ["desc"]);
        pattern.isExpanded = Boolean(pattern.isExpanded);
    }
    return shortPatterns;
};

/**
 * Prepare Hierarchy
 * @param {*} data
 * @returns
 */
export const prepareHierarchy = (hierarchy) => {
    const hirerchyData = JSON.parse(JSON.stringify(hierarchy));
    if (hirerchyData && hirerchyData[0]?.children?.length) {
        let childrenData = hirerchyData[0].children.map((data) => {
            const children = data?.children?.filter((data) => data)?.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                )));
            return {
                ...data,
                children: children || []
            };
        });
        const category = childrenData.filter((data) => data.type === "category");
        const domains = childrenData.filter((data) => data.type === "domain");
        childrenData = domains.map((data) => {
            const children = [...data.children].filter((data) => data);
            const createNestedArray = (array, parentId) => {
                const nestedChildren = array
                    .filter((item) => item.parent_id === parentId)
                    .map((item) => {
                        const children = item?.children ?? [].filter((data) => data);
                        const childrenData = [...children, ...createNestedArray(array, item.id)];
                        const category = [...childrenData].filter((obj) => obj.type === "category");
                        return {
                            ...item,
                            alerts: item.alerts || category.reduce((r, c) => r + c.alerts, 0),
                            issues: item.issues || category.reduce((r, c) => r + c.issues, 0),
                            children: [...childrenData],
                            widget_score: item.widget_score || category.reduce((r, c) => r + c.widget_score, 0) / category.length,
                            count: item.count || category.reduce((r, c) => r + c.count, 0)
                        };
                    });
                return nestedChildren;
            };
            const nestedChilren = [...children, ...createNestedArray(category, data.id)].filter((data) => data).filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                )));
            const categoryData = nestedChilren.filter((obj) => obj.type === "category");
            return {
                ...data,
                alerts: data.alerts || categoryData.reduce((r, c) => r + c.alerts, 0),
                issues: data.issues || categoryData.reduce((r, c) => r + c.issues, 0),
                children: [...nestedChilren],
                widget_score: data.widget_score || categoryData.reduce((r, c) => r + c.widget_score, 0) / categoryData.length,
                count: data.count || categoryData.reduce((r, c) => r + c.count, 0)
            };
        });
        hirerchyData[0].children = childrenData;
    }
    return hirerchyData;
};

/**
 * Get Measure colors
 * @param {*} num
 * @param {*} digits
 * @returns
 */
export const getMeasureColors = (status) => {
    let color = palette.greyText;
    switch (status.toLowerCase()) {
        case 'is_auto':
            color = palette.colorThemes.isAuto;
            break;
        case 'behavioral':
            color = palette.colorThemes.behavioral;
            break;
        case 'comparison':
            color = palette.colorThemes.comparison;
            break;
        case 'conditional':
            color = palette.colorThemes.conditional;
            break;
        case 'query':
            color = palette.colorThemes.query;
            break;
        default:
            color = palette.greyText;
            break;
    }
    return color;
};

/**
 * Validate Emails
 */
export const validateEmails = (data, isArray = false) => {
    let valid = true;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let emails = data;
    if (!isArray) {
        emails = data.replace(/\s/g, '').split(",");
    }

    for (let i = 0; i < emails.length; i++) {
        if (emails[i] === "" || !regex.test(emails[i])) {
            return valid = false;
        }
    }
    return valid;
};


/**
 * Check Is the value matches "00:00" time format
 * @param {*} value
 * @returns
 */
export const isValidTimeFormat = (value) => {
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    return timeRegex.test(value);
};


/**
 * Format the decimal values
 * @param {* Values to be formatted} value
 * @param {* No.of decimal places to format} decimailCount
 * @returns The formatted value
 */
export const formatDecimalValue = (value, decimalCount = 1) => {
    let formattedValue = value.toFixed(decimalCount);
    const values = formattedValue.split(".");
    const decimalValue = values?.length === 2 ? values[values.length - 1] : "";
    const parsedValue = decimalValue ? parseInt(decimalValue) : 0;
    if (parsedValue <= 0) {
        formattedValue = values[0];
    }
    return formattedValue;
};

/**
 * Sort the given list
 * @param {The list to be sorted} list
 * @param {The sort by column name} sortBy
 * @param {The order by type} orderBy
 * @returns
 */
export const orderList = (list, sortBy, orderBy) => {
    list = list.sort((a, b) => {
        let sortOrder = 1;
        if (orderBy === "desc") {
            sortOrder = -1;
        }
        const property = sortBy;
        let previousValue = a[property] ?? "";
        let currentValue = b[property] ?? "";
        if (typeof a[property] === "string") {
            previousValue = a[property]?.toLowerCase() ?? "";
            currentValue = b[property]?.toLowerCase() ?? "";
        }
        if (previousValue < currentValue) {
            return -1 * sortOrder;
        } else if (previousValue > currentValue) {
            return Number(sortOrder);
        }
        return 0 * sortOrder;
    });
    return list;
};

/**
 * To Data URL
 * @param {*} url
 * @param {*} callBackFunc
 */
export const toDataUrl = (url, callBackFunc) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callBackFunc(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};


/**
 * Get the width of the input text
 */
export const getTextWidth = (inputText, minDefaultWidth) => {
    const font = "15px Lato,sans-serif";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    let width = context.measureText(inputText).width;
    width = Math.ceil(width);
    if (minDefaultWidth) {
        width = width > minDefaultWidth ? width : minDefaultWidth;
        const defaultWidth = 80;
        width = (width - defaultWidth) > minDefaultWidth ? (width - defaultWidth) : minDefaultWidth;
    }
    return width;
};

/**
 * Parse the csv data and detect the delimiter
 * @param {*} data
 * @returns the delimiter
 */
export const getDelimiter = (data) => {
    const defaultDelimiters = [",", ";", "\t", "|"];
    const delimiterCount = {};
    for (const delimiter of defaultDelimiters) {
        let columns = data.split(delimiter);
        if (columns?.length === 1) {
            columns = [];
        }
        columns = columns.filter((column) => column?.length > 0);
        const count = columns?.length || 0;
        if (count > 0) {
            delimiterCount[delimiter] = count;
        }
    }
    const keys = Object.keys(delimiterCount);
    let selectedDelimiter = defaultDelimiters[0];
    if (keys?.length > 0) {
        selectedDelimiter = keys.reduce((a, b) => { return delimiterCount[a] > delimiterCount[b] ? a : b; });
    }
    selectedDelimiter = selectedDelimiter || ',';
    return selectedDelimiter;
};

/**
 * Get columns list for the given file
 * @param {*} file
 * @param {*} config
 */
export const getFileColumns = (file, config) => {
    const fileName = file.name;
    let columns = [];
    let delimiter = '';
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (
            fileName.toLowerCase().endsWith("csv")
            || fileName.toLowerCase().endsWith("tsv")
            || fileName.toLowerCase().endsWith("txt")
        ) {
            try {
                fileReader.readAsText(file);
                fileReader.onload = ({ target }) => {
                    let data = target?.result;
                    if (!data) {
                        return;
                    }
                    data = data.replace('\r', '').split('\n');
                    const firstLine = data?.length > 0 ? data[0] : "";
                    delimiter = getDelimiter(firstLine);
                    delimiter = delimiter || config.delimiter;
                    delimiter = delimiter || ',';
                    columns = firstLine.split(delimiter);
                    columns.forEach((column) => column.trim());
                    columns = columns.filter((column) => column?.length > 0);
                    if (delimiter === '\t') {
                        delimiter = "tab";
                    } else if (delimiter === ' ') {
                        delimiter = "space";
                    }
                    resolve({ columns, delimiter });
                };
            } catch (error) {
                reject(error);
            }
        } else if (fileName.toLowerCase().endsWith("xlsx")) {
            try {
                fileReader.readAsBinaryString(file);
                fileReader.onload = ({ target }) => {
                    const data = target?.result;
                    if (!data) {
                        return;
                    }
                    const workbook = XLSX.read(data, { type: "binary" });
                    if (workbook.SheetNames?.length > 0) {
                        const sheet = workbook.SheetNames[0];
                        const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { header: 1 });
                        columns = rowObject?.length > 0 ? rowObject[0] : [];
                    }
                    columns = columns.filter((column) => column?.length > 0);
                    resolve({ columns, delimiter });
                };
            } catch (error) {
                reject(error);
            }
        }
    });
};

/**
 * Get Pdf Report Font
 * @param {*} font
 */
export const getPdfFont = (font) => {
    if (font && font.includes(",")) {
        font = font.split(",")[0].trim();
    }
    return font;
};

/**
 * Check Is the value matches "mm/dd/yyyy " datetime format
 * @param {*} value
 * @returns
 */
export const isValidDateTimeFormat = (value) => {
    const datetimeRegex = /^(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[01])-\d{4}\s(?:[01]\d|2[0-3]):[0-5]\d$/;
    return datetimeRegex.test(value);
};