// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Tags
 * @returns
 */
async function getTags() {
  const response = await getRequest(`${urlConstants.tags.url}`);
  return response;
}

/**
 * Get Tags Detail By Id
 */
async function getTagById(id) {
  const response = await getRequest(`${urlConstants.tags.url}${id}/`);
  return response;
}


/**
 * Create Tag
 * @param {*} params
 * @returns
 */
async function createTag(params) {
  const response = await postRequest(`${urlConstants.tags.url}`, params);
  return response;
}

/**
 * update Tag
 * @param {*} tagId
 * @param {*} params
 * @returns
 */
async function updateTag(tagId, params) {
  const response = await putRequest(`${urlConstants.tags.url}${tagId}/`, params);
  return response;
}

/**
 * Delete Tag
 * @param {*} tagId
 * @returns
 */
async function deleteTag(tagId, purge) {
  const response = await deleteRequest(`${urlConstants.tags.url}${tagId}/?purge=${purge}`);
  return response;
}

/**
 * Get Sub Tags
 */
async function subTags(id) {
  const response = await getRequest(`${urlConstants.tags.url}${id}/${urlConstants.tags.subTags}`);
  return response;
}

/**
 * Get Tags Attributes
 */
async function attributes(id) {
  const response = await getRequest(`${urlConstants.tags.url}${id}/${urlConstants.tags.attributes}`);
  return response;
}

/**
 * Get Quality
 */
async function quality(params) {
  const response = await postRequest(`${urlConstants.tags.url}${urlConstants.tags.quality}`, params);
  return response;
}


/**
 * Get Tags Search
 * @returns
 */
async function getTagSearch() {
  const response = await getRequest(`${urlConstants.tags.search}`);
  return response;
}

/**
 * Update Tag Order
 * @param {*} params
 * @returns
 */
async function updateTagOrder(params) {
  const response = await postRequest(`${urlConstants.tags.updateTagOrder}`, params);
  return response;
}
/**
 * Get Tags Filter
 * @returns
 */
async function getTagsFilter() {
  const response = await getRequest(`${urlConstants.tags.filterTags}`);
  return response;
}

/**
 * Update Tag Order
 * @param {*} params
 * @returns
 */
async function updateSubTagOrder(params) {
  const response = await postRequest(`${urlConstants.tags.updateSubTagOrder}`, params);
  return response;
}

/**
 * Export All Service Functions
 */
export const tagsService = {
  getTags, getTagById, createTag, deleteTag, updateTag, subTags, attributes, quality, getTagSearch, updateTagOrder, getTagsFilter, updateSubTagOrder
};