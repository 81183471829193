import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Popover, Typography } from '@mui/material';

//  Import Components
import { MemoTextBoxComponent } from '../../../../../components';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';


function AddRole(props) {
    const { classes, open, anchorEl, handleClose, handleAddRule, currentList } = props;
    /**
     * Define State
     */
    const [roleName, setRoleName] = useState('');


    /**
     * Handle Close Event
     */
    const handleCloseEvent = () => {
        setRoleName('');
        handleClose();
    };

    /**
     * Handle Form Submit
     * @param {*} roleName
     */
    const handleFormSubmit = (roleName) => {
        handleAddRule(roleName);
        setRoleName('');
    };

    /**
     * Check Duplciate Roles
     */
    useEffect(() => {
        const isDuplicate = currentList.some((role) => role?.name?.toLowerCase() === roleName?.toLowerCase());
        ValidatorForm.addValidationRule('duplicateRole', () => {
            if (isDuplicate) {
                return false;
            } return true;
        });
    }, [roleName]);

    return (
        <Grid container>
            <Popover
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={
                    {
                        'aria-labelledby': 'basic-button'
                    }
                }
            >
                <ValidatorForm onSubmit={() => handleFormSubmit(roleName)} noValidate>
                    <Grid container spacing={4} className={classes.addRoleContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {'Add Role'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MemoTextBoxComponent
                                name="name"
                                fullWidth
                                label={"Add New Role"}
                                variant="standard"
                                size="medium"
                                value={roleName || ""}
                                validators={['required', 'duplicateRole']}
                                errorMessages={[appConstants.errorMessages.valueRequired, appConstants.errorMessages.duplicateRole]}
                                onChange={(event) => setRoleName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="mr-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={() => handleCloseEvent()}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Popover>
        </Grid>
    );
}

// Default Props
AddRole.defaultProps = {
    classes: {},
    open: false,
    anchorEl: null,
    handleClose: () => { },
    handleAddRule: () => { },
    currentList: []
};

// Prop Types
AddRole.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func,
    handleAddRule: PropTypes.func,
    currentList: PropTypes.array
};


export default AddRole;