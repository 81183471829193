import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Tooltip, Typography } from '@mui/material';

//  Import Styles
import DbtInfoStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import TalendDatasets from './talendDatasets.jsx';
import TalendRuns from './talendRuns.jsx';
import TalendTasks from './talendTasks.jsx';


function TalendInformationComponent(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;
    const infoContainerRef = useRef();
    const tabs = [
        { label: "Datasets", description: "" },
        { label: "Runs", description: "" },
        { label: "Tasks", description: "" }
    ];

    /**
     * Define State
     */
    const [template, setTemplate] = useState("Datasets");

    /**
     * Scroll Page Based on Tab Selection
     */
    const scrollPage = () => {
        if (infoContainerRef && infoContainerRef.current) {
            infoContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
        }
    };

    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }
        setTemplate(value);
        scrollPage();
    };


    /**
     * Get Dbt Info
     */
    const bindTabs = () => {
        switch (template) {
            case "Runs":
                return <TalendRuns data={data?.runs ?? []} />;
            case "Tasks":
                return <TalendTasks data={data?.tasks ?? []} />;
            default:
                return <TalendDatasets data={data?.datasets ?? []} />;
        }
    };

    // Get Selected Filter
    const selectedFilter = tabs.find((item) => item.label === template);


    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
                <Grid item className={classes.filter}>
                    <Typography variant="body1" className={classes.textSecondary}>
                        {selectedFilter?.description || ""}
                    </Typography>
                </Grid>
                <ToggleButtonGroup
                    color="secondary"
                    value={template || 'All'}
                    exclusive
                    aria-label="text alignment"
                    onChange={(event, value) => handleFilterOnChange(value)}
                >
                    {
                        tabs.map((data, index) =>
                            <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                <Tooltip title={data.label} arrow>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {data.label}
                                    </Typography>
                                </Tooltip>
                            </ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} className={classes.infoSection} ref={infoContainerRef} >
                {bindTabs()}
            </Grid>
        </Grid>
    );
}

// default props
TalendInformationComponent.defaultProps = {
    classes: {},
    data: {}
};

// prop types
TalendInformationComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object
};


/**
 * Export Component
 */
export default withStyles(
    (theme) => ({
        ...DbtInfoStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TalendInformationComponent);