// Default Imports
import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';

// Import Styles
import style from "./style.jsx";

// Import Helpers
import { isFloat } from '../../helpers/appHelpers.js';

const PolarChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, color, labelFormatType, showPercentage } = props;

    /**
     * Prepare Chart Data
     * @param {*} data
     * @returns
     */
    const prepareChartData = (data) => {
        if ((data instanceof Object)) {
            data = Object.keys(data).map((obj) => {
                return {
                    category: obj,
                    value: data[obj]
                };
            });
        }
        return data;
    };


    const chartData = useMemo(() => prepareChartData(data), [data]);

    /**
     * Get Percentage
     * @param {*} value
     * @returns
     */
    const getPercentage = (value) => {
        const totalCount = chartData.reduce((a, b) => a + b.value, 0);
        value = (value / totalCount) * 100;
        return `${value.toFixed(2)}`;
    };


    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        angleAxis: {
            show: false,
            max: Math.max(...chartData.map((obj) => obj.value))
        },
        grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 50
        },
        radiusAxis: {
            type: 'category',
            z: 3,
            show: false,
            axisLine: {
                show: false
            }
        },
        polar: {
            radius: [30, '100%']
        },
        series: chartData.map((obj, index) => {
            return {
                type: 'bar',
                data: [obj.value],
                coordinateSystem: 'polar',
                name: obj.category,
                barWidth: 20,
                label: {
                    show: true,
                    position: 'insideStart',
                    fontSize: 10,
                    rotate: -0.1,
                    formatter: (params) => {
                        const data = isFloat(params.value) ? params.value.toFixed(1) : params.value;
                        const value = labelFormatType === "percentage" ? getPercentage(data) : data;
                        return showPercentage ? `${value}%` : value;
                    }
                },
                color: color[index],
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            };
        }),
        legend: {
            top: "bottom",
            padding: [15, 0, 0, 5],
            itemGap: 25,
            itemWidth: 20,
            itemHeight: 10,
            textStyle: {
                fontSize: 14,
                fontFamily: palette.headers.body1.fontFamily,
                color: appConstants.chartColors.labelColor
            },
            type: 'scroll'
        },
        tooltip: {
            formatter: (params) => {
                return labelFormatType === "percentage" ? `${params.marker} ${params.seriesName} <br/> Count: ${params.value} <br/>
                Percentage : ${getPercentage(params.value)}` : `${params.marker} ${params.seriesName} : ${params.value}`;
            }
        }
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={{ height: '100%' }}
        />
    );
};


/**
 * Define Prop Types
 */
PolarChartComponent.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    color: PropTypes.array,
    labelFormatType: PropTypes.string,
    showPercentage: PropTypes.bool
};

/**
 * Set Default Values
 */
PolarChartComponent.defaultProps = {
    data: [],
    color: [],
    labelFormatType: "value",
    showPercentage: true
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(PolarChartComponent, areEqual));