// Import API Helper
import { getRequest, postRequest, putRequest, deleteRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Usage
 * @returns
 */
async function get_usage(id) {
  const response = await getRequest(`${urlConstants.usage.url}?asset_id=${id}`);
  return response;
}

/**
 * Run Query
 * @param {*} params
 * @returns
 */
async function run_query(params) {
  const response = await postRequest(`${urlConstants.usage.run_query}`, params);
  return response;
}

/**
 * Create
 * @param {*} params
 * @returns
 */
async function create(params) {
  const response = await postRequest(`${urlConstants.usage.url}`, params);
  return response;
}

/**
 * update
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
  const response = await putRequest(`${urlConstants.usage.url}${id}/`, params);
  return response;
}

/**
 * Delete
 * @param {*} id
 * @returns
 */
async function remove(id) {
  const response = await deleteRequest(`${urlConstants.usage.url}${id}/`);
  return response;
}

/**
 * Get Query History
 * @param {*} params
 * @returns
 */
async function query_history(params) {
  const response = await postRequest(`${urlConstants.usage.query_history}`, params);
  return response;
}


/**
 * Export All Service Functions
 */
export const usageService = {
  get_usage, run_query, create, update, remove, query_history
};