const style = (theme) => ({
    attributeBlock: {
        alignItems: 'center'
    },
    derivedType: {
        color: `${theme.palette.secondary.main} !important`,
        border: `1px solid ${theme.palette.greyshades.darkgrey}40`,
        background: '#ffffff',
        fontSize: 12,
        fontWeight: '400',
        width: 20,
        height: 20,
        textAlign: 'center',
        lineHeight: '18px',
        textTransform: 'capitalize'
    }
});
export default style;