import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import { Button, Grid, Typography } from '@mui/material';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import EmailIcon from '../../../assets/img/apps/teams.png';
import { MemoTextBoxComponent, LoaderButtonComponent } from '../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import appConstants from '../../../constants/appConstants.js';

import { getConfigurationRequest, createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';

import { checkPermission } from '../../../helpers/index';
import featureConstants from '../../../constants/featureConstants.js';

function Teams(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const navigate = useNavigate();

    /**
     * Define State
     */
    const defaultConfiguration = {
        webhook_url: ""
    };
    const [checkChanges, setCheckChanges] = useState(false);
    const [formData, setFormData] = useState({ ...defaultConfiguration });

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };

    /**
     * On Save
     */
    const onSave = () => {
        const config = {
            ...formData
        };
        const requestParams = {
            config,
            channel: channel.id,
            id: channelId,
            channelName: "teams"
        };
        if (!channelId) {
            dispatch(createConfigrationRequest(requestParams));
        } else {
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration Detail
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);

     /**
      * Set FormData
      */
     useEffect(() => {
        if (configuration && channelId) {
            const config = { ...configuration };
            setFormData({ ...config });
        }
    }, [configuration]);


    return (
        <ValidatorForm noValidate onSubmit={() => onSave()}>
            <Grid className={classes.pageContainer}>
                <Grid container spacing={6} item xs={12} className={classes.pageInnerBody}>
                    <Grid item xs={12} className={classes.header}>
                        <img src={EmailIcon} className={classes.appLogo} alt="app logo" />
                        <Grid className="pl-3">
                            <Typography variant="h5">
                                Microsoft Teams connection Settings
                            </Typography>
                            <Typography variant="body1">
                                {appConstants.labels.appIntegration.selectChannel}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            We will send alerts to WebHook channel in your Microsoft Teams.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={5} className="dflex alignCenter">
                            <Grid className="w-100">
                                {/* <Typography variant="body1">
                                    {appConstants.labels.appIntegration.selectTeams}
                                </Typography> */}

                                <MemoTextBoxComponent
                                    name="webhook_url"
                                    value={formData.webhook_url || ""}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Web Hook URL
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    errorMessages={[`Web hook url ${appConstants.errorMessages.requriedField}`]}
                                    disabled={!integrationPermission?.is_edit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button
                            variant="outlined"
                            size="small"
                            className="mr-2"
                            onClick={() => navigate(-1)}
                        >
                            {appConstants.labels.connector.Cancel}
                        </Button>
                        <LoaderButtonComponent
                            size="small"
                            type="submit"
                            disabled={!checkChanges}
                            isLoading={saveLoading}
                        >
                            {'Save'}
                        </LoaderButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
Teams.defaultProps = {
    classes: {}
};

// prop types
Teams.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Teams);