import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

//  Import Components
import { Button, Grid, Rating, Typography } from '@mui/material';
import { CircularScoreComponent, StatusDropDownComponent } from '../../../../../components/index.js';

// Import Images
import { TableIcon } from '../../../../../assets/svg/index.js';

//Constants
import appConstants from '../../../../../constants/appConstants.js';

function PreviewTheme(props) {

    /**
     * Redux selector function to get configurations detail from redux store
     */
    // const { theme: { theme: palette } } = useSelector((state) => state.configurations);
    const { theme } = props;

    /**
     * Crete Theme Based For Preview
     */
    const styles = makeStyles({
        previewContainer: {
            border: `1px solid ${theme?.greyshades?.grey ?? '#E5E7EB'}`,
            padding: '40px 30px',
            background: theme?.greyshades?.lightbackground ?? '#F5F7F8'
        },
        previewContainerInner: {
            background: '#fff',
            padding: '24px',
            // display: 'flex',
            alignItems: 'center',
            justifyContent: 'spcae-between',
            minHeight: 500,
            '& .MuiTypography-h4': {
                fontFamily: theme?.headers?.h4?.fontFamily ?? 'Poppins, sans-serif',
                fontSize: theme?.headers?.h4?.size ?? '30',
                fontWeight: theme?.headers?.h4?.weight ?? '500',
                fontStyle: theme?.headers?.h4?.style ?? 'normal',
                textTransform: theme?.headers?.h4?.transform ?? 'unset',
                textDecoration: theme?.headers?.h4?.textDecoration ?? 'auto',
                color: theme?.headers?.h4?.color ?? '#2E384D'
            },
            '& .MuiTypography-h6': {
                fontFamily: theme?.headers?.h6?.fontFamily ?? 'Poppins, sans-serif"',
                fontSize: theme?.headers?.h6?.size ?? '16',
                fontWeight: theme?.headers?.h6?.weight ?? '500',
                fontStyle: theme?.headers?.h6?.style ?? 'normal',
                textTransform: theme?.headers?.h6?.transform ?? 'unset',
                textDecoration: theme?.headers?.h6?.textDecoration ?? 'auto',
                color: theme?.headers?.h6?.color ?? '#2E384D'
            },
            '& .MuiTypography-body1': {
                fontFamily: theme?.headers?.body1?.fontFamily ?? 'Roboto, sans-serif',
                fontSize: theme?.headers?.body1?.size ?? '14',
                fontWeight: theme?.headers?.body1?.weight ?? '400',
                fontStyle: theme?.headers?.body1?.style ?? 'normal',
                textTransform: theme?.headers?.body1?.transform ?? 'unset',
                textDecoration: theme?.headers?.body1?.textDecoration ?? 'auto',
                color: theme?.headers?.body1?.color ?? '#2E384D'
            },
            '& .MuiChip-root': {
                borderRadius: '4px !important'
            },
            '& .TableIcon path': {
                fill: theme?.colors?.secondary?.main ?? "#64AAEF"
            },
            '& .MuiButton-contained.MuiButton-containedPrimary': {
                backgroundColor: theme?.colors?.primary?.main ?? "#F38080",
                fontWeight: theme?.buttons?.primaryButton?.weight ?? '500',
                fontSize: theme?.buttons?.primaryButton?.size ?? '15',
                fontStyle: theme?.buttons?.primaryButton?.style ?? 'normal',
                textTransform: theme?.buttons?.primaryButton?.transform ?? 'unset',
                textDecoration: theme?.buttons?.primaryButton?.textDecoration ?? 'auto'
            },
            '& .MuiButton-outlined.MuiButton-outlinedPrimary': {
                color: theme?.colors?.primary?.main ?? "#F38080",
                border: `1px solid ${theme?.colors?.primary?.main ?? "#F38080"}`,
                fontWeight: theme?.buttons?.primaryButton?.weight ?? '500',
                fontSize: theme?.buttons?.primaryButton?.size ?? '15',
                fontStyle: theme?.buttons?.primaryButton?.style ?? 'normal',
                textTransform: theme?.buttons?.primaryButton?.transform ?? 'unset',
                textDecoration: theme?.buttons?.primaryButton?.textDecoration ?? 'auto'
            }
        },
        prevDetailText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical'
        },
        textSecondary: {
            color: `${theme?.greyshades?.darkgrey ?? '#B0BAC9'} !important`
        }
    });

    const classes = styles();

    return (
        <Grid className={classes.previewThemeContainer}>
            <Typography variant="h6" className="fw-500 pb-15">
                {appConstants.labels.theme.previewTheme}
            </Typography>
            <Grid className={classes.previewContainer}>
                <Grid className={classes.previewContainerInner}>
                    <Grid className="dflex alignCenter spaceBetween">
                        <Grid className="dflex alignCenter">
                            <TableIcon />
                            <Typography variant="h6"
                                className="pl5 pr-2">
                                PersonDataset
                            </Typography>
                            <Grid className="dflex alignCenter pr-2">
                                <Grid item className="starIcon dflex">
                                    <Rating
                                        className={classes.starIcon}
                                        name="rating-preview"
                                        max={1}
                                        defaultValue={4.3}
                                        precision={0.5}
                                        size="small"
                                        readOnly
                                    />
                                </Grid>
                                <Typography variant="body1" className="pl5">
                                    {4.3}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid>
                            <StatusDropDownComponent value={"verified"} dropdownValue={appConstants.status} />
                        </Grid>
                    </Grid>
                    <Typography variant="body1" className={`${classes.prevDetailText} ${classes.textSecondary} pt-1`}>
                        This is a sample section to preview the style you have built. Hope you like what you see and apply.
                    </Typography>
                    <Grid className="dflex alignCenter spaceBetween pt-2">
                        <Grid className="dflex alignCenter">
                            <Typography variant="body1">
                                Total Rows:
                            </Typography>
                            <Typography variant="body1" className="pl5">
                                1686
                            </Typography>
                        </Grid>
                        <Grid className="dflex alignCenter">
                            <Typography variant="body1">
                                Attributes:
                            </Typography>
                            <Typography variant="body1" className="pl5">
                                36
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="dflex alignCenter justifyCenter pt-5">
                        <Grid className="mr-2" sx={{ '& .textValue': { display: 'none' } }}>
                            <CircularScoreComponent
                                size={80}
                                value={75}
                                thickness={10}
                                noLink
                                showValue />
                        </Grid>
                        <Grid>
                            <Typography variant="h4">
                                75%
                            </Typography>
                            <Typography variant="h6" className="">
                                Overall Quality
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="dflex alignCenter justifyCenter pt-5">
                        <Button variant="outlined" color="primary" size="small">
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" disableElevation className="ml-2">
                            View Health
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
PreviewTheme.defaultProps = {
    theme: {}
};

// prop types
PreviewTheme.propTypes = {
    theme: PropTypes.object
};

export default PreviewTheme;