const style = () => ({
    appContainer: {
        padding: '20px 0px 0px',
        '& .MuiTableCell-root': {
            '& .MuiSelect-select': {
                paddingLeft: '0px !important',
                minWidth: '65px'
            }
        }
    }
});
export default style;