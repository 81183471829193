import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getExecutionLogsRequest, getExecutionLogsSuccess, getExecutionLogsFailure, getNotificationLogsRequest, getNotificationLogsSuccess,
  getNotificationLogsFailure, updateNotificationLogsRequest, updateNotificationLogsSuccess, updateNotificationLogsFailure,
  getNotificationsStatsRequest, getNotificationsStatsSuccess, getNotificationsStatsFailure, triggerTaskRequest, triggerTaskSuccess, triggerTaskFailure,
  getDetailedLogRequest, getDetailedLogSuccess, getDetailedLogFailure
} from '../reducer/logsReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { logsService } from '../service';

/**
 * Get Execution Logs
 * @returns
 */
const getExecutionLogs = (action$) => action$.pipe(
  ofType(getExecutionLogsRequest),
  mergeMap(({ payload }) => {
    return from(logsService.get_execution_logs(payload.params, payload.token)).pipe(
      concatMap((res) => { return of(getExecutionLogsSuccess({ ...res.data, request_data: payload })); }),
      catchError(() => { return of(getExecutionLogsFailure()); })
    );
  })
);


/**
 * Get Notification Logs
 * @returns
 */
const getNotificationLogs = (action$) => action$.pipe(
  ofType(getNotificationLogsRequest),
  mergeMap(({ payload: { is_viewed, limit, offset } }) => {
    return from(logsService.get_notification_logs(is_viewed, limit, offset)).pipe(
      concatMap((res) => { return of(getNotificationLogsSuccess({ ...res, offset })); }),
      catchError((error) => { return of(getNotificationLogsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Issues
 * @param {*} action$
 * @returns
 */
const updateNotificationLogs = (action$) => action$.pipe(
  ofType(updateNotificationLogsRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(logsService.update_notification_logs(id, rest)).pipe(
      concatMap((res) => { return of(updateNotificationLogsSuccess(res.data)); }),
      catchError((error) => of(updateNotificationLogsFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Notifications Statistics Count
 * @returns
 */
const getNotificationStats = (action$) => action$.pipe(
  ofType(getNotificationsStatsRequest),
  mergeMap(({ payload }) => {
    return from(logsService.getNotificationStats(payload)).pipe(
      concatMap((res) => { return of(getNotificationsStatsSuccess(res)); }),
      catchError(() => { return of(getNotificationsStatsFailure()); })
    );
  })
);

/**
 * Trigger Task
 * @returns
 */
 const triggerTask = (action$) => action$.pipe(
  ofType(triggerTaskRequest),
  mergeMap(({ payload }) => {
    return from(logsService.triggerTask(payload)).pipe(
      concatMap((res) => { return of(triggerTaskSuccess({ ...res.data, request_data: payload })); }),
      catchError((error) => { return of(triggerTaskFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);


/**
 * Get Execution Logs
 * @returns
 */
const getDetailedLog = (action$) => action$.pipe(
  ofType(getDetailedLogRequest),
  mergeMap(({ payload }) => {
    return from(logsService.get_detailed_log(payload.taskId, payload.token)).pipe(
      concatMap((res) => { return of(getDetailedLogSuccess({ ...res.data, request_data: payload })); }),
      catchError(() => { return of(getDetailedLogFailure()); })
    );
  })
);


// Export All Logs Functions
export const logsEpic = [getExecutionLogs, getNotificationLogs, updateNotificationLogs, getNotificationStats, triggerTask, getDetailedLog];