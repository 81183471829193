const style = () => ({
    container: {
        padding: 20,
        marginTop: '-10px !important',
        '& .month-container': {
            marginBottom: 18
            // marginLeft: 12
        },
        '& .daysName': {
            marginLeft: 90
        }
    },
    cellContainer: {
        minWidth: 80
    },
    dateText: {
        flexShrink: 0,
        minWidth: 90,
        marginBottom: '16px !important'
    },
    textContainer: {
        minWidth: 85,
        // marginRight: 10,
        margin: '0 0px 14px 0px !important'
    },
    cell: {
        width: 16,
        height: 16,
        margin: 'auto',
        cursor: 'pointer',
        // marginRight: 8,
        position: 'relative',
        background: '#49D28E',
        '& .tooltip': {
            display: 'none'
        },
        '&:hover .tooltip': {
            display: 'block',
            position: 'absolute',
            borderStyle: "solid",
            whiteSpace: "nowrap",
            zIndex: 9999999,
            boxShadow: "rgb(0 0 0 / 20%) 1px 2px 10px",
            transition: "opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
            backgroundColor: "rgb(255, 255, 255)",
            borderWidth: 1,
            borderRadius: 4,
            color: "rgb(102, 102, 102)",
            padding: 10,
            top: 20,
            left: 0
        }
    },
    alertCell: {
        background: '#E7515A'
    }

});

export default style;