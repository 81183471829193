import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Import Styles
import Styles from './styles.jsx';

function DialogueLayoutComponent({ component: Component, ...props }) {

    /**
     * Define Props
     */
    const { classes } = props;
    const scrollRef = useRef(null);

    return (
        <Grid container className={classes.privateLayout}>
            <Grid item xs={12} className={classes.privateLayoutContainer} id="private" ref={scrollRef}>
                <Component scrollRef={scrollRef.current} />
            </Grid>
        </Grid>
    );
}

// prop types
DialogueLayoutComponent.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.elementType
};

export const DialogueLayout = withStyles(Styles)(DialogueLayoutComponent);