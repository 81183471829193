import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Helpers
import { getDayFilterDuration } from '../../../../../helpers/appHelpers';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';


function Historical(props) {

    const { classes, data, type } = props;
    const navigation = useNavigate();

    /**
     * Get Day Data
     * @param {*} day
     * @param {*} dates
     * @param {*} result
     * @returns
     */
    const getDayData = (date, alerts, result) => {
        const data = result.filter((obj) => obj.created_date === date);
        return alerts.map((alert) => {
            const value = data.find((obj) => obj.name === alert)?.count || 0;
            return {
                date,
                value
            };
        });
    };

    /**
     * Get WidgetData
     * @param {*} result
     * @returns
     */
    const getWidgetData = (result) => {
        let dates = [];
        for (let index = 0; index < 10; index++) {
            let date = moment();
            date = date.subtract(index, 'day').format('MMM DD YYYY');
            dates.push(date);
        }
        dates = dates.reverse();
        const alerts = appConstants.healthAlerts;
        const historyData = dates.map((date) => {
            const dayData = getDayData(date, alerts, result);
            return {
                name: date,
                data: dayData || []
            };
        });
        return historyData;
    };

    const historicalData = useMemo(() => getWidgetData(data), [data]);
    const alerts = appConstants.healthAlerts;

    /**
     * On Chart Event
     * @param {*} data
     */
    const onChartEvent = (data) => {
        const day = getDayFilterDuration(data.date);
        if (type === "issues") {
            navigation(`/home/issues?day=${day}`, { replace: true });
        } else {
            navigation(`/home/alerts?day=${day}&measure_name=${data.name}`, { replace: true });
        }
    };

    return (
        <Grid item xs={12} className={classes.container}>
            <Grid className="dflex justifyCenter daysName">
                {
                    alerts.map((alert, index) =>
                        <Typography key={`alert_${index}`} variant="body1" className={classes.textContainer} align="center" >
                            {alert}
                        </Typography>
                    )
                }
            </Grid>
            {
                historicalData.map((item, historyIndex) =>
                    <Grid key={`day_${historyIndex}`} className="dflex justifyCenter" alignItems="center">
                        <Typography variant="body2" align="center" className={classes.dateText}>
                            {item.name || ""}
                        </Typography>
                        <Grid className="dflex month-container">
                            {
                                item.data.map((data, index) =>
                                    <Grid key={`${data.date}_${index}`} className={classes.cellContainer}>
                                        <Grid onClick={() => onChartEvent({ name: alerts[index], date: data.date })} className={classNames(classes.cell, data.value > 0 ? classes.alertCell : null, historyIndex === 0 ? data.month : null)} >
                                            <Grid className="tooltip">
                                                <Typography>
                                                    {`Date  : ${data.date}`}
                                                </Typography>
                                                <Typography>
                                                    {`Count  : ${data.value}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid >
    );
}

// default props
Historical.defaultProps = {
    classes: {},
    data: [],
    type: "issues"
};

// prop types
Historical.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    type: PropTypes.string
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Historical, areEqual));