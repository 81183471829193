import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

// Import styles
import Style from './style.jsx';
import PopOverComponent from '../../../popOver/index.jsx';
import appConstants from '../../../../constants/appConstants.js';
import { TableComponent } from '../../index.js';
import { useDispatch, useSelector } from 'react-redux';
import { clearRunDetails } from '../../../../redux/reducer/scheduleReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';

function RecentRuns(props) {

    /**
     * Define Props
     */
    const { classes, data, restData } = props;

    const dispatch = useDispatch();

    const { run_details, run_details_loading } = useSelector((state) => state.schedule);

    const [anchorEl, setAnchorEl] = useState(null);

    /**
     * Render Class
     * @param {*} item
     * @returns
     */
    const renderClass = (item) => {
        if (item.completed < item.total) {
            return classes.incomplete;
        } return classes.complete;
    };

    /**
     * Get Run Details Based on Queue ID
     * @param {*} event
     * @param {*} item
     */
    const openRunDetails = (event, item) => {
        dispatch(navigate({ path: 'logs.executionLogs', state: { filter: true, item_id: item.id }, params: [restData?.connection_id] }));
        /*
         * dispatch(getRunDetailRequest(item?.id));
         * setAnchorEl(event.currentTarget);
         */
    };

    /**
     * On Clode Detail Popup
     */
    const onClosePopup = () => {
        dispatch(clearRunDetails());
        setAnchorEl(null);
    };


    return (
        <Grid container>
            {
                data && data.map((item, index) =>
                    item.total > 0 &&
                    <Grid key={index} className={classNames(classes.runColumn, restData.level === "report" ? classes.noPointer : null, renderClass(item))} onClick={restData.level === "report" ? null : (event) => openRunDetails(event, item)}>
                        {item.completed}
                    </Grid>
                )
            }
            <PopOverComponent
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClosePopup}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'center'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                }
            >
                <Grid className={classNames(classes.flexiContainer)}>
                    <TableComponent
                        styleType="striped"
                        headers={appConstants.table.latestRunTabHeader}
                        data={run_details || []}
                        NoResultText="No Data Found"
                        isLoading={run_details_loading}
                        stickyHeader
                    />
                </Grid>
            </PopOverComponent>
        </Grid>
    );
}

RecentRuns.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    restData: PropTypes.object
};

RecentRuns.defaultProps = {
    classes: {},
    data: [],
    restData: {}

};


export default withStyles(Style, { withTheme: true })(RecentRuns);