// Default Imports
import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';

// Import Helpers
import { numberWithCommas, nFormatter, dFormatter } from '../../helpers/appHelpers';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Styles
import style from "./style.jsx";

const CountChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, y_position, x_labels, key, classes, rule, ruleName, ruleCategory, chartType, highlightIndex, measureType } = props;
    const [chartInstance, setChartInstance] = useState(null);
    const isDateFormat = Boolean(rule?.has_date);

    const option = {

        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },

        /**
         * X axis data as a categorized date
         */
        xAxis: {
            type: 'category',
            boundaryGap: chartType === 'bar',
            axisLine: { onZero: true },
            data: data.map((value) => {
                let date = moment(value.created_date);
                if (ruleCategory?.toLowerCase() === "behavioral") {
                    date = date.utcOffset(0, false);
                }
                return date.format(isDateFormat ? 'MM-DD-YYYY' : 'MM-DD-YYYY hh:mm:ss');
            }),
            axisLabel: {
                formatter: (value, idx) => {
                    let date = moment(data[idx].created_date);
                    if (ruleCategory?.toLowerCase() === "behavioral") {
                        date = date.utcOffset(0, false);
                    }
                    return idx === 0
                        ? date.format(isDateFormat ? 'MM-DD-YYYY' : 'MM-DD-YYYY hh:mm:ss')
                        : date.format('MM-DD');
                },
                margin: 10
            },
            axisTick: {
                show: false
            }
        },

        /**
         * Y axis time as a continuous value
         */
        yAxis: {
            type: 'value',
            position: y_position,
            axisLabel: {
                formatter: (value) => {
                    return measureType === appConstants.labels.quality.volumeTypes[1] ? `${value} %` : `${ruleName.toLowerCase() !== 'freshness' ? nFormatter(value, ruleName) : dFormatter(value)}`;
                }
            },
            axisPointer: {
                label: {
                    formatter: (params) => {
                        return ruleName.toLowerCase() !== 'freshness' ? nFormatter(params.value, ruleName) : dFormatter(params.value);
                    }
                }
            },
            splitNumber: 3
        },

        /**
         * Set position of the canvas
         */
        grid: {
            left: 40,
            right: 30,
            top: 20,
            bottom: 10,
            containLabel: true
        },

        /**
         * Set position of the canvas
         */
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#ccc',
                    borderColor: '#aaa',
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: '#222'
                }
            },
            formatter: (params) => {
                let date = moment(data[params[2].dataIndex].created_date);
                let format = isDateFormat ? "MMM DD YYYY" : "MMM DD YYYY hh:mm A";
                let expectedText = "Expected:";
                if (ruleCategory?.toLowerCase() === "behavioral") {
                    format = isDateFormat ? "YYYY-MM-DD" : "YY-MM-DD hh:mm A";
                    date = date.utcOffset(0, false);
                }
                if (ruleCategory?.toLowerCase() === "comparison") {
                    expectedText = "Source-Target : ";
                }
                const datetext = date.format(format);
                let expectedFormat = "";
                const lower = measureType === appConstants.labels.quality.volumeTypes[1] ? data[params[2].dataIndex]?.threshold?.lt_percent : data[params[2].dataIndex]?.threshold?.lower_threshold ?? '';
                const upper = measureType === appConstants.labels.quality.volumeTypes[1] ? data[params[2].dataIndex]?.threshold?.ut_percent : data[params[2].dataIndex]?.threshold?.upper_threshold ?? '';
                if (lower && upper) {
                    if (ruleName.toLowerCase() === 'freshness') {
                        expectedFormat = `${dFormatter(lower)} - ${dFormatter(upper)}`;
                    } else {
                        expectedFormat = `${numberWithCommas(lower)} - ${numberWithCommas(upper)}`;
                    }
                }

                const alert_template = ["high", "medium", "low"].indexOf(data[params[2].dataIndex]?.status?.toLowerCase()) >= 0 ? `<div class="dflex alignCenter spaceBetween">
                        <div class="dflex alignCenter expectedBG"><div class="expectedSqure ${data[params[2].dataIndex]?.status?.toLowerCase() ?? ''}"></div>${expectedText}</div>
                        <div>${expectedFormat}</div>
                    </div>` : '';
                let value = data[params[2].dataIndex].value;
                if (ruleName.toLowerCase() !== 'freshness') {
                    value = measureType === appConstants.labels.quality.volumeTypes[1] ? data[params[2].dataIndex].percent_change : numberWithCommas(data[params[2].dataIndex].value);
                }
                return (
                    `<div class="chartToolTip">
                    <div class="chartToolTipHeader">
                        <div class="dflex alignCenter spaceBetween mb-1">
                            <div>${datetext}</div>
                            <div class="tooltipchip ${data[params[2].dataIndex]?.status?.toLowerCase() ?? ''}">${data[params[2].dataIndex]?.status ?? ''}</div>
                        </div>
                        <div class="dflex alignCenter spaceBetween mb-1">
                            <div class="dflex alignCenter"><div class="nulldot ${data[params[2].dataIndex]?.status?.toLowerCase() ?? 'ok'}"></div>
                            ${measureType === appConstants.labels.quality.volumeTypes[1] ? "Percent Change" : ruleName}: </div>
                            <div>${value}</div>
                        </div>
                        ${alert_template} 
                        </div>                  
                    </div>`
                );
            }
        },

        /**
         * Visual Representation of different color based on the difference
         */
        visualMap: {
            show: false,
            dimension: 0,
            pieces: data.map((item, index) => {
                function setColor(item) {
                    if (!item.status) {
                        return palette.colorThemes.ok;
                    }

                    return palette.colorThemes[item.status.toLowerCase()];
                }
                return {
                    gt: index - 1,
                    lte: index,
                    color: setColor(item)
                };
            }),
            outOfRange: {
                color: palette.colorThemes.ok
            }
        },

        /**
         * Series of Visual Lines
         */
        series: [
            {
                name: 'L',
                type: 'line',
                data: data.map((item) => {
                    return (measureType === appConstants.labels.quality.volumeTypes[1] ? item?.threshold?.lt_percent : item?.threshold?.lower_threshold ?? item.value);
                }),
                lineStyle: {
                    opacity: 0
                },
                stack: 'confidence-band',
                symbol: 'none'
            },
            {
                name: 'U',
                type: 'line',
                data: data.map((item) => {
                    return (measureType === appConstants.labels.quality.volumeTypes[1] ? (item?.threshold?.ut_percent - item?.threshold?.lt_percent) : (item?.threshold?.upper_threshold - item?.threshold?.lower_threshold));
                }),
                lineStyle: {
                    opacity: 0
                },
                areaStyle: {
                    color: '#CAEADA'
                },
                stack: 'confidence-band',
                symbol: 'none',
                stackStrategy: 'all'
            },
            {
                data: data.map((item) => {
                    let value = 0;
                    if (ruleName.toLowerCase() !== 'freshness') {
                        value = measureType === appConstants.labels.quality.volumeTypes[1] ? item.percent_change : item.value;
                    } else {
                        value = item.freshnessValue;
                    }
                    return value;
                }),
                type: chartType,
                symbolSize: (value, item) => {
                    const obj = data[item?.dataIndex];
                    switch (obj?.status?.toLowerCase()) {
                        case 'high':
                            return 9;
                        case 'medium':
                            return 8;
                        case 'low':
                            return 7;
                        default:
                            return 6;
                    }
                }
            }
        ]
    };
    if (x_labels === "all") {
        option.xAxis[0].axisLabel = {
            interval: 0
        };
    }

    if (ruleCategory?.toLowerCase() === "comparison") {
        option.series = [
            {
                name: 'Target',
                type: 'line',
                data: data.map((item) => {
                    return (item?.threshold?.lower_threshold ?? item.value);
                }),
                lineStyle: {
                    type: "dotted",
                    color: "#B0BAC9",
                    opacity: 80,
                    width: 1
                },
                symbol: 'none'
            },
            {
                name: 'Source',
                type: 'line',
                data: data.map((item) => {
                    return (item?.threshold?.upper_threshold);
                }),
                lineStyle: {
                    type: "dotted",
                    color: "#B0BAC9",
                    opacity: 80,
                    width: 1
                },
                symbol: 'none'
            },
            {
                data: data.map((item) => {
                    return ruleName.toLowerCase() !== 'freshness' ? item.value : item.freshnessValue;
                }),
                type: chartType,
                symbolSize: (value, item) => {
                    const obj = data[item?.dataIndex];
                    switch (obj?.status?.toLowerCase()) {
                        case 'high':
                            return 9;
                        case 'medium':
                            return 8;
                        case 'low':
                            return 7;
                        default:
                            return 6;
                    }
                }
            }
        ];
    }

    /**
     * Capture Chart Instance
     * @param {*} chartInstance
     */
    const onChartReady = (chartInstance) => {
        setTimeout(() => {
            setChartInstance(chartInstance);
        }, 2000);
    };

    /**
     * Highlight Data Point
     */
    useEffect(() => {
        if (chartInstance && highlightIndex && highlightIndex > 0) {
            chartInstance.dispatchAction({ type: 'showTip', dataIndex: highlightIndex, seriesIndex: 0 });
        }
    }, [chartInstance, highlightIndex]);


    return (
        <div key={`chart-${key}`} className={classes.chartTooltip}>
            <ReactEcharts
                key={`echart-${key}`}
                option={option}
                opts={{ renderer: "svg" }}
                style={{ height: 250 }}
                notMerge
                onChartReady={onChartReady}
            />
        </div>
    );
};


/**
 * Define Prop Types
 */
CountChartComponent.propTypes = {
    data: PropTypes.array,
    y_position: PropTypes.string,
    x_labels: PropTypes.string,
    key: PropTypes.string,
    classes: PropTypes.object,
    rule: PropTypes.object,
    ruleName: PropTypes.string,
    ruleCategory: PropTypes.string,
    chartType: PropTypes.string,
    highlightIndex: PropTypes.number,
    measureType: PropTypes.string
};

/**
 * Set Default Values
 */
CountChartComponent.defaultProps = {
    data: [],
    y_position: "left",
    x_labels: "grouped",
    key: '',
    classes: {},
    rule: {},
    ruleName: '',
    ruleCategory: '',
    chartType: 'line',
    highlightIndex: -1,
    measureType: ""
};


export default withStyles((theme) => ({
    ...style(theme)
}))(CountChartComponent);