import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';

//  Import Components
import { TableComponent, AreaChartComponent, DialogComponent } from '../../components/index.js';
import CreateEditIssueComponent from '../../containers/issues/createEdit/index.jsx';
import { useSearchParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import IssuesStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Constant
import appConstants from '../../constants/appConstants.js';

// Import Actions
import { getStatisticsRequest, getIssuesRequest, updateIssuesRequest, updateIssue, deleteIssuesRequest, exportRequest } from '../../redux/reducer/issuesReducer';
import { navigate } from '../../redux/reducer/navigationReducer';
import { updateUserPreference } from '../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer';
import { getDomainListRequest } from '../../redux/reducer/semanticReducer.js';
import { getApplicationsRequest } from '../../redux/reducer/applicationReducer.js';
import { getTagsRequest } from '../../redux/reducer/tagsReducer.js';
import { getTermsRequest } from '../../redux/reducer/termReducer';

// Import Helpers
import { checkPermission, getAlertStatusColors, permissionHeaders, prepareUpdateUserPreference, getUserPreference, orderList } from '../../helpers/appHelpers';
import featureConstants from '../../constants/featureConstants.js';

function Issues(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const searchControllerRef = useRef();

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const query_asset_name = searchParams.get("asset");
    const query_asset_id = searchParams.get("asset_id");
    const query_attribute_id = searchParams.get("attribute_id");
    const query_issue_id = searchParams.get("key1");
    const query_priority = searchParams.get("priority");
    const query_day_filter = searchParams.get("day");
    const queryStatusAction = searchParams.get("action_status");
    const queryPriorityAction = searchParams.get("action_priority");
    const query_domain = searchParams.get("domain");
    const query_application = searchParams.get("application");
    const query_tag = searchParams.get("tag");
    const query_status = searchParams.get("status");

    /**
     * Redux Selector
     */
    const { statistics, issuesList, search: searchFilter, issuesLoading, loadMore, exportLoading } = useSelector((state) => state.issues);
    const { permission, user } = useSelector((state) => state.auth);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { searchableTagsList } = useSelector((state) => state.tags, shallowEqual);
    const { searchableTermsList } = useSelector((state) => state.term, shallowEqual);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "issues", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "issues", "sorting");


    /**
     * Set Default Date
     * @returns
     */
    const setDefaultDate = () => {
        if (query_day_filter) {
            return query_day_filter;
        }
        return searchFilter.daysSelected || 'All';
    };

    /**
     * Define State
     */
    const filterData = searchFilter?.search_by ?? {};
    const [searchData, setSearchData] = useState({
        "status": "",
        "name": "",
        "asset_name": "",
        "asset_id": "",
        "priority": "",
        ...filterData
    });
    const [daysFilter, setDaysFilter] = useState({
        value: setDefaultDate(),
        options: ["Today", "Yesterday", "Last 3 days", "Last 7 days", "Last 14 days", "Last 30 days", "All"]
    });
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: ''
    });
    const [issueParams, setIssueParams] = useState(null);


    /**
     * Get Selection Date
     * @param {*} value
     * @returns
     */
    const getSelectionDate = (value) => {
        let days = 0;
        if (value === 'Today') {
            days = 1;
            return days;
        }
        if (value === 'Yesterday') {
            days = 2;
            return days;
        }
        days = value.replace(/[^0-9]/g, '');
        return days ? parseInt(days) : "All";
    };

    /**
     * Fetche Issues Statistics
     * @param {*} params
     */
    const getStatistics = (requestParams) => {
        let params = {
            days: getSelectionDate(daysFilter.value)
        };

        if (requestParams) {
            params = {
                ...params,
                ...requestParams
            };
        }
        dispatch(getStatisticsRequest({ params }));
    };


    /**
     * Fetche Issues List
     * @param {*} params
     */
    const getIssues = (params, statistics = false, clear = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        if (!params.days) {
            params.days = getSelectionDate(daysFilter.value);
        }

        const requestParams = {
            ...searchFilter,
            ...params,
            statistics
        };
        dispatch(getIssuesRequest({ params: requestParams, token, clear }));
    };

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Domains List
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        // Get Applications List
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        // Get Tags List
        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
        // Get Terms List
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }
    }, [dispatch]);

    /**
     * Use Effect for Initial Load
     */
    useEffect(() => {
        if ((!issuesList.length) || query_asset_name || query_asset_id || query_priority || query_domain || query_application || query_attribute_id || query_tag || query_status) {
            const search_by = { ...searchData };
            search_by.asset_name = query_asset_name || '';
            search_by.asset_id = query_asset_id || '';
            search_by.attribute_id = query_attribute_id || '';
            search_by.priority = query_priority || '';
            search_by.domain = query_domain || '';
            search_by.application = query_application || '';
            search_by.tag = query_tag || '';
            search_by.status = query_status || '';
            removeSearchParam();
            setSearchData({ ...search_by });
            let requestData = {
                search_by: search_by,
                offset: 0
            };
            if (query_asset_name || query_asset_id || query_domain || query_application || query_attribute_id || query_tag) {
                const selectedDays = { ...daysFilter };
                selectedDays.value = 'All';
                setDaysFilter({ ...selectedDays });
                requestData = {
                    ...requestData,
                    "daysSelected": 'All',
                    "days": 'All'
                };
            }
            if (sorting) {
                requestData.sortBy = sorting.sortBy;
                requestData.orderBy = sorting.orderBy;
            }
            getIssues(requestData, true, true);
        }
    }, [dispatch]);

    /**
     * Open Issues Details Based on Issue Id from Query Params
     */
    useEffect(() => {
        if (query_issue_id && (queryPriorityAction || queryStatusAction)) {
            const key = queryPriorityAction ? "priority" : "status";
            const requestParams = {
                [key]: key === "priority" ? queryPriorityAction : queryStatusAction,
                key: key,
                id: query_issue_id
            };
            dispatch(updateIssuesRequest(requestParams));
        }
        if (query_issue_id) {
            setIssueParams({
                id: query_issue_id
            });
            removeSearchParam();
        }
    }, [query_issue_id]);

    /**
     * Handle Status Selected
     * @param {*} status
     */
    const handleTileStatusClick = (status) => {
        getIssues({ status, offset: 0 }, false, true);
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "issues", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        if (sortBy === 'formatted_date') {
            sortBy = 'created_date';
        }
        const requestData = {
            sortBy, orderBy, offset: 0
        };
        getIssues(requestData, false, true);
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        getIssues({ search_by: search_by, offset: 0 }, true, true);
    };

    /**
     * Handle Days Range Change
     * @param {*} value
     */
    const onDaysFilterChanges = (value) => {
        const selectedDays = { ...daysFilter };
        selectedDays.value = value;
        setDaysFilter({ ...selectedDays });
        const days = getSelectionDate(value);
        getIssues({ offset: 0, days, daysSelected: value }, true, true);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreIssues = () => {
        if (loadMore && !issuesLoading) {
            getIssues({ offset: (searchFilter.offset + searchFilter.limit) });
        }
    };


    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestParams = {
            id: item.id,
            [key]: value
        };
        dispatch(updateIssuesRequest({ ...requestParams, key: key }));
        dispatch(updateIssue(requestParams));
        setTimeout(() => getStatistics({ search_by: { ...searchData } }), 100);
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} issue
     */
    const handleCellClickEvent = (key, issue) => {
        switch (key) {
            case "asset_name":
                if (!issue.asset_id) {
                    return;
                }
                dispatch(navigate({ path: 'assets.root', state: {}, params: [issue.asset_id] }));
                break;
            case "name":
                setIssueParams({
                    id: issue.id
                });
                break;
            default:
                break;
        }
    };


    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.assetDeleteMessage,
            data
        });
    };


    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteIssuesRequest({ id: showDialog.data.id, type: (type === "purge") }));
        setTimeout(() => {
            getStatistics({ search_by: { ...searchData } });
            dialogCancelEventHandle();
        }, 100);
    };

    /**
     * Handle action
     * @param {*} issue
     */
    const onHandleActions = (issue) => {
        onDelete({ id: issue.id, type: false });
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Export Issues
     */
    const exportIssues = () => {
        const requestParams = {
            ...searchFilter,
            fileName: 'issues.csv'
        };
        dispatch(exportRequest(requestParams));
    };

    /**
     * Clear Search Filters
     */
    const clearFilters = () => {
        let search_by = { ...searchData };
        search_by = Object.keys(search_by).forEach((key) => search_by[key] = '');
        setSearchData({ ...search_by });
        getIssues({ search_by: search_by, offset: 0 }, true, true);
    };

    /**
     * Get Table Action Options
     */
    const tableOptions = [{ type: "clear", customFunction: clearFilters }, { type: "search", customFunction: null }, { type: "download", customFunction: exportIssues, isLoading: exportLoading }, { type: "columns", customFunction: null }];

    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(searchableTermsList), [searchableTermsList]);

    /**
     * Filter tags
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsList), [searchableTagsList]);


    /**
     * Issues Table Headers
     */
    const tableHeaders = [
        { key: 'name', name: 'Issue', sorting: true, tooltip: true, width: "30%", isSearch: true, searchComponent: "text", searchKey: "name", clickable: true },
        { key: 'connection_name', name: 'Connection', sorting: true, tooltip: true, width: "13%", isSearch: true, searchComponent: "text", searchKey: "connection_name", showNA: true },
        { key: 'asset_name', name: 'Asset', sorting: true, tooltip: true, width: "13%", isSearch: true, searchComponent: "text", searchKey: "asset_name", clickable: true, showNA: true },
        { key: 'users', name: 'Users', tooltip: true, width: "10%", isSearch: false, component: "users", limit: 2, hideDefault: true },
        { key: 'priority', name: 'Priority', component: 'status', sorting: true, tooltip: true, width: "8%", isSearch: true, searchComponent: "text", searchKey: "priority", list: ["High", "Medium", "Low"] },
        { key: 'status', name: 'Status', component: 'status', width: "8%", sorting: true, isSearch: true, searchComponent: "text", searchKey: "status", list: ["New", "Inprogress", "Resolved"] },
        { key: 'formatted_date', name: 'Created On', width: "8%", sorting: true },
        { key: 'asset_id', name: 'Asset ID', width: "5%", sorting: true, isSearch: true, searchComponent: "text", searchKey: "asset_id", hideDefault: true },
        { key: 'applications', name: 'Application', width: "5%", isSearch: true, searchComponent: "autocomplete", list: applicationsList || [], searchKey: "applications", hideDefault: true, showNA: true, component: 'chips', className: 'applicationChip_close', haveColor: true, limit: 1, isNotEditable: true },
        { key: 'domains', name: 'Domain', width: "5%", isSearch: true, searchComponent: "autocomplete", list: domainsList || [], searchKey: "domains", hideDefault: true, showNA: true, component: 'chips', className: 'applicationChip_close', haveColor: true, limit: 1, isNotEditable: true },
        {
            key: 'terms',
            name: 'Terms',
            width: "5%",
            isSearch: true,
            searchComponent: "autocomplete",
            list: termsList || [],
            searchKey: "terms",
            limit: 1,
            hideDefault: true,
            showNA: true,
            component: 'chips',
            className: 'applicationChip_close',
            haveColor: true,
            isNotEditable: true
        },
        {
            key: 'tags',
            name: 'Tags',
            width: "5%",
            isSearch: true,
            searchComponent: "autocomplete",
            list: tagsList || [],
            searchKey: "tags",
            limit: 1,
            hideDefault: true,
            showNA: true,
            component: 'chips',
            className: 'applicationChip_close',
            haveColor: true,
            isNotEditable: true
        },
        { key: 'actions', name: 'Actions', width: "5%", actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }] }
    ];

    return (
        <ValidatorForm onSubmit={() => null}>
            <Grid container className={classes.IssuesContainer}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {
                            appConstants.issuesTiles.map((item) => (
                                <Grid item xs={3} key={item.name} onClick={() => handleTileStatusClick(item.key)}>
                                    <Grid id={`${item.key}_chart_container`} className={`${classes.issuesCard} ${searchFilter.status === item.key ? 'selected' : ''}`}>
                                        <Grid className={classes.cardData}>
                                            <Typography variant="h4">
                                                {(statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].count : 0}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid className={classes.chart}>
                                            {
                                                ((statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].count : 0) ?
                                                    <AreaChartComponent
                                                        containerId={`${item.key}_chart_container`}
                                                        size="small"
                                                        lineColor={getAlertStatusColors(item.key)}
                                                        toolTipName="Issues"
                                                        chartData={(statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].issues : []} />
                                                    :
                                                    <Grid className={classes.nodataCardWrapper}>
                                                        <Grid className={classes.nodataCard}>
                                                            <EqualizerIcon />
                                                            <Typography variant="body1" className={classes.textSecondary}>
                                                                No Data Found
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.issuesTable}>
                    <TableComponent
                        styleType="striped"
                        title={"Recent Issues"}
                        headers={permissionHeaders(tableHeaders, issuePermission)}
                        options={tableOptions}
                        data={issuesList || []}
                        sortBy={searchFilter?.sortBy ?? ''}
                        orderBy={searchFilter?.orderBy ?? 'asc'}
                        onClickSorting={onClickSorting}
                        filters={[{ ...daysFilter, onChange: onDaysFilterChanges }]}
                        onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                        onCompnentEvent={onCompnentEvent}
                        onClickActions={onHandleActions}
                        onCellClick={handleCellClickEvent}
                        height="calc(100vh - 170px)"
                        NoResultText="No Issues Found"
                        isLoading={issuesLoading}
                        searchData={searchData}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        stickyHeader
                        onScrollEnd={loadMoreIssues}
                    />
                </Grid>
                {
                    issueParams &&
                    <CreateEditIssueComponent
                        issueParams={issueParams}
                        open={Boolean(issueParams)}
                        onClose={() => setIssueParams(null)}
                    />
                }
                {
                    showDialog.open &&
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle} />
                }
            </Grid>
        </ValidatorForm>
    );
}

// default props
Issues.defaultProps = {
    classes: {}
};

// prop types
Issues.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...IssuesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Issues);