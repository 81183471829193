import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { PopOverComponent, SelectComponent, ToggleComponent, LoaderComponent, DialogComponent } from '../../../../components/index.js';
import WidgetComponents from '../widgetComponents/index.jsx';
import CustomWidgetComponents from '../customWidgetComponents/index.jsx';
import SelectDashboard from '../selectDashboard/index.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { SettingsIcon, MoreIcon, ShareIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { navigate } from '../../../../redux/reducer/navigationReducer';
import { checkPermission, prepareUpdateUserPreference, getUserPreference } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';
import {
    updateWidgetProperties, getWidgetDataRequest, widgetLoader, updateWidgetRequest, deleteWidgetRequest,
    widgetMoveRequest
} from '../../../../redux/reducer/dashboardReducer';
import { updateUserPreference } from '../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer';

// Import constant
import appConstants from '../../../../constants/appConstants.js';
import WidgetReports from '../../reports/index.jsx';


function Widget(props) {
    const { classes, data, isDisabled } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [options, openOptions] = useState(null);
    const [widgetOptions, openWidgetOptions] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [openDashboardDialog, setDashboardDialog] = useState(false);
    const [shareReports, setShareReports] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const securityPermission = checkPermission(permission, featureConstants.settings.security);
    const propertyPermission = checkPermission(permission, featureConstants.assets.properties);
    const conversationPermission = checkPermission(permission, featureConstants.assets.conversations);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);
    const alertsPermission = checkPermission(permission, featureConstants.home.alerts);
    const filter = getUserPreference(user?.user_preference ?? {}, "widget", data.id, "filter_properties");


    /**
     * Handle Filter Option
     * @param {*} event
     */
    const handleOptions = (event) => {
        openOptions(event.currentTarget);
    };


    /**
     * handleClose
     */
    const handleClose = () => {
        openOptions(null);
        openWidgetOptions(false);
    };

    /**
     * Get Widget Data
     * @param {*} params
     */
    const getWidgetData = (params) => {
        dispatch(widgetLoader(params.id));
        dispatch(getWidgetDataRequest(params));
    };

    /**
     * Widget Actions
     * @param {*} type
     */
    const widgetActions = (type) => {
        if (type === "refresh") {
            const requestParams = {
                id: data.id,
                type: 'properties',
                filter_value: data?.filter_properties?.filter_value ?? "",
                value: data?.filter_properties?.value ?? "default"
            };
            getWidgetData(requestParams);
        } else if (type === "delete") {
            let message = appConstants.dialogBox.widgetDeleteMessage;
            if (data?.widget_type === 'table') {
                message = `${message} ( NOTE: All reports mapped to the widget will be deleted)`;
            }
            setShowDeleteDialog({
                open: true,
                title: appConstants.dialogBox.delete,
                message: message,
                data: { id: data?.id }
            });
        } else if (type === "move") {
            setDashboardDialog(true);
        } else if (type === "add_template") {
            const params = {
                id: data.id,
                is_template: true,
                type
            };
            dispatch(updateWidgetProperties(params));
            dispatch(updateWidgetRequest(params));
        }
        handleClose();
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "widget", data.id, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Update Widget Data
     * @param {*} property
     * @param {*} value
     */
    const updateWidget = (property, value) => {
        const requestParams = {
            id: data.id,
            type: 'filter_properties',
            filter_value: property === "filter_value" ? value : data?.properties?.filter_value ?? "",
            value: property === "value" ? value : data?.properties?.value ?? "default"
        };
        getWidgetData(requestParams);
        dispatch(updateWidgetProperties(requestParams));
        const filterProperties = filter ? { ...filter } : {};
        updatePreference({ filter_properties: { ...filterProperties, [property]: value } });
    };

    /**
     * Table Component Filter Properties Update
     * @param {*} property
     * @param {*} value
     */
    const onChangeProperties = (property, value) => {
        const requestParams = {
            id: data.id,
            type: 'filter_properties',
            [property]: value
        };
        dispatch(updateWidgetProperties(requestParams));
        const filterProperties = filter ? { ...filter } : {};
        updatePreference({ filter_properties: { ...filterProperties, [property]: value } });
    };

    /**
     * Redirect Widget
     */
    const redirectWidget = () => {
        dispatch(navigate({ path: 'widget.detail', state: {}, params: [data.id] }));
    };

    /**
     * Check Data Permission
     * @param {*} data
     * @returns
     */
    const checkDataPermission = (data) => {
        if (_.isArray(data?.key) && data?.key?.includes("Semantics") && !semanticsPermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Properties") && !propertyPermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Conversations") && !conversationPermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Security") && !securityPermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Issues") && !issuePermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Alerts") && !alertsPermission?.is_view) {
            return { disablePermission: true };
        } else if (_.isArray(data?.key) && data?.key?.includes("Historical")) {
            if (data.filter_properties.value === "alerts" && !alertsPermission?.is_view) {
                return { disablePermission: true };
            } else if (data.filter_properties.value === "issues" && !issuePermission?.is_view) {
                return { disablePermission: true };
            }
            return data;
        } else if (_.isArray(data?.key) && data?.key?.includes("organization_score")) {
            if (data.filter_properties.value !== "asset" && !semanticsPermission?.is_view) {
                return { disablePermission: true };
            }
            return data;
        } else if (_.isArray(data?.key) && data?.key?.includes("semantics_issues_alerts")) {
            if (data.filter_properties.value === "issues" && (!issuePermission?.is_view || !semanticsPermission?.is_view)) {
                return { disablePermission: true };
            } else if (data.filter_properties.value === "alerts" && (!alertsPermission?.is_view || !semanticsPermission?.is_view)) {
                return { disablePermission: true };
            }
            return data;
        }
        return data;

    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDeleteDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = () => {
        dispatch(deleteWidgetRequest(data.id));
        dialogCancelEventHandle();
    };

    /**
     * On Select Dashboard
     * @param {*} value
     */
    const onSelectDashboard = (value) => {
        const requestParams = {
            dashboard: value,
            id: data.id
        };
        dispatch(widgetMoveRequest(requestParams));
        setDashboardDialog(false);
    };

    /**
     * Handle To show show page
     */
    const handleEmailShare = () => {
        setShareReports(!shareReports);
        setAnchorEl(null);
    };

    /**
     * Handle Show Share Options
     * @param {*} event
     */
    const handleShare = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handle Share Close
     */
    const handleShareClose = () => {
        setAnchorEl(null);
    };

    /**
     * Navigate to Reports Page
     */
    const navigateReports = () => {
        setAnchorEl(null);
        dispatch(navigate({ path: 'home.reports', state: {}, params: [] }));
    };

    return (
        <Grid
            container
            className={`widgetContainer ${classes.widgetContainer} ${widgetOptions && "showDrag"}`}
            alignItems={'center'}
            onMouseEnter={() => { openWidgetOptions(true); }}
            onMouseLeave={() => { openWidgetOptions(false); }}
        >
            <Grid item xs={12} className={`${classes.widgetHeader} ${data.properties.hide_title ? "flexEnd" : "spaceBetween"}`}>
                {
                    !data.properties.hide_title &&
                    <Typography variant="h6">
                        {data.name || ""}
                    </Typography>
                }
                <Grid className={`${data.properties.hide_title ? "NotitleWidget" : ''} dflex alignCenter rightActions`}>
                    <Grid className="dflex alignCenter widgetExtraOption">
                        {
                            data?.properties?.tabs?.length > 0 &&
                            <ToggleComponent
                                data={data?.properties?.tabs ?? []}
                                selectedValue={data?.filter_properties?.value ?? ""}
                                handleChange={(value) => value && updateWidget("value", value)}
                            />
                        }
                        {
                            data?.properties?.filters?.length > 0 &&
                            <SelectComponent
                                list={data?.properties?.filters ?? []}
                                propertyName="value"
                                value={data?.filter_properties?.filter_value ?? ""}
                                displayPropertyName={"label"}
                                onSelectChange={(value) => updateWidget("filter_value", value)}
                                noOutline
                            />
                        }
                    </Grid>
                    <Grid className={`${widgetOptions && "showoptions"} widgetOptions dflex alignCenter pl-1`}>
                        {
                            !data.is_default && !data.default_template &&
                            <IconButton className="filterIcon p5" onClick={() => redirectWidget()}>
                                <SettingsIcon />
                            </IconButton>
                        }
                        {
                            data?.widget_type === 'table' &&
                            <IconButton onClick={handleShare} className="shareIcon p5">
                                <ShareIcon />
                            </IconButton>
                        }
                        <IconButton onClick={handleOptions} className="moreIcon p5">
                            <MoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} className={`${classes.widgetBody} ${data.properties.hide_title ? "NotitleWidgetBody" : ''} ${data.isLoading ? "loadingWidget" : ""}`}>
                {
                    data.isLoading ?
                        <LoaderComponent loaderType="circular" /> :
                        <Grid container className="mb-1 h-100" alignItems={'center'} wrap="nowrap">
                            {
                                !data.is_default && !data.default_template ?
                                    <CustomWidgetComponents data={data} type="dashboard" onChangeProperties={onChangeProperties} />
                                    :
                                    <WidgetComponents data={checkDataPermission(data)} />
                            }
                        </Grid>
                }
            </Grid>
            <PopOverComponent
                anchorEl={options}
                open={Boolean(options)}
                onClose={handleClose}
            >
                <Grid className={classes.popoverComponent}>
                    <List>
                        <ListItem disablePadding onClick={() => widgetActions("refresh")}>
                            <ListItemButton>
                                <ListItemText primary="Refresh" />
                            </ListItemButton>
                        </ListItem>
                        {
                            !isDisabled && !data.is_default && !data.default_template && !data.is_template &&
                            <ListItem disablePadding onClick={() => widgetActions("add_template")}>
                                <ListItemButton>
                                    <ListItemText primary="Add To Template" />
                                </ListItemButton>
                            </ListItem>
                        }
                        {
                            !isDisabled && !data.is_default &&
                            <ListItem disablePadding onClick={() => widgetActions("move")}>
                                <ListItemButton>
                                    <ListItemText primary="Move" />
                                </ListItemButton>
                            </ListItem>
                        }
                        {
                            !isDisabled && !data.is_default &&
                            <ListItem disablePadding onClick={() => widgetActions("delete")}>
                                <ListItemButton>
                                    <ListItemText primary="Delete" />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                </Grid>
            </PopOverComponent>
            {
                showDeleteDialog.open && (
                    <DialogComponent
                        open={showDeleteDialog.open}
                        title={showDeleteDialog.title}
                        message={showDeleteDialog.message}
                        data={showDeleteDialog.data}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                    />
                )
            }
            {
                openDashboardDialog &&
                <SelectDashboard
                    open={openDashboardDialog}
                    onClose={() => setDashboardDialog(false)}
                    selectDashboard={(value) => onSelectDashboard(value)}
                />
            }

            {/* WidgetReports */}
            {
                data?.widget_type === 'table' &&
                <React.Fragment>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleShareClose}
                    >
                        <MenuItem onClick={handleEmailShare}>
                            Share this report as an email
                        </MenuItem>
                        <MenuItem onClick={navigateReports}>
                            Manage Scheduled Reports
                        </MenuItem>
                    </Menu>
                    {
                        shareReports &&
                        <WidgetReports
                            source_data={data}
                            open={shareReports}
                            toggleDrawer={handleEmailShare}
                        />
                    }
                </React.Fragment>
            }
            {/* WidgetReports */}
        </Grid>
    );
}

// default props
Widget.defaultProps = {
    classes: {},
    data: {},
    isDisabled: false
};

// prop types
Widget.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    isDisabled: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Widget, areEqual));