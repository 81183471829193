import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { Grid, IconButton, Typography, Collapse, Tooltip } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CodeOffIcon from '@mui/icons-material/CodeOff';

//  Import Styles
import PreviewStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

//  Import Components
import { MemoTextBoxComponent, TableComponent, TextBoxComponent, ToggleComponent, SearchComponent, AutoCompleteComponent, PopOverComponent } from '../../../../components/index.js';
import Queries from './queries.jsx';
import MeasureAdd from '../../properties/measure/add/index.jsx';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Images
import { CloseIcon, PlayIcon2, PlusIcon, SaveIcon, SearchIcon } from '../../../../assets/svg/index.js';

// import Actions
import { getUsageRequest, runQueryRequest, createUsageRequest, updateUsageRequest, clearQueryState } from '../../../../redux/reducer/usageReducer';
import { updateMeasureDetail } from '../../../../redux/reducer/measureReducer';

// Import Helpers
import { checkPermission } from '../../../../helpers/appHelpers.js';


function Preview(props) {

    /**
     * Define Props
     */
    const { classes, closeDialog } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { default_query, query_result, saved_queries } = useSelector((state) => state.usage);
    const asset_id = useSelector((state) => state.version.asset_id, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const usagePermission = checkPermission(permission, featureConstants.assets.preview);
    const { list: attributes } = useSelector((state) => state.attribute);


    /**
     * Define State
     */
    const [queryObj, setQueryObj] = useState({ name: "", query: "", id: null });
    const [usageActiveTab, setUsageActiveTab] = useState(usagePermission?.is_edit ? appConstants.toggle.usageTab[0].value : appConstants.toggle.usageTab[1].value);
    const [searchBox, openSearchBox] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState(null);

    /**
     *
     * Set Default Query
     */
    useEffect(() => {
        if (default_query && asset_id) {
            setQueryObj({ ...queryObj, query: default_query });
        }
    }, [default_query, asset_id]);

    /**
     * Get Usage
     */
    useEffect(() => {
        if (asset_id) {
            dispatch(getUsageRequest(asset_id));
            dispatch(runQueryRequest({ asset_id }));
        }
    }, [dispatch, asset_id]);

    /**
     * Handle Query Change
     * @param {*} value
     */
    const handleQueryChange = (name, value) => {
        setQueryObj({ ...queryObj, [name]: value });
    };

    /**
     * Save Query
     */
    const saveQuery = () => {
        const { name, query } = queryObj;
        if (queryObj.id) {
            dispatch(updateUsageRequest({ "asset": asset_id, id: queryObj.id, name, query }));
        } else if (!saved_queries.some((item) => item.name === name)) {
            dispatch(createUsageRequest({ "asset": asset_id, name, query }));
        }
        setQueryObj({ ...queryObj });
    };

    /**
     * Run Query
     */
    const runQuery = (event) => {
        const { query } = queryObj;
        if (query) {
            dispatch(runQueryRequest({ asset_id, query }));
        }
        event.stopPropagation();
    };

    /**
     * Prepare Filter Preview Result
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = typeof (item[key]) === "number" ? item[key].toString() : item[key];
                    if (typeof (value) === 'string' && !value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} issue
     */
    const handleCellClickEvent = (key, issue) => {
        const { id, name, query } = issue;
        if (key === "name") {
            setQueryObj({ id, name, query });
            setUsageActiveTab(appConstants.toggle.usageTab[0].value);
        }
    };

    /**
     * Creates New Query
     */
    const newQuery = (event) => {
        setQueryObj({ name: "", query: "", id: null });
        event.stopPropagation();
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };


    /**
     * Set Search Data
     */
    useEffect(() => {
        const headers = query_result?.headers ?? [];
        if (headers && headers.length) {
            const searchHeaders = {};
            for (const header of headers) {
                searchHeaders[header.name] = "";
            }
            setSearchData({ ...searchHeaders });
        }
    }, [query_result]);


    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(query_result?.data ?? [], searchData, sorting.sortBy, sorting.orderBy), [query_result?.data ?? [], searchData, sorting.sortBy, sorting.orderBy]);

    /**
     * Handle Search
     */
    const handleSearch = () => {
        openSearchBox(!searchBox);
    };

    /**
     * OnClose
     */
    const onClose = () => {
        closeDialog();
        dispatch(clearQueryState());
    };

    /**
     * Prepare Attribute List
     * @returns
     */
    const prepareAttributeList = () => {
        return [{ id: 'All', name: 'All', derived_type: 'All' }, ...attributes];
    };


    /**
     * Convert Measure
     * @param {*} item
     * @param {*} event
     */
    const convertMeasure = (item, event) => {
        setSelectedQuery(item);
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handle Measure Dialog Close
     */
    const handleMeasureDialogClose = () => {
        setSelectedAttribute(null);
        setSelectedQuery(null);
    };


    /**
     * On Select Attribute
     * @param {*} value
     */
    const onSelectAttribute = (value) => {
        setSelectedAttribute(value);
        setAnchorEl(null);
        const requestParams = {
            name: selectedQuery.name,
            query: selectedQuery.query,
            category: 'query',
            asset: asset_id
        };
        if (value.id !== "All") {
            requestParams.attribute = value.id;
        }
        dispatch(updateMeasureDetail(requestParams));
        dispatch(clearQueryState());
    };

    return (
        <Grid item xs={12} className={classes.PreviewContainer}>
            <Grid className="dflex alignCenter spaceBetween header pb-2">
                <Typography variant="h3">
                    Preview Query
                </Typography>
                <Tooltip title="Close" arrow>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <ValidatorForm className="" noValidate onSubmit={saveQuery}>
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                    >
                        <Grid container className={classes.usagePageContainer}>
                            <Grid item xs={12} align="right" className="">
                                <Grid className={`${classes.headerContainer} `}>
                                    <Grid className={classes.queryNameContainer}>
                                        <Typography variant="h5" align="left">
                                            {
                                                usageActiveTab === appConstants.toggle.usageTab[1].value ?
                                                    "Saved Queries" :
                                                    <TextBoxComponent
                                                        name={'name'}
                                                        value={queryObj.name}
                                                        variant="standard"
                                                        noOutline
                                                        placeholder="Enter Query Name"
                                                        onChange={(event) => handleQueryChange(event.target.name, event.target.value)}
                                                        validators={['required']}
                                                        errorMessages={[appConstants.labels.usage.query_name_required]}
                                                    />
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid className="dflex alignCenter">
                                        <Grid className="dflex alignCenter flexEnd w-100 mr-1 rightSideSearch">
                                            {
                                                usageActiveTab === appConstants.toggle.usageTab[1].value &&
                                                <React.Fragment>
                                                    <Collapse in={searchBox} orientation="horizontal">
                                                        <SearchComponent
                                                            placeholder={"Search"}
                                                            value={searchKey}
                                                            onChange={(value) => setSearchKey(value)} />
                                                    </Collapse>

                                                    {
                                                        !searchBox &&
                                                        <IconButton onClick={handleSearch} >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    }
                                                </React.Fragment>
                                            }
                                            {
                                                usageActiveTab === appConstants.toggle.usageTab[0].value &&
                                                <React.Fragment>
                                                    <Tooltip title="Run">
                                                        <IconButton onClick={(event) => runQuery(event)}>
                                                            <PlayIcon2 />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Save">
                                                        <IconButton type={'submit'}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Add">
                                                        <IconButton onClick={(event) => newQuery(event)}>
                                                            <PlusIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                        {
                                            usagePermission?.is_edit &&
                                            <ToggleComponent
                                                data={
                                                    [
                                                        {
                                                            name: <CodeOffIcon />,
                                                            value: 'preview'
                                                        },
                                                        {
                                                            name: <FormatListBulletedIcon />,
                                                            value: 'queries'
                                                        }
                                                    ]
                                                }
                                                selectedValue={usageActiveTab}
                                                handleChange={(selectedValue) => setUsageActiveTab(selectedValue || usageActiveTab)} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                usageActiveTab === appConstants.toggle.usageTab[1].value &&
                                <Grid item xs={12} className={classes.queryListContainer}>
                                    <Queries asset_id={asset_id} convertMeasure={convertMeasure} handleCellClickEvent={handleCellClickEvent} searchKey={searchKey} usagePermission={usagePermission} />
                                </Grid>
                            }
                            {
                                usageActiveTab === appConstants.toggle.usageTab[0].value &&

                                <Grid item xs={12}>
                                    <Grid item className={`${classes.queryContainer} queryContainer h-100`}>
                                        <MemoTextBoxComponent
                                            name={'query'}
                                            value={queryObj.query}
                                            noOutline
                                            fullWidth
                                            multiline
                                            rows={10}
                                            onChange={(event) => handleQueryChange(event.target.name, event.target.value)}
                                            validators={['required']}
                                            errorMessages={[appConstants.labels.usage.query_required]}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
            <Grid item className={`${classes.queryContainer} pt-1`}>
                <Grid container className={classes.tableWrapper}>
                    <Grid item xs={12}>
                        <TableComponent
                            headers={query_result?.headers ?? []}
                            data={filterData}
                            title={appConstants.labels.usage.preview}
                            options={appConstants.tableOptions.download}
                            searchData={searchData}
                            sortBy={sorting.sortBy}
                            orderBy={sorting.orderBy}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickSorting={onClickSorting}
                            styleType="striped"
                            NoResultText="No Records Found"
                            height="calc(100vh - 300px)"
                            isLoading={query_result.isLoading}
                            exportParams={
                                {
                                    data: filterData || [],
                                    fileName: "Preview.csv",
                                    headers: query_result?.headers?.map((obj) => obj.key) || []
                                }
                            }
                            textTransform={false}
                            stickyHeader
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                Boolean(anchorEl) &&
                <PopOverComponent
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <ValidatorForm onSubmit={() => { }}>
                        <Grid className={classes.popOverContainer}>
                            <AutoCompleteComponent
                                data={prepareAttributeList() || []}
                                customOption
                                blurOnSelect
                                labelKey={"name"}
                                renderType={'attributes'}
                                onChange={(event, newValue) => onSelectAttribute(newValue)}
                                placeholder="Select Attribute"
                            />
                        </Grid>
                    </ValidatorForm>
                </PopOverComponent>
            }
            {
                selectedAttribute &&
                <MeasureAdd
                    open={Boolean(selectedAttribute)}
                    attributes={attributes}
                    attribute_id={selectedAttribute.id !== "All" ? selectedAttribute.id : null}
                    asset_id={asset_id}
                    isAssetLevel={selectedAttribute.id === "All"}
                    handleMeaureAddEditDialog={handleMeasureDialogClose}
                />
            }
        </Grid>
    );
}

// default props
Preview.defaultProps = {
    classes: {}
};

// prop types
Preview.propTypes = {
    classes: PropTypes.object,
    closeDialog: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...PreviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Preview);