import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

//  Import Components
import { Grid, Rating, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { CircularScoreComponent } from '../../../../../components/index.js';

// Import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';

function MostRatedAssets(props) {

    const { classes, data } = props;
    const refContainer = useRef();
    const dispatch = useDispatch();

    /**
     * Redirect
     * @param {*} item
     */
    const redirect = (item) => {
        dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
    };

    /**
     * Get Height
     * @param {*} container
     * @returns
     */
    const getHeight = (container) => {
        let height = 400;
        if (container && container.current) {
            height = container?.current?.offsetParent?.clientHeight ?? 400;
        }
        return height;
    };

    const height = useMemo(() => getHeight(refContainer), [refContainer]);

    return (
        <Grid item xs={12} className={`pr-2 ${classes.ratedContainer}`} ref={refContainer} sx={{ height }}>
            {
                data && data.map((item, index) =>
                    <Grid container className={`${classes.widgetItem}`} key={`widget_${index}`} alignItems={'center'} wrap="nowrap">
                        <Grid item className={classes.widgetListIcon} onClick={() => redirect(item)}>
                            <CircularScoreComponent
                                size={46}
                                value={item.dqscore || 0}
                                showPercentage
                                noLink
                                showValue />
                        </Grid>
                        <Grid item xs={12} className={classes.widgetItemDetail}>
                            <Grid item className="dflex alignCenter">
                                <Grid item xs={8}>
                                    <Typography variant="body1" className="pointer" onClick={() => redirect(item)}>
                                        {item.name || ""}
                                    </Typography>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {`Last Modified Date ${moment(item.modified_date || item.created_date).format('MMM DD yyyy')}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid className="dflex alignCenter">
                                        <Grid item className="starIcon">
                                            <Rating
                                                className={classes.starIcon}
                                                name="half-feature-card"
                                                precision={0.5}
                                                size="small"
                                                readOnly
                                                value={item.ratings || 0}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                            />
                                        </Grid>
                                        <Typography
                                            variant="body1"
                                            className={`${classes.textSecondary} fw-500 pl5`}
                                        >
                                            {item.ratings ? item.ratings?.toFixed(1) : 0}
                                            <span className="pl5">
                                                {`(${item.rating_count || 0})`}
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}

// default props
MostRatedAssets.defaultProps = {
    classes: {},
    data: []
};

// prop types
MostRatedAssets.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(MostRatedAssets, areEqual));