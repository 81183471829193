import { config } from "../../config/appIntegrationConfig";
import { PublicClientApplication } from "@azure/msal-browser";

class Teams {

    constructor() {
        this.msalInstance = new PublicClientApplication(config.teams.msalConfig);
        this.token = "";
    }

    generateHeader(token) {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        if (this.token === "") {
            this.token = token;
        }
        return headers;
    }

    authenticate() {
        return new Promise((resolve, reject) => {
            this.msalInstance.loginPopup(config.teams.scope)
                .then((data) => {
                    let refreshToken = {};
                    for (const key of Object.keys(sessionStorage)) {
                        if (key.includes("refreshtoken")) {
                            refreshToken = JSON.parse(sessionStorage[key]);
                        }
                    }
                    data = {
                        access_token: data.accessToken,
                        refresh_token: refreshToken.secret,
                        authority: data.authority,
                        expiresOn: data.expiresOn,
                        extExpiresOn: data.extExpiresOn,
                        uniqueId: data.uniqueId,
                        correlationId: data.correlationId,
                        refreshToken
                    };
                    resolve({ result: data });
                })
                .catch((error) => {
                    reject({ error });
                });
        });
    }

    getTeams(accessToken) {
        return new Promise((resolve, reject) => {
            const headers = this.generateHeader(accessToken);
            const requestOptions = {
                method: 'GET',
                headers: headers
            };
            const url = `${config.teams.graphEndpoint}/${config.teams.api.joinedTeams}`;
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    result = result || {};
                    if (result.value) {
                        const teams = result.value.map((team) => {
                            return {
                                id: team.id,
                                name: team.displayName
                            };
                        });
                        resolve({ result: teams });
                    } else {
                        if (result?.error?.code === "InvalidAuthenticationToken") {
                            throw new Error("InvalidAuthenticationToken!");
                        }
                        reject({ error: result });
                    }
                })
                .catch((error) => reject({ error: error }));
        });
    }

    getChannels(accessToken, teamId) {
        return new Promise((resolve, reject) => {
            const headers = this.generateHeader(accessToken);
            const requestOptions = {
                method: 'GET',
                headers: headers
            };
            const channelUrl = config.teams.api.channels.replace('{teamId}', teamId);
            const url = `${config.teams.graphEndpoint}/${channelUrl}`;
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    result = result || {};
                    if (result.value) {
                        const channels = result.value.map((channel) => {
                            return {
                                id: channel.id,
                                name: channel.displayName
                            };
                        });
                        resolve({ result: channels });
                    } else {
                        reject(result);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    generateToken(refresh_token, clientId) {
        return new Promise((resolve, reject) => {
            const scopes = config.teams.scope.join(" ");
            const form_data = new FormData();
            form_data.append("client_id", clientId);
            form_data.append("scope", scopes);
            form_data.append("refresh_token", refresh_token);
            form_data.append("grant_type", "refresh_token");
            const requestOptions = {
                "content-type": "application/x-www-form-urlencoded",
                method: 'POST',
                redirect: 'follow',
                body: form_data
            };
            const url = `${config.teams.msalConfig.auth.authority}${config.teams.api.generateToken}`;

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    result = result || {};
                    resolve({ result: result });
                })
                .catch((error) => reject(error));
        });
    }
}

export default Teams;