import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Components
import { TableComponent, DialogComponent } from '../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import SemanticTermsStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';

// Actions
import {
    getTermsRequest, updateDetailRequest, deleteTermRequest
} from '../../../redux/reducer/termReducer.js';
import { checkPermission, permissionHeaders, prepareUpdateUserPreference, getUserPreference } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';
import { updateUserPreference } from '../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../redux/reducer/userReducer';
import { navigate } from '../../../redux/reducer/navigationReducer';

function SemanticTerms(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { termsList, isLoading } = useSelector((state) => state.term, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "terms", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "terms", "sorting");
    const { datatypes } = useSelector((state) => state.default);

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        "name": "",
        "description": "",
        "status": "All"
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [sorting, setSorting] = useState({
        sortBy: tableSorting?.sortBy ?? "",
        orderBy: tableSorting?.orderBy ?? "asc"
    });
    const { sortBy, orderBy } = sorting;

    /**
     * Table Headers
     */
    const termsTableHeader = [
        { key: 'name', name: 'Terms', width: "15%", sorting: true, isSearch: true, searchComponent: "text", searchKey: "name", clickable: true },
        { key: 'glossary_name', name: 'Domain', width: "15%", component: 'text_with_image', sorting: true, isSearch: true, searchComponent: "text", searchKey: "glossary_name", withImage: true, img_key: 'glossary_img' },
        { key: 'description', name: 'Description', sorting: true, component: "textbox", inlineEdit: true, datatype: "text", placeholder: "Enter description", tooltip: true, width: "20%", isSearch: true, searchComponent: "text", searchKey: "description", hideDefault: true, renderOnBlur: true, isDisabled: () => !semanticsPermission?.is_edit },
        { key: 'derived_type', name: 'Data Type', width: "10%", sorting: true, placeholder: 'Data Type', isSearch: true, component: "select", searchKey: "derived_type", selectComponentKey: 'name', isDisabled: () => !semanticsPermission?.is_edit, componentKey: 'name' },
        { key: 'status', name: 'Status', width: "10%", align: "center", sorting: true, component: "status", isSearch: true, searchComponent: "select", list: ["All", "Pending", "Ready For Review", "Verified"], searchKey: "status", hideDefault: true, isDisabled: () => !semanticsPermission?.is_edit },
        { key: 'is_primary_key', name: 'Primary Key', component: "switch", align: "center", isDisabled: () => !semanticsPermission?.is_edit, hideDefault: true },
        { key: 'is_unique', name: 'Is Unique', component: "switch", align: "center", isDisabled: () => !semanticsPermission?.is_edit, hideDefault: true },
        { key: 'is_blank', name: 'Is Blank', component: "switch", align: "center", isDisabled: () => !semanticsPermission?.is_edit, hideDefault: true },
        { key: 'is_null', name: 'Is Null', component: "switch", align: "center", isDisabled: () => !semanticsPermission?.is_edit, hideDefault: true },
        { key: 'length', label: 'length', name: 'Length', headerTooltipText: "Minimum and maximum length.", component: "range", align: "center", datatype: "integer", isDisabled: () => !semanticsPermission?.is_edit, hideDefault: true },
        { key: 'value', label: 'value', name: 'Range', headerTooltipText: "Minimum and maximum range.", component: "range", align: "center", datatype: "numeric", hideDefault: true, getDataType: (data) => data.derived_type, isDisabled: (data) => (data?.derived_type && (data?.derived_type?.toLowerCase() === "text" || data?.derived_type?.toLowerCase() === "bit")) || !semanticsPermission?.is_edit },
        { key: 'threshold', name: 'Threshold', sorting: true, inlineEdit: true, tooltip: true, hideDefault: true, defaultValue: 100, datatype: "numeric", component: "textbox", isDisabled: () => !semanticsPermission?.is_edit, validators: ['required', 'minNumber:1', 'maxNumber:100'], errorMessages: ['Threshold is required', 'Minimum Threshold is 1', 'Maxiumum Threshold is 100'] },
        {
            key: 'actions',
            name: 'Actions',
            width: '5%',
            align: "center",
            actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }]
        }
    ];

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(getTermsRequest());
    }, [dispatch]);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "terms", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };


    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = useCallback((sortBy, orderBy) => {
        setSorting({
            sortBy, orderBy
        });
        updatePreference({ sorting: { sortBy, orderBy } });
    }, [sorting]);

    /**
     * Handle Adding or Deleting Application
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = (item, actionType) => {
        if (actionType === 'delete') {
            setShowDeleteDialog({
                open: true,
                title: appConstants.dialogBox.delete,
                message: appConstants.dialogBox.applicationDeleteMessage,
                data: { id: item?.id }
            });
        }
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDeleteDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge", params) => {
        dispatch(deleteTermRequest(type === "purge" ? params.id : params.id));
        dialogCancelEventHandle();
    };

    /**
     * Prepare Filter Terms
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterTerms = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if ((searchFilters[key] !== "" && key !== 'status') || (key === 'status' && searchFilters[key] !== "All")) {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = data.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                    if (typeof (item[key]) === "object") {
                        const values = item?.[key]?.map((obj) => obj.name) || [];
                        return values.some((obj) => obj.toLowerCase().includes(searchFilters[key].toLowerCase()));
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = filterData.map((item) => ({
                ...item,
                description: item.description === null ? "" : item.description
            }));
            filterData = _.orderBy(
                filterData,
                [(item) => (_.isString(item[sortBy]) ? item[sortBy].toLowerCase() : item[sortBy])],
                [orderBy]
            );
        }
        return filterData;
    };

    /**
     * Filter Terms using UseMemo
     */
    const filterTerms = useMemo(() => prepareFilterTerms(termsList, searchData, sorting.sortBy, sorting.orderBy), [termsList, searchData, sorting.sortBy, sorting.orderBy]);

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestData = {
            ...item, [key]: value
        };
        dispatch(updateDetailRequest(requestData));
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, item) => {
        switch (key) {
            case "name":
                dispatch(navigate({ path: 'settings.semantics.terms.detail', state: { glossary: { "id": item.domain_id, "name": item.domain_name } }, params: [item.id] }));
                break;
            case "glossary_name":
                dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: { "id": item.domain_id, "name": item.domain_name } }, params: [item.glossary_id] }));
                break;
            default:
                break;
        }
    };

    return (
        <Grid container className={classes.termsPageContainer} justifyContent="space-between">
            <Grid item xs={12} className={classes.termsTableContainer}>
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        styleType="rowStriped"
                        stickyHeader
                        headers={permissionHeaders(termsTableHeader, semanticsPermission)}
                        data={filterTerms || []}
                        options={appConstants.tableOptions.common}
                        title={appConstants.labels.securityTab.terms}
                        description={''}
                        height="calc(100vh - 296px)"
                        NoResultText="No Terms Found"
                        isLoading={isLoading}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        searchData={searchData}
                        onHandleSearchEvent={onHandleSearchEvent}
                        onClickActions={handleTableActions}
                        onCompnentEvent={onCompnentEvent}
                        selectComponentList={
                            {
                                derived_type: datatypes
                            }
                        }
                        sortBy={sortBy}
                        orderBy={orderBy}
                        onClickSorting={onClickSorting}
                        onCellClick={handleCellClickEvent}
                    />
                </ValidatorForm>
            </Grid>
            {
                showDeleteDialog.open && (
                    <DialogComponent
                        open={showDeleteDialog.open}
                        title={showDeleteDialog.title}
                        message={showDeleteDialog.message}
                        data={showDeleteDialog.data}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle}
                    />
                )
            }
        </Grid>
    );
}

// default props
SemanticTerms.defaultProps = {
    classes: {}
};

// prop types
SemanticTerms.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticTermsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SemanticTerms);