import LoginBg from '../../assets/img/loginbgpattern.png';

const AuthStyles = (theme) => ({
    loginContainer: {
        height: '100vh',
        overflowY: 'hidden',
        background: `linear-gradient(112deg, #fff 0%, ${theme.palette.primary.main} 30%)`,
        '&:before': {
            background: `url(${LoginBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom right',
            content: "' ' !important",
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.5
        }
    },
    loginFormContainer: {
        position: 'relative',
        '& .versionContainer': {
            position: 'relative'
        }
    },
    loginSlider: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: '#fff',
        '& .slider': {
            '& .sliderGrid': {
                padding: '0 175px 0 100px'
            },
            '& .slickImages': {
                width: '100%',
                objectFit: 'contain'
            },
            '& .slick-track': {
                display: "flex",
                '& .slick-slide': {
                    display: "flex",
                    height: "auto",
                    alignItems: "center",
                    justifyContent: "center"
                }
            },
            '& .slick-active,slick-current .slickImages': {
            },
            '@media only screen and (min-width: 1600px)': {
                '& .sliderContent': {
                    maxWidth: '80%',
                    margin: 'auto auto 40px auto'
                }
            },
            '& .slick-dots': {
                bottom: "-30px !important",
                display: "flex",
                alignSelf: 'center',
                justifyContent: "center"
            }
        }
    },
    versiontext: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: "calc(100vh - 100px)",
        width: '100%',
        color: '#fff !important',
        textAlign: 'center',
        fontWeight: '500',
        '@media (max-width: 1400px)': {
            top: "calc(100vh - 75px) !important",
            fontSize: "10px !important",
            fontWeight: "300 !important"
        }
    },
    slider: {
        background: '#fff',
        width: '100%',
        '& .slider-item': {
            '&:focus-visible': {
                outline: 'none !important'
            }
        },
        '& .slick-dots li button:before': {
            fontSize: '12px',
            color: 'rgba(0,0,0,0.5)'
        },
        '& .slick-dots li.slick-active button:before': {
            color: 'rgba(0,0,0,0.5)'
        },
        '& .slickImages': {
            margin: 'auto'
        },
        '& .sliderItemContent': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-80%, -50%)',
            zIndex: 99,
            width: '50%',
            color: theme.palette.primary.contrastText
        },
        '& .sliderTitle': {
            marginBottom: 25
        },
        '& .sliderContent': {
        },
        '& .sliderItem': {
            margin: 'auto',
            maxWidth: '90%'
        },
        '& .sliderItem:before': {
            content: "' ' !important",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            // background: 'rgb(58 190 255 / 15%)',
            zIndex: '1'
        }
    }
});
export default AuthStyles;