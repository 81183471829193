const style = (theme) => ({
    measureContainer: {
        minHeight: 'calc(100vh - 205px)',
        alignContent: 'baseline',
        '& .btnTabsInline  .MuiTab-root': {
            marginRight: '1px',
            borderRadius: '0px'
        },
        '& .btnTabsInline.MuiTabs-root': {
            minHeight: '30px'
        },
        '& .searchBox': {
            padding: '0px 10px !important',
            background: '#fdfdfdbf'
        },
        '& .MuiTableContainer-root ': {
            background: 'transparent !important'
        },
        '& .chartCardItemContainer': {
            '@media only screen and (min-width: 1200px)': {
                '&.MuiGrid-container': {
                    flexWrap: 'nowrap'
                }
            }
        }
    },
    measureTable: {
        marginTop: '40px !important',
        width: '100%',
        '& tbody': {
            '& .MuiTableRow-root:nth-child(even)': {
                background: '#fff'
            },
            '& tr td': {
                textTransform: "capitalize",
                '&.name': {
                    textTransform: "unset !important"
                }
            }
        },
        '& .tableContainer': {
            height: 'auto !important',
            maxHeight: 'calc(100vh - 170px)'
        }
    },
    measureCard: {
        maxHeight: 100,
        height: 100,
        boxShadow: '0px 2px 8px #00000014',
        borderRadius: 4,
        background: '#fff',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        '&:hover': {
            outline: `2px solid ${theme.palette.secondary.main}40`
        },
        '&.selected': {
            outline: `2px solid ${theme.palette.secondary.main}40`
        }
    },
    nodataCardWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end !important'
    },
    nodataCard: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    chart: {
        paddingLeft: 20,
        width: "100%"
    }
});
export default style;