// Default Imports
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';

// Import Component
import { MeasureQualityHistory } from '../../../../../components/index.js';


function QualityDetail(props) {

    /**
     * Define Props
     */
    const { classes, data, onChangeFilter, selectedAlert, onHandleValueChange, handleBehaviourlEvent } = props;

    /**
     * Define Redux
     */
    const { result, length_enum_history, length_enum_dropdown, behavioral_history } = useSelector((state) => state.metric);

    /**
     * Get Selected Alert Index Using UseMemo
     */
    const getSelectedAlertIndex = (result) => {
        const index = result.findIndex((item) => item.id === selectedAlert);
        return index;
    };
    const highlightIndex = useMemo(() => getSelectedAlertIndex(result), [result]);

    return (
        <Grid item xs={12} className={classes.chartContainer}>
            <ValidatorForm onSubmit={() => { }} noValidate>
                <MeasureQualityHistory
                    data={data}
                    length_enum_history={length_enum_history}
                    length_enum_dropdown={length_enum_dropdown}
                    behavioral_history={behavioral_history}
                    onChangeFilter={onChangeFilter}
                    highlightIndex={highlightIndex}
                    onHandleValueChange={onHandleValueChange}
                    handleBehaviourlEvent={handleBehaviourlEvent} />
            </ValidatorForm>
        </Grid>
    );
}

// default props
QualityDetail.defaultProps = {
    classes: {},
    data: {},
    selectedAlert: "",
    onChangeFilter: () => { },
    onHandleValueChange: () => { },
    handleBehaviourlEvent: () => { }
};

// prop types
QualityDetail.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    selectedAlert: PropTypes.string,
    onChangeFilter: PropTypes.func,
    onHandleValueChange: PropTypes.func,
    handleBehaviourlEvent: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default React.memo(QualityDetail, areEqual);