import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid } from '@mui/material';
import { NavigationTabHeaderComponent } from '../../components/index.js';

//  Import Styles
import SemanticsStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Constant
import appConstants from '../../constants/appConstants.js';

// Import Actions
import { getTagsRequest } from '../../redux/reducer/tagsReducer.js';

function Semantics(props) {
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(getTagsRequest());
    }, [dispatch]);

    return (
        <Grid container justifyContent={"flex-end"} className={classes.SemanticsPageContainer}>
            <Grid item xs={12} align="right">
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                    >
                        <NavigationTabHeaderComponent
                            tabList={appConstants.tabs.securityTabs}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <Grid className={classes.tabsBody}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Semantics.defaultProps = {
    classes: {}
};

// prop types
Semantics.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Semantics);