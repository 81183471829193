import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableContainer, Table, TableHead, TableBody, Paper, IconButton, Box, TablePagination, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Import Components
import TableHeaderRowComponent from './tableHeaderRow.jsx';
import TableBodyRowComponent from './tableBodyRow.jsx';
import AddTableBodyRowComponent from './addTableBodyRow.jsx';
import TableHeaderComponent from './components/header/index.jsx';
import TableSearchComponent from './components/search/index.jsx';
import SubComponent from './components/subComponent/index.jsx';
import { NoResultComponent } from '../index';
import LazyLoadingBodyRowComponent from './lazyLoadingBodyRow.jsx';

// import Styles
import TableStyles from './style.jsx';

// import Images
import { PlusIcon } from '../../assets/svg/index.js';

// Import Helpers
import { exportCsv, orderList } from '../../helpers/appHelpers.js';

const TableRoot = (props) => {
    /**
     * Define Props
     */
    const { classes, headers, haveCheckBox, data, sortBy, orderBy, parentDetail, haveSubTable, defaultHeaderCheckBoxValue, subTableColSpan,
        subTable, onClickCheckbox, onClickSorting, onClickActions, onClickAccordian, onScrollChange, onScrollEnd, onCompnentEvent, tableData, styleType,
        onHandleChipDelete, onHandleChipAdd, onHandleChipSelect, searchData, onHandleSearchEvent, isAdd, selectComponentList, subTableHaveCheckbox,
        haveSubTableHeader, subTableActions, subTableIsAddData, onSubTableActions, onSubTableCompnentEvent, onSubTableHandleChipAdd, onSubTableHandleChipDelete, options,
        filters, title, countText, description, isLoading, onCellClick, height, NoResultText, highlightIndex, stickyHeader, showHeader, disableActions, pagination,
        exportParams, subComponent, subComponentList, subComponentOnChange, setHeight, onColumnChange, userPreferenceColumns, search, searchConfig, onSearchChange,
        defaultShowSearch, tableRef, textTransform, onHandleChipAcceptActions, onSubTableHandleChipAcceptActions, defaultAddRowData, onChangeAddRowData,
        disableAddRowActions, addRowProps, rowDraggable, handleRowDragAction, subTableRowDraggable, handleSubRowDragAction
    } = props;


    /**
     * Define States
     */
    const [visibleNewTableRowInput, setVisibleNewTableRowInput] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [columnSearchHeaders, setColumnSearchHeaders] = useState([]);
    const [showSearch, setSearch] = useState(defaultShowSearch);
    const [columnEdited, setColumnEdited] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dataList, setDataList] = useState([]);

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if ((event.target.scrollHeight - Math.floor(event.target.scrollTop) - 10) <= event.target.clientHeight && onScrollEnd) {
            onScrollEnd();
        }
    };

    /**
     * Style Type Condion
     * @param {*} styleType
     * @returns
     */
    const tableStyle = (styleType) => {
        switch (styleType) {
            case "collapse":
                return classes.collapseTable;
            case "rowStriped":
                return classes.rowStripedTable;
            default:
                return classes.stripedTable;
        }
    };

    /**
     * Handle Change Page
     * @param {*} event
     * @param {*} newPage
     */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /**
     * Handle Row Change
     * @param {*} event
     */
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const validateSubtable = (item) => {
        let is_enble = true;
        if ("hidesubtable" in item) {
            is_enble = !item.hidesubtable;
        }
        return is_enble;
    };

    /**
     * Download
     */
    const download = () => {
        const formatKeys = headers.filter((header) => header.downloadArrayKey).reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.downloadArrayKey }), {});
        const prepareDownloadData = exportParams.data || data.map((obj) => {
            const keys = Object.keys(obj);
            const formatValue = {};
            for (const key of keys) {
                let value = obj[key];
                const formatKey = formatKeys[key];
                if ((value instanceof Object) && formatKey) {
                    if (Array.isArray(value)) {
                        value = value.map((data) => data[formatKey]).toString();
                    } else {
                        value = value[formatKey];
                    }
                }
                formatValue[key] = value;
            }
            return formatValue;
        });
        const downloadHeader = headers.filter((header) => !header.skip_export || header.key !== "Actions" || header.key !== "actions").map((header) => header.key);
        const params = {
            headers: downloadHeader,
            data: prepareDownloadData,
            ...exportParams
        };
        exportCsv(params);
    };

    /**
     * Handle Change Event
     * @param {*} item
     * @param {*} name
     * @param {*} value
     */
    const handleChange = (item, name, value) => {
        if (!item.customFunction) {
            if (item.type === "columns") {
                let tableHeaderList = Object.assign([], tableHeaders);
                let searchHeaders = Object.assign([], columnSearchHeaders);
                if (name === "Select All") {
                    searchHeaders = searchHeaders.map((header) => {
                        return {
                            ...header,
                            showColumn: value
                        };
                    });
                    tableHeaderList = tableHeaderList.map((header) => {
                        return {
                            ...header,
                            showColumn: value
                        };
                    });
                } else {
                    const t_index = tableHeaderList.findIndex((header) => header.name === name);
                    const s_index = searchHeaders.findIndex((header) => header.name === name);
                    tableHeaderList[t_index].showColumn = value;
                    searchHeaders[s_index].showColumn = value;

                    const searchIndex = searchHeaders.findIndex((header) => header.name === "Select All");
                    if (tableHeaderList.length === tableHeaderList.filter((data) => data.showColumn).length) {
                        searchHeaders[searchIndex].showColumn = true;
                    } else {
                        searchHeaders[searchIndex].showColumn = false;
                    }
                }
                setColumnSearchHeaders([...searchHeaders]);
                setTableHeaders([...tableHeaderList]);
                setColumnEdited(true);
                onColumnChange([...tableHeaderList]);
            } else if (item.type === "search") {
                setSearch(!showSearch);
            } else if (item.type === "add") {
                setVisibleNewTableRowInput(true);
            } else if (item.type === "download") {
                download();
            }
        } else {
            item.customFunction(item, value);
        }
    };

    /**
     * Set Headers
     * @param {*} headerData
     */
    const loadHeaders = (headerData) => {
        const headerList = headerData.map((header) => {
            const showColumn = userPreferenceColumns.length ? userPreferenceColumns.includes(header.key) : !header.hideDefault;
            return {
                ...header,
                showColumn
            };
        });
        let columnSearchHeaders = headerList.filter((header) => !header.disableColumnSearch);
        columnSearchHeaders = orderList(columnSearchHeaders, 'name', 'asc');
        columnSearchHeaders.unshift({ name: "Select All", isDefault: true, showColumn: headerList.length === headerList.filter((data) => data.showColumn).length });
        setTableHeaders([...headerList]);
        setColumnSearchHeaders([...columnSearchHeaders]);
    };

    /**
     * Load Headers
     */
    useEffect(() => {
        if (((headers.length > 0) || !tableHeaders.length) && !columnEdited) {
            loadHeaders(headers);
        }
    }, [headers]);


    useEffect(() => {
        if (addRowProps?.isClosed) {
            setVisibleNewTableRowInput(false);
        }
    }, [addRowProps?.isClosed]);

    /**
     * Update headers
     */
    useEffect(() => {
        const tableHeaderData = tableHeaders.filter((header) => header.name !== "Select All").map((header) => header.name);
        const headerNames = headers.map((header) => header.name);
        if (columnEdited && headers.length && (JSON.stringify(tableHeaderData) !== JSON.stringify(headerNames))) {
            loadHeaders(headers);
        }
    }, [headers]);

    /**
     * Filter Headers
     * @param {*} data
     * @returns list
     */
    const filterHeaders = (data) => {
        return data.filter((header) => header.showColumn && header.name !== "Select All");
    };

    /**
     * Filter Headers using usememo
     */
    const filteredHeaders = useMemo(() => filterHeaders(tableHeaders), [tableHeaders]);

    /**
     * Get Table Data
     */
    const getTableData = (data, page, rowsPerPage) => {
        return pagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;
    };
    const tableDataList = useMemo(() => getTableData(data, page, rowsPerPage), [data, page, rowsPerPage]);

    /**
     * Set Table Data
     */
    useEffect(() => {
        setDataList(tableDataList);
    }, [tableDataList]);

    /**
     * Handle Table Row Drag and Drop Callback Event
     * @param {*} results
     * @returns
     */
    const handleDragEnd = (results) => {
        if (!results?.destination) {
            return;
        }
        const reorderedData = [...dataList];
        const [removed] = reorderedData.splice(results?.source.index, 1);
        reorderedData.splice(results.destination.index, 0, removed);
        setDataList(reorderedData);
        if (!subTableRowDraggable) {
            handleRowDragAction(reorderedData);

        }
        else {
            handleSubRowDragAction(reorderedData);
        }


    };

    return (
        <Grid item xs={12} className={tableStyle(styleType)}>
            {
                <Grid className="dflex alignCenter mb-1 tableHeaderGrid">
                    {
                        (options || filters) &&
                        <TableHeaderComponent
                            options={options}
                            filters={filters}
                            headers={columnSearchHeaders}
                            handleChange={handleChange}
                            title={title}
                            countText={countText}
                            description={description}
                            search={search}
                            searchConfig={searchConfig}
                            onSearchChange={onSearchChange}
                            searchData={searchData}
                            rowDraggable={rowDraggable}
                        />
                    }
                    {
                        isAdd &&
                        <Box sx={{ textAlign: 'right' }}>
                            <Tooltip title="Add" arrow>
                                <IconButton className={classes.PlusIcon} onClick={() => setVisibleNewTableRowInput(true)}>
                                    <PlusIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </Grid>
            }
            <TableContainer className={classNames(classes.tableContainer, "tableContainer")} ref={tableRef} component={Paper} style={{ height: pagination || stickyHeader || setHeight ? height : 'auto' }} onScroll={(event) => onScrollEvent(event)}>
                {
                    // eslint-disable-next-line no-inline-comments
                    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                        <Table onScroll={(event) => onScrollChange(event)} stickyHeader={stickyHeader} className={`${data && data.length === 0 ? classes.removeTableBorder : ''}`}>
                            {
                                ((visibleNewTableRowInput) || (subTable && !haveSubTableHeader)) && showHeader &&
                                <TableHead>
                                    <TableHeaderRowComponent
                                        classes={classes}
                                        headers={filteredHeaders}
                                        haveCheckBox={haveCheckBox}
                                        disableActions={disableActions}
                                        haveSubTable={haveSubTable}
                                        defaultHeaderCheckBoxValue={defaultHeaderCheckBoxValue}
                                        sortBy={sortBy}
                                        orderBy={orderBy}
                                        parentDetail={parentDetail}
                                        onClickCheckbox={(isChecked, selectedItem, parentId) => onClickCheckbox(isChecked, selectedItem, parentId, true)}
                                        onClickSorting={(sortBy, orderBy) => onClickSorting(sortBy, orderBy)}
                                        textTransform={textTransform}
                                        rowDraggable={rowDraggable}
                                    />
                                    {
                                        showSearch &&
                                        <TableSearchComponent
                                            headers={filteredHeaders}
                                            onChange={onHandleSearchEvent}
                                            searchData={searchData}
                                            haveCheckBox={haveCheckBox}
                                            rowDraggable={rowDraggable}
                                        />
                                    }
                                    {
                                        subComponent &&
                                        <SubComponent
                                            headers={filteredHeaders}
                                            subComponentList={subComponentList}
                                            subComponentOnChange={subComponentOnChange}
                                        />
                                    }
                                </TableHead>
                            }
                            <Droppable droppableId="TableBody" direction="vertical">
                                {
                                    (provided) => (

                                        <TableBody {...provided.droppableProps} ref={provided.innerRef} >
                                            {
                                                visibleNewTableRowInput &&
                                                <AddTableBodyRowComponent
                                                    classes={classes}
                                                    headers={filteredHeaders}
                                                    completeNewRowData={() => setVisibleNewTableRowInput(false)}
                                                    onHandleChipSelect={onHandleChipSelect}
                                                    onHandleChipDelete={onHandleChipDelete}
                                                    onClickActions={onClickActions}
                                                    haveCheckBox={haveCheckBox}
                                                    disableActions={disableActions}
                                                    haveSubTable={haveSubTable}
                                                    parentDetail={parentDetail}
                                                    selectComponentList={selectComponentList}
                                                    defaultAddRowData={defaultAddRowData}
                                                    onChangeAddRowData={onChangeAddRowData}
                                                    disableAddRowActions={disableAddRowActions}
                                                    rowDraggable={rowDraggable}
                                                    {...addRowProps}
                                                />
                                            }

                                            {
                                                dataList && dataList.map((item, index) => (
                                                    <Draggable key={`${item.id}_${index}`} draggableId={`${item.id}_${index}`} index={index}>
                                                        {
                                                            (provided) => (

                                                                <TableBodyRowComponent
                                                                    key={`bodyRow-${index}`}
                                                                    classes={classes}
                                                                    headers={filteredHeaders}
                                                                    data={((item instanceof Object) && item) || (tableData && tableData[item]) || {}}
                                                                    parentDetail={parentDetail}
                                                                    rowIndex={index}
                                                                    haveCheckBox={haveCheckBox}
                                                                    disableActions={disableActions}
                                                                    haveSubTable={haveSubTable && validateSubtable(item)}
                                                                    subTable={subTable}
                                                                    subTableColSpan={subTableColSpan}
                                                                    onClickCheckbox={onClickCheckbox}
                                                                    onClickActions={onClickActions}
                                                                    onClickAccordian={onClickAccordian}
                                                                    onCompnentEvent={onCompnentEvent}
                                                                    onCellClick={onCellClick}
                                                                    onHandleChipAdd={onHandleChipAdd}
                                                                    onHandleChipDelete={onHandleChipDelete}
                                                                    onHandleChipSelect={onHandleChipSelect}
                                                                    onHandleChipAcceptActions={onHandleChipAcceptActions}
                                                                    selectComponentList={selectComponentList || {}}
                                                                    subTableHaveCheckbox={subTableHaveCheckbox}
                                                                    haveSubTableHeader={haveSubTableHeader}
                                                                    subTableActions={subTableActions}
                                                                    subTableIsAddData={subTableIsAddData}
                                                                    onSubTableActions={onSubTableActions}
                                                                    onSubTableCompnentEvent={onSubTableCompnentEvent}
                                                                    onSubTableHandleChipAdd={onSubTableHandleChipAdd}
                                                                    onSubTableHandleChipDelete={onSubTableHandleChipDelete}
                                                                    onSubTableHandleChipAcceptActions={onSubTableHandleChipAcceptActions}
                                                                    onClickSorting={onClickSorting}
                                                                    highlightIndex={highlightIndex}
                                                                    provided={provided}
                                                                    rowDraggable={rowDraggable}
                                                                    handleSubRowDragAction={handleSubRowDragAction}
                                                                />
                                                            )
                                                        }
                                                    </Draggable>
                                                ))
                                            }

                                            {provided.placeholder}

                                            {
                                                isLoading &&
                                                <LazyLoadingBodyRowComponent headers={filteredHeaders} haveSubTable={haveSubTable} height={data.length > 0 ? 80 : height} />
                                            }

                                        </TableBody>
                                    )
                                }
                            </Droppable>

                        </Table>
                    </DragDropContext>

                }
                {
                    data && data.length === 0 && !isLoading && !visibleNewTableRowInput &&
                    <NoResultComponent title={NoResultText ? NoResultText : ''} height={height} />
                }
            </TableContainer>
            {
                pagination &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </Grid >
    );
};

/**
 * Define Component Props
 */
TableRoot.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    haveCheckBox: PropTypes.bool,
    disableAddRowActions: PropTypes.bool,
    defaultHeaderCheckBoxValue: PropTypes.bool,
    orderBy: PropTypes.string,
    sortBy: PropTypes.string,
    haveSubTable: PropTypes.bool,
    subTable: PropTypes.object,
    subTableColSpan: PropTypes.number,
    parentDetail: PropTypes.string,
    onClickCheckbox: PropTypes.func,
    onClickSorting: PropTypes.func,
    onClickActions: PropTypes.func,
    onClickAccordian: PropTypes.func,
    onCellClick: PropTypes.func,
    onScrollChange: PropTypes.func,
    onScrollEnd: PropTypes.func,
    onCompnentEvent: PropTypes.func,
    tableData: PropTypes.object,
    styleType: PropTypes.string,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    onHandleSearchEvent: PropTypes.func,
    searchData: PropTypes.object,
    isAdd: PropTypes.bool,
    selectComponentList: PropTypes.object,
    subTableHaveCheckbox: PropTypes.bool,
    haveSubTableHeader: PropTypes.bool,
    subTableActions: PropTypes.array,
    subTableIsAddData: PropTypes.bool,
    onSubTableActions: PropTypes.func,
    onSubTableCompnentEvent: PropTypes.func,
    onSubTableHandleChipAdd: PropTypes.func,
    onSubTableHandleChipDelete: PropTypes.func,
    options: PropTypes.array,
    filters: PropTypes.array,
    title: PropTypes.string,
    countText: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    NoResultText: PropTypes.string,
    highlightIndex: PropTypes.number,
    stickyHeader: PropTypes.bool,
    showHeader: PropTypes.bool,
    disableActions: PropTypes.bool,
    pagination: PropTypes.bool,
    exportParams: PropTypes.object,
    subComponent: PropTypes.bool,
    subComponentList: PropTypes.object,
    subComponentOnChange: PropTypes.func,
    setHeight: PropTypes.bool,
    onColumnChange: PropTypes.func,
    userPreferenceColumns: PropTypes.array,
    search: PropTypes.bool,
    searchConfig: PropTypes.object,
    onSearchChange: PropTypes.func,
    defaultShowSearch: PropTypes.bool,
    tableRef: PropTypes.element,
    textTransform: PropTypes.bool,
    onHandleChipAcceptActions: PropTypes.func,
    onSubTableHandleChipAcceptActions: PropTypes.func,
    defaultAddRowData: PropTypes.object,
    onChangeAddRowData: PropTypes.func,
    addRowProps: PropTypes.object,
    provided: PropTypes.object,
    rowDraggable: PropTypes.bool,
    handleRowDragAction: PropTypes.func,
    subTableRowDraggable: PropTypes.bool,
    handleSubRowDragAction: PropTypes.func
};

/**
 * Set Default Values
 */
TableRoot.defaultProps = {
    classes: {},
    headers: [],
    data: [],
    haveCheckBox: false,
    disableAddRowActions: false,
    defaultHeaderCheckBoxValue: false,
    orderBy: 'asc',
    sortBy: "",
    haveSubTable: false,
    subTable: {},
    parentDetail: "",
    subTableColSpan: 0,
    onClickCheckbox: () => { },
    onClickSorting: () => { },
    onClickActions: () => { },
    onClickAccordian: () => { },
    onScrollChange: () => { },
    onScrollEnd: () => { },
    onCompnentEvent: () => { },
    onCellClick: () => { },
    tableData: {},
    styleType: "collapse",
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    onHandleSearchEvent: () => { },
    searchData: {},
    isAdd: false,
    selectComponentList: {},
    subTableHaveCheckbox: false,
    haveSubTableHeader: false,
    subTableActions: [],
    subTableIsAddData: false,
    onSubTableActions: () => { },
    onSubTableCompnentEvent: () => { },
    onSubTableHandleChipAdd: () => { },
    onSubTableHandleChipDelete: () => { },
    options: [],
    filters: [],
    title: "",
    countText: "",
    description: "",
    isLoading: false,
    highlightIndex: -1,
    stickyHeader: false,
    showHeader: true,
    disableActions: false,
    pagination: false,
    exportParams: {},
    subComponent: false,
    subComponentList: {},
    subComponentOnChange: () => { },
    setHeight: false,
    onColumnChange: () => { },
    userPreferenceColumns: [],
    search: false,
    searchConfig: {
        placeholder: ""
    },
    onSearchChange: () => { },
    defaultShowSearch: false,
    tableRef: null,
    textTransform: true,
    onHandleChipAcceptActions: () => { },
    onSubTableHandleChipAcceptActions: () => { },
    defaultAddRowData: {},
    onChangeAddRowData: () => { },
    addRowProps: {},
    provided: {},
    rowDraggable: false,
    handleRowDragAction: () => { },
    subTableRowDraggable: false,
    handleSubRowDragAction: () => { }
};

export default withStyles(TableStyles)(TableRoot);