import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    list: [],
    tabs: [],
    groups: []
};

const fieldsSlice = createSlice({
    name: 'fields',
    initialState: { ...initialState },
    reducers: {
        getFieldsRequest(state) {
            state.isLoading = true;
        },
        getFieldsSuccess(state, action) {
            state.isLoading = false;
            state.list = action.payload.data || [];
        },
        getFieldsFailure(state) {
            state.isLoading = false;
        },
        createFieldsRequest() { },
        createFieldsSuccess(state, action) {
            const { data, tab, group } = action.payload.data;
            if (tab) {
                state.tabs.push({ ...tab });
            }
            if (group) {
                state.groups.push({ ...group });
            }
            state.list = [...state.list, data];
        },
        createFieldsFailure() { },
        updateFieldsRequest() { },
        updateFieldsSuccess(state, action) {
            const { data, tab, group } = action.payload.data;
            if (tab) {
                state.tabs.push({ ...tab });
            }
            if (group) {
                state.groups.push({ ...group });
            }
            const index = state.list.findIndex((obj) => obj.id === data.id);
            if (index !== -1) {
                state.list[index] = data;
                const tabs = [...new Set(state.list.map((obj) => obj.custom_field_tab))];
                state.tabs = state.tabs.filter((tab) => tabs.includes(tab.id));
                const groups = [...new Set(state.list.map((obj) => obj.custom_field_group))];
                state.groups = state.groups.filter((group) => groups.includes(group.id));
            }
        },
        updateFieldsFailure() { },
        deleteFieldsRequest() { },
        deleteFieldsSuccess(state, action) {
            const index = state.list.findIndex((obj) => obj.id === action.payload);
            if (index !== -1) {
                const currentData = state.list[index];
                state.list.splice(index, 1);
                const filterTab = state.list.some((obj) => obj.custom_field_tab === currentData.custom_field_tab);
                const filterGroup = state.list.some((obj) => obj.custom_field_group === currentData.custom_field_group);
                if (!filterTab) {
                    const tabIndex = state.tabs.findIndex((obj) => obj.id === currentData.custom_field_tab);
                    state.tabs.splice(tabIndex, 1);
                }
                if (!filterGroup) {
                    const groupIndex = state.groups.findIndex((obj) => obj.id === currentData.custom_field_group);
                    state.groups.splice(groupIndex, 1);
                }
            }
        },
        deleteFieldsFailure() { },
        getFieldsTabRequest() { },
        getFieldsTabSuccess(state, action) {
            state.tabs = action.payload.data || [];
        },
        getFieldsTabFailure() { },
        getFieldsGroupRequest() { },
        getFieldsGroupSuccess(state, action) {
            state.groups = action.payload.data || [];
        },
        getFieldsGroupFailure() { },
        updatePropertyRequest(state, action) {
            const index = state.list.findIndex((obj) => obj.id === action.payload.id);
            state.list[index] = {
                ...state.list[index],
                ...action.payload
            };
        }
    }
});

export const { getFieldsRequest, getFieldsSuccess, getFieldsFailure, createFieldsRequest, createFieldsSuccess, createFieldsFailure,
    updateFieldsRequest, updateFieldsSuccess, updateFieldsFailure, deleteFieldsRequest, deleteFieldsSuccess, deleteFieldsFailure,
    getFieldsTabRequest, getFieldsTabSuccess, getFieldsTabFailure, getFieldsGroupRequest, getFieldsGroupSuccess, getFieldsGroupFailure,
    updatePropertyRequest
} = fieldsSlice.actions;
export default fieldsSlice.reducer;