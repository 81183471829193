const style = () => ({
    drawerContainer: {
        '& .MuiDrawer-paper': {
            minWidth: 600,
            maxWidth: 600,
            padding: 30
        }
    }
});

export default style;