const style = (theme) => ({
    headerContainer: {
        background: '#F1F4F7',
        // background: '#FCFEFF',
        padding: '30px',
        borderBottom: `1px solid ${theme.palette.greyshades.lightgrey}`,
        boxShadow: '0px -1px 5px #0000001c',
        '& .starIcon svg': {
            paddingRight: 1
        },
        '& .starIcon .MuiTypography-root': {
            color: theme.palette.greyshades.darkgrey
        },
        '& .titleSection': {
            '& svg, path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .title': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 700,
            textOverflow: 'ellipsis'
        },
        '& .copyIcon': {
            marginLeft: 5,
            marginTop: 2,
            cursor: 'pointer',
            '&:hover': {
                '& .fillPath': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    verifiedChip: {
        height: '26px !important',
        background: '#54DF9A21 !important',
        borderRadius: '4px !important',
        padding: '0px 10px !important',
        '& .MuiChip-label': {
            paddingLeft: '6px',
            paddingRight: '0px'
        },
        '& svg': {
            fill: '#54DF9A !important'
        }
    },
    headerAction: {
        display: 'flex',
        '& .Mui-disabled': {
            opacity: '1 !important'
        },
        '& svg': {
            height: '18px'
        },
        '& .MuiIconButton-root': {
            padding: '3px !important',
            marginLeft: 10
        },
        '& svg.CalendarIcon': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.greyshades.darkgrey} !important`
            },
            '&:hover': {
                '& path, circle, ellipse': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .ShareIcon': {
            '&:hover .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .HeartIcon': {
            '&:hover .fillPath': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon2:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg:hover .fillPath': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    customChips: {
        '& .chip': {
            borderRadius: 4,
            padding: '2px 8px',
            marginLeft: 7
        }
    },
    copyDataset: {
        background: theme.palette.greyshades.lightestgrey,
        border: `1px solid ${theme.palette.greyshades.grey}`,
        cursor: 'pointer',
        borderRadius: '0px 4px 4px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 9px',
        height: 36,
        '& .datasetIcon img': {
            width: 20,
            height: 20,
            marginRight: 8,
            marginBottom: '-1px'
        },
        '& .datasetIcon.teradata img': {
            width: "auto",
            height: 12,
            marginRight: 8,
            marginBottom: '-1px'
        },
        '& .copyIcon': {
            background: '#fff',
            height: '34px',
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0px 4px 4px 0px',
            cursor: 'pointer',
            '&:hover ': {
                '& #Path_2746': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .datasetIcon ': {
            display: 'flex'
        },
        '& .MuiTypography-root': {
            lineHeight: '15px',
            maxWidth: '600px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        '@media only screen and (max-width: 1500px)': {
            '& .MuiTypography-root': {
                maxWidth: '400px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        },
        '&.nopointer': {
            cursor: 'default'
        }
    },
    bottomSection: {
        '& .AlertsIcon path': {
            fill: theme.palette.greyshades.darkgrey
        },
        '& .DangerIcon': {
            marginTop: '-2px'
        }
    },
    shareDialog: {
        '& .MuiPaper-root': {
            // overflow: 'hidden'
        }
    },
    shareContainer: {
        padding: 30,
        '& .CloseIcon': {
            width: '30px',
            height: '30px'
        },
        '& .closeButton': {
            width: 32,
            height: 32,
            padding: 5
        }
    },
    shareContainerImg: {
        width: '100%',
        objectFit: 'cover',
        height: '200px',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        objectPosition: 'top'
    },
    copyDatasetShare: {
        background: theme.palette.greyshades.lightestgrey,
        border: `1px solid ${theme.palette.greyshades.lightgrey}40`,
        borderRadius: '0px 4px 4px 0px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 9px',
        height: 36,
        maxWidth: 'calc(100% - 100px)',
        '& .datasetIcon img': {
            width: 20,
            height: 20,
            marginRight: 8,
            marginBottom: '-1px'
        },
        '& .datasetIcon.teradata img': {
            width: "auto",
            height: 12,
            marginRight: 8,
            marginBottom: '-1px'
        },
        '& .copyIcon': {
            background: '#fff',
            height: '34px',
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0px 4px 4px 0px',
            cursor: 'pointer',
            '&:hover ': {
                '& #Path_2746': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .datasetIcon ': {
            display: 'flex'
        },
        '& .MuiTypography-root': {
            lineHeight: '15px'
        }
    },
    bredcrumbContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px !important',
        '& .item': {
            display: 'flex',
            alignItems: 'center',
            '&:not(:first-child):before': {
                content: "' /' !important",
                padding: '0 14px',
                color: theme.palette.greyshades.darkgrey
            },
            '&.link:hover': {
                cursor: 'pointer',
                color: theme.palette.secondary.main
            }
        },
        '& .iconContainer': {
            width: 20,
            height: 20,
            background: theme.palette.greyshades.darkgrey,
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 7,
            '& svg': {
                width: '16px',
                height: '16px',
                fill: '#fff',
                '&.DatabaseIcon': {
                    width: '14px',
                    height: '14px',
                    '& path': {
                        fill: '#fff',
                        stroke: '#fff'
                    }
                }
            },
            '& .AssetIcon': {
                width: '13px',
                height: '13px',
                '& path': {
                    fill: '#fff'
                }
            },
            '& .AttributesIcon': {
                width: '13px',
                height: '13px'
            }
        }
    },
    moreActions: {
        '& .MuiListItemButton-root': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important'
        },
        '& .MuiListItem-root:hover': {
            '& .MuiTypography-root': {
                color: theme.palette.secondary.main
            },
            '& .PlayIcon2': {
                fill: `${theme.palette.secondary.main} !important`
            },
            '& svg.CalendarIcon': {
                '& path, circle, ellipse': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            },
            '& svg.DeleteIcon2': {
                '& path': {
                    fill: `${theme.palette.secondary.main} !important`,
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 34,
            '& svg': {
                width: 20,
                height: 20
            },
            '& .PlayIcon2': {
                width: 23,
                height: 23,
                marginLeft: '-2px',
                '&:hover': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            },
            '& svg.CalendarIcon': {
                width: '18px',
                height: '18px',
                '& path, circle, ellipse': {
                    fill: `${theme.palette.greyshades.darkgrey} !important`
                },
                '&:hover': {
                    '& path, circle, ellipse': {
                        fill: `${theme.palette.secondary.main} !important`
                    }
                }
            },
            '& svg.DeleteIcon2': {
                width: '17.326px',
                height: '17.173px',
                '&:hover ': {
                    '& path': {
                        fill: `${theme.palette.secondary.main} !important`,
                        stroke: `${theme.palette.secondary.main} !important`
                    }
                }
            }
        }
    },
    versionPopover: {
        '& .MuiPaper-root': {
            marginTop: '38px',
            marginLeft: '30px',
            height: 'calc(100% - 290px)',
            overflow: 'hidden'
        }
    }
});
export default style;