import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Import Material UI Componetns
import { Grid, Button, Typography } from '@mui/material';

//Import Styles
import ProfileDetailStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

//Import App Constants
import appConstants from '../../constants/appConstants.js';
import { PasswordInputComponent, PopOverComponent } from '../../components/index.js';
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import { Crypto } from '../../helpers/index.js';

// Import actions
import { changePasswordRequest } from '../../redux/reducer/authReducer.js';


function ChangePasswordPopover(props) {

    /**
     * Define Props & State
     */
    const dispatch = useDispatch();
    const { classes, open, anchorEl, onClose, data, isUserReset } = props;
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const { password, confirmPassword } = formData;

    /**
     * Check Password Policy
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('passwordPolicy', () => {
            if (password.length < 6) {
                return false;
            } else if (password.length > 50) {
                return false;
            } else if (password.search(/\d/) === -1 && password.search(/[!@#$^&*()_]/) === -1) {
                return false;
            } else if (password.search(/[a-z]/) === -1) {
                return false;
            } else if (password.search(/[A-Z]/) === -1) {
                return false;
            } return true;
        });
    }, [password]);

    /**
     * Check Password and Confirm Password Match
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', () => {
            if (confirmPassword !== password) {
                return false;
            }
            return true;
        });
    }, [password, confirmPassword]);

    /**
     * Handle Textbox Changes
     */
    const handleChange = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Handles the change password form submit event
     */
    const onChangePassword = () => {
        const encryptDecrypt = new Crypto();
        const requestParams = {
            password: encryptDecrypt.encrypt(password),
            first_name: data?.first_name ?? "",
            last_name: data?.last_name ?? "",
            isProfile: !isUserReset,
            id: data?.id ?? "",
            isUserReset: isUserReset,
            user_email: data?.email ?? ""
        };
        dispatch(changePasswordRequest(requestParams));
        onClose();
    };


    return (
        <Grid container className={classes.changePasswordPageContainer}>
            <PopOverComponent
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'top'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
                onClose={() => onClose()}
            >
                <ValidatorForm name="changepasswordForm" className={classes.changePasswordPageContainer} onSubmit={() => onChangePassword()} noValidate>
                    <Grid container className={classes.patternAddContainer} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {appConstants.labels.authentication.changePassword}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="pt-2">
                            <PasswordInputComponent
                                id="form_txt_newpassword"
                                name={'password'}
                                value={password}
                                fullWidth
                                placeholder={appConstants.labels.authentication.newPassword}
                                variant="outlined"
                                size="medium"
                                validators={['passwordRequired', 'passwordPolicy']}
                                errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.newPassword), appConstants.errorMessages.passwordPolicy]}
                                onChange={(event, value) => handleChange(event.target.name, value)}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} className="pt-2">
                            <PasswordInputComponent
                                id="form_txt_confirmpassword"
                                name={'confirmPassword'}
                                value={confirmPassword}
                                fullWidth
                                placeholder={appConstants.labels.authentication.confirmPassword}
                                variant="outlined"
                                size="medium"
                                validators={['passwordRequired', 'isPasswordMatch']}
                                errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.confirmPassword), appConstants.errorMessages.isPasswordMatch]}
                                onChange={(event, value) => handleChange(event.target.name, value)}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={() => onClose()}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="ml-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </PopOverComponent>
        </Grid>
    );
}

// default props
ChangePasswordPopover.defaultProps = {
    classes: {},
    open: false,
    anchorEl: null,
    data: {},
    onClose: () => { },
    isUserReset: false
};

// prop types
ChangePasswordPopover.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    data: PropTypes.object,
    isUserReset: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...ProfileDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ChangePasswordPopover);