import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getAllDashboardRequest, getAllDashboardSuccess, getAllDashboardFailure, getDashboardDetailRequest, getDashboardDetailSuccess, getDashboardDetailFailure,
    createDashboardRequest, createDashboardSuccess, createDashboardFailure, getWidgetDataRequest, getWidgetDataSuccess, getWidgetDataFailure, getWidgetListRequest, getWidgetListSuccess,
    getwidgetListFailure, createWidgetRequest, createWidgetSuccess, createWidgetFailure, updateDashboardRequest, updateDashboardSuccess, updateDashboardFailure, updateLayoutRequest, updateLayoutSuccess, updateLayoutFailure,
    updateWidgetRequest, updateWidgetSuccess, updateWidgetFailure, deleteWidgetRequest, deleteWidgetSuccess, deleteWidgetFailure, deleteDashboardRequest, deleteDashboardSuccess, deleteDashboardFailure,
    widgetMoveRequest, widgetMoveSuccess, widgetMoveFailure
} from '../reducer/dashboardReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from "../reducer/navigationReducer";

// Import Services
import { dashboardService } from '../service';

// Import Constant
import appConstants from '../../constants/appConstants';

/**
 * Get Dashboard
 * @returns
 */
const getDashboard = (action$) => action$.pipe(
    ofType(getAllDashboardRequest),
    mergeMap(() => {
        return from(dashboardService.getDashboard()).pipe(
            concatMap((res) => { return of(getAllDashboardSuccess(res)); }),
            catchError((error) => { return of(getAllDashboardFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Dashboard Detail
 * @param {*} action$
 * @returns
 */
const getDashboardDetail = (action$) => action$.pipe(
    ofType(getDashboardDetailRequest),
    mergeMap(({ payload: { dashboardId, token } }) => {
        return from(dashboardService.getDashboardDetail(dashboardId, token)).pipe(
            concatMap((res) => { return of(getDashboardDetailSuccess(res)); }),
            catchError(() => { return of(getDashboardDetailFailure()); })
        );
    })
);

/**
 * Create Dashboard
 * @param {*} action$
 * @returns
 */
const createDashboard = (action$) => action$.pipe(
    ofType(createDashboardRequest),
    mergeMap(({ payload }) => {
        return from(dashboardService.createDashboard(payload)).pipe(
            concatMap((res) => { return of(createDashboardSuccess(res)); }),
            catchError((error) => { return of(createDashboardFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create Dashboard
 * @param {*} action$
 * @returns
 */
const updateDashboard = (action$) => action$.pipe(
    ofType(updateDashboardRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(dashboardService.updateDashboard(id, rest)).pipe(
            concatMap((res) => { return of(updateDashboardSuccess(res)); }),
            catchError((error) => { return of(updateDashboardFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Widget Detail
 * @param {*} action$
 * @returns
 */
const getWidget = (action$) => action$.pipe(
    ofType(getWidgetDataRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(dashboardService.getWidgetdetail(id, rest)).pipe(
            concatMap((res) => { return of(getWidgetDataSuccess({ id: id, report: res.data })); }),
            catchError((error) => { return of(getWidgetDataFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Widget List
 * @param {*} action$
 * @returns
 */
const getWidgetList = (action$) => action$.pipe(
    ofType(getWidgetListRequest),
    mergeMap(() => {
        return from(dashboardService.getWidgetList()).pipe(
            concatMap((res) => { return of(getWidgetListSuccess(res)); }),
            catchError((error) => { return of(getwidgetListFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Widget Detail
 * @param {*} action$
 * @returns
 */
const createWidget = (action$) => action$.pipe(
    ofType(createWidgetRequest),
    mergeMap(({ payload }) => {
        return from(dashboardService.createWidget(payload)).pipe(
            concatMap((res) => {
                if (payload.type === "customWidget") {
                    return of(createWidgetSuccess(), navigate({ path: 'widget.detail', state: {}, params: [res.data.id] }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }));
                }
                return of(createWidgetSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }));
            }),
            catchError((error) => { return of(createWidgetFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Update Layout
 * @param {*} action$
 * @returns
 */
const updateLayout = (action$) => action$.pipe(
    ofType(updateLayoutRequest),
    mergeMap(({ payload }) => {
        return from(dashboardService.updateLayout(payload)).pipe(
            concatMap((res) => { return of(updateLayoutSuccess(res)); }),
            catchError((error) => { return of(updateLayoutFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Widget
 * @param {*} action$
 * @returns
 */
const updateWidget = (action$) => action$.pipe(
    ofType(updateWidgetRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(dashboardService.updateWidget(id, rest)).pipe(
            concatMap((res) => {
                if (rest.update_type === "widget") {
                    return of(updateWidgetSuccess({ data: res.data, type: "widget" }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
                }
                return of(updateWidgetSuccess(res));
            }),
            catchError((error) => { return of(updateWidgetFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Widget Detail
 * @param {*} action$
 * @returns
 */
const deleteWidget = (action$) => action$.pipe(
    ofType(deleteWidgetRequest),
    mergeMap(({ payload }) => {
        return from(dashboardService.deleteWidget(payload)).pipe(
            concatMap(() => { return of(deleteWidgetSuccess(payload)); }),
            catchError((error) => { return of(deleteWidgetFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get Widget Detail
 * @param {*} action$
 * @returns
 */
const deleteDashboard = (action$) => action$.pipe(
    ofType(deleteDashboardRequest),
    mergeMap(({ payload }) => {
        return from(dashboardService.deleteDashboard(payload)).pipe(
            concatMap(() => { return of(deleteDashboardSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted })); }),
            catchError((error) => { return of(deleteDashboardFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Move Widget
 * @param {*} action$
 * @returns
 */
const moveWidget = (action$) => action$.pipe(
    ofType(widgetMoveRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(dashboardService.updateWidget(id, rest)).pipe(
            concatMap(() => { return of(widgetMoveSuccess(id), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.widgetMove })); }),
            catchError((error) => { return of(widgetMoveFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Dashboard Functions
export const dashboardEpic = [
    getDashboard, getDashboardDetail, createDashboard, getWidget, getWidgetList, createWidget, updateDashboard, updateLayout,
    updateWidget, deleteWidget, deleteDashboard, moveWidget
];