import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { getWorkLogStatus } from '../../helpers/appHelpers';

const initialState = {
    isLoading: false,
    issuesList: [],
    search: { sortBy: "time", orderBy: "desc", offset: 0, limit: 20, search_by: {}, days: 3, status: 'all' },
    issuesLoading: false,
    loadMore: true,
    detail: {},
    id: null,
    worklog: [],
    comments: [],
    attachments: [],
    addedScreenShot: true,
    statistics: {
        new: { count: 0, issues: [] },
        inprogress: { count: 0, issues: [] },
        resolved: { count: 0, issues: [] },
        all: { count: 0, issues: [] }
    },
    shareDetails: {
        link: '',
        type: 'share_issue',
        trigger_type: 'manual',
        title: '',
        file: '',
        base64: '',
        name: '',
        message: '',
        asset_name: '',
        attribute_name: '',
        channels: ['email']
    },
    exportLoading: false
};

const issuesSlice = createSlice({
    name: 'issues',
    initialState: { ...initialState },
    reducers: {
        selectIssue(state, action) {
            state.id = action.payload;
        },
        getIssuesRequest(state, action) {
            state.issuesLoading = true;
            const clear = action.payload?.clear ?? false;

            if (clear) {
                state.issuesList = [];
                state.loadMore = true;
            }
        },
        getIssuesSuccess(state, action) {
            state.search = { ...state.search, ...action.payload.params };
            const issues = action.payload?.data ?? [];
            const issuesList = issues.map((issue) => {
                return {
                    ...issue,
                    time: moment(issue.created_date).fromNow(),
                    users: issue.users ? JSON.parse(issue.users) : []
                };
            });

            if ((issuesList.length < state.search.limit)) {
                state.loadMore = false;
                state.issuesList = [...state.issuesList, ...issuesList];
            } else {
                state.issuesList = [...state.issuesList, ...issuesList];
            }
            state.issuesLoading = false;
        },
        getIssuesFailure(state) {
            state.issuesLoading = false;
        },
        getIssuesDetailRequest(state) {
            state.isLoading = true;
        },
        getIssuesDetailSuccess(state, action) {
            state.detail = action.payload.data || {};
            state.shareDetails = {
                ...state.shareDetails,
                message: state.detail?.description ?? '',
                title: state.detail?.asset_name ?? '',
                asset_name: state.detail?.asset_name ?? '',
                attribute_name: state.detail?.attribute_name ?? ''
            };
            state.isLoading = false;
        },
        getIssuesDetailFailure(state) {
            state.isLoading = false;
        },
        createIssuesRequest(state) {
            state.isLoading = true;
        },
        createIssuesSuccess(state, action) {
            state.detail = action.payload.data || {};
            state.id = action.payload.data?.id || null;

            state.shareDetails = {
                ...state.shareDetails,
                message: state.detail?.description ?? '',
                title: state.detail?.asset_name ?? '',
                asset_name: state.detail?.asset_name ?? '',
                attribute_name: state.detail?.attribute_name ?? ''
            };

            state.isLoading = false;
        },
        createIssuesFailure(state) {
            state.isLoading = false;
        },
        updateIssuesRequest() { },
        updateIssuesSuccess() { },
        updateIssuesFailure() { },
        deleteIssuesRequest() { },
        deleteIssuesSuccess(state, action) {
            const index = state.issuesList.findIndex((issue) => issue.id === action.payload);
            if (index > -1) {
                state.issuesList.splice(index, 1);
            }
        },
        deleteIssuesFailure() { },
        clearState(state) {
            state.detail = {};
            state.id = null;
            state.worklog = [];
            state.attachments = [];
            state.addedScreenShot = true;
            state.shareDetails = {
                ...state.shareDetails,
                link: '',
                type: 'share_issue',
                file: '',
                base64: '',
                title: '',
                asset_name: '',
                attribute_name: ''
            };
        },
        updateIssueProperty(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        getWorkLogRequest(state) {
            state.isLoading = true;
        },
        getWorkLogSuccess(state, action) {
            const workLog = action.payload.data || [];
            state.worklog = workLog.map((data) => {
                return {
                    ...data,
                    day: moment(data.created_date).fromNow(),
                    activity: getWorkLogStatus(data)

                };
            });
            state.isLoading = false;
        },
        getWorkLogFailure() { },
        createCommentRequest() { },
        createCommentSuccess(state, action) {
            const responseData = action.payload.data;
            responseData.created_by = action.payload.requestData.user;
            state.comments.unshift(responseData);
        },
        createCommentFailure() { },
        getCommentRequest() { },
        getCommentSuccess(state, action) {
            state.comments = action.payload.data || [];
        },
        getCommentFailure() { },
        updateCommentRequest() { },
        updateCommentSuccess(state, action) {
            const comments = [...state.comments];
            const index = comments.findIndex((comment) => comment.id === action.payload.data.id);
            comments[index] = {
                ...comments[index],
                ...action.payload.data
            };
            state.comments = [...comments];
        },
        updateCommentFailure() { },
        deleteCommentRequest() { },
        deleteCommentSuccess(state, action) {
            const comments = [...state.comments];
            const index = comments.findIndex((comment) => comment.id === action.payload);
            comments.splice(index, 1);
            state.comments = [...comments];
        },
        deleteCommentFailure() { },
        createAttachmentRequest(state) {
            state.isLoading = true;
        },
        createAttachmentSuccess(state, action) {
            const attachments = [...state.attachments];
            const attachment = action.payload.data || [];
            attachments.unshift(attachment);
            state.attachments = [...attachments];
            state.addedScreenShot = true;
            state.isLoading = false;
        },
        createAttachmentFailure(state) {
            state.isLoading = false;
        },
        getAttachmentRequest(state) {
            state.isLoading = true;
        },
        getAttachmentSuccess(state, action) {
            state.attachments = action.payload.data || [];
            if (action.payload?.data?.length === 0) {
                state.addedScreenShot = false;
            }
            state.isLoading = false;
        },
        getAttachmentFailure(state) {
            state.isLoading = false;
        },
        getStatisticsRequest() { },
        getStatisticsSuccess(state, action) {
            const data = action.payload.data || {};
            state.statistics = {
                ...state.statistics,
                ...data
            };
        },
        getStatisticsFailure() { },
        updateIssue(state, action) {
            const index = state.issuesList.findIndex((issue) => issue.id === action.payload.id);
            if (index > -1) {
                state.issuesList[index] = { ...state.issuesList[index], ...action.payload };
            }
        },
        shareRequest() { },
        shareSuccess() { },
        shareFailure() { },
        updateShareDetails(state, action) {
            state.shareDetails = { ...state.shareDetails, ...action.payload };
        },
        clearShareDetails(state) {
            state.shareDetails = {
                ...state.shareDetails,
                name: '',
                message: '',
                channels: ['email']
            };
        },
        updateWatchRequest(state) {
            state.isLoading = true;
        },
        updateWatchSuccess(state) {
            state.detail = { ...state.detail, "is_watch": !state.detail.is_watch };
            state.isLoading = false;
        },
        updateWatchFailure(state) {
            state.isLoading = false;
        },
        removeAttachmentRequest() { },
        removeAttachmentSuccess() { },
        removeAttachmentFailure() { },
        exportRequest(state) {
            state.exportLoading = true;
        },
        exportSuccess(state) {
            state.exportLoading = false;
        },
        exportFailure(state) {
            state.exportLoading = false;
        }
    }
});

export const { selectIssue, getIssuesRequest, getIssuesSuccess, getIssuesFailure, getIssuesDetailRequest, getIssuesDetailSuccess, getIssuesDetailFailure,
    createIssuesRequest, createIssuesSuccess, createIssuesFailure, updateIssuesRequest, updateIssuesSuccess, updateIssuesFailure, deleteIssuesRequest, deleteIssuesSuccess,
    deleteIssuesFailure, clearState, updateIssueProperty, getWorkLogRequest, getWorkLogSuccess, getWorkLogFailure, createCommentRequest, createCommentSuccess, createCommentFailure,
    getCommentRequest, getCommentSuccess, getCommentFailure, updateCommentRequest, updateCommentSuccess, updateCommentFailure, deleteCommentRequest, deleteCommentSuccess, deleteCommentFailure, setIssueParams,
    createAttachmentRequest, createAttachmentSuccess, createAttachmentFailure, getAttachmentRequest, getAttachmentSuccess, getAttachmentFailure, getStatisticsRequest, getStatisticsSuccess, getStatisticsFailure,
    updateIssue, shareRequest, shareSuccess, shareFailure, updateShareDetails, clearShareDetails, updateWatchRequest, updateWatchSuccess, updateWatchFailure,
    removeAttachmentRequest, removeAttachmentSuccess, removeAttachmentFailure, exportRequest, exportSuccess, exportFailure
} = issuesSlice.actions;
export default issuesSlice.reducer;