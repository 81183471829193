import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, IconButton, MenuItem, Popover, Typography } from '@mui/material';

//import Styles
import SidebarStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';
import { ChevDownIcon, ChevLeftIcon, ChevRightIcon, CloseIcon, FileIcon2, FolderIcon, MoreIcon } from '../../../../../assets/svg/index.js';
import { TreeItem, TreeView } from '@mui/x-tree-view';
// import GlossaryAdd from '../../domain/components/add/index.jsx';
import DetailAdd from '../../detail/components/add/index.jsx';

import { getTreeRequest, selectCategory, selectType, deleteGlossaryRequest, deleteCategoryRequest, deleteTermRequest, resetSelectCategory } from '../../../../../redux/reducer/semanticReducer';
import { navigate } from '../../../../../redux/reducer/navigationReducer';

//import Images

function Sidebar(props) {
    /**
     * Define Props
     */
    const { classes, collapseState, collapseChange, semanticsPermission } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const { tree, type } = useSelector(({ semantic }) => semantic);

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [cardAnchorEl, setCardAchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    /**
     * Handle Click
     * @param {*} event
     * @param {*} item
     */
    const handleClick = (event, item) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        const params = {
            ...item, glossary_id: item.type === "glossary" ? item.id : item.glossary_id
        };
        if (item.type === "glossary") {
            delete params.id;
        }
        setSelectedItem({ ...params });
    };

    /**
     * Handle Close
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Handle Card
     * @param {*} event
     * @param {*} type
     */
    const handleCard = (event, type) => {
        setCardAchorEl(event.currentTarget);
        dispatch(selectType(type));
        dispatch(selectCategory({ ...selectedItem }));
    };

    /**
     * Handle Close Card
     */
    const handleCloseCard = () => {
        setCardAchorEl(null);
        dispatch(resetSelectCategory());
    };

    /**
     * Get Category
     * @param {*} categories
     * @param {*} terms
     */
    const getCategory = (categories, terms) => {
        for (const category of categories) {
            const term = terms.filter((term) => term.category_id === category.id);
            category.type = "category";
            category.children = [...term];
        }
    };

    /**
     * Get Tree Data
     * @param {*} data
     * @returns
     */
    const getTreeData = (data) => {
        return data.map((item) => {
            const category = item.category && typeof (item.category) === "string" ? JSON.parse(item.category) : [];
            let term = item.term && typeof (item.term) === "string" ? JSON.parse(item.term).map((obj) => {
                return {
                    ...obj,
                    type: "term"
                };
            }) : [];
            const categoryTerm = term.filter((obj) => obj.glossary_id !== item.id);
            getCategory(category, categoryTerm);
            const fullData = [...category, ...categoryTerm];
            const nestedArray = [];
            const createNestedArray = (array, parentId) => {
                const nestedChildren = array
                    .filter((item) => item.parent_id === parentId)
                    .map((item) => {
                        return {
                            ...item,
                            children: createNestedArray(array, item.id)
                        };
                    });


                return nestedChildren;
            };
            nestedArray.push(...createNestedArray(fullData, item.id));
            term = term.filter((obj) => obj.glossary_id === item.id);
            return {
                name: item.name,
                type: item.type,
                technical_name: item.technical_name,
                id: item.id,
                children: [...nestedArray, ...term]
            };
        });
    };

    const treeData = useMemo(() => getTreeData(tree), [tree, selectedItem]);

    /**
     * onSelect Item
     * @param {*} item
     */
    const onSelectItem = (item) => {
        if (item.type === "glossary") {
            dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: item }, params: [item.id] }));
        } else {
            const glossary = treeData.find((data) => data.id === (item.glossary_id || item.domain_id));
            if (item.type === "category") {
                dispatch(navigate({ path: 'settings.semantics.domains.category', state: { glossary: glossary, category: item }, params: [item.glossary_id, item.id] }));
            } else {
                const category = glossary?.children.find((data) => data.id === item.parent_id);
                dispatch(navigate({ path: 'settings.semantics.domains.term', state: { category: category || {}, glossary: glossary }, params: [item.id] }));
            }

        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const renderTree = (node) => {
        return (
            <TreeItem nodeId={`${node.type}_${node.id}`}
                key={node.id}
                label={
                    <Grid className={classes.treeItem}>
                        {
                            node.type === "glossary" ?
                                <Typography variant="h5" onClick={() => onSelectItem(node)}>
                                    {node.name}
                                </Typography> :
                                <Typography variant="body1" className="IconText" onClick={() => onSelectItem(node)}>
                                    {node.type === "category" ? <FolderIcon /> : <FileIcon2 />}
                                    {node.name}
                                </Typography>
                        }
                        {
                            semanticsPermission?.is_edit &&
                            <IconButton onClick={(event) => handleClick(event, node)}>
                                <MoreIcon />
                            </IconButton>
                        }
                    </Grid>
                }>
                {Array.isArray(node.children) ? node.children.map((child, childIndex) => renderTree(child, childIndex)) : null}
            </TreeItem>
        );
    };

    const onDelete = () => {
        if (selectedItem.type === "glossary") {
            dispatch(deleteGlossaryRequest(selectedItem.glossary_id));
        } else if (selectedItem.type === "category") {
            dispatch(deleteCategoryRequest(selectedItem.id));
        } else {
            dispatch(deleteTermRequest(selectedItem.id));
        }
        handleClose();
    };

    useEffect(() => {
        dispatch(getTreeRequest());
    }, []);

    return (
        <Grid className={`${classes.sidebarContainer} ${!collapseState && 'collapsedSidebar'}`}>
            <Grid className={`${classes.iconBg} ${!collapseState && 'collapsed'}`} onClick={collapseChange}>
                <ChevLeftIcon />
            </Grid>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ChevDownIcon />}
                defaultExpandIcon={<ChevRightIcon />}
                sx={{ flexGrow: 1, maxWidth: 300, overflowY: 'auto' }}
            >

                {
                    treeData.map((item, index) =>
                        renderTree(item, index)
                    )
                }
            </TreeView >
            <Popover
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'center',
                        horizontal: 'left'
                    }
                }
            >
                <Grid className={classes.options}>
                    {
                        selectedItem && selectedItem.type !== "term" && semanticsPermission?.is_edit &&
                        <MenuItem onClick={(event) => { handleCard(event, "category"); handleClose(); }}>
                            {"Add Sub Domain"}
                        </MenuItem>
                    }
                    {
                        selectedItem && selectedItem.type !== "term" && semanticsPermission?.is_edit &&
                        <MenuItem onClick={(event) => { handleCard(event, "term"); handleClose(); }}>
                            {"Add Term"}
                        </MenuItem>
                    }
                    {
                        semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => onDelete()} sx={{ color: "#F38080" }}>
                            {"Delete this item"}
                        </MenuItem>
                    }
                </Grid>
            </Popover >
            {
                Boolean(cardAnchorEl) &&
                <Popover
                    anchorEl={cardAnchorEl}
                    open={Boolean(cardAnchorEl)}
                    onClose={handleCloseCard}
                    className="addPopover"
                    anchorOrigin={
                        {
                            vertical: 'top',
                            horizontal: 'left'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'top',
                            horizontal: 'left'
                        }
                    }
                >
                    <Grid className={classes.addContainer}>
                        <Grid container justifyContent={"space-between"} className="pb-1">
                            <Typography variant="h6">
                                {`Add ${type === "category" ? "Category" : "Term"} `}
                            </Typography>
                            <IconButton onClick={handleCloseCard} className="p0 closeBtn">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <DetailAdd addType="sidebar" selectedItem={selectedItem} onClose={handleCloseCard} />
                    </Grid>
                </Popover>
            }
        </Grid>
    );
}

// default props
Sidebar.defaultProps = {
    classes: {},
    collapseChange: () => { },
    collapseState: true,
    semanticsPermission: {}
};

// prop types
Sidebar.propTypes = {
    classes: PropTypes.object,
    collapseChange: PropTypes.func,
    collapseState: PropTypes.bool,
    semanticsPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SidebarStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Sidebar);