const style = (theme) => ({
    prevContainer: {
        padding: '24px 30px',
        paddingTop: '0px',
        '& .CloseIcon': {
            width: '22px',
            height: '22px'
        },
        '& .EditIcon': {
            width: '17px',
            height: '17px',
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& .TickIcon': {
            marginBottom: '-5px !important'
        },
        '& .tickIconBtn': {
            width: 33,
            height: 33,
            padding: '0px'
        },
        '& .headerContainer': {
            paddingTop: 24,
            paddingBottom: 16,
            top: 0,
            position: 'sticky',
            background: '#fff',
            zIndex: 1
        }
    },
    previmageConatiner: {
        width: '100%',
        height: '100%'
    },
    chartConatiner: {
        padding: 24,
        borderRadius: 3,
        border: '1px solid #ECEFF2',
        background: '#FCFDFD'
    },
    prevImage: {
        objectFit: 'contain',
        width: '100%',
        height: '100%'
    },
    listContainer: {
        marginTop: '20px !important'
    },
    tabHeaderContainer: {
        '& .btnTabsInline': {
            width: '100%'
        }
    },
    tabsBody: {
        '& .MuiBox-root': {
            padding: '0px 0px 24px !important'
        }
    }
});
export default style;