const style = (theme) => ({
    issuesDetailContaier: {
        paddingTop: '40px !important',
        paddingBottom: '24px !important',
        padding: '0px 24px',
        background: '#fff',
        '& .btnTabsInline .MuiTab-root': {
            fontSize: `${theme.palette.headers.h4.size}px !important`
        }
    },
    tabsBody: {
        '& .MuiBox-root': {
            padding: '0px !important'
        }
    }
});
export default style;