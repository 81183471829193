import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Components
import Queries from './queries/index.jsx';

//  Import Styles
import UsageStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

function Usage(props) {

    /**
     * Define Props
     */
    const { classes } = props;

    /**
     * Define State
     */

    return (
        <Grid container className={classes.usagePageContainer}>
            <Queries />
        </Grid>
    );
}

// default props
Usage.defaultProps = {
    classes: {}
};

// prop types
Usage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...UsageStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Usage);