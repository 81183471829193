const style = (theme) => ({
    connectorForm: {
        padding: "30px 34px 0px",
        // boxShadow: '0px 0px 4px #00000014',
        marginBottom: 20,
        background: `${theme.palette.primary.contrastText}`,
        width: '100%',
        height: 'calc(100% - 20px)',
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px !important'
        },
        '& .Mui-expanded, .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionDetails-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        },
        '& .accordian-header.MuiAccordionSummary-root': {
            minHeight: `40px !important`,
            padding: '14px 24px !important',
            background: `${theme.palette.greyshades.lightestgrey} !important`
        }
    },
    connectorFormContainer: {
        padding: 24
    },
    connectorIcon: {
        marginRight: '16px !important',
        '& .connectorCardImg': {
            height: '40px !important'
        },
        '& svg': {
            fontSize: '36px !important',
            width: '36px !important',
            height: '36px !important'
        },
        '& img': {
            height: 45,
            maxWidth: 135,
            objectFit: 'contain'
        }
    },
    formContainer: {
        marginTop: '35px !important',
        paddingBottom: '20px'
    },
    checkBox: {
        '& .MuiTypography-root': {
            paddingLeft: '0px !important'
        }
    },
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px'
    },
    mappingContainer: {
        padding: 10
    },
    mappingListContainer: {
        maxHeight: 230,
        overflowY: 'auto'
    }
});
export default style;