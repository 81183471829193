const style = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    scheduleContainer: {
        maxWidth: 450,
        minWidth: 450,
        // padding: '15px 25px',
        '& .MuiTab-root': {
            fontSize: `${theme.palette.headers.h6.size}px !important`
        },
        '& .active': {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.background.paper} !important`
        },
        '& .MuiInput-input': {
            fontSize: '14px !important'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiBox-root': {
            padding: '5px 20px 5px'
        }
    },
    inputStyling: {
        // padding: '24px',
        '& .MuiInput-root, & .selectComponent .MuiOutlinedInput-root': {
            height: '38px',
            padding: '10.5px',
            borderRadius: 3,
            background: theme.palette.greyshades.lightgrey
        },
        '& .MuiSelect-select': {
            paddingLeft: '0px'
        },
        '& .MuiInputLabel-root': {
            zIndex: 1
        },
        '& .MuiFormLabel-root': {
            transform: 'translate(10px, 25px) scale(1)'
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottom: '2px solid #ef6464 !important'
        },
        '&.timeZone .MuiInput-input': {
            marginTop: '-10px !important'
        }
    },
    schedulerSection: {
        // border: `1px solid ${theme.palette.greyshades.lightgrey}`
    },
    tabBtn: {
        fontSize: '13px !important',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    dayBtn: {
        fontSize: '12px !important',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    TimingBtnGroup: {
        '& .MuiButtonGroup-grouped': {
            minWidth: "80px"
        }
    },
    monitoringContainer: {
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
    timeZone: {
        '& .MuiPaper-root': {
            left: 'auto !important',
            bottom: '0px !important',
            position: 'absolute'
        }
    },
    repeatSection: {
        marginTop: '0px !important',
        width: '100%',
        justifyContent: 'space-between',
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px !important',
            background: theme.palette.greyshades.lightgrey,
            borderRadius: 4
        },
        '& .MuiOutlinedInput-root': {
            background: theme.palette.greyshades.lightgrey,
            height: '38.59px',
            paddingRight: '8px'
        },
        '& .MuiSelect-select': {
            background: 'transparent !important'
        },
        '& .numberSection': {
            width: 120
        },
        '& .selectDays': {
            '& .MuiFormControl-root': {
                minWidth: 160
            }
        }
    },
    sheduleDays: {
        '& .MuiButton-root ': {
            borderRadius: '30px !important',
            height: '35px',
            width: '35px',
            minWidth: '35px !important',
            marginRight: '10px',
            color: '#222',
            backgroundColor: theme.palette.greyshades.lightgrey,
            '&:hover': {
                color: `${theme.palette.primary.contrastText} !important`
            }
        }
    },
    calenderContainer: {
        display: 'block',
        maxWidth: 290,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px  10px"
    },
    dayContainer: {
        display: 'inline-flex',
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        cursor: 'pointer',
        margin: 2,
        '&:hover': {
            backgroundColor: 'rgba(237, 242, 244, 0.5)'
        }
    },
    daySelected: {
        color: `${theme.palette.background.paper} !important`,
        fontWeight: '500 !important',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    }
});

export default style;