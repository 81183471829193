const style = () => ({
    propertiesDescription: {
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px !important',
        '& .MuiOutlinedInput-root': {
            maxWidth: 130,
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '6.5px 8px',
            textAlign: 'center',
            fontSize: 15
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        },
        '& .MuiGrid-root.textBoxContainer.w-100': {
            width: 'auto !important'
        }
    }
});
export default style;