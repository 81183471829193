import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography } from '@mui/material';
import { SelectComponent, LoaderButtonComponent, LazyLoadSelectCheckBox, SelectCheckBoxComponent } from '../../../../components/index.js';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { exportMetadataRequest, exportMeasureRequest, exportUsersRequest } from '../../../../redux/reducer/importExportReducer';
import { assetService } from '../../../../redux/service/assetService.js';
import { attributeService } from '../../../../redux/service/attributeService.js';
import { baseMeasureService } from '../../../../redux/service/baseMeasureService.js';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

function Export(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const pageLimit = 20;

    const standaloneMeasureTypes = [{ id: "query", name: "Query" }, { id: "comparison", name: "Comparison" }];
    const assetMeasureTypes = [{ id: "auto", name: "Auto" }, { id: "conditional", name: "Conditional" }, { id: "query", name: "Query" }, { id: "behavioral", name: "Behavioral" }, { id: "lookup", name: "Lookup" }];

    /**
     * Redux Store
     */
    const exportLoading = useSelector(({ importExport }) => importExport.exportLoading);
    /**
     * Define State
     */
    const [exportParams, setExport] = useState({
        "export_type": "Metadata",
        "export_file_type": "Excel",
        "assets": [],
        "attributes": [],
        "measure_level": "Asset"
    });
    const [assets, setAssets] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [assetPagination, setAssetPagination] = useState({
        hasMore: true,
        loading: false,
        limit: pageLimit,
        offset: 0,
        search: ""
    });
    const [attributePagination, setAttributePagination] = useState({
        hasMore: true,
        loading: false,
        limit: pageLimit,
        offset: 0,
        search: ""
    });
    const [measurePagination, setMeasurePagination] = useState({
        hasMore: true,
        loading: false,
        limit: pageLimit,
        offset: 0,
        search: ""
    });

    /**
     * Get File Name
     * @returns
     */
    const getFileName = () => {
        let fileName = `Export(type).${exportParams.export_file_type === "Excel" ? "xlsx" : "csv"}`;
        if (exportParams.export_type === "Metadata") {
            fileName = fileName.replace("(type)", "Metadata");
        } else if (exportParams.export_type === "Users") {
            fileName = fileName.replace("(type)", "Users");
        } else {
            fileName = fileName.replace("(type)", "Measure");
        }
        return fileName;
    };

    /**
     * Get Assets
     * @param {*} params
     * @param {*} clear
     */
    const getAssets = (params, clear = false) => {
        setAssetPagination((prev) => ({ ...prev, loading: true, offset: params.offset }));
        const requestParams = {
            ...assetPagination,
            ...params
        };
        assetService.getAssetList(requestParams).then((response) => {
            if (clear) {
                setAssets([...response.data]);
            } else {
                setAssets((prev) => ([...prev, ...response.data]));
            }
            setAssetPagination((prev) => ({ ...prev, loading: false, hasMore: response.data.length >= pageLimit }));
        });
    };

    /**
     * Get Attributes
     * @param {*} params
     * @param {*} clear
     */
    const getAttributes = (params, clear = false) => {
        if (!exportParams?.assets?.length) {
            return;
        }
        setAttributePagination((prev) => ({ ...prev, loading: true, offset: params.offset }));
        const requestParams = {
            ...attributePagination,
            ...params,
            assets: exportParams.assets.indexOf("All") !== -1 ? [] : exportParams.assets,
            assetSearch: assetPagination.search
        };
        attributeService.getAttributeList(requestParams).then((response) => {
            if (clear) {
                setAttributes([...response.data]);
            } else {
                setAttributes((prev) => ([...prev, ...response.data]));
            }
            setAttributePagination((prev) => ({ ...prev, loading: false, hasMore: response.data.length >= pageLimit }));
        });
    };

    /**
     * Get Measures
     * @param {*} params
     * @param {*} clear
     */
    const getMeasures = (params, clear = false) => {
        if (((!exportParams?.assets?.length) && exportParams.measure_level === "Asset") || !exportParams?.measure_types?.length) {
            return;
        }
        setMeasurePagination((prev) => ({ ...prev, loading: true, offset: params.offset }));
        const requestParams = {
            ...measurePagination,
            ...params,
            assets: exportParams.assets.indexOf("All") !== -1 ? [] : exportParams.assets,
            attributes: exportParams.attributes.indexOf("All") !== -1 ? [] : exportParams.attributes,
            assetSearch: assetPagination.search,
            attributeSearch: attributePagination.search,
            measure_level: exportParams.measure_level
        };
        if (!exportParams?.attributes?.length && exportParams.measure_level === "Asset") {
            requestParams.no_export_attribute = true;
        }
        if (exportParams.measure_types?.includes("All")) {
            requestParams.measure_types = exportParams.measure_level === "Standalone Measure" ? standaloneMeasureTypes.map((data) => data.id) : assetMeasureTypes.map((data) => data.id);
        } else {
            requestParams.measure_types = exportParams.measure_types;
        }
        baseMeasureService.getAutoMeasure(requestParams).then((response) => {
            if (clear) {
                setMeasures([...response.data]);
            } else {
                setMeasures((prev) => ([...prev, ...response.data]));
            }
            setMeasurePagination((prev) => ({ ...prev, loading: false, hasMore: response.data.length >= pageLimit }));
        });
    };

    /**
     * Load Assets
     */
    useEffect(() => {
        getAssets({ offset: 0, limit: pageLimit }, true);
    }, []);

    /**
     * On Scroll
     * @param {*} type
     */
    const onScroll = (type) => {
        if (type === "asset") {
            getAssets({ offset: assetPagination.offset + pageLimit });
        } else if (type === "attribute") {
            getAttributes({ offset: attributePagination.offset + pageLimit });
        } else {
            getMeasures({ offset: measurePagination.offset + pageLimit });
        }
    };

    /**
     * On Search Change
     * @param {*} type
     * @param {*} value
     */
    const onSearchChange = (type, value) => {
        if (type === "asset") {
            setAssetPagination((prev) => ({ ...prev, search: value }));
            getAssets({ offset: 0, search: value }, true);
        } else if (type === "attribute") {
            setAttributePagination((prev) => ({ ...prev, search: value }));
            getAttributes({ offset: 0, search: value }, true);
        } else {
            setMeasurePagination((prev) => ({ ...prev, search: value }));
            getMeasures({ offset: 0, search: value }, true);
        }
    };

    /**
     * On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const params = { ...exportParams };
        if (property === "assets") {
            params.attributes = value?.length > 0 ? ["All"] : [];
        }
        if (params.export_type === "Measure" && (property === "assets" || property === "attributes")) {
            params.measures = [];
        }
        if (property === 'measure_level') {
            params.measure_types = [];
            params.measures = [];
        }
        setExport({ ...params, [property]: value });
    };

    /**
     * Export
     */
    const onExport = () => {
        const requestParams = {
            export_type: exportParams.export_file_type,
            assets: exportParams.assets.indexOf("All") !== -1 ? [] : exportParams.assets,
            attributes: exportParams.attributes.indexOf("All") !== -1 ? [] : exportParams.attributes,
            fileName: getFileName(),
            default_measures: exportParams?.default_measures || false,
            measures: exportParams?.measures?.indexOf("All") === -1 ? exportParams.measures || [] : [],
            assetSearch: exportParams?.attributes?.indexOf("All") !== -1 ? assetPagination.search : "",
            attributeSearch: exportParams.assets.indexOf("All") !== -1 ? attributePagination.search : ""
        };
        if (exportParams.export_type === "Metadata") {
            dispatch(exportMetadataRequest(requestParams));
        } else if (exportParams.export_type === "Measure") {
            requestParams.measure_level = exportParams.measure_level;
            if (exportParams.measure_types.includes("All")) {
                requestParams.measure_types = exportParams.measure_level === "Standalone Measure" ? standaloneMeasureTypes.map((data) => data.id) : assetMeasureTypes.map((data) => data.id);
            } else {
                requestParams.measure_types = exportParams.measure_types;
            }
            requestParams.measure_search = exportParams.measures.indexOf("All") === -1 ? measurePagination.search : "";
            if (!exportParams?.attributes?.length && exportParams.measure_level === "Asset") {
                requestParams.no_export_attribute = true;
            }
            dispatch(exportMeasureRequest(requestParams));
        }
        if (exportParams.export_type === "Users") {
            dispatch(exportUsersRequest(requestParams));
        }
    };

    /**
     * On Open Select
     * @param {*} type
     */
    const onOpenSelect = (type) => {
        if (type === "attribute") {
            getAttributes({ offset: 0 }, true);
        } else {
            getMeasures({ offset: 0 }, true);
        }
    };

    /**
     * Get description for the give export type
     * @param {*} export_type The export type
     * @returns The description
     */
    const getDescription = (export_type) => {
        let description = "";
        switch (export_type) {
            case "Metadata":
                description = appConstants.labels.general.exportMetadataDesc;
                break;
            case "Users":
                description = appConstants.labels.general.exportUsersDesc;
                break;
            case "Measure":
                description = appConstants.labels.general.exportMeasureDesc;
                break;
            default:
                description = "";
                break;
        }
        return description;
    };

    /**
     * Get Disabled Status
     * @param {*} params
     */
    const getDisabledStatus = (params) => {
        if (params.export_type === "Metadata" && !params.assets?.length) {
            return true;
        } else if (params.export_type === "Measure") {
            if (params?.measure_level === "Standalone Measure" && !params?.measures?.length) {
                return true;
            } else if ((!params?.assets.length || !params?.measures?.length || !params?.measure_types?.length) && params?.measure_level === "Asset") {
                return true;
            }
        }
        return false;
    };
    const disabledStatus = useMemo(() => getDisabledStatus(exportParams), [exportParams]);

    return (
        <Grid item xs={12}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.export}
                </Typography>
            </Grid>
            <Grid className={classes.bodyContainer}>
                <Typography variant="body1">
                    {getDescription(exportParams?.export_type)}
                </Typography>
                <Grid container spacing={6} className="mt-3">
                    <Grid item xs={3} className="pt-0">
                        <SelectComponent
                            fullWidth={false}
                            label={appConstants.labels.general.exportType}
                            variant="standard"
                            onSelectChange={(value) => onChange('export_type', value)}
                            value={exportParams.export_type || ""}
                            list={["Metadata", "Measure", "Users"]}
                        // disabled={!generalPermission?.is_edit}
                        />
                    </Grid>
                    <Grid item xs={3} className="pt-0">
                        <SelectComponent
                            fullWidth={false}
                            label={appConstants.labels.general.exportFileType}
                            variant="standard"
                            onSelectChange={(value) => onChange('export_file_type', value)}
                            value={exportParams.export_file_type || ""}
                            list={["Excel", "CSV"]}
                        // disabled={!generalPermission?.is_edit}
                        />
                    </Grid>
                    {
                        exportParams.export_type === "Measure" &&
                        <Grid item xs={3} className="pt-0">
                            <SelectComponent
                                fullWidth={false}
                                label={appConstants.labels.general.measureLevel}
                                variant="standard"
                                onSelectChange={(value) => onChange('measure_level', value)}
                                value={exportParams.measure_level || ""}
                                list={["Asset", "Standalone Measure"]}
                            />
                        </Grid>
                    }
                    {
                        exportParams.export_type !== "Users" &&
                        <Fragment>
                            {
                                (exportParams.export_type === "Metadata" || exportParams.measure_level !== "Standalone Measure") &&
                                <Grid item xs={3} className="pt-0">
                                    <LazyLoadSelectCheckBox
                                        list={assets || []}
                                        label={appConstants.labels.general.asset}
                                        value={exportParams.assets ?? []}
                                        variant="standard"
                                        displayPropertyName={"name"}
                                        propertyName={"id"}
                                        showSelectAll
                                        hasMore={assetPagination.hasMore}
                                        loading={assetPagination.loading}
                                        onSelectChange={(value) => onChange("assets", value)}
                                        onScrollEnd={() => onScroll("asset")}
                                        onSearchChange={(value) => onSearchChange("asset", value)}
                                        disabled={exportParams.measure_level !== "Asset"}
                                    />
                                </Grid>
                            }
                            {
                                (exportParams.export_type === "Metadata" || exportParams.measure_level !== "Standalone Measure") &&
                                <Grid item xs={3} className={exportParams?.export_type !== 'Measure' ? "pt-0" : null}>
                                    <LazyLoadSelectCheckBox
                                        list={attributes || []}
                                        label={appConstants.labels.general.attribute}
                                        value={exportParams.attributes ?? []}
                                        variant="standard"
                                        displayPropertyName={"name"}
                                        propertyName={"attribute_id"}
                                        showSelectAll
                                        hasMore={attributePagination.hasMore}
                                        loading={attributePagination.loading}
                                        onSelectChange={(value) => onChange("attributes", value)}
                                        onScrollEnd={() => onScroll("attribute")}
                                        onOpen={() => onOpenSelect("attribute")}
                                        onSearchChange={(value) => onSearchChange("attribute", value)}
                                        disabled={exportParams.measure_level !== "Asset"}
                                    />
                                </Grid>
                            }
                            {
                                exportParams?.export_type === 'Measure' &&
                                <Fragment>
                                    <Grid item xs={3} className={exportParams.measure_level === "Standalone Measure" ? "pt-0" : null}>
                                        <SelectCheckBoxComponent
                                            list={exportParams.measure_level === "Asset" ? assetMeasureTypes : standaloneMeasureTypes}
                                            label={appConstants.labels.general.measureType}
                                            value={exportParams.measure_types ?? []}
                                            variant="standard"
                                            displayPropertyName={"name"}
                                            propertyName={"id"}
                                            showSelectAll
                                            onSelectChange={(value) => onChange("measure_types", value)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <LazyLoadSelectCheckBox
                                            list={measures || []}
                                            label={appConstants.labels.general.measure}
                                            value={exportParams?.measures ?? []}
                                            variant="standard"
                                            displayPropertyName={"name"}
                                            propertyName={"id"}
                                            showSelectAll
                                            onSelectChange={(value) => onChange("measures", value)}
                                            hasMore={measurePagination.hasMore}
                                            loading={measurePagination.loading}
                                            onScrollEnd={() => onScroll("measures")}
                                            onOpen={() => onOpenSelect("measures")}
                                            onSearchChange={(value) => onSearchChange("measures", value)}
                                        />
                                    </Grid>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    <Grid item xs={12}>
                        <LoaderButtonComponent
                            size={'small'}
                            isLoading={exportLoading}
                            onClick={() => onExport()}
                            disabled={disabledStatus}
                        >
                            {appConstants.labels.general.export}
                        </LoaderButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Export.defaultProps = {
    classes: {}
};

// prop types
Export.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Export);