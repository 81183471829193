import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getUserActivityRequest, getUserActivitySuccess, getUserActivityFailure, getUserActivityLogsRequest, getUserActivityLogsSuccess,
  getUserActivityLogsFailure, getUserActivityJobsSuccess, getUserActivityJobsFailure, getUserActivityJobsRequest
} from '../reducer/userActivityReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { userActivityService } from '../service';


/**
 * Get User Activity
 * @returns
 */
const get = (action$) => action$.pipe(
  ofType(getUserActivityRequest),
  mergeMap(({ payload }) => {
    return from(userActivityService.get(payload)).pipe(
      concatMap((res) => { return of(getUserActivitySuccess(res)); }),
      catchError((error) => { return of(getUserActivityFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Get User Activity job status
 * @returns
 */
const getActivityJobStatus = (action$) => action$.pipe(
  ofType(getUserActivityJobsRequest),
  mergeMap(({ payload }) => {
    return from(userActivityService.getActivityJobs(payload)).pipe(
      concatMap((res) => { return of(getUserActivityJobsSuccess(res)); }),
      catchError((error) => { return of(getUserActivityJobsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);


/**
 * Get User Activity Logs
 * @returns
 */
const getActivityLogs = (action$) => action$.pipe(
  ofType(getUserActivityLogsRequest),
  mergeMap(({ payload }) => {
    return from(userActivityService.getActivityLogs(payload)).pipe(
      concatMap((res) => { return of(getUserActivityLogsSuccess(res)); }),
      catchError((error) => { return of(getUserActivityLogsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);
// Export All User Activity Functions
export const userActivityEpic = [get, getActivityLogs, getActivityJobStatus];