import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { CircularScoreComponent } from '../../index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';


// Import Images
import { DangerIcon, IssuesIcon2 } from '../../../assets/svg/index.js';

// Import Constant
import palette from '../../../assets/theme/palette.js';

// Import Reducer
import { navigate } from '../../../redux/reducer/navigationReducer';


function Card(props) {

    const { classes, data, type } = props;
    const dispatch = useDispatch();
    const reactNavigate = useNavigate();

    /**
     * Redux selector function to get Quadrant Settings from redux store
     */
    const { scoring } = useSelector((state) => state.configurations.general);
    const users = useSelector(({ user }) => user.users);
    const logginUser = useSelector(({ auth }) => auth.user);

    /**
     * Define the color based on the score
     * @param {*} value
     * @returns
     */
    const getColor = (value, type) => {
        const { values, quadrant } = scoring;
        const QuadSettig = values[quadrant - 2];
        for (let i = 0; i < QuadSettig?.length; i++) {
            if (Math.ceil(value) >= QuadSettig[i].from && Math.ceil(value) <= QuadSettig[i].to) {
                return type === "flip" ? `${QuadSettig[i].color}` : `${QuadSettig[i].color}20`;
            }
        }
        return `${palette.secondary.main}20`;
    };

    /**
     * Render Count
     * @returns
     */
    const renderCount = () => {
        let value = "";
        switch (data.type) {
            case 'organization':
            case 'domain':
            case 'application':
            case 'connection':
            case 'category':
                value = `${data?.asset_count ?? data?.count ?? 0} Assets`;
                break;
            case 'asset':
            case 'tags':
            case 'terms':
                value = `${data.count || 0} Attributes`;
                break;
            case 'dimension':
                value = `${data.count || 0} Measures`;
                break;
            default:
                value = `${data.alerts || 0} Alerts`;
                break;
        }
        return (
            <Typography variant="body2" className={classes.textSecondary} align="left">
                {value}
            </Typography>
        );
    };

    /**
     * Get User
     * @param {*} item
     * @param {*} user
     * @param {*} logginUser
     * @returns
     */
    const getUser = (item, users, logginUser) => {
        const user = users.find((user) => user.id === item.created_by);
        return user ? { ...user, name: user.first_name ? `${user.first_name} ${user.last_name}` : user.email } : { ...logginUser, name: logginUser.first_name ? `${logginUser.first_name} ${logginUser.last_name}` : logginUser.email };
    };

    const user = useMemo(() => getUser(data, users, logginUser), [data, users, logginUser]);

    /**
     * Redirect
     */
    const redirect = () => {
        switch (data.type) {
            case 'domain':
            case 'category':
                dispatch(navigate({ path: 'domain.detail', state: {}, params: [data.id] }));
                break;
            case 'application':
                reactNavigate(`/catalog?application=${data.id}`, { replace: true });
                break;
            case 'tags':
                reactNavigate(`/catalog?tag=${data.id}`, { replace: true });
                break;
            case 'dimension':
                dispatch(navigate({ path: 'settings.semantics.dimension.root', state: {}, params: [] }));
                break;
            case 'asset':
                dispatch(navigate({ path: 'assets.root', state: {}, params: [data.asset_id || data.id] }));
                break;
            case 'attribute':
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [data.asset_id, data.attribute_id] }));
                break;
            case 'terms':
                reactNavigate(`/catalog?term=${data.id}`, { replace: true });
                break;
            default:
                break;

        }
    };
    return (
        <Grid item xs={12} className="h-100">
            <Grid item className={`${classes.organizationCard} organizationCard`}>
                <Grid className={`${classes.cardInner} cardInner`}>
                    <Grid className={`cardFront ${data.type === "category" ? "categoryCard" : null}`} sx={{ borderLeft: `5px solid ${getColor(data.widget_score || data.dqscore || 0, "flip")} !important` }}>
                        <Grid className="dflex alignCenter">
                            <Grid item className={`${classes.scoreContainer}`}>
                                <CircularScoreComponent
                                    size={40}
                                    value={data.widget_score || data.dqscore || 0}
                                    showPercentage
                                    noLink
                                    showValue />
                            </Grid>
                            <Grid item className={classes.userAsset}>
                                <Typography variant="body1" className={`pl-1 dataName ${type !== "preview" ? 'pointer' : null}`} onClick={() => type !== "preview" && redirect()}>
                                    {data.name || ""}
                                </Typography>
                                <Grid className="dflex alignCenter noWrap spaceBetween pl-1">
                                    {renderCount()}
                                    <Grid className={classes.dotContainer} />
                                    <Typography variant="body2" className={`${classes.textSecondary} oneLineEllip ml-1`} align="right">
                                        {user.name || ""}
                                    </Typography>
                                </Grid>
                                <Grid className="dflex alignCenter noWrap spaceBetween pl-1">
                                    {
                                        data.type !== "organization" &&
                                        <Fragment>
                                            {
                                                data.type !== 'attribute' && data.type !== "measure" &&
                                                <Typography variant="body2" className={classNames(classes.textSecondary, "pt7")} align="left">
                                                    {`${data.alerts} Alerts`}
                                                </Typography>
                                            }
                                            <Typography variant="body2" className={classNames(classes.textSecondary, "pt7")} align="right">
                                                {`${data.issues} Issues`}
                                            </Typography>
                                        </Fragment>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={"cardBack"} sx={{ background: getColor(data.dqscore || 0, "flip"), display: 'none' }}>
                        <Typography>
                            {data.name || ""}
                        </Typography>
                        <Grid className="dflex alignCenter spaceBetween pt-2">
                            <Grid className="dflex alignCenter">
                                <DangerIcon />
                                <Typography variant="subtitle1" className="pl5">
                                    {`${data.alerts || 0} Alerts`}
                                </Typography>
                            </Grid>
                            <Grid className="dflex alignCenter">
                                <IssuesIcon2 />
                                <Typography variant="subtitle1" className="pl5">
                                    {`${data.issues || 0} Issues`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Card.defaultProps = {
    classes: {},
    data: {},
    type: "widget"
};

// prop types
Card.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    type: PropTypes.string
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Card, areEqual));