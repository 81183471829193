// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Applications
 * @returns
 */
async function getReferences(payload, token) {
  const response = await postRequest(`${urlConstants.libraries.getReferences}`, payload, false, null, token);
  return response;
}

/**
 * Create Application
 * @param {*} params
 * @returns
 */
async function createReference(params) {
  const response = await postRequest(`${urlConstants.libraries.url}`, params, Boolean(params?.selectedFile));
  return response;
}

/**
 * update Application
 * @param {*} referenceId
 * @param {*} params
 * @returns
 */
async function updateReference(referenceId, params) {
  const response = await putRequest(`${urlConstants.libraries.url}${referenceId}/`, params, Boolean(params?.selectedFile));
  return response;
}

/**
 * Delete Application
 * @param {*} referenceId
 * @returns
 */
async function deleteReference(referenceId) {
  const response = await deleteRequest(`${urlConstants.libraries.url}${referenceId}/`);
  return response;
}

/**
 * Get Application Detail
 */
async function getReference(id) {
  const response = await getRequest(`${urlConstants.libraries.url}${id}/`);
  return response;
}


/**
 * Export All Service Functions
 */
export const librariesService = {
  getReferences, createReference, deleteReference, updateReference, getReference
};