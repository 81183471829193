import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';

//  Import Components
import RolesCard from './card/index.jsx';
import RolesList from './list/index.jsx';

//  Import Styles
import RolesStyle from './style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';
import { SearchComponent } from '../../../components/index.js';

// Import Reducer
import { getRolesPermissionRequest, addFeaturetoRoleRequest, updateFeaturetoRoleRequest, addNewRoleRequest, getRoleStatsRequest, updateRoleRequest, deleteRoleRequest } from '../../../redux/reducer/securityReducer';
import { getUserPermissionRequest } from '../../../redux/reducer/authReducer';
import { searchFeaturesList } from '../../../redux/reducer/defaultReducer';
import { displyAlert } from '../../../redux/reducer/alertReducer.js';

function Roles(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { roles_feature, feature_search_key } = useSelector((state) => state.default, shallowEqual);
    const { roles, permission, rolesStats } = useSelector((state) => state.security, shallowEqual);
    const { role: user_role_id, organization: { id: organization_id } } = useSelector((state) => state.auth.user, shallowEqual);

    /**
     * Get Roles Statistics
     */
    useEffect(() => {
        dispatch(getRoleStatsRequest());
        return () => {
            dispatch(getUserPermissionRequest(user_role_id));
        };
    }, [dispatch]);

    /**
     * Get Permission List
     */
    useEffect(() => {
        if (!permission || Object.keys(permission).length === 0) {
            dispatch(getRolesPermissionRequest());
        }
    }, [dispatch]);

    /**
     * Get Roles Status Value
     * @param {*} stats
     * @param {*} roles
     * @returns
     */
    const getRolesStats = (pStats, pRoles) => {
        const tRoles = _.cloneDeep(pRoles);
        return tRoles.map((sRole) => {
            sRole.count = 0;
            const tStats = pStats.filter((stat) => stat.role === sRole.id);
            if (tStats.length) {
                sRole.count = tStats[0].count;
            }
            return sRole;
        });
    };

    /**
     * Get Roles Stats Value
     */
    const roles_stats = useMemo(() => getRolesStats(rolesStats, roles), [rolesStats, roles]);

    /**
     * Handle Active Feature CheckBox
     * @param {*} role
     * @param {*} feature
     * @param {*} isActive
     */
    const handleCheckBox = (feature, role, isNew, newData) => {
        const requesteData = {
            role: role.id,
            feature: feature.id,
            organization: organization_id,
            ...newData
        };
        if (isNew) {
            dispatch(addFeaturetoRoleRequest(requesteData));
        } else {
            dispatch(updateFeaturetoRoleRequest({ ...requesteData, id: role.role_permission_id }));
        }
    };

    /**
     * Handle Add Rule Event
     * @param {*} newRole
     */
    const handleAddRule = (newRole) => {
        const defaultPermission = ["8068e987-043c-4a47-b464-9dbf61882c00", "d89a75f6-b3d7-480d-82cd-5757dfe49644", "2dd7508f-4c19-48cd-997e-60f1c548190a"];
        if (newRole) {
            dispatch(addNewRoleRequest({ 'name': newRole, 'default_permission': defaultPermission }));
        }
    };

    /**
     * Handle Features Search
     * @param {*} value
     */
    const handleSearchChange = (value) => {
        dispatch(searchFeaturesList(value));
    };

    /**
     * Handle User Edit
     * @param {*} value
     */
    const handleUpdateUser = (id, name) => {
        const requesteData = { id, name };
        const isDuplicate = roles.some((role) => role?.name?.toLowerCase() === name?.toLowerCase());
        if (!isDuplicate) {
            dispatch(updateRoleRequest(requesteData));
        } dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Federation File Missing!' } }));
    };

    /**
     * Handle User Delete
     * @param {*} value
     */
    const handleDeleteUser = (id) => {
        dispatch(deleteRoleRequest(id));
    };


    return (
        <Grid className={classes.usersContainer}>
            <Grid item xs={12} className="mb-3">
                <Typography variant="h5" className="pb5">
                    {appConstants.labels.security.roles}
                </Typography>
                <Typography variant="body1">
                    {appConstants.labels.security.rolesDescription}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <RolesCard classes={classes} data={roles_stats} />
            </Grid>
            <Grid item xs={12} className="mt-3">
                <SearchComponent
                    placeholder={appConstants.labels.security.rolesSearch}
                    value={feature_search_key}
                    onChange={(value) => handleSearchChange(value)} />
            </Grid>
            <Grid item xs={12} className={`${classes.rolesListRoot} mt-15`}>
                <RolesList
                    classes={classes}
                    data={_.cloneDeep(roles_feature)}
                    roles={_.cloneDeep(roles)}
                    permission={_.cloneDeep(permission)}
                    handleCheckBox={handleCheckBox}
                    handleAddRule={handleAddRule}
                    handleUpdateUser={handleUpdateUser}
                    handleDeleteUser={handleDeleteUser} />
            </Grid>
        </Grid>
    );
}

// default props
Roles.defaultProps = {
    classes: {}
};

// prop types
Roles.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...RolesStyle(theme)
    }),
    { withTheme: true }
)(Roles);