import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar, IconButton, MenuItem, Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Import Actions
import { logoutRequest } from '../../../redux/reducer/authReducer.js';

// Import Images
import { LogoutIcon, SettingsIcon, UserIcon2 } from '../../../assets/svg';

// Import Components
import { PopOverComponent } from '../../index.js';

// Import Helpers
import { permissionTabs, stringAvatar } from '../../../helpers';

// Import Contants
import appConstants from '../../../constants/appConstants';


const Profile = (props) => {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    const { first_name, last_name, avatar } = useSelector((state) => state.auth.user);
    const { permission } = useSelector((state) => state.auth);

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     * Handle Popup
     * @param {*} event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close Popup
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Logout
     */
    const logout = () => {
        setAnchorEl(null);
        dispatch(logoutRequest());
    };

    /**
     * Set Variables
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Get Profile Image
     */
    const get_user_name = () => {
        let user_name = '';
        if (first_name && first_name.trim()) {
            user_name = first_name;
        }

        if (last_name && last_name.trim()) {
            user_name = `${user_name} ${last_name}`;
        }
        return user_name;
    };

    return (
        <Grid>
            <IconButton className="p5" onClick={handleClick}>
                <Avatar
                    alt={get_user_name()}
                    {...stringAvatar(get_user_name(), avatar)}
                    sx={{ width: 21, height: 21 }}
                />
            </IconButton>
            <PopOverComponent
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={`${classes.popoverContainer} popoverContainer`}
            >
                <Grid className={classes.popoverContainer}>
                    <MenuItem onClick={handleClose} component={Link} to="/myprofile">
                        <UserIcon2 />
                        Profile
                    </MenuItem>
                    {
                        permissionTabs(permission, appConstants.tabs.settingsTabs)?.length > 0 &&
                        <MenuItem onClick={handleClose} component={Link} to="/settings">
                            <SettingsIcon />
                            Settings
                        </MenuItem>
                    }
                    <MenuItem onClick={logout}>
                        <LogoutIcon />
                        Logout
                    </MenuItem>
                </Grid>
            </PopOverComponent>
        </Grid>
    );
};

// default props
Profile.defaultProps = {
    classes: {}
};

// prop types
Profile.propTypes = {
    classes: PropTypes.object
};

export default Profile;