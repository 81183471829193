import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';

//  Import Styles
import LogoAddStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import UserImg from '../../../../assets/img/userImg.png';
import { DeleteIcon } from '../../../../assets/svg/index.js';

// Import Components
import { TextBoxComponent, DialogComponent } from '../../../../components/index.js';
import ImageCropper from '../../../../components/imageCrop/ImageCropper.jsx';

//Constants
import appConstants from '../../../../constants/appConstants.js';

function LogoAdd(props) {
    /**
     * Define Props
     */
    const { classes, data, handleThemeChange, handleImageUpload, editPermission } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);

    /**
     * Define State
     */
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: ''
    });

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    setImageToCrop(reader.result.toString() || '');
                    setImageDialog(true);
                }
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Copyright change handler function
     * @param {*} property
     * @param {*} value
     */
    const handleThemeEvent = (property, value) => {
        handleThemeChange({ ...data, [property]: value });
    };

    /**
     * Handle image logo upload
     */
    const handleImgUpload = () => {
        document.getElementById('logoImg').click();
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        if (croppedImage) {
            handleImageUpload(croppedImage, croppedImageUrl, 'new');
            return;
        }
        croppedImage = selectedFile;
        croppedImageUrl = window.URL.createObjectURL(croppedImage);
        handleImageUpload(croppedImage, croppedImageUrl, 'new');
    };

    /**
     * Handle Delete Event
     */
    const onDelete = () => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.imageDeleteMessage
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = () => {
        handleImageUpload(null, '', 'delete');
        dialogCancelEventHandle();
    };

    return (
        <Grid item xs={12} className={classes.LogoAddContainer}>
            <Grid item className={classes.logoSection}>
                <Grid className={classes.headerConatiner}>
                    <Typography variant="h5" className="">
                        {appConstants.labels.theme.logo}
                    </Typography>
                </Grid>
                <Grid className={classes.bodySection}>
                    <Typography variant="body1">
                        {appConstants.labels.theme.logoDesc}
                    </Typography>
                    <Grid container className="pt-3">
                        <Grid item xs={6}>
                            <Grid container wrap="nowrap">
                                <Grid className={classes.imgUploadSection} onClick={handleImgUpload}>
                                    <img
                                        src={data.logo?.length > 0 ? data.logo : UserImg}
                                        className={classes.profileImg}
                                        alt="ProfileImg"
                                    />
                                    {
                                        (data.file_to_upload || data.logo) && editPermission &&
                                        <Tooltip title="Delete" arrow>
                                            <IconButton className={classes.deleteIcon} onClick={(event) => { onDelete(); event.stopPropagation(); }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                                {
                                    editPermission &&
                                    <Grid className="pl-4">
                                        <Typography
                                            variant="body1"
                                            className={`pb5`}
                                        >
                                            {appConstants.labels.theme.uploadLogo}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                        >
                                            {
                                                appConstants.labels.theme
                                                    .uploadLogoDesc
                                            }
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                        >
                                            {
                                                appConstants.labels.theme
                                                    .uploadLogoDesc2
                                            }
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            onClick={handleImgUpload}
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            className="mt-2"
                                        >
                                            {appConstants.labels.theme.upload}
                                        </Button>
                                        <input
                                            accept="image/*"
                                            id="logoImg"
                                            type="file"
                                            className={classes.profileImgInput}
                                            onChange={handleSelectFile}
                                        />
                                        {
                                            openImageDialog && (
                                                <ImageCropper
                                                    open={openImageDialog}
                                                    onClose={() => setImageDialog(false)}
                                                    imageToCrop={imageToCrop}
                                                    aspect={null}
                                                    loadUncroppedImage
                                                    onImageCropped={
                                                        (croppedImage, croppedImageUrl) =>
                                                            updateCropImage(croppedImage, croppedImageUrl)
                                                    }
                                                />
                                            )
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className="pl-1">
                            <Typography
                                variant="body1"
                                className={`pb5`}
                            >
                                {appConstants.labels.theme.tagLine}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={`${classes.textSecondary} mb-2`}
                            >
                                {appConstants.labels.theme.tagLineDesc}
                            </Typography>
                            <Grid item xs={6}>
                                <TextBoxComponent
                                    id="tagline"
                                    name={'tagline'}
                                    variant="standard"
                                    label={appConstants.labels.theme.enterTagLine}
                                    value={data.tagline}
                                    disabled={!editPermission}
                                    onChange={
                                        (event) =>
                                            handleThemeEvent(
                                                event.target.name,
                                                event.target.value
                                            )
                                    }
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </Grid>
    );
}

// default props
LogoAdd.defaultProps = {
    classes: {},
    data: {},
    handleThemeChange: () => { },
    handleImageUpload: () => { },
    editPermission: false
};

// prop types
LogoAdd.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleThemeChange: PropTypes.func,
    handleImageUpload: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...LogoAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LogoAdd);