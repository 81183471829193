import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent, PasswordInputComponent } from '../../../components/index.js';


//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import ADFIcon from '../../../assets/img/connectors/adf.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';


// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState,
    getAdfFactoryDetailsRequest
} from '../../../redux/reducer/connectionReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';
import SchemaSelector from '../components/schemaSelector/index.jsx';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';


function Adf(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'adf',
        description: '',
        client_id: '',
        client_secret: '',
        tenant_id: '',
        subscriptions: [],
        resource_groups: [],
        data_factories: []

    });
    const {
        id,
        type,
        name,
        description,
        client_id,
        client_secret,
        tenant_id,
        subscriptions,
        resource_groups,
        data_factories
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, saveIsLoading, adfFactoryLoading, adf_factories } = useSelector((state) => state.connection);

    /**
     * Get Subscriptions, Resource Groups and Data Factories
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getAdfFactoryDetailsRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);

    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.client_secret = encryptDecrypt.decrypt(credentialDetails?.client_secret ?? "");
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
        if (property !== "name" && property !== "description") {
            setIsAuthenticationChanged(true);
        }
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                client_id: client_id,
                client_secret: encryptDecrypt.encrypt(client_secret),
                tenant_id: tenant_id,
                subscriptions: subscriptions,
                resource_groups: resource_groups,
                data_factories: data_factories
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(null);
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    /**
     * Handle ADF Subscription Selection
     * @param {*} newValue
     */
    const handleSubscriptionSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, subscriptions: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Handle ADF Resource Group Selection
     * @param {*} newValue
     */
    const handleResourceGroupSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, resource_groups: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Handle ADF Data Factory Selection
     * @param {*} newValue
     */
    const handleDataFactorySelection = (newValue) => {
        setFormData((formData) => ({ ...formData, data_factories: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Filter Resource Group by Subscription
     * @param {*} adf_data
     */
    const getResourceBySubscriptions = (adf_data, s_subscriptions) => {
        let resourceGroupData = [];
        if (s_subscriptions) {
            const adf_resources = adf_data?.resource_groups ?? [];
            resourceGroupData = adf_resources.filter((array_el) => {
                return s_subscriptions.filter((anotherOne_el) => {
                    return anotherOne_el.id === array_el.subscription_id;
                }).length > 0;
            });
        }
        return resourceGroupData || [];
    };
    const resourceGroups = getResourceBySubscriptions(adf_factories, subscriptions);

    /**
     * Filter Data Factories by Resource Group
     * @param {*} adf_data
     */
    const getFactoryByResourceGroup = (adf_data, s_resource_groups) => {
        let dataFactoryData = [];
        if (s_resource_groups) {
            const adf_resources = adf_data?.data_factories ?? [];
            dataFactoryData = adf_resources.filter((array_el) => {
                return s_resource_groups.filter((anotherOne_el) => {
                    return anotherOne_el.label.toLowerCase() === array_el.resource_group_name.toLowerCase();
                }).length > 0;
            });
        }
        return dataFactoryData || [];
    };
    const dataFactories = getFactoryByResourceGroup(adf_factories, resource_groups);

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={ADFIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.adf.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.adf
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_client_id"
                                name={'client_id'}
                                value={client_id}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.client_id}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.client_id
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <PasswordInputComponent
                                id="form_text_secret"
                                name={'client_secret'}
                                value={client_secret}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.client_secret}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['passwordRequired']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.client_secret
                                        )
                                    ]
                                }
                                onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_tenant_id"
                                name={'tenant_id'}
                                value={tenant_id}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.tenant_id}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.tenant_id
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                                //disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        {
                            detailById?.is_valid && adf_factories?.subscriptions &&
                            <SchemaSelector
                                dbs_schema={adf_factories?.subscriptions ?? []}
                                schema={subscriptions}
                                handleSchemaSelection={handleSubscriptionSelection}
                                schemaLoading={adfFactoryLoading}
                                detailById={detailById}
                                userClass={'pl-10'}
                                divSpan={5}
                                data_type={'adf_subscriptions'}
                                customLabel={'Subscriptions'}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.subscription_id
                                        )
                                    ]
                                }
                            />
                        }
                        {
                            detailById?.is_valid && adf_factories?.resource_groups &&
                            <SchemaSelector
                                dbs_schema={resourceGroups || []}
                                schema={resource_groups}
                                handleSchemaSelection={handleResourceGroupSelection}
                                schemaLoading={adfFactoryLoading}
                                detailById={detailById}
                                divSpan={4}
                                customLabel={'Resource Groups'}
                                data_type={'adf_resource_groups'}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.resource_group_name
                                        )
                                    ]
                                }
                            />
                        }
                        {
                            detailById?.is_valid && adf_factories?.data_factories &&
                            <SchemaSelector
                                dbs_schema={dataFactories || []}
                                schema={data_factories}
                                handleSchemaSelection={handleDataFactorySelection}
                                schemaLoading={adfFactoryLoading}
                                detailById={detailById}
                                divSpan={5}
                                userClass={'pl-10'}
                                customLabel={'Data Factories'}
                                data_type={'adf_data_factories'}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.data_factory_name
                                        )
                                    ]
                                }
                            />
                        }
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                            <Button
                                variant="outlined"
                                size="small"
                                className="ml-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Adf.defaultProps = {
    classes: {}
};

// prop types
Adf.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Adf);