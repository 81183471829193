const style = (theme) => ({
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px'
    },
    bodySection: {
        padding: '30px 50px 50px',
        '& .MuiInputLabel-shrink': {
            fontSize: '15px !important',
            color: `${theme.palette.headers.body1.color} !important`
        }
    },
    profileItem: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 24,
        '& .formLabel': {
            minWidth: 85
        },
        '& .MuiOutlinedInput-input': {
            padding: '2.5px 14px'
        }
    },
    profileImg: {
        width: 140,
        height: 140,
        objectFit: 'contain'
    },
    imgUploadSection: {
        width: 170,
        height: 170,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        position: 'relative'
    },
    deleteIcon: {
        position: 'absolute !important',
        right: 10,
        top: 10,
        padding: '4px !important',
        backgroundColor: `${theme.palette.greyshades.darkgrey} !important`,
        '& .DeleteIcon': {
            width: 18,
            height: 18,
            '& path': {
                fill: '#fff',
                stroke: '#fff'
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    profileImgInput: {
        display: 'none !important'
    },
    bannerCardWrapper: {
        margin: '15px !important',
        position: 'relative'
    },
    wrapperContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        maxWidth: 'calc(100% - 20px)',
        margin: 'auto',
        '& button.slick-arrow': {
            display: 'none !important'
        },
        '& .arrows': {
            '&.lt': {
                marginRight: '10px'
            },
            '&.rt': {
                marginLeft: '10px'
            },
            '& svg': {
                width: '10px',
                height: '18px'
            }
        },
        '& .slick-track': {
            display: 'flex',
            flexShrink: 0
        }
    },
    addBannerCard: {
        height: 150,
        background: theme.palette.greyshades.lightbackground,
        border: `1px dashed ${theme.palette.greyshades.darkgrey}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: '100%',
        margin: '0px 15px !important'
        // flexShrink: 0
    },
    bannerCard: {
        height: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        /*
         * maxWidth: '25%',
         * flexBasis: '25%',
         */
        position: 'relative',
        '& .DeleteIcon': {
            width: 18,
            height: 18,
            '& path': {
                fill: '#fff',
                stroke: '#fff'
            }
        }
    },
    bannerImg: {
        width: '100%',
        height: 150,
        objectFit: 'contain',
        border: `1px solid ${theme.palette.greyshades.darkgrey}`
    },
    themeSlider: {
        '& .innerItem': {
            padding: '10px !important'
        },
        '& .slick-track': {
            width: '100% !important'
        }
    },
    domainImgInput: {
        display: 'none'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        paddingLeft: '10px',
        '& .titleField': {
            display: 'flex',
            alignItems: 'end'
        }
    }
});
export default style;