// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import _ from 'lodash';
import moment from 'moment';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Helpers
import { toRound } from '../../helpers/appHelpers';


// Import Styles
import style from "./style.jsx";

const TrendingChartComponent = (props) => {
    /**
     * Define Props
     */
    const { chartData, onClickToolTipPoint } = props;

    /**
     * Define Color Code for Each Line
     */
    const colorCodes = appConstants.chartColors.trending;

    /**
     * Initial X Axis Data
     */
    const dateList = chartData.length > 0 ? chartData[0].metrics.map((value) => moment(value.created_date).format('DD-MM hh:mm')) : [];

    /**
     * Define Chart Options
     */
    const option = {
        // ToolTip Style and content
        tooltip: {
            trigger: 'item',
            animation: false,
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            formatter: (params) => {
                if (params.componentType === "markPoint") {
                    return `
                        Attribute: ${params.data.attribute}<br />
                        DQscore: ${params.data.coord[1]}<br />
                        Alert: ${params.data.count}<br />
                        `;
                }
                return `
                        Attribute: ${params.seriesName}<br />
                        DQscore: ${params.data}<br />
                        `;
            }
        },

        // Legends Style and content
        legend: {
            itemGap: 20,
            bottom: 0,
            data: chartData.map((cData) => {
                return {
                    name: cData.name,
                    icon: 'rectangle'
                };
            })
        },

        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },

        // Set position of the canvas
        grid: {
            top: 40,
            left: '3%',
            right: '3%',
            bottom: 50,
            containLabel: true
        },

        // X Axis Label Style and content
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dateList,
            alignTicks: false,
            splitLine: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: appConstants.chartColors.axisColor
                }
            },
            axisTick: {
                show: false
            }
        },

        // Y Axis Label Style and content
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLabel: {
                margin: 20
            }
        },

        // Set Type and Data of the chart
        series: chartData.map((cData, index) => {
            return {
                name: cData.name,
                type: 'line',
                smooth: true,
                lineStyle: {
                    width: 3,
                    color: colorCodes[index]
                },
                itemStyle: {
                    color: colorCodes[index]
                },
                symbolSize: 8,
                areaStyle: {
                    opacity: 1,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: colorCodes[index]
                        },
                        {
                            offset: 1,
                            color: 'rgba(255, 255, 255, 0)'
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                data: cData.metrics.map((value) => String(toRound(value.score))),

                // Markpoint to Show Alert if exists on line
                markPoint: {
                    symbol: `image://${require('../../assets/img/alert.png')}`,
                    symbolSize: 30,
                    itemStyle: {
                        shadowColor: colorCodes[index],
                        shadowBlur: 3
                    },
                    data: cData.metrics.map((value, index) => {
                        if (value.alerts > 0) {
                            return {
                                name: 'alert',
                                count: value.alerts,
                                attribute: cData.name,
                                coord: [dateList[index], value.score]
                            };
                        }
                        return {
                            name: 'alert',
                            coord: [null, null]
                        };
                    })
                }
            };
        })
    };

    /**
     * Handle Tooltip Click Event
     * @param {*} params
     */
    const onChartClick = (params) => {
        onClickToolTipPoint(chartData[params.dataIndex]);
    };

    /**
     * Handle Chart Event
     */
    const onEvents = {
        click: onChartClick
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            notMerge
            onEvents={onEvents}
            style={
                {
                    height: 350,
                    width: '100%'
                }
            } />
    );
};


/**
 * Define Prop Types
 */
TrendingChartComponent.propTypes = {
    chartData: PropTypes.array,
    onClickToolTipPoint: PropTypes.func
};

/**
 * Set Default Values
 */
TrendingChartComponent.defaultProps = {
    chartData: [],
    onClickToolTipPoint: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.chartData, nextProps.chartData);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(TrendingChartComponent, areEqual));