import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//import Componetns
import { MemoSimpleTabHeaderComponent, TabPanelComponent } from '../../../../components/index';
import Comments from './tabs/comments/index.jsx';
import WorkLog from './tabs/workLog.jsx';
import Results from './tabs/results.jsx';

//import Styles
import IssuesDetailStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//import Images

//Constants
import appConstants from '../../../../constants/appConstants.js';


function IssuesInfo(props) {

    /**
     * Define Props
     */
    const { classes, measureId, selectedAlert, measure_name, issuePermission } = props;

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Grid item xs={12} className={classes.issuesDetailContaier}>
            <MemoSimpleTabHeaderComponent
                className={classes.tabsHeader}
                tabStyle={'inline'}
                tabIndex={tabIndex}
                tabList={appConstants.tabs.issuesTabs}
                onTabChange={(newValue) => setTabIndex(newValue)}
            />
            <Grid className={classes.tabsBodySection}>
                <Grid className={classes.tabsBody}>
                    {
                        tabIndex === 0 &&
                        <TabPanelComponent value={0} index={tabIndex}>
                            <Results measureId={measureId} selectedAlert={selectedAlert} measure_name={measure_name} issuePermission={issuePermission} />
                        </TabPanelComponent>
                    }
                    {
                        tabIndex === 1 &&
                        <TabPanelComponent value={1} index={tabIndex}>
                            <WorkLog />
                        </TabPanelComponent>
                    }
                    {
                        tabIndex === 2 &&
                        <TabPanelComponent value={2} index={tabIndex}>
                            <Comments issuePermission={issuePermission} />
                        </TabPanelComponent>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
IssuesInfo.defaultProps = {
    classes: {},
    measureId: "",
    selectedAlert: "",
    measure_name: "",
    issuePermission: {}
};

// prop types
IssuesInfo.propTypes = {
    classes: PropTypes.object,
    measureId: PropTypes.string,
    selectedAlert: PropTypes.string,
    measure_name: PropTypes.string,
    issuePermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...IssuesDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(IssuesInfo);