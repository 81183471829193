import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import appConstants from '../../constants/appConstants.js';
import { uuidv4 } from '../../helpers';

const initialState = {
    selectedConnectionId: "",
    asset: {
        connectorsList: {},
        allData: [],
        data: [],
        total: 0,
        limit: 20,
        searchKey: '',
        sortBy: '',
        orderBy: 'asc',
        isLoading: false,
        isSelected: false,
        saveIsLoading: false,
        filterData: [],
        view: {},
        assetAttributes: []
    },
    attribute: {
        isLoading: false
    },
    defaultNewQuery: {
        id: uuidv4(),
        name: "",
        asset_type: appConstants.toggle.tableAndQuery[1].name,
        view_type: appConstants.toggle.viewTypes[0].name,
        column_count: 0,
        incremental_config: {},
        is_connected: false,
        is_delete: false,
        is_incremental: false,
        is_selected: true,
        row_count: 0,
        schema: "QUERIES",
        watermark: "",
        watermark_columns: "",
        query: "",
        isOpen: true
    }
};

const validateSearchFilter = (item, filters, searchFilters) => {
    for (const key of filters) {
        let value = typeof (item[key]) === 'number' ? item[key].toString() : item[key];
        value = value !== null ? value : "";
        if (typeof (value) === 'string' && !value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
            return false;
        }
    }
    return true;
};

const connectorSlice = createSlice({
    name: 'connector',
    initialState: { ...initialState },
    reducers: {
        connectorAssetRequest(state) {
            state.asset.isLoading = true;
            state.asset.data = [];
        },
        connectorAssetSuccess(state, action) {
            const { data, connection_id, view } = action.payload;
            state.asset.isLoading = false;
            const connectionDetails = { ...state.asset.connectorsList };
            connectionDetails[connection_id] = data?.assets ?? [];
            state.asset.connectorsList = { ...connectionDetails };
            state.selectedConnectionId = connection_id;

            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            state.asset.filterData = [...filteredAssets];
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);
            state.asset.view[connection_id] = view;
        },
        connectorAssetFailure(state) {
            state.asset.isLoading = false;
        },
        connectorAssetResetByConnectionId(state, action) {
            if (state.asset.connectorsList[action.payload]) {
                state.asset.connectorsList[action.payload] = [];
            }
        },
        connectorAttributeRequest(state, action) {
            state.attribute.isLoading = true;
            const index = state.asset.data.findIndex((m) => m.id === action.payload.asset_id);
            if (index > 0) {
                state.asset.data[index].isLoading = true;
            }
        },
        connectorAttributeSuccess(state, action) {
            state.asset.data = state.asset.data.map((m) => {
                if (action.payload && m.id === action.payload.requestData.asset_id) {
                    if (m.is_selected) {
                        const isConnected = action.payload.data.some((el) => el.is_selected === true);
                        action.payload.data = action.payload.data.map((n) => {
                            if (isConnected) {
                                return n;
                            }
                            n.is_selected = true;
                            return n;
                        });
                        m.attributes = action.payload.data;
                        m.isLoading = false;
                    }
                    m.attributes = action.payload.data;
                    m.isLoading = false;
                }
                return m;
            });
            state.asset.assetAttributes = action.payload.data;
            state.attribute.isLoading = false;
            state.attribute.asset_id = '';
        },
        connectorAttributeFailure(state) {
            state.attribute.isLoading = false;
        },
        connectorAssetReducerSelectUpdate(state, action) {
            const isChecked = action.payload.isChecked || false;
            const { searchFilters } = action.payload;
            const filters = [];
            for (const key of Object.keys(searchFilters)) {
                if ((key !== "view_type" && searchFilters[key] !== "") || (key === "view_type" && searchFilters[key] !== "All")) {
                    filters.push(key);
                }
            }

            if (action.payload.isHeader) {
                if (action.payload.parentData) {
                    const up_index = state.asset.data.findIndex((m) => m.id === action.payload.parentData);
                    state.asset.data[up_index].is_selected = isChecked;
                    if (state.asset.data[up_index].attributes) {
                        state.asset.data[up_index].attributes = state.asset.data[up_index].attributes.map((m) => {
                            m.is_selected = isChecked;
                            return m;
                        });
                    }
                } else {
                    state.asset.data = state.asset.data.map((m) => {
                        if ((m.is_connected && !m.is_delete) && m.is_selected) {
                            return m;
                        }
                        if (validateSearchFilter(m, filters, searchFilters)) {
                            m.is_selected = isChecked;
                            if (m.attributes) {
                                m.attributes = m.attributes.map((m) => {
                                    m.is_selected = isChecked;
                                    return m;
                                });
                            }
                            return m;
                        }
                        return m;
                    });

                    if (action.payload.connection_id in state.asset.connectorsList) {
                        let assetList = state.asset.connectorsList[action.payload.connection_id];
                        assetList = assetList.map((m) => {
                            if ((m.is_connected && !m.is_delete) && m.is_selected) {
                                return m;
                            }
                            if (validateSearchFilter(m, filters, searchFilters)) {
                                m.is_selected = isChecked;
                                if (m.attributes) {
                                    m.attributes = m.attributes.map((m) => {
                                        m.is_selected = isChecked;
                                        return m;
                                    });
                                }
                                return m;
                            }
                            return m;
                        });
                        state.asset.connectorsList[action.payload.connection_id] = assetList;
                    }
                }
            } else {
                if (action.payload.parentData) {
                    const up_index = state.asset.data.findIndex((m) => m.id === action.payload.parentData);
                    if (state.asset.data[up_index].attributes) {
                        const up_index_temp = state.asset.data[up_index].attributes.findIndex((m) => m.id === action.payload.item.id);
                        state.asset.data[up_index].attributes[up_index_temp].is_selected = isChecked;
                        state.asset.data[up_index].is_selected = state.asset.data[up_index].attributes.filter((item) => item.is_selected).length > 0;
                    }
                } else {
                    const up_index = state.asset.data.findIndex((m) => m.id === action.payload.item.id);
                    state.asset.data[up_index].is_selected = isChecked;
                    if (state.asset.data[up_index].attributes) {
                        state.asset.data[up_index].attributes = state.asset.data[up_index].attributes.map((m) => {
                            m.is_selected = isChecked;
                            return m;
                        });
                    }
                }
            }

            state.asset.data.forEach((m) => {
                const up_index = state.asset.connectorsList[action.payload.connection_id].findIndex((n) => m.id === n.id);
                state.asset.connectorsList[action.payload.connection_id][up_index] = m;
            });

            if (action.payload.connection_id in state.asset.connectorsList) {
                state.asset.filterData = state.asset.connectorsList[action.payload.connection_id];
            }
            state.isLoading = false;
        },
        connectorAssetReducerSortingUpdate(state, action) {
            const { sortBy, orderBy, connection_id, view } = action.payload;
            let filteredAssets = state.asset.filterData;
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }

            state.asset.connectorsList[connection_id] = _.orderBy(state.asset.connectorsList[connection_id], [sortBy], [orderBy]);
            filteredAssets = sortBy === "is_selected" ? _.orderBy(filteredAssets, ["name"], ["asc"]) : filteredAssets;
            filteredAssets = _.orderBy(filteredAssets, [sortBy], [orderBy]);
            state.asset.filterData = [...filteredAssets];
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);
            state.asset.sortBy = sortBy;
            state.asset.orderBy = orderBy;
        },
        connectorAssetReducerSearchUpdate(state, action) {
            const { searchFilters, connection_id, view } = action.payload;
            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            const filters = [];
            for (const key of Object.keys(searchFilters)) {
                if ((key !== "view_type" && searchFilters[key] !== "") || (key === "view_type" && searchFilters[key] !== "All")) {
                    filters.push(key);
                }
            }
            if (filters.length) {
                filteredAssets = filteredAssets.filter((item) => {
                    return filters.every((key) => {
                        let value = typeof item[key] === 'number' ? item[key].toString() : item[key];
                        value = value !== null ? value : "";
                        if ((key === "row_count" || key === "watermark_columns") && 'na'.includes(searchFilters[key].toLowerCase()) && !item[key]) {
                            return true;
                        }
                        return typeof value === 'string' && value.toLowerCase().includes(searchFilters[key].toLowerCase());
                    });
                });
            }
            state.asset.filterData = filteredAssets;
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);

        },
        clearConnectorState(state) {
            state.asset = {
                allData: [],
                data: [],
                total: 0,
                limit: 20,
                searchKey: '',
                sortBy: '',
                orderBy: 'asc',
                isLoading: false,
                filterData: []
            };
        },
        loadMoreAssets(state, action) {
            let filteredAssets = state.asset.filterData;
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            const { searchFilters } = action.payload;
            const filters = [];
            for (const key of Object.keys(searchFilters)) {
                if ((key !== "view_type" && searchFilters[key] !== "") || (key === "view_type" && searchFilters[key] !== "All")) {
                    filters.push(key);
                }
            }
            if (filters.length) {
                filteredAssets = filteredAssets.filter((item) => {
                    return filters.every((key) => {
                        let value = typeof item[key] === 'number' ? item[key].toString() : item[key];
                        value = value !== null ? value : "";
                        if ((key === "row_count" || key === "watermark_columns") && 'na'.includes(searchFilters[key].toLowerCase()) && !item[key]) {
                            return true;
                        }
                        return typeof value === 'string' && value.toLowerCase().includes(searchFilters[key].toLowerCase());
                    });
                });
            }

            if (action.payload.view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            const data = _.slice(filteredAssets, state.asset.data.length, state.asset.data.length + state.asset.limit);
            state.asset.data = [...state.asset.data, ...data];
        },
        onChangeView(state, action) {
            const connection_id = action.payload.connection_id;
            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            const pipelineMode = appConstants.toggle.tableQueryAndPipeline[2].name;
            if (action.payload.view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
                if (filteredAssets.length === 0) {
                    const id = uuidv4();
                    filteredAssets = [{ ...state.defaultNewQuery, id }];
                    state.asset.connectorsList[connection_id].push({ ...state.defaultNewQuery, id });
                }
            } else if (action.payload.view === pipelineMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === pipelineMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode && item.asset_type !== pipelineMode);
            }
            state.asset.filterData = [...filteredAssets];
            const data = _.slice(filteredAssets, 0, state.asset.limit);
            state.asset.data = [...data];
            state.asset.view[connection_id] = action.payload.view;
        },
        connectorAssetReducerValueUpdate(state, action) {
            const { id, connection_id, value: { key, value: cValue } } = action.payload;
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            const asset_data_index = state.asset.data.findIndex((m) => m.id === id);
            const asset = state.asset.data[asset_data_index];
            const valuesToUpdate = { [key]: cValue };
            if (asset && asset.asset_type === queryMode && asset.is_connected) {
                valuesToUpdate.is_updated = true;
            }

            if (asset_data_index >= 0) {
                state.asset.data.splice(asset_data_index, 1, { ...asset, ...valuesToUpdate });
            }
            let filteredAssets = state.asset.connectorsList[connection_id];
            filteredAssets = filteredAssets?.length > 0 ? filteredAssets : [];
            const connector_asset_index = filteredAssets.findIndex((n) => n.id === id);
            const connector_asset = filteredAssets[connector_asset_index];
            if (connector_asset_index >= 0) {
                state.asset.connectorsList[connection_id].splice(connector_asset_index, 1, { ...connector_asset, ...valuesToUpdate });
            }
        },
        assetCreateRequest(state) {
            state.asset.saveIsLoading = true;
        },
        assetCreateSuccess(state, action) {
            const { request_data } = action.payload;
            const { connection_id, selectedAsset } = request_data;
            for (const asset of selectedAsset) {
                const asset_data_index = state.asset.data.findIndex((m) => m.id === asset.id);
                if (asset_data_index > -1) {
                    const asset = state.asset.data[asset_data_index];
                    state.asset.data[asset_data_index] = { ...asset, is_connected: true };
                }

                const connection_data_index = state.asset.connectorsList[connection_id].findIndex((m) => m.id === asset.id);
                if (connection_data_index > -1) {
                    const asset = state.asset.connectorsList[connection_id][connection_data_index];
                    state.asset.connectorsList[connection_id][connection_data_index] = { ...asset, is_connected: true };
                }
            }
            state.asset.saveIsLoading = false;
            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (state.asset.view[connection_id] === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            state.asset.filterData = [...filteredAssets];
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);
        },
        asetCreateFailure(state) {
            state.asset.saveIsLoading = false;
        },
        assetDeleteRquest(state) {
            state.isLoading = true;
        },
        assetDeleteSuccess(state, action) {
            const { id, connection_id } = action.payload;
            const index = state.asset.data.findIndex((m) => m.id === id);
            const asset = state.asset.data[index];
            const filterDataindex = state.asset.filterData.findIndex((m) => m.id === id);
            const filterDataAsset = state.asset.filterData[filterDataindex];
            const isQueryMode = asset && asset.asset_type === appConstants.toggle.tableAndQuery[1].name;
            const newUUID = uuidv4();

            state.asset.data[index] = {
                ...asset,
                id: newUUID,
                is_delete: true,
                is_selected: false,
                is_connected: false,
                is_incremental: false
            };
            state.asset.filterData[filterDataindex] = {
                ...filterDataAsset,
                id: newUUID,
                is_delete: true,
                is_selected: false,
                is_connected: false,
                is_incremental: false
            };
            if (isQueryMode) {
                state.asset.data.splice(index, 1);
            }
            const assets = state.asset.connectorsList[connection_id];
            if (assets && assets.length > 0) {
                const allIndex = assets.findIndex((m) => m.id === id);
                const asset = assets[allIndex];
                const isQueryMode = asset && asset.asset_type === appConstants.toggle.tableAndQuery[1].name;

                assets[allIndex] = {
                    ...asset,
                    id: newUUID,
                    is_delete: true,
                    is_selected: false,
                    is_connected: false,
                    is_incremental: false
                };
                if (isQueryMode) {
                    assets.splice(allIndex, 1);
                    state.asset.connectorsList[connection_id] = assets;
                }
            }
            state.isLoading = false;
        },
        assetDeleteFailure(state) {
            state.isLoading = false;
        },
        updateAsset(state, action) {
            const { connection_id } = action.payload;
            const assetIndex = state.asset?.data?.findIndex((m) => m.id === action.payload.id) ?? -1;
            if (assetIndex > -1) {
                state.asset.data[assetIndex] = action.payload;
            }
            const connectorAssetIndex = state.asset?.connectorsList[connection_id]?.findIndex((m) => m.id === action.payload.id) ?? -1;
            if (connectorAssetIndex > -1) {
                state.asset.connectorsList[connection_id][connectorAssetIndex] = action.payload;
            }
        },
        setSelectedConnectionId(state, action) {
            state.selectedConnectionId = action.payload;
            const connectionDetails = { ...state.asset.connectorsList };
            let filteredAssets = connectionDetails[action.payload] || [];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (state.asset.view[action.payload] === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            filteredAssets = _.orderBy(filteredAssets, "is_selected", "desc");
            state.filteredAssets = [...filteredAssets];
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);
        },
        addQuery(state, action) {
            const connection_id = action.payload.connection_id;
            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryAssets = filteredAssets.filter((item) => item.asset_type === appConstants.toggle.tableAndQuery[1].name);
            let assetIndex = -1;
            if (queryAssets && queryAssets.length > 0) {
                assetIndex = filteredAssets.findIndex((item) => item.id === queryAssets[0].id);
            }

            if (filteredAssets && filteredAssets.length > 0 && assetIndex < 0) {
                filteredAssets.push({ ...state.defaultNewQuery, id: uuidv4() });
            } else {
                filteredAssets.splice(assetIndex, 0, { ...state.defaultNewQuery, id: uuidv4() });
            }
            state.asset.connectorsList[connection_id] = filteredAssets;

            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (action.payload.view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            const data = _.slice(filteredAssets, 0, state.asset.limit);
            state.asset.data = [...data];
        },
        resetUpdatedAsset(state, action) {
            const { connection_id } = action.payload;
            if (connection_id) {
                state.asset.data = state.asset.data.map((item) => {
                    item.is_updated = false;
                    return item;
                });

                let filteredAssets = state.asset.connectorsList[connection_id];
                filteredAssets = filteredAssets.map((item) => {
                    item.is_updated = false;
                    return item;
                });
                state.asset.connectorsList[connection_id] = filteredAssets;
            }
        },
        toggleConnectionAsset(state, action) {
            const index = state.asset.data.findIndex((asset) => asset.id === action.payload.id);
            if (index > -1) {
                state.asset.data[index].isOpen = action.payload.isOpen;
            }
        },
        selectAttributeRequest() { },
        selectAttributeSuccess(state, action) {
            const { connection_id, asset_id, attribute_id, name, is_selected, is_bulk_update } = action.payload.data;
            state.asset.data = state.asset.data.map((asset) => {
                if (asset.id !== asset_id) {
                    return asset;
                }
                asset.attributes = asset.attributes.map((attribute) => {
                    if (is_bulk_update || (attribute.column_name === name && attribute.id === attribute_id)) {
                        attribute.is_selected = is_selected;
                    }
                    return attribute;
                });
                return asset;
            });

            if (connection_id) {
                let assetList = state.asset.connectorsList[connection_id];
                assetList = assetList.map((asset) => {
                    if (asset.id !== asset_id) {
                        return asset;
                    }
                    asset.attributes = asset.attributes.map((attribute) => {
                        if (is_bulk_update || (attribute.column_name === name && attribute.id === attribute_id)) {
                            attribute.is_selected = is_selected;
                        }
                        return attribute;
                    });
                    return asset;
                });
                state.asset.connectorsList[connection_id] = assetList;
            }
        },
        selectAttributeFailure() { },
        syncConnectorAssetRequest(state) {
            state.asset.isLoading = true;
            state.asset.data = [];
        },
        syncConnectorAssetSuccess(state, action) {
            const { data, connection_id, view } = action.payload;
            state.asset.isLoading = false;
            const connectionDetails = { ...state.asset.connectorsList };
            connectionDetails[connection_id] = data?.assets ?? [];
            state.asset.connectorsList = { ...connectionDetails };
            state.selectedConnectionId = connection_id;

            let filteredAssets = state.asset.connectorsList[connection_id];
            const queryMode = appConstants.toggle.tableAndQuery[1].name;
            if (view === queryMode) {
                filteredAssets = filteredAssets.filter((item) => item.asset_type === queryMode);
            } else {
                filteredAssets = filteredAssets.filter((item) => item.asset_type !== queryMode);
            }
            state.asset.filterData = [...filteredAssets];
            state.asset.data = _.slice(filteredAssets, 0, state.asset.limit);
            state.asset.view[connection_id] = view;
        },
        syncConnectorAssetFailure(state) {
            state.asset.isLoading = false;
        }
    }
});

export const { connectorAssetRequest, connectorAssetSuccess, connectorAssetFailure, connectorAssetResetByConnectionId, connectorAssetReducerSelectUpdate, connectorAssetReducerSortingUpdate,
    connectorAssetReducerSearchUpdate, clearConnectorState, connectorAttributeRequest, connectorAttributeSuccess, connectorAttributeFailure,
    loadMoreAssets, connectorAssetReducerValueUpdate, assetCreateRequest, assetCreateSuccess, setSelectedConnectionId,
    asetCreateFailure, assetDeleteRquest, assetDeleteSuccess, assetDeleteFailure, updateAsset, onChangeView, addQuery, resetUpdatedAsset,
    toggleConnectionAsset, selectAttributeRequest, selectAttributeSuccess, selectAttributeFailure, syncConnectorAssetRequest, syncConnectorAssetSuccess, syncConnectorAssetFailure } = connectorSlice.actions;
export default connectorSlice.reducer;