import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from 'react-redux';

// import styles
import profileStyles from "../style.jsx";
import { withStyles } from "@mui/styles";
import appConstants from "../../../../../constants/appConstants.js";
import { Grid } from "@mui/material";
import ProfileView from "../../../../../components/profileView/index.jsx";


const PatternDistribution = (props) => {
    const { classes, propertiesPermission, onItemClick, onItemToggle, onDeleteItem, onClickTrending, onToggleRules } = props;
    /**
     * Get data from redux store
     */
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);
    const { measures } = useSelector((state) => state.measure, shallowEqual);
    const patternFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Pattern");
    const regexFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Regular Expressions");
    // const longPatternFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Long Pattern");

    // const shortPatternFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Short Pattern");

    /**
     * Handle item click
     */
    const handleItemClick = useCallback((event, item, index, visualType, type, propertyName) => {
        if (visualType === "list") {
            propertyName = item.type ? item.type : propertyName;
            let values = [];
            const selectedProperty = metadata[propertyName];
            if (selectedProperty) {
                values = JSON.parse(JSON.stringify(selectedProperty));
            }
            values = values?.length > 0 ? values : [];
            const selectedIndex = values.findIndex((value) => value.name === item.name);
            onItemClick(event, item, selectedIndex, visualType, type, propertyName);
        }
    }, [metadata]);

    /**
     * Handle item toggle event
     */
    const handleItemToggle = (event, item, index, type, propertyName) => {
        propertyName = (item?.type === "universal_patterns" && item?.type !== propertyName) ? item.type : propertyName;
        const selectedProperty = metadata[propertyName];
        let values = [];
        if (selectedProperty) {
            values = JSON.parse(JSON.stringify(selectedProperty));
        }
        values = values?.length > 0 ? values : [];
        const selectedIndex = values.findIndex((value) => value.name === item.name);
        onItemToggle(event, item, selectedIndex, type, propertyName);
    };


    return (
        <Grid container justifyContent={"flex-start"} columnSpacing={8} rowSpacing={4} className={classes.topSection}>
            <Grid item xs={12}>
                <ProfileView
                    title={appConstants.profile.shortPattern}
                    description={appConstants.profile.shortPatternDescription}
                    data={metadata.short_universal_patterns || []}
                    propertiesPermission={propertiesPermission}
                    chartType={"value"}
                    onItemClick={(...params) => handleItemClick(...params, "pattern", "short_universal_patterns")}
                    onItemToggle={(...params) => handleItemToggle(...params, "pattern", "short_universal_patterns")}
                    onClickTrending={() => onClickTrending("short_universal_patterns", patternFrequency)}
                    onToggleRules={(value) => onToggleRules("short_universal_patterns", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.short_universal_patterns > 0)}
                    showMeasureLink
                    showValue
                    enableDrillDown
                />
            </Grid>
            <Grid item xs={12}>
                <ProfileView
                    title={appConstants.profile.regularExpressionPattern}
                    description={appConstants.profile.regularExpressionPatternDescription}
                    data={metadata.user_defined_patterns || []}
                    propertiesPermission={propertiesPermission}
                    chartType={"value"}
                    onItemClick={(...params) => handleItemClick(...params, "pattern", "user_defined_patterns")}
                    onItemToggle={(...params) => handleItemToggle(...params, "pattern", "user_defined_patterns")}
                    onAdd={(event) => onItemClick(event, null, -1, null, "pattern", "user_defined_patterns")}
                    onDelete={(...params) => onDeleteItem(...params, "pattern", "user_defined_patterns")}
                    onClickTrending={() => onClickTrending("user_defined_patterns", regexFrequency)}
                    onToggleRules={(value) => onToggleRules("user_defined_patterns", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.user_defined_patterns > 0)}
                    showAdd
                    sort
                    enableDelete
                    showMeasureLink
                />
            </Grid>
        </Grid>
    );
};

// define default props
PatternDistribution.defaultProps = {
    classes: {},
    propertiesPermission: {},
    onItemClick: () => { },
    onItemToggle: () => { },
    onDeleteItem: () => { },
    onClickTrending: () => { },
    onToggleRules: () => { }
};

// define proptypes
PatternDistribution.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object,
    onItemClick: PropTypes.func,
    onItemToggle: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onClickTrending: PropTypes.func,
    onToggleRules: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...profileStyles(theme)
    }),
    { withTheme: true }
) (PatternDistribution);