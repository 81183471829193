import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';


const initialState = {
  isLoading: false,
  createStatus: '',
  list: [],
  detail: {},
  pagination: { offset: 0, limit: 15, search_by: "" },
  stat: { total_count: 0 }
};

const parseReference = (reference) => {
  let properties = reference.properties || {};
  if (typeof (properties) === 'string') {
    properties = JSON.parse(properties);
  }
  reference.properties = properties;

  let input_fields = reference.input_fields || [];
  if (typeof (input_fields) === 'string') {
    input_fields = JSON.parse(input_fields);
  }
  reference.input_fields = input_fields;

  let output_fields = reference.output_fields || [];
  if (typeof (output_fields) === 'string') {
    output_fields = JSON.parse(output_fields);
  }
  reference.output_fields = output_fields;

  let authorization = reference.authorization || [];
  if (typeof (authorization) === 'string') {
    authorization = JSON.parse(authorization);
  }
  reference.authorization = authorization;

  const columns = properties?.columns || [];
  const inputFields = [];
  for (const column of columns) {
    inputFields.push({ id: nanoid(), name: column });
  }
  reference.columns = inputFields;
  return reference;
};

const librariesSlice = createSlice({
  name: 'libraries',
  initialState: { ...initialState },
  reducers: {
    createReferenceRequest(state) {
      state.createStatus = 'pending';
    },
    createReferenceSuccess(state, action) {
      state.createStatus = 'success';
      let reference = action.payload?.data || {};
      if (reference && !Object.keys(reference).length > 0) {
        return;
      }
      reference = parseReference(reference);
      state.list.splice(0, 0, { ...reference });
    },
    createReferenceFailure(state) {
      state.createStatus = '';
    },
    updateReferenceRequest() { },
    updateReferenceSuccess(state, action) {
      let reference = action.payload || {};
      const referenceId = reference?.id || null;
      if (!referenceId || (reference && Object.keys(reference).length <= 0)) {
        return;
      }
      const index = state.list.findIndex((item) => item.id === referenceId);
      reference = parseReference(reference);
      state.list.splice(index, 1, { ...reference });
    },
    updateReferenceFailure() { },
    deleteReferenceRequest() { },
    deleteReferenceSuccess(state, action) {
      if (!action.payload) {
        return;
      }
      const index = state.list.findIndex((item) => item.id === action.payload);
      state.list.splice(index, 1);
    },
    deleteReferenceFailure() { },
    getReferenceDetailRequest() { },
    getReferenceDetailSuccess() { },
    getReferenceDetailFailure() { },
    getReferencesRequest() { },
    getReferencesSuccess(state, action) {
      const references = action?.payload?.data || [];
      const pagination = action?.payload?.pagination || state.pagination;
      const stat = action?.payload?.stat || state.stat;
      state.pagination = { ...pagination };
      state.stat = { ...stat };

      for (let reference of references) {
        reference = parseReference(reference);
      }
      if (pagination?.offset === 0) {
        state.list = [...references];
      } else {
        state.list = [...state.list, ...references];
      }
    },
    getReferencesFailure() { }
  }
});

export const {
  createReferenceRequest, createReferenceSuccess, createReferenceFailure, updateReferenceRequest,
  updateReferenceSuccess, updateReferenceFailure, deleteReferenceRequest, deleteReferenceSuccess,
  deleteReferenceFailure, getReferenceDetailRequest, getReferenceDetailSuccess, getReferenceDetailFailure,
  getReferencesRequest, getReferencesSuccess, getReferencesFailure
} = librariesSlice.actions;
export default librariesSlice.reducer;