import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrop } from 'react-dnd';

//  Import Components
import { Grid } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';


function DropColumn(props) {
    const { classes, onChange, children, term } = props;

    /**
     * OnDrop Event
     * @param {*} data
     */
    const onDropEvent = (data) => {
        onChange(data, term);
    };

    /**
     * Drop
     * @returns
     */
    const Drop = () => {
        const [{ isOver, canDrop }, drop] = useDrop({
            accept: "card",
            drop: onDropEvent,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        });
        return (
            <Grid ref={drop} item className={`${classes.dragBox} ${isOver || canDrop ? "active" : ""}`}>
                {children}
            </Grid>
        );
    };

    return (
        Drop()
    );
}

// default props
DropColumn.defaultProps = {
    classes: {},
    term: {},
    onChange: () => { }
};

// prop types
DropColumn.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    term: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DropColumn);