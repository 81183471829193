import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { Grid, IconButton, Typography, Avatar, Rating, Tooltip, ListItemButton, ListItemText, ListItem, ListItemIcon, List } from '@mui/material';
import { PauseCircleOutlineRounded } from '@mui/icons-material';
import _ from 'lodash';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

//  Import Components
import {
    UsersComponent,
    CircularScoreComponent,
    StatusDropDownComponent,
    PopOverComponent
} from '../../../../components/index.js';
import DialogComponent from '../../../../components/dialogBox/index.jsx';
import AssetTypeIconComponent from '../../../../components/assetTypeIcon/index.jsx';
import ConnectorsIcon from '../../../../components/connectorsIcon/index.jsx';
import VersionComponent from '../version/index.jsx';
import AssetShare from './share.jsx';
import Scheduler from '../../../../components/scheduler/index.jsx';

//  Import Styles
import HeaderStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Icons and Images
import {
    CalendarIcon,
    DangerIcon,
    DeleteIcon2,
    EyeIcon,
    HeartFilledIcon,
    HeartIcon,
    IssuesIcon2,
    PlayIcon2,
    QueriesIcon,
    VersionIcon,
    AttributesIcon,
    CopyIcon
} from '../../../../assets/svg/index.js';

// import Constant
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Helpers
import { toRound, stringAvatar, checkPermission, getApprovalStatus, orderList } from '../../../../helpers/appHelpers.js';

// Import Reducer
import { updateVersionState, updateVersionRequest, updateFavoriteRequest, getAssetStatusRequest } from '../../../../redux/reducer/versionReducer';
import { deleteAssetRequest } from '../../../../redux/reducer/assetReducer';
import { triggerJobRequest } from '../../../../redux/reducer/scheduleReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';
import { displyAlert } from '../../../../redux/reducer/alertReducer';

function Header(props) {
    /**
     * Define Props
     */
    const { classes, theme, data, takeScreenshot, accessByConnection } = props;
    const dispatch = useDispatch();
    const { id: assetId } = useParams();

    /**
     * Resux Select Action
     */
    const { thumbnail_list: users_list } = useSelector((state) => state.user, shallowEqual);
    const { detail: assetDetail } = useSelector((state) => state.version, shallowEqual);
    const { id: attributeId, detail: { name: attributeName } } = useSelector((state) => state.attribute, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const { connection_type } = useSelector((state) => state.default, shallowEqual);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const alertsPermission = checkPermission(permission, featureConstants.home.alerts);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);
    const usagePermission = checkPermission(permission, featureConstants.assets.usage);
    const connectionTypes = connection_type.map((connection) => connection.type.toLowerCase());

    /**
     * Get URL Params
     */
    const location = useLocation();
    const reactNavigate = useNavigate();
    const routeName = location.pathname.split('/');

    /**
     * Define State
     */
    const [versionCard, showVersionCard] = useState(false);
    const [scheduler, setScheduler] = useState(null);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    let statusChecker = null;

    /**
     * Check asset dag running status
     * @param {*} versionDetail
     * @returns
     */
    const checkStatus = (versionDetail) => {
        if (versionDetail.run_status === 'Completed' && versionDetail.semantic_run_status === 'Completed') {
            return;
        }
        dispatch(getAssetStatusRequest(versionDetail.asset));
    };

    /**
     *
     * Define Use Effects for dag run status checker
     */
    useEffect(() => {
        if (assetDetail.run_status && !statusChecker) {
            statusChecker = setInterval(() => checkStatus(assetDetail), appConstants.statusCheckInterval);
        }
        return () => {
            if (statusChecker) {
                clearInterval(statusChecker);
                statusChecker = null;
            }
        };
    }, [assetDetail.run_status]);


    /**
     * Handle onChange Event
     * @param property
     * @param value
     */
    const handleOnChange = (property, value) => {
        const requestParams = {
            [property]: value,
            id: data.id,
            changed_properties: [property],
            values: {
                [property]: value
            }
        };
        dispatch(updateVersionState({ key: property, value }));
        dispatch(updateVersionRequest(requestParams));
    };


    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.assetDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteAssetRequest({ id: data.asset, type: (type === "purge"), connection_id: data.connection_id }));
        dialogCancelEventHandle();
    };

    /**
     * Handle Version Side Bar
     */
    const versionClick = (event) => {
        // showVersionCard(!versionCard);
        showVersionCard(event.currentTarget);
    };

    /**
     * Handle Stewards Users Change
     * @param {*} stewards
     */
    const handleStewardUser = (stewards) => {
        const stewardsId = stewards?.map((item) => item.id);
        const requestParams = {
            'stewards': stewardsId,
            id: data.id,
            'changed_properties': ['steward_user']
        };
        dispatch(updateVersionRequest(requestParams));
        dispatch(updateVersionState({ key: stewards, stewards }));
    };

    /**
     * Copy Connection Path to Keyboard
     */
    const openConnectionString = (type) => {
        if (appConstants.connectionTypes[type]?.disableLink) {
            return null;
        }
        window.open(data.connection_url.url);

    };

    /**
     * Add and Delete Favorite
     */
    const addFavorite = () => {
        if (data.asset) {
            dispatch(updateFavoriteRequest({ "asset": data.asset }));
        }
    };

    /**
     * Show scheduler Dialog Box Cancel Event
     */
    const openScheduler = (event) => {
        setScheduler({
            open: true,
            anchorElement: event.target,
            connectionId: data.connection_id,
            isAsset: true,
            asset: { ...data.asset_detail, is_connected: true, schedule: { ...data.schedule } }
        });
    };

    /**
     * Hangle Close
     */
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    /**
     * Breadcrumb Navigation
     * @param {*} type
     */
    const breadcrumNavigation = (type) => {
        switch (type) {
            case "catalog":
                dispatch(navigate({ path: 'catalog.root', state: {}, params: [] }));
                break;
            case "asset":
                dispatch(navigate({ path: 'assets.root', state: {}, params: [assetId] }));
                break;
            default:
                break;
        }
    };

    /**
     * Check Breadcrumb Condition
     */
    const breadCrumbCondition = () => {
        if (attributeId && attributeId !== "All" && routeName[3] && routeName[3] === "properties") {
            return true;
        }
        return false;
    };

    /**
     * Handle Page Navigations
     * @param {*} key
     */
    const handleQuickNavigation = (key, name) => {
        if (key === "usage") {
            dispatch(navigate({ path: 'assets.usage', state: {}, params: [assetId] }));
        } else {
            if ((key === "alerts" || key === "issues") && data?.status === appConstants.status[3]) {
                return;
            }
            reactNavigate(`/home/${key}?asset_id=${name}`, { replace: true });
        }
    };

    /**
     * Copy the asset id to clip board
     */
    const copyToClipBoard = () => {
        navigator.clipboard.writeText(data.asset);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    /**
     * check if link is disabled
     * @param {*} type
     * @returns
     */
    const checkLink = (type) => {
        if (type && appConstants.connectionTypes[type]?.disableLink) {
            return "nopointer";
        }
        return "";
    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (status) => {
        if (status === 'Completed') {
            dispatch(triggerJobRequest({ "asset_id": data.asset, job_type: "asset" }));
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data.connection_id] }));
        }
    };

    /**
     * Filter users
     * @param {*} listData
     * @returns
     */
    const filterUsers = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const usersList = useMemo(() => filterUsers(users_list), [users_list]);

    return (
        <Grid item xs={12} className={classes.headerContainer}>
            <Grid
                container
                justifyContent={'space-between'}
                alignItems="center"
                className="mb-2"
            >
                <Grid item className={classes.bredcrumbContainer}>
                    <Typography variant="subtitle1" className="item link" onClick={() => breadcrumNavigation('catalog')}>
                        <Grid className="iconContainer">
                            <HomeOutlinedIcon />
                        </Grid>
                        Catalog
                    </Typography>
                    <Typography variant="subtitle1" className={`item ${breadCrumbCondition() && "link"}`} onClick={breadCrumbCondition() ? () => breadcrumNavigation('asset') : () => null}>
                        <Grid className="iconContainer">
                            <AssetTypeIconComponent asset_type={data.asset_type} connection_type={data.connection_type} />
                        </Grid>
                        {assetDetail.name || ""}
                    </Typography>
                    {
                        breadCrumbCondition() &&
                        <Typography variant="subtitle1" className="item">
                            <Grid className="iconContainer">
                                <AttributesIcon />
                            </Grid>
                            {attributeName || ""}
                        </Typography>
                    }
                </Grid>

                <Grid item className={classes.headerAction}>
                    {
                        overviewPermission?.is_view &&
                        <AssetShare takeScreenshot={takeScreenshot} data={{ name: data.name, type: data.type, asset_id: data.asset }} />
                    }
                    {
                        overviewPermission?.is_view &&
                        <Tooltip title="Favorite">
                            <span>
                                <IconButton onClick={() => addFavorite()} disabled={!data.id}>
                                    {
                                        data.is_favourite ? <HeartFilledIcon /> :
                                            <HeartIcon />
                                    }
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {
                        overviewPermission?.is_view && connectionTypes.includes(data.type) && data?.status !== appConstants.status[3] &&
                        <Tooltip title="Schedule">
                            <span>
                                <IconButton onClick={(event) => openScheduler(event)} disabled={!data.id}>
                                    <CalendarIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {
                        overviewPermission?.is_edit && connectionTypes.includes(data.type) && data?.connection_has_license && data?.connection_is_active &&
                        <Tooltip title={assetDetail?.run_status === 'Completed' ? "Run Now" : assetDetail?.run_status ?? "Pending"}>
                            <span>
                                <IconButton onClick={() => handleJobTrigger(assetDetail?.run_status)} disabled={!data.id}>
                                    {
                                        assetDetail?.run_status === 'Completed' ?
                                            <PlayIcon2 />
                                            : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />
                                    }
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {
                        overviewPermission?.is_edit &&
                        <Tooltip title="Delete">
                            <span>
                                <IconButton onClick={() => onDelete()} disabled={!data.id}>
                                    <DeleteIcon2 />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    <PopOverComponent
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className="pt-1 pb-1"
                    >
                        <List className={classes.moreActions}>
                            {
                                data?.status !== appConstants.status[3] &&
                                <ListItem disablePadding>
                                    <ListItemButton onClick={(event) => openScheduler(event)}>
                                        <ListItemIcon>
                                            <CalendarIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Shedule" />
                                    </ListItemButton>
                                </ListItem>
                            }
                            {
                                data?.connection_has_license && data?.connection_is_active &&
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => dispatch(triggerJobRequest({ "asset_id": data.asset, job_type: "asset" }))}>
                                        <ListItemIcon>
                                            <PlayIcon2 />
                                        </ListItemIcon>
                                        <ListItemText primary="Run Now" />
                                    </ListItemButton>
                                </ListItem>
                            }
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => onDelete()}>
                                    <ListItemIcon>
                                        <DeleteIcon2 />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </PopOverComponent>
                </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} className="mb-2">
                <Grid className="dflex alignCenter" sx={{ maxWidth: 'calc(60% - 0px)' }}>
                    <Grid item className="dflex alignCenter w-100">
                        <Grid className="titleSection">
                            <AssetTypeIconComponent asset_type={data.asset_type} connection_type={data.connection_type} />
                        </Grid>
                        <Typography variant="h3" className="ml-1 fw-400 title">
                            {data.name}
                        </Typography>
                        {
                            data.id &&
                            <Tooltip title={"Copy Asset Id"}>
                                <Grid className="copyIcon" onClick={() => copyToClipBoard()}>
                                    <CopyIcon />
                                </Grid>
                            </Tooltip>
                        }
                        {
                            (!(publishPermission?.is_none && approvePermission?.is_none)) &&
                            <Grid className="ml-1">
                                <StatusDropDownComponent
                                    disabled={data?.status === appConstants.status[3] || (!publishPermission?.is_edit && !approvePermission?.is_edit)}
                                    value={data?.status || "Pending"}
                                    onChange={(value) => handleOnChange("status", value)}
                                    dropdownValue={getApprovalStatus(publishPermission, approvePermission, data?.status || "Pending")}
                                />
                            </Grid>
                        }
                        {
                            data.ratings ?
                                <Grid className="dflex alignCenter ml-3">
                                    <Grid item className="starIcon dflex alignCenter">
                                        <Rating className={classes.starIcon} name="half-rating-read" value={toRound(data.ratings)} precision={0.1} size="small" readOnly />
                                    </Grid>
                                    <Typography
                                        variant="body1"
                                        className={`${classes.textSecondary} fw-500 pl-1`}
                                    >
                                        {data.ratings ? toRound(data.ratings) : ''}
                                    </Typography>
                                </Grid> : null
                        }

                        {/* <Grid item className={`${classes.customChips} ml-2`}>
                            <Grid item className="dflex alignCenter">
                                {
                                    data.tags &&
                                    data.tags.map((tag, index) => (
                                        <Grid
                                            key={`tag_${index}`}
                                            className={'chip'}
                                            style={{ backgroundColor: `${tag?.color}30` }}
                                        >
                                            <Typography variant="body1" sx={{ color: `${tag?.color} !important` }}>
                                                {tag?.name ?? ''}
                                            </Typography>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid> */}
                    </Grid>

                </Grid>
                <Grid item className={`${classes.copyDataset} ${checkLink(data?.type)}`} onClick={() => openConnectionString(data?.type)} sx={{ maxWidth: 'calc(40% - 50px)' }}>
                    <Grid className={`datasetIcon ${data.type || ''}`}>
                        <ConnectorsIcon connection_type={data.type} />
                    </Grid>
                    <Tooltip title={data.connection_string || ""} arrow>
                        <Typography variant="body1" className="pr-15 capitalize">
                            {data.connection_string}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'space-between'}
                className={`${classes.bottomSection}`}
            >
                <Grid className="dflex alignCenter">
                    <Grid className="dflex alignCenter">
                        <Grid>
                            <CircularScoreComponent value={data.dqscore || 0} showValue />
                        </Grid>
                    </Grid>
                    {
                        accessByConnection && accessByConnection.alerts &&
                        <Grid item className="dflex alignCenter ml-3">
                            <DangerIcon />
                            <Typography variant="body1" className={`pl-1 ${data?.status !== appConstants.status[3] ? 'pointer' : ''}`} onClick={() => alertsPermission?.is_view && handleQuickNavigation("alerts", data.asset)}>
                                {`${data.alerts || 0} Alerts`}
                            </Typography>
                        </Grid>
                    }
                    {
                        accessByConnection && accessByConnection.issues &&
                        <Grid item className="dflex alignCenter ml-3">
                            <IssuesIcon2 />
                            <Typography variant="body1" className={`ml-1 ${data?.status !== appConstants.status[3] ? 'pointer' : ''}`} onClick={() => issuePermission?.is_view && handleQuickNavigation("issues", data.asset)}>
                                {`${data.issues || 0} Issues`}
                            </Typography>
                        </Grid>
                    }
                    <Grid item className="dflex alignCenter ml-3">
                        <EyeIcon />
                        <Typography variant="body1" className="pl-1">
                            {`${data.views || 0} Views`}
                        </Typography>
                    </Grid>
                    {
                        accessByConnection && accessByConnection.attributes &&
                        <Grid item className="dflex alignCenter ml-3 pointer" onClick={() => usagePermission?.is_view && handleQuickNavigation("usage")}>
                            <QueriesIcon />
                            <Typography variant="body1" className="pl-1">
                                {`${data.queries || 0} Queries`}
                            </Typography>
                        </Grid>
                    }
                    <Grid className="ml-3">
                        <UsersComponent
                            list={usersList}
                            data={data.stewards || []}
                            isSteward
                            disabled={!overviewPermission?.is_edit}
                            handleStewardUser={(stewards) => handleStewardUser(stewards)} />
                    </Grid>
                </Grid>
                <Grid item className="dflex alignCenter">
                    <Typography variant="body1">
                        Last Updated
                    </Typography>
                    <Grid item className="dflex alignCenter ml-1">
                        {
                            data && data.created_by &&
                            <Avatar
                                alt={data?.updated_by?.name ?? data.created_by.name}
                                {...stringAvatar(data?.updated_by?.name ?? data.created_by.name, data?.updated_by?.avatar ?? data.created_by?.avatar)}
                                sx={{ width: 24, height: 24 }}
                            />
                        }
                        <Typography variant="body1" className="pl-1">
                            {/* {moment(data.modified_date || data.created_date).calendar()} */}
                            {
                                moment(data.modified_date || data.created_date).calendar(null, {
                                    sameDay: '[Today at] h:mm A',
                                    nextDay: '[Tomorrow at] h:mm A',
                                    lastDay: '[Yesterday at] h:mm A',
                                    lastWeek: 'dddd [at] h:mm A',
                                    sameElse: 'MMM DD YYYY'
                                })
                            }
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className="dflex alignCenter ml-3 pointer "
                        onClick={versionClick}
                    >
                        <VersionIcon />
                        <Typography variant="body1" className="pl-1">
                            V
                            {' '}
                            {data.version ? data.version : "0.0"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
                showDialog.open && (
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        optionButtonContent={
                            {
                                show: true,
                                title: appConstants.dialogBox.purge,
                                color: 'secondary'
                            }
                        }
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle}
                    />
                )
            }
            <PopOverComponent
                className={classes.versionPopover}
                open={Boolean(versionCard)}
                anchorEl={versionCard}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }
                onClose={() => { showVersionCard(null); }}>
                <VersionComponent
                    closeVersion={() => { showVersionCard(null); }}
                    slideIn={Boolean(versionCard)}
                    assetId={data.asset}
                />
            </PopOverComponent>
            {
                scheduler &&
                <Scheduler
                    scheduleProps={scheduler}
                    confirmDelete
                    disabled={!overviewPermission?.is_edit}
                    onClose={() => setScheduler({ open: false, anchorElement: null, connectionId: null, isAsset: false, asset: {} })}
                    onDelete={() => setScheduler({ open: false, anchorElement: null, connectionId: null, isAsset: false, asset: {} })}
                    category="asset"
                />
            }
        </Grid >
    );
}

// default props
Header.defaultProps = {
    classes: {},
    theme: {},
    data: {},
    accessByConnection: null,
    takeScreenshot: () => { }
};

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    data: PropTypes.object,
    accessByConnection: PropTypes.object,
    takeScreenshot: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return (
        _.isEqual(prevProps.data, nextProps.data) &&
        _.isEqual(prevProps.version_data, nextProps.version_data) &&
        _.isEqual(prevProps.accessByConnection, nextProps.accessByConnection) &&
        _.isEqual(prevProps.classes, nextProps.classes)
    );
}

export default withStyles(
    (theme) => ({
        ...HeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Header, areEqual));