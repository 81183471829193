import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector, useDispatch } from 'react-redux';

// Import Component
import DropColumn from './dropColumn.jsx';
import DragItem from './dragItem.jsx';
import { AssetTypeIconComponent, CircularScoreComponent } from '../../../../../../components/index.js';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';
import palette from '../../../../../../assets/theme/palette';

// Import Actions
import { navigate } from '../../../../../../redux/reducer/navigationReducer.js';


function KanbanView(props) {

    /**
     * Define Props
     */
    const { classes, terms, attributes, onChange } = props;
    const dispatch = useDispatch();

    /**
     * Redux Stor
     */
    const { scoring } = useSelector((state) => state.configurations.general);

    /**
     * Get Score Color
     * @param {*} value
     * @returns
     */
    const getScoreColor = (value) => {
        const { values, quadrant } = scoring;
        const QuadSettig = values[quadrant - 2];
        for (let i = 0; i < QuadSettig?.length; i++) {
            if (Math.ceil(value) >= QuadSettig[i].from && Math.ceil(value) <= QuadSettig[i].to) {
                return QuadSettig[i].color;
            }
        }
        return palette.secondary.main;
    };

    /**
     * Filter TermAttributes
     * @param {*} termId
     * @returns
     */
    const termAttributes = (termId) => {
        return attributes.filter((attribute) => attribute.term_id === termId);
    };

    const redirect = (item, type) => {
        if (type === "attribute") {
            dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [item.asset_id, item.attribute_id] }));
        } else {
            dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid className={`${classes.viewContainer} viewContainer`}>
                {
                    terms.map((term, index) =>
                        <DropColumn
                            term={term}
                            onChange={onChange}
                            key={index}
                        >
                            <Grid item className={`${classes.termsStripeCard} ${!term.id ? "disabled" : ""}`}>
                                <Typography variant="body1">
                                    {term.name || "Others"}
                                </Typography>
                                <Typography variant="body1">
                                    {`${term.score || 0} %`}
                                </Typography>
                            </Grid>
                            <Grid>
                                {
                                    termAttributes(term.id).map((attribute, index) =>
                                        <DragItem data={attribute} key={`${attribute.name}_${index}`}>
                                            <Grid className={classes.termsCard} sx={{ borderColor: `${getScoreColor(attribute.score)} !important` }}>
                                                <Grid container alignItems={"center"} justifyContent={"space-between"} className="pb-1">
                                                    <Grid item>
                                                        <Grid className="dflex alignCenter pb5">
                                                            <Typography variant="h6" className={classes.typeLabel}>
                                                                {attribute.derived_type && attribute.derived_type.charAt(0) || 'T'}
                                                            </Typography>
                                                            <Typography variant="h6" className={`pl-1 pointer`} onClick={() => redirect(attribute, "attribute")}>
                                                                {attribute.name || ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid className="dflex alignCenter">
                                                            <AssetTypeIconComponent
                                                                asset_type={attribute.asset_type}
                                                                connection_type={attribute.connection_type}
                                                            />
                                                            <Typography variant="body1" className={`${classes.textSecondary} pl-1 pointer`} onClick={() => redirect(attribute, "asset")}>
                                                                {attribute.asset_name || ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <CircularScoreComponent
                                                            size={40}
                                                            value={attribute.score || 0}
                                                            showPercentage
                                                            noLink
                                                            showValue />
                                                    </Grid>
                                                </Grid>
                                                <Typography variant="body1" className={classes.assetsText}>
                                                    {attribute?.alerts ?? 0}
                                                    {' '}
                                                    Alerts
                                                    <span className="assetDot" />
                                                    {attribute?.issues ?? 0}
                                                    {' '}
                                                    Issues
                                                </Typography>
                                            </Grid>
                                        </DragItem>
                                    )
                                }
                            </Grid>
                        </DropColumn>
                    )
                }
            </Grid>
        </DndProvider>
    );
}

// default props
KanbanView.defaultProps = {
    classes: {},
    terms: [],
    attributes: [],
    onChange: () => { }
};

// prop types
KanbanView.propTypes = {
    classes: PropTypes.object,
    terms: PropTypes.array,
    attributes: PropTypes.array,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(KanbanView);