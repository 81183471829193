import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    saveLoading: false,
    configuration: {},
    collibraAttributeTypes: [],
    alationDataSource: []
};

const integrationSlice = createSlice({
    name: 'integration',
    initialState: { ...initialState },
    reducers: {
        getConfigurationRequest(state) {
            state.isLoading = true;
            state.configuration = {};
        }, getConfigurationSuccess(state, action) {
            state.isLoading = false;
            state.configuration = action.payload?.config ?? {};
            state.configuration = {
                ...state.configuration
            };
        }, getConfigurationFailure(state) {
            state.isLoading = false;
            state.configuration = {};
        }, setConfiguration(state, action) {
            state.configuration = action.payload || {};
        }, createConfigrationRequest(state) {
            state.saveLoading = true;
        }, createConfigrationSuccess(state, action) {
            state.saveLoading = false;
            state.configuration = action.payload?.config ?? {};
            state.configuration = {
                ...state.configuration,
                id: action.payload?.id ?? "",
                channel: action.payload?.channel ?? ""
            };
        }, createConfigrationFailure(state) {
            state.saveLoading = false;
        }, updateConfigurationRequest(state) {
            state.saveLoading = true;
        }, updateConfigurationSuccess(state) {
            state.saveLoading = false;
        }, updateConfigurationFailure(state) {
            state.saveLoading = false;
        }, deleteConfigurationRequest(state) {
            state.saveLoading = true;
        }, deleteConfigurationSuccess(state) {
            state.saveLoading = false;
            state.configuration = {};
            state.alationDataSource = [];
        }, deleteConfigurationFailure(state) {
            state.saveLoading = false;
        }, updateConfiguration(state, action) {
            state.configuration = {
                ...state.configuration,
                ...action.payload
            };
        }, getCollibraAttributeTypesRequest(state) {
            state.saveLoading = true;
        }, getCollibraAttributeTypesSuccess(state, action) {
            state.collibraAttributeTypes = action.payload.data || [];
            state.saveLoading = false;
        }, getCollibraAttributeTypesFailure(state) {
            state.saveLoading = false;
        }, getAlationDataSourceRequest(state) {
            state.isLoading = true;
        }, getAlationDataSourceSuccess(state, action) {
            state.alationDataSource = action.payload.data || [];
            state.isLoading = false;
        }, getAlationDataSourceFailure(state) {
            state.isLoading = false;
        }
    }
});

export const { getConfigurationRequest, getConfigurationSuccess, getConfigurationFailure, setConfiguration, createConfigrationRequest, createConfigrationSuccess,
    createConfigrationFailure, updateConfigurationRequest, updateConfigurationSuccess, updateConfigurationFailure, updateConfiguration,
    deleteConfigurationRequest, deleteConfigurationSuccess, deleteConfigurationFailure, getCollibraAttributeTypesRequest, getCollibraAttributeTypesSuccess,
    getCollibraAttributeTypesFailure, getAlationDataSourceRequest, getAlationDataSourceSuccess,
    getAlationDataSourceFailure
} = integrationSlice.actions;
export default integrationSlice.reducer;