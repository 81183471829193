import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import DropBox from '../../../../DropBox/index.jsx';
import SelectedFields from '../../../../SelectedFields/index.jsx';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../../../../layouts/style.jsx';


function CountConfiguration(props) {
    const { classes, data, onChange } = props;

    /**
     * Get Detail
     * @param {*} item
     * @param {*} type
     * @returns
     */
    const getDetail = (item, type) => {
        if (type === "yAxis" && (item.datatype === "text" || item.datatype === "date")) {
            item.query = item.aggregationColumn || `count(distinct(${item.column}))`;
            item.alias_name = `Distinct Count of ${item.name}`;
            item.type = "measure";
        } else {
            item.query = item.column;
            item.alias_name = item.name;
            item.type = item.datatype === "text" || item.datatype === "date" ? "dimension" : "measure";
        }
        return item;
    };

    /**
     * OnDrop
     * @param {*} dropType
     * @param {*} item
     */
    const onDrop = (dropType, item) => {
        if (dropType === "xAxis" && item.datatype !== "date") {
            return false;
        }
        item = { ...getDetail(item, dropType) };
        const properties = Object.assign({}, { ...data });
        properties[dropType] = [{ ...item }];
        onChange(properties);
    };

    /**
     * On Delete Item
     * @param {*} dropType
     */
    const onDeleteItem = (dropType) => {
        const properties = Object.assign({}, { ...data });
        properties[dropType] = [];
        onChange(properties);
    };

    return (
        <Grid className={classes.nonLimitDragContainer}>
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    X-Axis
                </Typography>
            </Grid>
            <SelectedFields data={data?.xAxis ?? []}
                onDelete={() => onDeleteItem("xAxis")}
                accept={["dimension"]}
                onDrop={(item) => onDrop("xAxis", item)} />
            {
                !data.xAxis.length &&
                <DropBox accept={["dimension"]} onDrop={(item) => onDrop("xAxis", item)} />
            }
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    Y-Axis
                </Typography>
            </Grid>
            <SelectedFields data={data?.yAxis ?? []}
                onDelete={() => onDeleteItem("yAxis")}
                accept={["dimension"]}
                onDrop={(item) => onDrop("yAxis", item)} />
            {
                !data.yAxis.length &&
                <DropBox accept={["dimension"]} onDrop={(item) => onDrop("yAxis", item)} />
            }
        </Grid>
    );
}

// default props
CountConfiguration.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { }
};

// prop types
CountConfiguration.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CountConfiguration);