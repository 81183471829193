import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//  Import Styles
import SemanticAppStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';

// Import Component
import { TableComponent, DialogComponent } from '../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Actions
import { createFieldsRequest, getFieldsGroupRequest, getFieldsRequest, getFieldsTabRequest, updatePropertyRequest, updateFieldsRequest, deleteFieldsRequest } from '../../../redux/reducer/fieldsReducer';
import { updateUserPreference } from '../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../redux/reducer/userReducer';

// Import Helpers
import { checkPermission, permissionHeaders, prepareUpdateUserPreference, getUserPreference } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';
import { displyAlert } from '../../../redux/reducer/alertReducer.js';

function Fields(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        "name": "",
        "description": "",
        "data_type": "",
        "options": "",
        "level": "",
        "custom_field_tab": "",
        "custom_field_group": ""
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { list: fields, tabs, groups, isLoading } = useSelector((state) => state.fields, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "fields", "columns");

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Get Tab and Group Property
     * @param {*} key
     * @param {*} value
     * @returns
     */
    const getTabGroupProperty = (key, value) => {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        const returnData = {};
        if (key === "custom_field_group") {
            value = groups.find((group) => group.name === value)?.id ?? value;
        } else {
            value = tabs.find((tab) => tab.name === value)?.id ?? value;
        }
        if (!regexExp.test(value)) {
            if (key === "custom_field_group") {
                returnData.custom_field_group_name = value;
            } else {
                returnData.custom_field_tab_name = value;
            }
        }
        returnData[key] = value;
        return returnData;
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        let requestData = {
            id: item.id
        };
        if (key === "custom_field_tab" || key === "custom_field_group") {
            if (!value) {
                return false;
            }
            const data = getTabGroupProperty(key, value);
            if (appConstants.commonTabs.includes(data.custom_field_tab?.toLowerCase())) {
                return false;
            }
            requestData = {
                ...requestData,
                ...data
            };
        } else {
            requestData[key] = value;
            requestData.level = item.level;
        }

        if (key === "name") {
            const level = item.level || "Asset";
            const isDuplicate = fields.filter((field) => field.name?.toLowerCase() === value?.toLowerCase() && field.level === level).length > 0;
            if (isDuplicate) {
                dispatch(displyAlert({ type: 'error', message: 'Name already exists' }));
                return false;
            }
        }

        dispatch(updatePropertyRequest(requestData));
        dispatch(updateFieldsRequest(requestData));
    };


    /**
     * Handle Adding the new Chip in the Chip Component
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     */
    const handleChipAdd = (value, fieldValue, item, key) => {
        const requestData = {
            id: item.id,
            [key]: [...fieldValue, value],
            level: item.level
        };
        dispatch(updatePropertyRequest(requestData));
        dispatch(updateFieldsRequest(requestData));
    };

    /**
     * Handle Adding or Deleting Dimension
     * @param {*} item
     * @param {*} item
     */
    const handleTableActions = (item, actionType) => {
        if (actionType === 'delete') {
            setShowDeleteDialog({
                open: true,
                title: appConstants.dialogBox.delete,
                message: appConstants.dialogBox.fieldDeleteMessage,
                data: { id: item?.id }
            });
        }
        if (actionType === 'create') {
            const level = item.level || "Asset";
            const isDuplicate = fields.filter((field) => field.name?.toLowerCase() === item.name?.toLowerCase() && field.level === level).length > 0;
            if (isDuplicate || ((item.level === "Asset" && (!item.custom_field_tab || !item.custom_field_group)) || !item.name)) {
                if (isDuplicate) {
                    dispatch(displyAlert({ type: 'error', message: 'Name already exists' }));
                }
                return false;
            }
            let tabData = {};
            let groupData = {};
            if (item.custom_field_tab && item.custom_field_group) {
                tabData = getTabGroupProperty("custom_field_tab", item.custom_field_tab);
                groupData = getTabGroupProperty("custom_field_group", item.custom_field_group);
                if (appConstants.commonTabs.includes(tabData.custom_field_tab.toLowerCase())) {
                    return false;
                }
            }
            item = {
                ...item,
                ...tabData,
                ...groupData
            };
            if (!item.data_type) {
                item.data_type = "Text";
            }
            dispatch(createFieldsRequest(item));
        }
    };

    /**
     * Prepare Filter Fields
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterFields = (data, tabs, groups, searchFilters) => {
        data = data.map((obj) => {
            const tab = tabs.find((tab) => tab.id === obj.custom_field_tab) || {};
            const group = groups.find((group) => group.id === obj.custom_field_group) || {};
            return {
                ...obj,
                custom_field_group: group.name || "",
                custom_field_tab: tab.name || ""
            };
        });
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            data = data.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (key === "options") {
                        if (!value.some((obj) => obj?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase()))) {
                            return false;
                        }
                    } else {
                        if (!value?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase())) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        return data;
    };

    /**
     * Filter Dimension using UseMemo
     */
    const filterFields = useMemo(() => prepareFilterFields(fields, tabs, groups, searchData), [fields, tabs, groups, searchData]);

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDeleteDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type, params) => {
        dispatch(deleteFieldsRequest(params.id));
        dialogCancelEventHandle();
    };

    /**
     * Get Default Data
     */
    useEffect(() => {
        dispatch(getFieldsRequest());
        if (!groups.length) {
            dispatch(getFieldsGroupRequest());
        }
        if (!tabs.length) {
            dispatch(getFieldsTabRequest());
        }
    }, []);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "fields", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    return (
        <Grid container className={classes.appContainer} justifyContent="space-between">
            <Grid item xs={12}>
                <ValidatorForm onSubmit={() => null}>
                    <TableComponent
                        styleType="striped"
                        headers={permissionHeaders(appConstants.table.fieldsTableHeader, semanticsPermission)}
                        isAdd={semanticsPermission?.is_edit}
                        data={filterFields || []}
                        onCompnentEvent={onCompnentEvent}
                        onClickActions={handleTableActions}
                        onHandleChipAdd={handleChipAdd}
                        searchData={searchData}
                        onHandleSearchEvent={onHandleSearchEvent}
                        options={appConstants.tableOptions.common}
                        NoResultText="No Fields Found"
                        title={appConstants.labels.securityTab.fields}
                        description={appConstants.labels.securityTab.fieldsDesc}
                        isLoading={isLoading}
                        height="calc(100vh - 296px)"
                        selectComponentList={
                            {
                                level: ["Asset", "Measure"],
                                data_type: appConstants.commonDataTypes,
                                custom_field_tab: tabs,
                                custom_field_group: groups
                            }
                        }
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        stickyHeader
                    />
                </ValidatorForm>
            </Grid>
            {
                showDeleteDialog.open && (
                    <DialogComponent
                        open={showDeleteDialog.open}
                        title={showDeleteDialog.title}
                        message={showDeleteDialog.message}
                        data={showDeleteDialog.data}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                    />
                )
            }
        </Grid>
    );
}

// default props
Fields.defaultProps = {
    classes: {}
};

// prop types
Fields.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticAppStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Fields);