/* eslint no-underscore-dangle: 0 */
/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from 'lodash';
import React from 'react';

import { actions } from '../config.ts';
import Tooltip from './tooltip/index.tsx';

export interface action {
  key: string,
  icon: string | JSX.Element,
  title?: string;
  onClick: (canvas: any) => void;
  disable: boolean;
}

interface IProps {
  canvas: any;
  actionMenu: action[];
  visible: boolean;
}

const ActionMenu = (props: IProps) => {
  let { actionMenu = [] } = props;
  const { canvas, visible } = props;

  if (!visible) {
    return null;
  }

  if (!Array.isArray(actionMenu)) {
    actionMenu = [];
  }

  // Merge action menu
  let sysActions = _.cloneDeep(actions);
  const allActions: any = [];

  for (const action of actionMenu) {
    const sysAction: any = _.find(sysActions, (a) => {
      return a.key === action.key;
    });

    if (!sysAction) {
      allActions.push(action);

      continue;
    }

    // Merge user keys with the same name
    _.merge(sysAction, action);
    allActions.push(sysAction);

    // Remove user-overridden actions
    sysActions = sysActions.filter((action) => action.key !== sysAction.key);
  }

  sysActions.forEach((sysAction) => {
    allActions.unshift(sysAction);
  });

  // Compatible with multiple types of icon rendering
  const renderIcon: any = (icon: any) => {
    if (typeof icon === 'string') {
      return <i className={icon} />;
    }

    if (React.isValidElement(icon)) {
      return icon;
    }

    return null;
  };

  return (
    <div className="lineage-dag-canvas-action">
      {
        allActions.map((action: any) => {
          if (action.disable) {
            return null;
          }

          return (
            <div
              key={action.key}
              title={action.title}
              role="presentation"
              onClick={() => action.onClick(canvas)}
              onKeyDown={() => action.onClick(canvas)}
            >
              <Tooltip title={action.title}>
                {renderIcon(action.icon)}
              </Tooltip>
            </div>
          );
        })
      }
    </div>
  );
};

export default ActionMenu;