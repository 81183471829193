import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

//  Import Components
import { Grid, Typography } from '@mui/material';
import {
    DynamicBarChartComponent, PolarChartComponent, TableChartComponent, DynamicLineChartComponent, ChartTypeIcon, HierachyComponent,
    RadarChartComponent, GaugeComponent, TrendingCountComponent, SunBurstChartComponent, MultiDimHeatMap
} from '../../../../components/index';

// import Helpers
import { getAliasName, getChartData, getStackedData, isFloat, getDayFilterDuration, prepareHierarchy, toRound } from '../../../../helpers/appHelpers';

// Import Reducer
import { navigate } from '../../../../redux/reducer/navigationReducer.js';

// Import constant
import appConstants from '../../../../constants/appConstants';
import palette from '../../../../assets/theme/palette';

function CustomWidgetComponents(props) {

    const { data, type, onChangeProperties } = props;
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const { scoring } = useSelector((state) => state.configurations.general);

    /**
     * Define the color based on the score
     * @param {*} value
     * @returns
     */
    const getColor = (value) => {
        const { values, quadrant } = scoring;
        const QuadSettig = values[quadrant - 2];
        for (let i = 0; i < QuadSettig?.length; i++) {
            if (Math.ceil(value) >= QuadSettig[i].from && Math.ceil(value) <= QuadSettig[i].to) {
                return QuadSettig[i].color;
            }
        }
        return palette.secondary.main;
    };

    /**
     * Sense Label
     * @returns
     */
    const senseLabel = () => {
        const properties = data.properties || {};
        const breakDown = properties.breakDown || [];
        const widgetType = data.widget_type || "vertical_bar";
        const skipColumn = ["measure_type", "measure_name", "measure_category", "measure_level"];
        let label = "";
        if (breakDown.length) {
            label = getAliasName(breakDown[0].alias_name);
            if (label.includes("date") || skipColumn.includes(label)) {
                label = "";
            }
        }
        if (!label) {
            const xLabel = widgetType === "horizontal_bar" ? properties.yAxis : properties.xAxis;
            const yLabel = widgetType === "horizontal_bar" ? properties.xAxis : properties.yAxis;
            if (xLabel && xLabel.length) {
                label = getAliasName(xLabel[0].alias_name);
            }
            if (yLabel && yLabel.length) {
                const yAxisName = getAliasName(yLabel[0].alias_name).toLowerCase();
                if (yAxisName === "alerts" || yAxisName === "issues") {
                    label = yAxisName;
                }
            }
            if (skipColumn.includes(label) || label.includes("date")) {
                if (yLabel && yLabel.length) {
                    label = getAliasName(yLabel[0].alias_name);
                }
            }

        }
        return label;
    };

    /**
     * Chart Event
     */
    const onChartEvent = (params) => {
        if (type !== "preview") {
            const label = senseLabel();
            const report = data?.report?.default || [];
            const seriesName = params.seriesName === "series\u00000" ? params.name : params.seriesName;
            const chartData = report.find((obj) => obj[label] === seriesName) || report.find((obj) => obj[label] === params.name)
                || report.find((obj) => obj[label] === params[label]);
            switch (label.toLowerCase()) {
                case 'attribute_name':
                case 'asset_name':
                    if (label === 'attribute_name') {
                        dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [chartData.asset_id, chartData.attribute_id] }));
                    } else {
                        dispatch(navigate({ path: 'assets.root', state: {}, params: [chartData.asset_id] }));
                    }
                    break;
                case 'application_name':
                    if (chartData?.application_id) {
                        navigation(`/semantics/applications/${chartData.application_id}`, { replace: true });
                    } else {
                        dispatch(navigate({ path: 'settings.semantics.applications.root', state: {}, params: [] }));
                    }
                    break;
                case 'domain_name':
                    dispatch(navigate({ path: 'domain.detail', state: {}, params: [chartData.domain_id] }));
                    break;
                case 'dimension_name':
                    dispatch(navigate({ path: 'settings.semantics.dimension.root', state: {}, params: [] }));
                    break;
                case 'tag_name':
                    if (chartData.tag_id) {
                        navigation(`/catalog?tag=${chartData.tag_id}`, { replace: true });
                    } else {
                        dispatch(navigate({ path: 'settings.semantics.tags.root', state: {}, params: [] }));
                    }
                    break;
                case 'term_name':
                    if (chartData.term_id) {
                        navigation(`/catalog?term=${chartData.term_id}`, { replace: true });
                    }
                    break;
                case 'alert_priority':
                case 'alerts':
                    let alertDay;
                    let alertStatus;
                    if (Date.parse(params.name)) {
                        alertDay = params.name;
                    } else if (Date.parse(params.seriesName)) {
                        alertDay = params.seriesName;
                    } else {
                        alertDay = moment().subtract(16, "days");
                    }
                    if (appConstants.priority.includes(params.name)) {
                        alertStatus = params.name;
                    } else if (appConstants.priority.includes(params.seriesName)) {
                        alertStatus = params.seriesName;
                    }
                    const alertData = report.find((obj) => obj.asset_name === params.name) || report.find((obj) => obj.asset_name === params.seriesName);
                    const data = new URLSearchParams({
                        day: alertDay ? getDayFilterDuration(alertDay) : "",
                        // measure_name: alertData.measure_type,
                        status: alertStatus || "",
                        asset: alertData && alertData.asset_name ? alertData.asset_name : ""
                    });
                    navigation(`/home/alerts?${data.toString()}`, { replace: true });
                    break;
                case 'issue_priority':
                case 'issue_status':
                case 'issues':
                    let status;
                    let priority;
                    if (appConstants.issueStatus.includes(params.name)) {
                        status = params.name;
                    } else if (appConstants.issueStatus.includes(params.seriesName)) {
                        status = params.seriesName;
                    }
                    if (appConstants.priority.includes(params.name)) {
                        priority = params.name;
                    } else if (appConstants.priority.includes(params.seriesName)) {
                        priority = params.seriesName;
                    }
                    let day;
                    if (Date.parse(params.name)) {
                        day = params.name;
                    } else if (Date.parse(params.seriesName)) {
                        day = params.seriesName;
                    } else {
                        day = moment().subtract(16, "days");
                    }
                    const issueData = report.find((obj) => obj.asset_name === params.name) || report.find((obj) => obj.asset_name === params.seriesName);
                    const issueQuery = new URLSearchParams({
                        day: day ? getDayFilterDuration(day) : "",
                        priority: priority || "",
                        status: status || "",
                        asset: issueData && issueData.asset_name ? issueData.asset_name : ""
                    });
                    navigation(`/home/issues?${issueQuery.toString()}`, { replace: true });
                    break;
                default:
                    break;
            }
        }
    };


    /**
     * Render Widget
     * @returns
     */
    const renderWidget = () => {
        let report = data?.report?.default?.result ?? data?.report?.default ?? [];
        const chartProperties = data?.properties?.chartProperties ?? {};
        const properties = data?.properties ?? {};
        const xAxis = properties.xAxis || [];
        const yAxis = properties.yAxis || [];
        const breakDown = properties.breakDown || [];
        const xAxisLabel = xAxis.length ? getAliasName(xAxis[0].alias_name) : "";
        const yAxisLabel = yAxis.length ? getAliasName(yAxis[0].alias_name) : "";
        const xAxisAliasName = xAxis[0]?.alias_name ?? "";
        const yAxisAliasName = yAxis[0]?.alias_name ?? "";
        const xAxisName = xAxis[0]?.name ?? "";
        const yAxisName = yAxis[0]?.name ?? "";
        const levels = properties?.levels || [];
        if (data.widget_type === appConstants.dashboardWigetTypes.hierachy) {
            const dataLevels = levels.filter((data) => Object.keys(data).length);
            if (!dataLevels.length) {
                report = [];
            }
        }
        if (report.length) {
            switch (data.widget_type) {
                case appConstants.dashboardWigetTypes.polar:
                    const polarData = {};
                    for (const obj of report) {
                        polarData[obj[xAxisLabel]] = obj[yAxisLabel];
                    }
                    return (<PolarChartComponent
                        data={polarData}
                        color={data?.properties?.color}
                        containerId={`${data.name}_chart_container`}
                        labelFormatType={xAxisLabel.includes("score") || yAxisLabel.includes("score") ? "value" : "percentage"}
                        showPercentage
                    />);
                case appConstants.dashboardWigetTypes.horizontalBar:
                case appConstants.dashboardWigetTypes.verticalBar:
                    let chartData = {};
                    if (!breakDown.length) {
                        chartData = {
                            xAxis: xAxisLabel ? report.map((data) => (isFloat(data[xAxisLabel]) ? data[xAxisLabel].toFixed(1) : data[xAxisLabel])) : [],
                            yAxis: yAxisLabel ? report.map((data) => (isFloat(data[yAxisLabel]) ? data[yAxisLabel].toFixed(1) : data[yAxisLabel])) : [],
                            data: getChartData(report, data.widget_type, { xAxisLabel, yAxisLabel }),
                            xAxisLabel: xAxisAliasName,
                            yAxisLabel: yAxisAliasName
                        };
                        if (xAxisLabel.includes("score") || yAxisLabel.includes("score")) {
                            chartData.data = chartData.data.map((item) => {
                                return {
                                    value: item,
                                    itemStyle: {
                                        color: getColor(item)
                                    }
                                };
                            });
                        }
                    } else {
                        const breakDownLabel = getAliasName(breakDown[0].alias_name);
                        const axisType = data.widget_type === appConstants.dashboardWigetTypes.verticalBar ? yAxisLabel : xAxisLabel;
                        const groupByType = data.widget_type === appConstants.dashboardWigetTypes.verticalBar ? xAxisLabel : yAxisLabel;
                        const uniqueNames = [...new Set(report.map((obj) => obj[groupByType]))];
                        const stackedData = getStackedData(report, uniqueNames, breakDownLabel, groupByType, axisType);
                        chartData = {
                            xAxis: xAxisLabel ? [...new Set(report.map((data) => (isFloat(data[xAxisLabel]) ? data[xAxisLabel] : data[xAxisLabel])))] : [],
                            yAxis: yAxisLabel ? [...new Set(report.map((data) => (isFloat(data[yAxisLabel]) ? data[yAxisLabel] : data[yAxisLabel])))] : [],
                            data: stackedData,
                            xAxisLabel: xAxisAliasName,
                            yAxisLabel: yAxisAliasName
                        };
                    }
                    const domains = data.widget_type === appConstants.dashboardWigetTypes.verticalBar ? chartData.xAxis : [];
                    return (<DynamicBarChartComponent
                        containerId={`${data.name}_chart_container`}
                        chartData={chartData}
                        color={data?.properties?.color}
                        showXAxis
                        showYAxis
                        type={breakDown.length ? "stacked_bar" : "bar"}
                        xAxisType={data.widget_type === appConstants.dashboardWigetTypes.horizontalBar ? "value" : "category"}
                        yAxisType={data.widget_type === appConstants.dashboardWigetTypes.horizontalBar ? "category" : "value"}
                        showLegends
                        chartProperties={{ ...chartProperties, xRotate: domains.length > 4 ? 45 : 0, barWidth: domains.length > 6 ? 'auto' : 30 }}
                        onCallback={(params) => onChartEvent(params)}
                    />);
                case appConstants.dashboardWigetTypes.line:
                case appConstants.dashboardWigetTypes.area:
                    let lineData = {};
                    if (!breakDown.length) {
                        lineData = {
                            xAxis: xAxisLabel ? report.map((data) => (isFloat(data[xAxisLabel]) ? data[xAxisLabel] : data[xAxisLabel])) : [],
                            yAxis: yAxisLabel ? report.map((data) => (isFloat(data[yAxisLabel]) ? data[yAxisLabel] : data[yAxisLabel])) : [],
                            data: report.map((data) => (isFloat(data[yAxisLabel]) ? data[yAxisLabel] : data[yAxisLabel]))
                        };
                    } else {
                        const breakDownLabel = getAliasName(breakDown[0].alias_name);
                        const axisType = yAxisLabel;
                        const groupByType = xAxisLabel;
                        const uniqueNames = [...new Set(report.map((obj) => obj[groupByType]))];
                        const stackedData = getStackedData(report, uniqueNames, breakDownLabel, groupByType, axisType);
                        lineData = {
                            xAxis: xAxisLabel ? [...new Set(report.map((data) => (isFloat(data[xAxisLabel]) ? data[xAxisLabel] : data[xAxisLabel])))] : [],
                            yAxis: yAxisLabel ? [...new Set(report.map((data) => (isFloat(data[yAxisLabel]) ? data[yAxisLabel] : data[yAxisLabel])))] : [],
                            data: stackedData
                        };
                    }
                    return (
                        <DynamicLineChartComponent
                            chartData={lineData}
                            isStacked={Boolean(breakDown.length > 0)}
                            type={data.widget_type}
                            chartProperties={{ ...chartProperties, xRotate: lineData.xAxis.length > 4 ? 45 : 0 }}
                            onCallback={(params) => onChartEvent(params)}
                        />
                    );
                case appConstants.dashboardWigetTypes.table:
                    return (
                        <TableChartComponent data={report || []} onChangeProperties={onChangeProperties} filterProperties={data?.filter_properties ?? {}} />
                    );
                case appConstants.dashboardWigetTypes.hierachy:
                    const hirerchyData = levels[0]?.hierachy_table === "hierachy_domain" ? prepareHierarchy(report) : report;
                    return (
                        <HierachyComponent data={hirerchyData || []} details={data?.properties} chartProperties={data} type={type} />
                    );
                case appConstants.dashboardWigetTypes.spider:
                    const isScore = Boolean(yAxisLabel === "score" || yAxisLabel === "measure_score");
                    const radarData = {
                        indicator: report.map((obj) => {
                            return {
                                name: obj[xAxisLabel] || "",
                                max: isScore ? 100 : report.reduce((a, b) => a + b[yAxisLabel], 0)
                            };
                        }),
                        data: isScore ? report.map((obj) => (obj[yAxisLabel] ? toRound(obj[yAxisLabel] || 0) : 0)) : report.map((obj) => obj[yAxisLabel] || 0),
                        name: xAxisName || ""
                    };
                    const avgScore = report.reduce((a, b) => a + b[yAxisLabel], 0) / report.length;
                    return (
                        <RadarChartComponent
                            data={radarData}
                            score={avgScore || 0}
                            chartProperties={
                                {
                                    showQuality: isScore
                                }
                            }
                        />
                    );
                case appConstants.dashboardWigetTypes.gauge:
                    return (<GaugeComponent label={xAxisLabel} data={report} onChartEvent={onChartEvent} />);
                case appConstants.dashboardWigetTypes.count:
                    const countData = report.map((data) => {
                        return {
                            date: data[xAxisLabel] || "",
                            count: data[yAxisLabel] || 0
                        };
                    });
                    const countChartData = {
                        id: data.id,
                        color: "#09a7ef",
                        name: yAxisName?.replace("Name", ""),
                        chartData: countData.sort((a, b) => { return new Date(a.date) - new Date(b.date); })
                    };
                    return (<TrendingCountComponent data={countChartData} />);
                case appConstants.dashboardWigetTypes.sun_burst:
                    const distributeData = [];
                    const colors = ["#ff829c", "#c1e2f8", "#ffde92", "#50dde9"];
                    const uniqueDistribute = _.uniqBy(
                        _.flatMap(report, 'level_0_name')
                    ).filter((data) => data);
                    for (const level of uniqueDistribute) {
                        const level0Data = report.filter((data) => data.level_0_name === level);
                        const groupLevel1 = levels.length > 1 ? _.uniqBy(_.flatMap(level0Data, 'level_1_name')).filter((data) => data) : [];
                        const level1Result = [];
                        for (const level1 of groupLevel1) {
                            const level1Data = level0Data.filter((obj) => obj.level_1_name === level1);
                            const groupLevel2 = levels.length > 2 ? _.uniqBy(_.flatMap(level1Data, 'level_2_name')).filter((data) => data) : [];
                            const level2Result = groupLevel2.map((data) => {
                                const info = level1Data.filter((obj) => obj.level_2_name === data);
                                const count = info.reduce((a, b) => a + b.level_2_count, 0);
                                return {
                                    name: data,
                                    value: count,
                                    actualValue: count,
                                    itemStyle: {
                                        color: colors[2]
                                    }
                                };
                            });
                            const level1ActualCount = level1Data.reduce((a, b) => a + b.level_1_count, 0);
                            const level1SumCount = level2Result && level2Result.length ? level2Result.reduce((a, b) => a + b.value, 0) : level1ActualCount;
                            level1Result.push({
                                name: level1,
                                value: level1SumCount,
                                actualValue: level1Data.reduce((a, b) => a + b.level_1_count, 0),
                                children: level2Result || [],
                                itemStyle: {
                                    color: colors[1]
                                }
                            });
                        }
                        let sumCount = 0;
                        let actualValue = 0;
                        if (level1Result && level1Result.length) {
                            sumCount = level1Result.reduce((a, b) => a + b.value, 0);
                        } else if (levels.length > 1) {
                            sumCount = report.filter((report) => report.level_0_name === level && report.level_1_name).length;
                        } else {
                            sumCount = report.filter((report) => report.level_0_name === level).length;
                        }
                        if (levels.length > 1) {
                            actualValue = groupLevel1.length;
                        } else {
                            actualValue = report.filter((report) => report.level_0_name === level).length;
                        }
                        distributeData.push({
                            name: level,
                            value: sumCount || 1,
                            actualValue: actualValue || 0,
                            children: level1Result || [],
                            itemStyle: {
                                color: colors[0]
                            }
                        });
                    }
                    const legends = [];
                    for (const index in levels) {
                        legends.push({ name: levels[index].name.replace("Name", ""), color: colors[index] });
                    }
                    return (<SunBurstChartComponent data={distributeData || []} legends={legends || []} />);
                case appConstants.dashboardWigetTypes.heatmap:
                    return (
                        <MultiDimHeatMap data={data?.report?.default?.result ?? []} headers={data?.report?.default?.headers ?? []} chartProperties={data} chartData={data?.report?.default?.chartData ?? {}} />
                    );
                default:
                    break;
            }
        } else {
            return (
                <Grid className="noResultIcon">
                    {
                        type === "preview" ?
                            <Fragment>
                                <ChartTypeIcon chart_type={data.widget_type} />
                                <Typography variant="body1" className={"textSecondary"}>
                                    Please select the Axis
                                </Typography>
                            </Fragment> :
                            <ChartTypeIcon chart_type={data.widget_type} />
                    }
                </Grid>
            );
        }
    };
    return (
        <Grid style={{ width: '100%', height: '100%' }} id={`${data.name}_chart_container`} >
            {renderWidget()}
        </Grid>
    );
}

// default props
CustomWidgetComponents.defaultProps = {
    data: {},
    type: "preview",
    onChangeProperties: () => { }
};

// prop types
CustomWidgetComponents.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    onChangeProperties: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default (React.memo(CustomWidgetComponents, areEqual));