// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

// Import Styles
import style from "./style.jsx";

const DonutChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data } = props;


    const sumValue = data.reduce((prev, current) => prev + current.value, 0);


    const option = {
        series: [
            {
                type: 'pie',
                radius: ['65%', '90%'],
                avoidLabelOverlap: true,
                label: {
                    color: '#000',
                    fontSize: 15,
                    position: 'center',
                    formatter: () => {
                        return sumValue;
                    }
                },
                labelLine: {
                    show: false
                },
                data: data
            }
        ]
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={{ height: 100 }}
        />
    );
};


/**
 * Define Prop Types
 */
DonutChartComponent.propTypes = {
    data: PropTypes.array
};

/**
 * Set Default Values
 */
DonutChartComponent.defaultProps = {
    data: []
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(DonutChartComponent, areEqual));