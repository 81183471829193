import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../components/index';
import { useDispatch, useSelector } from 'react-redux';
import { getInvalidRowsRequest, sortInvalidRows } from '../../../../../redux/reducer/attributeReducer';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';


function InvalidRowsPreview(props) {

    /**
     * Define Props
     */
    const { classes, assetId, attributeId, open, onClose } = props;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const defaultLimit = 20;
    const tableOptions = [...appConstants.tableOptions.download];

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [limit, setLimit] = useState(defaultLimit);

    /**
     * State variables
     */
    const { invalidRows, isLoading, invalidRowsConfig } = useSelector((state) => state.attribute);

    /**
     * Reset page data
     */
    const resetData = () => {
        setLimit(defaultLimit);
        setSearchData({});
    };

    /**
     * Use effect to load failed rows for the given measure
     */
    useEffect(() => {
        if (attributeId) {
            dispatch(getInvalidRowsRequest({ assetId, attributeId }));
        }
        return () => {
            resetData();
        };
    }, [attributeId]);


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy
        };
        dispatch(sortInvalidRows(requestData));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        setLimit(defaultLimit);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScroll = () => {
        if (tableRef.current) {
            const scrollHeight = tableRef.current.scrollHeight - Math.floor(tableRef.current.scrollTop);
            const clientHeight = tableRef.current.clientHeight;
            if ((scrollHeight >= clientHeight && scrollHeight <= clientHeight + 2) && invalidRows?.length > defaultLimit) {
                setLimit(limit + defaultLimit);
            }
        }
    };

    /**
     * Handle Lazy Load
     */
    useEffect(() => {
        if (tableRef && tableRef.current) {
            const element = tableRef.current;
            element.addEventListener("scroll", onScroll);
            return () => element.removeEventListener("scroll", onScroll);
        }
    }, [tableRef, onScroll]);

    /**
     * Prepare Result Data
     * @param {*} data
     * @returns
     */
    const prepareFilterResult = (data, searchFilters, invalidRowsConfig) => {
        const { sortBy, orderBy } = invalidRowsConfig;
        const objectHeaders = data?.length > 0 ? Object.keys(data[0]) : [];
        const headers = [];
        for (const header of objectHeaders) {
            headers.push({
                key: header,
                name: header,
                sorting: true,
                tooltip: true,
                isSearch: true,
                searchComponent: "text",
                searchKey: header
            });
        }
        let filterData = JSON.parse(JSON.stringify([...data]));
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (!value?.toString().toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return { data: filterData, headers };
    };

    /**
     * Prepare Data using UseMemo
     */
    const resultData = useMemo(() => prepareFilterResult(invalidRows, searchData, invalidRowsConfig), [invalidRows, searchData, invalidRowsConfig]);
    /**
     * Load Data
     * @param {*} data
     * @param {*} config
     * @returns
     */
    const prepareLoadData = (data, limit) => {
        return data.slice(0, limit);
    };

    const filterData = useMemo(() => prepareLoadData(resultData.data, limit), [resultData.data, limit]);
    tableOptions.push({ type: 'close', customFunction: onClose });


    return (
        <Dialog open={open} onClose={onClose} className={classes.invalidRowsContainerDialog}>
            <Grid container className={classes.invalidRowsContainer}>
                <Grid item xs={12}>
                    <TableComponent
                        styleType="striped"
                        headers={resultData?.headers || []}
                        data={filterData || []}
                        options={tableOptions}
                        sortBy={invalidRowsConfig?.sortBy}
                        orderBy={invalidRowsConfig?.orderBy}
                        onClickSorting={onClickSorting}
                        onHandleSearchEvent={onHandleSearchEvent}
                        searchData={{ ...searchData }}
                        stickyHeader
                        title={"Invalid Record Preview"}
                        countText={filterData?.length > 0 ? `${filterData?.length} of ${resultData?.data?.length} records` : ""}
                        NoResultText="No Results Found"
                        height="calc(100vh - 300px)"
                        isLoading={isLoading}
                        exportParams={
                            {
                                fileName: "failed_rows.csv",
                                titleCaseHeader: true,
                                data: invalidRows
                            }
                        }
                        tableRef={tableRef}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
}

// default props
InvalidRowsPreview.defaultProps = {
    classes: {},
    open: false,
    assetId: "",
    attributeId: "",
    onClose: () => { }
};

// prop types
InvalidRowsPreview.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    assetId: PropTypes.string,
    attributeId: PropTypes.string,
    onClose: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(InvalidRowsPreview);