const style = (theme) => ({
    headerContainer: {
        background: '#FAFCFF',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 3,
        padding: '24px 30px 24px',
        '& .passedTitle': {
            background: '#B7F1D6',
            width: '40px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 0
        },
        '& .failedTile': {
            background: '#F7CBCC',
            width: '40px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 0
        }
    },
    countChips: {
        display: 'flex',
        '& .MuiChip-label': {
            paddingLeft: '7px',
            paddingRight: '7px'
        },
        '& .copyIcon': {
            cursor: 'pointer',
            marginRight: "15px",
            display: "flex",
            alignItems: "center",
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        }
    },
    acctordionTile: {
        boxShadow: 'none !important',
        marginBottom: '20px !important',
        '& .MuiAccordionSummary-root': {
            boxShadow: '0px 0px 4px rgb(237 237 237 / 90%) !important',
            padding: '0px 20px !important',
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            borderRadius: 4
        },
        '& .MuiAccordionDetails-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            padding: '30px',
            background: '#FCFDFD'
        },
        '& .ChevDownIcon': {
            width: '18px',
            height: '10px'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0px !important'
        }
    },
    dimensionChip: {
        paddingRight: '20px'
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            maxWidth: 130,
            height: '2rem !important',
            fontSize: '13px',
            background: '#fff'
        }
    },
    daysFilter: {
        background: '#fff',
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    },
    failedText: {
        // border: '1px solid #F38080',
        borderRadius: '4px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        '& .failedTextDesc': {
            marginTop: '-2px !important'
        },
        '& .MuiButton-root': {
            textTransform: 'capitalize',
            fontWeight: 400
        },
        '& .whitBg': {
            background: '#fff !important',
            color: '#222'
        },
        '& .DangerIcon': {
            // fill: '#F38080',
            marginRight: 10
        },
        '&.high': {
            background: `${theme.palette.colorThemes.high}10`,
            border: `1px solid ${theme.palette.colorThemes.high}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.high
            }
        },
        '&.medium': {
            background: `${theme.palette.colorThemes.medium}10`,
            border: `1px solid ${theme.palette.colorThemes.medium}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.medium
            }
        },
        '&.low': {
            background: `${theme.palette.colorThemes.low}10`,
            border: `1px solid ${theme.palette.colorThemes.low}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.low
            }
        },
        '&.ok': {
            background: `${theme.palette.colorThemes.ok}10`,
            border: `1px solid ${theme.palette.colorThemes.ok}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.ok
            }
        }
    },
    accordionChart: {
        width: '90%',
        margin: 'auto'
    },
    editIcon: {
        '& svg.MuiSvgIcon-root': {
            fontSize: '1.4rem',
            fill: theme.palette.greyshades.darkgrey
        }
    },
    saveCancel: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '-2px !important'
    },
    manualAuto: {
        background: theme.palette.greyshades.darkgrey,
        color: '#fff',
        padding: '3px 10px',
        borderRadius: '30px',
        fontSize: '13px',
        marginRight: '20px !important',
        height: '26px',
        lineHeight: '20px',
        fontWeight: '400',
        '&.active': {
            background: `${theme.palette.secondary.main} !important`
        }
    },
    issuesIcon: {
        '& .RateReviewIcon': {
            width: 23,
            height: 23
        }
    },
    rangeFieldInput: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        background: '#fff',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        },
        '& .Mui-focused fieldset': {
            borderColor: 'transparent !important',
            borderWidth: '0px !important'
        }
    },
    chartContainer: {
        padding: '30px',
        background: '#FCFDFD',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textAlign: 'right'
    },
    actions: {
        '& .CloseIcon': {
            width: '24px',
            height: '24px'
        },
        '& .EditIcon': {
            width: '18px',
            height: '18px',
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        }
    },
    listContainer: {
        marginTop: '20px !important',
        "& .btnTabsInline": {
            width: '100%'
        }
    },
    tabsBody: {
        '& .MuiBox-root': {
            padding: '0px 0px 24px !important'
        }
    },
    qualityContainer: {
        padding: 30,
        paddingTop: '0px !important',
        background: '#fff',
        height: '100vh',
        overflowY: 'auto',
        width: '100%',
        zIndex: 11
    },
    dialogHeader: {
        position: 'sticky',
        top: 0,
        paddingBottom: 16,
        background: '#fff',
        zIndex: 99,
        paddingTop: 30,
        '& .EditIcon': {
            '&:hover path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .NoNotificationIcon, .CloseIcon': {
            '&:hover': {
                fill: theme.palette.secondary.main
            }
        },
        '& .NotificationIcon': {
            '&:hover #Path_1191': {
                fill: theme.palette.secondary.main
            }
        },
        '& svg.DeleteIcon2:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    behaviourContainer: {
        padding: 30,
        position: 'relative',
        '& .closeContainer': {
            position: 'absolute',
            right: 5,
            top: 5
        }
    },
    behaviouralTable: {
        marginTop: 5,
        '& .MuiPaper-root': {
            maxHeight: 'calc(100vh - 340px)',
            overflowY: 'auto'
        }
    },
    passFailContainer: {
    }
});
export default style;