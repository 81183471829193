const style = () => ({
    licensePageContainer: {
        width: '100%',
        height: '100vh',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    licenseContainer: {
        width: 600,
        height: 400,
        padding: 15,
        background: '#fff',
        display: 'flex',
        textAlign: 'center',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important'

    },
    logo: {
        width: 90
    },
    btnContainer: {
        display: "flex",
        flexWrap: "nowrap",
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        '& button': {
            marginLeft: 10,
            '&:first-child': {
                marginLeft: 0
            }
        }
    }
});
export default style;