import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, Grid, Tooltip, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, FormControlLabel, Switch, ListItemButton, Badge } from '@mui/material';
import PropTypes from 'prop-types';

// Import Components
import { LoaderComponent, NoResultComponent, PopOverComponent } from '../../index.js';
import CreateEditIssueComponent from '../../../containers/issues/createEdit/index.jsx';

// Import Images
import { NotificationIcon } from '../../../assets/svg';

// Import Helpers
import { stringAvatar } from '../../../helpers/appHelpers.js';

// Import Reducers
import { getNotificationLogsRequest, updateNotificationLogsRequest } from '../../../redux/reducer/logsReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer.js';

const Notification = (props) => {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [issueParams, setIssueParams] = useState(null);

    const { notificationStats, notificationLogs: { list: notificationLogsList, filter: notificationFilter, isLoadMore, isLoading } } = useSelector((state) => state.logs);

    /**
     * Handle Popup
     * @param {*} event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (!notificationLogsList || !notificationLogsList.length) {
            const requestData = {
                is_viewed: notificationFilter.is_viewed,
                limit: notificationFilter.limit,
                offset: notificationFilter.offset
            };
            dispatch(getNotificationLogsRequest(requestData));
        }
    };

    /**
     * Close Popup
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Set Variables
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Handle Navigations
     * @param {*} notification
     * @returns
     */
    const navigateActions = (notification) => {
        const requestParams = {
            id: notification.id,
            is_viewed: notificationFilter.is_viewed
        };
        dispatch(updateNotificationLogsRequest(requestParams));
        setAnchorEl(null);
        if (notification.action === "delete") {
            return false;
        }
        switch (notification.level) {
            case "Asset":
                dispatch(navigate({ path: 'assets.root', state: {}, params: [notification.asset_details.asset_id] }));
                break;
            case "Conversation":
                dispatch(navigate({ path: 'assets.conversations', state: {}, params: [notification.asset_details.asset_id] }));
                break;
            case "Issue":
                setIssueParams({
                    id: notification.issues
                });
                break;
            case "Alerts":
                const state = {
                    measure_id: notification?.measure ?? null,
                    selectedAlert: notification?.alerts ?? null,
                    name: notification?.alert_details?.measure_name ?? '',
                    measureName: notification?.alert_details?.measure_name ?? '',
                    showEdit: false,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [notification?.measure ?? null] }));
                break;
            default:
                break;
        }
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if (!isLoading && isLoadMore && event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            const requestData = {
                is_viewed: notificationFilter.is_viewed,
                limit: notificationFilter.limit,
                offset: notificationFilter.offset + notificationFilter.limit
            };
            dispatch(getNotificationLogsRequest(requestData));
        }
    };

    /**
     * Handle View Change
     * @param {*} viewed
     */
    const handleChange = (viewed) => {
        const requestData = {
            is_viewed: viewed,
            limit: notificationFilter.limit,
            offset: 0,
            clearList: true
        };
        dispatch(getNotificationLogsRequest(requestData));
    };

    return (
        <Grid>
            <Tooltip title="Notification" arrow>
                <IconButton className="p5 mr5" onClick={handleClick}>
                    <Badge badgeContent={notificationStats || 0} color="primary">
                        <NotificationIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <PopOverComponent
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={`${classes.popoverContainer} popoverContainer`}
                onScroll={(event) => onScrollEvent(event)}
            >
                <Grid className={classes.notificationContainer} sx={{ maxHeight: 800 }}>
                    <Grid container justifyContent={"space-between"} alignItems="center" className={classes.notificationHeader}>
                        <Typography variant="h2" className={classes.notifyTitle}>
                            Notifications
                        </Typography>
                        <FormControlLabel className={classes.switch} labelPlacement="start" control={<Switch size="small" checked={notificationFilter.is_viewed} onClick={() => handleChange(!notificationFilter.is_viewed)} />} label="Only show unread" />
                    </Grid>
                    <Grid item xs={12} className={classes.notificationBody} onScroll={(event) => onScrollEvent(event)}>
                        {!notificationLogsList.length > 0 && !isLoading ? <NoResultComponent title={"No notifications yet!"} /> : ''}
                        {
                            notificationLogsList.length > 0 &&
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {
                                    notificationLogsList?.map((notification, index) =>
                                        <ListItem className={`${!notification.is_viewed ? 'notReadedd' : ''}`} key={index} onClick={() => navigateActions(notification)}>
                                            <ListItemButton>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        {...stringAvatar(notification.created_by.name, notification?.created_by?.avatar)} />
                                                    {!notification.is_viewed && <Grid className="activeDot" />}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ marginTop: '-5px' }}
                                                    primary={
                                                        <Typography variant="h6" className="w-100">
                                                            <span className="title">
                                                                {notification.title}
                                                            </span>
                                                            <span className={`${classes.textSecondary} time textSecondary pl5`}>
                                                                {(moment(notification.created_date).fromNow())}
                                                            </span>
                                                            <span className="description">
                                                                {notification.description}
                                                            </span>
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                            </List>
                        }
                        {isLoading && <LoaderComponent height={200} />}
                    </Grid>
                </Grid>
            </PopOverComponent>
            {
                issueParams &&
                <CreateEditIssueComponent
                    issueParams={issueParams}
                    open={Boolean(issueParams)}
                    onClose={() => setIssueParams(null)}
                />
            }
        </Grid>
    );
};

// default props
Notification.defaultProps = {
    classes: {}
};

// prop types
Notification.propTypes = {
    classes: PropTypes.object
};

export default Notification;