import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Button, Grid, IconButton, Slide, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TemplateCard from '../../component/templateCard/index.jsx';
import { TextBoxComponent } from '../../../../../../components/index.js';

//  Import Styles
import Style from '../../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import { CloseIcon } from '../../../../../../assets/svg/index.js';

// Import Constant
import appConstants from '../../../../../../constants/appConstants';

// Import Action
import { createDashboardRequest } from '../../../../../../redux/reducer/dashboardReducer';


function CreateDashboard(props) {

    const { classes, closeDrawer } = props;
    const dispatch = useDispatch();
    const dashboards = useSelector(({ dashboard }) => dashboard.list);


    /**
     * Define State
     */
    const [isAddDashboard, openNewDashboard] = useState(false);
    const [dashboard, setDashboard] = useState({
        name: ""
    });

    /**
     * Reset Form
     */
    const resetForm = () => {
        setDashboard({ name: "" });
    };

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setDashboard({
            ...dashboard,
            [property]: value
        });
    };

    /**
     * Handle New Dashboard
     * @param {*} type
     */
    const handleNewDashboard = (type = "dashboard") => {
        if (type === "new") {
            resetForm();
        }
        openNewDashboard(!isAddDashboard);
    };

    /**
     * Select Dashboard
     * @param {*} dashboardId
     */
    const onSelectDashboard = (dashboardId) => {
        onChange("dashboard", dashboardId);
        handleNewDashboard();
    };


    /**
     * Create Dashboard
     */
    const createDashboard = () => {
        dispatch(createDashboardRequest(dashboard));
        closeDrawer();
    };

    return (
        <Grid item xs={12} className={classes.dashboardOption}>
            {
                !isAddDashboard ?
                    <Fragment>
                        <Grid item xs={12} className={classes.top}>
                            <Grid item xs={12} className={classes.header}>
                                <Grid className="dflex alignCenter spaceBetween pb5">
                                    <Typography variant="h2" className="">
                                        Create a Dashboard
                                    </Typography>
                                    <IconButton onClick={closeDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Typography variant="body1">
                                    {appConstants.labels.dashboard.create}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.body}>
                                <Typography variant="h5" className="pb-15">
                                    Create a template from below
                                </Typography>
                                <Grid className={classes.cardContainer}>
                                    {
                                        dashboards.map((item, index) =>
                                            <TemplateCard key={`dashboard_${index}`} data={item} onSelect={(dashboardId) => onSelectDashboard(dashboardId)} />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.footer}>
                            <Typography variant="body1">
                                or
                            </Typography>
                            <Button variant="outlined" color="primary" size="small" className="ml-2" onClick={() => handleNewDashboard("new")}>
                                Create from Blank
                            </Button>
                        </Grid>
                    </Fragment> :
                    <Slide direction="left" in={isAddDashboard}>
                        <Grid item xs={12}>
                            <Grid item xs={12} className={classes.top}>
                                <Grid item xs={12} className={`${classes.header} pb-1`}>
                                    <Grid className="dflex alignCenter spaceBetween pb5">
                                        <Typography variant="h2" className="">
                                            Create a Dashboard
                                        </Typography>
                                        <IconButton onClick={closeDrawer}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.body}>
                                    <Grid className={classes.formContainer}>
                                        <ValidatorForm onSubmit={() => createDashboard()} noValidate>
                                            <TextBoxComponent
                                                label="Dasboard Name"
                                                variant="standard"
                                                className="mt-1"
                                                value={dashboard.name || ""}
                                                onChange={(event) => onChange("name", event.target.value)}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.nameRequired]}
                                                valOnChange
                                            />
                                            <Grid item xs={12} className={`dflex alignCenter spaceBetween pt-4`}>
                                                <Button variant="outlined" color="primary" size="small" onClick={handleNewDashboard}>
                                                    Back
                                                </Button>
                                                <Button variant="contained" type="submit" color="primary" size="small" disableElevation>
                                                    Create Dashboard
                                                </Button>
                                            </Grid>
                                        </ValidatorForm>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Slide>
            }
        </Grid>
    );
}

// default props
CreateDashboard.defaultProps = {
    classes: {}
};

// prop types
CreateDashboard.propTypes = {
    classes: PropTypes.object,
    closeDrawer: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CreateDashboard);