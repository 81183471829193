import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Button } from '@mui/material';

// Import Style
import style from './style.jsx';

// Import Reducer
import { navigate } from '../../redux/reducer/navigationReducer';

function NotFound(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Navigate to Home Page
     */
    const navigateHome = () => {
        dispatch(navigate({ path: 'home.root', state: {}, params: [] }));
    };

    return (
        <Grid className={classes.pageContainer}>
            <Grid item align="center">
                <svg xmlns="http://www.w3.org/2000/svg" width="360.856" height="383.918" viewBox="0 0 360.856 383.918">
                    <g id="Group_169252" data-name="Group 169252" transform="translate(-503 -97.015)">
                        <g id="Group_169136" data-name="Group 169136" transform="translate(465.727 94.084)">
                            <path id="Path_5727" data-name="Path 5727" d="M230.963,50.352c-17.917-.169-126.423-1.848-129.613,28.39-3.057,28.962,38.988,18.359,39.4,31.209.436,13.5-7.583,17.406-29.961,16.55-9.7-.371-40.308,3.058-40.8,22.38-.489,19.214,33.692,16.789,32,32.731-.981,18.119-26.94,22.06-37.167,26.85-11.111,5.812-31.559,15.394-26.861,35.962,6.184,23.851,45.451,19.562,60.242,22.5,9.878,1.6,41.287,4.373,36.821,25.509-2.348,12.7-28.131,13.443-23.2,29.741,4.988,15.236,49.95,18.247,59.059,18.74,37.808,2.045,120.831,5.39,157.808-10.208,13.6-5.677,25.6-12.513,24.2-32.9-.675-18.208-10.5-20.876,6.043-34.946,10.456-9.056,30.473-8.327,29.29-34.76-.64-14.26-37.113-20.181-36.3-35.1,1.269-23.448,46.044-3.537,46.207-45.014.1-25.889-81.356-11.405-80.525-37.014-1.1-10.393,16.832-14.8,16.062-26.306C334.086,66.247,312.988,52.617,230.963,50.352Z" transform="translate(0 -7.19)" fill="#e5eaf1" />
                            <path id="Path_5728" data-name="Path 5728" d="M170.484,268.054a.6.6,0,0,1-.326.087H107.794a.641.641,0,0,1-.617-.469,3.771,3.771,0,0,1,5.065-4.158,3.085,3.085,0,0,1,3.379-2.355,5.457,5.457,0,0,1,3.172-6.765,5.261,5.261,0,0,1,6.6,1.764,11.022,11.022,0,0,1,9.677-8.893,10.538,10.538,0,0,1,7.437,2.279,9.516,9.516,0,0,1,3.208,5.874,5.838,5.838,0,0,1,6.825-2.342c2.8.869,3.153,3.728,3.024,6.3,2.256-1.873,4.969-2.782,7.913-1.012,2.444,1.471,2.974,3.834,3.075,6.4,2.008-1.2,4.088-.009,4.248,2.738A.538.538,0,0,1,170.484,268.054Z" transform="translate(-10.6 -37.094)" fill="#9faec3" />
                            <path id="Path_5729" data-name="Path 5729" d="M386.944,117.232c0,11.955,32.434,14.16,34.118,0S386.944,99.284,386.944,117.232Z" transform="translate(-53.092 -15.52)" fill="#9faec3" />
                            <path id="Path_5730" data-name="Path 5730" d="M78.208,333.6c0,11.956,40.137,14.268,41.592,0C121.593,316.011,78.208,312.665,78.208,333.6Z" transform="translate(-6.215 -48.009)" fill="#9faec3" />
                            <path id="Path_5731" data-name="Path 5731" d="M354.009,412.63c0,14.179,47.6,16.92,49.325,0C405.461,391.77,354.009,387.8,354.009,412.63Z" transform="translate(-48.091 -59.6)" fill="#9faec3" />
                            <path id="Path_5732" data-name="Path 5732" d="M74.208,118.7c0,7.806,22.124,9.315,22.926,0C98.124,107.216,74.208,105.031,74.208,118.7Z" transform="translate(-5.608 -16.143)" fill="#9faec3" />
                            <path id="Path_5733" data-name="Path 5733" d="M210.733,280.116c.66-3.32-4.25-6.371-8.034-5.147.617-2.276-.086-4.341-3.439-5.36a8.545,8.545,0,0,0-4.552-.02c2.645-5.274.183-11.279-7.992-12.52-8.89-1.35-18.756,3.012-20.52,10.032-7.229-7.1-22.547,1.408-18.025,9.583-2.793-.444-5.671,1.137-5.84,3.488a2.842,2.842,0,0,0-3.312,4.4.807.807,0,0,0,.648.3h75.45a.835.835,0,0,0,.459-.123.573.573,0,0,0,.265-.314C216.7,281.987,213.534,279.666,210.733,280.116Z" transform="translate(-15.355 -38.55)" fill="#fff" />
                            <path id="Path_5734" data-name="Path 5734" d="M218.875,348.8c.293-1.469-1.88-2.818-3.552-2.276.274-1.008-.038-1.921-1.521-2.371a3.8,3.8,0,0,0-2.013-.009c1.169-2.332.08-4.986-3.535-5.535-3.931-.6-8.293,1.332-9.075,4.436-3.2-3.142-9.969.623-7.969,4.237-1.236-.2-2.51.5-2.584,1.542a1.256,1.256,0,0,0-1.463,1.947.355.355,0,0,0,.286.131h33.364a.371.371,0,0,0,.2-.053.25.25,0,0,0,.115-.14C221.513,349.624,220.115,348.6,218.875,348.8Z" transform="translate(-22.717 -50.95)" fill="#fff" />
                            <path id="Path_5735" data-name="Path 5735" d="M383.36,187.476a.458.458,0,0,0,.243.064h46.391a.477.477,0,0,0,.459-.349,2.805,2.805,0,0,0-3.768-3.093,2.294,2.294,0,0,0-2.514-1.751,4.061,4.061,0,0,0-2.36-5.033,3.915,3.915,0,0,0-4.912,1.313,8.2,8.2,0,0,0-7.2-6.615,7.84,7.84,0,0,0-5.533,1.694,7.084,7.084,0,0,0-2.386,4.371,4.343,4.343,0,0,0-5.076-1.742c-2.082.646-2.346,2.774-2.25,4.684-1.678-1.393-3.7-2.07-5.885-.752-1.818,1.093-2.212,2.851-2.287,4.761-1.495-.889-3.041-.007-3.16,2.036A.4.4,0,0,0,383.36,187.476Z" transform="translate(-52.512 -25.669)" fill="#fff" />
                            <path id="Path_5736" data-name="Path 5736" d="M240.8,90.157a.315.315,0,0,0,.165.044h31.351a.32.32,0,0,0,.31-.237,1.894,1.894,0,0,0-2.545-2.089,1.551,1.551,0,0,0-1.7-1.184,2.742,2.742,0,0,0-1.594-3.4,2.644,2.644,0,0,0-3.319.887,5.541,5.541,0,0,0-4.866-4.471,5.3,5.3,0,0,0-3.738,1.145,4.8,4.8,0,0,0-1.612,2.954,2.936,2.936,0,0,0-3.431-1.177c-1.409.436-1.586,1.874-1.52,3.165a3.228,3.228,0,0,0-3.979-.508,3.505,3.505,0,0,0-1.545,3.216c-1.01-.6-2.053,0-2.136,1.377A.268.268,0,0,0,240.8,90.157Z" transform="translate(-30.879 -11.655)" fill="#fff" />
                            <g id="Group_169135" data-name="Group 169135" transform="translate(138.479 2.93)">
                                <path id="Path_5749" data-name="Path 5749" d="M193.392,107.455a.259.259,0,0,0-.041-.131.235.235,0,0,0-.223-.134l-10.26.166a.266.266,0,0,0-.264.264,48.89,48.89,0,0,0,.242,5.974c.3,1.826,2.307,1.965,3.853,2.055,1.983.115,4.814.186,5.954-.865C193.973,113.568,193.461,109.391,193.392,107.455Z" transform="translate(-164.791 -45.349)" fill="#9faec3" />
                                <g id="Group_169128" data-name="Group 169128" transform="translate(17.682 62.882)">
                                    <path id="Path_5750" data-name="Path 5750" d="M184.365,111.5a1.969,1.969,0,0,1-1.911-1.478.157.157,0,1,1,.306-.071,1.662,1.662,0,0,0,2.059,1.188c1.121-.2,1.206-2.548,1.206-2.572a.158.158,0,0,1,.139-.153.16.16,0,0,1,.172.113c.028.1.059.236.093.388a3.723,3.723,0,0,0,.735,1.787,2.6,2.6,0,0,0,2.3.232,1.983,1.983,0,0,0,.914-1.932c-.016-.116-.025-.235-.033-.355a.159.159,0,0,1,.316-.03,3.013,3.013,0,0,1,.031.354,2.116,2.116,0,0,0,1.28,1.769,1.238,1.238,0,0,0,1.391-.61.158.158,0,1,1,.258.184,1.553,1.553,0,0,1-1.739.729,2.12,2.12,0,0,1-1.268-1.221,1.889,1.889,0,0,1-1.028,1.4,2.881,2.881,0,0,1-2.631-.288,3.018,3.018,0,0,1-.719-1.471c-.15.785-.506,1.826-1.361,1.982A2.832,2.832,0,0,1,184.365,111.5Z" transform="translate(-182.45 -108.417)" fill="#c5cedb" />
                                </g>
                                <path id="Path_5751" data-name="Path 5751" d="M187.751,106.369c-1.087,0-4.777.048-5.182.88-.542,1.17,5.464,1.077,6.623,1.039C194.689,108.109,195.343,106.092,187.751,106.369Z" transform="translate(-164.78 -45.22)" fill="#909db1" />
                                <g id="Group_169129" data-name="Group 169129" transform="translate(17.523 60.905)">
                                    <path id="Path_5752" data-name="Path 5752" d="M187.71,106.12c-.629,0-5.9-.091-5.417,1.335.236.7,1.35.758,1.941.841a27.573,27.573,0,0,0,3.951.183,20.2,20.2,0,0,0,3.475-.273c.515-.092,1.627-.269,1.855-.865.586-1.543-5.388-1.234-5.8-1.221-.271.008-.273.429,0,.422a28.375,28.375,0,0,1,3.593.075c.21.022,2.3.3,1.617.749-1.265.825-3.285.678-4.734.692a18.633,18.633,0,0,1-4.914-.376c-.759-.2-.245-.643.258-.789a8.922,8.922,0,0,1,1.64-.253c.843-.081,1.695-.1,2.542-.1A.211.211,0,0,0,187.71,106.12Z" transform="translate(-182.262 -106.086)" fill="#c5cedb" />
                                </g>
                                <g id="Group_169133" data-name="Group 169133" transform="translate(17.005 49.287)">
                                    <g id="Group_169130" data-name="Group 169130" transform="translate(11.395 0.071)">
                                        <path id="Path_5753" data-name="Path 5753" d="M195.36,92.625c-.07,4.154-.218,8.311-.274,12.465a.157.157,0,0,0,.315,0c.059-4.153.205-8.31.277-12.465a.159.159,0,0,0-.317,0Z" transform="translate(-195.086 -92.472)" fill="#9faec3" />
                                    </g>
                                    <g id="Group_169131" data-name="Group 169131">
                                        <path id="Path_5754" data-name="Path 5754" d="M181.651,92.541c.128,4.207.184,8.4.625,12.59.021.2.337.2.316,0-.44-4.19-.494-8.383-.623-12.59-.007-.2-.323-.2-.317,0Z" transform="translate(-181.651 -92.389)" fill="#9faec3" />
                                    </g>
                                    <g id="Group_169132" data-name="Group 169132" transform="translate(5.695 0.704)">
                                        <path id="Path_5755" data-name="Path 5755" d="M188.366,93.371c.352,4.241-.091,8.5.28,12.736.019.2.333.2.317,0-.371-4.239.073-8.5-.281-12.736-.018-.2-.332-.2-.316,0Z" transform="translate(-188.365 -93.218)" fill="#9faec3" />
                                    </g>
                                </g>
                                <path id="Path_5756" data-name="Path 5756" d="M211.323,48.642c-1.325-3.362-3.768-6.712-7.6-7.4a.671.671,0,0,0-.082-.006.5.5,0,0,0-.062-.013c-2.855-.29-4.517,2.556-5.522,4.737-1.809,3.94-2.606,8.35-3.317,12.586-1.394,8.329-2.836,17.456-.394,25.714a.553.553,0,0,0,.994.126c2.613-4.015,8-5.391,11.449-8.6,3.6-3.348,5.432-8.242,6.018-12.977A28.546,28.546,0,0,0,211.323,48.642Z" transform="translate(-166.374 -35.33)" fill="#9faec3" />
                                <path id="Path_5757" data-name="Path 5757" d="M178.116,62.273A65.144,65.144,0,0,0,170.4,42.5a.48.48,0,0,0-.5-.255.539.539,0,0,0-.243.064c-3.473,1.786-5.34,5.209-6.51,8.742a28.334,28.334,0,0,0-1.328,12.383A22.71,22.71,0,0,0,169.3,76.613c3.131,2.88,7.029,4.745,9.825,8.037a.551.551,0,0,0,.93-.377A128.711,128.711,0,0,0,178.116,62.273Z" transform="translate(-161.602 -35.487)" fill="#c5cedb" />
                                <path id="Path_5758" data-name="Path 5758" d="M198.15,36.273a14.888,14.888,0,0,0-10.044-1.288.454.454,0,0,0-.374.315c-2.05,2.925-2.349,7.015-2.651,10.435a123.087,123.087,0,0,0-.105,16.2c.321,7.263.932,14.538,1.858,21.752a.55.55,0,0,0,.544.533h6.242a.544.544,0,0,0,.469-.264c5.762-10.583,7.376-23.312,8.341-35.112.221-2.689.4-5.39.442-8.087C202.9,38.978,199.448,36.981,198.15,36.273Z" transform="translate(-165.119 -34.335)" fill="#c5cedb" />
                                <path id="Path_5759" data-name="Path 5759" d="M187.33,37.016c-.977-5.631-10.922-1.137-13.553.787a7.082,7.082,0,0,0-3.248,6.183,73.762,73.762,0,0,0,.559,13.583,65.883,65.883,0,0,0,4.869,18.052q1.146,2.618,2.518,5.124c.414.757,1.332,3.161,2.226,3.4,1.321.356,3.132.02,4.5.02a.5.5,0,0,0,.5-.358.54.54,0,0,0,.046-.176c.858-10.27,1.537-20.567,1.891-30.866C187.814,47.6,188.219,42.143,187.33,37.016Z" transform="translate(-162.942 -34.279)" fill="#9faec3" />
                                <g id="Group_169134" data-name="Group 169134" transform="translate(16.208 48.563)">
                                    <path id="Path_5760" data-name="Path 5760" d="M181.368,93.1a35.023,35.023,0,0,0,12.3,0c1.152-.2.663-1.74-.483-1.542a32.508,32.508,0,0,1-11.334,0c-1.144-.213-1.632,1.329-.483,1.542Z" transform="translate(-180.712 -91.535)" fill="#909db1" />
                                </g>
                            </g>
                            <g id="Group_169138" data-name="Group 169138" transform="translate(217.701 222.172)">
                                <path id="Path_5749-2" data-name="Path 5749" d="M207.852,107.809a.606.606,0,0,0-.1-.306.55.55,0,0,0-.522-.314q-12.006.2-24.013.389a.623.623,0,0,0-.617.617c.2,4.627-.2,9.393.566,13.981.713,4.274,5.4,4.6,9.018,4.81,4.641.268,11.267.435,13.935-2.025C209.212,122.118,208.015,112.341,207.852,107.809Z" transform="translate(-140.914 37.544)" fill="#9faec3" />
                                <g id="Group_169128-2" data-name="Group 169128" transform="translate(41.384 147.17)">
                                    <path id="Path_5750-2" data-name="Path 5750" d="M186.932,115.64a4.607,4.607,0,0,1-4.472-3.458.368.368,0,1,1,.717-.167,3.889,3.889,0,0,0,4.818,2.781c2.624-.474,2.823-5.963,2.823-6.019a.369.369,0,0,1,.326-.357.374.374,0,0,1,.4.264c.066.236.139.552.218.907.3,1.33.762,3.341,1.721,4.183,1.2,1.05,3.966,1.175,5.383.544,1.477-.578,2.2-2.646,2.14-4.522-.038-.272-.06-.55-.077-.83a.373.373,0,0,1,.74-.069,7.04,7.04,0,0,1,.073.828c.312,2.013,1.493,3.692,3,4.141A2.9,2.9,0,0,0,208,112.439a.371.371,0,1,1,.6.431,3.635,3.635,0,0,1-4.069,1.707,4.963,4.963,0,0,1-2.968-2.856A4.422,4.422,0,0,1,199.156,115c-1.644.729-4.7.6-6.158-.673a7.064,7.064,0,0,1-1.683-3.442c-.351,1.838-1.185,4.274-3.186,4.639A6.629,6.629,0,0,1,186.932,115.64Z" transform="translate(-182.45 -108.417)" fill="#c5cedb" />
                                </g>
                                <path id="Path_5751-2" data-name="Path 5751" d="M194.744,106.4c-2.545,0-11.18.113-12.129,2.061-1.268,2.737,12.788,2.521,15.5,2.432C210.982,110.476,212.512,105.755,194.744,106.4Z" transform="translate(-140.983 36.71)" fill="#909db1" />
                                <g id="Group_169129-2" data-name="Group 169129" transform="translate(41.01 142.542)">
                                    <path id="Path_5752-2" data-name="Path 5752" d="M195.012,106.165c-1.473,0-13.8-.212-12.679,3.124.552,1.63,3.16,1.775,4.544,1.967a64.537,64.537,0,0,0,9.246.429,47.288,47.288,0,0,0,8.133-.639c1.2-.214,3.807-.629,4.341-2.025,1.372-3.611-12.609-2.888-13.586-2.857-.633.02-.639,1,0,.987a66.421,66.421,0,0,1,8.409.175c.492.052,5.385.7,3.784,1.753-2.962,1.931-7.688,1.586-11.081,1.62-3.786.034-7.823.1-11.5-.879-1.777-.478-.574-1.5.6-1.846a20.88,20.88,0,0,1,3.837-.592c1.973-.191,3.966-.228,5.949-.23A.494.494,0,0,0,195.012,106.165Z" transform="translate(-182.262 -106.086)" fill="#c5cedb" />
                                </g>
                                <g id="Group_169133-2" data-name="Group 169133" transform="translate(39.798 115.352)">
                                    <g id="Group_169130-2" data-name="Group 169130" transform="translate(26.669 0.165)">
                                        <path id="Path_5753-2" data-name="Path 5753" d="M195.727,92.829c-.163,9.723-.51,19.452-.641,29.173a.368.368,0,0,0,.736,0c.137-9.721.48-19.45.647-29.173a.371.371,0,0,0-.742,0Z" transform="translate(-195.086 -92.472)" fill="#9faec3" />
                                    </g>
                                    <g id="Group_169131-2" data-name="Group 169131">
                                        <path id="Path_5754-2" data-name="Path 5754" d="M181.651,92.745c.3,9.846.431,19.66,1.463,29.466.05.468.788.47.738,0-1.03-9.806-1.157-19.62-1.459-29.466-.016-.474-.756-.474-.742,0Z" transform="translate(-181.651 -92.389)" fill="#9faec3" />
                                    </g>
                                    <g id="Group_169132-2" data-name="Group 169132" transform="translate(13.328 1.647)">
                                        <path id="Path_5755-2" data-name="Path 5755" d="M188.367,93.575c.824,9.925-.212,19.886.655,29.808.044.468.78.474.742,0-.867-9.921.171-19.882-.657-29.808-.042-.472-.778-.478-.74,0Z" transform="translate(-188.365 -93.218)" fill="#9faec3" />
                                    </g>
                                </g>
                                <path id="Path_5756-2" data-name="Path 5756" d="M235.84,58.616c-3.1-7.869-8.82-15.708-17.776-17.324a1.569,1.569,0,0,0-.193-.014,1.173,1.173,0,0,0-.145-.03c-6.682-.679-10.572,5.981-12.923,11.087-4.234,9.221-6.1,19.543-7.764,29.456-3.263,19.493-6.638,40.855-.921,60.181a1.3,1.3,0,0,0,2.326.3c6.116-9.4,18.733-12.617,26.8-20.117,8.429-7.835,12.712-19.289,14.084-30.372C240.717,80.482,240.052,69.3,235.84,58.616Z" transform="translate(-130.642 -27.46)" fill="#9faec3" />
                                <path id="Path_5757-2" data-name="Path 5757" d="M200.251,89.132c-2.968-16.041-9.975-32.075-18.062-46.288a1.124,1.124,0,0,0-1.171-.6,1.262,1.262,0,0,0-.568.149c-8.129,4.181-12.5,12.192-15.235,20.46-2.938,8.873-4.474,19.692-3.109,28.982,1.765,12.024,8.56,22.638,17.508,30.856,7.327,6.739,16.45,11.106,22.995,18.81a1.289,1.289,0,0,0,2.178-.883A301.24,301.24,0,0,0,200.251,89.132Z" transform="translate(-161.602 -26.441)" fill="#c5cedb" />
                                <path id="Path_5758-2" data-name="Path 5758" d="M216.1,38.45c-7.182-3.919-15.571-4.582-23.507-3.013a1.063,1.063,0,0,0-.875.736c-4.8,6.846-5.5,16.418-6.2,24.422-1.106,12.589-.806,25.31-.246,37.919.752,17,2.182,34.026,4.349,50.909a1.286,1.286,0,0,0,1.272,1.249h14.608a1.274,1.274,0,0,0,1.1-.617c13.487-24.768,17.264-54.56,19.521-82.176.516-6.293.929-12.615,1.034-18.928C227.214,44.78,219.133,40.105,216.1,38.45Z" transform="translate(-138.788 -33.913)" fill="#c5cedb" />
                                <path id="Path_5759-2" data-name="Path 5759" d="M209.989,40.685c-2.287-13.179-25.562-2.662-31.719,1.842-5.693,4.165-7.24,8.105-7.6,14.471a172.639,172.639,0,0,0,1.308,31.789,154.193,154.193,0,0,0,11.4,42.25q2.683,6.128,5.894,11.992c.969,1.771,3.117,7.4,5.209,7.962,3.091.834,7.331.046,10.533.046a1.159,1.159,0,0,0,1.167-.838,1.264,1.264,0,0,0,.107-.411c2.007-24.037,3.6-48.136,4.427-72.239C211.122,65.457,212.069,52.685,209.989,40.685Z" transform="translate(-152.911 -34.279)" fill="#9faec3" />
                                <g id="Group_169134-2" data-name="Group 169134" transform="translate(37.934 113.658)">
                                    <path id="Path_5760-2" data-name="Path 5760" d="M182.246,95.191a81.968,81.968,0,0,0,28.787,0c2.7-.466,1.552-4.073-1.13-3.609a76.082,76.082,0,0,1-26.526,0c-2.678-.5-3.819,3.111-1.131,3.609Z" transform="translate(-180.712 -91.535)" fill="#909db1" />
                                </g>
                            </g>
                            <g >
                                <text id="Oops_" data-name="Oops!" transform="translate(229.18 82.411)" fill="#9faec3" fontSize="20" fontFamily="Poppins-Regular, Poppins" letterSpacing="0.05em">
                                    <tspan x="-31.22" y="21">
                                        Oops!
                                    </tspan>
                                </text>
                                <text id="PAGE_NOT_FOUND" data-name="PAGE NOT FOUND" transform="translate(229.858 192.802)" fill="#9faec3" fontSize="14" fontFamily="Poppins-Regular, Poppins" letterSpacing="0.15em">
                                    <tspan x="-73.031" y="15">
                                        PAGE NOT FOUND
                                    </tspan>
                                </text>
                                <text id="_404" data-name="404" transform="translate(229.339 90.923)" fill="#9faec3" fontSize="85" fontFamily="Lato-Regular, Lato">
                                    <tspan x="-73.95" y="84">
                                        404
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </svg>
                {/* <Typography variant="h1" className="pt-4" align="center">
                    Page Not Found
                </Typography> */}
                <Typography variant="body1" className="pt-4" align="center">
                    The page you're looking for does not seem to exist or You don't have permission to access this page
                </Typography>
                <Grid align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={navigateHome}
                        className="mt-3">
                        Go to Home
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
NotFound.defaultProps = {
    classes: {}
};

// prop types
NotFound.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(NotFound);