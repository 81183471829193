import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { dFormatter } from "../../helpers";

const initialState = {
  isLoading: false,
  alertsLoading: false,
  loadMore: true,
  alerts: [],
  alertsCount: {
    high: { count: 0, alerts: [] },
    medium: { count: 0, alerts: [] },
    low: { count: 0, alerts: [] },
    all: { count: 0, alerts: [] }
  },
  search: { sortBy: "time", orderBy: "desc", offset: 0, limit: 20, search_by: {}, days: 3, status: 'all' },
  exportLoading: false
};

const driftSlice = createSlice({
  name: "drift",
  initialState: { ...initialState },
  reducers: {
    getAlertsRequest(state, action) {
      state.alertsLoading = true;
      const clear = action.payload?.clear ?? false;

      if (clear) {
        state.alerts = [];
        state.loadMore = true;
      }
    },
    getAlertsSuccess(state, action) {
      state.search = { ...state.search, ...action.payload.params };

      const alerts = action.payload.data;
      for (const alert of alerts) {
        alert.time = moment(alert.created_date).format('MM/DD/YYYY');
        alert.expected = "";
        if (alert.threshold?.lower_threshold || alert.threshold?.upper_threshold) {
          alert.expected = `${alert.threshold?.lower_threshold} - ${alert.threshold?.upper_threshold}`;
          if (alert.measure_name?.toLowerCase() === 'freshness') {
            alert.expected = `${dFormatter(alert.threshold?.lower_threshold)} - ${dFormatter(alert.threshold?.upper_threshold)}`;
          }
        }

        if (alert.measure_name?.toLowerCase() === 'freshness' && alert.value) {
          const value = parseInt(alert.value);
          alert.value = dFormatter(value);
        }
      }

      if ((alerts.length < state.search.limit)) {
        state.loadMore = false;
        state.alerts = [...state.alerts, ...alerts];
      } else {
        state.alerts = [...state.alerts, ...alerts];
      }
      state.alertsLoading = false;
    },
    getAlertsFailure(state) {
      state.alertsLoading = false;
    },
    getAlertsCountRequest(state) {
      state.isLoading = true;
    },
    getAlertsCountSuccess(state, action) {
      const data = action.payload.data || {};
      state.alertsCount = {
        ...state.alertsCount,
        ...data
      };
      state.isLoading = false;
    },
    getAlertsCountFailure() { },
    updateAlertList(state, action) {
      const alertIndex = state.alerts.findIndex((alert) => alert.id === action.payload.id);
      if (alertIndex > -1) {
        state.alerts[alertIndex] = { ...action.payload };
      }
    },
    removeAlert(state, action) {
      const alertIndex = state.alerts.findIndex((alert) => alert.id === action.payload.id);
      if (alertIndex > -1) {
        state.alerts.splice(alertIndex, 1);
      }
    },
    updateDriftAlertRequest(state) {
      state.isLoading = true;
    },
    updateDriftAlertSuccess(state, action) {
      state.isLoading = false;
      const alertIndex = state.alerts.findIndex((alert) => alert.id === action.payload.id);
      if (alertIndex > -1) {
        const alert = { ...state.alerts[alertIndex] };
        alert.marked_as = action.payload?.marked_as;
        state.alerts.splice(alertIndex, 1, { ...alert });
      }
    },
    updateDriftAlertFailure(state) {
      state.isLoading = false;
    },
    updateAlertIssueId(state, action) {
      const alertIndex = state.alerts.findIndex((alert) => action.payload?.data?.drift && alert.id === action.payload?.data?.drift);
      if (alertIndex > -1) {
        const alert = { ...state.alerts[alertIndex] };
        alert.issue_id = action.payload?.data?.id;
        alert.issue_number = action.payload?.data?.issue_id;
        state.alerts.splice(alertIndex, 1, { ...alert });
      }
    },
    deleteAlertIssueId(state, action) {
      const alerts = [...state.alerts];
      const index = alerts.findIndex((alert) => alert.issue_id === action.payload);
      if (index > -1) {
        const alert = { ...state.alerts[index] };
        alert.issue_id = null;
        alert.issue_number = null;
        state.alerts.splice(index, 1, { ...alert });
      }
    },
    exportRequest(state) {
      state.exportLoading = true;
    },
    exportSuccess(state) {
      state.exportLoading = false;
    },
    exportFailure(state) {
      state.exportLoading = false;
    }
  }
});

export const { getAlertsRequest, getAlertsSuccess, getAlertsFailure, removeAlert,
  updateDriftAlertRequest, updateDriftAlertSuccess, updateDriftAlertFailure, updateAlertList,
  getAlertsCountRequest, getAlertsCountSuccess, getAlertsCountFailure,
  updateAlertIssueId, deleteAlertIssueId, exportRequest, exportSuccess, exportFailure } = driftSlice.actions;
export default driftSlice.reducer;