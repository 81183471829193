import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import DropBox from '../../../../DropBox/index.jsx';
import SelectedFields from '../../../../SelectedFields/index.jsx';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../../../../layouts/style.jsx';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


function Hierachy(props) {
    /**
     * Define Props
     */
    const { classes, data, onChange } = props;

    /**
     * Define State
     */
    const [levels, setLevels] = useState([]);

    /**
     * Get Detail
     * @param {*} item
     * @param {*} type
     * @returns
     */
    const getDetail = (item) => {
        item.query = item.column;
        item.alias_name = item.name;
        item.type = item.datatype === "text" || item.datatype === "date" || item.isHeatmapSupport ? "dimension" : "measure";
        return item;
    };

    /**
     * OnDrop
     * @param {*} item
     */
    const onDrop = (item, index = null) => {
        if (item.hierachy_table) {
            item = { ...getDetail(item) };
            const properties = Object.assign({}, { ...data });
            const levelsList = [...levels];
            if (!Object.keys(levelsList[index]).length) {
                levelsList.push({});
            }
            levelsList[index] = { ...item };
            properties.levels = levelsList;
            setLevels([...levelsList]);
            onChange(properties);
        }
    };

    /**
     * On Delete Item
     * @param {*} dropType
     * @param {*} index
     */
    const onDeleteItem = (index) => {
        const properties = Object.assign({}, { ...data });
        const levelsList = [...levels];
        levelsList.splice(index, 1);
        properties.levels = levelsList;
        setLevels([...levelsList]);
        onChange(properties);
    };

    /**
     * On Drop Replace Item
     * @param {*} item
     * @param {*} index
     */
    const onDropReplace = (item, index) => {
        item = { ...getDetail(item) };
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        const sortingIndex = levels.findIndex((level) => level.name === item.name);
        if (sortingIndex !== -1) {
            levels[sortingIndex] = levels[index];
        }
        levels[index] = { ...item };
        properties.levels = levels;
        setLevels([...levels]);
        onChange(properties);
    };

    /**
     * Set Default Data
     */
    useEffect(() => {
        const hierachyLevels = data?.levels?.length ? data.levels : [{}];
        setLevels(hierachyLevels);
    }, []);

    const toggleOrganization = (status) => {
        const properties = Object.assign({}, { ...data });
        properties.hideOrg = status;
        onChange(properties);
    };

    return (
        <Grid className={classes.dragContainer}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!data.hideOrg}
                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                        checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                        onChange={() => toggleOrganization(!data.hideOrg)}
                    />
                }
                label={
                    <Typography variant="body1">
                        {"Show Organization"}
                    </Typography>
                }
            />
            {
                levels.map((level, index) =>
                    <Fragment key={`level_${index}`}>
                        <Grid item className={index !== 0 ? classes.dargField : null}>
                            <Typography variant="h6" className="pb5">
                                {`Level ${index + 1}`}
                            </Typography>
                        </Grid>
                        <SelectedFields data={Object.keys(level).length ? [level] : []}
                            onDelete={() => onDeleteItem(index)}
                            accept={["dimension", "sorting"]}
                            // onDrop={(item) => onDrop(item, index)}
                            onDrop={(item) => onDropReplace(item, index)}
                            sorting
                        />
                        {
                            !Object.keys(level).length &&
                            <DropBox accept={["dimension"]} onDrop={(item) => onDrop(item, index)} />
                        }
                    </Fragment>
                )
            }
        </Grid>
    );
}

// default props
Hierachy.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { }
};

// prop types
Hierachy.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Hierachy);