import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent, PasswordInputComponent } from '../../../components/index.js';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import JiraIcon from '../../../assets/img/apps/jira.png';

// Import Constant
import appConstants from '../../../constants/appConstants.js';

// Import Actions
import { getConfigurationRequest, createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { checkPermission } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';
import { Crypto } from '../../../helpers/index.js';


function Jira(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const encryptDecrypt = new Crypto();
    const defaultConfiguration = {
        url: "",
        username: "",
        apikey: "",
        project: "",
        is_validate: false,
        channel_type: "jira",
        web_hook_enabled: false
    };

    /**
     * Define States
     */
    const [checkChanges, setCheckChanges] = useState(false);
    const [formData, setFormData] = useState({ ...defaultConfiguration });

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };

    /**
     * On Save
     */
    const onSave = () => {
        if (!channelId) {
            const updateConfiguration = { ...formData };
            setFormData({ ...updateConfiguration });
            const configuration = {
                ...updateConfiguration,
                apikey: encryptDecrypt.encrypt(updateConfiguration.apikey),
                username: encryptDecrypt.encrypt(updateConfiguration.username)
            };
            const requestParams = {
                config: configuration,
                channel: channel.id,
                id: channelId,
                validate: checkChanges,
                type: "api",
                channelName: "jira"
            };
            dispatch(createConfigrationRequest(requestParams));
        } else {
            const configuration = {
                ...formData,
                apikey: encryptDecrypt.encrypt(formData.apikey),
                username: encryptDecrypt.encrypt(formData.username)
            };
            const requestParams = {
                config: configuration,
                channel: channel.id,
                id: channelId,
                validate: checkChanges,
                type: "api",
                channelName: "jira"
            };
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);


    /**
     * Set FormData
     */
    useEffect(() => {
        if (configuration && channelId) {
            const config = { ...configuration };
            config.username = encryptDecrypt.decrypt(config?.username ?? "");
            config.apikey = encryptDecrypt.decrypt(config?.apikey ?? "");
            setFormData({ ...config });
        }
    }, [configuration]);

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={JiraIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.jira.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.jira.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                name={"url"}
                                value={formData.url || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        API Endpoint
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`API Endpoint ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.username || ""}
                                onChange={(event) => onChange("username", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Username
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Username ${appConstants.errorMessages.requriedField}`]}
                                inputProps={{ maxLength: 100 }}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <PasswordInputComponent
                                value={formData.apikey || ""}
                                onChange={(event) => onChange("apikey", event.target.value)}
                                type="password"
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        API Key
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['passwordRequired']}
                                errorMessages={[`password ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.project || ""}
                                onChange={(event) => onChange("project", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Project ID
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Project ID ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControlLabel
                                control={<Checkbox disabled={!integrationPermission?.is_edit} checked={Boolean(formData.web_hook_enabled)} onChange={(event) => onChange("web_hook_enabled", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.connector.enable_web_hook}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt-3 mb-2">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigate(-1)}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            <LoaderButtonComponent
                                disabled={!checkChanges || saveLoading}
                                size="small"
                                type="submit"
                                isLoading={saveLoading}
                            >
                                {appConstants.labels.connector.save}
                            </LoaderButtonComponent>

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Jira.defaultProps = {
    classes: {}
};

// prop types
Jira.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Jira);