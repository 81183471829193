const style = (theme) => ({
    dashboardSelect: {
        '& .MuiFormControl-root': {
            minWidth: 230
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        '& .MuiInputBase-root': {
            paddingRight: '32px !important'
        },
        '& .MuiInput-root': {
            fontSize: '16px',
            color: '#222 !important',
            fontWeight: 600
        }
    },
    header: {
        paddingBottom: 30,
        '& .CloseIcon': {
            width: '22px',
            height: '22px'
        }
    },
    body: {
        '& .searchBox': {
            width: '205px',
            padding: '1px 10px !important',
            '&.active': {
                border: `1px solid ${theme.palette.secondary.main}`
            }
        }
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 20
    },
    cardContainer: {
        height: 'calc(100vh - 256px)',
        paddingRight: 8,
        overflowY: 'auto'
    },
    formContainer: {
        height: 'calc(100vh - 173px)',
        overflowY: 'auto'
    },
    dashboardSelectList: {
        minWidth: '120px',
        '& .MuiListItemButton-root': {
            paddingTop: '2px',
            paddingBottom: '2px'
        },
        '& .EditIcon': {
            width: '15px',
            height: '15px'
        },
        '& .MuiTypography-root': {
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    },
    searchConatiner: {
        padding: '0 16px',
        marginBottom: '8px',
        marginTop: "15px",
        '& .searchBox': {
            padding: '0px 10px !important'
        }
    },
    searchHeader: {
        width: 34,
        height: 34,
        '& .CloseIcon': {
            width: 22,
            height: 22
        }
    },
    widgetContainer: {
        '& .MuiIconButton-root:hover svg': {
            fill: `${theme.palette.secondary.main}`
        },
        '& .MoreIcon:hover .fillPath': {
            fill: `${theme.palette.secondary.main}`
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiIconButton-root:hover .WidgetIcon': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .DeleteIcon': {
            width: '21px',
            height: '21px'
        },
        '& .MuiIconButton-root:hover .DeleteIcon': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .refreshIcon': {
            fill: theme.palette.greyshades.darkgrey
        },
        '& .MuiIconButton-root:hover .defaultDashboard': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .dashboardIcon .active': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    dashboardSelectBtn: {
        height: '26px',
        width: '26px',
        marginLeft: '10px !important',
        padding: '2px !important'
    },
    top: {
        '& .MuiIconButton-root:hover': {
            '& .fillPath': {
                fill: theme.palette.secondary.main
            }
        }
    }
});

export default style;