import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import {
    defaultDataRequest, defaultDataSuccess, defaultDataFailure
} from '../reducer/defaultReducer';
import { defaultService } from '../service';

/**
 * Get Initial State for connection_type, datatypes, features and channels
 * @param {*} action$
 * @returns
 */
const defaultData = (action$) => action$.pipe(
    ofType(defaultDataRequest),
    mergeMap(({ payload }) => {
        return from(defaultService.getDefaultData(payload)).pipe(
            concatMap((res) => of(defaultDataSuccess(res))),
            catchError(() => of(defaultDataFailure()))
        );
    })
);


// Export All Epic Functions
export const defaultEpic = [defaultData];