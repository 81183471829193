import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Components
import { TableComponent, DialogComponent, ChangePasswordPopover } from '../../../components/index.js';
import UsersCard from './card/users.jsx';
import InviteUsersCard from './card/inviteUsers.jsx';

//  Import Styles
import UsersStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';

// Import Reducer
import { getUsersStatsRequest, inviteNewUserRequest, deleteUserRequest, updateUserDetailRequest, updateUserList, updateUserPreferenceRequest } from '../../../redux/reducer/userReducer';
import { getRolesListRequest } from '../../../redux/reducer/securityReducer';
import { getApplicationsRequest } from '../../../redux/reducer/applicationReducer';
import { getDomainListRequest } from '../../../redux/reducer/semanticReducer.js';
import { forgotPasswordRequest, updateUserPreference } from '../../../redux/reducer/authReducer';

// Import Constants
import { checkPermission, permissionHeaders, exportCsv, getUserPreference, prepareUpdateUserPreference } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';

function Users(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [searchData, setSearchData] = useState({
        "first_name": "",
        "last_name": "",
        "title": "",
        "email": "",
        "roleName": "",
        "is_active": "All"
    });
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState({
        open: false,
        anchorEl: null,
        data: null,
        isUserReset: true
    });

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };


    /**
     * Redux Select Action
     * @param {*} event
     */
    const { usersStats, users, isLoading } = useSelector((state) => state.user, shallowEqual);
    const { roles } = useSelector((state) => state.security, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const securityPermission = checkPermission(permission, featureConstants.settings.security);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "users", "columns");
    const { user: loogedUser } = useSelector((state) => state.auth, shallowEqual);

    const UsersTabHeader = [
        {
            key: 'first_name',
            name: 'First Name',
            sorting: false,
            tooltip: false,
            width: '25%',
            isSearch: true,
            searchKey: 'first_name',
            inlineEdit: true,
            component: 'textbox',
            placeholder: 'First Name',
            datatype: 'text',
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'last_name',
            name: 'Last Name',
            sorting: false,
            tooltip: false,
            width: '25%',
            isSearch: true,
            searchKey: 'last_name',
            inlineEdit: true,
            component: 'textbox',
            placeholder: 'Last Name',
            datatype: 'text',
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'title',
            name: 'Title',
            sorting: false,
            tooltip: false,
            width: '25%',
            isSearch: true,
            searchKey: 'title',
            inlineEdit: true,
            component: 'textbox',
            placeholder: 'Title',
            datatype: 'text',
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'email',
            name: 'Email',
            sorting: false,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchKey: 'email',
            removeCasing: true
        },
        {
            key: 'domains',
            name: 'Domain',
            sorting: false,
            tooltip: false,
            width: '10%',
            component: 'chips',
            valueKey: 'name',
            limit: 2,
            isAdd: true,
            chipAddType: 'autocomplete',
            haveColor: true,
            isSearch: true,
            searchKey: 'domains',
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'applications',
            name: 'Application',
            sorting: false,
            tooltip: false,
            width: '10%',
            component: 'chips',
            valueKey: 'name',
            limit: 2,
            isAdd: true,
            chipAddType: 'autocomplete',
            haveColor: true,
            isSearch: true,
            searchKey: 'applications',
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'role',
            name: 'Role',
            sorting: false,
            tooltip: false,
            width: '10%',
            component: 'select',
            selectComponentKey: 'name',
            componentKey: 'id',
            searchKey: 'roleName',
            isSearch: true,
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'auth_type',
            name: 'Auth Type',
            sorting: false,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchKey: 'auth_type',
            showCaps: true
        },
        {
            key: 'last_login',
            name: 'Last Login',
            sorting: false,
            tooltip: false,
            width: '10%',
            isSearch: false,
            type: "date",
            searchKey: 'last_login',
            hideDefault: true
        },
        {
            key: 'created_date',
            name: 'Created Date',
            sorting: false,
            tooltip: false,
            width: '10%',
            isSearch: false,
            type: "date",
            searchKey: 'created_date',
            hideDefault: true
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: false,
            tooltip: false,
            width: '10%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            searchKey: 'is_active',
            list: ['All', 'Active', 'Inactive'],
            disabledColumn: (data) => (data.id === loogedUser?.id)
        },
        {
            key: 'actions',
            name: 'Actions',
            sorting: false,
            tooltip: false,
            width: '10%',
            actions: [
                { type: 'delete', tooltip: true, tooltipText: 'Delete', renderAction: (data) => (data.id !== loogedUser?.id) },
                { type: 'password_reset', tooltip: true, tooltipText: 'Password Reset', renderAction: (data) => (data.is_active && data.id !== loogedUser?.id && loogedUser?.role_name?.toLowerCase() === 'admin' && data.auth_type !== 'sso') },
                { type: 'password_reset_email', tooltip: true, tooltipText: 'Reset Email', renderAction: (data) => (data.is_active && data.has_password && data.id !== loogedUser?.id && loogedUser?.role_name?.toLowerCase() === 'admin' && data.auth_type !== 'sso') },
                { type: 'resend_invite_email', tooltip: true, tooltipText: 'Resend Invite Mail', renderAction: (data) => (!data.has_password && data.id !== loogedUser?.id && loogedUser?.role_name?.toLowerCase() === 'admin' && data.auth_type !== 'sso') }
            ]
        }
    ];


    /**
     * Redux selector function to get applications from redux store
     */
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);

    /**
     * Redux selector function to get domains from redux store
     */
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);

    /**
     * Get Applications & Domains List
     */
    useEffect(() => {
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
    }, [dispatch]);

    /**
     * Get Roles Statistics
     */
    useEffect(() => {
        dispatch(getUsersStatsRequest());
    }, [dispatch]);

    /**
     * Get Roles List
     */
    useEffect(() => {
        if (!roles.length) {
            dispatch(getRolesListRequest());
        }
    }, [roles]);

    /**
     * Invite New User
     * @param {*} user
     */
    const handleInviteUser = (user) => {
        const post_data = {
            ...user,
            email: user.email.toLowerCase()
        };
        dispatch(inviteNewUserRequest(post_data));
    };

    /**
     * Handle component event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const { first_name, last_name, title, email, role, is_active } = item;
        const domains = item.domains.length ? item.domains.map((obj) => obj.id) : [];
        const applications = item.applications.length ? item.applications.map((obj) => obj.id) : [];
        const oldParams = { email, role, is_active, domains, applications, first_name, last_name, title };
        if (key === "domains" || key === "applications") {
            value = value.length ? [...value].map((obj) => obj.id) : [];
        }
        if (key === "first_name" || key === "last_name" || key === "title") {
            value = event.target.value || "";
        }

        const requestParams = {
            ...oldParams,
            [key]: value,
            id: item.id,
            type: "users",
            page: "security"
        };
        dispatch(updateUserDetailRequest(requestParams));
        dispatch(updateUserList(requestParams));

    };

    /**
     * Open Reset Modal
     * @param {*} data
     */
    const openResetModal = (event, selectedItem) => {
        event.stopPropagation();
        setOpenChangePasswordDialog({
            open: true,
            anchorEl: event.target,
            data: selectedItem,
            isUserReset: true
        });
    };

    /**
     * Close change password dialog
     */
    const closeChangePasswordDialog = () => {
        setOpenChangePasswordDialog({
            open: false,
            anchorEl: null,
            data: null,
            isUserReset: true
        });
    };

    /**
     * Handle click action
     * @param {*} selectedItem
     */
    const onClickActions = (selectedItem, actionName, event) => {
        switch (actionName) {
            case "delete":
                setShowDialog({
                    open: true,
                    title: appConstants.dialogBox.delete,
                    message: appConstants.dialogBox.userDeleteMessage,
                    data: selectedItem
                });
                break;
            case "password_reset":
                openResetModal(event, selectedItem);
                break;
            case "password_reset_email":
                dispatch(forgotPasswordRequest({ email: selectedItem?.email, loading: false }));
                break;
            case "resend_invite_email":
                const params = {
                    email: selectedItem?.email,
                    role: {
                        label: selectedItem?.role_name,
                        id: selectedItem?.role
                    },
                    type: "invite_user",
                    resend_invite: true
                };
                dispatch(inviteNewUserRequest(params));
                break;
            default:
                break;

        }
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteUserRequest({ id: showDialog.data.id, purge: (type === "purge") }));
        dialogCancelEventHandle();
    };

    /**
     * Prepare data for users
     * @param {*} userInfo
     * @param {*} application
     * @param {*} domain
     * @returns
     */
    const prepareFilteredUsers = (userInfo, application, domain, roles, searchFilters) => {
        const preparedData = [...userInfo].map((obj) => {
            const applicationInfo = obj?.applications ?? [];
            const domainInfo = obj?.domains ?? [];
            const applications = application.filter((item) => applicationInfo.indexOf(item.id) !== -1);
            const domains = domain.filter((item) => domainInfo.indexOf(item.id) !== -1);
            const role = roles.find((role) => role.id === obj.role);
            return { ...obj, applications, domains, roleName: role?.name ?? "" };
        });
        let filterData = [...preparedData];
        const filters = [];
        const booleanFields = ['is_active'];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "" && !booleanFields.includes(key)) {
                filters.push(key);
            }
            if (
                (booleanFields.includes(key) && searchFilters[key] !== 'All') ||
                (searchFilters[key] !== '' && !booleanFields.includes(key))
            ) {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = preparedData.filter((item) => {
                for (const key of filters) {
                    if (booleanFields.includes(key)) {
                        const value = Boolean(searchFilters[key] === 'Active');
                        if (item[key] !== value) {
                            return false;
                        }
                    } else {
                        if ((item[key] === null) || (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase()))) {
                            return false;
                        }
                        if (key === 'domains' || key === 'applications') {
                            for (const typeItem of item[key]) {
                                if (typeItem.name.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                                    return true;
                                }
                            }
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        return filterData;
    };

    /**
     * Set User Info
     */
    const userInfo = useMemo(() => prepareFilteredUsers(users, searchableApplicationsList, searchableGlossaries, roles, searchData), [users, searchableApplicationsList, searchableGlossaries, roles, searchData]);


    /**
     * Export Data
     */
    const exportData = () => {
        const headers = permissionHeaders(appConstants.table.UsersTabHeader, securityPermission);
        const data = userInfo.map((obj) => {
            return {
                ...obj,
                domains: obj.domains ? obj.domains.map((item) => item.name).toString() : "",
                applications: obj.applications ? obj.applications.map((item) => item.name).toString() : "",
                role: obj.roleName
            };
        });
        const params = {
            data: data || [],
            fileName: "users.csv",
            titleCaseHeader: true,
            headers: headers.map((header) => header.key).filter((header) => header !== "actions")
        };
        exportCsv(params);
    };
    const tableOptions = [
        { type: 'search', customFunction: null },
        { type: 'download', customFunction: exportData },
        { type: 'columns', customFunction: null }
    ];

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "users", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };


    return (
        <Grid className={classes.usersContainer}>
            <Grid item xs={12} className="mb-3">
                <Typography variant="h5" className="pb5">
                    {appConstants.labels.security.users}
                </Typography>
                <Typography variant="body1">
                    {appConstants.labels.security.usersDescription}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={securityPermission?.is_edit ? 6 : 12}>
                        <UsersCard classes={classes} data={usersStats} />
                    </Grid>
                    {
                        securityPermission?.is_edit &&
                        <Grid item xs={6}>
                            <InviteUsersCard classes={classes} handleInviteUser={(user) => handleInviteUser(user)} />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3">
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        title={appConstants.labels.security.userSearchPlaceholder}
                        styleType="striped"
                        headers={permissionHeaders(UsersTabHeader, securityPermission)}
                        data={userInfo || []}
                        selectComponentList={{ 'role': roles, 'domains': searchableGlossaries, 'applications': searchableApplicationsList }}
                        onCompnentEvent={onCompnentEvent}
                        onClickActions={onClickActions}
                        NoResultText="No Users Found"
                        height="calc(100vh - 300px)"
                        isLoading={isLoading}
                        options={tableOptions}
                        onHandleSearchEvent={onHandleSearchEvent}
                        searchData={searchData}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        stickyHeader
                    />
                </ValidatorForm>
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
            {
                openChangePasswordDialog.anchorEl &&
                <ChangePasswordPopover {...openChangePasswordDialog} onClose={() => closeChangePasswordDialog()} />
            }
        </Grid>
    );
}

// default props
Users.defaultProps = {
    classes: {}
};

// prop types
Users.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...UsersStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Users);