import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton, Tooltip } from "@mui/material";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Visibility, VisibilityOffOutlined } from "@mui/icons-material";
import style from "../../layouts/style.jsx";
import { withStyles } from "@mui/styles";
import _ from 'lodash';

const PasswordInputComponent = (props) => {
	/**
	 * Define Props
	 */
	const { classes, onChange, InputProps, value, isLogin, ...rest } = props;

	/**
	 * Define States
	 */
	const [type, setType] = useState('password');
	const inputRef = useRef(null);

	useEffect(() => {
		if (value && !inputRef?.current?.value) {
			inputRef.current.value = value;
		}
	}, [value, inputRef?.current]);

	useEffect(() => () => {
		if (inputRef?.current?.value) {
			inputRef.current.value = "";
		}
	}, []);

	ValidatorForm.addValidationRule('passwordRequired', () => {
		if (!value) {
			return false;
		}
		return true;
	});

	return (
		<TextValidator
			{...rest}
			inputRef={inputRef}
			autoComplete={isLogin ? "off" : "new-password"}
			onChange={(event) => onChange(event, event.target.value, value)}
			className={classes.passwordBox}
			type={type}
			InputLabelProps={value?.length > 0 ? { shrink: true } : null}
			InputProps={
				{
					...InputProps,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton className={classes.adornment} onClick={() => setType(type === 'password' ? 'text' : 'password')}>
								{
									type === 'password' ? (
										<Tooltip title="Show password" arrow>
											<VisibilityOffOutlined />
										</Tooltip>
									) : (
										<Tooltip title="Hide password" arrow>
											<Visibility />
										</Tooltip>
									)
								}
							</IconButton>
						</InputAdornment>
					)
				}
			}
		/>
	);
};

/**
 * Define Prop Types
 */
PasswordInputComponent.propTypes = {
	classes: PropTypes.object,
	onChange: PropTypes.func,
	InputProps: PropTypes.object,
	value: PropTypes.string.isRequired,
	isLogin: PropTypes.bool
};

// Default props
PasswordInputComponent.defaultProps = {
	classes: {},
	onChange: () => { },
	InputProps: {},
	value: '',
	isLogin: false
};


/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(PasswordInputComponent, areEqual));