/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { WidgetIcon } from '../../../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import DiscoverImg from '../../../../../../assets/img/dashboard/Discover.png';
import Measure from '../../../../../../assets/img/dashboard/Measure.png';
import Monitor from '../../../../../../assets/img/dashboard/Monitor.png';
import CustomDashboard from '../../../../../../assets/img/dashboard/New_Dashboard.png';


function TemplateCard(props) {
    const { classes, data, onSelect, type } = props;

    /**
     * Get Dashboard Image
     * @returns
     */
    const getDashboardImage = () => {
        switch (data.name) {
            case 'Discover':
                return DiscoverImg;
            case 'Measure':
                return Measure;
            case 'Observe':
                return Monitor;
            default:
                return CustomDashboard;
        }
    };

    return (
        <Grid item xs={12} className={classes.templateCard} onClick={() => onSelect(data)}>
            <Grid className="leftSection">
                {
                    type === "widget" ?
                        <WidgetIcon widgetType={data.is_default ? data.name : data.widget_type} /> :
                        <img src={getDashboardImage()} alt="discover" className="dashboardImg" />
                }

            </Grid>
            <Grid className="rightSection">
                <Typography variant="h6" className="pb5">
                    {data.name || ""}
                </Typography>
                <Typography variant="body1" className={`${classes.textSecondary} cardDesc`}>
                    {data.description || ""}
                </Typography>
            </Grid>
        </Grid>
    );
}

// default props
TemplateCard.defaultProps = {
    classes: {},
    data: {},
    onSelect: () => { },
    type: "dashboard"
};

// prop types
TemplateCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onSelect: PropTypes.func,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TemplateCard);