import { getClientHostOrigin } from '../helpers/appHelpers';
const REACT_APP_REDIRECT_URL = getClientHostOrigin();

export const config = {
    slack: {
        clientId: process.env.REACT_APP_SLACK_CLIENT_ID,
        secret: process.env.REACT_APP_SLACK_SECRET,
        redirectUri: REACT_APP_REDIRECT_URL,
        scope: 'channels:read,channels:write,groups:read,chat:write',
        url: 'https://slack.com/oauth/v2/authorize',
        authenticationUrl: 'https://slack.com/api/oauth.v2.access',
        channelsUrl: 'https://slack.com/api/conversations.list',
        grantType: 'authorization_code'
    },
    teams: {
        msalConfig: {
            auth: {
                clientId: process.env.REACT_APP_TEAMS_CLIEN_ID,
                authority: 'https://login.microsoftonline.com/common/',
                redirectUri: REACT_APP_REDIRECT_URL
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false
            }
        },
        scope: ['profile', 'openid', 'email', 'offline_access', 'Channel.ReadBasic.All', 'ChannelMessage.Send', 'Chat.Create', 'Chat.Read', 'Chat.ReadBasic', 'Chat.ReadWrite', 'Directory.Read.All', 'Directory.ReadWrite.All', 'Group.Read.All', 'Group.ReadWrite.All', 'Team.ReadBasic.All', 'TeamMember.Read.All', 'TeamMember.ReadWrite.All', 'User.EnableDisableAccount.All', 'User.Export.All', 'User.ManageIdentities.All', 'User.Read', 'User.Read.All', 'User.ReadBasic.All', 'User.ReadWrite', 'User.ReadWrite.All'],
        redirectUri: REACT_APP_REDIRECT_URL,
        graphEndpoint: 'https://graph.microsoft.com/v1.0',
        api: {
            joinedTeams: 'me/joinedTeams',
            channels: 'teams/{teamId}/channels',
            generateToken: 'oauth2/v2.0/token'
        }
    }
};