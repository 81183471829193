const style = (theme) => ({
    ruleSelectorContainer: {
        '& .MuiButtonBase-root.Mui-selected': {
            background: `${theme.palette.primary.main} !important`,
            border: `1px solid ${theme.palette.primary.main} !important`
        },
        '& .MuiButtonBase-root': {
            background: '#fff',
            minWidth: 50,
            border: `1px solid ${theme.palette.greyshades.darkgrey} !important`,
            '&:not(:last-child)': {
                borderRight: 'none !important'
            }
        }
    }
});
export default style;