import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Avatar, AvatarGroup, Grid, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';


//  Import Components
import { PopOverComponent, AddUser, ListUserComponent } from '../index.js';

// Import Helpers
import { stringAvatar } from '../../helpers/appHelpers';

//  Import Styles
import style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Image
import { UserAddIcon2 } from '../../assets/svg/index.js';


const UsersComponent = (props) => {

    /**
     *  Define Props
     */
    const { classes, list, data, handleStewardUser, disabled, isSteward, inline, isTableComponent } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [addUserBox, setAddUserBox] = useState(false);
    const [stewards, setStewards] = useState([]);

    /**
     *
     * Define Use Effects for Featured Comments
     */
    useEffect(() => {
        setStewards(data);
    }, [data]);

    /**
     * Open Popover
     * @param {*} event
     */
    const onPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close Popover
     */
    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    /**
     * Manage Stewards User
     * @param {*} value
     */
    const handleSelection = (value) => {
        let users = [];
        if (value.length) {
            users = _.uniqBy(value, 'id');
        }
        if (stewards.length > value.length) {
            handleStewardUser(users);
        }
        setStewards(users);

    };

    /**
     * Send Stewards User
     */
    const handleChipSelectionClose = () => {
        handleStewardUser(stewards);
    };

    return (
        <Grid item className={`${classes.usersList} ${inline ? "flexWrap" : null} usersList`}>
            {
                !isTableComponent &&
                <Typography variant="body1" className={`pr-1 stewardUser`}>
                    {isSteward ? 'Stewards' : 'Users'}
                </Typography>
            }
            <AvatarGroup max={3} className="pr5 avatarGroup" onClick={onPopoverOpen}>
                {
                    stewards.map((user) =>
                        <Grid key={user.id}>
                            <Avatar
                                alt={user.name}
                                className={classes.avatarMedium}
                                {...stringAvatar(user.name, user?.avatar ?? '')}
                                onClick={(event) => { event.stopPropagation(); }}
                            />
                        </Grid>
                    )
                }

            </AvatarGroup>
            {
                !addUserBox && !disabled &&
                <Tooltip title="Add User" arrow>
                    <Grid className="dflex" onClick={() => setAddUserBox((addUserBox) => !addUserBox)}>
                        <UserAddIcon2 />
                    </Grid>
                </Tooltip>
            }
            {
                addUserBox &&
                <Grid className={"addUserGrid"} container onBlur={() => setAddUserBox((addUserBox) => !addUserBox)}>
                    <AddUser
                        available_users={list}
                        data={stewards}
                        onChange={handleSelection}
                        handleChipSelectionClose={handleChipSelectionClose}
                        inline={inline}
                    />
                </Grid>
            }
            <PopOverComponent anchorEl={anchorEl} onClose={onPopoverClose}>
                <Grid className={classes.popoverDiv}>
                    {
                        stewards.map((user, index) => {
                            if (index >= 2) {
                                return <ListUserComponent key={`list-user-${index}`} user={user} showName />;
                            }
                            return null;
                        }
                        )
                    }
                </Grid>
            </PopOverComponent>
        </Grid>
    );
};

// Default props
UsersComponent.defaultProps = {
    classes: {},
    data: [],
    list: [],
    isSteward: true,
    handleStewardUser: () => { },
    disabled: false,
    inline: false,
    isTableComponent: false
};

// Prop types
UsersComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    list: PropTypes.array,
    handleStewardUser: PropTypes.func,
    disabled: PropTypes.bool,
    isSteward: PropTypes.bool,
    inline: PropTypes.bool,
    isTableComponent: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(UsersComponent);