import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Schedule Actions and Reducer
import {
    createScheduleRequest, createScheduleSuccess, createScheduleFailure,
    updateScheduleRequest, updateScheduleSuccess, updateScheduleFailure,
    deleteScheduleRequest, deleteScheduleSuccess, deleteScheduleFailure,
    getScheduleRequest, getScheduleSuccess, getScheduleFailure,
    triggerJobRequest, triggerJobSuccess, triggerJobFailure,
    killJobRequest, killJobSuccess, killJobFailure, getTargetAssetRequest, getTargetAssetSuccess, getTargetAssetFailure, getScheduleListRequest, getScheduleListSuccess, getScheduleListFailure,
    getAssetJobListRequest, getAssetJobListSuccess, getAssetJobListFailure, getRunDetailRequest, getRunDetailSuccess, getRunDetailFailure, getMeasureScheduleListRequest, getMeasureScheduleListSuccess, getMeasureScheduleListFailure, getReportScheduleListRequest, getReportScheduleListSuccess, getReportScheduleListFailure, getTargetMeasureRequest, getTargetMeasureSuccess, getTargetMeasureFailure
} from '../reducer/scheduleReducer';
import { connectorAssetReducerValueUpdate } from '../reducer/connectorReducer';
import { updateMeasureJobStatus, updateAssetMeasureProperty } from '../reducer/measureReducer';
import { updateJobStatus } from '../reducer/versionReducer';

// Import Reducers
import { displyAlert } from "../reducer/alertReducer";

// Import Service
import { scheduleService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";
import { updateAttributeRunStatus } from '../reducer/attributeReducer';


/**
 * Get Schedule Detail
 * @param {*} action$
 * @returns
 */
const getScheduleDetail = (action$) => action$.pipe(
    ofType(getScheduleRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getSchedule(payload)).pipe(
            concatMap((res) => of(getScheduleSuccess({ ...res.data, request: payload }))),
            catchError((error) => of(getScheduleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Schedule Action
 * @param {*} action$
 * @returns
 */
const createSchedule = (action$) => action$.pipe(
    ofType(createScheduleRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.create(payload)).pipe(
            concatMap((res) => of(
                createScheduleSuccess({ ...res.data.data }),
                payload.measure ?
                    updateAssetMeasureProperty({ id: payload.measure, is_schedule: true }) :
                    connectorAssetReducerValueUpdate(
                        {
                            id: payload.id || payload.asset,
                            connection_id: payload.connection_id || payload.connection,
                            value: {
                                key: 'is_schedule',
                                value: true
                            }
                        }),
                displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }))
            ),
            catchError((error) => of(createScheduleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Update Schedule Action
 * @param {*} action$
 * @returns
 */
const updateSchedule = (action$) => action$.pipe(
    ofType(updateScheduleRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(scheduleService.update(id, rest)).pipe(
            concatMap((res) => of(updateScheduleSuccess({ ...res.data }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateScheduleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Delete Schedule Action
 * @param {*} action$
 * @returns
 */
const deleteSchedule = (action$) => action$.pipe(
    ofType(deleteScheduleRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(scheduleService.remove(id)).pipe(
            concatMap(() => of(
                deleteScheduleSuccess(id),
                rest?.measure ?
                    updateAssetMeasureProperty({ id: rest.measure, is_schedule: false }) :
                    connectorAssetReducerValueUpdate(
                        {
                            id: rest?.asset ?? '',
                            connection_id: rest.connection_id || rest.connection,
                            value: {
                                key: 'is_schedule',
                                value: false
                            }
                        }),
                displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted })
            )),
            catchError((error) => of(deleteScheduleFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Trigger a Schedule for the given asset
 * @param {*} action$
 * @returns
 */
const triggerSchedule = (action$) => action$.pipe(
    ofType(triggerJobRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.trigger(payload)).pipe(
            concatMap((res) => {
                if (payload.job_type === "measure") {
                    return of(triggerJobSuccess(res), updateMeasureJobStatus({ status: "Pending", data: payload }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.trigger }));
                }
                return of(triggerJobSuccess(res), updateJobStatus({ status: "Pending", data: payload }), updateAttributeRunStatus({ data: { run_status: "Pending", attribute_id: payload?.attribute_id || null } }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.trigger }));
            }),
            catchError((error) => of(triggerJobFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Kill the job for the given asset
 * @param {*} action$
 * @returns
 */
const killJob = (action$) => action$.pipe(
    ofType(killJobRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.killJob(payload)).pipe(
            concatMap((res) => of(killJobSuccess(res), updateJobStatus({ status: "Completed", data: payload }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.killJob }))),
            catchError((error) => of(killJobFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

const getTargetAsset = (action$) => action$.pipe(
    ofType(getTargetAssetRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getTargetAsset(payload)).pipe(
            concatMap((res) => of(getTargetAssetSuccess({ ...payload, ...res }))),
            catchError((error) => of(getTargetAssetFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

const getTargetMeasure = (action$) => action$.pipe(
    ofType(getTargetMeasureRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getTargetMeasure(payload)).pipe(
            concatMap((res) => of(getTargetMeasureSuccess({ ...payload, ...res }))),
            catchError((error) => of(getTargetMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Asset Schedule List
 * @param {*} action$
 * @returns
 */
const getScheduleList = (action$) => action$.pipe(
    ofType(getScheduleListRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getScheduleList(payload)).pipe(
            concatMap((res) => of(getScheduleListSuccess({ ...res.data, ...payload }))),
            catchError((error) => of(getScheduleListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Asset Job List
 * @param {*} action$
 * @returns
 */
const getAssetScheduleJobList = (action$) => action$.pipe(
    ofType(getAssetJobListRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getAssetJobList(payload)).pipe(
            concatMap((res) => of(getAssetJobListSuccess(res.data))),
            catchError((error) => of(getAssetJobListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Run Details of Respective request_queue
 * @param {*} action$
 * @returns
 */
const getRunDetails = (action$) => action$.pipe(
    ofType(getRunDetailRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getRunDetails(payload)).pipe(
            concatMap((res) => of(getRunDetailSuccess(res))),
            catchError((error) => of(getRunDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Measure Schedule List
 * @param {*} action$
 * @returns
 */
const getMeasureScheduleList = (action$) => action$.pipe(
    ofType(getMeasureScheduleListRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getMeasureScheduleList(payload)).pipe(
            concatMap((res) => of(getMeasureScheduleListSuccess({ ...res.data }))),
            catchError((error) => of(getMeasureScheduleListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Report Schedule List
 * @param {*} action$
 * @returns
 */
const getReportScheduleList = (action$) => action$.pipe(
    ofType(getReportScheduleListRequest),
    mergeMap(({ payload }) => {
        return from(scheduleService.getReportScheduleList(payload)).pipe(
            concatMap((res) => of(getReportScheduleListSuccess({ ...res.data }))),
            catchError((error) => of(getReportScheduleListFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


// Export All Epic Functions
export const scheduleEpic = [
    getScheduleDetail, createSchedule, updateSchedule, deleteSchedule, triggerSchedule, killJob, getTargetAsset, getScheduleList,
    getAssetScheduleJobList, getRunDetails, getMeasureScheduleList, getReportScheduleList, getTargetMeasure
];