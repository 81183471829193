import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Icons
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

//  Import Components
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent, SelectComponent } from '../../../components/index.js';
import SchemaSelector from '../components/schemaSelector/index.jsx';

//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

/*
 * import Images
 */
import SnowflakeIcon from '../../../assets/img/connectors/snowflake.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';

// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState,
    getDBSchemasListRequest
} from '../../../redux/reducer/connectionReducer.js';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';


function Snowflake(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'snowflake',
        description: '',
        account: '',
        warehouse: '',
        database: '',
        schema: [],
        user: '',
        password: '',
        authentication_type: appConstants.labels.connector.snowflake.authentication_types[0],
        pass_phrase: '',
        private_key: '',
        push_semantic_tags: false,
        lineage: false
    });
    const {
        id,
        type,
        name,
        description,
        account,
        schema,
        database,
        warehouse,
        user,
        password,
        authentication_type,
        pass_phrase,
        private_key,
        push_semantic_tags,
        lineage
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, dbs_schema, saveIsLoading, schemaLoading } = useSelector((state) => state.connection);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getDBSchemasListRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);


    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.user = encryptDecrypt.decrypt(credentialDetails?.user ?? "");
            credentialDetails.authentication_type = credentialDetails?.authentication_type ?? appConstants.labels.connector.snowflake.authentication_types[0];
            if (credentialDetails.authentication_type === appConstants.labels.connector.snowflake.authentication_types[0]) {
                credentialDetails.password = encryptDecrypt.decrypt(credentialDetails?.password ?? "");
            } else {
                credentialDetails.pass_phrase = encryptDecrypt.decrypt(credentialDetails?.pass_phrase ?? "");
                credentialDetails.private_key = encryptDecrypt.decrypt(credentialDetails?.private_key ?? "");
            }
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const authCredentials = authentication_type === appConstants.labels.connector.snowflake.authentication_types[0] ? {
            password: encryptDecrypt.encrypt(password)
        } : {
            private_key: encryptDecrypt.encrypt(private_key),
            pass_phrase: encryptDecrypt.encrypt(pass_phrase)
        };
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                account,
                database,
                schema,
                warehouse,
                user: encryptDecrypt.encrypt(user),
                authentication_type,
                push_semantic_tags,
                lineage,
                ...authCredentials
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(null);
    };

    /**
     * Handle Database Value Selection
     * @param {*} newValue
     */
    const handleSchemaSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, schema: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    /**
     * Key File Upload
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const fileReader = new FileReader();
            fileReader.readAsText(event.target.files[0], "UTF-8");
            fileReader.onload = (json) => {
                setFormData((formData) => ({ ...formData, private_key: json.target.result }));
                setIsAuthenticationChanged(true);
            };
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Federation File Upload
     */
    const handleFileUpload = () => {
        document.getElementById(`uploadFile`).click();
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={SnowflakeIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.snowflake.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.snowflake
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={8} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_account"
                                name={'account'}
                                value={account}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.account}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.account
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_warehouse"
                                name={'warehouse'}
                                value={warehouse}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.warehouse}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.warehouse
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt0">
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_database"
                                name={'database'}
                                value={database}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.database}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.database
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <SelectComponent
                                id="form_text_authentication_type"
                                name={'authentication_type'}
                                fullWidth={false}
                                label={appConstants.labels.connector.authentication_type}
                                variant="standard"
                                value={authentication_type || ''}
                                onSelectChange={(value) => handleTextBoxChanges('authentication_type', value)}
                                list={appConstants.labels.connector.snowflake.authentication_types}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.authentication_type
                                        )
                                    ]
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt0">
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_user"
                                name={'user'}
                                value={user}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.user}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.user
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            {
                                authentication_type === appConstants.labels.connector.snowflake.authentication_types[0] &&
                                <PasswordInputComponent
                                    id="form_text_password"
                                    name={'password'}
                                    value={password}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.authentication.password}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['passwordRequired']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.authentication.password
                                            )
                                        ]
                                    }
                                    onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true); }}
                                    inputProps={{ maxLength: 100 }}
                                />
                            }
                            {
                                authentication_type === appConstants.labels.connector.snowflake.authentication_types[1] &&
                                <PasswordInputComponent
                                    id="form_text_pass_phrase"
                                    name={'pass_phrase'}
                                    value={pass_phrase}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.authentication.passPhrase}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['passwordRequired']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.authentication.passPhrase
                                            )
                                        ]
                                    }
                                    onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true); }}
                                    inputProps={{ maxLength: 100 }}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt0 dflex alignCenter">
                        {
                            authentication_type === appConstants.labels.connector.snowflake.authentication_types[1] &&
                            <Grid item xs={4}>
                                <input
                                    accept=".p8,.pub"
                                    style={{ display: 'none' }}
                                    id={`uploadFile`}
                                    type="file"
                                    onChange={handleSelectFile}
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleFileUpload}
                                >
                                    {`Upload Key File`}
                                </Button>
                            </Grid>
                        }
                        {
                            detailById?.is_valid &&
                            <SchemaSelector
                                dbs_schema={dbs_schema || []}
                                schema={schema || []}
                                handleSchemaSelection={handleSchemaSelection}
                                schemaLoading={schemaLoading}
                                detailById={detailById}
                                divSpan={appConstants.labels.connector.snowflake.authentication_types[0] === authentication_type ? 4 : 5}
                                userClass={appConstants.labels.connector.snowflake.authentication_types[0] !== authentication_type ? 'pl-10' : null}
                            />
                        }
                    </Grid>

                    <Grid container spacing={5} className="mt0 dflex alignCenter">
                        <Grid item xs={4} className={classes.checkBox}>
                            <FormControlLabel
                                control={<Checkbox checked={push_semantic_tags} onChange={(event) => handleTextBoxChanges("push_semantic_tags", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.connector.push_semantic_tags}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <FormControlLabel
                                control={<Checkbox checked={lineage} onChange={(event) => handleTextBoxChanges("lineage", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.connector.lineage}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className="mt-3 actionsContainer">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Snowflake.defaultProps = {
    classes: {}
};

// prop types
Snowflake.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Snowflake);