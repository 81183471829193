const style = (theme) => ({
    generalPageContainer: {
        padding: '24px 30px 30px'
    },
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px'
    },
    subHeaderConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '10px 24px'
    },
    bodyContainer: {
        padding: 24,
        paddingBottom: 6,
        '& svg.CalendarIcon path, svg.CalendarIcon circle, svg.CalendarIcon ellipse': {
            fill: `${theme.palette.greyshades.darkgrey} !important`
        },
        '& .calendarIcon:hover': {
            '& circle, ellipse, path': {
                fill: theme.palette.secondary.main
            }
        }
    },
    CalendarIcon: {
        '&:hover': {
            '& svg.CalendarIcon circle, svg.CalendarIcon ellipse, svg.CalendarIcon path': {
                fill: `${theme.palette.secondary.main} !important`
            },
            background: 'red'
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 6px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& fieldset': {
            borderColor: 'transparent !important'
        },
        '& .Mui-focused fieldset': {
            border: 'none !important'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 8,
            marginRight: 8
        }
    },
    scoringValuePicker: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`
    },
    colorPicker: {
        padding: '6px 15px',
        background: theme.palette.greyshades.lightgrey,
        position: 'relative',
        '& svg': {
            width: '14px',
            height: '20px'
        },
        '& .typeColor': {
            width: 22,
            border: 'none',
            height: 22,
            display: 'block',
            opacity: 0,
            position: 'absolute',
            top: '6px',
            right: '11px'
        }
    },
    subContent: {
        '& .textBoxContainer, .selectComponent': {
            width: '100%'
        }
    },
    notifyToggleBtn: {
        '& .MuiToggleButtonGroup-grouped': {
            height: '36px'
        }
    },
    notifciationItems: {
        '& .MuiFormControlLabel-root': {
            minWidth: '132px'
        }
    },
    importContainer: {
        '& .containerInner': {
            padding: 30,
            height: '100Vh',
            '& .dropContainer': {
                border: `2px dashed ${theme.palette.greyshades.lightgrey}`,
                background: theme.palette.greyshades.lightbackground,
                height: `calc(100% - 60px)`
            },
            '& .chooseFileBtn': {
                color: '#222',
                border: `1px solid ${theme.palette.greyshades.darkgrey}`
            },
            '& .importList': {
                height: `calc(100% - 60px)`,
                alignItems: 'baseline'
            },
            '& .headerRow, .bodyRow': {
                display: 'flex'
            },
            '& .headerCell, .bodyCell': {
                flex: 1,
                padding: '8px 10px'
            },
            '& .importTable': {
                paddingTop: 15
            }
        }
    },
    importListBody: {
        '& .MuiTableCell-body p': {
            minWidth: 180
        }
    }
});
export default style;