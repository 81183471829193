import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

//  Import Styles
import AddRuleStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import QueryBuilderComponent from '../../../../components/queryBuilder/index.jsx';
import Header from './header/index.jsx';
import Threshold from './threshold/index.jsx';
import { BehavioralComponent, QueryComponent, ToggleComponent, LoaderButtonComponent, ComparisonComponent, LookupComponent } from '../../../../components/index.js';
import MeasureChart from './chart/index.jsx';
import ConditionalScoring from './conditionalScoring/index.jsx';
import ValidateMeasure from './validateMeasure.jsx';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';

// Import Action
import {
    updateMeasureDetail, clearMeasureDetailState, createMeasureRequest,
    updateMeasureRequest, createStandaloneMeasureRequest, updateStandAloneMeasureRequest, getMeasureDetailRequest
} from '../../../../redux/reducer/measureReducer';
import { displyAlert } from '../../../../redux/reducer/alertReducer';

import { checkPermission, getUserPreference, prepareUpdateUserPreference } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';

import { updateUserPreference } from '../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer';

import { getReferencesRequest } from '../../../../redux/reducer/librariesReducer.js';
import { getAttributesByVersionRequest } from '../../../../redux/reducer/attributeReducer.js';
function RuleAdd(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const { state } = useLocation();
    const dispatch = useDispatch();
    const reactNavigation = useNavigate();
    const { id: measureId } = useParams();
    const { attribute_id: attribute, asset_id: asset, isAssetLevel: assetLevel, isMeasureLevel: measureLevel } = state || {};

    /**
     * Define State Variables
     */
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [changedProperties, setChangedProperties] = useState([]);
    const [formSubmit, setFormSubmit] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [isCategoryChanged, setIsCategoryChanged] = useState(false);
    const [comparisonFieldMissing, setComparisonFieldMissing] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [lookupOption, setLookupOption] = useState('');
    const [lookupMetadata, setlookupMetadata] = useState({});
    const [inputColumn, setInputColumn] = useState([]);
    const [selectedMeasureId, setSelectedMeasureId] = useState(measureId);


    /**
     * Redux Select Action
     * @param {*} event
     */
    const { detail: data, isLoading } = useSelector((state) => state.measure);
    const { detail: metric_detail } = useSelector((state) => state.metric);
    const { properties, threshold_constraints, is_auto, category } = data;
    const { permission } = useSelector((state) => state.auth);
    const propertyPermission = checkPermission(permission, featureConstants.assets.properties);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const searchableTermsList = useSelector((state) => state.term.searchableTermsList, shallowEqual);
    const { list: references } = useSelector((state) => state.libraries);
    const { detail: connectionDetails } = useSelector((state) => state.version);
    const user = useSelector((state) => state.auth.user);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "lookupMeasures", "columns");
    const { list: attributes } = useSelector((state) => state.attribute);
    let lookupName = references && references.map(({ name }) => name);
    const attribute_id = attribute || data.attribute_id || "";
    const asset_id = asset || data.asset_id || "";
    const isAssetLevel = assetLevel || Boolean(data?.attribute_id === "" || !data?.attribute_id) && data.asset_id;
    const isMeasureLevel = measureLevel || (data.level === "measure");

    const validConnectionTypes = [
        appConstants.connectionTypes.denodo.value,
        appConstants.connectionTypes.athena.value,
        appConstants.connectionTypes.saphana.value,
        appConstants.connectionTypes.redshift_spectrum.value,
        appConstants.connectionTypes.emr_spark.value
      ];

      if ((validConnectionTypes.includes(connectionDetails?.type?.toLowerCase())) || (validConnectionTypes.includes(state?.connection_type?.toLowerCase()))) {
        lookupName = references?.filter((ref) =>
            ref?.type !== 'FILE' &&
            ref?.properties?.tableConfig?.some((config) =>
                config.connection?.some((conn) => ((conn.id === connectionDetails?.connection_id) || (conn.id === state?.connection_id)))
            )
        ).map(({ name }) => name);
    }

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        referance_column: '',
        type: ''
    });
    const sortData = useState({ "sortBy": "name", "orderBy": "asc" });


    const isDisabled = (data, header) => {
        let isDisabled = false;
        switch (header?.key) {
            case 'referance_column':
                isDisabled = (data?.row_type === "default");
                break;
            case 'type':
                isDisabled = (data?.row_type === "default");
                break;
            default:
                isDisabled = false;
                break;
        }
        return isDisabled;
    };

    const lookupTableHeaders = [
        { key: 'referance_column', name: 'Reference Parameter', datatype: 'text', sorting: true, isSearch: true, searchKey: 'referance_column', component: 'select', applyHeaderStyle: true, validate: true, validators: ['required'], errorMessages: ['Key is required'], isDisabled: (data, header) => isDisabled(data, header) },
        {
            key: 'type',
            name: 'Type',
            sorting: true,
            isSearch: true,
            searchKey: 'type',
            tooltip: true,
            datatype: 'text',
            component: 'select',
            inlineEdit: false,
            applyDisableCss: true,
            isDisabled: (data, header) => isDisabled(data, header)
        },
        { key: 'lookup', name: 'Lookup Value', component: 'chips', datatype: 'array', searchKey: 'lookup', valueKey: 'name', defaultValue: [], isAdd: true, addType: "text", chipAddType: 'autocompletewithinput', showNA: true, addText: 'Add Parameter', isChipDelete: true, applyHeaderStyle: true },
        {
            key: 'actions',
            name: 'Actions',
            width: '5%',
            applyHeaderStyle: true,
            actions: [{ type: 'delete', tooltip: true, tooltipText: 'Delete' }]
        }
    ];

    const prepareTableRows = (value, method, rowtype) => {
        return { "referance_column": value, "type": method, "row_type": rowtype };
    };

    const updateLookup = (newvalue) => {
        if (newvalue) {
            setLookupOption(newvalue);
            const filterObj = references.filter((obj) => obj.name === newvalue)[0];
            const tableRows = [];
            setlookupMetadata({ "id": filterObj.id, "type": filterObj.type });
            if (filterObj) {
                const inputColumns = filterObj.columns.map(({ name }) => name);
                for (const value of filterObj.input_fields) {
                    tableRows.push(prepareTableRows(value.name, 'Input', "default"));
                }
                for (const value of filterObj.output_fields) {
                    tableRows.push(prepareTableRows(value.name, 'Output', "default"));
                }
                setTableRows(tableRows);
                setInputColumn(inputColumns);
            }
        }
    };

    const handleCellClickEvent = (key, value, item) => {
        const updateObj = JSON.parse(JSON.stringify(tableRows));
        if (key === "referance_column") {
            const value_present = updateObj.filter((obj) => obj.referance_column === value);
            if (value_present.length > 0) {
                dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Please select a different column.' } }));
                return false;
            }
        }
        const foundIndex = updateObj.findIndex((obj) => obj.referance_column === item.referance_column);
        updateObj[foundIndex][key] = value;
        setTableRows([...updateObj]);
    };

    const handleActionsClickEvent = (data, actionName) => {
        if (actionName === "delete") {
            const updateObj = tableRows.filter((obj) => obj.referance_column !== data.referance_column);
            setTableRows([...updateObj]);
        }
    };

    /**
     * Measure Add Popup
     */
    const handleMeasureAddEditDialog = () => {
        const tableRecord = [...tableRows];
        tableRecord.push(prepareTableRows("", "Input", "userenter"));
        setTableRows(tableRecord);
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value, key, property) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, key, property, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns }, "table", "lookupMeasures");
    };

    /**
     * Get Table Action Options
     */
    const lookupTableOptions = [{ type: "search", customFunction: null }, { type: "columns", customFunction: null }, { type: "add", customFunction: handleMeasureAddEditDialog }];


    /**
     * Load Selected Term
     */
    const loadSelectedTerm = (searchableTermsList) => {
        let term = null;
        if (data?.term_id) {
            term = searchableTermsList.find((item) => item.id === data.term_id);
        }
        setSelectedTerm(term);
    };

    const resetChangedProperties = useCallback(() => {
        setChangedProperties([]);
    }, []);

    useEffect(() => {
        if (data?.term_id && searchableTermsList && searchableTermsList.length > 0) {
            loadSelectedTerm(searchableTermsList);
        }
        return () => {
            setFormSubmit(false);
        };
    }, [searchableTermsList, data]);

    useEffect(() => {
        dispatch(getReferencesRequest({ params: { offset: 0, limit: 1000, sortBy: "name", orderBy: "asc" }, token: null }));
    }, []);

    useEffect(() => {
        if (properties) {
            if ('lookup_properties' in properties) {
                setTableRows([...properties.lookup_properties]);
                setLookupOption(properties.lookupOption);
                setInputColumn(properties.inputColumn);
                setlookupMetadata(properties.lookupMetadata);
            }
        }
    }, [properties]);

    /**
     * Edit Measure
     * @param {*} key
     * @param {*} value
     */
    const editMeasure = useCallback((key, value) => {
        dispatch(updateMeasureDetail({ [key]: value }));
        setChangedProperties([...changedProperties, key]);
    }, []);

    /**
     * Change Measure Category
     * @param {*} category
     */
    const changeMeasureCategory = (category) => {
        const updatedProperties = JSON.parse(JSON.stringify(properties));
        updatedProperties.failed_rows_query = {
            "is_enabled": false,
            "query": ""
        };
        updatedProperties.limitConfig = {
            "override": false,
            "row_count": "",
            "column_count": ""
        };
        updatedProperties.conditional_scoring = {
            "is_enabled": false,
            "query": ""
        };
        if (isMeasureLevel) {
            updatedProperties.conditional_scoring = {
                "is_enabled": true,
                "query": ""
            };
        }
        updatedProperties.priority = "";
        updatedProperties.comparison = [];
        dispatch(updateMeasureDetail({
            category,
            query: "",
            is_auto: true,
            threshold_constraints: {},
            is_aggregation_query: false,
            properties: updatedProperties
        }));
        setIsCategoryChanged(data.category !== category);
        setChangedProperties([...changedProperties, 'category']);
    };

    /**
     * Save Measure
     */
    const saveMeasure = (event) => {
        if (formSubmit) {
            setFormSubmit(false);
            event.preventDefault();

            const isBehavioral = Boolean(category && category.toLowerCase() === "behavioral");
            const isComparison = Boolean(category && category.toLowerCase() === "comparison");
            const isLookup = Boolean(category && category.toLowerCase() === "lookup");

            if (isLookup) {
                if (lookupOption === "") {
                    dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Please select a lookup type.' } }));
                    return false;
                }
                const updateObj = JSON.parse(JSON.stringify(tableRows));
                if (updateObj.length === 0) {
                    dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Please add a parameter.' } }));
                    return false;
                }
                const value_present = updateObj.filter((obj) => obj.lookup === undefined || obj.lookup && obj.lookup.length === 0);
                if (value_present.length > 0) {
                    dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Please select a lookup value.' } }));
                    return false;
                }
            }

            if (isBehavioral) {
                if (!data.properties.aggregator_attributes) {
                    dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Please select a aggregator attribute.' } }));
                    return false;
                }
            }
            let level = isAssetLevel ? "asset" : "attribute";
            if (isMeasureLevel) {
                if (!data.connection) {
                    setConnectionError(true);
                    return;
                }
                setConnectionError(false);
                level = "measure";
                if (isComparison && !properties.priority) {
                    setComparisonFieldMissing(true);
                    return;
                }
                setComparisonFieldMissing(false);
            }

            const requestData = {
                ...data,
                "allow_score": (!isBehavioral && !isComparison) ? Boolean(data.allow_score) : false,
                "is_positive": (!isBehavioral && !isComparison) ? Boolean(data.is_positive) : false,
                "is_drift_enabled": !isComparison ? Boolean(data.is_drift_enabled) : false,
                "is_auto": !isComparison ? is_auto : false,
                "asset": asset_id,
                "attribute": isAssetLevel || isMeasureLevel ? "" : attribute_id,
                "type": "custom",
                "level": level,
                "connection": isMeasureLevel ? data.connection : "",
                "domains": data?.domains?.map((domain) => domain.id) ?? [],
                "application": data?.application?.map((application) => application.id) ?? [],
                "stewards": data?.stewards?.map((user) => user.id) ?? [],
                "custom_fields": data?.custom_fields ?? []
            };

            if (isLookup) {
                let failed_rows_query = {};
                let limitConfig = {};
                if ('failed_rows_query' in requestData.properties) {
                    failed_rows_query = requestData.properties.failed_rows_query;
                }
                if ('limitConfig' in requestData.properties) {
                    limitConfig = requestData.properties.limitConfig;
                }
                requestData.properties = { "lookup_properties": tableRows, "lookupOption": lookupOption, "inputColumn": inputColumn, "lookupMetadata": lookupMetadata, "failed_rows_query": failed_rows_query, "limitConfig": limitConfig };
            }

            if (!requestData.id) {
                if (isMeasureLevel) {
                    dispatch(createStandaloneMeasureRequest(requestData));
                }
                else {
                    dispatch(createMeasureRequest(requestData));
                }
            } else {
                requestData.updated_properties = [...new Set([...changedProperties])];
                if (isMeasureLevel) {
                    requestData.isUpdate = true;
                    dispatch(updateStandAloneMeasureRequest(requestData));
                } else {
                    dispatch(updateMeasureRequest(requestData));
                }
            }
            resetChangedProperties();
        }
    };

    /**
     * Hangle Dialog Close Event
     */
    const handleDialogClose = () => {
        setConnectionError(false);
        setComparisonFieldMissing(false);
        dispatch(clearMeasureDetailState());
        reactNavigation(-1);
    };

    /**
     * Handle Navigation
     */
    const handleNavigation = (type, data) => {
        if (type === "term") {
            dispatch(navigate({ path: 'settings.semantics.domains.term', state: {}, params: [data.id] }));
        }
    };

    const prepareFilterResult = (data, searchFilters, sortFilters) => {
        const { sortBy, orderBy } = sortFilters;
        let filterData = JSON.parse(JSON.stringify([...data]));
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }

        filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        return filterData;
    };

    /**
     * Get selected Measure Detail
     */
    useEffect(() => {
        if (measureId) {
            dispatch(getMeasureDetailRequest(measureId));
        }
    }, []);


    useEffect(() => {
        if (data?.id && !measureId) {
            reactNavigation(`/measure/${data.id}`, { replace: true });
        }
    }, [data.id]);

    /**
     * Get Attributes and Get Asset Measures
     */
    useEffect(() => {
        if (state?.asset_id && (!measureId || (measureId && selectedMeasureId))) {
            dispatch(getAttributesByVersionRequest(state?.asset_id));
        }
    }, [state, selectedMeasureId]);

    useEffect(() => {
        if (measureId !== selectedMeasureId) {
            setSelectedMeasureId(measureId);
        }
    }, [measureId]);

    /**
     * Get Query
     * @param {*} data
     * @returns
     */
    const getQuery = (data) => {
        let query = data?.query ?? "";
        if (data?.type?.toLowerCase() === "semantic") {
            query = data.semantic_query ?? "";
        }
        return query ?? "";
    };
    const query = useMemo(() => getQuery(data), [data]);

    /**
     * Prepare Data using UseMemo
     */
    const resultData = useMemo(() => prepareFilterResult(tableRows, searchData, sortData), [tableRows, searchData, sortData]);
    return (
        <Grid>
            <ValidatorForm onSubmit={(event) => saveMeasure(event)} noValidate>
                <Grid
                    container
                    className={`${classes.editContainer} editContainer`}
                >
                    <Header
                        data={{ ...data }}
                        handleMeaureAddEditDialog={handleDialogClose}
                        editMeasure={editMeasure}
                        propertyPermission={propertyPermission}
                        publishPermission={publishPermission}
                        approvePermission={approvePermission}
                        isDisabled={((!propertyPermission?.is_edit) || (data.is_default || Boolean(data.term_id)))}
                        isMeasureLevel={isMeasureLevel}
                        connectionError={connectionError}
                    />
                    {
                        (data?.type === "custom" || data?.type === "semantic") &&
                        <React.Fragment>
                            <Grid item xs={12} className={classes.header} align="right">
                                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        {
                                            data?.term_id && selectedTerm?.id &&
                                            <Typography>
                                                {appConstants.labels.semanticTab.measureNavigation}
                                                <Button variant="text" className={classes.semanticTermlink} onClick={() => handleNavigation("term", selectedTerm)}>
                                                    {selectedTerm.name}
                                                </Button>
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <ToggleComponent
                                            isDisabled={(!propertyPermission?.is_edit || (data.id || data.is_default || Boolean(data.term_id)))}
                                            data={isMeasureLevel ? appConstants.toggle.connectionMeasureCategory : appConstants.toggle.conditionalAndQuery}
                                            selectedValue={data.category || appConstants.toggle.conditionalAndQuery[0].value}
                                            handleChange={(selectedValue) => changeMeasureCategory(selectedValue)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                data.category !== appConstants.toggle.connectionMeasureCategory[1].value &&
                                <Grid item xs={12} className={classes.ruleGreyContainer}>
                                    {
                                        data.category === appConstants.toggle.conditionalAndQuery[0].value &&
                                        <QueryBuilderComponent
                                            data={properties || {}}
                                            attributes={attributes}
                                            attribute_id={attribute_id}
                                            isNew={(!data.id || isCategoryChanged)}
                                            editMeasure={editMeasure}
                                            propertyPermission={propertyPermission}
                                            isDisabled={(!propertyPermission?.is_edit || (data.is_default || Boolean(data.term_id)))}
                                            enableValidate
                                            isValidate
                                        />
                                    }
                                    {
                                        data.category === appConstants.toggle.conditionalAndQuery[1].value &&
                                        <QueryComponent
                                            asset_id={asset_id}
                                            connection_id={data?.connection ?? data?.connection_id}
                                            name="query"
                                            data={query}
                                            onChange={editMeasure}
                                            disabled={!propertyPermission?.is_edit || Boolean(data.term_id)}
                                            enableValidate
                                            isAggregateQuery={data.is_aggregation_query}
                                            onCheckAggregateQuery={(value) => editMeasure("is_aggregation_query", value)}
                                            measureProperties={properties}
                                            editMeasure={editMeasure}
                                            showLimitConfig
                                            validateResult
                                            isMeasureLevel={isMeasureLevel}
                                            isValidate
                                        />
                                    }
                                    {
                                        data.category === appConstants.toggle.conditionalAndQuery[2].value &&
                                        <BehavioralComponent
                                            data={properties || {}}
                                            attributes={attributes}
                                            isNew={(!data.id || isCategoryChanged)}
                                            attribute_id={attribute_id}
                                            editMeasure={editMeasure}
                                            propertyPermission={propertyPermission}
                                        />
                                    }
                                    {
                                        data.category === appConstants.toggle.conditionalAndQuery[3].value &&
                                        <LookupComponent
                                            data={properties || {}}
                                            attributes={attributes}
                                            isNew={(!data.id || isCategoryChanged)}
                                            attribute_id={attribute_id}
                                            editMeasure={editMeasure}
                                            propertyPermission={propertyPermission}
                                            lookupTableHeaders={lookupTableHeaders}
                                            lookupTableOptions={lookupTableOptions}
                                            tableRows={resultData}
                                            lookupName={lookupName}
                                            onColumnChange={(columns) => onColumnsChange(columns)}
                                            onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                                            handleCellClickEvent={(key, value, item) => handleCellClickEvent(key, value, item)}
                                            handleActionsClickEvent={(data, actionName) => handleActionsClickEvent(data, actionName)}
                                            updateLookup={updateLookup}
                                            lookupOption={lookupOption}
                                            inputColumn={inputColumn}
                                            lookupMetadata={lookupMetadata}
                                            userPreferenceColumns={columns || []}
                                            isValidate
                                        />
                                    }
                                    {
                                        (data.category !== appConstants.toggle.conditionalAndQuery[1].value && data.category !== appConstants.toggle.connectionMeasureCategory[1].value) &&
                                        <ValidateMeasure
                                            type={data.category}
                                            data={data.category === appConstants.toggle.conditionalAndQuery[1].value ? query : properties}
                                            asset_id={asset_id}
                                            connection_id={data?.connection ?? data?.connection_id}
                                            isAggregateQuery={data.is_aggregation_query}
                                            onCheckAggregateQuery={(value) => editMeasure("is_aggregation_query", value)}
                                            measureProperties={properties}
                                            editMeasure={editMeasure}
                                            showLimitConfig
                                            validateResult
                                            isMeasureLevel={isMeasureLevel}
                                            isValidate={data.category !== appConstants.toggle.conditionalAndQuery[3].value}
                                        />
                                    }
                                </Grid>
                            }
                            {
                                data.category === appConstants.toggle.connectionMeasureCategory[1].value &&
                                <ComparisonComponent
                                    asset_id={asset_id}
                                    connection_id={data?.connection ?? data?.connection_id}
                                    name="comparison"
                                    data={data}
                                    isNew={(!data.id || isCategoryChanged)}
                                    editMeasure={editMeasure}
                                    propertyPermission={propertyPermission}
                                    enableValidate
                                    dataRequired={comparisonFieldMissing}
                                    isValidate
                                />
                            }
                        </React.Fragment>
                    }
                    {
                        data.category === appConstants.toggle.conditionalAndQuery[1].value &&
                        <ConditionalScoring
                            title={"Total Records Query"}
                            description={"Recommended to use when the total record is not depends on the asset."}
                            propertyName={"conditional_scoring"}
                            isMeasureLevel={isMeasureLevel}
                            measureProperties={{ ...properties, asset_id, term_id: data?.term_id ?? null, connection_id: data?.connection ?? data?.connection_id }}
                            editMeasure={editMeasure}
                            propertyPermission={propertyPermission}
                            isValidate
                        />
                    }
                    {
                        (!data.is_default && data.category !== appConstants.toggle.conditionalAndQuery[2].value && data.category !== appConstants.toggle.connectionMeasureCategory[1].value) &&
                        <ConditionalScoring
                            title={"Failed Rows Query"}
                            description={"Recommended to use for invalid conditions."}
                            propertyName={"failed_rows_query"}
                            isMeasureLevel={isMeasureLevel}
                            measureProperties={{ ...properties, asset_id, term_id: data?.term_id ?? null, connection_id: data?.connection ?? data?.connection_id }}
                            editMeasure={editMeasure}
                            propertyPermission={propertyPermission}
                            isValidate
                        />
                    }
                    {
                        data.category !== appConstants.toggle.connectionMeasureCategory[1].value &&
                        <Threshold data={threshold_constraints || {}} is_auto={is_auto} editMeasure={editMeasure} propertyPermission={propertyPermission} isDisabled={(data.category === "range")} />
                    }
                    {
                        data.id && data.category !== appConstants.toggle.connectionMeasureCategory[1].value &&
                        <Grid item xs={12}>
                            <MeasureChart name={data.name} ruleCategory={data.category} data={metric_detail.history_data || []} rule={data} />
                        </Grid>
                    }
                </Grid>
                <Grid className="dflex alignCenter flexEnd p-3">
                    <Button className="mr-2" variant="outlined" color="primary" size="small" onClick={() => handleDialogClose()}>
                        {appConstants.cancel}
                    </Button>
                    <LoaderButtonComponent
                        size={'small'}
                        isLoading={isLoading}
                        onClick={() => setFormSubmit(true)}
                    >
                        {appConstants.save}
                    </LoaderButtonComponent>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
}

// default props
RuleAdd.defaultProps = {
    classes: {}
};

// prop types
RuleAdd.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AddRuleStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(RuleAdd);