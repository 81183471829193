import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useSearchParams, useOutletContext, useLocation } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import _ from 'lodash';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Icons
import EqualizerIcon from '@mui/icons-material/Equalizer';

//  Import Components
import { TableComponent, DialogComponent, AreaChartComponent } from '../../components/index.js';
import EditMeasureDialog from './components/editDialog/index.jsx';
import MeasureFilter from './components/filter/index.jsx';
import Scheduler from '../../components/scheduler/index.jsx';

// Import Reducers
import {
    getAssetMeasureRequest, updateAssetMeasureProperty, updateStandAloneMeasureRequest, updateAssetMeasureRequest, exportAssetMeasureRequest, assetMeasureSorting,
    updateMeasureDetail, deleteAssetMeasureRequest, clearFilter, getMeasureRunStatusRequest, getMeasureRunStatusSuccess
} from '../../redux/reducer/measureReducer';
import { getDimensionRequest } from '../../redux/reducer/dimensionReducer';
import { navigate } from '../../redux/reducer/navigationReducer';
import { updateUserPreference } from '../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer';
import { updateAttributeMetadataRequest } from '../../redux/reducer/attributeReducer.js';
import { getDomainListRequest } from '../../redux/reducer/semanticReducer.js';
import { getApplicationsRequest } from '../../redux/reducer/applicationReducer.js';
import { getTagsRequest } from '../../redux/reducer/tagsReducer.js';
import { getTermsRequest } from '../../redux/reducer/termReducer';
import { updateVersionRequest } from '../../redux/reducer/versionReducer.js';
import { scheduleService } from '../../redux/service/scheduleService.js';

// Import Helpers
import { checkPermission, disableFlags, getUserPreference, permissionHeaders, prepareUpdateUserPreference, getMeasureColors, orderList } from '../../helpers/appHelpers';
import featureConstants from '../../constants/featureConstants.js';
import appConstants from '../../constants/appConstants.js';


function Measure(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const searchControllerRef = useRef();
    const tableRef = useRef();

    /**
     * Define Ref Context
     */
    const scrollRef = useOutletContext();

    /**
     * Define Redux
     */
    const { assetMeasures, assetLevelSearch: searchFilter, assetLevelLoading, loadMore, assetMeasureCount, exportLoading, statistics, reloadMeasures } = useSelector(({ measure }) => measure);
    const searchableDimensionList = useSelector(({ dimension }) => dimension.searchableDimensionList);
    const { permission, user } = useSelector((state) => state.auth);
    const measurePermission = checkPermission(permission, featureConstants.home.measures);
    const propertiesPermission = checkPermission(permission, featureConstants.assets.properties);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "homeMeasures", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "homeMeasures", "sorting");
    const selectedFilterTemplate = getUserPreference(user?.user_preference ?? {}, "tableFilter", "homeMeasures", "template");
    const customTemplates = getUserPreference(user?.user_preference ?? {}, "tableFilter", "homeMeasures", "templates");

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    let query_measure_id = searchParams.get("measure_id");
    const query_domain = searchParams.get("domain");
    const query_application = searchParams.get("application");
    const query_tag = searchParams.get("tag");

    if (location?.state?.filter) {
        query_measure_id = location?.state?.measure_id;
    }

    /**
     * Define State
     */
    const defaultSearchData = {
        "name": "",
        "description": "",
        "connection": "",
        "type": "",
        "level": "",
        "dimension": "",
        "asset": "",
        "attribute": "",
        "allow_score": "All",
        "is_drift_enabled": "All",
        "is_active": "Active",
        "is_positive": "All",
        "threshold_type": "All",
        "schema": "",
        "is_default": "All",
        "status": "All",
        "terms": [],
        "applications": [],
        "tags": [],
        "domains": [],
        "measure_id": ""
    };
    const measureSearch = searchFilter.search_by ? { ...searchFilter.search_by } : Object.assign({}, { ...defaultSearchData });
    const [openEditDialog, setOpeEditDialog] = useState(false);
    const [scheduler, setScheduler] = useState(null);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [measureTile, setMeasureTile] = useState(appConstants.measureTiles);
    const [isClearFilter, setClearFilter] = useState(false);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { searchableTagsList } = useSelector((state) => state.tags, shallowEqual);
    const { searchableTermsList } = useSelector((state) => state.term, shallowEqual);
    let statusChecker = null;

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Check measure running status
     * @param {*} versionDetail
     * @returns
     */
    const checkRunStatus = () => {
        dispatch(getMeasureRunStatusRequest());
    };

    /**
     *
     * Define Use Effects for measure run status checker
     */
    useEffect(() => {
        if (!statusChecker) {
            statusChecker = setInterval(() => checkRunStatus(), appConstants.statusCheckInterval);
        }
        return () => {
            if (statusChecker) {
                clearInterval(statusChecker);
                statusChecker = null;
            }
        };
    }, [dispatch]);

    /**
     * Set Sorting Value
     */
    useEffect(() => {
        if (tableSorting) {
            const requestParams = {
                sortBy: tableSorting?.sortBy ?? "",
                orderBy: tableSorting?.orderBy ?? "asc"
            };
            dispatch(assetMeasureSorting(requestParams));
        }
    }, []);


    /**
     * Validate Filters is Used
     * @param {*} params
     */
    const validateFiltersUsed = (params) => {
        // Validate Filter is Applied
        let compareSearchData = { ...params?.search_by };
        switch (compareSearchData?.template) {
            case "Monitoring":
                compareSearchData = {
                    ...compareSearchData,
                    "is_drift_enabled": defaultSearchData.is_drift_enabled
                };
                break;
            case "Scoring":
                compareSearchData = {
                    ...compareSearchData,
                    "allow_score": defaultSearchData.allow_score
                };
                break;
            case "Standalone":
                compareSearchData = {
                    ...compareSearchData,
                    "level": defaultSearchData.level,
                    "is_active": defaultSearchData.is_active
                };
                break;
            default:
                break;
        }
        delete compareSearchData?.template;
        setClearFilter(!_.isEqual(defaultSearchData, compareSearchData));
    };


    /**
     * Fetches the list of Measures
     * @param {*} params
     */
    const getMeasures = (params, clear = false, statistics = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        const requestParams = {
            ...searchFilter,
            ...params,
            statistics
        };
        dispatch(getAssetMeasureRequest({ params: requestParams, token, clear }));
        validateFiltersUsed(requestParams);
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value, key, property) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, key, property, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy, offset: 0
        };
        getMeasures(requestData, true);
        updatePreference({ sorting: { sortBy, orderBy } }, "table", "homeMeasures");
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        if (!scrollRef) {
            return;
        }
        if (!value && tableRef.current) {
            // Scroll the element horizontally to the left (initial position)
            tableRef.current.scrollLeft = 0;
        }
        scrollRef.scrollLeft = 0;
        const search_by = { ...measureSearch };
        search_by[key] = value;
        getMeasures({ search_by: search_by, offset: 0 }, true, true);
    };


    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreMeasures = () => {
        if (loadMore && !assetLevelLoading) {
            getMeasures({ offset: (searchFilter.offset + searchFilter.limit) });
        }
    };


    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (data) => {
        if (!data?.run_status || data?.run_status === 'Completed') {
            scheduleService.trigger({ "measure_id": data.id, job_type: "measure" }).then(() => {
                const measureStatus = [{ id: data.id, status: "Pending" }];
                dispatch(getMeasureRunStatusSuccess({ data: measureStatus }));
            }).catch(() => { });
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data?.connection_id || data?.connection] }));
        }
    };


    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestParams = {
            id: item.id,
            [key]: value
        };
        let changedProperties = [key];
        dispatch(updateAssetMeasureProperty(requestParams));
        if (key === "dimension") {
            value = value.length ? value[0].id : null;
            requestParams[key] = value;
        } else if (key === "upper_threshold" || key === "lower_threshold") {
            value = value !== "" ? value : 0;
            requestParams.drift_threshold = {
                upper_threshold: key === "upper_threshold" ? value : item.upper_threshold || 0,
                lower_threshold: key === "lower_threshold" ? value : item.lower_threshold || 0
            };
            changedProperties = ["drift_threshold"];
        }
        else if (key === "applications" || key === "domains") {
            if (item.level !== "measure" && item.version_id) {
                const ids = value?.map((item) => item.id);
                const property = (key === "applications") ? "application" : key;
                dispatch(updateVersionRequest({
                    id: item.version_id,
                    [property]: [...ids],
                    [`selected_${property}`]: value,
                    changed_properties: [property]
                }));
                return;
            }
            delete requestParams[key];
            key = (key === "applications") ? "application" : key;
            value = value?.map((item) => item.id);
            requestParams[key] = value;
        }
        else if (key === "term" || key === "tags") {
            if (!item.attribute_id) {
                return;
            }
            const requestParams = {
                attribute_id: item.attribute_id
            };

            if (key === "tags") {
                requestParams.selected_tags = value;
                value = value?.map((item) => item.id);
                requestParams[key] = value;
            } else if (key === "term") {
                let termId = '';
                if (value.length) {
                    termId = value[0].id;
                }
                requestParams.selected_term = value;
                requestParams.term = termId;
                requestParams.term_approval_id = termId;
                value = termId;
            }
            dispatch(updateAttributeMetadataRequest({ ...requestParams }));
            return;
        }
        requestParams.updated_properties = [...new Set([...changedProperties])];
        dispatch(updateStandAloneMeasureRequest(requestParams));
    };

    /**
     * Get Measures
     */
    useEffect(() => {
        if (!assetMeasures.length && (!selectedFilterTemplate || selectedFilterTemplate === "All") || query_measure_id || query_domain || query_application || query_tag) {
            const search_by = { ...measureSearch };

            if (query_measure_id) {
                search_by.measure_id = query_measure_id || '';
            }
            if (query_domain) {
                search_by.domain = query_domain || '';
            }
            if (query_application) {
                search_by.application = query_application || '';
            }
            if (query_tag) {
                search_by.tag = query_tag || '';
            }

            if (query_measure_id || query_domain || query_application || query_tag) {
                setTimeout(() => {
                    removeSearchParam();
                }, 0);
            }
            getMeasures({ search_by: search_by, offset: 0 }, true, true);
        }
    }, []);

    /**
     * Reload Measures When Bulk Edit Happens
     */
    useEffect(() => {
        if (reloadMeasures) {
            getMeasures({ offset: 0 }, true, true);
        }
    }, [reloadMeasures]);

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Dimension List
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
        // Get Domains List
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        // Get Applications List
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        // Get Tags List
        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
        // Get Terms List
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }
    }, [dispatch]);

    /**
     * Handle Edit Dialog
     */
    const handleEditDialog = () => {
        setOpeEditDialog(true);
    };

    /**
     * On Edit Measure
     * @param {*} params
     */
    const onEditMeasure = (params) => {
        const requestParams = {
            search_params: measureSearch,
            update_params: params
        };
        const changedProperties = Object.keys(params);
        const dimensionIndex = changedProperties.indexOf("dimension_id");
        if (dimensionIndex > -1) {
            changedProperties.splice(dimensionIndex, 1, "dimension");
        }
        requestParams.updated_properties = [...new Set([...changedProperties])];
        dispatch(updateAssetMeasureRequest(requestParams));
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, item) => {
        switch (key) {
            case "asset":
                if (item.asset_id) {
                    dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
                }
                break;
            case "name":
                const isAssetLevel = item?.attribute_id === "All" || item?.attribute_id === "" || !item?.attribute_id;
                const state = {
                    measure_id: item?.id ?? null,
                    alert_id: item?.id ?? null,
                    name: item?.name ?? '',
                    isAssetLevel: isAssetLevel && (item.level !== "measure"),
                    attribute_id: item?.attribute_id,
                    asset_id: item?.asset_id,
                    isMeasureLevel: (item.level === "measure"),
                    type: "measure",
                    showEdit: true,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [item.id] }));
                break;
            case 'connection_name':
                dispatch(navigate({ path: 'connector.asset', state: {}, params: [item.connection_type, item.connection_id] }));
                break;
            default:
                if (item.asset_id) {
                    dispatch(navigate({ path: 'assets.attributeProperties', state: key === "name" ? { 'section': 'measure' } : {}, params: [item.asset_id, item.attribute_id] }));
                }
                break;
        }
    };


    /**
     * Export Measure
     */
    const exportMeasure = () => {
        const requestParams = {
            ...searchFilter,
            fileName: "Export_Measure.csv"
        };
        dispatch(exportAssetMeasureRequest(requestParams));
    };

    /**
     * Handle Add Measure
     */
    const handleAddDialog = () => {
        dispatch(updateMeasureDetail({ category: "query", properties: { conditional_scoring: { is_enabled: true } } }));
        dispatch(navigate({ path: 'measure.add', state: { attribute_id: "", asset_id: "", isAssetLevel: false, isMeasureLevel: true }, params: [] }));
    };

    /**
     * Update Search
     * @param {*} template
     */
    const updateSearch = (template, clearFilter = false) => {
        let templateSearchData = { ...defaultSearchData };
        if (!template || ["All", "Monitoring", "Scoring", "Standalone"].includes(template)) {
            if (!clearFilter) {
                templateSearchData = { ...measureSearch };
            }

            switch (template) {
                case 'Scoring':
                    templateSearchData = {
                        ...templateSearchData,
                        "allow_score": "Active",
                        "is_drift_enabled": defaultSearchData.is_drift_enabled,
                        "level": defaultSearchData.level,
                        "is_active": defaultSearchData.is_active
                    };
                    break;
                case 'Monitoring':
                    templateSearchData = {
                        ...templateSearchData,
                        "is_drift_enabled": "Active",
                        "allow_score": defaultSearchData.allow_score,
                        "level": defaultSearchData.level,
                        "is_active": defaultSearchData.is_active
                    };
                    break;
                case 'Standalone':
                    templateSearchData = {
                        ...templateSearchData,
                        "level": 'measure',
                        "is_active": "Active",
                        "allow_score": defaultSearchData.allow_score,
                        "is_drift_enabled": defaultSearchData.is_drift_enabled
                    };
                    break;
                default:
                    if (template === "All") {
                        templateSearchData = {
                            ...templateSearchData,
                            "is_active": "Active",
                            "level": defaultSearchData.level,
                            "allow_score": defaultSearchData.allow_score,
                            "is_drift_enabled": defaultSearchData.is_drift_enabled
                        };
                    }
                    break;
            }
            templateSearchData = { ...templateSearchData, "template": template };
        } else {
            const customTemplate = customTemplates?.find((data) => data.template === template);
            templateSearchData = customTemplate?.filters ?? defaultSearchData;
        }
        const key = measureTile.find((data) => data.isSelected)?.key;
        if (key) {
            const params = key === "is_auto" ? { is_default: true } : { card_category: key, is_default: false };
            templateSearchData = { ...templateSearchData, ...params };
        }
        if ((measureSearch.measure_id) && !clearFilter) {
            templateSearchData.measure_id = measureSearch.measure_id || '';
        }
        if (query_domain) {
            templateSearchData.domain = query_domain;
        }
        if (query_application) {
            templateSearchData.application = query_application;
        }
        getMeasures({ search_by: templateSearchData, offset: 0 }, true, true);
    };

    /**
     * Clear Search Filters
     */
    const clearFilters = () => {
        updateSearch(selectedFilterTemplate, true);
    };


    /**
     * Get Memozied Table Options
     * @param {*} searchFilters
     * @returns
     */
    const getTableOptions = (searchFilters) => {
        const measureDropDownFilters = ["allow_score", "is_drift_enabled", "is_active", "is_positive", "threshold_type"];
        const options = [{ type: "clear", customFunction: clearFilters, isClearFilter: isClearFilter }, { type: "search", customFunction: null }, { type: "download", customFunction: exportMeasure, isLoading: exportLoading }, { type: "columns", customFunction: null }];
        if (measurePermission?.is_edit) {
            options.push({ type: "add", customFunction: handleAddDialog });
        }
        let isFilterApply = false;
        for (const key of Object.keys(searchFilters)) {
            if ((measureDropDownFilters.includes(key) && searchFilters[key] !== "All") || (searchFilters[key] !== "" && !measureDropDownFilters.includes(key))) {
                isFilterApply = true;
                continue;
            }
        }
        if (isFilterApply && measurePermission?.is_edit) {
            options.unshift({ type: "edit", customFunction: handleEditDialog });
        }
        return options;
    };
    const tableOptions = getTableOptions(measureSearch);

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns }, "table", "homeMeasures");
    };


    /**
     * Apply Filter Template
     */
    useEffect(() => {
        if (selectedFilterTemplate !== "All" && selectedFilterTemplate) {
            updateSearch(selectedFilterTemplate);
        }
    }, []);

    /**
     * OnSave Template Preference
     * @param {*} type
     * @param {*} value
     */
    const onSaveTemplatePreference = (type, value) => {
        if (type === "template" && value === "Schedule") {
            updatePreference({ [type]: value }, "tableFilter", "homeMeasures");
        }
        else if (type === "template" && value !== "Schedule") {
            updateSearch(value);
            updatePreference({ [type]: value }, "tableFilter", "homeMeasures");

        } else {
            const template = customTemplates || [];
            let selectedTemplate = value.template;
            if (type === "delete_template") {
                template.splice(value.index, 1);
                selectedTemplate = "All";
                updateSearch(selectedTemplate);
            } else {
                template.push({ filters: measureSearch, ...value });
            }
            updatePreference({ templates: template, template: selectedTemplate }, "tableFilter", "homeMeasures");
        }
    };


    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.measureDeleteMessage,
            data
        });
    };

    /**
     * Handle Actions
     * @param {*} data
     * @param {*} actionName
     */
    const onClickActions = (data, actionName, event) => {
        switch (actionName) {
            case 'schedule':
                setScheduler({ open: true, anchorElement: event.target, isAsset: false, isMeasure: true, connectionId: data?.connection || data?.connection_id, measureId: data.id });
                break;
            case 'delete':
                if (measurePermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit) {
                    onDelete(data);
                }
                break;
            case 'run':
                if ((measurePermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit)) {
                    handleJobTrigger(data);
                }
                break;
            default:
                break;
        }
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteAssetMeasureRequest({ id: showDialog?.data?.id, type: (type === "purge") }));
        dialogCancelEventHandle();
    };

    /**
     * Handle Tile Click Event
     */
    const handleTileClick = (key) => {
        const index = measureTile.findIndex((obj) => obj.key === key);
        let tiles = [...measureTile];
        const isSelected = tiles[index].isSelected;
        tiles = tiles.map((tile) => { return { ...tile, isSelected: false }; });
        tiles[index].isSelected = !isSelected;
        setMeasureTile([...tiles]);
        const params = key === "is_auto" ? { is_default: true } : { card_category: key, is_default: false };
        const searchBy = Object.assign({}, measureSearch);
        delete searchBy['card_category'];
        delete searchBy['is_default'];
        const search_by = { ...searchBy, ...params };
        getMeasures({ search_by, offset: 0 }, true);
    };

    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(searchableTermsList), [searchableTermsList]);

    /**
     * Filter tags
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsList), [searchableTagsList]);


    /**
     * Clear Filter Component unMount
     */
    useEffect(() => {
        return () => {
            const location = window.location.pathname;
            if (!location.includes("measure")) {
                dispatch(clearFilter());
            }
        };
    }, []);


    /**
     * Modify Asset Measures based on Valid, SortBy and OrderBy
     * @param {*} assetMeasures
     * @param {*} searchFilter
     * @returns
     */
    const modifiedAssetMeasures = (assetMeasures, searchFilter) => {
        const sortBy = searchFilter?.sortBy ?? '';
        const orderBy = searchFilter?.orderBy ?? 'asc';
        let filterData = assetMeasures.map((obj) => {
            return {
                ...obj,
                valid_rows: obj.is_positive ? obj.valid_rows : obj.invalid_rows,
                invalid_rows: obj.is_positive ? obj.invalid_rows : obj.valid_rows
            };
        });
        const records = ["total_rows", "valid_rows", "invalid_rows"];
        if (records.includes(sortBy)) {
            const isAllowScore = filterData.filter((item) => item.allow_score);
            const isNotAllowScore = filterData.filter((item) => !item.allow_score);
            filterData = orderBy === "asc" ? [..._.orderBy(isAllowScore, [sortBy], [orderBy]), ...isNotAllowScore] : [...isNotAllowScore, ..._.orderBy(isAllowScore, [sortBy], [orderBy])];
        }
        return filterData;
    };

    /**
     * Prepare Asset Measures
     */
    const filterMeasures = useMemo(() => modifiedAssetMeasures(assetMeasures, searchFilter), [assetMeasures, searchFilter]);

    /**
     * Measure Table Header
     */
    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: false,
            width: '15%',
            minWidth: "200px",
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name',
            clickable: true,
            showAlert: true,
            alertKey: "recent_alerts_count",
            classes: "name",
            showIssues: true,
            issueKey: "issues",
            isViewLink: measurePermission?.is_view
        },
        {
            key: 'score',
            name: 'Score',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: false,
            clickable: false,
            component: "score",
            allowScoreKey: "allow_score"
        },
        {
            key: 'description',
            name: 'Description',
            sorting: true,
            tooltip: true,
            width: '25%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'description'
        },
        {
            key: 'connection_name',
            name: 'Connection',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'connection',
            clickable: true,
            hideDefault: true
        },
        {
            key: 'connection_schema',
            name: 'Schema',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'schema'
        },
        {
            key: 'connection_database',
            name: 'Database',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'database'
        },
        {
            key: 'connection_account',
            name: 'Account',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'account'
        },
        {
            key: 'asset',
            name: 'Asset',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'asset',
            clickable: true,
            showCaps: true
        },
        {
            key: 'attribute',
            name: 'Attribute',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'attribute',
            clickable: true,
            showCaps: true
        },
        {
            key: 'alerts',
            name: 'Alerts',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            hideDefault: true
        },
        {
            key: 'is_default',
            name: 'Default',
            sorting: true,
            tooltip: false,
            width: '5%',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Yes', 'No'],
            searchKey: 'is_default',
            hideDefault: true
        },
        {
            key: 'issues',
            name: 'Issues',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'issues',
            hideDefault: true
        },
        {
            key: 'level',
            name: 'Level',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'level',
            hideDefault: true
        },
        {
            key: 'type',
            name: 'Type',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'type',
            hideDefault: true
        },
        {
            key: 'category',
            name: 'Category',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'category',
            hideDefault: true
        },
        {
            key: 'status',
            name: 'Status',
            width: "10%",
            align: "center",
            sorting: true,
            component: "status",
            isSearch: true,
            searchComponent: "select",
            list: ["All", "Pending", "Ready For Review", "Verified", "Deprecated"],
            renderList: ["Pending", "Ready For Review", "Verified"],
            searchKey: "status",
            defaultDisabled: true,
            isDisabled: (data, header) => disableFlags(data, header) || !measurePermission?.is_edit || !propertiesPermission?.is_edit,
            showRenderList: true,
            showCaps: true
        },
        {
            key: 'threshold_type',
            name: 'Threshold Type',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'select',
            searchKey: 'threshold_type',
            list: ['All', 'Auto', 'Manual'],
            hideDefault: true
        },
        {
            key: 'dimension',
            name: 'Dimension',
            sorting: true,
            tooltip: false,
            width: '10%',
            component: 'chips',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'dimension',
            isNotEditable: false,
            disableInput: false,
            isAdd: true,
            chipAddType: 'autocomplete',
            addLimitCount: 1,
            valueKey: 'name',
            haveColor: true,
            downloadArrayKey: "name",
            hideDefault: true,
            showCaps: true
        },
        { key: 'weightage', name: 'Weightage', width: "5%", sorting: true, tooltip: true, defaultValue: 100, datatype: "numeric", component: "textbox", isSearch: false, searchComponent: "text", searchKey: "weightage", hideDefault: true, isDisabled: (data, header) => disableFlags(data, header) || !measurePermission?.is_edit || !propertiesPermission?.is_edit, validators: ['required', 'minNumber:1', 'maxNumber:100'], errorMessages: ['Weightage is required', 'Minimum Weigtage is 1', 'Maxiumum Weigtage is 100'] },
        {
            key: 'upper_threshold',
            name: 'Upper Threshold',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'textbox',
            datatype: "integer",
            isSearch: false,
            placeholder: "Upper Threshold",
            hideDefault: true
        },
        {
            key: 'lower_threshold',
            name: 'Lower Threshold',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'textbox',
            datatype: "integer",
            isSearch: false,
            placeholder: "Lower Threshold",
            hideDefault: true
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_active',
            hideDefault: true,
            isDisabled: (data, header) => disableFlags(data, header)
        },
        {
            key: 'is_positive',
            name: 'Valid',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Valid', 'Invalid'],
            searchKey: 'is_positive',
            isDisabled: (data, header) => disableFlags(data, header),
            hideDefault: true
        },
        {
            key: 'allow_score',
            name: 'Scoring',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'allow_score',
            isDisabled: (data, header) => disableFlags(data, header),
            hideDefault: true
        },
        {
            key: 'is_drift_enabled',
            name: 'Monitor',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_drift_enabled',
            isDisabled: (data, header) => disableFlags(data, header),
            hideDefault: true
        },
        {
            key: 'applications',
            name: 'Applications',
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: applicationsList || [],
            searchKey: "applications",
            component: 'chips',
            valueKey: 'name',
            limit: 1,
            isAdd: true,
            chipAddType: 'autocomplete',
            haveColor: true,
            addText: "Add Application",
            hideDefault: true,
            showNA: true
        },
        {
            key: 'domains',
            name: 'Domains',
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: domainsList || [],
            searchKey: "domains",
            component: 'chips',
            valueKey: 'name',
            limit: 1,
            isAdd: true,
            chipAddType: 'autocomplete',
            haveColor: true,
            addText: "Add Domain",
            hideDefault: true,
            showNA: true
        },
        {
            key: 'term',
            name: 'Terms',
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: termsList || [],
            searchKey: "term",
            component: 'chips',
            limit: 2,
            valueKey: 'name',
            className: "termsChips",
            isAdd: true,
            chipAddType: 'autocomplete',
            addLimitCount: 1,
            addText: "Add Terms",
            enableAcceptActions: (data) => (data?.term_id && !data?.term_approval_id && Boolean(propertiesPermission?.is_edit)),
            hideDefault: true,
            showNA: true,
            renderComponent: (data) => data.level !== "measure" && data.asset_id && data.attribute_id,
            disabledColumn: (data) => data.level !== "measure"
        },
        {
            key: 'tags',
            name: 'Tags',
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: tagsList || [],
            searchKey: "tags",
            component: 'chips',
            valueKey: 'name',
            limit: 2,
            isAdd: true,
            chipAddType: 'autocomplete',
            haveColor: true,
            addText: "Add Tags",
            hideDefault: true,
            showNA: true,
            renderComponent: (data) => data.level !== "measure" && data.asset_id && data.attribute_id,
            disabledColumn: (data) => data.level !== "measure"
        },
        {
            key: 'next_run',
            name: 'Next Run',
            type: 'date',
            sorting: true,
            tooltip: true,
            width: '10%',
            isSearch: false,
            hideDefault: true
        },
        { key: 'last_runs', name: 'Last Result', width: "6%", showNA: true, component: "drift runs" },
        {
            key: 'invalid_rows',
            name: 'Invalid Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'invalid_rows',
            hideDefault: true
        },
        {
            key: 'valid_rows',
            name: 'Valid Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'valid_rows',
            hideDefault: true
        },
        {
            key: 'row_count',
            name: 'Total Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'row_count',
            hideDefault: true
        },
        {
            key: 'actions',
            name: 'Actions',
            width: '8%',
            align: 'center',
            showNA: true,
            actions: [
                { type: 'run', tooltip: true, tooltipText: 'Run Now', renderAction: (data) => data?.level === "measure", getToolTipText: (data) => { return (data?.data?.run_status && data?.data?.run_status !== 'Completed') ? data?.data?.run_status : 'Run Now'; } },
                { type: 'schedule', tooltip: true, tooltipText: 'Schedule', renderAction: (data) => (data?.level === "measure") },
                { type: 'delete', tooltip: true, tooltipText: 'Delete', renderAction: (data) => (data?.level === "measure" || (data?.level !== "measure" && data?.type === "custom")) }
            ]
        }
    ];


    return (
        <ValidatorForm onSubmit={() => null}>
            <Grid container className={classes.measureContainer}>
                <Grid container spacing={4} className="chartCardItemContainer">
                    {
                        measureTile.map((item) => (
                            <Grid className="chartCardItem" item xs key={item.name} onClick={() => handleTileClick(item.key)}>
                                <Grid id={`${item.key}_chart_container`} className={`${classes.measureCard} ${item.isSelected ? 'selected' : ''}`}>
                                    <Grid className={classes.cardData}>
                                        <Typography variant="h4">
                                            {(statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].count : 0}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {item.name}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.chart}>
                                        {
                                            ((statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].count : 0) ?
                                                <AreaChartComponent
                                                    containerId={`${item.key}_chart_container`}
                                                    size="small"
                                                    lineColor={getMeasureColors(item.key)}
                                                    toolTipName="Measures"
                                                    chartData={(statistics && item.key in statistics) && statistics[item.key] ? statistics[item.key].items : []} />
                                                :
                                                <Grid className={classes.nodataCardWrapper}>
                                                    <Grid className={classes.nodataCard}>
                                                        <EqualizerIcon />
                                                        <Typography variant="body1" className={classes.textSecondary}>
                                                            No Data Found
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid className={classes.measureTable}>
                    <MeasureFilter onSaveTemplatePreference={onSaveTemplatePreference} customTemplates={customTemplates} selectedTemplate={selectedFilterTemplate} />
                    <Grid item xs={12} className="pt-1">
                        <TableComponent
                            title={`Measures`}
                            description={`${assetMeasureCount.measure_count || 0} Measures, ${assetMeasureCount.asset_count || 0} Assets and ${assetMeasureCount.attribute_count || 0} Attributes`}
                            styleType="striped"
                            headers={permissionHeaders(tableHeaders, measurePermission)}
                            data={filterMeasures || []}
                            sortBy={searchFilter?.sortBy ?? ''}
                            orderBy={searchFilter?.orderBy ?? 'asc'}
                            searchData={measureSearch}
                            onClickSorting={onClickSorting}
                            onCompnentEvent={onCompnentEvent}
                            onHandleSearchEvent={onHandleSearchEvent}
                            selectComponentList={
                                {
                                    dimension: searchableDimensionList,
                                    tags: tagsList,
                                    term: termsList,
                                    applications: applicationsList,
                                    domains: domainsList
                                }
                            }
                            onCellClick={handleCellClickEvent}
                            onClickActions={onClickActions}
                            options={tableOptions}
                            height="calc(100vh - 170px)"
                            NoResultText="No Measures Found"
                            isLoading={assetLevelLoading}
                            onColumnChange={(columns) => onColumnsChange(columns)}
                            userPreferenceColumns={columns || []}
                            defaultShowSearch
                            tableRef={tableRef}
                            stickyHeader
                            onScrollEnd={loadMoreMeasures}
                        />
                    </Grid>
                </Grid>
                {
                    openEditDialog &&
                    <EditMeasureDialog
                        open={openEditDialog}
                        onEditMeasure={onEditMeasure}
                        onClose={() => setOpeEditDialog(false)}
                        selectedTemplate={selectedFilterTemplate}
                    />
                }

                {
                    scheduler &&
                    <Scheduler scheduleProps={scheduler} onClose={() => setScheduler(null)} disabled={(!measurePermission?.is_edit)} />
                }
                {
                    showDialog.open &&
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle} />
                }
            </Grid>
        </ValidatorForm>
    );
}

// default props
Measure.defaultProps = {
    classes: {}
};

// prop types
Measure.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Measure);