import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { nanoid } from 'nanoid';
import _ from 'lodash';

// Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import style from './style.jsx';

// Import component
import RuleComponent from './rule.jsx';
import RuleGroup from './ruleGroup.jsx';
import RuleAdd from './components/ruleAdd/index.jsx';
import RuleConnectors from './components/ruleConnector/index.jsx';

// Import Query Builder Config
import config from './config';
function QueryBuilderComponent(props) {
    /**
     * Define Props
     */
    const { classes, data, attributes, attribute_id, semanticRule, semanticDatatype, isNew, editMeasure, propertyPermission, isDisabled, isPattern } = props;
    const newRuleProps = {
        attribute: {
            id: '',
            label: '',
            has_numeric_values: false,
            datatype: ''
        },
        operator: {
            id: isPattern ? "match" : "isEqualTo",
            label: isPattern ? "matches" : "is equal to"
        },
        values: [""]
    };
    const newGroupsProps = {
        connector: 'and',
        not: false,
        rules: []
    };
    const [measure, setMeasure] = useState({});

    /**
     * Get Selected Attribute
     * @param {*} sAttribute
     * @returns
     */
    const getSelectedAttribute = (sAttribute) => {
        const selectedAttribute = attributes.find((a) => a.id === sAttribute);
        return selectedAttribute || {};
    };

    /**
     *  Get New Rule or Groups Props
     * @param {*} type
     * @returns
     */
    const getNewRuleProps = (type) => {
        const sAttribute = getSelectedAttribute(attribute_id);
        const nRuleProps = {
            ...newRuleProps,
            id: nanoid(),
            attribute: semanticRule || isPattern ? {} : {
                id: sAttribute.id,
                label: sAttribute.name,
                has_numeric_values: sAttribute.has_numeric_values || false,
                datatype: sAttribute?.derived_type?.toLowerCase() ?? ''
            }
        };

        const newRule = type === 'group' ? {
            ...newGroupsProps,
            id: nanoid(),
            rules: [
                {
                    ...nRuleProps
                }
            ]
        } : { ...nRuleProps };

        return newRule;
    };

    /**
     * Assign Rules To State
     */
    useEffect(() => {
        if (isNew || (!Object.keys(measure).length && !isNew)) {
            const rules = getNewRuleProps('group');
            setMeasure(rules);
        }
    }, [attribute_id, isNew]);

    /**
     * Update Measure
     */
    useEffect(() => {
        editMeasure('properties', measure);
    }, [measure]);

    /**
     * Bind data for the existing measure
     */
    useEffect(() => {
        if (data?.id && data?.id !== measure?.id) {
            setMeasure(data);
        }
    }, [measure, data]);

    /**
     * Find and Add New Rule By Id
     * @param {*} id
     * @param {*} rules
     */
    const addRuleById = (rules, id, type) => {
        const updatedRules = rules.map((item) => {
            if (item.id === id && item.rules) {
                item.rules = [
                    ...item.rules,
                    getNewRuleProps(type)
                ];
            }
            if (item.rules) {
                addRuleById(item.rules, id, type);
            }
            return item;
        });
        return updatedRules;
    };

    /**
     * Add New Rule
     * @param {*} type
     */
    const addRule = (type, id) => {
        const sRule = _.cloneDeep(measure);

        if (!id) {
            const uRule = {
                ...sRule,
                rules: [
                    ...sRule.rules,
                    getNewRuleProps(type)
                ]
            };

            setMeasure({ ...uRule });
        } else {
            const uRule = {
                ...sRule,
                rules: addRuleById(sRule?.rules ?? [], id, type)
            };

            setMeasure({ ...uRule });
        }
    };

    /**
     * Find and Delete Rule By Id
     * @param {*} rules
     * @param {*} id
     * @returns
     */
    const deleteRuleById = (rules, id) => {
        const uRules = rules.filter((x) => x.id !== id);
        const updatedRules = uRules.map((item) => {
            if (item.rules) {
                item.rules = item.rules.filter((x) => x.id !== id);
                deleteRuleById(item.rules, id);
            }
            return item;
        });
        return updatedRules;
    };

    /**
     * Delte Rule
     * @param {*} id
     */
    const deleteRule = (id) => {
        const sRule = _.cloneDeep(measure);
        const uRule = {
            ...sRule,
            rules: deleteRuleById(sRule?.rules ?? [], id)
        };
        setMeasure(uRule);
    };

    /**
     * Edit Values by Rule Id
     * @param {*} rules
     * @param {*} key
     * @param {*} value
     * @param {*} id
     * @returns
     */
    const editRuleById = (rules, key, value, id, valueIndex, resetValues = ['']) => {
        const updatedRules = rules.map((item) => {
            if (item.id === id) {
                if (valueIndex !== undefined && valueIndex !== null && valueIndex !== '') {
                    item[key][valueIndex] = value;
                } else {
                    item[key] = value;
                    if ((key === 'attribute' || key === 'operator') && item.values) {
                        if (key === 'attribute') {
                            item.values = item.values.map(() => '');
                            let datatype = value?.datatype ?? 'Text';
                            const hasNumerics = Boolean(value?.has_numeric_values);
                            if (semanticRule && semanticDatatype) {
                                datatype = semanticDatatype;
                            }
                            if (isPattern) {
                                datatype = 'pattern';
                            }
                            datatype = datatype ?? 'Text';
                            const filteredOperators = config.Operators.filter((operator) => operator.types.indexOf(datatype.toLowerCase()) >= 0 || (hasNumerics && operator.types.indexOf("text_numerics") >= 0));
                            const operator = filteredOperators.find((operator) => operator.id === item.operator?.id);
                            if (!operator) {
                                item.operator = {
                                    id: isPattern ? "match" : "isEqualTo",
                                    label: isPattern ? "matches" : "is equal to"
                                };
                                item.values = [""];
                            }
                        } else {
                            item.values = resetValues;
                        }
                    }
                }
            }
            if (item.rules) {
                item.rules = editRuleById(item.rules, key, value, id, valueIndex, resetValues);
            }
            return item;
        });
        return updatedRules;
    };

    /**
     * On Value Change Event
     * @param {*} key
     * @param {*} value
     * @param {*} id
     */
    const editRule = (key, value, id, valueIndex, resetValues = ['']) => {
        const sRule = _.cloneDeep(measure);
        if (!id) {
            sRule[key] = value;
            setMeasure(sRule);
        } else {
            const uRule = {
                ...sRule,
                rules: editRuleById(sRule?.rules ?? [], key, value, id, valueIndex, resetValues)
            };
            setMeasure(uRule);
        }
    };

    /**
     * Bind Rules
     * @param {*} rules
     * @returns
     */
    const bindRule = (rules) => {
        const isDeleteEnabled = rules && rules.length > 1;
        let uRules = rules && rules.length ? [...rules] : [];
        uRules = rules.map((rule) => {
            const uRule = {
                ...rule
            };
            if (!uRule.id) {
                uRule.id = nanoid();
            }
            return uRule;
        });

        const rulesDom = uRules.map((rule, index) => {
            return (
                <React.Fragment key={`${rule.id || 'root'}-${index}`}>
                    {
                        rule.connector
                            ?
                            <Grid className={classes.group} key={`groupComponent-${rule.id || index}`}>
                                <RuleGroup
                                    key={rule.id}
                                    classes={classes}
                                    data={rule}
                                    isNotEnable={rule.not || false}
                                    addRule={addRule}
                                    deleteRule={deleteRule}
                                    enableOrCondition={rule.rules && rule.rules.length > 1}
                                    editRule={editRule}
                                    isDisabled={isDisabled}
                                    propertyPermission={propertyPermission}>
                                    {
                                        rule.rules &&
                                        <Grid className={classes.ruleItems}>
                                            {bindRule(rule.rules || [])}
                                        </Grid>
                                    }
                                </RuleGroup>
                            </Grid>
                            :
                            <Grid className={classes.rule} key={`ruleComponent-${rule.id || index}`}>
                                <RuleComponent
                                    key={rule.id}
                                    classes={classes}
                                    data={
                                        {
                                            id: rule.id || '',
                                            attribute: rule.attribute || {},
                                            operator: rule.operator || {},
                                            values: rule.values || [""]
                                        }
                                    }
                                    isDeleteEnabled={isDeleteEnabled}
                                    operators={config.Operators}
                                    attribute_id={attribute_id}
                                    attributes={attributes}
                                    deleteRule={deleteRule}
                                    editRule={editRule}
                                    isDisabled={isDisabled}
                                    semanticRule={semanticRule}
                                    semanticDatatype={semanticDatatype}
                                    isPattern={isPattern}
                                    propertyPermission={propertyPermission}
                                />
                            </Grid>
                    }
                </React.Fragment>
            );
        });
        return rulesDom;
    };

    return (
        <Grid className={classes.rootComponent}>
            <Grid container justifyContent="space-between">
                <RuleConnectors
                    editRule={editRule}
                    enableOrCondition={measure.rules && measure.rules.length > 1}
                    selectedCondition={measure.connector}
                    isNotEnable={measure?.not || false}
                    propertyPermission={propertyPermission}
                    isDisabled={isDisabled} />
                {
                    propertyPermission?.is_edit &&
                    <RuleAdd addRule={addRule} isDisabled={isDisabled} />
                }
            </Grid>
            {bindRule(measure.rules || [])}
        </Grid>
    );
}

/**
 * Define Component Props
 */
QueryBuilderComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    attribute_id: PropTypes.string,
    attributes: PropTypes.array,
    semanticRule: PropTypes.bool,
    semanticDatatype: PropTypes.string,
    isNew: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isPattern: PropTypes.bool,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object
};

/**
 * Set Default Values
 */
QueryBuilderComponent.defaultProps = {
    classes: {},
    data: {},
    attribute_id: "",
    attributes: [],
    semanticRule: false,
    semanticDatatype: "Text",
    isNew: false,
    isDisabled: false,
    isPattern: false,
    editMeasure: () => { },
    propertyPermission: {}
};

export default withStyles((theme) => ({
    ...LayoutStyles(theme),
    ...style(theme)
}))(QueryBuilderComponent);