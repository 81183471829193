const urlConstants = {
    auth: {
        login: 'auth/login/',
        sso: 'sso/',
        logout: 'auth/logout/',
        forgotPassword: 'auth/forgotpassword/',
        resetPassword: 'auth/reset_password/',
        changePassword: 'auth/change_password/'
    },
    default: {
        url: 'default/'
    },
    organization: {
        url: 'organization/',
        getToken: 'organization/gettoken/',
        generateToken: 'organization/generatetoken/'
    },
    connection: {
        url: 'connection/',
        getTable: 'connection/assets/',
        getAttribute: 'connection/asset/schema/',
        executionLogs: 'connection/get_execution_logs/',
        catalogList: 'connection/connections_list/',
        getSchemas: 'connection/schema/',
        removeSchemas: 'connection/schema/',
        search: 'connection/search/',
        pipelineInfo: 'connection/pipeline_info/',
        adfFactories: 'connection/adf_factories/',
        connections: 'connection/connection/',
        connectionList: 'connection/connectionlist/',
        getDetailedLog: 'connection/getdetailedlog/',
        syncAssets: 'connection/sync_assets/'
    },
    asset: {
        url: 'asset/',
        get_status: 'asset/get_status/',
        timeline: 'measure/get_timeline/',
        dimension: 'measure/get_dimension/',
        extension: 'asset/extension/',
        lineage: 'asset/lineage/',
        searchable_list: 'asset/searchable_list/',
        assets_attributes: 'asset/assets_attributes/',
        assetList: 'asset/assetlist/'
    },
    version: {
        url: 'version/',
        by_asset: 'version/asset/'
    },
    favorite: {
        url: "favorites/"
    },
    attribute: {
        url: 'attribute/',
        by_verions: 'attribute/byversion/',
        metadata: 'attribute/metadata/',
        getInvalidRows: 'attribute/getinvalidrows/',
        select: 'attribute/select/',
        get_active_measure_categories: 'attribute/get_active_measure_categories',
        get_all: "attribute/get_all/",
        toggle_semantics: "attribute/toggle_semantics/",
        toggle_advanced_profile: "attribute/toggle_advanced_profile/",
        attributeList: 'attribute/attributelist/',
        attributeStatisticsPreview: 'attribute/statistics_preview/'
    },
    metrics: {
        url: 'metrics/',
        getQuality: 'metrics/get_quality/',
        getQualityMetrics: 'metrics/get_metrics/',
        getQualityMetricsDrift: 'metrics/get_drift_data/',
        getQualityMetricsThumbnail: 'metrics/get_thumbnail_data/',
        getQualityMetricsAlerts: 'metrics/alerts/',
        getQualityMetricsTrending: 'metrics/get_trending_chart/',
        getQualityDimensionMetrics: 'metrics/get_dimension_chart/',
        getComparisonMetric: 'metrics/comparison_metric/'
    },
    drift: {
        url: 'drift/',
        getAlerts: 'drift/alerts/',
        getAlertsCount: 'drift/get_alert_statistics/',
        assetAlerts: 'drift/asset_alerts/',
        export: 'drift/export/'
    },
    dirftAlert: {
        url: 'drift_alert/'
    },
    catalog: {
        url: 'catalog/',
        stats: 'stats/',
        suggestions: 'suggestions/',
        track: 'track/'
    },
    conversation: {
        url: 'conversations/',
        featured: 'conversations/pinned/'
    },
    measure: {
        url: 'measure/',
        updateCustomMeasure: 'measure/update_custom_measure/',
        getDefaultMeasure: 'measure/getdefaultmeasure/',
        getMeasureRules: 'measure/getmeasurerules/',
        pattern: 'measure/pattern/',
        enums: 'measure/enums/',
        assetMeasure: 'measure/all/',
        updateAssetMeasure: 'measure/updatemeasure/',
        getMeasurePreview: 'measure/getpreview/',
        exportAssetMeasure: 'measure/exportassetmeasure/',
        getMeasureLogs: 'measure/get_measure_logs/',
        syncProfileMeasure: 'measure/sync_measures/',
        validateMeasure: 'measure/validate_measure/',
        toggle_measures: 'measure/toggle_measures/',
        measureStatistics: 'measure/measure_statistics/',
        getStatus: 'measure/get_status/',
        exportFailedRows: 'measure/exportpreview/',
        getComparisonMeasure: 'measure/comparison_measure/',
        getMeasureRunStatus: 'measure/get_measure_run_status/'
    },
    semantic: {
        glossary: {
            url: 'glossary/',
            category: 'terms_category/',
            term: 'terms/',
            getGlossaryDetail: 'glossary/getglossarydetail/',
            getDefinition: 'semantic/term/getdefinition/',
            getTermDetail: 'semantic/term/detail/',
            statistics: 'glossary/statistics/',
            getGlossaryTermList: 'semantic/glossary/getglossarytermlist/',
            getTerms: 'terms/',
            attributeMapTerm: 'semantic/term/attributemapterm/',
            getTermAssets: 'semantic/term/gettermassets/',
            unLinkAttribute: 'semantic/term/unlinkattribute/',
            domains: 'glossary/domains/',
            domainAssets: 'glossary/domain_assets/',
            image: 'glossary/image/',
            tree: 'glossary/tree/',
            domainList: 'glossary/domainlist/',
            subDomainList: 'glossary/sub_domains/',
            terms: 'glossary/terms/',
            attributes: 'glossary/attributes/',
            quality: 'glossary/quality/',
            domainListFilter: 'glossary/domainlist_filter/'
        },
        category: {
            url: 'terms_category/'
        },
        terms: {
            url: 'terms/',
            getLinkedAssets: 'terms/assets/',
            linkAssets: 'terms/linkassets/',
            unLinkAsset: 'terms/unlinkasset/',
            mapped_list: 'terms/mapped/list/',
            mapped_list_filter: 'terms/mapped/list_filter/'
        },
        term_approval: {
            url: 'term_approval/'
        }
    },
    applications: {
        url: 'application/',
        assets: 'assets/',
        quality: 'quality/',
        filterApplications: 'application/list_filter/'
    },
    tags: {
        url: 'tags/',
        subTags: 'subtags/',
        attributes: 'attributes/',
        quality: 'quality/',
        search: 'tags/searchtag/',
        filterTags: 'tags/list_filter/',
        updateTagOrder: 'tags/tag-order/',
        updateSubTagOrder: 'tags/subtag-order/'
    },
    schedule: {
        url: 'schedule/',
        getSchedule: 'schedule/for_asset/',
        triggerSchedule: 'schedule/run_now/',
        triggerTask: 'schedule/trigger_task/',
        killJob: 'schedule/kill_job/',
        targetAsset: 'schedule/target_asset/',
        targetMeasure: 'schedule/target_measure/',
        scheduleList: 'schedule/schedule_list/',
        asstJobList: 'schedule/asset_job_list/',
        runDetails: 'schedule/latest_run_details/',
        measureScheduleList: 'schedule/measure_schedule_list/',
        reportScheduleList: 'schedule/report_schedule_list/'
    },
    security: {
        roles: 'roles/',
        roles_permission: 'roles_permission/',
        roles_stats: 'user/get_roles_stats/',
        user_permission: 'roles_permission/permission/',
        get_user_info: 'user/get_user/'
    },
    notifications: {
        url: 'notifications/'
    },
    user: {
        url: 'user/',
        users_stats: 'user/get_users_stats/',
        user_invite: 'user/invite/',
        thumbnail_list: 'user/thumbnail/',
        logout: 'user/logout/',
        userPreference: 'user/userpreference/'
    },
    configurations: {
        theme: {
            url: 'configurations/theme/',
            logo: 'configurations/theme/logo/'
        },
        general: {
            url: 'configurations/general/',
            general_schedule: 'configurations/general/general_schedule/'
        },
        banner: {
            url: 'configurations/banner/',
            getGeneralTheme: 'configurations/general/get_general_theme/'
        }
    },
    dimension: {
        url: 'dimension/'
    },
    issues: {
        url: 'issues/',
        statistics: 'issues/getstatistics/',
        getIssues: 'issues/list/',
        workLog: {
            url: 'worklog/'
        },
        comments: {
            url: 'comments/'
        },
        attachment: {
            url: 'attachment/'
        },
        watch: {
            url: 'watch/'
        },
        export: 'issues/export/'
    },
    baseMeasure: {
        url: 'basemeasure/',
        get_active_patterns: 'basemeasure/get_active_patterns/',
        getDashStatus: 'basemeasure/get_dashboard_status/',
        getNames: 'basemeasure/get_names/',
        getAutoMeasure: 'basemeasure/getautomeasure/'
    },
    usage: {
        url: 'usage/',
        run_query: 'usage/run_query/',
        query_history: 'usage/query_history/'
    },
    integration: {
        url: 'integration/',
        collibraAttributeTypes: 'integration/collibraattribute/',
        alationDataSource: 'integration/alationdatasource/'
    },
    dashboard: {
        url: 'dashboard/',
        applyFilter: 'dashboard/applyfilter/'
    },
    widget: {
        url: 'widget/',
        getWidget: 'widget/getwidgetdata/',
        updateLayout: 'widget/updatelayout/',
        widgetPreview: 'widget/widgetpreview/'
    },
    logs: {
        notificationLogs: 'notification_logs/',
        notificationStats: 'notification_logs/stats/',
        triggerTask: 'notification_logs/stats/'
    },
    versionHistory: {
        url: 'version_history/'
    },
    fields: {
        url: 'fields/',
        getTab: 'fields/gettab/',
        getGroup: 'fields/getgroup/'
    },
    fieldProperty: {
        url: 'fields_property/',
        getProperty: 'fields_property/get_property/',
        collapseGroup: 'fields_property/groupcollapse/',
        orderGroup: 'fields_property/order_group/'
    },
    importExport: {
        exportMetadata: 'import_export/exportmetadata/',
        importMetadata: 'import_export/importmetadata/',
        exportMeasure: 'import_export/exportmeasure/',
        importMeasure: 'import_export/importmeasure/',
        exportUsers: 'import_export/exportusers/',
        importUsers: 'import_export/importusers/'
    },
    userActivity: {
        url: 'useractivity/',
        userActivityJobs: 'useractivity/getjobs/'
    },
    reportMapping: {
        url: 'version_asset_mapping/',
        asset: 'version_asset_mapping/asset/'
    },
    license: {
        url: 'license/',
        sync: 'license/sync/'
    },
    reports: {
        url: 'reports/',
        assets: 'reports/assets/',
        columns: 'reports/columns/',
        preview_data: 'reports/preview_data/',
        getReportRunStatus: 'reports/get_report_run_status/',
        assets_filter: 'reports/filters/assets/',
        dashboard: 'reports/dashboard/',
        getReports: 'reports/reports/'
    },
    libraries: {
        url: 'libraries/',
        getReferences: 'libraries/getall/'
    }
};

export default urlConstants;