import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from 'react-redux';

// import styles
import profileStyles from "../style.jsx";
import { withStyles } from "@mui/styles";
import appConstants from "../../../../../constants/appConstants.js";
import { Grid } from "@mui/material";
import ProfileView from "../../../../../components/profileView/index.jsx";
import { isNumeric } from "../../../../../helpers/appHelpers.js";
// import { updateMeasureRequest } from "../../../../../redux/reducer/measureReducer.js";


const Distribution = (props) => {
    const { classes, propertiesPermission, onClickTrending, onToggleRules } = props;
    // const dispatch = useDispatch();

    /**
     * Get data from redux store
     */
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);
    const measures = useSelector((state) => state.measure.measures);
    const isnumericAttribute = isNumeric(metadata?.derived_type);
    const rowSize = isnumericAttribute ? 4 : 6;

    const onItemToggle = (event, item, index, type, measures) => {
        const measureFilter = appConstants.profile.measureGroups.find((item) => item.group === type);
        const selectedMeasureType = measureFilter?.measures?.find((measure) => measure.name === item?.name);
        let selectedMeasure = null;
        if (selectedMeasureType) {
            selectedMeasure = measures.find((item) => item.technical_name === selectedMeasureType?.technical_name);
        }
        if (!selectedMeasure || selectedMeasure?.disable_scoring) {
            return;
        }
        const requestParams = {
            is_positive: !selectedMeasure.is_positive,
            id: selectedMeasure.id
        };
        requestParams.updated_properties = ["is_positive"];
        // dispatch(updateMeasureRequest(requestParams));
    };

    /**
     * Get Active Measures
     * @param {The measure group name} propertyName
     * @returns
     */
    const getActiveMeasures = (propertyName) => {
        let profileData = metadata[propertyName];
        profileData = profileData ? profileData : [];
        profileData = JSON.parse(JSON.stringify(profileData));
        let measureFilters = [];
        if (propertyName) {
            const measureFilter = appConstants.profile.measureGroups.find((item) => item.key === propertyName);
            measureFilters = measureFilter?.measures || [];
        }
        for (const measure of profileData) {
            const selectedMeasureFilter = measureFilters.find((item) => item.name === measure.name);
            measure.isActive = false;
            if (selectedMeasureFilter) {
                const selectedMeasure = measures.find((item) => item.technical_name === selectedMeasureFilter.technical_name);
                measure.isActive = (selectedMeasure && selectedMeasure.is_active);
            }
        }
        return profileData;
    };

    return (
        <Grid container justifyContent={"flex-start"} columnSpacing={8} rowSpacing={4} className={classes.topSection}>
            <Grid item xs={6}>
                <ProfileView
                    title={appConstants.profile.completeness}
                    description={appConstants.profile.completenessDescription}
                    data={getActiveMeasures("completeness") || []}
                    propertiesPermission={propertiesPermission}
                    chartHeight={150}
                    onClickTrending={() => onClickTrending("distribution", null, "completeness")}
                    onItemToggle={(...params) => onItemToggle(...params, "completeness", measures)}
                    onToggleRules={(value) => onToggleRules("completeness", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.completeness > 0)}
                    showToggleViewIcon={false}
                    showMeasureLink
                />
            </Grid>
            <Grid item xs={6}>
                <ProfileView
                    title={appConstants.profile.uniqueness}
                    description={appConstants.profile.uniquenessDescription}
                    data={getActiveMeasures("uniqueness") || []}
                    propertiesPermission={propertiesPermission}
                    chartHeight={80}
                    onClickTrending={() => onClickTrending("distribution", null, "uniqueness")}
                    onItemToggle={(...params) => onItemToggle(...params, "uniqueness", measures)}
                    onToggleRules={(value) => onToggleRules("uniqueness", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.uniqueness > 0)}
                    showToggleViewIcon={false}
                    showMeasureLink
                />
            </Grid>
            <Grid item xs={rowSize}>
                <ProfileView
                    title={appConstants.profile.character}
                    description={appConstants.profile.characterDescription}
                    data={getActiveMeasures("character_distribution") || []}
                    propertiesPermission={propertiesPermission}
                    chartHeight={175}
                    onClickTrending={() => onClickTrending("distribution", null, "character")}
                    onToggleRules={(value) => onToggleRules("character", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.character > 0)}
                    showToggleViewIcon={false}
                    showMeasureLink
                    small={isnumericAttribute}
                />
            </Grid>
            <Grid item xs={rowSize}>
                <ProfileView
                    title={appConstants.profile.space}
                    description={appConstants.profile.spaceDescription}
                    data={getActiveMeasures("space_distribution") || []}
                    propertiesPermission={propertiesPermission}
                    chartHeight={150}
                    onClickTrending={() => onClickTrending("distribution", null, "space")}
                    onToggleRules={(value) => onToggleRules("space", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.space > 0)}
                    showToggleViewIcon={false}
                    showMeasureLink
                    small={isnumericAttribute}
                />
            </Grid>
            {
                isnumericAttribute &&
                <Grid item xs={rowSize}>
                    <ProfileView
                        title={appConstants.profile.numeric}
                        description={appConstants.profile.numericDescription}
                        data={getActiveMeasures("numeric_distribution") || []}
                        propertiesPermission={propertiesPermission}
                        chartHeight={100}
                        onClickTrending={() => onClickTrending("distribution", null, "numeric")}
                        onItemToggle={(...params) => onItemToggle(...params, "numeric", measures)}
                        onToggleRules={(value) => onToggleRules("numeric", value)}
                        isMeasureEnabled={Boolean(metadata?.attribute_measures?.numeric > 0)}
                        showToggleViewIcon={false}
                        showMeasureLink
                        small={isnumericAttribute}
                    />
                </Grid>
            }
        </Grid>
    );
};

// define default props
Distribution.defaultProps = {
    classes: {},
    propertiesPermission: {},
    onClickTrending: () => { },
    onToggleRules: () => { },
    attribute_id: ""
};

// define proptypes
Distribution.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object,
    onClickTrending: PropTypes.func,
    onToggleRules: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...profileStyles(theme)
    }),
    { withTheme: true }
) (Distribution);