import { createSlice } from '@reduxjs/toolkit';

// Define initial state for user reducer
const initialState = {
    isThemeChanged: false,
    isGeneralChanged: false,
    theme: {
        isLoading: false,
        id: '',
        logo: '',
        banner: [],
        tagline: '',
        app_title: '',
        copyright: '',
        theme: {},
        logoAction: null
    },
    general: {
        isLoading: false,
        semantics: {},
        storage: {},
        reporting: {},
        anomaly: {},
        versioning: {},
        scoring: {
            values: [],
            quadrant: 4
        },
        system: {},
        notifications: {
            is_active: true,
            limit: 10,
            frequency: 'Hourly'
        },
        profile: {
            is_active: true,
            limit: "None",
            sampling: "Full",
            all: false,
            count: 25
        },
        isLoaded: false,
        semantic_next_run: null
    },
    banner: {
        isLoaded: false,
        list: [],
        selectedBanners: []
    },
    version: null
};

// create theme slice for redux state
const configurationsSlice = createSlice({
    name: 'configurations',
    initialState: { ...initialState },
    reducers: {
        themeDetailRequest(state) {
            state.theme.isLoading = true;
            state.isThemeChanged = false;
        },
        themeDetailSuccess(state, action) {
            state.theme.isLoading = false;
            state.theme = {
                ...state.theme,
                ...action.payload?.data ?? {}
            };
        },
        themeDetailFailure(state) {
            state.theme.isLoading = false;
        },
        themeUpdateRequest(state) {
            state.theme.isLoading = true;
        },
        themeUpdateSuccess(state, action) {
            if (state.theme.file_to_upload) {
                delete state.theme.file_to_upload;
            }
            state.theme.logoAction = null;
            state.theme.logo = action.payload.data.logo;
            state.theme.isLoading = false;
            state.isThemeChanged = false;
        },
        themeUpdateFailure(state) {
            state.theme.isLoading = false;
        },
        updateThemeInReducer(state, action) {
            state.theme = { ...state.theme, ...action.payload };
            state.isThemeChanged = true;
        },
        generalSettingsRequest(state) {
            state.general.isLoading = true;
            state.isGeneralChanged = false;
        },
        generalSettingsSuccess(state, action) {
            state.general.isLoading = false;
            state.general.isLoaded = true;
            state.general = {
                ...state.general,
                ...action.payload?.data ?? {}
            };
        },
        generalSettingsFailure(state) {
            state.general.isLoading = false;
        },
        updateGeneralInReducer(state, action) {
            const { category, data, subProperty } = action.payload;
            if (category === 'quadrant') {
                const quad_index = state.general.scoring.quadrant;
                const index_val = parseInt(Object.keys(data)[0]);
                const new_val = [...state.general.scoring.values];
                new_val[quad_index - 2][index_val][subProperty] = data[index_val];
                if (subProperty === 'to') {
                    new_val[quad_index - 2][index_val + 1].from = data[index_val];
                }
                state.general.scoring.values = [...new_val];
            } else {
                state.general[category] = { ...state.general[category], ...data };
            }
            state.isGeneralChanged = true;
        },
        generalUpdateRequest(state) {
            state.general.isLoading = true;
        },
        generalUpdateSuccess(state) {
            state.general.isLoading = false;
            state.isGeneralChanged = false;
        },
        generalUpdateFailure(state) {
            state.general.isLoading = false;
        },
        createBannerRequest() { },
        createBannerSuccess(state, action) {
            const { id, banner } = action.payload.data;
            state.banner.list.push({ id: id, banner: banner });
        },
        createBannerFailure() { },
        getBannerRequest() {
        },
        getBannerSuccess(state, action) {
            state.banner.isLoaded = true;
            state.banner.list = [
                ...state.banner.list,
                ...action.payload?.data ?? []
            ];
        },
        getBannerFailure(state) {
            state.banner.isLoaded = false;
        },
        deleteBannerRequest() { },
        deleteBannerSuccess(state, action) {
            const bannersList = [...state.banner.list];
            const index = bannersList.findIndex((banner) => banner.id === action.payload);
            bannersList.splice(index, 1);
            state.banner.list = [...bannersList];
        },
        deleteBannerFailure() { },
        generalScheduleRequest() { },
        generalScheduleSucess(state, action) {
            state.general = {
                ...state.general,
                semantic_next_run: action.payload?.data?.semantics_next_run,
                reporting_next_run: action.payload?.data?.reporting_next_run
            };
        },
        generalScheduleFailure() { },
        getGeneralThemeRequest() { },
        getGeneralThemeSucess(state, action) {
            state.banner = {
                ...state.banner,
                selectedBanners: action.payload?.banner?.length > 0 ? [...action.payload.banner] : []
            };
            state.theme = { ...state.theme, ...action.payload.theme };
            state.version = { ...state.version, ...action.payload.version };
        },
        getGeneralThemeFailure() { }
    }
});

export const {
    themeDetailRequest,
    themeDetailSuccess,
    themeDetailFailure,
    themeUpdateRequest,
    themeUpdateSuccess,
    themeUpdateFailure,
    updateThemeInReducer,
    generalSettingsRequest,
    generalSettingsSuccess,
    generalSettingsFailure,
    updateGeneralInReducer,
    generalUpdateRequest,
    generalUpdateSuccess,
    generalUpdateFailure,
    createBannerRequest,
    createBannerSuccess,
    createBannerFailure,
    getBannerRequest,
    getBannerSuccess,
    getBannerFailure,
    deleteBannerRequest,
    deleteBannerSuccess,
    deleteBannerFailure,
    generalScheduleRequest,
    generalScheduleSucess,
    generalScheduleFailure,
    getGeneralThemeRequest,
    getGeneralThemeSucess,
    getGeneralThemeFailure
} = configurationsSlice.actions;
export default configurationsSlice.reducer;