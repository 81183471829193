const style = (theme) => ({
    dargField: {
        marginTop: '20px !important'
    },
    dragBox: {
        border: `1px dashed ${theme.palette.greyshades.darkgrey}`,
        background: `${theme.palette.greyshades.tablegrey}80`,
        borderRadius: 4,
        padding: '10px 20px'
    },
    dragContainer: {
        maxHeight: 'calc(100vh - 500px)',
        paddingRight: 8,
        overflowY: 'auto',
        marginTop: 20
    },
    nonLimitDragContainer: {
        maxHeight: 'calc(100vh - 410px)',
        paddingRight: 8,
        overflowY: 'auto',
        marginTop: 20
    }
});

export default style;