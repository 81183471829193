const style = () => ({
    closeIcon: {
        marginLeft: '20px !important',
        '& svg': {
            width: '22px',
            height: '22px'
        }
    },
    weightageContainer: {
        '& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-input': {
            height: '10px !important',
            width: '120px'
        },
        '& .MuiFormHelperText-root': {
            textAlign: 'left',
            marginRight: '0px',
            fontSize: '12px',
            marginLeft: '5px'
        }
    }
});
export default style;