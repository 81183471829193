// Import API Helper
import { getRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Widget
 * @returns
 */
async function getWidget(id) {
    const response = await getRequest(`${urlConstants.widget.url}${id}/`);
    return response;
}

/**
 * Get Widget
 * @returns
 */
async function getPreview(params) {
    const response = await postRequest(`${urlConstants.widget.widgetPreview}`, params);
    return response;
}

/**
 * Get Hierachy Data
 * @param {*} params
 * @returns
 */
async function getHierachyData(params) {
    const response = await postRequest(`${urlConstants.widget.widgetPreview}`, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const customWidgetService = {
    getWidget, getPreview, getHierachyData
};