import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Typography, Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Component
import CollibraMappingAddRow from './mappingAddRow.jsx';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PlusIcon } from '../../../assets/svg';

// Import Actions
import { updateConfiguration, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';

// Import Constant
import appConstants from '../../../constants/appConstants.js';

import { Crypto } from '../../../helpers/index.js';


function CollibraMapping(props) {
    /**
     * Define Props
     */
    const { classes, configuration } = props;
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const dispatch = useDispatch();
    const defaultMapping = { dq_column: "", collibra_column: "", isNew: true };
    const [mapping, setMapping] = useState([]);
    const [addNewField, setAddNewField] = useState(false);
    const [newMapField, setMapField] = useState(null);
    const encryptDecrypt = new Crypto();

    /**
     * Redux Store
     */
    const { collibraAttributeTypes } = useSelector(({ integration }) => integration);

    /**
     * OnUpdate Config
     * @param {*} value
     */
    const onUpdateConfig = (value) => {
        const requestParams = {
            mapping: value
        };
        dispatch(updateConfiguration(requestParams));
        const params = {
            config: { ...configuration, username: encryptDecrypt.encrypt(configuration.username), password: encryptDecrypt.encrypt(configuration.password), mapping: value },
            channel: channel.id,
            id: channelId,
            type: "api",
            has_mapping: true
        };
        dispatch(updateConfigurationRequest(params));
    };

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     * @param {*} item
     * @param {*} index
     */
    const onChange = (property, value, item, index) => {
        if (value) {
            if (property === "collibra_column") {
                item.collibra_type_id = value.id;
            } else {
                const column = appConstants.collibraMapColumns.find((column) => column.name === value);
                item.catalog_key = column.id;
                item.type = column.type;
                item.priority = column.priority;
            }
            item[property] = property === "dq_column" ? value : value.label;
            if (index !== null) {
                const mappingFields = [...mapping];
                mappingFields[index] = { ...item };
                setMapping([...mappingFields]);
                onUpdateConfig(mappingFields);
            } else {
                setMapField({ ...item });
            }
        }
    };

    /**
     * On Add New Item
     */
    const onAddNewItem = () => {
        setAddNewField(true);
        setMapField({ ...defaultMapping });
    };

    /**
     * On Action Events
     * @param {*} action
     * @param {*} index
     */
    const onAction = (action, index) => {
        if (action === "cancel") {
            setAddNewField(false);
        } else {
            const mappingFields = [...mapping];
            if (action === "create") {
                const field = { ...newMapField };
                delete field.isNew;
                mappingFields.push({ ...field });
                setAddNewField(false);
            } else {
                mappingFields.splice(index, 1);
            }
            setMapping([...mappingFields]);
            onUpdateConfig(mappingFields);
        }
    };

    /**
     * Get Filter Columns
     * @returns
     */
    const getDQColumns = (mapping) => {
        const defaultDQColumns = appConstants.collibraMapColumns.map((column) => column.name);
        const mappingList = mapping.map((obj) => obj.dq_column);
        return defaultDQColumns.filter((obj) => !mappingList.includes(obj));
    };

    /**
     * Get Collibra Filter Columns
     * @returns
     */
    const getCollibraColumns = (mapping, collibraColumns) => {
        const mappingList = mapping.map((obj) => obj.collibra_column);
        return collibraColumns.filter((obj) => !mappingList.includes(obj.name));
    };


    /**
     * Set Default Mappings
     */
    useEffect(() => {
        if (!mapping.length) {
            const mappingFields = configuration.mapping.length ? [...configuration.mapping] : [{ ...defaultMapping }];
            setMapping([...mappingFields]);
        }
    }, []);

    const dqColumns = useMemo(() => getDQColumns(mapping), [mapping]);
    const collibraColumns = useMemo(() => getCollibraColumns(mapping, collibraAttributeTypes), [mapping, collibraAttributeTypes]);


    return (
        <ValidatorForm noValidate onSubmit={() => { }} className="w-100">
            <Accordion>
                <AccordionSummary className="accordian-header" expandIcon={<ArrowDropDownIcon />} >
                    <Typography variant="h5" className="capitalize">
                        Mapping
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className={classes.mappingContainer}>
                        <Grid align="right">
                            <IconButton onClick={() => onAddNewItem()}>
                                <PlusIcon />
                            </IconButton>
                        </Grid>
                        <Grid container className="mb15">
                            <Grid item xs={5}>
                                <Typography variant="h6">
                                    DQ Column
                                </Typography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={5}>
                                <Typography variant="h6">
                                    Collibra Column
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            addNewField &&
                            <CollibraMappingAddRow
                                column={newMapField}
                                onAction={(action) => onAction(action)}
                                onChange={(property, value) => onChange(property, value, newMapField, null)}
                                dqColumns={dqColumns}
                                collibraColumns={collibraColumns}
                            />
                        }
                        {
                            mapping.map((column, index) =>
                                <CollibraMappingAddRow
                                    key={`column_${index}`}
                                    column={column}
                                    onAction={(action) => onAction(action, index)}
                                    onChange={(property, value) => onChange(property, value, column, index)}
                                    dqColumns={dqColumns}
                                    collibraColumns={collibraColumns}
                                />
                            )
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </ValidatorForm>
    );
}

// default props
CollibraMapping.defaultProps = {
    classes: {},
    configuration: {},
    onSave: () => { }
};

// prop types
CollibraMapping.propTypes = {
    classes: PropTypes.object,
    configuration: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CollibraMapping);