import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

//  Import Styles
import PreviewCodeStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { StringToHTML } from '../../../../../components/index.js';

// Import Images
import { CloseIcon, CopyIcon } from '../../../../../assets/svg/index.js';

// Import Actions
import { displyAlert } from '../../../../../redux/reducer/alertReducer';

function PreviewSourceCodeComponent(props) {

    /**
     * Define Props
     */
    const { classes, source_code, closeDialog } = props;
    const dispatch = useDispatch();

    /**
     * OnClose
     */
    const onClose = () => {
        closeDialog();
    };

    /**
     * Copy Connection Path to Keyboard
     */
    const copyToClipBoard = (copyData) => {
        navigator.clipboard.writeText(copyData);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    return (
        <Grid item xs={12}>
            <Grid item xs={12} className={classes.QueryContainer}>
                <Grid className="dflex alignCenter spaceBetween header pb5">
                    <Typography variant="h2">
                        Source Code
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid className="codeContainer">
                    <Grid className="codeActions">
                        <Tooltip title={'Copy'} arrow>
                            <IconButton className="copyBtn" onClick={() => copyToClipBoard(source_code)}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <StringToHTML data={source_code.replace(/^\s+|\s+$/g, '')} />
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
PreviewSourceCodeComponent.defaultProps = {
    classes: {},
    source_code: '',
    closeDialog: () => { }
};

// prop types
PreviewSourceCodeComponent.propTypes = {
    classes: PropTypes.object,
    source_code: PropTypes.string,
    closeDialog: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.source_code, nextProps.source_code)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export As Memo Component
 */
export const PreviewCode = withStyles(
    (theme) => ({
        ...PreviewCodeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(PreviewSourceCodeComponent, areEqual));