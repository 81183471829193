/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography, Box, Tooltip, Avatar, Grid, CircularProgress, Divider, Paper, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from "@mui/material/Chip";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Images
import { ChevDownIcon, ClearFilter } from '../../assets/svg/index.js';

// Import Helpers
import { stringAvatar } from '../../helpers/appHelpers';

// Import Component
import { CircularScoreComponent } from '../../components/index.js';
import AssetTypeIconComponent from '../../components/assetTypeIcon/index.jsx';


function AutoCompleteComponent(props) {

    /**
     * Define Props
     */
    const { classes, name, selectedValue, data, placeholder, onChange, onClose, variant, labelKey, customOption,
        validators, errorMessages, onBlur, freeSolo, create, onInputChange, disableClearable, renderType, blurOnSelect,
        dataTypeIcon, autoSelect, disabled, fullWidth, label, multiple, style, loading, compareKey, select_all, showTooltip, noOutline, draggable, ...rest } = props;
    const filter = createFilterOptions();
    let filteredValues = data || [];

    // Define State
    const [values, setValues] = useState(selectedValue);
    const [inputFocused, setInputFocused] = useState(false);

    /**
     * Get Option Value
     * @param {*} option
     * @returns
     */
    const getOptionValues = (option) => {
        if (option.derivedType) {
            return {
                label: option?.name ?? '',
                id: option.id,
                datatype: option.derivedType ? option.derivedType.toLowerCase() : 'text',
                values: option.values || (option?.valueRequired === true ? [''] : [])
            };
        } else if (option.types) {
            return {
                label: option?.name ?? '',
                id: option.id,
                values: option.values || (option?.valueRequired === true ? [''] : [])
            };
        } else if (option.name) {
            return {
                label: option?.name ?? '',
                id: option.id
            };
        }
        return option;
    };


    /**
     * Check Selected Value and Options are Equal
     * @param {*} option
     * @param {*} value
     * @returns
     */
    const isOptionEqualToValue = (option, value) => {
        let optionValue = option;
        let selValue = value;
        const key = compareKey || labelKey;

        if (optionValue instanceof Object && key) {
            optionValue = labelKey ? option[key] : option?.label ?? '';
        }

        if (selValue instanceof Object) {
            selValue = value[key];
        }

        optionValue = optionValue || '';
        selValue = selValue || '';

        return optionValue === selValue;
    };

    /**
     * Get Option Label
     * @param {*} option
     * @returns
     */
    const getOptionLabel = (option) => {
        if (option instanceof Object) {
            if (labelKey && option[labelKey]) {
                return option[labelKey];
            }
            else if (option.name) {
                return option.name;
            }
        }
        return option;
    };

    /**
     * Return Render Content
     * @param {*} props
     * @param {*} option
     * @returns
     */
    const getRenderOption = (props, option) => {
        const option_value = getOptionLabel(option);
        if (renderType === 'attributes') {
            return (
                <Box component="li" {...props} style={{ position: 'relative' }}>
                    <Typography
                        variant="body2"
                        className={classes.attributeTypeIcon}
                        style={{ background: option.tag_color ? `${option.tag_color}30` : '' }}
                    >
                        {option.derived_type && option.derived_type.charAt(0) || 'T'}
                    </Typography>
                    <Tooltip title={option_value} arrow placement="right">
                        <Typography
                            variant="body1"
                            noWrap
                            className={`${option?.alerts > 0 ? classes.alertColor : ''} ${classes.listText}`}
                        >
                            {option_value}
                        </Typography>
                    </Tooltip>
                </Box>
            );
        } else if (renderType === 'users') {
            return (
                <Box component="li" sx={{ '& > img': { marginRight: '8px', flexShrink: 0 } }} {...props}>
                    <Avatar {...stringAvatar(option.name, option?.avatar ?? '')}
                        sx={{ width: 26, height: 26, marginRight: '10px', textTransform: 'uppercase' }}
                        onClick={(event) => { event.stopPropagation(); }} />
                    {option_value}
                </Box>
            );
        } else if (renderType === 'queryBuilderAttributes') {
            return (
                <Box component="li" {...props}>
                    <Typography
                        variant="body2"
                        className={classes.attributeTypeIcon}
                    >
                        {option.derived_type && option.derived_type.charAt(0) || 'T'}
                    </Typography>
                    <Tooltip title={option_value} arrow>
                        <Typography variant="body1" noWrap className={classes.listText}>
                            {option_value}
                        </Typography>
                    </Tooltip>
                </Box>
            );
        } else if (renderType === 'chips') {
            const toolTipName = option.customToolTipName || option_value;
            return (
                <Box component="li" className={"chipDropDownValue"} {...props}>
                    {
                        dataTypeIcon &&
                        <Typography
                            variant="body2"
                            className={classes.attributeTypeIcon}
                        >
                            {option.derived_type && option.derived_type.charAt(0) || 'T'}
                        </Typography>
                    }
                    <Tooltip title={toolTipName} arrow>
                        <Typography
                            className={classes.chipDropDownValue}
                            variant="body1">
                            {option_value}
                        </Typography>
                    </Tooltip>
                </Box>
            );
        } else if (renderType === 'reportMap') {
            let toolTip_value = option_value;
            if (option?.connection_name) {
                toolTip_value = `${toolTip_value} (${option?.connection_name})`;
            }

            return (
                <Box component="li" {...props} key={option?.id ?? option_value} sx={{ height: "45px" }} >
                    {
                        option.type &&
                        <Grid className="connectorIcon mr-1 dflex alignCenter">
                            <AssetTypeIconComponent asset_type={option.type || 'asset'} />
                        </Grid>
                    }
                    <Tooltip title={toolTip_value} arrow>
                        <Typography variant="body1" noWrap>
                            {option_value}
                        </Typography>
                    </Tooltip >
                    {
                        option.dqscore && option.dqscore >= 0 &&
                        <CircularScoreComponent
                            size={35}
                            value={option.dqscore || 0}
                            showPercentage
                            noLink
                            showValue />
                    }
                </Box >
            );
        } else if (renderType === 'comparison' || showTooltip) {
            return (
                <Box component="li" {...props} key={option?.id ?? option_value} sx={{ height: "45px" }}>
                    <Tooltip title={option_value} arrow>
                        <Typography variant="body1" noWrap className={classes.listText}>
                            {option_value}
                        </Typography>
                    </Tooltip>
                </Box>
            );
        }

        return (
            <Box component="li" {...props} key={option?.id ?? option_value} sx={{ height: "45px" }}>
                <Typography variant="body1" noWrap className={classes.listText}>
                    {option_value}
                </Typography>
            </Box>
        );
    };

    /**
     * Select / Clear Based On
     * @param {*} type
     */
    const filterSelectAllValues = (event, type = 'select') => {
        if (type === 'clear') {
            let selected_values = [];
            if (compareKey) {
                selected_values = selectedValue.filter((objFromA) => {
                    return !filteredValues.find((objFromB) => {
                        return objFromA.id === objFromB.id;
                    });
                });
            } else {
                selected_values = selectedValue.filter((objFromA) => {
                    return !filteredValues.find((objFromB) => {
                        return objFromA === objFromB;
                    });
                });
            }
            onChange(event, selected_values);
        } else {
            let selected_values = [];
            if (compareKey) {
                selected_values = filteredValues.filter((objFromA) => {
                    return !selectedValue.find((objFromB) => {
                        return objFromA.id === objFromB.id;
                    });
                });
            } else {
                selected_values = filteredValues.filter((objFromA) => {
                    return !selectedValue.find((objFromB) => {
                        return objFromA === objFromB;
                    });
                });
            }
            onChange(event, [...selectedValue, ...selected_values]);
        }
    };

    /**
     * Validate Select All
     * @returns
     */
    const validateSelectAll = () => {
        if (filteredValues && filteredValues.length) {
            let s_length = -1;
            if (compareKey) {
                s_length = filteredValues.filter((item1) => selectedValue.some((item2) => (item2[compareKey] === item1[compareKey])))?.length ?? 0;
            } else {
                s_length = filteredValues.filter((item1) => selectedValue.some((item2) => (item2 === item1)))?.length ?? 0;
            }
            return s_length === filteredValues.length;
        }
        return false;
    };


    /**
     * Add Select All Option
     * @returns
     */
    const addSelectAllOption = () => {
        if (select_all && filteredValues && filteredValues?.length > 0) {
            return (
                <React.Fragment>
                    <Box
                        onMouseDown={(event) => event.preventDefault()}
                        pl={1.5}
                        py={0.5}
                    >
                        <Grid container spacing={2} justifyContent={'space-between'}>
                            <Grid item>
                                <FormControlLabel
                                    onClick={
                                        (event) => {
                                            event.preventDefault();
                                            filterSelectAllValues(event, event.target.checked ? 'select' : 'clear');
                                        }
                                    }
                                    label="Select all"
                                    control={<Checkbox id="select-all-checkbox" checked={validateSelectAll()} />}
                                />
                            </Grid>
                            <Grid item align={'right'} className={'pr-1'}>
                                <Tooltip title={'Clear'} arrow>
                                    <IconButton
                                        key={`icon-clear`}
                                        className={'mt8'}
                                        onClick={
                                            (event) => {
                                                event.preventDefault();
                                                filterSelectAllValues(event, 'clear');
                                            }
                                        }
                                        disabled={!(selectedValue && selectedValue?.length > 0)}
                                    >
                                        <ClearFilter />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                </React.Fragment >
            );
        }
        return null;
    };

    // Handle Draggable Autocomplete Focus
    const handleDraggableFocus = () => {
        setInputFocused(true);
    };

    const handleDraggableBlur = () => {
        setInputFocused(false);
    };

    /**
     * Handle On Drag End
     * @param {*} result
     * @returns
     */
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const nitems = Array.from(values);
        const [reorderedItem] = nitems.splice(oldIndex, 1);
        nitems.splice(newIndex, 0, reorderedItem);
        setValues(nitems);
        onChange('columns', nitems);
    };

    const onDeleteItem = (event, value) => {
        event.preventDefault();
        event.stopPropagation();
        const nitems = Array.from(values);
        const updatedItems = nitems.filter((item) => item !== value);
        setValues(updatedItems);
        onChange('columns', updatedItems);
    };

    /**
     * RenderTag option if draggable
     * @returns
     */
    const renderDraggableTags = (value, getTagProps) => {
        const SortableItem = SortableElement(({ index, value }) => {
            const onMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
            };
            return (
                <Chip
                    className={classes.draggableChip}
                    label={value}
                    onMouseDown={onMouseDown}
                    onDelete={(event) => onDeleteItem(event, value)}
                />
            );
        });

        const SortableList = SortableContainer(({ items, getTagProps }) => {
            return (
                <div>
                    {
                        items.map((value, index) => (
                            <SortableItem key={value} index={index} value={value} getTagProps={getTagProps} />
                        ))
                    }
                </div>
            );
        });

        if (values.length !== selectedValue.length) {
            setValues(value);
        } else if (values.length === 1) {
            setValues(selectedValue);
        }

        const displayedValues = inputFocused ? value : value.slice(0, 1);
        const remainingCount = value.length - displayedValues.length;

        return (
            <div className={classes.draggableContainer}>
                <SortableList
                    items={displayedValues}
                    axis="xy"
                    transitionDuration={0}
                    onSortEnd={({ oldIndex, newIndex }) => onSortEnd({ oldIndex, newIndex, getTagProps })}
                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                    getTagProps={getTagProps}
                    distance={4}
                />
                {
                    remainingCount > 0 && !inputFocused && (
                        <div>
                            {`+${remainingCount}`}
                        </div>
                    )
                }
            </div>
        );
    };

    /**
     * Updating the renderTags property
     * if draggable is true
     */
    let drest = { ...rest };
    if (draggable) {
        drest = {
            ...rest,
            renderTags: renderDraggableTags,
            onFocus: handleDraggableFocus,
            onBlur: handleDraggableBlur
        };
    }

    return (
        <Autocomplete
            id="attribute-search"
            clearOnEscape
            clearOnBlur
            freeSolo={freeSolo}
            autoSelect={autoSelect}
            style={style}
            popupIcon={<ChevDownIcon />}
            classes={
                {
                    option: classes.option,
                    paper: classes.customPaper
                }
            }
            className={classes.autoCompleteContainer}
            value={selectedValue || null}
            selectOnFocus
            handleHomeEndKeys
            options={
                customOption ? data : data.map((option) => (
                    getOptionValues(option)
                ))
            }
            isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
            filterOptions={
                (options, params) => {
                    const filtered = filter(options, params);
                    if (create) {
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option[labelKey]);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                [labelKey]: `Add "${inputValue}"`
                            });
                        }
                    }
                    filteredValues = filtered;
                    return filtered;
                }
            }
            onChange={
                (event, newValue) => {
                    let value = newValue;
                    if (newValue && newValue.inputValue) {
                        value = newValue.inputValue;
                        if (value.includes('Add "')) {
                            value = value.split('Add "')[1].split('"')[0];
                        }
                    }
                    onChange(event, value);
                }
            }
            onClose={() => onClose()}
            renderInput={
                (params) => (
                    <TextValidator
                        {...params}
                        name={name}
                        label={label}
                        fullWidth={fullWidth}
                        variant={variant}
                        validators={validators}
                        errorMessages={errorMessages}
                        value={selectedValue || ''}
                        placeholder={placeholder}
                        onBlur={() => (onBlur ? onBlur() : null)}
                        disabled={disabled}
                        className={`noOutline ${noOutline ? classes.outLineNone : null}`}
                        InputProps={
                            {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }
                        }
                    />
                )
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            onInputChange={onInputChange}
            disableClearable={disableClearable}
            renderOption={getRenderOption}
            blurOnSelect={blurOnSelect}
            multiple={multiple}
            disabled={disabled}
            fullWidth={fullWidth}
            PaperComponent={
                (paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                        <Paper {...restPaperProps}>
                            {addSelectAllOption()}
                            {children}
                        </Paper>
                    );
                }
            }
            {
            ...drest
            }
        />
    );
}


// default props
AutoCompleteComponent.defaultProps = {
    classes: {},
    name: "",
    selectedValue: "",
    variant: "standard",
    placeholder: "Select Attribute",
    data: [],
    labelKey: "label",
    compareKey: "",
    customOption: false,
    validators: [],
    errorMessages: [],
    freeSolo: true,
    create: false,
    disableClearable: false,
    renderType: "",
    blurOnSelect: false,
    dataTypeIcon: false,
    autoSelect: false,
    disabled: false,
    fullWidth: true,
    label: "",
    multiple: false,
    style: {},
    loading: false,
    onInputChange: () => { },
    onBlur: () => { },
    onChange: () => { },
    onClose: () => { },
    select_all: false,
    showTooltip: false,
    noOutline: false,
    draggable: false
};

// prop types
AutoCompleteComponent.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    placeholder: PropTypes.string,
    variant: PropTypes.string,
    data: PropTypes.array,
    labelKey: PropTypes.string,
    compareKey: PropTypes.string,
    customOption: PropTypes.bool,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    freeSolo: PropTypes.bool,
    create: PropTypes.bool,
    disableClearable: PropTypes.bool,
    inputValue: PropTypes.string,
    blurOnSelect: PropTypes.bool,
    renderType: PropTypes.string,
    dataTypeIcon: PropTypes.bool,
    autoSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiple: PropTypes.bool,
    style: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onInputChange: PropTypes.func,
    onBlur: PropTypes.func,
    select_all: PropTypes.bool,
    showTooltip: PropTypes.bool,
    noOutline: PropTypes.bool,
    draggable: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AutoCompleteComponent);