import { combineReducers } from 'redux';

import alertReducer from './alertReducer';
import authReducer from './authReducer';
import defaultReducer from './defaultReducer';
import organizationReducer from './organizationReducer';
import connectionReducer from './connectionReducer';
import navigationReducer from './navigationReducer';
import connectorReducer from './connectorReducer';
import assetReducer from './assetReducer';
import attributeReducer from './attributeReducer';
import catalogReducer from './catalogReducer';
import conversationReducer from './conversationReducer';
import measureReducer from './measureReducer';
import semanticReducer from './semanticReducer';
import scheduleReducer from './scheduleReducer';
import securityReducer from './securityReducer';
import userReducer from './userReducer';
import configurationsReducer from './configurationsReducer';
import applicationReducer from './applicationReducer';
import tagsReducer from './tagsReducer';
import dimensionReducer from './dimensionReducer';
import domainsReducer from './domainsReducer';
import versionReducer from './versionReducer';
import metricReducer from './metricReducer';
import driftReducer from './driftReducer';
import issuesReducer from './issuesReducer';
import termReducer from './termReducer';
import baseMeasureReducer from './baseMeasureReducer';
import usageReducer from './usageReducer.js';
import integrationReducer from './integrationReducer';
import dashboardReducer from './dashboardReducer';
import logsReducer from './logsReducer';
import customWidgetReducer from './customWidgetReducer';
import fieldsReducer from './fieldsReducer';
import fieldPropertyReducer from './fieldPropertyReducer';
import importExportReducer from './importExportReducer';
import userActivityReducer from './userActivityReducer';
import extensionReducer from './extensionReducer';
import licenseReducer from './licenseReducer';
import reportsReducer from './reportsReducer';
import librariesReducer from './librariesReducer';

const MainReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    default: defaultReducer,
    organization: organizationReducer,
    connection: connectionReducer,
    navigation: navigationReducer,
    connector: connectorReducer,
    asset: assetReducer,
    attribute: attributeReducer,
    catalog: catalogReducer,
    conversation: conversationReducer,
    measure: measureReducer,
    semantic: semanticReducer,
    schedule: scheduleReducer,
    security: securityReducer,
    user: userReducer,
    configurations: configurationsReducer,
    applications: applicationReducer,
    tags: tagsReducer,
    dimension: dimensionReducer,
    domains: domainsReducer,
    version: versionReducer,
    metric: metricReducer,
    drift: driftReducer,
    issues: issuesReducer,
    term: termReducer,
    baseMeasure: baseMeasureReducer,
    integration: integrationReducer,
    usage: usageReducer,
    dashboard: dashboardReducer,
    logs: logsReducer,
    customWidget: customWidgetReducer,
    fields: fieldsReducer,
    fieldProperty: fieldPropertyReducer,
    importExport: importExportReducer,
    userActivity: userActivityReducer,
    extension: extensionReducer,
    license: licenseReducer,
    reports: reportsReducer,
    libraries: librariesReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logoutSuccess') {
        state = undefined;
    }
    return MainReducer(state, action);
};
export default rootReducer;