const style = (theme) => ({
    rightPanel: {
        width: '480px',
        paddingLeft: '24px',
        height: 'calc(100vh - 390px)',
        overflowY: 'auto'
    },
    rightPanelInner: {
        padding: '12px',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important',
        borderRadius: '4px',
        height: '100%'
    },
    linkModel: {
        cursor: 'pointer',
        '&:hover': {
            '& .MuiTypography-root': {
                color: `${theme.palette.secondary.main} !important`
            }
        }
    },
    impactedAssets: {
        background: '#fff',
        boxShadow: '0px 1px 10px #00000014',
        borderRadius: '4px',
        marginBottom: '5px',
        padding: '10px',
        '& img': {
            height: "40px",
            width: "40px",
            marginRight: "10px"
        },
        '& .assetsItem': {
            maxWidth: "calc(100% - 60px)"
        },
        '& .assetTitle': {
            maxWidth: "calc(100% - 40px)"
        }
    }
});
export default style;