import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import { getWidgetRequest, getWidgetSuccess, getWidgetFailure, getWidgetPreviewRequest, getWidgetPreviewSuccess, getWidgetPreviewFailure } from '../reducer/customWidgetReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { customWidgetService } from '../service';

/**
 * Get Widget
 * @returns
 */
const getWidget = (action$) => action$.pipe(
    ofType(getWidgetRequest),
    mergeMap(({ payload }) => {
        return from(customWidgetService.getWidget(payload)).pipe(
            concatMap((res) => { return of(getWidgetSuccess(res)); }),
            catchError((error) => { return of(getWidgetFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Widget
 * @returns
 */
const getPreview = (action$) => action$.pipe(
    ofType(getWidgetPreviewRequest),
    mergeMap(({ payload }) => {
        return from(customWidgetService.getPreview(payload)).pipe(
            concatMap((res) => { return of(getWidgetPreviewSuccess(res)); }),
            catchError((error) => { return of(getWidgetPreviewFailure(error?.response?.data ?? "")); })
        );
    })
);

// Export All Application Functions
export const customWidgetEpic = [getWidget, getPreview];