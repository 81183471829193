import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Grid, IconButton, Typography } from '@mui/material';
import { SearchComponent, PopOverComponent, NoResultComponent } from '../../../../../../components/index.js';

// Import Images
import { ChevDownIcon } from '../../../../../../assets/svg/index.js';

//  Import Styles
import LayoutStyles from '../../../../../../layouts/style.jsx';
import Style from '../../style.jsx';

// Import Actions
import { selectDashboard } from '../../../../../../redux/reducer/dashboardReducer';
import DashboardList from './list.jsx';

function SelectDashboard(props) {

    const { classes } = props;
    const dispatch = useDispatch();
    const { list, id: dashboardId } = useSelector(({ dashboard }) => dashboard);

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState("");

    /**
     * Close Popup
     */
    const handleDashboardClose = () => {
        setAnchorEl(null);
    };

    /**
     * On Change Dashboard
     * @param {*} value
     */
    const onChangeDashboard = (value) => {
        dispatch(selectDashboard(value));
        handleDashboardClose();
    };

    /**
     * Handle Popup
     * @param {*} event
     */
    const handleSelectDashboard = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Get Selected Dashboard
     * @param {*} selectedItem
     * @param {*} dashboards
     * @returns
     */
    const getSelectedDashboard = (selectedItem, dashboards) => {
        return dashboards.find((item) => item.id === selectedItem);
    };

    /**
     * Filter Dashboard
     * @param {*} dashboard
     * @param {*} searchText
     * @returns
     */
    const filterDashboard = (dashboard, searchText) => {
        if (searchText.trim()) {
            dashboard = dashboard.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase()));
        }
        return dashboard;
    };

    const selectedDashboard = useMemo(() => getSelectedDashboard(dashboardId, list), [dashboardId, list]);
    const filterList = useMemo(() => filterDashboard(list, search), [list, search]);
    const open = Boolean(anchorEl);


    return (
        <Fragment>
            <Grid item className="inlineFlex pl-15 pointer" onClick={(event) => handleSelectDashboard(event)}>
                <Typography variant="h5" component="h4" className="dflex alignCenter">
                    {selectedDashboard?.name || ""}
                </Typography>
                <IconButton className={classes.dashboardSelectBtn}>
                    <ChevDownIcon />
                </IconButton>
            </Grid>
            <PopOverComponent
                anchorEl={anchorEl}
                onClose={handleDashboardClose}
                open={open}>
                <Grid className={classes.searchConatiner}>
                    <SearchComponent
                        placeholder="Search"
                        value={search}
                        onChange={(value) => setSearch(value)} />
                </Grid>
                {
                    filterList.length ?
                        <Grid className={classes.dashboardSelectList}>
                            {
                                filterList.map((item) => (
                                    <DashboardList
                                        item={item}
                                        key={item.id}
                                        selectedDashboard={selectedDashboard}
                                        onChangeDashboard={onChangeDashboard} />
                                ))
                            }
                        </Grid> :
                        <NoResultComponent height="150" title="No Users Found" />
                }
            </PopOverComponent>
        </Fragment>
    );
}

// default props
SelectDashboard.defaultProps = {
    classes: {}
};

// prop types
SelectDashboard.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SelectDashboard);