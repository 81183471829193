import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: '',
	message: '',
    delay: 4000
};

const alertSlice = createSlice({
    name: 'alert',
    initialState: { ...initialState },
    reducers: {
        displyAlert(state, action) {
            state.type = action.payload.type;
            state.message = action.payload.message;
            state.delay = action.payload.delay || state.delay;
        },
        clearAlert(state) {
            state.type = '';
            state.message = '';
        }
    }
});

export const { displyAlert, clearAlert } = alertSlice.actions;
export default alertSlice.reducer;