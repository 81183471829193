import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent, AutoCompleteComponent } from '../../../components/index.js';


//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import TalendIcon from '../../../assets/img/connectors/talend.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';


// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState,
    getPipelineInfoRequest
} from '../../../redux/reducer/connectionReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';


function Talend(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'talend',
        description: '',
        api_key: '',
        endpoint: '',
        project: [],
        environment: "",
        workspace: ""
    });
    const {
        id,
        type,
        name,
        description,
        api_key,
        endpoint,
        project,
        environment,
        workspace
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, saveIsLoading, pipelineInfo } = useSelector((state) => state.connection);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getPipelineInfoRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);


    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.api_key = encryptDecrypt.decrypt(credentialDetails?.api_key ?? "");
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
        if (property !== "name" && property !== "description") {
            setIsAuthenticationChanged(true);
        }
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                api_key: encryptDecrypt.encrypt(api_key),
                endpoint: endpoint,
                project: project,
                environment: environment,
                workspace: workspace
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(false);
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };


    /**
     * Filter workspace by environment
     * @param {*} data
     */
    const getWorkspaceByEnvironment = (data, environment) => {
        let workspaces = data?.workspaces ?? [];
        if (environment) {
            workspaces = workspaces.filter((obj) => obj.environment_id === environment);
        }
        return workspaces || [];
    };
    const filterWorkspaces = useMemo(() => getWorkspaceByEnvironment(pipelineInfo, environment), [environment]);

    /**
     * Get AutocompleteValue
     * @param {*} type
     * @param {*} autocompleteValue
     * @returns
     */
    const getAutocompleteValue = (type, autocompleteValue) => {
        let value = "";
        if (autocompleteValue) {
            if (type === "environment" && pipelineInfo?.environments) {
                value = pipelineInfo?.environments.find((environment) => environment.id === autocompleteValue);
            }
            if (type === "project" && pipelineInfo?.projects) {
                value = pipelineInfo?.projects.find((project) => project.id === autocompleteValue);
            }
            if (type === "workspace" && pipelineInfo?.workspaces) {
                value = pipelineInfo?.workspaces.find((workspace) => workspace.id === autocompleteValue);
            }
        }
        return value;
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={TalendIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.talend.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.talend
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="form_text_endpoint"
                                name={'endpoint'}
                                value={endpoint}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.endpoint}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.endpoint
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_api_key"
                                name={'api_key'}
                                value={api_key}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.apiKey}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.apiKey
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        {
                            detailById?.is_valid &&
                            <Fragment>
                                <Grid item xs={5} >
                                    <AutoCompleteComponent
                                        id="environment"
                                        name={'environment'}
                                        label={appConstants.labels.connector.environments}
                                        variant="standard"
                                        value={getAutocompleteValue("environment", environment)}
                                        onChange={(_, value) => handleTextBoxChanges('environment', value ? value.id : value)}
                                        data={pipelineInfo.environments || []}
                                        fullWidth
                                        placeholder="Select Environment"
                                    />
                                </Grid>
                                <Grid item xs={5} className="pl-10">
                                    <AutoCompleteComponent
                                        id="project"
                                        name={'project'}
                                        label={appConstants.labels.connector.projects}
                                        variant="standard"
                                        value={getAutocompleteValue("project", project)}
                                        onChange={(_, value) => handleTextBoxChanges('project', value ? value.id : value)}
                                        data={pipelineInfo.projects || []}
                                        fullWidth
                                        placeholder="Select Project"
                                    />
                                </Grid>
                                {
                                    pipelineInfo.environments &&
                                    <Grid item xs={5}>
                                        <AutoCompleteComponent
                                            id="workspace"
                                            name={'workspace'}
                                            label={appConstants.labels.connector.workspaces}
                                            variant="standard"
                                            value={getAutocompleteValue("workspace", workspace)}
                                            onChange={(_, value) => handleTextBoxChanges('workspace', value ? value.id : value)}
                                            data={filterWorkspaces || []}
                                            fullWidth
                                            placeholder="Select Workspace"
                                        />
                                    </Grid>
                                }
                            </Fragment>
                        }
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Talend.defaultProps = {
    classes: {}
};

// prop types
Talend.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Talend);