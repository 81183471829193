const style = (theme) => ({
    DomainDetailPageContainer: {
        paddingTop: 10,
        '& .domainDetailTitle': {
            paddingBottom: 15
        },
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    leftContent: {
        paddingRight: 50,
        display: 'flex',
        flexDirection: 'column !important',
        // justifyContent: 'space-between',
        textAlign: 'left'
    },
    tagsPageContainer: {
        paddingBotton: '32px',
        '& .loaderContainer': {
            paddingLeft: '32px'
        }
    },
    tabsRootContainer: {
        background: '#fff',
        padding: '24px 30px 30px',
        '& .btnTabsInline  .MuiTab-root': {
            marginRight: '1px',
            borderRadius: '0px'
        },
        '& .btnTabsInline.MuiTabs-root': {
            minHeight: '30px'
        },
        '& .cardsSection': {
            '& .loaderContainer': {
                paddingLeft: '24px'
            }
        }
    },
    termsCard: {
        borderRadius: 2,
        boxShadow: '0px 1px 8px #00000021',
        background: '#fff',
        height: '100%',
        padding: '18px',
        '& .assetTypeIcon': {
            width: '25.21px',
            display: 'flex',
            '& svg': {
                width: '20px'
            }
        }
    },
    assetsText: {
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.greyshades.darkgrey} !important`,
        '& span.assetDot': {
            background: theme.palette.greyshades.lightgrey,
            height: '6px',
            width: '6px',
            borderRadius: '30px',
            margin: '5px 10px'
        }
    },
    typeLabel: {
        float: "left !important",
        color: `${theme.palette.secondary.main} !important`,
        background: theme.palette.greyshades.lightgrey,
        padding: '0px 4px',
        fontSize: '14px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textTransform: 'capitalize !important',
        flexShrink: 0,
        textAlign: "center",
        minWidth: '15px'
    },
    addUser: {
        '& .addUserGrid': {
            marginTop: '20px'
        }
    },
    tabsHeaderSection: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    }
});
export default style;