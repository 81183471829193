const style = (theme) => ({
    settingsPageContainer: {
        background: '#ECEFF2'
    },
    pageTitle: {
        paddingBottom: "24px",
        fontWeight: '400 !important'
    },
    tabsSection: {
        // marginTop: "70px !important"
    },
    tabsBody: {
        background: theme.palette.primary.contrastText,
        height: 'calc(100vh - 135px)',
        overflowY: 'auto',
        paddingBottom: '24px'
    }
});
export default style;