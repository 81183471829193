import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';

//  Import Styles
import style from './style.jsx';
import LayoutStyle from '../../../../layouts/style.jsx';

// Import Images
import { CreateIssues } from '../../../../assets/svg/index.js';


const IssueActionComponent = (props) => {

    /**
     * Define Props
     */
    const { classes, issueId, driftId, issueNumber, status, onActionPerform, isDisabled } = props;

    if (!driftId) {
        return null;
    }


    return (
        <Grid>
            {
                issueId ?
                    <Typography className={classNames(classes.issueNumber, status?.toLowerCase() === 'resolved' ? 'resolved' : null)} onClick={(event) => (!isDisabled ? onActionPerform("select", issueId) : event.stopPropagation())}>
                        {issueNumber}
                    </Typography> :
                    <Tooltip title="Create Issues" arrow>
                        <IconButton className={`${classes.issuesIcon} p5`} onClick={(event) => (!isDisabled ? onActionPerform("add") : event.stopPropagation())}>
                            {!isDisabled && <CreateIssues />}
                        </IconButton>
                    </Tooltip>
            }
        </Grid>
    );
};

// default props
IssueActionComponent.defaultProps = {
    classes: {},
    issueId: "",
    driftId: '',
    issueNumber: "",
    status: "",
    onActionPerform: () => { },
    isDisabled: false

};

// prop types
IssueActionComponent.propTypes = {
    classes: PropTypes.object,
    issueId: PropTypes.string,
    onActionPerform: PropTypes.func,
    driftId: PropTypes.string,
    issueNumber: PropTypes.string,
    status: PropTypes.string,
    isDisabled: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...style(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(IssueActionComponent);