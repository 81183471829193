import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Collapse } from "@mui/material";

// Import Components
import TableBodyCellComponent from '../tables/tableBodyCell.jsx';
import NestedAccordian from './index.jsx';


const BodyRowComponent = (props) => {

    /**
     * Define Props
     */
    const { classes, headers, data, accordian, headerProperty, onAccordianOnChange, onCellClick, selectComponentList } = props;


    return (
        <Fragment>
            <TableRow key="tableHeaderRow">
                {
                    headers && headers.map((header, index) =>
                        <TableBodyCellComponent
                            key={`cell_${index}`}
                            header={header}
                            data={data}
                            classes={classes}
                            onCellClick={onCellClick}
                            onAccordianOnChange={onAccordianOnChange}
                            selectComponentList={selectComponentList}
                        />
                    )
                }
                {/* {
                    headerProperty?.accordian &&
                    <TableCell key={'accordian-cell'}>
                    </TableCell>
                } */}
            </TableRow>
            {
                headerProperty?.accordian &&
                <TableRow className={`${classes.accordionTableRow} accordionTableRow`}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headers.length + 2}>
                        <Collapse in={data.isOpen} timeout="auto" unmountOnExit>
                            <NestedAccordian isLoading={data.isLoading} onCellClick={onCellClick} onAccordianOnChange={onAccordianOnChange} data={data.children || []} headerProperty={accordian[headerProperty?.childLevel] || {}} accordian={accordian} />
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    );
};

/**
 * Set Component Props
 */
BodyRowComponent.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    data: PropTypes.object,
    accordian: PropTypes.object,
    headerProperty: PropTypes.object,
    onAccordianOnChange: PropTypes.func,
    onCellClick: PropTypes.func,
    selectComponentList: PropTypes.object
};


/**
 * Set Default Values
 */
BodyRowComponent.defaultProps = {
    headers: [],
    accordian: {},
    data: {},
    headerProperty: {},
    selectComponentList: {},
    onAccordianOnChange: () => { },
    onCellClick: () => { }
};

export default BodyRowComponent;