const style = (theme) => ({
    textEditorWrapper: {
        width: '100%'
    },
    selectBox: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 250,
        paddingRight: 20,
        justifyContent: 'flex-end',
        '& .MuiTypography-root': {
            marginTop: '-2px'
        },
        '& .MuiSelect-select': {
            color: theme.palette.greyshades.darkgrey
        }
    },
    editorContainer: {
        background: theme.palette.greyshades.lightbackground,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 4,
        padding: 24
    },
    editorFields: {
        width: 'calc(100% - 40px)',
        paddingLeft: '20px',
        '& .MuiOutlinedInput-root': {
            background: '#fff'
        },
        '& textarea': {
            minHeight: 40
        },
        '& fieldset': {
            borderColor: `${theme.palette.greyshades.lightgrey} !important`
        }
    },
    avatarImg: {
        marginTop: 5
    }
});
export default style;