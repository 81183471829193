const style = () => ({
    patternAddContainer: {
        padding: 24
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    addPatternDialog: {
        '& .MuiPaper-root': {
            maxWidth: 800
        }
    }
});
export default style;