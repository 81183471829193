// Import API Helper
import { postRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Asset
 */
async function getAsset(params) {
    const response = await postRequest(urlConstants.connection.getTable, params);
    return response;
}

/**
 * Get Asset
 */
async function getAttribute(params) {
    const response = await postRequest(urlConstants.connection.getAttribute, params);
    return response;
}

/**
 * Save Asset
 */
async function saveAsset(params) {
    const response = await postRequest(urlConstants.asset.url, params);
    return response;
}

/**
 * Remove
 * @returns
 */
async function removeAsset(id, purge) {
    const response = await deleteRequest(`${urlConstants.asset.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Remove
 * @returns
 */
async function selectAttribute(payload) {
    const response = await putRequest(`${urlConstants.attribute.select}`, payload);
    return response;
}

/**
 * Sync Asset
 */
async function syncAsset(params) {
    const response = await postRequest(urlConstants.connection.syncAssets, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const connectorService = {
    getAsset, getAttribute, saveAsset, removeAsset, selectAttribute, syncAsset
};