import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isLoading: false,
    id: "",
    version_id: "",
    detail: null,
    versions: [],
    trending_chart: [],
    dimension_chart: [],
    assets: [],
    lineage: {},
    report_mapped_asset: null,
    assets_searchable_list: [],
    loading_type: '',
    assets_attributes_list: []
};

const assetSlice = createSlice({
    name: 'asset',
    initialState: { ...initialState },
    reducers: {
        getAssetDetailRequest(state) {
            state.isLoading = true;
            state.detail = {};
        },
        getAssetDetailSuccess(state, action) {
            state.detail = action.payload?.data ?? null;
            state.isLoading = false;
        },
        getAssetDetailFailure(state) {
            state.detail = {};
            state.isLoading = false;
        },
        updateAssetDetailInState(state, action) {
            const { asset, id } = action.payload.data;
            state.id = asset;
            state.version_id = id;
        },
        updateAssetRequest(state) {
            state.isLoading = true;
        },
        updateAssetSuccess(state) {
            state.isLoading = false;
        },
        updateAssetFailure(state) {
            state.isLoading = false;
        },
        deleteAssetRequest(state) {
            state.isLoading = true;
        },
        deleteAssetSuccess(state) {
            state.isLoading = false;
        },
        deleteAssetFailure(state) {
            state.isLoading = false;
        },
        getAssetTrendingChartRequest(state) {
            state.isLoading = true;
        },
        getAssetTrendingChartSuccess(state, action) {
            state.trending_chart = action.payload.data.map((data) => {
                return {
                    name: data?.name ?? '',
                    attribute_id: data?.attribute_id ?? '',
                    metrics: JSON.parse(data.metrics)
                };
            });
        },
        getAssetTrendingChartFailure(state) {
            state.isLoading = false;
        },
        getAssetDimentionChartRequest(state) {
            state.isLoading = true;
        },
        getAssetDimentionChartSuccess(state, action) {
            state.dimension_chart = action.payload.data.map((data) => {
                let metrics = JSON.parse(data.metrics);
                if (metrics?.length > 0) {
                    metrics = metrics.map((metric) => {
                        return {
                            ...metric,
                            score: metric.score?.toFixed(1) ?? 0
                        };
                    });
                }

                return {
                    name: data.name,
                    attribute_id: data?.attribute_id ?? '',
                    t_score: data.t_score?.toFixed(1),
                    metrics
                };
            });
            state.isLoading = false;
        },
        getAssetDimentionChartFailure(state) {
            state.isLoading = false;
        },
        clearAssetState(state) {
            state.isLoading = false;
            state.id = "";
            state.version_id = "";
            state.detail = null;
            state.versions = [];
            state.trending_chart = [];
            state.dimension_chart = [];
            state.lineage = {};
            state.report_mapped_asset = null;
        },
        getAllAssetsRequest() { },
        getAllAssetsSuccess(state, action) {
            const assets = action.payload.data || [];
            state.assets = assets.map((data) => {
                return {
                    ...data,
                    assetName: data.name,
                    name: `${data.name}`,
                    displayName: `${data.connection_name}.${data.name}`
                };
            });
        },
        getAllAssetsFailure() { },
        updateAssetPropertyRequest(state) {
            state.isLoading = true;
        },
        updateAssetPropertySuccess(state) {
            state.isLoading = false;
        },
        updateAssetPropertyFailure(state) {
            state.isLoading = false;
        },
        updateAssetReportRequest(state) {
            state.isLoading = true;
        },
        updateAssetReportSuccess(state) {
            state.isLoading = false;
        },
        updateAssetReportFailure(state) {
            state.isLoading = false;
        },
        getAssetLineageRequest(state) {
            state.isLoading = true;
            state.lineage = {};
        },
        getAssetLineageSuccess(state, action) {
            const data = action?.payload?.data ?? {};
            let tables = data?.tables ?? [];
            let relations = data?.relations ?? [];
            const impacts = data?.impacts ?? [];
            tables = tables.map((item) => {
                return {
                    ...item,
                    isCollapse: true
                };
            });
            relations = relations.map((item, index) => {
                return {
                    id: `relation_${index}`,
                    ...item
                };
            });
            state.lineage = {
                tables,
                relations,
                impacts
            };
            state.isLoading = false;
        },
        getAssetLineageFailure(state) {
            state.lineage = {};
            state.isLoading = false;
        },
        removeAssetReportRequest(state) {
            state.isLoading = true;
        },
        removeAssetReportSuccess(state) {
            state.isLoading = false;
        },
        removeAssetReportFailure(state) {
            state.isLoading = false;
        },
        updateLineageShowHideColoumns(state, action) {
            const table_id = action?.payload ?? '';
            let tables = state.lineage?.tables ?? [];
            tables = tables.map((item) => {
                if (item.id === table_id) {
                    return {
                        ...item,
                        isCollapse: !item.isCollapse
                    };
                }
                return item;
            });
            state.lineage = {
                ...state.lineage,
                tables
            };
        },
        validateAssetReportMappingRequest(state) {
            state.isLoading = true;
        },
        validateAssetReportMappingSuccess(state, action) {
            state.report_mapped_asset = action.payload?.data ?? null;
            state.isLoading = false;
        },
        validateAssetReportMappingFailure(state) {
            state.isLoading = false;
        },
        getAssetsSearchableListRequest() { },
        getAssetsSearchableListSuccess(state, action) {
            state.assets_searchable_list = action.payload || [];
        },
        getAssetsSearchableListFailure() { },
        getAssetsAttributesRequest(state) {
            state.loading_type = 'assets_attributes_list';
            state.isLoading = true;
        },
        getAssetsAttributesSuccess(state, action) {
            state.assets_attributes_list = action.payload.data || [];
            state.loading_type = '';
            state.isLoading = false;
        },
        getAssetsAttributesFailure(state) {
            state.loading_type = '';
            state.isLoading = false;
        }
    }
});

export const { getAssetDetailRequest, getAssetDetailSuccess, getAssetDetailFailure, updateAssetDetailInState, updateAssetRequest,
    updateAssetSuccess, updateAssetFailure, getVersionsRequest, getVersionsSuccess, getVersionsFailure,
    deleteAssetRequest, deleteAssetSuccess, deleteAssetFailure, clearAssetState, getAssetTrendingChartRequest, getAssetTrendingChartSuccess,
    getAssetTrendingChartFailure, getAssetDimentionChartRequest, getAssetDimentionChartSuccess, getAssetDimentionChartFailure,
    getAllAssetsRequest, getAllAssetsSuccess, getAllAssetsFailure, updateAssetPropertyRequest, updateAssetPropertySuccess,
    updateAssetPropertyFailure, updateAssetReportRequest, updateAssetReportSuccess, updateAssetReportFailure, getAssetLineageRequest,
    getAssetLineageSuccess, getAssetLineageFailure, removeAssetReportRequest, removeAssetReportSuccess, removeAssetReportFailure,
    updateLineageShowHideColoumns, validateAssetReportMappingRequest, validateAssetReportMappingSuccess, validateAssetReportMappingFailure,
    getAssetsSearchableListRequest, getAssetsSearchableListSuccess, getAssetsSearchableListFailure, getAssetsAttributesRequest,
    getAssetsAttributesSuccess, getAssetsAttributesFailure
} = assetSlice.actions;
export default assetSlice.reducer;