import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    exportLoading: false,
    importLoading: false,
    importData: {
        headers: [],
        data: [],
        fileName: ""
    },
    errors: []
};

const importExportSlice = createSlice({
    name: 'importExport',
    initialState: { ...initialState },
    reducers: {
        exportMetadataRequest(state) {
            state.exportLoading = true;
        },
        exportMetadataSuccess(state) {
            state.exportLoading = false;
        },
        exportMetadataFailure(state) {
            state.exportLoading = false;
        },
        exportUsersRequest(state) {
            state.exportLoading = true;
        },
        exportUsersSuccess(state) {
            state.exportLoading = false;
        },
        exportUsersFailure(state) {
            state.exportLoading = false;
        },
        setImportData(state, action) {
            state.importData = { ...action.payload } || [];
        },
        resetImportData(state) {
            state.importData = {
                headers: [],
                data: [],
                fileName: ""
            };
            state.errors = [];
        },
        importMetadataRequest(state) {
            state.importLoading = true;
        },
        importMetadataSuccess(state, action) {
            state.importLoading = false;
            state.errors = action.payload.data || [];
        },
        importMetadataFailure(state) {
            state.importLoading = false;
        },
        exportMeasureRequest(state) {
            state.exportLoading = true;
        },
        exportMeasureSuccess(state) {
            state.exportLoading = false;
        },
        exportMeasureFailure(state) {
            state.exportLoading = false;
        },
        importMeasureRequest(state) {
            state.importLoading = true;
        },
        importMeasureSuccess(state, action) {
            state.importLoading = false;
            state.errors = action.payload.data || [];
        },
        importMeasureFailure(state) {
            state.importLoading = false;
        },
        importUsersRequest(state) {
            state.importLoading = true;
        },
        importUsersSuccess(state, action) {
            state.importLoading = false;
            state.errors = action.payload.data || [];
        },
        importUsersFailure(state) {
            state.importLoading = false;
        }
    }
});

export const { exportMetadataRequest, exportMetadataSuccess, exportMetadataFailure, setImportData, resetImportData,
    importMetadataRequest, importMetadataSuccess, importMetadataFailure, exportMeasureRequest, exportMeasureSuccess,
    exportMeasureFailure, importMeasureRequest, importMeasureSuccess, importMeasureFailure,
    exportUsersRequest, exportUsersSuccess, exportUsersFailure, importUsersRequest, importUsersSuccess, importUsersFailure
} = importExportSlice.actions;
export default importExportSlice.reducer;