import React, { useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';


//  Import Components
import { Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextComponent, UsersComponent } from '../../../../../../components/index.js';
import ImageCropper from '../../../../../../components/imageCrop/ImageCropper.jsx';

//  Import Styles
import Style from '../../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import { CameraIcon, MoreIcon } from '../../../../../../assets/svg/index.js';
import PlaceholderImg from '../../../../../../assets/img/placeholder.png';

// Reducer
import { navigate } from '../../../../../../redux/reducer/navigationReducer';
import { deleteGlossaryRequest, updateGlossaryImageRequest, updateGlossaryRequest } from '../../../../../../redux/reducer/semanticReducer';
import { getUsersThumbnailRequest } from '../../../../../../redux/reducer/userReducer.js';

function GlossaryCard(props) {
    /**
     * Define Props
     */
    const { classes, data, index, semanticsPermission } = props;
    const dispatch = useDispatch();
    const formRef = useRef();

    // Initial State
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEdited, setEdited] = useState(false);
    const [glossary, setGlossary] = useState({});
    const [uploadImageUrl, setUploadImageUrl] = useState(null);

    const { thumbnail_list: usersList } = useSelector((state) => state.user, shallowEqual);

    /**
     * Navigate to New Connector View
     */
    const navigateDetailPage = () => {
        dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: data }, params: [data.id] }));
    };

    //img upload
    const handleImgUpload = () => {
        document.getElementById(`domainImage_${index}`).click();
    };

    /**
     * Handle Click
     * @description Handle More Menu Click Event
     * @param {*} event
     */
    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handle More Menu Close
     */
    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    const onEditGlossary = () => {
        setEdited(true);
        // setGlossary({ ...data });
        handleMoreClose();
    };

    /**
     * Delete Glossary
     */

    const onDeleteGlossary = () => {
        handleMoreClose();
        dispatch(deleteGlossaryRequest(data.id));
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, upload_image_url) => {
        dispatch(updateGlossaryImageRequest({ id: data.id, image: croppedImage }));
        setUploadImageUrl(upload_image_url);
    };

    /**
     * On Save Glossary
     */
    const onSaveGlossary = (data = null) => {
        const glossaryData = data || glossary;
        const requestParams = {
            ...glossaryData
        };
        dispatch(updateGlossaryRequest(requestParams));
        if (!data) {
            setEdited(false);
        }
    };

    /**
     * Handle OnChange
     * @param {*} name
     * @param {*} value
     */
    const onChange = (name, value) => {
        if (name === "users") {
            value = value.map((user) => user.id);
            onSaveGlossary({ ...glossary, users: value });
        }
        setGlossary((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * on Trigger Submit
     */
    const onTriggerSubmit = () => {
        formRef.current.submit();
    };


    /**
     * Set Default Data
     */
    useEffect(() => {
        setGlossary({ ...data });
    }, []);

    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);

    /**
     * Filter Mapped Users
     * @param {*} users
     * @param {*} userList
     * @returns
     */
    const getUsers = (users, userList) => {
        const usersData = typeof (userList) === "string" ? JSON.parse(userList) : userList;
        return users.filter((user) => usersData.includes(user.id));
    };
    const users = useMemo(() => getUsers(usersList, data.users || []), [usersList, data.users || []]);

    return (
        <Grid item xs={4} lg={4} xl={2} onMouseLeave={() => isEdited && onTriggerSubmit()}>
            <ValidatorForm className="h-100" onSubmit={() => onSaveGlossary()} ref={formRef}>
                <Grid className={classes.domainCard}>
                    <Grid item className={`${classes.imgContainer} ${data.image ? "uploadedImg" : "defaultImg"}`} onClick={() => navigateDetailPage()}>
                        <img src={uploadImageUrl || data.image ? `${data.image}?ts=${Date.now()}` : PlaceholderImg} alt="DomainCard img" />
                    </Grid>
                    <Grid item className={classes.detail}>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="pb-1"
                            wrap="nowrap"
                        >
                            {
                                isEdited ?
                                    <TextComponent
                                        className={classes.title}
                                        noOutline
                                        placeholder="Enter Name"
                                        variant="standard"
                                        name="name"
                                        value={glossary.name || ""}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        validators={['required']}
                                        errorMessages={["Name is required"]}
                                    /> :
                                    <Typography variant="h5" className={classes.navigateText} onClick={() => navigateDetailPage()} noWrap>
                                        {data.name || ""}
                                    </Typography>
                            }

                            <Grid className="dflex alignCenter">
                                <input
                                    accept="image/*"
                                    className={classes.domainImgInput}
                                    id={`domainImage_${index}`}
                                    type="file"
                                    onChange={handleSelectFile}
                                />
                                {
                                    openImageDialog && (
                                        <ImageCropper
                                            open={openImageDialog}
                                            onClose={() => setImageDialog(false)}
                                            imageToCrop={imageToCrop}
                                            aspect={16 / 9}
                                            onImageCropped={
                                                (croppedImage, croppedImageUrl) =>
                                                    updateCropImage(croppedImage, croppedImageUrl)
                                            }
                                        />
                                    )
                                }
                                {
                                    semanticsPermission?.is_edit &&
                                    <Tooltip title="Upload image" arrow>
                                        <IconButton
                                            className="p2 mr5 cameraIcon"
                                            onClick={() => handleImgUpload()}
                                        >
                                            <CameraIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    !isEdited && semanticsPermission?.is_edit &&
                                    <Tooltip title="More" arrow>
                                        <IconButton className="p0" onClick={(event) => handleMoreClick(event)}>
                                            <MoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                        {
                            isEdited ?
                                <TextComponent
                                    className={classes.description}
                                    placeholder="Enter Description"
                                    name="description"
                                    fullWidth
                                    multiline
                                    variant="standard"
                                    rows={3}
                                    value={glossary.description || ""}
                                    onChange={(event) => onChange(event.target.name, event.target.value)} />
                                :
                                <Tooltip title={data.description} arrow>
                                    <Typography
                                        onClick={() => navigateDetailPage(data)}
                                        variant="body1"
                                        className={`${classes.textSecondary} descriptionText`}
                                        align="left"
                                    >
                                        {data.description}
                                    </Typography>
                                </Tooltip>
                        }
                        <Grid className="mt8">
                            <UsersComponent
                                list={usersList}
                                data={users || []}
                                isSteward={false}
                                // disabled={!isEdited}
                                inline
                                handleStewardUser={(stewards) => onChange("users", stewards)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                Boolean(anchorEl) &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMoreClose}
                    MenuListProps={
                        {
                            'aria-labelledby': 'basic-button'
                        }
                    }
                >
                    {
                        semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => onEditGlossary()}>
                            Edit
                        </MenuItem>
                    }
                    {
                        semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => onDeleteGlossary()}>
                            Delete
                        </MenuItem>
                    }
                </Menu>
            }
        </Grid>
    );
}

// default props
GlossaryCard.defaultProps = {
    classes: {},
    data: {},
    index: 0,
    semanticsPermission: {}
};

// prop types
GlossaryCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    index: PropTypes.number,
    semanticsPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes)
        && _.isEqual(prevProps.semanticsPermission && nextProps.semanticsPermission);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(GlossaryCard, areEqual));