const styles = (theme) => ({
    listAction: {
        '& .EditIcon': {
            width: 16,
            height: 16,
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '&:hover': {
                '& path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .DeleteIcon': {
            width: 18,
            height: 18,
            '&:hover': {
                '& path': {
                    fill: theme.palette.secondary.main,
                    stroke: theme.palette.secondary.main
                }
            }
        },
        '& .MuiButtonBase-root': {
            padding: '4px !important'
        }
    },
    selectedItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 12px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        background: `${theme.palette.greyshades.tablegrey}60`,
        borderRadius: '30px',
        marginBottom: '10px !important',
        '&.active': {
            border: `2px dashed ${theme.palette.secondary.main}`
        }
    },
    text: {
        flex: 1
    }
});

export default styles;