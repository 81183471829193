import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getApplicationsRequest, getApplicationsSuccess, getApplicationsFailure, createApplicationRequest, createApplicationSuccess,
  createApplicationFailure, updateApplicationRequest, updateApplicationSuccess, updateApplicationFailure, deleteApplicationRequest,
  deleteApplicationSuccess, deleteApplicationFailure,
  getApplicationByIdRequest, getApplicationByIdSuccess, getApplicationByIdFailure, getApplicationAssetsRequest, getApplicationAssetsSuccess,
  getApplicationAssetsFailure,
  getApplicationQualityRequest,
  getApplicationQualityFailure,
  getApplicationQualitySucess,
  getApplicationsFilterRequest,
  getApplicationsFilterSuccess,
  getApplicationsFilterFailure
} from '../reducer/applicationReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { applicationService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';

/**
 * Get Applications
 * @returns
 */
const getApplications = (action$) => action$.pipe(
  ofType(getApplicationsRequest),
  mergeMap(() => {
    return from(applicationService.getApplications()).pipe(
      concatMap((res) => { return of(getApplicationsSuccess(res.data)); }),
      catchError((error) => { return of(getApplicationsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Get Application Detail
 * @param {*} action$
 * @returns
 */
const getApplicationById = (action$) => action$.pipe(
  ofType(getApplicationByIdRequest),
  mergeMap(({ payload }) => {
    return from(applicationService.getApplicationById(payload)).pipe(
      concatMap((res) => of(getApplicationByIdSuccess(res))),
      catchError((error) => {
        const errorMsg = error;
        if (validate404Error(errorMsg)) {
          return of(getApplicationByIdFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
        }
        return of(getApplicationByIdFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
      })
    );
  })
);

/**
 * Create Application
 * @param {*} action$
 * @returns
 */
const createApplication = (action$) => action$.pipe(
  ofType(createApplicationRequest),
  mergeMap(({ payload }) => {
    return from(applicationService.createApplication(payload)).pipe(
      concatMap((res) => { return of(createApplicationSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created })); }),
      catchError((error) => { return of(createApplicationFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Application
 * @param {*} action$
 * @returns
 */
const updateApplication = (action$) => action$.pipe(
  ofType(updateApplicationRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(applicationService.updateApplication(id, rest)).pipe(
      concatMap((res) => { return of(updateApplicationSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateApplicationFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Delete Application
 * @param {*} action$
 * @returns
 */
const deleteApplication = (action$) => action$.pipe(
  ofType(deleteApplicationRequest),
  mergeMap(({ payload: { id, type } }) => {
    return from(applicationService.deleteApplication(id, type)).pipe(
      concatMap(() => of(deleteApplicationSuccess(id), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
      catchError((error) => of(deleteApplicationFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Application Assets
 * @param {*} action$
 * @returns
 */
const assets = (action$) => action$.pipe(
  ofType(getApplicationAssetsRequest),
  mergeMap(({ payload }) => {
    return from(applicationService.assets(payload)).pipe(
      concatMap((res) => of(getApplicationAssetsSuccess({ ...res }))),
      catchError((error) => of(getApplicationAssetsFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Quality
 * @param {*} action$
 * @returns
 */
const quality = (action$) => action$.pipe(
  ofType(getApplicationQualityRequest),
  mergeMap(({ payload: { accordian, clear, ...rest } }) => {
    return from(applicationService.quality(rest)).pipe(
      concatMap((res) => of(getApplicationQualitySucess({ ...res, accordian, clear }))),
      catchError((error) => of(getApplicationQualityFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Get Applications
 * @returns
 */
const getApplicationsFilter = (action$) => action$.pipe(
  ofType(getApplicationsFilterRequest),
  mergeMap(() => {
    return from(applicationService.getApplicationsFilter()).pipe(
      concatMap((res) => { return of(getApplicationsFilterSuccess(res.data)); }),
      catchError((error) => { return of(getApplicationsFilterFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

// Export All Application Functions
export const applicationEpic = [getApplications, getApplicationById, createApplication, deleteApplication, updateApplication, assets, quality, getApplicationsFilter];