import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useScreenshot, createFileName } from 'use-react-screenshot';

//  Import Components
import { Grid } from '@mui/material';
import Widgets from './components/widgets/index.jsx';
import Header from './components/header/index.jsx';
import { LoaderComponent } from '../../components/index.js';

//  Import Styles
import DashboardStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Helpers
import { checkPermission } from '../../helpers/appHelpers.js';

// Import Constants
import featureConstants from '../../constants/featureConstants.js';

function Dashboard(props) {
    /**
     * Define Props
     */
    const { classes } = props;

    /**
     * Define State
     */
    const dashboard_div_ref = useRef();
    // eslint-disable-next-line no-unused-vars
    const [image, takeScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 1.0
        }
    );

    /**
     * Redux
     */
    const isLoading = useSelector(({ dashboard }) => dashboard.isLoading);
    const { permission } = useSelector((state) => state.auth);
    const dashboardPermission = checkPermission(permission, featureConstants.home.dashboard);

    /**
     * Dashboard Screenshot
     * @param {*} dataurl
     */
    const download = (image, { name = "dashboard", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    /**
     * Download Dashboard
     */
    const downloadDashboard = () => {
        let timer = null;
        if (dashboard_div_ref) {
            timer = setTimeout(() => {
                takeScreenShot(dashboard_div_ref.current).then(download);
            }, 1000);
        }
        return () => clearTimeout(timer);
    };

    return (
        <Grid container className={classes.dashboardContainer} spacing={2} ref={dashboard_div_ref}>
            <Grid item xs={12}>
                <Header downloadDashboard={downloadDashboard} />
            </Grid>
            <Grid item xs={12}>
                <Widgets isDisabled={!dashboardPermission?.is_edit} />
                {
                    isLoading &&
                    <LoaderComponent height="400px" />
                }

            </Grid>
        </Grid>
    );
}

// default props
Dashboard.defaultProps = {
    classes: {}
};

// prop types
Dashboard.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DashboardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Dashboard);