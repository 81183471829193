import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Grid, Typography } from '@mui/material';
import CustomWidgetComponents from '../../../components/customWidgetComponents/index.jsx';
import { LoaderComponent } from '../../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Actions
import { getWidgetPreviewRequest, clearReport, updateProperties } from '../../../../../redux/reducer/customWidgetReducer';

// Import Helpers
import { getWidgetTables, getSupportColumns } from '../../../../../helpers/appHelpers';


function ChartPreview(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const { detail, report, preivewLoading, errorMessage } = useSelector(({ customWidget }) => customWidget);

    /**
     * Get Widget Preview
     */
    useEffect(() => {
        const axis = [...detail.properties.xAxis, detail.properties.yAxis, ...detail.properties.levels].filter((data) => Object.keys(data).length);
        let loadPreview = axis.length;
        if (detail?.widget_type === 'heatmap') {
            const xAxis = detail.properties.xAxis.filter((data) => Object.keys(data).length);
            const yAxis = detail.properties.yAxis.filter((data) => Object.keys(data).length);
            const groupBy = detail.properties.groupBy.filter((data) => Object.keys(data).length);
            loadPreview = xAxis.length && yAxis.length && groupBy.length;
        }
        if (loadPreview) {
            const tables = getWidgetTables(detail);
            const supportColumn = getSupportColumns(detail);
            const requestParams = {
                ...detail,
                properties: {
                    ...detail.properties,
                    tables: tables || [],
                    supportColumn: supportColumn || []
                }
            };
            dispatch(getWidgetPreviewRequest(requestParams));
        } else {
            dispatch(clearReport());
        }
    }, [detail.properties, detail.filter_properties.filters, detail.widget_type]);

    /**
     * OnChange Filter Properties
     * @param {*} property
     * @param {*} value
     */
    const onChangeProperties = (property, value) => {
        const requestParams = {
            filter_properties: {
                ...detail.filter_properties,
                [property]: value
            }
        };
        dispatch(updateProperties(requestParams));
    };


    return (
        <Grid className={classes.customWidgetContainer}>
            {
                errorMessage &&
                <Typography variant="body1" className="errorText">
                    {errorMessage}
                </Typography>
            }
            <Grid className={classes.customWidgetContainerInner}>
                {
                    preivewLoading ?
                        <LoaderComponent /> :
                        <CustomWidgetComponents
                            data={
                                {
                                    ...detail,
                                    report: { default: report }
                                }
                            }
                            type="preview"
                            onChangeProperties={onChangeProperties}
                        />
                }
            </Grid>
        </Grid>
    );
}

// default props
ChartPreview.defaultProps = {
    classes: {}
};

// prop types
ChartPreview.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ChartPreview);