const style = (theme) => ({
    comparisonContainer: {
        background: '#fff',
        padding: 24,
        '& .greyInput .MuiOutlinedInput-input, .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '6px 10px'
        },
        '& .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '2px 10px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '7.5px 14px'
        },
        '& .PlusIcon': {
            '&:hover ': {
                fill: theme.palette.secondary.main
            }
        }
    },
    alertContainer: {
        background: '#fff',
        padding: 0
    },
    addMeasureContainer: {
        background: theme.palette.greyshades.lightbackground,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        padding: 20,
        marginTop: '15px !important',
        minHeight: '14px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 2,
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            minWidth: 140,
            padding: '0px 9px !important',
            height: '33.59px',
            borderRadius: '2px'
        },
        '& .MuiInputBase-input': {
            padding: '5.5px 0px !important',
            fontSize: 14
        },
        '& fieldset': {
            borderColor: `${theme.palette.greyshades.grey} !important`
        },
        '& .MuiAutocomplete-clearIndicator svg': {
            width: '18px',
            height: '18px',
            fill: theme.palette.greyshades.darkgrey,
            marginTop: '2px'
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginTop: 2
        },
        '& .ChevDownIcon': {
            width: '13px',
            height: '13px'
        }
    },
    field: {
        flex: 1,
        paddingRight: '40px !important',
        maxWidth: 250,
        '& .MuiAutocomplete-root': {
            '&:hover': {
                '& .MuiOutlinedInput-input': {
                    paddingRight: '30px !important'
                }
            },
            '& .MuiOutlinedInput-input': {
                paddingRight: '15px !important'
            }
        }
    },
    textSecondary: {
        color: `${theme.palette.greyshades.darkgrey} !important`,
        whiteSpace: "nowrap"
    },
    ruleGreyContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: 24,
        marginTop: '15px !important',
        minHeight: '144px'
    },
    checkBtn: {
        '& .fillPath': {
            fill: theme.palette.secondary.main
        }
    },
    compareBtn: {
        '& .PlusIcon': {
            fill: theme.palette.secondary.main
        }
    },
    addMeasureBtn: {
        '&:hover': {
            '& .MuiTypography-root': {
                color: `${theme.palette.secondary.main} !important`,
                cursor: 'pointer'
            },
            '& .PlusIcon': {
                fill: theme.palette.secondary.main
            }
        }
    }
});
export default style;