import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    conversationList: [],
    isLoading: false,
    coversationLoadMore: true,
    featuredList: [],
    filter: {
        limit: 50,
        offset: 0,
        priority: 'all'
    },
    attributesTagList: []
};

const conversationSlice = createSlice({
    name: 'conversation',
    initialState: { ...initialState },
    reducers: {
        conversationCreateRequest(state) {
            state.isLoading = true;
        },
        conversationCreateSuccess(state, action) {
            state.isLoading = false;
            if (action.payload.data.thread) {
                const conversations = [...state.conversationList];
                const index = conversations.findIndex((conversation) => conversation.id === action.payload.data.thread);
                conversations[index].replies = conversations[index].replies ? conversations[index].replies : [];
                const responseData = action.payload.data;
                responseData.created_by = action.payload.requestData.user;
                conversations[index].replies.unshift(responseData);
            } else {
                const responseData = action.payload.data;
                responseData.created_by = action.payload.requestData.user;
                state.conversationList.unshift(responseData);
            }
        },
        conversationCreateFailure(state) {
            state.isLoading = false;
        },
        conversationListRequest(state, action) {
            state.isLoading = true;
            if (action.payload.offset === 0) {
                state.conversationList = [];
                state.coversationLoadMore = true;
                state.featuredList = [];
            }
        },
        conversationListSuccess(state, action) {
            state.conversationList = [...state.conversationList, ...action.payload.data];
            state.filter.offset = action.payload.offset;
            state.filter.priority = action.payload.priority;
            if (action.payload.data.length < state.filter.limit) {
                state.coversationLoadMore = false;
            }
            state.isLoading = false;
        },
        conversationListFailure(state) {
            state.isLoading = false;
        },
        conversationUpdateRequest(state) {
            state.isLoading = true;
        },
        conversationUpdateSuccess(state, action) {
            state.isLoading = false;
            const featuredComments = [...state.featuredList];
            const featuredIndex = featuredComments.findIndex((featured) => featured.id === action.payload.data.id);
            if (action.payload.requestData.isFeaturedUpdate) {
                if (featuredIndex >= 0) {
                    featuredComments.splice(featuredIndex, 1);
                    state.featuredList = [...featuredComments];
                }
            } else {
                const conversations = [...state.conversationList];
                if (action.payload.requestData.isReplyEdit) {
                    const parentIndex = conversations.findIndex((conversation) => conversation.id === action.payload.requestData.thread);
                    const replyIndex = conversations[parentIndex].replies.findIndex((reply) => reply.id === action.payload.data.id);
                    conversations[parentIndex].replies[replyIndex] = { ...conversations[parentIndex].replies[replyIndex], ...action.payload.data };
                } else {
                    const index = conversations.findIndex((conversation) => conversation.id === action.payload.data.id);
                    conversations[index].is_pinned = action.payload.data.is_pinned;
                    conversations[index].title = action.payload.data.title;
                    conversations[index].comment = action.payload.data.comment;
                    conversations[index].priority = action.payload.data.priority;
                    conversations[index].rating = action.payload.data.rating;
                }
                state.conversationList = [...conversations];
            }
        },
        conversationUpdateFailure(state) {
            state.isLoading = false;
        },
        conversationDeleteRequest(state) {
            state.isLoading = true;
        },
        conversationDeleteSuccess(state, action) {
            state.isLoading = false;
            const conversations = [...state.conversationList];
            if (action.payload.parent_id) {
                const parentIndex = conversations.findIndex((conversation) => conversation.id === action.payload.parent_id);
                const replyIndex = conversations[parentIndex].replies.findIndex((reply) => reply.id === action.payload.message_id);
                conversations[parentIndex].replies.splice(replyIndex, 1);
            } else {
                const index = conversations.findIndex((conversation) => conversation.id === action.payload.message_id);
                conversations.splice(index, 1);
            }
            state.conversationList = [...conversations];
        },
        conversationDeleteFailure(state) {
            state.isLoading = false;
        },
        getFeaturedCommentRequest(state) {
            state.isLoading = true;
        },
        getFeaturedCommentSuccess(state, action) {
            state.isLoading = false;
            state.featuredList = action.payload.data;
        },
        getFeaturedCommentFailure(state) {
            state.isLoading = false;
        },
        getAttributesTagsListRequest() { },
        getAttributesTagsListSuccess(state, action) {
            state.attributesTagList = action?.payload?.data ?? [];
        },
        getAttributesTagsListFailure() { }
    }
});

export const { conversationCreateRequest, conversationCreateSuccess, conversationCreateFailure, conversationListRequest, conversationListSuccess,
    conversationListFailure, conversationUpdateRequest, conversationUpdateSuccess, conversationUpdateFailure, conversationDeleteRequest,
    conversationDeleteSuccess, conversationDeleteFailure, getFeaturedCommentRequest, getFeaturedCommentSuccess, getFeaturedCommentFailure,
    getAttributesTagsListRequest, getAttributesTagsListSuccess, getAttributesTagsListFailure
} = conversationSlice.actions;
export default conversationSlice.reducer;