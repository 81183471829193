const style = (theme) => ({
    SemanticsPageContainer: {
        padding: '24px 30px 0px'
    },
    tabHeaderContainer: {
        '& .tabHeader': {
            borderBottom: 'none !important',
            justifyContent: 'end'
        },
        '& .tabsLink': {
            padding: '0px',
            marginRight: 1
        },
        '& .tabsLink .MuiTypography-h4.MuiTypography-root': {
            padding: '3px 16px',
            height: '24px !important',
            display: 'flex',
            alignItems: 'center',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontWeight: '400 !important',
            background: theme.palette.greyshades.lightgrey,
            '&.active': {
                border: 'none !important',
                background: theme.palette.secondary.main,
                color: '#fff !important',
                padding: '3px 16px'
            }
        }
    }
});
export default style;