const style = (theme) => ({
    leftContainer: {
        padding: 30,
        borderRight: `1px solid ${theme.palette.greyshades.lightgrey}`
    },
    widgetListIcon: {
        marginRight: '15px !important',
        '& .textValue': {
            fontSize: '13px !important',
            visibility: 'hidden'
        }
    },
    progressBar: {
        minWidth: '100px'
    }
});

export default style;