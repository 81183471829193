import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { LinearProgress } from '@mui/material';

//  Import Styles
import LinearProgressBarStyle from '../style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';


function LinearProgressBar(props) {

    /**
     * Define Props
     */
    const { value } = props;

    /**
     * Define State
     */
    const [progressValue, setProgressValue] = useState(0);


    const loadValue = (value) => {
        setTimeout(() => setProgressValue(value), 100);
    };

    /**
     * Load initial data
     */
    useEffect(() => {
        loadValue(value);
    }, [value]);

    return (
        <LinearProgress variant="determinate" value={progressValue || 0} color={'inherit'} />
    );
}

// default props
LinearProgressBar.defaultProps = {
    value: 0
};

// prop types
LinearProgressBar.propTypes = {
    value: PropTypes.number
};

export default withStyles(
    (theme) => ({
        ...LinearProgressBarStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LinearProgressBar);