import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    tabs: [],
    fields: [],
    groups: []
};

const fieldPropertySlice = createSlice({
    name: 'fieldProperty',
    initialState: { ...initialState },
    reducers: {
        getTabListRequest() { },
        getTabListSuccess(state, action) {
            state.tabs = action.payload.data || [];
        },
        getTabListFailure() { },
        getFieldPropertyRequest(state) {
            state.isLoading = true;
        },
        getFieldPropertySuccess(state, action) {
            state.isLoading = false;
            state.fields = action.payload.data || [];
            state.groups = state.fields.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.group_id === value.group_id
                ))
            ).map((obj) => {
                return {
                    name: obj.group_name,
                    id: obj.group_id,
                    collapse: obj.collapse,
                    position: obj.position
                };
            }).sort((a, b) => a.position - b.position);
        },
        getFieldPropertyFailure(state) {
            state.isLoading = false;
        },
        updateFieldProperty(state, action) {
            const { custom_field, ...rest } = action.payload;
            const index = state.fields.findIndex((field) => field.custom_field === custom_field);
            state.fields[index] = {
                ...state.fields[index],
                ...rest
            };
        },
        createFieldPropertyRequest() { },
        createFieldPropertySuccess(state, action) {
            const { id, custom_field } = action.payload.data;
            const index = state.fields.findIndex((field) => field.custom_field === custom_field);
            state.fields[index] = {
                ...state.fields[index],
                id
            };
        },
        createFieldPropertyFailure() { },
        updateFieldPropertyRequest() { },
        updateFieldPropertySuccess() { },
        updateFieldPropertyFailure() { },
        orderGroupData(state, action) {
            state.groups = action.payload;
        },
        groupCollapse(state, action) {
            const { id, collapse } = action.payload;
            const index = state.groups.findIndex((group) => group.id === id);
            state.groups[index].collapse = collapse;
        },
        updateGroupCollapseRequest() { },
        updateGroupCollapseSuccess() { },
        updateGroupCollapseFailure() { },
        updateGroupOrderRequest() { },
        updateGroupOrderSuccess() { },
        updateGroupOrderFailure() { }
    }
});

export const { getTabListRequest, getTabListSuccess, getTabListFailure, getFieldPropertyRequest, getFieldPropertySuccess,
    getFieldPropertyFailure, updateFieldProperty, createFieldPropertyRequest, createFieldPropertySuccess, createFieldPropertyFailure,
    updateFieldPropertyRequest, updateFieldPropertySuccess, updateFieldPropertyFailure, orderGroupData, groupCollapse, updateGroupCollapseRequest,
    updateGroupCollapseSuccess, updateGroupCollapseFailure, updateGroupOrderRequest, updateGroupOrderSuccess, updateGroupOrderFailure
} = fieldPropertySlice.actions;
export default fieldPropertySlice.reducer;