const style = (theme) => ({
    searchedBtns: {
        backgroundColor: `${theme.palette.secondary.main}10 !important`,
        color: `${theme.palette.headers.body1.color} !important`,
        padding: '4px 12px !important',
        margin: '5px !important',
        overflow: 'hidden',
        maxWidth: 'calc(100% - 10px)',
        '& .SearchIcon path#Path_1197': {
            fill: `${theme.palette.secondary.main}`
        },
        '& .SearchIcon': {
            width: '18px',
            height: '18px'
        },
        '& .MuiTypography-root': {
            marginTop: '-2px'
        }
    },
    searchListContainer: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
});

export default style;