import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';

//  Import Styles
import QueryExecutionStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// import Actions
import { getQueryHistoryRequest } from '../../../../redux/reducer/usageReducer';

// Import Components
import { LineChartComponent, LoaderComponent, NoResultComponent } from '../../../../components/index.js';

function QueryExecution(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const asset_id = useSelector((state) => state.version.asset_id, shallowEqual);
    const { execution_time_per_day, dateRange } = useSelector((state) => state.usage, shallowEqual);

    /**
     *
     * Get Usage
     */
    useEffect(() => {
        if (asset_id && execution_time_per_day?.data?.length <= 0) {
            dispatch(getQueryHistoryRequest({ asset_id, type: 'execution_time_per_day' }));
        }
    }, [dispatch, asset_id]);

    const chartData = execution_time_per_day.data?.map((datum) => { return { x: datum.rundate, y: datum.time, count: datum.count }; });

    /**
     * Get Date Range Data
     * @param {*} range
     */
    const getDataByDateRang = (range) => {
        dispatch(getQueryHistoryRequest({ asset_id, type: 'execution_time_per_day', days: range }));
        dispatch(getQueryHistoryRequest({ asset_id, type: 'recent_queries', days: range }));
        dispatch(getQueryHistoryRequest({ asset_id, type: 'top_5_users', days: range }));
        dispatch(getQueryHistoryRequest({ asset_id, type: 'top_5_roles', days: range }));
    };

    return (
        <Grid className={`${classes.blueContainer} p-2`}>
            <Grid container justifyContent={"space-between"}>
                <Typography variant="h5" className="mb-3">
                    Query Execution Time(ms)
                </Typography>
                <Grid className="dflex alignBaseline chartheader">
                    <ToggleButtonGroup
                        value={dateRange}
                        exclusive
                        aria-label="text alignment"
                        onChange={(event, value) => getDataByDateRang(value)}
                        className={classes.daysFilter}
                    >
                        <ToggleButton
                            value={7}
                            aria-label="left aligned"
                        >
                            <Typography variant="body2" className={classes.textSecondary}>
                                7d
                            </Typography>
                        </ToggleButton>
                        <ToggleButton
                            value={14}
                            aria-label="centered"
                        >
                            <Typography variant="body2" className={classes.textSecondary}>
                                14d
                            </Typography>
                        </ToggleButton>
                        <ToggleButton
                            value={30}
                            aria-label="right aligned"
                        >
                            <Typography variant="body2" className={classes.textSecondary}>
                                30d
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid>
                {
                    !execution_time_per_day.isLoading && chartData && chartData.length > 0 &&
                    <LineChartComponent data={chartData || []} formatType="execution" chartHeight="240px" dateFormat= "MMM DD YYYY" />
                }
                {
                    !execution_time_per_day.isLoading && chartData && chartData.length === 0 &&
                    <NoResultComponent height="190px" title="No Queries Found" />
                }
                {
                    execution_time_per_day.isLoading &&
                    <LoaderComponent loaderType="circular" height="150" />
                }
            </Grid>
        </Grid >
    );
}

// default props
QueryExecution.defaultProps = {
    classes: {}
};

// prop types
QueryExecution.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...QueryExecutionStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(QueryExecution);