import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import styles
import style from './style.jsx';

// Import Images
import {
	VerticalBarChart, HorizontalBarChart, Polar, DotChart, SunBurstChart, RadarChart, LineChart, OrganizationChart, TableChart, AreaChart, CountLineChart,
	GaugeChart, HeatMapChartIcon
} from '../../assets/svg/index.js';

// Import Constant
import appConstants from '../../constants/appConstants.js';

function ChartTypeIcon(props) {

	/**
	 * Define Props
	 */
	const { chart_type } = props;

	/**
	 * Connectors Data
	 */
	const chartIcons = [
		{
			icon: <VerticalBarChart />,
			type: appConstants.dashboardWigetTypes.verticalBar
		},
		{
			icon: <HorizontalBarChart />,
			type: appConstants.dashboardWigetTypes.horizontalBar
		},
		{
			icon: <Polar />,
			type: appConstants.dashboardWigetTypes.polar
		},
		{
			icon: <DotChart />,
			type: 'dot'
		},
		{
			icon: <SunBurstChart />,
			type: appConstants.dashboardWigetTypes.sun_burst
		},
		{
			icon: <RadarChart />,
			type: 'radar'
		},
		{
			icon: <LineChart />,
			type: appConstants.dashboardWigetTypes.line
		},
		{
			icon: <AreaChart />,
			type: appConstants.dashboardWigetTypes.area
		},
		{
			icon: <OrganizationChart />,
			type: appConstants.dashboardWigetTypes.hierachy
		},
		{
			icon: <TableChart />,
			type: appConstants.dashboardWigetTypes.table
		},
		{
			icon: <RadarChart />,
			type: appConstants.dashboardWigetTypes.spider
		},
		{
			icon: <CountLineChart />,
			type: appConstants.dashboardWigetTypes.count
		},
		{
			icon: <GaugeChart />,
			type: appConstants.dashboardWigetTypes.gauge
		},
		{
			icon: <HeatMapChartIcon />,
			type: appConstants.dashboardWigetTypes.heatmap
		}
	];


	/**
	 * Get Icon
	 * @returns
	 */
	const bindIcon = () => {
		const data = chartIcons.find((data) => data.type === chart_type);
		if (data) { return data.icon; }
		return null;
	};


	return (
		bindIcon()
	);
}

/**
 * Define Component Props
 */
ChartTypeIcon.propTypes = {
	classes: PropTypes.object,
	chart_type: PropTypes.string
};

/**
 * Define Default Values
 */
ChartTypeIcon.defaultProps = {
	classes: {},
	chart_type: ''
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.chart_type, nextProps.chart_type) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(style)(React.memo(ChartTypeIcon, areEqual));