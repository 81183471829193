const style = (theme) => ({
    valuesPatternList: {
        marginTop: '15px !important',
        maxHeight: '246px',
        overflowY: 'auto',
        paddingRight: '10px',
        '& .MuiTypography-root.listItem': {
            cursor: 'pointer',
            paddingRight: 8,
            width: '100%',
            padding: '4px 0px',
            marginRight: 8,
            '&:hover': {
                transition: '0.5s all',
                background: theme.palette.greyshades.lightestgrey,
                paddingLeft: '5px !important'
            }
        }
    },
    patternValuesRt: {
        display: 'flex',
        alignItems: 'center'
    },
    pattenVelues: {
        padding: '4px 12px',
        borderRadius: '3px',
        fontSize: 15,
        minWidth: 43,
        fontWeight: 500,
        textAlign: 'center',
        '&.valid': {
            color: `${theme.palette.colorThemes.low} !important`,
            background: `${theme.palette.colorThemes.low}30`
        },
        '&.invalid': {
            color: `${theme.palette.colorThemes.high} !important`,
            background: `${theme.palette.colorThemes.high}30`
        }
    },
    pattenDelete: {
        marginLeft: '24px !important',
        padding: '0px !important',
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    PlusIcon: {
        width: '40px',
        height: '40px',
        '& .PlusIcon:hover': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    chartIconClass: {
        '& svg': {
            fill: theme.palette.greyshades.darkgrey,
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    }
});
export default style;