import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

//  Import Components
import { Grid, IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import Profile from './components/profile.jsx';
import { SearchComponent } from '../textBox/index.js';

//  Import Styles
import style from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DefaultLogo from '../../assets/img/dq_logo.png';
import { PlusIcon } from '../../assets/svg/index.js';

// Import Header and Constants

import { checkPermission, getHeaderTitle } from '../../helpers';
import appConstants from '../../constants/appConstants.js';

// Import Reducer
import { navigate } from '../../redux/reducer/navigationReducer';
import { catalogSearchUpdate, catalogListRequest } from '../../redux/reducer/catalogReducer';
import featureConstants from '../../constants/featureConstants.js';
import Notification from './components/notification.jsx';


const HeaderComponent = (props) => {

    /**
     * Define Props
     */
    const { classes, scrollRef } = props;
    const dispatch = useDispatch();
    const reactNavigation = useNavigate();
    const searchControllerRef = useRef();
    const [showLogo, setShowLogo] = useState(false);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { search_by, sortBy, orderBy, limit, search_key, filters, tabIndex, offset, isLoading } = useSelector((state) => state.catalog.search, shallowEqual);
    const { logo } = useSelector((state) => state.configurations.theme, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const connPermission = checkPermission(permission, featureConstants.settings.connections);

    /**
     * Get Current Route Name
     */
    const location = useLocation();
    const routeName = location.pathname.split('/')[1];
    const reactNavigate = useNavigate();
    const isCatalogPage = routeName === 'catalog';

    /**
     * Show Logo
     * @returns
     */
    const showLogoandTitle = () => {
        const hideLogoPage = ['home'];
        return hideLogoPage.indexOf(routeName) < 0;
    };

    /**
     * Show Search Box
     * @returns
     */
    const showSearchBox = () => {
        const hideSearchBoxPage = ['home'];
        return hideSearchBoxPage.indexOf(routeName) < 0;
    };

    /**
     * Navigate to New Connector View
     */
    const navigateConnectorPage = () => {
        dispatch(navigate({ path: 'connector.root', state: {}, params: [] }));
    };

    /**
     * Load Catalogs
     * @param {*} params
     * @param {*} clear
     */
    const loadCatalogs = (params, clear = false) => {

        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        const requestParams = {
            search_by,
            tabIndex,
            sortBy,
            orderBy,
            limit,
            offset: clear ? 0 : offset,
            search_key,
            filters,
            ...params
        };
        dispatch(catalogListRequest({ params: requestParams, token, clear }));
    };


    /**
     * Handle Search Event
     * @param {*} value
     */
    const search = (value) => {
        const searchKey = value?.search_key ?? '';
        if (isCatalogPage) {
            loadCatalogs({ search_key: searchKey }, true);
        } else {
            dispatch(catalogSearchUpdate({ "search_key": searchKey }));
            reactNavigate(`/catalog`, { replace: true });
        }
    };

    /**
     * Update Search Key
     * @param {*} value
     */
    const updateSearchKey = (value) => {
        dispatch(catalogSearchUpdate({ "search_key": value }));
    };

    /**
     * Navigate to Home Page
     */
    const navigateHome = () => {
        dispatch(navigate({ path: 'home.root', state: {}, params: [] }));
    };

    useEffect(() => {
        if (scrollRef) {
            scrollRef.addEventListener("scroll", () => {
                if (scrollRef.scrollTop > 400) {
                    setShowLogo(true);
                }
                else {
                    setShowLogo(false);
                }
            });
        }
    }, [scrollRef]);

    return (
        <Grid item xs={12}>
            <Grid
                container
                className={classes.navbar}
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item className={classes.logoSection}>
                    {
                        !showLogoandTitle() && showLogo &&
                        <Zoom in={showLogo} mountOnEnter unmountOnExit>
                            <Grid className={classes.logoSectionDiv}>
                                <Grid onClick={() => navigateHome()}>
                                    <img src={(logo?.length > 0 ? logo : DefaultLogo)} className={`${classes.appLogo} appLogo`} alt="app logo" />
                                </Grid>
                            </Grid>
                        </Zoom>
                    }
                    {
                        showLogoandTitle() &&
                        <React.Fragment>
                            <Grid className={classes.logoSectionDiv}>
                                <Grid onClick={() => navigateHome()}>
                                    <img src={(logo?.length > 0 ? logo : DefaultLogo)} className={`${classes.appLogo} appLogo`} alt="app logo" />
                                </Grid>
                                <IconButton className={`${classes.backNavigation} backNavigation`} onClick={() => reactNavigation(-1)}>
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                            </Grid>
                            <Typography variant="h2" className={classes.pageTitle}>
                                {getHeaderTitle(location.pathname)}
                            </Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid className={classes.globalSearch}>
                    {
                        showSearchBox() &&
                        <SearchComponent
                            value={search_key}
                            placeholder={appConstants.labels.home.globalSearch}
                            searchPosition="right"
                            showDqLogo
                            onEnterKeyPress={(value, type) => search(value, type)}
                            clearSearchEvent={() => search('')}
                            onChange={(value) => updateSearchKey(value)}
                            isLoading={isLoading}
                        />
                    }
                </Grid>
                <Grid item className="navRightside">
                    {
                        connPermission?.is_edit &&
                        <Tooltip title="Add Connection" arrow>
                            <IconButton className="p5 mr5" id="add-connector" onClick={() => navigateConnectorPage()}>
                                <PlusIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Notification classes={classes} />
                    {/* <Tooltip title="Help" arrow>
                        <IconButton className="p5 mr5">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Profile classes={classes} />
                </Grid>
            </Grid>
        </Grid>
    );
};

// default props
HeaderComponent.defaultProps = {
    classes: {}
};

// prop types
HeaderComponent.propTypes = {
    classes: PropTypes.object,
    scrollRef: PropTypes.instanceOf(HTMLDivElement)
};

export default withStyles(
    (theme) => ({
        ...style(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(HeaderComponent);