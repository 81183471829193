import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';

// Styles
import FeaturesStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { DeleteIcon, PlusIcon } from '../../../../assets/svg';

// Import Constatns
import appConstants from '../../../../constants/appConstants';

// Import Components
import ImageCropper from '../../../../components/imageCrop/ImageCropper.jsx';

function BannerSection(props) {

    /**
     * Define Props
     */
    const { classes, handleBannerImages, banner_list, delete_banner, editPermission } = props;

    // Initial State
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);

    // Handle Banner Upload Image
    const handleImgUpload = () => {
        document.getElementById('domainImage').click();
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    setImageToCrop(reader.result.toString() || '');
                    setImageDialog(true);
                }
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };


    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        if (croppedImage) {
            handleBannerImages(croppedImage, croppedImageUrl);
            return;
        }
        croppedImage = selectedFile;
        croppedImageUrl = window.URL.createObjectURL(croppedImage);
        handleBannerImages(croppedImage, croppedImageUrl, 'new');
    };

    return (
        <Grid item className={classes.logoSection}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.theme.banner}
                </Typography>
            </Grid>
            <Grid className={classes.bodySection}>
                <Typography variant="body1" className="mb-3">
                    {appConstants.labels.theme.bannerDesc}
                </Typography>
                <Grid container spacing={3}>
                    {
                        banner_list.map((banner) =>
                            <Grid item xs={3} key={banner.id}>
                                <Grid item className={classes.bannerCard}>
                                    {
                                        editPermission &&
                                        <IconButton className={classes.deleteIcon} onClick={() => delete_banner(banner.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    {
                                        banner?.banner?.length > 0 &&
                                        <img src={banner.banner} className={classes.bannerImg} alt="bannerimage" />
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    <input
                        accept="image/*"
                        className={classes.domainImgInput}
                        id="domainImage"
                        type="file"
                        onChange={handleSelectFile}
                    />
                    {
                        openImageDialog && (
                            <ImageCropper
                                open={openImageDialog}
                                onClose={() => setImageDialog(false)}
                                imageToCrop={imageToCrop}
                                aspect={16 / 9}
                                onImageCropped={
                                    (croppedImage, croppedImageUrl) =>
                                        updateCropImage(croppedImage, croppedImageUrl)
                                }
                            />
                        )
                    }
                    {
                        editPermission &&
                        <Grid item xs={3}>
                            <Grid item className={classes.addBannerCard} onClick={handleImgUpload}>
                                <Grid className="dflex alignCenter">
                                    <IconButton>
                                        <PlusIcon />
                                    </IconButton>
                                    <Typography variant="body1" className={classes.textSecondary}>
                                        {appConstants.labels.theme.addBanner}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>

            </Grid>
        </Grid>
    );
}

// default props
BannerSection.defaultProps = {
    classes: {},
    handleBannerImages: () => { },
    banner_list: [],
    delete_banner: () => { },
    editPermission: []
};

// prop types
BannerSection.propTypes = {
    classes: PropTypes.object,
    handleBannerImages: PropTypes.func,
    banner_list: PropTypes.array,
    delete_banner: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...FeaturesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(BannerSection);