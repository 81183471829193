const style = () => ({
    linearContainer: {
        display: 'flex',
        alignItems: 'center',
        '& .valuesText': {
            width: '28px !important',
            marginLeft: 8,
            textAlign: "right"
        }
    },
    progressBar: {
        width: 'calc(100% - 60px)',
        '& .MuiLinearProgress-root': {
            height: 12
        }
    }
});
export default style;