import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getDetailRequest, getDetailSuccess, getDetailFailure, updateDetailRequest, updateDetailSuccess, updateDetailFailure, getLinkedAssetRequest, getLinkedAssetSuccess,
    getLinkedAssetFailure, unLinkAssetRequest, unLinkAssetSuccess, unLinkAssetFailure, deleteTermRequest, deleteTermSuccess, deleteTermFailure,
    linkAssetRequest, linkAssetSuccess, linkAssetFailure, getTermsRequest, getTermsSuccess, getTermsFailure, getMappedTermsListRequest,
    getMappedTermsListSuccess, getMappedTermsListFailure, getMappedTermsListFilterRequest, getMappedTermsListFilterSuccess, getMappedTermsListFilterFailure
} from '../reducer/termReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { termService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

/**
 * Get Terms List
 * @returns
 */
const list = (action$) => action$.pipe(
    ofType(getTermsRequest),
    mergeMap(({ payload }) => {
        return from(termService.list(payload)).pipe(
            concatMap((res) => { return of(getTermsSuccess({ 'requested_data': payload, ...res })); }),
            catchError((error) => { return of(getTermsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Term Detail
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getDetailRequest),
    mergeMap(({ payload }) => {
        return from(termService.detail(payload)).pipe(
            concatMap((res) => { return of(getDetailSuccess(res)); }),
            catchError((error) => { return of(getDetailFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Term Detail
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateDetailRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(termService.update(id, rest)).pipe(
            concatMap((res) => { return of(updateDetailSuccess(res)); }),
            catchError((error) => { return of(updateDetailFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get Linked Assets
 * @param {*} payload
 * @returns
 */
const getLinkedAssets = (action$) => action$.pipe(
    ofType(getLinkedAssetRequest),
    mergeMap(({ payload }) => {
        return from(termService.getLinkedAssets(payload)).pipe(
            concatMap((res) => { return of(getLinkedAssetSuccess(res)); }),
            catchError(() => { return of(getLinkedAssetFailure()); })
        );
    })
);

/**
 * Link Assets
 * @param {*} payload
 * @returns
 */
const linkAssets = (action$) => action$.pipe(
    ofType(linkAssetRequest),
    mergeMap(({ payload }) => {
        return from(termService.linkAssets(payload)).pipe(
            concatMap(() => { return of(linkAssetSuccess(payload)); }),
            catchError(() => { return of(linkAssetFailure()); })
        );
    })
);

/**
 * Un Linked Assets
 * @param {*} payload
 * @returns
 */
const unLinkAsset = (action$) => action$.pipe(
    ofType(unLinkAssetRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(termService.unLinkAsset(id, rest)).pipe(
            concatMap((res) => { return of(unLinkAssetSuccess(res)); }),
            catchError(() => { return of(unLinkAssetFailure()); })
        );
    })
);

/**
 * Un Linked Assets
 * @param {*} payload
 * @returns
 */
const deleteTerm = (action$) => action$.pipe(
    ofType(deleteTermRequest),
    mergeMap(({ payload }) => {
        return from(termService.remove(payload)).pipe(
            concatMap((res) => { return of(deleteTermSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted })); }),
            catchError(() => { return of(deleteTermFailure()); })
        );
    })
);


/**
 * Get Mapped Terms List for Asset With Count
 * @returns
 */
const mappedList = (action$) => action$.pipe(
    ofType(getMappedTermsListRequest),
    mergeMap(({ payload }) => {
        return from(termService.mappedList(payload)).pipe(
            concatMap((res) => { return of(getMappedTermsListSuccess(res)); }),
            catchError((error) => { return of(getMappedTermsListFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Mapped Terms List for Asset With Count
 * @returns
 */
const mappedListFilter = (action$) => action$.pipe(
    ofType(getMappedTermsListFilterRequest),
    mergeMap(({ payload }) => {
        return from(termService.mappedListFilter(payload)).pipe(
            concatMap((res) => { return of(getMappedTermsListFilterSuccess(res)); }),
            catchError((error) => { return of(getMappedTermsListFilterFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Terms Functions
export const termEpic = [list, detail, update, getLinkedAssets, linkAssets, unLinkAsset, deleteTerm, mappedList, mappedListFilter];