import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { Grid } from '@mui/material';
//  Import Components


//  Import Styles
import BarChartViewStyle from '../style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';
import palette from '../../../assets/theme/palette.js';
import appConstants from '../../../constants/appConstants.js';

function StatisticsChart(props) {

    /**
     * Define Props
     */
    const { classes, theme, statistics, onChartClick } = props;

    /**
     * Define State Variables
     */
    const [chartInstance, setChartInstance] = useState(null);
    const mean = parseFloat(statistics?.mean || 0);
    const standardDeviation = parseFloat(statistics?.standard_deviation || 0);
    const sigmas = [-3, -2, -1, 0, 1, 2, 3];
    const hasNormalDistribution = (mean > 0 || standardDeviation > 0);

    /**
     * Define the fucntion, which converts exponential values to decimal
     */
    const exponentToDecimal = (value, precision = 0) => {
        if ((0.9).toFixed() !== '1') {
            return value.toFixed(precision);
        }
        const pow = Math.pow(10, precision);
        return (Math.round(value * pow) / pow).toFixed(precision);
    };

    //const xValues = sigmas.map((sigma) => mean + sigma * standardDeviation);
    const yValues = sigmas.map((sigma) => {
        let y = 0;
        if (hasNormalDistribution) {
            const x = mean + sigma * standardDeviation;
            const exponent = -0.5 * Math.pow((x - mean) / standardDeviation, 2);
            y = (1 / (standardDeviation * Math.sqrt(2 * Math.PI))) * Math.exp(exponent);
            y = exponentToDecimal(y, 10);
        }
        return { sigma, y };
    });

    /**
     * Handle the click event here
     */
    const clickHandler = (params) => {
        const sigma = params.name.replace('σ', '');
        const nsigma = (0 - sigma);
        const x = mean + sigma * standardDeviation;
        const y = mean + nsigma * standardDeviation;
        const range = [x, y]
                        .slice()
                        .sort((a, b) => a - b)
                        .map((number) => String(Math.round(number, 2)));
        const requestParams = {
            range: range
        };
        // Call the function passed from the parent component
        onChartClick(requestParams);
    };


    const handleChartClick = useCallback((chart) => {
        if (!chart) {
            return false;
        }
        chart.on('click', clickHandler);
    }, [chartInstance]);

    /**
     * Define Chart Options
     */
    const option = {
        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },
        tooltip: {
            trigger: 'item',
            formatter: ((params) => {
                const sigma = params.name.replace('σ', '');
                const x = mean + sigma * standardDeviation;
                return `Sigma: ${sigma}σ<br />
                    Probability Density: ${params.value}<br />
                    Actual Value: ${Math.round(x, 2)}`;
            })
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                    title: 'Download',
                    type: "png",
                    show: false,
                    name: "Normal Distribution",
                    connectedBackgroundColor: theme.palette.greyshades.darkgrey
                }
            }
        },
        xAxis: [
            {
                type: "category",
                data: sigmas.map((sigma) => {
                    return (sigma !== 0) ? `${sigma}σ` : sigma;
                }),
                /*
                 * name: "",
                 * nameLocation: "middle",
                 * nameTextStyle: {
                 *  padding: [10, 0, 0, 0]
                 * },
                 */
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    onZero: true,
                    lineStyle: {
                        color: theme.palette.greyshades.darkgrey,
                        width: 1
                    }
                },
                axisLabel: {
                    show: true,
                    rotate: 0
                },
                axisTick: {
                    show: false,
                    alignWithLabel: false
                },
                offset: 0
            },
            {
                type: "category",
                data: sigmas.map((sigma) => {
                    return (mean - sigma * standardDeviation).toFixed(2);
                }),
                name: "Standard Deviation from the Mean",
                nameLocation: "middle",
                nameTextStyle: {
                    padding: [20, 0, 0, 0]
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    onZero: true,
                    lineStyle: {
                        color: theme.palette.greyshades.darkgrey,
                        width: 1
                    }
                },
                axisLabel: {
                    show: true,
                    rotate: 0
                },
                axisTick: {
                    show: false,
                    alignWithLabel: false
                },
                position: 'bottom',
                offset: 35
            }
        ],
        yAxis: {
            type: "value",
            name: "Probability Density",
            nameLocation: "middle",
            nameTextStyle: {
                padding: [0, 0, 10, 0]
            },
            splitLine: {
                show: false
            },
            /*
             * min: parseFloat(minY.toFixed(4)),
             * max: parseFloat(maxY.toFixed(4)),
             */
            axisLine: {
                show: true,
                onZero: true,
                lineStyle: {
                    color: theme.palette.greyshades.darkgrey,
                    width: 1
                }
            },
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false,
                alignWithLabel: false
            }
        },
        /**
         * Set position of the canvas
         */
        grid: {
            top: '8%',
            left: '10%',
            right: '5%',
            bottom: '22%',
            containLabel: false
        },
        series: [
            {
                type: "bar",
                data: yValues.map((item) => ({
                    value: item.y,
                    symbol: "pointer",
                    itemStyle: {
                      color: theme.palette.greyshades.grey
                    }
                })),
                barMaxWidth: 0.5,
                smooth: true
            },
            {
                type: "line",
                data: yValues.map((item) => ({
                    value: item.y,
                    symbol: "pointer",
                    areaStyle: {},
                    itemStyle: {}
                })),
                smooth: true,
                symbol: 'circle',
                symbolSize: 10,
                itemStyle: {
                    color: '#c8eeff'
                    /*
                     *  borderWidth: 3,
                     *  borderColor: theme.palette.greyshades.darkgrey,
                     */
                },
                label: {
                    show: true,
                    position: "top",
                    padding: [0, 0, 5, 0],
                    rich: {
                        name: {
                            color: theme.palette.greyshades.darkestgrey
                        },
                        value: {
                            padding: [7, 0, 2, 0],
                            color: theme.palette.greyshades.darkgrey
                        }
                    }
                },
                areaStyle: {
                    color: '#c8eeff'
                },
                lineStyle: {
                    type: 'solid',
                    color: "#9ed9f394",
                    width: 2
                }
            }
        ],
        // Add the onEvents property to handle the click event
        click: handleChartClick(chartInstance)
    };

    return (
        <Grid container justifyContent={'space-between'} alignItems="center">
            <Grid
                item
                xs={12}
                className={classnames(classes.statisticsBarChartView)}
            >
                {
                    (mean && standardDeviation) ?
                    <ReactEcharts
                        option={option}
                        opts={{ renderer: 'svg' }}
                        id="barChart_Id"
                        className={'profileChart'}
                        style={{ height: 400 }}
                        onChartReady={(chart) => setChartInstance(chart)}
                    />
                    : null
                }
            </Grid>
        </Grid>
    );
}

// default props
StatisticsChart.defaultProps = {
    classes: {},
    theme: {},
    statistics: {},
    onChartClick: () => {}
};

// prop types
StatisticsChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    statistics: PropTypes.object,
    onChartClick: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...BarChartViewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StatisticsChart);