import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Dialog } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import PreviewRunResultStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';
import { PreviewQuery } from '../previewQuery/index.jsx';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { validateAssetReportMappingRequest } from '../../../../../redux/reducer/assetReducer.js';


function DbtTestsResultsComponent(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { report_mapped_asset, id: asset_id } = useSelector((state) => state.asset);


    /**
     * Validate Asset Mapping
     */
    useEffect(() => {
        dispatch(validateAssetReportMappingRequest(asset_id));
    }, [asset_id]);

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });
    const [previewQueryShow, setPreviewQueryShow] = useState(false);
    const [previewQuery, setPreviewQuery] = useState({});


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], searchData, sorting.sortBy, sorting.orderBy), [data || [], searchData, sorting.sortBy, sorting.orderBy]);


    /**
     * Handle Table Action
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = (item, actionType) => {
        if (actionType === 'query_view') {
            setPreviewQuery(item);
            setPreviewQueryShow(true);
        }
    };

    /**
     * Close Dialog
     */
    const handleCloseDialog = () => {
        setPreviewQueryShow(false);
        setPreviewQuery({});
    };

    return (
        <Grid item xs={12} className={classes.attributeListContainer}>
            <TableComponent
                title="Tests"
                headers={appConstants.table.dbtTestsListHeader}
                stickyHeader
                data={filterData}
                options={appConstants.tableOptions.common}
                searchData={searchData}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                onHandleSearchEvent={onHandleSearchEvent}
                onClickSorting={onClickSorting}
                styleType="striped"
                NoResultText="No Records Found"
                height="450px"
                onClickActions={handleTableActions}
            />
            <Dialog
                open={previewQueryShow}
                onClose={handleCloseDialog}
                fullScreen
            >
                <PreviewQuery name={previewQuery?.name ?? ''} report_mapped_asset_id={report_mapped_asset?.id ?? ''} closeDialog={handleCloseDialog} rawSql={previewQuery?.rawSql ?? ''} compiledSql={previewQuery?.compiledSql ?? ''} />
            </Dialog>
        </Grid>
    );
}

// default props
DbtTestsResultsComponent.defaultProps = {
    classes: {},
    data: []
};

// prop types
DbtTestsResultsComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export As Memo Component
 */
export const DbtTestResults = withStyles(
    (theme) => ({
        ...PreviewRunResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(DbtTestsResultsComponent, areEqual));