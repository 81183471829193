import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

// Import Componetns
import CommentsList from '../../../../../../components/richTextEditor/timeline/index.jsx';
import { RichTextEditorComponent } from '../../../../../../components/index';

// Import Styles
import CommentsStyle from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Actions
import { createCommentRequest, updateCommentRequest, getCommentRequest, deleteCommentRequest } from '../../../../../../redux/reducer/issuesReducer';
import { getAttributesTagsListRequest } from '../../../../../../redux/reducer/conversationReducer.js';

function Comments(props) {

    /**
     * Define Props
     */
    const { classes, issuePermission } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const { user } = useSelector((state) => state.auth);
    const { users_list } = useSelector((state) => state.user, shallowEqual);
    const { id: issueId, comments } = useSelector((state) => state.issues);
    const { attributesTagList } = useSelector((state) => state.conversation);

    /**
     * Handle Comment
     * @param {*} formData
     */
    const handleCreateMessage = (formData) => {
        if (formData.id) {
            dispatch(updateCommentRequest(formData));
        } else {
            formData.created_by = user.id;
            formData.organization = user.organization.id;
            formData.issue = issueId;
            formData.user = { id: user.id ? user.id : '', name: `${user.first_name} ${user.last_name}` };
            dispatch(createCommentRequest(formData));
        }
    };

    /**
     * Delete Comment
     * @param {*} id
     */
    const handleDeleteMessage = (id) => {
        dispatch(deleteCommentRequest(id));
    };

    /**
     * Get Comments
     */
    useEffect(() => {
        if (issueId) {
            dispatch(getCommentRequest(issueId));
        }
    }, [issueId]);

    /**
     * Get Asset and Attribute List
     */
    useEffect(() => {
        if (!attributesTagList || attributesTagList.length === 0) {
            const requestData = {
                all: true
            };
            dispatch(getAttributesTagsListRequest(requestData));
        }
    }, [dispatch]);

    return (
        <Grid className={classes.pageContainer}>
            {
                issuePermission?.is_edit &&
                <Grid className={classes.textEditorWrapper}>
                    <RichTextEditorComponent
                        attributes={attributesTagList}
                        users_list={users_list}
                        formSubmit={handleCreateMessage}
                        formFields={{ warning: false, showRating: true }}
                    />
                </Grid>
            }
            <CommentsList
                data={comments}
                attributes={attributesTagList}
                users_list={users_list}
                handleDeleteMessage={handleDeleteMessage}
                handleCreateMessage={handleCreateMessage}
                permission={issuePermission}
                formFields={{ warning: false, showRating: true }}
                actions={['edit', 'delete']}
                singleLine
            />
        </Grid>
    );
}

// default props
Comments.defaultProps = {
    classes: {},
    issuePermission: {}
};

// prop types
Comments.propTypes = {
    classes: PropTypes.object,
    issuePermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...CommentsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Comments);