import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

//  Import Components
import { AssetCardComponent, LoaderComponent, NoResultComponent } from '../../../../components/index.js';

// Import Actions
import { getApplicationAssetsRequest } from '../../../../redux/reducer/applicationReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';

function ApplicationSummary(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * get Application ID from URL
     */
    const { id: application_id } = useParams();

    /**
     * Get Reducer Values
     */
    const { assets, assetsLoading } = useSelector((state) => state.applications);

    /**
     * Get Application Assets Request
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        if (application_id) {
            dispatch(getApplicationAssetsRequest(application_id));
        }
    }, [application_id]);

    /**
     * Navigate To Assets
     * @param {*} selectedItem
     */
    const handleNavigation = (selectedItem) => {
        dispatch(navigate({ path: 'assets.root', state: {}, params: [selectedItem.source_id] }));
    };

    return (
        <Grid container className={classes.tabsRootContainer}>
            <Grid item xs={12}>
                <Typography variant="h6" className="mb7" align="left">
                    {`Assets (${assets?.length ?? 0})`}
                </Typography>
                <Grid container spacing={3} className="pt-1">
                    {
                        assetsLoading ?
                            <LoaderComponent
                                sx={{ paddingTop: '24px' }}
                                xsCard={3}
                                cardHeight="175px"
                                loaderType="card"
                                height="auto" />
                            :
                            assets.map((item, index) => (
                                <Grid item xs={4} md={3} key={`asset_${index}`} align="left">
                                    <AssetCardComponent
                                        data={item}
                                        handleNavigation={(selectedItem) => handleNavigation(selectedItem)}
                                    />
                                </Grid>
                            ))
                    }
                    {
                        !assetsLoading && !assets.length &&
                        <NoResultComponent title="No Assets Found" />
                    }
                </Grid>
            </Grid>

        </Grid>
    );
}

// default props
ApplicationSummary.defaultProps = {
    classes: {}
};

// prop types
ApplicationSummary.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ApplicationSummary);