import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextBoxComponent } from '../../../../../../components/index';
import ImageCropper from '../../../../../../components/imageCrop/ImageCropper.jsx';

//  Import Styles
import GlossaryAddStyle from "./style.jsx";
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import { CameraIcon, DeleteIcon } from '../../../../../../assets/svg/index.js';
import Placeholder from '../../../../../../assets/img/placeholder.png';

// import Action
import { toggleAddGlossary, createGlossaryRequest } from '../../../../../../redux/reducer/semanticReducer';

function GlossaryAdd(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const formRef = useRef();
    const [glossary, setGlossary] = useState({ name: "", technical_name: "", description: "", image: null, image_blob: null });
    const { organization: { id: organizationId } } = useSelector((state) => state.auth.user);

    // Initial State
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);

    //img upload
    const handleImgUpload = () => {
        document.getElementById('domainImage').click();
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        glossary.image = croppedImage;
        glossary.image_blob = croppedImageUrl;
        setGlossary({ ...glossary });
    };

    /**
     * Handle On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        glossary[property] = value;
        setGlossary({ ...glossary });
    };

    /**
     * On Close Glossary
     */
    const onCloseGlossary = () => {
        dispatch(toggleAddGlossary());
    };

    /**
     * Trigger Submit Event
     */
    const triggerSubmit = () => {
        formRef.current.submit();
    };

    /**
     * Save Glossary
     */
    const onSaveGlossary = () => {
        if (glossary.name !== "") {
            const requestParams = {
                ...glossary,
                technical_name: glossary.name,
                type: "domain",
                organization: organizationId
            };
            dispatch(createGlossaryRequest(requestParams));
        }
    };


    return (
        <Grid item xs={4} lg={4} xl={2}>
            <Grid className={classes.domainCard} onMouseLeave={() => triggerSubmit()}>
                <Grid item className={classes.imgContainer}>
                    <img src={glossary.image_blob || Placeholder} alt="DomainCard img" />
                </Grid>
                <Grid item className={`${classes.detail} detail`}>
                    <ValidatorForm onSubmit={() => onSaveGlossary()} ref={formRef}>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="pb-1"
                            wrap="noWrap"
                        >
                            <TextBoxComponent
                                className={classes.title}
                                noOutline
                                placeholder="Enter Name"
                                variant="standard"
                                name="name"
                                value={glossary.name || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                validators={['required']}
                                errorMessages={["Name is required"]}
                                valOnChange
                            />
                            <Grid className="dflex alignCenter">
                                <input
                                    accept="image/*"
                                    className={classes.domainImgInput}
                                    id="domainImage"
                                    type="file"
                                    onChange={handleSelectFile}
                                />
                                {
                                    openImageDialog && (
                                        <ImageCropper
                                            open={openImageDialog}
                                            onClose={() => setImageDialog(false)}
                                            imageToCrop={imageToCrop}
                                            aspect={16 / 9}
                                            onImageCropped={
                                                (croppedImage, croppedImageUrl) =>
                                                    updateCropImage(croppedImage, croppedImageUrl)
                                            }
                                        />
                                    )
                                }
                                <Tooltip title="Upload image" arrow>
                                    <IconButton
                                        className="p2 mr5 cameraIcon"
                                        onClick={handleImgUpload}
                                    >
                                        <CameraIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" arrow>
                                    <IconButton
                                        className="p2"
                                        onClick={() => onCloseGlossary()}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <TextBoxComponent
                            className={classes.description}
                            placeholder="Enter Description"
                            name="description"
                            fullWidth
                            multiline
                            variant="standard"
                            valOnChange
                            rows={3}
                            value={glossary.description || ""}
                            onChange={(event) => onChange(event.target.name, event.target.value)} />
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
GlossaryAdd.defaultProps = {
    classes: {}
};

// prop types
GlossaryAdd.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GlossaryAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GlossaryAdd);