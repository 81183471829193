import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';

//import Styles
import HeaderStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//import Componetns
import { AutoCompleteComponent, StatusDropDownComponent } from '../../../../components/index.js';
import CircularScore from '../../../../components/circularScore/index.jsx';

// Import Images
import { PlayIcon2, ThunderIcon } from '../../../../assets/svg/index.js';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Helper
import { nFormatter, dFormatter, checkPermission, getApprovalStatus, orderList } from '../../../../helpers/appHelpers.js';

// Import Actions
import { updateAttributeMetadataRequest, updateAttributeMetadata } from '../../../../redux/reducer/attributeReducer';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';
import palette from '../../../../assets/theme/palette.js';
import InvalidRecordPreview from './components/invalidRecordPreview.jsx';
import { triggerJobRequest } from '../../../../redux/reducer/scheduleReducer.js';
import { PauseCircleOutlineRounded } from '@mui/icons-material';


function Header(props) {

    /**
     * Define Props
     */
    const { classes, theme, data, onClickFilter } = props;
    const dispatch = useDispatch();
    const [attirubtesList, setAttributesList] = useState([]);
    const { id: assetId, attribute_id: attributeId } = useParams();
    const [invalidDialogHandler, setInvalidDialogHandler] = useState({
        open: false,
        assetId: "",
        attributeId: ""
    });

    /**
     * Reducer Action
     */
    const { list: attributes, detail, version_id } = useSelector((state) => state.attribute);
    const { detail: versionDetail, alerts: alertCount } = useSelector((state) => state.version);
    const { measures } = useSelector((state) => state.measure);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);

    /**
     * Set Attributes List
     */
    useEffect(() => {
        let attributesList = JSON.parse(JSON.stringify(attributes));
        attributesList = orderList(attributesList, "name", "asc");
        setAttributesList([{ id: 'All', name: 'All', derived_type: 'All' }, ...attributesList]);
    }, [version_id, attributes]);

    /**
     * Handle onChange Event
     * @param property
     * @param value
     */
    const handleOnChange = (property, value) => {
        const requestParams = {
            [property]: value,
            attribute_id: attributeId
        };
        dispatch(updateAttributeMetadata(requestParams));
        if (property === "status") {
            requestParams.changed_properties = [property];
            requestParams.values = {
                [property]: value
            };
        }
        dispatch(updateAttributeMetadataRequest(requestParams));
    };

    /**
     * on Handle Change Seclect Attribute
     * @param {object} attribute
     */
    const onSelectAttribute = (attribute) => {
        if (attribute && attribute.id !== 'All') {
            dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [assetId, attribute.id] }));
        } else {
            dispatch(navigate({ path: 'assets.properties', state: {}, params: [assetId] }));
        }
    };

    /**
     * Get Attribute Name
     * @param {*} attribute_list
     * @param {*} attributeId
     * @returns
     */
    const getAttributeName = (attribute_list, attributeId) => {
        const attribute = attribute_list.find((obj) => obj.id === attributeId);
        return attribute ? attribute : { id: 'All', name: 'All', derived_type: 'All' };
    };

    /**
     * Get Last Run status
     * @param {*} measures
     * @returns
     */
    const getLastRUnStatus = (measures) => {
        const runStatus = {};
        for (const measure of measures) {
            runStatus[measure.name] = measure.last_runs[measure.last_runs.length - 1]?.status;
        }
        return runStatus;
    };

    /**
     * Get Status Color
     * @param {*} status
     * @returns
     */
    const getColor = (status) => {
        switch (status) {
            case "low":
                return palette.colorThemes.low;
            case "high":
                return palette.colorThemes.high;
            case 'medium':
                return palette.colorThemes.medium;
            default:
                return palette.headers.body1.color;
        }
    };

    /**
     * Handle invalid record preview
     * @param {*} event
     * @param {*} invalidRowsCount
     * @returns
     */
    // eslint-disable-next-line no-unused-vars
    const onClickInvalidRecord = useCallback((event, invalidRowsCount) => {
        event.stopPropagation();
        if (!invalidRowsCount) {
            return;
        }
        setInvalidDialogHandler({
            open: true,
            assetId: detail?.asset_id,
            attributeId: detail?.id ?? ""
        });
    }, [data]);


    /**
     * Handle invalid record preview
     * @param {*} event
     * @param {*} invalidRowsCount
     * @returns
     */
    const onCloseInvalidPreviewDialog = () => {
        setInvalidDialogHandler({
            open: false,
            assetId: "",
            attributeId: ""
        });
    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (status) => {
        if (status === 'Completed') {
            if (versionDetail?.run_status !== "Completed") {
                return;
            }
            dispatch(triggerJobRequest({ "asset_id": assetId, attribute_id: attributeId, job_type: "asset" }));
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [versionDetail.connection_id || versionDetail.connection] }));
        }
    };

    /**
     * Get Attribute Name Using Use Memo
     */
    const attribute = useMemo(() => getAttributeName(attributes, attributeId), [attributes, attributeId]);
    const lastRun = useMemo(() => getLastRUnStatus(measures), [measures]);
    const isAssetLevel = attribute?.id === "All";
    return (
        <Grid container justifyContent={"space-between"} className={classes.headerContainer}>
            <Grid item className="dflex alignCenter">
                {
                    attribute?.derived_type?.length > 0 &&
                    <Grid className="">
                        {
                            <Tooltip title={attribute?.derived_type ?? ''} arrow>
                                <Typography
                                    variant="body2"
                                    className={classes.headerDTIcon}
                                    style={{ "visibility": isAssetLevel ? 'hidden' : 'visible' }}
                                >
                                    {attribute?.derived_type.charAt(0) || 'T'}
                                </Typography>
                            </Tooltip>
                        }
                    </Grid>

                }
                <Grid item sx={{ width: 300 }}>
                    <Tooltip title={attribute?.name ?? 'All'} arrow>
                        <AutoCompleteComponent
                            data={attirubtesList || []}
                            selectedValue={attribute?.name ?? 'All'}
                            customOption
                            blurOnSelect
                            labelKey={"name"}
                            renderType={'attributes'}
                            onChange={(event, newValue) => onSelectAttribute(newValue)}
                            placeholder="Select Attribute"
                        />
                    </Tooltip>
                </Grid>
                {
                    (!isAssetLevel && !(publishPermission?.is_none && approvePermission?.is_none)) &&
                    <Grid className={"ml-1"}>
                        <StatusDropDownComponent
                            value={data?.status || "Pending"}
                            onChange={(value) => handleOnChange("status", value)}
                            dropdownValue={getApprovalStatus(publishPermission, approvePermission, data?.status || "Pending")}
                            disabled={data?.status === appConstants.status[3] || (!publishPermission?.is_edit && !approvePermission?.is_edit)}
                        />
                    </Grid>
                }
                {
                    (!isAssetLevel && data?.datatype?.length > 0) &&
                    <Tooltip title={"Source Datatype"} arrow>
                        <Grid className={"ml-1"}>
                            <Typography className={classes.headerDTIcon}>
                                {data?.datatype}
                            </Typography>
                        </Grid>
                    </Tooltip>
                }
            </Grid>
            {
                isAssetLevel &&
                <React.Fragment>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("All")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.active_measures || 0}
                            </Typography>
                        </Grid>
                        <Typography variant="body1" >
                            {appConstants.labels.datasetProperties.active}
                        </Typography>
                    </Grid>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("Monitoring")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.observed_measures || 0}
                            </Typography>
                            {
                                alertCount?.length > 0 &&
                                <Grid className="thunderIcon" onClick={() => { }}>
                                    <ThunderIcon />
                                </Grid>
                            }
                        </Grid>
                        <Typography variant="body1" >
                            {appConstants.labels.datasetProperties.monitored}
                        </Typography>
                    </Grid>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("Scoring")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.scored_measures || 0}
                            </Typography>
                        </Grid>
                        <Typography variant="body1" >
                            {appConstants.labels.datasetProperties.scored}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.circular}>
                        <CircularScore size={48} value={data.dqscore || 0} showValue />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="" color={getColor(lastRun?.Schema)}>
                            {data.column_count || 0}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.columns}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="" color={getColor(lastRun?.Volume)}>
                            {nFormatter(data.row_count || 0)}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.volume}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="" color={getColor(lastRun?.Freshness)}>
                            {data.freshness ? dFormatter(data.freshness || 0) : 'NA'}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.freshness}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="" color={getColor(lastRun?.Duplicates)}>
                            {nFormatter(data.duplicate_count || 0)}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.duplicate}
                        </Typography>
                    </Grid>
                </React.Fragment>
            }

            {
                !isAssetLevel &&
                <React.Fragment>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("All")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.activeMeasures || 0}
                            </Typography>
                        </Grid>
                        <Typography variant="body1" >
                            {appConstants.labels.datasetProperties.active}
                        </Typography>
                    </Grid>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("Monitoring")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.observedMeasures || 0}
                            </Typography>
                            {
                                data.alerts > 0 &&
                                <Grid className="thunderIcon" onClick={() => { }}>
                                    <ThunderIcon />
                                </Grid>
                            }
                        </Grid>
                        <Typography variant="body1">
                            {appConstants.labels.datasetProperties.monitored}
                        </Typography>
                    </Grid>
                    <Grid item className="measureFilter" onClick={() => onClickFilter("Scoring")}>
                        <Grid className="measureTile">
                            <Typography variant="body1">
                                {data.scoredMeasures || 0}
                            </Typography>
                        </Grid>
                        <Typography variant="body1" >
                            {appConstants.labels.datasetProperties.scored}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.circular}>
                        <CircularScore size={48} value={data.dqscore || 0} showValue />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="">
                            {nFormatter(data.totalRows || 0)}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.totalRecords}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className="">
                            {nFormatter(data.validRows || 0)}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.validRecords}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={data.invalidRows ? classes.invalidRecordCount : null}>
                            {nFormatter(data.invalidRows || 0)}
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            {appConstants.labels.datasetProperties.invalidRecords}
                        </Typography>
                    </Grid>
                    {
                        overviewPermission?.is_edit && versionDetail?.connection_has_license && versionDetail?.connection_is_active &&
                        <Grid item>
                            <Tooltip title={detail?.run_status === 'Completed' ? "Run Now" : detail?.run_status ?? "Pending"}>
                                <span>
                                    <IconButton onClick={() => handleJobTrigger(detail?.run_status)} disabled={detail?.status?.toLowerCase() === 'deprecated'}>
                                        {
                                            detail?.run_status === 'Completed' ?
                                                <PlayIcon2 />
                                                : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />
                                        }
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    }
                </React.Fragment>
            }
            <InvalidRecordPreview
                open={invalidDialogHandler?.open || false}
                assetId={invalidDialogHandler?.assetId ?? ""}
                attributeId={invalidDialogHandler?.attributeId ?? ""}
                onClose={() => onCloseInvalidPreviewDialog()}
            />
        </Grid>
    );
}

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    onClickFilter: PropTypes.func,
    data: PropTypes.object,
    theme: PropTypes.object
};

// default props
Header.defaultProps = {
    classes: {},
    onClickFilter: () => { },
    data: {},
    theme: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...HeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Header, areEqual));