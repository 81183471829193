import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, Grid } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useScreenshot } from 'use-react-screenshot';

//import Componetns
import Header from './header/index.jsx';
import IssueDetail from './issueDetail/index.jsx';
import IssueInfo from './issueInfo/index.jsx';
import Attachment from './attachment/index.jsx';

//import Styles
import IssuesStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Actions
import {
    selectIssue, clearState, createIssuesRequest, getIssuesDetailRequest, updateIssueProperty,
    updateIssuesRequest, updateShareDetails
} from '../../../redux/reducer/issuesReducer';

// Import Helpers
import { checkPermission } from '../../../helpers/appHelpers.js';

// Import Contants
import featureConstants from '../../../constants/featureConstants.js';


function CreateEditIssueComponent(props) {

    /**
     * Define Props
     */
    const { classes, issueParams, open, onClose } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { id: issueId, detail } = useSelector((state) => state.issues);
    const { permission } = useSelector((state) => state.auth);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);


    /**
     * Define State
     */
    const header_div_ref = useRef();
    const [screenShotCaptured, setScreenShotCaptured] = useState(false);
    const [shareImageScreenShot, takeShareImageScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 0.5
        }
    );

    /**
     * Create New Issue
     */
    useEffect(() => {
        if (!issueId && issueParams && issueParams.id) {
            dispatch(selectIssue(issueParams.id));
        }
        if (!issueId && issueParams && !issueParams.id) {
            const requestParams = {
                ...issueParams
            };
            dispatch(createIssuesRequest(requestParams));
        }
    }, [issueId, issueParams]);

    /**
     * Get Issue Detail
     */
    useEffect(() => {
        if (issueId) {
            dispatch(getIssuesDetailRequest(issueId));
        }
    }, [issueId]);

    /**
     * Handle OnChange
     * @param {*} property
     * @param {*} value
     */
    const handleOnChange = (property, value) => {
        if (!issueId || value === detail?.status || value === detail?.priority) {
            return;
        }
        const requestParams = {
            [property]: value
        };
        dispatch(updateIssueProperty(requestParams));
        if (property === "application" || property === "domains" || property === "users") {
            value = value.filter((data) => (!("is_editable" in data) || data.is_editable === true));
            requestParams[property] = value.map((data) => data.id);
        }
        requestParams.id = issueId;
        requestParams.key = property;
        dispatch(updateIssuesRequest(requestParams));
    };

    /**
     * Close issue Dialog
     */
    const onCloseIssueDialog = () => {
        dispatch(clearState());
        onClose();
    };

    /**
     * Upload Alert Screenshot
     * @param {*} dataurl
     */
    const uploadScreenShot = (dataurl) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], 'share.jpg', { type: mime });
        dispatch(updateShareDetails({ 'file': croppedImage, 'base64': dataurl }));
        setScreenShotCaptured(true);
    };


    /**
     * Handle Screenshot After Dom Loaded
     */
    const takeScreenshot = () => {
        let timer = null;
        if (header_div_ref && !screenShotCaptured && !shareImageScreenShot) {
            timer = setTimeout(() => {
                takeShareImageScreenShot(header_div_ref.current).then(uploadScreenShot);
            }, 1000);
        }
        return () => clearTimeout(timer);
    };

    const containerRef = useRef();
    const containerWidth = containerRef.current ? containerRef.current.clientWidth : 1850;

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onCloseIssueDialog}
        >
            <ValidatorForm noValidate onSubmit={() => null}>
                <Grid container className={classes.issuesPageContainer} ref={header_div_ref}>
                    <Header data={{ ...detail }} handleIssueClose={onCloseIssueDialog} handleOnChange={handleOnChange} takeScreenshot={takeScreenshot} issuePermission={issuePermission} />
                    <Grid className={classes.issuesBodyContainer} container wrap="nowrap" ref={containerRef} sx={{ padding: '0px 24px' }}>
                        <Grid item xs={9} className="">
                            {/* <IssueStatus status={detail.status} /> */}
                            <IssueDetail containerWidth={containerWidth} data={detail} handleOnChange={handleOnChange} issuePermission={issuePermission} />
                        </Grid>
                        <Grid item xs={3} className="pl-3">
                            <Attachment disabled={!issuePermission?.is_edit} />
                        </Grid>
                    </Grid>
                    <IssueInfo measureId={detail.measure} selectedAlert={issueParams?.drift} measure_name={issueParams?.measure_name} issuePermission={issuePermission} />
                </Grid>
            </ValidatorForm>
        </Dialog>
    );
}

// default props
CreateEditIssueComponent.defaultProps = {
    classes: {},
    open: false,
    onClose: () => { },
    issueParams: {}
};

// prop types
CreateEditIssueComponent.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    issueParams: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...IssuesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CreateEditIssueComponent);