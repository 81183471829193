import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Sync } from '@mui/icons-material';

//import Componetns
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DialogComponent, NumberInputComponent, SwitchComponent, TextBoxComponent } from '../../../../components/index.js';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';
import { checkPermission } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Actions
import { syncProfileMeasuresRequest } from '../../../../redux/reducer/measureReducer.js';


function ProfileSettings(props) {

    /**
     * Define Props
     */
    const { classes, onChange } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
     const { general } = useSelector((state) => state.configurations);
     const { permission } = useSelector((state) => state.auth);
     const generalPermission = checkPermission(permission, featureConstants.settings.general);
     const { profile } = general;

     /**
      * State Variables
      */
    const [showDialog, setShowDialog] = useState(false);

    /**
     * Handle confirm event for sync measures
     */
    const onSyncMeasures = () => {
        dispatch(syncProfileMeasuresRequest());
        setShowDialog(false);
    };

    return (
        <Grid item xs={12}>
            <Grid container justifyContent={"space-between"} className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.profile}
                </Typography>

                <Tooltip title={"Sync Measures"}>
                    <IconButton className={classes.syncIcon} onClick={() => setShowDialog(true)}>
                        <Sync />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid container justifyContent={"flex-start"} alignItems={"center"} wrap="wrap" className={classes.bodyContainer}>
                <Grid item xs={12} className={"profileDescriptionContainer"}>
                    <Typography variant="body1">
                        {appConstants.labels.general.profileDesc}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={"profileInput"}>
                    <NumberInputComponent
                        name={''}
                        value={profile.distinct_values_threshold || 8000}
                        onChange={(value) => onChange('profile', 'distinct_values_threshold', value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="length-min-ntb"
                        label={"Distinct Values Threshold"}
                        InputLabelProps={{ shrink: true }}
                        disabled={!generalPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={3} className={"profileInput"}>
                    <NumberInputComponent
                        name={''}
                        value={profile.distinct_percentage_threshold || 25}
                        onChange={(value) => onChange('profile', 'distinct_percentage_threshold', value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="length-min-ntb"
                        label={"Distinct Percentage Threshold"}
                        InputLabelProps={{ shrink: true }}
                        disabled={!generalPermission?.is_edit}
                    />
                    <Typography variant="body1" className="textLabel">
                        {"%"}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={"profileInput"}>
                    <NumberInputComponent
                        name={''}
                        value={profile.pattern_frequency_threshold || 1000}
                        onChange={(value) => onChange('profile', 'pattern_frequency_threshold', value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="length-min-ntb"
                        label={"Pattern Frequency Threshold"}
                        InputLabelProps={{ shrink: true }}
                        disabled={!generalPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={3} className={"profileInput"}>
                    <TextBoxComponent
                        name={'profile_database'}
                        value={profile?.database || ""}
                        onChange={(event) => onChange('profile', 'database', event.target.value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="length-min-ntb"
                        label={appConstants.labels.general.profilingDatabase}
                        InputLabelProps={{ shrink: true }}
                        disabled={!generalPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={3} className={"profileInput mt-3 pr-3"}>
                    <TextBoxComponent
                        name={'profile_schema'}
                        value={profile?.schema || ""}
                        onChange={(event) => onChange('profile', 'schema', event.target.value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="profile_schema"
                        label={
                            <span className="requiredLabel">
                                {appConstants.labels.general.profilingSchema}
                                <span className="requiredstar">
                                    *
                                </span>
                            </span>
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled={!generalPermission?.is_edit}
                    />
                </Grid>
                <Grid item xs={3} className={"profileInput mt-3"}>
                    <SwitchComponent size="small"
                        handleChange={(value) => onChange('profile', 'allow_create_temp_table', value)}
                        checked={profile?.allow_create_temp_table || false}
                        label={appConstants.labels.general.createTempTable}
                        disabled={!generalPermission?.is_edit} />
                </Grid>
            </Grid>

            {
                showDialog &&
                <DialogComponent
                    open={showDialog}
                    title={"Sync Measures"}
                    message={"Are you sure you want to sync measures? Doing this will clean up all your existing measures in already configured assets!"}
                    onCancel={() => setShowDialog(false)}
                    onConfirm={() => onSyncMeasures()}
                />
            }
        </Grid>
    );
}

// default props
ProfileSettings.defaultProps = {
    classes: {},
    onChange: () => {}
};

// prop types
ProfileSettings.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ProfileSettings);