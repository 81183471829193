import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import AlertsStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import featureConstants from '../../constants/featureConstants.js';

//  Import Components
import { AreaChartComponent, TableComponent } from '../../components/index.js';
import CreateEditIssueComponent from '../../containers/issues/createEdit/index.jsx';


// Import Reducers
import { getAlertsRequest, getAlertsCountRequest, updateAlertList, updateDriftAlertRequest, exportRequest } from '../../redux/reducer/driftReducer.js';
import { navigate } from '../../redux/reducer/navigationReducer.js';
import { updateUserPreference } from '../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer';
import { getDomainListRequest } from '../../redux/reducer/semanticReducer.js';
import { getApplicationsRequest } from '../../redux/reducer/applicationReducer.js';
import { getTagsRequest } from '../../redux/reducer/tagsReducer.js';
import { getTermsRequest } from '../../redux/reducer/termReducer';

// Import Helpers
import { checkPermission, getAlertStatusColors, permissionHeaders, getUserPreference, prepareUpdateUserPreference, orderList } from '../../helpers/appHelpers.js';


function Alerts(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const searchControllerRef = useRef();

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const query_asset_name = searchParams.get("asset");
    const query_asset_id = searchParams.get("asset_id");
    const query_attribute_id = searchParams.get("attribute_id");
    const query_measure_name = searchParams.get("measure_name");
    const query_day_filter = searchParams.get("day");
    const query_priority = searchParams.get("priority");
    const query_status = searchParams.get("status");
    const query_domain = searchParams.get("domain");
    const query_application = searchParams.get("application");
    const query_tag = searchParams.get("tag");
    const query_measure_type = searchParams.get("measure_type");

    /**
     * Define Redux
     */
    const { alerts, alertsCount, search: searchFilter, alertsLoading, loadMore, exportLoading } = useSelector((state) => state.drift);
    const { permission, user } = useSelector((state) => state.auth);
    const alertPermission = checkPermission(permission, featureConstants.home.alerts);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "alerts", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "alerts", "sorting");
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { searchableTagsList } = useSelector((state) => state.tags, shallowEqual);
    const { searchableTermsList } = useSelector((state) => state.term, shallowEqual);

    /**
     * Set Default Date
     * @returns
     */
    const setDefaultDate = () => {
        if (query_day_filter) {
            return query_day_filter;
        }
        return searchFilter.daysSelected || 'Last Run';
    };

    /**
     * Define State
     */
    const filterData = searchFilter?.search_by ?? {};
    const [searchData, setSearchData] = useState({
        "status": "",
        "message": "",
        "asset_name": "",
        "attribute_name": "",
        "measure_name": "",
        "value": "",
        "issue_id": "",
        "asset_id": "",
        ...filterData
    });
    const [daysFilter, setDaysFilter] = useState({
        value: setDefaultDate(),
        options: ["Today", "Yesterday", "Last Run", "Last 3 days", "Last 7 days", "Last 14 days", "Last 30 days", "All"]
    });
    const [issueParams, setIssueParams] = useState(null);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "alerts", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Get Selection Date
     * @param {*} value
     * @returns
     */
    const getSelectionDate = (value) => {
        let days = 0;
        if (value === 'Today') {
            days = 1;
            return days;
        }
        if (value === 'Yesterday') {
            days = 2;
            return days;
        }
        days = value.replace(/[^0-9]/g, '');
        return days ? parseInt(days) : "All";
    };


    /**
     * Fetche Alerts Statistics
     * @param {*} params
     */
    const getAlertsCount = (requestParams) => {

        let params = {
            days: getSelectionDate(daysFilter.value),
            daysSelected: daysFilter.value
        };

        if (requestParams) {
            params = {
                ...params,
                ...requestParams
            };
        }

        dispatch(getAlertsCountRequest({ params }));
    };

    /**
     * Fetches the list of alerts
     * @param {*} params
     */
    const getAlerts = (params, statistics = false, clear = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        if (!params.days) {
            params.days = getSelectionDate(daysFilter.value);
            if (!params.daysSelected) {
                params.daysSelected = daysFilter.value === 'Last Run' ? 'Last Run' : daysFilter.value;
            }
        }

        const requestParams = {
            ...searchFilter,
            ...params,
            statistics
        };
        dispatch(getAlertsRequest({ params: requestParams, token, clear }));
    };

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Domains List
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        // Get Applications List
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        // Get Tags List
        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
        // Get Terms List
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }
    }, [dispatch]);


    /**
     * Use Effect for Initial Load
     */
    useEffect(() => {
        if ((!alerts.length) || query_asset_name || query_asset_id || query_measure_name || query_priority || query_status || query_domain || query_application || query_attribute_id || query_tag || query_measure_type) {
            const search_by = { ...searchData };
            search_by.asset_name = query_asset_name || '';
            search_by.asset_id = query_asset_id || '';
            search_by.attribute_id = query_attribute_id || '';
            search_by.measure_name = query_measure_name || '';
            search_by.priority = query_priority || '';
            search_by.status = query_priority || query_status;
            search_by.measure_type = query_measure_type || '';
            search_by.domain = query_domain || '';
            search_by.application = query_application || '';
            search_by.tag = query_tag || '';
            removeSearchParam();
            setSearchData({ ...search_by });
            let requestData = {
                search_by: search_by,
                offset: 0
            };

            if (query_asset_name || query_asset_id || query_domain || query_application || query_attribute_id || query_tag) {
                const selectedDays = { ...daysFilter };
                selectedDays.value = 'Last Run';
                setDaysFilter({ ...selectedDays });
                requestData = {
                    ...requestData,
                    "daysSelected": 'Last Run',
                    "days": 0
                };
            }

            if (sorting) {
                requestData.sortBy = sorting.sortBy;
                requestData.orderBy = sorting.orderBy;
            }
            getAlerts(requestData, true, true);
        }
    }, [dispatch]);

    /**
     * Handle Status Selected
     * @param {*} status
     */
    const handleTileStatusClick = (status) => {
        getAlerts({ status, offset: 0 }, false, true);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        if (sortBy === 'formatted_date') {
            sortBy = 'created_date';
        }
        const requestData = {
            sortBy, orderBy, offset: 0
        };
        updatePreference({ sorting: { sortBy, orderBy } });
        getAlerts(requestData, false, true);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        getAlerts({ search_by: search_by, offset: 0 }, true, true);
    };

    /**
     * Handle Days Range Change
     * @param {*} value
     */
    const onDaysFilterChanges = (value) => {
        const selectedDays = { ...daysFilter };
        selectedDays.value = value;
        setDaysFilter({ ...selectedDays });
        const days = getSelectionDate(value);
        getAlerts({ offset: 0, days, daysSelected: value }, true, true);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreAlerts = () => {
        if (loadMore && !alertsLoading) {
            getAlerts({ offset: (searchFilter.offset + searchFilter.limit) });
        }
    };


    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, alert) => {
        switch (key) {
            case "asset_name":
                dispatch(navigate({ path: 'assets.root', state: {}, params: [alert.asset_id] }));
                break;
            case "attribute_name":
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [alert.asset_id, alert.attribute_id] }));
                break;
            case "measure_name":
            case "message":
                const level = !alert?.asset_id ? "measure" : "asset";
                const isAssetLevel = alert?.asset_id && (alert?.attribute_id === "All" || alert?.attribute_id === "" || !alert?.attribute_id);
                const state = {
                    asset_id: alert?.asset_id,
                    attribute_id: alert?.attribute_id,
                    selectedAlert: alert.id,
                    level,
                    measureName: alert?.measure ?? "",
                    showEdit: true,
                    isAssetLevel,
                    isMeasureLevel: !alert.asset_id,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [alert.measure_id] }));
                break;
            case "issue_id":
                const status = alert?.status ?? "Low";
                setIssueParams({
                    id: alert.issue_id,
                    name: alert?.message ?? '',
                    description: alert?.message ?? '',
                    priority: appConstants.general.priority.includes(status) ? status : 'Low',
                    asset: alert.asset_id,
                    attribute: alert.attribute_id,
                    version: alert.version_id,
                    drift: alert.id,
                    measure: alert.measure_id,
                    measure_name: alert?.measure ?? ''
                });
                break;
            default:
                break;
        }
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        if (key === "status") {
            const alert = { ...item };
            alert[key] = value;
            dispatch(updateAlertList({ ...alert }));

            const requestParams = {
                id: item.id,
                status: value
            };
            dispatch(updateDriftAlertRequest(requestParams));
            setTimeout(() => getAlertsCount({ search_by: { ...searchData } }), 100);
        }
    };

    /**
     * Mark the alert as normal
     * @param {*} item
     */
    const markAsNormal = (alert) => {
        if (!alert) {
            return;
        }
        const requestParams = {
            id: alert.id,
            marked_as: !alert.marked_as ? "normal" : null
        };
        dispatch(updateDriftAlertRequest(requestParams));
        setTimeout(() => getAlertsCount({ search_by: { ...searchData } }), 100);
    };

    /**
     * Handle more action
     * @param {*} alert
     * @param {*} event
     */
    const onHandleActions = (alert, _, event) => {
        if (!event) {
            return;
        }
        markAsNormal(alert, event);
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Export Alerts
     */
    const exportAlerts = () => {
        const requestParams = {
            ...searchFilter,
            fileName: 'alerts.csv'
        };
        dispatch(exportRequest(requestParams));
    };

    /**
     * Clear Search Filters
     */
    const clearFilters = () => {
        let search_by = { ...searchData };
        search_by = Object.keys(search_by).forEach((key) => search_by[key] = '');
        setSearchData({ ...search_by });
        getAlerts({ search_by: search_by, offset: 0 }, true, true);
    };

    /**
     * Get Table Action Options
     */
    const alertsTableOptions = [{ type: "clear", customFunction: clearFilters }, { type: "search", customFunction: null }, { type: "download", customFunction: exportAlerts, isLoading: exportLoading }, { type: "columns", customFunction: null }];


    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(searchableTermsList), [searchableTermsList]);

    /**
     * Filter tags
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsList), [searchableTagsList]);

    /**
     * Alerts Table Headers
     */
    const tableHeaders = [
        { key: 'message', name: 'Alert', sorting: true, tooltip: true, width: "30%", isSearch: true, searchComponent: "text", searchKey: "message", clickable: true },
        { key: 'connection_name', name: 'Connection', sorting: true, tooltip: true, width: "10%", isSearch: true, searchComponent: "text", searchKey: "connection_name", showNA: true },
        { key: 'asset_name', name: 'Asset', sorting: true, tooltip: true, width: "10%", isSearch: true, searchComponent: "text", searchKey: "asset_name", clickable: true, showNA: true },
        { key: 'attribute_name', name: 'Attribute', sorting: true, tooltip: true, width: "9%", isSearch: true, searchComponent: "text", searchKey: "attribute_name", clickable: true, hideDefault: true },
        { key: 'measure_name', name: 'Measure', sorting: true, tooltip: true, width: "9%", isSearch: true, searchComponent: "text", searchKey: "measure_name", clickable: true },
        { key: 'percent_change', name: '% Change', width: "7%", sorting: true, hideDefault: false },
        { key: 'expected', name: 'Expected', width: "7%", hideDefault: true },
        { key: 'value', name: 'Actual', width: "7%", sorting: true, isSearch: true, searchComponent: "text", searchKey: "value", hideDefault: true },
        { key: 'status', name: 'Priority', sorting: true, width: "7%", isSearch: true, searchComponent: "text", searchKey: "status", component: "alert_status", list: ['High', 'Medium', 'Low'], isPermissionDisabled: true },
        { key: 'formatted_date', name: 'Created On', width: "7%", sorting: true },
        { key: 'issue_id', name: 'Issue ID', width: "5%", component: "issues", sorting: true, isSearch: true, searchComponent: "text", searchKey: "issue_id", clickable: true },
        { key: 'asset_id', name: 'Asset ID', width: "4%", sorting: true, isSearch: true, searchComponent: "text", searchKey: "asset_id", hideDefault: true },
        { key: 'applications', name: 'Application', width: "5%", isSearch: true, searchComponent: "autocomplete", list: applicationsList || [], searchKey: "applications", hideDefault: true, showNA: true, component: 'chips', className: 'applicationChip_close', haveColor: true, limit: 1, isNotEditable: true },
        { key: 'domains', name: 'Domain', width: "5%", isSearch: true, searchComponent: "autocomplete", list: domainsList || [], searchKey: "domains", hideDefault: true, showNA: true, component: 'chips', className: 'applicationChip_close', haveColor: true, limit: 1, isNotEditable: true },
        {
            key: 'terms',
            name: 'Terms',
            width: "5%",
            isSearch: true,
            searchComponent: "autocomplete",
            list: termsList || [],
            searchKey: "terms",
            limit: 1,
            hideDefault: true,
            showNA: true,
            component: 'chips',
            className: 'applicationChip_close',
            haveColor: true,
            isNotEditable: true
        },
        {
            key: 'tags',
            name: 'Tags',
            width: "5%",
            isSearch: true,
            searchComponent: "autocomplete",
            list: tagsList || [],
            searchKey: "tags",
            limit: 1,
            hideDefault: true,
            showNA: true,
            component: 'chips',
            className: 'applicationChip_close',
            haveColor: true,
            isNotEditable: true
        },
        { key: 'actions', name: 'Actions', width: "5%", actions: [{ type: 'drift_mark_resolved' }] }
    ];

    /**
     * Check Issue permission
     * @returns
     */
    const checkIssuePermission = () => {
        const alertHeaders = permissionHeaders(tableHeaders, alertPermission);
        const index = alertHeaders?.findIndex((header) => header.key === "issue_id");
        if (index >= 0) {
            if (!issuePermission?.is_edit) {
                alertHeaders[index].isPermissionDisabled = true;
            } else {
                alertHeaders[index].isPermissionDisabled = false;
            }
        }
        return alertHeaders;
    };

    return (
        <ValidatorForm onSubmit={() => null}>
            <Grid container className={classes.alertsContainer}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {
                            appConstants.alertTiles.map((item) => (
                                <Grid item xs={3} key={item.name} onClick={() => handleTileStatusClick(item.key)}>
                                    <Grid id={`${item.key}_chart_container`} className={`${classes.alertsCard} ${searchFilter.status === item.key ? 'selected' : ''}`} >
                                        <Grid className={classes.cardData}>
                                            <Typography variant="h4">
                                                {(alertsCount && item.key in alertsCount) && alertsCount[item.key] ? alertsCount[item.key].count : 0}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid className={classes.chart}>
                                            {
                                                ((alertsCount && item.key in alertsCount) && alertsCount[item.key] ? alertsCount[item.key].count : 0) ?
                                                    <AreaChartComponent
                                                        containerId={`${item.key}_chart_container`}
                                                        size="small"
                                                        toolTipName="Alerts"
                                                        lineColor={getAlertStatusColors(item.key)}
                                                        chartData={(alertsCount && item.key in alertsCount) && alertsCount[item.key] ? alertsCount[item.key].alerts : []}
                                                    />
                                                    :
                                                    <Grid className={classes.nodataCardWrapper}>
                                                        <Grid className={classes.nodataCard}>
                                                            <EqualizerIcon />
                                                            <Typography variant="body1" className={classes.textSecondary}>
                                                                No Data Found
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className={`${classes.alertsTable}`}>
                    <TableComponent
                        headers={checkIssuePermission()}
                        stickyHeader
                        options={alertsTableOptions}
                        filters={[{ ...daysFilter, onChange: onDaysFilterChanges }]}
                        title={"Recent Alerts"}
                        data={alerts || []}
                        sortBy={searchFilter?.sortBy ?? ''}
                        orderBy={searchFilter?.orderBy ?? 'asc'}
                        onClickSorting={onClickSorting}
                        onCompnentEvent={onCompnentEvent}
                        styleType="striped"
                        onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                        onClickActions={onHandleActions}
                        onCellClick={handleCellClickEvent}
                        height="calc(100vh - 170px)"
                        NoResultText="No Alerts Found"
                        isLoading={alertsLoading}
                        searchData={searchData}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        onScrollEnd={loadMoreAlerts}
                    />
                </Grid>
                {
                    issueParams &&
                    <CreateEditIssueComponent
                        issueParams={issueParams}
                        open={Boolean(issueParams)}
                        onClose={() => setIssueParams(null)}
                    />
                }
            </Grid>
        </ValidatorForm>
    );
}

// default props
Alerts.defaultProps = {
    classes: {}
};

// prop types
Alerts.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AlertsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Alerts);