const style = (theme) => ({
    sidebarContainer: {
        paddingRight: '30px',
        paddingBottom: '40px',
        paddingLeft: 0,
        // borderRight: `1px solid ${theme.palette.greyshades.darkgrey}40`,
        position: 'relative',
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        width: 300,
        boxSizing: 'border-box',
        height: '100%',
        '& .ArrowUp path': {
            fill: `${theme.palette.greyshades.darkgrey}90`
        },
        '& .ArrowDown path': {
            fill: `${theme.palette.greyshades.darkgrey}90`
        },
        '& .sortIcons .MuiIconButton-root': {
            width: 26,
            height: 26,
            '&:hover': {
                '& svg': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .active': {
            '& svg': {
                // fill: theme.palette.secondary.main
            }
        },
        '&.collapsedSidebar': {
            transform: 'translateX(-288px)',
            '& .sideBarItem': {
                visibility: 'hidden'
            }
        },
        '& .sideBarItem': {
            marginBottom: 20,
            '& .title': {
                paddingBottom: 6,
                borderBottom: `1px solid ${theme.palette.greyshades.darkgrey}40`
            },
            '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
                color: theme.palette.greyshades.darkgrey,
                fontSize: '1rem'
            },
            '& .MuiRating-icon svg.MuiSvgIcon-root': {
                fontSize: '1.4rem !important'
            },
            '& .MuiRating-root': {
                color: '#F6C831 !important'
            },
            '& .MuiPaper-root': {
                marginTop: 0,
                background: 'transparent !important',
                boxShadow: 'none !important',
                '&:before': {
                    background: 'transparent'
                },
                '&.Mui-expanded': {
                    margin: '0px !important'
                }
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: '32.5px !important',
                minHeight: '32.5px !important',
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-root': {
                padding: '0px !important',
                minHeight: '32.5px !important',
                height: '32.5px !important'
            },
            '& .MuiAccordionDetails-root': {
                padding: '0px !important'
            },
            '& .MuiAccordionSummary-expandIconWrapper:hover': {
                '& svg': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .sidebarItemBody': {
            padding: '13px 8px 0',
            '& .checkBoxText': {
                maxWidth: 'calc(100% - 25px)',
                textTransform: 'capitalize'
            },
            '& span.MuiTypography-root': {
                width: '100%'
            },
            '& .MuiCheckbox-root .MuiSvgIcon-root': {
                fontSize: '1.3rem'
            },
            '& .rangeSlider': {
                alignItems: 'center'
            },
            '& .MuiCheckbox-root': {
                padding: '6px !important'
            },
            '& .MuiFormControlLabel-root': {
                width: '100%',
                '& .MuiTypography-root': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    paddingLeft: '2px',
                    '&:hover': {
                        color: `${theme.palette.secondary.main} !important`
                    }
                }
            },
            '& .checkedIcon': {
                color: `${theme.palette.secondary.main} !important`,
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    iconBg: {
        backgroundColor: '#ffffff',
        right: '-13px',
        padding: 5,
        top: 15,
        width: 26,
        height: 26,
        borderRadius: '50%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 8px #0000001a',
        cursor: 'pointer',
        transition: 'all 0.5s',
        '&.collapsed': {
            right: '-14px',
            transform: 'rotate(180deg)'
        },
        '&:hover .ChevLeftIcon path': {
            fill: theme.palette.secondary.main
        }
    },
    filterText: {
        '& input': {
            textTransform: 'capitalize'
        }
    },
    resetFilter: {
        // position: 'fixed',
        display: 'none',
        bottom: 0,
        width: '286px',
        background: '#f5f7f8',
        textAlign: 'right',
        '&.active': {
            display: 'block'
        },
        '& .MuiButton-root': {
            zIndex: 9999,
            opacity: 0,
            transition: 'all 0.5s'
        },
        '&.active .MuiButton-root': {
            display: 'block',
            opacity: 1,
            transition: 'all 0.5s',
            marginTop: '12px',
            marginRight: 'auto',
            width: 'calc(100% - 22px)'
        }
    }
});

export default style;