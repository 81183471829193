const style = (theme) => ({
    connectorForm: {
        padding: "30px 34px 0px",
        // boxShadow: '0px 0px 4px #00000014',
        marginBottom: 20,
        background: `${theme.palette.primary.contrastText}`,
        width: '100%',
        height: 'calc(100% - 20px)',
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px !important'
        },
        '& .Mui-expanded, .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionDetails-root': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    },
    connectorFormContainer: {
        padding: 24
    },
    connectorIcon: {
        marginRight: '16px !important',
        '& .connectorCardImg': {
            height: '40px !important'
        },
        '& svg': {
            fontSize: '36px !important',
            width: '36px !important',
            height: '36px !important'
        },
        '& img': {
            height: 45,
            maxWidth: 135,
            objectFit: 'contain'
        }
    },
    formContainer: {
        marginTop: '35px !important',
        paddingBottom: '20px',
        '& .MuiFormLabel-root': {
            color: "#999999",
            fontSize: "14px",
            paddingBottom: "10px"
        }
    },
    checkBox: {
        '& .MuiTypography-root': {
            paddingLeft: '0px !important'
        }
    },
    accordionCheckBoxes: {
        '& .MuiAccordionSummary-root': {
            minHeight: '45px',
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            padding: '0px 10px 0px 5px !important',
            '&.Mui-expanded': {
                minHeight: '45px'
            }
        },
        '& .MuiAccordionDetails-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            padding: '12px !important',
            background: theme.palette.greyshades.lightestgrey
        },
        '& .detailInnerContainer': {
            padding: '10px 30px !important',
            background: '#fff',
            '& .switchItems': {
                paddingBottom: '8px',
                paddingTop: '8px'
            }
        },
        '&.MuiAccordion-root::before': {
            background: 'transparent !important'
        },
        '&.MuiAccordion-root': {
            '&:nth-child(3)': {
                '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important'
                }
            },
            '&:nth-child(2)': {
                '& .MuiAccordionSummary-root': {
                    borderBottom: 'none !important'
                }
            }
        }
    },
    footerActions: {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        background: '#fff',
        '& .MuiGrid-grid-xs-12': {
            position: 'relative',
            bottom: '-30px',
            background: '#fff',
            paddingBottom: '20px'
        }
    }
});
export default style;