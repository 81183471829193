const style = () => ({
    usersBox: {
        '& textarea.MuiOutlinedInput-input': {
            height: 'inherit !important',
            minHeight: '200px'
        }
    },
    PropertiesContainer: {
        '& .MuiOutlinedInput-root': {
            padding: '5px 9px'
        },
        '& .MuiSelect-select': {
            padding: '6px !important'
        }
    }
});

export default style;