import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Link } from '@mui/material';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

//  Import Components
import { AssetTypeIconComponent, CircularScoreComponent, LoaderComponent, NoResultComponent } from '../../../../components/index.js';
import DomainCard from '../../components/card/index.jsx';

// Import Actions
import { getSubTagsRequest, getTagAttributesRequest } from '../../../../redux/reducer/tagsReducer.js';

function TagsSummary(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * get Tag ID from URL
     */
    const { id: tag_id } = useParams();

    /**
     * Get Reducer Values
     */
    const { detail, attributes, attributesLoading, subTags, subTagsLoading } = useSelector((state) => state.tags);

    /**
     * Get Tag Attributes Request
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        if (tag_id) {
            dispatch(getTagAttributesRequest(tag_id));
        }
    }, [tag_id]);

    /**
     * Get Tag Attributes Request
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        if (tag_id && !detail?.parent) {
            dispatch(getSubTagsRequest(tag_id));
        }
    }, [tag_id, detail]);

    return (
        <Grid container className={classes.tabsRootContainer}>
            {
                !detail?.parent &&
                <Grid item xs={12}>
                    <Typography variant="h6" className="mb7" align="left">
                        {`Sub Tags (${subTags?.length ?? 0})`}
                    </Typography>
                    <Grid container className={classes.tagsPageContainer} spacing={4}>
                        {
                            subTagsLoading ?
                                <LoaderComponent
                                    sx={{ paddingTop: '24px' }}
                                    xsCard={4}
                                    cardHeight="175px"
                                    loaderType="card"
                                    height="auto" />
                                :
                                subTags.map((item, index) => (
                                    <Grid item xl={3} sm={4} key={`subtags_${index}`}>
                                        <DomainCard
                                            data={item}
                                            type="tags"
                                        />
                                    </Grid>
                                ))
                        }
                        {
                            !subTagsLoading && !subTags.length &&
                            <NoResultComponent title="No Sub Tags Avilable" />
                        }
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} className="pt-3">
                <Typography variant="h6" className="mb7" align="left">
                    {`Attributes (${attributes?.length ?? 0})`}
                </Typography>
                <Grid container spacing={3} className="pt-1 cardsSection">
                    {
                        attributesLoading ?
                            <LoaderComponent
                                sx={{ paddingTop: '24px' }}
                                xsCard={4}
                                cardHeight="175px"
                                loaderType="card"
                                height="auto" />
                            :
                            attributes.map((item, index) => (
                                <Grid item xl={3} sm={4} key={`attribute_${index}`}>
                                    <Link
                                        underline="none"
                                        variant="subtitle1"
                                        component={RouterLink}
                                        to={`/asset/${item.asset_id}/properties/${item.id}`}>
                                        <Grid item className={classes.termsCard}>
                                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Grid className="dflex alignCenter pb-1">
                                                        <Typography variant="h6" className={classes.typeLabel}>
                                                            {item?.derived_type?.charAt(0) ?? 'T'}
                                                        </Typography>
                                                        <Typography variant="h6" className={`pl-1`}>
                                                            {item.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className="dflex alignCenter mb5">
                                                        <Grid className="assetTypeIcon">
                                                            <AssetTypeIconComponent
                                                                asset_type={item.asset_type}
                                                            />
                                                        </Grid>
                                                        <Typography variant="body1" className={`${classes.textSecondary} pl-1`}>
                                                            {item.asset_name || ''}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="body1" className={classes.assetsText}>
                                                        {item?.alerts ?? 0}
                                                        {' '}
                                                        Alerts
                                                        <span className="assetDot" />
                                                        {item?.issues ?? 0}
                                                        {' '}
                                                        Issues
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <CircularScoreComponent
                                                        size={40}
                                                        value={item.dqscore}
                                                        showPercentage
                                                        noLink
                                                        showValue />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            ))
                    }
                    {
                        !attributesLoading && !attributes.length &&
                        <NoResultComponent title="No Attributes Found" />
                    }
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
TagsSummary.defaultProps = {
    classes: {}
};

// prop types
TagsSummary.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TagsSummary);