import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet, useLocation } from 'react-router-dom';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { NavigationTabHeaderComponent } from '../../components/index.js';

//  Import Styles
import SemanticsOverviewStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Impor Constants
import appConstants from '../../constants/appConstants.js';

function SemanticsOverview(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const location = useLocation();

    /**
     * Get Page Title
     * @returns
     */
    const getPageTitle = () => {
        if (location.pathname.includes('/semantics/domains')) {
            return 'Domains';
        } else if (location.pathname.includes('/semantics/tags')) {
            return 'Tags';
        }
        return 'Applications';
    };

    return (
        <Grid container justifyContent={"flex-end"} className={classes.semanticsOverviewPageContainer}>
            <Grid item xs={12} align="right">
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                        alignItems={"center"}
                    >
                        <Typography variant="h2">
                            {getPageTitle()}
                        </Typography>
                        <NavigationTabHeaderComponent
                            tabList={appConstants.tabs.domainsTabs}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <Grid className={classes.tabsBody}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
SemanticsOverview.defaultProps = {
    classes: {}
};

// prop types
SemanticsOverview.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticsOverviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SemanticsOverview);