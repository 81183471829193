import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import ListItemStyle from "./style.jsx";
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Import Images
import { BoldIcon, ColorDropIcon, FormatIcon, ItalicIcon, UnderLineIcon } from '../../../../../../../assets/svg/index.js';
import { SelectComponent, NumberInputComponent } from '../../../../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Constants
import appConstants from '../../../../../../../constants/appConstants.js';
import { checkPermission, createElement } from '../../../../../../../helpers/appHelpers.js';
import { useSelector } from 'react-redux';
import featureConstants from '../../../../../../../constants/featureConstants.js';

function ListItem(props) {

  /**
   * Define Props
   */
  const { classes, styleName, data, isColor, onThemeChange, hidePalette } = props;
  const { permission } = useSelector((state) => state.auth);
  const editPermission = checkPermission(permission, featureConstants.settings.theme);
  const color = data?.color ?? isColor;

  /**
   * Theme change handler function
   * @param {*} property
   * @param {*} value
   */
  const handleThemeChange = (property, value) => {
    if (property === "fontFamily") {
      const setFontFamily = appConstants.fontFamily.find((font) => font.name === value);
      const titleElement = document.querySelector(`link[href="${setFontFamily.url}"]`);
      if (setFontFamily && !titleElement) {
        const linkElement = createElement(setFontFamily.url);
        document.querySelector('head').appendChild(linkElement);
      }
    }
    if (isColor) {
      onThemeChange({ [styleName]: property === "size" ? _.toNumber(value) : value });
    } else {
      onThemeChange({
        [styleName]: {
          ...data,
          [property]: property === "size" ? _.toNumber(value) : value
        }
      });
    }
  };

  return (
    <Grid container className={classes.listItem} alignItems={"center"}>
      <Grid item className={classes.cellItem}>
        <Typography
          variant={'body1'}>
          {styleName}
        </Typography>
      </Grid>
      {
        !isColor &&
        <Grid item className={classes.cellItem}>
          <ValidatorForm onSubmit={() => { }}>
              <Grid className="dflex alignItems">
                <SelectComponent
                  noOutline
                  className={`${classes.outlineNone} outlineNone`}
                  list={appConstants.fontFamily}
                  onSelectChange={(value) => handleThemeChange("fontFamily", value)}
                  value={data.fontFamily || "Poppins, sans-serif"}
                  displayPropertyName="name"
                  propertyName="name"
                  isDisabled={!editPermission}
                />
                <NumberInputComponent
                  name={'size'}
                  value={data.size || ''}
                  onChange={(value) => handleThemeChange('size', value)}
                  fullWidth
                  noOutline
                  variant="standard"
                  size="small"
                  id="length-min-ntb"
                  disabled={!editPermission}
                />
              </Grid>
          </ValidatorForm>
        </Grid>
      }
      {
        !isColor &&
        <Grid item className={classes.cellItem}>
          <Grid container>
            <IconButton className={`p6 ${data.weight === 600 ? 'active' : ''}`} onClick={() => editPermission && handleThemeChange('weight', data.weight !== 600 ? 600 : 500)} disabled={!editPermission}>
              <BoldIcon />
            </IconButton>
            <IconButton className={`p6 ${data.style !== 'normal' ? 'active' : ''}`} onClick={() => editPermission && handleThemeChange('style', data.style !== 'normal' ? 'normal' : 'italic')} disabled={!editPermission}>
              <ItalicIcon />
            </IconButton>
            <IconButton className={`p6 ${data.textDecoration !== 'auto' ? 'active' : ''}`} onClick={() => editPermission && handleThemeChange('textDecoration', data.textDecoration !== 'auto' ? 'auto' : 'underline')} disabled={!editPermission}>
              <UnderLineIcon />
            </IconButton>
            <IconButton className={`p6 ${data.transform !== 'unset' ? 'active' : ''}`} onClick={() => editPermission && handleThemeChange('transform', data.transform !== 'unset' ? 'unset' : 'capitalize')} disabled={!editPermission}>
              <FormatIcon />
            </IconButton>
          </Grid>
        </Grid>
      }
      {
        isColor &&
        <React.Fragment>
          <Grid item className={classes.cellItem} />
          <Grid item className={classes.cellItem} />
        </React.Fragment>
      }
      <Grid item className={classes.cellItem}>
        {
          !hidePalette &&
          <Grid className={classes.colorPickerComponent}>
            <Grid className={classes.colorPicker}>
              <ColorDropIcon dropColor={color || ''} />
                {
                  editPermission &&
                  <input className="typeColor" type="color" value={color || ''} onChange={(event) => handleThemeChange('color', event.target.value)} />
                }
            </Grid>
            <Typography variant="subtitle2" className="pl-1">
              {color || ''}
            </Typography>
          </Grid>
        }
      </Grid>
    </Grid >
  );
}

// default props
ListItem.defaultProps = {
  classes: {},
  styleName: '',
  data: {},
  isColor: '',
  isFontStyle: false,
  onThemeChange: () => { },
  hidePalette: false
};

// prop types
ListItem.propTypes = {
  classes: PropTypes.object,
  styleName: PropTypes.string,
  data: PropTypes.object,
  isColor: PropTypes.string,
  onThemeChange: PropTypes.func,
  hidePalette: PropTypes.bool
};

export default withStyles(
  (theme) => ({
    ...ListItemStyle(theme),
    ...LayoutStyles(theme)
  }),
  { withTheme: true }
)(ListItem);