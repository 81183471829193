import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@mui/styles";

import { Grid, Avatar, Typography, Chip } from '@mui/material';
import ListChipItems from './listChip.jsx';

import { stringAvatar } from '../../helpers/appHelpers';

// import Styles
import UsersStyles from './style.jsx';

const ListUserComponent = (props) => {
    /**
     * Define Props
     */
    const { user, classes, showName, users, limit } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const renderUser = (data, type) => {
        const name = data.name !== " " ? data.name : data.email;
        return (
            <Grid className={`${type === "user" ? classes.ListUser : "mr-1"} ListUser`}>
                <Grid className="dflex alignCenter mb-1 ListofUsers">
                    <Avatar alt={name || ''} {...stringAvatar(name, data?.avatar ?? '')} className={classes.avatarLarge} onClick={(event) => { event.stopPropagation(); }} />
                    {
                        showName &&
                        <Typography variant="body1" className={`${classes.textSecondary} pl-1`}>
                            {name}
                        </Typography>
                    }
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid className="dflex">
            {
                user && user.name &&
                renderUser(user, "user")
            }
            {
                users && users.slice(0, limit).map((item, index) =>
                    <Grid key={`user_${index}`} className="dflex">
                        {
                            item &&
                            renderUser(item, "users")
                        }
                    </Grid>
                )
            }
            {(users.length > limit) && <Chip className={classes.chip} label={`+${users.length - limit}`} onClick={(event) => setAnchorEl(event.currentTarget)} />}
            {
                Boolean(anchorEl) &&
                <ListChipItems
                    data={users}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                />
            }
        </Grid>
    );
};

/**
 * Set Default Values
 */
ListUserComponent.defaultProps = {
    classes: {},
    user: {},
    showName: false,
    users: [],
    limit: 0
};

ListUserComponent.propTypes = {
    user: PropTypes.object,
    classes: PropTypes.object,
    showName: PropTypes.bool,
    users: PropTypes.array,
    limit: PropTypes.number
};

export default withStyles(UsersStyles)(ListUserComponent);