import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import styles
import style from './style.jsx';

// Import Images
import SlackIcon from '../../assets/img/apps/slack.png';
import EmailIcon from '../../assets/img/apps/email.png';
import TeamsIcon from '../../assets/img/apps/teams.png';
import AzureADIcon from '../../assets/img/apps/azureAd.png';
import CollibraIcon from '../../assets/img/apps/collibra.png';
import AlationIcon from '../../assets/img/apps/alation.png';
import JiraIcon from '../../assets/img/apps/jira.png';
import PingIdentityIcon from '../../assets/img/apps/pingIdentity.png';
import OKTAIntegrationIcon from '../../assets/img/apps/Okta.svg';
import IBMIcon from '../../assets/img/apps/ibm.png';
import BigPandaIcon from '../../assets/img/apps/bigpanda.png';

function AppIntegrationIcon(props) {

	/**
	 * Define Props
	 */
	const { type } = props;


	/**
	 * Connectors Data
	 */
	const appsIcons = [
		{
			icon: <img alt="slack" src={SlackIcon} className="cardImg" />,
			type: 'slack'
		},
		{
			icon: <img alt="teams" src={TeamsIcon} className="cardImg" />,
			type: 'teams'
		},
		{
			icon: <img alt="email" src={EmailIcon} className="cardImg" />,
			type: 'email'
		},
		{
			icon: <img alt="azure active directory" src={AzureADIcon} className="cardImg" />,
			type: 'azureAD'
		},
		{
			icon: <img alt="Collibra" src={CollibraIcon} className="cardImg" />,
			type: 'collibra'
		},
		{
			icon: <img alt="alation" src={AlationIcon} className="cardImg" />,
			type: 'alation'
		},
		{
			icon: <img alt="jira" src={JiraIcon} className="cardImg" />,
			type: 'jira'
		},
		{
			icon: <img alt="ping_identity" src={PingIdentityIcon} className="cardImg" />,
			type: 'ping_identity'
		},
		{
			icon: <img alt="okta" src={OKTAIntegrationIcon} className="cardImg" />,
			type: 'okta'
		},
		{
			icon: <img alt="ibm_saml" src={IBMIcon} className="cardImg" />,
			type: 'ibm_saml'
		},
		{
			icon: <img alt="bigpanda" src={BigPandaIcon} className="cardImg" />,
			type: 'bigpanda'
		}
	];


	/**
	 *
	 * @returns
	 */
	const bindIcon = () => {
		const data = appsIcons.find((data) => data.type === type);

		if (data) { return data.icon; }

		return null;
	};


	return (
		bindIcon()
	);
}

/**
 * Define Component Props
 */
AppIntegrationIcon.propTypes = {
	classes: PropTypes.object,
	size: PropTypes.string,
	type: PropTypes.string
};

/**
 * Define Default Values
 */
AppIntegrationIcon.defaultProps = {
	classes: {},
	size: "large",
	type: ''
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.type, nextProps.type) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(style)(React.memo(AppIntegrationIcon, areEqual));