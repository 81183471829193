import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  default_query: "",
  saved_queries: [],
  query_result: {
    headers: [],
    data: [],
    isLoading: false
  },
  dateRange: 7,
  execution_time_per_day: {
    data: [],
    isLoading: false
  },
  recent_queries: {
    data: [],
    isLoading: false
  },
  top_5_users: {
    data: [],
    isLoading: false
  },
  top_5_roles: {
    data: [],
    isLoading: false
  },
  queryError: "",
  querySubmit: false
};

const usageSlice = createSlice({
  name: 'usage',
  initialState: { ...initialState },
  reducers: {
    getUsageRequest(state) {
      state.isLoading = true;
      state.default_query = "";
      state.saved_queries = [];
    },
    getUsageSuccess(state, action) {
      state.default_query = action?.payload?.data?.default_query ?? "";
      state.saved_queries = action?.payload?.data?.saved_queries ?? [];
      state.isLoading = false;
    },
    getUsageFailure(state) {
      state.isLoading = false;
    },
    runQueryRequest(state) {
      state.query_result.isLoading = true;
      state.query_result.data = [];
      state.query_result.headers = [];
      state.queryError = "";
    },
    runQuerySuccess(state, action) {
      const data = action?.payload?.data ?? [];
      const headers = [];
      if (data && data.length) {
        for (const key in data[0]) {
          const header = {
            key: key,
            name: key || 'Results',
            sorting: true,
            isSearch: true,
            searchComponent: "text",
            searchKey: key
          };
          headers.push(header);
        }
      }

      state.query_result = {
        headers: headers,
        data: data
      };
      state.query_result.isLoading = false;
      state.querySubmit = true;
    },
    runQueryFailure(state, action) {
      state.isLoading = false;
      state.query_result.isLoading = false;
      state.queryError = action.payload;
    },
    updateUsageRequest(state) {
      state.isLoading = true;
    },
    updateUsageSuccess(state, action) {
      state.isLoading = false;
      const savedQueries = [...state.saved_queries];
      const queryIndexIndex = savedQueries.findIndex((query) => query.id === action.payload.id);
      savedQueries[queryIndexIndex] = action.payload;
      state.saved_queries = [...savedQueries];
    },
    updateUsageFailure(state) {
      state.isLoading = false;
    },
    createUsageRequest(state) {
      state.isLoading = false;
    },
    createUsageSuccess(state, action) {
      state.saved_queries = [...state.saved_queries, action.payload.data];
      state.isLoading = false;
    },
    createUsageFailure(state) {
      state.isLoading = false;
    },
    removeUsageRequest(state) {
      state.isLoading = false;
    },
    removeUsageSuccess(state, action) {
      state.saved_queries = state.saved_queries.filter((m) => m.id !== action.payload);
      state.isLoading = false;
    },
    removeUsageFailure(state) {
      state.isLoading = false;
    },
    getQueryHistoryRequest(state, action) {
      const { type, days } = action.payload;
      state.dateRange = days || 7;
      switch (type) {
        case 'execution_time_per_day':
          state.execution_time_per_day.isLoading = true;
          break;
        case 'recent_queries':
          state.recent_queries.isLoading = true;
          break;
        case 'top_5_users':
          state.top_5_users.isLoading = true;
          break;
        case 'top_5_roles':
          state.top_5_roles.isLoading = true;
          break;
        default:
          break;
      }
    },
    getQueryHistorySuccess(state, action) {
      const data = action?.payload?.data ?? [];
      const { days, type } = action.payload?.request_data ?? {};

      switch (type) {
        case 'execution_time_per_day':
          state.execution_time_per_day = {
            data: data,
            days: days,
            isLoading: false
          };
          break;
        case 'recent_queries':
          state.recent_queries = {
            data: data,
            days: days,
            isLoading: false
          };
          break;
        case 'top_5_users':
          state.top_5_users = {
            data: data,
            isLoading: false
          };
          break;
        case 'top_5_roles':
          state.top_5_roles = {
            data: data,
            isLoading: false
          };
          break;
        default:
          break;
      }

      state.isLoading = false;
    },
    getQueryHistoryFailure(state, action) {
      state.isLoading = false;
      const { type } = action.payload;
      switch (type) {
        case 'execution_time_per_day':
          state.execution_time_per_day.isLoading = false;
          break;
        case 'recent_queries':
          state.recent_queries.isLoading = false;
          break;
        case 'top_5_users':
          state.top_5_users.isLoading = false;
          break;
        case 'top_5_roles':
          state.top_5_roles.isLoading = false;
          break;
        default:
          break;
      }
    },
    clearQueryState(state) {
      state.queryError = "";
      state.query_result = {
        headers: [],
        data: [],
        isLoading: false
      };
      state.querySubmit = false;
    },
    resetUsageDetail(state) {
      state.default_query = "";
      state.saved_queries = [];
      state.query_result = {
        headers: [],
        data: [],
        isLoading: false
      };
      state.dateRange = 7;
      state.execution_time_per_day = {
        data: [],
        isLoading: false
      };
      state.recent_queries = {
        data: [],
        isLoading: false
      };
      state.top_5_users = {
        data: [],
        isLoading: false
      };
      state.top_5_roles = {
        data: [],
        isLoading: false
      };
    }
  }
});

export const { getUsageRequest, getUsageSuccess, getUsageFailure, runQueryRequest, runQuerySuccess, runQueryFailure, updateUsageRequest,
  updateUsageSuccess, updateUsageFailure, createUsageRequest, createUsageSuccess, createUsageFailure, removeUsageRequest, removeUsageSuccess,
  removeUsageFailure, getQueryHistoryRequest, getQueryHistorySuccess, getQueryHistoryFailure, resetUsageDetail, clearQueryState
} = usageSlice.actions;
export default usageSlice.reducer;