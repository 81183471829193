import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  createReferenceRequest, createReferenceSuccess, createReferenceFailure, updateReferenceRequest,
  updateReferenceSuccess, updateReferenceFailure, deleteReferenceRequest, deleteReferenceSuccess,
  deleteReferenceFailure, getReferenceDetailRequest, getReferenceDetailSuccess, getReferenceDetailFailure,
  getReferencesRequest, getReferencesSuccess, getReferencesFailure
} from '../reducer/librariesReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { librariesService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';

/**
 * Get Libraries
 * @returns
 */
const getReferences = (action$) => action$.pipe(
  ofType(getReferencesRequest),
  mergeMap(({ payload: { params, token } }) => {
    return from(librariesService.getReferences(params, token)).pipe(
      concatMap((res) => { return of(getReferencesSuccess(res.data)); }),
      catchError((error) => { return of(getReferencesFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Create Reference
 * @param {*} action$
 * @returns
 */
const createReference = (action$) => action$.pipe(
  ofType(createReferenceRequest),
  mergeMap(({ payload }) => {
    return from(librariesService.createReference(payload)).pipe(
      concatMap((res) => { return of(createReferenceSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created })); }),
      catchError((error) => { return of(createReferenceFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Reference
 * @param {*} action$
 * @returns
 */
const updateReference = (action$) => action$.pipe(
  ofType(updateReferenceRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(librariesService.updateReference(id, rest)).pipe(
      concatMap((res) => { return of(updateReferenceSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateReferenceFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Delete Reference
 * @param {*} action$
 * @returns
 */
const deleteReference = (action$) => action$.pipe(
  ofType(deleteReferenceRequest),
  mergeMap(({ payload }) => {
    return from(librariesService.deleteReference(payload)).pipe(
      concatMap(() => of(deleteReferenceSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
      catchError((error) => of(deleteReferenceFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Reference Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
  ofType(getReferenceDetailRequest),
  mergeMap(({ payload }) => {
    return from(librariesService.detail(payload)).pipe(
      concatMap((res) => of(getReferenceDetailSuccess(res))),
      catchError((error) => {
        const errorMsg = error;
        if (validate404Error(errorMsg)) {
          return of(getReferenceDetailFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
        }
        return of(getReferenceDetailFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
      })
    );
  })
);


// Export All Reference Functions
export const librariesEpic = [getReferences, detail, createReference, deleteReference, updateReference];