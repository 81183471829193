const HomeStyle = (theme) => ({
    responsizeGrid: {
        '& .react-grid-item': {
            '&:hover': {
                '& .ResizeCorner': {
                    opacity: '1 !important',
                    visibiltity: 'visible',
                    transition: 'all 0.5s'
                },
                '& .widgetContainer': {
                    border: `1.5px solid ${theme.palette.secondary.main}`
                }
            },
            '& .ResizeCorner': {
                position: 'absolute',
                right: 0,
                bottom: 0,
                paddingRight: '5px',
                paddingBottom: '5px',
                cursor: 'se-resize',
                opacity: 0,
                zIndex: 2,
                '&:hover': {
                    opacity: '1 !important'
                },
                '& path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .react-grid-item.react-grid-placeholder': {
            pointerEvents: 'none'

        },
        '& .react-resizable-handle': {
            // display: 'none !important'
            opacity: 0,
            bottom: '5px',
            right: '5px',
            zIndex: 2
        }
    }
});

export default HomeStyle;