import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent, SelectComponent } from '../../../components/index.js';
import SchemaSelector from '../components/schemaSelector/index.jsx';

//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

/*
 * import Images
 */
import RedshiftSpectrumIcon from '../../../assets/img/connectors/redshift_spectrum.png';


// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import appConstants from '../../../constants/appConstants.js';
import { useDispatch, useSelector } from 'react-redux';
import { clearConnectionState, connectionCreateRequest, connectionDetailRequest, connectionUpdateRequest, getDBSchemasListRequest } from '../../../redux/reducer/connectionReducer.js';
import { Crypto } from '../../../helpers/index.js';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';

// Import Actions

function RedshiftSpectrum(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'redshift_spectrum',
        description: '',
        server: '',
        port: '',
        database: '',
        authentication_type: appConstants.labels.connector.redshift_spectrum.authentication_type_dd[0],
        schema: [],
        user: '',
        password: '',
        awsaccesskey: '',
        awssecretaccesskey: '',
        secretname: '',
        region: ''
    });

    const {
        id,
        type,
        name,
        description,
        server,
        port,
        database,
        authentication_type,
        schema,
        user,
        password,
        awsaccesskey,
        awssecretaccesskey,
        secretname,
        region
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, dbs_schema, saveIsLoading, schemaLoading } = useSelector((state) => state.connection);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getDBSchemasListRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);


    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            if (credentialDetails.authentication_type === appConstants.labels.connector.redshift_spectrum.authentication_type_dd[0]) {
                credentialDetails.user = encryptDecrypt.decrypt(credentialDetails?.user ?? "");
                credentialDetails.password = encryptDecrypt.decrypt(credentialDetails?.password ?? "");
            } else {
                credentialDetails.awsaccesskey = encryptDecrypt.decrypt(credentialDetails?.awsaccesskey ?? "");
                credentialDetails.awssecretaccesskey = encryptDecrypt.decrypt(credentialDetails?.awssecretaccesskey ?? "");
            }
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                database,
                authentication_type,
                schema
            }
        };
        if (authentication_type === "Secret Manager") {
            postData.credentials.awsaccesskey = encryptDecrypt.encrypt(awsaccesskey);
            postData.credentials.awssecretaccesskey = encryptDecrypt.encrypt(awssecretaccesskey);
            postData.credentials.region = region;
            postData.credentials.secretname = secretname;
        } else {
            postData.credentials.user = encryptDecrypt.encrypt(user);
            postData.credentials.password = encryptDecrypt.encrypt(password);
            postData.credentials.port = port;
            postData.credentials.server = server;

        }
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(null);
    };

    /**
     * Handle Database Value Selection
     * @param {*} newValue
     */
    const handleSchemaSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, schema: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={RedshiftSpectrumIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.redshift_spectrum.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.redshift_spectrum
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={8} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_database"
                                name={'database'}
                                value={database}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.database}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.database
                                        )
                                    ]
                                }
                                onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <SelectComponent
                                value={authentication_type || ''}
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.authType}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                variant="standard"
                                name={'authentication_type'}
                                list={appConstants.labels.connector.redshift_spectrum.authentication_type_dd}
                                onSelectChange={(value) => { handleTextBoxChanges('authentication_type', value); setIsAuthenticationChanged(true); }}
                            />
                        </Grid>
                        {/* UserName password fields */}
                        {
                            appConstants.labels.connector.redshift_spectrum.authentication_type_dd[0] === authentication_type &&
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <MemoTextBoxComponent
                                        id="form_text_server"
                                        name={'server'}
                                        value={server}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.server}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.server
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={5} className="pl-10">
                                    <MemoTextBoxComponent
                                        id="form_text_port"
                                        name={'port'}
                                        value={port}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.port}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.port
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MemoTextBoxComponent
                                        id="form_text_user"
                                        name={'user'}
                                        value={user}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.user}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.user
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={5} className="pl-10">
                                    <PasswordInputComponent
                                        id="form_text_password"
                                        name={'password'}
                                        value={password}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.authentication.password}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['passwordRequired']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.authentication
                                                        .password
                                                )
                                            ]
                                        }
                                        onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true);}}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {/* //Secret Key */}
                        {
                            appConstants.labels.connector.redshift_spectrum.authentication_type_dd[1] === authentication_type &&
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <MemoTextBoxComponent
                                        id="form_text_awsaccesskey"
                                        name={'awsaccesskey'}
                                        value={awsaccesskey}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.awsAccessKey}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.awsAccessKey
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={5} className="pl-10">
                                    <PasswordInputComponent
                                        id="form_text_awssecretaccesskey"
                                        name={'awssecretaccesskey'}
                                        value={awssecretaccesskey}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.awsSecretAccessKey}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.awsSecretAccessKey
                                                )
                                            ]
                                        }
                                        onChange={(event, value) => { handleTextBoxChanges(event.target.name, value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MemoTextBoxComponent
                                        id="form_text_secretname"
                                        name={'secretname'}
                                        value={secretname}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.secretName}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.secretName
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={5} className="pl-10">
                                    <MemoTextBoxComponent
                                        id="form_text_region"
                                        name={'region'}
                                        value={region}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.region}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.region
                                                )
                                            ]
                                        }
                                        onChange={(event) => { handleTextBoxChanges(event.target.name, event.target.value); setIsAuthenticationChanged(true); }}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {/* //Secret Key */}
                        {
                            detailById?.is_valid &&
                            <SchemaSelector
                                dbs_schema={dbs_schema || []}
                                schema={schema || []}
                                handleSchemaSelection={handleSchemaSelection}
                                schemaLoading={schemaLoading}
                                detailById={detailById}
                                divSpan={4}
                            />
                        }
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid >
        </Grid >
    );
}

// default props
RedshiftSpectrum.defaultProps = {
    classes: {}
};

// prop types
RedshiftSpectrum.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(RedshiftSpectrum);