const style = (theme) => ({
    FiltersContainer: {
        '& .MuiOutlinedInput-root': {
            padding: '5px 9px !important',
            borderRadius: '0px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiSelect-select': {
            padding: '6px !important'
        },
        '& .sideBarItem': {
            marginBottom: 20,
            '& .title': {
                paddingBottom: 6,
                borderBottom: `1px solid ${theme.palette.greyshades.darkgrey}40`
            },
            '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
                color: theme.palette.greyshades.darkgrey,
                fontSize: '1rem'
            },
            '& .MuiRating-icon svg.MuiSvgIcon-root': {
                fontSize: '1.4rem !important'
            },
            '& .MuiRating-root': {
                color: '#F6C831 !important'
            },
            '& .MuiPaper-root': {
                marginTop: 0,
                background: 'transparent !important',
                boxShadow: 'none !important',
                '&:before': {
                    background: 'transparent'
                },
                '&.Mui-expanded': {
                    margin: '0px !important'
                }
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: '32.5px !important',
                minHeight: '32.5px !important',
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-root': {
                padding: '0px !important',
                minHeight: '32.5px !important',
                height: '32.5px !important'
            },
            '& .MuiAccordionDetails-root': {
                padding: '0px !important'
            },
            '& .MuiAccordionSummary-expandIconWrapper:hover': {
                '& svg': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .sidebarItemBody': {
            padding: '13px 8px 0',
            '& .MuiCheckbox-root .MuiSvgIcon-root': {
                fontSize: '1.3rem'
            },
            '& .rangeSlider': {
                alignItems: 'center'
            },
            '& .MuiCheckbox-root': {
                padding: '6px !important'
            },
            '& .MuiFormControlLabel-root': {
                width: '100%',
                '& .MuiTypography-root': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }
            },
            '& .checkedIcon': {
                color: `${theme.palette.secondary.main} !important`,
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    }
});

export default style;