// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Terms List
 * @returns
 */
async function list(params) {
    const response = await getRequest(`${urlConstants.semantic.terms.url}?${new URLSearchParams(params).toString()}`);
    return response;
}

/**
 * Get Detail
 * @returns
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.semantic.terms.url}${id}/`);
    return response;
}


/**
 * Update Detail
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.semantic.terms.url}${id}/`, params);
    return response;
}


/**
 * Get Linked Assets
 * @returns
 */
async function getLinkedAssets(termId) {
    const response = await getRequest(`${urlConstants.semantic.terms.getLinkedAssets}${termId}/`);
    return response;
}

/**
 * Link Assets
 * @param {object} params
 * @returns
 */
async function linkAssets(params) {
    const response = await postRequest(`${urlConstants.semantic.terms.linkAssets}`, params);
    return response;
}

/**
 * UnLink Asset
 * @param {object} params
 * @returns
 */
async function unLinkAsset(id, params) {
    const response = await putRequest(`${urlConstants.semantic.terms.unLinkAsset}${id}/`, params);
    return response;
}

/**
 * Delete Term
 * @param {*} id
 * @returns
 */
async function remove(id) {
    const response = await deleteRequest(`${urlConstants.semantic.terms.url}${id}/`);
    return response;
}

/**
 * Term List Mapped To Asset With Count for Search Filter
 * @returns
 */
async function mappedList() {
    const response = await getRequest(`${urlConstants.semantic.terms.mapped_list}`);
    return response;
}

/**
 * Term List Mapped To Asset With Count for Search Filter
 * @returns
 */
async function mappedListFilter() {
    const response = await getRequest(`${urlConstants.semantic.terms.mapped_list_filter}`);
    return response;
}

/**
 * Export All Service Functions
 */
export const termService = {
    list, detail, update, getLinkedAssets, linkAssets, unLinkAsset, remove, mappedList, mappedListFilter
};