// Default Imports
import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';

// Import Styles
import style from "./style.jsx";

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Helpers
import { nFormatter, numberWithCommas } from '../../helpers/appHelpers';


const BarChartComponent = (props) => {

    /**
     * Define Props
     */
    const { chartData, editable, handleEditEvent, validKey } = props;

    /**
     * Define Ref
     */
    const myChart = useRef();

    /**
     * Define Chart Options
     */
    const option = {

        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },

        // X Axis Label Style and content
        xAxis: {
            type: 'category',
            splitLine: {
                show: false
            },
            axisLabel: {
                rotate: 30,
                margin: 10,
                overflow: 'truncate',
                showMinLabel: true,
                showMaxLabel: true
            },
            axisTick: {
                show: true,
                alignWithLabel: true
            }
        },

        // Y Axis Label Style and content
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLine: {
                show: true
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                formatter: (value) => {
                    return nFormatter(value, 'default');
                }
            }
        },

        // Tooltip Style and content
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: 'none'
            },
            formatter: (params) => {
                const tar = params[0];
                return `
                        Name: ${tar?.data.value[0]}<br />
                        Count: ${numberWithCommas(tar?.data.value[1])}<br />
                        `;
            }
        },

        /**
         * Set position of the canvas
         */
        grid: {
            left: 50,
            right: 10,
            top: 10,
            containLabel: true
        },

        // Set Type and Data of the chart
        series: [
            {
                silent: true,
                emphasis: {
                    itemStyle: {
                        opacity: 'none'
                    }
                },
                data: chartData.map((item) => {
                    return {
                        value: [item?.name ?? item.value, item.count, item[validKey]], itemStyle: {
                            color: (!item[validKey]) ? 'rgba(241, 184, 177, 0.5)' : 'rgba(182, 217, 233, 0.5)'
                        }
                    };
                }),
                type: 'bar',
                barMaxWidth: 15
            }
        ]
    };

    /**
     * To Change the value of Data when Edit
     * @param {*} current
     * @param {*} index
     */
    function onChangeVal(current, index) {
        const tempArr = [...chartData];
        tempArr[index][validKey] = !current;
        handleEditEvent(tempArr);
    }

    /**
     * To Create a clickable graphic representation for edit
     */
    if (editable) {
        setTimeout(() => {
            myChart.current?.getEchartsInstance().setOption({
                //Draw Line threshold Graphic
                graphic: chartData.map((item, index) => {
                    const xPos1 = myChart.current?.getEchartsInstance().convertToPixel('grid', [_.isString(item.name) ? item.name : _.toString(item.name), 0]);
                    return {
                        type: 'image',
                        z: 80,
                        x: xPos1[0] - 12,
                        bottom: 20,
                        onclick: () => onChangeVal(item[validKey], index, item.measure_id),
                        style: {
                            image: `${require(item[validKey] ? '../../assets/img/close.png' : '../../assets/img/tick.png')}`,
                            width: 24,
                            height: 24
                        }
                    };
                })
            });
        }, 0);
    }


    return (
        <ReactEcharts ref={myChart} option={option} opts={{ renderer: "svg" }} id="barChart_Id" style={{ height: 400 }} notMerge={Boolean(!editable)} />
    );
};


/**
 * Define Prop Types
 */
BarChartComponent.propTypes = {
    chartData: PropTypes.array,
    editable: PropTypes.bool,
    handleEditEvent: PropTypes.func,
    validKey: PropTypes.string
};

/**
 * Set Default Values
 */
BarChartComponent.defaultProps = {
    chartData: [],
    editable: false,
    handleEditEvent: () => { },
    validKey: 'is_valid'
};

export default withStyles((theme) => ({
    ...style(theme)
}))(BarChartComponent);