import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

//  Import Components
import { TableComponent, DialogComponent } from '../../components/index.js';
import Scheduler from '../../components/scheduler/index.jsx';
import WidgetReports from '../../containers/dashboard/reports/index.jsx';

// Import Constant
import appConstants from '../../constants/appConstants.js';
import featureConstants from '../../constants/featureConstants.js';

// Import Actions
import { getReportsRequest, deleteReportRequest, getReportsRunStatusSuccess, getReportsRunStatusRequest } from '../../redux/reducer/reportsReducer.js';
import { navigate } from '../../redux/reducer/navigationReducer';
import { updateUserPreference } from '../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer';

// Import Helpers
import { checkPermission, permissionHeaders, toDataUrl, getUserPreference, prepareUpdateUserPreference } from '../../helpers/appHelpers';
import { scheduleService } from '../../redux/service/scheduleService.js';


function Reports(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const searchControllerRef = useRef();

    /**
     * Redux Store to get values
     */
    const { reports, isLoading, search: searchFilter, loadMore } = useSelector((state) => state.reports);
    const { permission, user } = useSelector((state) => state.auth);
    const reportsPermission = checkPermission(permission, featureConstants.home.reports);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "reports", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "reports", "sorting");
    let statusChecker = null;

    /**
     * Define State
     */
    const filterData = searchFilter?.search_by ?? {};
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [scheduler, setScheduler] = useState(null);
    const [widgetReport, setWidgetReport] = useState(null);
    const [searchData, setSearchData] = useState({
        ...filterData
    });

    /**
     * Fetches the list of Reports
     * @param {*} params
     */
    const getReports = (params, clear = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        const requestParams = {
            ...searchFilter,
            ...params
        };
        dispatch(getReportsRequest({ params: requestParams, token, clear }));
    };

    /**
     * Handle New Reports
     */
    const handleReports = () => {
        dispatch(navigate({ path: 'home.update_reports', state: {}, params: ['new'] }));
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, item) => {
        switch (key) {
            case "name":
                if (item.type === 'Custom Widget') {
                    setWidgetReport({ "open": true, "report_id": item.id });
                } else {
                    dispatch(navigate({ path: 'home.update_reports', state: {}, params: [item.id] }));
                }
                break;
            default:
                break;
        }
    };

    /**
     * Confirm Dialog for Delete Reports
     * @param {*} data
     */
    const onDeleteReportAction = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.reportDeleteMessage,
            data
        });
    };

    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = (selectedItem, event) => {
        event.stopPropagation();
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, isMeasure: false, isReport: true, reportId: selectedItem.id });
    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (data) => {
        if (!data?.run_status || data?.run_status === 'Completed') {
            scheduleService.trigger({ "report_id": data.id, job_type: "report" }).then(() => {
                const reportStatus = [{ id: data.id, status: "Pending" }];
                dispatch(getReportsRunStatusSuccess({ data: reportStatus }));
            }).catch(() => { });
        }
    };


    /**
     * Check Run Status
     */
    const checkRunStatus = () => {
        dispatch(getReportsRunStatusRequest());
    };


    /**
     *
     * Define Use Effects for report run status checker
     */
    useEffect(() => {
        if (!statusChecker) {
            statusChecker = setInterval(() => checkRunStatus(), appConstants.statusCheckInterval);
        }
        return () => {
            if (statusChecker) {
                clearInterval(statusChecker);
                statusChecker = null;
            }
        };
    }, [dispatch]);

    /**
     * Handle Action Event
     * @param {*} item
     * @param {*} actionName
     */
    const onClickActions = (item, actionName, event) => {
        switch (actionName) {
            case "delete":
                onDeleteReportAction(item);
                break;
            case "download":
                let last_generated_file_format = '';
                if (item.last_generated_file.toLowerCase().includes('pdf')) {
                    last_generated_file_format = 'pdf';
                } else {
                    last_generated_file_format = 'csv';
                }
                toDataUrl(item.last_generated_file, (data) => {
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = `${item.name.replace(/ /g, "_")}.${last_generated_file_format.toLowerCase()}`;
                    link.dispatchEvent(new MouseEvent('click'));
                });

                break;
            case "schedule":
                openScheduler(item, event);
                break;
            case 'run':
                if ((reportsPermission?.is_edit)) {
                    handleJobTrigger(item);
                }
                break;
            default:
                break;
        }
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        getReports({ search_by: search_by, offset: 0 }, true);
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "reports", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy, offset: 0
        };
        updatePreference({ sorting: { sortBy, orderBy } });
        getReports(requestData, true);
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = () => {
        dispatch(deleteReportRequest(showDialog.data.id));
        dialogCancelEventHandle();
    };


    /**
     * Clear Search Filters
     */
    const clearFilters = () => {
        let search_by = { ...searchData };
        search_by = Object.keys(search_by).forEach((key) => search_by[key] = '');
        setSearchData({ ...search_by });
        getReports({ search_by: search_by, offset: 0 }, true);
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Get Memozied Table Options
     * @param {*} searchFilters
     * @returns
     */
    const getTableOptions = () => {
        const options = [
            { type: "clear", customFunction: clearFilters },
            { type: "search", customFunction: null },
            { type: "columns", customFunction: null }
        ];
        if (reportsPermission?.is_edit) {
            options.push({ type: "add", customFunction: handleReports });
        }
        return options;
    };
    const tableOptions = getTableOptions(searchData);

    const reportsHeader = [
        { key: 'name', name: 'Name', sorting: true, tooltip: true, width: "15%", isSearch: true, searchComponent: "text", searchKey: "name", clickable: true },
        { key: 'description', name: 'Description', sorting: true, width: "20%", isSearch: true, searchComponent: "text", searchKey: "description" },
        { key: 'type', name: 'Type', sorting: true, tooltip: true, width: "10%", isSearch: true, searchComponent: "text", searchKey: "type" },
        { key: 'last_run', name: 'Last Run', sorting: true, width: "10%", isSearch: false, searchComponent: "text", searchKey: "last_run", type: "date" },
        { key: 'next_schedule_date', name: 'Next Run', sorting: true, width: "10%", isSearch: false, searchComponent: "text", searchKey: "next_schedule_date", type: "date" },
        { key: 'created_date', name: 'Created At', sorting: true, width: "10%", isSearch: false, searchComponent: "text", searchKey: "created_date", type: "date" },
        {
            key: 'actions',
            name: 'Actions',
            sorting: false,
            tooltip: false,
            width: '10%',
            actions: [
                { type: 'run', tooltip: true, getToolTipText: (data) => { return (data?.data?.run_status && data?.data?.run_status !== 'Completed') ? data?.data?.run_status : 'Run Now'; } },
                { type: 'download', tooltip: true, renderAction: (data) => (data.last_generated_file), tooltipText: 'Download Last Generated Report' },
                { type: 'schedule', tooltip: true, tooltipText: 'Schedule', renderAction: (data) => ((data?.type === "Custom Widget" && data?.recurring) || data?.type !== 'Custom Widget') },
                { type: 'delete', tooltip: true, tooltipText: 'Delete Report' }
            ]
        }
    ];

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreReports = () => {
        if (loadMore && !isLoading) {
            getReports({ offset: (searchFilter.offset + searchFilter.limit) });
        }
    };

    /**
     * Use Effect for Initial Load
     */
    useEffect(() => {
        const requestData = {
            offset: 0,
            search_by: { ...searchData }
        };
        if (sorting) {
            requestData.sortBy = sorting.sortBy;
            requestData.orderBy = sorting.orderBy;
        }
        getReports(requestData, true);
    }, []);


    return (
        <Grid className={classes.pageContainer}>
            <Grid item xs={12} className="">
                <TableComponent
                    title={`Reports`}
                    styleType="striped"
                    headers={permissionHeaders(reportsHeader, reportsPermission)}
                    data={reports || []}
                    options={tableOptions}
                    height="calc(100vh - 170px)"
                    NoResultText="No Reports Found"
                    isLoading={isLoading}
                    onClickActions={onClickActions}
                    onCellClick={handleCellClickEvent}
                    sortBy={searchFilter?.sortBy ?? ''}
                    orderBy={searchFilter?.orderBy ?? 'asc'}
                    searchData={searchData}
                    onClickSorting={onClickSorting}
                    onHandleSearchEvent={onHandleSearchEvent}
                    stickyHeader
                    onScrollEnd={loadMoreReports}
                    onColumnChange={(columns) => onColumnsChange(columns)}
                    userPreferenceColumns={columns || []}
                />
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    optionButtonContent={{ show: false, title: appConstants.dialogBox.purge, color: 'secondary' }}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }

            {
                scheduler?.open &&
                <Scheduler
                    scheduleProps={scheduler}
                    onClose={() => setScheduler({ open: false, anchorElement: null, isAsset: false, isMeasure: false, isReport: true, reportId: scheduler.reportId })}
                    disabled={!reportsPermission?.is_edit}
                />
            }
            {
                widgetReport?.open &&
                <WidgetReports
                    report_id={widgetReport.report_id}
                    open={widgetReport?.open}
                    toggleDrawer={() => setWidgetReport(null)}
                />
            }
        </Grid>
    );
}

// default props
Reports.defaultProps = {
    classes: {}
};

// prop types
Reports.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Reports);