import { createSlice } from '@reduxjs/toolkit';
import appConstants from '../../constants/appConstants';


const initialState = {
    isLoading: false,
    id: "",
    name: "",
    asset_id: "",
    version_id: "",
    list: [],
    detail: {
        statisticsPreviewData: {}
    },
    attributes: [],
    allAttributes: [],
    isLoadingAllAttributes: false,
    invalidRows: [],
    invalidRowsConfig: {
        sortBy: '',
        orderBy: 'asc'
    }
};

const attributeSlice = createSlice({
    name: 'attribute',
    initialState: { ...initialState },
    reducers: {
        getAttributesByVersionRequest(state) {
            state.isLoading = true;
        },
        getAttributesByVersionSuccess(state, action) {
            state.list = action.payload.data ? action.payload.data : [];
            state.isLoading = false;
        },
        getAttributesByVersionFailure(state) {
            state.isLoading = false;
        },
        getAttributesDetailRequest(state) {
            state.isLoading = true;
        },
        getAttributesDetailSuccess(state, action) {
            state.detail = action.payload.data ? action.payload.data : {};
            state.id = action.payload?.data?.id ?? null;
            state.asset_id = action.payload?.data?.asset_id ?? null;
            state.version_id = action.payload?.data?.version_id ?? null;
            let patterns = action.payload?.data?.patterns ?? null;
            if (patterns && typeof (patterns) === 'string') {
                patterns = JSON.parse(patterns);
            }
            let enums = action.payload?.data?.enums ?? null;
            if (enums && typeof (enums) === 'string') {
                enums = JSON.parse(enums);
            }
            let tags = action.payload?.data?.tags ?? null;
            if (tags && typeof (tags) === 'string') {
                tags = JSON.parse(tags);
            }
            state.patterns = patterns ? patterns : [];
            state.enums = enums ? enums : [];
            state.detail = {
                ...state.detail,
                tags: tags ? tags : [],
                statisticsPreviewData: {}
            };
            state.isLoading = false;
        },
        getAttributesDetailFailure(state) {
            state.isLoading = false;
        },
        updateAttributeRequest(state) {
            state.isLoading = true;
        },
        updateAttributeSuccess(state) {
            state.isLoading = false;
        },
        updateAttributeFailure(state) {
            state.isLoading = false;
        },
        updateAttributeMetadataRequest(state) {
            state.isLoading = true;
        },
        updateAttributeMetadataSuccess(state, action) {
            state.isLoading = false;
            if ('term' in action.payload.requestParams) {
                state.detail = {
                    ...state.detail,
                    term: action.payload.data.term,
                    term_approval: action.payload.data.term_approval,
                    term_approval_id: action.payload.data.term_approval_id
                };
            }
        },
        updateAttributeMetadataFailure(state) {
            state.isLoading = false;
        },
        updateAttributeMetadata(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        clearAttributeState(state) {
            state.isLoading = false;
            state.asset_id = "";
            state.version_id = "";
            state.id = "";
            state.name = "";
            state.list = [];
            state.detail = {};
        },
        selectAttribute(state, action) {
            state.id = action.payload;
            state.name = state.list.filter((attr) => attr.id === state.id)[0]?.name ?? '';
        },
        getAllAttributesRequest() { },
        getAllAttributesSuccess(state, action) {
            const attributeList = action.payload.data || [];
            state.attributes = attributeList.map((data) => {
                return {
                    ...data,
                    attributeName: data.name,
                    name: `${data.connection_name}.${data.asset_name}.${data.name}`
                };
            });
        },
        getAllAttributesFailure() { },
        updateAttributePatternRequest() { },
        updateAttributePatternSuccess(state, action) {
            state.detail = {
                ...state.detail,
                patterns: action.payload.patterns ?? []
            };
        },
        updateAttributePatternFailure() { },
        getInvalidRowsRequest(state) {
            state.isLoading = true;
            state.invalidRows = [];
        },
        getInvalidRowsSuccess(state, action) {
            state.invalidRows = action.payload.data || [];
            state.isLoading = false;
        },
        getInvalidRowsFailure(state) {
            state.isLoading = false;
            state.invalidRows = [];
        },
        sortInvalidRows(state, action) {
            const { sortBy, orderBy } = action.payload;
            state.invalidRowsConfig = { sortBy, orderBy };
        },
        clearInvalidRows(state) {
            state.invalidRows = [];
            state.invalidRowsConfig = { sortBy: '', orderBy: 'asc' };
        },
        updateDistributionMetadata(state, action) {
            const technicalName = action.payload?.technical_name || "";
            const isValid = action.payload?.is_positive || false;
            if (action.payload.attribute_measures) {
                state.detail = {
                    ...state.detail,
                    active_advanced_measures: action.payload.attribute_measures.active_advanced_measures,
                    attribute_measures: { ...action.payload.attribute_measures }
                };
            }
            if (!technicalName) {
                return state;
            }
            const measureGroup = appConstants.profile.measureGroups.find((group) => {
                const hasMeasure = group.measures.find((item) => item.technical_name === technicalName);
                return Boolean(hasMeasure);
            });
            if (!measureGroup) {
                return state;
            }
            const measureType = measureGroup.measures.find((item) => item.technical_name === technicalName);
            if (!measureType) {
                return state;
            }

            let distributionMeasure = state.detail[measureGroup.key];
            distributionMeasure = distributionMeasure || [];
            if (!distributionMeasure?.length <= 0) {
                return state;
            }
            const distributionMeasures = JSON.parse(JSON.stringify(distributionMeasure));
            for (const measure of distributionMeasures) {
                if (measure.name !== measureType.name) {
                    continue;
                }
                measure.is_valid = isValid;
            }
            state.detail = {
                ...state.detail,
                [measureGroup.key]: distributionMeasures
            };
            return state;
        },
        toggleActiveMeasureRequest() { },
        toggleActiveMeasureSuccess(state, action) {
            if (action.payload?.data) {
                state.detail = {
                    ...state.detail,
                    active_advanced_measures: action.payload.data.active_advanced_measures,
                    attribute_measures: { ...action.payload.data }
                };
            }
        },
        toggleActiveMeasureFailure() { },
        getActiveMeasureCategoriesRequest() { },
        getActiveMeasureCategoriesSuccess(state, action) {
            if (action.payload?.data) {
                state.detail = {
                    ...state.detail,
                    active_advanced_measures: action.payload.data.active_advanced_measures,
                    attribute_measures: { ...action.payload.data }
                };
            }
        },
        getActiveMeasureCategoriesFailure() { },
        getAllAttributesMetadataRequest(state) { state.isLoadingAllAttributes = true; },
        getAllAttributesMetadataSuccess(state, action) {
            const attributes = action.payload.data.attributes?.length > 0 ? action.payload.data.attributes : [];
            state.isLoadingAllAttributes = false;
            if (action.payload.inputParams?.offset === 0) {
                state.allAttributes = attributes?.length > 0 ? [...attributes] : [];
            } else {
                const allAttributes = [...state.allAttributes];
                if (attributes?.length > 0) {
                    for (const attribute of attributes) {
                        const index = allAttributes.findIndex((item) => item.id === attribute.id);
                        if (index > -1) {
                            continue;
                        }
                        allAttributes.push({ ...attribute });
                    }
                }
                state.allAttributes = [...allAttributes];
            }
            state.totalAttributes = attributes?.length > 0 ? attributes[0].total_attributes : 0;
        },
        getAllAttributesMetadataFailure(state) { state.isLoadingAllAttributes = false; },
        clearAllAttributesMetadata(state) {
            state.allAttributes = [];
            state.totalAttributes = 0;
        },
        updateAllAttributeMetadata(state, action) {
            const { attribute_id, is_list } = action.payload.requestParams;
            if (is_list) {
                return;
            }
            const metadata = action.payload.data || {};
            const attributeIndex = state.allAttributes.findIndex((item) => item.attribute_id === attribute_id);
            if (attributeIndex > -1) {
                const selectedAttribute = JSON.parse(JSON.stringify(state.allAttributes[attributeIndex]));
                if (metadata.isTermApproval) {
                    selectedAttribute.term_id = metadata.term;
                    selectedAttribute.term_approval_id = metadata.term_approval_id;
                    state.allAttributes.splice(attributeIndex, 1, { ...selectedAttribute });
                    return;
                }
                selectedAttribute.description = metadata.description;
                selectedAttribute.is_primary_key = metadata.is_primary_key;
                selectedAttribute.max_length = metadata.max_length;
                selectedAttribute.max_value = metadata.max_value;
                selectedAttribute.min_length = metadata.min_length;
                selectedAttribute.min_value = metadata.min_value;
                selectedAttribute.status = metadata.status;
                selectedAttribute.dqscore = metadata.dqscore;
                selectedAttribute.tags = metadata.tags;
                selectedAttribute.term_id = metadata.term;
                selectedAttribute.term_approval_id = metadata.term_approval;
                selectedAttribute.is_semantic_enabled = metadata.is_semantic_enabled;
                selectedAttribute.is_advanced_profiling = metadata.is_advanced_profiling;
                state.allAttributes.splice(attributeIndex, 1, { ...selectedAttribute });
            }
        },
        updateAssetAttributeMetadata(state, action) {
            const { attribute_id } = action.payload;
            const attributeIndex = state.allAttributes.findIndex((item) => item.attribute_id === attribute_id);
            if (attributeIndex > -1) {
                let selectedAttribute = JSON.parse(JSON.stringify(state.allAttributes[attributeIndex]));
                selectedAttribute = { ...selectedAttribute, ...action.payload };
                state.allAttributes.splice(attributeIndex, 1, { ...selectedAttribute });
            }
        },
        toggleAssetSemanticsRequest() { },
        toggleAssetSemanticsSuccess(state, action) {
            const { is_semantic_enabled } = action.payload.data;
            state.detail.is_semantic_enabled = is_semantic_enabled;
            const attributes = state.allAttributes.map((attribute) => {
                attribute.is_semantic_enabled = is_semantic_enabled;
                return attribute;
            });
            state.allAttributes = [...attributes];
        },
        toggleAssetSemanticsFailure() { },
        toggleAssetAdvancedProfileRequest() { },
        toggleAssetAdvancedProfileSuccess(state, action) {
            const { is_advanced_profiling } = action.payload.data;
            state.detail.is_advanced_profiling = is_advanced_profiling;
            const attributes = state.allAttributes.map((attribute) => {
                attribute.is_advanced_profiling = is_advanced_profiling;
                return attribute;
            });
            state.allAttributes = [...attributes];
        },
        toggleAssetAdvancedProfileFailure() { },
        updateAttributeRunStatus(state, action) {
            const data = action.payload.data;
            const status = data.run_status;
            if ((!data.attribute_id && (status === 'Completed')) || (data.attribute_id && state.detail.id === data.attribute_id)) {
                state.detail.run_status = status;
            }
            const attributes = state.allAttributes.map((attribute) => {
                if ((!data.attribute_id && (status === 'Completed')) || (data.attribute_id && attribute.attribute_id === data.attribute_id)) {
                    attribute.run_status = status;
                }
                return attribute;
            });
            state.allAttributes = [...attributes];
        },
        updateAttributeTermRequest() { },
        updateAttributeTermSuccess() { },
        updateAttributeTermFailure() { },
        getAttributeStatisticsPreviewRequest(state) {
            state.isLoading = true;
        },
        getAttributeStatisticsPreviewSuccess(state, action) {
            const data = action.payload.data || {};
            state.detail.statisticsPreviewData = data;
            state.isLoading = false;
        },
        getAttributeStatisticsPreviewFailure(state) {
            state.isLoading = false;
        }
    }
});

export const { getAttributesByVersionRequest, getAttributesByVersionSuccess, getAttributesByVersionFailure, getAttributesDetailRequest,
    getAttributesDetailSuccess, getAttributesDetailFailure, updateAttributeRequest, updateAttributeSuccess, updateAttributeFailure, updateAttributeMetadataRequest,
    updateAttributeMetadataSuccess, updateAttributeMetadataFailure, clearAttributeState, updateAttributeMetadata, selectAttribute,
    getAllAttributesRequest, getAllAttributesSuccess, getAllAttributesFailure, updateAttributePatternRequest, updateAttributePatternSuccess, updateAttributePatternFailure,
    getInvalidRowsRequest, getInvalidRowsSuccess, getInvalidRowsFailure, sortInvalidRows, clearInvalidRows, updateDistributionMetadata,
    toggleActiveMeasureRequest, toggleActiveMeasureSuccess, toggleActiveMeasureFailure, getActiveMeasureCategoriesRequest, getActiveMeasureCategoriesSuccess, getActiveMeasureCategoriesFailure,
    getAllAttributesMetadataRequest, getAllAttributesMetadataSuccess, getAllAttributesMetadataFailure, clearAllAttributesMetadata, updateAllAttributeMetadata,
    toggleAssetSemanticsRequest, toggleAssetSemanticsSuccess, toggleAssetSemanticsFailure, updateAssetAttributeMetadata,
    toggleAssetAdvancedProfileRequest, toggleAssetAdvancedProfileSuccess, toggleAssetAdvancedProfileFailure, updateAttributeRunStatus,
    updateAttributeTermRequest, updateAttributeTermSuccess, updateAttributeTermFailure, getAttributeStatisticsPreviewRequest, getAttributeStatisticsPreviewSuccess, getAttributeStatisticsPreviewFailure
} = attributeSlice.actions;
export default attributeSlice.reducer;