import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, FormControlLabel, Checkbox, Collapse, IconButton } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Import Components
import TableComponent from './index.jsx';
import TableBodyCellComponent from './tableBodyCell.jsx';

// Import Images
import { ChevDownIcon, ChevUpIcon } from '../../assets/svg/index.js';
import { QueryComponent } from '../index.js';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const TableBodyRowComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, headers, data, parentDetail, rowIndex, haveCheckBox, disableActions, haveSubTable, subTable, onClickCheckbox, onClickAccordian,
        subTableColSpan, selectComponentList, subTableHaveCheckbox, haveSubTableHeader, subTableActions, subTableIsAddData, onSubTableActions,
        onSubTableCompnentEvent, onSubTableHandleChipAdd, onSubTableHandleChipDelete, highlightIndex, onClickSorting, provided, rowDraggable, handleSubRowDragAction, ...rest
    } = props;


    /**
     * Toggle Accordian
     * @param {*} event
     * @param {*} toggle
     */
    const toggleAccordian = (event, toggle) => {
        event.preventDefault();
        if (haveSubTable && toggle) {
            onClickAccordian(data, !data.isOpen);
        }
    };

    /**
     * Handle CheckBox
     * @param {*} event
     * @param {*} data
     */
    const handleCheckBoxClick = (event, data) => {
        onClickCheckbox(event.target.checked, data, parentDetail, false);
    };

    const renderSubTableComponent = () => {
        let component = null;
        switch (subTable.component) {
            case "query":
                const queryProps = subTable.componentProps;
                component = (
                    <QueryComponent
                        name="Query"
                        data={data.query}
                        connection_id={queryProps?.connection_id}
                        disabled={subTable?.isPermissionDisabled}
                        placeholder={queryProps.placeholder ? queryProps.placeholder : ""}
                        classNames={queryProps.className}
                        onChange={(key, value, event) => onSubTableCompnentEvent("query", value, data, event, parentDetail)}
                        enableValidate={Boolean(queryProps?.connection_id || null)}
                        isMeasureLevel
                        removeAggregateQuery
                        assetName={data?.name || ""}
                        isConnectedAsset={Boolean(data?.is_connected)}
                        isValidate
                        validateDuplicate={Boolean(data?.name?.length > 0)}
                    />
                );
                break;
            default:
                component = (
                    <TableComponent
                        {...rest}
                        headers={subTable.headers}
                        haveCheckBox={subTableHaveCheckbox}
                        defaultHeaderCheckBoxValue={data[subTable.rows] && data[subTable.rows].filter((item) => item.is_selected).length === data[subTable.rows].length || false}
                        data={data[subTable.rows] || []}
                        parentDetail={data.id || ''}
                        onClickCheckbox={onClickCheckbox}
                        haveSubTableHeader={haveSubTableHeader}
                        actions={subTableActions}
                        isAdd={subTableIsAddData}
                        onCompnentEvent={onSubTableCompnentEvent}
                        onHandleChipAdd={onSubTableHandleChipAdd}
                        onHandleChipDelete={onSubTableHandleChipDelete}
                        selectComponentList={selectComponentList}
                        onClickActions={onSubTableActions}
                        isLoading={data.isLoading}
                        height={subTable.height}
                        disableActions={disableActions}
                        sortBy={subTable.sortBy}
                        orderBy={subTable.orderBy}
                        onClickSorting={(sortBy, orderBy) => onClickSorting(sortBy, orderBy, data)}
                        rowDraggable={rowDraggable}
                        subTableRowDraggable
                        handleSubRowDragAction={handleSubRowDragAction}

                    />
                );
                break;
        }
        return component;
    };

    return (
        <React.Fragment>
            <TableRow
                {...(rowDraggable && provided.draggableProps)}
                ref={rowDraggable ? provided.innerRef : undefined}
                key={`tableBodyRow-${rowIndex}`}
                className={`${classes.highlightRow} ${rowIndex === highlightIndex ? data?.status?.toLowerCase() : ''}`}>
                {
                    haveCheckBox &&
                    <TableCell key={`tableRowCheckbox-${rowIndex}`} className={"tableChecbox"}>
                        <FormControlLabel control={<Checkbox checked={data.is_selected || false} disabled={disableActions} onChange={(event) => handleCheckBoxClick(event, data)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />} />
                    </TableCell>
                }
                {
                    rowDraggable &&
                    <TableCell key={`cell_drag`} {...(rowDraggable && provided.dragHandleProps)} >
                        <DragIndicatorIcon />
                    </TableCell>
                }

                {
                    headers && headers.map((header, index) =>
                        <TableBodyCellComponent
                            key={`cell_${index}`}
                            header={header}
                            rowIndex={rowIndex}
                            data={data}
                            selectComponentList={selectComponentList}
                            parentDetail={parentDetail}
                            classes={classes}
                            {...rest}
                        />
                    )
                }
                {
                    haveSubTable &&
                    <TableCell key={'accordian-cell'} className="accordionTableColun">
                        <IconButton
                            key={`icon-up-${rowIndex}`}
                            aria-label={data.isOpen ? "Up" : "Down"}
                            color="inherit"
                            className={'p-1 collapseIcon'}
                            onClick={(event) => toggleAccordian(event, true)}
                        >
                            {
                                data.isOpen ? <ChevUpIcon key={`up-${rowIndex}`} />
                                    :
                                    <ChevDownIcon key={`down-${rowIndex}`} />
                            }
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
            {
                haveSubTable &&
                <TableRow className={classes.accordionTableRow}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={subTableColSpan}>
                        <Collapse in={data.isOpen} timeout="auto" unmountOnExit>
                            {renderSubTableComponent()}
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment>
    );
};

/**
 * Set Component Props
 */
TableBodyRowComponent.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    data: PropTypes.object,
    parentDetail: PropTypes.string,
    rowIndex: PropTypes.number,
    haveCheckBox: PropTypes.bool,
    actions: PropTypes.array,
    haveSubTable: PropTypes.bool,
    subTable: PropTypes.object,
    subTableColSpan: PropTypes.number,
    onClickCheckbox: PropTypes.func,
    onClickActions: PropTypes.func,
    onClickAccordian: PropTypes.func,
    onCompnentEvent: PropTypes.func,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    chipClassName: PropTypes.string,
    selectComponentList: PropTypes.object,
    subTableHaveCheckbox: PropTypes.bool,
    haveSubTableHeader: PropTypes.bool,
    subTableActions: PropTypes.array,
    subTableIsAddData: PropTypes.bool,
    onSubTableActions: PropTypes.func,
    onSubTableCompnentEvent: PropTypes.func,
    onSubTableHandleChipAdd: PropTypes.func,
    onSubTableHandleChipDelete: PropTypes.func,
    onClickSorting: PropTypes.func,
    highlightIndex: PropTypes.number,
    disableActions: PropTypes.bool,
    rowDraggable: PropTypes.bool,
    provided: PropTypes.shape({
        innerRef: PropTypes.func.isRequired,
        draggableProps: PropTypes.object.isRequired,
        dragHandleProps: PropTypes.object,
        placeholder: PropTypes.node,
        isDragging: PropTypes.bool
    }),
    handleSubRowDragAction: PropTypes.func
};


/**
 * Set Default Values
 */
TableBodyRowComponent.defaultProps = {
    classes: {},
    headers: [],
    data: {},
    parentDetail: "",
    rowIndex: 0,
    haveCheckBox: false,
    actions: [],
    haveSubTable: false,
    subTable: {},
    subTableColSpan: 0,
    onClickCheckbox: () => { },
    onClickActions: () => { },
    onClickAccordian: () => { },
    onCompnentEvent: () => { },
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    selectComponentList: {},
    subTableHaveCheckbox: false,
    haveSubTableHeader: false,
    subTableActions: [],
    subTableIsAddData: false,
    onSubTableActions: () => { },
    onSubTableCompnentEvent: () => { },
    onSubTableHandleChipAdd: () => { },
    onSubTableHandleChipDelete: () => { },
    onClickSorting: () => { },
    highlightIndex: -1,
    disableActions: false,
    rowDraggable: false,
    provided: {},
    handleSubRowDragAction: () => { }
};

export default TableBodyRowComponent;