import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
    measures: [],
    patterns: [],
    activePatterns: [],
    isLoading: false,
    saveIsLoading: false,
    detail: {
        is_active: true,
        is_auto: true,
        is_drift_enabled: true,
        is_positive: true,
        allow_score: true,
        type: 'custom',
        category: 'conditional'
    },
    dashboardFilter: null,
    searchableMeasuresList: [],
    measureLoading: false,
    automeasures: []
};

const baseMeasureSlice = createSlice({
    name: 'baseMeasure',
    initialState: { ...initialState },
    reducers: {
        createMeasureRequest(state) {
            state.saveIsLoading = true;
        },
        createMeasureSuccess(state, action) {
            const { id, dimension, type, category, is_active } = action.payload.data;

            if (action.payload.data?.type === "pattern") {
                state.patterns = [
                    ...state.patterns,
                    { ...action.payload.data }
                ];
                return;
            }

            state.detail = { ...state.detail, id };
            const newMeasure = {
                description: state.detail.description,
                name: state.detail.name,
                type: type ? type : state.detail.type,
                category: category ? category : state.detail.category,
                is_default: false,
                is_custom: true,
                id,
                dimension,
                is_active
            };
            state.measures = [
                ...state.measures,
                newMeasure
            ];
            state.saveIsLoading = false;
        },
        createMeasureFailure(state) {
            state.saveIsLoading = false;
        },
        getMeasureRequest(state) {
            state.isLoading = true;
        },
        getMeasureSuccess(state, action) {
            state.isLoading = false;
            if (action.payload.payload?.type?.toLowerCase() === "pattern") {
                const patterns = action.payload.data || [];
                for (const pattern of patterns) {
                    if (pattern.pattern && typeof (pattern.pattern) === "string") {
                        pattern.pattern = JSON.parse(pattern.pattern);
                    }
                }
                state.patterns = patterns;
            } else {
                const measures = action.payload.data || [];
                state.measures = _.orderBy(measures, ['name'], ['asc']);
            }
        },
        getMeasureFailure() { },
        updateMeasureDetail(state, action) {
            state.detail = { ...state.detail, ...action.payload };
        },
        updateMeasureRequest(state) {
            state.saveIsLoading = true;
        },
        updateMeasureSuccess(state, action) {
            const { req_data, response } = action.payload;
            const id = response?.id ?? '';
            let measures = state.measures;
            if (response?.type === "pattern") {
                measures = state.patterns;
            }
            const up_index = measures.findIndex((m) => m.id === id);
            if (up_index > -1) {
                let selectedMeasure = measures[up_index];
                selectedMeasure = {
                    ...selectedMeasure,
                    ...response,
                    description: req_data?.description ?? selectedMeasure.description,
                    is_active: req_data?.is_active ?? selectedMeasure.is_active,
                    name: req_data?.name ?? selectedMeasure.name
                };

                if (response?.type === "pattern") {
                    state.patterns.splice(up_index, 1, selectedMeasure);
                } else {
                    state.measures.splice(up_index, 1, selectedMeasure);
                }
            }
            state.saveIsLoading = false;
        },
        updateMeasureFailure(state) {
            state.saveIsLoading = false;
        },
        deleteMeasureRequest() { },
        deleteMeasureSuccess(state, action) {
            const index = state.measures.findIndex((measure) => measure.id === action.payload);
            if (index > -1) {
                state.measures.splice(index, 1);
            }
            const patternIndex = state.patterns.findIndex((measure) => measure.id === action.payload);
            if (patternIndex > -1) {
                state.patterns.splice(patternIndex, 1);
            }
        },
        deleteMeasureFaiure() { },
        getMeasureDetailRequest(state) {
            state.isLoading = true;
        },
        getMeasureDetailSuccess(state, action) {
            state.detail = { ...state.detail, ...action.payload?.data ?? {} };
            state.isLoading = false;
        },
        getMeasureDetailFailure(state) {
            state.isLoading = false;
        },
        updateMeasureProperty(state, action) {
            const { id, ...rest } = action.payload;
            const index = state.measures.findIndex((measure) => measure.id === id);
            if (index !== -1) {
                state.measures[index] = {
                    ...state.measures[index],
                    ...rest
                };
            }
        },
        clearMeasureDetailState(state) {
            state.detail = {
                is_active: true,
                is_auto: true,
                is_drift_enabled: true,
                is_positive: true,
                allow_score: true,
                type: 'custom',
                category: 'conditional'
            };
        },
        clearMeasureList(state) {
            state.measures = [];
        },
        getActivePatternsRequest(state) {
            state.isLoading = false;
        },
        getActivePatternsSuccess(state, action) {
            state.isLoading = false;
            state.activePatterns = action.payload.data || [];
        },
        getActivePatternsFailure() { },
        getDashboardFilterRequest() { },
        getDashboardFilterSuccess(state, action) {
            const response_obj = JSON.parse(action.payload.distinct_values);
            const modified_obj = {};
            for (const key in response_obj) {
                modified_obj[key] = response_obj[key].map((value) => ({ id: value, name: value }));
            }
            state.dashboardFilter = modified_obj;
        },
        getDashboardFilterFailure() { },
        getMeasureNameListRequest(state) {
            state.measureLoading = true;
        },
        getMeasureNameListSuccess(state, action) {
            state.searchableMeasuresList = action.payload || [];
            state.measureLoading = false;
        },
        getMeasureNameListFailure(state) {
            state.measureLoading = false;
        }
    }
});

export const { createMeasureRequest, createMeasureSuccess, createMeasureFailure, getMeasureRequest, getMeasureSuccess, getMeasureFailure,
    updateMeasureRequest, updateMeasureSuccess, updateMeasureFailure, deleteMeasureRequest, deleteMeasureSuccess, deleteMeasureFaiure,
    getMeasureDetailRequest, getMeasureDetailSuccess, getMeasureDetailFailure, updateMeasureDetail, updateMeasureProperty, clearMeasureDetailState,
    clearMeasureList, getActivePatternsRequest, getActivePatternsSuccess, getActivePatternsFailure, getDashboardFilterRequest, getDashboardFilterSuccess,
    getDashboardFilterFailure, getMeasureNameListRequest, getMeasureNameListSuccess, getMeasureNameListFailure
} = baseMeasureSlice.actions;
export default baseMeasureSlice.reducer;