const style = (theme) => ({
    pageContainer: {
        padding: '24px',
        background: '#fff'
    },
    pageInnerBody: {
        '& .MuiLink-root': {
            color: `${theme.palette.primary.main} !important`,
            textDecoration: 'none',
            padding: '0px 5px'
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    appLogo: {
        height: '34px',
        objectFit: 'contain'
    },
    notifyOuter: {
        padding: 24,
        background: theme.palette.greyshades.lightgrey,
        marginLeft: 40,
        marginTop: '20px !important'
    },
    notifyInner: {
        padding: 16,
        background: '#fff'
    },
    logoImg: {
        height: 40,
        width: 40,
        flexShrink: 0,
        objectFit: 'contain'
    },
    notificationhedaer: {
        display: 'flex'
    },
    listItems: {
        borderLeft: `5px solid ${theme.palette.greyshades.lightgrey}`,
        marginLeft: '45px !important',
        padding: '0px 14px',
        marginTop: '15px !important',
        '& .MuiButton-root': {
            border: `1px solid ${theme.palette.greyshades.darkgrey} !important`,
            color: `${theme.palette.headers.body1.color} !important`,
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
            fontSize: '15px !important'
        }
    },
    chartContainer: {
        margin: '10px 0px 20px !important',
        padding: 20,
        border: `1px solid ${theme.palette.greyshades.grey}`,
        borderRadius: 7
    }
});
export default style;