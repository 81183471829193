const style = (theme) => ({
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`,
      borderColor: 'transparent'
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`,
      borderColor: 'transparent'
    },
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`
    },
    '& .MuiAutocomplete-clearIndicator svg': {
      width: '18px',
      height: '18px',
      fill: theme.palette.greyshades.darkgrey,
      marginTop: '2px'
    },
    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 2
    },
    '& .ChevDownIcon': {
      width: '13px',
      height: '13px'
    }
  },
  attributeTypeIcon: {
    color: `${theme.palette.secondary.main} !important`,
    padding: '2px 5px',
    width: '15px !important',
    fontSize: '14px',
    border: `1px solid ${theme.palette.greyshades.lightgrey}`,
    textTransform: 'capitalize',
    flexShrink: 0,
    marginRight: '8px !important',
    textAlign: "center"
  },
  chipDropDownValue: {
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`
    }
  },
  connectorIcon: {
    width: '20px'
  },
  listText: {
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`
    }
  },
  outLineNone: {
    '& .MuiSelect-select': {
        paddingTop: '2.5px !important',
        paddingBottom: '2.5px !important',
        paddingRight: '32px !important'
    },
    '& .MuiOutlinedInput-notchedOutline, & .MuiInput-underline:before': {
        border: 'none !important'
    },
    '&:hover': {
        '& .MuiOutlinedInput-notchedOutline, & .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
        }
    }
},
customPaper: {
  border: `1px solid ${theme.palette.greyshades.lightgrey}`
},
draggableContainer: {
  paddingLeft: '0px',
  marginBottom: '0px',
  marginTop: '0px',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  alignItems: 'center',
  '& .MuiChip-root': {
    margin: '5px',
    cursor: 'move'
  }
}

});
export default style;