import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getAlertsRequest, getAlertsSuccess, getAlertsFailure,
    updateDriftAlertRequest, updateDriftAlertSuccess, updateDriftAlertFailure,
    getAlertsCountRequest, getAlertsCountSuccess, getAlertsCountFailure, exportRequest, exportSuccess, exportFailure
} from '../reducer/driftReducer.js';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { driftService } from '../service';

/**
 * Get Alerts
 * @returns
 */
const getAlerts = (action$) => action$.pipe(
    ofType(getAlertsRequest),
    mergeMap(({ payload }) => {
        return from(driftService.getDriftAlerts(payload.params, payload.token)).pipe(
            concatMap((res) => { return of(getAlertsSuccess({ params: payload.params, data: res?.data ?? [] }), getAlertsCountSuccess({ data: res?.more_data?.statistics ?? {} })); }),
            catchError(() => { return of(getAlertsFailure()); })
        );
    })
);

/**
 * Get Alerts
 * @returns
 */
const getAlertsCount = (action$) => action$.pipe(
    ofType(getAlertsCountRequest),
    mergeMap(({ payload }) => {
        return from(driftService.getDriftAlertsCount(payload.params, payload.token)).pipe(
            concatMap((res) => { return of(getAlertsCountSuccess(res)); }),
            catchError((error) => { return of(getAlertsCountFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * updateDriftAlert
 * @returns
 */
const updateDriftAlert = (action$) => action$.pipe(
    ofType(updateDriftAlertRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(driftService.updateDriftAlert(id, rest)).pipe(
            concatMap(() => { return of(updateDriftAlertSuccess({ id, ...rest })); }),
            catchError(() => { return of(updateDriftAlertFailure()); })
        );
    })
);


/**
 * Export
 * @returns
 */
const exportAlerts = (action$) => action$.pipe(
    ofType(exportRequest),
    mergeMap(({ payload }) => {
        return from(driftService.exportAlerts(payload)).pipe(
            concatMap((res) => { return of(exportSuccess(res.data)); }),
            catchError((error) => { return of(exportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Domains Functions
export const driftEpic = [updateDriftAlert, getAlerts, getAlertsCount, exportAlerts];