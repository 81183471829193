import CryptoJS from "crypto-js";


export class Crypto {
    constructor() {
        const { REACT_APP_ENCRYPT_KEY, REACT_APP_ENCRYPT_IV_SECRET } = process.env;
        this.parseKey = CryptoJS.enc.Utf8.parse(REACT_APP_ENCRYPT_KEY);
        this.parseIV = CryptoJS.enc.Utf8.parse(REACT_APP_ENCRYPT_IV_SECRET);
    }

    encrypt(value) {
        const encrypted = CryptoJS.AES.encrypt(value, this.parseKey, { iv: this.parseIV, mode: CryptoJS.mode.CBC });
        return encrypted.toString();
    }

    decrypt(value) {
        try {
            const decrypted = CryptoJS.AES.decrypt(value, this.parseKey, { iv: this.parseIV, mode: CryptoJS.mode.CBC });
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch {
            return value;
        }
    }
}