const style = (theme) => ({
    cardContainer: {
        paddingTop: 10,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: theme.palette.greyshades.lightbackground,
        '& .countText': {
            color: '#2E384D'
        }
    },
    catalogList: {
        paddingLeft: '54px !important',
        WebkitBoxFlex: 1,
        flex: 1,
        transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        marginLeft: 0,
        maxHeight: 'calc(100vh - 121px)',
        overflowY: 'auto',
        '&.sideBarClose': {
            paddingLeft: '45px !important',
            transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            marginLeft: '-260px'
        },
        '@media (min-width: 1200px)': {
            '& .MuiGrid-grid-xs-4': {
                maxWidth: '33.333333%',
                flexBasis: '33.333333%'
            }
        },
        '@media (max-width: 1400px)': {
            '&.sideBarClose': {
                paddingLeft: '22px !important',
                '& .MuiGrid-grid-xs-4': {
                    paddingLeft: '46px'
                }
            }
        },
        '@media (min-width: 1536px)': {
            '&.sideBarClose': {
                '& .MuiGrid-grid-xl-2': {
                    maxWidth: '20%',
                    flexBasis: '20%'
                }
            },
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '25%',
                flexBasis: '25%'
            }
        }
    },
    catalogListContainer: {
        paddingTop: 5,
        // height: 'calc(100vh - 114px)',
        overflowY: 'auto',
        paddingBottom: '0px',
        paddingRight: '24px'
    },
    leftBar: {
        flex: '0 0 auto',
        width: '300px'
    },
    catalogFilter: {
        '& .MuiTab-root:hover': {
            color: `${theme.palette.secondary.main} !important`
        }
    }
});

export default style;