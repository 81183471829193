import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography } from '@mui/material';

//import Styles
import Styles from "./style.jsx";
import LayoutStyles from '../../../../../../../layouts/style.jsx';
import { ChipComponent, RangeSliderComponent } from '../../../../../../../components/index.js';
import appConstants from '../../../../../../../constants/appConstants.js';

// Import Actions
import { getTagsRequest } from '../../../../../../../redux/reducer/tagsReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';

function TopSection(props) {

    /**
     * Define Props
     */
    const { classes, data, onChange, semanticsPermission } = props;
    const dispatch = useDispatch();
    const tagList = useSelector(({ tags }) => tags.searchableTagsList);

    /**
     * Call Tag List API While tag list is empty
     */
    useEffect(() => {
        if (!tagList || !tagList.length) {
            dispatch(getTagsRequest());
        }
    }, [dispatch]);
    return (
        <Grid container spacing={6}>
            <Grid item xs={6} className="pr-4">
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.securityTab.tags}
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="left"
                    className={`pb-1 ${classes.propertiesDescription}`}
                >
                    {appConstants.labels.securityTab.tagsDesc}
                </Typography>
                <ValidatorForm onSubmit={() => { }}>
                    <ChipComponent
                        labelKey="name"
                        limit={2}
                        editable={semanticsPermission?.is_edit}
                        add={semanticsPermission?.is_edit}
                        data={data.tags || []}
                        availableList={tagList || tagList}
                        disableInput={false}
                        addType="autocomplete"
                        haveColor
                        handleChipEvent={(value) => onChange("tags", value)}
                    />
                </ValidatorForm>
            </Grid>
            <Grid item xs={6} className="pl-4">
                <Typography variant="h6" align="left">
                    {appConstants.labels.securityTab.threshold}
                </Typography>
                <Typography
                    align="left"
                    variant="subtitle1"
                    className={`${classes.propertiesDescription}`}
                >
                    {appConstants.labels.securityTab.thresholdDesc}
                </Typography>
                <Grid item className="mt-2">
                    <RangeSliderComponent value={data?.threshold ?? 0} handleSliderChange={(value) => onChange("threshold", value)} disabled={!semanticsPermission?.is_edit} />
                </Grid>
            </Grid>
        </Grid>
    );
}

// prop types
TopSection.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func,
    semanticsPermission: PropTypes.object
};

// default props
TopSection.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { },
    semanticsPermission: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(TopSection, areEqual));