import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import style from "./style.jsx";

const NumberInputComponent = (props) => {

	/**
	 * Define Props
	 */
	const { classes, integeronly, allowNegative, noOutline, onChange, variant, valOnChange, value, fullWidth, retrictZero, minValue, maxValue, ...rest } = props;

	/**
	 * Define States
	 */
	const [inputValue, setInputValue] = useState(value);

	/**
	 * Set Updated Value
	 */
	useEffect(() => {
		setInputValue(value);
	}, [value]);


	/**
	 * Define on change event
	 * @param {*} event
	 */
	const handleValueChange = (event) => {
		let pattern = integeronly ? '^([-]{1})?[0-9]+$' : '^([-]{1})?([0-9]+([.][0-9]*)?|[.][0-9]+)$';
		if (!allowNegative && integeronly) {
			pattern = '^[0-9]+$';
		}
		if (props.pattern) {
			pattern = props.pattern;
		}
		if (retrictZero) {
			pattern = '^[1-9]\\d*$';
		}
		const regex = new RegExp(pattern);
		if (event.target.value === '' || regex.test(event.target.value)) {
			setInputValue(event.target.value);
			if (valOnChange) {
				onChange(event.target.value);
			}
		}
	};

	/**
	 * Define on change event using with debounce
	 */
	const handleOnBlur = (event) => {
		if (event.target.value !== value) {
			onChange(event.target.value);
		}
	};

	return (
		<Grid className={`${classes.numberContainer} ${noOutline ? classes.outLineNone : null} textBoxContainer`}>
			<TextValidator
				className={noOutline ? classes.outLineNone : null}
				fullWidth={fullWidth}
				variant={variant}
				value={inputValue}
				InputProps={{ inputProps: { min: minValue, max: maxValue } }}
				type="number"
				autoComplete={"off"}
				{...rest}
				onChange={(event) => handleValueChange(event)}
				onBlur={(event) => handleOnBlur(event)}
			/>
		</Grid>
	);
};

// prop types
NumberInputComponent.propTypes = {
	onChange: PropTypes.func,
	integeronly: PropTypes.bool,
	allowNegative: PropTypes.bool,
	pattern: PropTypes.string,
	classes: PropTypes.object,
	noOutline: PropTypes.bool,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	valOnChange: PropTypes.bool,
	retrictZero: PropTypes.bool,
	minValue: PropTypes.number,
	maxValue: PropTypes.number
};

// default props
NumberInputComponent.defaultProps = {
	onChange: () => { },
	integeronly: true,
	allowNegative: true,
	pattern: "",
	classes: {},
	noOutline: false,
	variant: 'outlined',
	fullWidth: false,
	value: null,
	valOnChange: false,
	retrictZero: false,
	minValue: null,
	maxValue: null
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export Default Component
 */
export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(NumberInputComponent);

/**
 * Export As Memo Component
 */
export const MemoNumberInputComponent = withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(NumberInputComponent, areEqual));