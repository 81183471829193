import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// Import Componetns
import { TableComponent } from '../../../../components/index.js';
import MeasureFilter from './filter/index.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Styles
import RulesStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Action
import {
    getMeasureRequest, measureSorting, updateMeasureProperty, updateMeasureRequest
} from '../../../../redux/reducer/measureReducer';
import { getDimensionRequest } from '../../../../redux/reducer/dimensionReducer';
import { updateUserPreference } from '../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer';
import { checkPermission, disableFlags, getUserPreference, prepareUpdateUserPreference } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';
import appConstants from '../../../../constants/appConstants.js';

function Measures(props) {

    /**
     * Define Props
     */
    const { classes, attribute_id, asset_id, disableAdd, isAssetLevel, selectedView, version_detail } = props;
    const defaultSearchData = {
        "name": "",
        "description": "",
        "type": "",
        "dimension_name": "",
        "default": "",
        "is_active": "Active",
        "is_positive": "All",
        "is_drift_enabled": "All",
        "allow_score": "All"
    };
    const [searchData, setSearchData] = useState({ ...defaultSearchData });
    const dispatch = useDispatch();
    const measureControllerRef = useRef();
    const { permission } = useSelector((state) => state.auth);
    const propertiesPermission = checkPermission(permission, featureConstants.assets.properties);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);


    /**
     * Redux Select Action
     * @param {*} event
     */
    const { measures, sortBy, orderBy, isLoading } = useSelector((state) => state.measure);
    const { searchableDimensionList } = useSelector((state) => state.dimension);
    const user = useSelector((state) => state.auth.user);
    let columns = getUserPreference(user?.user_preference ?? {}, "table", "assetMeasures", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "assetMeasures", "sorting");
    const selectedFilterTemplate = getUserPreference(user?.user_preference ?? {}, "tableFilter", "assetMeasures", "template");
    const customTemplates = getUserPreference(user?.user_preference ?? {}, "tableFilter", "assetMeasures", "templates");
    columns = columns || [];

    /**
     * Sorting
     */
    useEffect(() => {
        if (sorting) {
            const requestData = {
                sortBy: sorting.sortBy, orderBy: sorting.orderBy, type: "measure"
            };
            dispatch(measureSorting(requestData));
        }
    }, []);

    /**
     * Update Search
     * @param {*} template
     */
    const updateSearch = (template) => {
        let templateSearchData = {};
        if (!template || ["All", "Scoring", "Monitoring"].includes(template)) {
            switch (template) {
                case 'Scoring':
                    templateSearchData = { ...defaultSearchData, allow_score: "Active" };
                    break;
                case 'Monitoring':
                    templateSearchData = { ...defaultSearchData, is_drift_enabled: "Active" };
                    break;
                default:
                    templateSearchData = { ...defaultSearchData };
                    if (selectedView === "All" || template === "All") {
                        templateSearchData = { ...templateSearchData, is_active: "Active" };
                    }
                    break;
            }
        } else {
            const customTemplate = customTemplates.find((data) => data.template === template);
            templateSearchData = customTemplate?.filters ?? defaultSearchData;
        }
        setSearchData({ ...templateSearchData });
    };

    /**
     * Apply Filter Template
     */
    useEffect(() => {
        if (selectedFilterTemplate !== "All") {
            updateSearch(selectedFilterTemplate);
        }
    }, [selectedFilterTemplate]);

    /**
     * Updated the selected view
     */
    useEffect(() => {
        if (selectedView) {
            updateSearch(selectedView);
        }
    }, [selectedView]);


    /**
     * Get Measures Data By Attribute or Asset Level
     */
    useEffect(() => {
        if (measureControllerRef && measureControllerRef.current) {
            measureControllerRef.current.abort();
        }
        measureControllerRef.current = new AbortController();
        const token = { signal: measureControllerRef?.current?.signal };

        if (asset_id && isAssetLevel) {
            dispatch(getMeasureRequest({ query: `?asset_id=${asset_id}`, token }));
        }
        if (asset_id && attribute_id && !isAssetLevel) {
            dispatch(getMeasureRequest({ query: `?attribute_id=${attribute_id}`, token }));
        }
    }, [asset_id, attribute_id, isAssetLevel]);

    /**
     * Get Dimensions List
     */
    useEffect(() => {
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
    }, [dispatch]);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value, key, property) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, key, property, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy, type: "measure"
        };
        dispatch(measureSorting(requestData));
        updatePreference({ sorting: { sortBy, orderBy } }, "table", "assetMeasures");
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        let changedProperties = [];
        const requestParams = {
            [key]: value,
            id: item.id
        };
        dispatch(updateMeasureProperty(requestParams));
        if (key === "weightage" && (parseFloat(value) < 1 || parseFloat(value) > 100)) {
            return false;
        }
        if (key === "is_custom") {
            value = !value;
            key = "is_default";
        }
        if (key === "dimension") {
            value = value.length ? value[0].id : null;
        }

        changedProperties.push(key);
        if (key === "is_active" && !value) {
            requestParams["allow_score"] = value;
            requestParams["is_drift_enabled"] = value;
            changedProperties = [...changedProperties, "allow_score", "is_drift_enabled"];
            if (appConstants.frequencyMeasures.indexOf(item.technical_name.toLowerCase()) < 0) {
                requestParams["is_positive"] = value;
                changedProperties = [...changedProperties, "is_positive"];
            }
        }
        requestParams[key] = value;
        if (attribute_id) {
            requestParams.attribute_id = attribute_id;
        }
        requestParams.updated_properties = [...new Set([...changedProperties])];
        dispatch(updateMeasureRequest(requestParams));
    };

    /**
     * Prepare Filter Measures
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterMeasures = (data, searchFilters, dimensions) => {
        let filterData = [...data].map((obj) => {
            let dimension = dimensions.find((dimension) => dimension.id === obj.dimension);
            dimension = dimension ? [{ ...dimension }] : [];
            return {
                ...obj,
                is_default: obj.is_default ? "Yes" : "No",
                dimension,
                valid_records: obj.is_positive ? obj.valid_records : obj.invalid_records,
                invalid_records: obj.is_positive ? obj.invalid_records : obj.valid_records
            };
        });
        const booleanFields = ["is_active", "is_positive", "allow_score", "is_drift_enabled"];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if ((booleanFields.includes(key) && searchFilters[key] !== "All") || (searchFilters[key] !== "" && !booleanFields.includes(key))) {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (booleanFields.includes(key)) {
                        const value = Boolean(searchFilters[key] === "Active") || Boolean(searchFilters[key] === "Valid");
                        if (item[key] !== value) {
                            return false;
                        }
                    } else {
                        const value = item[key];
                        if (!value?.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        const records = ["total_records", "valid_records", "invalid_records"];
        if (records.includes(sortBy)) {
            const isAllowScore = filterData.filter((item) => item.allow_score);
            const isNotAllowScore = filterData.filter((item) => !item.allow_score);
            filterData = orderBy === "asc" ? [..._.orderBy(isAllowScore, [sortBy], [orderBy]), ...isNotAllowScore] : [...isNotAllowScore, ..._.orderBy(isAllowScore, [sortBy], [orderBy])];
        }
        return filterData;
    };

    /**
     * Measure Add Popup
     */
    const handleMeasureAddEditDialog = () => {
        dispatch(navigate({
            path: 'measure.add', state: {
                attribute_id,
                asset_id,
                isAssetLevel,
                connection_type: version_detail?.type ?? "",
                connection_id: version_detail?.connection_id ?? ""
            }, params: []
        }));
    };

    /**
     * Open Close Measure Quality / Detail Dialog
     */
    const handleMeasureQualityDialog = (measure = null) => {
        dispatch(navigate({
            path: 'measure.detail', state: {
                showEdit: true,
                attribute_id,
                asset_id,
                isAssetLevel,
                prevUrl: true
            }, params: [measure?.id ?? null]
        }));
    };

    /**
     * Filter Measures using UseMemo
     */
    const filterMeasures = useMemo(() => prepareFilterMeasures(measures, searchData, searchableDimensionList), [measures, searchData, searchableDimensionList]);
    const measureTableOptions = [{ type: "search", customFunction: null }, { type: "download", customFunction: null }, { type: "columns", customFunction: null }, { type: "add", customFunction: handleMeasureAddEditDialog }];
    const tableHeaders = [
        { key: 'name', name: 'Name', sorting: true, tooltip: true, width: "15%", isSearch: true, searchComponent: "text", searchKey: "name", showAlert: true, alertKey: "recent_alerts_count", showIssues: true, issueKey: "issues", customFunction: handleMeasureQualityDialog, clickable: true },
        { key: 'score', name: 'Score', width: "5%", sorting: true, component: "score", customFunction: handleMeasureQualityDialog, clickable: true, allowScoreKey: "allow_score", hideDefault: true },
        { key: 'description', name: 'Description', sorting: true, tooltip: true, width: "25%", isSearch: true, searchComponent: "text", searchKey: "description", customFunction: handleMeasureQualityDialog, clickable: true },
        { key: 'is_default', name: 'Default', width: "5%", sorting: true, tooltip: true, component: "text", isSearch: true, searchComponent: "text", searchKey: "is_default", hideDefault: true },
        { key: 'weightage', name: 'Weightage', width: "5%", sorting: true, inlineEdit: true, tooltip: true, defaultValue: 100, datatype: "numeric", component: "textbox", isSearch: false, searchComponent: "text", searchKey: "weightage", isDisabled: (data, header) => disableFlags(data, header) || !propertiesPermission?.is_edit, hideDefault: true, validators: ['required', 'minNumber:1', 'maxNumber:100'], errorMessages: ['Weightage is required', 'Minimum Weigtage is 1', 'Maxiumum Weigtage is 100'] },
        { key: 'is_active', name: 'Active', width: "5%", sorting: true, tooltip: true, component: "switch", isSearch: true, searchComponent: "select", list: ["All", "Active", "Inactive"], searchKey: "is_active", isDisabled: (data, header) => disableFlags(data, header) || !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'is_positive', name: 'Valid', width: "5%", sorting: true, tooltip: true, component: "switch", isSearch: true, searchComponent: "select", list: ["All", "Valid", "Invalid"], searchKey: "is_positive", isDisabled: (data, header) => disableFlags(data, header) || !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'allow_score', name: 'Scoring', width: "5%", sorting: true, tooltip: true, component: "switch", isSearch: true, searchComponent: "select", list: ["All", "Active", "Inactive"], searchKey: "allow_score", isDisabled: (data, header) => disableFlags(data, header) || !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'is_drift_enabled', name: 'Monitor', width: "5%", sorting: true, tooltip: true, component: "switch", isSearch: true, searchComponent: "select", list: ["All", "Active", "Inactive"], searchKey: "is_drift_enabled", isDisabled: (data, header) => disableFlags(data, header), isPermissionDisabled: !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'dimension_name', name: 'Dimension', width: "10%", sorting: true, tooltip: true, isSearch: true, searchComponent: "text", searchKey: "dimension_name", isPermissionDisabled: !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'type', name: 'Category', width: "10%", sorting: true, tooltip: true, isSearch: true, searchComponent: "text", searchKey: "type", hideDefault: true },
        { key: 'invalid_records', name: 'Invalid Records', sorting: true, tooltip: false, width: '10%', isSearch: false, searchComponent: 'text', searchKey: 'invalid_records', hideDefault: true },
        { key: 'valid_records', name: 'Valid Records', sorting: true, tooltip: false, width: '10%', isSearch: false, searchComponent: 'text', searchKey: 'valid_records', hideDefault: true },
        { key: 'total_records', name: 'Total Records', sorting: true, tooltip: false, width: '10%', isSearch: false, searchComponent: 'text', searchKey: 'total_records', hideDefault: true },
        { key: 'last_runs', name: 'Last Result', width: "10%", component: "drift runs" }
    ];

    /**
     * Get Permission Otions
     * @param {*} options
     * @returns
     */
    const permissionOptions = (options) => {
        const filteredOption = [...options];
        if (!propertiesPermission?.is_edit || disableAdd) {
            const index = filteredOption.findIndex((option) => option.type === "add");
            filteredOption.splice(index, 1);
        }
        return filteredOption;
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns }, "table", "assetMeasures");
    };

    /**
     * OnSave Template Preference
     * @param {*} type
     * @param {*} value
     */
    const onSaveTemplatePreference = (type, value) => {
        if (type === "template") {
            updateSearch(value);
            updatePreference({ [type]: value }, "tableFilter", "assetMeasures");
        } else {
            const template = customTemplates || [];
            let selectedTemplate = value.template;
            if (type === "delete_template") {
                template.splice(value.index, 1);
                selectedTemplate = "All";
                updateSearch(selectedTemplate);
            } else {
                template.push({ filters: searchData, ...value });
            }
            updatePreference({ templates: template, template: selectedTemplate }, "tableFilter", "assetMeasures");
        }
    };

    // remove is_active column based on publish permission
    if (approvePermission?.is_none) {
        const measureIndex = tableHeaders.findIndex((x) => x.key === "is_active");
        if (measureIndex > -1) {
            tableHeaders.splice(measureIndex, 1);
        }
    }

    return (
        <Grid container className={classes.rulesContainer}>
            <MeasureFilter onSaveTemplatePreference={onSaveTemplatePreference} customTemplates={customTemplates} selectedTemplate={selectedView || selectedFilterTemplate} />
            <Grid item xs={12} className="mt5">
                <ValidatorForm >
                    <TableComponent
                        headers={tableHeaders}
                        options={permissionOptions(measureTableOptions)}
                        // title={"Measures"}
                        data={filterMeasures || []}
                        stickyHeader
                        sortBy={sortBy}
                        orderBy={orderBy}
                        onClickSorting={onClickSorting}
                        styleType="rowStriped"
                        searchData={searchData}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        onCompnentEvent={onCompnentEvent}
                        onHandleSearchEvent={onHandleSearchEvent}
                        selectComponentList={{ dimension: searchableDimensionList }}
                        NoResultText="No Measures Found"
                        height="calc(100vh - 300px)"
                        isLoading={isLoading}
                        exportParams={
                            {
                                fileName: "property_measures.csv",
                                titleCaseHeader: true,
                                renameColumn: { "dimension_name": "dimension" }
                            }
                        }
                        userPreferenceColumns={columns || []}
                    />
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// prop types
Measures.propTypes = {
    classes: PropTypes.object,
    attribute_id: PropTypes.string,
    asset_id: PropTypes.string,
    selectedView: PropTypes.string,
    isAssetLevel: PropTypes.bool,
    disableAdd: PropTypes.bool,
    version_detail: PropTypes.object
};

// default props
Measures.defaultProps = {
    classes: {},
    attributes: [],
    attribute_id: "",
    asset_id: "",
    selectedView: "",
    isAssetLevel: false,
    disableAdd: false,
    version_detail: {}
};

export default withStyles(
    (theme) => ({
        ...RulesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Measures);