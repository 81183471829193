import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getTagsRequest, getTagsSuccess, getTagsFailure, createTagRequest, createTagSuccess,
  createTagFailure, deleteTagRequest, deleteTagSuccess, deleteTagFailure,
  updateTagRequest, updateTagSuccess, updateTagFailure,
  getTagByIdRequest, getTagByIdSuccess, getTagByIdFailure,
  getTagAttributesRequest, getTagAttributesSuccess, getTagAttributesFailure,
  getTagsQualityRequest, getTagsQualitySuccess, getTagsQualityFailure,
  getSubTagsRequest, getSubTagsSuccess, getSubTagsFailure, getSearchTagListRequest, getSearchTagListSuccess,
  getSearchTagListFailure, updateTagOrderSuccess, updateTagOrderRequest, updateTagOrderFailure,
  getTagsFilterRequest, getTagsFilterSuccess, getTagsFilterFailure, updateSubTagOrderRequest, updateSubTagOrderSuccess, updateSubTagOrderFailure
} from '../reducer/tagsReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { tagsService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';

/**
 * Get Tags
 * @returns
 */
const getTags = (action$) => action$.pipe(
  ofType(getTagsRequest),
  mergeMap(() => {
    return from(tagsService.getTags()).pipe(
      concatMap((res) => { return of(getTagsSuccess(res.data)); }),
      catchError((error) => { return of(getTagsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Get Tag Detail
 * @param {*} action$
 * @returns
 */
const getTagById = (action$) => action$.pipe(
  ofType(getTagByIdRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.getTagById(payload)).pipe(
      concatMap((res) => of(getTagByIdSuccess(res))),
      catchError((error) => {
        const errorMsg = error;
        if (validate404Error(errorMsg)) {
          return of(getTagByIdFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
        }
        return of(getTagByIdFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
      })
    );
  })
);

/**
 * Create Tag
 * @param {*} action$
 * @returns
 */
const createTag = (action$) => action$.pipe(
  ofType(createTagRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.createTag(payload)).pipe(
      concatMap((res) => {
        return of(createTagSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }));
      }),
      catchError((error) => { return of(createTagFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Tag
 * @param {*} action$
 * @returns
 */
const updateTag = (action$) => action$.pipe(
  ofType(updateTagRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(tagsService.updateTag(id, rest)).pipe(
      concatMap((res) => { return of(updateTagSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateTagFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Delete Tag
 * @param {*} action$
 * @returns
 */
const deleteTag = (action$) => action$.pipe(
  ofType(deleteTagRequest),
  mergeMap(({ payload: { id, type, parent_id } }) => {
    return from(tagsService.deleteTag(id, type)).pipe(
      concatMap(() => of(deleteTagSuccess({ id, parent_id }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
      catchError((error) => of(deleteTagFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Sub Tags
 * @param {*} action$
 * @returns
 */
const subTags = (action$) => action$.pipe(
  ofType(getSubTagsRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.subTags(payload)).pipe(
      concatMap((res) => of(getSubTagsSuccess({ ...res }))),
      catchError((error) => of(getSubTagsFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Tag Attributes
 * @param {*} action$
 * @returns
 */
const attributes = (action$) => action$.pipe(
  ofType(getTagAttributesRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.attributes(payload)).pipe(
      concatMap((res) => of(getTagAttributesSuccess({ ...res }))),
      catchError((error) => of(getTagAttributesFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Quality
 * @param {*} action$
 * @returns
 */
const quality = (action$) => action$.pipe(
  ofType(getTagsQualityRequest),
  mergeMap(({ payload: { accordian, clear, ...rest } }) => {
    return from(tagsService.quality(rest)).pipe(
      concatMap((res) => of(getTagsQualitySuccess({ ...res, accordian, clear }))),
      catchError((error) => of(getTagsQualityFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Get Tags
 * @returns
 */
const getTagSearch = (action$) => action$.pipe(
  ofType(getSearchTagListRequest),
  mergeMap(() => {
    return from(tagsService.getTagSearch()).pipe(
      concatMap((res) => { return of(getSearchTagListSuccess(res.data)); }),
      catchError((error) => { return of(getSearchTagListFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);


/**
 * Get Tags
 * @returns
 */
const getTagsFilter = (action$) => action$.pipe(
  ofType(getTagsFilterRequest),
  mergeMap(() => {
    return from(tagsService.getTagsFilter()).pipe(
      concatMap((res) => { return of(getTagsFilterSuccess(res.data)); }),
      catchError((error) => { return of(getTagsFilterFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Tag Order
 * @param {*} action$
 * @returns
 */
const updateTagOrder = (action$) => action$.pipe(
  ofType(updateTagOrderRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.updateTagOrder(payload)).pipe(
      concatMap((res) => { return of(updateTagOrderSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateTagOrderFailure(), displyAlert({ 'type': 'error', 'message': error })))
       );
  })
);

/**
 * Update Sub Tag Order
 * @param {*} action$
 * @returns
 */
const updateSubTagOrder = (action$) => action$.pipe(
  ofType(updateSubTagOrderRequest),
  mergeMap(({ payload }) => {
    return from(tagsService.updateSubTagOrder(payload)).pipe(
      concatMap((res) => { return of(updateSubTagOrderSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateSubTagOrderFailure(), displyAlert({ 'type': 'error', 'message': error })))
       );
  })
);

// Export All Application Functions
export const tagsEpic = [getTags, getTagById, createTag, deleteTag, updateTag, subTags, attributes, quality, getTagSearch, getTagsFilter, updateTagOrder, updateSubTagOrder];