import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { getVersionText } from '../../helpers';

// Define initial state for version reducer
const initialState = {
    isLoading: false,
    id: '',
    asset_id: '',
    detail: {},
    selected_version: {},
    isVersionDetailChanged: false,
    alerts: [],
    shareDetails: {
        link: '',
        type: 'share_asset',
        trigger_type: 'manual',
        title: '',
        file: '',
        base64: '',
        name: '',
        message: '',
        channels: ['email']
    },
    versionHistory: [],
    versionLoading: false,
    historyLoadMore: true,
    filter: {
        limit: 20,
        offset: 0
    },
    measureLogs: [],
    measureLogFilter: {
        limit: 20,
        offset: 0,
        sortBy: 'date',
        orderBy: 'desc'
    }
};

// create version slice for redux state
const versionSlice = createSlice({
    name: 'version',
    initialState: { ...initialState },
    reducers: {
        getVersionRequest(state) {
            state.isLoading = true;
            state.versionHistory = [];
        },
        getVersionSuccess(state, action) {
            state.detail = action.payload.data || {};
            state.asset_id = state.detail?.asset ?? '';
            state.id = state.detail?.id ?? '';
            state.shareDetails = {
                ...state.shareDetails,
                title: state.detail?.name ?? ''
            };
            state.isLoading = false;
        },
        getVersionFailure(state) {
            state.isLoading = false;
        },
        updateVersionState(state, action) {
            const { key, value } = action.payload;
            state.detail = { ...state.detail, [key]: value };
            state.isVersionDetailChanged = true;
        },
        updateVersionRequest() { },
        updateVersionSuccess(state) {
            state.isVersionDetailChanged = false;
        },
        updateVersionFailure() { },
        updateFavoriteRequest(state) {
            state.isLoading = true;
        },
        updateFavoriteSuccess(state) {
            state.detail = {
                ...state.detail,
                is_favourite: !state.detail.is_favourite
            };
            state.isLoading = false;
        },
        updateFavoriteFailure(state) {
            state.isLoading = false;
        },
        getVersionAlertsRequest(state) {
            state.isLoading = true;
        },
        getVersionAlertsSuccess(state, action) {
            state.alerts = action.payload.data || [];
            state.isLoading = false;
        },
        getVersionAlertsFailure(state) {
            state.isLoading = false;
        },
        clearVersionState(state) {
            state.id = '';
            state.asset_id = '';
            state.detail = {};
            state.versions = [];
            state.selected_version = {};
            state.isVersionDetailChanged = false;
            state.alerts = [];
            state.shareDetails = {
                ...state.shareDetails,
                link: '',
                type: 'share_asset',
                file: '',
                base64: '',
                title: ''
            };
        },
        updateVersionBasicInfo(state, action) {
            const data = action?.payload ?? null;
            if (data) {
                state.detail = {
                    ...state.detail,
                    "id": data.version_id || '',
                    "name": data.asset_name || '',
                    "asset": data.asset_id || '',
                    "alerts": data.alerts || '',
                    "issues": data.issues || '',
                    "dqscore": data.dqscore || '',
                    "ratings": data.ratings || '',
                    "type": data.connection_type || '',
                    "asset_type": data.type || '',
                    "created_date": data.created_date || '',
                    "modified_date": data.modified_date || ''
                };
            }
        },
        shareRequest() { },
        shareSuccess() { },
        shareFailure() { },
        updateShareDetails(state, action) {
            state.shareDetails = { ...state.shareDetails, ...action.payload };
        },
        clearShareDetails(state) {
            state.shareDetails = {
                ...state.shareDetails,
                name: '',
                message: '',
                channels: ['email']
            };
        },
        updateStarRating(state, action) {
            const { overall_rating } = action.payload.data;
            state.detail.ratings = overall_rating;
        },
        termApprovalRequest() { },
        termApprovalFailure() { },
        termApprovalSuccess(state, action) {
            const termApproval = action.payload.data || {};
            if (!state.detail?.terms) {
                return;
            }
            const index = state.detail.terms.findIndex(
                (data) =>
                    data &&
                    data.id === termApproval.term_id &&
                    data.attribute_id === termApproval.attribute_id
            );
            if (termApproval.is_approved && index > -1) {
                state.detail.terms[index] = {
                    ...state.detail.terms[index],
                    approval_id: termApproval.term_approval_id
                };
            }
            if (!termApproval.is_approved && !termApproval.id && index > -1) {
                state.detail.terms.splice(index, 1);
            }
        },
        deleteApprovedTermRequest() { },
        deleteApprovedTermFailure() { },
        deleteApprovedTermSuccess(state, action) {
            const deletedTerm = action.payload.data || {};
            const index = state.detail.terms.findIndex(
                (data) =>
                    data.id === deletedTerm.term_id &&
                    data.attribute_id === deletedTerm.attribute_id
            );
            if (index > -1) {
                state.detail.terms.splice(index, 1);
            }
        },
        getAssetStatusRequest() { },
        getAssetStatusFailure() { },
        getAssetStatusSuccess(state, action) {
            const status = action.payload.data;
            state.detail.run_status = status?.run_status
                ? status.run_status
                : state.detail.run_status;

            state.detail.semantic_run_status = status?.semantic_run_status
                ? status.semantic_run_status
                : state.detail.semantic_run_status;
            state.isLoading = false;
        },
        updateJobStatus(state, action) {
            const { status, data } = action.payload;
            if (data.job_type === "asset") {
                state.detail.run_status = status ? status : state.detail.run_status;
            } else {
                state.detail.semantic_run_status = status ? status : state.detail.run_status;
            }
        },
        getVersionHistoryRequest(state, action) {
            state.versionLoading = true;
            if (action.payload.offset === 0) {
                state.versionHistory = [];
                state.historyLoadMore = true;
            }
        },
        getVersionHistorySuccess(state, action) {
            state.versionLoading = false;
            state.versionHistory = [...state.versionHistory, ...action.payload.data];
            state.filter.offset = action.payload.offset;
            if (action.payload.data.length < state.filter.limit) {
                state.historyLoadMore = false;
            }
        },
        getVersionHistoryFailure(state) {
            state.versionLoading = false;
        },
        updateVersionDetail(state, action) {
            const versionInfo = action.payload || {};
            if (versionInfo.latest_version && versionInfo.latest_version_id) {
                state.detail = {
                    ...state.detail,
                    version: versionInfo.latest_version,
                    id: versionInfo.latest_version_id
                };
            }
            if (versionInfo?.monitoring) {
                state.detail = {
                    ...state.detail,
                    ...versionInfo.monitoring
                };
            }
        },
        updatePrimaryColumns(state, action) {
            const primary_columns = state.detail?.primary_columns ?? [];
            const metadata = action.payload.data ?? {};
            const attribute_id = metadata.attribute ?? "";
            const is_primary_key = metadata.is_primary_key ?? false;
            const column_index = primary_columns.findIndex((column) => column.id === attribute_id);
            if (column_index > -1) {
                primary_columns.splice(column_index, 1);
            }

            if (attribute_id && is_primary_key) {
                primary_columns.push({
                    id: attribute_id,
                    name: metadata.name ?? "",
                    derived_type: metadata.derived_type ?? "Text"
                });
            }
            state.detail = {
                ...state.detail,
                primary_columns
            };
        },
        getMeasureLogsRequest(state) {
            state.isLoading = true;
        },
        getMeasureLogsSuccess(state, action) {
            state.isLoading = false;
            const { data, offset } = action.payload;
            const logs = data.map((obj) => {
                const auditText = getVersionText(obj, false);
                let value = obj?.value && typeof (obj.value) === "string" ? JSON.parse(obj.value) : obj.value;
                value = value ? value : {};
                const replaceBy = auditText.replace("by", "");
                return {
                    ...obj,
                    value,
                    user_name: obj?.created_by?.name ?? "",
                    date: moment(obj.created_date),
                    created_date: moment(obj.created_date).fromNow(),
                    audit_text: replaceBy.replace(/\s+/g, ' ')
                };
            });
            state.measureLogs = logs?.length > 0 && offset > 0 ? [...state.measureLogs, ...logs] : [...logs];
        },
        getMeasureLogsFailure(state) {
            state.isLoading = false;
        },
        sortMeasureLogs(state, action) {
            const { sortBy, orderBy } = action.payload;
            state.measureLogFilter = { ...state.measureLogFilter, sortBy, orderBy };
        },
        clearMeasureLogs(state) {
            state.isLoading = false;
            state.measureLogs = [];
            state.measureLogFilter = {
                limit: 20,
                offset: 0,
                sortBy: 'date',
                orderBy: 'desc'
            };
        },
        updateAssetSemanticEnabled(state, action) {
            const { is_semantic_enabled } = action.payload.data;
            state.detail.is_semantic_enabled = is_semantic_enabled;
        },
        updateDQScore(state, action) {
            state.detail = {
                ...state.detail,
                dqscore: action.payload?.data ?? 0
            };
        }
    }
});

export const {
    getVersionRequest, getVersionSuccess, getVersionFailure, updateVersionState, updateVersionRequest, updateVersionSuccess, clearVersionState, updateVersionFailure, updateFavoriteRequest, updateFavoriteSuccess,
    updateFavoriteFailure, getVersionAlertsRequest, getVersionAlertsSuccess, getVersionAlertsFailure, shareRequest, shareSuccess, shareFailure,
    updateShareDetails, clearShareDetails, updateStarRating, termApprovalRequest, termApprovalSuccess, termApprovalFailure, deleteApprovedTermRequest, deleteApprovedTermSuccess,
    deleteApprovedTermFailure, getAssetStatusRequest, getAssetStatusFailure, getAssetStatusSuccess, updateJobStatus, updateVersionBasicInfo,
    getVersionHistoryRequest, getVersionHistorySuccess, getVersionHistoryFailure, updateVersionDetail, updatePrimaryColumns,
    getMeasureLogsRequest, getMeasureLogsSuccess, getMeasureLogsFailure, clearMeasureLogs, sortMeasureLogs, updateAssetSemanticEnabled, updateDQScore
} = versionSlice.actions;
export default versionSlice.reducer;