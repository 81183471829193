const style = (theme) => ({
    QueryContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '0px 30px 20px',
        height: 'calc(100vh - 0px)',
        overflowY: 'auto',
        '& .MuiBox-root': {
            padding: '0px'
        },
        '& .header': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            paddingTop: 30,
            zIndex: 99,
            '& .CloseIcon': {
                width: '22px',
                height: '22px',
                '&:hover': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .codeContainer': {
            overflowY: 'auto',
            background: theme.palette.greyshades.lightestgrey,
            padding: '15px',
            '& .codeActions': {
                position: 'sticky',
                top: '0px',
                textAlign: 'right'
            }
        }
    }
});
export default style;