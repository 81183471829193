import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import { Grid, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';

//  Import Styles
import statisticsStyle from '../style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import appConstants from '../../../../../constants/appConstants.js';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import StatisticsChart from '../../../../../components/profileView/components/statisticsChart.jsx';
import StatisticsPreviewChart from '../../../../../components/profileView/components/statisticsPreviewChart.jsx';
import { SwitchComponent } from '../../../../../components/index.js';
import { formatDecimalValue, isNumeric } from '../../../../../helpers/appHelpers.js';
import { getAttributeStatisticsPreviewRequest } from '../../../../../redux/reducer/attributeReducer';


function Statistics(props) {

    /**
     * Define Props
     */
    const { classes, onToggleRules } = props;
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);
    const measures = useSelector((state) => state.measure.measures);
    const inputData = metadata?.statistics || {};
    const mean = parseFloat(inputData?.mean || 0);
    const standardDeviation = parseFloat(inputData?.standard_deviation || 0);
    const hasChart = ((mean && standardDeviation) && inputData?.length > 0);
    const dispatch = useDispatch();
    const statisticsPreviewData = metadata?.statisticsPreviewData || {};

    /**
     * Define state varriables
     */
    const [isPreviewChartVisible, setPreviewChartVisible] = useState(false);
    const previewChartVisible = (params) => {
        const requestParams = {
            asset_id: metadata.asset_id,
            version_id: metadata.version_id,
            data: {
                connector: 'and',
                not: false,
                rules: [
                    {
                        attribute: {
                            id: metadata.id,
                            label: metadata.technical_name,
                            has_numeric_values: metadata.has_numeric_values,
                            datatype: metadata.derived_type
                        },
                        operator: {
                            id: "isBetween",
                            label: "is between"
                        },
                        values: params.range
                    }
                ]
            }
        };
        dispatch(getAttributeStatisticsPreviewRequest(requestParams));
        setPreviewChartVisible(true);
    };

    useEffect(() => {
        // Dispatch action to set statisticsPreviewData to {}
        dispatch(getAttributeStatisticsPreviewRequest({}));
    }, [dispatch]);

    /**
     * Get values for the given key
     * @param {*} key
     * @returns
     */
    const getValue = (key) => {
        let value = inputData[key];
        value = value || 0;
        if (key === "range" && isNumeric(metadata?.derived_type)) {
            let minValue = metadata?.min_value ? parseFloat(metadata?.min_value) : 0;
            minValue = minValue ? minValue : 0;
            let maxValue = metadata?.max_value ? parseFloat(metadata?.max_value) : 0;
            maxValue = maxValue ? maxValue : 0;
            const range = (maxValue - minValue);
            value = range ? formatDecimalValue(range, 2) : 0;
        }
        return value;
    };

    /**
     * Check the measure is active or not.
     * @param {*} item The measure
     * @returns true if active, false otherwise
     */
    const isActive = (item) => {
        let isActive = false;
        if (!item.key?.length) {
            return isActive;
        }

        let measureName = item.key;
        switch (item.key) {
            case "q0":
                measureName = "min_value";
                break;
            case "q2":
                measureName = "mean";
                break;
            case "q4":
                measureName = "max_value";
                break;
            default:
                break;
        }
        const selectedMeasure = measures.find((measure) => measure.technical_name === measureName);
        isActive = (selectedMeasure?.is_active);
        return isActive;
    };

    const renderStatisticalMeasures = () => {
        const statisticalMeasures = JSON.parse(JSON.stringify(appConstants.profile.statistics));
        statisticalMeasures.splice(appConstants.profile.statistics.length - 1, 1);
        if (!hasChart) {
            statisticalMeasures.splice(appConstants.profile.statistics.length - 5, 5);
        }
        return statisticalMeasures.map((item) => (
            <TableRow key={`statistics-${item.key}`} className="measure">
                <TableCell className={classNames(classes.measureValue, "left")}>
                    <Typography variant="body1" className={`value ${isActive(item) ? 'is_active' : ''}`}>
                            {getValue(item.key) || "0"}
                    </Typography>
                </TableCell>
                <TableCell className={classNames(classes.measureDescription, "left")}>
                    <Grid item className={classes.measureName}>
                        <Typography variant="h6" className="title">
                            {`${item.name}: `}
                            <span className="description">
                                {item.description}
                            </span>
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        ));
    };


    const renderQuantileMeasures = () => {
        const quantile = appConstants.profile.statistics.find((item) => item.name === "QUARTILE");
        let statMeasures = [];
        if (!hasChart) {
            const statisticalMeasures = JSON.parse(JSON.stringify(appConstants.profile.statistics));
            statisticalMeasures.splice(statisticalMeasures.length - 1, 1);
            statMeasures = statisticalMeasures.slice(-4);
        }
        const measures = [...statMeasures, quantile, ...quantile.subKeys];
        return measures.map((item) => (
            <TableRow key={`statistics-${item.key}`} className="measure">
                {
                    (item.key?.length > 0) &&
                    <TableCell className={classes.measureValue}>
                        <Typography variant="body1" className={`value ${isActive(item) ? 'is_active' : ''}`}>
                                {getValue(item.key) || "0"}
                        </Typography>
                    </TableCell>
                }
                <TableCell className={classes.measureDescription} colSpan={(item.key?.length > 0) ? 0 : 2}>
                    <Grid item className={classes.measureName}>
                        <Typography variant="h6" className="title">
                            {`${item.name}: `}
                            <span className="description">
                                {item.description}
                            </span>
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        ));
    };


    return (
        <Grid container direction={"row"} columnSpacing={6} className={classes.statisticalContainer}>
            <Grid item xs={12} className={classes.titleContainer}>
                <Typography variant="h6" className="pb5">
                    {appConstants.profile.statisticsTitle}
                </Typography>
                <SwitchComponent size="small" checked={Boolean(metadata?.attribute_measures?.statistics > 0)} handleChange={(value) => onToggleRules("statistics", value)} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.description}>
                    {appConstants.profile.statisticsDescription}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction={"column"} justifyContent={'flext-start'} alignItems={"flex-start"}>
                    <Table>
                        <TableBody>
                            { renderStatisticalMeasures() }
                        </TableBody>
                    </Table>
                </Grid>
                <Grid container direction={"column"} justifyContent={'flext-start'} alignItems={"flex-start"}>
                    <StatisticsChart statistics={inputData} onChartClick={previewChartVisible} />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction={"column"} justifyContent={'flext-start'} alignItems={"flex-start"}>
                    <Table>
                        <TableBody>
                            { renderQuantileMeasures() }
                        </TableBody>
                    </Table>
                </Grid>
                {
                    isPreviewChartVisible && (mean !== 0 && standardDeviation !== 0) && (
                        <Grid container direction={"column"} justifyContent={'flext-start'} alignItems={"flex-start"}>
                            <StatisticsPreviewChart previewData={statisticsPreviewData || {}} name={metadata.technical_name} />
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
}

// default props
Statistics.defaultProps = {
    classes: {},
    onToggleRules: () => {}
};

// prop types
Statistics.propTypes = {
    classes: PropTypes.object,
    onToggleRules: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.inputData, nextProps.inputData) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...statisticsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Statistics);

export const StatisticsMemo = withStyles(
    (theme) => ({
        ...statisticsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Statistics, areEqual));