import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import DragBox from '../DragBox/index.jsx';

//  Import Styles
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import constant
import appConstants from '../../../../../constants/appConstants.js';
import { orderList } from '../../../../../helpers/appHelpers.js';

function CustomWidgetProperties(props) {

    const { widgetType, properties } = props;

    /**
     * Filter Dimensions
     * @param {*} type
     * @param {*} dimensions
     * @param {*} columns
     * @returns
     */
    const getDimensions = (type, dimensions, columns) => {
        let dimensionList = [...dimensions].filter((dimension) => !dimension.exclude_charts.includes(type));
        if (type === "hierachy" || type === "sun_burst") {
            const columnList = columns.filter((data) => data?.name).map((data) => data.name);
            dimensionList = dimensionList.filter((dimension) => !columnList.includes(dimension.name));
        }
        return dimensionList;
    };

    /**
     * Filter Measures
     * @param {*} type
     * @param {*} measures
     * @returns
     */
    const getMeasures = (type, measures) => {
        return measures.filter((measure) => !measure.exclude_charts.includes(type));
    };

    const dimensions = useMemo(() => getDimensions(widgetType, appConstants.dimensions, properties?.levels ?? []), [widgetType, appConstants.dimensions, properties?.levels ?? []]);
    const measures = useMemo(() => getMeasures(widgetType, appConstants.measures), [widgetType, appConstants.measures]);

    return (
        <Grid className={"assetList"}>
            <Typography variant="h6" className="pb5">
                Dimension
            </Typography>
            {
                orderList(dimensions, 'name', 'asc').map((dimension, index) =>
                    <DragBox key={index} name={dimension.name} type={"dimension"} />
                )
            }

            {
                measures.length > 0 &&
                <Fragment>
                    <Typography variant="h6" className="pb5">
                        Measures
                    </Typography>
                    {
                        orderList(measures, 'name', 'asc').map((measure, index) =>
                            <DragBox key={index} name={measure.name} type={"measure"} />
                        )
                    }
                </Fragment>
            }

        </Grid>
    );
}

// default props
CustomWidgetProperties.defaultProps = {
    classes: {},
    properties: {}
};

// prop types
CustomWidgetProperties.propTypes = {
    widgetType: PropTypes.string,
    properties: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CustomWidgetProperties);