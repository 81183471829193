import React, { useState, useCallback, useEffect } from 'react';
import {
    Grid,
    Typography,
    ButtonGroup,
    Button,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { ValidatorForm } from 'react-material-ui-form-validator';

// import Components
import appConstants from '../../constants/appConstants.js';
import { DateTimePickerComponent } from '../datePickers';
import style from './style.jsx';
import NumberInputComponent from '../textBox/numberInput.jsx';
import { AutoCompleteComponent, SelectComponent, PopOverComponent, DialogComponent, ChipComponent } from '../index.js';
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import { convertUTCtoLocalDateTime } from '../../helpers';
import { DeleteIcon } from '../../assets/svg/index.js';
import { getScheduleRequest, createScheduleRequest, updateScheduleRequest, deleteScheduleRequest, clearSchedule, getTargetAssetRequest, getTargetMeasureRequest } from '../../redux/reducer/scheduleReducer';
import { updateAsset } from '../../redux/reducer/connectorReducer.js';
import { updateAssetPropertyRequest } from '../../redux/reducer/assetReducer.js';
import { connectionListRequest } from '../../redux/reducer/connectionReducer.js';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


function Scheduler(props) {

    /**
     * Define Props
     */
    const { classes, scheduleProps, confirmDelete, onClose, onDelete, generalSchedule, onScheduledData, savedProps, disabled, isEventSchedule, semantic_next_run, category } = props;
    const dispatch = useDispatch();

    /**
     * Set Default Variables
     */
    const timeZones = [
        { name: moment.tz.guess() },
        ...appConstants.schedule.TimeZones
    ];
    const [days] = useState([...Array.from({ length: 31 }, (i, index) => (index + 1).toString())]);
    let scheduleTypes = [...appConstants.schedule.scheduleTypes];
    let defaultSchedule = scheduleTypes[2];
    let defaultParams = {
        type: defaultSchedule.name,
        duration: '1',
        start_date: moment().format('MM-DD-YYYY HH:mm'),
        configuration: {
            time: ''
        },
        multiTime: [],
        timezone: timeZones && timeZones.length > 0 ? timeZones[0].name : '',
        target_asset: '',
        schedule_method: 'time',
        target_type: 'Asset'
    };
    if (generalSchedule) {
        scheduleTypes = JSON.parse(JSON.stringify(scheduleTypes));
        // scheduleTypes.splice(0, 3);
        defaultSchedule = scheduleTypes[1];
        defaultParams = {
            type: defaultSchedule.name,
            duration: '1',
            start_date: moment().format('MM-DD-YYYY HH:mm'),
            configuration: {
                dates: ['1'],
                time: ''
            },
            multiTime: [],
            timezone: timeZones && timeZones.length > 0 ? timeZones[0].name : ''
        };
    }
    const defaultIncrementalConfig = {
        depth: {
            type: 'Days',
            value: ''
        },
        isNew: true
    };

    // state variables
    const [schedule, setSchedule] = useState({ ...defaultParams });
    const [openDeleteAlert, showDeleteAlert] = useState(false);
    const [submission] = useState(true);
    const [isIncremental, setIsIncremental] = useState(false);
    const [isIncrementalConfigLoaded, setIsIncrementalConfigLoaded] = useState(false);
    const [incrementalConfig, setIncrementalConfig] = useState({ ...defaultIncrementalConfig });
    const [eventSelected, setEventSelected] = useState(false);
    const [isIncrementalChanged, setIsIncrementalConfigChanged] = useState(false);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    // selector variables
    const scheduleConfig = useSelector((state) => state.schedule.scheduleConfig, shallowEqual);
    const allAssets = useSelector((state) => state.connector.asset.connectorsList[scheduleProps.connectionId], shallowEqual);
    const { list: connection_list } = useSelector((state) => state.connection, shallowEqual);
    const { target_asset_list, target_measure_list, select_asset_id, select_measure_id } = useSelector((state) => state.schedule, shallowEqual);

    /**
     * On Close Scheduler handler
     */
    const onCloseScheduler = useCallback(() => {
        dispatch(clearSchedule());
        onClose();
    }, []);

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (connection_list.length <= 0) {
            dispatch(connectionListRequest());
        }
        if (target_asset_list.length <= 0) {
            dispatch(getTargetAssetRequest());
        }
        if (target_asset_list.length <= 0) {
            dispatch(getTargetMeasureRequest());
        }
    }, []);

    /**
     * UseEffect to get the schedule for the given connection or asset
     */
    useEffect(() => {
        if (!generalSchedule) {
            if (scheduleProps && scheduleProps.isAsset && !scheduleProps.asset?.is_connected) {
                if (scheduleProps.asset && scheduleProps.asset?.schedule && Object.keys(scheduleProps.asset?.schedule).length > 0) {
                    setSchedule({ ...scheduleProps.asset?.schedule });
                    return;
                }
                setSchedule({ ...defaultParams });
                return;
            }
            const params = {
                "connection_id": scheduleProps.connectionId,
                "asset_id": scheduleProps?.asset?.id ?? null,
                "measure_id": scheduleProps?.measureId ?? null,
                "report_id": scheduleProps?.reportId ?? null,
                "level": scheduleProps?.level ?? null
            };
            dispatch(getScheduleRequest(params));
        }
    }, [scheduleProps.isAsset, scheduleProps.asset]);

    /**
     * UseEffect to get update General Semantics Schedule
     */
    useEffect(() => {
        if (generalSchedule && _.isEmpty(savedProps) === false) {
            setSchedule({ ...savedProps });
        } else if (generalSchedule) {
            setSchedule({ ...defaultParams });
        }
    }, [savedProps]);


    const updateSchedulefromReducer = () => {
        if (!generalSchedule) {
            let config = scheduleConfig ? scheduleConfig : defaultParams;
            if (scheduleProps?.isAsset && scheduleProps.asset && !scheduleProps.asset?.is_connected) {
                const asset = allAssets?.find((asset) => asset.id === scheduleProps.asset?.id);
                if (asset) {
                    config = asset.schedule ? asset.schedule : config;
                }
                setIsIncremental(Boolean(scheduleProps.asset?.is_incremental));
                const incrementalConfig = scheduleProps.asset?.incremental_config && Object.keys(scheduleProps.asset?.incremental_config).length ? scheduleProps.asset?.incremental_config : defaultIncrementalConfig;
                setIncrementalConfig({ ...incrementalConfig });
            }
            if (config.schedule_method === "event") {
                setEventSelected(config?.schedule_method === "event");
            }
            setSchedule(JSON.parse(JSON.stringify(config)));
        }
    };

    /**
     * Updated schedule from reducer
     */
    useEffect(() => {
        updateSchedulefromReducer();
    }, [scheduleConfig]);

    /**
     * Set initial state to false
     */
    useEffect(() => {
        setEventSelected(false);
    }, []);


    useEffect(() => {
        if (!generalSchedule) {
            if (isIncrementalConfigLoaded) {
                return;
            }
            let asset = allAssets?.find((asset) => asset.id === scheduleProps.asset?.id);
            asset = asset ? { ...asset } : { ...scheduleProps.asset };
            setIsIncremental(Boolean(scheduleProps.asset?.is_incremental));
            let incrementalConfig = scheduleProps.asset?.incremental_config && Object.keys(scheduleProps.asset?.incremental_config).length ? scheduleProps.asset?.incremental_config : defaultIncrementalConfig;
            incrementalConfig = asset.incremental_config && Object.keys(asset.incremental_config).length ? asset.incremental_config : incrementalConfig;
            incrementalConfig = typeof (incrementalConfig) === "string" ? JSON.parse(incrementalConfig) : incrementalConfig;
            setIncrementalConfig({ ...incrementalConfig });
            setIsIncrementalConfigLoaded(true);
        }
    }, [allAssets, isIncrementalConfigLoaded]);

    /**
     * Get configurations for each schedule type
     * @param {*} scheduleType the schedule type
     * @returns schedule configuration
     */
    const getConfiguration = (scheduleType) => {
        let configuration = {};
        switch (scheduleType) {
            case appConstants.schedule.scheduleTypes[2].name:
                configuration = {
                    time: ''
                };
                break;
            case appConstants.schedule.scheduleTypes[3].name:
                configuration = {
                    weekdays: [0],
                    time: ''
                };
                break;
            case appConstants.schedule.scheduleTypes[4].name:
                configuration = {
                    dates: ['1'],
                    time: ''
                };
                break;
            default:
                configuration = {};
                break;
        }
        return configuration;
    };

    /**
     * Changes the schedule properties
     */
    const changeSchedule = useCallback((property, value) => {
        let skipPropertyUpdate = false;
        if (property === 'type') {
            schedule.configuration = getConfiguration(value);
        }
        else if (property === 'weekdays') {
            skipPropertyUpdate = true;
            const weekdays = schedule.configuration?.weekdays?.length > 0 ? JSON.parse(JSON.stringify(schedule.configuration?.weekdays)) : [];
            const weekdayIndex = weekdays.indexOf(value);
            if (weekdayIndex > -1) {
                weekdays.splice(weekdayIndex, 1);
            } else {
                weekdays.push(value);
            }
            schedule.configuration = { ...schedule.configuration, weekdays };
        }
        else if (property === 'time') {
            schedule.configuration.time = value;
            skipPropertyUpdate = true;
        } else if (property === 'dates') {
            skipPropertyUpdate = true;
            const dates = schedule.configuration?.dates?.length > 0 ? JSON.parse(JSON.stringify(schedule.configuration?.dates)) : [];
            const dateIndex = dates.indexOf(value);
            if (dateIndex > -1) {
                dates.splice(dateIndex, 1);
            } else {
                dates.push(value);
            }
            schedule.configuration = { ...schedule.configuration, dates };
        }
        if (!skipPropertyUpdate) {
            schedule[property] = value;
        }
        setSchedule(JSON.parse(JSON.stringify(schedule)));
    }, [schedule, select_asset_id]);

    /**
     * Changes the schedule properties
     */
    const onChangeIncrementalConfig = useCallback((property, value) => {
        const config = { ...incrementalConfig };
        config[property] = value;
        if (config?.depth?.value && scheduleProps?.asset?.schedule?.id && config?.isNew) {
            delete config.isNew;
        }
        setIncrementalConfig(config);
        setIsIncrementalConfigChanged(true);
        const asset = { ...scheduleProps.asset, "connection_id": scheduleProps.connectionId, incremental_config: { ...config } };
        dispatch(updateAsset(asset));
    }, [incrementalConfig]);

    /**
     * Handle Depth Confirm Schedule Event
     */
    const onConfirmSchedule = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.depthUpdate,
            message: appConstants.dialogBox.depthUpdateMessage,
            data
        });
    };

    /**
     * Handles schedule submission
     */
    const onSchedule = useCallback(() => {
        if (generalSchedule) {
            const generalSchedule = {
                "type": schedule.type,
                "duration": schedule.duration,
                "start_date": schedule.start_date,
                "configuration": schedule.configuration,
                "timezone": schedule.timezone,
                "multiTime": schedule.multiTime,
                "schedule_method": "time"
            };
            onScheduledData('schedule', generalSchedule);
            return;
        }
        let assetId = null;
        const depth = (
            incrementalConfig
            && incrementalConfig.depth
            && incrementalConfig.depth.value
            && incrementalConfig.depth.value.length > 0
        ) ? incrementalConfig.depth : {};
        const incremental_config = { ...incrementalConfig, depth };

        if (scheduleProps.isAsset && scheduleProps?.asset?.is_connected) {
            assetId = scheduleProps.asset?.id ?? null;
        }
        const assetSchedule = {
            "id": schedule.id,
            "type": schedule.type,
            "duration": eventSelected ? "1" : schedule.duration,
            "start_date": schedule.start_date,
            "configuration": eventSelected ? { time: '' } : schedule.configuration,
            "timezone": schedule.timezone,
            "connection": scheduleProps.connectionId,
            "asset": assetId,
            "multiTime": eventSelected ? [] : schedule.multiTime,
            "target_type": eventSelected ? schedule.target_type : "",
            "target_measure": eventSelected ? schedule.target_measure?.id : "",
            "schedule_method": eventSelected ? "event" : "time",
            "measure": scheduleProps?.measureId ?? null,
            "report": scheduleProps?.reportId ?? null,
            "category": category ?? ''
        };
        if (schedule.target_type === "Measure") {
            assetSchedule.target_measure = eventSelected ? schedule.target_measure.id : "";
        } else {
            assetSchedule.target_asset = eventSelected ? schedule.target_asset.id : "";
        }
        if (scheduleProps.isMeasure) {
            assetSchedule.level = "measure";
            assetSchedule.schedule_type = "measure";
        }
        if (scheduleProps.isReport) {
            assetSchedule.level = "report";
            assetSchedule.schedule_type = "report";
        }
        if (category === 'connection') {
            assetSchedule.schedule_type = "connection";
        }
        if (category === 'asset') {
            assetSchedule.schedule_type = "asset";
        }
        if (category === 'sync') {
            assetSchedule.level = "sync";
            assetSchedule.schedule_type = "sync";
        }
        if (scheduleProps.isAsset && !assetId) {
            if (eventSelected) {
                assetSchedule.target_connection = schedule.target_connection;
                assetSchedule.target_asset = schedule.target_asset;
                assetSchedule.target_type = schedule.target_type;
            }
            const asset = {
                ...scheduleProps.asset,
                "connection_id": scheduleProps.connectionId,
                schedule: { ...assetSchedule },
                incremental_config: { ...incremental_config }
            };
            dispatch(updateAsset(asset));
            onCloseScheduler();
            return;
        }
        if (incremental_config?.isNew) {
            delete incremental_config.isNew;
        }
        if (assetId && isIncremental) {
            const assetConfig = {
                id: assetId,
                incremental_config: { ...incremental_config }
            };
            dispatch(updateAssetPropertyRequest(assetConfig));
            const asset = {
                ...scheduleProps.asset,
                "connection_id": scheduleProps.connectionId,
                incremental_config: { ...incremental_config }
            };
            dispatch(updateAsset(asset));
        }

        if (schedule.id) {
            dispatch(updateScheduleRequest(assetSchedule));
        } else {
            dispatch(createScheduleRequest(assetSchedule));
        }
        onCloseScheduler();
        dialogCancelEventHandle();
    }, [schedule, incrementalConfig, eventSelected]);

    /**
     * Delete schedule
     */
    const onDeleteSchedule = useCallback(() => {
        if (generalSchedule) {
            onDelete();
            onScheduledData('schedule', {});
            return;
        }
        if (!schedule.id) {
            return;
        }

        if (confirmDelete) {
            showDeleteAlert(true);
            return;
        }

        dispatch(deleteScheduleRequest(schedule));
        if (onDelete) {
            onDelete();
        }
    }, [schedule]);

    /**
     * Delete schedule after confirmation
     * @param {*} data
     */
    const deleteConfirmEventHandle = () => {
        dispatch(deleteScheduleRequest(schedule));
        if (onDelete) {
            onDelete();
        }
        showDeleteAlert(false);
        setEventSelected(false);
    };

    /**
     * Cancels the delete operation
     * @param {*} data
     */
    const deleteCancelEventHandle = () => {
        showDeleteAlert(false);
    };

    const onHandleChipAdd = (time) => {
        const tempsetSchedule = [...schedule.multiTime];
        if (!tempsetSchedule.includes(time)) {
            tempsetSchedule.push(time);
            setSchedule({
                ...schedule,
                multiTime: tempsetSchedule
            });
        }
    };

    const onHandleRemove = (time) => {
        setSchedule({
            ...schedule,
            multiTime: time
        });
    };

    const onCancelSchedule = () => {
        onCloseScheduler();
        updateSchedulefromReducer();

    };

    const filteredTargetAsset = target_asset_list.filter((asset) => asset.id !== select_asset_id).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });
    const filteredTargetMeasure = target_measure_list.filter((measure) => measure.id !== select_measure_id).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });

    return (
        <PopOverComponent
            open={scheduleProps.open}
            anchorEl={scheduleProps.anchorElement}
            onClose={() => onCloseScheduler()}
        >
            <ValidatorForm
                autoComplete="off"
                className={classNames(classes.scheduleContainer)}
                onSubmit={() => (isIncrementalChanged && !incrementalConfig?.isNew ? onConfirmSchedule() : onSchedule())}
                noValidate
            >
                <Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems="center"
                        wrap="nowrap"
                        className="pr-2 pt-2 pl-2"
                    >
                        {/* {
                                    (schedule.id || (generalSchedule && !_.isEmpty(savedProps))) && ( */}
                        {
                            <Grid item className="mb-1 mt-1">
                                <Typography variant="h4">
                                    {'Schedule'}
                                </Typography>
                            </Grid>
                        }

                        {
                            (schedule.id || (generalSchedule && !_.isEmpty(savedProps))) && (!disabled) && (
                                <Grid item>
                                    <IconButton onClick={onDeleteSchedule} >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className={'mt-2 pr-2 pl-2'}
                    >
                        <Grid item xs={3} className="dflex alignCenter">
                            <Typography variant="body1" className={'fw-500'}>
                                Start Date
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.inputStyling}>
                            <DateTimePickerComponent
                                autoOk
                                variant="standard"
                                color="secondary"
                                value={schedule.start_date ?? ''}
                                minDate={new Date()}
                                onChange={
                                    (date) =>
                                        changeSchedule('start_date', date)
                                }
                                validators={['required']}
                                errorMessages={[setRequiredErrorMessage('Start Date')]}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        className={`${classes.repeatSection} mt-2 pr-2 pl-2`}
                        sx={{ marginTop: '20px !important' }}
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="body1" className={'fw-500'}>
                                Repeat Every
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className="">
                            <Grid className="dflex alignCenter">
                                <Grid item className="numberSection mr-2">
                                    <NumberInputComponent
                                        name="duration"
                                        noOutline
                                        value={schedule?.duration || ''}
                                        onChange={
                                            (value) =>
                                                changeSchedule('duration', value)
                                        }
                                        disabled={disabled || eventSelected}
                                        retrictZero
                                    />
                                </Grid>
                                <Grid item className="selectDays">
                                    <SelectComponent
                                        name="type"
                                        noOutline
                                        list={scheduleTypes}
                                        propertyName={'name'}
                                        value={schedule?.type || ''}
                                        onSelectChange={
                                            (value) =>
                                                changeSchedule('type', value)
                                        }
                                        isDisabled={disabled || eventSelected}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    schedule.type !==
                                    appConstants.schedule.scheduleTypes[0]
                                        .name &&
                                    schedule.type !==
                                    appConstants.schedule.scheduleTypes[1]
                                        .name &&
                                    schedule.type !==
                                    appConstants.schedule.scheduleTypes[2]
                                        .name && (
                                        <Grid
                                            className={`${classes.schedulerSection} mt-3`}
                                        >
                                            <Grid
                                                className={classes.inputStyling}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    alignItems="baseline"
                                                    justify="center"
                                                    wrap="nowrap"
                                                >
                                                    {
                                                        appConstants.schedule
                                                            .scheduleTypes[3]
                                                            .name ===
                                                        schedule.type && (
                                                            <Grid
                                                                item
                                                                className={classes.sheduleDays}
                                                            >
                                                                <ButtonGroup className="mb5">
                                                                    {
                                                                        appConstants.schedule.weekDays.map(
                                                                            (
                                                                                weekday,
                                                                                index
                                                                            ) => (
                                                                                <Button
                                                                                    disableElevation
                                                                                    onClick={
                                                                                        () =>
                                                                                            changeSchedule(
                                                                                                'weekdays',
                                                                                                weekday.indexValue
                                                                                            )
                                                                                    }
                                                                                    variant="contained"
                                                                                    key={`weekday_${index}`}
                                                                                    className={
                                                                                        classNames(
                                                                                            classes.dayBtn,
                                                                                            schedule.configuration?.weekdays?.indexOf(
                                                                                                weekday.indexValue
                                                                                            ) !==
                                                                                                -1
                                                                                                ? 'active'
                                                                                                : ''
                                                                                        )
                                                                                    }
                                                                                    disabled={eventSelected}
                                                                                >
                                                                                    {
                                                                                        weekday
                                                                                            .name[0]
                                                                                    }
                                                                                </Button>
                                                                            )
                                                                        )
                                                                    }
                                                                </ButtonGroup>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        appConstants.schedule
                                                            .scheduleTypes[4]
                                                            .name ===
                                                        schedule.type && (
                                                            <Grid
                                                                item
                                                                className="mr-2 dflex alignCenter"
                                                            >
                                                                <Grid
                                                                    className={classes.calenderContainer}
                                                                >
                                                                    {
                                                                        days.map(
                                                                            (
                                                                                day,
                                                                                index
                                                                            ) => (
                                                                                <Typography
                                                                                    onClick={
                                                                                        () =>
                                                                                            changeSchedule(
                                                                                                'dates',
                                                                                                day
                                                                                            )
                                                                                    }
                                                                                    key={`${day}-${index}`}
                                                                                    component="div"
                                                                                    className={
                                                                                        `${classes.dayContainer
                                                                                        } 
                                                            ${schedule?.configuration?.dates?.indexOf(
                                                                                            day.toString()
                                                                                        ) !== -1
                                                                                            ? classes.daySelected
                                                                                            : ''
                                                                                        }`
                                                                                    }
                                                                                >
                                                                                    {day}
                                                                                </Typography>
                                                                            )
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                    {/* <Grid item>
                                                        <TimePickerComponent
                                                            variant="standard"
                                                            label="Select a Time"
                                                            color="secondary"
                                                            value={
                                                                schedule
                                                                    ?.configuration
                                                                    ?.time ?? ''
                                                            }
                                                            onChange={
                                                                (time) =>
                                                                    changeSchedule(
                                                                        'time',
                                                                        time
                                                                    )
                                                            }
                                                            validators={['required']}
                                                            disabled={disabled}
                                                            errorMessages={
                                                                [
                                                                    setRequiredErrorMessage(
                                                                        'time'
                                                                    )
                                                                ]
                                                            }
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    {
                        !(schedule.type === 'Hours' || schedule.type === 'Minutes') &&
                        <Grid
                            container
                            spacing={3}
                            className={classes.repeatSection}
                            alignItems="baseline"
                        >
                            <Grid item xs={3} className="dflex alignCenter">
                                <Typography variant="body1" className={'fw-500 pr-2 pl-2'}>
                                    Occurance
                                </Typography>
                            </Grid>
                            <Grid item xs={9} className="pl-2 pr-2">
                                <Grid item xs={12} className="mt-1">
                                    <ChipComponent
                                        data={schedule?.multiTime ?? []}
                                        // labelKey="name"
                                        placeholder="Select Attribute"
                                        add={!(schedule?.multiTime?.length >= 5)}
                                        availableList={[]}
                                        editable={!eventSelected && !disabled}
                                        handleChipEvent={(value) => onHandleRemove(value)}
                                        addType="timepicker"
                                        haveDataType
                                        addText="Add Time"
                                        stringChip
                                        onChipAdd={(value) => onHandleChipAdd(value)}
                                        noId
                                        limit={3}
                                        maxLimit={5}
                                        disabled={eventSelected || disabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        isEventSchedule &&
                        <Grid
                            container
                            spacing={3}
                            className={`${classes.repeatSection} mt-2 pr-2 pl-2`}
                            alignItems="baseline"
                            direction="row"
                            justify="space-between"
                        >
                            <Grid item xs={3} className="dflex alignCenter" />
                            <Grid item xs={9} container>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        size="small"
                                        onChange={(event) => { setEventSelected(event.target.checked); }}
                                        control={<Checkbox defaultChecked={false} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                        label="Event Schedule"
                                        checked={eventSelected}
                                        disabled={disabled}
                                    />
                                </Grid>
                                {
                                    eventSelected &&
                                    <Grid item xs={6}>
                                        <SelectComponent
                                            name="target_type"
                                            noOutline
                                            list={[{ name: 'Asset' }, { name: 'Measure' }]}
                                            propertyName={'name'}
                                            value={schedule?.target_type || 'Asset'}
                                            onSelectChange={
                                                (value) =>
                                                    changeSchedule('target_type', value)
                                            }
                                            isDisabled={disabled}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        eventSelected &&
                        <Grid>
                            <Grid container className={'mt-1 pl-2 pr-2 mb-2'} alignItems="center">
                                <Grid item xs={3} className="dflex alignCenter">
                                    <Typography variant="body1" className={'fw-500'}>
                                        {schedule?.target_type === "Measure" ? "Measure" : "Asset"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} className={`${classes.inputStyling} timeZone`}>
                                    {
                                        schedule?.target_type === "Measure" ?
                                            <AutoCompleteComponent
                                                name="target_measure"
                                                variant="standard"
                                                selectedValue={schedule?.target_measure ?? ''}
                                                data={filteredTargetMeasure}
                                                onChange={
                                                    (event, newValue) =>
                                                        changeSchedule('target_measure', newValue)
                                                }
                                                placeholder="Select Measure"
                                                disabled={disabled}
                                                showTooltip
                                            />

                                            :
                                            <AutoCompleteComponent
                                                name="target_asset"
                                                variant="standard"
                                                selectedValue={schedule?.target_asset ?? ''}
                                                data={filteredTargetAsset}
                                                onChange={
                                                    (event, newValue) =>
                                                        changeSchedule('target_asset', newValue)
                                                }
                                                placeholder="Select Asset"
                                                disabled={disabled}
                                                showTooltip
                                            />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        isIncremental &&
                        <Grid
                            container
                            className={`${classes.repeatSection} mt-2 pr-2 pl-2`}
                            alignItems="center"
                            sx={{ marginTop: '10px !important', marginBottom: '20px !important' }}
                        >
                            <Grid item xs={3}>
                                <Typography variant="body1" className={'fw-500'}>
                                    Depth
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid className="dflex alignCenter">
                                    <Grid item className="numberSection mr-2">
                                        <NumberInputComponent
                                            name="value"
                                            noOutline
                                            value={incrementalConfig?.depth?.value || ''}
                                            onChange={
                                                (value) =>
                                                    onChangeIncrementalConfig('depth', { ...incrementalConfig.depth, value })
                                            }
                                        />
                                    </Grid>
                                    <Grid item className="selectDays">
                                        <SelectComponent
                                            name="type"
                                            noOutline
                                            list={appConstants.dataInterval || []}
                                            value={incrementalConfig?.depth?.type || ''}
                                            onSelectChange={
                                                (value) =>
                                                    onChangeIncrementalConfig('depth', { ...incrementalConfig.depth, type: value })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <Grid container className={'mt-1 pl-2 pr-2'}>
                        <Grid item xs={3} className="dflex alignCenter">
                            <Typography variant="body1" className={'fw-500'}>
                                Time Zone
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={`${classes.inputStyling} timeZone`}>
                            <AutoCompleteComponent
                                name="timezone"
                                variant="standard"
                                selectedValue={schedule?.timezone ?? ''}
                                data={timeZones}
                                onChange={
                                    (event, newValue) =>
                                        changeSchedule('timezone', newValue.label)
                                }
                                placeholder="Select Attribute"
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent={(schedule?.next_schedule_date || semantic_next_run) ? "space-between" : "flex-end"}
                        alignItems="center"
                        className={'mt-3 mb-2 pl-2 pr-2'}
                    >
                        {
                            ((schedule.id && schedule?.next_schedule_date)
                                || (generalSchedule && !_.isEmpty(savedProps) && semantic_next_run)) && (schedule.schedule_method === 'time') &&
                            <Grid item>
                                <Typography className={'fw-600'}>
                                    {`Next Run: ${convertUTCtoLocalDateTime(generalSchedule ? semantic_next_run : schedule.next_schedule_date)}`}
                                </Typography>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                disableElevation
                                size="small"
                                color="primary"
                                onClick={onCancelSchedule}
                                style={{ marginRight: '10px' }}
                                variant="outlined"
                                className={
                                    classNames(
                                        classes.cancelBtn,
                                        classes.tabBtn
                                    )
                                }
                            >
                                {"Cancel"}
                            </Button>
                            {
                                !disabled &&
                                <Button
                                    disableElevation
                                    size="small"
                                    className={classes.tabBtn}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        (!submission)
                                        || (eventSelected && ((schedule?.target_type === "Asset" && !schedule?.target_asset?.id)
                                            || (schedule?.target_type === "Measure" && !schedule?.target_measure?.id)))
                                        || (!eventSelected && schedule?.type !== "Hours" && schedule?.type !== "Minutes" && schedule?.multiTime?.length === 0)
                                    }
                                >
                                    {"Submit"}
                                </Button>
                            }
                            {
                                showDialog.open && (
                                    <DialogComponent
                                        open={showDialog.open}
                                        title={showDialog.title}
                                        message={showDialog.message}
                                        data={showDialog.data}
                                        onCancel={dialogCancelEventHandle}
                                        onConfirm={onSchedule}
                                        onOptionDialogButtonClick={onSchedule}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>


                </Grid>
            </ValidatorForm>
            {
                openDeleteAlert &&
                <DialogComponent
                    open={openDeleteAlert}
                    title={appConstants.dialogBox.delete}
                    message={appConstants.dialogBox.deleteSchedule}
                    onCancel={deleteCancelEventHandle}
                    onConfirm={deleteConfirmEventHandle}
                />
            }
        </PopOverComponent>
    );
}

Scheduler.defaultProps = {
    classes: {},
    scheduleProps: {},
    onClose: () => { },
    onDelete: () => { },
    generalSchedule: false,
    onScheduledData: () => { },
    savedProps: {},
    confirmDelete: false,
    disabled: false,
    isEventSchedule: true,
    semantic_next_run: null,
    category: ''
};

Scheduler.propTypes = {
    classes: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    generalSchedule: PropTypes.bool,
    onScheduledData: PropTypes.func,
    savedProps: PropTypes.object,
    confirmDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    isEventSchedule: PropTypes.bool,
    semantic_next_run: PropTypes.string,
    category: PropTypes.string
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.scheduleProps, nextProps.scheduleProps) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.savedProps, nextProps.savedProps) && _.isEqual(prevProps.semantic_next_run, nextProps.semantic_next_run);
}

export default withStyles(
    (theme) => ({
        ...style(theme)
    }),
    { withTheme: true }
)(React.memo(Scheduler, areEqual));