import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import DropBox from '../../../../DropBox/index.jsx';
import SelectedFields from '../../../../SelectedFields/index.jsx';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../../../../layouts/style.jsx';


function PolarConfiguration(props) {
    const { classes, data, onChange } = props;

    /**
     * Get Detail
     * @param {*} item
     * @param {*} type
     * @returns
     */
    const getDetail = (item) => {
        item.query = item.column;
        item.alias_name = item.name;
        item.type = item.datatype === "text" || item.datatype === "date" || item.isHeatmapSupport ? "dimension" : "measure";
        return item;
    };

    /**
     * OnDrop
     * @param {*} item
     */
    const onDrop = (item) => {
        item = { ...getDetail(item) };
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        levels.push({ ...item });
        properties.levels = levels;
        onChange(properties);
    };

    /**
     * On Delete Item
     * @param {*} dropType
     * @param {*} index
     */
    const onDeleteItem = (index) => {
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        levels.splice(index, 1);
        properties.levels = levels;
        onChange(properties);
    };

    /**
     * On Drop Replace Item
     * @param {*} item
     * @param {*} index
     */
    const onDropReplace = (item, index) => {
        item = { ...getDetail(item) };
        const properties = Object.assign({}, { ...data });
        const levels = Object.assign([], properties.levels || []);
        const sortingIndex = levels.findIndex((level) => level.name === item.name);
        if (sortingIndex !== -1) {
            levels[sortingIndex] = levels[index];
        }
        levels[index] = { ...item };
        properties.levels = levels;
        onChange(properties);
    };

    return (
        <Grid className={classes.nonLimitDragContainer}>
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    Columns
                </Typography>
            </Grid>
            <SelectedFields data={data?.levels ?? []}
                onDelete={(index) => onDeleteItem(index)}
                accept={["dimension", "measure", "sorting"]}
                onDrop={(item, index) => onDropReplace(item, index)}
                sorting
            />
            <DropBox accept={["dimension", "measure"]} onDrop={(item) => onDrop(item)} />
        </Grid>
    );
}

// default props
PolarConfiguration.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { }
};

// prop types
PolarConfiguration.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(PolarConfiguration);