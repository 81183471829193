const style = (theme) => ({
    dargField: {
        marginTop: '20px !important'
    },
    dragBox: {
        border: `1px dashed ${theme.palette.greyshades.darkgrey}`,
        background: `${theme.palette.greyshades.tablegrey}80`,
        borderRadius: 4,
        padding: '10px 20px',
        '&.active': {
            border: `2px dashed ${theme.palette.secondary.main}`
        }
    }
});

export default style;