import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './progressStyle.css';
import Styles from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

function IssuesStatus(props) {

    /**
     * Define Props
     */
    const { classes, status } = props;
    const steps = [
        {
            index: 0,
            label: "New"
        },
        {
            index: 1,
            label: "Inprogress"
        },
        {
            index: 2,
            label: "Resolved"
        }
    ];

    /**
     * Define State
     */
    const [activeIndex, setActiveIndex] = React.useState(0);

    /**
     * Get Linear Value
     * @returns Number
     */
    const getValue = () => {
        switch (status) {
            case 'Inprogress':
                setActiveIndex(1);
                break;
            case 'Resolved':
                setActiveIndex(2);
                break;
            default:
                setActiveIndex(0);
                break;
        }
    };

    /**
     * Define Use Effect
     */
    useEffect(() => {
        getValue();
    }, [status]);

    return (
        <Grid container className={classes.issuesStatusHeader}>
            <ul className="progress-indicator">
                {
                    steps.map((step) => (
                        <li
                            key={step.index}
                            className={
                                `progress-step
              ${activeIndex === step.index ? "active" : "incomplete"}
              ${activeIndex > step.index ? "complete" : "incomplete"}`
                            }
                        >
                            <span className="step-number">
                                <span className="step-numbertext">
                                    {step.index + 1}
                                </span>
                                <CheckCircleIcon className="doneIcon" />
                            </span>
                            <Typography variant="h5" className="lableName">
                                {step.label}
                            </Typography>
                        </li>
                    ))
                }
            </ul>
        </Grid>
    );
}

// default props
IssuesStatus.defaultProps = {
    classes: {},
    status: "New"
};

// prop types
IssuesStatus.propTypes = {
    classes: PropTypes.object,
    status: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(IssuesStatus);