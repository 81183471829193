import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import PreviewRunResultStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';
import { dFormatter } from '../../../../../helpers/appHelpers.js';


function AdfPipelineRuns(props) {

    /**
     * Define Props
     */
    const { classes, data, headers, title } = props;

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "runStart", orderBy: "desc"
    });

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = JSON.parse(JSON.stringify(data));
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        filterData.forEach((item) => {
            if (item.durationInMs) {
                item.durationInMs = dFormatter(Math.ceil(item.durationInMs / 1000));
            }
        }
        );
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], searchData, sorting.sortBy, sorting.orderBy), [data || [], searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <Grid item xs={12} className={classes.PreviewContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <TableComponent
                    title={title}
                    headers={headers}
                    stickyHeader
                    data={filterData}
                    options={appConstants.tableOptions.common}
                    searchData={searchData}
                    sortBy={sorting.sortBy}
                    orderBy={sorting.orderBy}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onClickSorting={onClickSorting}
                    styleType="striped"
                    NoResultText="No Records Found"
                    height="calc(100vh - 450px)"
                />
            </Grid>
        </Grid>
    );
}

// default props
AdfPipelineRuns.defaultProps = {
    classes: {},
    data: [],
    headers: [],
    title: ''
};

// prop types
AdfPipelineRuns.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    headers: PropTypes.array,
    title: PropTypes.string
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export As Memo Component
 */
export const AdfPipelineRunsComponent = withStyles(
    (theme) => ({
        ...PreviewRunResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(AdfPipelineRuns, areEqual));