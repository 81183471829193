const style = (theme) => ({
  failedText: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '8px',
    marginTop: '20px !important',
    marginBottom: '10px !important',
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
    },
    '& .DangerIcon': {
      flexShrink: 0,
      width: 20,
      height: 20,
      marginRight: 10
      // fill: theme.palette.primary.main
    },
    '& .CreateIssues': {
      width: '22px',
      height: '21px'
    },
    '&.high': {
      background: `${theme.palette.colorThemes.high}10`,
      border: `1px solid ${theme.palette.colorThemes.high}40`,
      '& .DangerIcon': {
        fill: theme.palette.colorThemes.high
      }
    },
    '&.medium': {
      background: `${theme.palette.colorThemes.medium}10`,
      border: `1px solid ${theme.palette.colorThemes.medium}40`,
      '& .DangerIcon': {
        fill: theme.palette.colorThemes.medium
      }
    },
    '&.low': {
      background: `${theme.palette.colorThemes.low}10`,
      border: `1px solid ${theme.palette.colorThemes.low}40`,
      '& .DangerIcon': {
        fill: theme.palette.colorThemes.low
      }
    },
    '&.ok': {
      background: `${theme.palette.colorThemes.ok}10`,
      border: `1px solid ${theme.palette.colorThemes.ok}40`,
      '& .DangerIcon': {
        fill: theme.palette.colorThemes.ok
      }
    }
  },
  countChips: {
    display: 'flex',
    '& .MuiChip-label': {
      paddingLeft: '7px',
      paddingRight: '7px'
    },
    '& .chipContainer': {
      maxWidth: 'calc(33.33% - 37px)'
    },
    '& .MuiChip-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  alertsCardContainer: {
    height: '100%',
    background: theme.palette.greyshades.lightbackground,
    border: `1px solid ${theme.palette.greyshades.lightgrey}`,
    borderRadius: 4,
    padding: '24px',
    '& .DangerIcon': {
      fill: theme.palette.primary.main,
      width: '22px',
      height: '22px'
    },
    '& .slider': {
      marginTop: '5px',
      "& .ShareIcon": {
        width: '18px',
        height: '18px'
      },
      '& .sliderItem ': {
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        boxShadow: theme.palette.boxShadow2,
        padding: '16px',
        borderRadius: 3,
        height: '100% !important',
        background: '#fff',
        '&:hover': {
          background: `${theme.palette.greyshades.lightestgrey} !important`
        }
      },
      '& .slick-slide': {
        padding: '0px',
        minHeight: '438px !important',
        '&>div': {
          margin: '5px 6px',
          height: 'calc(100% - 7px) !important',
          '&:hover': {
            background: theme.palette.greyshades.lightestgrey,
            cursor: 'pointer',
            '& .sliderItem ': {
              background: `${theme.palette.greyshades.lightestgrey} !important`
            }
          }
        }
      }
    },
    '& .slick-prev, .slick-next': {
      display: 'none !important'
    },
    '& .echarts-for-react ': {
      height: '200px !important'
    },
    '& .slick-slider': {
      height: 'calc(100% - 36px)'
    },
    '& .slick-list': {
      height: '100%'
    },
    '& .sliderGrid, .slick-track, .sliderBody': {
      height: '100%'
    }
  },
  shareDialog: {
    '& .MuiPaper-root': {
      overflow: 'hidden'
    }
  },
  shareIconBtn: {
    '&:hover .fillPath': {
      fill: `${theme.palette.secondary.main} !important`
    }
  },
  shareContainer: {
    padding: 30,
    '& .CloseIcon': {
      width: '30px',
      height: '30px'
    },
    '& .closeButton': {
      width: 32,
      height: 32,
      padding: 5
    }
  },
  shareContainerImg: {
    height: 200,
    width: '100%',
    objectFit: 'contain',
    border: `1px solid ${theme.palette.greyshades.lightgrey}`
  },
  copyDataset: {
    background: theme.palette.greyshades.lightestgrey,
    border: `1px solid ${theme.palette.greyshades.lightgrey}40`,
    borderRadius: '0px 4px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 0px 9px',
    height: 36,
    maxWidth: 'calc(100% - 100px)',
    '& .datasetIcon img': {
      width: 20,
      height: 20,
      marginRight: 8,
      marginBottom: '-1px'
    },
    '& .copyIcon': {
      background: '#fff',
      height: '34px',
      width: '30px',
      minWidth: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0px 4px 4px 0px',
      cursor: 'pointer',
      '&:hover ': {
        '& #Path_2746': {
          fill: `${theme.palette.secondary.main} !important`
        }
      }
    },
    '& .datasetIcon ': {
      display: 'flex'
    },
    '& .MuiTypography-root': {
      lineHeight: '15px'
    }
  },
  headerLeft: {
    width: 'calc(100% - 38px)',
    overflow: 'hidden'
  }

});

export default style;