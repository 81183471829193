import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

//import Componetns
import { TableComponent } from '../../../../components/index.js';

//import Styles
import HomeStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { getTagsRequest } from '../../../../redux/reducer/tagsReducer';
import { getTermsRequest } from '../../../../redux/reducer/termReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';
import LabelContainer from '../../../../components/labelContainer/index.jsx';
import { updateUserPreference } from '../../../../redux/reducer/authReducer.js';
import { getAllAttributesMetadataRequest, toggleAssetAdvancedProfileRequest, updateAllAttributeMetadata, updateAssetAttributeMetadata, updateAttributeMetadataRequest } from '../../../../redux/reducer/attributeReducer.js';
import { checkPermission, formatDecimalValue, getTextWidth, getUserPreference, orderList, prepareUpdateUserPreference } from '../../../../helpers/appHelpers.js';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';
import { triggerJobRequest } from '../../../../redux/reducer/scheduleReducer.js';
import { termApprovalRequest } from '../../../../redux/reducer/versionReducer.js';
import featureConstants from '../../../../constants/featureConstants.js';
import appConstants from '../../../../constants/appConstants.js';


function AttributesList(props) {
    const { classes, propertiesPermission } = props;
    const dispatch = useDispatch();
    const reactNavigate = useNavigate();
    const { id: assetId } = useParams();
    const searchControllerRef = useRef();
    const defaultLimit = 20;
    const decimalCount = 2;
    let timeout = null;
    const defaultSearchData = {
        "name": "",
        "description": "",
        "datatype": "",
        "status": "All",
        "term": "",
        "tags": ""
    };
    const defaultSorting = {
        sortBy: "",
        orderBy: ""
    };
    const tableOptions = [{ type: "search", customFunction: null }, { type: "columns", customFunction: null }];
    let tableHeaders = [
        { key: 'name', name: 'Attribute', sticky: true, showTypeLabel: true, typeLabelKey: 'derived_type', showAlert: true, alertKey: "alerts", showIssues: true, issueKey: "issues", colorKey: "tagColor", sorting: true, tooltip: true, width: "15%", isSearch: true, searchComponent: "text", searchKey: "name", clickable: true },
        { key: 'datatype', name: 'Datatype', sorting: true, tooltip: true, headerTooltipText: "Source Datatype", width: "10%", isSearch: true, searchComponent: "text", searchKey: "datatype" },
        { key: 'description', name: 'Description', sorting: true, inlineEdit: true, component: "textbox", datatype: "text", placeholder: "Enter description", tooltip: true, width: "20%", isSearch: true, searchComponent: "text", searchKey: "description", hideDefault: true, renderOnBlur: true, isDisabled: () => !propertiesPermission?.is_edit },
        { key: 'is_primary_key', name: 'Primary', width: "10%", component: "switch", align: "center", isDisabled: () => !propertiesPermission?.is_edit },
        { key: 'is_semantic_enabled', name: 'Semantic', width: "10%", component: "switch", headerTooltipText: "Enable semantic for an attribute", align: "center", isDisabled: () => !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'is_advanced_profiling', name: 'Advanced', headerTooltipText: "Enable advanced profile", align: "center", width: "10%", component: "switch", isDisabled: () => !propertiesPermission?.is_edit },
        { key: 'length', label: 'length', name: 'Length', headerTooltipText: "Minimum and maximum length for the attribute.", component: "range", align: "center", datatype: "integer", isDisabled: () => !propertiesPermission?.is_edit },
        { key: 'value', label: 'value', name: 'Range', headerTooltipText: "Minimum and maximum range for the attribute.", component: "range", align: "center", datatype: "numeric", getDataType: (data) => data.derived_type, isDisabled: (data) => (data?.derived_type && (!data?.has_numeric_values && (data?.derived_type?.toLowerCase() === "text" || data?.derived_type?.toLowerCase() === "bit"))) || !propertiesPermission?.is_edit },
        { key: 'non_empty_percentage', name: 'Fill', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "non_empty_count", tooltip: true, getTooltipText: (data) => `Count: ${data.non_empty_count} (${data.non_empty_percentage}%)` },
        { key: 'null_percentage', name: 'Null', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "null_count", tooltip: true, getTooltipText: (data) => `Count: ${data.null_count} (${data.null_percentage}%)`, hideDefault: true },
        { key: 'blank_percentage', name: 'Blank', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "blank_count", tooltip: true, getTooltipText: (data) => `Count: ${data.blank_count} (${data.blank_percentage}%)`, hideDefault: true },
        { key: 'space_percentage', name: 'Space', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "space_count", tooltip: true, getTooltipText: (data) => `Count: ${data.space_count} (${data.space_percentage}%)`, hideDefault: true },
        { key: 'zero_values_percentage', name: 'Zero', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "zero_values", tooltip: true, getTooltipText: (data) => `Count: ${data.zero_values} (${data.zero_values_percentage}%)`, hideDefault: true },
        { key: 'distinct_percentage', name: 'Unique', endAdornment: "%", width: "10%", align: "center", sorting: true, sortKey: "distinct_count", tooltip: true, getTooltipText: (data) => `Count: ${data.distinct_count} (${data.distinct_percentage}%)`, hideDefault: true },
        { key: 'dqscore', name: 'Score', width: "5%", sorting: true, component: "score" },
        { key: 'status', name: 'Status', width: "10%", align: "center", sorting: true, component: "status", isSearch: true, searchComponent: "select", list: ["All", "Pending", "Ready For Review", "Verified"], searchKey: "status", hideDefault: true, isDisabled: (data) => (data?.status === appConstants.status[3] || !propertiesPermission?.is_edit) },
        { key: 'alerts', name: 'Alerts', width: "10%", align: "center", sorting: true, searchKey: "alerts", clickable: true, hideDefault: true },
        { key: 'issues', name: 'Issues', width: "10%", align: "center", sorting: true, searchKey: "issues", clickable: true, hideDefault: true },
        { key: 'term', name: 'Terms', placeholder: "Select Term", width: "25%", align: "center", isSearch: true, searchComponent: "text", searchKey: "term", component: 'chips', limit: 2, valueKey: 'name', className: "termsChips", disableInput: false, isAdd: true, chipAddType: 'autocomplete', addLimitCount: 1, addText: "Add Terms", enableAcceptActions: (data) => (data?.term_id && !data?.term_approval_id && Boolean(propertiesPermission?.is_edit)), isPermissionDisabled: !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'tags', name: 'Tags', placeholder: "Select Tag", width: "25%", align: "center", isSearch: true, searchComponent: "text", searchKey: "tags", component: 'chips', valueKey: 'name', limit: 2, isAdd: true, chipAddType: 'autocomplete', haveColor: true, addText: "Add Tags", isPermissionDisabled: !propertiesPermission?.is_edit, hideDefault: true },
        { key: 'active_measures', name: 'Active', width: "5%", align: "center", sorting: true, clickable: true, hideDefault: true },
        { key: 'observed_measures', name: 'Monitoring', width: "5%", align: "center", sorting: true, clickable: true, hideDefault: true },
        { key: 'scored_measures', name: 'Scoring', width: "10%", align: "center", sorting: true, clickable: true, hideDefault: true }
    ];

    /**
     * Define State Variables
     */
    const [searchData, setSearchData] = useState({ ...defaultSearchData });
    const [sortingInput, setSortingInput] = useState({ ...defaultSorting });
    const [allAdvancedProfile, setAllAdvancedProfile] = useState(false);

    /**
     * Get data from redux store
     */
    const { detail: assetDetail } = useSelector((state) => state.version, shallowEqual);
    const { searchableTagsList } = useSelector((state) => state.tags, shallowEqual);
    const { searchableTermsList } = useSelector((state) => state.term, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const alertsPermission = checkPermission(permission, featureConstants.home.alerts);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);

    const { isLoadingAllAttributes, totalAttributes, allAttributes } = useSelector((state) => state.attribute, shallowEqual);
    const user = useSelector((state) => state.auth.user);
    let columns = getUserPreference(user?.user_preference ?? {}, "table", "allAttributes", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "allAttributes", "sorting");
    columns = columns || [];

    /**
     *
     * Get Tags
     */
    useEffect(() => {
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }

        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
    }, [dispatch]);


    /**
     * Sorting
     */
    useEffect(() => {
        if (sorting) {
            setSortingInput({ ...sorting });
        }
    }, [user?.user_preference]);

    /**
     * Updated all advanced profile
     */
    useEffect(() => {
        const totalAttributes = allAttributes?.length || 0;
        const profilingAttributes = allAttributes?.filter((item) => item.is_advanced_profiling).length || 0;
        setAllAdvancedProfile(totalAttributes === profilingAttributes);
    }, [allAttributes]);


    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value, key, property) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, key, property, value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns }, "table", "allAttributes");
    };


    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onComponentEvent = (key, value, item) => {
        if (!item?.attribute_id) {
            return;
        }

        let requestParams = {
            [key]: value
        };
        let inputParams = {
            [key]: value
        };

        if (key === "term") {
            const term = (value?.length > 0) ? value[0].id : null;
            requestParams = { term };
            inputParams = { term_id: term, term: value || [], term_approval_id: term };
        }
        else if (key === "tags") {
            const tags = [];
            if (value?.length > 0) {
                for (const tag of value) {
                    if (!tag.id) {
                        continue;
                    }
                    tags.push(tag.id);
                }
            }
            requestParams = { tags };
            inputParams = { tags: value || [] };
        }
        requestParams.is_list = true;
        requestParams.attribute_id = item.attribute_id;
        inputParams.attribute_id = item.attribute_id;
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAssetAttributeMetadata(inputParams));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(() => {
            const attributeRequestParams = {
                asset_id: assetId,
                offset: 0,
                limit: defaultLimit,
                search: search_by,
                sort: sortingInput
            };
            dispatch(getAllAttributesMetadataRequest({ params: attributeRequestParams, token }));
        }, 300);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = { sortBy, orderBy };
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(() => {
            const attributeRequestParams = {
                asset_id: assetId,
                offset: 0,
                limit: defaultLimit,
                search: searchData,
                sort: requestData
            };
            dispatch(getAllAttributesMetadataRequest({ params: attributeRequestParams, token }));
        }, 300);
        setSortingInput(requestData);
        updatePreference({ sorting: requestData }, "table", "allAttributes");
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} attribute
     */
    const handleCellClickEvent = (key, attribute) => {
        switch (key) {
            case "name":
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [attribute.asset_id, attribute.attribute_id] }));
                break;
            case "active_measures":
                dispatch(navigate({ path: 'assets.attributeProperties', state: { section: "measure", measureView: "All" }, params: [attribute.asset_id, attribute.attribute_id] }));
                break;
            case "observed_measures":
                dispatch(navigate({ path: 'assets.attributeProperties', state: { section: "measure", measureView: "Monitoring" }, params: [attribute.asset_id, attribute.attribute_id] }));
                break;
            case "scored_measures":
                dispatch(navigate({ path: 'assets.attributeProperties', state: { section: "measure", measureView: "Scoring" }, params: [attribute.asset_id, attribute.attribute_id] }));
                break;
            case "issues":
                if (!issuePermission?.is_view || attribute.issues <= 0) {
                    return;
                }
                reactNavigate(`/home/issues?attribute_id=${attribute.attribute_id}`, { replace: true });
                break;
            case "alerts":
                if (!alertsPermission?.is_view || attribute.alerts <= 0) {
                    return;
                }
                reactNavigate(`/home/alerts?attribute_id=${attribute.attribute_id}`, { replace: true });
                break;
            default:
                break;
        }
    };

    /**
     * Handle Actions Click Event
     * @param {*} key
     * @param {*} attribute
     */
    const handleActionsClickEvent = (data, actionName, event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        switch (actionName) {
            case "run":
                if (data?.run_status === 'Completed') {
                    if (assetDetail?.run_status !== "Completed") {
                        return;
                    }
                    dispatch(triggerJobRequest({ "asset_id": data.asset_id, attribute_id: data.attribute_id, job_type: "asset" }));
                } else {
                    dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data.connection_id] }));
                }
                break;
            default:
                break;
        }
    };

    /**
     * Handle Term approval
     * @param {*} isApproved
     * @param {*} term
     */
    const handleTermApproval = (isApproved, term, data) => {
        const requestParams = {
            asset_id: data.asset_id,
            term_id: term.id,
            attribute_id: data.attribute_id,
            is_approved: isApproved
        };
        dispatch(termApprovalRequest(requestParams));
        const metadataRequestParams = {
            isTermApproval: true,
            term: isApproved ? term.id : null,
            term_approval_id: isApproved ? term.id : null,
            attribute_id: data.attribute_id
        };
        dispatch(updateAllAttributeMetadata({ data: metadataRequestParams, requestParams: { attribute_id: data.attribute_id } }));
    };

    /**
     * Handle table scroll end event
     */
    const onScrollEnd = useCallback(() => {
        if (allAttributes?.length >= totalAttributes) {
            return;
        }
        const attributeRequestParams = {
            asset_id: assetId,
            offset: allAttributes?.length || 0,
            limit: defaultLimit,
            search: searchData,
            sort: sortingInput
        };
        dispatch(getAllAttributesMetadataRequest({ params: attributeRequestParams, token: null }));
    }, [allAttributes, totalAttributes]);

    /**
     * Handle toggle for asset advanced profile
     */
    const onToggleAdvancedProfile = (value) => {
        setAllAdvancedProfile(value);
        dispatch(toggleAssetAdvancedProfileRequest({ asset_id: assetId, profile: value }));
    };


    const prepareAttributesList = (allAttributes, sorting, run_status) => {
        let attributes = JSON.parse(JSON.stringify(allAttributes));
        for (const attribute of attributes) {
            attribute.run_status = attribute.run_status || "Completed";
            if (run_status === "Completed") {
                attribute.run_status = run_status;
            }
            attribute.term = [];
            if (attribute.term_id) {
                const term = searchableTermsList.find((term) => term.id === attribute.term_id);
                attribute.term = term ? [term] : [];
            }
            attribute.tags = typeof (attribute.tags) === "string" ? JSON.parse(attribute.tags) : attribute.tags;
            attribute.tagColor = attribute.tags?.length > 0 ? `${attribute.tags[0].color}30` : null;
            attribute.distinct_count = attribute.distinct_count ? attribute.distinct_count : 0;
            attribute.null_count = attribute.null_count ? attribute.null_count : 0;
            attribute.blank_count = attribute.blank_count ? attribute.blank_count : 0;
            attribute.space_count = attribute.space_count ? attribute.space_count : 0;
            attribute.zero_values = attribute.zero_values ? attribute.zero_values : 0;
            if (!attribute.min_value) {
                attribute.min_value = (attribute.derived_type?.toLowerCase() === "integer" || attribute.derived_type?.toLowerCase() === "numeric") ? "0" : "";
            }
            if (attribute?.derived_type?.toLowerCase()?.includes("date")) {
                let min_value = attribute.min_value ? attribute.min_value : "";
                const isDate = (attribute?.derived_type?.toLowerCase() === "date");
                if (min_value && attribute?.derived_type?.toLowerCase() === "date") {
                    if (!min_value) {
                        min_value = `${min_value} 23:59:58`;
                    }
                }
                let max_value = attribute.max_value ? attribute.max_value : "";
                if (max_value && attribute?.derived_type?.toLowerCase() === "date") {
                    if (!max_value) {
                        max_value = `${max_value} 23:59:58`;
                    }
                }
                if (isDate) {
                    attribute.min_value = min_value ? moment(min_value).utc() : "";
                    attribute.max_value = max_value ? moment(max_value).utc() : "";
                } else {
                    attribute.min_value = min_value ? moment(min_value) : "";
                    attribute.max_value = max_value ? moment(max_value) : "";
                }
            }

            attribute.distinct_percentage = attribute.row_count > 0 ? formatDecimalValue(((attribute.distinct_count / attribute.row_count) * 100), decimalCount) : 0;
            attribute.null_percentage = attribute.row_count > 0 ? formatDecimalValue(((attribute.null_count / attribute.row_count) * 100), decimalCount) : 0;
            attribute.blank_percentage = attribute.row_count > 0 ? formatDecimalValue(((attribute.blank_count / attribute.row_count) * 100), decimalCount) : 0;
            attribute.space_percentage = attribute.row_count > 0 ? formatDecimalValue(((attribute.space_count / attribute.row_count) * 100), decimalCount) : 0;
            attribute.zero_values_percentage = attribute.row_count > 0 ? formatDecimalValue(((attribute.zero_values / attribute.row_count) * 100), decimalCount) : 0;

            let non_empty_count = attribute.row_count > 0 ? (attribute.row_count - (attribute.null_count + attribute.blank_count + attribute.space_count)) : 0;
            non_empty_count = non_empty_count > 0 ? non_empty_count : 0;
            attribute.non_empty_count = non_empty_count;
            attribute.non_empty_percentage = attribute.row_count > 0 ? formatDecimalValue(((non_empty_count / attribute.row_count) * 100), decimalCount) : 0;

            const minDefaultWidth = 80;
            let minWidth = getTextWidth(attribute.min_value, minDefaultWidth);
            minWidth = minWidth > minDefaultWidth ? minWidth : minDefaultWidth;
            attribute.minValueWidth = minWidth;
            let maxWidth = getTextWidth(attribute.max_value, minDefaultWidth);
            maxWidth = maxWidth > minDefaultWidth ? maxWidth : minDefaultWidth;
            attribute.maxValueWidth = maxWidth;
        }
        attributes = orderList(attributes, sorting.sortBy, sorting.orderBy);
        return attributes;
    };
    const attributesList = useMemo(() => prepareAttributesList(allAttributes, sortingInput, assetDetail?.run_status), [allAttributes, sortingInput, assetDetail?.run_status]);
    tableOptions.push({
        type: "switch",
        value: allAdvancedProfile,
        tooltipText: `${allAdvancedProfile ? 'Disable' : 'Enable'} advanced profile for all attributes`,
        onChange: (value) => onToggleAdvancedProfile(value),
        isDisabled: () => !propertiesPermission.is_edit
    });
    const minValueWidth = Math.max(...attributesList.map((attribute) => attribute.minValueWidth));
    const maxValueWidth = Math.max(...attributesList.map((attribute) => attribute.maxValueWidth));
    const valueWidth = minValueWidth > maxValueWidth ? minValueWidth : maxValueWidth;
    tableHeaders = tableHeaders.map((tableHeader) => {
        if (tableHeader.key === "value") {
            return {
                ...tableHeader,
                inputWidth: valueWidth
            };
        }
        return tableHeader;
    });

    /**
     * Get Attrubute Table Headers
     */
    const getAttributesTableHeaders = () => {
        const a_headers = [...tableHeaders];
        if (assetDetail?.connection_is_active && assetDetail?.connection_has_license && propertiesPermission?.is_edit) {
            a_headers.push(
                {
                    key: 'actions', name: 'Run', width: '5%', align: 'center',
                    actions: [{ type: 'run', tooltip: true, tooltipText: 'Run Now', getToolTipText: (data) => { return (data?.data?.run_status && data?.data?.run_status !== 'Completed') ? data?.data?.run_status : 'Run Now'; }, isDisabled: (data) => data?.status?.toLowerCase() === 'deprecated' }]
                }
            );
        }
        return a_headers;
    };

    return (
        <Grid item xs={12} className={`${classes.attributeListContainer} ${classes.chartWrapper} pt-3`}>
            <LabelContainer label={"Attributes"}>
                <ValidatorForm onSubmit={() => { }} noValidate>
                    <TableComponent
                        description={"List of all attributes"}
                        headers={getAttributesTableHeaders()}
                        options={tableOptions}
                        data={attributesList || []}
                        sortBy={sortingInput?.sortBy || ""}
                        orderBy={sortingInput?.orderBy || ""}
                        styleType="rowStriped"
                        NoResultText="No Attributes Found"
                        stickyHeader
                        isLoading={isLoadingAllAttributes}
                        searchData={searchData}
                        onClickSorting={onClickSorting}
                        selectComponentList={{ tags: searchableTagsList, term: searchableTermsList }}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        onCompnentEvent={onComponentEvent}
                        onHandleSearchEvent={onHandleSearchEvent}
                        onCellClick={handleCellClickEvent}
                        onClickActions={handleActionsClickEvent}
                        onHandleChipAcceptActions={handleTermApproval}
                        onScrollEnd={onScrollEnd}
                        userPreferenceColumns={columns || []}
                    />
                </ValidatorForm>
            </LabelContainer>
        </Grid>
    );
}

// default props
AttributesList.defaultProps = {
    classes: {},
    propertiesPermission: {}
};

// prop types
AttributesList.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...HomeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AttributesList);