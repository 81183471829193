import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PauseCircleOutlineRounded } from '@mui/icons-material';

//  Import Styles
import OverviewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Contants
import appConstants from '../../../constants/appConstants.js';
import featureConstants from '../../../constants/featureConstants.js';

// Import Images
import { SemanticTriggerIcon } from '../../../assets/svg/index.js';

//  Import Components
import { ChipComponent, PreviewComponent, SwitchComponent, TextBoxComponent } from '../../../components/index.js';
import SlideTemplateComponent from './components/alerts/index.jsx';
import FeatureCard from './components/featureCard/index.jsx';
import ConnectionCard from './components/connectionCard/index.jsx';
import DbtInformationComponent from './components/dbtInformation/index.jsx';
import FivetranMetadata from './components/fivetranMetadata/index.jsx';
import DatabricksInfoComponent from './components/databricksInfo/index.jsx';
import MoreTablesInfo from './components/moreTablesInfo/index.jsx';
import TalendInformationComponent from './components/talend/index.jsx';
import AdfInformationComponent from './components/adfInformation/index.jsx';

// Import Actions
import { getFeaturedCommentRequest, conversationUpdateRequest, conversationListRequest } from '../../../redux/reducer/conversationReducer.js';
import { updateVersionState, updateVersionRequest, getVersionAlertsRequest, termApprovalRequest, deleteApprovedTermRequest, updateAssetSemanticEnabled } from '../../../redux/reducer/versionReducer';
import { getApplicationsRequest } from '../../../redux/reducer/applicationReducer';
import { getDomainListRequest } from '../../../redux/reducer/semanticReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer.js';
import { triggerJobRequest } from '../../../redux/reducer/scheduleReducer.js';

// Import Helpers
import { checkPermission, get_connection_type_access, orderList } from '../../../helpers/appHelpers.js';
import { toggleAssetSemanticsRequest } from '../../../redux/reducer/attributeReducer.js';

function Overview(props) {


    /**
     * Define Props
     */
    const { classes, theme } = props;
    const dispatch = useDispatch();
    const { id: assetId } = useParams();
    const containerRef = useRef();
    const containerWidth = containerRef.current ? containerRef.current.clientWidth : 1500;

    /**
     * Define State
     */
    const [accessByConnection, setAccessByConnection] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { detail: versionDetail, isVersionDetailChanged, alerts } = useSelector((state) => state.version);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { list: attributeList, allAttributes } = useSelector((state) => state.attribute, shallowEqual);
    const { featuredList, filter } = useSelector((state) => state.conversation);
    const { general } = useSelector((state) => state.configurations, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const overviewPermission = checkPermission(permission, featureConstants.assets.overview);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const propertyPermission = checkPermission(permission, featureConstants.assets.properties);
    const alertsPermission = checkPermission(permission, featureConstants.home.alerts);

    /**
     * Use Ref to store assetdetails for unmount dispatch
     */
    const versionDetailStore = useRef(versionDetail, { ...isVersionDetailChanged });
    const changedProperties = useRef([]);

    /**
     * Connection Type Based Data Retrieve
     */
    useEffect(() => {
        if (versionDetail && versionDetail.type) {
            const connection_type_access = get_connection_type_access(versionDetail.type, versionDetail.asset_type);
            if (connection_type_access.attributes || versionDetail.asset_type === 'Pipeline') {
                if (!searchableGlossaries || searchableGlossaries.length === 0) {
                    dispatch(getDomainListRequest());
                }

                if (versionDetail?.alerts) {
                    dispatch(getVersionAlertsRequest(assetId));
                }
            }
            setAccessByConnection(connection_type_access);

            // Get Pinned Comments
            if (versionDetail?.pinned_msg) {
                dispatch(getFeaturedCommentRequest({ type: 'asset_id', id: assetId }));
            }
        }
    }, [dispatch, versionDetail.id]);

    /**
     * Fetch Conversations
     */
    useEffect(() => {
        if (versionDetail.asset) {
            const requestData = {
                type: 'asset_id',
                id: versionDetail.asset,
                limit: filter.limit,
                offset: 0
            };
            dispatch(conversationListRequest(requestData));
        }
    }, [dispatch, versionDetail.asset]);

    /**
     *
     * Fetch Applications
     */
    useEffect(() => {
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
    }, [assetId]);

    /**
     * Update detail in useRef when reducer updates
     */
    useEffect(() => {
        const newDetails = { ...versionDetail };
        newDetails.isVersionDetailChanged = isVersionDetailChanged;
        versionDetailStore.current = newDetails;
    }, [versionDetail, isVersionDetailChanged]);

    /**
     * Update Changed Properties
     * @param {*} property
     */
    const updateChangedProperties = (property) => {
        const properties = changedProperties.current;
        properties.push(property);
        changedProperties.current = [...properties];
    };

    /**
     * Handle Onchage Text Box
     * @param {*} property
     * @param {*} value
     */
    const handleOnChange = (property, value) => {
        dispatch(updateVersionState({ key: property, value }));
        updateChangedProperties(property);
        let newValue = value;
        if (property === "domains" || property === "application") {
            newValue = value?.map((item) => item.id);
        }
        dispatch(updateVersionRequest(
            {
                id: versionDetail.id,
                [property]: newValue,
                changed_properties: [property]
            }
        ));
    };

    /**
     * Handle Term approval
     * @param {*} isApproved
     * @param {*} term
     */
    const handleTermApproval = (isApproved, term) => {
        const requestParams = {
            asset_id: versionDetail.asset,
            version_id: versionDetail.id,
            term_id: term.id,
            attribute_id: term.attribute_id,
            is_approved: isApproved
        };
        dispatch(termApprovalRequest(requestParams));
    };

    /**
     * Handle Term click event
     * @param {*} isApproved
     * @param {*} term
     */
    const handleTermClick = (term) => {
        dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [versionDetail.asset, term.attribute_id] }));
    };

    /**
     * Handle Term chip delete click event
     * @param {*} term
     */
    const handleTermRemoveClick = (term) => {
        const query_params = `?attribute_id=${term.attribute_id}&version_id=${versionDetail.id}`;
        dispatch(deleteApprovedTermRequest({ id: term.id, query_params }));
    };

    /**
     * Handle Pin Message
     * @param {*} formData
     */
    const handlePinMessage = (formData) => {
        dispatch(conversationUpdateRequest(formData));
    };

    /**
     * Navigate to Connection Detail View
     */
    const navigateToAssetPage = (params) => {
        if (params && params.connection_id) {
            dispatch(navigate({ path: 'connector.asset', state: {}, params: [params.type, params.connection_id] }));
        }
    };

    /**
     * Hangle Preview Image Popup
     */
    const handlePreview = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenPreview(!openPreview);
    };

    const triggerSemanticJob = () => {
        if (versionDetail?.semantic_run_status === 'Completed') {
            dispatch(triggerJobRequest({ asset_id: versionDetail.asset, job_type: "semantics" }));
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [versionDetail.connection_id || versionDetail.connection] }));
        }
    };

    /**
     * Toggle semantics for all attributes
     * @param {*} value
     */
    const toggleSemantics = (value) => {
        dispatch(toggleAssetSemanticsRequest({ asset_id: assetId, is_enabled: value }));
        dispatch(updateAssetSemanticEnabled({ data: { is_semantic_enabled: value } }));
    };

    /**
     * Check whenther semantics enabled for all attributes or not
     * @returns boolean
     */
    const checkIsSemanticEnabled = () => {
        let isSemanticEnabled = Boolean(versionDetail?.is_semantic_enabled);
        if (allAttributes?.length > 0) {
            const semanticAttributes = allAttributes.filter((attribute) => attribute.is_semantic_enabled).length;
            isSemanticEnabled = (allAttributes.length === semanticAttributes);
        }
        return isSemanticEnabled;
    };
    const isSemanticEnabled = checkIsSemanticEnabled();

    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter identifiers
     * @param {*} listData
     * @returns
     */
    const filterIdentifiers = (listData) => {
        let identifiers = JSON.parse(JSON.stringify(listData));
        identifiers = orderList(identifiers, 'name', 'asc');
        return identifiers;
    };
    const identifiersList = useMemo(() => filterIdentifiers(attributeList), [attributeList]);

    /**
     * Filter identifiers
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(versionDetail?.terms), [versionDetail?.terms]);


    return (
        <Grid item xs={12} className={classes.overviewContainer} ref={containerRef}>
            <Grid container justifyContent={'space-between'} spacing={8}>
                <Grid item xs={(alertsPermission?.is_view && alerts && alerts.length > 0) || (accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.tableau && versionDetail?.properties?.img) ? 8 : 12}>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        spacing={3}
                    >
                        <Grid item xs={12} className="description">
                            <Grid item xs={12} className="boxedLayout">
                                <Typography variant="h5" className="pb-1">
                                    {appConstants.labels.datasetOverview.description}
                                </Typography>
                                <ValidatorForm onSubmit={() => { }}>
                                    <TextBoxComponent
                                        name="description"
                                        fullWidth
                                        multiline
                                        variant="standard"
                                        minRows={2}
                                        maxRows={8}
                                        placeholder="Enter description"
                                        value={versionDetail?.description ?? ""}
                                        onChange={(event) => handleOnChange(event.target.name, event.target.value)}
                                        disabled={!overviewPermission?.is_edit}
                                    />
                                </ValidatorForm >
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent={'space-between'}
                                className={`${classes.overviewChipDivs} boxedLayout`}
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h5"
                                        className="pb5"
                                    >
                                        {appConstants.labels.datasetOverview.domain}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={`${classes.textSecondary} pb7`}
                                    >
                                        {appConstants.labels.datasetOverview.domainDesc}
                                    </Typography>
                                    <ValidatorForm onSubmit={() => { }}>
                                        <ChipComponent
                                            data={versionDetail?.domains ?? []}
                                            labelKey="name"
                                            placeholder="Select Domains"
                                            add={Boolean(overviewPermission?.is_edit)}
                                            availableList={domainsList || []}
                                            editable={Boolean(overviewPermission?.is_edit)}
                                            className={'domainChips'}
                                            handleChipEvent={(value) => handleOnChange('domains', value)}
                                            addType="autocomplete"
                                            limit={containerWidth < 1500 ? 1 : 2}
                                            addText="Add Domain"
                                        />
                                    </ValidatorForm>
                                </Grid>
                                <Grid item xs={6} className="pl-3">
                                    <Typography
                                        variant="h5"
                                        className="pb5"
                                    >
                                        {appConstants.labels.datasetOverview.applications}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={`${classes.textSecondary} pb7`}
                                    >
                                        {appConstants.labels.datasetOverview.applicationsDesc}
                                    </Typography>
                                    <ValidatorForm onSubmit={() => { }}>
                                        <ChipComponent
                                            data={versionDetail?.application ?? []}
                                            labelKey="name"
                                            placeholder="Select Applications"
                                            add={Boolean(overviewPermission?.is_edit)}
                                            availableList={applicationsList || []}
                                            editable={Boolean(overviewPermission?.is_edit)}
                                            handleChipEvent={(value) => handleOnChange('application', value)}
                                            addType="autocomplete"
                                            haveColor
                                            limit={containerWidth < 1500 ? 1 : 2}
                                            addText="Add Application"
                                        />
                                    </ValidatorForm>
                                </Grid>
                                {
                                    accessByConnection && accessByConnection.attributes &&
                                    <React.Fragment>
                                        <Grid item xs={6} className="pt-3">
                                            <Typography variant="h5" className="pb5">
                                                {appConstants.labels.datasetOverview.identifier}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className={`${classes.textSecondary} pb7`}
                                            >
                                                {appConstants.labels.datasetOverview.identifierDesc}
                                            </Typography>
                                            <ValidatorForm onSubmit={() => { }}>
                                                <ChipComponent
                                                    data={versionDetail?.primary_columns ?? []}
                                                    labelKey="name"
                                                    placeholder="Select Attribute"
                                                    add={Boolean(overviewPermission?.is_edit)}
                                                    availableList={identifiersList || []}
                                                    editable={Boolean(overviewPermission?.is_edit)}
                                                    handleChipEvent={(value) => handleOnChange('primary_columns', value)}
                                                    addType="autocomplete"
                                                    autoCompleteDataTypeOption
                                                    haveDataType
                                                    limit={containerWidth < 1500 ? 1 : 2}
                                                    addText="Add Identifier"
                                                />
                                            </ValidatorForm>
                                        </Grid>
                                        <Grid item xs={6} className="pl-3 pt-3 termsDiv">
                                            <Grid container justifyContent={"space-between"} alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Typography variant="h5" className="pb5">
                                                            {appConstants.labels.datasetOverview.terms}
                                                        </Typography>
                                                        {
                                                            general?.semantics?.is_active && semanticsPermission?.is_edit && versionDetail?.connection_has_license && versionDetail?.connection_is_active && versionDetail?.status !== appConstants.status[3] &&
                                                            <Tooltip title={versionDetail?.semantic_run_status === 'Completed' ? appConstants.labels.tooltips.semanticDiscovery : versionDetail?.semantic_run_status ?? "Pending"}>
                                                                <IconButton className="mr-1 p1"
                                                                    sx={{ marginTop: '-5px !important' }}
                                                                    disabled={Boolean(!semanticsPermission?.is_edit)}
                                                                    onClick={() => triggerSemanticJob()}>
                                                                    {
                                                                        versionDetail?.semantic_run_status === 'Completed' ?
                                                                            <SemanticTriggerIcon />
                                                                            : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </Grid>
                                                {
                                                    general?.semantics?.is_active &&
                                                    <Tooltip title={`${isSemanticEnabled ? 'Disable' : 'Enable'} semantics for all attributes`}>
                                                        <Grid item className={classes.semanticsToggle}>
                                                            <SwitchComponent size="small"
                                                                checked={isSemanticEnabled || false}
                                                                handleChange={(value) => toggleSemantics(value)}
                                                                disabled={Boolean(!semanticsPermission?.is_edit)} />
                                                        </Grid>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Typography
                                                variant="body1"
                                                className={`${classes.textSecondary} pb7`}
                                            >
                                                {appConstants.labels.datasetOverview.termsDesc}
                                            </Typography>
                                            <ValidatorForm onSubmit={() => { }}>
                                                <ChipComponent
                                                    direction="row"
                                                    spacing={1}
                                                    className={'termsChips'}
                                                    labelKey={"name"}
                                                    data={termsList || []}
                                                    limit={containerWidth < 1500 ? 1 : 2}
                                                    editable={Boolean(semanticsPermission?.is_edit)}
                                                    acceptActions={Boolean(semanticsPermission?.is_edit)}
                                                    isChipDelete
                                                    onChipSelect={(term) => handleTermClick(term)}
                                                    onChipRemove={(term) => handleTermRemoveClick(term)}
                                                    onHandleAcceptAction={(status, term) => handleTermApproval(status, term)}
                                                    disable_link={!propertyPermission?.is_view} />
                                            </ValidatorForm>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>

                        {/* Connection Detail */}
                        <ConnectionCard data={versionDetail} accessByConnection={accessByConnection} navigateToAssetPage={navigateToAssetPage} permission={permission} />
                        {/* Connection Detail */}
                    </Grid>
                </Grid>
                {
                    alertsPermission?.is_view && alerts && alerts.length > 0 &&
                    <Grid item xs={4} className="pl-3">
                        <SlideTemplateComponent sliderTitle={appConstants.labels.datasetOverview.alerts} sliderCount={"24"} alert_data={alerts} />
                    </Grid>
                }
                {
                    accessByConnection && accessByConnection?.report && (accessByConnection?.report_type?.tableau || accessByConnection?.report_type?.powerbi) && versionDetail?.properties?.img?.length > 0 &&
                    <Grid item xs={4} className="pl-3" onClick={(event) => handlePreview(event)}>
                        <Grid className={classes.previewImageContainer}>
                            <img src={versionDetail.properties.img} alt="Report" />
                        </Grid>

                        <PreviewComponent
                            open={openPreview}
                            handleClosePreview={handlePreview}
                            imageURL={versionDetail.properties.img}
                        />

                    </Grid>
                }
            </Grid>
            {
                accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.dbt &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <DbtInformationComponent versionDetail={versionDetail} />
                    </Grid>
                </Grid>
            }
            {
                accessByConnection && accessByConnection?.report_type?.fivetran && versionDetail?.properties?.metadata?.length > 0 &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <FivetranMetadata data={versionDetail.properties.metadata} />
                    </Grid>
                </Grid>
            }
            {
                accessByConnection && accessByConnection?.report_type?.databricks && versionDetail?.properties?.workflow_info?.workflow_events?.length > 0 &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <DatabricksInfoComponent versionDetail={versionDetail.properties} />
                    </Grid>
                </Grid>
            }
            {
                accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.adf && versionDetail?.properties?.pipeline_info?.pipeline_runs?.length > 0 &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <AdfInformationComponent versionDetail={versionDetail.properties} />
                    </Grid>
                </Grid>
            }
            {
                accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.airflow &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <MoreTablesInfo data={versionDetail.properties} />
                    </Grid>
                </Grid>
            }
            {
                accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.talend &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-3"
                >
                    <Grid item xs={12} className="boxedLayout">
                        <TalendInformationComponent data={versionDetail?.properties ?? {}} />
                    </Grid>
                </Grid>
            }
            {
                featuredList.length > 0 &&
                <Grid
                    container
                    justifyContent={'space-between'}
                    className="pt-4"
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" className="pb-1">
                            {`${appConstants.labels.datasetOverview.featured} (${featuredList.length})`}
                        </Typography>
                        <Grid className={classes.featureSlider}>
                            <FeatureCard items={featuredList} handlePinMessage={handlePinMessage} permission={permission} />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid >
    );
}

// default props
Overview.defaultProps = {
    classes: {},
    theme: {}
};

// prop types
Overview.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...OverviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Overview);