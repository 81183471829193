import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import Style
import Style from './style.jsx';

function LoginSlider(props) {

    /**
     * Define Props
     */
    const { slide_item, index, onImageLoad } = props;

    return (
        <Grid key={index} className={`sliderItem`}>
            <Grid key={index} className={`sliderItemContent`}>
                {
                    slide_item.heading &&
                    <Typography variant="h1" align="center" className={`sliderTitle`}>
                        {slide_item.heading}
                    </Typography>
                }
                {
                    slide_item.description &&
                    <Typography variant="body1" align="center" className={`sliderContent`}>
                        {slide_item.description}
                    </Typography>
                }
            </Grid>
            <img alt="banner" src={slide_item.image} className={`slickImages`} onLoad={(event) => onImageLoad(event, slide_item, index)} />
        </Grid>
    );
}

// default props
LoginSlider.defaultProps = {
    slide_item: {},
    onImageLoad: () => {},
    index: 0
};

// prop types
LoginSlider.propTypes = {
    slide_item: PropTypes.object,
    onImageLoad: PropTypes.func,
    index: PropTypes.number
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.slide_item, nextProps.slide_item);
}


export default withStyles(Style)(React.memo(LoginSlider, areEqual));