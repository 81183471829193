// Import API Helper
import { getRequest, postRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Roles
 * @returns
 */
async function get_roles() {
    const response = await getRequest(`${urlConstants.security.roles}`);
    return response;
}

/**
 * Get Roles Permission
 * @returns
 */
async function get_roles_permission() {
    const response = await getRequest(`${urlConstants.security.roles_permission}`);
    return response;
}

/**
 * Add Featured to Role
 * @param {*} params
 * @returns
 */
async function add_feature_roles(params) {
    const response = await postRequest(`${urlConstants.security.roles_permission}`, params);
    return response;
}

/**
 * Update Featured to Role
 */
 async function update_feature_roles(id, params) {
    const response = await putRequest(`${urlConstants.security.roles_permission}${id}/`, params);
    return response;
}

/**
 * Delete Feature to Role
 * @param {*} id
 * @returns
 */
async function delete_feature_roles(id) {
    const response = await deleteRequest(`${urlConstants.security.roles_permission}${id}/`);
    return response;
}

/**
 * Add New Role
 * @param {*} params
 * @returns
 */
async function add_new_role(params) {
    const response = await postRequest(`${urlConstants.security.roles}`, params);
    return response;
}

/**
 * Get Roles Statistics
 * @returns
 */
async function get_roles_stats() {
    const response = await getRequest(`${urlConstants.security.roles_stats}`);
    return response;
}

/**
 * update User Details
 * @param {*} userId
 * @param {*} params
 * @returns
 */
 async function update_user_details(userId, params) {
    const response = await putRequest(`${urlConstants.security.roles}${userId}/`, params);
    return response;
  }

/**
 * Delete User
 * @param {*} userId
 * @returns
 */
 async function delete_user(userId) {
    const response = await deleteRequest(`${urlConstants.security.roles}${userId}/`);
    return response;
  }

/**
 * Export All Service Functions
 */
export const securityService = {
    get_roles, get_roles_permission, add_feature_roles, delete_feature_roles, add_new_role, get_roles_stats, update_user_details, delete_user, update_feature_roles
};