const style = (theme) => ({
    daysFilter: {
        background: '#fff',
        marginLeft: 20,
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    },
    measureChart: {
        marginTop: 30
    },
    chartContainer: {
        padding: '30px',
        background: theme.palette.greyshades.tablegrey,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textAlign: 'right',
        marginTop: 16
    }
});
export default style;