import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Components
import { MemoSimpleTabHeaderComponent } from '../../../../components/index.js';
import StandaloneMeasure from './components/measure/index.jsx';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

import appConstants from '../../../../constants/appConstants.js';
import AssetQuality from './components/assetQuality/index.jsx';

// Import Images

// Import Actions

function DomainQuality(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;

    const [tabIndex, setTabIndex] = useState(0);


    /**
     * Handle Naviagation
     * @param {*} selectedItem
     */

    return (
        <Grid container className={classes.tabsRootContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid
                    container
                    wrap="nowrap"
                    justifyContent={"flex-end"}
                    alignItems="center"
                    className={classes.tabHeaderContainer}
                >
                    <MemoSimpleTabHeaderComponent
                        tabList={appConstants.tabs.domainSummaryTabs}
                        className={classes.tabsHeader}
                        tabStyle={'blue'}
                        tabIndex={tabIndex}
                        onTabChange={(newValue) => setTabIndex(newValue)}
                    />
                </Grid>
                <Grid className={classes.tabsBodySection} sx={{ paddingTop: '6px' }}>
                    {
                        tabIndex === 0 &&
                        <AssetQuality />
                    }
                    {
                        tabIndex === 1 &&
                        <StandaloneMeasure />
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
DomainQuality.defaultProps = {
    classes: {}
};

// prop types
DomainQuality.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DomainQuality);