import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getDomainsRequest, getDomainsSuccess, getDomainsFailure, getDomainsAssetsRequest, getDomainsAssetsSuccess, getDomainsAssetsFailure,
  getDomainDetailRequest, getDomainDetailSuccess, getDomainDetailFailure, getSubDomainRequest, getSubDomainSuccess, getSubDomainFailure,
  getDomainTermsRequest, getDomainTermsSuccess, getDomainTermsFailure, getDomainAttributesRequest, getDomainAttributesSuccess, getDomainAttributesFailure,
  getDomainQualityRequest, getDomainQualitySucess, getDomainQualityFailure
} from '../reducer/domainsReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { domainsService } from '../service';

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';


/**
 * Get Domains
 * @returns
 */
const list = (action$) => action$.pipe(
  ofType(getDomainsRequest),
  mergeMap(() => {
    return from(domainsService.list()).pipe(
      concatMap((res) => { return of(getDomainsSuccess(res)); }),
      catchError((error) => { return of(getDomainsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Get Doamin Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
  ofType(getDomainDetailRequest),
  mergeMap(({ payload }) => {
    return from(domainsService.detail(payload)).pipe(
      concatMap((res) => of(getDomainDetailSuccess(res))),
      catchError((error) => {
        const errorMsg = error;
        if (validate404Error(errorMsg)) {
          return of(getDomainDetailFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
        }
        return of(getDomainDetailFailure(), displyAlert({ 'type': 'error', 'message': errorMsg }));
      })
    );
  })
);

/**
 * Get Doamin Assets
 * @param {*} action$
 * @returns
 */
const assets = (action$) => action$.pipe(
  ofType(getDomainsAssetsRequest),
  mergeMap(({ payload }) => {
    return from(domainsService.assets(payload)).pipe(
      concatMap((res) => of(getDomainsAssetsSuccess({ ...res }))),
      catchError((error) => of(getDomainsAssetsFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get SUb Domains
 * @param {*} action$
 * @returns
 */
const subDomains = (action$) => action$.pipe(
  ofType(getSubDomainRequest),
  mergeMap(({ payload }) => {
    return from(domainsService.subDomains(payload)).pipe(
      concatMap((res) => of(getSubDomainSuccess({ ...res }))),
      catchError((error) => of(getSubDomainFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Get Terms
 * @param {*} action$
 * @returns
 */
const terms = (action$) => action$.pipe(
  ofType(getDomainTermsRequest),
  mergeMap(({ payload }) => {
    return from(domainsService.terms(payload)).pipe(
      concatMap((res) => of(getDomainTermsSuccess({ ...res }))),
      catchError((error) => of(getDomainTermsFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Get Attributes
 * @param {*} action$
 * @returns
 */
const attributes = (action$) => action$.pipe(
  ofType(getDomainAttributesRequest),
  mergeMap(({ payload }) => {
    return from(domainsService.attributes(payload)).pipe(
      concatMap((res) => of(getDomainAttributesSuccess({ ...res }))),
      catchError((error) => of(getDomainAttributesFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Get Quality
 * @param {*} action$
 * @returns
 */
const quality = (action$) => action$.pipe(
  ofType(getDomainQualityRequest),
  mergeMap(({ payload: { accordian, clear, ...rest } }) => {
    return from(domainsService.quality(rest)).pipe(
      concatMap((res) => of(getDomainQualitySucess({ ...res, accordian, clear }))),
      catchError((error) => of(getDomainQualityFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

// Export All Domains Functions
export const domainsEpic = [list, detail, assets, subDomains, terms, attributes, quality];