const style = () => ({
    selectDialog: {
        '& .MuiPaper-root': {
            overflow: 'hidden'
        },
        '&+ .MuiModal-root .MuiPaper-root': {
            boxShadow: '0px 1px 5px #a5a5a5 !important'
        }
    },
    selectContainer: {
        padding: 30,
        width: `500px !important`,
        '& .CloseIcon': {
            width: '30px',
            height: '30px'
        },
        '& .closeButton': {
            width: 32,
            height: 32,
            padding: 5
        },
        '& .MuiFormHelperText-root': {
            color: `#F38080`
        }
    }
});
export default style;