// Import API Helper
import { deleteRequest, getRequest, putRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';

/**
 * Get Asset Detail
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.asset.url}${id}/`);
    return response;
}


/**
 * Update Asset
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.asset.url}${id}/`, params);
    return response;
}


/**
 * Get Asset Versions
 */
async function versions(params) {
    const response = await getRequest(`${urlConstants.version.by_asset}${params}/`);
    return response;
}

/**
 * Remove Asset
 */
async function remove(id, purge) {
    const response = await deleteRequest(`${urlConstants.asset.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Get Asset Trending Chart Data
 * @param {*} params
 * @returns
 */
async function trending_chart(params) {
    const response = await postRequest(`${urlConstants.asset.timeline}`, params);
    return response;
}

/**
 * Get Asset Dimension Chart Data
 * @param {*} params
 * @returns
 */
async function dimension_chart(params) {
    const response = await postRequest(`${urlConstants.asset.dimension}`, params);
    return response;
}

/**
 * Get Asset List
 * @returns
 */
async function getAllAssets() {
    const response = await getRequest(urlConstants.asset.url);
    return response;
}

/**
 * Get Lineage
 * @param {*} params
 * @returns
 */
async function getLineage(params) {
    const response = await postRequest(`${urlConstants.asset.lineage}`, params);
    return response;
}


/**
 * Update Asset Report Mapping
 * @param {*} params
 * @returns
 */
async function mapReport(params) {
    const response = await postRequest(`${urlConstants.reportMapping.url}`, params);
    return response;
}

/**
 * Remove Map Report Asset
 */
async function removeReportMap(id) {
    const response = await deleteRequest(`${urlConstants.reportMapping.url}${id}/`);
    return response;
}

/**
 * Validate Asset Report Mapping
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function validateAssetReportMapping(id) {
    const response = await getRequest(`${urlConstants.reportMapping.asset}${id}/`);
    return response;
}

/**
 * Get Searchable Asset List
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getSearchableList() {
    const response = await getRequest(`${urlConstants.asset.searchable_list}`);
    return response;
}

/**
 * Get Asset and Attributes List
 * @returns
 */
async function getAllAssetsAndAttributes(params) {
    const response = await postRequest(urlConstants.asset.assets_attributes, params);
    return response;
}


/**
 * Get Asset List
 * @returns
 */
async function getAssetList(params) {
    const response = await postRequest(urlConstants.asset.assetList, params);
    return response;
}


/**
 * Export All Service Functions
 */
export const assetService = {
    detail, update, versions, remove, trending_chart, dimension_chart, getAllAssets, getLineage, mapReport, removeReportMap,
    validateAssetReportMapping, getSearchableList, getAllAssetsAndAttributes, getAssetList
};