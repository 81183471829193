import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, FormLabel, Dialog } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Components
import QueryBuilderComponent from '../queryBuilder/index.jsx';
import { TextBoxComponent } from '../textBox/index.js';
import { SwitchComponent, AutoCompleteComponent } from '../index';

//  Import Styles
import PatternAddStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helpers
import appConstants from '../../constants/appConstants.js';
import { validatePattern } from '../../helpers/appHelpers.js';


function AddPattern(props) {

    /**
     * Define Props
     */
    const { classes, open, selectedPattern, handleClose, handleUpdatePattern, activePatterns, connectionType } = props;

    /**
     * Define State
     */
    const [pattern, setPattern] = useState(null);

    /**
     * Define useEffect
     */
    useEffect(() => {
        if (selectedPattern) {
            setPattern(selectedPattern);
        } else {
            setPattern({ "name": "", "technical_name": "", "posix_pattern": "", "sql_pattern": "", "count": 0, "is_positive": false, "is_default": false });
        }
    }, [selectedPattern]);

    /**
     * Handle Change
     * @param {*} property
     * @param {*} value
     */

    const handleChange = (property, value) => {
        let updatedPattern = JSON.parse(JSON.stringify(pattern));
        if (property === 'name') {
            const selectedActivePattern = activePatterns.find((pattern) => pattern.name === value);
            if (selectedActivePattern) {
                updatedPattern = { ...selectedActivePattern, "is_default": true };
            } else {
                updatedPattern = { ...updatedPattern, name: value, "is_default": false };
            }
        }
        else if (property === 'posix_pattern' || property === 'sql_pattern') {
            const patternValue = updatedPattern?.pattern ?? {};
            patternValue[property] = value;
            updatedPattern = { ...updatedPattern, pattern: { ...patternValue } };
        } else {
            updatedPattern = { ...updatedPattern, [property]: value };
        }
        setPattern({ ...updatedPattern });
    };

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('regexValid', (value) => {
            return validatePattern(value);
        });
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth
            className={classes.addPatternDialog}
        >
            <ValidatorForm onSubmit={() => handleUpdatePattern(pattern)} noValidate>
                {
                    pattern &&
                    <Grid container className={classes.patternAddContainer} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {selectedPattern ? "Edit Pattern" : "Add Pattern"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteComponent
                                name="name"
                                variant="standard"
                                selectedValue={pattern.name || ""}
                                data={activePatterns || []}
                                onChange={(event, data) => handleChange(event.target.name, data)}
                                placeholder=""
                                freeSolo
                                autoSelect
                                label={
                                    <span className="requiredLabel">
                                        Name
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.nameRequired]}
                                disabled={pattern.is_default}
                            />
                        </Grid>
                        {
                            (!connectionType || (connectionType && (connectionType.toLowerCase() !== appConstants.connectionTypes.mssql.value))) &&
                            <Grid item xs={12}>
                                <TextBoxComponent
                                    name="posix_pattern"
                                    fullWidth
                                    label={
                                        <span className="requiredLabel">
                                            Posix Pattern
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    variant="standard"
                                    size="medium"
                                    value={pattern?.pattern?.posix_pattern || ""}
                                    validators={['required', 'regexValid']}
                                    errorMessages={[appConstants.errorMessages.valueRequired, appConstants.errorMessages.patternNotValid]}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    disabled={pattern.is_default}
                                />
                            </Grid>
                        }
                        {
                            (!connectionType || (connectionType && (connectionType.toLowerCase() === appConstants.connectionTypes.mssql.value))) &&
                            <Grid item xs={12}>
                                <Typography variant="body2" className="pb-2 fw-500" >
                                    SQL Pattern
                                </Typography>
                                <QueryBuilderComponent
                                    data={pattern?.pattern?.sql_pattern ?? {}}
                                    isNew={Boolean(!selectedPattern)}
                                    editMeasure={(type, value) => handleChange('sql_pattern', value)}
                                    isPattern
                                    isDisabled={pattern.is_default || false}
                                    propertyPermission={{ "is_edit": !pattern.is_default }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.switchContainer}>
                            <FormLabel>
                                Is Valid
                            </FormLabel>
                            <SwitchComponent
                                size="small"
                                checked={pattern.is_positive}
                                handleChange={(value) => handleChange("is_positive", value)}
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={handleClose}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="ml-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </ValidatorForm>
        </Dialog>
    );
}

// default props
AddPattern.defaultProps = {
    classes: {},
    open: false,
    selectedPattern: null,
    handleClose: () => { },
    handleUpdatePattern: () => { },
    activePatterns: [],
    connectionType: ""
};

// prop types
AddPattern.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    selectedPattern: PropTypes.object,
    handleClose: PropTypes.func,
    handleUpdatePattern: PropTypes.func,
    activePatterns: PropTypes.array,
    connectionType: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...PatternAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AddPattern);