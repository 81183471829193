import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import { Grid, IconButton } from '@mui/material';
import Card from './card.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import './treeview.css';

// Import Images
import { OrgAdd, OrgMinus } from '../../../../../assets/svg/index.js';

function Organization(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;
    const [hierachyData, setHierachyData] = useState([]);

    /**
     * Load Data
     */
    useEffect(() => {
        if (data.length && !hierachyData.length) {
            const organization = {
                ...data[0]
            };
            setHierachyData([
                {
                    type: "Organization",
                    data: [
                        {
                            ...organization,
                            isSelected: true
                        }
                    ]
                },
                {
                    type: "Domain",
                    data: [...data[0].children]
                }
            ]);
        }
    }, []);

    /**
     * Get Type
     * @param {*} type
     * @returns
     */
    const getType = (type) => {
        switch (type) {
            case 'Organization':
                return 'Domain';
            case 'Domain':
            case 'Category':
                return 'Asset';
            default:
                return 'Attribute';
        }
    };

    /**
     * OnSelect Event
     * @param {*} item
     * @param {*} itemIndex
     * @param {*} parent
     */
    const onSelectItem = (item, itemIndex, parent) => {
        if (item.children) {
            let hierachy = JSON.parse(JSON.stringify(hierachyData));
            const type = getType(parent.type);
            const selectedIndex = hierachy[parent.index].data.findIndex((data) => data.isSelected);
            hierachy[parent.index].data[selectedIndex] = {
                ...hierachy[parent.index].data[selectedIndex],
                isSelected: false
            };
            hierachy[parent.index].data[itemIndex] = {
                ...hierachy[parent.index].data[itemIndex],
                isSelected: !item.isSelected
            };
            if (!item.isSelected) {
                const index = hierachy.findIndex((obj) => obj.type === type);
                if (index > 0) {
                    hierachy = hierachy.slice(0, parent.index + 1);
                }
                const children = item?.children.filter((obj) => obj) ?? [];
                hierachy.push({
                    type,
                    data: children
                });
            } else {
                hierachy = hierachy.slice(0, parent.index + 1);
            }
            setHierachyData([...hierachy]);
        }
    };

    /**
     * Get Height
     * @param {*} index
     * @param {*} items
     * @returns
     */
    const getHeight = (index, items) => {
        const itemIndex = hierachyData[index - 1].data.findIndex((obj) => obj.isSelected);
        return ((itemIndex + 1) - items) * 110;
    };


    /**
     * Get Selection Line Height
     * @param {*} index
     * @param {*} currentIndex
     * @param {*} type
     * @returns
     */
    const getSelectLineHeight = (index, currentIndex, type) => {
        if (index !== 0) {
            const element = document.getElementById(`${index}-${currentIndex}-node`);
            const parentElementIndex = hierachyData[index - 1].data.findIndex((obj) => obj.isSelected);
            const parentNode = document.getElementById(`${index - 1}-${parentElementIndex}-node`);
            if (type === "height") {
                return element && parentNode ? Math.abs(parentNode.offsetTop - element.offsetTop) : 0;
            }
            return parentNode.offsetTop - element.offsetTop > 0 ? "Clinetop" : "Clinebot";
        }
    };

    const validateTree = (value) => {
        if (value && value.length > 0) {
            if (value[0] === null) {
                return false;
            }
            return true;
        }
        return false;
    };

    return (
        <Grid item xs={12} className={classes.horizontalView} sx={{ height: '100%' }}>
            <div className="org-chart">
                {
                    hierachyData.map((item, index) =>
                        <div className="chartColumn" key={index} >
                            <ul>
                                {
                                    item.data.map((obj, childIndex) =>
                                        <li key={childIndex} id={`${index}-${childIndex}-node`} className={classNames(obj.isSelected ? 'selected' : null)} style={{ marginTop: `${index === 0 ? ((obj.children.length - 1) * 90) / 2 : 0}px` }}>
                                            <Grid className="orgCard">
                                                <Card data={obj} />
                                                {
                                                    validateTree(obj?.children) &&
                                                    <IconButton onClick={() => onSelectItem(obj, childIndex, { ...item, index })} className={`${classes.addRemoveIcon} noDrag`}>
                                                        {obj.isSelected ? <OrgMinus /> : <OrgAdd />}
                                                    </IconButton>
                                                }
                                            </Grid>
                                            <div className="lin" />
                                            {
                                                childIndex === 0 && index !== 0 &&
                                                <div className="Hlinetop" />
                                            }
                                            {
                                                childIndex === item.data.length - 1 && index !== 0 &&
                                                <div className="Hlinebot" />
                                            }
                                            {
                                                obj.isSelected &&
                                                <div className={getSelectLineHeight(index, childIndex, "class")} style={{ height: `${getSelectLineHeight(index, childIndex, "height")}px` }} />
                                            }
                                            {
                                                childIndex === item.data.length - 1 &&
                                                <div className="gline" style={{ height: `${index !== 0 ? getHeight(index, item.data.length) : 3}px` }} />
                                            }
                                        </li>
                                    )
                                }
                            </ul>
                        </div>

                    )
                }
            </div>
        </Grid>
    );
}

// default props
Organization.defaultProps = {
    classes: {},
    data: []
};

// prop types
Organization.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }), { withTheme: true }
)(React.memo(Organization, areEqual));