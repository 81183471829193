const style = (theme) => ({
    selectComponent: {
        '& .ChevDownIcon': {
            width: 12,
            height: 12,
            flexShrink: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: '10px'
        },
        '& .Mui-focused': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiInputLabel-root': {
            transform: 'translate(0, 19px) scale(1)',
            color: '#999999'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, -6.5px) scale(1)',
            fontSize: '14px'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent !important'
        },
        '& .MuiInput-underline:before': {
            borderColor: `${theme.palette.greyshades.lightgrey} !important`
        }
    },
    outLineNone: {
        '& .MuiSelect-select': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
            paddingRight: '32px !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`,
                borderRadius: 0
            }
        }
    },
    customPaper: {
        border: `1px solid ${theme.palette.greyshades.lightgrey}`
    }
});
export default style;