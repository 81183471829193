import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useDispatch } from 'react-redux';

//  Import Components
import { Button, Grid, Typography } from '@mui/material';
import { ChipComponent } from '../../../../../components/index';
import CreateEditIssueComponent from '../../../../issues/createEdit/index.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';

function LastIssues(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();
    const [issueParams, setIssueParams] = useState(null);

    /**
     * Get Priority Class
     * @param {*} priority
     * @returns
     */
    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'High':
                return 'danger';
            case 'Medium':
                return 'warning';
            default:
                return 'info';
        }
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} issue
     */
    const redirect = (key, issue) => {
        switch (key) {
            case "asset":
                dispatch(navigate({ path: 'assets.root', state: {}, params: [issue.asset_id] }));
                break;
            case "attribute_name":
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [issue.asset_id, issue.attribute_id] }));
                break;
            case "issue":
                setIssueParams({
                    id: issue.id,
                    asset: issue.asset_id,
                    attribute: issue.attribute_id,
                    version: issue.version_id,
                    drift: issue.drift_id,
                    measure: issue.measure_id,
                    name: issue?.name ?? '',
                    description: issue?.name ?? '',
                    priority: issue?.status ?? 'Low'
                });
                break;
            default:
                break;
        }
    };


    return (
        <Grid item xs={12} className="h-100">
            <Grid item xs={12} className={classes.latesIssuesContainer}>
                {
                    data && data.map((item, index) =>
                        <Grid key={`issues_${index}`} container className={`${classes.widgetItem}`} alignItems={'center'} wrap="nowrap">
                            <Grid item className={classes.widgetListIcon}>
                                <ReportGmailerrorredIcon />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" align="left" className="pointer" onClick={() => redirect("issue", item)}>
                                    {item.name || ""}
                                </Typography>
                                <ValidatorForm onSubmit={() => { }}>
                                    <ChipComponent chipClass={`${getPriorityClass(item.priority)} sm mr-15`} data={[item.priority || 0]} />
                                </ValidatorForm>
                                <Grid item className="dflex alignCenter spaceBetween">
                                    <Grid className="dflex">
                                        <Typography variant="subtitle1" className={`${classes.textSecondary} pb5 pointer`} onClick={() => redirect("asset", item)}>
                                            {item.asset_name || ""}
                                        </Typography>
                                        {
                                            item.attribute_name &&
                                            <Fragment>
                                                <Typography variant="subtitle1" className={`${classes.textSecondary} pr-1 pl-1`}>
                                                    |
                                                </Typography>
                                                <Typography variant="subtitle1" className={`${classes.textSecondary} pointer`} onClick={() => redirect("attribute_name", item)}>
                                                    {item.attribute_name || ""}
                                                </Typography>
                                            </Fragment>
                                        }
                                        {
                                            !item.asset_name && item.measure_name &&
                                            <Typography variant="subtitle1" className={`${classes.textSecondary} pb5`}>
                                                {item.measure_name || ""}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Typography variant="subtitle1" className={classes.textSecondary}>
                                        {moment(item.created_date).format('MMM DD YYYY hh:mm A')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
            <Grid align="right" className={classes.linkBtnText}>
                <Button variant="text"
                    className={classes.linkBtn}
                    disableRipple
                    onClick={() => dispatch(navigate({ path: 'home.issues', state: {}, params: [] }))}
                >
                    See all issues
                </Button>
            </Grid>
            {
                issueParams &&
                <CreateEditIssueComponent
                    issueParams={issueParams}
                    open={Boolean(issueParams)}
                    onClose={() => setIssueParams(null)}
                />
            }
        </Grid>
    );
}

// default props
LastIssues.defaultProps = {
    classes: {},
    data: []
};

// prop types
LastIssues.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LastIssues);