import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';

//  Import Components
import { ToggleButtonGroup, ToggleButton, Grid, Typography, IconButton, Button } from '@mui/material';
import { PopOverComponent, DatePickerComponent } from '../../../../../../components/index.js';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../../constants/appConstants.js';

// Import Actions
import { updateDashboardFilter, getDashboardDetailRequest } from '../../../../../../redux/reducer/dashboardReducer.js';
import { updateUserPreference } from '../../../../../../redux/reducer/authReducer';
// import { updateUserPreferenceRequest } from '../../../../../../redux/reducer/userReducer';

// Import Image
import { CalendarIcon } from '../../../../../../assets/svg/index';

// Import Helpers
import { getUserPreference, prepareUpdateUserPreference } from '../../../../../../helpers/appHelpers.js';

// Import Service
import { userService } from '../../../../../../redux/service/userService.js';


function DashboardFilter(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const requestAbortControllerRef = useRef();

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date()
    });


    /**
     * Get Data from Redux
     */
    const { id, detail } = useSelector(({ dashboard }) => dashboard);
    const user = useSelector(({ auth }) => auth.user);
    const filter = getUserPreference(user?.user_preference ?? {}, "dashboard", id, "filters");


    /**
     * Update User Widget
     * @param {*} filterData
     */
    const updateWidget = (type, value) => {
        const filters = detail?.filters ?? {};
        const requestParams = {
            id,
            filters: {
                ...filters,
                [type]: value
            }
        };


        if (requestAbortControllerRef && requestAbortControllerRef.current) {
            requestAbortControllerRef.current.abort();
        }
        requestAbortControllerRef.current = new AbortController();
        const token = { signal: requestAbortControllerRef?.current?.signal };


        dispatch(updateDashboardFilter(requestParams));
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "dashboard", id, { filters: { date: value } });
        dispatch(updateUserPreference(userPreference));
        const updateParams = {
            id: user.id,
            user_preference: userPreference
        };
        userService.updateUserPreference(updateParams).then(() => {
            dispatch(getDashboardDetailRequest({ dashboardId: id, token }));
        });
    };

    /**
     * Open Date picker
     * @param {*} event
     */
    const openPicker = (event) => {
        let dateFilter = filter ? filter?.date ?? "" : detail?.filters?.date ?? "";
        if (dateFilter && dateFilter.includes('between')) {
            dateFilter = dateFilter.split("between")[1].split("and");
            setDate({
                startDate: moment(dateFilter[0].trim()),
                endDate: moment(dateFilter[1].trim())
            });
        }
        setAnchorEl(event.currentTarget);
    };

    /**
     * OnChange Date
     * @param {*} property
     * @param {*} value
     */
    const onChangeDate = (property, value) => {
        setDate({
            ...date,
            [property]: value
        });
    };

    /**
     * Apply Filter
     */
    const applyFilter = () => {
        if (date.startDate && date.endDate) {
            const value = `between '${moment(date.startDate).format('YYYY-MM-DD')}' and '${moment(date.endDate).format('YYYY-MM-DD')}'`;
            updateWidget("date", value);
        }
        setAnchorEl(null);
    };

    return (
        <Grid className="dflex">
            <ToggleButtonGroup
                value={filter ? filter?.date ?? "all" : detail?.filters?.date ?? "all"}
                exclusive
                aria-label="text alignment"
                onChange={(event, value) => updateWidget("date", value)}
                className={classes.daysFilter}
            >
                {
                    appConstants.dashboardDateFilter.map((filter, index) =>
                        <ToggleButton
                            key={`date_filter_${index}`}
                            value={filter.value}
                            aria-label="left aligned"
                        >
                            <Typography variant="body2" className={classes.textSecondary}>
                                {filter.name}
                            </Typography>
                        </ToggleButton>
                    )
                }
            </ToggleButtonGroup>
            <IconButton className={classNames(classes.calendarIconDiv, (filter ? filter.date?.includes('between') : detail?.filters?.date?.includes('between')) ? 'active' : null)} onClick={(event) => openPicker(event)}>
                <CalendarIcon />
            </IconButton>
            <PopOverComponent
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <Grid container spacing={4} className={classes.calendarPopper}>
                    <Grid item xs={6}>
                        <Typography variant="body1" className={"pb5"}>
                            From date
                        </Typography>
                        <DatePickerComponent value={date.startDate} onChange={(value) => onChangeDate("startDate", value)} maxDate={new Date()} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" className={"pb5"}>
                            To date
                        </Typography>
                        <DatePickerComponent value={date.endDate} onChange={(value) => onChangeDate("endDate", value)} minDate={date.startDate} maxDate={new Date()} />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button onClick={() => setAnchorEl(null)} color="primary" variant="outlined" size="small" className="mr-1">
                            Cancel
                        </Button>
                        <Button onClick={() => applyFilter()} color="primary" variant="contained" size="small" disableElevation>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </PopOverComponent>
        </Grid>
    );
}

// default props
DashboardFilter.defaultProps = {
    classes: {}
};

// prop types
DashboardFilter.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DashboardFilter);