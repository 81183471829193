// Default Imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, Checkbox, Tooltip, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

// Import Svg
import { SearchIcon, DownloadIcon2, PlusIcon, PlayIcon2, CalendarIcon, ThreeColumnIcon, EditIcon, CloseIcon3, ClearFilter } from '../../../../assets/svg/index';

// Import Component
import PopOverComponent from '../../../popOver/index.jsx';

//Import Styles
import style from "./style.jsx";
import { SelectComponent, SearchComponent, SwitchComponent } from '../../..';
import { RefreshOutlined, PauseCircleOutlineRounded } from '@mui/icons-material';

const TableHeaderComponent = (props) => {

    /**
     * Define Props
     */
    const { options, title, countText, headers, handleChange, classes, filters, description, search, searchConfig, onSearchChange, searchData, theme } = props;

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [columnSearch, setColumnSearch] = useState("");
    const [globalSearchKey, setGlobalSearchKey] = useState("");

    /**
     * Icon Actions
     * @param {*} type
     * @param {*} event
     */
    const iconActions = (item = {}, event = null) => {
        switch (item.type) {
            case 'search':
                handleChange(item);
                break;
            case 'edit':
            case 'download':
                handleChange(item);
                break;
            case 'columns':
                setAnchorEl(event.currentTarget);
                break;
            case 'add':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                    return;
                }
                handleChange(item);
                break;
            case 'close':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                }
                break;
            case 'refresh':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                }
                break;
            case 'schedule':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                }
                break;
            case 'runnow':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                }
                break;
            case 'clear':
                if (item.customFunction) {
                    item.customFunction(event, item.type);
                }
                break;
            default:
                break;
        }
    };


    /**
     * Bind Options
     * @returns Element
     */
    const bindOptions = () => {
        return (
            options && options.map((item, index) => {
                let action = null;
                let title = '';
                if (item.isLoading) {
                    return (
                        <Grid className={classes.tableIconLoader} key={`loader_${index}`}>
                            <CircularProgress
                                size="sm"
                                value={50}
                                sx={
                                    {
                                        color: (theme) =>
                                            (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8')
                                    }
                                }
                                thickness={3}
                            />
                        </Grid>
                    );
                }
                switch (item.type) {
                    case 'search':
                        action = <SearchIcon />;
                        title = 'Search';
                        break;
                    case 'download':
                        action = <DownloadIcon2 />;
                        title = 'Download';
                        break;
                    case 'columns':
                        action = <ThreeColumnIcon />;
                        title = 'Select Columns';
                        break;
                    case 'add':
                        action = <PlusIcon />;
                        title = 'Add';
                        break;
                    case 'edit':
                        action = <EditIcon />;
                        title = 'Edit';
                        break;
                    case 'schedule':
                        action = <CalendarIcon />;
                        title = 'Schedule';
                        break;
                    case 'runnow':
                        action = item?.jobStatus === "Completed" ? <PlayIcon2 /> : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />;
                        title = item?.jobStatus === "Completed" ? 'Runnow' : 'Pending';
                        break;
                    case 'close':
                        action = <CloseIcon3 />;
                        title = 'Close';
                        break;
                    case 'refresh':
                        action = <RefreshOutlined className={classes.tableIcon} />;
                        title = 'Refresh';
                        break;
                    case 'switch':
                        action = (
                            <Tooltip title={item.tooltipText} arrow key={`tooltip-${index}`}>
                                <Grid item className={classes.switchOption}>
                                    <SwitchComponent size="small"
                                        checked={item.value || false}
                                        handleChange={(value) => item.onChange && item.onChange(value)}
                                        disabled={item.isDisabled && item.isDisabled()} />
                                </Grid>
                            </Tooltip>
                        );
                        return action;
                    case 'clear':
                        if ("isClearFilter" in item) {
                            if (item.isClearFilter) {
                                action = <ClearFilter />;
                            }
                        } else {
                            const haveSearchData = Object.keys(searchData).filter((key) => {
                                if (searchData[key]) {
                                    if (typeof searchData[key] === "string" && searchData[key] !== '') {
                                        return true;
                                    } else if (Array.isArray(searchData[key]) && searchData[key]?.length > 0) {
                                        return true;
                                    }
                                }
                                return false;
                            });

                            if (haveSearchData && haveSearchData.length) {
                                action = <ClearFilter />;
                            }
                        }
                        title = 'Clear Filters';
                        break;
                    default:
                        action = null;
                        break;
                }

                if (action) {
                    return (
                        <Tooltip title={title} arrow key={`tooltip-${index}`}>
                            <IconButton
                                key={`icon-${index}`}
                                className={'p5'}
                                onClick={(event) => iconActions(item, event)}
                            >
                                {action}
                            </IconButton>
                        </Tooltip>);
                }
                return null;
            })
        );
    };

    const filteredHeaderArr = headers.filter((item) => item.name?.toLowerCase().includes(columnSearch.toLowerCase()));

    return (
        <Fragment>
            <Grid container className="tableHeaderOptions pb5" alignItems={"center"} justifyContent="space-between">
                <Grid item>
                    {
                        title &&
                        <Typography component="h5" variant="h5" align="left" className="pb5">
                            {title}
                        </Typography>
                    }
                    {
                        description &&
                        <Typography variant="body1">
                            {description}
                        </Typography>
                    }
                </Grid>
                <Grid item className={classes.headerOption}>
                    {
                        countText?.length > 0 &&
                        <Typography variant="h5" className={classes.countText}>
                            {countText}
                        </Typography>
                    }
                    {
                        filters && filters.map((filter, index) =>
                            <SelectComponent
                                className="mr-2"
                                key={`filter_${index}`}
                                value={filter.value || ""}
                                list={filter.options || []}
                                onSelectChange={(value) => filter.onChange(value)}
                                noOutline
                            />
                        )
                    }
                    {
                        search &&
                        <Grid className={"mr-1"}>
                            <SearchComponent
                                placeholder={searchConfig?.placeholder?.length > 0 ? searchConfig.placeholder : "Search Here"}
                                onChange={
                                    (value) => {
                                        setGlobalSearchKey(value);
                                        onSearchChange(value);
                                    }
                                }
                                value={globalSearchKey}
                            />
                        </Grid>
                    }
                    {bindOptions()}
                </Grid>
            </Grid>
            {
                Boolean(anchorEl) &&
                <PopOverComponent
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className={classes.poperComponent}
                >
                    <Grid className={classes.columnPopover}>
                        <List className={`${classes.columnList} columnList`}>
                            <ListItem className={classes.searchBoxColumn}>
                                <SearchComponent
                                    placeholder={"Search Columns"}
                                    value={columnSearch}
                                    onChange={(value) => setColumnSearch(value)} />
                            </ListItem>
                            <Grid className={classes.gridList}>
                                {
                                    filteredHeaderArr && filteredHeaderArr.map((header, index) =>
                                        <ListItem key={`header_${index}`}>
                                            <ListItemIcon>
                                                <Checkbox checked={header.showColumn} onChange={(event) => handleChange({ type: "columns" }, header.name, event.target.checked)} />
                                            </ListItemIcon>
                                            <ListItemText primary={header.name} />
                                        </ListItem>
                                    )
                                }
                            </Grid>
                        </List>
                    </Grid>
                </PopOverComponent>
            }
        </Fragment>
    );
};


/**
 * Define Component Props
 */
TableHeaderComponent.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    countText: PropTypes.string,
    headers: PropTypes.array,
    search: PropTypes.bool,
    searchConfig: PropTypes.object,
    handleChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    classes: PropTypes.object,
    filters: PropTypes.array,
    description: PropTypes.string,
    searchData: PropTypes.object,
    theme: PropTypes.object
};

/**
 * Set Default Values
 */
TableHeaderComponent.defaultProps = {
    classes: {},
    options: [],
    headers: [],
    title: "",
    countText: "",
    search: false,
    searchConfig: {
        placeholder: ""
    },
    handleChange: () => { },
    onSearchChange: () => { },
    filters: [],
    description: "",
    searchData: {}
};

export default withStyles((theme) => ({
    ...style(theme)
}), { withTheme: true })(TableHeaderComponent);