const style = (theme) => ({
    overviewInfo: {
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        backgroundColor: theme.palette.greyshades.lightbackground,
        '& .leftSection': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 20,
            flexGrow: 1,
            flexWrap: 'wrap',
            '& img': {
                width: 25
            },
            '& .teradata img': {
                width: "auto",
                height: 16
            },
            '&.multiSection .infoSection': {
                width: "16.6%"
            },
            '&.multiSection .infoSectionPB': {
                paddingBottom: "50px"
            }
        },
        '& .rightSection': {
            borderLeft: `1px solid ${theme.palette.greyshades.grey}`,
            padding: 20,
            '& .MuiTypography-root': {
                color: theme.palette.secondary.main
            }
        },
        '& .error': {
            color: "red"
        },
        '& .ellipsisText': {
            maxWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    rowsContainer: {
        cursor: 'pointer',
        '&:hover': {
            '& .MuiTypography-root': {
                color: `${theme.palette.secondary.main} !important`
            }
        }
    },
    infoToolTip: {
        fontSize: '14px !important',
        color: '#fff !important',
        textTransform: 'capitalize !important',
        padding: '2px 0px'
    }
});
export default style;