import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

// Import Constants
import appConstants from '../../../../constants/appConstants';

// Import Component
import UserListChipItems from '../../../../components/users/listChip.jsx';


function RolesCard(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;

    /**
     * Redux Store
     */
    const users = useSelector(({ user }) => user.users_list);
    const logginUser = useSelector(({ auth }) => auth.user);

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectRole, setSelectRole] = useState(null);

    /**
     * OnSelect Role
     * @param {*} event
     * @param {*} item
     */
    const onSelectRole = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectRole(item.id);
    };

    /**
     * OnClose Dialog
     */
    const onClose = () => {
        setAnchorEl(null);
        setSelectRole(null);
    };


    /**
     * Get Users
     * @returns
     */
    const getUsers = () => {
        const userList = [...users, { ...logginUser }];
        return userList.filter((user) => user.role === selectRole);
    };

    return (
        <Grid className={classes.usersCard}>
            <Typography variant="body1" className={classes.cardTitle}>
                {appConstants.labels.security.users}
            </Typography>
            <Grid container justifyContent={'space-between'}>
                {
                    data.map((item, index) => (
                        <Grid item key={`user-card-${index}`} className="pointer pr-1" onClick={(event) => onSelectRole(event, item)}>
                            <Typography variant="body1" className={classes.cardText}>
                                {item.count}
                            </Typography>
                            <Typography variant="body1" className={classes.cardValue}>
                                {item.name}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>
            {
                Boolean(anchorEl) &&
                <UserListChipItems
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => onClose()}
                    data={getUsers() || []}
                />
            }
        </Grid>
    );
}

// default props
RolesCard.defaultProps = {
    classes: {},
    data: []
};

// prop types
RolesCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default React.memo(RolesCard, areEqual);