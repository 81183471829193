const style = (theme) => ({
    attributesDetailContainer: {
        padding: '24px 0px',
        paddingBottom: 0,
        '& .searchBox': {
            padding: '2px 10px !important'
        }
    },
    propertiesDescription: {
        color: `${theme.palette.greyshades.darkgrey} !important`,
        paddingTop: 8,
        lineHeight: '20px !important',
        fontStyle: 'italic',
        textAlign: 'left'
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px !important',
        '& .MuiOutlinedInput-root': {
            maxWidth: 130,
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '6.5px 8px 3.5px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        }
    }
});
export default style;