import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../../components/index';
import { useDispatch, useSelector } from 'react-redux';
import { getMeasureLogsRequest, clearMeasureLogs, sortMeasureLogs } from '../../../../../../redux/reducer/versionReducer.js';

// Import Constant
import appConstants from '../../../../../../constants/appConstants.js';
import moment from 'moment';

function MeasureLogs(props) {

    /**
     * Define Props
     */
    const { classes, measureId } = props;
    const dispatch = useDispatch();

    /**
     * State variables
     */
    const [searchData, setSearchData] = useState({});
    const { measureLogs, isLoading, measureLogFilter } = useSelector((state) => state.version);
    const { id: assetId } = useSelector((state) => state.asset);

    /**
     * Use effect to load failed rows for the given measure
     */
    useEffect(() => {
        if (measureId && measureLogs?.length <= 0) {
            const requestParams = {
                id: assetId,
                measureId: measureId,
                ...measureLogFilter
            };
            dispatch(getMeasureLogsRequest(requestParams));
        }
        return () => {
            dispatch(clearMeasureLogs());
        };
    }, [measureId]);


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        sortBy = (sortBy === "created_date") ? "date" : sortBy;
        const requestData = {
            sortBy, orderBy
        };
        dispatch(sortMeasureLogs(requestData));
    };

    /**
     * Prepare Result Data
     * @param {*} data
     * @returns
     */
    const prepareFilterResult = (data, searchFilters, measureLogFilter) => {
        const { sortBy, orderBy } = measureLogFilter;
        let filterData = JSON.parse(JSON.stringify([...data]));
        filterData = filterData.map((item) => {
            return {
                ...item,
                date: item.date ? moment(item.date) : null
            };
        });
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }

        filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        return filterData;
    };

    /**
     * Prepare Data using UseMemo
     */
    const filteredData = useMemo(() => prepareFilterResult(measureLogs, searchData, measureLogFilter), [measureLogs, searchData, measureLogFilter]);

    return (
        <Grid item xs={12} className={classes.FailedRowsContainer}>
            <TableComponent
                styleType="striped"
                headers={appConstants.table.measureLogsHeader}
                data={filteredData || []}
                options={appConstants.tableOptions.measureLogs}
                sortBy={measureLogFilter?.sortBy}
                orderBy={measureLogFilter?.orderBy}
                onHandleSearchEvent={onHandleSearchEvent}
                onClickSorting={onClickSorting}
                searchData={{ ...searchData }}
                NoResultText="No Results Found"
                height="calc(100vh - 300px)"
                isLoading={isLoading}
                exportParams={
                    {
                        fileName: "measure_logs.csv",
                        titleCaseHeader: true
                    }
                }
                stickyHeader
            />
        </Grid>
    );
}

// default props
MeasureLogs.defaultProps = {
    classes: {},
    measureId: ""
};

// prop types
MeasureLogs.propTypes = {
    classes: PropTypes.object,
    measureId: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(MeasureLogs);