import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import DomainCard from '../../components/card/index.jsx';
import { AssetCardComponent, LoaderComponent, NoResultComponent } from '../../../../components/index.js';
import StandaloneMeasure from './components/measure/index.jsx';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';


// Import Actions
import { getDomainsAssetsRequest, getSubDomainRequest, getDomainTermsRequest } from '../../../../redux/reducer/domainsReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';


function DomainSummary(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const { id: domain_id } = useParams();
    const dispatch = useDispatch();

    /**
     * Redux Store get Domain Asset,Domains
     */
    const { assets, assetsLoading, subDomains, domainLoading, terms, termsLoading } = useSelector((state) => state.domains);

    /**
     * Handle Naviagation
     * @param {*} selectedItem
     */
    const handleNavigation = (selectedItem) => {
        dispatch(navigate({ path: 'assets.root', state: {}, params: [selectedItem.source_id] }));
    };

    /**
     * Call Default Data
     */
    useEffect(() => {
        if (domain_id) {
            dispatch(getDomainsAssetsRequest(domain_id));
            dispatch(getSubDomainRequest(domain_id));
            dispatch(getDomainTermsRequest(domain_id));
        }
    }, [domain_id]);

    return (
        <Grid container className={classes.tabsRootContainer}>
            {/* {
                subDomains.length && */}
            <Grid item xs={12} className="cardsSection">
                <Typography variant="h6" className="mb7" align="left">
                    {`Sub Domains (${subDomains.length})`}
                </Typography>

                <Grid container spacing={3} className="cardsSection">
                    {
                        domainLoading ?
                            <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={4} cardHeight="175px" loaderType="card" height="auto" />
                            :
                            subDomains.map((item, index) =>
                                <Grid item key={`subDomain_${index}`} xl={3} sm={4}>
                                    <DomainCard
                                        data={item}
                                        type="domains"
                                    />
                                </Grid>
                            )
                    }
                    {
                        !domainLoading && !subDomains.length &&
                        <NoResultComponent title={"No Sub Domains Found"} />
                    }

                </Grid>
            </Grid>
            <Grid item xs={12} className="pt-3 cardsSection">
                <Typography variant="h6" className="mb7" align="left">
                    {`Assets (${assets.length})`}
                </Typography>
                <Grid container spacing={3}>
                    {
                        assetsLoading ?
                            <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={4} cardHeight="175px" loaderType="card" height="auto" />
                            :
                            assets.map((catalog, index) =>
                                <Grid item xs={4} xl={2} key={index}>
                                    <AssetCardComponent data={catalog} handleNavigation={(selectedItem) => handleNavigation(selectedItem)} />
                                </Grid>
                            )
                    }
                    {
                        !assetsLoading && !assets.length &&
                        <NoResultComponent title={"No Assets Found"} />
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} className="pt-3 cardsSection">
                <Typography variant="h6" className="mb7" align="left">
                    {`Terms (${terms.length})`}
                </Typography>
                <Grid container spacing={3}>
                    {
                        termsLoading ?
                            <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={4} cardHeight="175px" loaderType="card" height="auto" />
                            :
                            terms.map((term, index) =>
                                <Grid item xs={3} xl={3} sm={4} key={index}>
                                    <DomainCard data={term} type="terms" />
                                </Grid>
                            )
                    }
                    {
                        !termsLoading && !terms.length &&
                        <NoResultComponent title="No Terms Found" />
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} className="pt-3">
                <StandaloneMeasure />
            </Grid>

        </Grid>
    );
}

// default props
DomainSummary.defaultProps = {
    classes: {}
};

// prop types
DomainSummary.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DomainSummary);