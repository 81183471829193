const style = (theme) => ({
    widgetItemDetail: {
    },
    widgetListIcon: {
        marginRight: '15px !important'
    },
    progressBar: {
        minWidth: '100px'
    },
    widgetItem: {
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        padding: '12px 0',
        '& .textValue': {
            fontSize: '11px !important'
        }
    },
    ratedContainer: {
        overflowY: "auto"
    }
});

export default style;