import React, { useMemo, useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Images
import { DeleteIcon } from '../../assets/svg/index.js';

// Import Components
import { AutoCompleteComponent, ValueEditorComponent } from '../index.js';

// Import Constant
import appConstants from '../../constants/appConstants.js';

// Import Helpers
import { validatePattern } from '../../helpers/appHelpers.js';

function RuleComponent(props) {
    /**
     * Define Props
     */
    const { classes, data, attributes, isDeleteEnabled, operators, deleteRule, editRule, semanticRule, semanticDatatype, propertyPermission, isDisabled, isPattern } = props;

    /**
     * Handle On Change Event
     * @param {*} type
     * @param {*} value
     * @param {*} id
     */
    const handleAttributeChange = (sAttribute) => {
        if (sAttribute) {
            const sValue = {
                label: sAttribute.name,
                id: sAttribute.id,
                has_numeric_values: sAttribute.has_numeric_values || false,
                datatype: sAttribute?.derived_type?.toLowerCase() ?? 'text'
            };
            editRule('attribute', sValue, data?.id ?? null);
        }
    };


    /**
     * Handle Condition Change Event
     * @param {*} type
     * @param {*} value
     * @param {*} id
     */
    const handleConditionChange = (sCondition) => {
        if (sCondition) {
            const { id, label, values } = sCondition || {};
            const sValue = {
                id, label
            };
            editRule('operator', sValue, data?.id ?? null, null, values ?? ['']);
        }
    };

    /**
     * Filter Operator Based On DataType
     */
    const filterOperators = (operators, dataObj) => {
        let datatype = dataObj?.attribute?.datatype ?? 'Text';
        const hasNumerics = Boolean(dataObj?.attribute?.has_numeric_values);
        if (semanticRule && semanticDatatype) {
            datatype = semanticDatatype;
        }

        if (isPattern) {
            datatype = 'pattern';
        }

        datatype = datatype ?? 'Text';
        const filteredOperators = operators.filter((item) => item.types.indexOf(datatype.toLowerCase()) >= 0 || (hasNumerics && item.types.indexOf("text_numerics") >= 0));
        return filteredOperators;
    };
    const operatorsData = useMemo(() => filterOperators(operators, data), [operators, data]);

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        if (isPattern) {
            ValidatorForm.addValidationRule('regexValid', (value) => {
                return validatePattern(value);
            });
        }
    }, []);

    return (
        <Grid
            key={data?.id ?? 0}
            container
            justifyContent={'space-between'}
            className={classes.ruleContainer}
        >
            <Grid item className={`dflex alignCenter`}>
                {
                    !semanticRule && !isPattern &&
                    <Grid item className={classes.ruleField} style={{ width: '250px' }}>
                        <AutoCompleteComponent
                            freeSolo={false}
                            name="attribute"
                            variant="outlined"
                            selectedValue={data?.attribute?.label ?? null}
                            data={attributes || []}
                            customOption
                            labelKey={"name"}
                            renderType={'queryBuilderAttributes'}
                            onChange={(event, newValue) => handleAttributeChange(newValue)}
                            placeholder="Select Attribute"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={!propertyPermission?.is_edit || isDisabled}
                        />
                    </Grid>
                }
                <Grid item className={classes.ruleField} style={{ width: '250px' }}>
                    <AutoCompleteComponent
                        freeSolo={false}
                        name="operator"
                        variant="outlined"
                        selectedValue={data?.operator?.label ?? null}
                        data={operatorsData || []}
                        onChange={(event, newValue) => handleConditionChange(newValue)}
                        placeholder="Select Operator"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        disabled={!propertyPermission?.is_edit || isDisabled}
                    />
                </Grid>
                {
                    data.values && data.values.map((value, index) => (
                        <Grid item className={classes.ruleField} key={`value-grid-${index}`}>
                            <ValueEditorComponent
                                variant="outlined"
                                placeholder="Enter a Value"
                                value={value || ''}
                                type={data?.attribute?.datatype ?? 'text'}
                                onChange={(event) => editRule('values', event?.target?.value ?? event, data?.id ?? null, index)}
                                name={`field_Value_${index}`}
                                validators={isPattern ? ['regexValid'] : ['required']}
                                errorMessages={isPattern ? [appConstants.errorMessages.patternNotValid] : ['this field is required']}
                                disabled={isDisabled}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            {
                isDeleteEnabled && propertyPermission?.is_edit &&
                <IconButton onClick={() => deleteRule(data?.id ?? null)} disableRipple disabled={!propertyPermission?.is_edit || isDisabled}>
                    <DeleteIcon />
                </IconButton>
            }
        </Grid>
    );
}

/**
 * Define Component Props
 */
RuleComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    attributes: PropTypes.array,
    isDeleteEnabled: PropTypes.bool,
    operators: PropTypes.array,
    deleteRule: PropTypes.func,
    editRule: PropTypes.func,
    semanticRule: PropTypes.bool,
    isDisabled: PropTypes.bool,
    semanticDatatype: PropTypes.string,
    isPattern: PropTypes.bool,
    propertyPermission: PropTypes.object
};

/**
 * Set Default Values
 */
RuleComponent.defaultProps = {
    classes: {},
    data: {},
    attributes: [],
    isDeleteEnabled: true,
    operators: [],
    deleteRule: () => { },
    editRule: () => { },
    semanticRule: false,
    isDisabled: false,
    semanticDatatype: "Text",
    isPattern: false,
    propertyPermission: {}
};

export default RuleComponent;