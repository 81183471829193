// Default Imports
import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from "echarts-for-react";

// Import Styles
import style from "./style.jsx";

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';


const DynamicBarChartComponent = (props) => {

    /**
     * Define Props
     */
    const { chartData, color, showXAxis, showYAxis, xAxisType, yAxisType, type, showLegends, chartProperties, onCallback } = props;

    /**
     * Define Ref
     */
    const myChart = useRef();
    const series = type === "stacked_bar" ?
        chartData.data.map((series, index) => {
            return {
                type: 'bar',
                name: series.name,
                stack: 'total',
                color: color[index],
                data: series.series || [],
                showBackground: chartProperties.showBackground || false,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.1)'
                },
                barMaxWidth: chartProperties.barWidth || 'auto'
            };
        })
        : [
            {
                type: 'bar',
                color: color,
                data: chartData.data,
                showBackground: chartProperties.showBackground || false,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.1)'
                },
                barMaxWidth: chartProperties.barWidth || 'auto'
            }
        ];

    /**
     * Define Chart Options
     */
    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        grid: { containLabel: true, top: 10, bottom: showLegends ? 50 : 20 },
        xAxis: {
            type: xAxisType || "category",
            data: chartData.xAxis || [],
            show: showXAxis,
            nameLocation: "center",
            nameGap: xAxisType === "category" ? 95 : 40,
            name: chartData.xAxisLabel || "",
            axisLabel: {
                fontSize: chartProperties.xRotate ? 13 : 14,
                rotate: chartProperties.xRotate || 0,
                width: 120,
                overflow: 'truncate'
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: palette.greyshades.lightgrey
                }
            }
        },
        yAxis: {
            type: yAxisType || "value",
            data: chartData.yAxis || [],
            show: showYAxis,
            nameLocation: "center",
            nameGap: yAxisType === "value" ? 50 : 185,
            name: chartData.yAxisLabel || "",
            axisLabel: {
                fontSize: 14,
                width: 150,
                overflow: 'truncate'
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: palette.greyshades.lightgrey
                }
            }
        },
        series: series,
        legend: {
            show: showLegends,
            type: "scroll",
            top: "bottom",
            padding: 0,
            itemGap: 60,
            itemWidth: 20,
            itemHeight: 8,
            textStyle: {
                fontSize: 14,
                fontFamily: palette.headers.body1.fontFamily,
                color: appConstants.chartColors.labelColor
            }
        },
        barWidth: chartProperties.barWidth || 'auto',
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params) => {
                return type === "stacked_bar" ? `<span class="capitalize">${params.name}</span> <br /> ${params.marker} <span class="capitalize">${params.seriesName} : ${params.value}</span>` :
                    `${params.marker} <span class="capitalize">${params.name} : ${params.value}</span>`;
            }
        }
    };

    /**
     * Point Click Event
     * @param {*} params
     */
    const onClick = (params) => {
        onCallback(params);
    };

    /**
     * Chart Events
     */
    const onEvents = onCallback ? {
        click: onClick
    } : {};

    return (
        <ReactEcharts ref={myChart} option={option} opts={{ renderer: "svg" }} style={{ height: '100%' }} notMerge onEvents={onEvents} />
    );
};


/**
 * Define Prop Types
 */
DynamicBarChartComponent.propTypes = {
    chartData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    showYAxis: PropTypes.bool,
    showXAxis: PropTypes.bool,
    yAxisType: PropTypes.string,
    xAxisType: PropTypes.string,
    type: PropTypes.string,
    showLegends: PropTypes.bool,
    chartProperties: PropTypes.object,
    onCallback: PropTypes.func
};

/**
 * Set Default Values
 */
DynamicBarChartComponent.defaultProps = {
    chartData: {},
    color: "",
    showYAxis: false,
    showXAxis: false,
    yAxisType: "value",
    xAxisType: "category",
    type: "bar",
    showLegends: true,
    chartProperties: {},
    onCallback: () => { }
};

export default withStyles((theme) => ({
    ...style(theme)
}))(DynamicBarChartComponent);