import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent, SelectComponent, SwitchComponent } from '../../../components/index.js';

//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import TableauIcon from '../../../assets/img/connectors/tableau.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';

// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState
} from '../../../redux/reducer/connectionReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';


function Tableau(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'tableau',
        description: '',
        host: '',
        authentication_type: appConstants.labels.connector.tableau.authentication_type_dd[0],
        site: '',
        user: '',
        password: '',
        personal_access_token_name: '',
        personal_access_token_key: '',
        ssl: true
    });
    const {
        id,
        type,
        name,
        description,
        host,
        site,
        authentication_type,
        user,
        password,
        personal_access_token_name,
        personal_access_token_key,
        ssl
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, saveIsLoading } = useSelector((state) => state.connection);

    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.user = encryptDecrypt.decrypt(credentialDetails?.user ?? "");
            credentialDetails.password = encryptDecrypt.decrypt(credentialDetails?.password ?? "");
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
        if (property !== "name" && property !== "description") {
            setIsAuthenticationChanged(true);
        }
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                host,
                site,
                authentication_type,
                user: encryptDecrypt.encrypt(user),
                password: encryptDecrypt.encrypt(password),
                personal_access_token_name,
                personal_access_token_key,
                ssl
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(false);
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };


    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={TableauIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.tableau.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.tableau
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={8} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_host"
                                name={'host'}
                                value={host}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.host}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.host
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <SelectComponent
                                id="form_text_authentication_type"
                                name={'authentication_type'}
                                fullWidth={false}
                                label={appConstants.labels.connector.authentication_type}
                                variant="standard"
                                value={authentication_type || ''}
                                onSelectChange={(value) => handleTextBoxChanges('authentication_type', value)}
                                list={appConstants.labels.connector.tableau.authentication_type_dd}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.authentication_type
                                        )
                                    ]
                                }
                            />
                        </Grid>
                    </Grid>
                    {
                        appConstants.labels.connector.tableau.authentication_type_dd[0] === authentication_type &&
                        <Grid container spacing={5} className="mt0">
                            <Grid item xs={4}>
                                <MemoTextBoxComponent
                                    id="form_text_user"
                                    name={'user'}
                                    value={user}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.connector.user}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.connector.user
                                            )
                                        ]
                                    }
                                    onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                    inputProps={{ maxLength: 100 }}
                                    valOnChange
                                />
                            </Grid>
                            <Grid item xs={5} className="pl-10">
                                <PasswordInputComponent
                                    id="form_text_password"
                                    name={'password'}
                                    value={password}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.authentication.password}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['passwordRequired']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.authentication
                                                    .password
                                            )
                                        ]
                                    }
                                    onChange={(event, value) => handleTextBoxChanges(event.target.name, value)}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                        </Grid>
                    }
                    {
                        appConstants.labels.connector.tableau.authentication_type_dd[1] === authentication_type &&
                        <Grid container spacing={5} className="mt0">
                            <Grid item xs={4}>
                                <MemoTextBoxComponent
                                    id="form_text_personal_access_token_name"
                                    name={'personal_access_token_name'}
                                    value={personal_access_token_name}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.connector.personal_access_token_name}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.connector.personal_access_token_name
                                            )
                                        ]
                                    }
                                    onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                    inputProps={{ maxLength: 100 }}
                                    valOnChange
                                />
                            </Grid>
                            <Grid item xs={5} className="pl-10">
                                <MemoTextBoxComponent
                                    id="form_text_personal_access_token_key"
                                    name={'personal_access_token_key'}
                                    value={personal_access_token_key}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            {appConstants.labels.connector.personal_access_token_key}
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    errorMessages={
                                        [
                                            setRequiredErrorMessage(
                                                appConstants.labels.connector
                                                    .personal_access_token_key
                                            )
                                        ]
                                    }
                                    onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                    inputProps={{ maxLength: 100 }}
                                    valOnChange
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={5} className="mt0">
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_site"
                                name={'site'}
                                value={site}
                                fullWidth
                                variant="standard"
                                label={appConstants.labels.connector.site}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10 mt-3">
                            <SwitchComponent
                                size="small"
                                handleChange={(value) => handleTextBoxChanges('ssl', value)}
                                checked={ssl || false}
                                label={appConstants.labels.connector.ssl}
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Tableau.defaultProps = {
    classes: {}
};

// prop types
Tableau.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Tableau);