import axios from "axios";
import fileDownload from 'js-file-download';

// Import helpers
import { getToken, removeToken, removeCookie, extractResponse } from "./appHelpers";

// ENV Variables
const { REACT_APP_API_ENDPOINT } = process.env;

// Import Constants
import urlConstants from "../constants/urlConstants";


/**
 * Prepares headers for the request
 */
const getHeaders = (isFormData = false) => {
    const token = getToken();
    const headers = {};
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (isFormData) {
        headers["Content-Type"] = "multipart/form-data";
    } else {
        headers["Content-Type"] = "application/json";
    }
    return headers;
};

/**
 * SSO request
 */
export const ssoRequestURL = () => {
    const url = window.location.hostname;
    const parts = url.split('.');
    const sub = parts[0];
    return `${REACT_APP_API_ENDPOINT}/${urlConstants.auth.sso}${sub}/sso`;
};

/**
 * SSO request URL
 */
export const ssoRequestURL_Format = () => {
    const url = window.location.hostname;
    const parts = url.split('.');
    const sub = parts[0];
    return `${REACT_APP_API_ENDPOINT}/${urlConstants.auth.sso}${sub}/`;
};


/**
 * api make request
 */
const makeRequest = async (endpoint, verb, data, isFormData = false, responseType = "json", cancellationToken = null, exportResponse = false, exportFileName) => {
    const requestOptions = {
        method: verb,
        url: endpoint,
        headers: getHeaders(isFormData),
        responseType: responseType,
        data,
        signal: cancellationToken?.signal
    };
    return axios(requestOptions)
        .then((response) => {
            if (exportResponse) {
                fileDownload(response.data, exportFileName);
            }
            return extractResponse(response);
        })
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 503) && !endpoint.includes('/login')) {
                removeCookie();
                removeToken();
            }
            throw error;
        });
};

/**
 * GET request
 */
export const getRequest = async (endpoint, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "GET", {}, null, null, cancellationToken);
};

/**
 * PUT requests
 */
export const putRequest = async (endpoint, body, isFormData, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "PUT", body, isFormData, null, cancellationToken);
};

/**
 * POST request
 */
export const postRequest = async (endpoint, body, isFormData, responseType, cancellationToken, exportResponse, exportFileName) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "POST", body, isFormData, responseType, cancellationToken, exportResponse, exportFileName);
};

/**
 * DELETE request
 */
export const deleteRequest = async (endpoint, body, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "DELETE", body, null, null, cancellationToken);
};