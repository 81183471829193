const style = (theme) => ({
    overAllContainer: {
        /*
         * background: '#fff',
         * margin: '-24px',
         * width: 'calc(100% + 48px) !important',
         * height: 'calc(100vh - 340px) !important'
         */
    },
    arrowBtn: {
        position: 'absolute !important',
        color: '#fff !important',
        height: '36px',
        width: '20px',
        borderRadius: '4px !important',
        transform: 'translate(-50%, 0%)',
        top: '0%',
        right: '-40px',
        '& svg': {
            fontSize: '18px'
        },
        '&.open': {
            backgroundColor: `${theme.palette.greyshades.darkgrey} !important`
        },
        '&.close': {
            backgroundColor: `${theme.palette.secondary.main} !important`
        }
    }
});
export default style;