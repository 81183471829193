import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';


//  Import Styles
import LineageStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Component
import { LineageComponent, LoaderComponent, NoResultComponent } from '../../../components/index.js';
import RightPanelInfo from './rightPanleInfo/index.jsx';

// Import Actions
import { getAllAssetsRequest, getAssetLineageRequest, updateLineageShowHideColoumns, updateAssetReportRequest, removeAssetReportRequest } from '../../../redux/reducer/assetReducer';
import { navigate } from '../../../redux/reducer/navigationReducer';

// Import Constants
import appConstants from '../../../constants/appConstants.js';

function Lineage(props) {

    const { classes } = props;

    /**
     * Define Props
     */
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [infoBar, openInfoBar] = useState(false);
    const [resetLineageCanvas, setResetLineageCanvas] = useState(false);

    /**
     * Redux Store Data
     */
    const { assets, lineage, isLoading } = useSelector(({ asset }) => asset);
    const { detail: versionDetail } = useSelector((state) => state.version);

    /**
     * Get Mapped Asset
     */
    useEffect(() => {
        if (versionDetail.asset) {
            dispatch(getAssetLineageRequest({ asset_id: versionDetail.asset }));
        }
    }, [dispatch, versionDetail.asset]);

    /**
     * Get Asset and Attributes
     */
    useEffect(() => {
        if (!assets.length) {
            dispatch(getAllAssetsRequest());
        }
    }, [dispatch, assets]);

    /**
     * Map Report to Asset
     * @param {*} value
     */
    const assetMappingOnSelect = (value, selectedReport) => {
        if (value) {
            dispatch(updateAssetReportRequest(
                {
                    version: versionDetail.id,
                    asset: versionDetail.asset,
                    report_asset: value.id,
                    report_id: selectedReport?.id
                }
            )
            );
        }
    };

    /**
     * Remove Mapping
     * @param {*} value
     */
    const removeLink = (value) => {
        if (value?.mapped_id) {
            dispatch(removeAssetReportRequest({
                mapped_id: value.mapped_id,
                asset: versionDetail.asset
            }));
        }
    };

    /**
     * Handle Navigation
     * @param {*} value
     */
    const handleNavigation = (value) => {
        if (value && (value.asset_id || value.is_asset)) {
            const type = value?.type.toLowerCase();
            if (type === 'table' || type === 'view' || type === 'pipeline' || type === 'base table') {
                dispatch(navigate({ path: 'assets.root', state: {}, params: [value.asset_id] }));
            } else {
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [value.asset_id, value.mapped_attribute_id] }));
            }
        }
    };

    /**
     * Handle Lineage Info Box
     */
    const handleInfoBar = () => {
        openInfoBar(!infoBar);
        setTimeout(() => {
            setResetLineageCanvas(!resetLineageCanvas);
        }, 100);
    };

    /**
     * handle Show / Hide Columns
     * @param {*} value
     */
    const handleShowColumns = (value) => {
        if (value.id) {
            dispatch(updateLineageShowHideColoumns(value.id));
        }
    };

    /**
     * Get Impacted Assets
     * @returns
     */
    const getImpactedAssets = () => {
        const lineage_tables = lineage?.tables ?? [];
        const lineage_impacts = lineage?.impacts ?? [];
        let assets_impacts = [];
        if (lineage_impacts && lineage_impacts.length) {
            assets_impacts = lineage_impacts.map((id) => lineage_tables.find((item) => item.id === id));
        }
        return assets_impacts;
    };

    const impacts = useMemo(() => getImpactedAssets(lineage), [lineage]);


    return (
        <Grid container className={classes.overAllContainer} wrap="nowrap">
            {
                !isLoading && lineage && lineage?.tables?.length > 0 &&
                <LineageComponent
                    config={lineage}
                    assets={assets}
                    allowDQAssetMapping
                    assetMappingOnSelect={assetMappingOnSelect}
                    removeLink={(value) => removeLink(value)}
                    handleNavigation={(value) => handleNavigation(value)}
                    resetLineageCanvas={resetLineageCanvas}
                    handleShowColumns={(value) => handleShowColumns(value)}
                    assetType={versionDetail?.type ?? ''}
                />
            }
            {isLoading && <LoaderComponent loaderType="circular" height="calc(100vh - 360px) !important" />}
            {!isLoading && (!lineage.tables || !lineage?.tables?.length > 0) && <NoResultComponent title="Lineage not yet prepared." height="calc(100vh - 360px) !important" />}
            {
                versionDetail?.type === appConstants.connectionTypes.dbt.value &&
                <Grid className="relative">
                    <IconButton className={`${classes.arrowBtn} ${infoBar ? "open" : "close"}`} onClick={handleInfoBar}>
                        {!infoBar ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
                    </IconButton>
                    {
                        infoBar &&
                        <RightPanelInfo
                            data={versionDetail?.properties ?? {}}
                            models_assets={versionDetail?.models_assets ?? []}
                            impacts={impacts || []}
                            handleNavigation={(value) => handleNavigation(value)}
                        />
                    }
                </Grid>
            }
        </Grid>
    );
}

// default props
Lineage.defaultProps = {
    classes: {}
};

Lineage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LineageStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Lineage);