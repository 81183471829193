import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent, PasswordInputComponent } from '../../../components/index.js';
import CollibraMapping from './mapping.jsx';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import CollibraIcon from '../../../assets/img/apps/collibra.png';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import { Crypto, checkPermission } from '../../../helpers/index.js';

// Import Actions
import { getConfigurationRequest, createConfigrationRequest, updateConfigurationRequest, getCollibraAttributeTypesRequest } from '../../../redux/reducer/integrationReducer';
import featureConstants from '../../../constants/featureConstants.js';

function Collibra(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const defaultConfiguration = {
        url: "",
        username: "",
        password: "",
        domain: "",
        community: "",
        type_id: "",
        is_validate: false,
        mapping: []
    };
    const encryptDecrypt = new Crypto();

    /**
     * Define State
     */
    const [checkChanges, setCheckChanges] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [formData, setFormData] = useState({ ...defaultConfiguration });

    /**
     * Redux Store
     */
    const { configuration, saveLoading, collibraAttributeTypes } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };


    /**
     * Get attribute Types
     */
    const getAttributeTypes = () => {
        const config = {
            ...formData,
            username: encryptDecrypt.encrypt(formData.username),
            password: encryptDecrypt.encrypt(formData.password)
        };
        dispatch(getCollibraAttributeTypesRequest(config));
    };

    /**
     * On Save
     */
    const onSave = () => {
        if (!formData.is_validate || !channelId) {
            getAttributeTypes();
            setFormSubmit(true);
        } else {
            const config = {
                ...formData,
                username: encryptDecrypt.encrypt(formData.username),
                password: encryptDecrypt.encrypt(formData.password)
            };
            const requestParams = {
                config,
                channel: channel.id,
                id: channelId,
                type: "api",
                channelName: "collibra",
                has_mapping: true
            };
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Default Mapped Columns
     * @returns
     */
    const mapDefaultColumns = () => {
        const attributes = [];
        const appDefaultColumns = appConstants.collibraMapColumns.filter((column) => column.isDefault);
        for (const attribute of appDefaultColumns) {
            const collibraColumn = collibraAttributeTypes.find((column) => column.name === attribute.matchColumn);
            if (collibraColumn) {
                attributes.push({
                    dq_column: attribute.name,
                    collibra_column: collibraColumn.name,
                    collibra_type_id: collibraColumn.id,
                    catalog_key: attribute.id,
                    type: attribute.type,
                    priority: attribute.priority
                });
            }
        }
        return attributes;
    };

    /**
     * Validate Configuration and Save
     */
    useEffect(() => {
        if (collibraAttributeTypes.length && !formData.is_validate && formSubmit) {
            const updateConfiguration = { ...formData };
            if (!updateConfiguration.mapping.length) {
                const mappingColumns = mapDefaultColumns();
                updateConfiguration.mapping = [...mappingColumns];
            }
            updateConfiguration.is_validate = true;
            // dispatch(setConfiguration({ ...updateConfiguration }));
            setFormData({ ...updateConfiguration });
            const config = {
                ...updateConfiguration,
                username: encryptDecrypt.encrypt(updateConfiguration.username),
                password: encryptDecrypt.encrypt(updateConfiguration.password)
            };
            const requestParams = {
                config: config,
                channel: channel.id,
                id: channelId,
                type: "api",
                channelName: "collibra",
                has_mapping: true
            };
            if (!channelId) {
                dispatch(createConfigrationRequest(requestParams));
            } else {
                dispatch(updateConfigurationRequest(requestParams));
            }
            setFormSubmit(false);
        }
    }, [collibraAttributeTypes, formSubmit]);

    /**
     * Call Collibra Attribute Types
     */
    useEffect(() => {
        if (formData.is_validate && !collibraAttributeTypes.length) {
            getAttributeTypes();
        }
    }, [formData.is_validate]);

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);


    /**
     * Set FormData
     */
    useEffect(() => {
        if (configuration && channelId) {
            const config = { ...configuration };
            config.username = encryptDecrypt.decrypt(config?.username ?? "");
            config.password = encryptDecrypt.decrypt(config?.password ?? "");
            setFormData({ ...config });
        }
    }, [configuration]);

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={CollibraIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.collibra.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.collibra.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                name="url"
                                value={formData.url || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        API Endpoint
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`API Endpoint ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.username || ""}
                                onChange={(event) => onChange("username", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Username
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Username ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <PasswordInputComponent
                                value={formData.password || ""}
                                onChange={(event) => onChange("password", event.target.value)}
                                type="password"
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Password
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['passwordRequired']}
                                errorMessages={[`password ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.domain || ""}
                                onChange={(event) => onChange("domain", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={"Domain ID"}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.community || ""}
                                onChange={(event) => onChange("community", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={"Community ID"}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                value={formData.type_id || ""}
                                onChange={(event) => onChange("type_id", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={"Type ID"}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt-3 mb-2">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigate(-1)}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            <LoaderButtonComponent
                                disabled={!checkChanges || saveLoading}
                                size="small"
                                type="submit"
                                isLoading={saveLoading}
                            >
                                {appConstants.labels.connector.save}
                            </LoaderButtonComponent>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                {
                    formData.is_validate &&
                    <CollibraMapping configuration={formData} onSave={() => onSave()} />
                }
            </Grid>
        </Grid>
    );
}

// default props
Collibra.defaultProps = {
    classes: {}
};

// prop types
Collibra.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Collibra);