/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Helpers
import { createLinkedComment, convertHtml } from '../../../../../helpers/appHelpers';

// Import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';
import { LatestCommentsIcon } from '../../../../../assets/svg/index.js';

function LatestComments(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Redirect
     * @param {*} item
     */
    const redirect = (item, type = "comment") => {
        if (type === "comment") {
            dispatch(navigate({ path: 'assets.conversationDetail', state: {}, params: [item.asset_id, item.id] }));
        } else {
            dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
        }

    };

    return (
        <Grid item xs={12} className={classes.latestComments}>
            {
                data && data.map((item, index) =>
                    <Grid key={`${index}_latest_comment`} container className={`${classes.widgetItem}`} alignItems={'center'} wrap="nowrap">
                        <Grid item className={classes.widgetListIcon}>
                            <LatestCommentsIcon />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid onClick={() => redirect(item, "comment")} className="commentText pointer" dangerouslySetInnerHTML={{ __html: createLinkedComment(convertHtml(item.comment)) }} />
                            <Grid item className="dflex alignCenter spaceBetween">
                                <Grid className="dflex alignCenter spaceBetween">
                                    <Typography onClick={() => redirect(item, "asset")} variant="body2" className={classnames(classes.textSecondary, "pointer")}>
                                        {item.asset_name || ""}
                                    </Typography>
                                    <Typography variant="body2" className="pl5 pr5">
                                        |
                                    </Typography>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {item.user}
                                    </Typography>
                                </Grid>
                                <Typography variant="body2" className={classes.textSecondary}>
                                    {moment(item.created_date).format('MMM DD YYYY hh:mm A')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}

// default props
LatestComments.defaultProps = {
    classes: {},
    data: []
};

// prop types
LatestComments.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(LatestComments, areEqual));