// Import API Helper
import { postRequest, getRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Search Result
 */
async function search(params, cancellationToken) {
    const response = await postRequest(urlConstants.catalog.url, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Statistics Values
 */
async function stats() {
    const response = await getRequest(urlConstants.catalog.url + urlConstants.catalog.stats);
    return response;
}

/**
 * Get Suggestions
 */
async function suggestions() {
    const response = await getRequest(urlConstants.catalog.url + urlConstants.catalog.suggestions);
    return response;
}

/**
 * Track
 */
async function track(params) {
    const response = await postRequest(urlConstants.catalog.url + urlConstants.catalog.track, params);
    return response;
}


/**
 * Export All Service Functions
 */
export const catalogService = {
    search, stats, suggestions, track
};