import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  applications: [],
  searchableApplicationsList: [],
  assets: [],
  detail: {},
  assetsLoading: false,
  quality: [],
  qualityIsLoading: false,
  searchableApplicationsListFilter: [],
  applicationsFilter: []
};

const applicationSlice = createSlice({
  name: 'applications',
  initialState: { ...initialState },
  reducers: {
    getApplicationsRequest(state) {
      state.isLoading = true;
    },
    getApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload || [];
      state.searchableApplicationsList = state.applications.map((item) => {
        return {
          name: item.name,
          id: item.id,
          color: item.color,
          count: item?.count ?? 0,
          measure_count: item?.measure_count ?? 0
        };
      });
    },
    getApplicationsFailure() { },
    getApplicationByIdRequest(state) {
      state.isLoading = true;
    },
    getApplicationByIdSuccess(state, action) {
      const { data } = action.payload;
      state.detail = data || {};
      state.isLoading = false;
    },
    getApplicationByIdFailure(state) {
      state.isLoading = false;
    },
    createApplicationRequest() { },
    createApplicationSuccess(state, action) {
      const { data } = action.payload;
      state.applications = [...state.applications, data];
      state.searchableApplicationsList = state.applications.map((item) => {
        return {
          ...item,
          name: item.name,
          id: item.id,
          color: item.color
        };
      });
    },
    createApplicationFailure() { },
    updateApplicationRequest() { },
    updateApplicationSuccess(state, action) {
      const data = action.payload;
      const index = state.applications.findIndex((applicaiton) => applicaiton.id === data.id);
      state.applications[index] = data;
      state.searchableApplicationsList = state.applications.map((item) => {
        return {
          ...item,
          name: item.name,
          id: item.id,
          color: item.color
        };
      });
    },
    updateApplicationFailure() { },
    deleteApplicationRequest() { },
    deleteApplicationSuccess(state, action) {
      state.applications = state.applications.filter((applicaiton) => applicaiton.id !== action.payload);
      state.searchableApplicationsList = state.applications.map((item) => {
        return {
          ...item,
          name: item.name,
          id: item.id,
          color: item.color
        };
      });
    },
    deleteApplicationFailure() { },
    getApplicationAssetsRequest(state) {
      state.assetsLoading = true;
    },
    getApplicationAssetsSuccess(state, action) {
      const { data } = action.payload;
      state.assets = data || [];
      state.assetsLoading = false;
    },
    getApplicationAssetsFailure(state) {
      state.assetsLoading = false;
    },
    getApplicationQualityRequest(state, action) {
      if (action.payload.clear) {
        state.qualityIsLoading = true;
      }
    },
    getApplicationQualitySucess(state, action) {
      const { clear, accordian } = action.payload;
      const data = action.payload.data || [];
      if (clear) {
        state.quality = data || [];
      } else {
        if (accordian && Object.keys(accordian).length) {
          const { type, asset_id, attribute_id } = accordian;
          const index = state.quality.findIndex((data) => data.id === asset_id);
          if (type === "asset") {
            state.quality[index].isLoading = false;
            state.quality[index].children = data;
          } else {
            const attributes = state.quality[index].children || [];
            const attributeIndex = attributes.findIndex((data) => data.id === attribute_id);
            state.quality[index].children[attributeIndex].isLoading = false;
            state.quality[index].children[attributeIndex].children = data;
          }
        }
      }
      state.qualityIsLoading = false;
    },
    getApplicationQualityFailure(state) {
      state.qualityIsLoading = false;
    },
    collapseAccordian(state, action) {
      const { type, asset_id, attribute_id, value } = action.payload;
      const index = state.quality.findIndex((data) => data.id === asset_id);
      if (type === "asset") {
        if (value && !state.quality[index]?.children?.length) {
          state.quality[index].isLoading = true;
        }
        state.quality[index].isOpen = value;
      } else {
        const attributes = state.quality[index].children || [];
        const attributeIndex = attributes.findIndex((data) => data.id === attribute_id);
        if (value && !state.quality[index]?.children[attributeIndex]?.children?.length) {
          state.quality[index].children[attributeIndex].isLoading = true;
        }
        state.quality[index].children[attributeIndex].isOpen = value;
      }
    },
    clearApplicationDetails(state) {
      state.detail = {};
      state.assets = [];
      state.quality = [];
    },
    getApplicationsFilterRequest(state) {
      state.isLoading = true;
    },
    getApplicationsFilterSuccess(state, action) {
      state.isLoading = false;
      state.applicationsFilter = action.payload || [];
      state.searchableApplicationsListFilter = state.applicationsFilter.map((item) => {
        return {
          name: item.name,
          id: item.id,
          color: item.color,
          count: item?.count ?? 0,
          measure_count: item?.measure_count ?? 0
        };
      });
    },
    getApplicationsFilterFailure() { }
  }
});

export const { getApplicationsRequest, getApplicationsSuccess, getApplicationsFailure,
  createApplicationRequest, createApplicationSuccess, createApplicationFailure,
  deleteApplicationRequest, deleteApplicationSuccess, deleteApplicationFailure,
  updateApplicationRequest, updateApplicationSuccess, updateApplicationFailure,
  getApplicationAssetsRequest, getApplicationAssetsSuccess, getApplicationAssetsFailure,
  getApplicationByIdRequest, getApplicationByIdSuccess, getApplicationByIdFailure,
  getApplicationQualityRequest, getApplicationQualitySucess, getApplicationQualityFailure,
  collapseAccordian, clearApplicationDetails, getApplicationsFilterRequest, getApplicationsFilterSuccess, getApplicationsFilterFailure
} = applicationSlice.actions;
export default applicationSlice.reducer;