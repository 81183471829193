import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography, Dialog } from '@mui/material';


// Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import style from "./style.jsx";
import { CloseIcon } from '../../assets/svg/index.js';

function PreviewComponent(props) {

    /**
     * Define Props
     */
    const { classes, open, handleClosePreview, imageURL } = props;

    /**
     * Handle Change
     * @param {*} event
     */

    return (
        <div>
            <Dialog
                open={open}
                maxWidth={"lg"}
                className={classes.dialogContainer}
                fullWidth
                onClose={handleClosePreview}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid className={classes.prevContainer}>
                    <Grid className="dflex alignCenter spaceBetween mb-2 header">
                        <Typography variant="h5" className="">
                            Preview
                        </Typography>
                        <IconButton onClick={handleClosePreview}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={classes.previmageConatiner}>
                        {
                            imageURL?.length > 0 &&
                            <img src={imageURL} className={classes.prevImage} alt="preview" />
                        }
                    </Grid>
                </Grid>
            </Dialog>
        </div>

    );
}


/**
 * Define Component Props
 */
PreviewComponent.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    imageURL: PropTypes.string,
    handleClosePreview: PropTypes.func
};


/**
 * Set Default Values
 */
PreviewComponent.defaultProps = {
    className: "",
    imageURL: "",
    open: false,
    handleClosePreview: () => { }
};

export default withStyles((theme) => ({
    ...LayoutStyles(theme),
    ...style(theme)
}))(PreviewComponent);