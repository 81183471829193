// Import API Helper
import { getRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Organization Detail
 */
async function detail(params) {
    const response = await getRequest(`${urlConstants.organization.url}${params}/`);
    return response;
}


/**
 * Generate Token/Revoke Token
 * @param {*} type
 * @returns
 */
async function generateToken(type) {
    const response = await getRequest(`${urlConstants.organization.generateToken}?type=${type}`);
    return response;
}


/**
 * Get Token
 * @returns
 */
async function getToken() {
    const response = await getRequest(urlConstants.organization.getToken);
    return response;
}

/**
 * update
 * @returns
 */
async function update(params) {
    const response = await putRequest(`${urlConstants.organization.url}${params.id}/`, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const organizationService = {
    detail, generateToken, getToken, update
};