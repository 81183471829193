const style = (theme) => ({
    connectPageContainer: {
        '& .searchBox': {
            padding: '2px 10px !important'
        }
    },
    connectionHeader: {
        padding: '24px 30px 14px'
    },
    configurecardBody: {
        padding: '10px 30px 24px 30px',
        // height: 'calc(100vh - 200px)',
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    connectionCardNew: {
        padding: '10px 15px 14px',
        border: `1px dashed ${theme.palette.secondary.main}`,
        alignItems: 'center',
        boxShadow: '0px 0px 10px #0000000D',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column !important',
        cursor: 'pointer',
        minHeight: '228.19px',
        // height: "100%",
        height: '252.19px'
    },
    autoCompleteMaxWidth: {
        width: '235px'
    },
    configurecardBodyInner: {
        height: 'calc(100vh - 350px)',
        paddingRight: 15,
        marginTop: '0px !important',
        overflowY: 'auto',
        '@media (max-width: 1400px)': {
            height: 'calc(100vh - 320px)'
        }
    }
});
export default style;