import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import _ from 'lodash';
import { dFormatter, getExpectedManualThreshold } from '../../helpers';

const initialState = {
    isLoading: true,
    detail: {},
    result: [],
    sortBy: '',
    orderBy: 'asc',
    length_enum_history: [],
    length_enum_dropdown: []
};

const metricSlice = createSlice({
    name: 'metric',
    initialState: { ...initialState },
    reducers: {
        getMetricDetailRequest(state) {
            state.isLoading = true;
        },
        getMetricDetailSuccess(state, action) {
            state.isLoading = false;
            state.detail = action.payload?.data?.detail || {};
            state.length_enum_history = action.payload?.data?.length_enum_history || [];
            state.behavioral_history = action.payload?.data?.behavioral_history || [];
            if (state.length_enum_history.length) {
                state.length_enum_history = _.orderBy(state.length_enum_history, ['measure_name'], ['asc']);
                state.length_enum_dropdown = state.length_enum_history.filter((item) => item.measure_name?.toString()?.toLowerCase()?.length > 0).map((item) => {
                    const itemName = item.base_measure_name ? item.base_measure_name : item.measure_name;
                    return { "id": itemName?.toString(), "name": itemName?.toString() };
                });
                state.length_enum_dropdown = _.orderBy(state.length_enum_dropdown, ['id'], ['asc']);
            }

            if (state.behavioral_history.length) {
                let has_date = false;
                if (state.detail?.properties) {
                    const properties = (typeof state.detail?.properties === "string") ? JSON.parse(state.detail.properties) : state.detail.properties;
                    if (properties.attributes) {
                        const attribute = properties.attributes.find((item) => item.data_type?.toLowerCase() === 'date');
                        has_date = Boolean(attribute);
                    }
                }
                state.detail = {
                    ...state.detail,
                    "has_date": has_date,
                    "show_measure_name": true
                };
            }

            const alertData = state.detail?.history_data || [];
            state.result = alertData.map((obj) => {
                obj.created_date = moment(obj.created_date);
                let expected = "";
                if (obj?.threshold) {
                    if (obj?.is_auto) {
                        expected = `${obj.threshold?.lower_threshold} - ${obj.threshold?.upper_threshold}`;
                        if (state.detail?.measure_name?.toLowerCase() === 'freshness') {
                            expected = `${dFormatter(obj.threshold?.lower_threshold)} - ${dFormatter(obj.threshold?.upper_threshold)}`;
                        }
                    }
                    else {
                        expected = getExpectedManualThreshold(obj.threshold);
                    }
                }
                if (state.detail?.measure_name?.toLowerCase() === 'freshness' && obj.value) {
                    obj.freshnessValue = obj.value;
                    obj.value = dFormatter(obj.value);
                }

                return {
                    ...obj,
                    expected,
                    day: obj.created_date?.fromNow(),
                    status: obj.status !== "OK" ? obj.status : ""
                };
            });
            let results = [...state.result];
            results = results.map((item) => {
                if (item.deviation) {
                    item.deviation = parseFloat(item.deviation);
                }
                if (item.percent_change) {
                    item.percent_change = parseFloat(item.percent_change);
                }
                return item;
            });
            results = _.orderBy(results, 'created_date', 'desc');
            state.result = results;
            state.sortBy = 'created_date';
            state.orderBy = 'desc';
        },
        getMetricDetailFailure(state) {
            state.isLoading = false;
        },
        updateDriftMetricProperty(state, action) {
            const { id } = action.payload;
            const alerts = [...state.result];
            const index = alerts.findIndex((alert) => alert.id === id);
            if (index !== -1) {
                alerts[index] = {
                    ...alerts[index],
                    ...action.payload
                };
                state.result = [...alerts];
            }
        },
        measureSorting(state, action) {
            const { sortBy, orderBy } = action.payload;
            let results = [...state.result];
            results = _.orderBy(results, [sortBy], [orderBy]);
            state.result = results;
            state.sortBy = sortBy;
            state.orderBy = orderBy;
        },
        updateMetricProperty(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        getLengthEnumValueDetail(state, action) {
            const index = state.length_enum_history.findIndex((item) => (item.measure_name?.toString()?.toLowerCase() === action.payload?.toString()?.toLowerCase() || item.base_measure_name?.toString()?.toLowerCase() === action.payload?.toString()?.toLowerCase()));
            state.detail = state.length_enum_history[index];
            const alertData = state.detail?.history_data || [];
            state.result = alertData.map((obj) => {
                obj.created_date = moment(obj.created_date);
                let expected = "";
                if (obj?.threshold) {
                    if (obj?.is_auto) {
                        expected = `${obj.threshold?.lower_threshold} - ${obj.threshold?.upper_threshold}`;
                    }
                    else {
                        expected = getExpectedManualThreshold(obj.threshold);
                    }
                }
                return {
                    ...obj,
                    expected,
                    day: obj.created_date?.fromNow(),
                    status: obj.status !== "OK" ? obj.status : ""
                };
            });
        },
        setBehaviourDetailByKey(state, action) {
            const index = state.behavioral_history.findIndex((item) => item.measure_name === action.payload);
            state.detail = state.behavioral_history[index];
            let has_date = false;
            if (state.detail?.properties) {
                const properties = (typeof state.detail?.properties === "string") ? JSON.parse(state.detail.properties) : state.detail.properties;
                if (properties.attributes) {
                    const attribute = properties.attributes.find((item) => item.data_type?.toLowerCase() === 'date');
                    has_date = Boolean(attribute);
                }
            }
            const alertData = state.detail?.history_data || [];

            state.detail = {
                ...state.detail,
                "has_date": has_date,
                "show_measure_name": true
            };

            state.result = alertData.map((obj) => {
                obj.created_date = moment(obj.created_date);
                let expected = "";
                if (obj?.threshold) {
                    if (obj?.is_auto) {
                        expected = `${obj.threshold?.lower_threshold} - ${obj.threshold?.upper_threshold}`;
                    }
                    else {
                        expected = getExpectedManualThreshold(obj.threshold);
                    }
                }
                return {
                    ...obj,
                    expected,
                    day: obj.created_date?.fromNow(),
                    status: obj.status !== "OK" ? obj.status : ""
                };
            });
        },
        clearMetricsState(state) {
            state.isLoading = true;
            state.detail = {};
            state.result = [];
            state.length_enum_history = [];
            state.length_enum_dropdown = [];
            state.behavioral_history = [];
            state.sortBy = '';
            state.orderBy = 'asc';
        },
        deleteMetricIssueId(state, action) {
            const alerts = [...state.result];
            const index = alerts.findIndex((alert) => alert.issue_id === action.payload);
            if (index > -1) {
                const alert = { ...alerts[index] };
                alert.issue_id = null;
                alert.issue_number = null;
                state.result.splice(index, 1, { ...alert });
            }
        },
        updateMeasureRunStatus(state, action) {
            const status = action.payload.data;
            state.detail.run_status = status?.run_status
                ? status.run_status
                : state.detail.run_status;
        },
        getComparisonMetricRequest(state, action) {
            const index = state.result.findIndex((m) => m.id === action.payload.id);
            if (index > -1) {
                state.result[index].isLoading = true;
            }
        },
        getComparisonMetricSuccess(state, action) {
            const metrics = action.payload?.data ?? [];
            const payload = action.payload.payload;
            const index = state.result.findIndex((item) => item.run_id === payload.run_id);
            if (index > -1) {
                const metric = metrics.map((data) => {
                    const freshnessMeasure = data.measure_name.includes("Freshness");
                    return {
                        ...data,
                        source_count: freshnessMeasure ? dFormatter(data.source_count) : data.source_count,
                        target_count: freshnessMeasure ? dFormatter(data.target_count) : data.target_count,
                        value: freshnessMeasure ? dFormatter(data.value) : data.value
                    };
                });
                state.result[index].metrics = [...metric];
                state.result[index].isLoading = false;
            }
        },
        getComparisonMetricFailure(state, action) {
            const index = state.result.findIndex((item) => item.id === action.payload.id);
            if (index > -1) {
                state.result[index].isLoading = false;
            }
        },
        toggleMetricResult(state, action) {
            const index = state.result.findIndex((item) => item.id === action.payload.id);
            if (index > -1) {
                state.result[index].isOpen = action.payload.isOpen;
            }
        }

    }
});

export const { getMetricDetailRequest, getMetricDetailSuccess, getMetricDetailFailure, updateDriftMetricProperty, measureSorting, updateMetricProperty,
    clearMetricsState, getLengthEnumValueDetail, setBehaviourDetailByKey, deleteMetricIssueId, updateMeasureRunStatus,
    getComparisonMetricRequest, getComparisonMetricSuccess, getComparisonMetricFailure, toggleMetricResult
} = metricSlice.actions;
export default metricSlice.reducer;