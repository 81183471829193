import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent } from '../../../components/index.js';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import IBMIcon from '../../../assets/img/apps/ibm.png';

// Import Constant
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { ssoRequestURL_Format } from '../../../helpers/apiHelpers.js';

// Import Actions
import { getConfigurationRequest, setConfiguration, createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer.js';
import { displyAlert } from '../../../redux/reducer/alertReducer.js';
import { checkPermission } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';

function IbmSaml(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};

    /**
     * Define State
     */
    const [attachment, setAttachment] = useState(null);

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * On Save
     */
    const onSave = () => {
        const requestParams = {
            attachment,
            channel: channel.id,
            is_sso: true,
            sso_type: channel.type,
            id: channelId,
            channelName: "IbmSaml"
        };
        if (!channelId) {
            if (attachment) {
                dispatch(createConfigrationRequest(requestParams));
            } else {
                dispatch(displyAlert({ 'type': 'error', 'message': { "message": 'Federation File Missing!' } }));
            }
        } else {
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        } else {
            dispatch(setConfiguration({ "federation": false }));
        }
    }, []);

    /**
     * Federation File Upload
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setAttachment(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Federation File Upload
     */
    const handleFileUpload = () => {
        document.getElementById(`uploadFile`).click();
    };

    // Set Sso Url
    const urlSSOUrl = ssoRequestURL_Format();

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={IBMIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.IBMIdentity.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.IBMIdentity.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <Grid item xs={12} className="pb-2">
                                <Typography variant="h6">
                                    Basic SAML Configurations Details
                                </Typography>
                            </Grid>
                            <MemoTextBoxComponent
                                name="entity_id"
                                value={`${urlSSOUrl}metadata`}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Entity id
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                disabled
                            />
                            <Grid item xs={12} className="pt-3">
                                <MemoTextBoxComponent
                                    name="sign_in_url"
                                    value={`${urlSSOUrl}sso`}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Sign in URL
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} className="pt-3">
                                <MemoTextBoxComponent
                                    name="acs_url"
                                    value={`${urlSSOUrl}acs`}
                                    fullWidth
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            ACS URL
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid item xs={12}>
                                <Typography variant="h6" className="pb-3">
                                    Organization Input Here
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="dflex alignEnd">
                                <input
                                    accept="text/xml"
                                    style={{ display: 'none' }}
                                    id={`uploadFile`}
                                    type="file"
                                    onChange={handleSelectFile}
                                    disabled={!integrationPermission?.is_edit}
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleFileUpload}
                                    disabled={!integrationPermission?.is_edit}
                                    className={classes.btnUpload}
                                >
                                    {`${configuration.federation || attachment ? 'Update' : 'Add'} Federation File`}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} align="right">
                                <LoaderButtonComponent
                                    size="small"
                                    type="submit"
                                    isLoading={saveLoading}
                                >
                                    {appConstants.labels.connector.save}
                                </LoaderButtonComponent>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className="ml-2"
                                    onClick={() => navigate(-1)}
                                >
                                    {appConstants.labels.connector.Cancel}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
IbmSaml.defaultProps = {
    classes: {}
};

// prop types
IbmSaml.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(IbmSaml);