const style = (theme) => ({
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px'
    },
    profileBody: {
        padding: "30px 40px",
        '& .uploadimg': {
            cursor: 'pointer',
            display: 'inline-flex',
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`
            }
        }
    },
    profileItem: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 24,
        '& .formLabel': {
            minWidth: 88,
            fontSize: 15
        },
        '& .MuiOutlinedInput-input': {
            padding: '2.5px 14px'
        },
        '& .MuiAutocomplete-root': {
            minWidth: 150,
            maxWidth: 200
        },
        '& .chips': {
            flexWrap: 'wrap',
            '& .plusIcon': {
                margin: '5px 0px 1px 0px !important'
            }
        },
        '& .MuiTypography-body1': {
            width: '100%'
        },
        '& .MuiChip-root': {
            margin: '4px !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
            '&:hover': {
                borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
            }
        },
        '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
            }
        }
    },
    profileImg: {
        width: 190,
        height: 190,
        objectFit: 'fill'
    },
    imgUploadSection: {
        width: 190,
        height: 190,
        margin: 'auto',
        position: 'relative'
    },
    profileImgInput: {
        display: 'none !important'
    },
    channelImg: {
        height: '24px',
        paddingLeft: '50px'
    },
    deleteIcon: {
        top: '10px',
        right: '10px',
        padding: '4px !important',
        position: 'absolute !important',
        backgroundColor: '#B0BAC9 !important',
        '& .DeleteIcon': {
            '& path': {
                fill: '#fff',
                stroke: '#fff'
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    changePasswordPageContainer: {
        backgroundRepeat: 'no-repeat',
        padding: '20px',
        maxWidth: 320,
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        '& .MuiSvgIcon-root': {
            fill: theme.palette.greyshades.darkgrey
        }
    }
});
export default style;