import { createSlice } from '@reduxjs/toolkit';

// Import Helpers
import { getUserPreference, getUser } from '../../helpers/appHelpers';

const initialState = {
    isLoading: false,
    id: "",
    list: [],
    widgets: [],
    widgetList: [],
    detail: {},
    widgetLoading: false,
    saveLoading: false
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: { ...initialState },
    reducers: {
        selectDashboard(state, action) {
            state.id = action.payload;
            state.widgets = [];
            const selectedDashboard = state.list.find((obj) => obj.id === action.payload);
            state.detail = {
                ...selectedDashboard
            };
        },
        getAllDashboardRequest() { },
        getAllDashboardSuccess(state, action) {
            state.list = action.payload.data || [];
            if (state.list.length) {
                const user = getUser();
                const selectedId = getUserPreference(user?.user_preference ?? {}, "dashboard", "selected", "id");
                state.id = selectedId || state.list[0].id;
                state.detail = {
                    ...state.list[0]
                };
            }
        },
        getAllDashboardFailure() { },
        getDashboardDetailRequest(state) {
            state.isLoading = true;
            state.widgets = [];
        },
        getDashboardDetailSuccess(state, action) {
            state.isLoading = false;
            state.widgets = action.payload.data || [];
        },
        getDashboardDetailFailure(state) {
            state.isLoading = false;
        },
        createDashboardRequest() { },
        createDashboardSuccess(state, action) {
            const dashboard = action.payload.data || {};
            state.widgets = [];
            state.list.push({ ...dashboard });
            state.id = dashboard.id;
            state.detail = { ...dashboard };
        },
        createDashboardFailure() { },
        updateDashboardRequest(state, action) {
            const data = action.payload;
            const index = state.list.findIndex((item) => item.id === data.id);
            state.list[index].name = data.name;
        },
        updateDashboardSuccess(state, action) {
            const { data } = action.payload;
            const index = state.list.findIndex((item) => item.id === data.id);
            state.list[index] = data;
        },
        updateDashboardFailure() { },
        updateWidgetProperties(state, action) {
            const { id, type, ...rest } = action.payload;
            const index = state.widgets.findIndex((item) => item.id === id);
            if (index !== -1) {
                if (type === "filter_properties") {
                    state.widgets[index].filter_properties = {
                        ...state.widgets[index].filter_properties,
                        ...rest
                    };
                } else {
                    state.widgets[index] = {
                        ...state.widgets[index],
                        ...rest
                    };
                }
            }
        },
        getWidgetDataRequest() { },
        getWidgetDataSuccess(state, action) {
            const { id, report } = action.payload;
            const index = state.widgets.findIndex((item) => item.id === id);
            state.widgets[index].isLoading = false;
            state.widgets[index].report = report;
        },
        getWidgetDataFailure() { },
        widgetLoader(state, action) {
            const index = state.widgets.findIndex((item) => item.id === action.payload);
            state.widgets[index].isLoading = true;
        },
        getWidgetListRequest(state) {
            state.widgetList = [];
            state.widgetLoading = true;
        },
        getWidgetListSuccess(state, action) {
            state.widgetList = action.payload.data || [];
            state.widgetLoading = false;
        },
        getwidgetListFailure(state) {
            state.widgetList = [];
            state.widgetLoading = false;
        },
        createWidgetRequest(state) {
            state.saveLoading = true;
        },
        createWidgetSuccess(state) {
            state.saveLoading = false;
        },
        createWidgetFailure(state) {
            state.saveLoading = false;
        },
        updateDashboardFilter(state, action) {
            const { id, ...rest } = action.payload;
            const index = state.list.findIndex((obj) => obj.id === id);
            state.list[index] = {
                ...state.list[index],
                ...rest
            };
            state.detail = {
                ...state.detail,
                ...rest
            };
        },
        updateLayoutRequest() { },
        updateLayoutSuccess() { },
        updateLayoutFailure() { },
        updateWidgetRequest(state) {
            state.saveLoading = true;
        },
        updateWidgetSuccess(state, action) {
            state.saveLoading = false;
            if (action.payload.type === "widget") {
                const widget = action.payload.data || {};
                const index = state.widgets.findIndex((data) => data.id === widget.id);
                state.widgets[index] = {
                    ...state.widgets[index],
                    ...widget
                };
            }
        },
        updateWidgetFailure(state) {
            state.saveLoading = false;
        },
        layoutUpdate(state, action) {
            let widgets = [...state.widgets];
            widgets = widgets.map((widget) => {
                const currentLayout = action.payload.find((obj) => obj.id === widget.id);
                return {
                    ...widget,
                    layout: currentLayout.data || widget.layout
                };
            });
            state.widgets = [...widgets];
        },
        deleteWidgetRequest() { },
        deleteWidgetSuccess(state, action) {
            const index = state.widgets.findIndex((obj) => obj.id === action.payload);
            state.widgets.splice(index, 1);
        },
        deleteWidgetFailure() { },
        deleteDashboardRequest() { },
        deleteDashboardSuccess(state, action) {
            state.id = state.list[0].id;
            state.widgets = [];
            const index = state.list.findIndex((obj) => obj.id === action.payload);
            state.list.splice(index, 1);
            const selectedDashboard = state.list.find((obj) => obj.id === state.id);
            state.detail = {
                ...selectedDashboard
            };
        },
        deleteDashboardFailure() { },
        widgetMoveRequest() { },
        widgetMoveSuccess(state, action) {
            const index = state.widgets.findIndex((obj) => obj.id === action.payload);
            if (index > -1) {
                state.widgets.splice(index, 1);
            }
        },
        widgetMoveFailure() { },
        cleanWidgets(state) {
            state.widgets = [];
        }
    }
});

export const { selectDashboard, getAllDashboardRequest, getAllDashboardSuccess, getAllDashboardFailure, getDashboardDetailRequest, getDashboardDetailSuccess,
    getDashboardDetailFailure, createDashboardRequest, createDashboardSuccess, createDashboardFailure, updateWidgetProperties, getWidgetDataRequest, getWidgetDataSuccess,
    getWidgetDataFailure, widgetLoader, getWidgetListRequest, getWidgetListSuccess, getwidgetListFailure, createWidgetRequest, createWidgetSuccess, createWidgetFailure,
    updateDashboardRequest, updateDashboardSuccess, updateDashboardFailure, updateDashboardFilter, updateLayoutRequest, updateLayoutSuccess, updateLayoutFailure,
    updateWidgetRequest, updateWidgetSuccess, updateWidgetFailure, layoutUpdate, deleteWidgetRequest, deleteWidgetSuccess, deleteWidgetFailure, deleteDashboardRequest, deleteDashboardSuccess, deleteDashboardFailure, widgetMoveRequest, widgetMoveSuccess, widgetMoveFailure,
    cleanWidgets
} = dashboardSlice.actions;
export default dashboardSlice.reducer;