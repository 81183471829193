import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import Public Layout
import { AuthLayout } from "../layouts";

// Import Helpers
import { getCookie, removeCookie } from "../helpers/appHelpers.js";

import { logoutRequest } from "../redux/reducer/authReducer";

// Routes the page without any authentication by the user
function AuthRoute({ component, ...props }) {

  /**
   * Get Login Status
   */
  let loggeInStatus = getCookie('token');
  const newUserActivation = window.location.pathname.includes("changepassword");
  if (newUserActivation && loggeInStatus) {
    removeCookie('token');
    const dispatch = useDispatch();
    dispatch(logoutRequest());
    loggeInStatus = "";
  }


  return loggeInStatus === '' ? <AuthLayout component={component} {...props} /> : <Navigate to={{ pathname: "/home", state: { from: props.location } }} />;
}

// Define Props Types
AuthRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string
};
export default AuthRoute;