/* eslint-disable multiline-comment-style */
// Default Imports
import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from "echarts-for-react";
import { graphic } from "echarts/core";

// Import Styles
import style from "./style.jsx";

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';

// // Import Helpers
import { numberWithCommas } from '../../helpers/appHelpers';
import moment from 'moment';


const AreaChartComponent = (props) => {

    /**
     * Define Props
     */
    const { chartData, containerId, lineColor, toolTipName } = props;

    /**
     * Define Ref
     */
    const myChart = useRef();

    /**
     * Define Chart Options
     */
    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },
        grid: {
            left: 20,
            right: 0,
            bottom: 20,
            top: 20
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: "none"
            },
            formatter: (params) => {
                const data = params[0];
                const date = moment(data?.name, 'YYYY-MM-DD').format('MMM DD YYYY');
                return `Date: ${date}<br /> ${toolTipName}: ${numberWithCommas(data?.value ?? 0)}`;
                // return `Date: ${data?.name ?? ""}<br /> ${toolTipName}: ${numberWithCommas(data?.value ?? 0)}`;
            }
        },
        xAxis: {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: chartData.map((item) => item.date)
        },
        yAxis: {
            show: false,
            type: 'value',
            boundaryGap: false
        },
        series: [
            {
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {
                    color: lineColor
                },
                areaStyle: {
                    color: new graphic.LinearGradient(0.5, 0.6, 0.5, 0.2, [
                        {
                            offset: 0,
                            color: 'white'
                        },
                        {
                            offset: 1,
                            color: lineColor
                        }
                    ])
                },
                data: chartData.map((item) => item.count)
            }
        ]
    };

    let height = 120;
    if (containerId) {
        const parentContainer = document.getElementById(containerId);
        if (parentContainer) {
            height = parentContainer.clientHeight ? parentContainer.clientHeight : height;
        }
    }

    return (
        <ReactEcharts ref={myChart} option={option} opts={{ renderer: "svg" }} style={{ height }} notMerge />
    );
};


/**
 * Define Prop Types
 */
AreaChartComponent.propTypes = {
    chartData: PropTypes.array,
    lineColor: PropTypes.string,
    containerId: PropTypes.string,
    toolTipName: PropTypes.string
};

/**
 * Set Default Values
 */
AreaChartComponent.defaultProps = {
    chartData: [],
    lineColor: "",
    containerId: "",
    toolTipName: "Count"
};

export default withStyles((theme) => ({
    ...style(theme)
}))(AreaChartComponent);