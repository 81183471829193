import React, { useEffect, useState, useMemo, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useScreenshot } from 'use-react-screenshot';

//  Import Styles
import AddRuleStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import QualityHeader from './components/header.jsx';
import QualityDetail from './components/detail.jsx';
import List from './components/list.jsx';
import BehaviourlList from './components/behaviourl_list.jsx';
import { LoaderComponent, NoResultComponent } from '../../../../components/index.js';

// Import Actions
import { getMetricDetailRequest, getLengthEnumValueDetail, clearMetricsState } from '../../../../redux/reducer/metricReducer';
import { clearFailedRows, clearMeasureDetailState, getMeasureDetailRequest } from '../../../../redux/reducer/measureReducer.js';
import { getAttributesByVersionRequest } from '../../../../redux/reducer/attributeReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';
import { updateDriftAlertRequest } from '../../../../redux/reducer/driftReducer.js';

// Import Constansts
import featureConstants from '../../../../constants/featureConstants.js';
import { checkPermission } from '../../../../helpers/appHelpers';


function Quality(props) {

    /**
     * Define Props
     */
    const { id: measureId } = useParams();
    const { state } = useLocation();
    const { classes } = props;
    const dispatch = useDispatch();
    const reactNavigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isMeasureLevel, level, measureCategory, selectedAlert: alert, showEdit, measureName: name, type, selectedMeasures } = state || {};
    const query_alert_id = searchParams.get("alert_id");
    const query_measure_name = searchParams.get("measure_name");
    const query_alert_action = searchParams.get("action");

    const selectedAlert = alert || query_alert_id;
    const measureName = name || query_measure_name;


    /**
     * Define State
     */
    const [dayFilter, setDayFilter] = useState(7);
    const [selectedMeasureId, setSelectedMeasureId] = useState(measureId);
    const [showBehaviourList, setShowBehaviourList] = useState(false);
    const card_div_ref = useRef();
    const scrollRef = useRef();
    const [screenShotCaptured, setScreenShotCaptured] = useState(false);
    const [shareImageScreenShot, takeShareImageScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 0.8
        }
    );
    const [alertScreenShot, setAlertScreenShot] = useState({ 'file': null, 'base64': null });

    /**
     * Redux Store
     */
    const detail = useSelector((state) => state.metric.detail);
    const { detail: measureDetail } = useSelector((state) => state.measure);
    const { isLoading } = useSelector((state) => state.metric);
    const { permission } = useSelector((state) => state.auth);
    const measurePermission = checkPermission(permission, featureConstants.home.measures);
    const propertyPermsission = checkPermission(permission, featureConstants.assets.properties);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const propertiesPermission = type === "measure" ? measurePermission : propertyPermsission;


    /**
     * Get Attributes and Get Asset Measures
     */
    useEffect(() => {
        if (selectedMeasureId && state) {
            if (state?.asset_id) {
                dispatch(getAttributesByVersionRequest(state?.asset_id));
            }
        }
    }, [state, selectedMeasureId]);

    /**
     * Get metric details for the selected measures
     * @param {*} measureId
     * @param {*} dayFilter
     * @param {*} measureName
     */
    const getMetricDetails = (measureId, dayFilter, measureName) => {
        const params = {
            id: measureId,
            days: dayFilter || 7,
            name: measureName || ""
        };
        dispatch(clearFailedRows());
        dispatch(getMetricDetailRequest(params));
    };


    /**
     * Load metrics for given measure id
     */
    useEffect(() => {
        if (selectedMeasureId) {
            getMetricDetails(selectedMeasureId, dayFilter, measureName);
        }
    }, [selectedMeasureId, dayFilter]);


    /**
     * Update measure id
     */
    useEffect(() => {
        if (measureId !== selectedMeasureId) {
            setSelectedMeasureId(measureId);
            dispatch(clearFailedRows());
        }
    }, [measureId, dayFilter]);

    /**
     * Get Measure Detail
     * @param {*} id
     * @param {*} measures_list
     * @returns
     */
    const getMeasureDetail = (id, measureDetail, data) => {
        if (!id) {
            return data;
        }
        const measure = measureDetail.id === id ? measureDetail : {};
        if (measure) {
            return {
                ...measure,
                ...data,
                measure_id: measureId,
                name: measure.name ?? '',
                description: measure.description ?? ''
            };
        }
        if (!measure && (isMeasureLevel || !data.asset_id)) {
            return {
                ...data,
                level: "measure"
            };
        }
        if (level === "measure") {
            return {
                ...data,
                level: "measure"
            };
        }
        return data;
    };

    /**
     * Filter Using UseMemo
     */
    const data = useMemo(() => getMeasureDetail(selectedMeasureId, measureDetail, detail), [selectedMeasureId, measureDetail, detail]);


    /**
     * On Change Filter
     * @param {*} value
     */
    const onChangeFilter = (value) => {
        if (value) {
            setDayFilter(value);
        }
    };

    /**
     * On Change measure
     * @param {*} value
     */
    const onChangeMeasure = (measure) => {
        if (measure?.id) {
            setSelectedMeasureId(measure.id);
        }
    };

    /**
     * Hangle Length Enum Value Change Event
     * @param {*} value
     */
    const onHandleValueChange = (value) => {
        if (value) {
            dispatch(getLengthEnumValueDetail(value.id));
        }
    };

    /**
     * Show Behaviour List
     */
    const handleBehaviourlList = () => {
        setShowBehaviourList(!showBehaviourList);
    };

    /**
     * Upload Alert Screenshot
     * @param {*} dataurl
     */
    const uploadScreenShot = (dataurl) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], 'share.jpg', { type: mime });
        setAlertScreenShot({ 'file': croppedImage, 'base64': dataurl });
        setScreenShotCaptured(true);
    };

    /**
     * Handle Screenshot After Dom Loaded
     */
    const takeScreenshot = () => {
        let timer = null;
        if (card_div_ref && !screenShotCaptured && !shareImageScreenShot) {
            timer = setTimeout(() => {
                takeShareImageScreenShot(card_div_ref.current).then(uploadScreenShot);
            }, 1000);
        }
        return () => clearTimeout(timer);
    };

    /**
     * Get selected Measure Detail
     */
    useEffect(() => {
        if (selectedMeasureId) {
            dispatch(getMeasureDetailRequest(selectedMeasureId));
        }
    }, [selectedMeasureId]);

    /**
     * Open Measure Popup Based on Measure Id from Query Params
     */
    useEffect(() => {
        if (query_alert_action && query_alert_id) {
            const requestParams = {
                id: query_alert_id,
                marked_as: query_alert_action === 'mark_as_normal' ? 'normal' : null
            };
            dispatch(updateDriftAlertRequest(requestParams));
        }

    }, [query_alert_id, query_alert_action]);

    /**
     * Handle Measure Quality Dialog
     */
    const handleMeasureQualityDialog = () => {
        dispatch(clearMeasureDetailState());
        dispatch(clearMetricsState());
        if (state?.prevUrl) {
            reactNavigation(-1);
        } else {
            reactNavigation("/home/alerts", { replace: true });
        }
    };

    /**
     * Edit Measure
     */
    const editMeasure = () => {
        const isAssetLevel = data?.attribute_id === "All" || data?.attribute_id === "" || !data?.attribute_id || data?.attribute === "All" || data?.attribute === "" || !data?.attribute;
        const state = { attribute_id: data?.attribute_id || data?.attribute, asset_id: data?.asset_id || data?.asset, isAssetLevel: isAssetLevel && (data.level !== "measure"), isMeasureLevel: (data.level === "measure") };
        dispatch(navigate({ path: 'measure.edit', state: state, params: [measureId] }));
    };

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Define Remove Search Param
     */
    useEffect(() => {
        if (query_alert_id || query_measure_name) {
            removeSearchParam();
        }
    }, []);


    return (
        <Grid container className={classes.container} ref={card_div_ref}>
            <Grid className={classes.qualityContainer} ref={scrollRef}>
                {
                    !isLoading && selectedMeasureId &&
                    <QualityHeader
                        handleMeasureQualityDialog={handleMeasureQualityDialog}
                        data={data}
                        classes={classes}
                        editMeasure={editMeasure}
                        showEdit={showEdit}
                        propertiesPermission={propertiesPermission}
                        publishPermission={publishPermission}
                        approvePermission={approvePermission}
                        takeScreenshot={takeScreenshot}
                        alertScreenShot={alertScreenShot}
                        measureCategory={measureCategory}
                        selectedMeasures={selectedMeasures}
                        onChangeMeasure={onChangeMeasure}
                    />
                }
                {
                    selectedMeasureId && data.name &&
                    <Fragment>
                        <QualityDetail
                            data={{ ...detail, dayFilter } || {}}
                            onChangeFilter={(value) => onChangeFilter(value)}
                            classes={classes}
                            selectedAlert={selectedAlert}
                            onHandleValueChange={(value) => onHandleValueChange(value)}
                            handleBehaviourlEvent={() => handleBehaviourlList()}
                        />
                        <List selectedAlert={selectedAlert} dayFilter={dayFilter} data={data} propertiesPermission={propertiesPermission} measureId={measureId} scrollRef={scrollRef} />
                        <BehaviourlList
                            open={showBehaviourList}
                            selectedKey={detail?.measure_name ?? ''}
                            handleBehaviourlList={handleBehaviourlList}
                            propertiesPermission={propertiesPermission}
                        />
                    </Fragment>
                }
            </Grid>

            {
                isLoading &&
                <LoaderComponent />
            }

            {
                !data.name && !isLoading &&
                <NoResultComponent />
            }
        </Grid>
    );
}

// default props
Quality.defaultProps = {
    classes: {}
};

// prop types
Quality.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AddRuleStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Quality);