export default [
    {
        family: "Lato",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew8.ttf",
                fontWeight: 700
            }
        ]
    },
    {
        family: "Roboto",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf",
                fontWeight: 500
            },
            {
                src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf",
                fontWeight: 700
            }
        ]
    },
    {
        family: "Lora",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuyJG.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJG.ttf",
                fontWeight: 500
            },
            {
                src: "https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Montserrat",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf",
                fontWeight: 500
            },
            {
                src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Noto Sans",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/notosans/v35/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/notosans/v35/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyDPA99d.ttf",
                fontWeight: 500
            },
            {
                src: "https://fonts.gstatic.com/s/notosans/v35/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAaBN9d.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Nunito",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDIkhRTM.ttf",
                fontWeight: 500
            },
            {
                src: "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Nunito Sans",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilntA.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GCC5ntA.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GMS5ntA.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Open Sans",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4n.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Oswald",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUE.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUE.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Poppins",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "PT Sans",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/ptsans/v17/jizYRExUiTo99u79D0eEwA.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh4Ok.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Raleway",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCP.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Slabo",
        src: "https://fonts.gstatic.com/s/slabo13px/v15/11hEGp_azEvXZUdSBzzRcKc.ttf"
    }, {
        family: "Source Sans Pro",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/sourcesanspro/v22/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/sourcesanspro/v22/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/sourcesanspro/v22/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf",
                fontWeight: 700
            }
        ]
    }, {
        family: "Work Sans",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf"
            },
            {
                src: "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNig.ttf",
                fontWeight: 600
            },
            {
                src: "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNig.ttf",
                fontWeight: 700
            }
        ]
    }
];