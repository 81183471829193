import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// import Components
import { TextBoxComponent } from '../../../textBox';

const TimePickerComponent = (props) => {
    /**
     * Props definition
     */
    const { value, isManual, onChange, format, variant, validators, onAccept, errorMessages, ...rest } = props;

    /**
     * Define State
     */
    const [valueByInput, setValueByInput] = useState(false);

    /**
     * OnKeyDown Input
     * @param {*} value
     */
    const onKeyDownInput = () => {
        setValueByInput(true);
    };

    /**
     * Default values definition
     */
    const timeFormat = format || "HH:mm";
    let dateString = "";
    const currentDate = new Date();
    const currentDay = currentDate.getDate().toString().padStart(2, "0");
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const currentYear = currentDate.getFullYear();

    if (value) {
        dateString = `${currentDay}-${currentMonth}-${currentYear} ${value ? value : `${currentDate.getHours()}:${currentDate.getMinutes()}`}`;
    }

    const onTimeChange = (value, context) => {
        let hours = "00";
        let minutes = "";
        if (value) {
            hours = value.getHours().toString().padStart("2", 0);
            minutes = value.getMinutes();
        }

        dateString = `${currentDay}-${currentMonth}-${currentYear} ${hours}:${minutes}`;
        const parsedDate = moment(dateString, "dd-MM-YYYY HH:mm");
        if (parsedDate.isValid()) {
            if (valueByInput) {
                if (minutes.toString().length === 2) {
                    onChange(parsedDate.format(timeFormat));
                } else {
                    onChange("");
                    return false;
                }
            } else {
                onChange(parsedDate.format(timeFormat));
            }
        }
        else if (value === null) {
            onChange("");
        }
        else {
            if (isManual) {
                onChange(context);
            } else {
                return false;
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                ampm={false}
                value={dateString ? moment(dateString, 'dd-MM-yyyy HH:mm') : null}
                format={format}
                onChange={
                    (value, context) => {
                        setValueByInput(false);
                        onTimeChange(value, context);
                    }
                }
                onAccept={
                    (date) => {
                        onAccept(moment(date).format(timeFormat));
                    }
                }
                renderInput={(params) => <TextBoxComponent {...params} {...rest} value={value} variant={variant} validators={validators} errorMessages={errorMessages} onKeyDown={() => onKeyDownInput()} />}
                {...rest}
            />
        </LocalizationProvider>
    );
};


// default props
TimePickerComponent.defaultProps = {
    value: "",
    onChange: () => { },
    label: "",
    format: "HH:mm",
    variant: "standard",
    validators: [],
    errorMessages: [],
    onAccept: () => { },
    isManual: false
};

// prop types
TimePickerComponent.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    format: PropTypes.string,
    variant: PropTypes.string,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    onAccept: PropTypes.func,
    isManual: PropTypes.bool
};

export default (TimePickerComponent);