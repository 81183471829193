import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useScreenshot } from 'use-react-screenshot';


//  Import Styles
import DatasetOverviewStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Constants
import appConstants from '../../constants/appConstants.js';

// import Actions
import { clearAssetState } from '../../redux/reducer/assetReducer';
import { getAttributesByVersionRequest, clearAttributeState, clearAllAttributesMetadata, getAllAttributesMetadataRequest } from '../../redux/reducer/attributeReducer';
import { getVersionRequest, clearVersionState, updateShareDetails, updateVersionBasicInfo } from '../../redux/reducer/versionReducer.js';
import { getDimensionRequest } from '../../redux/reducer/dimensionReducer';
import { clearMetricsState } from '../../redux/reducer/metricReducer';
import { getUsersThumbnailRequest } from '../../redux/reducer/userReducer';
import { getTabListRequest } from '../../redux/reducer/fieldPropertyReducer.js';

//  Import Components
import Header from './components/header/index.jsx';
import { NavigationTabHeaderComponent } from '../../components/index.js';

// Helper Functions
import { permissionTabs, get_connection_type_access } from '../../helpers/appHelpers.js';

function AssetOverview(props) {

    /**
     * Define Props
     */
    const { classes, scrollRef } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const { id: assetId } = useParams();

    /**
     * Define State
     */
    const header_div_ref = useRef();
    const [screenShotCaptured, setScreenShotCaptured] = useState(false);
    const [shareImageScreenShot, takeShareImageScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 0.8
        }
    );
    const [accessByConnection, setAccessByConnection] = useState(null);


    /**
     * Resux Select Action
     * @param {*} event
     */
    const versionDetail = useSelector((state) => state.version.detail, shallowEqual);
    const searchableDimensionList = useSelector((state) => state.dimension.searchableDimensionList, shallowEqual);
    const { thumbnail_list: users_list } = useSelector((state) => state.user, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const fieldTabs = useSelector(({ fieldProperty }) => fieldProperty.tabs, shallowEqual);

    /**
     * Get Asset and Version Details
     * @params {uuid} versionId
     */
    useEffect(() => {
        dispatch(clearMetricsState());
        dispatch(updateVersionBasicInfo(location?.state?.selectedAsset ?? null));
        dispatch(getVersionRequest(assetId));

        // Get Dimention List
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }

        // Get Users List
        if (!users_list || users_list.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, [dispatch, assetId]);

    /**
     * Connection Type Based Data Retrieve
     */
    useEffect(() => {
        if (versionDetail && versionDetail.type && versionDetail.organization) {
            const connection_type_access = get_connection_type_access(versionDetail.type);
            if (connection_type_access.attributes) {
                const attributeRequestParams = {
                    asset_id: assetId,
                    offset: 0,
                    limit: 20
                };
                dispatch(getAttributesByVersionRequest(assetId));
                dispatch(getAllAttributesMetadataRequest({ params: attributeRequestParams, token: null }));
            }

            // Get Custom Tabs List
            if (versionDetail?.custom_tabs) {
                dispatch(getTabListRequest("asset"));
            }
            setAccessByConnection(connection_type_access);
        }
    }, [dispatch, versionDetail]);

    /**
     * Clear State Values
     */
    useEffect(() => {
        return (() => {
            const location = window.location.pathname;
            if (!location.includes("measure")) {
                dispatch(clearVersionState());
                dispatch(clearAssetState());
                dispatch(clearAttributeState());
                dispatch(clearAllAttributesMetadata());
            }
        });
    }, [dispatch]);

    /**
     * Upload Alert Screenshot
     * @param {*} dataurl
     */
    const uploadScreenShot = (dataurl) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], 'share.jpg', { type: mime });
        dispatch(updateShareDetails({ 'file': croppedImage, 'base64': dataurl }));
        setScreenShotCaptured(true);
    };


    /**
     * Handle Screenshot
     */
    const takeScreenshot = () => {
        let timer = null;
        if (header_div_ref && !screenShotCaptured && !shareImageScreenShot) {
            timer = setTimeout(() => {
                takeShareImageScreenShot(header_div_ref.current).then(uploadScreenShot);
            }, 1000);
        }
        return () => clearTimeout(timer);
    };

    /**
     * Prepare Tabs Based On Connection Type
     * @param {*} connectionType
     * @param {*} assetType
     * @returns
     */
    const prepareTabBasedOnConnectionType = (connectionType, assetType) => {
        if (connectionType) {
            const tabs = appConstants.tabs.assetTabsByConnectionType.filter((tab) => tab.connectionType.indexOf(connectionType.toLowerCase()) > -1 || tab.connectionType.indexOf('all') > -1);
            if (connectionType.toLowerCase() === "databricks" && assetType.toLowerCase() === "pipeline") {
                const databricksTabs = [appConstants.tabs.assetTabsByConnectionType[2], appConstants.tabs.assetTabsByConnectionType[3]];
                return databricksTabs;
            }
            return tabs;
        }
        return [];
    };
    const tabs = useMemo(() => prepareTabBasedOnConnectionType(versionDetail.type, versionDetail.asset_type), [versionDetail]);

    /**
     * Prepare CustomTab
     * @param {*} customFieldTabs
     * @returns
     */
    const prepareCustomTabs = (customFieldTabs) => {
        return customFieldTabs.map((obj) => {
            return {
                name: obj.name,
                path: `custom-tab/${obj.id}`,
                active_path: `asset/.*/custom-tab/${obj.id}`,
                featureName: 'Assets',
                subFeatureName: 'fields',
                restricted: false
            };
        });
    };
    const customTabs = useMemo(() => prepareCustomTabs(fieldTabs), [fieldTabs]);

    return (
        <Grid container className={classes.overviewPageContainer} ref={header_div_ref}>
            <Header data={versionDetail} accessByConnection={accessByConnection} takeScreenshot={takeScreenshot} />
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid className={classes.tabsHeaderSection} container wrap="nowrap">
                    {
                        versionDetail?.id &&
                        <NavigationTabHeaderComponent tabList={permissionTabs(permission, [...appConstants.tabs.datasetOverviewTabs, ...tabs, ...customTabs])} />
                    }
                </Grid>
                <Grid className={classes.tabsBodySection}>
                    <Grid className={classes.tabsBody}>
                        <Outlet context={scrollRef} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AssetOverview.defaultProps = {
    classes: {}
};

// prop types
AssetOverview.propTypes = {
    classes: PropTypes.object,
    scrollRef: PropTypes.instanceOf(HTMLDivElement)
};

export default withStyles(
    (theme) => ({
        ...DatasetOverviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AssetOverview);