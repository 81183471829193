import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getFieldsRequest, getFieldsSuccess, getFieldsFailure, createFieldsRequest, createFieldsSuccess, createFieldsFailure,
    updateFieldsRequest, updateFieldsSuccess, updateFieldsFailure, deleteFieldsRequest, deleteFieldsSuccess, deleteFieldsFailure, getFieldsTabRequest, getFieldsTabSuccess, getFieldsTabFailure, getFieldsGroupRequest, getFieldsGroupSuccess, getFieldsGroupFailure
} from '../reducer/fieldsReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { fieldsService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

/**
 * Get Fields
 * @returns
 */
const list = (action$) => action$.pipe(
    ofType(getFieldsRequest),
    mergeMap(() => {
        return from(fieldsService.list()).pipe(
            concatMap((res) => { return of(getFieldsSuccess(res)); }),
            catchError((error) => { return of(getFieldsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create Fields
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(createFieldsRequest),
    mergeMap(({ payload }) => {
        return from(fieldsService.create(payload)).pipe(
            concatMap((res) => { return of(createFieldsSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created })); }),
            catchError((error) => { return of(createFieldsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Fields
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateFieldsRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(fieldsService.update(id, rest)).pipe(
            concatMap((res) => { return of(updateFieldsSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
            catchError((error) => of(updateFieldsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Dimension
 * @param {*} action$
 * @returns
 */
const deleteFields = (action$) => action$.pipe(
    ofType(deleteFieldsRequest),
    mergeMap(({ payload }) => {
        return from(fieldsService.remove(payload)).pipe(
            concatMap(() => of(deleteFieldsSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
            catchError((error) => of(deleteFieldsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Tabs
 * @returns
 */
const getTabs = (action$) => action$.pipe(
    ofType(getFieldsTabRequest),
    mergeMap(() => {
        return from(fieldsService.getTabs()).pipe(
            concatMap((res) => { return of(getFieldsTabSuccess(res)); }),
            catchError((error) => { return of(getFieldsTabFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get Groups
 * @returns
 */
const getGroups = (action$) => action$.pipe(
    ofType(getFieldsGroupRequest),
    mergeMap(() => {
        return from(fieldsService.getGroups()).pipe(
            concatMap((res) => { return of(getFieldsGroupSuccess(res)); }),
            catchError((error) => { return of(getFieldsGroupFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);
// Export All Fields Functions
export const fieldsEpic = [list, create, update, deleteFields, getTabs, getGroups];