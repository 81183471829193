import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, Fade, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { AddPatternComponent, TableComponent } from '../../components/index.js';

//  Import Styles
import QualityStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Constants
import appConstants from '../../constants/appConstants.js';
import { getMeasureRequest, updateMeasureRequest, updateMeasureProperty, createMeasureRequest, deleteMeasureRequest } from '../../redux/reducer/baseMeasureReducer';
import { getDimensionRequest } from '../../redux/reducer/dimensionReducer';
import { checkPermission, disableFlags, permissionHeaders } from '../../helpers/appHelpers.js';
import featureConstants from '../../constants/featureConstants.js';

// Import Image

// Dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function SubMeasures(props) {
    /**
     * Define Props
     */
    const { classes, type, open, onClose } = props;
    const dispatch = useDispatch();
    const { permission } = useSelector((state) => state.auth);
    const qualityPermission = checkPermission(permission, featureConstants.settings.quality);
    const defaultSearchData = {
        "name": "",
        "description": "",
        "is_positive": "All"
    };

    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: false,
            width: '15%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name'
        },
        {
            key: 'description',
            name: 'Description',
            sorting: true,
            tooltip: true,
            width: '25%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'description'
        },
        {
            key: 'type',
            name: 'Category',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'type'
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_active'
        },
        {
            key: 'is_positive',
            name: 'Is Valid',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Valid', 'Invalid'],
            searchKey: 'is_positive',
            isDisabled: (data, header) => disableFlags(data, header)
        }
    ];

    const permissionOptions = (options) => {
        const filteredOption = [...options];
        if (!qualityPermission?.is_edit) {
            const index = filteredOption.findIndex(
                (option) => option.type === 'add'
            );
            filteredOption.splice(index, 1);
        }
        return filteredOption;
    };

    /**
     * Redux Store to get values
     */
    const { patterns: measures, isLoading } = useSelector((state) => state.baseMeasure);
    const searchableDimensionList = useSelector((state) => state.dimension.searchableDimensionList);

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({ ...defaultSearchData });
    const [patternData, setPatternData] = useState(null);
    const [sorting, setSorting] = useState({
        sortBy: "",
        orderBy: "asc"
    });
    const { sortBy, orderBy } = sorting;

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = useCallback((sortBy, orderBy) => {
        setSorting({
            sortBy, orderBy
        });
    }, [sorting]);

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const params = {
            id: item.id,
            [key]: value
        };
        dispatch(updateMeasureProperty(params));
        dispatch(updateMeasureRequest(params));
    };

    /**
     * Handle Actions
     * @param {*} data
     * @param {*} actionName
     */
    const onClickActions = (data, actionName, event) => {
        switch (actionName) {
            case 'edit':
                setPatternData({
                    anchorEl: event.target,
                    selectedPattern: { ...data }
                });
                break;
            case 'delete':
                dispatch(deleteMeasureRequest(data?.id));
                break;
            default:
                break;
        }
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Get Measures
     */
    useEffect(() => {
        if (!type || (type && type.toLowerCase() !== "pattern")) {
            return;
        }

        const requestParams = {
            type: type.toLowerCase(),
            list_type: "quality",
            default_type: "default"
        };
        dispatch(getMeasureRequest(requestParams));
    }, [type]);

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Dimention List
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
    }, [dispatch]);

    /**
     * Handle table option events
     */
    const handleTableOptionEvents = (event, type) => {
        event.stopPropagation();
        switch (type) {
            case "add":
                setPatternData({
                    anchorEl: event.currentTarget,
                    selectedPattern: null
                });
                break;
            case "close":
                onClose();
                break;
            default:
                break;
        }
    };

    /**
     * Prepare Filter Measures
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterMeasures = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data].map((obj) => {
            const dimension = obj.dimension || [];
            return {
                ...obj,
                is_custom: !obj.is_default,
                dimension: typeof (dimension) === "string" ? JSON.parse(dimension) : dimension
            };
        });
        const measureDropDownFilters = ["allow_score", "is_drift_enabled", "is_active", "is_positive"];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if ((measureDropDownFilters.includes(key) && searchFilters[key] !== "All") || (searchFilters[key] !== "" && !measureDropDownFilters.includes(key))) {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (measureDropDownFilters.includes(key)) {
                        const value = Boolean(searchFilters[key] === "Active") || Boolean(searchFilters[key] === "Valid");
                        if (item[key] !== value) {
                            return false;
                        }
                    } else {
                        let value = item[key];
                        if (key === "dimension") {
                            value = value.length ? value[0].name : "";
                        }
                        if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Measures using UseMemo
     */
    const filterMeasures = useMemo(() => prepareFilterMeasures(measures, searchData, sorting.sortBy, sorting.orderBy), [measures, searchData, sorting.sortBy, sorting.orderBy]);
    const tableOptions = [
        { type: 'search', customFunction: null },
        { type: 'columns', customFunction: null },
        { type: 'add', customFunction: handleTableOptionEvents },
        { type: 'close', customFunction: handleTableOptionEvents }
    ];
    const hasUserDefinedPatterns = filterMeasures && filterMeasures.filter((item) => item.is_user_defined).length > 0;
    if (hasUserDefinedPatterns) {
        tableHeaders.push({
            key: 'actions',
            name: 'Actions',
            width: '5%',
            align: 'center',
            actions: [
                { type: 'edit', tooltip: true, tooltipText: 'Edit', renderAction: (data) => (data?.is_user_defined && data?.id) },
                { type: 'delete', tooltip: true, tooltipText: 'Delete', renderAction: (data) => (data?.is_user_defined && data?.id) }
            ]
        });
    }

    /**
     * Handle Search
     * @param {*} pattern
     */
    const onSavePattern = (pattern) => {
        const patternMeasure = {
            ...pattern,
            derived_type: ["all"],
            type: 'pattern',
            category: 'custom',
            level: 'attribute',
            properties: {
                sql_pattern: pattern?.pattern?.sql_pattern,
                posix_pattern: pattern?.pattern?.posix_pattern
            },
            is_default: true,
            allow_score: true
        };
        if (pattern?.id) {
            if ("dimension" in patternMeasure) {
                delete patternMeasure.dimension;
            }
            dispatch(updateMeasureRequest({ ...patternMeasure }));
        } else {
            dispatch(createMeasureRequest({ ...patternMeasure, is_user_defined: true }));
        }
        setPatternData({
            anchorEl: null,
            selectedPattern: null
        });
    };


    return (
        <Dialog
            fullScreen
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <Grid container className={classes.qualityPageContainer}>
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid className={`${classes.tabsBodySection} ${classes.subMeasuresTable}`}>
                        <TableComponent
                            stickyHeader
                            title={`Patterns`}
                            description={appConstants.labels.quality.patternsListDesc}
                            styleType="striped"
                            headers={permissionHeaders(tableHeaders, qualityPermission)}
                            data={filterMeasures || []}
                            sortBy={sortBy}
                            orderBy={orderBy}
                            searchData={searchData}
                            onClickSorting={onClickSorting}
                            onCompnentEvent={onCompnentEvent}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickActions={onClickActions}
                            selectComponentList={{ dimension: searchableDimensionList }}
                            options={permissionOptions(tableOptions)}
                            height="calc(100vh - 130px)"
                            NoResultText="No Items Found"
                            isLoading={isLoading}
                        />
                    </Grid>
                </Grid>
                {
                    Boolean(patternData?.anchorEl) &&
                    <AddPatternComponent
                        selectedPattern={patternData?.selectedPattern ? { ...patternData?.selectedPattern, is_default: patternData?.is_user_defined } : null}
                        open={Boolean(patternData?.anchorEl)}
                        anchorEl={patternData?.anchorEl}
                        handleClose={() => setPatternData({ anchorEl: null, selectedPattern: null })}
                        handleUpdatePattern={(pattern) => onSavePattern(pattern)} />
                }
            </Grid>
        </Dialog>
    );
}

// default props
SubMeasures.defaultProps = {
    classes: {},
    type: "",
    open: false,
    onClose: () => { }
};

// prop types
SubMeasures.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...QualityStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SubMeasures);