import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';

//  Import Components
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextBoxComponent, MemoSimpleTabHeaderComponent, TabPanelComponent, LoaderButtonComponent } from '../../../components/index.js';
import Filters from './components/filters/index.jsx';
import WidgetProperties from './components/properties/index.jsx';
import WidgetConfiguration from './components/configuration/index.jsx';
import ChartPreview from './components/chartPreview/index.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../constants/appConstants.js';

// Import Actions
import { updateProperties, getWidgetRequest, clearState } from '../../../redux/reducer/customWidgetReducer';
import { createWidgetRequest, updateWidgetRequest } from '../../../redux/reducer/dashboardReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';

// Import Helpers
import { getWidgetTables, getLocalStorage, saveLocalStorage, removeLocalStorage, getSupportColumns } from '../../../helpers/appHelpers';


function CustomWidget(props) {

    const { classes } = props;
    const dispatch = useDispatch();
    const { id: widgetId } = useParams();

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);

    /**
     * Redux Store
     */
    const detail = useSelector(({ customWidget }) => customWidget.detail);
    const { id: dashboardId, saveLoading } = useSelector(({ dashboard }) => dashboard);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const requestParams = {
            [property]: value
        };
        dispatch(updateProperties(requestParams));
    };


    /**
     * Save Widget
     */
    const onSaveWidget = () => {
        const dashboard = getLocalStorage("dashboard") || dashboardId;
        const requestParams = {
            ...detail,
            dashboard,
            id: widgetId,
            update_type: "widget",
            type: "customWidget",
            properties: {
                ...detail.properties,
                tables: getWidgetTables(detail),
                supportColumn: getSupportColumns(detail)
            }
        };
        if (!widgetId) {
            requestParams.is_template = false;
            dispatch(createWidgetRequest(requestParams));
        } else {
            dispatch(updateWidgetRequest(requestParams));
        }
    };

    /**
     * Get Widget Detail
     */
    useEffect(() => {
        if (widgetId) {
            dispatch(getWidgetRequest(widgetId));
        }
        if (dashboardId) {
            saveLocalStorage("dashboard", dashboardId);
        }
    }, []);

    /**
     * onCancel
     */
    const onCancel = () => {
        dispatch(clearState());
        dispatch(navigate({ path: 'home.dashboard', state: {}, params: [] }));
        removeLocalStorage('dashboard');
    };

    return (
        <ValidatorForm className="w-100" onSubmit={() => onSaveWidget()}>
            <DndProvider backend={HTML5Backend}>
                <Grid item xs={12} className={classes.newWidgetContainer}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item className={classes.newWidgetHeader}>
                            <Grid className={classes.titleContainer}>
                                <TextBoxComponent
                                    variant="standard"
                                    noOutline
                                    value={detail.name || ""}
                                    placeholder="New Widget"
                                    validators={['required']}
                                    errorMessages={[appConstants.errorMessages.nameRequired]}
                                    onChange={(event) => onChange("name", event.target.value)}
                                    valOnChange
                                />
                            </Grid>
                            <TextBoxComponent
                                fullWidth
                                variant="standard"
                                noOutline
                                value={detail.description || ""}
                                placeholder="Enter your description here"
                                onChange={(event) => onChange("description", event.target.value)}
                            />
                        </Grid>
                        <Grid item className="ddflex alignCenter">

                            <Button variant="outlined" color="primary" size="small" className="mr-2" onClick={() => onCancel()}>
                                Cancel
                            </Button>
                            <LoaderButtonComponent
                                size={'small'}
                                isLoading={saveLoading}>
                                {appConstants.save}
                            </LoaderButtonComponent>

                        </Grid>
                    </Grid>
                    <Grid container className={classes.bodyContainer}>
                        <Grid className={classes.assetConatiner}>
                            <Grid
                                container
                                wrap="nowrap"
                                className={classes.tabHeaderContainer}
                            >
                                <MemoSimpleTabHeaderComponent
                                    className={classes.tabsHeader}
                                    tabStyle={'inline'}
                                    tabIndex={tabIndex}
                                    tabList={appConstants.tabs.widgetTabs}
                                    onTabChange={(newValue) => setTabIndex(newValue)}
                                />
                            </Grid>
                            {
                                tabIndex === 0 &&
                                <TabPanelComponent value={0} index={tabIndex}>
                                    <WidgetProperties widgetType={detail.widget_type} properties={detail?.properties ?? {}} />
                                </TabPanelComponent>
                            }
                            {
                                tabIndex === 1 &&
                                <TabPanelComponent value={1} index={tabIndex}>
                                    <Filters onChange={(property, value) => onChange(property, value)} />
                                </TabPanelComponent>
                            }
                        </Grid>
                        <Grid item>
                            <Grid className={classes.sectionHeader}>
                                <Typography variant="h3">
                                    Configure
                                </Typography>
                            </Grid>
                            <WidgetConfiguration data={detail?.properties ?? {}} />
                        </Grid>
                        <Grid item className={classes.chartContainer}>
                            {/* <Grid className={classes.sectionHeader}>
                                <Typography variant="h3" />
                            </Grid> */}
                            <ChartPreview />
                        </Grid>
                    </Grid>
                </Grid>
            </DndProvider>
        </ValidatorForm>
    );
}

// default props
CustomWidget.defaultProps = {
    classes: {}
};

// prop types
CustomWidget.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CustomWidget);