import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Grid } from '@mui/material';

//  Import Components
import DomainCard from '../components/card/index.jsx';
import { LoaderComponent } from '../../../components/index.js';

//  Import Styles
import ApplicationsStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Actions
import { getApplicationsRequest } from '../../../redux/reducer/applicationReducer.js';

function Applications(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { applications, isLoading } = useSelector((state) => state.applications, shallowEqual);


    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(getApplicationsRequest());
    }, [dispatch]);


    return (
        <Grid container className={classes.applicationsPageContainer} spacing={4}>
            {
                applications.map((item, index) => (
                    <Grid item xl={3} sm={4} key={index}>
                        <DomainCard
                            data={item}
                            type="applications"
                        />
                    </Grid>
                ))
            }
            {isLoading && <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={3} cardHeight="175px" loaderType="card" height="auto" />}
        </Grid>
    );
}

// default props
Applications.defaultProps = {
    classes: {}
};

// prop types
Applications.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ApplicationsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Applications);