import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';

//  Import Components
import Header from './components/header/index.jsx';
import StyleGuideColors from './items/colors.jsx';
import StyleGuideText from './items/text.jsx';
import StyleGuideButton from './items/button.jsx';
import StyleGuideColorShades from './items/colorShades.jsx';

//  Import Styles
import StyleGuideListStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

function StyleGuideList(props) {

    /**
     * Define Props
     */
    const { classes, data, handleThemeChangeEvent, handleThemeReset, handleThemeSave, editPermission } = props;

    return (
        <Grid className={classes.themeList}>
            <Header />
            <StyleGuideColors data={data.colors || {}} onThemeChange={(value) => handleThemeChangeEvent(value, 'colors')} />
            <StyleGuideText data={data.headers || {}} onThemeChange={(value) => handleThemeChangeEvent(value, 'headers')} />
            <StyleGuideButton data={data.buttons || {}} onThemeChange={(value) => handleThemeChangeEvent(value, 'buttons')} />
            <StyleGuideColorShades data={data.greyshades || {}} onThemeChange={(value) => handleThemeChangeEvent(value, 'greyshades')} />
            {
                editPermission &&
                <Grid className={classes.styleGuideAction}>
                    <Button variant="outlined" color="primary" size="small" className="mr-2" onClick={handleThemeReset}>
                        Reset
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={handleThemeSave} disableElevation>
                        Save
                    </Button>
                </Grid>
            }
        </Grid>
    );
}

// default props
StyleGuideList.defaultProps = {
    classes: {},
    data: {},
    handleThemeChangeEvent: () => { },
    handleThemeReset: () => { },
    handleThemeSave: () => { },
    editPermission: false
};

// prop types
StyleGuideList.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleThemeChangeEvent: PropTypes.func,
    handleThemeReset: PropTypes.func,
    handleThemeSave: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...StyleGuideListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StyleGuideList);