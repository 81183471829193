import { createSlice } from '@reduxjs/toolkit';
import appConstants from '../../constants/appConstants';
import { convertUTCtoLocalDateTime, getDurationText } from '../../helpers';

const initialState = {
  isLoading: false,
  executionLogs: {
    isTaskLoading: false,
    errorDetail: "",
    isLoading: false,
    isLoadMore: true,
    list: [],
    stat: {},
    searchConfig: { limit: 20, offset: 0, sortBy: '', orderBy: '', search: "" },
    connection_is_active: false,
    connection_has_license: false
  },
  notificationLogs: {
    isLoading: false,
    count: 0,
    list: [],
    isLoadMore: true,
    filter: {
      limit: 20,
      offset: 0,
      is_viewed: true
    }
  },
  notificationStats: 0
};

const logsSlice = createSlice({
  name: 'logs',
  initialState: { ...initialState },
  reducers: {
    getExecutionLogsRequest(state, action) {
      state.executionLogs.isLoading = true;
      if (action.payload.clear) {
        state.executionLogs.list = [];
      }
    },
    getExecutionLogsSuccess(state, action) {
      state.executionLogs.isLoading = false;
      const executionLogs = action.payload.data.execution_logs;
      for (const log of executionLogs) {
        log.run_status = log.status;
        log.start = convertUTCtoLocalDateTime(log.start_time);
        log.end = convertUTCtoLocalDateTime(log.end_time);
        log.durationText = getDurationText(log.duration);

        const queueDetails = log.queue_details;
        for (const queuedetail of queueDetails) {
          queuedetail.run_status = queuedetail.status;
          if (queuedetail.task_config && typeof (queuedetail.task_config) === "string") {
            queuedetail.task_config = JSON.parse(queuedetail.task_config);
          }
          queuedetail.start = convertUTCtoLocalDateTime(queuedetail.start_time);
          queuedetail.end = convertUTCtoLocalDateTime(queuedetail.end_time);
          queuedetail.durationText = getDurationText(queuedetail.duration);
        }
        log.queue_details = queueDetails;
        log.filtered_queue_details = JSON.parse(JSON.stringify(log.queue_details));
      }
      if (action.payload.request_data?.isRefresh) {
        state.executionLogs.connection_id = action.payload.request_data.connection_id;
        state.executionLogs.list = executionLogs ? [...executionLogs] : [];
      } else {
        state.executionLogs.list = executionLogs ? [...state.executionLogs.list, ...executionLogs] : state.executionLogs.list;
      }
      state.executionLogs.stat = action.payload.data.statistics ? { ...action.payload.data.statistics } : state.executionLogs.stat;
      state.executionLogs.searchConfig.offset = action.payload.request_data?.params?.offset ?? state.executionLogs.searchConfig.offset;
      state.executionLogs.isLoadMore = (state.executionLogs.stat?.Total > state.executionLogs.list.length);
      state.executionLogs.connection_is_active = action.payload.data?.connection_is_active ?? false;
      state.executionLogs.connection_has_license = action.payload.data?.connection_has_license ?? false;
    },
    getExecutionLogsFailure(state) { state.executionLogs.isLoading = false; },
    getDetailedLogRequest(state) {
      state.executionLogs.isTaskLoading = true;
      state.executionLogs.errorDetail = "";
    },
    getDetailedLogSuccess(state, action) {
      state.executionLogs.isTaskLoading = false;
      const taskId = action.payload.request_data?.taskId || null;
      state.executionLogs.errorDetail = action.payload.error_log || appConstants.dialogBox.noDetailedLogMessage;
      if (taskId && action.payload.error_log?.length > 0) {
        const logs = state.executionLogs.list.map((queue) => {
          let queue_details = JSON.parse(JSON.stringify(queue.queue_details));
          queue_details = queue_details.map((task) => {
            let errorLog = task.error_log || "";
            if (task.id === taskId) {
              errorLog = action.payload.error_log || "";
            }
            return { ...task, error_log: errorLog };
          });
          return { ...queue, queue_details, filtered_queue_details: queue_details };
        });
        state.executionLogs.list = JSON.parse(JSON.stringify(logs));
      }
    },
    getDetailedLogFailure(state) {
      state.executionLogs.isTaskLoading = false;
      state.executionLogs.errorDetail = appConstants.dialogBox.noDetailedLogMessage;
    },
    getNotificationLogsRequest(state, action) {
      state.notificationLogs.isLoading = true;
      if (action.payload.clearList) {
        state.notificationLogs.filter.is_viewed = action.payload.is_viewed;
        state.notificationLogs.list = [];
        state.notificationLogs.isLoading = true;
        state.notificationLogs.isLoadMore = true;
      }
    },
    getNotificationLogsSuccess(state, action) {
      state.notificationLogs.list = action.payload.data ? [...state.notificationLogs.list, ...action.payload.data] : state.notificationLogs.list;
      state.notificationLogs.filter.offset = action.payload?.offset ?? state.notificationLogs.filter.offset;
      if (action.payload.data && (!action.payload.data.length || action.payload.data.length < state.notificationLogs.filter.limit)) {
        state.notificationLogs.isLoadMore = false;
      }
      state.notificationLogs.isLoading = false;
    },
    getNotificationLogsFailure() { },
    updateNotificationLogsRequest() { },
    updateNotificationLogsSuccess(state, action) {
      const notificationList = [...state.notificationLogs.list];
      const index = notificationList.findIndex((notification) => notification.id === action.payload.id);
      if (index >= 0) {
        if (action.payload.is_viewed) {
          notificationList.splice(index, 1);
        } else {
          notificationList[index] = {
            ...notificationList[index],
            is_viewed: true
          };
        }
        state.notificationLogs.list = [...notificationList];
      }

      if (state.notificationStats > 0) {
        state.notificationStats -= 1;
      }
    },
    updateNotificationLogsFailure() { },
    getNotificationsStatsRequest() { },
    getNotificationsStatsSuccess(state, action) {
      state.notificationStats = action?.payload?.data ?? 0;
    },
    getNotificationsStatsFailure() { },
    updateNotificationsStats(state, action) {
      state.notificationStats = action?.payload?.data?.notifications ?? 0;
    },
    updateExecutionLogs(state, action) {
      const { id: queueId, isOpen, queueDetailFilters, ...rest } = action.payload;
      state.executionLogs.list.map((log) => {
        log.isOpen = false;
        return log;
      });
      const queueIndex = state.executionLogs.list.findIndex((item) => item.id === queueId);
      if (queueIndex > -1) {
        const queue = state.executionLogs.list[queueIndex];
        let filtered_queue_details = JSON.parse(JSON.stringify(queue.queue_details));
        if (queueDetailFilters && queueDetailFilters.status) {
          filtered_queue_details = filtered_queue_details.filter((item) => item.status === queueDetailFilters.status);
        }
        queue.filtered_queue_details = filtered_queue_details;
        const selectedQueue = { ...queue, isOpen, ...rest };
        state.executionLogs.list.splice(queueIndex, 1, selectedQueue);
      }
    },
    triggerTaskRequest() { },
    triggerTaskSuccess(state, action) {
      const { queue_id, task_id, type } = action.payload.request_data;
      const queueIndex = state.executionLogs.list.findIndex((item) => item.id === queue_id);
      if (queueIndex > -1) {
        const queue = state.executionLogs.list[queueIndex];
        const selectedQueue = { ...queue, status: 'Pending', run_status: 'Pending' };
        if (type === "job") {
          selectedQueue.queue_details = selectedQueue.queue_details.map((item) => {
            item.status = 'Pending';
            item.run_status = 'Pending';
            return item;
          });
          selectedQueue.filtered_queue_details = JSON.parse(JSON.stringify(selectedQueue.queue_details));
        } else {
          const queueDetailIndex = selectedQueue.queue_details.findIndex((item) => item.id === task_id);
          if (queueDetailIndex > -1) {
            const queueDetail = selectedQueue.queue_details[queueDetailIndex];
            const selectedQueueDetail = { ...queueDetail, status: 'Pending', run_status: 'Pending' };
            selectedQueue.queue_details.splice(queueDetailIndex, 1, selectedQueueDetail);
            selectedQueue.filtered_queue_details = JSON.parse(JSON.stringify(selectedQueue.queue_details));
          }
        }
        state.executionLogs.list.splice(queueIndex, 1, selectedQueue);
      }
    },
    triggerTaskFailure() { },
    clearExecutionLogs(state) {
      state.executionLogs = {
        isLoading: false,
        isLoadMore: true,
        list: [],
        stat: {},
        searchConfig: { limit: 20, offset: 0, sortBy: '', orderBy: '', search: "" },
        connection_is_active: false,
        connection_has_license: false
      };
    }
  }
});

export const { getExecutionLogsRequest, getExecutionLogsSuccess, getExecutionLogsFailure, getNotificationLogsRequest, getNotificationLogsSuccess,
  getNotificationLogsFailure, updateNotificationLogsRequest, updateNotificationLogsSuccess, updateNotificationLogsFailure, getNotificationsStatsRequest,
  getNotificationsStatsSuccess, getNotificationsStatsFailure, updateExecutionLogs, updateNotificationsStats,
  triggerTaskRequest, triggerTaskSuccess, triggerTaskFailure, clearExecutionLogs, getDetailedLogRequest, getDetailedLogSuccess, getDetailedLogFailure
} = logsSlice.actions;
export default logsSlice.reducer;