import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from "@mui/material";
import { withStyles } from '@mui/styles';
import _ from 'lodash';


// import Styles
import style from './style.jsx';


const SimpleTabHeaderComponent = (props) => {

    /**
     * Define Props and State
     */
    const { classes, tabList, onTabChange, tabIndex, tabStyle, hideTabsList } = props;

    /**
     * Filter Tab List
     * @param {*} tabList
     * @param {*} hideTabsList
     */
    const filterList = (tabList, hideTabsList) => {
        if (hideTabsList.length) {
            return tabList.filter((item) => hideTabsList.indexOf(item.toLowerCase()) >= 0);
        }
        return tabList;
    };

    /**
     * Get Tab List
     */
    const list = useMemo(() => filterList(tabList, hideTabsList), [tabList, hideTabsList]);


    /**
     * Handle Change Event
     * @param {*} event
     * @param {*} newValue
     */
    const handleChange = (event, newValue) => {
        onTabChange(newValue);
    };

    /**
     * Set Class Name
     */
    let clsName = classes.btnTabsInline;
    if (tabStyle === "grey") {
        clsName = classes.btnTabsGrey;
    } else if (tabStyle === 'blue') {
        clsName = classes.btnTabsBlue;
    } else {
        clsName = classes.btnTabsInline;
    }

    return (
        <Tabs
            value={tabIndex}
            onChange={(event, value) => handleChange(event, value)}
            aria-label="defaultTab"
            className={`${clsName} btnTabsInline`}
        >
            {
                list && list.map((data, index) => (
                    <Tab label={data} key={index} disableRipple />
                ))
            }
        </Tabs>
    );
};


// Define Prop Type
SimpleTabHeaderComponent.propTypes = {
    tabList: PropTypes.array,
    classes: PropTypes.object,
    onTabChange: PropTypes.func,
    tabIndex: PropTypes.number,
    tabStyle: PropTypes.string,
    hideTabsList: PropTypes.array
};


// Default Props
SimpleTabHeaderComponent.defaultProps = {
    tabList: [],
    classes: {},
    onTabChange: () => { },
    tabIndex: 0,
    tabStyle: "grey",
    hideTabsList: []
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.tabIndex, nextProps.tabIndex) && _.isEqual(prevProps.hideTabsList, nextProps.hideTabsList) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.tabList, nextProps.tabList);
}

export default withStyles(style)(SimpleTabHeaderComponent);

/**
 * Export As Memo Component
 */
export const MemoSimpleTabHeaderComponent = withStyles(style)(React.memo(SimpleTabHeaderComponent, areEqual));