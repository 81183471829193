import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';


//  Import Styles
import PreviewQueryStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { MemoSimpleTabHeaderComponent, TabPanelComponent, StringToHTML, PopOverComponent, AutoCompleteComponent } from '../../../../../components/index.js';
import PreviewQueryResult from './results.jsx';
import MeasureAdd from '../../../properties/measure/add/index.jsx';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

// Import Images
import { CloseIcon, CopyIcon, SyncIcon } from '../../../../../assets/svg/index.js';

// Import Actions
import { getAttributesByVersionRequest } from '../../../../../redux/reducer/attributeReducer';
import { displyAlert } from '../../../../../redux/reducer/alertReducer';
import { clearQueryState } from '../../../../../redux/reducer/usageReducer';
import { updateMeasureDetail } from '../../../../../redux/reducer/measureReducer';


function PreviewQueryComponent(props) {

    /**
     * Define Props
     */
    const { classes, name, report_mapped_asset_id, rawSql, compiledSql, closeDialog } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { list: attributes } = useSelector((state) => state.attribute);


    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState(null);


    /**
     * Get Attribute List By AssetID
     */
    useEffect(() => {
        if (report_mapped_asset_id) {
            dispatch(getAttributesByVersionRequest(report_mapped_asset_id));
        }
    }, [report_mapped_asset_id]);

    /**
     * OnClose
     */
    const onClose = () => {
        closeDialog();
    };

    /**
     * Copy Connection Path to Keyboard
     */
    const copyToClipBoard = (copyData) => {
        navigator.clipboard.writeText(copyData);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    /**
     * Get Query Preview Header
     * @returns
     */
    const getQueryHeader = () => {
        if (report_mapped_asset_id) {
            return `SQL Code (Test): ${name}`;
        }
        return name;
    };

    /**
     * Prepare Attribute List
     * @returns
     */
    const prepareAttributeList = () => {
        return [{ id: 'All', name: 'All', derived_type: 'All' }, ...attributes];
    };


    /**
     * Show Convert Rule Measure Dialog
     */
    const convertRule = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * On Select Attribute
     * @param {*} value
     */
    const onSelectAttribute = (value) => {
        setSelectedAttribute(value);
        setAnchorEl(null);
        const requestParams = {
            name: name,
            query: compiledSql.replace(/^\s+|\s+$/g, ''),
            category: 'query',
            asset: report_mapped_asset_id
        };
        if (value.id !== "All") {
            requestParams.attribute = value.id;
        }
        dispatch(updateMeasureDetail(requestParams));
        dispatch(clearQueryState());
    };

    /**
     * Handle Measure Dialog Close
     */
    const handleMeasureDialogClose = () => {
        setSelectedAttribute(null);
    };

    return (
        <Grid item xs={12}>
            <Grid item xs={12} className={classes.QueryContainer}>
                <Grid className="dflex alignCenter spaceBetween header pb5">
                    <Typography variant="h2">
                        {getQueryHeader()}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                    >
                        <MemoSimpleTabHeaderComponent
                            className={classes.tabsHeader}
                            tabStyle={'inline'}
                            tabIndex={tabIndex}
                            tabList={appConstants.tabs.dbtPrevTabs}
                            onTabChange={(newValue) => setTabIndex(newValue)}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <Grid className={classes.tabsBody} style={{ height: `${report_mapped_asset_id ? '33vh' : 'calc(100vh - 148px)'}` }}>
                            {
                                tabIndex === 0 &&
                                <TabPanelComponent value={0} index={tabIndex}>
                                    <Grid className="codeContainer">
                                        <Grid className="codeActions">
                                            <Tooltip title={'Copy Sql'} arrow>
                                                <IconButton className="copyBtn" onClick={() => copyToClipBoard(compiledSql)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                report_mapped_asset_id &&
                                                <Tooltip title={'Convert Rule'} arrow>
                                                    <IconButton className="convertMeasure" onClick={(event) => convertRule(event)}>
                                                        <SyncIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Grid>
                                        <StringToHTML data={compiledSql.replace(/^\s+|\s+$/g, '')} />
                                    </Grid>
                                </TabPanelComponent>
                            }
                            {
                                tabIndex === 1 &&
                                <TabPanelComponent value={1} index={tabIndex}>
                                    <Grid className="codeContainer">
                                        <Grid className="codeActions">
                                            <Tooltip title={'Copy Sql'} arrow>
                                                <IconButton className="copyBtn" onClick={() => copyToClipBoard(rawSql)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <StringToHTML data={rawSql.replace(/^\s+|\s+$/g, '')} />
                                    </Grid>
                                </TabPanelComponent>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    report_mapped_asset_id &&
                    <PreviewQueryResult
                        asset_id={report_mapped_asset_id}
                        query={compiledSql.replace(/^\s+|\s+$/g, '')}
                    />
                }
                {
                    Boolean(anchorEl) &&
                    <PopOverComponent
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <ValidatorForm onSubmit={() => { }}>
                            <Grid className={classes.popOverContainer}>
                                <AutoCompleteComponent
                                    data={prepareAttributeList() || []}
                                    customOption
                                    blurOnSelect
                                    fullWidth
                                    labelKey={"name"}
                                    renderType={'attributes'}
                                    onChange={(event, newValue) => onSelectAttribute(newValue)}
                                    placeholder="Select Attribute"
                                />
                            </Grid>
                        </ValidatorForm>
                    </PopOverComponent>
                }
                {
                    selectedAttribute &&
                    <MeasureAdd
                        open={Boolean(selectedAttribute)}
                        attributes={attributes}
                        attribute_id={selectedAttribute.id !== "All" ? selectedAttribute.id : null}
                        asset_id={report_mapped_asset_id}
                        isAssetLevel={selectedAttribute.id === "All"}
                        handleMeaureAddEditDialog={handleMeasureDialogClose}
                    />
                }
            </Grid>
        </Grid >
    );
}

// default props
PreviewQueryComponent.defaultProps = {
    classes: {},
    name: 'SQL Code',
    rawSql: '',
    compiledSql: '',
    report_mapped_asset_id: "",
    closeDialog: () => { }
};

// prop types
PreviewQueryComponent.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    rawSql: PropTypes.string,
    compiledSql: PropTypes.string,
    report_mapped_asset_id: PropTypes.string,
    closeDialog: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.rawSql, nextProps.rawSql)
        && _.isEqual(prevProps.compiledSql, nextProps.compiledSql)
        && _.isEqual(prevProps.report_mapped_asset_id, nextProps.report_mapped_asset_id)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export As Memo Component
 */
export const PreviewQuery = withStyles(
    (theme) => ({
        ...PreviewQueryStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(PreviewQueryComponent, areEqual));