import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, Typography, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

// Import Components
import { SelectComponent } from '../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

function SelectDashboard(props) {

    /**
     * Define Props
     */
    const { classes, open, onClose, selectDashboard } = props;

    const [dashboard, setDashboard] = useState("");
    const [isSubmit, setSubmit] = useState(false);


    /** Redux Store */
    const { list, id } = useSelector(({ dashboard }) => dashboard);

    /**
     * Filter Selected Dashboard List
     * @param {*} data
     * @param {*} dashboardId
     * @returns
     */
    const getDashobardList = (data, dashboardId) => {
        return data.filter((obj) => obj.id !== dashboardId);
    };

    const dashboardList = useMemo(() => getDashobardList(list, id), [list, id]);


    const onSubmit = () => {
        setSubmit(true);
        if (dashboard) {
            selectDashboard(dashboard);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.selectDialog}
        >
            <ValidatorForm onSubmit={() => onSubmit()}>
                <Grid container spacing={5} className={classes.selectContainer}>
                    <Grid item xs={12} className="dflex spaceBetween w-100">
                        <Grid>
                            <Typography variant="h6">
                                Select Dashboard
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="pt-2">
                        <Grid className="mt-1">
                            <SelectComponent
                                value={dashboard || ""}
                                onSelectChange={(value) => setDashboard(value)}
                                label="Dashboard"
                                variant="standard"
                                list={dashboardList}
                                name="dashboard"
                                propertyName="id"
                                displayPropertyName="name"
                                noOutline />
                            {
                                isSubmit && !dashboard &&
                                <FormHelperText>
                                    Dashboard is required
                                </FormHelperText>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button disableElevation color="primary" variant="outlined" size="small" className="mr-1 cancelBtn" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button disableElevation color="primary" variant="contained" size="small" type="submit">
                            Ok
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Dialog>
    );
}

// default props
SelectDashboard.defaultProps = {
    classes: {},
    open: {},
    onClose: () => { },
    selectDashboard: () => { }
};

// prop types
SelectDashboard.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectDashboard: PropTypes.func
};


export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SelectDashboard);