const style = (theme) => ({
    editorRoot: {
        width: '100%',
        boxShadow: 'none',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        fontFamily: theme.palette.textFont,
        fontSize: 15,
        color: `${theme.palette.headers.body1.color} !important`,
        padding: 0,
        margin: '10px 0px',
        position: 'relative',
        background: '#fff !important',
        '& .public-DraftEditorPlaceholder-root': {
            position: 'absolute'
        },
        '& .DraftEditor-root': {
            padding: 20,
            position: 'relative',
            zIndex: 0
        },
        '& .public-DraftEditorPlaceholder-inner': {
            color: theme.palette.greyshades.darkgrey,
            fontWeight: 400
        },
        '& .editorToolBar': {
            background: theme.palette.greyshades.lightbackground,
            padding: '2px 16px',
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            border: `1px solid ${theme.palette.greyshades.grey}`,
            '& .MuiFormControl-root': {
                minWidth: 124
            },
            '& .MuiRating-root': {
                fontSize: 22,
                color: '#F6C831'
            },
            '& .MuiSelect-select': {
                color: `${theme.palette.greyshades.darkgrey} !important`
            }
        },
        '& .styleBtn': {
            border: 'none',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            height: '34px',
            width: '34px',
            justifyContent: 'center',
            marginRight: '4px',
            marginLeft: '4px',
            cursor: 'pointer',
            '&.active': {
                '& svg': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .notranslate.public-DraftEditor-content': {
            minHeight: '72px',
            maxHeight: '106px',
            overflowY: 'auto'
        },
        '& .editcloseIcon': {
            position: 'absolute',
            right: '1px',
            top: '1px',
            background: '#fff',
            padding: "0px !important",
            '& svg': {
                fontSize: 20,
                fill: theme.palette.greyshades.darkgrey,
                cursor: 'pointer'
            }
        }
    },
    editOtionRoot: {
        '& .SimpleMentionEditor_editor__MOmkb': {
            marginBottom: '0px !important'
        }
    },
    editorWrapper: {
        border: 'none !important',
        background: 'transparent !important',
        boxShadow: 'none !important',
        padding: '0px !important',
        marginBottom: '0px !important',
        '& .SimpleMentionEditor_editor__MOmkb': {
            marginBottom: '0px !important'
        }
    },
    suggestionOptions: {
        fontFamily: theme.palette.textFont,
        fontSize: 14,
        color: `${theme.palette.headers.body1.color} !important`,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: '8px 10px',
        '&:hover': {
            background: `${theme.palette.secondary.main}20 !important`
        }
    }
});
export default style;