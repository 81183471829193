import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    isSyncing: false,
    licenses: []
};

const licenseSlice = createSlice({
    name: 'license',
    initialState: { ...initialState },
    reducers: {
        getLicenseRequest() { },
        getLicenseSuccess(state, action) {
            state.licenses = action.payload.data || [];
        },
        getLicenseFailure() { },
        createLicenseRequest(state) {
            state.isLoading = true;
        },
        createLicenseSuccess(state) {
            state.isLoading = false;
        },
        createLicenseFailure(state) {
            state.isLoading = false;
        },
        syncLicenseRequest(state) {
            state.isSyncing = true;
        },
        syncLicenseSuccess(state) {
            state.isSyncing = false;
        },
        syncLicenseFailure(state) {
            state.isSyncing = false;
        }
    }
});

export const {
    getLicenseRequest, getLicenseSuccess, getLicenseFailure, createLicenseRequest, createLicenseSuccess,
    createLicenseFailure, syncLicenseRequest, syncLicenseSuccess, syncLicenseFailure
} = licenseSlice.actions;
export default licenseSlice.reducer;