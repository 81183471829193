import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import ListStyle from '../style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Components
import Result from './listTabs/result.jsx';
import FailedRows from './listTabs/failedRows.jsx';
import { MemoSimpleTabHeaderComponent, TabPanelComponent } from '../../../../../components/index.js';

//Constant
import appConstants from '../../../../../constants/appConstants.js';

function PreviewTabs(props) {

    /**
     * Define Rules
     */
    const { classes, data, type, removeFailedRows } = props;
    const [tabIndex, setTabIndex] = useState(0);

    /**
     * Sort Chart Data
     */
    let chartData = [];
    if (type === 'Length') {
        chartData = _.orderBy(data, ['length'], ['asc']);
    } else {
        chartData = data;
    }
    let tabs = appConstants.tabs.measureTabs;
    if (removeFailedRows) {
        tabs = [tabs[0]];
    }

    return (
        <Grid item xs={12} className={classes.listContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid
                    container
                    wrap="nowrap"
                    className={classes.tabHeaderContainer}
                >
                    <MemoSimpleTabHeaderComponent
                        className={classes.tabsHeader}
                        tabStyle={'inline'}
                        tabIndex={tabIndex}
                        tabList={tabs}
                        onTabChange={(newValue) => setTabIndex(newValue)}
                    />
                </Grid>
                <Grid className={classes.tabsBodySection}>
                    <Grid className={classes.tabsBody}>
                        {
                            tabIndex === 0 &&
                            <TabPanelComponent value={0} index={tabIndex}>
                                <Result data={chartData} type={type} />
                            </TabPanelComponent>
                        }
                        {
                            tabIndex === 1 &&
                            <TabPanelComponent value={1} index={tabIndex}>
                                <FailedRows />
                            </TabPanelComponent>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
PreviewTabs.defaultProps = {
    classes: {},
    data: [],
    type: "",
    removeFailedRows: false
};

// prop types
PreviewTabs.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    removeFailedRows: PropTypes.bool,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...ListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(PreviewTabs);