// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

// Import Components
import { Grid, Typography } from '@mui/material';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';


// Import Styles
import style from "./style.jsx";

const SunBurstChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, legends, classes } = props;

    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        series: {
            radius: ['15%', '90%'],
            type: 'sunburst',
            data: data,
            label: {
                rotate: 'radial',
                show: false
            },
            itemStyle: {
                borderWidth: 2
            },
            levels: [],
            legend: {}
        },
        tooltip: {
            trigger: "item",
            formatter: (params) => {
                return `${params.data?.name || params.name}   ${params.data?.actualValue || params.data?.value}`;
            }
        }
    };


    return (
        <Grid style={{ width: '100%', height: '100%' }}>
            <ReactEcharts
                option={option}
                opts={{ renderer: "svg" }}
                style={{ height: legends.length ? '95%' : '100%' }}
            />
            {
                legends.length &&
                <Grid className={classes.customLegendContainer}>
                    {
                        legends.map((legend, index) =>
                            <Grid className="legend" key={`legend_${index}`}>
                                <Grid style={{ background: legend.color }} className="rect" />
                                <Typography variant="body2" className="legendText">
                                    {legend.name || ""}
                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>
            }
        </Grid>
    );
};


/**
 * Define Prop Types
 */
SunBurstChartComponent.propTypes = {
    data: PropTypes.array,
    legends: PropTypes.array,
    classes: PropTypes.object
};

/**
 * Set Default Values
 */
SunBurstChartComponent.defaultProps = {
    data: [],
    legends: [],
    classes: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(SunBurstChartComponent, areEqual));