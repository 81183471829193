// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import _ from 'lodash';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';

// Import Styles
import style from "./style.jsx";

const DynamicLineChartComponent = (props) => {
    /**
     * Define Props
     */
    const { chartData, color, type, isStacked, onCallback, chartProperties } = props;

    const colors = [
        "#82B4ED", "#F388EC", "#7CE2E6", "#74C497", "#6C7BF1", "#ECE986",
        "#FCB055", "#89E89C", "#F24B73", "#EC4BF2", "#FBD6D2", "#F190B7", "#B887B2", "#C2777A", "#3EA0C5", "#B8BAF5", "#C193F5", "#63CCBB"
    ];
    /**
     * Define Chart Options
     */

    const series = isStacked ? chartData.data.map((item, index) => {
        return {
            name: item.name,
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
                width: 3,
                color: colors[index]
            },
            itemStyle: {
                color: colors[index]
            },
            emphasis: {
                focus: 'series'
            },
            areaStyle: {
                opacity: type === "line" ? 0 : 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: colors[index]
                    },
                    {
                        offset: 1,
                        color: 'rgba(255, 255, 255, 1)'
                    }
                ])
            },
            data: item.data || item.series
        };
    }) : [
        {
            data: chartData.data,
            type: 'line',
            showSymbol: true,
            color: color !== "" ? color : "#FCE09A",
            smooth: true,
            lineStyle: {
                width: 3,
                color: color !== "" ? color : "#FCE09A"
            },
            itemStyle: {
                color: color !== "" ? color : "#FCE09A"
            },
            emphasis: {
                focus: 'series'
            },
            areaStyle: {
                opacity: type === "line" ? 0 : 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: color !== "" ? color : "#FCE09A"
                    },
                    {
                        offset: 1,
                        color: 'rgba(255, 255, 255, 1)'
                    }
                ])
            }
        }
    ];

    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        /*
         * legend: {
         *     top: "bottom",
         *     padding: 0,
         *     itemGap: 60,
         *     itemWidth: 20,
         *     itemHeight: 10,
         *     textStyle: {
         *         fontSize: 14,
         *         fontFamily: palette.headers.body1.fontFamily,
         *         color: appConstants.chartColors.labelColor
         *     }
         * },
         */
        legend: {
            itemGap: 20,
            bottom: 0,
            data: chartData.data.map((cData) => {
                return {
                    name: isStacked ? cData.name : "",
                    icon: 'rectangle'
                };
            }),
            type: 'scroll',
            orient: 'horizontal'
        },
        grid: {
            left: '3%',
            right: '5%',
            bottom: 50,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis || [],
            boundaryGap: false,
            axisLabel: {
                fontSize: chartProperties.xRotate ? 12 : 14,
                rotate: chartProperties.xRotate || 0,
                width: 130,
                overflow: 'truncate'
            }
        },
        yAxis: {
            type: 'value'
        },
        series: series
    };

    /**
     * Point Click Event
     * @param {*} params
     */
    const onClick = (params) => {
        onCallback(params);
    };

    /**
     * Chart Events
     */
    const onEvents = onCallback ? {
        click: onClick
    } : {};


    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={{ height: '100%' }}
            onEvents={onEvents || {}}
            notMerge
        />
    );
};


/**
 * Define Prop Types
 */
DynamicLineChartComponent.propTypes = {
    chartData: PropTypes.object,
    color: PropTypes.string,
    type: PropTypes.string,
    isStacked: PropTypes.bool,
    onCallback: PropTypes.func,
    chartProperties: PropTypes.object
};

/**
 * Set Default Values
 */
DynamicLineChartComponent.defaultProps = {
    chartData: {},
    color: "",
    type: "line",
    isStacked: false,
    onCallback: () => { },
    chartProperties: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.chartData, nextProps.chartData);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(DynamicLineChartComponent, areEqual));