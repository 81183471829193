import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

//  Import Components
import { Grid, TableContainer, Table, TableHead, TableBody, Paper } from '@mui/material';
import HeaderRowComponent from './headerRow.jsx';
import BodyRowComponent from './bodyRow.jsx';
import TableHeaderComponent from '../tables/components/header/index.jsx';
import TableSearchComponent from '../tables/components/search/index.jsx';
import LazyLoadingBodyRowComponent from '../tables/lazyLoadingBodyRow.jsx';
import { NoResultComponent } from '../index.js';

//  Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import TableStyles from '../tables/style.jsx';

// Import Helpers
import { orderList } from '../../helpers/appHelpers';


function NestedAccordian(props) {
    const { classes, accordian, options, headerProperty, data, onAccordianOnChange,
        searchData, onHandleSearchEvent, onCellClick, userPreferenceColumns, isLoading,
        sortBy, orderBy, onClickSorting, selectComponentList } = props;

    const headers = headerProperty.headers || [];

    const [tableHeaders, setTableHeaders] = useState([]);
    const [columnSearchHeaders, setColumnSearchHeaders] = useState([]);
    const [showSearch, setSearch] = useState(false);
    const [columnEdited, setColumnEdited] = useState(false);
    /**
     * Handle Change Event
     * @param {*} item
     * @param {*} name
     * @param {*} value
     */
    const handleChange = (item, name, value) => {
        if (!item.customFunction) {
            if (item.type === "columns") {
                let tableHeaderList = Object.assign([], tableHeaders);
                let searchHeaders = Object.assign([], columnSearchHeaders);
                if (name === "Select All") {
                    searchHeaders = searchHeaders.map((header) => {
                        return {
                            ...header,
                            showColumn: value
                        };
                    });
                    tableHeaderList = tableHeaderList.map((header) => {
                        return {
                            ...header,
                            showColumn: value
                        };
                    });
                } else {
                    const t_index = tableHeaderList.findIndex((header) => header.name === name);
                    const s_index = searchHeaders.findIndex((header) => header.name === name);
                    tableHeaderList[t_index].showColumn = value;
                    searchHeaders[s_index].showColumn = value;

                    const searchIndex = searchHeaders.findIndex((header) => header.name === "Select All");
                    if (tableHeaderList.length === tableHeaderList.filter((data) => data.showColumn).length) {
                        searchHeaders[searchIndex].showColumn = true;
                    } else {
                        searchHeaders[searchIndex].showColumn = false;
                    }
                }
                setColumnSearchHeaders([...searchHeaders]);
                setTableHeaders([...tableHeaderList]);
                setColumnEdited(true);
                // onColumnChange([...tableHeaderList]);
            } else if (item.type === "search") {
                setSearch(!showSearch);
            }
        }
    };

    /**
     * Set Headers
     * @param {*} headerData
     */
    const loadHeaders = (headerData) => {
        const headerList = headerData.map((header) => {
            const showColumn = userPreferenceColumns.length ? userPreferenceColumns.includes(header.key) : !header.hideDefault;
            return {
                ...header,
                showColumn
            };
        });
        let columnSearchHeaders = headerList.filter((header) => !header.disableColumnSearch);
        columnSearchHeaders = orderList(columnSearchHeaders, 'name', 'asc');
        columnSearchHeaders.unshift({ name: "Select All", isDefault: true, showColumn: headerList.length === headerList.filter((data) => data.showColumn).length });
        setTableHeaders([...headerList]);
        setColumnSearchHeaders([...columnSearchHeaders]);
    };

    /**
     * Load Headers
     */
    useEffect(() => {
        if (((headers.length > 0) || !tableHeaders.length) && !columnEdited) {
            loadHeaders(headers);
        }
    }, [headers]);

    /**
     * Update headers
     */
    useEffect(() => {
        const tableHeaderData = tableHeaders.filter((header) => header.name !== "Select All").map((header) => header.name);
        const headerNames = headers.map((header) => header.name);
        if (columnEdited && headers.length && (JSON.stringify(tableHeaderData) !== JSON.stringify(headerNames))) {
            loadHeaders(headers);
        }
    }, [headers]);

    /**
     * Filter Headers
     * @param {*} data
     * @returns list
     */
    const filterHeaders = (data) => {
        return data.filter((header) => header.showColumn && header.name !== "Select All");
    };
    /**
     * Filter Headers using usememo
     */
    const filteredHeaders = useMemo(() => filterHeaders(tableHeaders), [tableHeaders]);

    return (
        <Grid item xs={12} className={classNames(classes.collapseTable, classes.collapseStyledTable)} >
            {
                (options) &&
                <TableHeaderComponent
                    options={options}
                    headers={columnSearchHeaders}
                    handleChange={handleChange}
                    searchData={searchData}
                />
            }
            <TableContainer component={Paper} className="collapseTableContainer">
                <Table>
                    {
                        headerProperty.showHeader &&
                        <TableHead>
                            <HeaderRowComponent
                                classes={classes}
                                headers={filteredHeaders}
                                sortBy={sortBy}
                                orderBy={orderBy}
                                onClickSorting={(sortBy, orderBy) => onClickSorting(sortBy, orderBy)} />
                            {
                                showSearch &&
                                <TableSearchComponent
                                    headers={filteredHeaders}
                                    onChange={onHandleSearchEvent}
                                    searchData={searchData} />
                            }
                        </TableHead>
                    }
                    <TableBody>
                        {
                            data.map((item, index) =>
                                <BodyRowComponent headerProperty={headerProperty}
                                    accordian={accordian}
                                    classes={classes}
                                    key={index}
                                    data={item}
                                    headers={filteredHeaders}
                                    onAccordianOnChange={onAccordianOnChange}
                                    onCellClick={onCellClick}
                                    selectComponentList={selectComponentList || {}} />
                            )
                        }

                        {
                            isLoading &&
                            <LazyLoadingBodyRowComponent headers={filteredHeaders} haveSubTable={headerProperty.accordian} height={data.length > 0 ? 80 : 100} />
                        }
                    </TableBody>
                </Table>
                {
                    data && data.length === 0 && !isLoading &&
                    <NoResultComponent title={"No Data Found"} />
                }
            </TableContainer>
        </Grid>
    );
}

// default props
NestedAccordian.defaultProps = {
    classes: {},
    accordian: {},
    headers: [],
    data: [],
    options: [],
    searchData: {},
    defaultShowSearch: true,
    userPreferenceColumns: [],
    isLoading: false,
    sortBy: "",
    orderBy: "desc",
    selectComponentList: {},
    onAccordianOnChange: () => { },
    onHandleSearchEvent: () => { },
    onCellClick: () => { },
    onClickSorting: () => { }
};

// prop types
NestedAccordian.propTypes = {
    classes: PropTypes.object,
    accordian: PropTypes.object,
    headerProperty: PropTypes.object,
    data: PropTypes.array,
    onAccordianOnChange: PropTypes.func,
    onHandleSearchEvent: PropTypes.func,
    onCellClick: PropTypes.func,
    options: PropTypes.array,
    searchData: PropTypes.object,
    userPreferenceColumns: PropTypes.array,
    isLoading: PropTypes.bool,
    onClickSorting: PropTypes.func,
    sortBy: PropTypes.string,
    orderBy: PropTypes.string,
    selectComponentList: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...TableStyles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(NestedAccordian);