import React, { useState, useMemo } from 'react';
import { MenuItem, FormControl, Select, Grid, InputLabel, Checkbox, FormControlLabel, Typography, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

// Import Component
import SearchComponent from '../textBox/search.jsx';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import style from "./style.jsx";
import { ChevDownIcon, NoResultIcon } from '../../assets/svg/index.js';

function SelectCheckBoxComponent(props) {

  /**
   * Define Props
   */
  const {
    classes, className, list, value, propertyName, label, noOutline, onSelectChange, variant,
    displayPropertyName, placeholder, isDisabled, showSelectAll, onScrollEnd, noSlice
  } = props;

  const [search, setSearch] = useState("");

  /**
   * Handle list scroll change event
   * @param {*} event
   */
  const onScrollChange = (event) => {
    if ((event.target.scrollHeight - Math.floor(event.target.scrollTop) - 10) <= event.target.clientHeight && onScrollEnd) {
      onScrollEnd();
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      onScroll: onScrollChange,
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        padding: 0
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };


  const getFilterData = (list, search) => {
    let data = [...list];
    if (search.trim()) {
      data = data.filter((obj) => obj[displayPropertyName].toLowerCase().includes(search.toLowerCase()));
    }
    if (showSelectAll && data.length) {
      data.unshift({
        [displayPropertyName]: "Select All",
        [propertyName]: "All"
      });
    }
    return data;
  };

  const filterData = useMemo(() => getFilterData(list, search), [list, search]);

  /**
   * Handle Change
   * @param {*} event
   */
  const handleChange = (option) => {
    let selectedValue = [...value];
    const isAll = option[propertyName] === "All";
    if (selectedValue.includes("All") && !isAll) {
      selectedValue = filterData.filter((obj) => obj[propertyName] !== "All").map((obj) => obj[propertyName]);
    }
    const index = selectedValue.indexOf(option[propertyName]);
    if (index === -1) {
      if (selectedValue.indexOf("All") !== -1) {
        selectedValue.splice(selectedValue.indexOf("All"), 1);
      }
      if (isAll) {
        selectedValue = [];
      }
      selectedValue.push(option[propertyName]);
    } else {
      if (!isAll) {
        selectedValue.splice(index, 1);
      } else {
        selectedValue = [];
      }
    }
    onSelectChange(selectedValue);
  };

  /**
   * Get spliced data
   * @param {*} data
   * @returns
   */
  const getSlicedData = (data, noSlice) => {
    if (noSlice) {
      return data;
    }
    return data.slice(0, 30);
  };

  return (
    <Grid className={`selectComponent ${classes.selectComponent} ${noOutline ? classes.outLineNone : null}`}>
      <FormControl fullWidth>
        <InputLabel>
          {label}
        </InputLabel>
        {
          value.length !== 0 &&
          <Chip
            label={value.indexOf("All") !== -1 ? `Selected All Items` : `Selected ${value.length} Items`}
          />
        }
        <Select
          placeholder={placeholder}
          label={label}
          value={[]}
          variant={variant}
          displayEmpty
          onChange={handleChange}
          className={className}
          MenuProps={MenuProps}
          disabled={isDisabled}
          multiple
          IconComponent={() => <ChevDownIcon />}
        >
          <Grid className={classes.searchContainer}>
            <SearchComponent
              placeholder={"Search"}
              value={search}
              onChange={(value) => setSearch(value)} />
          </Grid>
          {
            getSlicedData(filterData, noSlice).map((option, index) => {
              return (
                <li key={`${index}_option`} className={classes.list}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value.indexOf('All') !== -1 || value.length === list.length || value.indexOf(option[propertyName]) !== -1 || false}
                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                        checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                        onChange={() => handleChange(option)}
                      />
                    }
                    label={
                      <Typography variant="body1">
                        {option[displayPropertyName]}
                      </Typography>
                    }
                  />
                </li>
              );
            })
          }
          {
            !list || list.length === 0 &&
            <MenuItem disabled className="justifyCenter">
              <Grid className="dflex directionColumn">
                <NoResultIcon noresultSize="60px" />
                <span>
                  No Data
                </span>
              </Grid>
            </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
  );
}


/**
 * Define Component Props
 */
SelectCheckBoxComponent.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  list: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  propertyName: PropTypes.string,
  noOutline: PropTypes.bool,
  onSelectChange: PropTypes.func,
  variant: PropTypes.string,
  displayPropertyName: PropTypes.string,
  isDisabled: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  onScrollEnd: PropTypes.func,
  noSlice: PropTypes.bool
};


/**
 * Set Default Values
 */
SelectCheckBoxComponent.defaultProps = {
  className: "",
  classes: {},
  list: [],
  value: '',
  label: '',
  noOutline: false,
  onSelectChange: () => { },
  onScrollEnd: () => { },
  isDisabled: false,
  is_mulitple: false,
  showSelectAll: false,
  noSlice: false
};

export default withStyles((theme) => ({
  ...LayoutStyles(theme),
  ...style(theme)
}))(SelectCheckBoxComponent);