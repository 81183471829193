import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../../components/index';

//Constants
import appConstants from '../../../../../..//constants/appConstants';


function Result(props) {

    /**
     * Define Props
     */
    const { classes, data, type } = props;

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "name", orderBy: "asc"
    });

    /**
     * Get Header Constant
     * @param {*} type
     * @returns
     */
    const getHeader = (type) => {
        switch (type) {
            case "Length":
                return appConstants.table.statisticsQualityLengthTable;
            case "Pattern":
                return appConstants.table.statisticsQualityPatternTable;
            default:
                return appConstants.table.statisticsQualityTable;
        }
    };

    /**
     * Convert Data for Table view
     */
    const convertedData = data.map((datum) => {
        if (type === "Pattern") {
            const { id, count, measure_id, name, pattern } = datum;
            return { id, count, measure_id, name, is_valid: Boolean(datum.is_positive).toString(), posix_pattern: typeof (pattern.posix_pattern) === "object" ? JSON.stringify(pattern.posix_pattern) : pattern.posix_pattern, sql_pattern: typeof (pattern.sql_pattern) === "object" ? JSON.stringify(pattern.sql_pattern) : pattern.sql_pattern };
        } else if (type === "Enum") {
            return { ...datum, is_valid: Boolean(datum.is_valid).toString() };
        }
        const { length, count, is_valid } = datum;
        return { name: length, count, is_valid: Boolean(is_valid).toString() };
    });

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const handleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const handlSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (searchableData, searchFilters, sortBy, orderBy) => {
        let filterData = [...searchableData];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };


    /**
     * Filter Data using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(convertedData || [], searchData, sorting.sortBy, sorting.orderBy), [convertedData || [], searchData, sorting.sortBy, sorting.orderBy]);


    return (
        <Grid item xs={12} className={classes.ResultContainer}>
            <TableComponent
                styleType="striped"
                options={[{ type: "search", customFunction: null }]}
                headers={getHeader(type)}
                data={filterData || []}
                NoResultText="No Results Found"
                height="calc(100vh - 300px)"
                searchData={searchData}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                onClickSorting={handlSorting}
                onHandleSearchEvent={handleSearchEvent}
                stickyHeader
            />
        </Grid>
    );
}

// default props
Result.defaultProps = {
    classes: {},
    data: [],
    type: ""
};

// prop types
Result.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Result);