import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography } from '@mui/material';
// import Slider from "react-slick";

// Styles
import FeaturesStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import ImageCropper from '../../../../components/imageCrop/ImageCropper.jsx';
import { DialogComponent, SliderComponent } from '../../../../components/index.js';


// Import Images
import { ChevLeftIcon, ChevRightIcon, DeleteIcon, PlusIcon } from '../../../../assets/svg';

// Import Constatns
import appConstants from '../../../../constants/appConstants';

function BannerSection(props) {

    /**
     * Define Props
     */
    const { classes, handleBannerImages, banner_list, delete_banner, editPermission } = props;
    const containerRef = useRef();

    /**
     * Set State
     */
    const [carouselRef, setCarouselRef] = useState(null);
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);
     const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });


    // Handle Banner Upload Image
    const handleImgUpload = () => {
        document.getElementById('domainImage').click();
    };

    /**
     * Handle image selection
     * @param {*} event
     */
     const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };


    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        handleBannerImages(croppedImage, croppedImageUrl);
    };

    /**
     * Handle Carosel Event
     * @param {*} slider
     */
    const setCarouselReference = (slider) => {
        setCarouselRef(slider);
    };

     /**
      * Handle Delete Event
      */
      const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.imageDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
     const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = () => {
        delete_banner(showDialog.data.id);
        dialogCancelEventHandle();
    };

    const width = containerRef.current ? containerRef.current.clientWidth / 4 : 0;

    return (
        <Grid item className={classes.logoSection}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.theme.banner}
                </Typography>
            </Grid>
            <Grid className={classes.bodySection}>
                <Typography variant="body1" className="mb-3">
                    {appConstants.labels.theme.bannerDesc}
                </Typography>
                <Grid className={classes.wrapperContainer} ref={containerRef}>
                    <Grid item>
                        <IconButton className={"arrows lt"} onClick={() => carouselRef.slickPrev()}>
                            <ChevLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid sx={{ width: '100%', maxWidth: `calc(100% - ${width}px)` }}>
                        <SliderComponent setCarouselRef={setCarouselReference} featureSlider slide_settings={{ slidesToShow: 3 }}>
                            {
                                banner_list.map((banner) =>
                                    <h3 key={banner.id}>
                                        <Grid item className={classes.bannerCardWrapper}>
                                            <Grid item className={classes.bannerCard}>
                                                {
                                                    editPermission &&
                                                    <IconButton className={classes.deleteIcon} onClick={() => onDelete(banner)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                                <img src={banner.banner} className={classes.bannerImg} alt="bannerimage" />
                                            </Grid>
                                        </Grid>
                                    </h3>
                                )
                            }
                        </SliderComponent>
                    </Grid>
                    {
                        editPermission &&
                        <Grid item sx={{ width: `${width - 30}px !important` }} className={classes.addBannerCard} onClick={handleImgUpload}>
                            <Grid className="dflex alignCenter">
                                <IconButton>
                                    <PlusIcon />
                                </IconButton>
                                <Typography variant="body1" className={classes.textSecondary}>
                                    {appConstants.labels.theme.addBanner}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    <input
                        accept="image/*"
                        className={classes.domainImgInput}
                        id="domainImage"
                        type="file"
                        onChange={handleSelectFile}
                    />
                    {
                        openImageDialog && (
                            <ImageCropper
                                open={openImageDialog}
                                onClose={() => setImageDialog(false)}
                                imageToCrop={imageToCrop}
                                aspect={16 / 9}
                                onImageCropped={
                                    (croppedImage, croppedImageUrl) =>
                                        updateCropImage(croppedImage, croppedImageUrl)
                                }
                            />
                        )
                    }
                    <Grid item>
                        <IconButton className={"arrows rt"} onClick={() => carouselRef.slickNext()}>
                            <ChevRightIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </Grid>
    );
}

// default props
BannerSection.defaultProps = {
    classes: {},
    handleBannerImages: () => { },
    banner_list: [],
    delete_banner: () => { },
    editPermission: false
};

// prop types
BannerSection.propTypes = {
    classes: PropTypes.object,
    handleBannerImages: PropTypes.func,
    banner_list: PropTypes.array,
    delete_banner: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...FeaturesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(BannerSection);