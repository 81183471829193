import React from 'react';
import { MenuItem, FormControl, Select, Grid, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

// Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import style from "./style.jsx";
import { ChevDownIcon } from '../../assets/svg/index.js';

function SelectComponent(props) {

  /**
   * Define Props
   */
  const {
    classes, className, list, value, propertyName, label, noOutline, onSelectChange, variant,
    displayPropertyName, placeholder, isDisabled, is_multiple, onScrollEnd
  } = props;

  /**
   * Handle Change
   * @param {*} event
   */
  const handleChange = (event) => {
    onSelectChange(event.target.value);
  };

  const renderIcon = () => {
    if (isDisabled) {
      return null;
    }
    if (!isDisabled) {
      return <ChevDownIcon />;
    }
  };

  /**
   * Handle list scroll change event
   * @param {*} event
   */
  const onScrollChange = (event) => {
    if ((event.target.scrollHeight - Math.floor(event.target.scrollTop) - 10) <= event.target.clientHeight && onScrollEnd) {
      onScrollEnd();
    }
  };

  const MenuProps = {
    PaperProps: {
      onScroll: onScrollChange
    },
    classes: {
      paper: classes.customPaper
    }
  };

  return (
    <Grid className={`selectComponent ${classes.selectComponent} ${noOutline ? classes.outLineNone : null}`}>
      <FormControl fullWidth>
        <InputLabel>
          {label}
        </InputLabel>
        <Select
          placeholder={placeholder}
          value={list.length > 0 ? value : ""}
          label={label}
          variant={variant}
          displayEmpty
          onChange={handleChange}
          className={className}
          disabled={isDisabled}
          multiple={is_multiple}
          MenuProps={MenuProps}
          IconComponent={() => renderIcon()}
        >
          {
            list.map((value, index) => {
              const listValue = propertyName ? value[propertyName] : value;
              const displayProperty = displayPropertyName ? value[displayPropertyName] : listValue;
              return (
                <MenuItem value={listValue} key={index}>
                  {displayProperty}
                </MenuItem>
              );
            })
          }
          {
            !list || list.length === 0 &&
            <MenuItem disabled>
              No Data
            </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
  );
}


/**
 * Define Component Props
 */
SelectComponent.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  list: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  propertyName: PropTypes.string,
  noOutline: PropTypes.bool,
  onSelectChange: PropTypes.func,
  variant: PropTypes.string,
  displayPropertyName: PropTypes.string,
  isDisabled: PropTypes.bool,
  is_multiple: PropTypes.bool,
  onScrollEnd: PropTypes.func
};


/**
 * Set Default Values
 */
SelectComponent.defaultProps = {
  className: "",
  classes: {},
  list: [],
  value: '',
  label: '',
  noOutline: false,
  onSelectChange: () => { },
  onScrollEnd: () => { },
  isDisabled: false,
  is_mulitple: false,
  applyDisableArrow: false,
  placeholder: ""
};

export default withStyles((theme) => ({
  ...LayoutStyles(theme),
  ...style(theme)
}))(SelectComponent);