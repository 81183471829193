import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Import Styles
import Styles from './styles.jsx';
import { HeaderComponent } from '../../components/index.js';

// Import Reducer
import { defaultDataRequest } from '../../redux/reducer/defaultReducer';
import { getUserPermissionRequest } from '../../redux/reducer/authReducer';
import { getUsersRequest } from '../../redux/reducer/userReducer';
import { getAllDashboardRequest } from '../../redux/reducer/dashboardReducer';

function PrivateLayoutComponent({ component: Component, ...props }) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const [scrollRef, setScrollRef] = useState(useRef(null));

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { user: { role }, permission } = useSelector((state) => state.auth, shallowEqual);
    const { users_list } = useSelector((state) => state.user, shallowEqual);
    const { list: dashboards } = useSelector((state) => state.dashboard, shallowEqual);

    /**
     * Check for change in scrollRef and update it after rendering completes
     */
    useEffect(() => {
        setScrollRef(scrollRef.current);
    }, [scrollRef]);

    /**
     * Get default (connection_type, datatypes, features and channels list) settings and Users, Dashboards List
     */
    useEffect(() => {
        // Get Default Settings Info
        dispatch(defaultDataRequest("connection_type,datatypes,features,channels"));

        // Get Users List
        if (!users_list || !users_list.length) {
            dispatch(getUsersRequest({ search: "" }));
        }

        // Get Dashboards List
        if (!dashboards || !dashboards.length) {
            dispatch(getAllDashboardRequest());
        }
    }, [dispatch]);

    /**
     * Get Connection Types Data
     */
    useEffect(() => {
        if (role && (!permission || !permission.length)) {
            dispatch(getUserPermissionRequest(role));
        }
    }, [dispatch, role]);


    return (
        <Grid container className={classes.privateLayout}>
            <HeaderComponent scrollRef={scrollRef.current !== undefined ? scrollRef.current : scrollRef} />
            <Grid item xs={12} className={classes.privateLayoutContainer} id="private" ref={scrollRef}>
                <Component scrollRef={scrollRef} />
            </Grid>
        </Grid>
    );
}

// prop types
PrivateLayoutComponent.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.elementType
};

export const PrivateLayout = withStyles(Styles)(PrivateLayoutComponent);