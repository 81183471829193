const style = (theme) => ({
    userAsset: {
        '& .MuiAvatar-root': {
            width: 30,
            height: 30,
            border: `1px solid #fff`
        },
        '& .dataName': {
            maxWidth: '188px',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingBottom: '3px'
        }
    },
    scoreContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    organizationCard: {
        // padding: 20,
        borderRadius: 4,
        width: '280px',
        height: '90px',
        '&:hover': {
            '& .cardInner': {
                // transform: 'rotateY(180deg)'
            }
        }
    },
    cardInner: {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
        padding: 12,
        '& .cardFront': {
            background: '#fff',
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            overflow: 'hidden',
            display: 'flex'
        },
        '& .categoryCard': {
            background: '#f7f7f7',
            border: '1px solid #e1e1e1'
        },
        '& .cardFront, .cardBack': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            WebkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            left: 0,
            top: 0,
            padding: 12,
            borderRadius: 4
        },
        '& .cardBack': {
            '& .MuiTypography-root': {
                color: '#fff !important'
            },
            transform: 'rotateY(180deg)',
            '& .DangerIcon, .IssuesIcon2': {
                fill: '#fff !important'
            },
            '& .DangerIcon': {
                marginTop: '-3px'
            }
        }
    },
    horizontalView: {
        paddingTop: 20,
        '& li.selected': {
            '& .cardFront': {
                border: `1px solid ${theme.palette.secondary.main}`
            },
            '& .MuiSvgIcon-root, .fillColor': {
                fill: `${theme.palette.secondary.main} !important`
            },
            '& .lin': {
                backgroundColor: `${theme.palette.secondary.main} !important`
            }
        },
        '& .Clinebot, .Clinetop': {
            backgroundColor: `${theme.palette.secondary.main} !important`
        }
    },
    cardAvatar: {
        width: '28px !important',
        height: '28px !important',
        border: "2px solid #fff"
    },
    centerCard: {
        '& .organizationCard': {
            margin: 'auto'
        }
    },
    collapseBtn: {
        backgroundColor: '#d8cff1 !important',
        width: 24,
        height: 24,
        '&.notCollapsed': {
            marginTop: '12px !important',
            '&:before': {
                height: '1em',
                left: '50%',
                top: '-13px',
                width: '2px',
                backgroundColor: '#d8cff1',
                content: "' ' !important",
                position: 'absolute'
            }
        },
        '& svg': {
            fill: '#fff'
        }
    },
    organizationChart: {
        overflowY: 'auto',
        '& .collapsedDiv:before': {
            height: '0px !important'
        },
        '& .organizationListOpen': {
            height: 'auto !important',
            overflow: 'visible !important',
            transition: '300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
            transitionDuration: '300ms',
            visibility: 'visible'
        },
        '& .organizationListClose': {
            height: '0px',
            overflow: 'hidden',
            minHeight: '0px',
            transition: '300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
            transitionDuration: '300ms',
            visibility: 'hidden'
        }
    },
    addRemoveIcon: {
        position: 'absolute !important',
        right: '-10px',
        top: '29px',
        zIndex: 9,
        '& .MuiSvgIcon-root, .fillColor': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    dotContainer: {
        width: 10,
        height: 10,
        background: `${theme.palette.greyshades.lightgrey} !important`,
        borderRadius: 30,
        marginLeft: '12px'
    }
});

export default style;