const style = (theme) => ({
    topContainer: {
        marginBottom: 30,
        '& .description .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .linked': {
            '& .MuiChip-root': {
                background: theme.palette.greyshades.lightgrey
            }
        },
        '& .noOutline .MuiInput-underline:before': {
            borderBottom: '1px solid #ffffff'
        }
    },
    listTitle: {
        fontWeight: '500 !important',
        textAlign: 'left',
        paddingRight: 15
    },
    listValue: {
        '&.linkText': {
            color: `${theme.palette.secondary.main} !important`
        }
    }
});
export default style;