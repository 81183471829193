import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Drawer, Grid } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images

function DrawerComponent(props) {
    const { classes, children, open, toggleDrawer } = props;

    // const [open, setOpen] = React.useState(false);

    /*
     * const toggleDrawer = () => {
     *     setOpen(!open);
     * };
     */

    return (
        <Grid className={classes.drawerContainer}>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={toggleDrawer}
                className={`${classes.drawerContainer} drawerContainer`}
            >
                {children}
            </Drawer>
        </Grid>
    );
}

// default props
DrawerComponent.defaultProps = {
    classes: {}
};

// prop types
DrawerComponent.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DrawerComponent);