import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

//  Import Components
import Sidebar from '../components/sidebar/index.jsx';
import Header from '../components/header/index.jsx';
import CardDetail from './components/cardDetail/index.jsx';

//  Import Styles
import Styles from './style.jsx';
import GlossaryStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { toggleAddGlossary, getStatisticsRequest } from '../../../../redux/reducer/semanticReducer';
import { navigate } from '../../../../redux/reducer/navigationReducer';

// Import Helpers
import featureConstants from '../../../../constants/featureConstants.js';
import { checkPermission, semanticsBreadcrumb } from '../../../../helpers/appHelpers.js';

function GlossaryDetail(props) {
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: glossaryId, categoryId } = useParams();
    const { state } = useLocation();
    const selectedGlossary = state.glossary || {};
    const selectedCategory = state.category || {};
    const [searchText, setSearchText] = useState("");
    const [collapseOpen, setCollapseOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);


    /**
     * Redux Store
     */
    const { tree } = useSelector(({ semantic }) => semantic);
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     * Handle Add More
     * @param {*} event
     */
    const handleAddMore = (event) => {
        event.stopPropagation();
        setAnchorEl(event.target);
    };

    /**
     * Handle Close More
     * @description Close Modal
     */
    const handleCloseMore = () => {
        setAnchorEl(null);
    };


    const addNewItem = (type) => {
        dispatch(toggleAddGlossary(type));
        handleCloseMore();
    };

    /**
     * Handle Collapse Event
     */
    const onCollapseChange = () => {
        setCollapseOpen((collapseOpen) => !collapseOpen);
    };


    /**
     * BreadCrumb Action
     */

    const onBreadCurumAction = (item) => {
        if (item.type === "root") {
            dispatch(navigate({ path: 'settings.semantics.domains.root', state: {}, params: [] }));
        } else if (item.type === "category") {
            dispatch(navigate({ path: 'settings.semantics.domains.category', state: { glossary: selectedGlossary, category: item }, params: [item.glossary_id, item.id] }));
        } else {
            dispatch(navigate({ path: 'settings.semantics.domains.detail', state: { glossary: selectedGlossary }, params: [item.id] }));
        }
    };

    /**
     * CloseForm
     */
    /*
     * const handleCloseForm = () => {
     *     setFormType(null);
     * };
     */

    useEffect(() => {
        const requestParams = {
            type: categoryId ? "category" : "glossary",
            id: categoryId || glossaryId
        };
        dispatch(getStatisticsRequest(requestParams));
    }, [categoryId]);


    const breadCrumbs = useMemo(() => semanticsBreadcrumb(tree, selectedGlossary, categoryId || selectedCategory.id), [tree, selectedGlossary, categoryId || selectedCategory.id]);
    return (
        <Grid container className={classes.glossaryContainer}>
            <Grid container spacing={3} className={`${classes.glossaryListContainer} pt-2`}>
                <Grid item sx={{ flex: '0 0 auto', width: '300px' }}>
                    <Sidebar
                        collapseChange={onCollapseChange}
                        collapseState={!collapseOpen}
                        semanticsPermission={semanticsPermission}
                    />
                </Grid>
                <Grid item className={`${classes.glossaryList} ${collapseOpen && "sideBarClose"}`}>
                    <Header
                        actitonType="more"
                        type={categoryId ? "term" : "category"}
                        handleMoreAction={(event) => handleAddMore(event)}
                        addClick={() => addNewItem()}
                        breadcrumbs={breadCrumbs}
                        onBreadCurumAction={(item) => onBreadCurumAction(item)}
                        searchText={searchText}
                        onSearchChange={(value) => setSearchText(value)}
                        isFilter
                        data={categoryId ? selectedCategory : selectedGlossary}
                        semanticsPermission={semanticsPermission}
                    />
                    <CardDetail search={searchText} />
                </Grid>
            </Grid>
            {
                Boolean(anchorEl) &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMore}
                    MenuListProps={
                        {
                            'aria-labelledby': 'basic-button'
                        }
                    }
                >
                    <MenuItem onClick={() => addNewItem("category")}>
                        {"Add Sub Domain"}
                    </MenuItem>
                    <MenuItem onClick={() => addNewItem("term")}>
                        {"Add Term"}
                    </MenuItem>
                </Menu>
            }
        </Grid>
    );
}

// default props
GlossaryDetail.defaultProps = {
    classes: {}
};

// prop types
GlossaryDetail.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...GlossaryStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GlossaryDetail);