/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

//  Import Components
import { Avatar, Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Helpers
import { createLinkedComment, convertHtml, stringAvatar } from '../../../../../helpers/appHelpers';

// Import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';

function MostLatestTopics(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Redirect
     * @param {*} type
     * @param {*} item
     */
    const redirect = (type, item) => {
        if (type === "conversation") {
            dispatch(navigate({ path: 'assets.conversationDetail', state: {}, params: [item.asset_id, item.id] }));
        } else {
            dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
        }
    };

    return (
        <Grid item xs={12} className={classes.latestTopics}>
            {
                data && data.map((item, index) =>
                    <Grid key={`${index}_lastest_topic`} container className={`${classes.widgetItem}`} alignItems={'flex-start'} wrap="nowrap">
                        <Grid item className={classes.widgetListIcon}>
                            <Avatar alt={item.user} {...stringAvatar(item.user, item?.avatar ?? '')} sx={{ width: 30, height: 30 }} onClick={(event) => { event.stopPropagation(); }} />
                        </Grid>
                        <Grid item xs={12} className={classes.widgetItemDetail}>
                            <Grid onClick={() => redirect("conversation", item)} className="commentText pb5 pointer" dangerouslySetInnerHTML={{ __html: createLinkedComment(convertHtml(item.comment)) }} />
                            <Grid item className="dflex alignCenter spaceBetween">
                                <Typography onClick={() => redirect("asset", item)} variant="body2" className={`${classes.textSecondary} pointer`}>
                                    {item.name || ""}
                                </Typography>
                                <Typography variant="body2" className={classes.textSecondary}>
                                    {moment(item.created_date).format('MMM DD YYYY hh:mm A')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}

// default props
MostLatestTopics.defaultProps = {
    classes: {},
    data: []
};

// prop types
MostLatestTopics.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(MostLatestTopics, areEqual));