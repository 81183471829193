import React from 'react';
import PropTypes from 'prop-types';
import { AccordionSummary, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

function AccordionHeader(props) {
  const { classes, data } = props;

  return (
    <AccordionSummary className={classes.accordionHeader}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Typography variant="h6" className="fs-15">
        {data.name || ''}
      </Typography>
    </AccordionSummary>
  );
}

// default props
AccordionHeader.defaultProps = {
  classes: {},
  data: {}
};

// prop types
AccordionHeader.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default React.memo(AccordionHeader, areEqual);