import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from "@mui/material";

const TabPanelComponent = (props) => {
    /**
     * Define Props
     */
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            className="tabBodyContainer"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>
                {children}
            </Box>
        </Typography>
    );
};

TabPanelComponent.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number
};

export default TabPanelComponent;