import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Rating, Tooltip } from '@mui/material';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import Editor from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//  Import Styles
import editorStyles from './SimpleMentionEditor.module.css';
import connectionCardStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images
import { BoldIcon, CodeIcon, ItalicIcon, ListIcon2, NumberListIcon, QuoteIcon, SendIcon, SendIconGrey, StrikeThroughIcon } from '../../assets/svg/index.js';

// Import Components
import { SelectComponent, TextBoxComponent } from '../index.js';

/**
 * Define Variable's
 */
const mentions = {};

function RichTextEditorComponent(props) {

    /**
     * Define Props
     */
    const { classes, formSubmit, attributes, isCancel, current_data, onCancel, users_list, formFields } = props;
    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin(
            {
                mentionTrigger: ['@', '#'],
                entityMutability: "IMMUTABLE"
            }
        );
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);


    /**
     * Define State
     */
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [showTools, setShowTools] = useState(true);
    const [commentData, setCommentData] = useState({
        rating: 0,
        priority: 'Endorsed',
        title: ''
    });
    const { rating } = commentData;

    /**
     * useEffect to update mentions for Editor
     */
    useEffect(() => {
        // Bind Users
        mentions['@'] = users_list.map((user) => {
            return { name: `${user.first_name ? user.name : user.email}`, id: user.id, type: 'user' };
        });

        // Bind Asset and Attribute List
        mentions['#'] = attributes;

        setSuggestions(mentions);
    }, [attributes, users_list]);

    /**
     * Define useEffect for initial editable comment
     */
    useEffect(() => {
        if (Object.keys(current_data).length > 0) {
            const contentState = convertFromRaw(JSON.parse(current_data.comment));
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
            setCommentData({
                ...commentData,
                rating: current_data.rating,
                title: current_data.title
            });
        }
    }, []);

    /**
     * Handle Key Command
     * @param {*} command
     * @returns
     */
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(
            editorState,
            command
        );
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    /**
     * Handle Toggle Inline Style
     * @param {*} style
     */
    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    /**
     * Toggle Block Type
     * @param {*} blockType
     */
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    /**
     * Handle Search Change Event
     */
    const onSearchChange = useCallback(({ trigger, value }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions, trigger));

    }, []);

    /**
     * Handle On Open Change Event
     */
    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    /**
     * Handle Textbox Changes
     */
    const handleChange = useCallback((property, value) => {
        if (property === "rating" && !value) {
            value = 0;
        }
        setCommentData((commentData) => ({ ...commentData, [property]: value }));
    }, [commentData]);

    /**
     * Handle Submit Event
     * @param {*} event
     */
    const handleSubmit = (event) => {
        const updateForm = {
            ...commentData,
            rating: 0,
            title: ''
        };
        setCommentData(updateForm);
        setEditorState(() => EditorState.createEmpty());
        const content = { ...commentData, priority: commentData.priority.toLowerCase() };
        const contentState = convertToRaw(editorState.getCurrentContent());
        const entityMap = contentState.entityMap;
        const mention_tags = [];
        Object.values(entityMap).map((entity) => {
            if (entity.type === 'mention') {
                mention_tags.push(entity.data.mention);
            }
            if (entity.type === '#mention') {
                mention_tags.push(entity.data.mention);
            }
            return null;
        });
        content.mention_tags = mention_tags;
        content.comment = JSON.stringify(contentState);
        if (Object.keys(current_data).length > 0) {
            content.id = current_data.id;
            content.is_pinned = current_data.is_pinned;
        }
        if (!formFields.warning) {
            delete content.priority;
        }
        formSubmit(content);
        event.preventDefault();
    };

    /**
     * Validate Editor
     * @returns
     */
    const checkValidate = () => {
        if (formFields.title) {
            if (commentData.title) {
                return true;
            }
            return false;

        }
        if (editorState.getCurrentContent().hasText() || commentData.rating > 0) {
            return true;
        }
        return false;
    };

    /**
     * Handle for active icons
     * @param {*} style
     * @returns
     */
    const isStyleActive = (style) => {
        const currentStyle = editorState.getCurrentInlineStyle();
        return currentStyle.has(style);
    };

    /**
     * Handle Blokc Type Active
     * @param {*} blockType
     * @returns
     */
    const isBlockTypeActive = (blockType) => {
        const selection = editorState.getSelection();
        const blockTypeOfSelection = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
        return blockTypeOfSelection === blockType;
    };

    return (
        <div className={`${editorStyles.editor} ${classes.editorWrapper}`}>
            <ValidatorForm noValidate onSubmit={() => null}>
                {
                    formFields.title &&
                    <Grid item xs={12}>
                        <TextBoxComponent
                            className="mb-2"
                            fullWidth
                            variant="outlined"
                            placeholder="Enter a Title to start a conversation"
                            name="title"
                            value={commentData.title}
                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                            valOnChange
                        />
                    </Grid>
                }
                <Grid item
                    xs={12}
                    className={`${classes.editorRoot}`}
                    onMouseLeave={() => setShowTools(true)}
                    onMouseEnter={() => setShowTools(true)}
                >
                    <Editor
                        editorState={editorState}
                        onChange={(id) => setEditorState(id)}
                        handleKeyCommand={handleKeyCommand}
                        placeholder="Use @ mention to tag a user, Use # mention to tag an attribute(s)"
                        plugins={plugins}
                    />
                    {
                        suggestions &&
                        <MentionSuggestions
                            open={open}
                            onOpenChange={onOpenChange}
                            suggestions={suggestions}
                            onSearchChange={onSearchChange}
                            entryComponent={
                                /* eslint-disable no-unused-vars */
                                ({
                                    mention,
                                    searchValue,
                                    isFocused,
                                    ...parentProps
                                }) => {
                                    return (
                                        <Tooltip title={mention.name} arrow>
                                            <div {...parentProps} className={`${classes.suggestionOptions}`}>
                                                {mention.name}
                                            </div>
                                        </Tooltip>
                                    );
                                }
                            }
                        />
                    }
                    {
                        isCancel &&
                        <Grid className="editcloseIcon p6" onClick={onCancel}>
                            <HighlightOffIcon />
                        </Grid>
                    }
                    {
                        showTools &&
                        <Grid container justifyContent={"space-between"} alignItems="center" className="editorToolBar">
                            <Grid item className="dflex alignCenter">
                                <Grid className={`styleBtn ${isStyleActive('BOLD') ? 'active' : ''}`} onClick={() => toggleInlineStyle('BOLD')}>
                                    <BoldIcon />
                                </Grid>
                                <Grid className={`styleBtn ${isStyleActive('ITALIC') ? 'active' : ''}`} onClick={() => toggleInlineStyle('ITALIC')}>
                                    <ItalicIcon />
                                </Grid>
                                <Grid className={`styleBtn ${isStyleActive('STRIKETHROUGH') ? 'active' : ''}`} onClick={() => toggleInlineStyle('STRIKETHROUGH')}>
                                    <StrikeThroughIcon />
                                </Grid>
                                <Grid className={`styleBtn ${isBlockTypeActive('blockquote') ? 'active' : ''}`} onClick={() => toggleBlockType('blockquote')}>
                                    <QuoteIcon />
                                </Grid>
                                <Grid className={`styleBtn ${isStyleActive('CODE') ? 'active' : ''}`} onClick={() => toggleInlineStyle('CODE')}>
                                    <CodeIcon />
                                </Grid>
                                <Grid className={`styleBtn ${isBlockTypeActive('unordered-list-item') ? 'active' : ''}`} onClick={() => toggleBlockType('unordered-list-item')}>
                                    <ListIcon2 />
                                </Grid>
                                <Grid className={`styleBtn ${isBlockTypeActive('ordered-list-item') ? 'active' : ''}`} onClick={() => toggleBlockType('ordered-list-item')}>
                                    <NumberListIcon />
                                </Grid>
                            </Grid>
                            <Grid item className="dflex alignCenter">
                                {
                                    formFields.warning &&
                                    <SelectComponent
                                        className={`${classes.outlineNone} outlineNone`}
                                        value={commentData.priority}
                                        onSelectChange={(value) => handleChange('priority', value)}
                                        list={['Endorsed', 'Warning', 'Error']}
                                        name="priority"
                                        noOutline />
                                }
                                {
                                    formFields.showRating &&
                                    <Grid className="ml-4">
                                        <Rating
                                            className={classes.starIcon}
                                            name="rating"
                                            value={rating}
                                            precision={0.5}
                                            size="small"
                                            onChange={
                                                (event, newValue) => {
                                                    handleChange('rating', newValue);
                                                }
                                            } />
                                    </Grid>
                                }
                                <IconButton className="ml-4 p6" disabled={!checkValidate()} onClick={handleSubmit}>
                                    {!checkValidate() ? <SendIconGrey /> : <SendIcon />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </ValidatorForm>
        </div>
    );
}

// default props
RichTextEditorComponent.defaultProps = {
    classes: {},
    data: {},
    attributes: [],
    isCancel: false,
    current_data: {},
    onCancel: () => { },
    formSubmit: () => { },
    users_list: [],
    formFields: {}
};

// prop types
RichTextEditorComponent.propTypes = {
    classes: PropTypes.object,
    formSubmit: PropTypes.func,
    attributes: PropTypes.array,
    isCancel: PropTypes.bool,
    current_data: PropTypes.object,
    onCancel: PropTypes.func,
    users_list: PropTypes.array,
    formFields: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...connectionCardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)((RichTextEditorComponent));