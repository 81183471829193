const style = () => ({
    modalContainer: {
        padding: 24,
        maxWidth: 560,
        '& form': {
            width: '100%',
            height: '100%'
        }
    },
    tableContainer: {
        paddingTop: "6px !important",
        '& .MuiGrid-item .MuiTableContainer-root': {
            maxHeight: '300px !important'
        }
    }
});
export default style;