import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Chip, Grid, Typography } from '@mui/material';
import { PopOverComponent } from '../index.js';

/// Import Icons and Images
import { ArrowDown, ArrowUp, DeprecatedIcon, DoubleArrowUp, PendingIcon, ReadyForReviewIcon, VerfiedIcon } from '../../assets/svg/index.js';

//  Import Styles
import style from './styles.jsx';
import LayoutStyles from '../../layouts/style.jsx';


function StatusDropDownComponent(props) {
    const { classes, value, onChange, dropdownValue, priority, disable, disabled, noBackground } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const onPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    /**
     * Handle Selection
     * @param {*} value
     */
    const handleOnChange = (value) => {
        onChange(value);
        onPopoverClose();
    };


    /**
     * Return Chip ClassName
     * @param {*} status
     */
    const getChipClass = (status) => {
        switch (status?.replace(/[\s]/g, '').toLowerCase()) {
            case "verified":
                return classes.verifiedChip;
            case "pending":
                return classes.pendingChip;
            case "readyforreview":
                return classes.readyforreviewChip;
            case "deprecated":
                return classes.deprecatedChip;
            case "high":
                return `${classes.highChip} highChip`;
            case "medium":
                return `${classes.mediumChip} mediumChip`;
            case "low":
                return `${classes.lowChip} lowChip`;
            case "ok":
                return `${classes.okChip} okChip`;
            case "new":
                return `${classes.highChip} newChip`;
            case "inprogress":
                return `${classes.mediumChip} inprogressChip`;
            case "resolved":
                return `${classes.lowChip} resolvedChip`;
            default:
                return '';
        }
    };

    /**
     * Return Chip ClassName
     * @param {*} status
     */
    const getStatusIcon = (status) => {
        switch (status?.replace(/[\s]/g, '').toLowerCase()) {
            case "verified":
                return <VerfiedIcon />;
            case "pending":
                return <PendingIcon />;
            case "readyforreview":
                return <ReadyForReviewIcon />;
            case "deprecated":
                return <DeprecatedIcon />;
            case "high":
                return <DoubleArrowUp />;
            case "medium":
                return <ArrowUp />;
            case "low":
                return <ArrowDown />;
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {
                value === null &&
                <Grid className={classes.popoverList} sx={{ padding: '6px 0px !important' }}>
                    <Typography component="span">
                        {"NA"}
                    </Typography>
                </Grid>
            }
            {
                value !== null &&
                <Chip
                    className={`${getChipClass(value)} ${priority ? "priorityChip" : ""} ${classes.chips} ${noBackground && classes.noBackground}`}
                    icon={getStatusIcon(value)}
                    label={value}
                    onClick={(event) => !disable && onPopoverOpen(event)}
                    disabled={disabled}
                />
            }
            <PopOverComponent
                anchorEl={anchorEl}
                onClose={onPopoverClose}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
            >
                <Grid className={classes.popoverDiv}>
                    {
                        dropdownValue.map((status, index) => {
                            return (
                                <Grid className={classes.popoverList} key={`status_${index}`} onClick={() => handleOnChange(status)}>
                                    {getStatusIcon(status)}
                                    <Typography component="span" className={status?.replace(/[\s]/g, '')}>
                                        {status}
                                    </Typography>
                                </Grid>
                            );
                        }
                        )
                    }
                </Grid>
            </PopOverComponent>
        </React.Fragment>
    );

}

// default props
StatusDropDownComponent.defaultProps = {
    classes: {},
    value: "",
    onChange: () => { },
    dropdownValue: [],
    priority: false,
    disable: false,
    disabled: false,
    noBackground: false
};

// prop types
StatusDropDownComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    dropdownValue: PropTypes.array,
    priority: PropTypes.bool,
    disable: PropTypes.bool,
    disabled: PropTypes.bool,
    noBackground: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StatusDropDownComponent);