import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

// Import Components
import { SwitchComponent, TextBoxComponent, LoaderComponent } from '../../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Image Images
import { CloseIcon, CopyIcon, ShareIcon } from '../../../../../assets/svg/index.js';

//Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Actions
import { shareRequest } from '../../../../../redux/reducer/issuesReducer';
import { displyAlert } from '../../../../../redux/reducer/alertReducer';

// Import Constants
import appConstants from '../../../../../constants/appConstants';

// Import Helpers
import { numberWithCommas, dFormatter } from '../../../../../helpers/appHelpers';


function AlertShare(props) {

    /**
     * Define Props
     */
    const { classes, data, takeScreenshot, alertScreenShot } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { detail: versionDetail } = useSelector((state) => state.version);

    // Get Alert URL Link
    let link = window.location.origin;
    // link = `${link}home/alerts?key1=${data.measure_id}&key2=${data?.id}&key3=${data?.measure_name}`;
    link = `${link}/measure/${data.measure_id}/detail?measure_name=${data?.measure_name}&alert_id=${data?.id}`;

    // Get Threshold Value
    let expectedFormat = "";
    let actualFormat = "";
    const lower = data.history_data[data.history_data.length - 1]?.threshold?.lower_threshold ?? '';
    const upper = data.history_data[data.history_data.length - 1]?.threshold?.upper_threshold ?? '';
    const actual = data.history_data[data.history_data.length - 1]?.value ?? '';
    if (lower && upper) {
        if (data?.name?.toLowerCase() === 'freshness') {
            expectedFormat = `${dFormatter(lower)} - ${dFormatter(upper)}`;
            actualFormat = `${dFormatter(actual)}`;

        } else {
            expectedFormat = `${numberWithCommas(lower)} - ${numberWithCommas(upper)}`;
            actualFormat = `${numberWithCommas(actual)}`;
        }
    }

    /**
     * Define state
     */
    const [shareDetails, setShareDetails] = useState(
        {
            name: '',
            message: data.history_data[data.history_data.length - 1].message ? data.history_data[data.history_data.length - 1].message : `${data.name + " value percentage exceed the threshold limit"}`,
            channels: ['email'],
            link,
            type: 'share_alert',
            trigger_type: 'manual',
            title: versionDetail?.name ?? '',
            asset_name: versionDetail?.name || data.asset_name || "",
            attribute_name: data?.attribute_name ?? '',
            measure_id: data?.measure_id ?? '',
            alert_id: data?.id ?? '',
            measure_name: data?.measure_name ?? '',
            expected: expectedFormat || '',
            actual: actualFormat || ''
        });
    const [shareContainer, openShareContainer] = useState(false);

    /**
     * Handle Share
     */
    const handleShare = (event) => {
        takeScreenshot();
        openShareContainer(true);
        event.stopPropagation();
    };

    /**
     * Handle Close
     */
    const handleClose = () => {
        openShareContainer(false);
    };

    /**
     * On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setShareDetails({ ...shareDetails, [property]: value });
    };

    /**
     * Share Link
     */
    const share = () => {
        const requestData = { ...shareDetails, ...alertScreenShot };
        if (requestData.base64) {
            delete requestData.base64;
        }
        dispatch(shareRequest({ ...requestData, 'asset': data.asset_id }));
        handleClose();
    };

    /**
     * Copy Connection Path to Keyboard
     */
    const copyToClipBoard = () => {
        navigator.clipboard.writeText(shareDetails.link);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    /**
     * Channels On Change
     * @param {*} property
     * @param {*} value
     */
    const onChennalChange = (type) => {
        let channels = [...shareDetails.channels];
        if (channels.indexOf(type) > -1) {
            channels = channels.filter((item) => item !== type);
        } else {
            channels.push(type);
        }

        setShareDetails({ ...shareDetails, "channels": channels });
    };


    return (
        <Fragment>
            <Tooltip title="Share">
                <IconButton className={`${classes.shareIconBtn} p5`} onClick={handleShare}>
                    <ShareIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={shareContainer}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.shareDialog}
            >
                <ValidatorForm onSubmit={() => share()}>
                    <Grid container spacing={5} className={classes.shareContainer}>
                        <Grid item xs={12} className="dflex spaceBetween w-100">
                            <Grid>
                                <Typography variant="h6">
                                    Share Alert
                                </Typography>
                                <Typography variant="body1" className={classes.textSecondary}>
                                    {data.name || ""}
                                </Typography>
                            </Grid>
                            <IconButton className="closeButton" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className="pt-1">
                            {
                                alertScreenShot.base64?.length > 0
                                    ? <img src={alertScreenShot?.base64} className={classes.shareContainerImg} alt="shareimg" />
                                    : <LoaderComponent loaderType="attachment" height={200} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.textSecondary}>
                                To
                            </Typography>
                            <Grid className="mt-1">
                                <TextBoxComponent
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Name or Email
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    value={shareDetails?.name ?? ''}
                                    errorMessages={[appConstants.errorMessages.valueRequired]}
                                    onChange={(event) => onChange("email", event.target.value)}
                                />
                            </Grid>
                            <Grid className="mt-2">
                                <TextBoxComponent
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Message
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    multiline
                                    value={shareDetails?.message ?? ''}
                                    validators={['required']}
                                    errorMessages={[appConstants.errorMessages.messageRequired]}
                                    onChange={(event) => onChange("message", event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="dflex">
                            <Grid>
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('email')}
                                    checked={shareDetails.channels?.indexOf('email') > -1 || false}
                                    label="Email"
                                />
                            </Grid>
                            <Grid className="ml-4">
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('slack')}
                                    checked={shareDetails.channels?.indexOf('slack') > -1 || false}
                                    label="Slack"
                                />
                            </Grid>
                            <Grid className="ml-4">
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('teams')}
                                    checked={shareDetails.channels?.indexOf('teams') > -1 || false}
                                    label="Team"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="dflex spaceBetween alignCenter">
                            <Grid item className={classes.copyDataset}>
                                <Typography variant="body1" className="pr-15" noWrap>
                                    {shareDetails.link}
                                </Typography>
                                <Grid className="copyIcon" onClick={() => copyToClipBoard()}>
                                    <CopyIcon />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                type="submit"
                                disabled={!alertScreenShot.base64 || !shareDetails.channels.length}
                                disableElevation
                            >
                                Share
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Dialog>
        </Fragment>
    );
}

// default props
AlertShare.defaultProps = {
    classes: {},
    data: {},
    alertScreenShot: {},
    takeScreenshot: () => { }
};

// prop types
AlertShare.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    alertScreenShot: PropTypes.object,
    takeScreenshot: PropTypes.func
};


export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AlertShare);