const style = (theme) => ({
    connectPageContainer: {
        background: theme.palette.primary.contrastText,
        // padding: '24px 0px 24px 30px',
        minHeight: '100%',
        alignContent: 'baseline'
    },
    configurecardBody: {
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    connectionCard: {
        padding: '10px 15px 14px',
        border: `1px solid #E5E7EB82`,
        textAlign: 'center',
        background: `${theme.palette.secondary.main}15`,
        boxShadow: '0px 0px 10px #0000000D',
        height: '100%',
        '&.deactive': {
            background: '#f5f5f5 !important',
            pointerEvents: 'none',
            '& .connectionCardHeader': {
                filter: 'grayscale(1)'
            },
            '& .cardImg, img': {
                filter: 'grayscale(1)'
            },
            '& .connectorCardImg': {
                filter: 'grayscale(1)'
            },
            '& .MuiTypography-root': {
                color: `${theme.palette.greyshades.darkgrey} !important`
            }
        },
        '&.connectorcard': {
            padding: '24px !important',
            background: `${theme.palette.primary.contrastText}`,
            cursor: 'pointer',
            // height: 190,
            '&:hover': {
                outline: `2px solid ${theme.palette.secondary.main}`,
                background: 'rgb(244 246 252 / 50%);'
            }
        }
    },
    connectionHeader: {
        padding: '24px 30px 0px 30px',
        paddingRight: 30,
        '& .searchBox, .MuiFormControl-root': {
            width: '100% !important',
            maxWidth: 320
        },
        '& .searchBox': {
            padding: '2px 10px !important'
        }
    },
    connectioncardBody: {
        height: 'calc(100vh - 152px)',
        overflowY: 'auto',
        padding: '12px 30px 24px 30px',
        '& .MuiBox-root': {
            padding: '0px !important'
        },
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    cardDesc: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        height: '45.5px'
    },
    connectorsInnerBody: {
'& .restapi': {
    maxWidth: '80% !important'
}
    },
    imgContainer: {
        height: 54,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '16px',
        '& .connectorCardImg': {
            height: 40,
            maxWidth: '100%',
            objectFit: 'contain',
            '& .teradata': {
                height: "25px !important"
            }
        },
        '&.teradata': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            '& img': {
                height: "auto !important",
                width: "auto !important",
                maxHeight: "25px !important"
            }
        }
    }
});
export default style;