const style = (theme) => ({
    behavioralContainer: {
        background: '#fff',
        padding: 24,
        '& .greyInput .MuiOutlinedInput-input, .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '6px 10px'
        },
        '& .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '2px 10px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '7.5px 14px'
        },
        '& .chipContainer': {
            '& .MuiInput-input': {
                padding: '0px 4px 0px 0px !important'
            }
        }
    },
    interval: {
        position: 'relative',
        top: '-2px',
        zIndex: 9,
        '& .MuiSelect-select': {
            paddingRight: '10px !important'
        },
        '& .ChevDownIcon': {
            display: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderBottom: 'none !important'
        },
        '& .MuiOutlinedInput-root': {
            fontSize: '18px'
        },
        '& .MuiPaper-root': {
            top: "487px !important"
        }
    },
    displayAdd: {
        '& .MuiIconButton-root': {
            display: "none"
        }
    },
    intervalText: {
        width: "100%"
    }
});
export default style;