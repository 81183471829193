const style = (theme) => ({
    ResultContainer: {
        marginTop: '10px !important',
        '& .noResulteContainer': {
            minHeight: '210px'
        }
    },
    cancelBtn: {
        background: `${theme.palette.greyshades.darkgrey} !important`

    },
    dialog: {
        '& .MuiDialog-container .MuiDialog-paper': {
            minWidth: 450
        }
    },
    FailedRowsContainer: {
        '& .noResulteContainer': {
            minHeight: '210px'
        }
    }
});
export default style;