import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getDimensionRequest, getDimensionSuccess, getDimensionFailure, createDimensionRequest, createDimensionSuccess,
  createDimensionFailure, updateDimensionRequest, updateDimensionSuccess, updateDimensionFailure, deleteDimensionRequest,
  deleteDimensionSuccess, deleteDimensionFailure
} from '../reducer/dimensionReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { dimensionService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";

/**
 * Get Dimensions
 * @returns
 */
const getDimensions = (action$) => action$.pipe(
  ofType(getDimensionRequest),
  mergeMap(() => {
    return from(dimensionService.getDimension()).pipe(
      concatMap((res) => { return of(getDimensionSuccess(res.data)); }),
      catchError((error) => { return of(getDimensionFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Create Dimension
 * @param {*} action$
 * @returns
 */
const createDimension = (action$) => action$.pipe(
  ofType(createDimensionRequest),
  mergeMap(({ payload }) => {
    return from(dimensionService.createDimension(payload)).pipe(
      concatMap((res) => { return of(createDimensionSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created })); }),
      catchError((error) => { return of(createDimensionFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Dimension
 * @param {*} action$
 * @returns
 */
const updateDimension = (action$) => action$.pipe(
  ofType(updateDimensionRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(dimensionService.updateDimension(id, rest)).pipe(
      concatMap((res) => { return of(updateDimensionSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })); }),
      catchError((error) => of(updateDimensionFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Delete Dimension
 * @param {*} action$
 * @returns
 */
const deleteDimension = (action$) => action$.pipe(
  ofType(deleteDimensionRequest),
  mergeMap(({ payload: { id, type } }) => {
    return from(dimensionService.deleteDimension(id, type)).pipe(
      concatMap(() => of(deleteDimensionSuccess(id), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
      catchError((error) => of(deleteDimensionFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

// Export All Dimension Functions
export const dimensionEpic = [getDimensions, createDimension, deleteDimension, updateDimension];