import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Components
import { MemoSimpleTabHeaderComponent, TableComponent, TabPanelComponent } from '../../../../components/index.js';
import QueryExecution from './queryExecution.jsx';
import Users from './components/users.jsx';
import Roles from './components/roles.jsx';

//  Import Styles
import Queriestyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { get_connection_type_access } from '../../../../helpers/appHelpers.js';

// import Actions
import { getQueryHistoryRequest } from '../../../../redux/reducer/usageReducer';
import { updateUserPreference } from '../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer';

// Import Helpers
import { convertUTCtoLocalDateTime, prepareUpdateUserPreference, getUserPreference } from '../../../../helpers/appHelpers';


function Queries(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { asset_id, type } = useSelector((state) => state.version, shallowEqual);
    const recent_queries = useSelector((state) => state.usage.recent_queries, shallowEqual);
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "usage", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "usage", "sorting");

    /**
     * Set State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: tableSorting?.sortBy ?? "runtime", orderBy: tableSorting?.sortBy ?? "desc"
    });

    /**
     *
     * Get Usage
     */
    useEffect(() => {
        if (asset_id && recent_queries?.data?.length <= 0) {
            dispatch(getQueryHistoryRequest({ asset_id, type: 'recent_queries' }));
        }
    }, [dispatch, asset_id]);

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = data.map((datum) => { return { ...datum, start_time: convertUTCtoLocalDateTime(datum.start_time) }; });
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "usage", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(recent_queries?.data ?? [], searchData, sorting.sortBy, sorting.orderBy), [recent_queries?.data ?? [], searchData, sorting.sortBy, sorting.orderBy]);

    // Get Connection Based Access
    const connection_access = get_connection_type_access(type?.toLowerCase());

    return (
        <Grid item xs={12} className={classes.usersContainer}>
            <Grid container spacing={3} className={classes.topContainer}>
                <Grid item xs={connection_access.usageTab.roles && connection_access.usageTab.roles ? 9 : 12}>
                    <QueryExecution />
                </Grid>
                {
                    connection_access.usageTab.roles && connection_access.usageTab.roles &&
                    <Grid item xs={3}>
                        <Grid className={classes.blueContainer}>
                            <Grid item xs={12} className={classes.tabsSection}>
                                <Grid
                                    container
                                    wrap="nowrap"
                                    className={classes.tabHeaderContainer}
                                >
                                    <MemoSimpleTabHeaderComponent
                                        className={classes.tabsHeader}
                                        tabStyle={'inline'}
                                        tabIndex={tabIndex}
                                        tabList={appConstants.tabs.usegeUserandRoleTabs}
                                        onTabChange={(newValue) => setTabIndex(newValue)}
                                    />
                                </Grid>
                                <Grid className={classes.tabsBodySection}>
                                    <Grid className={classes.tabsBody}>
                                        {
                                            tabIndex === 0 &&
                                            <TabPanelComponent value={0} index={tabIndex}>
                                                <Users />
                                            </TabPanelComponent>
                                        }
                                        {
                                            tabIndex === 1 &&
                                            <TabPanelComponent value={1} index={tabIndex}>
                                                <Roles />
                                            </TabPanelComponent>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid className={classes.recentQueries}>
                <TableComponent
                    styleType="striped"
                    title={appConstants.labels.usage.recentQueries}
                    options={appConstants.tableOptions.download}
                    headers={appConstants.table.UsageRecentQueries}
                    NoResultText="No Queries Found"
                    data={filterData}
                    searchData={searchData}
                    sortBy={sorting.sortBy}
                    orderBy={sorting.orderBy}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onClickSorting={onClickSorting}
                    onColumnChange={(columns) => onColumnsChange(columns)}
                    isLoading={recent_queries.isLoading}
                    hideDataLoading
                    height="300px"
                    exportParams={
                        {
                            fileName: "queries.csv"
                        }
                    }
                    userPreferenceColumns={columns || []}
                    stickyHeader
                />
            </Grid>
        </Grid>
    );
}

// default props
Queries.defaultProps = {
    classes: {}
};

// prop types
Queries.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Queriestyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Queries);