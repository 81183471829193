const TableStyles = (theme) => ({
    collapseTable: {
        '& .noPaddingLeft .MuiOutlinedInput-input': {
            paddingLeft: '0px !important'
        },
        '& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.accordionTableColun': {
            width: '1% !important'
        },
        maxWidth: 'calc(100% - 1px) !important',
        '& .queryPageContainer': {
            margin: '9px -12px 0px -10px !important',
            '& .MuiOutlinedInput-input': {
                padding: '4px 10px 4px 8px !important'
            }
        },
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey,
            "& th.stickyColumn": {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 3,
                background: "inherit"
            }
        },
        '& .MuiTableCell-root': {
            padding: '10px 10px',
            borderBottom: 'none',
            '& .MuiCheckbox-root': {},
            '& .MuiGrid-root': {
                '& .btnValidator .MuiFormHelperText-root': {
                    textAlign: "left !important"
                },
                '& button.btnSource': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                },
                '&.btnSource div': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                }
            },
            '& .MuiGrid-root .btnApiKey': {
                border: 'none !important',
                background: `none !important`,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.87) !important',
                '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26) !important'
                }
            }
        },
        '& thead': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 1
        },
        '& .MuiTableCell-head': {
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`,
                whiteSpace: "nowrap"
            },
            '& .searchBox ': {
                padding: '0px 10px !important',
                '& .searchIconRt': {
                    fontSize: '20px'
                },
                '& .searchRtWithClear': {
                    marginRight: '-7px !important'
                }
            },
            '& .MuiSelect-select': {
                padding: '5.5px 14px !important',
                paddingRight: '32px !important',
                '&~.ChevDownIcon': {
                    marginRight: '2px'
                }
            },
            '& .MuiAutocomplete-inputRoot': {
                padding: '0px !important',
                flexWrap: 'nowrap',
                '& .MuiAutocomplete-input': {
                    padding: '6.5px 4px 7.5px 6px !important',
                    background: '#fff !important',
                    minWidth: '250px'
                },
                '& .MuiAutocomplete-tag': {
                    margin: '4px !important',
                    height: '24px !important',
                    fontSize: '13px !important',
                    '& .MuiChip-deleteIconOutlinedColorDefault': {
                        width: '18px !important',
                        height: '18px !important'
                    }
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.greyshades.grey}`,
                borderRadius: `2px`
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                }
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important',
            overflowX: 'unset'
        },
        '& .MuiTableBody-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            overflowX: 'hidden',
            '& .MuiTableCell-root:first-child *': {
                // fontWeight: 600
            }
        },
        '& .MuiCheckbox-root': {
            padding: '6px !important'
        },
        '& .MuiFormControlLabel-root .MuiTypography-root': {
            fontSize: '14px !important'
        },
        '& .CalendarIcon': {
            width: '20px !important',
            height: '20px !important',
            '& circle, ellipse, path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '&:hover': {
                '& circle, ellipse, path': {
                    fill: theme.palette.secondary.main
                }
            },
            '&.active': {
                '& circle, ellipse, path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .tableChecbox': {
            width: '0.5% !important',
            maxWidth: 36,
            paddingRight: '0px !important',
            paddingLeft: '18px',
            '& label': {
                marginRight: '0px !important'
            }
        },
        '& th.tableChecbox label': {
            marginTop: '-3px !important'
        },
        '& .MuiCollapse-wrapperInner th h6': {},
        '& .MuiTableRow-root .MuiTableCell-root:nth-child(2)': {
            // paddingLeft: '5px !important'
        },
        '& .MuiTableRow-root': {
            '.MuiTableCell-root:last-child': {
                width: '15px !important'
            }
        },
        '& .collapseIcon': {
            width: 27,
            height: 27,
            padding: '0px !important'
        },
        '& .MuiOutlinedInput-input': {
            padding: '7px 14px 4.5px',
            background: '#fff'
        },
        '& .noOutline .MuiOutlinedInput-input': {
            background: `${theme.palette.greyshades.lightestgrey} !important`,
            borderRadius: 2
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: '0.5s all'
                }
            }
        },
        '& .chipContainer': {
            width: '100%',
            '& form': {
                width: '100%'
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.CalendarIcon.active': {
            '& circle, ellipse, path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`,
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .ChevDownIcon, .ChevUpIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .DownloadIcon': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .moreCount:hover': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                },
                '& .strokePath': {
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .collapseTableContainer': {
            overflowX: 'auto !important',
            height: "calc(100vh - 300px)"
        },
        '& .MuiTableRow-root.referenceLibrary': {
            '& td': {
                paddingBottom: '20px !important',
                '& .textBoxContainer div.MuiTextField-root p.MuiFormHelperText-root': {
                    textAlign: 'left !important',
                    height: 'auto !important'
                }
            }
        },
        '& .loaderContainer': {
            width: '100%'
        },
        '& .showCaps': {
            '& .MuiGrid-root': {
                textTransform: 'uppercase !important'
            },
            '& .MuiChip-root': {
                // textTransform: 'uppercase',
                fontWeight: 400
            }
        }
    },
    accordionTableRow: {
        background: '#ECEFF2',
        // background: '#fff',
        borderTop: `1px solid ${theme.palette.greyshades.lightgrey}`,
        '& .PlayIcon2': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .DownloadIcon': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .MuiTable-root, .MuiTableCell-body': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .MuiTableCell-body': {
            borderLeft: `none !important`,
            borderRight: `none !important`,
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey,
            fontFamily: theme.palette.headers.h1.fontFamily,
            fontWeight: theme.palette.headers.h1.weight,
            "& th.stickyColumn": {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 3,
                background: "inherit"
            }
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiCollapse-root .MuiTableCell-root': {
            padding: '4px 10px'
        },
        '& thead': {
            position: 'unset',
            top: 0,
            background: 'inherit',
            zIndex: 1
        },
        '& .MuiTableHead-root': {
            borderBottom: 'none !important',
            background: '#F5F7F8'
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            }
        },
        '& .MuiTableBody-root': {
            border: 'none !important',
            '& .MuiTableRow-root:nth-child(even)': {
                // background: "#F5F7F8"
            }
        },
        '& .MuiCollapse-root': {
            paddingRight: 30,
            paddingLeft: 15
        },
        '& .MuiCollapse-wrapper': {
            padding: '12px 8px 20px 6px'
        },
        '& .outlineNone': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
                '&:hover': {
                    borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`,
                    background: theme.palette.greyshades.lightestgrey
                }
            }
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: '0.5s all'
                }
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.CalendarIcon.active': {
            '& circle, ellipse, path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`,
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered': {
            borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`,
            '& .MuiCollapse-wrapper .MuiCollapse-wrapperInner': {
                '& .queryComponentContainers div.MuiGrid-root .validationResultContainer': {
                    '& .MuiGrid-item .MuiGrid-item .MuiTableContainer-root': {
                        width: "calc(100vw - 155px) !important",
                        overflow: "auto !important"
                    }
                }
            }
        },
        '& .moreCount:hover': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                },
                '& .strokePath': {
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTableRow-root.referenceLibrary': {
            '& td': {
                paddingBottom: '20px !important',
                '& .textBoxContainer div.MuiTextField-root p.MuiFormHelperText-root': {
                    textAlign: 'left !important',
                    height: 'auto !important'
                }
            }
        },
        '& .showCaps': {
            '& .MuiGrid-root': {
                textTransform: 'uppercase !important'
            },
            '& .MuiChip-root': {
                // textTransform: 'uppercase',
                fontWeight: 400
            }
        }
    },
    stripedTable: {
        width: '100%',
        position: 'relative',
        '& .circularLoaderContainer': {
            position: 'absolute',
            left: '50%',
            top: '20%',
            transform: 'translate(-50%)',
            zIndex: 99
        },
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .clickableItem': {
            '&:hover': {
                color: theme.palette.secondary.main
            }
        },
        '& .tableCell.removeCaseTransform .MuiGrid-root': {
            '&::first-letter': {
                textTransform: 'none !important'
            }
        },
        '& .tableCell.showCaps .MuiGrid-root': {
            textTransform: 'uppercase !important'
            // fontWeight: 600
        },
        '& .showCaps': {
            '& .MuiChip-root': {
                // textTransform: 'uppercase',
                fontWeight: 400
            }
        },
        '& .tableCell.initalCaps .MuiGrid-root': {
            textTransform: 'capitalize !important'
        },
        '& .MuiChip-root.highChip, .MuiChip-root.mediumChip, .MuiChip-root.lowChip, .MuiChip-root.okChip':
        {
            background: 'transparent !important',
            padding: '0px !important',
            cursor: 'default'
        },
        '& .MuiTable-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .ListofUsers.MuiGrid-root.mb-1': {
            marginBottom: '0px !important'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiInput-underline .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTableCell-root': {
            padding: '10px 12px',
            verticalAlign: 'middle !important',
            '& .MuiGrid-root': {
                '& .btnValidator .MuiFormHelperText-root': {
                    textAlign: "left !important"
                },
                '& button.btnSource': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                },
                '&.btnSource div': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                }
            },
            '& .MuiGrid-root .btnApiKey': {
                border: 'none !important',
                background: `none !important`,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.87) !important',
                '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26) !important'
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '5.5px 14px',
            background: '#fff'
        },
        '& .MuiSelect-outlined': {
            background: 'transparent !important'
        },
        '& .noOutline .MuiOutlinedInput-input': {
            background: `${theme.palette.greyshades.lightestgrey} !important`,
            borderRadius: 2
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: '0.5s all'
                }
            }
        },
        '& .CalendarIcon': {
            width: '20px !important',
            height: '20px !important',
            '& circle, ellipse, path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '&:hover': {
                '& circle, ellipse, path': {
                    fill: theme.palette.secondary.main
                }
            },
            '&.active': {
                '& circle, ellipse, path': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            textTransform: 'capitalize',
            padding: '10px 12px',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            },
            '& .searchBox ': {
                padding: '0px 10px !important',
                '& .searchIconRt': {
                    fontSize: '20px'
                },
                '& .searchRtWithClear': {
                    marginRight: '-7px !important'
                }
            },
            '& .MuiSelect-select': {
                padding: '5.5px 14px !important',
                paddingRight: '32px !important',
                '&~.ChevDownIcon': {
                    marginRight: '2px'
                }
            },
            '& .MuiAutocomplete-inputRoot': {
                padding: '0px !important',
                flexWrap: 'nowrap',
                display: 'flex',
                maxWidth: '250px',
                cursor: 'default',
                overflowX: 'auto',
                '& .MuiAutocomplete-input': {
                    padding: '6.5px 4px 7.5px 6px !important',
                    background: '#fff !important',
                    minWidth: '250px'
                },
                '& .MuiAutocomplete-tag': {
                    margin: '4px !important',
                    height: '24px !important',
                    fontSize: '13px !important',
                    '& .MuiChip-deleteIconOutlinedColorDefault': {
                        width: '18px !important',
                        height: '18px !important'
                    }
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.greyshades.grey}`,
                borderRadius: `2px`
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                }
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            borderLeft: `none !important`,
            borderRight: `none !important`,
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            '& .attributeTypeIcon': {
                color: `${theme.palette.secondary.main} !important`,
                background: theme.palette.primary.contrastText,
                padding: '2px 6px',
                height: '20px',
                marginRight: '7px',
                fontSize: '13px',
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c8cbcc66'
            },
            '& .textBoxContainer': {
                width: '100%'
            },
            '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
                padding: 0
            }
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey,
            "& th.stickyColumn": {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 3,
                background: "inherit"
            }
        },
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: `${theme.palette.greyshades.lightgrey} !important`
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root:nth-child(odd)': {
                // background: theme.palette.greyshades.tablegrey
                background: '#fff'
            },
            '& .MuiTableRow-root': {
                borderBottom: `1px solid #F1F4F7 !important`
            }
        },
        '& .keyicon': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 18,
            '& svg path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& .MuiChip-root': {
            height: 26,
            '&.numberChip': {
                borderRadius: 4,
                '& .MuiChip-label': {
                    paddingLeft: 9,
                    paddingRight: 9
                }
            },
            '&.listchips': {
                background: '#fff',
                border: `1px solid ${theme.palette.greyshades.darkgrey}`
            },
            '&.success': {
                color: theme.palette.componentColors.success,
                background: `${theme.palette.componentColors.success}25`
            },
            '&.danger': {
                color: theme.palette.componentColors.danger,
                background: `${theme.palette.componentColors.danger}25`
            },
            '&.warning': {
                color: theme.palette.componentColors.warning,
                background: `${theme.palette.componentColors.warning}25`
            },
            '&.info': {
                color: theme.palette.componentColors.info,
                background: `${theme.palette.componentColors.info}25`
            },
            '&.orange': {
                color: theme.palette.componentColors.shade2,
                background: `${theme.palette.componentColors.orangebg}25`
            },
            '&.default': {
                background: theme.palette.greyshades.lightestgrey,
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            }
        },
        '& .MuiIconButton-root.active svg': {
            fill: theme.palette.componentColors.info
        },
        '& .ThunderIcon': {
            width: '11.057px',
            height: '19.661px'
        },
        '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
            padding: '1px 4px 1px 0px !important'
        },
        '& .chipContainer': {
            width: '100%',
            '& form': {
                width: '100%'
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`,
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTable-stickyHeader': {
            '& .MuiTableHead-root': {
                '& .MuiTableRow-root:nth-child(2)': {
                    '& .MuiTableCell-stickyHeader': {
                        top: '40.88px !important',
                        background: '#fff !important'
                    }
                }
            }
        },
        '& .moreCount:hover': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                },
                '& .strokePath': {
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTableRow-root.referenceLibrary': {
            '& td': {
                paddingBottom: '20px !important',
                '& .textBoxContainer div.MuiTextField-root p.MuiFormHelperText-root': {
                    textAlign: 'left !important',
                    height: 'auto !important'
                }
            }
        }
    },
    rowStripedTable: {
        width: '100%',
        position: 'relative',
        '& .circularLoaderContainer': {
            position: 'absolute',
            left: '50%',
            top: '20%',
            transform: 'translate(-50%)',
            zIndex: 99
        },
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .showCaps': {
            '& .MuiGrid-root': {
                textTransform: 'uppercase !important'
            },
            '& .MuiChip-root': {
                // textTransform: 'uppercase',
                fontWeight: 400
            }
        },
        '& .MuiChip-root.highChip, .MuiChip-root.mediumChip, .MuiChip-root.lowChip, .MuiChip-root.okChip':
        {
            background: 'transparent !important',
            padding: '0px !important'
        },
        '& .MuiTable-root': {
            // border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .ListofUsers.MuiGrid-root.mb-1': {
            marginBottom: '0px !important'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiInput-underline .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTableCell-root': {
            padding: '16px 12px',
            verticalAlign: 'middle !important',
            '& .MuiGrid-root': {
                '& .btnValidator .MuiFormHelperText-root': {
                    textAlign: "left !important"
                },
                '& button.btnSource': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                },
                '&.btnSource div': {
                    border: 'none !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: `${theme.palette.greyshades.lightgrey} !important`,
                    fontWeight: 'normal',
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    }
                }
            },
            '& .MuiGrid-root .btnApiKey': {
                border: 'none !important',
                background: `none !important`,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.87) !important',
                '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26) !important'
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '5.5px 14px',
            background: '#fff'
        },
        '& .MuiSelect-outlined': {
            background: 'transparent !important'
        },
        '& .noOutline .MuiOutlinedInput-input': {
            background: `${theme.palette.greyshades.lightestgrey} !important`,
            borderRadius: 2
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: '0.5s all'
                }
            }
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            textTransform: 'capitalize',
            padding: '10px 12px',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            },
            '& .searchBox ': {
                padding: '0px 10px !important',
                '& .searchIconRt': {
                    fontSize: '20px'
                },
                '& .searchRtWithClear': {
                    marginRight: '-7px !important'
                }
            },
            '& .MuiSelect-select': {
                padding: '5.5px 14px !important',
                paddingRight: '32px !important',
                '&~.ChevDownIcon': {
                    marginRight: '2px'
                }
            },
            '& .MuiAutocomplete-inputRoot': {
                padding: '0px !important',
                flexWrap: 'nowrap',
                '& .MuiAutocomplete-input': {
                    padding: '6.5px 4px 7.5px 6px !important',
                    background: '#fff !important',
                    minWidth: '150px'
                },
                '& .MuiAutocomplete-tag': {
                    margin: '4px !important',
                    height: '24px !important',
                    fontSize: '13px !important',
                    '& .MuiChip-deleteIconOutlinedColorDefault': {
                        width: '18px !important',
                        height: '18px !important'
                    }
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.greyshades.grey}`,
                borderRadius: `2px`
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                }
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            borderLeft: `none !important`,
            borderRight: `none !important`,
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            // border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            '& .attributeTypeIcon': {
                color: `${theme.palette.secondary.main} !important`,
                background: theme.palette.primary.contrastText,
                padding: '2px 6px',
                height: '20px',
                marginRight: '7px',
                fontSize: '13px',
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c8cbcc66'
            },
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiTableRow-head': {
            // background: theme.palette.greyshades.lightgrey,
            "& th.stickyColumn": {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 3,
                background: "inherit"
            }
        },
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: `${theme.palette.greyshades.lightgrey} !important`
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root:nth-child(odd)': {
                background: theme.palette.greyshades.tablegrey,
                '& .TableRoot-stickyColumn-*': {
                    background: theme.palette.greyshades.tablegrey
                }
                // background: '#fff'
            },
            '& .MuiTableRow-root:nth-child(even)': {
                background: '#fff',
                '& .TableRoot-stickyColumn-*': {
                    background: '#fff'
                }
                // background: '#fff'
            },
            '& .MuiTableRow-root': {
                // boxShadow: '0px 1px 7px #b0bac961'

                // borderBottom: `1px solid #F1F4F7 !important`,
                '& .MuiTableCell-root:first-child *': {
                    /*
                     * after confirmation to implement
                     * textTransform: 'uppercase',
                     * fontWeight: 600
                     */
                }
            }
        },
        '& .keyicon': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 18,
            '& svg path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& .MuiChip-root': {
            height: 26,
            '&.numberChip': {
                borderRadius: 4,
                '& .MuiChip-label': {
                    paddingLeft: 9,
                    paddingRight: 9
                }
            },
            '&.listchips': {
                background: '#fff',
                border: `1px solid ${theme.palette.greyshades.darkgrey}`
            },
            '&.success': {
                color: theme.palette.componentColors.success,
                background: `${theme.palette.componentColors.success}25`
            },
            '&.danger': {
                color: theme.palette.componentColors.danger,
                background: `${theme.palette.componentColors.danger}25`
            },
            '&.warning': {
                color: theme.palette.componentColors.warning,
                background: `${theme.palette.componentColors.warning}25`
            },
            '&.info': {
                color: theme.palette.componentColors.info,
                background: `${theme.palette.componentColors.info}25`
            },
            '&.orange': {
                color: theme.palette.componentColors.shade2,
                background: `${theme.palette.componentColors.orangebg}25`
            },
            '&.default': {
                background: theme.palette.greyshades.lightestgrey,
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            }
        },
        '& .MuiIconButton-root.active svg': {
            fill: theme.palette.componentColors.info
        },
        '& .ThunderIcon': {
            width: '11.057px',
            height: '19.661px'
        },
        '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
            padding: '1px 4px 1px 0px !important'
        },
        '& .chipContainer': {
            width: '100%',
            '& form': {
                width: '100%'
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`,
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .MuiTable-stickyHeader': {
            '& .MuiTableHead-root': {
                '& .MuiTableRow-root:nth-child(2)': {
                    '& .MuiTableCell-stickyHeader': {
                        top: '44.88px !important',
                        background: '#fff !important'
                    }
                }
            }
        },
        '& .MuiIconButton-root': {
            '&:hover': {
                '& .fillPath': {
                    fill: `${theme.palette.secondary.main} !important`
                },
                '& .strokePath': {
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        }
    },
    outlineNone: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '& .MuiOutlinedInput-input': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
            '&:hover': {
                background: theme.palette.greyshades.lightestgrey
            }
        },
        '&:hover': {
            borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
        }
    },
    tableContainer: {
        '& svg.NoResultIcon': {
            width: '140px',
            height: '140px'
        },
        '& .EditIcon': {
            width: '18px',
            height: '18px',
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '&:hover': {
                '& path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .noResulteContainer': {
            backgroundColor: "#fff !important"
        }
    },
    highlightRow: {
        '& .MuiTableCell-root:first-child *': {
            /*
             *  after confirmation to implement
             * fontWeight: 600,
             * textTransform: 'uppercase'
             */
        },
        '&.high': {
            background: `${theme.palette.colorThemes.high}10 !important`
        },
        '&.medium': {
            background: `${theme.palette.colorThemes.medium}10 !important`
        },
        '&.low': {
            background: `${theme.palette.colorThemes.low}10 !important`
        }
    },
    PlusIcon: {
        marginTop: '-4px !important',
        '& .PlusIcon:hover': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    termApprovalIconContainer: {
        background: 'transparent',
        '&.acceptIcon': {
            position: 'relative !important',
            top: 'unset',
            right: 'unset',
            '& button': {
                height: 26,
                width: 26
            }
        }
    },
    scoreText: {
        marginLeft: "8px !important",
        fontSize: 12
    },
    textWithIcon: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContents: "flex-start"
    },
    textWithImage: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContents: "flex-start"
    },
    errorlogIcon: {
        fill: `${theme.palette.greyshades.darkgrey} !important`
    },
    typeLabel: {
        float: "left !important",
        color: `${theme.palette.secondary.main} !important`,
        background: theme.palette.greyshades.lightgrey,
        padding: '3px 6px',
        fontSize: '14px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textTransform: 'capitalize !important',
        flexShrink: 0,
        marginRight: '8px !important',
        textAlign: "center",
        minWidth: '15px'
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        "& .MuiGrid-root.thunderIcon": {
            width: '8px',
            height: '13px',
            marginLeft: '6px'
        },
        "& .MuiGrid-root.issueIcon": {
            marginLeft: '8px',
            marginTop: '2px',
            display: "flex",
            alignSelf: "center"
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            minWidth: 80,
            maxWidth: '200px !important',
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '6.5px 8px',
            textAlign: 'center',
            fontSize: 15
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        },
        '& .rangeLabel': {
            textTransform: "capitalize",
            marginRight: 12,
            color: "rgba(0, 0, 0, 0.38)",
            fontWeight: 400,
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontSize: theme.palette.headers.body1.size,
            fontStyle: theme.palette.headers.body1.style,
            textDecoration: theme.palette.headers.body1.textDecoration
        }
    },
    stickyColumn: {
        position: "sticky",
        left: 0,
        zIndex: 1,
        background: "inherit"
    },
    removeTableBorder: {
        border: 'none !important'
    },
    collapseStyledTable: {
        '& .MuiTableBody-root > .accordionTableRow': {
            '& .MuiCollapse-root': {
                background: '#ECEFF2 !important'
            },
            '& .accordionTableRow': {
                '& .MuiCollapse-root': {
                    background: '#fff !important',
                    paddingLeft: '14px',
                    paddingRight: '26px'
                },
                '&>.MuiTableCell-root': {
                    padding: '0px !important'
                },
                '& .MuiCollapse-entered ': {
                    marginBottom: '10px !important'
                }
            }
        },
        '& .MuiCollapse-root': {
            paddingLeft: '8px',
            paddingRight: '18px',
            paddingTop: '4px',
            paddingBottom: '2px',
            '& .MuiTableCell-root': {
                padding: '6px 10px'
            }
        }
    }
});

export default TableStyles;