const style = (theme) => ({
    navbar: {
        height: '80px',
        '& .navRightside': {
            display: 'flex',
            alignItems: 'center',
            padding: '8px 20px 8px 20px',
            '& svg': {
                fill: theme.palette.greyshades.darkgrey
            },
            '& .MuiIconButton-root:hover svg ': {
                fill: theme.palette.secondary.main
            },
            '& .profileImg': {
                margin: '0px',
                fontSize: 16,
                background: `${theme.palette.secondary.main}90`
            },
            '& .AlertsIcon path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '& .AlertsIcon': {
                width: '23px',
                height: '23px'
            },
            '& .MuiIconButton-root:hover .NotificationIcon #Path_1191': {
                fill: theme.palette.secondary.main
            },
            '& .PlusIcon': {
                width: '24px',
                height: '24px'
            }
        }
    },
    popoverContainer: {
        '& .MuiMenuItem-root': {
            paddingRight: '40px',
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`,
                '& svg path': {
                    fill: theme.palette.secondary.main
                }
            },
            '& svg': {
                marginRight: '10px'
            }
        }
    },
    backNavigation: {
        // backgroundColor: `${theme.palette.greyshades.darkgrey} !important`,
        borderRadius: '4px !important',
        width: 38,
        height: 38,
        '& svg path': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    appLogo: {
        width: 38,
        height: 38,
        padding: '11px 20px 11px 20px',
        objectFit: 'contain'
    },
    logoSection: {
        position: 'relative',
        display: 'flex',
        alignItems: " center"
    },
    logoSectionDiv: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: " center",
        '&:hover': {
            '& .backNavigation': {
                visibility: 'visible',
                left: 60,
                opacity: 1
            },
            '& .appLogo': {
                opacity: 1,
                transition: '0.5s',
                width: 38
            }
        },
        '& .backNavigation': {
            visibility: 'hidden',
            transition: "0.5s",
            position: 'absolute',
            left: 40,
            opacity: 0,
            top: '11px !important'
        }
    },
    globalSearch: {
        padding: '8px 20px 8px 20px',
        '& input': {
            minWidth: 500
        }
    },
    pageTitle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 30,
        height: 80
    },
    //notification
    notificationHeader: {
        padding: '16px 24px 16px 16px',
        borderBottom: `1px solid ${theme.palette.greyshades.lightgrey}`
    },
    notifyTitle: {
        fontSize: `${(theme.palette.headers.h2.size) - 2}px !important`
    },
    notificationContainer: {
        width: '500px',
        maxHeight: '100% !important',
        '& .title': {
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
            fontWeight: 600,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .time': {
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`
        },
        '& .description': {
            textTransform: 'lowercase !important',
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
            display: 'block',
            wordBreak: 'break-word',
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .MuiList-root': {
            maxWidth: '100%'
        },
        '& .MuiListItem-root': {
            padding: '0px !important',
            position: 'relative'
        },
        '& .MuiListItemButton-root': {
            paddingLeft: '22px',
            paddingRight: '8px',
            paddingTop: '10px',
            paddingBottom: '10px',
            alignItems: "self-start"
        },
        '& .MuiAvatar-root': {
            width: '37px',
            height: '37px'
        },
        '& .MuiListItemAvatar-root': {
            minWidth: '52px',
            position: 'relative'
        },
        '& .notReaded': {
            '& .MuiAvatar-root': {
                outline: `3px solid ${theme.palette.primary.main}`
            }
        },
        '& .notifyText': {
            maxWidth: 'calc(100% - 100px)'
        },
        '& .notifyTime': {
            width: '100px',
            display: 'block',
            textAlign: 'right'
        },
        '& .activeDot': {
            width: '8px',
            height: '8px',
            background: theme.palette.primary.main,
            borderRadius: '57px',
            position: 'absolute',
            left: '-18px',
            top: '50%',
            transform: 'translateY(-50%)'
        }
    },
    notificationBody: {
        padding: '6px 10px 16px',
        height: 'calc(100vh - 174px)',
        overflowY: 'auto'
    },
    switch: {
        '& .MuiFormControlLabel-label': {
            paddingRight: '6px',
            marginTop: '-3px'
        }
    }
});

export default style;