const style = (theme) => ({
    changePasswordPageContainer: {
        backgroundRepeat: 'no-repeat',
        padding: '20px',
        maxWidth: 320,
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px'
        },
        '& .MuiSvgIcon-root': {
            fill: theme.palette.greyshades.darkgrey
        }
    }
});

export default style;