import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

//  Import Styles
import HeaderStyle from "../../style.jsx";
import LayoutStyles from '../../../../../../../layouts/style.jsx';

function Header(props) {

    /**
     * Define Props
     */
    const { classes } = props;

    return (
        <Grid container className={classes.headerList}>
            <Grid item className={classes.cellItem}>
                <Typography variant="h6" className="fw-500">
                    Element
                </Typography>
            </Grid>
            <Grid item className={classes.cellItem} sx={{ minWidth: 240 }}>
                <Typography variant="h6" className="fw-500">
                    Font Type
                </Typography>
            </Grid>
            <Grid item className={classes.cellItem}>
                <Typography variant="h6" className="fw-500">
                    Font Style
                </Typography>
            </Grid>
            <Grid item className={classes.cellItem}>
                <Typography variant="h6" className="fw-500">
                    Color
                </Typography>
            </Grid>
        </Grid>
    );
}

// default props
Header.defaultProps = {
    classes: {}
};

// prop types
Header.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...HeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Header);