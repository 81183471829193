import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getConfigurationRequest, getConfigurationSuccess, getConfigurationFailure, createConfigrationRequest, createConfigrationSuccess,
    createConfigrationFailure, updateConfigurationRequest, updateConfigurationSuccess, updateConfigurationFailure,
    deleteConfigurationRequest, deleteConfigurationSuccess, deleteConfigurationFailure, getCollibraAttributeTypesRequest,
    getCollibraAttributeTypesSuccess, getCollibraAttributeTypesFailure, getAlationDataSourceRequest,
    getAlationDataSourceSuccess, getAlationDataSourceFailure
} from '../reducer/integrationReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';
import { defaultDataRequest, deleteChannelConfig } from "../reducer/defaultReducer";

// Import Services
import { integrationService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Get Detail
 * @returns
 */
const getDetail = (action$) => action$.pipe(
    ofType(getConfigurationRequest),
    mergeMap(({ payload }) => {
        return from(integrationService.detail(payload)).pipe(
            concatMap((res) => { return of(getConfigurationSuccess(res.data)); }),
            catchError((error) => { return of(getConfigurationFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(createConfigrationRequest),
    mergeMap(({ payload }) => {
        return from(integrationService.create(payload)).pipe(
            concatMap((res) => {
                return of(createConfigrationSuccess(res.data),
                    displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }),
                    defaultDataRequest("channels"),
                    payload.type === "collaboration" || payload.channelName === "alation" ?
                        navigate({ path: 'settings.integration.detail', state: {}, params: [payload?.channelName, res.data.id] }) :
                        navigate({ path: 'settings.integration.root', state: {}, params: [] }));
            }),
            catchError((error) => { return of(createConfigrationFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateConfigurationRequest),
    mergeMap(({ payload: { id, has_mapping, ...rest } }) => {
        return from(integrationService.update(id, rest)).pipe(
            concatMap((res) => {
                if (has_mapping) {
                    return of(
                        updateConfigurationSuccess(res.data),
                        displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })
                    );
                }
                return of(
                    updateConfigurationSuccess(res.data),
                    displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }),
                    navigate({ path: 'settings.integration.root', state: {}, params: [] })
                );
            }),
            catchError((error) => { return of(updateConfigurationFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Delete
 * @returns
 */
const deleteChannel = (action$) => action$.pipe(
    ofType(deleteConfigurationRequest),
    mergeMap(({ payload: { id } }) => {
        return from(integrationService.deleteChannel(id)).pipe(
            concatMap((res) => { return of(deleteConfigurationSuccess(res.data), deleteChannelConfig({ id }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted })); }),
            catchError((error) => { return of(deleteConfigurationFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Collibra Attribute Types
 * @returns
 */
const getCollibraAttribute = (action$) => action$.pipe(
    ofType(getCollibraAttributeTypesRequest),
    mergeMap(({ payload }) => {
        return from(integrationService.getCollibraAttributeTypes(payload)).pipe(
            concatMap((res) => {
                if (!res.data.length) {
                    return of(getCollibraAttributeTypesSuccess([]), displyAlert({ 'type': 'error', 'message': "Invalid Credentials" }));
                }
                return of(getCollibraAttributeTypesSuccess(res));
            }),
            catchError((error) => { return of(getCollibraAttributeTypesFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Alation Data Source
 * @returns
 */
const getAlationData = (action$) => action$.pipe(
    ofType(getAlationDataSourceRequest),
    mergeMap(({ payload }) => {
        return from(integrationService.getAlationDataSource(payload)).pipe(
            concatMap((res) => {
                if (!res.data.length) {
                    return of(getAlationDataSourceSuccess([]), displyAlert({ 'type': 'error', 'message': "Invalid Credentials" }));
                }
                return of(getAlationDataSourceSuccess(res));
            }),
            catchError((error) => { return of(getAlationDataSourceFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Integration Functions
export const integrationEpic = [getDetail, create, update, deleteChannel, getCollibraAttribute, getAlationData];