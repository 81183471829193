const style = (theme) => ({
    continuousSlider: {
        width: '100%',
        marginLeft: '18px !important',
        marginRight: '18px !important',
        '& .MuiSlider-thumb': {
            width: '18px',
            height: '18px',
            left: '8px',
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiSlider-rail': {
            color: '#C8CBCC'
        },
        '& .MuiSlider-valueLabelOpen': {
            background: 'transparent !important',
            transform: 'translateY(-65%) scale(1) !important',
            color: `${theme.palette.headers.body1.color} !important`,
            fontSize: '14px',
            '&:before': {
                display: 'none !important'
            }
        },
        '& .MuiSlider-root': {
            color: 'transparent'
        }
    },
    textSecondary: {
        color: `${theme.palette.greyshades.darkgrey} !important`
},
rangeSlider: {
    '& .MuiSlider-root': {
        minWidth: 70,
        padding: '8px 0'
    },
    '& .MuiSlider-thumb': {
        width: 16,
        height: 16
    }
},
sliderLabel: {
    marginTop: '-3px !important'
}
});
export default style;