const style = (theme) => ({
    patternValuesRt: {
        display: 'flex',
        alignItems: 'center'
    },
    pattenDelete: {
        marginLeft: '10px !important',
        padding: '0px !important',
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    enumerationAddContainer: {
        padding: 24
    },
    enumTextBox: {
        flexWrap: "nowrap !important",
        '& .textBoxContainer': {
            width: 'calc(100% - 82px)'
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px !important',
            paddingLeft: '0px !important'
        },
        '&:hover': {
            '& .MuiOutlinedInput-input': {
                padding: '8.5px 14px !important',
                paddingLeft: '14px !important'
            }
        },
        "& .accept": {
            background: `#fff !important`,
            '& svg': {
                fill: `#00cd08 !important`
            }
        },
        '& .reject': {
            background: `#fff !important`,
            '& svg': {
                fill: `#f5655a  !important`
            }
        }
    },
    valuesPatternList: {
        marginTop: '15px !important',
        maxHeight: '248px',
        overflowY: 'auto',
        paddingRight: '10px',
        '& .textBoxBg .MuiInputBase-input': {
            fontSize: '15px !important'
        }
    },
    PlusIcon: {
        '& .PlusIcon:hover': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    chartIconClass: {
        '& svg': {
            fill: theme.palette.greyshades.darkgrey,
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    }
});
export default style;