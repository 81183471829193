import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

//import Componetns
import { Grid, Typography } from '@mui/material';

//import Styles
import Styles from "./style.jsx";
import LayoutStyles from '../../../../../../../layouts/style.jsx';
import { SwitchComponent } from '../../../../../../../components/index.js';
import appConstants from '../../../../../../../constants/appConstants.js';

function Constraints(props) {

    /**
     * Define Props
     */
    const { classes, data, onChange, semanticsPermission } = props;

    return (
        <Grid container spacing={6}>
            <Grid item xs={6} className="pr-3">
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.datasetProperties.nullBlankUnique}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.propertiesDescription}`}
                    align="left"
                >
                    {appConstants.labels.datasetProperties.nullBlankUniqueDesc}
                </Typography>
                <Grid item className="dflex alignCenter mt-2">
                    <Grid className="mr-5">
                        <SwitchComponent size="small"
                            checked={data.is_null || false}
                            handleChange={(value) => onChange("is_null", value)}
                            label={appConstants.labels.datasetProperties.null}
                            disabled={!semanticsPermission?.is_edit} />
                    </Grid>
                    <Grid className="mr-5">
                        <SwitchComponent size="small"
                            checked={data.is_blank || false}
                            handleChange={(value) => onChange("is_blank", value)}
                            label={appConstants.labels.datasetProperties.blank}
                            disabled={!semanticsPermission?.is_edit} />
                    </Grid>
                    <SwitchComponent size="small"
                        checked={data.is_unique || false}
                        handleChange={(value) => onChange("is_unique", value)}
                        label={appConstants.labels.datasetProperties.unique}
                        disabled={!semanticsPermission?.is_edit} />
                </Grid>
            </Grid>
            <Grid item xs={6} className="pl-4">
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.datasetProperties.primary}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.propertiesDescription}`}
                    align="left"
                >
                    {appConstants.labels.datasetProperties.primaryDesc}
                </Typography>
                <Grid item className="dflex alignCenter spaceBetween mt-2">
                    <SwitchComponent size="small"
                        checked={data.is_primary_key || false}
                        handleChange={(value) => onChange("is_primary_key", value)}
                        label={appConstants.labels.datasetProperties.on}
                        disabled={!semanticsPermission?.is_edit} />
                </Grid>
            </Grid>
        </Grid>
    );
}

// prop types
Constraints.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func,
    semanticsPermission: PropTypes.object
};

// default props
Constraints.defaultProps = {
    classes: {},
    data: {},
    onChange: () => { },
    semanticsPermission: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Constraints, areEqual));