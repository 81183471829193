const style = (theme) => ({
    widgetItemDetail: {
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        paddingBottom: 10,
        marginBottom: '15px !important',
        '& .commentText p': {
            margin: '0px !important',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            color: theme.palette.headers.body1.color,
            fontFamily: theme.palette.headers.body1.fontFamily
        },
        '& mention a': {
            color: `${theme.palette.secondary.main} !important`
        }
    },
    widgetListIcon: {
        marginRight: '15px !important'
    },
    latestTopics: {
        height: '100%',
        overflowY: 'auto',
        paddingRight: 8
    }
});

export default style;