import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { NestedAccordian } from '../../../../../../components/index.js';

// Import Actions
import { getApplicationQualityRequest, collapseAccordian, getApplicationsRequest } from '../../../../../../redux/reducer/applicationReducer.js';
import { navigate } from '../../../../../../redux/reducer/navigationReducer';
import { getDomainListRequest } from '../../../../../../redux/reducer/semanticReducer';
import { getTagsRequest } from '../../../../../../redux/reducer/tagsReducer.js';
import { getTermsRequest } from '../../../../../../redux/reducer/termReducer';

// Import Helpers
import { orderList } from '../../../../../../helpers/appHelpers.js';

function AssetQualityMeasure() {

    /**
     * Destructure Props
     */
    const { id: application_id } = useParams();
    const dispatch = useDispatch();

    /**
     * Reducers
     */
    const { quality, qualityIsLoading, searchableApplicationsList } = useSelector((state) => state.applications);
    const { searchableGlossaries } = useSelector((state) => state.semantic);
    const { searchableTagsList } = useSelector((state) => state.tags);
    const { searchableTermsList } = useSelector((state) => state.term);


    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "name", orderBy: "asc"
    });


    /**
     * Handle Cell Click Event
     * @param {*} key
     */
    const handleCellClickEvent = (key, data) => {
        if (data.type === "asset") {
            dispatch(navigate({ path: 'assets.root', state: {}, params: [data.id] }));
        } else if (data.type === "attribute") {
            dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [data.asset_id, data.id] }));
        } else {
            const state = {
                measure_id: data?.id ?? null,
                name: data?.name ?? '',
                measureName: data?.name ?? '',
                isAssetLevel: !data.attribute_id,
                isMeasureLevel: false,
                attribute_id: data?.attribute_id,
                asset_id: data?.asset_id,
                showEdit: true,
                prevUrl: true
            };
            dispatch(navigate({ path: 'measure.detail', state: state, params: [data?.id ?? null] }));
        }
    };

    /**
     * Get Application Quality
     * @param {*} params
     * @param {*} clear
     */
    const getApplicationQuality = (params, clear = false) => {
        const requestParams = {
            ...params,
            application_id,
            clear
        };
        dispatch(getApplicationQualityRequest(requestParams));
    };

    /**
     * Handle Search Event
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        const params = {
            search_by,
            level: "asset"
        };
        getApplicationQuality(params, true);
    };
    /**
     * Handle Accordion Event
     * @param {*} value
     * @param {*} data
     * @param {*} type
     */
    const onCollapseEvent = (value, data, type) => {
        const requestParams = {
            type,
            value,
            asset_id: type === "asset" ? data.id : data.asset_id,
            attribute_id: data.id
        };
        dispatch(collapseAccordian(requestParams));
        if (!data?.children?.length) {
            const level = type === "asset" ? "attribute" : "measure";
            const params = {
                accordian: requestParams,
                search_by: searchData,
                level
            };
            if (level === "attribute") {
                params.asset_id = data.id;
            } else {
                params.attribute_id = data.id;
                params.asset_id = data.asset_id;
            }
            getApplicationQuality(params);
        }
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
        const params = {
            level: "asset",
            sortBy: sorting.sortBy,
            orderBy: sorting.orderBy,
            search_by: searchData
        };
        getApplicationQuality(params, true);
    };

    /**
     * Get Application Quality Data
     */
    useEffect(() => {
        if (application_id) {
            getApplicationQuality({ level: "asset" }, true);
        }
    }, [application_id]);

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Domains List
        if (!searchableGlossaries || !searchableGlossaries.length) {
            dispatch(getDomainListRequest());
        }
        // Get Applications List
        if (!searchableApplicationsList || !searchableApplicationsList.length) {
            dispatch(getApplicationsRequest());
        }
        // Get Tags List
        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
        // Get Terms List
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }
    }, [dispatch]);

    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(searchableTermsList), [searchableTermsList]);

    /**
     * Filter tags
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsList), [searchableTagsList]);

    /**
     * Set Accordion Values
     */
    const accordianMenus = {
        "asset": {
            showHeader: true,
            headers: [
                { key: 'name', name: 'Asset/ Attributes/ Measures', width: '20%', sorting: true, tooltip: true, component: "asset", isSearch: true, searchKey: 'name', searchComponent: "text", clickable: true },
                { key: 'alerts', name: 'Alerts', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'issues', name: 'Issues', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'domains', name: 'Domain', width: '20%', isNotEditable: true, isSearch: true, searchComponent: "autocomplete", list: domainsList || [], searchKey: "domains", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'tags', name: 'Tags', width: '20%', isNotEditable: true, isSearch: true, searchComponent: "autocomplete", list: tagsList || [], searchKey: "tags", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'applications', name: 'Application', isNotEditable: true, isSearch: true, searchComponent: "autocomplete", list: applicationsList || [], searchKey: "applications", showEmptyOption: false, width: '20%', component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'terms', name: 'Terms', width: '20%', isNotEditable: true, isSearch: true, searchComponent: "autocomplete", list: termsList || [], searchKey: "terms", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'score', name: 'Score', width: '8%', isSearch: false, sorting: true, tooltip: true, component: "score" },
                { key: 'accordian', component: 'accordian', name: 'Actions', width: '8%', align: 'center' }
            ],
            accordian: true,
            childLevel: "attribute"
        },
        "attribute": {
            showHeader: false,
            headers: [
                { key: 'name', name: 'Name', component: "attribute", sorting: true, tooltip: true, hidePrimaryKey: true, width: '20%', isSearch: true, searchKey: 'name', searchComponent: "text", clickable: true },
                { key: 'alerts', name: 'Alerts', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'issues', name: 'Issues', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'domains', name: 'Domain', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: domainsList || [], searchKey: "domains", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'tags', name: 'Tags', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: tagsList || [], searchKey: "tags", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'applications', name: 'Application', isNotEditable: true, width: '20%', showNA: true, searchComponent: "autocomplete", list: applicationsList || [], searchKey: "applications", showEmptyOption: false, component: 'chips', valueKey: 'name', limit: 1 },
                { key: 'terms', name: 'Terms', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: termsList || [], searchKey: "terms", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'score', name: 'Score', width: '8%', sorting: true, tooltip: true, isSearch: false, component: "score" },
                { key: 'accordian', component: 'accordian', name: 'Actions', width: '8%', align: 'center', renderComponent: (data) => data?.type !== "measure", conditionalRender: true, hideNA: true }
            ],
            accordian: true,
            childLevel: "measure"
        },
        "measure": {
            showHeader: false,
            headers: [
                { key: 'name', name: 'Name', component: "text", sorting: true, tooltip: true, width: '20%', isSearch: true, searchKey: 'name', searchComponent: "text", clickable: true },
                { key: 'alerts', name: 'Alerts', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'issues', name: 'Issues', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'domains', name: 'Domain', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: domainsList || [], searchKey: "domains", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'tags', name: 'Tags', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: tagsList || [], searchKey: "tags", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'applications', name: 'Application', isNotEditable: true, width: '20%', showNA: true, searchComponent: "autocomplete", list: applicationsList || [], searchKey: "applications", showEmptyOption: false, component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'terms', name: 'Terms', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: termsList || [], searchKey: "terms", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'score', name: 'Score', width: '8%', isNotEditable: true, sorting: true, tooltip: true, isSearch: false, component: "score" },
                { key: 'accordian', name: 'Actions', width: '8%', align: 'center' }
            ],
            childLevel: "measure"
        }
    };

    return (
        <Grid>
            <ValidatorForm onSubmit={() => null}>
                <NestedAccordian
                    options={[{ type: "search", customFunction: null }, { type: "columns", customFunction: null }]}
                    accordian={accordianMenus}
                    data={quality}
                    headerProperty={accordianMenus.asset}
                    onAccordianOnChange={onCollapseEvent}
                    defaultShowSearch
                    searchData={searchData}
                    sortBy={sorting.sortBy}
                    orderBy={sorting.orderBy}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onCellClick={handleCellClickEvent}
                    onClickSorting={onClickSorting}
                    isLoading={qualityIsLoading}
                    selectComponentList={
                        {
                            applications: applicationsList,
                            domains: domainsList,
                            tags: tagsList,
                            term: termsList
                        }
                    }
                />
            </ValidatorForm>
        </Grid>
    );
}

// default props
AssetQualityMeasure.defaultProps = {
    classes: {}
};

// prop types
AssetQualityMeasure.propTypes = {
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AssetQualityMeasure);