import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, Checkbox, FormControlLabel, FormControl, FormLabel, FormGroup, Switch, CircularProgress } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent, SelectComponent, PasswordInputComponent, AutoCompleteComponent } from '../../../components/index.js';

//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import AlationIcon from '../../../assets/img/apps/alation.png';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { checkPermission, setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';

// Import Actions
import { getConfigurationRequest, updateConfigurationRequest, getAlationDataSourceRequest, createConfigrationRequest } from '../../../redux/reducer/integrationReducer';
import featureConstants from '../../../constants/featureConstants.js';
import { navigate } from '../../../redux/reducer/navigationReducer';

function Alation(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [checkChanges, setCheckChanges] = useState(false);
    const [formData, setFormData] = useState({
        authentication_type: appConstants.labels.integration.alation.authentication_type_dd[0],
        host: '',
        user: '',
        password: '',
        user_id: '',
        refresh_token: '',
        datasource: [],
        import: {
            domains: true,
            tags: true
        },
        export: [
            {
                asset: true,
                options: {
                    summary: true,
                    measures: true,
                    alerts: true,
                    issues: true
                }
            },
            {
                attribute: true,
                options: {
                    summary: true,
                    measures: true,
                    alerts: true,
                    issues: true
                }
            },
            {
                domain: true,
                options: {
                    summary: true,
                    measures: true,
                    alerts: true,
                    issues: true
                }
            }
        ]
    });

    /**
     * Redux Store
     */
    const { configuration, saveLoading, alationDataSource, isLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };

    /**
     * Handle Checkbox
     * @param {*} property
     * @param {*} value
     */
    const onCheckBoxChange = (property, key, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({
            ...prevState,
            [property]: {
                ...prevState[property],
                [key]: value
            }
        }));
    };

    /**
     * Handle SwitchBoxCheckBoxChange
     * @param {*} property
     * @param {*} key
     * @param {*} value
     */
    const onSwitchBoxCheckBoxChange = (property, index, key, value) => {
        setCheckChanges(true);
        setFormData((prevState) => {
            const updatedExport = [...prevState[property]];
            const updatedItem = { ...updatedExport[index] };
            updatedItem[key] = value;
            updatedItem.options = Object.fromEntries(Object.entries(updatedItem.options).map(([k]) => (
                [k, value]
            )));
            updatedExport[index] = updatedItem;
            return { ...prevState, [property]: updatedExport };
        });
    };

    const onSwitchBoxChange = (property, index, key, value, option) => {
        setCheckChanges(true);
        setFormData((prevState) => {
            const updatedExport = [...prevState[property]];
            const updatedOptions = {
                ...updatedExport[index].options,
                [option]: value
            };
            updatedExport[index] = {
                ...updatedExport[index],
                options: updatedOptions,
                [key]: Object.values(updatedOptions).some((val) => val === true)
            };
            return { ...prevState, [property]: updatedExport };
        });
    };

    /**
     * On Save
     */
    const onSave = () => {
        const config = {
            ...formData,
            user: encryptDecrypt.encrypt(formData?.user ?? ""),
            password: encryptDecrypt.encrypt(formData?.password ?? ""),
            refresh_token: encryptDecrypt.encrypt(formData?.refresh_token ?? ""),
            export: JSON.stringify(formData?.export ?? []),
            datasource: JSON.stringify(formData?.datasource ?? [])
        };
        const requestParams = {
            config,
            channel: channel.id || configuration.channel,
            id: channelId || configuration.id,
            validate: checkChanges,
            channelName: "alation"
        };
        if (!channelId) {
            dispatch(createConfigrationRequest(requestParams));
        } else {
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);


    /**
     * Set FormData
     */
    useEffect(() => {
        if (configuration && (channelId || configuration.id)) {
            const config = {
                ...configuration
            };
            config.user = encryptDecrypt.decrypt(config?.user ?? "");
            config.password = encryptDecrypt.decrypt(config?.password ?? "");
            config.refresh_token = encryptDecrypt.decrypt(config?.refresh_token ?? "");
            config.export = JSON.parse(configuration?.export ?? JSON.stringify(formData?.export));
            setFormData({ ...config });
            if (configuration.is_valid) {
                const requestParams = {
                    config,
                    channel: channel.id || configuration.channel,
                    id: channelId || configuration.id,
                    validate: checkChanges,
                    channelName: "alation"
                };
                dispatch(getAlationDataSourceRequest(requestParams));
            }
        }
    }, [configuration]);

    /**
     * Navigate To Integrations List Page
     */
    const navigateToIntegrations = () => {
        dispatch(navigate({ path: 'settings.integration.root', state: {}, params: [] }));
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={AlationIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.alation.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.alation.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="form_text_host"
                                name={'host'}
                                value={formData.host}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.host}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.host
                                        )
                                    ]
                                }
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                valOnChange
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <SelectComponent
                                id="form_text_authentication_type"
                                name={'authentication_type'}
                                fullWidth={false}
                                label={appConstants.labels.connector.authentication_type}
                                variant="standard"
                                value={formData.authentication_type || ''}
                                onSelectChange={(value) => onChange('authentication_type', value)}
                                list={appConstants.labels.integration.alation.authentication_type_dd}
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.authentication_type
                                        )
                                    ]
                                }
                                isDisabled={!integrationPermission?.is_edit}
                            />
                        </Grid>

                        {
                            appConstants.labels.integration.alation.authentication_type_dd[0] === formData.authentication_type &&
                            <React.Fragment>
                                <Grid item xs={5}>
                                    <MemoTextBoxComponent
                                        id="form_text_user"
                                        name={'user'}
                                        value={formData.user}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.user}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.user
                                                )
                                            ]
                                        }
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    <PasswordInputComponent
                                        id="form_text_password"
                                        name={'password'}
                                        value={formData.password}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.authentication.password}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['passwordRequired']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.authentication
                                                        .password
                                                )
                                            ]
                                        }
                                        onChange={(event, value) => onChange(event.target.name, value)}
                                        inputProps={{ maxLength: 100 }}
                                        valOnChange
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            configuration.is_valid &&
                            <Grid item xs={12}>
                                <Grid item xs={5} className="dflex">
                                    <AutoCompleteComponent
                                        id="form_text_datasource"
                                        name={'datasource'}
                                        fullWidth
                                        variant="standard"
                                        placeholder={"Select Data Source"}
                                        multiple
                                        data={alationDataSource || []}
                                        selectedValue={formData.datasource || []}
                                        label={"Data Source"}
                                        onChange={(event, value) => onChange('datasource', value)}
                                        limitTags={3}
                                        disabled={isLoading}
                                        select_all
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.valueRequired]}
                                    />
                                    {
                                        isLoading &&
                                        <Grid className="mt-2 ml-1">
                                            <CircularProgress size={30} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                        {
                            appConstants.labels.integration.alation.authentication_type_dd[1] === formData.authentication_type &&
                            <React.Fragment>
                                <Grid item xs={5}>
                                    <MemoTextBoxComponent
                                        id="form_text_user_id"
                                        name={'user_id'}
                                        value={formData.user_id}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.user_id}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.user_id
                                                )
                                            ]
                                        }
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        valOnChange
                                        disabled={!integrationPermission?.is_edit}
                                    />

                                </Grid>
                                <Grid item xs={5} >
                                    <MemoTextBoxComponent
                                        id="form_text_refresh_token"
                                        name={'refresh_token'}
                                        value={formData.refresh_token}
                                        fullWidth
                                        variant="standard"
                                        label={
                                            <span className="requiredLabel">
                                                {appConstants.labels.connector.refresh_token}
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        validators={['required']}
                                        errorMessages={
                                            [
                                                setRequiredErrorMessage(
                                                    appConstants.labels.connector.refresh_token
                                                )
                                            ]
                                        }
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        valOnChange
                                        disabled={!integrationPermission?.is_edit}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={5}>

                            <FormLabel component="legend">
                                {appConstants.labels.connector.export}
                            </FormLabel>
                            {
                                appConstants.alationExportProperties.map((item, index) => (
                                    <Accordion className={classes.accordionCheckBoxes} key={item.name}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <FormControlLabel
                                                label={item.lable}
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => onSwitchBoxCheckBoxChange('export', index, item.name, event.target.checked)}
                                                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                        checkedIcon={<CheckBoxOutlinedIcon />}
                                                        checked={formData.export[index][item.name]}
                                                        disabled={!integrationPermission?.is_edit}
                                                    />
                                                }
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container className={'detailInnerContainer'}>
                                                {
                                                    appConstants.alationExportOptions.map((option) => (
                                                        <Grid item xs={6} className="switchItems" key={option.name + '-' + item.name}>
                                                            <FormControlLabel
                                                                label={option.value}
                                                                control={
                                                                    <Switch
                                                                        onClick={(event) => onSwitchBoxChange('export', index, item.name, event.target.checked, option.name)}
                                                                        size="small"
                                                                        checked={formData.export[index].options[option.name]}
                                                                        disabled={!integrationPermission?.is_edit}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl>
                                <FormLabel component="legend">
                                    {appConstants.labels.connector.import}
                                </FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Domains"
                                        control={
                                            <Checkbox
                                                checked={!(formData?.import?.domains === 'false' || formData.import?.domains === false)}
                                                onChange={(event) => onCheckBoxChange('import', 'domains', event.target.checked)}
                                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                checkedIcon={<CheckBoxOutlinedIcon />}
                                                disabled={!integrationPermission?.is_edit}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Tags"
                                        control={
                                            <Checkbox
                                                checked={!(formData?.import?.tags === 'false' || formData.import?.tags === false)}
                                                onChange={(event) => onCheckBoxChange('import', 'tags', event.target.checked)}
                                                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                                checkedIcon={<CheckBoxOutlinedIcon />}
                                                disabled={!integrationPermission?.is_edit}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={`mt-3 ${classes.footerActions}`}>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToIntegrations()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                configuration.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        disabled={!checkChanges}
                                        isLoading={saveLoading}>
                                        {appConstants.labels.connector.save}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        disabled={!checkChanges}
                                        size={'small'}
                                        isLoading={saveLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Alation.defaultProps = {
    classes: {}
};

// prop types
Alation.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Alation);