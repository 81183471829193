import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Crypto } from '../../../helpers/index.js';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent } from '../../../components/index.js';
import SchemaSelector from '../components/schemaSelector/index.jsx';


//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

/*
 * import Images
 */
import BigqueryIcon from '../../../assets/img/connectors/bigquery.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';

// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState,
    getDBSchemasListRequest
} from '../../../redux/reducer/connectionReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';

function Bigquery(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const encryptDecrypt = new Crypto();
    const isAddMode = !connection_id;

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'bigquery',
        description: '',
        keyjson: null,
        location: '',
        schema: []
    });
    const {
        id,
        type,
        name,
        description,
        keyjson,
        location,
        schema
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, saveIsLoading, dbs_schema, schemaLoading } = useSelector((state) => state.connection);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getDBSchemasListRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);


    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            if (credentials) {
                const { location, schema, ...rest } = credentials;
                rest.keyjson = encryptDecrypt.decrypt(rest?.keyjson ?? {});
                const obj = {
                    id,
                    name,
                    type,
                    description,
                    keyjson: rest.keyjson,
                    location: location,
                    schema
                };
                if (obj.id) {
                    setFormData(obj);
                }
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                schema,
                location,
                keyjson: encryptDecrypt.encrypt(keyjson)
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(null);
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    /**
     * Federation File Upload
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const fileReader = new FileReader();
            fileReader.readAsText(event.target.files[0], "UTF-8");
            fileReader.onload = (json) => {
                setFormData((formData) => ({ ...formData, keyjson: json.target.result }));
            };
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Federation File Upload
     */
    const handleFileUpload = () => {
        document.getElementById(`uploadFile`).click();
    };

    /**
     * Handle Database Value Selection
     * @param {*} newValue
     */
    const handleSchemaSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, schema: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={BigqueryIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.bigquery.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.bigquery
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={8} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt0">
                        <Grid item xs={4}>
                            <MemoTextBoxComponent
                                id="form_text_location"
                                name={'location'}
                                value={location}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.location}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.location
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                inputProps={{ maxLength: 100 }}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <input
                                accept="application/JSON"
                                style={{ display: 'none' }}
                                id={`uploadFile`}
                                type="file"
                                onChange={handleSelectFile}
                            />
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleFileUpload}
                            >
                                {`Upload Key File`}
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        detailById?.is_valid &&
                        <Grid container spacing={5} className="mt0">
                            <SchemaSelector
                                dbs_schema={dbs_schema || []}
                                schema={schema || []}
                                handleSchemaSelection={handleSchemaSelection}
                                schemaLoading={schemaLoading}
                                detailById={detailById}
                                divSpan={4}
                            />
                        </Grid>
                    }
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Bigquery.defaultProps = {
    classes: {}
};

// prop types
Bigquery.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Bigquery);