import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions and Reducer
import {
    getAssetDetailRequest, getAssetDetailSuccess, getAssetDetailFailure, updateAssetRequest, updateAssetSuccess, updateAssetFailure,
    deleteAssetRequest, deleteAssetSuccess, deleteAssetFailure, getAssetTrendingChartRequest, getAssetTrendingChartSuccess, getAssetTrendingChartFailure,
    getAssetDimentionChartRequest, getAssetDimentionChartSuccess, getAssetDimentionChartFailure, getAllAssetsRequest, getAllAssetsSuccess,
    getAllAssetsFailure, updateAssetPropertyRequest, updateAssetPropertySuccess, updateAssetPropertyFailure, updateAssetReportRequest,
    updateAssetReportSuccess, updateAssetReportFailure, getAssetLineageRequest, getAssetLineageSuccess, getAssetLineageFailure,
    removeAssetReportRequest, removeAssetReportSuccess, removeAssetReportFailure, validateAssetReportMappingRequest,
    validateAssetReportMappingSuccess, validateAssetReportMappingFailure, getAssetsSearchableListRequest, getAssetsSearchableListSuccess,
    getAssetsSearchableListFailure, getAssetsAttributesRequest, getAssetsAttributesSuccess, getAssetsAttributesFailure
} from '../reducer/assetReducer';
import { navigate } from '../reducer/navigationReducer';
import { updateDQScore } from '../reducer/versionReducer';

// Import Reducers
import { displyAlert } from '../reducer/alertReducer';

// Import Service
import { assetService } from '../service';
import { assetDeleteSuccess, resetUpdatedAsset } from '../reducer/connectorReducer';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Get Asset Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getAssetDetailRequest),
    mergeMap(({ payload }) => {
        return from(assetService.detail(payload)).pipe(
            concatMap((res) => of(getAssetDetailSuccess({ ...res }))),
            catchError((error) => of(getAssetDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Asset
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateAssetRequest),
    mergeMap(({ payload: { id, type, connectionParams } }) => {
        return from(assetService.update(id, type)).pipe(
            concatMap((res) => {
                if (connectionParams && !connectionParams.hasNewAssets) {
                    return of(
                        updateAssetSuccess(res),
                        resetUpdatedAsset(connectionParams),
                        navigate({ path: 'catalog.root', state: {}, params: [] })
                    );
                }
                return of(updateAssetSuccess(res));
            }),
            catchError((error) => of(updateAssetFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Asset status
 * @param {*} action$
 * @returns
 */
const update_properties = (action$) => action$.pipe(
    ofType(updateAssetPropertyRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(assetService.update(id, rest)).pipe(
            concatMap((res) => {
                return of(updateAssetPropertySuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
            }),
            catchError((error) => of(updateAssetPropertyFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Delete Asset
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
    ofType(deleteAssetRequest),
    mergeMap(({ payload: { id, type, connection_id } }) => {
        return from(assetService.remove(id, type)).pipe(
            concatMap((res) => of(deleteAssetSuccess(res), assetDeleteSuccess({ id, connection_id }), navigate({ path: 'catalog.root', state: {}, params: [] }))),
            catchError((error) => of(deleteAssetFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Trending Chart
 * @param {*} action$
 * @returns
 */
const trending_chart = (action$) => action$.pipe(
    ofType(getAssetTrendingChartRequest),
    mergeMap(({ payload }) => {
        return from(assetService.trending_chart(payload)).pipe(
            concatMap((res) => of(getAssetTrendingChartSuccess({ ...res }))),
            catchError((error) => of(getAssetTrendingChartFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Dimension Chart
 * @param {*} action$
 * @returns
 */
const dimension_chart = (action$) => action$.pipe(
    ofType(getAssetDimentionChartRequest),
    mergeMap(({ payload }) => {
        return from(assetService.dimension_chart(payload)).pipe(
            concatMap((res) => of(getAssetDimentionChartSuccess({ ...res }))),
            catchError((error) => of(getAssetDimentionChartFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Asset List
 * @param {*} action$
 * @returns
 */
const getAllAssets = (action$) => action$.pipe(
    ofType(getAllAssetsRequest),
    mergeMap(() => {
        return from(assetService.getAllAssets()).pipe(
            concatMap((res) => of(getAllAssetsSuccess({ ...res }))),
            catchError((error) => of(getAllAssetsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Lineage for Asset
 * @param {*} action$
 * @returns
 */
const getLineage = (action$) => action$.pipe(
    ofType(getAssetLineageRequest),
    mergeMap(({ payload }) => {
        return from(assetService.getLineage(payload)).pipe(
            concatMap((res) => of(getAssetLineageSuccess({ ...res }))),
            catchError((error) => of(getAssetLineageFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Asset Report
 * @param {*} action$
 * @returns
 */
const mapReport = (action$) => action$.pipe(
    ofType(updateAssetReportRequest),
    mergeMap(({ payload }) => {
        return from(assetService.mapReport(payload)).pipe(
            concatMap((res) => of(updateAssetReportSuccess({ ...res }), getAssetLineageRequest({ asset_id: payload.asset }), updateDQScore(res))),
            catchError((error) => of(updateAssetReportFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Asset Report
 * @param {*} action$
 * @returns
 */
const removeReportMap = (action$) => action$.pipe(
    ofType(removeAssetReportRequest),
    mergeMap(({ payload }) => {
        return from(assetService.removeReportMap(payload.mapped_id)).pipe(
            concatMap((res) => of(removeAssetReportSuccess(payload), getAssetLineageRequest({ asset_id: payload.asset }), updateDQScore(res))),
            catchError((error) => of(removeAssetReportFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Validate Asset Report Mapping
 * @param {*} action$
 * @returns
 */
const validateAssetReportMapping = (action$) => action$.pipe(
    ofType(validateAssetReportMappingRequest),
    mergeMap(({ payload }) => {
        return from(assetService.validateAssetReportMapping(payload)).pipe(
            concatMap((res) => of(validateAssetReportMappingSuccess(res))),
            catchError((error) => of(validateAssetReportMappingFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Searchable Assets List
 * @param {*} action$
 * @returns
 */
const getSearchableAssetList = (action$) => action$.pipe(
    ofType(getAssetsSearchableListRequest),
    mergeMap(({ payload }) => {
        return from(assetService.getSearchableList(payload)).pipe(
            concatMap((res) => of(getAssetsSearchableListSuccess(res.data))),
            catchError(() => of(getAssetsSearchableListFailure()))
        );
    })
);

/**
 * Get All Assets and Attributes
 * @param {*} action$
 * @returns
 */
const getAllAssetsAndAttributes = (action$) => action$.pipe(
    ofType(getAssetsAttributesRequest),
    mergeMap(() => {
        return from(assetService.getAllAssetsAndAttributes()).pipe(
            concatMap((res) => of(getAssetsAttributesSuccess({ ...res }))),
            catchError((error) => of(getAssetsAttributesFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

// Export All Epic Functions
export const assetEpic = [
    detail, update, remove, trending_chart, dimension_chart, update_properties, getAllAssets, getLineage, mapReport,
    removeReportMap, validateAssetReportMapping, getSearchableAssetList, getAllAssetsAndAttributes
];