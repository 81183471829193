import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';

// Import Styles
import style from "./style.jsx";

const IntegerInputComponent = (props) => {

	/**
	 * Define Props
	 */
	const { noOutline, classes, value, onChange, valOnChange, ...rest } = props;

	/**
	 * Define States
	 */
	const [inputValue, setInputValue] = useState(value);

	/**
	 * Set Updated Value
	 */
	useEffect(() => {
		setInputValue(value);
	}, [value]);

	/**
	 * Define on change event
	 * @param {*} event
	 */
	const handleChange = (event) => {
		const regex = new RegExp('^([-]{1})?[0-9]*$');
		if (event.target.value === '' || regex.test(event.target.value)) {
			setInputValue(event.target.value);
			if (valOnChange) {
				onChange(event);
			}
		}
	};

	/**
	 * Define on change event using with debounce
	 */
	const handleOnBlur = (event) => {
		if (event.target.value !== value) {
			onChange(event);
		}
	};

	return (
		<Grid className={noOutline ? classes.outLineNone : null}>
			<TextValidator
				className={noOutline ? classes.outLineNone : null}
				autoComplete={"off"}
				onChange={(event) => handleChange(event)}
				value={inputValue}
				{...rest}
				onBlur={(event) => handleOnBlur(event)}
			/>
		</Grid>
	);
};

/**
 * Define Props
 */
IntegerInputComponent.propTypes = {
	onChange: PropTypes.func,
	classes: PropTypes.object,
	noOutline: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	valOnChange: PropTypes.bool
};

/**
 * Set Default Values
 */
IntegerInputComponent.defaultProps = {
	onChange: () => { },
	classes: {},
	noOutline: false,
	value: null,
	valOnChange: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(IntegerInputComponent);


/**
 * Export As Memo Component
 */
export const MemoIntegerInputComponent = withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(IntegerInputComponent, areEqual));