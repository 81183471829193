import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getLicenseRequest, getLicenseSuccess, getLicenseFailure, createLicenseRequest, createLicenseSuccess,
    createLicenseFailure, syncLicenseRequest, syncLicenseSuccess, syncLicenseFailure
} from '../reducer/licenseReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';
import { updateLicense } from '../reducer/authReducer';

// Import Services
import { licenseService } from '../service';


// Import Constants
import appConstants from "../../constants/appConstants";


/**
 * Get License
 * @returns
 */
const getLicense = (action$) => action$.pipe(
    ofType(getLicenseRequest),
    mergeMap(() => {
        return from(licenseService.get()).pipe(
            concatMap((res) => { return of(getLicenseSuccess(res)); }),
            catchError((error) => { return of(getLicenseFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create License
 * @param {*} action$
 * @returns
 */
const createLicense = (action$) => action$.pipe(
    ofType(createLicenseRequest),
    mergeMap(({ payload }) => {
        return from(licenseService.create(payload)).pipe(
            concatMap((res) => {
                if (payload.activation) {
                    return of(createLicenseSuccess(), updateLicense(), navigate({ path: 'home.root', state: {} }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.license }));
                }
                return of(createLicenseSuccess(res), getLicenseRequest(), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.license }));
            }),
            catchError((error) => { return of(createLicenseFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get License
 * @returns
 */
const syncLicenseConfig = (action$) => action$.pipe(
    ofType(syncLicenseRequest),
    mergeMap(() => {
        return from(licenseService.sync()).pipe(
            concatMap((res) => { return of(syncLicenseSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.syncLicense })); }),
            catchError((error) => { return of(syncLicenseFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


// Export All License Functions
export const licenseEpic = [getLicense, createLicense, syncLicenseConfig];