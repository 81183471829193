const style = (theme) => ({
    chips: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-deletable': {
            '& .acceptIcon': {
                visibility: 'hidden',
                opacity: 0,
                transition: 'all 0.5s'
            },
            '&:hover': {
                '& .acceptIcon': {
                    visibility: 'visible',
                    opacity: 1,
                    transition: 'all 0.5s'
                }
            }
        },
        '& .MuiAutocomplete-root': {
            maxWidth: 300,
            minWidth: 150
        },
        '&>:not(style)+:not(style)': {
            marginLeft: '0px !important'
        },
        '& .MuiChip-root': {
            height: 26,
            position: 'relative',
            maxWidth: 180,
            borderRadius: 4,
            fontSize: 14,
            marginRight: '8px',
            '&:hover': {
                '& svg.MuiChip-deleteIcon': {
                    visibility: 'visible',
                    opacity: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        fill: theme.palette.secondary.main
                    }
                }
            },
            '&:active': {
                boxShadow: 'none'
            },
            '& svg.MuiChip-deleteIcon': {
                position: 'absolute',
                right: '-10px',
                top: '-8px',
                background: '#fff',
                borderRadius: '30px',
                boxShadow: '0px 0px 5px #00000026',
                width: '15px',
                padding: '1px',
                height: '15px',
                visibility: 'hidden',
                opacity: 0,
                transition: '0.5s all'
            },
            '&.sm-r': {
                height: '24px !important'
            },
            '&.sm': {
                height: 24,
                borderRadius: 4
            },
            '&.success': {
                color: theme.palette.componentColors.success,
                background: `${theme.palette.componentColors.success}25`
            },
            '&.success-txt': {
                /*
                 * color: theme.palette.colors.success,
                 * background: theme.palette.primary.contrastText
                 */
                background: '#54DF9A',
                color: '#ffffff'
            },
            '&.danger': {
                color: theme.palette.componentColors.danger,
                background: `${theme.palette.componentColors.danger}25 !important`
            },
            '&.dangerRed': {
                color: theme.palette.componentColors.shade1,
                background: `${theme.palette.componentColors.shade1}25 !important`
            },
            '&.warning': {
                color: theme.palette.componentColors.warning,
                background: `${theme.palette.componentColors.warning}25 !important`
            },
            '&.info': {
                color: theme.palette.componentColors.info,
                background: `${theme.palette.componentColors.info}25 !important`
            },
            '&.orange': {
                color: theme.palette.componentColors.shade2,
                background: `${theme.palette.componentColors.shade2}25 !important`
            },
            '&.grey': {
                color: `${theme.palette.componentColors.default} !important`,
                background: `${theme.palette.componentColors.default}30 !important`
            },
            '&.secondary': {
                background: `${theme.palette.componentColors.secondary}25 !important`,
                border: `1px solid ${theme.palette.componentColors.secondary} !important`
            },
            '&.secondaryDark': {
                border: `1px solid ${theme.palette.componentColors.secondary} !important`,
                backgroundColor: `${theme.palette.componentColors.secondary}50 !important`
            },
            '&.default': {
                background: theme.palette.greyshades.lightestgrey,
                border: `1px solid ${theme.palette.greyshades.lightgrey} !important`
            },
            '&.yellow': {
                background: `${theme.palette.componentColors.shade3}25 !important`,
                border: `1px solid ${theme.palette.componentColors.shade3} !important`
            },
            '&.MuiChip-colorDefault': {
                background: `${theme.palette.greyshades.lightgrey}`
                // border: `1px solid ${theme.palette.greyshades.darkgrey}30`
            },
            '& .dataType': {
                paddingRight: 5,
                color: theme.palette.secondary.main,
                fontWeight: 600
            }
        },
        '&.domainChips .MuiChip-root': {
            backgroundColor: '#E0EEFC !important',
            color: '#64AAEF !important'
        },
        '&.applicationChip .MuiChip-root': {
            backgroundColor: '#FFAF9729 !important',
            color: '#FFAF97 !important'
        },
        '&.termsChips .MuiChip-root': {
            backgroundColor: '#FEECF2 !important',
            color: '#DE628E !important'
        },
        '&.applicationChip_close .MuiChip-deleteIcon': {
            display: 'none',
            pointerEvents: 'none'
        },
        '&.default .MuiChip-root': {
            backgroundColor: '#F2F6F7 !important',
            border: `1px solid #E4E8E9`
        },
        '& .MuiChip-deleteIcon': {
            fill: theme.palette.greyshades.darkgrey,
            // width: 17,
            height: 17,
            '& .MuiIconButton-root': {
                width: 17
            }
        },
        '& .PlusIcon': {
            '&:hover ': {
                fill: theme.palette.secondary.main
            }
        },
        '& .addContainer:hover': {
            '& .PlusIcon': {
                fill: theme.palette.secondary.main
            },
            '& .MuiTypography-root': {
                color: `${theme.palette.secondary.main} !important`
            }
        }
    },
    outLineNone: {
        marginBottom: 5,

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderBottom: `1px solid #E5E7EB`,
            borderRadius: 0,

            '&:hover': {
                borderBottom: `2px solid #E5E7EB !important`
            },

            '& input': {
                backgroundColor: 'transparent'
            }
        }
    },
    textSecondary: {
        color: `${theme.palette.greyshades.darkgrey} !important`,
        whiteSpace: "nowrap"
    },
    listItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '50px'
    },
    moreCount: {

    }
});
export default style;