const style = (theme) => ({
    prevContainer: {
        padding: 30,
        paddingTop: '0px !important',
        '& .header': {
            position: 'sticky',
            top: 0,
            paddingBottom: 16,
            background: '#fff',
            zIndex: 99,
            paddingTop: 30,
            '& .CloseIcon': {
                width: '24px',
                height: '24px',
                '&:hover path': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    previmageConatiner: {
        width: '100%',
        height: '100%',
        textAlign: 'center'
    },
    prevImage: {
        objectFit: 'contain',
        // width: '100%',
        height: '100%',
        // maxHeight: 'calc(100vh - 200px)',
        maxWidth: '100%'
    },
    dialogContainer: {
        '& .MuiPaper-root': {
            maxWidth: 'calc(100% - 64px)'
        }
    }
});
export default style;