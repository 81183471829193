// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get issues
 * @returns
 */
async function get(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.issues.getIssues}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Create Issues
 * @param {*} params
 * @returns
 */
async function create(params) {
    const response = await postRequest(`${urlConstants.issues.url}`, params);
    return response;
}

/**
 * update Issues
 * @param {*} issueId
 * @param {*} params
 * @returns
 */
async function update(issueId, params) {
    const response = await putRequest(`${urlConstants.issues.url}${issueId}/`, params);
    return response;
}

/**
 * Delete Issues
 * @param {*} issueId
 * @returns
 */
async function remove(id, purge) {
    const response = await deleteRequest(`${urlConstants.issues.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Get Issue Detail
 * @param {*} issueId
 * @returns
 */
async function detail(issueId) {
    const response = await getRequest(`${urlConstants.issues.url}${issueId}/`);
    return response;
}

/**
 * Get Issue WorkLog
 * @param {*} issueId
 * @returns
 */
async function getWorkLog(issueId) {
    const response = await getRequest(`${urlConstants.issues.workLog.url}?issue=${issueId}`);
    return response;
}

/**
 * Get Comment
 * @param {*} issueId
 * @returns
 */
async function getComment(issueId) {
    const response = await getRequest(`${urlConstants.issues.comments.url}?issue=${issueId}`);
    return response;
}

/**
 * Create Comment
 * @param {*} params
 * @returns
 */
async function createComment(params) {
    const response = await postRequest(`${urlConstants.issues.comments.url}`, params);
    return response;
}


/**
 * Update Comment
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function updateComment(id, params) {
    const response = await putRequest(`${urlConstants.issues.comments.url}${id}/`, params);
    return response;
}

/**
 * Delete Comment
 * @param {*} id
 * @returns
 */
async function deleteComment(id) {
    const response = await deleteRequest(`${urlConstants.issues.comments.url}${id}/`);
    return response;
}

/**
 * Delete Comment
 * @param {*} id
 * @returns
 */
async function createAttachment(params) {
    const formData = new FormData();
    Object.keys(params).map((param) =>
        formData.append(param, params[param])
    );
    const response = await postRequest(`${urlConstants.issues.attachment.url}`, formData, true);
    return response;
}

/**
 * Get Issue Attachment
 * @param {*} issueId
 * @returns
 */
async function getAttachment(issueId) {
    const response = await getRequest(`${urlConstants.issues.attachment.url}?issue=${issueId}`);
    return response;
}

/**
 * Get Statistics
 * @returns
 */
async function getStatistics(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.issues.statistics}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Share
 */
async function share(params) {
    const form_data = new FormData();
    Object.keys(params).map((param) =>
        form_data.append(param, params[param])
    );
    const response = await postRequest(urlConstants.notifications.url, form_data, true);
    return response;
}


/**
 * Watch
 */
async function watch(params) {
    const response = await postRequest(`${urlConstants.issues.watch.url}`, params);
    return response;
}

/**
 * Delete Attachment
 * @param {*} attachmentId
 * @returns
 */
async function removeAttachment(attachmentId) {
    const response = await deleteRequest(`${urlConstants.issues.attachment.url}${attachmentId}/`);
    return response;
}


/**
 * Export
 */
async function exportIssues(params) {
    const fileName = params.fileName || "issues.csv";
    const response = await postRequest(urlConstants.issues.export, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Export All Service Functions
 */
export const issuesService = {
    get, create, update, remove, detail, getWorkLog, getComment, createComment, updateComment, deleteComment, createAttachment, getAttachment,
    getStatistics, share, watch, removeAttachment, exportIssues
};