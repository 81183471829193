const style = (theme) => ({
    connectionCard: {
        padding: '18px 20px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        textAlign: 'center',
        background: `${theme.palette.secondary.main}17`,
        boxShadow: '0px 0px 10px #0000000D',
        height: '252.19px',
        cursor: 'pointer',
        '&:hover': {
            outline: `2px solid ${theme.palette.secondary.main}`
        },
        '& .connectorCardImg': {
            marginBottom: 5,
            height: 40,
            maxWidth: '100%',
            objectFit: 'contain',
            marginTop: '0px'
        },
        '&.deactive': {
            '&:hover': {
                outline: `none !important`
            },
            cursor: 'default !important',
            background: '#f5f5f5',
            '& .connectionCardHeader': {
                filter: 'grayscale(1)'
            },
            '& .cardImg, .connectorCardImg': {
                filter: 'grayscale(1)'
            },
            '& .MuiTypography-root': {
                color: `${theme.palette.greyshades.darkgrey} !important`
            }
        },
        '& .CopyIcon': {
            width: 20,
            height: 20
        },
        '& .connectorTileDesc ': {
            height: 106
        },
        '& .failedTextContainer': {
            height: 90
        },
        '& .connectorsTitle, .connectorsTitledesc': {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
        },
        '& .cardFooter': {
            marginTop: 14
        }
    },
    configureCardBrief: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        height: 45
    },
    cardImg: {
        fontSize: '34px !important',
        marginBottom: '8px',
        marginTop: '-18px',
        fill: `${theme.palette.secondary.main} !important`
    },
    failedText: {
        color: '#F38080 !important',
        background: '#FEF5F5',
        border: '1px solid',
        borderRadius: '4px',
        padding: '8px',
        marginTop: '20px !important',
        marginBottom: '10px !important',
        '& span': {
            textDecoration: 'underline'
        }
    },
    action: {
        marginRight: '-10px',
        '& .MuiIconButton-root': {
            padding: 7
        },
        '& svg': {
            width: '19px !important',
            height: '19px !important',
            '&.EditIcon': {
                width: '18px !important',
                height: '18px !important',
                '& path': {
                    fill: theme.palette.greyshades.darkgrey
                },
                '&:hover path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .CopyIcon:hover #Path_2746': {
            fill: theme.palette.secondary.main
        },
        '& .DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    imgContainer: {
        height: 48,
        '&.teradata': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            '& img': {
                height: "auto !important",
                width: "auto !important",
                maxHeight: "25px !important"
            }
        }
    }
});
export default style;