// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Schedule
 */
async function getSchedule(params) {
    const response = await postRequest(`${urlConstants.schedule.getSchedule}`, params);
    return response;
}

/**
 * Get Schedule
 */
async function create(params) {
    const response = await postRequest(`${urlConstants.schedule.url}`, params);
    return response;
}

/**
 * Update Schedule
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.schedule.url}${id}/`, params);
    return response;
}

/**
 * Remove Schedule
 */
async function remove(id) {
    const response = await deleteRequest(`${urlConstants.schedule.url}${id}/`);
    return response;
}

/**
 * Trigger Schedule
 */
async function trigger(payload) {
    const response = await postRequest(`${urlConstants.schedule.triggerSchedule}`, payload);
    return response;
}

/**
 * Kill Job
 */
async function killJob(payload) {
    const response = await postRequest(`${urlConstants.schedule.killJob}`, payload);
    return response;
}

/**
 * Get Target Asset
 */
async function getTargetAsset() {
    const response = await getRequest(`${urlConstants.schedule.targetAsset}`);
    return response;
}

/**
 * Get Target Measure
 */
async function getTargetMeasure() {
    const response = await getRequest(`${urlConstants.schedule.targetMeasure}`);
    return response;
}

/**
 * Get Asset Schedule List
 */
async function getScheduleList(params) {
    const response = await postRequest(`${urlConstants.schedule.scheduleList}`, params);
    return response;
}

/**
 * Get Asset Job List
 */
async function getAssetJobList(params) {
    const response = await postRequest(`${urlConstants.schedule.asstJobList}`, params);
    return response;
}


/**
 * Get Target Asset
 */
async function getRunDetails(params) {
    const response = await getRequest(`${urlConstants.schedule.runDetails}${params}/`);
    return response;
}

/**
 * Get Measure Schedule List
 */
async function getMeasureScheduleList(params) {
    const response = await postRequest(`${urlConstants.schedule.measureScheduleList}`, params);
    return response;
}

/**
 * Get Report Schedule List
 */
async function getReportScheduleList(params) {
    const response = await postRequest(`${urlConstants.schedule.reportScheduleList}`, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const scheduleService = {
    create, update, remove, getSchedule, trigger, killJob, getTargetAsset, getScheduleList, getAssetJobList, getRunDetails, getMeasureScheduleList, getReportScheduleList, getTargetMeasure
};