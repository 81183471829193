const style = (theme) => ({
    PreviewContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '0px 30px 20px',
        height: 'calc(100vh - 0px)',
        overflowY: 'auto',
        '& .MuiBox-root': {
            padding: '0px'
        },
        '& .header': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            paddingTop: 30,
            zIndex: 99,
            '& .CloseIcon': {
                width: '22px',
                height: '22px',
                '&:hover': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    tabsSection: {
        '& .btnTabsInline': {
            width: '100%',
            height: '43px'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none !important'
        },
        '& textarea': {
            minHeight: '200px',
            resize: 'vertical',
            padding: '10px',
            '&:hover': {
                background: theme.palette.greyshades.lightestgrey,
                transistion: 'all 0.5s'
            }
        },
        '& .textBoxContainer .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none !important'
        },
        '& .MuiOutlinedInput-root': {
            // padding: '0px !important'
        },
        '& .MuiInput-underline:before': {
            borderColor: 'transparent !important'
        }
    },
    tabsBody: {
        height: 'calc(100vh - 142px)',
        overflowY: 'auto',
        background: theme.palette.greyshades.lightestgrey,
        padding: '15px',
        '& .codeContainer': {
            position: 'relative',
            '& .copyBtn': {
                position: 'absolute',
                right: '0px',
                top: '0px'
            }
        }
    }
});
export default style;