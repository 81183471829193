// Default Imports
import React from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

//Import Styles
import style from './style.jsx';

const RangeSliderComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, value, handleSliderChange, marks, maxValue, disabled } = props;

    /**
     * Handle On Change Event
     * @param {*} event
     */
    const handleOnChange = (event) => {
        handleSliderChange(event.target.value);
    };

    return (
        <Grid container spacing={2} alignItems="center" className={`${classes.rangeSlider} rangeSlider`} wrap="nowrap">
            <Grid item>
                <Slider
                    color="secondary"
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleOnChange}
                    aria-labelledby="input-slider"
                    marks={marks}
                    max={maxValue}
                    disabled={disabled}
                />
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    {`${value} %`}
                </Typography>
            </Grid>
        </Grid>
    );
};

// default props
RangeSliderComponent.defaultProps = {
    classes: {},
    value: 0,
    handleSliderChange: () => { },
    marks: false,
    maxValue: 100,
    disabled: false

};

RangeSliderComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.number,
    handleSliderChange: PropTypes.func,
    marks: PropTypes.bool,
    maxValue: PropTypes.number,
    disabled: PropTypes.bool

};
export default withStyles((theme) => ({
    ...style(theme)
}))(RangeSliderComponent);