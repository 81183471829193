import { createSlice } from '@reduxjs/toolkit';

// Import App Constants
import appConstants from '../../constants/appConstants.js';

// Import Helper
import { setCookie, removeCookie, getUser } from '../../helpers/appHelpers.js';

// Get Logged In User Details
const loggedInUser = getUser();

const initialState = {
    isLoading: false,
    isLoggedIn: Boolean(loggedInUser),
    user: loggedInUser || { license: { is_active: true, status: "Active" } },
    permission: loggedInUser?.roles || []
};

const authSlice = createSlice({
    name: 'auth',
    initialState: { ...initialState },
    reducers: {
        loginRequest(state) {
            state.isLoading = true;
            state.isLoggedIn = false;
        },
        loginSuccess(state, action) {
            const token = action.payload?.data?.access ?? null;

            if (token) {
                const user = action.payload.data && action.payload.data.user ? action.payload.data.user : {};
                state.user = user;
                state.permission = user?.roles ?? [];
                setCookie('token', token);
                localStorage.setItem(appConstants.localStorageKey, JSON.stringify(user));
                state.isLoggedIn = true;
            }

            state.isLoading = false;
        },
        loginFailure(state) {
            removeCookie('token');
            localStorage.removeItem(appConstants.localStorageKey);
            state.isLoggedIn = false;
            state.isLoading = false;
        },
        ssoValidateRequest(state) {
            state.isLoading = true;
        },
        ssoValidateSuccess(state, action) {
            const user = action.payload?.data;
            state.user = user;
            state.permission = user?.roles ?? [];
            localStorage.setItem(appConstants.localStorageKey, JSON.stringify(user));
            state.isLoggedIn = true;
            state.isLoading = false;
        },
        ssoValidateFailure(state) {
            localStorage.removeItem(appConstants.localStorageKey);
            removeCookie('token');
            state.isLoggedIn = false;
            state.isLoading = false;
        },
        updateAuthUserDetails(state, action) {
            const userData = action.payload?.data ?? {};
            if (state.user?.id === userData?.id) {
                state.user = {
                    ...state.user,
                    ...userData,
                    name: `${userData.first_name || ""} ${userData.last_name || ""}`
                };
            }
        },
        logoutRequest() { },
        logoutSuccess(state) {
            localStorage.removeItem(appConstants.localStorageKey);
            removeCookie('token');
            state.isLoggedIn = false;
            state.user = {};
            state.isLoading = false;
        },
        logoutFailure() { },
        forgotPasswordRequest(state) {
            state.isLoading = true;
        },
        forgotPasswordSuccess(state) {
            state.isLoading = false;
        },
        forgotPasswordFailure(state) {
            state.isLoading = false;
        },
        resetPasswordRequest(state) {
            state.isLoading = true;
        },
        resetPasswordSuccess(state) {
            state.isLoading = false;
        },
        resetPasswordFailure(state) {
            state.isLoading = false;
        },
        changePasswordRequest(state) {
            state.isLoading = true;
        },
        changePasswordSuccess(state) {
            state.isLoading = false;
        },
        changePasswordFailure(state) {
            state.isLoading = false;
        },
        getUserPermissionRequest(state) {
            state.isLoading = true;
        },
        getUserPermissionSuccess(state, action) {
            state.permission = action.payload.data;
            state.user.roles = action.payload.data || state.permission;
            localStorage.setItem(appConstants.localStorageKey, JSON.stringify(state.user));
            state.isLoading = false;
        },
        getUserPermissionFailure(state) {
            state.isLoading = false;
        },
        updateUserPreference(state, action) {
            state.user = { ...state.user, user_preference: { ...action.payload } };
        },
        updateLicense(state) {
            state.user = {
                ...state.user,
                license: { is_active: true, status: "Active" }
            };
            localStorage.setItem(appConstants.localStorageKey, JSON.stringify(state.user));
        }
    }
});

export const { loginRequest, loginSuccess, loginFailure, logoutRequest, logoutSuccess, logoutFailure, forgotPasswordRequest, forgotPasswordSuccess,
    forgotPasswordFailure, resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, getUserPermissionRequest, getUserPermissionSuccess,
    getUserPermissionFailure, changePasswordRequest, changePasswordSuccess, changePasswordFailure, updateAuthUserDetails, ssoValidateRequest,
    ssoValidateSuccess, ssoValidateFailure, updateUserPreference, updateLicense
} = authSlice.actions;
export default authSlice.reducer;