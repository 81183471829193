import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Rating, Typography } from '@mui/material';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { ContinuousSliderComponent, AutoCompleteComponent } from '../../../components';
import FilterItems from './filters.jsx';

//  Import Styles
import CatalogStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import { ChevLeftIcon, AscendingIcon, DescendingIcon } from '../../../assets/svg';

// Import Reducers
import { getCatalogConnectionsListRequest } from '../../../redux/reducer/connectionReducer';
import { getApplicationsFilterRequest } from '../../../redux/reducer/applicationReducer';
import { getDomainListFilterRequest } from '../../../redux/reducer/semanticReducer.js';
import { getTagsFilterRequest } from '../../../redux/reducer/tagsReducer';
import { getMappedTermsListFilterRequest } from '../../../redux/reducer/termReducer';

// Import Constants
import appConstants from '../../../constants/appConstants.js';
import { orderList } from '../../../helpers';

function FilterBar(props) {

    /**
     * Define Props
     */
    const { classes, collapseChange, collapseState, onFilterChange, onSortingChange, filters } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [customAccordionSelected, setCustomAccordionSelected] = useState(null);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { searchableApplicationsListFilter } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossariesFilter } = useSelector((state) => state.semantic, shallowEqual);
    const { catalogConnectionsList } = useSelector((state) => state.connection, shallowEqual);
    const { searchableTagsFilterList } = useSelector((state) => state.tags, shallowEqual);
    const { mappedTermsListFilter } = useSelector((state) => state.term, shallowEqual);

    /**
     * Get Applications and Glossary List
     */
    useEffect(() => {
        if (!searchableApplicationsListFilter || searchableApplicationsListFilter.length === 0) {
            dispatch(getApplicationsFilterRequest());
        }
        if (!searchableGlossariesFilter || searchableGlossariesFilter.length === 0) {
            dispatch(getDomainListFilterRequest());
        }
        if (!searchableTagsFilterList || searchableTagsFilterList.length === 0) {
            dispatch(getTagsFilterRequest());
        }
        if (!mappedTermsListFilter || mappedTermsListFilter.length === 0) {
            dispatch(getMappedTermsListFilterRequest());
        }
        if (!catalogConnectionsList || catalogConnectionsList.length === 0) {
            dispatch(getCatalogConnectionsListRequest());
        }
    }, [dispatch]);

    /**
     * Handle Opposite Values
     * @param {*} value
     */
    const getOppositeValue = (value) => {
        const withoutPrefix = 'without_';
        return value && value.startsWith(withoutPrefix) ? value.substring(withoutPrefix.length) : withoutPrefix + value;
    };

    /**
     * Handle CheckBox Changes
     * @param {*} type
     * @param {*} item
     */
    const handleCheckBoxClick = (type, item, key = 'id') => {
        let copyData = [...filters[type]];
        const fValue = key === 'name' ? item.name : item.id;
        if (_.findIndex(copyData, (value) => value === fValue) === -1) {
            copyData = [...copyData, fValue !== null ? fValue : "_show_blank_select_"];
        } else {
            copyData.splice(_.findIndex(copyData, (value) => value === fValue), 1);
        }
        const oppositeValue = getOppositeValue(fValue);
        const indexOfOppositeValue = _.findIndex(copyData, (value) => value === oppositeValue);

        if (indexOfOppositeValue !== -1) {
            copyData.splice(indexOfOppositeValue, 1);
        }

        onFilterChange(type, copyData);
    };

    /**
     * Handle Sortby Event
     * @param {*} type
     * @param {*} c_data
     */
    const handleSortByEvent = (type, value) => {
        onSortingChange(type, value);
    };

    /**
     * Control Custom Accordion
     * @param {*} selectedAccordion
     */
    const handleCustomChange = (selectedAccordion) => {
        const panel = customAccordionSelected !== selectedAccordion ? selectedAccordion : null;
        setCustomAccordionSelected(panel);
    };

    /**
     * Filter Tags Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        let tags = JSON.parse(JSON.stringify(listData));
        tags = tags.filter((item) => item.count && item.count > 0);
        tags = orderList(tags, 'name', 'asc');
        return tags;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsFilterList), [searchableTagsFilterList]);

    /**
     * Filter Applications Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = applications.filter((item) => item.count && item.count > 0);
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsListFilter), [searchableApplicationsListFilter]);


    /**
     * Filter Domains Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = domains.filter((item) => item.count && item.count > 0);
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossariesFilter), [searchableGlossariesFilter]);


    /**
     * Filter Terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        let terms = JSON.parse(JSON.stringify(listData));
        terms = terms.filter((item) => item.count && item.count > 0);
        terms = orderList(terms, 'name', 'asc');
        return terms;
    };
    const termsList = useMemo(() => filterTerms(mappedTermsListFilter), [mappedTermsListFilter]);


    /**
     * Filter Connections
     * @param {*} listData
     * @returns
     */
    const filterConnections = (listData) => {
        let connections = JSON.parse(JSON.stringify(listData));
        connections = connections.filter((item) => item.count && item.count > 0);
        connections = orderList(connections, 'name', 'asc');
        return connections;
    };
    const connectionsList = useMemo(() => filterConnections(catalogConnectionsList), [catalogConnectionsList]);


    return (
        <Grid className={`${classes.sidebarContainer} ${!collapseState && 'collapsedSidebar'}`}>
            <Grid className={`${classes.iconBg} ${!collapseState && 'collapsed'}`} onClick={collapseChange}>
                <ChevLeftIcon />
            </Grid>
            <Grid className={`${classes.sideBarInner} ${!collapseState && 'collapsedSidebar'}`}>
                <Grid>
                    <FilterItems header={'Sort By'} default_open>
                        <Grid container wrap="nowrap" alignItems={"center"} spacing={1}>
                            <Grid item className={`${classes.filterText} w-100 pl-0`}>
                                <ValidatorForm onSubmit={() => { }} noValidate>
                                    <AutoCompleteComponent
                                        name="sortBy"
                                        selectedValue={appConstants.catalog_sort_by.filter((a) => a.id === filters?.sortBy ?? 'created_date')[0]?.name ?? 'Created Date'}
                                        data={appConstants.catalog_sort_by}
                                        size={'small'}
                                        variant="outlined"
                                        onChange={
                                            (event, newValue) =>
                                                handleSortByEvent('sortBy', newValue?.id ?? 'created_date')
                                        }
                                        placeholder="Select Sort By"
                                    />
                                </ValidatorForm>
                            </Grid>
                            <Grid item className="dflex sortIcons">
                                <Tooltip
                                    arrow
                                    title={filters.orderBy === 'asc' ? "Ascending" : "Descending"}
                                >
                                    <IconButton onClick={() => handleSortByEvent('orderBy', filters.orderBy === 'asc' ? 'desc' : 'asc')} className={'active p5'}>
                                        {filters.orderBy === 'asc' ? <AscendingIcon /> : <DescendingIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </FilterItems>
                </Grid>

                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'filter_by'}
                    default_open
                    header={'Filter By'}
                    options={appConstants.filter_by}
                    data={filters?.filter_by ?? []}
                    field_key={'id'}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'id')}
                />

                <FilterItems header={'Ratings'} default_open>
                    <Grid className="dflex alignCenter">
                        <Grid item>
                            <Rating
                                className={classes.starIcon}
                                name="rating"
                                value={filters.rating || 0}
                                precision={0.5}
                                size="small"
                                onChange={
                                    (event, newValue) => {
                                        onFilterChange('rating', newValue);
                                    }
                                } />
                        </Grid>
                        <Typography
                            variant="body1"
                            className="fw-500 pl5"
                        >
                            {filters.rating || ''}
                        </Typography>
                    </Grid>
                </FilterItems>
                <FilterItems header={'DQ Score'} default_open>
                    <ContinuousSliderComponent
                        value={filters.score || [0, 100]}
                        onSliderValueChange={(value) => onFilterChange('score', value)}
                    />
                </FilterItems>
                {
                    applicationsList && applicationsList.length > 0 &&
                    <FilterItems
                        classes={classes}
                        checkboxList
                        type={'applications'}
                        header={'Application'}
                        options={applicationsList}
                        data={filters?.applications ?? []}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />
                }
                {
                    connectionsList && connectionsList.length > 0 &&
                    <FilterItems
                        classes={classes}
                        checkboxList
                        type={'connection_type'}
                        header={'Connections'}
                        options={connectionsList}
                        data={filters?.connection_type ?? []}
                        field_key={'name'}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'name')}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />
                }
                {
                    domainsList && domainsList.length > 0 &&
                    <FilterItems
                        classes={classes}
                        checkboxList
                        type={'domains'}
                        header={'Domains'}
                        options={domainsList}
                        data={filters?.domains ?? []}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />
                }
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'asset_status'}
                    header={'Status'}
                    options={appConstants.assetStatusFilters}
                    data={filters?.asset_status ?? []}
                    field_key={'name'}
                    default_open={filters?.asset_status?.length > 0}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'name')}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
                {
                    tagsList && tagsList.length > 0 &&
                    <FilterItems
                        classes={classes}
                        checkboxList
                        type={'tags'}
                        header={'Tags'}
                        options={tagsList}
                        data={filters?.tags ?? []}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />
                }
                {
                    termsList && termsList.length > 0 &&
                    <FilterItems
                        classes={classes}
                        checkboxList
                        type={'terms'}
                        header={'Terms'}
                        options={termsList}
                        data={filters?.terms ?? []}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />
                }
            </Grid>
        </Grid>
    );
}

// default props
FilterBar.defaultProps = {
    classes: {},
    collapseChange: () => { },
    collapseState: true,
    filters: {},
    tabIndex: 0,
    onFilterChange: () => { },
    onSortingChange: () => { },
    disableFilterButton: true
};

// prop types
FilterBar.propTypes = {
    classes: PropTypes.object,
    collapseChange: PropTypes.func,
    collapseState: PropTypes.bool,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
    onSortingChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...CatalogStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FilterBar);