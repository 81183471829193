import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import { Button, Grid, Link, Typography, CircularProgress } from '@mui/material';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import SlackIcon from '../../../assets/img/apps/slack.png';
import { AutoCompleteComponent, LoaderButtonComponent } from '../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import appConstants from '../../../constants/appConstants.js';

import { getConfigurationRequest, updateConfiguration, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer';

import { AppIntegration, checkPermission, orderList } from '../../../helpers/index';
import featureConstants from '../../../constants/featureConstants.js';

function Slack(props) {
    /** Define Props */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const navigate = useNavigate();

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * Define State
     */
    const [channels, setChannels] = useState([]);
    const [channelsLoading, setChannelsLoading] = useState(false);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        dispatch(updateConfiguration({
            [property]: value
        }));
    };

    /**
     * On Save
     */
    const onSave = () => {
        const requestParams = {
            config: configuration,
            id: channelId,
            channelName: "slack"
        };
        dispatch(updateConfigurationRequest(requestParams));
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        dispatch(getConfigurationRequest(channelId));
    }, []);

    /**
     * Get Channels
     */
    useEffect(() => {
        if (configuration.access_token && !channels.length) {
            const integration = new AppIntegration('slack');
            setChannelsLoading(true);
            integration.getChannels(configuration.access_token)
                .then((data) => {
                    const channelList = orderList(data.result, 'name', 'asc');
                    setChannels([...channelList]);
                    setChannelsLoading(false);
                });
        }
    }, [configuration]);

    /**
     * Get Channel Value
     * @param {*} list
     * @param {*} value
     * @returns
     */
    const getValue = (list, value) => {
        const data = list.find((obj) => obj.id === value);
        return data ? data.name : "";
    };
    const autoCompleteValue = useMemo(() => getValue(channels, configuration.channel_id), [channels, configuration.channel_id]);
    return (
        <ValidatorForm noValidate onSubmit={() => onSave()}>
            <Grid className={classes.pageContainer}>
                <Grid container spacing={6} item xs={12} className={classes.pageInnerBody}>
                    <Grid item xs={12} className={classes.header}>
                        <img src={SlackIcon} className={classes.appLogo} alt="app logo" />
                        <Grid className="pl-3">
                            <Typography variant="h5">
                                Slack connection Settings
                            </Typography>
                            <Typography variant="body1">
                                {appConstants.labels.appIntegration.selectChannel}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            We will send alerts to selected channels in your Slack workspace. If you are connecting to a private channel, please see our
                            <Link>
                                Help Guide
                            </Link>
                            for setup instructions.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={5}>
                            <Grid className="w-100">
                                <Typography variant="body1">
                                    {appConstants.labels.appIntegration.selectachannel}
                                </Typography>
                                <AutoCompleteComponent
                                    name="channel_id"
                                    variant="standard"
                                    selectedValue={autoCompleteValue || ""}
                                    data={channels || []}
                                    onChange={(event, data) => onChange("channel_id", data.id)}
                                    placeholder="Select Channel"
                                    validators={['required']}
                                    errorMessages={['Value required']}
                                    disabled={!integrationPermission?.is_edit}
                                />
                            </Grid>
                            {
                                channelsLoading &&
                                <Grid>
                                    <CircularProgress size={30} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <LoaderButtonComponent
                            size="small"
                            type="submit"
                            isLoading={saveLoading}
                        >
                            {'Save'}
                        </LoaderButtonComponent>
                        <Button
                            variant="outlined"
                            size="small"
                            className="ml-2"
                            onClick={() => navigate(-1)}
                        >
                            {appConstants.labels.connector.Cancel}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
Slack.defaultProps = {
    classes: {}
};

// prop types
Slack.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Slack);