import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';


const initialState = {
    roles: [],
    rolesStats: [],
    permission_source: [],
    permission: {},
    isLoading: false,
    isUpdating: false
};

const securitySlice = createSlice({
    name: 'security',
    initialState: { ...initialState },
    reducers: {
        getRolesListRequest(state) {
            state.isLoading = true;
        },
        getRolesListSuccess(state, action) {
            state.isLoading = false;
            const roles = [...action.payload.data];
            const defaultRoles = roles.filter((item) => item.is_default);
            const userDefinedRoles = roles.filter((item) => !item.is_default);
            state.roles = [...defaultRoles, ...userDefinedRoles];
        },
        getRolesListFailure(state) {
            state.isLoading = false;
        },
        getRolesPermissionRequest(state) {
            state.isLoading = true;
        },
        getRolesPermissionSuccess(state, action) {
            state.isLoading = false;
            state.permission_source = action.payload.data;
            state.permission = _.groupBy(action.payload.data, 'role');
        },
        getRolesPermissionFailure(state) {
            state.isLoading = false;
        },
        addFeaturetoRoleRequest(state) {
            state.isLoading = true;
            state.isUpdating = true;
        },
        addFeaturetoRoleSuccess(state, action) {
            const { data } = action.payload;
            state.isLoading = false;
            state.permission_source = [...state.permission_source, data];
            state.permission[data.role] = [...state.permission[data.role], data];
            state.isLoading = false;
            state.isUpdating = false;
        },
        addFeaturetoRoleFailure(state) {
            state.isLoading = false;
            state.isUpdating = false;
        },
        updateFeaturetoRoleRequest(state) {
            state.isLoading = true;
            state.isUpdating = true;
        },
        updateFeaturetoRoleSuccess(state, action) {
            const { data } = action.payload;
            state.isLoading = false;
            const currentPermission = [...state.permission[data.role]];
            const permissionIndex = currentPermission.findIndex((psn) => psn.id === data.id);
            currentPermission[permissionIndex].is_edit = data.is_edit;
            currentPermission[permissionIndex].is_view = data.is_view;
            currentPermission[permissionIndex].is_none = data.is_none;
            state.permission[data.role] = [...currentPermission];
            state.isUpdating = false;
        },
        updateFeaturetoRoleFailure(state) {
            state.isLoading = false;
            state.isUpdating = true;
        },
        deleteFeatureFromRoleRequest(state) {
            state.isLoading = true;
        },
        deleteFeatureFromRoleSuccess(state, action) {
            const id = action.payload;
            state.permission_source = state.permission_source.filter((i) => i.id !== id);
            state.permission = _.groupBy(state.permission_source, 'role');
            state.isLoading = false;
        },
        deleteFeatureFromRoleFailure(state) {
            state.isLoading = false;
        },
        addNewRoleRequest(state) {
            state.isLoading = true;
        },
        addNewRoleSuccess(state, action) {
            state.roles = [...state.roles, action.payload.data.roles];
            const permission_list = action.payload.data.permission;
            permission_list.forEach((element) => {
                state.permission_source = [...state.permission_source, element];
                state.permission = _.groupBy(state.permission_source, 'role');
            });
            state.isLoading = false;
        },
        addNewRoleFailure(state) {
            state.isLoading = false;
        },
        getRoleStatsRequest(state) {
            state.isLoading = true;
        },
        getRoleStatsSuccess(state, action) {
            state.rolesStats = action.payload.data;
            state.isLoading = false;
        },
        getRoleStatsFailure(state) {
            state.isLoading = false;
        },
        updateRoleRequest(state) {
            state.isLoading = true;
        },
        updateRoleSuccess(state, action) {
            state.isLoading = false;
            const data = action.payload;
            const index = state.roles.findIndex((role) => role.id === data.id);
            state.roles[index].name = data.name;
        },
        updateRoleFailure(state) {
            state.isLoading = false;
        },
        deleteRoleRequest() { },
        deleteRoleSuccess(state, action) {
            state.roles = state.roles.filter((role) => role.id !== action.payload);
        },
        deleteRoleFailure() { }
    }
});

export const { getRolesListRequest, getRolesListSuccess, getRolesListFailure, getRolesPermissionRequest, getRolesPermissionSuccess,
    getRolesPermissionFailure, addFeaturetoRoleRequest, addFeaturetoRoleSuccess, addFeaturetoRoleFailure, deleteFeatureFromRoleRequest,
    deleteFeatureFromRoleSuccess, deleteFeatureFromRoleFailure, addNewRoleRequest, addNewRoleSuccess, addNewRoleFailure, getRoleStatsRequest,
    getRoleStatsSuccess, getRoleStatsFailure, updateRoleRequest, updateRoleSuccess, updateRoleFailure, deleteRoleRequest, deleteRoleSuccess, deleteRoleFailure, updateFeaturetoRoleRequest, updateFeaturetoRoleSuccess, updateFeaturetoRoleFailure } = securitySlice.actions;
export default securitySlice.reducer;