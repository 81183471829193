import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

// Import Styles
import QueryStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import { TextBoxComponent } from '../../../textBox/index.js';

// Import Constants
import appConstants from "../../../../constants/appConstants";
import ValidateMeasure from '../../../../containers/measure/components/add/validateMeasure.jsx';


function QueryComponent(props) {
    /**
     * Define Props
     */
    const {
        classes, classNames, asset_id, data, onChange, name, placeholder, disabled,
        enableValidate, isAggregateQuery, onCheckAggregateQuery, removeAggregateQuery,
        showLineNumber, measureProperties, editMeasure, connection_id, showLimitConfig, validateResult,
        isMeasureLevel, assetName, validateDuplicate, isConnectedAsset, isValidate
    } = props;


    /**
     * State variables
     */
    const lineCounterRef = useRef(null);
    const [query, setQuery] = useState("");


    /**
     * Redux Store
     */
    const { queryError } = useSelector((state) => state.measure);
    const defaultMinimumLines = 1;

    useEffect(() => {
        if (data !== query) {
            setQuery(data);
        }
    }, [data]);
    const lineCount = useMemo(() => { return showLineNumber ? query.split("\n").length : 0; }, [query, showLineNumber]);
    const lineNumbers = useMemo(() => {
        if (!showLineNumber) {
            return [];
        }
        return Array.from({ length: Math.max(defaultMinimumLines, lineCount) }, (_, i) => i + 1);
    }, [lineCount, defaultMinimumLines, showLineNumber]);


    const handleTextareaScroll = (event) => {
        if (lineCounterRef.current) {
            lineCounterRef.current.scrollTop = event.target.scrollTop;
        }
    };


    return (
        <Grid className={"queryComponentContainers"}>
            <Grid className={`queryPageContainer ${classnames(classes.queryContainer, classNames)}`}>
                {
                    showLineNumber &&
                    <Grid className={"lineNumberContainer"} ref={lineCounterRef}>
                        {
                            lineNumbers.map((i) => (
                                <Grid className={"lineNumber"} key={i}>
                                    {i}
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                <Grid className={"queryContainer"}>
                    <TextBoxComponent
                        name={name}
                        value={data}
                        multiline
                        rows={8}
                        noOutline
                        valOnChange
                        placeholder={placeholder}
                        onChange={(event) => setQuery(event.target.value)}
                        onBlur={(event) => onChange(name, event.target.value, event)}
                        validators={['required']}
                        errorMessages={[name && name.length > 0 ? `${name} is required` : appConstants.errorMessages.valueRequired]}
                        disabled={disabled}
                        helperText={enableValidate && queryError ? queryError : ""}
                        inputProps={{ onScroll: handleTextareaScroll }}
                    />
                </Grid>
            </Grid>
            {
                enableValidate &&
                <ValidateMeasure
                    type={"query"}
                    data={data}
                    asset_id={asset_id}
                    isAggregateQuery={isAggregateQuery}
                    removeAggregateQuery={removeAggregateQuery}
                    onCheckAggregateQuery={onCheckAggregateQuery}
                    measureProperties={measureProperties}
                    editMeasure={editMeasure}
                    connection_id={connection_id}
                    showLimitConfig={showLimitConfig}
                    validateResult={validateResult}
                    isMeasureLevel={isMeasureLevel}
                    assetName={assetName}
                    isConnectedAsset={isConnectedAsset}
                    isValidate={isValidate}
                    validateDuplicate={validateDuplicate}
                />
            }
        </Grid>
    );
}

// default props
QueryComponent.defaultProps = {
    classes: {},
    asset_id: "",
    data: "",
    name: "",
    placeholder: "",
    classNames: "",
    showLineNumber: true,
    onChange: () => { },
    disabled: false,
    enableValidate: false,
    isAggregateQuery: false,
    removeAggregateQuery: false,
    validateResult: false,
    showLimitConfig: false,
    onCheckAggregateQuery: () => { },
    measureProperties: {},
    editMeasure: () => { },
    connection_id: "",
    isMeasureLevel: false,
    assetName: "",
    isConnectedAsset: false,
    validateDuplicate: false,
    isValidate: false
};

// prop types
QueryComponent.propTypes = {
    classes: PropTypes.object,
    asset_id: PropTypes.string,
    classNames: PropTypes.string,
    data: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    enableValidate: PropTypes.bool,
    isValidate: PropTypes.bool,
    isAggregateQuery: PropTypes.bool,
    validateResult: PropTypes.bool,
    showLimitConfig: PropTypes.bool,
    connection_id: PropTypes.string,
    removeAggregateQuery: PropTypes.bool,
    onCheckAggregateQuery: PropTypes.func,
    showLineNumber: PropTypes.func,
    measureProperties: PropTypes.object,
    editMeasure: PropTypes.func,
    isMeasureLevel: PropTypes.func,
    assetName: PropTypes.string,
    isConnectedAsset: PropTypes.bool,
    validateDuplicate: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.assetName, nextProps.assetName)
        && _.isEqual(prevProps.isConnectedAsset, nextProps.isConnectedAsset)
        && _.isEqual(prevProps.classes, nextProps.classes)
        && _.isEqual(prevProps.isAggregateQuery, nextProps.isAggregateQuery)
        && _.isEqual(prevProps.connection_id, nextProps.connection_id)
        && _.isEqual(prevProps.measureProperties, nextProps.measureProperties);
}

export default withStyles(
    (theme) => ({
        ...QueryStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(QueryComponent, areEqual));