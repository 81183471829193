// Import API Helper
import { getRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * Detail
 * @param {*} id
 * @returns
 */
async function get() {
    const response = await getRequest(urlConstants.license.url);
    return response;
}

/**
 * Create
 * @param {*} params
 * @returns
 */
async function create(params) {
    const response = await postRequest(urlConstants.license.url, params);
    return response;
}


/**
 * Create
 * @param {*} params
 * @returns
 */
async function sync() {
    const response = await postRequest(urlConstants.license.sync);
    return response;
}

/**
 * Export All Service Functions
 */
export const licenseService = { get, create, sync };