const style = (theme) => ({
    thresholdContainer: {
        marginTop: '30px !important',
        background: theme.palette.greyshades.lightbackground,
        // border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        padding: 24,
        '& .thresholdType': {
            '& .MuiTypography-root': {
                whiteSpace: 'nowrap'
            }
        }
    },
    thresholdAddContainer: {
        marginLeft: 24,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 4,
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            minWidth: 140,
            padding: '0px 9px !important',
            height: '33.59px',
            borderRadius: '2px'
        },
        '& .MuiInputBase-input': {
            padding: '5.5px 0px !important',
            fontSize: 14
        },
        '& fieldset': {
            borderColor: `${theme.palette.greyshades.grey} !important`
        },
        '& .MuiAutocomplete-clearIndicator svg': {
            width: '18px',
            height: '18px',
            fill: theme.palette.greyshades.darkgrey,
            marginTop: '2px'
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginTop: 2
        },
        '& .ChevDownIcon': {
            width: '13px',
            height: '13px'
        }
    },
    field: {
        flex: 1,
        paddingRight: '30px !important',
        maxWidth: 300
    },
    rangeFieldInput: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        background: '#fff',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        },
        '& .Mui-focused fieldset': {
            borderColor: 'transparent !important',
            borderWidth: '0px !important'
        },
        '& .MuiOutlinedInput-root': {
            minWidth: '60px'
            // width: '60px'
        },
        '& .gridItem': {
            flex: 1
        }
    }
});
export default style;