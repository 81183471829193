const style = (theme) => ({
    usersCard: {
        padding: '24px 36px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            minWidth: '130px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid rgb(229 231 235)'
        },
        '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
            fontSize: '1rem'
        }
    },
    cardTitle: {
        position: 'absolute',
        background: '#fff',
        top: '-14px',
        left: '16px',
        padding: '2px 15px',
        fontWeight: 400
    },
    cardText: {
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        color: `${theme.palette.secondary.main} !important`
    }
});
export default style;