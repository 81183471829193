const TermDetailStyle = () => ({
    attributesDetailContainer: {
        padding: '24px 0px',
        paddingBottom: 0,
        '& .searchBox': {
            padding: '2px 10px !important'
        },
        '& .btnTabsInline': {
            width: '100%'
        }
    },
    tabsSection: {
        '& .tabsLink .MuiTypography-h6': {
            padding: '6px 16px 5px',
            fontSize: '14px',
            fontWeight: '400'
        }
    },
    glossaryList: {
        paddingLeft: '54px !important',
        paddingTop: '0px !important',
        WebkitBoxFlex: 1,
        flex: 1,
        transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        marginLeft: 0,
        '&.sideBarClose': {
            paddingLeft: '45px !important',
            transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            marginLeft: '-260px'
        },

        '@media (min-width: 1536px)': {
            '&.sideBarClose': {
                '& .MuiGrid-grid-xl-2': {
                    maxWidth: '20%',
                    flexBasis: '20%'
                }
            },
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '25%',
                flexBasis: '25%'
            }
        }
    },
    glossaryListContainer: {
        paddingTop: 0,
        // height: 'calc(100vh - 180px)',
        overflowY: 'auto',
        marginTop: '0px !important',
        flexWrap: 'nowrap'
        /*
         * paddingBottom: '24px',
         * paddingRight: '24px'
         */
    },
    tabsBody: {
        '& .MuiBox-root': {
            padding: '0px'
        }
    }
});
export default TermDetailStyle;