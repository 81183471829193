import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { CircularScoreComponent } from '../../../../../components/index.js';


// import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';

function Domain(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Redirect
     * @param {*} item
     */
    const redirect = (item) => {
        dispatch(navigate({ path: 'domain.detail', state: {}, params: [item.glossary_id] }));
    };

    return (
        <Grid item xs={12} className="h-100">
            <Grid container className="h-100">
                <Grid item xs={9} className="h-100 overflow-auto">
                    <Grid container spacing={7} className={`${classes.widgetItem} pt-4`} alignItems={'center'}>
                        {
                            data && data.default && data.default.map((item, index) =>
                                <Grid key={`${index}_domain_widget`} item xs={3} className="dflex pt-3 alignCenter">
                                    <Grid item className={classes.widgetListIcon}>
                                        <CircularScoreComponent
                                            size={60}
                                            value={item.dqscore || 0}
                                            showPercentage
                                            noLink
                                            showValue />
                                    </Grid>
                                    <Grid item xs={12} className={classes.widgetItemDetail}>
                                        <Typography variant="body1" className="pointer" onClick={() => redirect(item)}>
                                            {item.name || ""}
                                        </Typography>
                                        <Typography variant="body2" className={classes.textSecondary}>
                                            {` ${item.alerts || 0} Alerts ${item.issues || 0} Issues`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3} className={classes.rightContainer}>
                    <Grid container className={classes.rightContainerInner}>
                        <Grid item xs={6}>
                            <Typography variant="h2">
                                {data.metric_domain && data.metric_domain[0] ? data.metric_domain[0].count : 0}
                            </Typography>
                            <Typography variant="body1">
                                Domains
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h2">
                                {data.metric_application && data.metric_application[0] ? data.metric_application[0].count : 0}
                            </Typography>
                            <Typography variant="body1">
                                Applications
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h2">
                                {data.metric_term && data.metric_term[0] ? data.metric_term[0].count : 0}
                            </Typography>
                            <Typography variant="body1">
                                Terms
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h2">
                                {data.metric_tags && data.metric_tags[0] ? data.metric_tags[0].count : 0}
                            </Typography>
                            <Typography variant="body1">
                                Tags
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Domain.defaultProps = {
    classes: {},
    data: {}
};

// prop types
Domain.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Domain, areEqual));