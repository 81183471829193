// Import API Helper
import { deleteRequest, getRequest, putRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * List Fields
 * @returns
 */
async function list() {
    const response = await getRequest(urlConstants.fields.url);
    return response;
}

/**
 * Create Fields
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function create(params) {
    const response = await postRequest(urlConstants.fields.url, params);
    return response;
}

/**
 * Update Fields
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.fields.url}${id}/`, params);
    return response;
}

/**
 * Remove Asset
 */
async function remove(id) {
    const response = await deleteRequest(`${urlConstants.fields.url}${id}/`);
    return response;
}


/**
 * Get Tabs
 * @returns
 */
async function getTabs() {
    const response = await getRequest(urlConstants.fields.getTab);
    return response;
}


/**
 * Get Tabs
 * @returns
 */
async function getGroups() {
    const response = await getRequest(urlConstants.fields.getGroup);
    return response;
}

/**
 * Export All Service Functions
 */
export const fieldsService = {
    list, create, update, remove, getTabs, getGroups
};