import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import { Grid, IconButton } from '@mui/material';
import Card from './card.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';
import './treeview.css';

// Import Images
import { OrgAdd, OrgMinus } from '../../../assets/svg/index.js';

// Import Service
import { customWidgetService } from '../../../redux/service/customWidgetService.js';

function Hierachy(props) {

    /**
     * Define Props
     */
    const { classes, data, type, details, chartProperties } = props;
    const [hierachyData, setHierachyData] = useState([]);

    /**
     * Load Data
     */
    useEffect(() => {
        if (data.length && !hierachyData.length) {
            const organization = {
                ...data[0]
            };
            let params = [
                {
                    type: "Organization",
                    data: [
                        {
                            ...organization,
                            isSelected: true
                        }
                    ]
                },
                {
                    type: "level1",
                    data: data[0].children ? [...data[0].children] : []
                }
            ];
            if (details?.hideOrg) {
                params = [
                    {
                        type: "level1",
                        data: data[0].children ? [...data[0].children] : []
                    }
                ];
            }

            setHierachyData(params);

        }
    }, []);

    /**
     * Get Type
     * @param {*} type
     * @returns
     */
    const getType = (type) => {
        switch (type) {
            case 'Organization':
                return 'level1';
            case 'level1':
                return 'level2';
            default:
                return 'level3';
        }
    };

    /**
     * OnSelect Event
     * @param {*} item
     * @param {*} itemIndex
     * @param {*} parent
     */
    const onSelectItem = (item, itemIndex, parent) => {
        // if (item.children) {
        let hierachy = JSON.parse(JSON.stringify(hierachyData));
        const type = getType(parent.type);
        const selectedIndex = hierachy[parent.index].data.findIndex((data) => data.isSelected);
        hierachy[parent.index].data[selectedIndex] = {
            ...hierachy[parent.index].data[selectedIndex],
            isSelected: false
        };
        hierachy[parent.index].data[itemIndex] = {
            ...hierachy[parent.index].data[itemIndex],
            isSelected: !item.isSelected
        };
        if (!item.isSelected) {
            const index = hierachy.findIndex((obj) => obj.type === type);
            if (index > 0) {
                hierachy = hierachy.slice(0, parent.index + 1);
            }
            if (item?.children && !item.sub_type) {
                const children = item.children.filter((tag, index, array) => array.findIndex((t) => t.id === tag.id && t.name === tag.name) === index).sort((a, b) => b.dqscore - a.dqscore);
                hierachy.push({
                    type,
                    data: children || []
                });
                setHierachyData([...hierachy]);
            } else {
                const isRecursiveDomain = Boolean(item.sub_type === "recursive_domain");
                const levels = details?.levels ?? [];
                const currentLevel = isRecursiveDomain ? 'hierachy_domain' : `hierachy_${item.type}`;
                const currentIndex = levels.findIndex((data) => data.hierachy_table === currentLevel);
                const childrenData = levels[currentIndex + 1] || {};
                const requestParams = {
                    ...chartProperties,
                    hierachy_property: {
                        hierachy_level: childrenData,
                        current_level: currentLevel,
                        data: item
                    }
                };
                customWidgetService.getPreview(requestParams).then((response) => {
                    const childData = isRecursiveDomain && item.children ? item.children : [];
                    let responseData = response?.data ?? [];
                    if (isRecursiveDomain && childData?.length) {
                        responseData = [...childData, ...responseData];
                    }
                    if (!isRecursiveDomain && !responseData) {
                        return false;
                    }
                    if (responseData) {
                        hierachy.push({
                            type,
                            data: responseData ?? []
                        });
                        if (!responseData.length) {
                            hierachy[parent.index].data[itemIndex] = {
                                ...hierachy[parent.index].data[itemIndex],
                                isSelected: false,
                                blockAdd: true
                            };
                        }
                        setHierachyData([...hierachy]);
                    }
                });
            }

        } else {
            hierachy = hierachy.slice(0, parent.index + 1);
            setHierachyData([...hierachy]);
        }
        // }
    };

    /**
     * Get Height
     * @param {*} index
     * @param {*} items
     * @returns
     */
    const getHeight = (index, items) => {
        const itemIndex = hierachyData[index - 1].data.findIndex((obj) => obj.isSelected);
        return ((itemIndex + 1) - items) * 110;
    };


    /**
     * Get Selection Line Height
     * @param {*} index
     * @param {*} currentIndex
     * @param {*} type
     * @returns
     */
    const getSelectLineHeight = (index, currentIndex, type) => {
        if (index !== 0) {
            const element = document.getElementById(`${index}-${currentIndex}-node${chartProperties?.id ? `-${chartProperties.id}` : ""}`);
            const parentElementIndex = hierachyData[index - 1].data.findIndex((obj) => obj.isSelected);
            const parentNode = document.getElementById(`${index - 1}-${parentElementIndex}-node${chartProperties?.id ? `-${chartProperties.id}` : ""}`);
            if (type === "height") {
                return element && parentNode ? Math.abs(parentNode.offsetTop - element.offsetTop) : 0;
            }
            return parentNode.offsetTop - element.offsetTop > 0 ? "Clinetop" : "Clinebot";
        }
    };


    /**
     * Get Next Children Level
     * @param {*} item
     * @returns
     */
    const getNextChildren = (item) => {
        if (item.blockAdd) {
            return false;
        }
        const isRecursiveDomain = Boolean(item.sub_type === "recursive_domain");
        if (!item.children || isRecursiveDomain) {
            const levels = details?.levels ?? [];
            const currentLevel = isRecursiveDomain ? 'hierachy_domain' : `hierachy_${item.type}`;
            const currentIndex = levels.findIndex((data) => data.hierachy_table === currentLevel);
            const childrenData = levels[currentIndex + 1] || {};
            return Boolean(Object.keys(childrenData).length);
        }
        return item.children.length > 0;
    };


    return (
        <Grid item xs={12} className={classes.horizontalView} sx={{ height: '100%' }}>
            <div className="org-chart">
                {
                    hierachyData.map((item, index) =>
                        <div className="chartColumn" key={index} >
                            <ul>
                                {
                                    item.data.map((obj, childIndex) =>
                                        <li key={childIndex} id={`${index}-${childIndex}-node${chartProperties?.id ? `-${chartProperties.id}` : ""}`} className={classNames(obj.isSelected ? 'selected' : null)} style={{ marginTop: `${item.type === 'Organization' ? ((obj.children.length - 1) * 90) / 2 : 0}px` }}>
                                            <Grid className="orgCard">
                                                <Card data={obj} type={type} />
                                                {
                                                    getNextChildren(obj) &&
                                                    <IconButton onClick={() => onSelectItem(obj, childIndex, { ...item, index })} className={`${classes.addRemoveIcon} noDrag`}>
                                                        {obj.isSelected ? <OrgMinus /> : <OrgAdd />}
                                                    </IconButton>
                                                }
                                            </Grid>
                                            <div className="lin" />
                                            {
                                                childIndex === 0 && index !== 0 &&
                                                <div className="Hlinetop" />
                                            }
                                            {
                                                childIndex === item.data.length - 1 && index !== 0 &&
                                                <div className="Hlinebot" />
                                            }
                                            {
                                                obj.isSelected &&
                                                <div className={getSelectLineHeight(index, childIndex, "class")} style={{ height: `${getSelectLineHeight(index, childIndex, "height")}px` }} />
                                            }
                                            {
                                                childIndex === item.data.length - 1 &&
                                                <div className="gline" style={{ height: `${index !== 0 ? getHeight(index, item.data.length) : 3}px` }} />
                                            }
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </Grid>
    );
}

// default props
Hierachy.defaultProps = {
    classes: {},
    data: [],
    type: "widget",
    details: {}
};

// prop types
Hierachy.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    type: PropTypes.string,
    details: PropTypes.object,
    chartProperties: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }), { withTheme: true }
)(React.memo(Hierachy, areEqual));