import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Import Material UI Componetns
import { Grid, Typography, IconButton, Button, Tooltip } from '@mui/material';

//Import Styles
import ProfileDetailStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//Import App Constants
import appConstants from '../../../../constants/appConstants.js';

//Import Images
import UserImg from '../../../../assets/img/userImg.png';
import { DeleteIcon } from '../../../../assets/svg/index.js';

// Import Components
import { ChipComponent, MemoTextBoxComponent, DialogComponent } from '../../../../components/index.js';
import ImageCropper from '../../../../components/imageCrop/ImageCropper.jsx';

//Import actions
import { getUserDetailRequest, updateUserDetailRequest, updateUserDetail } from '../../../../redux/reducer/userReducer';
import { getApplicationsRequest } from '../../../../redux/reducer/applicationReducer';
import { getDomainListRequest } from '../../../../redux/reducer/semanticReducer.js';
import { updateOrganizationRequest } from '../../../../redux/reducer/organizationReducer.js';
import { checkPermission } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';

function ProfileDetail(props) {

    /**
     * Define Props
     */
    const { classes, onShowChangePassword } = props;
    const dispatch = useDispatch();
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);

    /**
     * Define State
     */
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: ''
    });

    /**
     * Redux selector
     */
    const currentUser = useSelector((state) => state.auth.user);
    const { detail: userDetail, isUserDetailEdited } = useSelector((state) => state.user);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const securityPermission = checkPermission(permission, featureConstants.settings.security);

    /**
     * Get Applications & Domains List
     */
    useEffect(() => {
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
    }, [dispatch]);

    /**
     * Use Ref to store user details for unmount dispatch
     */
    const userDetailStore = useRef(userDetail);
    const userDetailEditState = useRef(isUserDetailEdited);

    /**
     * Update userDetail in useRef when reducer updates
     */
    useEffect(() => {
        userDetailStore.current = userDetail;
    }, [userDetail]);

    /**
     * Update Edit State in useRef when reducer updates
     */
    useEffect(() => {
        userDetailEditState.current = isUserDetailEdited;
    }, [isUserDetailEdited]);

    /**
     * Dispatch all user details during unmount
     */
    /*
     * useEffect(() => {
     *     return () => {
     *         const { first_name, last_name, phone_number, id, applications, domains, avatar, file_to_upload, title, channels } = userDetailStore.current;
     *         const requestParams = { id, first_name, last_name, phone_number, applications, domains, title, channels };
     *         if (file_to_upload && userDetailEditState.current) {
     *             dispatch(updateUserDetailRequest({ ...requestParams, file_to_upload, type: "current_user" }));
     *         } else if (userDetailEditState.current) {
     *             dispatch(updateUserDetailRequest({ ...requestParams, avatar, type: "current_user" }));
     *         }
     *     };
     * }, []);
     */

    /**
     * Dispatch user detail request
     */
    useEffect(() => {
        dispatch(getUserDetailRequest(currentUser.id));
    }, [dispatch]);

    /**
     * Input changes handler function
     * @param {*} property
     * @param {*} value
     */
    const handleInputChange = (property, value) => {
        if (property === "organization_name") {
            dispatch(updateUserDetail({ id: userDetail.id, organization: { ...userDetail.organization, name: value } }));
            dispatch(updateOrganizationRequest({ id: userDetail.organization.id, name: value }));
        } else {
            dispatch(updateUserDetail({ id: userDetail.id, [property]: value }));
            const requestParams = { id: userDetail.id, [property]: value, type: "current_user", field: property };
            dispatch(updateUserDetailRequest(requestParams));
        }
    };

    /**
     * Image upload handler function
     */
    const handleImgUpload = () => {
        document.getElementById('profileImg').click();
    };

    /**
     * Handle Chip Change
     * @param {*} property
     * @param {*} value
     */
    const handleChipChange = (property, value) => {
        value = value.map((obj) => obj.id);
        dispatch(updateUserDetail({ [property]: value }));
        const requestParams = { id: userDetail.id, [property]: value, type: "current_user", field: property };
        dispatch(updateUserDetailRequest(requestParams));
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        dispatch(updateUserDetail({ 'file_to_upload': croppedImage, 'avatar': croppedImageUrl }));
        const requestParams = { id: userDetail.id, 'file_to_upload': croppedImage, type: "current_user", field: 'profile_image' };
        dispatch(updateUserDetailRequest(requestParams));
    };

    /**
     * Handle Delete Event
     */
    const onDelete = () => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.imageDeleteMessage
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: ""
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = () => {
        dispatch(updateUserDetail({ 'file_to_upload': null, 'avatar': "" }));
        const requestParams = { id: userDetail.id, type: "current_user", field: 'profile_image' };
        dispatch(updateUserDetailRequest(requestParams));
        dialogCancelEventHandle();
    };

    /**
     * Get Active Channels List
     * @param {*} channels
     * @returns
     */
    /*
     * const getActiveChannelsList = () => {
     *     if (channels) {
     *         return channels.filter((channel) => channel.channel.integration_id && channel.tag === 'collaboration');
     *     } return [];
     * };
     */

    /**
     * Return Selected Domain List
     */
    const getSelectedDomainList = () => {
        if (userDetail.domains && searchableGlossaries) {
            return searchableGlossaries.filter((obj) => userDetail?.domains.indexOf(obj.id) >= 0);
        }
        return [];
    };

    /**
     * Return Selected Applications List
     */
    const geSelectedApplicationsList = () => {
        if (userDetail.applications && searchableApplicationsList) {
            return searchableApplicationsList.filter((obj) => userDetail?.applications.indexOf(obj.id) >= 0);
        }
        return [];
    };

    /**
     * Return Selected Channels List
     */
    /*
     * const getSelectedChannelsList = () => {
     *     const searchableChannelsList = getActiveChannelsList(channels);
     *     if (userDetail.channels && searchableChannelsList) {
     *         return searchableChannelsList.filter((obj) => userDetail?.channels.indexOf(obj.id) >= 0);
     *     }
     *     return [];
     * };
     */

    return (
        <Grid item xs={12} className={classes.profileDetailContainer}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.profile.editProfile}
                </Typography>
            </Grid>
            <Grid className={classes.profileBody}>
                <Typography variant="body1">
                    {appConstants.labels.profile.editProfileDesc}
                </Typography>
                <Grid container className="pt-3">
                    <Grid item xs={4}>
                        <Grid className={classes.imgUploadSection} onClick={handleImgUpload}>
                            <img src={userDetail.avatar || UserImg}
                                className={classes.profileImg}
                                alt="ProfileImg" />
                            {
                                (userDetail.file_to_upload || userDetail.avatar) &&
                                <Tooltip title="Delete" arrow>
                                    <IconButton className={classes.deleteIcon} onClick={(event) => { onDelete(); event.stopPropagation(); }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid align="center">
                            <Typography variant="body1" className={`${classes.textSecondary} fs-16 pt-3 uploadimg`} align="center" onClick={handleImgUpload}>
                                Upload Image
                            </Typography>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.profileImgInput}
                            id="profileImg"
                            type="file"
                            onChange={handleSelectFile}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <ValidatorForm onSubmit={() => { }}>
                            <Grid container>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.firstName}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <MemoTextBoxComponent
                                            id="form_text_first_name"
                                            name="first_name"
                                            value={userDetail?.first_name ?? ''}
                                            noOutline
                                            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.lastName}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <MemoTextBoxComponent
                                            id="form_text_last_name"
                                            name="last_name"
                                            value={userDetail?.last_name ?? ''}
                                            noOutline
                                            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.email}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <Typography variant="body1" className={`pl-15 formLabel`}>
                                            {userDetail?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.phone}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <MemoTextBoxComponent
                                            noOutline
                                            id="form_text_phone"
                                            name={'phone_number'}
                                            value={userDetail?.phone_number ?? ''}
                                            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={`${classes.profileItem} ${classes.muiChip}`}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.domain}
                                    </Typography>
                                    <Grid className={`pl-5`}>
                                        <ChipComponent
                                            data={getSelectedDomainList()}
                                            labelKey="name"
                                            placeholder="Select Domains"
                                            add={securityPermission?.is_edit}
                                            availableList={searchableGlossaries}
                                            editable={securityPermission?.is_edit}
                                            className={'domainChips'}
                                            handleChipEvent={(value) => handleChipChange('domains', value)}
                                            addType="autocomplete"
                                            limit={2}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.title}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <MemoTextBoxComponent
                                            id="form_text_title"
                                            name="title"
                                            value={userDetail?.title ?? ''}
                                            noOutline
                                            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={`${classes.profileItem} ${classes.muiChip}`}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.application}
                                    </Typography>
                                    <Grid className="pl-5">
                                        <ChipComponent
                                            data={geSelectedApplicationsList()}
                                            labelKey="name"
                                            placeholder="Select Applications"
                                            add={securityPermission?.is_edit}
                                            availableList={searchableApplicationsList}
                                            editable={securityPermission?.is_edit}
                                            handleChipEvent={(value) => handleChipChange('applications', value)}
                                            addType="autocomplete"
                                            haveColor
                                            limit={2}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.status}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <Typography variant="body1" className={`pl-15 formLabel`}>
                                            {userDetail?.is_active ? "Active" : "Inactive"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.channel}
                                    </Typography>
                                    <Grid className="pl-5">
                                        <ChipComponent
                                            data={getSelectedChannelsList()}
                                            labelKey="name"
                                            placeholder="Select Channels"
                                            add
                                            availableList={getActiveChannelsList()}
                                            editable
                                            handleChipEvent={(value) => handleChipChange('channels', value)}
                                            addType="autocomplete"
                                            haveColor
                                            limit={2}
                                        />
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={6} className={classes.profileItem}>
                                    <Typography variant="subtitle1" className={`${classes.textSecondary} formLabel`}>
                                        {appConstants.labels.profile.organization}
                                    </Typography>
                                    <Grid className="pl-35">
                                        <MemoTextBoxComponent
                                            id="form_text_title"
                                            name="organization_name"
                                            value={userDetail?.organization?.name ?? ""}
                                            noOutline
                                            onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.profileItem} />
                                {
                                    userDetail?.auth_type?.toLowerCase() !== "sso" &&
                                    <Grid item xs={6} className={classes.profileItem}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="small"
                                            onClick={(event) => onShowChangePassword(event, userDetail)}>
                                            {appConstants.labels.authentication.changePassword}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </Grid>
            {
                openImageDialog && (
                    <ImageCropper
                        open={openImageDialog}
                        onClose={() => setImageDialog(false)}
                        imageToCrop={imageToCrop}
                        onImageCropped={
                            (croppedImage, croppedImageUrl) =>
                                updateCropImage(croppedImage, croppedImageUrl)
                        }
                    />
                )
            }
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </Grid >
    );
}

// default props
ProfileDetail.defaultProps = {
    classes: {},
    onShowChangePassword: () => { }
};

// prop types
ProfileDetail.propTypes = {
    classes: PropTypes.object,
    onShowChangePassword: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...ProfileDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ProfileDetail);