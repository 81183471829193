import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from "@mui/material/styles";

// Routes
import Routes from "../config/routeConfig.jsx";

// Import Theme
import { getTheme } from "../assets/theme";

//Components
import AlertComponent from "../components/alert/index.jsx";

//Import actions
import { ssoValidateRequest } from '../redux/reducer/authReducer';
import { themeDetailRequest, generalSettingsRequest } from '../redux/reducer/configurationsReducer';

// Import Helpers
import { setThemePalette, getCookie } from "../helpers/appHelpers.js";

function App() {

  /**
   * Define Props
   */
  const dispatch = useDispatch();

  /**
   * Set State
   */
  const [theme, setTheme] = useState(getTheme());
  const [importFont, setImportFont] = useState(false);

  /**
   * Redux selector function to get configurations detail from redux store
   */
  const { theme: { app_title, theme: palette }, general: { isLoaded: generalLoaded } } = useSelector((state) => state.configurations);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  /**
   * Validate User Is Logged In or Not
   */
  const token = getCookie('token');
  useEffect(() => {
    if (token !== '' && !isLoggedIn) {
      dispatch(ssoValidateRequest());
    }
  }, [token]);

  /**
   * Dispatch theme detail request
   */
  useEffect(() => {
    if (isLoggedIn) {
      if (!palette.primary) {
        dispatch(themeDetailRequest());
      } else {
        setTheme(getTheme(palette));
      }
    }
  }, [dispatch, palette, isLoggedIn]);

  /**
   * Create Font Import
   */
  useEffect(() => {
    if (isLoggedIn && palette.primary && !importFont) {
      setImportFont(true);
      setThemePalette(getTheme(palette));
    }
  }, [isLoggedIn, palette]);

  /**
   * Dispatch theme detail request
   */
  useEffect(() => {
    if (isLoggedIn) {
      if (!generalLoaded) {
        dispatch(generalSettingsRequest());
      }
    }
  }, [dispatch, generalLoaded, isLoggedIn]);

  /**
   * Use effect to update app title based on config
   */
  useEffect(() => {
    if (app_title?.trim()?.length > 0) {
      document.title = app_title;
    }
  }, [app_title]);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <AlertComponent />
    </ThemeProvider>
  );
}

export default App;