import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid } from '@mui/material';

//  Import Components
import Add from './components/add/index.jsx';
import Card from './components/card/index.jsx';
import { LoaderComponent, NoResultComponent } from '../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Reducer
import { getGlossaryRequest } from '../../../../redux/reducer/semanticReducer';


function GlossaryCard(props) {
    /**
     * Define Props
     */
    const { classes, search, semanticsPermission } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { isAddNewGlossary, isLoading, glossary } = useSelector((state) => state.semantic, shallowEqual);


    /**
     * Get Detail
     */
    useEffect(() => {
        dispatch(getGlossaryRequest());
    }, []);

    /**
     * Filter Data
     * @param {*} data
     * @param {*} searchText
     * @returns
     */
    const filterGlossary = (data, searchText) => {
        let glossaryData = [...data];
        if (searchText.trim()) {
            glossaryData = glossaryData.filter((obj) => obj.name.toLowerCase().includes(searchText.toLowerCase()) || obj.description && obj.description.toLowerCase().includes(searchText.toLowerCase()));
        }
        return glossaryData;
    };

    const filteredGlossary = useMemo(() => filterGlossary(glossary, search), [glossary, search]);

    return (
        <Grid container className={classes.domainCardContainer} spacing={4}>
            {
                isAddNewGlossary &&
                <Add />
            }
            {
                filteredGlossary && filteredGlossary.map((item, index) => (
                    <Card data={item} key={`glossary_${index}`} index={index} semanticsPermission={semanticsPermission} />
                ))
            }
            {!isLoading && filteredGlossary.length < 1 && <NoResultComponent height="calc(100vh - 375px)" />}
            {
                isLoading &&
                <Grid sx={{ padding: '0px 30px', width: '100%' }}>
                    <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={3} xlCard={2} cardHeight="149px" loaderType="card" height="auto" />
                </Grid>
            }
        </Grid>
    );
}

// default props
GlossaryCard.defaultProps = {
    classes: {},
    search: "",
    semanticsPermission: {}
};

// prop types
GlossaryCard.propTypes = {
    classes: PropTypes.object,
    search: PropTypes.string,
    semanticsPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GlossaryCard);