const style = () => ({
    detailCard: {
        padding: '0px 10px 40px',
        marginTop: '0px !important',
        borderRadius: 7,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        textAlign: 'left',
        '&:before': {
            position: 'absolute',
            background: '#0000004f',
            width: '100%',
            height: '100%',
            zIndex: 1,
            content: "' ' !important",
            left: 0,
            top: 0,
            borderRadius: 7
        },
        '& .MuiTypography-root': {
            color: '#fff !important',
            zIndex: 2
        },
        '& .MuiGrid-grid-xs-3': {
            zIndex: 3
        },
        '@media only screen and (min-width: 1200px)': {
            '& .MuiGrid-grid-xs-3': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        },
        '& .MuiCircularProgress-root': {
            width: '75px !important',
            height: '75px !important'
        },
        '& .progressbackground': {
            color: '#B7BAC0 !important'
        },
        '& .textValue': {
            fontSize: '18px !important',
            color: '#fff !important'
        }
    }
});
export default style;