import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Typography, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

//  Import Components
import { LoaderButtonComponent, MemoTextBoxComponent, SwitchComponent, PasswordInputComponent } from '../../../components/index.js';

//  Import Styles
import FormStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import BigPandaIcon from '../../../assets/img/apps/bigpanda.png';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import { Crypto, checkPermission } from '../../../helpers/index.js';

// Import Actions
import { getConfigurationRequest, createConfigrationRequest, updateConfigurationRequest } from '../../../redux/reducer/integrationReducer.js';
import featureConstants from '../../../constants/featureConstants.js';

function Bigpanda(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: channelId } = useParams();
    const { state } = useLocation();
    const channel = state.channel || {};
    const defaultConfiguration = {
        appkey: "",
        url: "",
        username: "",
        org_token: "",
        push_alerts: false,
        priorities: [],
        push_issues: false,
        web_hook_enabled: false
    };
    const encryptDecrypt = new Crypto();

    /**
     * Define State
     */
    const [checkChanges, setCheckChanges] = useState(false);
    const [formData, setFormData] = useState({ ...defaultConfiguration });

    /**
     * Redux Store
     */
    const { configuration, saveLoading } = useSelector(({ integration }) => integration);
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setCheckChanges(true);
        setFormData((prevState) => ({ ...prevState, [property]: value }));
    };

    /**
     * Update priorities
     * @param {*} property
     * @param {*} key
     * @param {*} value
     */
    const onChangePriority = (property, key, value) => {
        setCheckChanges(true);
        if (key === 'alert_priorities') {
            const exiting_priority = [...formData[property]] || [];
            if (!exiting_priority.includes(value)) {
                exiting_priority.push(value);
            } else {
                exiting_priority.splice(exiting_priority.indexOf(value), 1);
            }
            setFormData((prevState) => ({ ...prevState, [property]: exiting_priority }));
        }
    };

    /**
     * On Save
     */
    const onSave = () => {
        const credentials = {
            username: encryptDecrypt.encrypt(formData?.username ?? ""),
            org_token: encryptDecrypt.encrypt(formData?.org_token ?? ""),
            appkey: encryptDecrypt.encrypt(formData?.appkey ?? "")
        };
        const config = {
            ...formData,
            ...credentials
        };
        const requestParams = {
            config,
            channel: channel.id,
            id: channelId,
            channelName: "bigpanda"
        };
        if (!channelId) {
            dispatch(createConfigrationRequest(requestParams));
        } else {
            dispatch(updateConfigurationRequest(requestParams));
        }
    };

    /**
     * Get Configuration
     */
    useEffect(() => {
        if (channelId) {
            dispatch(getConfigurationRequest(channelId));
        }
    }, []);


    /**
     * Set FormData
     */
    useEffect(() => {
        if (configuration && channelId) {
            const config = { ...configuration };
            config.username = encryptDecrypt.decrypt(config?.username ?? "");
            config.org_token = encryptDecrypt.decrypt(config?.org_token ?? "");
            config.appkey = encryptDecrypt.decrypt(config?.appkey ?? "");
            setFormData({ ...config });
        }
    }, [configuration]);

    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={BigPandaIcon} alt="Icon" />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.integration.BigPanda.header}
                        </Typography>

                        <Typography variant="body1">
                            {appConstants.labels.integration.BigPanda.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={() => onSave()}
                    className="w-100"
                    autoComplete="off"
                >
                    <Grid container justifyContent={"space-between"} spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                name="url"
                                value={formData.url || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        URL
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`URL ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <PasswordInputComponent
                                value={formData.appkey || ""}
                                onChange={(event) => onChange("appkey", event.target.value)}
                                type="password"
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        APP Key
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['passwordRequired']}
                                errorMessages={[`API Key ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        {/* <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="username"
                                value={formData.username || ""}
                                onChange={(event) => onChange("username", event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Username
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Username ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid> */}
                        <Grid item xs={5}>
                            <PasswordInputComponent
                                id="password"
                                value={formData.org_token || ""}
                                onChange={(event) => onChange("org_token", event.target.value)}
                                type="password"
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        Organization Token
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['passwordRequired']}
                                errorMessages={[`organization token ${appConstants.errorMessages.requriedField}`]}
                                disabled={!integrationPermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={5} className="">
                            <SwitchComponent size="small"
                                handleChange={(value) => onChange("push_alerts", value)}
                                checked={!((formData.push_alerts === 'false' || formData.push_alerts === false))}
                                label={'Push Alerts'}
                                disabled={!integrationPermission?.is_edit} />
                            <ToggleButtonGroup
                                className={classes.alertsToggleBtn}
                                color="primary"
                                value={formData?.priorities ?? []}
                                exclusive
                                onChange={(event, value) => onChangePriority('priorities', 'alert_priorities', value)}
                                aria-label={'Push Alerts'}
                                disabled={!formData.push_alerts ?? true}
                            >
                                <ToggleButton value="high">
                                    <Tooltip title="High">
                                        <Grid>
                                            H
                                        </Grid>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="medium">
                                    <Tooltip title="Medium">
                                        <Grid>
                                            M
                                        </Grid>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="low">
                                    <Tooltip title="Low">
                                        <Grid>
                                            L
                                        </Grid>
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <SwitchComponent size="small"
                                handleChange={(value) => onChange("push_issues", value)}
                                checked={!((formData.push_issues === 'false' || formData.push_issues === false))}
                                label={'Push Issues'}
                                disabled={!integrationPermission?.is_edit} />
                        </Grid>
                        <Grid item xs={5} className={classes.checkBox}>
                            <FormControlLabel
                                control={<Checkbox disabled={!integrationPermission?.is_edit} checked={!((formData.web_hook_enabled === 'false' || formData.web_hook_enabled === false))} onChange={(event) => onChange("web_hook_enabled", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.integration.BigPanda.enableWebhook}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigate(-1)}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            <LoaderButtonComponent
                                disabled={!checkChanges}
                                size="small"
                                type="submit"
                                isLoading={saveLoading}
                            >
                                {appConstants.labels.connector.save}
                            </LoaderButtonComponent>

                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Bigpanda.defaultProps = {
    classes: {}
};

// prop types
Bigpanda.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...FormStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Bigpanda);