import appConstants from '../../constants/appConstants.js';

const style = () => ({
    circularContainer: {
        width: 'max-content'
    },
    null: {
        color: "#B0BAC9"
    },
    color20: {
        color: `${appConstants.scoreColor[0]} !important`,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: appConstants.scoreColor[0]
        }
    },
    color40: {
        color: `${appConstants.scoreColor[1]} !important`,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: appConstants.scoreColor[1]
        }
    },
    color60: {
        color: `${appConstants.scoreColor[2]} !important`,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: appConstants.scoreColor[2]
        }
    },
    color80: {
        color: `${appConstants.scoreColor[3]} !important`,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: appConstants.scoreColor[3]
        }
    },
    color100: {
        color: `${appConstants.scoreColor[4]} !important`,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: appConstants.scoreColor[4]
        }

    },
    progressbackground: {
        position: 'absolute',
        color: '#c1d6c652 !important'
    },
    progressContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    nullText: {
        color: "#B0BAC9",
        position: 'absolute',
        fontSize: 13
    },
    value: {
        position: 'absolute',
        fontWeight: 600,
        fontSize: 10
    },
    noPointer: {
        cursor: "auto"
    }
});
export default style;