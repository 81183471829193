import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import { PDFViewer, Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';

// Import styles
import style from './style.jsx';

// Import Images
import welcomeLogo from '../../assets/img/dq_logo.png';

// Import Helpers
import { getPdfFont } from "../../helpers/appHelpers.js";

// Import Constant
import pdfReportFonts from '../../constants/pdfReportFonts.js';

const PdfViewerComponent = (props) => {

	/**
	 * Define Props
	 */
	const { classes, detail, data, hierarchyView, theme } = props;

	const columnNames = Object.keys(data[0]);
	const calculateColumnWidths = (data, headers) => {
		if (!data || data.length === 0 || !headers || headers.length === 0) {
			return [];
		}
		// Calculate max width required by cell content in each column
		const contentWidths = headers.map((header) => {
			const maxWidth = Math.max(...data.map((item) => (item[header]?.toString().length || 0) * 14));
			return maxWidth;
		});

		// Calculate width required by header text
		const headerWidths = headers.map((header) => header.length * 14);

		// Final column widths are the max of contentWidths and headerWidths for each column
		const columnWidths = contentWidths.map((width, index) => Math.max(width, headerWidths[index]));
		// Ensure the maximum width does not exceed 250px
		return columnWidths.map((width) => (width > 200 ? 200 : width));


	};


	const columnWidths = calculateColumnWidths(data, columnNames);
	console.log("ColumnWidths", columnWidths);

	const sumArray = (columnWidths) => {
		return columnWidths.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
	};

	console.log("SumColumnWidths", sumArray(columnWidths));
	/**
	 * Load Fonts
	 */
	useEffect(() => {
		const fonts = [
			...new Set([
				theme.palette.headers.h1.fontFamily, theme.palette.headers.h2.fontFamily, theme.palette.headers.h3.fontFamily,
				theme.palette.headers.h4.fontFamily, theme.palette.headers.h5.fontFamily, theme.palette.headers.h6.fontFamily,
				theme.palette.headers.body1.fontFamily, theme.palette.headers.body2.fontFamily
			])
		];
		const registerFonts = Font.getRegisteredFonts();
		for (const font of fonts) {
			const fontName = getPdfFont(font);
			const data = pdfReportFonts.find((obj) => obj.family === fontName) || null;
			const existingFont = registerFonts[fontName] || null;
			if (data && !existingFont) {
				Font.register(data);
			}
		}
	}, []);

	// Create styles
	const styles = StyleSheet.create({
		page: {
			backgroundColor: '#fff',
			paddingTop: 30,
			paddingBottom: 60,
			paddingHorizontal: 25
		},
		header: { position: 'relative', height: 80, fontWeight: "700" },
		footer: {
			position: "absolute",
			bottom: 0,
			height: 40,
			width: "100%"
		},
		footerText: {
			textAlign: 'center',
			fontFamily: getPdfFont(theme.palette.headers.body2.fontFamily),
			fontSize: theme.palette.headers.body2.size
		},
		headerText: {
			fontSize: theme.palette.headers.h1.size,
			fontFamily: getPdfFont(theme.palette.headers.h1.fontFamily),
			color: theme.palette.headers.h1.color,
			textAlign: 'center'
		},
		headerDescription: { paddingTop: 30, fontFamily: getPdfFont(theme.palette.headers.body1.fontFamily), fontSize: theme.palette.headers.body1.size, color: theme.palette.headers.body1.color, textAlign: 'left' },
		image: { width: '50px', height: '50px', position: 'absolute', right: '0' },
		tableContainer: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			marginTop: 5,
			borderWidth: 1,
			borderColor: `${theme.palette.secondary.main} 50%`
		},
		tableHeaderContainer: {
			flexDirection: 'row',
			borderBottomColor: `${theme.palette.secondary.main} 50%`,
			backgroundColor: theme.palette.secondary.main,
			borderBottomWidth: 1,
			alignItems: 'center',
			minHeight: 30,
			textAlign: 'center',
			flexGrow: 1
		},
		tableHeaderCell: {
			fontFamily: getPdfFont(theme.palette.headers.h3.fontFamily),
			fontSize: theme.palette.headers.h3.size,
			borderRightColor: theme.palette.secondary.main,
			borderRightWidth: 1,
			textTransform: 'uppercase',
			color: theme.palette.headers.h3.color,
			paddingLeft: 8,
			paddingRight: 5,
			width: '100%',
			textAlign: 'left'
		},
		tableRowContainer: {
			flexDirection: 'row',
			borderBottomColor: theme.palette.secondary.main,
			borderBottomWidth: 1,
			alignItems: 'center',
			minHeight: 24,
			flexGrow: 1,
			fontSize: 8
		},
		tableRowCell: {
			fontSize: theme.palette.headers.body2.size,
			textAlign: 'left',
			borderRightColor: theme.palette.secondary.main,
			borderRightWidth: 1,
			paddingLeft: 8,
			flexGrow: 1,
			fontFamily: getPdfFont(theme.palette.headers.body1.fontFamily),
			color: theme.palette.headers.body2.color,
			workBreak: 'break-word',
			width: '100%',
			whiteSpace: 'pre-wrap'
		},
		hierarchyView: {
			marginTop: 5
		},
		hText: {
			fontSize: 13,
			paddingTop: 7,
			paddingBottom: 7
		}
	});


	/**
	 * Redux Select Action
	 */
	const { logo } = useSelector((state) => state.configurations.theme, shallowEqual);

	/**
	 * Bind Header Section
	 * @returns
	 */
	const HeaderSection = () => (
		<View style={styles.header} fixed>
			<Text style={styles.headerText}>
				{detail?.name ?? ''}
			</Text>
			<Image
				style={styles.image}
				src={logo || welcomeLogo}
			/>
		</View>
	);

	/**
	 * Bind Footer Section
	 */
	const FooterSection = () => (
		<View style={styles.footer} fixed>
			<Text style={styles.footerText}>
				{moment().format('MM/DD/YYYY h:mm:ss a')}
			</Text>
		</View>
	);

	/**
	 * Prepare Table
	 */
	const TableSection = () => (
		<View style={styles.tableContainer}>
			<TableHeader />
			<TableRow />
		</View>
	);

	/**
	 * Table Header
	 * @returns
	 */
	const TableHeader = () => {
		const headers = columnNames.map((item, index) =>
			<Text key={`key_${index}`} style={[styles.tableHeaderCell, { maxWidth: columnWidths[index] }]}>
				{item}
			</Text>
		);
		return (
			<View style={styles.tableHeaderContainer}>
				{headers}
			</View>
		);
	};

	/**
	 * Table Row
	 * @returns
	 */
	const TableRow = () => {
		const rows = data.map((item, index) =>
			<View style={styles.tableRowContainer} key={`view_key_${index}`}>
				{
					Object.keys(item).map((column, c_index) => {
						let column_value = item[column]?.toString();
						const datePattern = /^\d{4}-\d{2}-\d{2}-?T\d{2}:\d{2}:\d{2}\.\d{6}$/;
						if (datePattern.test(column_value) && moment(column_value).isValid()) {
							const date = moment(column_value);
							column_value = date.format('MM-DD-YYYY hh:mm:ss');
						}
						else if (column_value === "") {
							column_value = 'NA';
						}
						else if (!column_value) {
							column_value = 'NULL';
						}
						else if (column_value && column_value.includes('|')) {
							column_value = column_value.split('|').map((part) => {
								if (!isNaN(part) && part.trim() !== "") {
									return parseFloat(parseFloat(part).toFixed(9));
								}
								return part;
							}).join('|');
						}
						const maxWidth = columnWidths[c_index];
						return (
							<Text style={[styles.tableRowCell, { maxWidth: maxWidth, flexDirection: 'row', flexWrap: 'wrap', minHeight: '25px' }]} key={`view_cell_${c_index}`}>
								{column_value.split('')}
							</Text>);
					}
					)
				}
			</View>
		);
		return (
			<View>
				{rows}
			</View>
		);
	};

	/**
	 * Group Data by Two Columns
	 * @param {*} data
	 * @param {*} cols
	 */
	const group_by_cols = (data, column) => {
		return _.chain(data)
			.groupBy(column)
			.toPairs()
			.map((currentItem) => {
				return _.zipObject([column, 'data'], currentItem);
			})
			.value();
	};

	/**
	 * Get Hierachy Style
	 * @param {*} level
	 * @returns
	 */
	const getHierachyStyle = (level) => {
		let font = theme.palette.headers.h6;
		switch (level) {
			case 0:
				font = theme.palette.headers.h1;
				break;
			case 1:
				font = theme.palette.headers.h2;
				break;
			case 2:
				font = theme.palette.headers.h3;
				break;
			case 3:
				font = theme.palette.headers.h4;
				break;
			case 4:
				font = theme.palette.headers.h5;
				break;
			default:
				font = theme.palette.headers.h6;
				break;
		}
		return {
			color: font.color,
			fontFamily: getPdfFont(font.fontFamily),
			fontWeight: font.weight,
			fontStyle: font.style
		};
	};

	/**
	 * Prepare Group By Values
	 * @param {*} g_data
	 */
	const prepareGroupByValue = (g_data, level) => {
		const columns = detail?.group_by ?? [];
		return columns.map((item, g_index) => {
			const g_column = item.column;
			const g_agg = item.aggregate;
			const g_col_agg = `${g_column?.toUpperCase()}_${g_agg?.toUpperCase()}`;
			return g_data.map((val, h_index) => {
				const f_data = val[g_col_agg]?.toString()?.split('|') ?? [];
				return f_data.map((val1, r_index) =>
					<Text style={[styles.hText, { ...getHierachyStyle(level), paddingLeft: level * 20 }]} key={`view_cell_g_${g_index}_${h_index}_${r_index}_${level}`}>
						{g_column}
						{` : `}
						{val1 && !isNaN(parseFloat(val1)) ? parseFloat(val1).toFixed(9) : Number(val1) || 'NA'}
					</Text>
				);
			}
			);
		}
		);
	};

	/**
	 * Prepare Hierarchy
	 * @param {*} h_data
	 * @param {*} column
	 */
	const prepareHierarchy = (h_rows, level = 0) => {
		const columns = detail?.columns ?? [];
		const h_column = columns[level];
		const h_data = group_by_cols(h_rows, h_column?.toUpperCase());
		const h_form = h_data.map((item, c_index) => {
			let column_value = item[h_column?.toUpperCase()]?.toString();
			const datePattern = /^\d{4}-\d{2}-\d{2}-?T\d{2}:\d{2}:\d{2}\.\d{6}$/;
			if (datePattern.test(column_value) && moment(column_value).isValid()) {
				const date = moment(column_value);
				column_value = date.format('MM-DD-YYYY hh:mm:ss');
			}
			else if (column_value === null || column_value === 'null') {
				column_value = 'NULL';
			}
			else if (column_value === "") {
				column_value = 'NA';
			}
			return (
				<View key={`view_cell_v_${c_index}_${level}`}>
					<Text style={[styles.hText, { ...getHierachyStyle(level), paddingLeft: level * 20 }]} key={`view_cell_t_${c_index}_${level}`}>
						{h_column}
						{` : `}
						{column_value || 'NA'}
					</Text>
					{level < columns.length - 1 ? prepareHierarchy(item?.data ?? [], level + 1) : prepareGroupByValue(item?.data ?? [], level + 1)}
				</View>
			);
		}
		);
		return h_form;
	};


	/**
	 * Prepare Hierarchy Section
	 */
	const HierarchySection = () => {
		const h_form = prepareHierarchy(data);
		return (
			<View style={styles.hierarchyView}>
				{h_form}
			</View>
		);
	};
	let dpi = {};
	if (columnNames.length <= 5) {
		dpi = {
			dpi: (sumArray(columnWidths) + 50) / 8
		};

	}
	else {
		dpi = {
			dpi: sumArray(columnWidths) / 8
		};


	}


	//const dpi = useMemo(() => getScale(detail.properties?.fit_to_width, columnNames || [], detail.properties?.orientation), [detail?.properties?.fit_to_width, columnNames || [], detail?.properties?.orientation]);
	console.log("DPI", dpi);
	return (
		<Grid container spacing={2} className={classes.pdfContainer}>
			<PDFViewer>
				<Document>
					<Page {...dpi} orientation={"portrait"} size="A4" style={styles.page}>
						<HeaderSection />
						{!hierarchyView && <TableSection />}
						{hierarchyView && <HierarchySection />}
						<FooterSection />
					</Page>
				</Document>
			</PDFViewer>
		</Grid>
	);
};

// default props
PdfViewerComponent.propTypes = {
	classes: {},
	detail: {},
	data: [],
	hierarchyView: false,
	theme: {}
};

// prop types
PdfViewerComponent.defaultProps = {
	classes: PropTypes.object,
	detail: PropTypes.object,
	data: PropTypes.array,
	hierarchyView: PropTypes.bool,
	theme: PropTypes.object
};


export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(PdfViewerComponent);