import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Components
import { Button, Grid, Typography } from '@mui/material';
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent } from '../../components/index.js';

// Import Styles
import AuthenticationStyle from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DQLabsLogo from '../../assets/img/dqlabs.png';

// Import Actions
import { loginRequest } from '../../redux/reducer/authReducer.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import { ssoRequestURL } from '../../helpers/apiHelpers.js';
import { Crypto } from '../../helpers/index.js';


// Import Constants
import appConstants from '../../constants/appConstants.js';
import _ from 'lodash';


function Login(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { isLoading } = useSelector((state) => state.auth, shallowEqual);
    const { theme: { tagline, logo } } = useSelector((state) => state.configurations);


    /**
     * Define States
     */
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const { email, password } = formData;


    /**
     * Handle Textbox Changes
     */
    const handleChange = useCallback((property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    }, [formData]);

    /**
     * Submit Form
     * @param {*} data
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const encryptDecrypt = new Crypto();
        const requestParams = {
            email: encryptDecrypt.encrypt(formData.email.toLowerCase()),
            password: encryptDecrypt.encrypt(formData.password)
        };
        dispatch(loginRequest(requestParams));
    };

    /**
     * Handle SSO Login
     */
    const handleSSOLogin = () => {
        window.location = ssoRequestURL();
    };

    return (
        <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Grid className={`${classes.loginBox}`}>
                <Grid container spacing={5}>
                    <Grid item xs={12} align="center">
                        <img
                            alt="logo"
                            src={_.isEmpty(logo) ? DQLabsLogo : logo}
                            className={classes.loginLogo}
                        />
                        <Typography variant="h5">
                            {tagline}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MemoTextBoxComponent
                            id="form_text_email"
                            name={'email'}
                            value={email}
                            fullWidth
                            placeholder={appConstants.labels.authentication.email}
                            variant="outlined"
                            size="medium"
                            validators={['required', 'isEmail']}
                            valOnChange
                            errorMessages={
                                [
                                    setRequiredErrorMessage(appConstants.labels.authentication.email),
                                    appConstants.errorMessages.invalidMail
                                ]
                            }
                            inputProps={{ maxLength: 100 }}
                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInputComponent
                            id="form_txt_password"
                            name={'password'}
                            value={password}
                            fullWidth
                            placeholder={appConstants.labels.authentication.password}
                            variant="outlined"
                            size="medium"
                            validators={['passwordRequired']}
                            errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.password)]}
                            inputProps={{ maxLength: 100 }}
                            onChange={(event, value) => handleChange(event.target.name, value)}
                            isLogin
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mt2}>
                        <Grid container justifyContent={'space-between'}>
                            <LoaderButtonComponent
                                size={'large'}
                                isLoading={isLoading}>
                                {appConstants.labels.authentication.login}
                            </LoaderButtonComponent>
                            <Grid align="right">
                                <Button
                                    variant="text"
                                    className={classes.linkBtn}
                                    disableRipple
                                >
                                    <Link to="/forgotpassword">
                                        {appConstants.labels.authentication.forgotPassword}
                                    </Link>
                                </Button>
                                <Button
                                    variant="text"
                                    className={classes.linkBtn}
                                    disableRipple
                                    onClick={handleSSOLogin}
                                >
                                    {appConstants.labels.authentication.sso}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} align="center">
                        <Button
                            variant="text"
                            className={classes.linkBtn}
                            disableRipple
                        >
                            <Link to="#">
                                {appConstants.labels.authentication.signingFirstTime}
                            </Link>
                        </Button>
                    </Grid> */}
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
Login.defaultProps = {
    classes: {}
};

// prop types
Login.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AuthenticationStyle(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(Login);