import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getMeasureRequest, getMeasureSuccess, getMeasureFailure, createMeasureRequest, createMeasureSuccess, createMeasureFailure,
    updateMeasureRequest, updateMeasureSuccess, updateMeasureFailure, deleteMeasureRequest, deleteMeasureSuccess, deleteMeasureFaiure,
    getMeasureDetailRequest, getMeasureDetailSuccess, getMeasureDetailFailure, getActivePatternsRequest, getActivePatternsSuccess,
    getActivePatternsFailure, getDashboardFilterRequest, getDashboardFilterSuccess, getDashboardFilterFailure, getMeasureNameListRequest,
    getMeasureNameListSuccess
} from '../reducer/baseMeasureReducer';
import { displyAlert } from "../reducer/alertReducer";
import { updatePattern } from '../reducer/termReducer';

// Import Services
import { baseMeasureService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Get Base Measure
 * @returns
 */
const get = (action$) => action$.pipe(
    ofType(getMeasureRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.get(payload)).pipe(
            concatMap((res) => { return of(getMeasureSuccess({ data: res.data, payload })); }),
            catchError((error) => { return of(getMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create Base Measure
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(createMeasureRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.create(payload)).pipe(
            concatMap((res) => {
                return of(
                    res.data?.type === "pattern" ?
                        updatePattern(res.data) :
                        createMeasureSuccess(res),
                    displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created })
                );
            }),
            catchError((error) => { return of(createMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Base Measure
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateMeasureRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(baseMeasureService.update(id, rest)).pipe(
            concatMap((res) => {
                return of(
                    rest.data?.type === "pattern" ?
                        updatePattern({ id, ...rest }) :
                        updateMeasureSuccess({ "response": res?.data ?? {}, "req_data": rest }),
                    displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })
                );
            }),
            catchError((error) => { return of(updateMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Base Measure
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
    ofType(deleteMeasureRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.remove(payload)).pipe(
            concatMap(() => {
                return of(deleteMeasureSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }));
            }),
            catchError((error) => { return of(deleteMeasureFaiure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Base Measure
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getMeasureDetailRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.detail(payload)).pipe(
            concatMap((res) => { return of(getMeasureDetailSuccess(res)); }),
            catchError((error) => { return of(getMeasureDetailFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get active patterns list
 * @returns
 */
const getActivePatterns = (action$) => action$.pipe(
    ofType(getActivePatternsRequest),
    mergeMap(() => {
        return from(baseMeasureService.getActivePatterns()).pipe(
            concatMap((res) => { return of(getActivePatternsSuccess(res)); }),
            catchError((error) => { return of(getActivePatternsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Measure Stats for Dashboard
 * @param {*} action$
 * @returns
 */
const getMeasureDashboardStatus = (action$) => action$.pipe(
    ofType(getDashboardFilterRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.getMeasureDashStatus(payload)).pipe(
            concatMap((res) => of(getDashboardFilterSuccess(res.data))),
            catchError(() => of(getDashboardFilterFailure()))
        );
    })
);

/**
 * Get Measures Name List
 * @param {*} action$
 * @returns
 */
const getMeasuresNameList = (action$) => action$.pipe(
    ofType(getMeasureNameListRequest),
    mergeMap(({ payload }) => {
        return from(baseMeasureService.getMeasuresNameList(payload)).pipe(
            concatMap((res) => of(getMeasureNameListSuccess(res.data))),
            catchError(() => of(getMeasureDetailFailure()))
        );
    })
);


// Export All BaseMeasure Functions
export const baseMeasureEpic = [get, create, update, remove, detail, getActivePatterns, getMeasureDashboardStatus, getMeasuresNameList];