import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useScreenshot } from 'use-react-screenshot';

// Import Images
import { DangerIcon } from '../../../../../assets/svg/index.js';

// Import Components
import { CountChartComponent, ChipComponent } from '../../../../../components/index.js';
import AlertShare from './alertShare.jsx';

// Import Style
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Helpers
import { toRound } from '../../../../../helpers/appHelpers.js';

// Import Constant
import appConstants from '../../../../../constants/appConstants';

// Import Reducers
import { clearMeasureDetailState, clearMeasureList } from '../../../../../redux/reducer/measureReducer';
import { clearMetricsState } from '../../../../../redux/reducer/metricReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { navigate } from '../../../../../redux/reducer/navigationReducer.js';
function AlertSlideCard(props) {

    /**
     * Define Props
     */
    const { classes, slide_item, index, dragging, ...rest } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const card_div_ref = useRef();
    const [screenShotCaptured, setScreenShotCaptured] = useState(false);
    const [shareImageScreenShot, takeShareImageScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 0.8
        }
    );
    const [alertScreenShot, setAlertScreenShot] = useState({ 'file': null, 'base64': null });

    /**
     * Show Measure Details
     * @param {*} item
     */
    const handleMeasureQualityDialog = (event, item) => {
        if (dragging) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            dispatch(clearMeasureDetailState());
            dispatch(clearMetricsState());
            dispatch(clearMeasureList());
            if (item && item.measure_id) {
                const isAssetLevel = item?.attribute_id === "All" || item?.attribute_id === "" || !item?.attribute_id;
                const state = {
                    measure_id: item?.measure_id ?? null,
                    selectedAlert: item?.id ?? null,
                    showEdit: true,
                    name: item?.measure_name ?? '',
                    measureName: item?.measure_name ?? '',
                    isAssetLevel,
                    attribute_id: item?.attribute_id,
                    asset_id: item?.asset_id,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [item?.measure_id ?? null] }));
            }
        }
    };

    /**
     * Upload Alert Screenshot
     * @param {*} dataurl
     */
    const uploadScreenShot = (dataurl) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], 'share.jpg', { type: mime });
        setAlertScreenShot({ 'file': croppedImage, 'base64': dataurl });
        setScreenShotCaptured(true);
    };


    /**
     * Handle Screenshot After Dom Loaded
     */
    const takeScreenshot = () => {
        let timer = null;
        if (card_div_ref && !screenShotCaptured && !shareImageScreenShot) {
            timer = setTimeout(() => {
                takeShareImageScreenShot(card_div_ref.current).then(uploadScreenShot);
            }, 1000);
        }
        return () => clearTimeout(timer);
    };

    const checkIsPositive = (selectedData) => {
        let isPositive = selectedData?.is_positive || false;
        const technicalName = selectedData?.technical_name || "";
        if (appConstants.profile.frequencyMeasures.indexOf(technicalName) > -1) {
            let selectedItem = null;
            const measureName = selectedData?.measure_name || "";
            switch (technicalName) {
                case appConstants.profile.frequencyMeasures[0]:
                    const universal_patterns = JSON.parse(selectedData?.universal_patterns || '');
                    selectedItem = universal_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[1]:
                    const short_universal_patterns = JSON.parse(selectedData?.short_universal_patterns || '');
                    selectedItem = short_universal_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[2]:
                    const user_defined_patterns = JSON.parse(selectedData?.user_defined_patterns || '');
                    selectedItem = user_defined_patterns?.find((item) => item.enum_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[3]:
                    const length_distribution = JSON.parse(selectedData?.length_distribution || '');
                    selectedItem = length_distribution?.find((item) => item.length_value === measureName);
                    break;
                case appConstants.profile.frequencyMeasures[4]:
                    const value_distribution = JSON.parse(selectedData?.value_distribution || '');
                    selectedItem = value_distribution?.find((item) => item.enum_value === measureName);
                    break;
                default:
                    selectedItem = null;
                    break;
            }
            isPositive = selectedItem?.is_valid || false;
        }
        return isPositive;
    };

    return (
        <Grid key={index} className="sliderItem" {...rest} ref={card_div_ref}>
            <Grid className="sliderBody" onClick={(event) => handleMeasureQualityDialog(event, slide_item)}>
                <Grid container className="sliderBodyContainer h-100">
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} wrap="nowrap" alignItems={"baseline"}>
                            <Grid item className={classes.headerLeft}>
                                <Tooltip title={slide_item.attribute_name} arrow>
                                    <Typography variant="body1" noWrap>
                                        {slide_item.attribute_name}
                                    </Typography>
                                </Tooltip>
                                <Typography variant="body1" className="fs-18">
                                    {slide_item.name}
                                </Typography>
                                <Typography variant="body1" className="pb5 breakWord">
                                    {slide_item.description}
                                </Typography>
                            </Grid>
                            <Grid item onClick={(event) => { event.stopPropagation(); }}>
                                <AlertShare takeScreenshot={takeScreenshot} alertScreenShot={alertScreenShot} data={slide_item} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="pt7">
                        {
                            slide_item.allow_score &&
                            <Grid
                                item
                                className={`${classes.countChips} ${classes.muiChip}`}
                            >
                                <ValidatorForm className="dflex w-100" onSubmit={() => { }}>
                                    <ChipComponent chipClass="success sm mr-15" toolTipName="Valid Records" data={[(checkIsPositive(slide_item) ? slide_item.valid_count : slide_item.invalid_count) || 0]} />
                                    <ChipComponent chipClass="danger sm mr-15" toolTipName="Invalid Records" data={[(checkIsPositive(slide_item) ? slide_item.invalid_count : slide_item.valid_count) || 0]} />
                                    <ChipComponent chipClass="grey sm mr-15" toolTipName="Total Records" data={[slide_item.total_count || 0]} />
                                    <ChipComponent chipClass="success sm mr-15" toolTipName={`Score ${slide_item.score}`} data={[`${toRound(slide_item.score)}%`]} />
                                </ValidatorForm>
                            </Grid>
                        }
                        {
                            slide_item.history_data && slide_item.history_data.length > 0 && slide_item.history_data[slide_item.history_data.length - 1].status
                            && slide_item.history_data[slide_item.history_data.length - 1].status?.toLowerCase() !== appConstants.drift.ok &&
                            <Typography variant="body1" className={`${classes.failedText} ${slide_item?.status?.toLowerCase() ?? 'ok'}`}>
                                <DangerIcon />
                                <span>
                                    {slide_item.history_data[slide_item.history_data.length - 1].message ? slide_item.history_data[slide_item.history_data.length - 1].message : `${slide_item.name + " value percentage exceed the threshold limit"}`}
                                </span>
                            </Typography>
                        }
                    </Grid>
                    {
                        slide_item.history_data &&
                        <Grid style={{ width: '100%' }}>
                            <CountChartComponent
                                ruleName={slide_item.name}
                                data={slide_item.history_data}
                                y_position="right"
                                key={`rule-${index}`}
                                has_alerts />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
AlertSlideCard.defaultProps = {
    classes: {},
    slide_item: {},
    index: 0,
    dragging: false
};

// prop types
AlertSlideCard.propTypes = {
    classes: PropTypes.object,
    slide_item: PropTypes.object,
    index: PropTypes.number,
    dragging: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.slide_item, nextProps.slide_item) && _.isEqual(prevProps.dragging, nextProps.dragging) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(AlertSlideCard, areEqual));