import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import {
    catalogListRequest, catalogListSuccess, catalogListFailure, catalogStatisticsRequest, catalogStatisticsSuccess, catalogStatisticsFailure,
    catalogSearchSuggestionsRequest, catalogSearchSuggestionsSuccess, catalogSearchSuggestionsFailure, catalogSearchTrackingRequest, catalogSearchTrackingSuccess,
    catalogSearchTrackingFailure
} from '../reducer/catalogReducer';
import { catalogService } from '../service';
import { displyAlert } from "../reducer/alertReducer";
import { updateNotificationsStats } from "../reducer/logsReducer";

/**
 * Search Actions
 * @param {*} action$
 * @returns
 */
const search = (action$) => action$.pipe(
    ofType(catalogListRequest),
    mergeMap(({ payload }) => {
        return from(catalogService.search(payload.params, payload.token)).pipe(
            concatMap((res) => of(catalogListSuccess({ ...res, request_data: payload }))),
            catchError(() => of(catalogListFailure()))
        );
    })
);

/**
 * Get Count Statistics of catalog
 * @param {*} action$
 * @returns
 */
const catalogStats = (action$) => action$.pipe(
    ofType(catalogStatisticsRequest),
    mergeMap(({ payload }) => {
        return from(catalogService.stats(payload)).pipe(
            concatMap((res) => of(catalogStatisticsSuccess(res), updateNotificationsStats(res))),
            catchError((error) => of(catalogStatisticsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Suggestions Actions
 * @param {*} action$
 * @returns
 */
const suggestions = (action$) => action$.pipe(
    ofType(catalogSearchSuggestionsRequest),
    mergeMap(({ payload }) => {
        return from(catalogService.suggestions(payload)).pipe(
            concatMap((res) => of(catalogSearchSuggestionsSuccess(res))),
            catchError(() => of(catalogSearchSuggestionsFailure()))
        );
    })
);

/**
 * Track
 * @param {*} action$
 * @returns
 */
const track = (action$) => action$.pipe(
    ofType(catalogSearchTrackingRequest),
    mergeMap(({ payload }) => {
        return from(catalogService.track(payload)).pipe(
            concatMap((res) => of(catalogSearchTrackingSuccess(res))),
            catchError(() => of(catalogSearchTrackingFailure()))
        );
    })
);


// Export All Epic Functions
export const catalogEpic = [search, catalogStats, suggestions, track];