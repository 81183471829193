const style = (theme) => ({
    alertsCard: {
        maxHeight: 120,
        height: 120,
        background: '#fff',
        padding: 10
    },
    chartContainer: {
        position: 'relative',
        right: '25px',
        top: '-36px',
        width: '100px',
        '& .echarts-for-react text': {
            fontWeight: 500,
            fontSize: '20px !important',
            fontFamily: `${theme.palette.headers.h1.fontFamily} !important`
        }
    }
});

export default style;