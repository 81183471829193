import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

// Import Styles
import Styles from "./style.jsx";

function PublicLayoutComponent({ component: Component, ...props }) {

  return (

    /* Container */
    <Grid container>
      <Component {...props} />
    </Grid>

  );
}

// prop types
PublicLayoutComponent.propTypes = {
  classes: PropTypes.object,
  component: PropTypes.elementType
};

export const PublicLayout = withStyles(Styles)(PublicLayoutComponent);