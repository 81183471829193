const style = (theme) => ({
    semanticsOverviewPageContainer: {
        padding: '24px 30px 0px'
    },
    tabHeaderContainer: {
        paddingBottom: '20px',
        zIndex: 99,
        position: 'relative',
        '& .tabHeader': {
            borderBottom: 'none !important',
            justifyContent: 'end',
            background: 'transparent'
        },
        '& .tabsLink': {
            padding: '0px',
            marginRight: 1
        },
        '& .tabsLink .MuiTypography-h4.MuiTypography-root': {
            padding: '3px 16px',
            height: '30px !important',
            display: 'flex',
            alignItems: 'center',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            fontFamily: theme.palette.headers.h6.fontFamily,
            fontWeight: '500 !important',
            background: theme.palette.greyshades.lightgrey,
            color: `${theme.palette.greyshades.darkgrey} !important`,
            borderRadius: '30px',
            marginRight: '8px',
            '&.active': {
                border: 'none !important',
                background: theme.palette.secondary.main,
                color: '#fff !important',
                padding: '3px 16px'
            }
        }
    }
});
export default style;