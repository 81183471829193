// Import API Helper
import { getRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Metric Detail
 * @param {*} measureId
 * @returns
 */
async function detail(params) {
    const response = await getRequest(`${urlConstants.metrics.url}${params.id}/?days=${params.days}&name=${params.name}`);
    return response;
}

/**
 * Get Comparison Metrics
 * @param {*} measureId
 * @returns
 */
async function getComparisonMetric(params) {
    const response = await postRequest(urlConstants.metrics.getComparisonMetric, params);
    return response;
}

/**
 * Export All Metric Service Functions
 */
export const metricService = {
    detail, getComparisonMetric
};