import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

//  Import Components
import { Button, Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { ChipComponent, ConnectorsIcon } from '../../../../../components/index.js';
import { DoubleArrowUp, ArrowUp, ArrowDown } from '../../../../../assets/svg/index.js';

// Import Actions
import { navigate } from '../../../../../redux/reducer/navigationReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';

function LastIssues(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Get Alert Status Icon
     * @param {*} status
     * @returns
     */
    const getAlertStatusIcon = (status) => {
        switch (status) {
            case 'High':
                return (<DoubleArrowUp />);
            case 'Medium':
                return (<ArrowUp />);
            default:
                return (<ArrowDown />);
        }
    };

    /**
     * Get Status Class
     * @param {*} status
     * @returns
     */
    const getStatusClass = (status) => {
        switch (status) {
            case 'High':
                return 'high';
            case 'Medium':
                return 'medium';
            default:
                return 'low';
        }
    };

    /**
     * Get Priority Class
     * @param {*} priority
     * @returns
     */
    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'High':
                return 'danger';
            case 'Medium':
                return 'warning';
            default:
                return 'info';
        }
    };

    const redirect = (alert, type) => {
        switch (type) {
            case "asset_name":
                dispatch(navigate({ path: 'assets.root', state: {}, params: [alert.asset_id] }));
                break;
            case "attribute_name":
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [alert.asset_id, alert.attribute_id] }));
                break;
            case "message":
                const state = {
                    measure_id: alert?.measure_id ?? null,
                    selectedAlert: alert?.id ?? null,
                    showEdit: false,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [alert?.measure_id ?? null] }));
                break;
            default:
                break;
        }
    };


    return (
        <Grid item xs={12} className="h-100">
            <Grid item xs={12} className={classes.alertsContainer}>
                {
                    data && data.map((item, index) =>
                        <Grid key={`alert_${index}`} container className={`${classes.widgetItem}`} alignItems={'center'} wrap="nowrap">
                            <Grid item className={`${classes.widgetListIcon} ${getStatusClass(item.status)}`}>
                                {getAlertStatusIcon(item.status)}
                            </Grid>
                            <Grid item xs={12} className="dflex spaceBetween">
                                <Grid item className="pr-1">
                                    <Typography variant="body1" align="left" className="pb5 pointer" onClick={() => redirect(item, "message")}>
                                        {item.message || ""}
                                    </Typography>
                                    <ValidatorForm onSubmit={() => { }}>
                                        <ChipComponent chipClass={`${getPriorityClass(item.status)} sm mr-15`} data={[item.measure_type || 0]} />
                                    </ValidatorForm>
                                    <Grid item className="dflex alignCenter">
                                        <Typography variant="subtitle1" className={`${classes.textSecondary} pointer`} onClick={() => redirect(item, "asset_name")}>
                                            {item.asset_name || ""}
                                        </Typography>
                                        {
                                            item.attribute_name &&
                                            <Fragment>
                                                <Typography variant="subtitle1" className={`${classes.textSecondary} pr-1 pl-1`}>
                                                    |
                                                </Typography>
                                                <Typography variant="subtitle1" className={`${classes.textSecondary} pointer`} onClick={() => redirect(item, "attribute_name")}>
                                                    {item.attribute_name || ""}
                                                </Typography>
                                            </Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className={classNames(classes.connectorIon, `${item.type || ''}`)}>
                                    <ConnectorsIcon connection_type={item.type} size={'small'} />
                                </Grid>
                            </Grid>
                        </Grid>

                    )
                }
            </Grid>
            <Grid align="right" className={classes.linkBtnText}>
                <Button variant="text"
                    onClick={() => dispatch(navigate({ path: 'home.alert', state: {}, params: [] }))}
                    className={classes.linkBtn}
                    disableRipple>
                    See all alerts
                </Button>
            </Grid>
        </Grid>
    );
}

// default props
LastIssues.defaultProps = {
    classes: {},
    data: []
};

// prop types
LastIssues.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LastIssues);