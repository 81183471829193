import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//import Componetns
import { Grid, Button } from '@mui/material';
import { TableComponent, LoaderButtonComponent } from '../../../../../../components/index.js';

//import Styles
import Styles from "./style.jsx";
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../../constants/appConstants.js';

// Import Actions
import { importMetadataRequest, importMeasureRequest, importUsersRequest } from '../../../../../../redux/reducer/importExportReducer.js';


function ImportData(props) {

    /**
     * Define Props
     */
    const { classes, type, onClose } = props;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const defaultLimit = 20;

    // Redux Store
    const { importData, importLoading } = useSelector(({ importExport }) => importExport);
    const fields = useSelector(({ fields }) => fields.list);

    // Define State
    const [mappedHeaders, setMappedHeaders] = useState([]);
    const [limit, setLimit] = useState(defaultLimit);

    /**
     * Set Mapped Header
     * @param {*} data
     */
    const setMappedHeader = (data = {}) => {
        let headers = [];
        if (type === "Metadata") {
            const fieldHeaders = fields.filter((item) => item.level === "Asset").map((field) => {
                return {
                    key: "",
                    name: field.name,
                    data_type: field.data_type || "text",
                    disableMapColumn: false,
                    subComponent: true,
                    subComponentType: "autocomplete",
                    subComponentKey: "key",
                    width: '15%'
                };
            });
            headers = [...appConstants.table.importMetadataHeaders, ...fieldHeaders];
        } else if (type === "Measure") {
            const MeasurefieldHeaders = fields.filter((item) => item.level === "Measure").map((field) => {
                return {
                    key: "",
                    name: field.name,
                    data_type: field.data_type || "text",
                    disableMapColumn: false,
                    subComponent: true,
                    subComponentType: "autocomplete",
                    subComponentKey: "key",
                    width: '15%'
                };
            });
            headers = [...appConstants.table.importMeasureHeaders, ...MeasurefieldHeaders];
        } else {
            headers = [...appConstants.table.importUsersHeaders];
        }
        const dataHeader = data?.headers ?? [];
        const headerData = headers.map((header) => {
            const headerInfo = dataHeader.find((obj) => obj.toLowerCase() === header.name.toLowerCase());
            return {
                ...header,
                key: headerInfo || ""
            };
        });
        setMappedHeaders([...headerData]);
    };

    /**
     * Set Mapped Header
     */
    useEffect(() => {
        if (!mappedHeaders.length && importData && importData?.data?.length) {
            setMappedHeader({ ...importData });
        }
    }, [importData]);

    /**
     * onChange Column Mapping
     * @param {*} item
     * @param {*} value
     */
    const onChangeColumnMapping = (item, value) => {
        const headers = [...mappedHeaders];
        const index = headers.findIndex((obj) => obj.name === item.name);
        if (index !== -1) {
            headers[index].key = value;
            setMappedHeaders([...headers]);
        }
    };

    /**
     * Handle Import
     */
    const handleImport = () => {
        const headers = {};
        for (const header of mappedHeaders) {
            headers[header.name] = header.key || "";
        }
        const requestParams = {
            headers,
            data: importData.data ?? [],
            upload_file_name: importData.fileName || ""
        };
        if (type === "Metadata") {
            const assets = [...new Set(importData.data.map((obj) => obj["Asset Id"]))];
            requestParams.assets = [...assets];
            dispatch(importMetadataRequest(requestParams));
        } else if (type === "Measure") {
            dispatch(importMeasureRequest(requestParams));
        } else {
            dispatch(importUsersRequest(requestParams));
        }
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScroll = () => {
        if (tableRef.current) {
            const scrollHeight = tableRef.current.scrollHeight - Math.floor(tableRef.current.scrollTop);
            const clientHeight = tableRef.current.clientHeight;
            if ((scrollHeight >= clientHeight && scrollHeight <= clientHeight + 2) && importData?.data?.length > defaultLimit) {
                setLimit(limit + defaultLimit);
            }
        }
    };

    /**
     * Handle Lazy Load
     */
    useEffect(() => {
        if (tableRef && tableRef.current) {
            const element = tableRef.current;
            element.addEventListener("scroll", onScroll);
            return () => element.removeEventListener("scroll", onScroll);
        }
    }, [tableRef, onScroll]);

    /**
     * Get Available Options
     * @param {*} sourceData
     * @param {*} mapHeaders
     * @returns
     */
    const getAvailableOptions = (sourceData = {}, mapHeaders = []) => {
        const mapHeaderKeys = mapHeaders.map((obj) => obj.key);
        const sourceHeaders = sourceData?.headers ?? [];
        return sourceHeaders.filter((obj) => !mapHeaderKeys.includes(obj));
    };

    /**
     * Load Data
     * @param {*} data
     * @param {*} config
     * @returns
     */
    const prepareLoadData = (data, limit) => {
        return data.slice(0, limit);
    };

    const availableOptions = useMemo(() => getAvailableOptions(importData, mappedHeaders), [importData, mappedHeaders]);
    const filterData = useMemo(() => prepareLoadData(importData.data, limit), [importData.data, limit]);
    return (
        <Grid container className="importList" alignItems={"center"} justifyContent="center">
            <Grid item xs={12} className={classes.importListBody}>
                <TableComponent
                    headers={mappedHeaders}
                    options={[]}
                    data={filterData ?? []}
                    subComponent
                    subComponentList={{ options: availableOptions }}
                    subComponentOnChange={onChangeColumnMapping}
                    styleType="striped"
                    setHeight
                    height="calc(100vh - 250px)"
                    NoResultText="No Data Found"
                    tableRef={tableRef}
                    stickyHeader
                />
            </Grid>
            <Grid item xs={12} className="importListAction" align="right">
                <LoaderButtonComponent
                    size={'small'}
                    isLoading={importLoading}
                    onClick={() => handleImport()}
                    className="mr-1"
                    disabled={importData?.headers?.length <= 0 || importData?.data?.length <= 0}
                >
                    Import
                </LoaderButtonComponent>
                <Button disableElevation variant="outlined" size="small" onClick={() => onClose()} className="ml-1">
                    Cancel
                </Button>
            </Grid>
        </Grid>

    );
}

// default props
ImportData.defaultProps = {
    classes: {},
    type: "Metadata",
    onClose: () => { }
};

// prop types
ImportData.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    onClose: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ImportData);