const style = (theme) => ({
    searchResultContainer: {
        padding: '16px 24px',
        boxShadow: '0px 1px 8px #cccccc59',
        background: '#fff',
        marginTop: '5px',
        position: 'absolute',
        width: '100%',
        borderRadius: '4px',
        zIndex: 9,
        top: 43
    },
    searchListItem: {
        display: 'flex',
        '& .searchListText': {
            marginLeft: 12
        },
        '& svg': {
            width: 20,
            height: 20
        },
        '& svg, svg path': {
            fill: theme.palette.greyshades.darkgrey
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20
        },
        '& .MuiListItemButton-root': {
            marginLeft: '-16px',
            marginRight: '-14px'
        },
        '& .MuiList-root': {
            paddingTop: '2px',
            paddingBottom: '2px'
        },
        '& .lisItem': {
            // padding: '4px 14px 4px 14px',
            '&:hover': {
                background: theme.palette.greyshades.lightbackground,
                '& .searchListText': {
                    color: theme.palette.secondary.main
                },
                '& .lisItemBtn': {
                background: theme.palette.greyshades.lightbackground
                }
            },
            '& .listIcon': {
                minWidth: 20
            }
        },
        '& .lisItemBtn': {
            padding: '6px 16px 6px 16px',
            marginLeft: '-16px',
            marginRight: '-16px',
            cursor: 'pointer'
        }
    }
});
export default style;