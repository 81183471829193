import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Grid, Typography, Tooltip } from '@mui/material';
import WidgetConfiguration from './components/widgetConfiguration/index.jsx';
import { ChartTypeIcon } from '../../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { updateProperties, clearReport } from '../../../../../redux/reducer/customWidgetReducer';


function CustomWidgetConfiguration(props) {
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const detail = useSelector(({ customWidget }) => customWidget.detail);

    /**
     * On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const requestParams = {
            [property]: value
        };
        dispatch(updateProperties(requestParams));
    };

    /**
     * Sense Axis
     * @param {*} data
     * @param {*} reversePlot
     * @param {*} isBreakDown
     * @returns
     */
    const senseAxis = (data, reversePlot = false, isBreakDown = false, widgetType = "vertical_bar") => {
        const levels = data.levels.filter((obj) => obj.name);
        let columns = [...data.xAxis, ...data.yAxis, ...levels, ...data.breakDown];
        const excludeChartTypes = [appConstants.dashboardWigetTypes.gauge, appConstants.dashboardWigetTypes.count, appConstants.dashboardWigetTypes.spider];
        if (excludeChartTypes.includes(widgetType)) {
            columns = columns.filter((column) => !column.exclude_charts.includes(widgetType));
        }
        const measure = columns.find((obj) => obj.type === "measure");
        let dimension = columns.find((obj) => obj.type === "dimension");
        if (widgetType === appConstants.dashboardWigetTypes.count && dimension?.datatype !== "date") {
            dimension = null;
        }
        const xAxis = [];
        const yAxis = [];
        let breakDown = [];
        if (measure) {
            const measureDetail = Object.assign({}, measure);
            if (measureDetail.datatype === "text" || measureDetail.datatype === "date") {
                measureDetail.query = measureDetail.aggregationColumn || `count(distinct(${measureDetail.column}))`;
                measureDetail.alias_name = `Distinct Count of ${measureDetail.name}`;
            }
            if (reversePlot) {
                xAxis.push({ ...measureDetail });
            } else {
                yAxis.push({ ...measureDetail });
            }
        }
        if (dimension) {
            if (reversePlot) {
                yAxis.push({ ...dimension });
            } else {
                xAxis.push({ ...dimension });
            }
        }
        if (data.breakDown.length && isBreakDown) {
            breakDown = data.breakDown;
        }
        return {
            xAxis: xAxis,
            yAxis: yAxis,
            breakDown: breakDown
        };

    };

    /**
     * On Change Chart
     * @param {*} chartType
     */
    const onChangeChart = (chartType) => {
        let properties = Object.assign({}, detail.properties);
        let modifyProperties = false;
        let levels = [];
        if ((detail.widget_type === appConstants.dashboardWigetTypes.hierachy || detail.widget_type === appConstants.dashboardWigetTypes.sun_burst) && properties?.levels?.length > 1) {
            levels = properties.levels.filter((level) => level.name);
        }
        switch (chartType) {
            case appConstants.dashboardWigetTypes.verticalBar:
            case appConstants.dashboardWigetTypes.horizontalBar:
                properties = {
                    ...properties,
                    ...senseAxis(properties, chartType === appConstants.dashboardWigetTypes.horizontalBar, true, chartType),
                    levels: [],
                    groupBy: []
                };
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.polar:
                properties = {
                    ...properties,
                    ...senseAxis(properties, false, false, chartType),
                    levels: [],
                    groupBy: []
                };
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.table:
                const tableLevels = [...properties.yAxis, ...properties.xAxis, ...properties.breakDown, ...levels];
                properties = {
                    ...properties,
                    levels: tableLevels.map((level) => { return { ...level, query: level.column, alias_name: level.name }; }),
                    xAxis: [],
                    yAxis: [],
                    breakDown: [],
                    groupBy: []
                };
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.line:
            case appConstants.dashboardWigetTypes.area:
                properties = {
                    ...properties,
                    ...senseAxis(properties, false, true, chartType),
                    levels: [],
                    groupBy: []
                };
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.hierachy:
                const columns = [...properties.yAxis, ...properties.xAxis, ...properties.breakDown, ...properties.levels].filter((data) => data.hierachy_table)
                    .map((data) => { return { ...data, type: "dimension" }; });
                properties = {
                    ...properties,
                    levels: [...columns, {}],
                    xAxis: [],
                    yAxis: [],
                    breakDown: [],
                    hideOrg: true,
                    groupBy: []
                };
                dispatch(clearReport());
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.sun_burst:
                const widgetLevel = properties.levels.filter((data) => Object.keys(data).length);
                const sunBurstcolumns = [...properties.yAxis, ...properties.xAxis, ...properties.breakDown, ...widgetLevel].filter((data) => !data.exclude_charts.includes(chartType))
                    .map((data) => { return { ...data, type: "dimension" }; });
                properties = {
                    ...properties,
                    levels: [...sunBurstcolumns],
                    xAxis: [],
                    yAxis: [],
                    breakDown: [],
                    groupBy: [],
                    hideOrg: true
                };
                dispatch(clearReport());
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.gauge:
            case appConstants.dashboardWigetTypes.spider:
            case appConstants.dashboardWigetTypes.count:
                properties = {
                    ...properties,
                    ...senseAxis(properties, false, false, chartType),
                    levels: [],
                    groupBy: []
                };
                dispatch(clearReport());
                modifyProperties = true;
                break;
            case appConstants.dashboardWigetTypes.heatmap:
                properties = {
                    ...properties,
                    ...senseAxis(properties, false, false, chartType),
                    levels: [],
                    groupBy: []
                };
                dispatch(clearReport());
                modifyProperties = true;
                break;
            default:
                break;
        }
        if (modifyProperties) {
            onChange("properties", properties);
        }
        onChange("widget_type", chartType);
    };
    return (
        <Grid className={classes.configurationContainer}>
            <Typography variant="h6" className="mb-1">
                Chart
            </Typography>
            <Grid container spacing={2} alignItems={"center"} className={classes.chartLits}>
                {
                    appConstants.chartOptions.map((item, index) =>
                        <Grid item xs={3} key={`chart_${index}`}>
                            <Tooltip title={item.name} arrow>
                                <Grid
                                    className={`${classes.chartItem} ${detail.widget_type === item.type ? "active" : ""}`}
                                    onClick={() => onChangeChart(item.type)}>
                                    <ChartTypeIcon chart_type={item.type} />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    )
                }
            </Grid>
            <Grid item>
                <WidgetConfiguration
                    type={detail.widget_type}
                    data={detail?.properties ?? {}}
                    filter_properties={detail?.filter_properties ?? {}}
                    onChange={(property, value) => onChange(property, value)} />
            </Grid>

        </Grid>
    );
}

// default props
CustomWidgetConfiguration.defaultProps = {
    classes: {}
};

// prop types
CustomWidgetConfiguration.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CustomWidgetConfiguration);