import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Tooltip } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import AddRuleStyle from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { QueryComponent, SwitchComponent } from '../../../../../../components/index.js';


function ConditionalScoring(props) {

    /**
     * Define Props
     */
    const { classes, measureProperties, editMeasure, propertyPermission, isDisabled, title, description, propertyName, isMeasureLevel } = props;

    const onUpdateProperties = useCallback((key, value) => {
        const properties = JSON.parse(JSON.stringify(measureProperties));
        const conditionalScoring = (properties && propertyName && properties[propertyName]) ? properties[propertyName] : {};
        conditionalScoring[key] = value;
        properties[propertyName] = { ...conditionalScoring };
        if (editMeasure) {
            editMeasure("properties", properties);
        }
    }, [measureProperties]);


    const propertyDetails = (measureProperties && propertyName && measureProperties[propertyName]) ? measureProperties[propertyName] : {};
    return (
        <Grid item xs={12} className={classes.thresholdContainer}>
            <Grid
                container
                alignItems={"center"}
                wrap="nowrap"
                className={`thresholdType`}>
                <Grid item>
                    <Tooltip title={description}>
                        <SwitchComponent size="small"
                            label={title}
                            checked={propertyDetails?.is_enabled}
                            handleChange={(value) => onUpdateProperties('is_enabled', value)}
                            disabled={isMeasureLevel ? (isMeasureLevel && propertyName === 'conditional_scoring') : (!propertyPermission?.is_edit || isDisabled)}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            {
                propertyDetails?.is_enabled &&
                <Grid className="mt-2" style={{ background: 'transparent' }}>
                    <QueryComponent
                        asset_id={measureProperties?.asset_id}
                        name="query"
                        data={propertyDetails?.query || ""}
                        onChange={onUpdateProperties}
                        disabled={!propertyPermission?.is_edit}
                        enableValidate
                        removeAggregateQuery
                        measureProperties={measureProperties}
                        connection_id={measureProperties?.connection_id}
                        isConditional
                        validateResult={propertyName !== 'conditional_scoring'}
                        isValidate
                        isMeasureLevel={isMeasureLevel}
                    />
                </Grid>
            }
        </Grid>
    );
}

// default props
ConditionalScoring.defaultProps = {
    classes: {},
    measureProperties: {},
    isDisabled: false,
    editMeasure: () => { },
    propertyPermission: { },
    title: "",
    description: "",
    propertyName: "",
    isMeasureLevel: false,
    isValidate: false
};

// prop types
ConditionalScoring.propTypes = {
    classes: PropTypes.object,
    measureProperties: PropTypes.object,
    isDisabled: PropTypes.bool,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    propertyName: PropTypes.string,
    isMeasureLevel: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.measureProperties, nextProps.measureProperties) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...AddRuleStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(ConditionalScoring, areEqual));