// import DasboardBg from '../../assets/svg/dasboardBg.svg';
const HomeStyle = (theme) => ({
    widgetBody: {
        marginTop: '15px !important'
    },
    widgetListIcon: {
        background: `${theme.palette.secondary.main}10`,
        width: '40px',
        height: '40px !important',
        flexShrink: 0,
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px !important'
    },
    dashboardCardsColumn: {
        padding: 16
    },
    personalizeidget: {
        display: 'flex',
        alignItems: "center",
        background: theme.palette.primary.contrastText,
        paddingRight: 20,
        '& .MuiButton-root': {
            whiteSpace: "nowrap",
            textTransform: 'capitalize'
        }
    },
    dashboardContainer: {
        '& .react-grid-item.react-grid-placeholder': {
            background: `${theme.palette.secondary.main}10`,
            opacity: '1 !important',
            border: `2px dashed ${theme.palette.secondary.main} !important`
        }
    }
});

export default HomeStyle;