// Import API Helper
import { getRequest, putRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * Get Mapped Tabs
 * @param {*} level
 * @returns
 */
async function list(level) {
    const response = await getRequest(`${urlConstants.fieldProperty.url}?level=${level}`);
    return response;
}

/**
 * Get Property Tab Values
 * @param {*} level
 * @returns
 */
async function getPropertyValues(params) {
    const response = await postRequest(urlConstants.fieldProperty.getProperty, params);
    return response;
}

/**
 * Create Field Property
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function create(params) {
    const response = await postRequest(urlConstants.fieldProperty.url, params);
    return response;
}

/**
 * Update Fields
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.fieldProperty.url}${id}/`, params);
    return response;
}

/**
 * Collapse Group
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function collapseGroup(id, params) {
    const response = await putRequest(`${urlConstants.fieldProperty.collapseGroup}${id}/`, params);
    return response;
}

/**
 * Order Group
 * @param {*} params
 * @returns
 */
async function orderGroup(params) {
    const response = await postRequest(urlConstants.fieldProperty.orderGroup, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const fieldPropertyService = {
    list, create, update, getPropertyValues, collapseGroup, orderGroup
};