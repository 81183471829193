const style = (theme) => ({
    issuesStatusHeader: {
        borderRadius: 4,
        // background: theme.palette.greyshades.lightbackground,
        marginBottom: 15,
        marginTop: '24px',
        '& .progress-step.incomplete .step-number': {
            background: '#F1F4F7',
            borderColor: theme.palette.greyshades.darkgrey,
            color: '#222'
        },
        '& .progress-step': {
            '& .step-numbertext': {
                border: `3px solid ${theme.palette.greyshades.darkgrey}`
            },
            '&.complete': {
                '& .step-numbertext': {
                    background: `${theme.palette.secondary.main} !important`,
                    border: `3px solid ${theme.palette.secondary.main}`
                }
            },
            '&.active': {
                '& .step-numbertext': {
                    border: `3px solid ${theme.palette.secondary.main}`
                }
            },
            '&:nth-child(1)': {
                alignItems: 'start',
                '& .lableName': {
                    textAlign: 'left'
                },
                '& .step-number': {
                    paddingLeft: '0px'
                },
                '&.complete:before': {
                    width: '150%'
                }
            },
            '&:nth-child(2)': {
                alignItems: 'center',
                '& .lableName': {
                    textAlign: 'center'
                },
                '&.complete:before': {
                    width: '200%'
                },
                '&.incomplete:before': {
                    width: '200%'
                }
            },
            '&:nth-child(3)': {
                alignItems: 'end',
                '& .lableName': {
                    textAlign: 'right'
                },
                '& .step-number': {
                    paddingRight: '0px'
                }
            }
        },
        '& .progress-step.active .step-number': {
            background: '#F1F4F7',
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main
        },
        '& .progress-step.complete .doneIcon': {
            fill: `${theme.palette.secondary.main} !important`
        },
        '& .progress-step.complete .step-number': {
            background: '#F1F4F7'
        },
        '& .progress-step.active .lableName': {
            color: theme.palette.secondary.main
        },
        '& .progress-step:before': {
            background: `${theme.palette.greyshades.darkgrey}20`
        },
        '& .progress-step.complete:before': {
            background: `${theme.palette.secondary.main} !important`
        },
        '& .progress-step.complete:after': {
            background: theme.palette.secondary.main
        },
        '& .progress-step.incomplete:after': {
            background: theme.palette.secondary.main
        }
    },
    statusBar: {
        padding: '8px 16px 10px',
        '&.active': {
            background: `${theme.palette.secondary.main}30`
        }
    },
    statusProgress: {
        backgroundColor: `${theme.palette.greyshades.lightbackground} !important`
    }
});
export default style;