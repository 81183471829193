/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Grid, IconButton, Typography, MenuItem, Rating } from '@mui/material';
import _ from 'lodash';
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

// Import Helpers
import { toRound, stringAvatar } from '../../../helpers/appHelpers.js';

//  Import Styles
import LayoutStyles from '../../../layouts/style.jsx';
import Style from './style.jsx';

// Import Components
import { PopOverComponent, RichTextEditorComponent } from '../../../components/index.js';
import ReplyComponent from './reply.jsx';

// Import Images
import { MoreIcon, PinIcon, PinIconGrey, ReplyIcon } from '../../../assets/svg/index.js';

function CommentsTimeLine(props) {

    /**
     * Define Props
     */
    const { classes, data, handlePinMessage, handleDeleteMessage, handleCreateMessage, attributes, users_list,
        permission, formFields, actions, singleLine } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showReplyEditor, setShowReplyEditor] = useState(false);
    const [editCommentState, setEditCommentState] = useState(false);
    const [editorCheck, setEditorCheck] = useState(false);

    /**
     * Handle Pop Over Open
     * @param {*} event
     */
    const onPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handle Pop Over Close
     */
    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    /**
     * Handle Conver to Html Event
     */
    const convertHtml = (content) => {
        const contentState = convertFromRaw(JSON.parse(content));
        const options = {
            entityStyleFn: (entity) => {
                const entityType = entity.get("type").toLowerCase();
                if (entityType === "mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "mention",
                        content: mention.id,
                        attributes: {
                            userid: mention.id
                        }
                    };
                }
                if (entityType === "#mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "asset",
                        attributes: {
                            userid: mention.userId
                        }
                    };
                }
            }
        };
        return stateToHTML(contentState, options);
    };

    /**
     * Handle Reply Message Event
     */
    const onReply = () => {
        setShowReplyEditor(!showReplyEditor);
        const element = document.getElementById("private");
        setTimeout(() => {
            const conversationElement = document.getElementById(`${data.id}`);
            if (element && conversationElement && !showReplyEditor) {
                element.scrollTop = (conversationElement.offsetTop + conversationElement.offsetHeight) - 350;
            }
        }, 100);
        formFields.showRating = true;
    };

    /**
     * Handle Form Submit Event
     * @param {*} thread_id
     */
    const handleSubmit = (formData) => {
        if (!formData.id) {
            formData.thread = data.id;
        } else {
            setEditCommentState(false);
        }
        handleCreateMessage(formData);
        setShowReplyEditor(false);
    };

    /**
     * Handle Edit Pop
     */
    const editComment = () => {
        setAnchorEl(null);
        if (showReplyEditor) {
            setEditorCheck(true);
            setShowReplyEditor(false);
        } else {
            setEditCommentState(true);
        }
        if (!data.thread && !data.issue) {
            formFields.showRating = false;
        }
    };

    /**
     * on cancel edit
     */
    const onCancelEdit = () => {
        setEditCommentState(false);
        setEditorCheck(false);
    };

    /**
     * Close Reply Editor
     */
    const closeReplyEditor = () => {
        setShowReplyEditor(false);
    };

    /**
     * Handle pin Message Event
     * @param {*} id
     * @param {*} is_pinned
     */
    const pinMsg = (id, is_pinned) => {
        const formData = {
            id: id,
            is_pinned: is_pinned
        };
        handlePinMessage(formData);
    };

    /**
     * Handle Comment Event
     * @param {*} id
     */
    const deleteComment = (id) => {
        setAnchorEl(null);
        handleDeleteMessage(id);
    };

    /**
     * use effect to handle multiple editor
     */
    useEffect(() => {
        if (editorCheck) {
            setEditCommentState(true);
        }
    }, [editorCheck]);

    /**
     * Calculate Average Rating for Topic based on Replies
     * @param {*} replies
     * @returns
     */
    const avgRating = (replies) => {
        if (replies && replies.length > 0) {
            const rating_arr = replies.filter((reply) => reply.rating > 0).map((filteredReply) => filteredReply.rating);
            return toRound(_.mean(rating_arr));
        }
        return 0;
    };

    /**
     * Find Links and make it hyperlink
     * @param {*} comment_text
     * @returns
     */
    const createLinkedComment = (comment_text) => {
        const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
        const new_comment = comment_text.replace(urlRegex, ((url) => {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        }));
        return new_comment;
    };

    /**
     * Set Ratings
     * @returns
     */
    const get_ratings = () => {
        if (singleLine || (data?.replies && data?.replies.length === 0)) {
            return data.rating || '0.0';
        }
        return avgRating(data.replies) || "0.0";
    };

    return (
        <Grid className="w-100" id={data.id}>
            <Grid className={`${classes.conversationConainer} ${data.priority?.toLowerCase() ?? ''}`}>
                {
                    editCommentState ?
                        <RichTextEditorComponent
                            key={data.id}
                            formSubmit={handleSubmit}
                            attributes={attributes}
                            users_list={users_list}
                            isCancel
                            current_data={data}
                            onCancel={onCancelEdit}
                            formFields={formFields}
                        />
                        :
                        <Grid>
                            <Grid justifyContent={"space-between"} container className={classes.conversationHeader}>
                                <Grid className="dflex alignCenter">
                                    {
                                        !singleLine &&
                                        <Typography variant="h6" className={`pb5 mr-2`}>
                                            {data.title}
                                        </Typography>
                                    }
                                    <Grid className="dflex alignCenter">
                                        <Grid item>
                                            <Rating className={`${classes.starIcon}`} name="half-rating-read" value={get_ratings()} precision={0.5} size="small" readOnly max={5} />
                                        </Grid>
                                        <Typography
                                            variant="body1"
                                            className="fw-500 pl5"
                                        >
                                            {get_ratings()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item className="dflex alignBaseline">
                                    {
                                        permission?.is_edit && actions.indexOf('reply') >= 0 &&
                                        <IconButton onClick={onReply} className="replyBtn p5">
                                            <ReplyIcon />
                                        </IconButton>
                                    }
                                    {
                                        actions.indexOf('pin') >= 0 &&
                                        <IconButton className="p5 ml5" onClick={() => permission?.is_edit && pinMsg(data.id, !data.is_pinned)}>
                                            {
                                                data.is_pinned ? <PinIcon />
                                                    : <PinIconGrey />
                                            }
                                        </IconButton>
                                    }
                                    {
                                        permission?.is_edit &&
                                        <IconButton className="p5" onClick={onPopoverOpen}>
                                            <MoreIcon />
                                        </IconButton>
                                    }
                                    <PopOverComponent anchorEl={anchorEl} onClose={onPopoverClose}>
                                        <Grid className={classes.popoverDiv}>
                                            {
                                                permission?.is_edit &&
                                                <MenuItem onClick={() => editComment(data.id)}>
                                                    Edit
                                                </MenuItem>
                                            }
                                            {
                                                permission?.is_edit &&
                                                <MenuItem onClick={() => deleteComment(data.id)}>
                                                    Delete
                                                </MenuItem>
                                            }
                                        </Grid>
                                    </PopOverComponent>
                                </Grid>
                            </Grid>
                            <Grid className={`${classes.contentContainer} ${singleLine ? 'singleLine' : ''}`}>
                                <Grid item className={`${classes.nameAvatar} nameAvatar`}>
                                    <Avatar src={data.created_by.avatar || null}
                                        {...stringAvatar(data.created_by.name)} />
                                    <Typography variant="body1" className={`${classes.nameText} ml-2`}>
                                        {`${data.created_by.name || ""}`}
                                    </Typography>
                                    <Typography variant="body1" className={`${classes.textSecondary} ml-3`}>
                                        {(moment(data.created_date).fromNow())}
                                    </Typography>
                                </Grid>
                                <Typography variant="body1 commentsDiv">
                                    <div className="commentText" dangerouslySetInnerHTML={{ __html: createLinkedComment(convertHtml(data.comment)) }} />
                                </Typography>
                            </Grid>
                        </Grid>
                }
                {
                    data.replies?.length > 0 &&
                    <Grid className="replyOverallContainer">
                        {
                            data.replies?.length > 0 &&
                            data.replies.map((reply, index) =>
                                <ReplyComponent reply={reply} key={index} handleDeleteMessage={handleDeleteMessage} attributes={attributes} users_list={users_list} handleCreateMessage={handleCreateMessage} permission={permission} />
                            )
                        }
                    </Grid>
                }
                {
                    showReplyEditor &&
                    <Grid className={classes.replyEditor}>
                        <RichTextEditorComponent
                            key={`${data.id}_reply`}
                            formSubmit={handleSubmit}
                            attributes={attributes}
                            users_list={users_list}
                            formFields={formFields}
                            isCancel
                            onCancel={closeReplyEditor}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>

    );
}

// default props
CommentsTimeLine.defaultProps = {
    classes: {},
    data: {},
    attributes: [],
    handlePinMessage: () => { },
    handleDeleteMessage: () => { },
    handleCreateMessage: () => { },
    users_list: [],
    permission: {},
    formFields: {},
    actions: [],
    singleLine: false
};

// prop types
CommentsTimeLine.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    attributes: PropTypes.array,
    handlePinMessage: PropTypes.func,
    handleDeleteMessage: PropTypes.func,
    handleCreateMessage: PropTypes.func,
    users_list: PropTypes.array,
    permission: PropTypes.object,
    formFields: PropTypes.object,
    actions: PropTypes.array,
    singleLine: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme),
        ...Style(theme)
    }),
    { withTheme: true }
)(React.memo(CommentsTimeLine, areEqual));