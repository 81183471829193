import palette from "../../assets/theme/palette";
const style = (theme) => ({
    qualityPageContainer: {
        padding: '24px 30px 0px',
        '& .btnTabsInline  .MuiTab-root': {
            marginRight: '1px',
            borderRadius: '0px'
        },
        '& .btnTabsInline.MuiTabs-root': {
            minHeight: '30px'
        }
    },
    ruleGreyContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: 24,
        marginTop: '15px !important'
    },
    tabHeaderContainer: {
        marginBottom: 10,
        '& .tabHeader': {
            borderBottom: 'none !important',
            justifyContent: 'end'
        },
        '& .tabsLink': {
            padding: '0px',
            marginRight: 1
        },
        '& .tabsLink .MuiTypography-h6': {
            padding: '3px 16px',
            fontSize: '15px',
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontWeight: '400 !important',
            background: palette.greyshades.lightgrey,
            '&.active': {
                border: 'none !important',
                background: palette.secondary.main,
                color: '#fff !important',
                padding: '3px 16px'
            }
        },
        '& .DownloadIcon': {
            width: '24px',
            height: '24px'
        }
    },
    rulesActions: {
        '& .UploadIcon, .DownloadIcon': {
            width: '26px',
            height: '26px'
        },
        '& .PlusIcon': {
            width: '24px',
            height: '24px'
        }
    },
    subMeasuresTable: {
        '& .MuiTableContainer-root': {
            height: 'calc(100vh - 100px)',
            marginBottom: '20px'
        }
    }
});
export default style;