const style = (theme) => ({
    card: {
        marginBottom: 5,
        marginRight: 8,
        '& img': {
            width: 45,
            height: 45
        },
        '& .MuiCardActionArea-root': {
            padding: 10
        },
        '& .selected': {
            border: `2px solid ${theme.palette.secondary.main}`
        }
    },
    selectContainer: {
        padding: 30,
        width: `550px !important`,
        '& .CloseIcon': {
            width: '30px',
            height: '30px'
        },
        '& .closeButton': {
            width: 32,
            height: 32,
            padding: 5
        },
        '& .MuiFormHelperText-root': {
            color: `#F38080`
        }
    },
    filter: {
        display: 'flex',
        '& .MuiToggleButton-root': {
            padding: '2px 10px',
            textTransform: 'unset',
            fontWeight: 500,
            lineHeight: 1
        },
        '& .MuiToggleButtonGroup-root': {
            background: '#fff'
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            },
            '& svg': {
                fill: '#fff'
            }
        }
    },
    toggleButton: {
        '& .deleteIcon': {
            position: 'absolute',
            right: '-10px',
            top: '-8px',
            background: '#fff',
            borderRadius: '30px',
            boxShadow: '0px 0px 5px #00000026',
            width: '15px',
            padding: '2px',
            height: '15px',
            visibility: 'hidden',
            opacity: 1,
            transition: '0.5s all',
            zIndex: 999,
            '& svg': {
                width: 14,
                height: 14
            }
        },
        '&:hover': {
            '& .deleteIcon': {
                visibility: 'visible',
                opacity: 1,
                cursor: 'pointer',
                '&:hover': {
                    fill: theme.palette.secondary.main
                },
                '&:hover svg': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    }
});
export default style;