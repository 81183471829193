import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment-timezone';

// Import Constants
import appConstants from '../../constants/appConstants.js';


/**
 * Set Scheduler Default Value
 */
const timeZones = [
    { name: moment.tz.guess() },
    ...appConstants.schedule.TimeZones
];
const default_schdule = {
    level: 'report',
    schedule_type: 'report',
    type: appConstants.schedule.scheduleTypes[2].name,
    duration: '1',
    start_date: moment().format('MM-DD-YYYY HH:mm'),
    configuration: {
        time: ''
    },
    multiTime: [],
    timezone: timeZones && timeZones.length > 0 ? timeZones[0].name : '',
    schedule_method: 'time'
};

const defaultDetail = {
    name: "",
    description: "",
    type: "Exception Report",
    subject: '',
    message: '',
    with_filters: true,
    recurring: false,
    format: "PDF",
    assets: [],
    columns: [],
    group_by: [],
    users: "",
    filters: {
        date_range: 'last_run',
        domains: [],
        applications: [],
        measures: [],
        assets: []
    },
    hierarchy_view: false,
    properties: {
        fit_to_width: false,
        oridentation: "portrait"
    },
    recipients: [],
    attach_as: ['csv'],
    schedule: { ...default_schdule }
};

const initialState = {
    isLoading: false,
    assetLoading: false,
    columnsLoading: false,
    previewLoading: false,
    saveLoading: false,
    assetsFilterLoading: false,
    reports: [],
    reports_assets: [],
    reports_columns: [],
    preiview_data: [],
    detail: { ...defaultDetail },
    errorMessage: "",
    assets_filter: [],
    closeWidgetReport: false,
    search: { sortBy: "created_date", orderBy: "desc", offset: 0, limit: 20, search_by: {} },
    loadMore: true
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState: { ...initialState },
    reducers: {
        updateProperties(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        clearState(state) {
            state.detail = { ...defaultDetail };
            state.reports_assets = [];
            state.reports_columns = [];
            state.preiview_data = [];
            state.errorMessage = "";
            state.closeWidgetReport = false;
        },
        getReportsAssetsRequest(state) {
            state.assetLoading = true;
        },
        getReportsAssetsSuccess(state, action) {
            state.reports_assets = action.payload.data || [];
            state.assetLoading = false;
        },
        getReportsAssetsFailure(state) {
            state.assetLoading = false;
        },
        getReportsColumnsRequest(state) {
            state.columnsLoading = true;
        },
        getReportsColumnsSuccess(state, action) {
            const attributes = action.payload?.data ?? [];
            const attributeList = Object.assign([], attributes);
            state.reports_columns = attributeList.sort();
            state.detail = {
                ...state.detail,
                columns: _.intersection(state.detail?.columns ?? [], state.reports_columns)
            };
            state.columnsLoading = false;
        },
        getReportsColumnsFailure(state) {
            state.columnsLoading = false;
        },
        getReportsPreviewDataRequest(state) {
            state.previewLoading = true;
        },
        getReportsPreviewDataSuccess(state, action) {
            state.preiview_data = action.payload.data || [];
            state.previewLoading = false;
        },
        getReportsPreviewDataFailure(state) {
            state.previewLoading = false;
        },
        getReportsRequest(state, action) {
            state.isLoading = true;
            const clear = action.payload?.clear ?? false;
            if (clear) {
                state.reports = [];
                state.loadMore = true;
            }
        },
        getReportsSuccess(state, action) {
            state.search = { ...state.search, ...action.payload.params };
            const reports = action.payload.data;
            if ((reports.length < state.search.limit)) {
                state.loadMore = false;
                state.reports = [...state.reports, ...reports];
            } else {
                state.reports = [...state.reports, ...reports];
            }
            state.isLoading = false;
        },
        getReportsFailure(state) {
            state.isLoading = false;
        },
        getReportByIdRequest(state) {
            state.isLoading = true;
        },
        getReportByIdSuccess(state, action) {
            state.detail = action.payload.data;
            if (!state.detail?.schedule) {
                state.detail = {
                    ...state.detail,
                    "schedule": default_schdule
                };
            }
            state.isLoading = false;
        },
        getReportByIdFailure(state) {
            state.isLoading = false;
        },
        createReportRequest(state) {
            state.saveLoading = true;
        },
        createReportSuccess(state) {
            state.saveLoading = false;
        },
        createReportFailure(state) {
            state.saveLoading = false;
        },
        updateReportRequest(state) {
            state.saveLoading = true;
        },
        updateReportSuccess(state) {
            state.saveLoading = false;
        },
        updateReportFailure(state) {
            state.saveLoading = false;
        },
        deleteReportRequest(state) {
            state.isLoading = true;
        },
        deleteReportSuccess(state, action) {
            state.reports = state.reports.filter((i) => i.id !== action.payload);
            state.isLoading = false;
        },
        deleteReportFailure(state) {
            state.isLoading = false;
        },
        resetReportStateValues(state, action) {
            const type = action.payload;
            switch (type) {
                case "report_columns":
                    state.reports_columns = [];
                    state.detail = {
                        ...state.detail,
                        columns: []
                    };
                    break;
                default:
                    break;
            }
        },
        getReportsRunStatusRequest() { },
        getReportsRunStatusSuccess(state, action) {
            const runningReports = action.payload.data || [];
            let reports = state.reports;
            if (runningReports?.length > 0) {
                for (const checkreports of runningReports) {
                    const index = reports.findIndex((item) => item.id === checkreports.id);
                    if (index <= -1) {
                        continue;
                    }
                    const selectedreports = { ...reports[index] };
                    selectedreports.run_status = checkreports.status;
                    reports.splice(index, 1, { ...selectedreports });
                    if (state.detail?.id === checkreports.id) {
                        state.detail = { ...state.detail, run_status: checkreports.status };
                    }
                }
            } else {
                reports = reports.map((checkreports) => {
                    return {
                        ...checkreports,
                        run_status: "Completed"
                    };
                });
            }
            state.reports = [...reports];
        },
        getReportsRunStatusFailure() { },
        getReportsAssetsFilterRequest(state) {
            state.assetsFilterLoading = true;
        },
        getReportsAssetsFilterSuccess(state, action) {
            state.assets_filter = action.payload.data || [];
            state.assetsFilterLoading = false;
        },
        getReportsAssetsFilterFailure(state) {
            state.assetsFilterLoading = false;
        },
        dashboardReportRequest(state) {
            state.saveLoading = true;
            state.closeWidgetReport = false;
        },
        dashboardReportSuccess(state) {
            state.saveLoading = false;
            state.closeWidgetReport = true;
        },
        dashboardReportFailure(state) {
            state.saveLoading = false;
            state.closeWidgetReport = false;
        }
    }
});

export const { updateProperties, clearState, getReportsAssetsRequest, getReportsAssetsSuccess, getReportsAssetsFailure, getReportsColumnsRequest,
    getReportsColumnsSuccess, getReportsColumnsFailure, getReportsRequest, getReportsSuccess, getReportsFailure, getReportByIdRequest, getReportByIdSuccess,
    getReportByIdFailure, createReportRequest, createReportSuccess, createReportFailure, updateReportRequest, updateReportSuccess, updateReportFailure,
    deleteReportRequest, deleteReportSuccess, deleteReportFailure, getReportsPreviewDataRequest, getReportsPreviewDataSuccess, getReportsPreviewDataFailure,
    resetReportStateValues, getReportsRunStatusRequest, getReportsRunStatusSuccess, getReportsRunStatusFailure, getReportsAssetsFilterRequest, getReportsAssetsFilterSuccess,
    getReportsAssetsFilterFailure, dashboardReportRequest, dashboardReportSuccess, dashboardReportFailure
} = reportsSlice.actions;
export default reportsSlice.reducer;