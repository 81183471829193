import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { CircularScoreComponent } from '../../../../../components/index.js';

// Import Images

function IssuesMetric(props) {

    const { data, classes } = props;

    /**
     * Get Percentage
     * @returns
     */
    const getPercentage = () => {
        const percentage = (data.resolved / (data.new + data.inprogress + data.resolved)) * 100;
        return percentage ? percentage.toFixed(1) : 0;
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item className="pl-2">
                    <Grid>
                        <Typography variant="h2">
                            {(data.new + data.inprogress + data.resolved) || 0}
                        </Typography>
                        <Typography variant="body1">
                            Total
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid>
                        <Typography variant="h2">
                            {data.new || 0}
                        </Typography>
                        <Typography variant="body1">
                            New
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid>
                        <Typography variant="h2">
                            {data.inprogress || 0}
                        </Typography>
                        <Typography variant="body1">
                            Inprogress
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid>
                        <Typography variant="h2">
                            {data.resolved || 0}
                        </Typography>
                        <Typography variant="body1">
                            Resolved
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className="relative">
                    <Grid className={classes.chartContainer}>
                        <CircularScoreComponent
                            size={100}
                            thickness={10}
                            value={getPercentage() || 0}
                            showPercentage
                            noLink
                            showValue
                            color="#E2A03F" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
IssuesMetric.defaultProps = {
    data: {}
};

// prop types
IssuesMetric.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(IssuesMetric);