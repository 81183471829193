import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

//  Import Components
import { TableComponent } from '../../components/index.js';

//  Import Styles
import ExecutionStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Constants
import appConstants from '../../constants/appConstants.js';

// Import Images

function Execution(props) {
    const { classes } = props;

    return (
        <Grid container className={classes.executionPageContainer}>
            <Grid item xs={12} className="mt-1">
                <Typography variant="h5" className="fw-500 pb5">
                    {appConstants.labels.executionLogs.errorLogging}
                </Typography>
                <TableComponent
                    styleType="striped"
                    headers={appConstants.table.ExecutionLogsTabHeader}
                    NoResultText="No Logs Found"
                    /*
                     * data={Object.keys(data)}
                     * tableData={data}
                     * onCompnentEvent={onCompnentEvent}
                     * onHandleChipDelete={onHandleChipDelete}
                     * onHandleChipSelect={onHandleChipSelect}
                     */
                    stickyHeader
                />
            </Grid>
            <Grid item xs={12} className="mt-3">
                <Typography variant="h5" className="fw-500 pb5">
                    {appConstants.labels.executionLogs.infoLogging}
                </Typography>
                <TableComponent
                    styleType="striped"
                    headers={appConstants.table.ExecutionLogsTabHeader}
                    NoResultText="No Logs Found"
                    /*
                     * data={Object.keys(data)}
                     * tableData={data}
                     * onCompnentEvent={onCompnentEvent}
                     * onHandleChipDelete={onHandleChipDelete}
                     * onHandleChipSelect={onHandleChipSelect}
                     */
                    stickyHeader
                />
            </Grid>
            <Grid item xs={12} className="mt-3">
                <Typography variant="h5" className="fw-500 pb5">
                    {appConstants.labels.executionLogs.libraryErrorLogging}
                </Typography>
                <TableComponent
                    styleType="striped"
                    headers={appConstants.table.ExecutionLogsTabHeader}
                    NoResultText="No Logs Found"
                    /*
                     * data={Object.keys(data)}
                     * tableData={data}
                     * onCompnentEvent={onCompnentEvent}
                     * onHandleChipDelete={onHandleChipDelete}
                     * onHandleChipSelect={onHandleChipSelect}
                     */
                    stickyHeader
                />
            </Grid>
        </Grid>
    );
}

// default props
Execution.defaultProps = {
    classes: {}
};

// prop types
Execution.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ExecutionStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Execution);