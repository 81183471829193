import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

import { getVersionText } from '../../helpers';

const initialState = {
    isLoading: false,
    list: [],
    selectedUser: {},
    logs: [],
    is_queue: false
};

const userActivitySlice = createSlice({
    name: 'userActivity',
    initialState: { ...initialState },
    reducers: {
        getUserActivityRequest(state) {
            state.isLoading = true;
        },
        getUserActivitySuccess(state, action) {
            state.isLoading = false;
            state.list = action.payload.data || [];
            state.list = state.list.map((obj) => {
                const sessions = obj.sessions.map((session) => {
                    return {
                        ...session,
                        start_time: moment(session.session_start_time).format("MM/DD/YYYY hh:mm A"),
                        end_time: session.session_end_time ? moment(session.session_end_time).format("MM/DD/YYYY hh:mm A") : "NA"
                    };
                });
                return {
                    ...obj,
                    last_login: obj.last_login ? moment(obj.last_login).format("MM/DD/YYYY hh:mm A") : "NA",
                    sessions
                };
            });
        },
        getUserActivityFailure(state) {
            state.isLoading = false;
        },
        getUserActivityJobsRequest(state) {
            state.isLoading = true;
        },
        getUserActivityJobsSuccess(state, action) {
            state.isLoading = false;
            state.is_queue = action.payload.data.data || "Completed";
        },
        getUserActivityJobsFailure(state) {
            state.isLoading = false;
        },
        selectedUser(state, action) {
            state.selectedUser = action.payload;
        },
        getUserActivityLogsRequest(state) {
            state.isLoading = true;
        },
        getUserActivityLogsSuccess(state, action) {
            state.isLoading = false;
            state.logs = action.payload.data || [];
            state.logs = state.logs.map((obj) => {
                const auditText = getVersionText(obj, true);
                return {
                    ...obj,
                    created_date: moment(obj.created_date).fromNow(),
                    audit_text: auditText.replace("by", "")
                };
            });
        },
        getUserActivityLogsFailure(state) {
            state.isLoading = false;
        },
        collapseItem(state, action) {
            const index = state.list.findIndex((obj) => obj.user_id === action.payload.id);
            if (index > -1) {
                state.list[index].isOpen = action.payload.isOpen;
            }
        }
    }
});

export const { getUserActivityRequest, getUserActivitySuccess, getUserActivityFailure, selectedUser, getUserActivityLogsRequest,
    getUserActivityLogsSuccess, getUserActivityLogsFailure, collapseItem, getUserActivityJobsRequest, getUserActivityJobsSuccess,
    getUserActivityJobsFailure
} = userActivitySlice.actions;
export default userActivitySlice.reducer;