import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { AssetTypeIconComponent, DialogComponent, ListUserComponent, TextBoxComponent } from '../../../../components/index';
import AssetShare from '../share/index.jsx';
import IssueStatus from '../issueStatus/index.jsx';


//import Styles
import HeaderStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { CloseIcon, DeleteIcon, EyeIcon2, EyeIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { deleteIssuesRequest, updateWatchRequest } from '../../../../redux/reducer/issuesReducer';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

function Header(props) {
    /**
     * Define Props
     */
    const { classes, data, handleIssueClose, handleOnChange, takeScreenshot, issuePermission } = props;
    const dispatch = useDispatch();
    /**
     * Define State
     */
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: ''
    });

    const { detail } = useSelector((state) => state.issues);

    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.issueDeleteMessage,
            data
        });
    };


    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteIssuesRequest({ id: data.id, type: (type === "purge") }));
        dialogCancelEventHandle();
        handleIssueClose();
    };


    /**
     * Add and Delete Watch
     */
    const addWatch = () => {
        if (data.id) {
            dispatch(updateWatchRequest({ "issue": data.id }));
        }
    };

    return (
        <Grid item xs={12} className={classes.headerContainer}>
            <Grid item xs={12} className={classes.headerContainerInner}>
                <Grid className="dflex spaceBetween mb-1">
                    <Grid className="dflex alignCenter flexOne">
                        <Grid className={classes.issueNumber}>
                            {data.issue_id}
                        </Grid>
                        <Grid className="flexOne">
                            <Grid className="dflex alignCenter spaceBetween">
                                <TextBoxComponent
                                    name="name"
                                    placeholder="Enter a name"
                                    value={data.name || ""}
                                    onChange={(event) => handleOnChange("name", event.target.value)}
                                    variant="standard"
                                    fullWidth
                                    noOutline
                                    disabled={!issuePermission?.is_edit}
                                />
                                <Grid className="dflex alignCenter pl-3">
                                    {
                                        issuePermission?.is_edit &&
                                        <Tooltip arrow title="Watch">
                                            <IconButton onClick={() => addWatch()} disabled={!data.issue_id}>
                                                {
                                                    data.is_watch ? <EyeIcon /> :
                                                        <EyeIcon2 />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <AssetShare data={{ name: data.issue_id, issue_id: data.id, status: data.status, priority: data.priority, description: data.description }} takeScreenshot={takeScreenshot} />
                                    {
                                        issuePermission?.is_edit &&
                                        <Tooltip arrow title="Delete">
                                            <IconButton onClick={() => onDelete()} disabled={!data.issue_id}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <Tooltip arrow title="Close">
                                        <IconButton onClick={() => handleIssueClose()} disabled={!data.issue_id}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid className="dflex alignCenter spaceBetween pt-1">
                                <Grid className={`${classes.datasetName}`}>
                                    <AssetTypeIconComponent asset_type={data.asset_name ? "asset" : "query"} />
                                    {
                                        data.asset_name &&
                                        <Typography variant="body1" className="pl-1 catalog-cardTitle fs-16">
                                            {`${data.attribute_name ? data.asset_name + ' / ' + data.attribute_name : data.asset_name}`}
                                        </Typography>
                                    }
                                    {
                                        data.measure_name &&
                                        <Typography variant="body1" className="pl-1 catalog-cardTitle fs-16">
                                            {`${data.measure_name}`}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item className="dflex alignCenter">
                                    <Typography variant="body1" className="mr-3">
                                        {`Created on ${moment(data.created_date).calendar()}`}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        Last Updated
                                    </Typography>
                                    <Grid item className="dflex alignCenter ml-1 avatarList">
                                        <ListUserComponent user={data.updated_by || data.created_by} />
                                    </Grid>
                                    <Typography variant="body1" className="pl-1">
                                        {moment(data.modified_date || data.created_date).calendar()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid />
                </Grid>
                {
                    showDialog.open &&
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle} />
                }
                <IssueStatus status={detail.status} />
            </Grid>
        </Grid>
    );
}

// default props
Header.defaultProps = {
    classes: {},
    data: {},
    handleIssueClose: () => { },
    handleOnChange: () => { },
    takeScreenshot: () => { },
    issuePermission: {}
};

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleIssueClose: PropTypes.func,
    handleOnChange: PropTypes.func,
    takeScreenshot: PropTypes.func,
    issuePermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...HeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Header);