const style = (theme) => ({
    card: {
        padding: '10px 15px 14px',
        border: `1px solid #E5E7EB82`,
        textAlign: 'center',
        background: `${theme.palette.secondary.main}15`,
        boxShadow: '0px 0px 10px #0000000D',
        height: '100%',
        position: 'relative',
        '&.deactive': {
            background: '#f5f5f5',
            '& .connectionCardHeader': {
                filter: 'grayscale(1)'
            },
            '& .cardImg, img': {
                filter: 'grayscale(1)'
            },
            '& .connectorCardImg': {
                filter: 'grayscale(1)'
            },
            '& .MuiTypography-root': {
                color: `${theme.palette.greyshades.darkgrey} !important`
            }
        },
        '&.card': {
            padding: '30px 24px !important',
            background: `${theme.palette.primary.contrastText}`,
            cursor: 'pointer',
            // height: 190,
            '&:hover': {
                outline: `2px solid ${theme.palette.secondary.main}`,
                background: 'rgb(244 246 252 / 50%);'
            }
        }
    },
    deleteIcon: {
        '&:hover .DeleteIcon': {
            '& path': {
                fill: theme.palette.secondary.main,
                stroke: theme.palette.secondary.main
            }
        }
    },
    cardDesc: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        height: '45.5px',
        marginBottom: '10px !important'
    },
    imgContainer: {
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '16px',
        paddingTop: 30,
        '& .cardImg': {
            height: 35,
            maxWidth: '100%',
            objectFit: 'contain'
        }
    },
    actions: {
        position: 'absolute !important',
        left: 0,
        top: 0,
        padding: '6px !important'
    }
});
export default style;