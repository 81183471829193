import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Values from 'values.js';

//  Import Components
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from '@mui/material';
import DropBox from '../../../../DropBox/index.jsx';
import SelectedFields from '../../../../SelectedFields/index.jsx';

//  Import Styles
import Style from '../style.jsx';
import LayoutStyles from '../../../../../../../../layouts/style.jsx';
import { NumberInputComponent, SelectComponent, TextBoxComponent } from '../../../../../../../../components/index.js';
import appConstants from '../../../../../../../../constants/appConstants.js';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const defaultColor = "#CA7D7D";

function HeatMapConfiguration(props) {
    const { classes, widgetType, data, onChange } = props;

    const [opeEditItem, setOpenEditItem] = useState(false);
    const [editItem, setEditItem] = useState({
        name: '',
        aggregation: '',
        show_heatmap: false,
        color: '#CA7D7D',
        step_count: 5,
        color_range: []
    });
    const [colorShades, setColorShades] = useState([]);
    const getColors = (color) => {
        const colors = new Values(color).shades(5);
        setColorShades(colors);
    };

    useEffect(() => {
        getColors(editItem?.color || defaultColor);
    }, [editItem?.color]);

    /**
     * Get Detail
     * @param {*} item
     * @param {*} type
     * @returns
     */
    const getDetail = (item, type) => {
        if (type === 'groupBy') {
            let query = item.column;
            if (item.aggregation !== "none") {
                if (item.aggregation === 'distinct') {
                    query = `count(${item.aggregation} ${item.column})`;
                } else {
                    if (item.datatype === 'integer') {
                        if (item.aggregation === 'median') {
                            query = `PERCENTILE_CONT(0.5) WITHIN GROUP (ORDER BY ${item.column}::Decimal)`;
                        } else {
                            query = `${item.aggregation}(${item.column}::Decimal)`;
                        }
                    } else {
                        query = `${item.aggregation}(${item.column})`;
                    }
                }
            }
            item.query = query;
            item.alias_name = item.name;
            item.type = "measure";
        } else if (item?.name === "Run Date") {
            item.column = item.column.replace('date(scores.created_date)', 'metrics.created_date');
            item.table = 'metrics';
            item.table_name = 'metrics';
            item.standaloneColumn = item.column;
            item.query = item.column;
            item.alias_name = item.name;
            item.type = "dimension";
        } else {
            item.query = item.column;
            item.alias_name = item.name;
            item.type = item.datatype === "text" || item.datatype === "date" || item.isHeatmapSupport ? "dimension" : "measure";
        }
        return item;
    };

    /**
     * OnDrop
     * @param {*} dropType
     * @param {*} item
     */
    const onDrop = (dropType, item) => {
        item = { ...getDetail(item, dropType) };
        const properties = Object.assign({}, { ...data });
        const items = Object.assign([], properties[dropType] || []);
        if (!items.some((elem) => elem.name === item.name)) {
            items.push({ ...item });
            properties[dropType] = items;
            onChange(properties);
        }
    };

    /**
     * On Drop Replace Item
     * @param {*} item
     * @param {*} index
     */
    const onDropReplace = (dropType, item, index) => {
        item = { ...getDetail(item, dropType) };
        const properties = Object.assign({}, { ...data });
        const items = Object.assign([], properties[dropType] || []);
        const sortingIndex = items.findIndex((elem) => elem.name === item.name);
        if (sortingIndex !== -1) {
            items[sortingIndex] = items[index];
        }
        items[index] = { ...item };
        properties[dropType] = items;
        onChange(properties);
    };

    /**
     * On Delete Item
     * @param {*} dropType
     */
    const onDeleteItem = (dropType, index) => {
        const properties = Object.assign({}, { ...data });
        const items = Object.assign([], properties[dropType] || []);
        items.splice(index, 1);
        properties[dropType] = items;
        onChange(properties);
    };

    const saveEditField = () => {
        if (editItem?.step_count <= 12) {
            const properties = Object.assign({}, { ...data });
            let item = Object.assign([], properties.groupBy || [])[0];
            item = {
                ...item,
                aggregation: editItem?.aggregation,
                show_heatmap: editItem?.show_heatmap,
                color: editItem?.color,
                step_count: editItem?.step_count,
                color_range: colorShades.map((elem) => elem?.hexString())
            };
            properties.groupBy = [{ ...getDetail(item, 'groupBy') }];
            onChange(properties);
            setOpenEditItem(false);
        }
    };

    return (
        <Grid className={classes.dragContainer}>
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    X-Axis
                </Typography>
            </Grid>
            <SelectedFields data={data?.xAxis ?? []}
                onDelete={(index) => onDeleteItem("xAxis", index)}
                accept={["measure", "dimension", "sorting"]}
                onDrop={(item, index) => onDropReplace("xAxis", item, index)}
                widgetType={widgetType}
                sorting
            />
            {
                data.xAxis.length < 4 &&
                <DropBox accept={["dimension"]} onDrop={(item) => onDrop("xAxis", item)} />
            }
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    Y-Axis
                </Typography>
            </Grid>
            <SelectedFields
                data={data?.yAxis ?? []}
                onDelete={(index) => onDeleteItem("yAxis", index)}
                accept={["dimension", "sorting"]}
                onDrop={(item, index) => onDropReplace("yAxis", item, index)}
                widgetType={widgetType}
                sorting
            />
            {
                data.yAxis.length < 4 &&
                <DropBox accept={widgetType === "vertical_bar" ? ["measure", "dimension"] : ["dimension"]} onDrop={(item) => onDrop("yAxis", item)} />
            }
            <Grid item className={classes.dargField}>
                <Typography variant="h6" className="pb5">
                    Group by
                </Typography>
            </Grid>
            <SelectedFields
                widgetType={widgetType}
                data={data?.groupBy ?? []}
                onDelete={() => onDeleteItem("groupBy")}
                accept={["measure"]}
                onDrop={(item, index) => onDropReplace("groupBy", item, index)}
                onEdit={
                    (item) => {
                        setOpenEditItem(true);
                        setEditItem({ ...editItem, ...item });
                    }
                }
            />
            {
                !data?.groupBy?.length &&
                <DropBox accept={["measure"]} onDrop={(item) => onDrop("groupBy", item)} />
            }
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={opeEditItem}
            >
                <DialogTitle>
                    Edit Field
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Attribute
                            </Typography>
                            <TextBoxComponent
                                // label="Widget Name"
                                variant="outlined"
                                className="mt-1"
                                value={editItem?.name || ''}
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.requriedField]}
                                valOnChange
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ paddingBottom: '10px' }}>
                                Aggregation
                            </Typography>
                            <SelectComponent
                                value={editItem?.aggregation || ""}
                                onSelectChange={(value) => { setEditItem({ ...editItem, aggregation: value }); }}
                                variant="outlined"
                                list={appConstants.aggregationList.filter((elem) => (elem.type === 'any' || (elem.type === 'integer' && editItem.datatype === 'integer')))}
                                name="dashboard"
                                propertyName="value"
                                displayPropertyName="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                size="small"
                                onChange={(event) => { setEditItem({ ...editItem, show_heatmap: event.target.checked }); }}
                                control={<Checkbox defaultChecked={false} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label="Show HeatMap"
                                checked={editItem?.show_heatmap}
                            />
                        </Grid>
                        {
                            editItem?.show_heatmap &&
                            <Fragment>
                                <Grid item xs={10} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {
                                        colorShades.slice(0, editItem?.step_count || 5).map((color, index) => (
                                            <div key={index} style={{ backgroundColor: color.hexString(), padding: 12 }} />
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <input type="color" value={editItem?.color || defaultColor} onChange={(event) => { setEditItem({ ...editItem, color: event.target.value }); }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ paddingBottom: '10px' }}>
                                        Steps
                                    </Typography>
                                    <NumberInputComponent
                                        variant="outlined"
                                        onChange={(value) => { setEditItem({ ...editItem, step_count: Number(value) }); }}
                                        value={editItem?.step_count || 5}
                                        validators={['maxNumber:12']}
                                        errorMessages={['Max weightage is 12']}
                                    />
                                </Grid>
                            </Fragment>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenEditItem(false); }}>
                        Cancel
                    </Button>
                    <Button onClick={() => { saveEditField(); }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    );
}

// default props
HeatMapConfiguration.defaultProps = {
    classes: {},
    widgetType: "heatmap",
    data: {},
    onChange: () => { }
};

// prop types
HeatMapConfiguration.propTypes = {
    classes: PropTypes.object,
    widgetType: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(HeatMapConfiguration);