const style = (theme) => ({
    domainCardContainer: {
        marginTop: '-5px !important',
        paddingBottom: 10
    },
    domainCard: {
        boxShadow: '0px 3px 8px #0000001a',
        borderRadius: '6px',
        padding: '16px 20px 18px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        height: '100%',
        '& .descriptionText': {
            display: '-webkit-box',
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .detail .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .DeleteIcon': {
            width: 20,
            height: 19
        }
    },
    detail: {
        textAlign: 'center',
        marginTop: '2px !important'
    },
    imgContainer: {
        marginBottom: 5,
        '& img, svg': {
            height: 40
        }
    },
    title: {
        fontSize: '18px !important',
        marginBottom: '12px !important',
        display: 'inline-flex'
    },
    domainImgInput: {
        display: 'none'
    },
    addtitle: {
        width: 'calc(100% - 45px)',
        marginBottom: 12,
        '& .MuiInput-input': {
            fontFamily: `${theme.palette.textFont} !important`,
            fontSize: '20px !important',
            fontWeight: 400,
            textAlign: 'center'
        }
    },
    description: {
        '& .MuiInput-input': {
            fontFamily: theme.palette.textFont,
            fontSize: '15px !important',
            fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: `${theme.palette.greyshades.darkgrey} !important`,
    textAlign: 'center'
        }
    }
});
export default style;