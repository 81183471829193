/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Avatar, Grid, IconButton, Rating, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
// Import Helpers
import { checkPermission, toRound } from '../../../../../helpers/appHelpers.js';

//  Import Styles
import FeatureCardStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Images
import { PinIcon, PinIconGrey } from '../../../../../assets/svg/index.js';
import featureConstants from '../../../../../constants/featureConstants.js';

function FeatureCard(props) {
    const { classes, items, handlePinMessage, permission } = props;

    const conversationPermission = checkPermission(permission, featureConstants.assets.conversations);

    /**
     * Handle Conver to Html Event
     */
    const convertHtml = (content) => {
        const contentState = convertFromRaw(JSON.parse(content));
        const options = {
            entityStyleFn: (entity) => {
                const entityType = entity.get("type").toLowerCase();
                if (entityType === "mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "mention",
                        content: mention.id,
                        attributes: {
                            userid: mention.id
                        }
                    };
                }
                if (entityType === "#mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "asset",
                        attributes: {
                            userid: mention.userId
                        }
                    };
                }
            }
        };
        return stateToHTML(contentState, options);
    };

    /**
     * Handle pin Message Event
     * @param {*} id
     * @param {*} is_pinned
     */
    const pinMsg = (event, id, is_pinned) => {
        event.preventDefault();
        const formData = {
            id: id,
            is_pinned: is_pinned,
            isFeaturedUpdate: true
        };
        handlePinMessage(formData);
    };

    /**
     * Calculate Average Rating for Topic based on Replies
     * @param {*} replies
     * @returns
     */
    const avgRating = (replies) => {
        if (replies && replies.length > 0) {
            const rating_arr = replies.filter((reply) => reply.rating > 0).map((filteredReply) => filteredReply.rating);
            return toRound(_.mean(rating_arr));
        }
        return 0;
    };

    return (
        <Grid>
            {
                items &&
                items.length > 0 &&
                items.map((item, index) => (
                    <Grid
                        container
                        className={classes.featureCardContainer}
                        key={index}
                        component={Link}
                        to={`conversation/${item.id}`}
                    >
                        <Grid item xs={12} className="mb-1">
                            <Grid
                                container
                                alignItems={'flex-start'}
                                justifyContent="space-between"
                            >
                                <Grid item className="dflex alignCenter">
                                    <Grid item>
                                        <Typography variant="h6" className={`pb5`}>
                                            {item.title}
                                        </Typography>
                                        <Grid className="dflex alignCenter">
                                            <Grid item>
                                                <Rating className={`${classes.starIcon} pb5`} name="half-rating-read" value={avgRating(item.replies)} precision={0.5} size="small" readOnly max={5} />
                                            </Grid>
                                            <Typography
                                                variant="body1"
                                                className="fw-500 pl5"
                                            >
                                                {avgRating(item.replies) || "0.0"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className="dflex alignCenter">
                                    <IconButton className="p5 ml5" onClick={(event) => (conversationPermission?.is_edit ? pinMsg(event, item.id, !item.is_pinned) : null)}>
                                        {
                                            item.is_pinned ? <PinIcon />
                                                : <PinIconGrey />
                                        }
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item className="dflex alignCenter">
                                <Avatar src={item.created_by.avatar || null} alt={item.created_byname} />
                                <Grid item className="pl-15 dflex alignCenter">
                                    <Typography variant="body1" className="fs-16">
                                        {`${item.created_by.name || ""}`}
                                    </Typography>
                                    <Typography variant="body1" className={`${classes.textSecondary} ml-3`}>
                                        {(moment(item.created_date).fromNow())}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <div className="commentText" dangerouslySetInnerHTML={{ __html: convertHtml(item.comment) }} />
                            </Typography>
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    );
}

// default props
FeatureCard.defaultProps = {
    classes: {},
    items: [],
    handlePinMessage: () => { },
    permission: []
};

// prop types
FeatureCard.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.array,
    handlePinMessage: PropTypes.func,
    permission: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.items, nextProps.items) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...FeatureCardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(FeatureCard, areEqual));