import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    loginRequest, loginSuccess, loginFailure, logoutSuccess, logoutRequest, logoutFailure, forgotPasswordRequest, forgotPasswordSuccess,
    forgotPasswordFailure, resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, getUserPermissionRequest,
    getUserPermissionSuccess, getUserPermissionFailure, changePasswordRequest, changePasswordSuccess, changePasswordFailure, ssoValidateRequest, ssoValidateSuccess, ssoValidateFailure
} from '../reducer/authReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Constants
import appConstants from "../../constants/appConstants";

// Import Services
import { authService } from '../service';


/**
 * Login Actions
 * @param {*} action$
 * @returns
 */
const login = (action$) => action$.pipe(
    ofType(loginRequest),
    mergeMap(({ payload }) => {
        return from(authService.login(payload)).pipe(
            concatMap((res) => {
                if (payload.isExtension) {
                    return of(loginSuccess(res), navigate({ path: 'extension.tableau', state: {} }));
                }
                return of(loginSuccess(res), navigate({ path: 'home.root', state: {} }));
            }),
            catchError((error) => {
                return of(loginFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * SSO Validate and Get User Info Actions
 * @param {*} action$
 * @returns
 */
const get_user_info = (action$) => action$.pipe(
    ofType(ssoValidateRequest),
    mergeMap(({ payload }) => {
        return from(authService.get_user_info(payload)).pipe(
            concatMap((res) => {
                return of(ssoValidateSuccess(res), navigate({ path: 'home.root', state: {} }));
            }),
            catchError((error) => {
                return of(ssoValidateFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);


/**
 * Forgot Password Actions
 * @param {*} action$
 * @returns
 */
const forgotPassword = (action$) => action$.pipe(
    ofType(forgotPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.forgotPassword(payload)).pipe(
            concatMap((res) => of(forgotPasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.mail }))),
            catchError((error) => of(forgotPasswordFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Reset Password Actions
 * @param {*} action$
 * @returns
 */
const resetPassword = (action$) => action$.pipe(
    ofType(resetPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.resetPassword(payload)).pipe(
            concatMap((res) => of(resetPasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }), navigate({ path: 'auth.login', state: {} }))),
            catchError((error) => of(resetPasswordFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Change Password Actions
 * @param {*} action$
 * @returns
 */
const changePassword = (action$) => action$.pipe(
    ofType(changePasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.changePassword(payload)).pipe(
            concatMap((res) => {
                if (payload.isUserReset) {
                    return of(changePasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
                }
                return of(changePasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }), logoutSuccess(), navigate({ path: 'auth.login', state: {} }));
            }),
            catchError((error) => of(changePasswordFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Logout Actions
 * @param {*} action$
 * @returns
 */
const logout = (action$) => action$.pipe(
    ofType(logoutRequest),
    mergeMap(({ payload }) => {
        return from(authService.logout(payload)).pipe(
            concatMap((res) => of(logoutSuccess(res))),
            catchError((error) => of(logoutFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get User Permission
 * @param {*} action$
 * @returns
 */
const permission = (action$) => action$.pipe(
    ofType(getUserPermissionRequest),
    mergeMap(({ payload }) => {
        return from(authService.permission(payload)).pipe(
            concatMap((res) => of(getUserPermissionSuccess(res))),
            catchError((error) => of(getUserPermissionFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


// Export All Auth Epic Functions
export const authEpic = [login, get_user_info, forgotPassword, resetPassword, logout, permission, changePassword];