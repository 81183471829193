import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, IconButton, Typography } from '@mui/material';

// Import Images
import { CheckIcon, CloseIcon2, EditIcon } from '../../../../../../assets/svg/index.js';

//  Import Styles
import LayoutStyles from '../../../../../../layouts/style.jsx';
import Style from '../../style.jsx';
import { TextBoxComponent } from '../../../../../../components/index.js';
import appConstants from '../../../../../../constants/appConstants.js';
import { useDispatch } from 'react-redux';
import { updateDashboardRequest } from '../../../../../../redux/reducer/dashboardReducer.js';


function DashboardList(props) {

    const { item, selectedDashboard, onChangeDashboard } = props;

    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");

    const editName = (event, name) => {
        event.stopPropagation();
        setIsOpen(true);
        setTitle(name);
    };

    const onHandleComponentEvent = (event) => {
        event.stopPropagation();
    };

    const onHandleChangeEvent = (name) => {
        setTitle(name);
    };

    const onSave = (event) => {
        event.stopPropagation();
        if (title.length <= 0) {
            return;
        }
        setIsOpen(false);
        dispatch(updateDashboardRequest({ id: item.id, name: title }));
    };

    const onCancel = (event) => {
        event.stopPropagation();
        setIsOpen(false);
    };

    return (
        <Grid
            disablePadding
            onClick={() => selectedDashboard?.id !== item.id && onChangeDashboard(item.id)}
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: '5px 20px', cursor: 'pointer' }}
        >
            <Grid item>
                {
                    isOpen ?
                        <Grid>
                            <TextBoxComponent
                                name="name"
                                fullWidth
                                variant="standard"
                                value={item.name}
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.nameRequired]}
                                onClick={(event) => onHandleComponentEvent(event)}
                                onChange={(event) => onHandleChangeEvent(event.target.value)}
                                valOnChange
                                inputProps={{ maxLength: 25 }}
                            />
                        </Grid> :
                        <Typography variant="h6">
                            {item.name}
                        </Typography>
                }
            </Grid>
            {
                !item.is_default &&
                <Grid item>
                    {
                        !isOpen ?
                            <IconButton edge="end" aria-label="comments" onClick={(event) => editName(event, item.name)}>
                                <EditIcon />
                            </IconButton> :
                            <Grid item>
                                <IconButton edge="end" aria-label="comments" onClick={(event) => onSave(event, item.id)}>
                                    <CheckIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="comments" onClick={(event) => onCancel(event)}>
                                    <CloseIcon2 />
                                </IconButton>
                            </Grid>
                    }
                </Grid>
            }
        </Grid>
    );
}

// default props
DashboardList.defaultProps = {
    item: {},
    selectedDashboard: {},
    onChangeDashboard: () => { }
};

// prop types
DashboardList.propTypes = {
    item: PropTypes.object,
    selectedDashboard: PropTypes.object,
    onChangeDashboard: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DashboardList);