import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

//  Import Components
import { Grid, Typography } from '@mui/material';
import Card from './card/index.jsx';
import { DialogComponent, NoResultComponent, SearchComponent, TableComponent } from '../../components/index.js';
import MemoSimpleTabHeaderComponent from '../../components/tabs/simpleTabHeader/index.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import LogViewer from '../logs/components/logViewer/index.jsx';
import LookupApiHeaders from '../../components/lookupApiHeaders/index.jsx';
import LookupTableConfig from '../../components/lookupTableConfig/index.jsx';

//  Import Styles
import IntegrationStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';
import appConstants from '../../constants/appConstants.js';

// Import Action
import { deleteConfigurationRequest } from '../../redux/reducer/integrationReducer';
import { createReferenceRequest, deleteReferenceRequest, getReferencesRequest, updateReferenceRequest } from '../../redux/reducer/librariesReducer';
import { checkPermission, getFileColumns, getUserPreference, orderList, prepareUpdateUserPreference } from '../../helpers/appHelpers.js';
import { updateUserPreference } from '../../redux/reducer/authReducer.js';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer.js';
import featureConstants from '../../constants/featureConstants.js';


function Integration(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const { channels } = useSelector((state) => state.default);
    const dispatch = useDispatch();
    const searchControllerRef = useRef();
    let timeout = null;
    const defaultReferenceSorting = {
        sortBy: "created_date",
        orderBy: "desc"
    };

    /**
     * Define State
     */
    const [isCreating, setIsCreating] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [showDialog, setShowDialog] = useState({
        open: false,
        type: '',
        title: '',
        message: '',
        data: null
    });
    const defaultReferenceData = {
        name: "",
        type: "FILE",
        source: "",
        delimiter: ",",
        authorization: [{ key: "", value: "" }],
        input_fields: [],
        output_fields: []
    };
    const [newReference, setNewReference] = useState({ ...defaultReferenceData });
    const [selectedFileConfig, setSelectedFileConfig] = useState(null);
    const [searchData, setSearchData] = useState({
        name: "",
        type: "",
        source: ""
    });
    const [sortingInput, setSortingInput] = useState({ ...defaultReferenceSorting });
    const [logView, setLogViewer] = useState({
        open: false,
        anchorElement: null,
        errorMessage: ""
    });
    const defaultHeadersModal = {
        open: false,
        anchorElement: null,
        data: null
    };
    const defaultTableModal = {
        open: false,
        anchorElement: null,
        data: null
    };
    const [showHeadersModal, setShowHeadersModal] = useState(defaultHeadersModal);
    const [showTableModal, setShowTableModal] = useState(defaultTableModal);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const showLibraries = appConstants.tabs.appIntegrationTabs[tabIndex] === "Libraries";
    const { createStatus, list: references, stat: { total_count }, pagination, isLoading } = useSelector((state) => state.libraries, shallowEqual);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "libraries", "columns");
    const sorting = getUserPreference(user?.user_preference ?? {}, "table", "libraries", "sorting");
    const { permission } = useSelector((state) => state.auth);
    const integrationPermission = checkPermission(permission, featureConstants.settings.integration);

    useEffect(() => {
        if (createStatus === 'success') {
            setNewReference({ ...defaultReferenceData });
        }
        setIsCreating((createStatus === 'pending'));
    }, [createStatus]);

    /**
     * Sorting
     */
    useEffect(() => {
        if (sorting) {
            setSortingInput({ ...sorting });
        }
    }, [user?.user_preference]);

    const onClickFileUploader = (event, header, data, rowIndex) => {
        if (!integrationPermission?.is_edit) {
            return;
        }
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (data.type === "FILE") {
            document.getElementById("referenceFileUploader").click();
            setSelectedFileConfig({ header, data, rowIndex });
        }
    };

    const onClickApiKey = (event, header, data, rowIndex, isAddRow) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (data?.type !== 'API' && data?.type !== 'TABLE') {
            return;
        }
        if (data?.type === 'API') {
            setShowHeadersModal({
                open: true,
                anchorElement: event.target,
                data,
                header,
                rowIndex,
                isAddRow
            });
        }

        if (data?.type === 'TABLE') {
            setShowTableModal({
                open: true,
                anchorElement: event.target,
                data,
                isAddRow,
                header,
                rowIndex
            });
        }
    };


    const isDisabled = (data, header) => {
        let isDisabled = false;
        switch (header?.key) {
            case 'type':
                isDisabled = (data?.id?.length > 0 || data?.name?.length === 0);
                break;
            case 'source':
                isDisabled = (data?.name?.length === 0) || (data?.type === "TABLE");
                break;
            case 'delimiter':
                isDisabled = (
                    data?.source?.length === 0
                    || data?.type !== 'FILE'
                    || (
                        data?.type === 'FILE' && data?.source?.length > 0
                        && !(
                            data?.source?.toLowerCase()?.endsWith("csv")
                            || data?.source?.toLowerCase()?.endsWith("tsv")
                            || data?.source?.toLowerCase()?.endsWith("txt")
                        )
                    )
                );
                break;
            case 'throttle_limit':
                isDisabled = (data?.source?.length === 0 || data?.type !== 'API');
                break;
            case 'authorization':
                isDisabled = (data?.name?.length === 0);
                break;
            case 'input_fields':
                isDisabled = (data?.name?.length === 0) || data?.source?.length === 0;
                break;
            case 'output_fields':
                isDisabled = (data?.name?.length === 0) || data?.source?.length === 0 || (!data?.input_fields || (data?.input_fields && data?.input_fields?.length === 0));
                break;
            default:
                isDisabled = false;
                break;
        }
        return isDisabled || !integrationPermission?.is_edit;
    };


    const getInputColumns = (data) => {
        let columns = data?.columns ?? [];
        columns = columns || [];
        columns = columns.filter((column) => {
            return (
                data.input_fields.findIndex((field) => field.name === column.name) <= -1
                && data.output_fields.findIndex((field) => field.name === column.name) <= -1
            );
        });
        return columns;
    };

    const LibrariesTableHeader = [
        { key: 'name', name: 'Name', width: '15%', datatype: 'text', placeholder: 'Enter the name', component: 'textbox', className: "referenceNameInput", sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "name", applyHeaderStyle: true, validate: true, isDisabled: () => !integrationPermission?.is_edit },
        { key: 'type', name: 'Type', align: 'center', defaultValue: appConstants.referenceTypes[0].name, width: '10%', datatype: 'text', placeholder: 'Select type', component: 'select', componentKey: 'name', selectComponentKey: 'name', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "type", applyHeaderStyle: true, isDisabled: (data, header) => isDisabled(data, header) },
        { key: 'authorization', name: 'Authorization', datatype: 'text', component: 'button', actionType: "api_headers", className: 'btnApiKey', label: 'Configuration', width: '10%', showNA: true, applyHeaderStyle: true, isDisabled: (data, header) => isDisabled(data, header), isVisible: (data) => data?.type !== 'FILE', onClick: (...params) => onClickApiKey(...params) },
        {
            key: 'source', name: 'Reference Library', datatype: 'text', fileUploaderId: 'referenceFileUploader', isFullWidth: true, getComponentType: (data) => { return data?.type === 'API' ? 'textbox' : 'button'; }, includeColumns: true,
            component: 'button', className: 'btnSource', actionType: "file_upload", showValue: true, label: "Select a file", placeholder: "Enter the API url", width: '25%', sorting: true, tooltip: true, isSearch: true, searchComponent: 'text', searchKey: "source",
            applyHeaderStyle: true, isDisabled: (data, header) => isDisabled(data, header), validate: true, validators: ['required'], errorMessages: ["API url is required"], onClick: (event, header, data, rowIndex) => onClickFileUploader(event, header, data, rowIndex),
            btnValidationMessageKey: "sourceRequired"
        },
        { key: 'delimiter', name: 'Delimiter', defaultValue: appConstants.delimiterTypes[0].value, width: '10%', datatype: 'text', placeholder: 'Enter the delimiter', showNa: true, component: 'select', componentKey: 'value', selectComponentKey: 'name', applyHeaderStyle: true, hideDefault: true, className: 'delimiterInput', isDisabled: (data, header) => isDisabled(data, header), isVisible: (data) => data?.type === 'FILE' && (data?.source?.toLowerCase()?.endsWith("csv") || data?.source?.toLowerCase()?.endsWith("tsv") || data?.source?.toLowerCase()?.endsWith("txt")) },
        { key: 'throttle_limit', name: 'Throttle Limit', width: '10%', datatype: 'integer', placeholder: 'No.of requests / min', showNa: true, component: 'textbox', applyHeaderStyle: true, hideDefault: true, isDisabled: (data, header) => isDisabled(data, header), isVisible: (data) => data?.type === 'API' },
        { key: 'input_fields', name: 'Key', component: 'chips', datatype: 'array', valueKey: 'name', defaultValue: [], width: '15%', isAdd: true, addType: "text", getAddType: (data) => { return data?.type === 'API' ? 'text' : 'autocomplete'; }, showNA: true, addText: 'Add Parameter', isChipDelete: true, applyHeaderStyle: true, isDisabled: (data, header) => isDisabled(data, header), getListInputData: (data) => getInputColumns(data) },
        { key: 'output_fields', name: 'Value', component: 'chips', datatype: 'array', valueKey: 'name', defaultValue: [], width: '15%', isAdd: true, addType: "text", getAddType: (data) => { return data?.type === 'API' ? 'text' : 'autocomplete'; }, showNA: true, addText: 'Add Parameter', isChipDelete: true, applyHeaderStyle: true, isDisabled: (data, header) => isDisabled(data, header), getListInputData: (data) => getInputColumns(data) },
        {
            key: 'actions',
            name: 'Actions',
            sorting: false,
            tooltip: false,
            width: '5%',
            applyHeaderStyle: true,
            actions: [
                { type: 'create', tooltip: true, tooltipText: 'Create', actionType: "submit" },
                { type: 'cancel', tooltip: true, tooltipText: 'Cancel' },
                { type: 'error_log', tooltip: true, tooltipText: 'Error', conditionalRenderColumn: "error", showError: (data) => data.error?.length > 0 },
                { type: 'delete', tooltip: true, tooltipText: 'Delete', renderAction: () => integrationPermission?.is_edit }
            ]
        }
    ];


    /**
     * Define Use Effects
     */
    useEffect(() => {
        const requestParams = {
            ...pagination,
            ...sortingInput
        };
        dispatch(getReferencesRequest({ params: requestParams, token: null }));
    }, [dispatch]);


    /**
     * Get Permission Otions
     * @param {*} options
     * @returns
     */
    const permissionOptions = (options) => {
        const filteredOption = [...options];
        if (!integrationPermission?.is_edit) {
            const index = filteredOption.findIndex((option) => option.type === "add");
            filteredOption.splice(index, 1);
        }
        return filteredOption;
    };


    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(() => {
            const requestParams = {
                offset: 0,
                limit: pagination.limit,
                search: search_by,
                ...sortingInput
            };
            dispatch(getReferencesRequest({ params: requestParams, token }));
        }, 300);
    };

    /**
     * Handle New Row Data Change Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onChangeNewRowData = (key, value, item) => {
        item = { ...item, [key]: value };
        switch (key) {
            case 'type':
                item.source = "";
                item.delimiter = "";
                item.throttle_limit = null;
                item.selectedFile = null;
                item.authorization = {};
                item.input_fields = [];
                item.output_fields = [];
                break;
            case 'source':
                if (item?.type === "FILE") {
                    item = {
                        ...item,
                        ...value,
                        input_fields: [],
                        output_fields: []
                    };
                } else {
                    item = {
                        ...item,
                        [key]: value,
                        input_fields: [],
                        output_fields: []
                    };
                }
                break;
            default:
                break;
        }
        setNewReference({ ...item });
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        if (!integrationPermission?.is_edit) {
            return;
        }
        let requestData = {
            ...item, [key]: value
        };
        if (key === "source") {
            if (item?.type === "FILE") {
                requestData = {
                    ...item,
                    ...value,
                    sourceRequired: "",
                    input_fields: [],
                    output_fields: []
                };
            } else {
                requestData = {
                    ...item,
                    [key]: value,
                    sourceRequired: "",
                    input_fields: [],
                    output_fields: []
                };
            }
        }
        requestData.properties_input = requestData.properties ? JSON.stringify(requestData.properties) : "";
        requestData.authorization_input = requestData.authorization ? JSON.stringify(requestData.authorization) : "";
        requestData.input_fields_data = requestData.input_fields ? JSON.stringify(requestData.input_fields) : "";
        requestData.output_fields_data = requestData.output_fields ? JSON.stringify(requestData.output_fields) : "";
        dispatch(updateReferenceRequest(requestData));
        if (selectedFileConfig) {
            setSelectedFileConfig(null);
        }
    };

    /**
     * Handle Deleting Chip in the Chip Component
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     */
    const handleChipDelete = (selectedChip, fieldValue, item, key) => {
        const value = fieldValue.filter((chip) => selectedChip !== chip);
        onCompnentEvent(key, value, item);
    };

    /**
     * Handle save api headers
     * @param {*} value
     */
    const onSaveHeaders = (value) => {
        if (showHeadersModal?.isAddRow) {
            onChangeNewRowData("authorization", value, showHeadersModal?.data);
        } else {
            onCompnentEvent("authorization", value, showHeadersModal?.data);
        }
        setShowHeadersModal(defaultHeadersModal);
    };

    /**
     * Handle save api headers
     * @param {*} value
     */
    const onSaveTableLibrary = (value) => {
        const columns = value[0].attributes || [];
        const inputFields = [];
        const colinputFields = [];
        for (const column of columns) {
            inputFields.push({ id: nanoid(), name: column.name });
            colinputFields.push(column.name);
        }
        const newRef = { ...newReference };
        newRef.properties = { "columns": colinputFields, "tableConfig": value };
        if (value[0].connection.length === 0 || value[0].assets.length === 0 || value[0].attributes.length === 0) {
            return false;
        }
        newRef.columns = inputFields;
        newRef.source = "Table Type";
        setNewReference({ ...newRef });
        if (!showTableModal?.isAddRow) {
            onCompnentEvent("properties", { "columns": colinputFields, "tableConfig": value }, showTableModal?.data);
        }
        setShowTableModal(defaultTableModal);
    };

    /**
     * Handle Adding the new Chip in the Chip Component
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     */
    const handleChipAdd = (value, fieldValue, item, key) => {
        if (item?.type === "API" && typeof (value) === "string") {
            value = { name: value };
        }
        const chipValue = [...fieldValue, value];
        onCompnentEvent(key, chipValue, item);
    };

    /**
     * Handle Close LogViewer
     */
    const onCloseLogViewer = () => {
        setLogViewer({
            open: false,
            anchorElement: null,
            errorMessage: ""
        });
    };

    /**
     * Handle Adding or Deleting Application
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = useCallback((item, actionType, event) => {
        switch (actionType) {
            case 'delete':
                setShowDialog({
                    open: true,
                    type: "Reference",
                    title: appConstants.dialogBox.delete,
                    message: appConstants.dialogBox.referenceDeleteMessage.replace('<type>', `${item?.type === 'API' ? 'api' : 'file'}`),
                    data: { id: item?.id }
                });
                break;
            case 'create':
                if (actionType === 'create' && item?.name?.length > 0 && item?.source?.length > 0) {
                    item.properties_input = item.properties ? JSON.stringify(item.properties) : "";
                    item.authorization_input = item.authorization ? JSON.stringify(item.authorization) : "";
                    item.input_fields_data = item.input_fields ? JSON.stringify(item.input_fields) : "";
                    item.output_fields_data = item.output_fields ? JSON.stringify(item.output_fields) : "";
                    setIsCreating(true);
                    dispatch(createReferenceRequest(item));
                }
                if (item?.source?.length === 0 && item?.type !== "TABLE") {
                    setNewReference({ ...item, sourceRequired: "Reference file is required" });
                }
                break;
            case 'cancel':
                setNewReference({ ...defaultReferenceData });
                break;
            case "error_log":
                if (item?.error?.length <= 0) {
                    return;
                }
                setLogViewer({
                    open: true,
                    anchorElement: event.target,
                    errorMessage: item.error
                });
                break;
            default:
                break;
        }
    }, [dispatch]);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "libraries", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = { sortBy, orderBy };
        setSortingInput(requestData);
        updatePreference({ sorting: requestData });
    };

    /**
     * Handles the channel delete event
     * @param {*} channel The channel to be deleted
     */
    const handleDelete = (channel) => {
        setShowDialog({
            open: true,
            type: "Channel",
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.channelDeleteMessage,
            data: channel
        });
    };

    /**
     * Hanlde delete dialog box cancel event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            type: "",
            title: "",
            message: "",
            data: null
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = () => {
        if (
            (showDialog?.type === 'Channel' && !showDialog.data?.channel?.integration_id) ||
            (showDialog?.type === 'Reference' && !showDialog.data?.id)
        ) {
            dialogCancelEventHandle();
            return;
        }
        if (showDialog?.type === 'Channel') {
            dispatch(deleteConfigurationRequest({ id: showDialog.data.channel.integration_id }));
        } else if (showDialog?.type === 'Reference') {
            dispatch(deleteReferenceRequest(showDialog.data.id));
        }
        dialogCancelEventHandle();
    };

    /**
     * Get Filtered Channels
     * @param {*} data
     * @param {*} search
     * @param {*} tab
     * @returns
     */
    const getChannel = (data, search, tab) => {
        if (tab !== 0) {
            data = data.filter((obj) => obj.tag.toLowerCase() === appConstants.tabs.appIntegrationTabs[tab].toLowerCase());
        }
        if (search.trim()) {
            data = data.filter((obj) => obj.name.toLowerCase().includes(search.toLowerCase()));
        }
        return data;
    };

    /**
     * Handle file upload
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0 && selectedFileConfig) {
            const item = selectedFileConfig?.data || {};
            const selectedFile = event.target.files[0];
            const header = selectedFileConfig?.header || {};

            if (header?.includeColumns) {
                getFileColumns(
                    selectedFile, { delimiter: item?.delimiter }
                ).then((response) => {
                    const isCSV = selectedFile?.name?.toLowerCase().endsWith("csv");
                    let delimiter = response?.delimiter;
                    if (isCSV) {
                        delimiter = response?.delimiter || ',';
                    } else if (selectedFile?.name?.toLowerCase().endsWith("tsv")) {
                        delimiter = response?.delimiter || 'tab';
                    } else if (selectedFile?.name?.toLowerCase().endsWith("txt")) {
                        delimiter = response?.delimiter || ',';
                    }
                    const properties = {
                        ...item.properties,
                        delimiter: delimiter,
                        columns: response?.columns || []
                    };
                    const columns = properties.columns || [];
                    const inputFields = [];
                    for (const column of columns) {
                        inputFields.push({ id: nanoid(), name: column });
                    }
                    const data = {
                        ...item,
                        source: selectedFile.name,
                        delimiter: delimiter,
                        columns: inputFields || [],
                        selectedFile,
                        properties
                    };
                    onCompnentEvent("source", data, item);
                });
            } else {
                const data = {
                    ...item,
                    source: selectedFile.name,
                    selectedFile
                };
                onCompnentEvent("source", data, item);
            }
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Handle table scroll end event
     */
    const onScrollEnd = useCallback(() => {
        if (references?.length >= total_count) {
            return;
        }
        const requestParams = {
            ...pagination,
            offset: references?.length,
            ...sortingInput
        };
        dispatch(getReferencesRequest({ params: requestParams, token: null }));
    }, [references, total_count]);

    /**
     * Filter the references data
     * @param {*} references
     * @param {*} sorting
     * @returns
     */
    const filterReferences = (references, sorting) => {
        let libraries = JSON.parse(JSON.stringify(references));
        libraries = orderList(libraries, sorting.sortBy, sorting.orderBy);
        return libraries;
    };


    const filterChannels = useMemo(() => getChannel(channels, searchText, tabIndex), [channels, searchText, tabIndex]);
    const filteredReferences = useMemo(() => filterReferences(references, sortingInput), [references, sortingInput]);

    return (
        <Grid container className={classes.integrationPageContainer}>
            <Grid item xs={12} className={classes.header}>
                <Grid item xs={12} className="pb-2">
                    <Typography variant="h5" className="pb5">
                        {appConstants.labels.appIntegration.title}
                    </Typography>
                    <Typography variant="body1">
                        {appConstants.labels.appIntegration.description}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'space-between'} wrap="nowrap">
                    <Grid item>
                        {
                            !showLibraries &&
                            <SearchComponent
                                fullWidth
                                placeholder={appConstants.labels.appIntegration.search}
                                value={searchText}
                                onChange={(value) => setSearchText(value)}
                            />
                        }
                    </Grid>
                    <Grid item>
                        <MemoSimpleTabHeaderComponent
                            tabList={appConstants.tabs.appIntegrationTabs}
                            tabIndex={tabIndex}
                            onTabChange={(newValue) => setTabIndex(newValue)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.cardBody}>
                {
                    !showLibraries && filterChannels.length > 0 &&
                    <Grid container spacing={3} className={classes.innerBody}>
                        {
                            filterChannels.map((item, index) => (
                                <Grid item xs={3} xl={2} key={index}>
                                    <Card data={item} onDelete={() => handleDelete(item)} />
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                {
                    showLibraries &&
                    <ValidatorForm onSubmit={() => handleTableActions(newReference, "create")} className={classes.referenceListContainer}>
                        <TableComponent
                            headers={LibrariesTableHeader}
                            isAdd={Boolean(integrationPermission?.is_edit)}
                            styleType={null}
                            data={filteredReferences || []}
                            defaultAddRowData={newReference}
                            disableAddRowActions={isCreating}
                            sortBy={sortingInput?.sortBy || ""}
                            orderBy={sortingInput?.orderBy || ""}
                            onChangeAddRowData={onChangeNewRowData}
                            onCompnentEvent={onCompnentEvent}
                            onClickActions={handleTableActions}
                            onClickSorting={onClickSorting}
                            onHandleChipAdd={handleChipAdd}
                            onHandleChipDelete={handleChipDelete}
                            searchData={searchData}
                            selectComponentList={{ type: appConstants.referenceTypes, delimiter: appConstants.delimiterTypes }}
                            onHandleSearchEvent={onHandleSearchEvent}
                            options={permissionOptions(appConstants.tableOptions.common)}
                            height="calc(100vh - 450px)"
                            NoResultText="No References Found"
                            isLoading={isLoading}
                            onColumnChange={(columns) => onColumnsChange(columns)}
                            userPreferenceColumns={columns || []}
                            onScrollEnd={onScrollEnd}
                            stickyHeader
                            addRowProps={{ isDirectRow: true, isClosed: (createStatus === 'success'), className: 'referenceLibrary' }}
                        />
                    </ValidatorForm>
                }
                <Grid item sx={{ flex: 1 }} />
                {
                    (!showLibraries && filterChannels.length < 1)
                    && <NoResultComponent title="No items found" height="calc(100vh - 320px)" />
                }
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
            {
                logView &&
                <LogViewer
                    open={logView.open}
                    anchorElement={logView.anchorElement}
                    errorMessage={logView.errorMessage}
                    onClose={onCloseLogViewer}
                />
            }
            {
                showHeadersModal &&
                <LookupApiHeaders
                    open={showHeadersModal.open}
                    anchorElement={showHeadersModal.anchorElement}
                    headers={showHeadersModal.data?.authorization || []}
                    onSave={(value) => onSaveHeaders(value)}
                    onClose={() => setShowHeadersModal(defaultHeadersModal)}
                />
            }
            {
                showTableModal &&
                <LookupTableConfig
                    open={showTableModal.open}
                    anchorElement={showTableModal.anchorElement}
                    headers={showTableModal.data?.authorization || []}
                    properties={showTableModal.data?.properties || {}}
                    onSave={(value) => onSaveTableLibrary(value)}
                    onClose={() => setShowTableModal(defaultTableModal)}
                />
            }
            <input
                accept="application/JSON,.csv,.tsv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: 'none' }}
                id={`referenceFileUploader`}
                type="file"
                onChange={handleSelectFile}
            />
        </Grid>
    );
}

// default props
Integration.defaultProps = {
    classes: {}
};

// prop types
Integration.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...IntegrationStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Integration);