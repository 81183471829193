import { createSlice } from '@reduxjs/toolkit';
import appConstants from '../../constants/appConstants';


const defaultDetail = {
    name: "",
    description: "",
    is_default: false,
    default_template: false,
    widget_type: "vertical_bar",
    properties: {
        xAxis: [],
        yAxis: [],
        levels: [],
        breakDown: [],
        groupBy: []
    },
    filter_properties: {
        value: "default",
        filters: {
            rating: 5,
            score: [0, 100],
            applications: [],
            domains: [],
            tags: [],
            terms: [],
            connection_type: [],
            filter_by: [],
            measure_type: [],
            measure_level: [],
            measure_category: [],
            dimension: [],
            asset_status: [...appConstants.status.slice(0, 3)]
        }
    },
    layout: { "x": 0, "y": Infinity, "w": 6, "h": 3, "static": false, minH: 2 }
};

const initialState = {
    isLoading: false,
    preivewLoading: false,
    detail: { ...defaultDetail },
    report: [],
    errorMessage: ""
};

const customWidgetSlice = createSlice({
    name: 'customWidget',
    initialState: { ...initialState },
    reducers: {
        getWidgetRequest(state) {
            state.detail = { ...defaultDetail };
            state.isLoading = true;
        },
        getWidgetSuccess(state, action) {
            state.detail = action.payload.data || {};
            state.isLoading = false;
        },
        getWidgetFailure(state) {
            state.detail = { ...defaultDetail };
            state.isLoading = false;
        },
        updateWidgetRequest() { },
        updateWidgetSuccess() { },
        updateWidgetFailure() { },
        getWidgetPreviewRequest(state) {
            state.errorMessage = "";
            state.preivewLoading = true;
        },
        getWidgetPreviewSuccess(state, action) {
            state.report = action.payload.data || [];
            state.preivewLoading = false;
        },
        getWidgetPreviewFailure(state, action) {
            state.errorMessage = action.payload;
            state.preivewLoading = false;
            state.report = [];
        },
        updateProperties(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload
            };
        },
        clearState(state) {
            state.detail = { ...defaultDetail };
            state.report = [];
            state.errorMessage = "";
        },
        clearReport(state) {
            state.report = [];
            state.errorMessage = "";
        }
    }
});

export const { getWidgetRequest, getWidgetSuccess, getWidgetFailure, updateWidgetRequest, updateWidgetSuccess, updateWidgetFailure, updateProperties,
    getWidgetPreviewRequest, getWidgetPreviewSuccess, getWidgetPreviewFailure, getCustomWidgetDetailRequest, getCustomWidgetDetailSuccess, getCustomWidgetDetailFailure,
    clearState, clearReport
} = customWidgetSlice.actions;
export default customWidgetSlice.reducer;