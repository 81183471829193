const style = () => ({
    actionContainer: {
        '& .MuiButton-root': {
            color: '#222',
            /*
             * textTransform: 'capitalize',
             * fontWeight: 400,
             */
            background: '#E1E5E6',
            '&:hover': {
                color: '#fff'
            }
        }
    }
});
export default style;