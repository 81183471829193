/* eslint no-underscore-dangle: 0 */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line no-unused-vars */

import React from 'react';
import 'butterfly-dag/dist/index.css';
import _ from 'lodash';
import LineageCanvas from './canvas/canvas';
import { transformInitData, transformEdges, diffPropsData, updateCanvasData, diffActionMenuData } from './adaptor';
import ActionMenu, { action } from './component/action-menu.tsx';


// Similar to the concept of column in antd's table
interface columns {
  key: string,
  width?: number,
  primaryKey: boolean,
  render?(text: any, record: any, index: number): void
}

interface ITable {
  id: string;
  name: string;
  isCollapse: boolean;
  fields: Array<columns>
}

interface IRelation {
  id?: string;
  srcTableId: string;
  tgtTableId: string;
  srcTableColName: string;
  tgtTableColName: string;
}

interface ComProps {
  width?: any,
  height?: any,
  tables: Array<ITable>,
  relations: Array<IRelation>,
  className?: string,
  actionMenu: action[],
  config?: {
    titleRender: (node: ITable) => void,
    showActionIcon?: boolean,
    enableHoverChain: boolean,
    minimap?: {
      enable: boolean,
      config: {
        nodeColor: any
      }
    },
    gridMode: {
      isAdsorb: boolean,
      theme: {
        shapeType: string,
        gap: number,
        lineWidth: 1,
        lineColor: string,
        circleRadiu: number,
        circleColor: string
      }
    },
    butterfly: any;
  },
  emptyContent?: string | JSX.Element,
  emptyWidth?: number | string,
  centerId?: string,
  onChange(data: any): void,
  onLoaded(canvas: any): void
}


export default class LineageDag extends React.Component<ComProps, any> {
  props: any;

  protected _isFirstFocus: any;

  protected canvas: any;

  protected canvasData: any;

  protected originEdges: any;

  protected lineageRef: any;

  constructor(props: ComProps) {
    super(props);
    this.canvas = null;
    this.canvasData = null;
    this.originEdges = [];
    this._isFirstFocus = false;
    this.lineageRef = React.createRef();
  }

  componentDidMount() {
    const root = this.lineageRef.current;
    const enableHoverChain = _.get(this.props, 'config.enableHoverChain', true);
    const titleRender = _.get(this.props, 'config.titleRender');

    const canvasObj = {
      root: root,
      disLinkable: true,
      linkable: true,
      draggable: true,
      zoomable: true,
      moveable: true,
      theme: {
        edge: {
          type: 'endpoint',
          shapeType: 'AdvancedBezier',
          arrow: true,
          arrowPosition: 0.2,
          defaultAnimate: true
        },
        endpoint: {
          limitNum: undefined,
          expandArea: {
            left: 0,
            right: 0,
            top: 0,
            botton: 0
          }
        }
      },
      data: {
        enableHoverChain: enableHoverChain
      }
    };

    this.canvas = new LineageCanvas(canvasObj);

    let result = transformInitData({
      tables: this.props.tables,
      relations: this.props.relations,
      columns: this.props.columns,
      operator: this.props.operator,
      _titleRender: titleRender,
      _enableHoverChain: enableHoverChain,
      _emptyContent: this.props.emptyContent,
      _emptyWidth: this.props.emptyWidth
    });

    this.originEdges = result.edges;

    result = transformEdges(result.nodes, _.cloneDeep(result.edges));
    this.canvasData = {
      nodes: result.nodes,
      edges: result.edges
    };

    setTimeout(() => {
      const tmpEdges = result.edges;
      result.edges = [];
      this.canvas.wrapper.style.visibility = 'hidden';
      this.canvas.draw(result, () => {
        this.canvas.relayout({
          edges: tmpEdges.map((item) => {
            return {
              source: item.sourceNode,
              target: item.targetNode
            };
          })
        }, true);
        this.canvas.wrapper.style.visibility = 'visible';
        this.canvas.addEdges(tmpEdges, true);

        const minimap: any = _.get(this, 'props.config.minimap', {});

        const minimapCfg = _.assign({}, minimap.config, {
          events: [
            'system.node.click',
            'system.canvas.click'
          ]
        });

        if (minimap && minimap.enable) {
          this.canvas.setMinimap(true, minimapCfg);
        }

        if (_.get(this, 'props.config.gridMode')) {
          this.canvas.setGridMode(true, _.assign({}, _.get(this, 'props.config.gridMode', {})));
        }

        if (result.nodes.length !== 0) {
          this.canvas.focusCenterWithAnimate();
          this._isFirstFocus = true;
        }

        this.forceUpdate();
        if (this.props.onLoaded) {
          this.props.onLoaded(this.canvas);
        }
      });
      this.canvas.on('system.node.click', (data) => {
        const node = data.node;
        this.canvas.focus(node.id);
      });
      this.canvas.on('system.canvas.click', () => {
        this.canvas.unfocus();
      });
    }, _.get(this.props, 'config.delayDraw', 0));

  }

  shouldComponentUpdate(newProps: ComProps) {

    const enableHoverChain = _.get(newProps, 'config.enableHoverChain', true);
    const titleRender = _.get(this.props, 'config.titleRender');

    let result = transformInitData({
      tables: newProps.tables,
      relations: newProps.relations,
      columns: this.props.columns,
      operator: this.props.operator,
      _titleRender: titleRender,
      _enableHoverChain: enableHoverChain,
      _emptyContent: this.props.emptyContent,
      _emptyWidth: this.props.emptyWidth
    });

    this.originEdges = result.edges;

    result = transformEdges(result.nodes, _.cloneDeep(result.edges));
    const diffInfo = diffPropsData(result, this.canvasData);
    let isNeedRelayout = false;

    if (diffInfo.rmEdges.length > 0) {
      this.canvas.removeEdges(diffInfo.rmEdges.map((edge) => edge.id));
      isNeedRelayout = true;
    }

    if (diffInfo.rmNodes.length > 0) {
      this.canvas.removeNodes(diffInfo.rmNodes.map((item) => item.id));
      isNeedRelayout = true;
    }

    if (diffInfo.addNodes.length > 0) {
      this.canvas.addNodes(diffInfo.addNodes);
      isNeedRelayout = true;
    }

    if (diffInfo.collapseNodes.length > 0) {
      diffInfo.collapseNodes.forEach((item) => {
        const node = this.canvas.getNode(item.id);
        node.collapse(item.isCollapse);
      });
      isNeedRelayout = true;
    }

    if (diffInfo.addEdges.length > 0) {
      this.canvas.addEdges(diffInfo.addEdges);
      isNeedRelayout = true;
    }

    if (isNeedRelayout) {
      this.canvas.relayout({
        centerNodeId: newProps.centerId
      });
      const nodesRenderPromise = this.canvas.nodes.map((item) => {
        return item._renderPromise;
      });

      this.canvas._renderPromise = Promise.all(nodesRenderPromise).then(() => {
        return new Promise<void>((resolve) => {
          if (newProps.centerId) {
            this.canvas.focusNodeWithAnimate(newProps.centerId, 'node', {}, () => {
              setTimeout(() => {
                resolve();
              }, 50);
            });
            this.canvas.focus(newProps.centerId);
          } else {
            if (!this._isFirstFocus) {
              this.canvas.focusCenterWithAnimate();
              this._isFirstFocus = true;
            }
            resolve();
          }
        });
      });
    }

    this.canvasData = result;

    updateCanvasData(result.nodes, this.canvas.nodes);

    // The action menu has been updated and requires react’s update mechanism to be updated.
    const isNeedUpdate = diffActionMenuData(newProps.actionMenu, this.props.actionMenu);

    return isNeedUpdate;
  }

  render() {
    const { canvas, lineageRef } = this;
    const { actionMenu = [] } = this.props;
    const actionMenuVisible = _.get(this, 'props.config.showActionIcon', true);

    return (
      <div ref={lineageRef}
        className={this._genClassName()}
      >
        <ActionMenu
          canvas={canvas}
          actionMenu={actionMenu}
          visible={actionMenuVisible}
        />
      </div>
    );
  }

  _genClassName() {
    let classname = '';
    if (this.props.className) {
      classname = this.props.className + ' butterfly-lineage-dag';
    } else {
      classname = 'butterfly-lineage-dag';
    }
    return classname;
  }
}