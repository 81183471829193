const style = (theme) => ({
    popoverDiv: {
        padding: '8px 0px',
        '& svg': {
            marginRight: '7px !important'
        },
        '& .DoubleArrowUp path': {
            fill: theme.palette.colorThemes.high
        },
        '& .ArrowUp path': {
            fill: theme.palette.colorThemes.medium
        },
        '& .ArrowDown path': {
            fill: `${theme.palette.colorThemes.low}`
        }
    },
    chips: {
        height: '26px !important',
        borderRadius: '4px !important',
        padding: '0px 10px !important',
        '& .MuiChip-label': {
            paddingLeft: '0px',
            paddingRight: '0px',
            fontSize: '14px'
        },
        '& svg': {
            marginRight: '7px !important',
            marginLeft: '0px !important'
        },
        '& .DoubleArrowUp path': {
            fill: theme.palette.colorThemes.high
        },
        '& .ArrowUp path': {
            fill: theme.palette.colorThemes.medium
        },
        '& .ArrowDown path': {
            fill: `${theme.palette.colorThemes.low}`
        },
        '&.Mui-disabled': {
            opacity: '1 !important'
        }
    },
    verifiedChip: {
        background: `${theme.palette.secondary.main}21 !important`,
        color: `${theme.palette.secondary.main} !important`,
        '& svg': {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    pendingChip: {
        background: `${theme.palette.colorThemes.medium}40 !important`,
        color: `${theme.palette.colorThemes.medium} !important`,
        '& svg': {
            fill: `${theme.palette.colorThemes.medium} !important`
        }
    },
    readyforreviewChip: {
        background: `${theme.palette.primary.main}20 !important`,
        color: `${theme.palette.primary.main} !important`,
        '& svg': {
            fill: `${theme.palette.primary.main} !important`
        }
    },
    deprecatedChip: {
        background: `rgba(0, 0, 0, 0.08) !important`,
        color: `rgba(0, 0, 0, 0.87) !important`,
        '& svg': {
            height: 15,
            width: 15,
            fill: `rgba(0, 0, 0, 0.57) !important`
        }
    },
    highChip: {
        background: `${theme.palette.colorThemes.high}40 !important`,
        '& svg': {
            fill: `${theme.palette.colorThemes.high} !important`
        },
        '&.newChip': {
            color: `${theme.palette.colorThemes.high} !important`
        }
    },
    mediumChip: {
        background: `${theme.palette.colorThemes.medium}40 !important`,
        '& svg': {
            fill: `${theme.palette.colorThemes.medium} !important`
        },
        '&.inprogressChip': {
            color: `${theme.palette.colorThemes.medium} !important`
        }
    },
    lowChip: {
        background: `${theme.palette.colorThemes.low}21 !important`,
        '& svg': {
            fill: `${theme.palette.colorThemes.low} !important`
        },
        '&.resolvedChip': {
            color: `${theme.palette.colorThemes.low} !important`
        }
    },
    popoverList: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '6px 16px',
        '& svg': {
            marginRight: '7px'
        },
        '&:first-child': {
        },
        '&:hover': {
            background: '#f5f7f8'
        },
        '& .PendingIcon': {
            fill: `${theme.palette.colorThemes.medium} !important`
        },
        '& .VerfiedIcon': {
            fill: `${theme.palette.secondary.main} !important`
        },
        '& .RedyForReviewIcon': {
            fill: `${theme.palette.primary.main} !important`
        },
        '& .New': {
            background: `${theme.palette.colorThemes.high}40 !important`,
            color: `${theme.palette.colorThemes.high} !important`,
            padding: '2px 8px',
            borderRadius: 4
        },
        '& .Inprogress': {
            background: `${theme.palette.colorThemes.medium}40 !important`,
            color: `${theme.palette.colorThemes.medium} !important`,
            padding: '2px 8px',
            borderRadius: 4
        },
        '& .Resolved': {
            background: `${theme.palette.colorThemes.low}40 !important`,
            color: `${theme.palette.colorThemes.low} !important`,
            padding: '2px 8px',
            borderRadius: 4
        }
    },
    noBackground: {
        background: 'transparent !important'
    }
});
export default style;