import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
    Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
    FormControl, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../../components/index';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getMeasurePreviewRequest, sortFailedRows, exportFailedRowRequest } from '../../../../../../redux/reducer/measureReducer.js';

// Import Helpers
import { exportCsv, dFormatter } from '../../../../../../helpers/appHelpers.js';
import appConstants from '../../../../../../constants/appConstants.js';


function FailedRows(props) {

    /**
     * Define Props
     */
    const { classes, measureId, selectedValue, scrollRef } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [limit, setLimit] = useState(20);
    const [openDialog, setOpenDialog] = useState(false);
    const [previewOption, setPreviewOption] = useState(appConstants.exportPreviewOptions[0]);

    /**
     * State variables
     */
    const { failedRows, isLoading, failedRowsConfig, exportPreviewLoading } = useSelector((state) => state.measure);
    const metricDetail = useSelector((state) => state.metric.detail, shallowEqual);

    /**
     * Use effect to load failed rows for the given measure
     */
    useEffect(() => {
        if (measureId) {
            const requestParams = {
                measure_id: measureId,
                filter_by: selectedValue ?? null
            };
            dispatch(getMeasurePreviewRequest(requestParams));
            setLimit(20);
        }
    }, [measureId, selectedValue]);


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy
        };
        dispatch(sortFailedRows(requestData));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreFailedRows = () => {
        if (scrollRef && scrollRef.current && scrollRef.current.scrollHeight - Math.floor(scrollRef.current.scrollTop) === scrollRef.current.clientHeight) {
            setLimit(limit + 20);
        }
    };

    /**
     * Handle Lazy Load
     */
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            const element = scrollRef.current;
            element.addEventListener("scroll", loadMoreFailedRows);
            return () => element.removeEventListener("scroll", loadMoreFailedRows);
        }
    }, [scrollRef, loadMoreFailedRows]);


    /**
     * Prepare Result Data
     * @param {*} data
     * @returns
     */
    const prepareFilterResult = (data, searchFilters) => {
        const objectHeaders = data?.length > 0 ? Object.keys(data[0]) : [];
        const headers = [];
        for (const header of objectHeaders) {
            if (header?.toLowerCase() === "row_number") {
                continue;
            }
            headers.push({
                key: header,
                name: header,
                sorting: true,
                tooltip: true,
                isSearch: true,
                searchComponent: "text",
                searchKey: header
            });
        }
        let filterData = JSON.parse(JSON.stringify([...data]));
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    const value = item[key];
                    if (!value?.toString().toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        filterData = _.orderBy(filterData, ['created_date'], ['desc']);
        filterData = filterData.map((item) => {
            if (metricDetail.technical_name === "freshness") {
                const columns = Object.keys(item);
                const selectedItem = {};
                for (const column of columns) {
                    selectedItem[column] = dFormatter(item[column]);
                }
                return selectedItem;
            }
            return item;
        });
        return { data: filterData, headers };
    };

    /**
     * Prepare Data using UseMemo
     */
    const resultData = useMemo(() => prepareFilterResult(failedRows, searchData), [failedRows, searchData]);


    /**
     * Open Dowload Failed Rows Dialog
     */
    const downloadFailedRows = () => {
        setOpenDialog(true);
    };

    /**
     * On Close Dialog
     */
    const onCloseDialog = () => {
        setOpenDialog(false);
        setPreviewOption(appConstants.exportPreviewOptions[0]);
    };

    /**
     * Export Preview
     */
    const exportPreview = () => {
        if (previewOption === appConstants.exportPreviewOptions[0]) {
            const params = {
                data: resultData?.data || [],
                fileName: "failed_rows.csv",
                titleCaseHeader: true,
                headers: resultData?.headers.map((header) => header.key)
            };
            exportCsv(params);
        } else {
            const requestParams = {
                measure_id: measureId,
                filter_by: selectedValue ?? null
            };
            dispatch(exportFailedRowRequest(requestParams));
        }
        onCloseDialog();
    };

    /**
     * Load Data
     * @param {*} data
     * @param {*} config
     * @returns
     */
    const prepareLoadData = (data, limit) => {
        return data.slice(0, limit);
    };

    const filterData = useMemo(() => prepareLoadData(resultData.data, limit), [resultData.data, limit]);
    const tableOptions = [{ type: 'search', customFunction: null }, { type: 'download', customFunction: downloadFailedRows, isLoading: exportPreviewLoading }, { type: 'columns', customFunction: null }];

    return (
        <Grid item xs={12} className={classes.FailedRowsContainer}>
            <TableComponent
                styleType="striped"
                headers={resultData?.headers || []}
                data={filterData || []}
                options={tableOptions}
                sortBy={failedRowsConfig?.sortBy}
                orderBy={failedRowsConfig?.orderBy}
                onClickSorting={onClickSorting}
                onHandleSearchEvent={onHandleSearchEvent}
                searchData={{ ...searchData }}
                NoResultText="No Results Found"
                height="calc(100vh - 300px)"
                // stickyHeader
                isLoading={isLoading}
                exportParams={
                    {
                        fileName: "failed_rows.csv",
                        titleCaseHeader: true,
                        data: resultData.data
                    }
                }
                stickyHeader
            />
            {
                openDialog &&
                <Dialog
                    open
                    onClose={onCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialog}
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Export Preview'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'Export Valid Records'}
                        </DialogContentText>
                        <FormControl>
                            <RadioGroup
                                defaultValue={appConstants.exportPreviewOptions[0]}
                                name="preview-export"
                                onChange={(event) => setPreviewOption(event.target.value)}
                            >
                                {
                                    appConstants.exportPreviewOptions.map((data, index) =>
                                        <FormControlLabel key={`preview_${index}`} value={data} control={<Radio />} label={data} />
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions className={"actions"}>
                        <Grid container justifyContent={'flex-end'}>
                            <Button disableElevation variant="contained" size="small" className={classNames(classes.cancelBtn, "mr-1")} onClick={() => onCloseDialog()}>
                                Cancel
                            </Button>
                            <Button disableElevation color="primary" variant="contained" size="small" onClick={() => exportPreview()}>
                                Download
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            }
        </Grid>
    );
}

// default props
FailedRows.defaultProps = {
    classes: {},
    measureId: "",
    selectedValue: "",
    scrollRef: null
};

// prop types
FailedRows.propTypes = {
    classes: PropTypes.object,
    measureId: PropTypes.string,
    selectedValue: PropTypes.string,
    scrollRef: PropTypes.element
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FailedRows);