const style = (theme) => ({
    propertiesHeaderContainer: {
        position: 'relative',
        '& .MuiToggleButton-root': {
            padding: '8px',
            border: `1px solid ${theme.palette.greyshades.darkgrey}`,
            '&.Mui-selected': {
                background: `${theme.palette.secondary.main} !important`,
                '& svg.TileIcon g': {
                    stroke: theme.palette.primary.contrastText
                },
                '& svg path, svg ellipse, svg circle': {
                    fill: theme.palette.primary.contrastText
                }
            }
        },
        '& .ChevRightIcon, .ChevLeftIcon': {
            width: '14px',
            height: '16px',
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            }
        }
    },
    sliderNumber: {
        paddingTop: 7,
        paddingBottom: 5
    },
    tabHeaderContainer: {
        '& button': {
            "marginRight": "1px !important",
            "borderRadius": "0px !important"
        },
        '& .tabHeader': {
            borderBottom: 'none !important',
            justifyContent: 'end'
        },
        '& .tabsLink': {
            padding: '0px',
            marginRight: 1
        },
        '& .tabsLink .MuiTypography-h6': {
            padding: '3px 16px',
            fontSize: '15px',
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontWeight: '400 !important',
            background: theme.palette.greyshades.lightgrey,
            '&.active': {
                border: 'none !important',
                background: theme.palette.secondary.main,
                color: '#fff !important',
                padding: '3px 16px'
            }
        },
        '& .DownloadIcon': {
            width: '24px',
            height: '24px'
        }
    },
    advancedProfileToggle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "14px !important",
        '& p': {
            marginRight: "16px !important",
            marginBottom: "1px !important",
            fontSize: "15px !important",
            fontWeight: 500
        }
    },
    profileDescription: {
        "& p": {
            marginTop: "6px !important",
            minHeight: "44px !important"
        }
    }
});
export default style;