import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { disableFlags } from '../../helpers/appHelpers';
import appConstants from '../../constants/appConstants';
import moment from 'moment';

const initialState = {
    isLoading: false,
    detail: {
        is_active: true,
        is_auto: true,
        is_drift_enabled: true,
        is_positive: true,
        allow_score: true,
        type: 'custom',
        category: 'conditional',
        status: 'pending',
        run_status: 'Completed',
        custom_fields: []
    },
    sortBy: '',
    orderBy: 'asc',
    measures: [],
    assetMeasures: [],
    assetLevelLoading: false,
    loadMore: true,
    assetLevelSearch: { sortBy: "default", orderBy: "desc", offset: 0, limit: 20, search_by: "" },
    assetMeasureCount: {},
    failedRows: [],
    failedRowsConfig: {
        sortBy: '',
        orderBy: 'asc'
    },
    query_result: {
        headers: [],
        data: [],
        isLoading: false
    },
    queryError: "",
    querySubmit: false,
    exportLoading: false,
    statistics: {},
    exportPreviewLoading: false,
    comparisonMeasures: {},
    measureType: appConstants.labels.quality.volumeTypes[0],
    reloadMeasures: false
};

const measureSlice = createSlice({
    name: 'measure',
    initialState: { ...initialState },
    reducers: {
        createMeasureRequest(state) {
            state.isLoading = true;
        },
        createMeasureSuccess(state, action) {
            const { id, dimension_name, asset_id, attribute_id, custom_fields } = action.payload.data;
            state.detail = { ...state.detail, id, asset_id, attribute_id, custom_fields };
            const existing_fields = [...state.detail.custom_fields];

            const updatedFields = existing_fields.map((item1) => {
                const matchingItem2 = custom_fields.find((item2) => item1.custom_field === item2.custom_field);
                return matchingItem2 ? { ...item1, ...matchingItem2 } : item1;
            });

            const newMeasure = {
                allow_score: state.detail.allow_score,
                description: state.detail.description,
                category: state.detail.category,
                is_drift_enabled: state.detail.is_drift_enabled,
                is_positive: state.detail.is_positive,
                name: state.detail.name,
                type: state.detail.type,
                last_runs: [],
                is_default: false,
                score: 0,
                is_custom: true,
                dimension: [],
                is_active: true,
                id: id,
                dimension_name: dimension_name || '',
                asset_id: asset_id,
                attribute_id: attribute_id,
                custom_fields: updatedFields
            };
            state.measures = [
                ...state.measures,
                newMeasure
            ];
            state.detail = { ...state.detail, custom_fields: updatedFields };
            state.isLoading = false;
        },
        createMeasureFailure(state) {
            state.isLoading = false;
        },
        updateMeasureRequest(state) {
            state.isLoading = true;
        },
        updateMeasureSuccess(state, action) {
            const { req_data, response } = action.payload;
            const id = response?.id ?? '';
            const dimension_name = response?.dimension_name ?? '';

            const up_index = state.measures.findIndex((m) => m.id === id);
            if (up_index > -1) {
                let selectedMeasure = state.measures[up_index];
                selectedMeasure = {
                    ...selectedMeasure,
                    allow_score: req_data?.allow_score ?? selectedMeasure.allow_score,
                    description: req_data?.description ?? selectedMeasure.description,
                    is_active: req_data?.is_active ?? selectedMeasure.is_active,
                    is_drift_enabled: req_data?.is_drift_enabled ?? selectedMeasure.is_drift_enabled,
                    is_positive: req_data?.is_positive ?? selectedMeasure.is_positive,
                    name: req_data?.name ?? selectedMeasure.name
                };
                if (dimension_name) {
                    selectedMeasure = {
                        ...selectedMeasure,
                        dimension_name: dimension_name
                    };
                }
                state.measures.splice(up_index, 1, selectedMeasure);
                const dependentMeasures = ['short_pattern', 'long_pattern'];
                if (dependentMeasures.indexOf(selectedMeasure?.technical_name) > -1) {
                    state.measures = state.measures.map((measure) => {
                        if (dependentMeasures.indexOf(measure?.technical_name) > -1) {
                            measure = {
                                ...measure,
                                is_active: selectedMeasure.is_active
                            };
                        }
                        return measure;
                    });
                }
            }
            state.isLoading = false;
        },
        updateMeasureFailure(state) {
            state.isLoading = false;
        },
        deleteMeasureRequest() { },
        deleteMeasureSuccess(state, action) {
            const measure_id = action.payload;
            state.measures = state.measures.filter((m) => m.id !== measure_id);
        },
        deleteMeasureFailure() { },
        getMeasureRequest(state) {
            state.isLoading = true;
            state.measures = [];
        },
        getMeasureSuccess(state, action) {
            let measures = action.payload.data || [];
            measures = measures.map((measure) => {
                let dimension = measure.dimension || [];
                dimension = typeof (dimension) === "string" ? JSON.parse(dimension) : dimension;

                let primary_columns = measure.primary_columns || [];
                primary_columns = typeof (primary_columns) === "string" ? JSON.parse(primary_columns) : primary_columns;

                const invalid_records = measure.allow_score ? measure.invalid_records : "NA";
                const valid_records = measure.allow_score ? measure.valid_records : "NA";
                const total_records = measure.allow_score ? measure.total_records : "NA";

                return {
                    ...measure,
                    is_custom: !measure.is_default,
                    dimension,
                    primary_columns,
                    invalid_records,
                    valid_records,
                    total_records
                };
            });
            if (state.sortBy) {
                measures = _.orderBy(measures, [state.sortBy], [state.orderBy]);
            }
            state.measures = [...measures];
            state.detail = {
                is_active: true,
                is_auto: true,
                is_drift_enabled: true,
                is_positive: true,
                allow_score: true,
                type: 'custom',
                category: 'conditional',
                run_status: 'Completed'
            };
            state.isLoading = false;
        },
        getMeasureFailure(state) {
            state.measures = [];
        },
        measureSorting(state, action) {
            const { sortBy, orderBy } = action.payload;
            let measureList = [...state.measures];
            measureList = _.orderBy(measureList, [sortBy], [orderBy]);
            state.measures = measureList;
            state.sortBy = sortBy;
            state.orderBy = orderBy;
        },
        updateMeasureProperty(state, action) {
            const { id, ...rest } = action.payload;
            const index = state.measures.findIndex((measure) => measure.id === id);
            if (index !== -1) {
                state.measures[index] = {
                    ...state.measures[index],
                    ...rest
                };
            }
        },
        updateMeasureDetail(state, action) {
            state.detail = { ...state.detail, ...action.payload };
        },
        clearMeasureDetailState(state) {
            state.detail = {
                is_active: true,
                is_auto: true,
                is_drift_enabled: true,
                is_positive: true,
                allow_score: true,
                type: 'custom',
                category: 'conditional',
                run_status: 'Completed'
            };
            state.isLoading = false;
        },
        getMeasureDetailRequest(state, action) {
            state.isLoading = true;
            const id = action?.payload ?? null;
            const index = state.measures.findIndex((measure) => measure.id === id);
            if (index !== -1) {
                state.detail = {
                    id: id,
                    measure_id: id,
                    name: state.measures[index].name || '',
                    description: state.measures[index].description || ''
                };
            }
        },
        getMeasureDetailSuccess(state, action) {
            state.detail = { ...state.detail, ...action.payload?.data ?? {} };
            if (action.payload?.data?.name !== "Volume") {
                state.measureType = appConstants.labels.quality.volumeTypes[0];
            }
            state.isLoading = false;
        },
        getMeasureDetailFailure(state) {
            state.isLoading = false;
        },
        updateAttributeEnumMeasureRequest() { },
        updateAttributeEnumMeasureSuccess() { },
        updateAttributeEnumMeasureFailure() { },
        getAssetMeasureRequest(state, action) {
            state.assetLevelLoading = true;
            state.assetLevelSearch = { ...state.assetLevelSearch, ...action.payload.params };
            const clear = action.payload?.clear ?? false;
            state.reloadMeasures = false;
            if (clear) {
                state.assetMeasures = [];
                state.loadMore = true;
            }
        },
        getAssetMeasureSuccess(state, action) {
            state.assetLevelSearch = { ...state.assetLevelSearch, ...action.payload.params };
            let asset_measures = action.payload.data.measures;
            state.assetMeasureCount = action.payload.data.count;
            asset_measures = asset_measures.map((data) => {
                let dimension = data.dimension || [];
                dimension = typeof (dimension) === "string" ? JSON.parse(dimension) : dimension;

                let primary_columns = data.primary_columns || [];
                primary_columns = typeof (primary_columns) === "string" ? JSON.parse(primary_columns) : primary_columns;
                const upper_threshold = data.upper_threshold?.toString();
                const lower_threshold = data.lower_threshold?.toString();
                const invalid_rows = data.allow_score ? data.invalid_rows : "NA";
                const valid_rows = data.allow_score ? data.valid_rows : "NA";
                const row_count = data.allow_score ? data.row_count : "NA";

                return {
                    ...data,
                    next_run: data.next_run ? moment(data.next_run) : null,
                    alerts: data.alerts || "NA",
                    dimension: dimension.length ? [{ ...dimension[0] }] : [],
                    primary_columns,
                    upper_threshold,
                    lower_threshold,
                    invalid_rows,
                    valid_rows,
                    row_count
                };
            });
            if ((asset_measures.length < state.assetLevelSearch.limit)) {
                state.loadMore = false;
            }
            state.assetMeasures = [...state.assetMeasures, ...asset_measures];
            state.assetLevelLoading = false;
        },
        getAssetMeasureFailure(state) {
            state.assetLevelLoading = false;
        },
        updateAssetMeasureProperty(state, action) {
            const { id, ...rest } = action.payload;
            if (id) {
                const index = state.assetMeasures.findIndex((measure) => measure.id === id);
                if (index > -1) {
                    state.assetMeasures[index] = {
                        ...state.assetMeasures[index],
                        ...rest
                    };
                }
            } else {
                const isScore = "allow_score" in action.payload || "is_positive" in action.payload;
                state.assetMeasures = state.assetMeasures.map((measure) => {
                    let data = {
                        ...measure
                    };
                    const isDisabled = isScore ? disableFlags({ ...measure, is_active: true }, { key: "allow_score" }) : false;
                    if (!isDisabled) {
                        data = {
                            ...measure,
                            ...action.payload
                        };
                    }
                    return data;
                });
            }
        },
        updateAssetMeasureRequest(state) {
            state.assetMeasures = [];
            state.loadMore = true;
            state.assetLevelLoading = true;
        },
        updateAssetMeasureSuccess(state) {
            state.assetLevelLoading = false;
        },
        updateAssetMeasureFailure(state) {
            state.assetLevelLoading = false;
        },
        deleteAssetMeasureRequest() { },
        updateStandAloneMeasureRequest(state) {
            state.isLoading = true;
        },
        updateStandAloneMeasureSuccess(state, action) {
            const { req_data, response } = action.payload;
            const id = response?.id ?? '';
            // const dimension_name = response?.dimension_name ?? '';
            const up_index = state.assetMeasures.findIndex((m) => m.id === id);
            if (up_index > -1) {
                let selectedMeasure = state.assetMeasures[up_index];
                selectedMeasure = {
                    ...selectedMeasure,
                    allow_score: req_data?.allow_score ?? selectedMeasure.allow_score,
                    description: req_data?.description ?? selectedMeasure.description,
                    is_active: req_data?.is_active ?? selectedMeasure.is_active,
                    is_drift_enabled: req_data?.is_drift_enabled ?? selectedMeasure.is_drift_enabled,
                    is_positive: req_data?.is_positive ?? selectedMeasure.is_positive,
                    name: req_data?.name ?? selectedMeasure.name
                };

                state.assetMeasures.splice(up_index, 1, selectedMeasure);
            }
            state.isLoading = false;
        },
        updateStandAloneMeasureFailure() { },
        deleteAssetMeasureSuccess(state, action) {
            const index = state.assetMeasures.findIndex((measure) => measure.id === action.payload);
            if (index !== -1) {
                state.assetMeasures.splice(index, 1);
            }
        },
        deleteAssetMeasureFailure() { },
        createStandaloneMeasureRequest(state) {
            state.isLoading = true;
        },
        createStandaloneMeasureSuccess(state, action) {
            const data = action.payload.data ?? {};
            const dimension = data.dimension || [];
            const stewards = data.stewards || [];
            data.dimension = typeof (dimension) === "string" ? JSON.parse(dimension) : dimension;
            data.level = "measure";
            state.detail = { ...state.detail, id: data.id, stewards, level: "measure" };
            state.assetMeasures.unshift(data);
            state.isLoading = false;
        },
        createStandaloneMeasureFailure(state) {
            state.isLoading = false;
        },
        getMeasurePreviewRequest(state) {
            state.isLoading = true;
            state.failedRows = [];
        },
        getMeasurePreviewSuccess(state, action) {
            state.isLoading = false;
            state.failedRows = action.payload.data || [];
        },
        getMeasurePreviewFailure(state) {
            state.isLoading = false;
            state.failedRows = [];
        },
        sortFailedRows(state, action) {
            const { sortBy, orderBy } = action.payload;
            let failedRows = [...state.failedRows];
            failedRows = _.orderBy(failedRows, [sortBy], [orderBy]);
            state.failedRows = failedRows;
            state.sortBy = sortBy;
            state.orderBy = orderBy;
            state.failedRowsConfig = { sortBy, orderBy };
        },
        clearFailedRows(state) {
            state.failedRows = [];
        },
        clearMeasureList(state) {
            state.measures = [];
            state.assetMeasures = [];
        },
        exportAssetMeasureRequest(state) {
            state.exportLoading = true;
        },
        exportAssetMeasureSuccess(state) {
            state.exportLoading = false;
        },
        exportAssetMeasureFailure(state) {
            state.exportLoading = false;
        },
        assetMeasureSorting(state, action) {
            state.assetLevelSearch = { ...state.assetLevelSearch, ...action.payload };
        },
        syncProfileMeasuresRequest() { },
        syncProfileMeasuresSuccess() { },
        syncProfileMeasuresFailure() { },
        validateMeasureRequest(state) {
            state.query_result.isLoading = true;
            state.query_result.data = [];
            state.query_result.headers = [];
            state.queryError = "";
        },
        validateMeasureSuccess(state, action) {
            const data = action?.payload?.data ?? [];
            const headers = [];
            if (data && data.length) {
                for (const key in data[0]) {
                    const header = {
                        key: key,
                        name: key || 'Results',
                        sorting: true,
                        isSearch: true,
                        searchComponent: "text",
                        searchKey: key
                    };
                    headers.push(header);
                }
            }

            state.query_result = {
                headers: headers,
                data: data
            };
            state.query_result.isLoading = false;
            state.querySubmit = true;
        },
        validateMeasureFailure(state, action) {
            state.isLoading = false;
            state.query_result.isLoading = false;
            state.queryError = action.payload;
        },
        clearValidateQueryState(state) {
            state.queryError = "";
            state.query_result = {
                headers: [],
                data: [],
                isLoading: false
            };
            state.querySubmit = false;
        },
        updateCustomMeasuresRequest() { },
        updateCustomMeasuresSuccess() { },
        updateCustomMeasuresFailure() { },
        getMeasureStatisticsRequest() { },
        getMeasureStatisticsSuccess(state, action) {
            const data = action.payload.data || {};
            state.statistics = {
                ...state.statistics,
                ...data
            };
        },
        getMeasureStatisticsFailure() { },
        updateMeasureJobStatus(state, action) {
            const { status } = action.payload;
            state.detail.run_status = status ? status : state.detail.run_status;
        },
        getMeasureStatusRequest() { },
        getMeasureStatusFailure() { },
        getMeasureStatusSuccess(state, action) {
            const status = action.payload.data;
            state.detail.run_status = status?.run_status
                ? status.run_status
                : state.detail.run_status;
        },
        toggleMeasures(state, action) {
            const { measures, is_active } = action.payload;
            state.measures = state.measures.map((measure) => {
                if (measures.indexOf(measure.technical_name) > -1) {
                    measure.is_active = is_active;
                }
                return measure;
            });
            return state;
        },
        exportFailedRowRequest(state) {
            state.exportPreviewLoading = true;
        },
        exportFailedRowSuccess(state) {
            state.exportPreviewLoading = false;
        },
        exportFailedRowFailure(state) {
            state.exportPreviewLoading = false;
        },
        clearFilter(state) {
            state.assetLevelSearch = { sortBy: "default", orderBy: "desc", offset: 0, limit: 20, search_by: "" };
            state.assetMeasures = [];
        },
        getComparisonMeasureRequest() { },
        getComparisonMeasureSuccess(state, action) {
            if (action.payload?.request?.source_asset && action.payload?.request?.target_asset) {
                const g_key = `${action.payload?.request?.source_asset}_${action.payload?.request?.target_asset}`;
                state.comparisonMeasures = {
                    ...state.comparisonMeasures,
                    [g_key]: action.payload?.response?.data ?? []
                };
            }
        },
        getComparisonMeasureFailure() { },
        clearComparisonMeasure(state) {
            state.comparisonMeasures = {};
        },
        updateMeasureType(state, action) {
            state.measureType = action.payload;
        },
        getMeasureRunStatusRequest() { },
        getMeasureRunStatusSuccess(state, action) {
            const runningMeasures = action.payload.data || [];
            let assetMeasures = state.assetMeasures;
            if (runningMeasures?.length > 0) {
                for (const measure of runningMeasures) {
                    const index = assetMeasures.findIndex((item) => item.id === measure.id);
                    if (index <= -1) {
                        continue;
                    }
                    const selectedMeasure = { ...assetMeasures[index] };
                    selectedMeasure.run_status = measure.status;
                    assetMeasures.splice(index, 1, { ...selectedMeasure });
                    if (state.detail?.id === measure.id) {
                        state.detail = { ...state.detail, run_status: measure.status };
                    }
                }
            } else {
                assetMeasures = assetMeasures.map((measure) => {
                    return {
                        ...measure,
                        run_status: "Completed"
                    };
                });
            }
            state.assetMeasures = [...assetMeasures];
        },
        getMeasureRunStatusFailure() { },
        updateMeasuresMetadata(state, action) {
            const payload = action.payload;
            let assetMeasures = state.assetMeasures;
            assetMeasures = assetMeasures.map((measure) => {
                const updatedProperties = {};
                if (measure.attribute_id === payload.attribute_id) {
                    if ("selected_term" in payload) {
                        updatedProperties.term = payload.selected_term;
                    }
                    if ("selected_tags" in payload) {
                        updatedProperties.tags = payload.selected_tags;
                    }
                }

                if (measure.version_id === payload.id) {
                    if ("selected_application" in payload) {
                        updatedProperties.applications = [...payload.selected_application];
                    }
                    if ("selected_domains" in payload) {
                        updatedProperties.domains = [...payload.selected_domains];
                    }
                }
                return { ...measure, ...updatedProperties };
            });
            state.assetMeasures = [...assetMeasures];
        },
        getUpdatedAssetMeasureRequest(state) {
            state.reloadMeasures = true;
        },
        resetAssetMeasureSearch(state) {
            state.assetLevelSearch = { sortBy: "default", orderBy: "desc", offset: 0, limit: 20, search_by: "" };
        }
    }
});

export const { createMeasureRequest, createMeasureSuccess, createMeasureFailure, updateMeasureRequest, updateMeasureSuccess, updateMeasureFailure,
    deleteMeasureRequest, deleteMeasureSuccess, deleteMeasureFailure, getQualityRuleFailure, getMeasureRequest, getMeasureSuccess, getMeasureFailure,
    measureSorting, updateMeasureProperty, updateMeasureDetail, clearMeasureDetailState, getMeasureDetailRequest, getMeasureDetailSuccess, getMeasureDetailFailure,
    updateAttributeEnumMeasureRequest, updateAttributeEnumMeasureSuccess, updateAttributeEnumMeasureFailure, getAssetMeasureRequest, getAssetMeasureSuccess, getAssetMeasureFailure,
    updateAssetMeasureProperty, updateAssetMeasureRequest, updateAssetMeasureSuccess, updateAssetMeasureFailure, getMeasurePreviewRequest, getMeasurePreviewSuccess, getMeasurePreviewFailure,
    sortFailedRows, clearFailedRows, exportAssetMeasureRequest, exportAssetMeasureSuccess, exportAssetMeasureFailure, assetMeasureSorting, clearMeasureList,
    syncProfileMeasuresRequest, syncProfileMeasuresSuccess, syncProfileMeasuresFailure, clearValidateQueryState, validateMeasureRequest, validateMeasureSuccess, validateMeasureFailure,
    updateCustomMeasuresRequest, updateCustomMeasuresSuccess, updateCustomMeasuresFailure, deleteAssetMeasureRequest, deleteAssetMeasureSuccess, deleteAssetMeasureFailure,
    createStandaloneMeasureRequest, createStandaloneMeasureSuccess, createStandaloneMeasureFailure, updateStandAloneMeasureRequest, updateStandAloneMeasureSuccess, updateStandAloneMeasureFailure,
    getMeasureStatisticsRequest, getMeasureStatisticsSuccess, getMeasureStatisticsFailure, updateMeasureJobStatus, getMeasureStatusRequest, getMeasureStatusSuccess, getMeasureStatusFailure,
    getComparisonAttributeMeasureRequest, getComparisonAttributeMeasureSuccess, getComparisonAttributeMeasureFailure, getComparisonMeasureRequest, getComparisonMeasureSuccess, getComparisonMeasureFailure,
    toggleMeasures, exportFailedRowRequest, exportFailedRowSuccess, exportFailedRowFailure, clearFilter,
    updateMeasureType, getMeasureRunStatusRequest, getMeasureRunStatusSuccess, getMeasureRunStatusFailure, updateMeasuresMetadata, getUpdatedAssetMeasureRequest,
    resetAssetMeasureSearch
} = measureSlice.actions;
export default measureSlice.reducer;