const style = (theme) => ({
    filterContainer: {
        paddingLeft: '6px',
        width: 300,
        '& .MuiButton-text': {
            color: theme.palette.secondary.main
        }
    },
    filterDetail: {
        padding: 16,
        '& .MuiButton-root': {
            '&:hover': {
                '& .ChevLeftIcon path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .MuiButton-text': {
            color: theme.palette.greyshades.darkgrey,
            '&:hover': {
                color: theme.palette.secondary.main
            }
        },
        '& .selectComponent .MuiInput-root': {
            marginTop: '0px !important'
        }
    },
    calendarIconDiv: {
        border: '1px solid rgba(0, 0, 0, 0.12) !important',
        borderRadius: '0px !important',
        height: '31.5px',
        width: '38.77px',
        borderLeft: 'none !important',
        borderTopRightRadius: '4px !important',
        borderBottomRightRadius: '4px !important',
        backgroundColor: '#fff !important',
        '&.active': {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: `#fff !important`
        },
        '&.active svg.CalendarIcon': {
            width: '18px',
            height: '18px',
            '& path, circle, ellipse': {
                fill: `#fff !important`
            }
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
        },
        '& svg.CalendarIcon': {
            width: '18px',
            height: '18px',
            '& path, circle, ellipse': {
                fill: `${theme.palette.greyshades.darkgrey} !important`
            }
        }
    },
    daysFilter: {
        background: '#fff',
        marginLeft: 20,
        '& .MuiToggleButtonGroup-grouped:last-of-type': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        },
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    },
    calendarPopper: {
        padding: 30,
        maxWidth: '470px',
        '& .MuiOutlinedInput-input': {
            padding: '9.5px 14px'
        }
    }
});

export default style;