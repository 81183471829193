// Default Imports
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Chip, IconButton, Stack, Typography, Backdrop, Grid, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { TextValidator } from 'react-material-ui-form-validator';

// Import Styles
import style from "./style.jsx";

// Import Component
import ListChipItems from './components/list.jsx';
import AutoCompleteComponent from '../autoComplete/index.jsx';

// Import Images
import { PlusIcon } from '../../assets/svg/index.js';
import AcceptRejectIcon from './components/acceptRejectIcon.jsx';
import { TimePickerComponent } from '../index.js';
import { isValidTimeFormat } from '../../helpers/appHelpers.js';

const ChipComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, className, chipClassName, dirction, spacing, data, labelKey, limit, editable, haveDataType, onChipAdd, onChipRemove,
        chipClass, add, onChipSelect, availableList, placeholder, handleChipEvent, filterCondition, addType, isrequired,
        disableInput, handleChipSelectionClose, haveColor, autoCompleteDataTypeOption, addLimit, isChipDelete, addText, stringChip,
        acceptActions, onHandleAcceptAction, disable_link, enableLink, disableCloseOnSelect, noId, toolTipName, disabled, showNA, enableDelete,
        deleteWithIndex
    } = props;

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [isAddNewChip, setAddNewChip] = useState(false);
    const [autocompleteInput, setAutoCompleteInput] = useState("");
    const [chipValue, setChipValue] = useState("");
    const [manualTime, setManualTime] = useState(null);

    /**
     * Handle Chip Delete
     * @param {*} chipId
     */
    const handleDelete = (chipId, cur_index) => {
        setManualTime(null);
        if (noId) {
            const listItems = [...data];
            listItems.splice(cur_index, 1);
            handleChipEvent(listItems);
        } else {
            if (!isChipDelete) {
                const listItems = [...data];
                let index = chipId.id ? listItems.findIndex((data) => data.id === chipId.id) : cur_index;
                if (deleteWithIndex) {
                    index = cur_index;
                }
                listItems.splice(index, 1);
                handleChipEvent(listItems);
            } else {
                onChipRemove(chipId);
            }
        }
    };

    /**
     * Returns the lable value based on the given key
     * @param {*} item
     * @returns
     */
    const getLabel = (item) => {
        return labelKey ? item[labelKey] : item;
    };

    /**
     * Returns the lable value based on the given key
     * @param {*} item
     * @returns
     */
    const getCustomLabel = (item) => {
        if (labelKey && (typeof (item) === "object") && "customToolTipName" in item) {
            return item.customToolTipName;
        }
        return labelKey ? item[labelKey] : item;
    };


    /**
     * Handle Add Button
     * @param {*} event
     */
    const handlePlusButton = (event) => {
        if (!disableInput) {
            setAddNewChip(true);
        }
        onChipSelect(null, event);
    };

    /**
     * Handle ChipValue Change
     * @param {*} event
     */
    const handleChange = (event) => {
        setChipValue(event.target.value);
    };

    /**
     * Handle Chip Time Change
     * @param {*} event
     */
    const handleTimeChange = (time) => {
        onChipAdd(time);
        setChipValue("");
        setAddNewChip(false);
    };


    /**
     * Handle Chip Time Change
     * @param {*} event
     */
    const handleManualTimeChange = (time) => {
        setManualTime(time);
    };

    /**
     * Handle OnChange
     * @param {*} selectedItem
     */
    const onChange = (event, selectedItem, inputType = "default") => {
        if (selectedItem && ((selectedItem.id) || (stringChip && selectedItem.trim()))) {
            const listItems = [...data];
            listItems.push({ ...selectedItem });
            handleChipEvent(listItems);
            if (addLimit && addLimit === listItems.length) {
                setAddNewChip(false);
            }
        } else {
            if (inputType === "withinput") {
                event.preventDefault();
                setAutoCompleteInput("");
                const userInput = { "id": selectedItem, "name": selectedItem };
                const listItems = [...data];
                listItems.push({ ...userInput });
                handleChipEvent(listItems);
                if (addLimit && addLimit === listItems.length) {
                    setAddNewChip(false);
                }
            }
        }
    };

    /**
     * Handle input KeyDown
     * @param {*} event
     * @returns
     */
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const duplicatedValues = data.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setChipValue("");
                return;
            }

            if (!event.target.value.replace(/\s/g, "").length) { return; }

            onChipAdd(event.target.value);
            setChipValue("");
            setAddNewChip(false);
        }
    };

    /**
     * FIlter Chip Values
     * @param {*} list
     * @param {*} filterCondition
     * @returns list
     */
    const filterList = (list = [], filterCondition = false) => {
        if (filterCondition) {
            list = list.filter((obj) => !obj.is_default);
        }
        return list;
    };

    /**
     * Filter Selection List
     * @param {*} list
     * @param {*} selectedValue
     * @returns {Array}
     */
    const filterSelectionAvailableList = (list = [], selectedValue = []) => {
        if ((selectedValue instanceof String)) {
            selectedValue = JSON.parse(selectedValue);
        }
        selectedValue = selectedValue?.filter((obj) => obj).map((obj) => obj[labelKey]);
        return list.filter((obj) => !selectedValue?.includes(obj[labelKey]));

    };

    /**
     * Get Color For Chip
     * @param {*} list
     * @param {*} id
     * @returns
     */
    const getColor = (item) => {
        if (item?.color) {
            return item.color;
        }
        if (availableList?.length) {
            const color = availableList.find(((i) => i.id === item.id))?.color ?? '';
            return color;
        }
        return '';
    };

    /**
     * Filter Using UseMemo
     */
    const list = useMemo(() => filterList(data, filterCondition), [data, filterCondition]);
    const filterAvailableList = useMemo(() => filterSelectionAvailableList(availableList, data), [availableList, data]);


    const ManualTimeEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (isValidTimeFormat(manualTime)) {
                onChipAdd(manualTime);
                setChipValue("");
                setAddNewChip(false);
            }
        }
    };


    const renderInput = () => {
        switch (addType) {
            case 'autocomplete':
                return (
                    <Fragment>
                        <AutoCompleteComponent
                            variant="standard"
                            data={filterAvailableList || []}
                            onChange={(event, newValue) => onChange(event, newValue)}
                            placeholder={placeholder}
                            customOption
                            labelKey={labelKey}
                            fullWidth
                            style={{ zIndex: 1 }}
                            onInputChange={(event) => setAutoCompleteInput(event?.target?.value ?? '')}
                            onClose={() => handleChipSelectionClose()}
                            inputValue={autocompleteInput || ""}
                            disableClearable
                            dataTypeIcon={autoCompleteDataTypeOption}
                            renderType={'chips'}
                            disableCloseOnSelect={disableCloseOnSelect}
                        />
                        <Backdrop style={{ opacity: 0 }} open={isAddNewChip} onClick={() => setAddNewChip(false)} />
                    </Fragment>
                );
            case 'autocompletewithinput':
                return (
                    <Fragment>
                        <AutoCompleteComponent
                            variant="standard"
                            data={filterAvailableList || []}
                            onChange={(event, newValue) => onChange(event, newValue, "withinput")}
                            placeholder={placeholder}
                            customOption
                            labelKey={labelKey}
                            fullWidth
                            style={{ zIndex: 1 }}
                            onInputChange={(event) => setAutoCompleteInput(event?.target?.value ?? '')}
                            onClose={() => handleChipSelectionClose()}
                            inputValue={autocompleteInput || ""}
                            disableClearable
                            dataTypeIcon={autoCompleteDataTypeOption}
                            renderType={'chips'}
                            disableCloseOnSelect={disableCloseOnSelect}
                        />
                        <Backdrop style={{ opacity: 0 }} open={isAddNewChip} onClick={() => setAddNewChip(false)} />
                    </Fragment>
                );
            case 'timepicker':
                return (
                    <Grid
                        onKeyDown={(event) => ManualTimeEnter(event)}>
                        <TimePickerComponent
                            variant="standard"
                            placeholder="Select a Time"
                            color="secondary"
                            value={chipValue || ""}
                            onAccept={
                                (time) =>
                                    handleTimeChange(
                                        time
                                    )
                            }
                            onChange={
                                (time) =>
                                    handleManualTimeChange(
                                        time
                                    )
                            }
                        />
                    </Grid>
                );
            default:
                return (
                    <Grid className={classes.outLineNone} onMouseLeave={() => setAddNewChip(false)}>
                        <TextValidator
                            autoComplete={"off"}
                            required={isrequired}
                            value={chipValue}
                            placeholder={placeholder}
                            onChange={(event) => handleChange(event)}
                            onKeyDown={(event) => handleKeyDown(event)} />
                    </Grid>
                );
        }
    };

    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (disabled) {
            setAddNewChip(false);
        }
    }, [disabled]);

    return (
        <Grid className="chipContainer">
            <Stack
                direction={dirction}
                spacing={spacing}
                className={`chips ${classes.chips} ${className}`}
            >
                {
                    (addType === "text" ? !isAddNewChip : true) &&
                    <React.Fragment>
                        {
                            !haveDataType && list.slice(0, limit).map((item, index) => {
                                return (
                                    <Tooltip title={toolTipName ? `${toolTipName} : ${getCustomLabel(item)}` : getCustomLabel(item)} arrow key={`chip-${index}`}>
                                        <Chip
                                            className={`${chipClass} ${chipClassName}`}
                                            deleteIcon={(acceptActions && !item.approval_id) ? <AcceptRejectIcon onHandleAction={(status) => onHandleAcceptAction(status, item)} /> : <CloseIcon />}
                                            key={`chip-${index}`}
                                            label={getLabel(item)}
                                            style={{ backgroundColor: haveColor ? `${getColor(item)}25` : '' }}
                                            sx={
                                                {
                                                    '& .MuiChip-label': {
                                                        color: `${haveColor ? `${getColor(item)}` : ''}`
                                                    }
                                                }
                                            }
                                            onClick={(!disable_link) && (editable || enableLink) ? (event) => { onChipSelect(item, event); } : null}
                                            onDelete={((editable || (acceptActions && !item.approval_id)) && (!enableDelete || (enableDelete && enableDelete(item)))) ? () => { handleDelete(item, index); } : null} />
                                    </Tooltip>
                                );
                            }
                            )
                        }
                        {
                            haveDataType && list.slice(0, limit).map((item, index) =>
                                <Chip
                                    key={`chip-${index}`}
                                    deleteIcon={acceptActions ? <AcceptRejectIcon /> : <CloseIcon />}
                                    style={{ backgroundColor: haveColor ? getColor(item.id) : '' }}
                                    label={
                                        <Typography variant="body2">
                                            <span className={"dataType"}>
                                                {item.derived_type && item.derived_type.charAt(0) || ''}
                                            </span>
                                            {' '}
                                            {getLabel(item)}
                                        </Typography>
                                    }
                                    onDelete={(editable && (!enableDelete || (enableDelete && enableDelete(item)))) ? () => { handleDelete(item, index); } : null} />
                            )
                        }
                        <Grid>
                            {(data.length > limit) ? <Chip className={"moreCount"} label={`+${data.length - limit}`} onClick={(event) => setAnchorEl(event?.currentTarget?.parentElement ?? event.currentTarget)} /> : null}
                        </Grid>
                    </React.Fragment>
                }

                {
                    add && !isAddNewChip &&
                    <Grid className="dflex alignCenter addContainer">
                        <Tooltip title="Add" arrow>
                            <IconButton className="p0 plusIcon" style={{ cursor: disabled ? 'default' : 'pointer' }} onClick={disabled ? null : (event) => handlePlusButton(event)}>
                                <PlusIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body1" style={{ cursor: disabled ? 'default' : 'pointer' }} onClick={disabled ? null : (event) => handlePlusButton(event)} className={`${classes.textSecondary} pt1 pl-1`}>
                            {addText}
                        </Typography>
                    </Grid>
                }
                {
                    (!add && showNA && data?.length === 0) &&
                    <Typography>
                        {"NA"}
                    </Typography>
                }
                {isAddNewChip && renderInput()}
                {
                    Boolean(anchorEl) &&
                    <ListChipItems
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        editable={editable}
                        data={data}
                        labelKey={labelKey}
                        handleDelete={handleDelete}
                        onChipSelect={onChipSelect}
                        onClose={() => setAnchorEl(null)}
                        enableDelete={enableDelete}
                        acceptActions={acceptActions}
                        onHandleAcceptAction={(status, item) => onHandleAcceptAction(status, item)}
                        disable_link={disable_link}
                    />
                }
            </Stack>
        </Grid>
    );
};


/**
 * Define Prop Types
 */
ChipComponent.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    chipClassName: PropTypes.string,
    labelKey: PropTypes.string,
    data: PropTypes.array,
    limit: PropTypes.number,
    editable: PropTypes.bool,
    dirction: PropTypes.string,
    chipClass: PropTypes.string,
    spacing: PropTypes.number,
    haveDataType: PropTypes.bool,
    onChipRemove: PropTypes.func,
    onChipAdd: PropTypes.func,
    add: PropTypes.bool,
    onChipSelect: PropTypes.func,
    availableList: PropTypes.array,
    placeholder: PropTypes.string,
    handleChipEvent: PropTypes.func,
    filterCondition: PropTypes.bool,
    addType: PropTypes.string,
    isrequired: PropTypes.bool,
    disableInput: PropTypes.bool,
    handleChipSelectionClose: PropTypes.func,
    haveColor: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    autoCompleteDataTypeOption: PropTypes.bool,
    addLimit: PropTypes.number,
    isChipDelete: PropTypes.bool,
    addText: PropTypes.string,
    stringChip: PropTypes.bool,
    acceptActions: PropTypes.bool,
    onHandleAcceptAction: PropTypes.func,
    disable_link: PropTypes.bool,
    enableLink: PropTypes.bool,
    noId: PropTypes.bool,
    toolTipName: PropTypes.string,
    disabled: PropTypes.bool,
    showNA: PropTypes.bool,
    enableDelete: PropTypes.func,
    deleteWithIndex: PropTypes.bool
};

/**
 * Set Default Values
 */
ChipComponent.defaultProps = {
    classes: {},
    className: "",
    labelKey: "",
    data: [],
    limit: 10,
    editable: false,
    dirction: "row",
    spacing: 1,
    haveDataType: false,
    add: false,
    onChipAdd: () => { },
    onChipRemove: () => { },
    onChipSelect: () => { },
    availableList: [],
    placeholder: "",
    filterCondition: false,
    addType: "text",
    disableInput: false,
    handleChipSelectionClose: () => { },
    onHandleAcceptAction: () => { },
    haveColor: false,
    disableCloseOnSelect: true,
    autoCompleteDataTypeOption: false,
    addLimit: null,
    isChipDelete: false,
    stringChip: false,
    acceptActions: false,
    disable_link: false,
    enableLink: false,
    noId: false,
    toolTipName: "",
    disabled: false,
    showNA: false,
    enableDelete: null,
    deleteWithIndex: false
};

export default withStyles((theme) => ({
    ...style(theme)
}))(ChipComponent);