import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import { Grid, Typography, Tooltip } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';
import { CircularScoreComponent } from '../../components/index.js';

// Import Helpers
import { toRound } from '../../helpers/appHelpers.js';


function GaugeComponent(props) {

    const { classes, label, data, onChartEvent } = props;

    /**
     * Redirect
     * @param {*} item
     */
    const redirect = (item) => {
        onChartEvent(item);
    };

    return (
        <Grid item xs={12} className="h-100">
            <Grid container className="h-100">
                <Grid item xs={12} className="h-100 overflow-auto">
                    <Grid container spacing={7} className={`${classes.widgetItem} pt-2`} alignItems={'center'}>
                        {
                            data && data.map((item, index) =>
                                <Grid key={`${index}_dqscore`} item xs={3} className="dflex alignCenter">
                                    <Grid item className={classes.widgetListIcon}>
                                        <CircularScoreComponent
                                            size={55}
                                            value={item.score || item.widget_score || item.measure_score || 0}
                                            showPercentage
                                            noLink
                                            showValue />
                                    </Grid>
                                    <Grid item xs={12} className={classNames(classes.widgetItemDetail, "pointer")} onClick={() => redirect(item)}>
                                        <Typography variant="h2" className="pointer">
                                            {`${toRound(item.score || item.widget_score || item.measure_score || 0)}%`}
                                        </Typography>
                                        <Tooltip title={item[label] || ""} arrow>
                                            <Typography variant="body1" className={classNames(classes.textSecondary, classes.overlap, "pointer")}>
                                                {item[label] || ""}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
GaugeComponent.defaultProps = {
    classes: {},
    data: {},
    label: "",
    onChartEvent: () => { }
};

// prop types
GaugeComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    label: PropTypes.string,
    onChartEvent: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.label, nextProps.label) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(GaugeComponent, areEqual));