const style = () => ({
    description: {
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #ffffff00 !important'
        }
    },
    topSection: {
        '& .chipContainer': {
            width: '100%'
        }
    }
});
export default style;