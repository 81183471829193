import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Import Images
import DQLabsLogo from '../../assets/img/dqlabs.png';
import { DangerIcon, IssuesIcon2 } from '../../assets/svg/index.js';

// Import Style
import LayoutStyles from '../../layouts/style.jsx';
import Style from './style.jsx';

// Import Components
import { AssetTypeIconComponent, CircularScoreComponent, TableComponent, LoaderComponent, NoResultComponent } from '../../components/index.js';

// Import Reducers
import { getAssetByExtensionRequest } from '../../redux/reducer/extensionReducer.js';

function ExtensionDetail(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const query_site_url = searchParams.get("search_url");
    const query_local_url = localStorage.getItem("extension_search_url");

    /**
     * Define Redux
     */
    const { assets, alerts, issues, isLoading } = useSelector((state) => state.extension);

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        setSearchParams({});
    };

    /**
     * Search Data By URL
     */
    useEffect(() => {
        if (query_site_url === "clear_search_key" || query_local_url === "query_local_url") {
            localStorage.removeItem("extension_search_url");
        } else {
            if (query_site_url) {
                dispatch(getAssetByExtensionRequest({ "url": query_site_url, "type": "url" }));
            }

            if (query_local_url) {
                dispatch(getAssetByExtensionRequest({ "url": query_local_url, "type": "url" }));
            }
        }
        removeSearchParam();
    }, [query_site_url, query_local_url]);

    /**
     * Alerts Table Headers and Columns
     */
    const tableAlertHeaders = [
        { key: 'message', name: 'Alert', tooltip: true, width: "75%" },
        { key: 'status', name: 'Status', width: "25%" }
    ];

    /**
     * Issues Table Headers and Columns
     */
    const tableIssuesHeaders = [
        { key: 'name', name: 'Name', tooltip: true, width: "75%" },
        { key: 'priority', name: 'Priority', width: "25%" }
    ];

    /**
     * Navigate to Asset
     * @param {*} asset_id
     */
    const navigateToAsset = (asset_id) => {
        let origin_url = window.location.origin;
        if (origin_url && asset_id) {
            origin_url += `/asset/${asset_id}`;
            window.open(origin_url, "_blank");
        }
    };

    return (
        <Grid className={classes.extensionContainer}>
            <Grid className={classes.drawerHeader}>
                <img
                    className={classes.headerLogo}
                    src={DQLabsLogo}
                    alt="app logo"
                />
            </Grid>
            <Grid className={classes.drawerBody}>
                <Grid className={classes.drawerBodyInner}>
                    {
                        !isLoading && assets.length > 0 &&
                        <Grid className={classes.infoContainer}>
                            {
                                assets && assets.map((item, index) =>
                                    <Grid container justifyContent={"space-between"} className={classes.assetHeader} key={`asset-${index}`} onClick={() => navigateToAsset(item.id)}>
                                        <Grid item>
                                            <Grid className={"titleSection pb-1"}>
                                                <AssetTypeIconComponent
                                                    asset_type={item.type}
                                                    connection_type={item.connection_type}
                                                />
                                                <Typography variant="h3" className={"pl-1 fw-500"}>
                                                    {item.name}
                                                </Typography>
                                            </Grid>
                                            <Grid container wrap="nowrap">
                                                <Grid item className="dflex alignCenter">
                                                    <IssuesIcon2 />
                                                    <Typography
                                                        variant="body1"
                                                        className="fw-400 pl-1"
                                                    >
                                                        {item.issues || 0}
                                                        {' '}
                                                        Issues
                                                    </Typography>
                                                </Grid>
                                                <Grid item className="dflex alignCenter pl-3">
                                                    <DangerIcon />
                                                    <Typography
                                                        variant="body1"
                                                        className="fw-400 pl-1"
                                                    >
                                                        {item.alerts || 0}
                                                        {' '}
                                                        Alerts
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className={"itemsRoot"}>
                                            <Grid item align="right">
                                                <CircularScoreComponent
                                                    size={50}
                                                    value={item.dqscore || 0}
                                                    showPercentage
                                                    noLink
                                                    showValue />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }

                            {
                                alerts && alerts.length > 0 &&
                                <Grid item xs={12} className={classes.tableRoot}>
                                    <TableComponent
                                        headers={tableAlertHeaders}
                                        data={alerts || []}
                                        title={"Recent 5 Alerts"}
                                        styleType="striped"
                                        NoResultText="No Alerts Found"
                                        height="200px"
                                        stickyHeader
                                    />
                                </Grid>

                            }
                            {
                                issues && issues.length > 0 &&
                                <Grid item xs={12} className={classes.tableRoot}>
                                    <TableComponent
                                        headers={tableIssuesHeaders}
                                        data={issues || []}
                                        title={"Recent 5 Issues"}
                                        styleType="striped"
                                        NoResultText="No Issues Found"
                                        height="200px"
                                        stickyHeader
                                    />
                                </Grid>
                            }
                        </Grid>
                    }
                    {isLoading && <LoaderComponent />}
                    {!isLoading && assets.length === 0 && <NoResultComponent />}
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
ExtensionDetail.defaultProps = {
    classes: {}
};

// prop types
ExtensionDetail.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ExtensionDetail);