import { combineEpics } from 'redux-observable';
import { authEpic } from './authEpic';
import { defaultEpic } from './defaultEpic';
import { connectionEpic } from './connectionEpic';
import { connectorEpic } from './connectorEpic';
import { organizationEpic } from './organizationEpic';
import { assetEpic } from './assetEpic';
import { attributeEpic } from './attributeEpic';
import { catalogEpic } from './catalogEpic';
import { conversationEpic } from './conversationEpic';
import { measureEpic } from './measureEpic';
import { semanticEpic } from './semanticEpic';
import { scheduleEpic } from './scheduleEpic';
import { securityEpic } from './securityEpic';
import { userEpic } from './userEpic';
import { configurationsEpic } from './configurationsEpic';
import { applicationEpic } from './applicationEpic';
import { tagsEpic } from './tagsEpic';
import { dimensionEpic } from './dimensionEpic';
import { domainsEpic } from './domainsEpic';
import { versionEpic } from './versionEpic';
import { metricEpic } from './metricEpic';
import { driftEpic } from './driftEpic';
import { issuesEpic } from './issuesEpic';
import { termEpic } from './termEpic';
import { baseMeasureEpic } from './baseMeasureEpic';
import { usageEpic } from './usageEpic';
import { integrationEpic } from './integrationEpic';
import { dashboardEpic } from './dashboardEpic';
import { logsEpic } from './logsEpic';
import { customWidgetEpic } from './customWidgetEpic';
import { fieldsEpic } from './fieldsEpic';
import { fieldPropertyEpic } from './fieldPropertyEpic';
import { importExportEpic } from './importExportEpic';
import { userActivityEpic } from './userActivityEpic';
import { extensionEpic } from './extensionEpic';
import { licenseEpic } from './licenseEpic';
import { reportsEpic } from './reportsEpic';
import { librariesEpic } from './librariesEpic';

const rootEpic = combineEpics(
    ...authEpic,
    ...defaultEpic,
    ...organizationEpic,
    ...connectionEpic,
    ...connectorEpic,
    ...assetEpic,
    ...attributeEpic,
    ...catalogEpic,
    ...conversationEpic,
    ...measureEpic,
    ...semanticEpic,
    ...scheduleEpic,
    ...securityEpic,
    ...userEpic,
    ...configurationsEpic,
    ...applicationEpic,
    ...tagsEpic,
    ...dimensionEpic,
    ...domainsEpic,
    ...versionEpic,
    ...metricEpic,
    ...driftEpic,
    ...issuesEpic,
    ...termEpic,
    ...baseMeasureEpic,
    ...integrationEpic,
    ...usageEpic,
    ...dashboardEpic,
    ...logsEpic,
    ...customWidgetEpic,
    ...fieldsEpic,
    ...fieldPropertyEpic,
    ...importExportEpic,
    ...userActivityEpic,
    ...extensionEpic,
    ...licenseEpic,
    ...reportsEpic,
    ...librariesEpic
);

export default rootEpic;