const style = (theme) => ({
    lineageContainer: {
        minHeight: 'calc(100vh - 390px)',
        width: "100%",
        fontFamily: theme.palette.headers.body1.fontFamily,
        color: theme.palette.headers.body1.color,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important',
        padding: '20px',
        borderRadius: '4px',
        "& .assetTypeIcon svg, .assetTypeIcon path": {
            fill: `${theme.palette.primary.main} !important`
        },
        "& .datatypeIcon": {
            color: `${theme.palette.secondary.main} !important`,
            background: theme.palette.primary.contrastText,
            height: '20px !important',
            width: '20px !important',
            fontSize: '14px',
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            textTransform: 'capitalize',
            flexShrink: 0,
            marginRight: '8px !important',
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '5px'
        },
        "& .datatypeSvg svg": {
            fill: `${theme.palette.secondary.main} !important`
        }
    },
    mappingListPopover: {
        minWidth: "300px",
        padding: 5,
        boxShadow: '0px 0px 4px rgb(237 237 237 / 90%) !important'
    }
});
export default style;