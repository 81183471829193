import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";

//  Import Components
import Widget from '../widget/index.jsx';

// Import Images
import { ResizeCorner } from '../../../../assets/svg/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';
import '../../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../../node_modules/react-resizable/css/styles.css';

// Import Redux
import { getDashboardDetailRequest, updateLayoutRequest, layoutUpdate, cleanWidgets } from '../../../../redux/reducer/dashboardReducer';

function Widgets(props) {

    /**
     * Define Props
     */
    const { classes, isDisabled } = props;
    const dispatch = useDispatch();
    const gridRef = useRef();
    const requestAbortControllerRef = useRef();

    const [width, setWidth] = useState(1600);

    /**
     * Redux Store
     */
    const { widgets, id: dashboardId } = useSelector(({ dashboard }) => dashboard);

    /**
     * Generate Layout
     * @param {*} data
     * @returns
     */
    const generateLayout = (data) => {
        return {
            lg: data.map((obj, index) => {
                return {
                    ...obj.layout || {},
                    y: obj.layout.y !== null ? obj.layout.y : Infinity,
                    i: `${index}_${obj.id}`
                };
            })
        };
    };
    const layouts = useMemo(() => generateLayout(widgets), [widgets]);


    /**
     * Get Dashboard Detail
     */
    useEffect(() => {
        if (dashboardId && !widgets.length) {
            if (requestAbortControllerRef && requestAbortControllerRef.current) {
                requestAbortControllerRef.current.abort();
            }
            requestAbortControllerRef.current = new AbortController();
            const token = { signal: requestAbortControllerRef?.current?.signal };
            dispatch(getDashboardDetailRequest({ dashboardId, token }));
        }
    }, [dashboardId]);

    useEffect(() => {
        return () => {
            dispatch(cleanWidgets());
        };
    }, []);

    /**
     * Set Width
     * @param {*} gridRef
     * @returns
     */
    useEffect(() => {
        if (gridRef?.current) {
            const width = gridRef?.current?.offsetWidth;
            setWidth(width);
        }
    }, [gridRef]);

    /**
     * Update Layout
     * @param {*} layout
     */
    const updateLayout = (layout) => {
        layout = layout.map((obj) => {
            const item = widgets[obj.i];
            return {
                data: { ...obj },
                id: item.id
            };
        });
        dispatch(updateLayoutRequest({ layout }));
        dispatch(layoutUpdate(layout));
    };


    /**
     * On Layout Drag
     * @param {*} layout
     */
    const onDragStop = (layout) => {
        updateLayout(layout);
    };

    /**
     * On Layout Resize
     * @param {*} layout
     */
    const onResizeStop = (layout) => {
        updateLayout(layout);
    };


    return (
        <div ref={gridRef}>
            <ResponsiveGridLayout
                className={`${classes.responsizeGrid} ${"layout"}`}
                layouts={layouts}
                margin={[15, 15]}
                useCSSTransforms
                transformScale={1}
                width={width}
                cols={{ lg: 12, md: 12, xxs: 12, sm: 12, xs: 12 }}
                onDragStop={onDragStop}
                onResizeStop={onResizeStop}
                autoSize
                isDraggable={!isDisabled}
                isResizable={!isDisabled}
                draggableCancel=".widgetOptions, .tableHeaderGrid, .MuiToolbar-root, .commentText, .noDrag, .rightActions, .pointer, path, svg, text"
            >
                {
                    widgets.map((widget, index) =>
                        <Grid key={index}
                            data-grid={{ ...widget.layout, y: widget.layout.y !== null ? widget.layout.y : Infinity }}
                            style={{ background: "#fff" }}>
                            <Widget data={widget} isDisabled={isDisabled} />
                            {
                                !isDisabled &&
                                <ResizeCorner />
                            }
                        </Grid>
                    )
                }
            </ResponsiveGridLayout>
        </div >
    );
}

// default props
Widgets.defaultProps = {
    classes: {},
    isDisabled: false
};

// prop types
Widgets.propTypes = {
    classes: PropTypes.object,
    isDisabled: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Widgets);