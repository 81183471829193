import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography
} from '@mui/material';

//  Import Components
import ListItem from '../components/listItem/index.jsx';

//  Import Styles
import StyleGuideColorShadesStyle from '../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';
import { ChevDownIcon } from '../../../../../../assets/svg/index.js';


function StyleGuideColorShades(props) {

    /**
     * Define Props
     */
    const { classes, data, onThemeChange } = props;

    /**
     * Define State
     */
    const [expanded, setExpanded] = React.useState(false);

    /**
     * Handle Change
     * @param {*} panel
     * @returns
     */
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**
     *
     * @param {*} style
     * @param {*} property
     * @param {*} value
     */
    const handleThemeChangeEvent = (property) => {
        onThemeChange({ ...data, ...property });
    };

    return (
        <Grid className={classes.themeList}>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ChevDownIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography variant="body1" className="fw-500">
                        Color Shades
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        Object.keys(data).map((item) => (
                            <ListItem
                                key={`color-${item}`}
                                styleName={item}
                                isColor={data[item]}
                                onThemeChange={handleThemeChangeEvent}
                            />
                        ))
                    }
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

// default props
StyleGuideColorShades.defaultProps = {
    classes: {},
    data: {},
    onThemeChange: () => { }
};

// prop types
StyleGuideColorShades.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onThemeChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...StyleGuideColorShadesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StyleGuideColorShades);