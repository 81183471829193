import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Component
import AssetTypeIconComponent from '../assetTypeIcon/index.jsx';

function searchSuggestionsComponent(props) {

    /**
     * Define Props
     */
    const { classes, suggestion, tracking, suggestionClickEvent, showTracking, search_key } = props;

    /**
     * Filter Search Suggestions
     * @param {*} data
     * @param {*} key
     * @returns
     */
    const filter_suggestion = (data = [], key = '') => {
        if (data && key) {
            data = data.filter((obj) => obj.search_key.includes(key));
        }
        return data;
    };


    /**
     * Filter Using UseMemo
     */
    const suggestion_list = useMemo(() => filter_suggestion(suggestion, search_key), [suggestion, search_key]);

    return (
        <Grid className={classes.searchResultContainer}>
            <Typography variant="h6" className={`${classes.textSecondary} mb-1`}>
                {showTracking ? 'Trending Searches' : 'Frequently Searches'}
            </Typography>

            <Grid className={classes.searchListItem}>
                <Grid className="w-100">
                    {
                        showTracking && tracking.map((item, index) =>
                            <Grid
                                className="w-100 lisItem"
                                key={`tracking-${index}`}
                                onClick={
                                    (event) => {
                                        event.preventDefault();
                                        suggestionClickEvent(item, 'track');
                                    }
                                }
                            >
                                <Grid className="lisItemBtn dflex alignCenter">
                                    <Grid className="listIcon dflex">
                                        <AssetTypeIconComponent asset_type={item.type} />
                                    </Grid>
                                    <Typography variant="body1" className="searchListText">
                                        {item.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }

                    {
                        !showTracking && suggestion_list.map((item, index) =>
                            <Grid
                                className="w-100 lisItem"
                                key={`suggestion-${index}`}
                                onClick={
                                    (event) => {
                                        event.preventDefault();
                                        suggestionClickEvent(item, 'suggestion');
                                    }
                                }
                            >
                                <Grid className="lisItemBtn">
                                    <Typography variant="body1" className="searchListText">
                                        {item.search_key}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>

            {
                ((showTracking && tracking.length === 0) || (!showTracking && suggestion_list.length === 0)) &&
                <Typography variant="body1" className={'text-align-center'}>
                    No Suggestions Found
                </Typography>
            }

        </Grid>
    );
}

// default props
searchSuggestionsComponent.defaultProps = {
    classes: {},
    suggestion: [],
    tracking: [],
    showTracking: true,
    suggestionClickEvent: () => { },
    search_key: ""
};

// prop types
searchSuggestionsComponent.propTypes = {
    classes: PropTypes.object,
    suggestion: PropTypes.array,
    tracking: PropTypes.array,
    showTracking: PropTypes.bool,
    suggestionClickEvent: PropTypes.func,
    search_key: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(searchSuggestionsComponent);