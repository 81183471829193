// Import API Helper
import { getRequest, postRequest, putRequest, deleteRequest, buildFormData } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * Detail
 * @param {*} id
 * @returns
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.integration.url}${id}/`);
    return response;
}

/**
 * Create
 * @param {*} params
 * @returns
 */
async function create(params) {
    let formData = params;
    if (params.type !== "api") {
        formData = new FormData();
        formData = buildFormData(formData, params);
    }
    const response = await postRequest(urlConstants.integration.url, formData, params.type !== "api");
    return response;
}

/**
 * Update
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    let formData = params;
    if (params.type !== "api") {
        formData = new FormData();
        formData = buildFormData(formData, params);
    }
    const response = await putRequest(`${urlConstants.integration.url}${id}/`, formData, params.type !== "api");
    return response;
}

/**
 * Delete
 * @param {*} id
 * @returns
 */
async function deleteChannel(id) {
    const response = await deleteRequest(`${urlConstants.integration.url}${id}/`);
    return response;
}

/**
 * Get Collibra Attribute Types
 * @param {*} params
 * @returns
 */
async function getCollibraAttributeTypes(params) {
    const response = await postRequest(urlConstants.integration.collibraAttributeTypes, params);
    return response;
}

/**
 * Get Alation Datasource Types
 * @param {*} params
 * @returns
 */
async function getAlationDataSource(params) {
    const response = await postRequest(urlConstants.integration.alationDataSource, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const integrationService = {
    detail, create, update, deleteChannel, getCollibraAttributeTypes, getAlationDataSource
};