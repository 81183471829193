import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';

//Components
import { MemoTextBoxComponent } from '../../components/index.js';

// Import Styles
import AuthenticationStyle from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DQLabsLogo from '../../assets/img/dqlabs.png';

// Import Actions
import { forgotPasswordRequest } from '../../redux/reducer/authReducer.js';

// Import Helpers
import appConstants from '../../constants/appConstants.js';
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';


function ForgotPassword(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    const { theme: { tagline, logo } } = useSelector((state) => state.configurations);


    /**
     * Define States
     */
    const [formData, setFormData] = useState({
        email: '',
        loading: false
    });
    const { email } = formData;

    /**
     * Handle Textbox Changes
     */
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    /**
     * Submit Form
     * @param {*} data
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(forgotPasswordRequest(formData));
    };

    return (
        <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Grid className={`${classes.loginBox}`}>
                <Grid container spacing={5}>
                    <Grid item xs={12} align="center">
                        <img
                            alt="logo"
                            src={_.isEmpty(logo) ? DQLabsLogo : logo}
                            className={classes.loginLogo}
                        />
                        <Typography variant="h5">
                            {tagline}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="mb-2">
                            {appConstants.labels.authentication.forgotPassword}
                        </Typography>
                        <MemoTextBoxComponent
                            id="form_text_email"
                            name={'email'}
                            value={email}
                            fullWidth
                            placeholder={appConstants.labels.authentication.email}
                            variant="outlined"
                            size="medium"
                            validators={['required', 'isEmail']}
                            errorMessages={
                                [
                                    setRequiredErrorMessage(appConstants.labels.authentication.email),
                                    appConstants.errorMessages.invalidMail
                                ]
                            }
                            onChange={handleChange}
                            valOnChange
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mt2}>
                        <Grid container justifyContent={'space-between'} alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="large"
                            >
                                {appConstants.labels.authentication.submit}
                            </Button>
                            <Grid align="right">
                                <Button
                                    variant="text"
                                    className={classes.linkBtn}
                                    disableRipple
                                >
                                    <Link to="/login">
                                        {appConstants.labels.authentication.backtoLogin}
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
ForgotPassword.defaultProps = {
    classes: {}
};

// prop types
ForgotPassword.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AuthenticationStyle(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(ForgotPassword);