import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Tooltip, Typography } from '@mui/material';

//  Import Styles
import DbtInfoStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import DbtColumnList from '../dbtColumnList/index.jsx';
import { DbtJobRuns } from '../dbtJobRuns/index.jsx';
import { DbtTestResults } from '../dbtTestsResults/index.jsx';


function DbtInformationComponent(props) {

    /**
     * Define Props
     */
    const { classes, versionDetail } = props;
    const infoContainerRef = useRef();
    const dbtMoreInfoTabs = [
        { label: "Attributes", description: "Columns are not resources in and of themselves. Instead, they are child properties of another resource type." },
        { label: "Runs", description: "A job consists of commands that are 'chained' together and executed as run steps" },
        { label: "Tests", description: "The tests property defines assertions about a column, table, or view." }
    ];

    /**
     * Define State
     */
    const [template, setTemplate] = useState("Attributes");
    const [selectedColumn, setSelectedColumn] = useState('');

    /**
     * Scroll Page Based on Tab Selection
     */
    const scrollPage = () => {
        if (infoContainerRef && infoContainerRef.current) {
            infoContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
        }
    };

    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }

        if (value === 'Attributes' || value === 'Runs') {
            setSelectedColumn('');
        }
        setTemplate(value);
        scrollPage();
    };

    /**
     * Set Selected Column For Test
     * @param {*} value
     */
    const filterTestsByColumns = (value) => {
        setSelectedColumn(value.name?.toLowerCase());
        setTemplate('Tests');
    };

    /**
     * Set Tests Data
     * @returns
     */
    const getTestsData = () => {
        const tests = versionDetail?.properties?.tests ?? [];
        if (selectedColumn && tests.length) {
            return tests.filter((i) => i.columnName?.toLowerCase() === selectedColumn);
        }
        return tests || [];
    };


    /**
     * Prepare Columns Data
     * @returns
     */
    const getColumnsData = () => {
        const filterData = versionDetail?.properties?.columns?.map((item) => {
            return {
                ...item,
                "tests": versionDetail?.properties?.tests?.filter((i) => i.columnName?.toLowerCase() === item.name?.toLowerCase()).length || ''
            };
        });
        return filterData || [];
    };

    /**
     * Get Dbt Info
     */
    const bindDbtInfo = () => {
        switch (template) {
            case "Runs":
                return <DbtJobRuns data={versionDetail?.properties?.runResults ?? []} />;
            case "Tests":
                return <DbtTestResults data={getTestsData()} />;
            default:
                return <DbtColumnList data={getColumnsData()} filterTestsByColumns={filterTestsByColumns} />;
        }
    };

    // Get Selected Filter
    const selectedFilter = dbtMoreInfoTabs.find((item) => item.label === template);


    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
                <Grid item className={classes.filter}>
                    <Typography variant="body1" className={classes.textSecondary}>
                        {selectedFilter?.description || ""}
                    </Typography>
                </Grid>
                <ToggleButtonGroup
                    color="secondary"
                    value={template || 'All'}
                    exclusive
                    aria-label="text alignment"
                    onChange={(event, value) => handleFilterOnChange(value)}
                >
                    {
                        dbtMoreInfoTabs.map((data, index) =>
                            <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                <Tooltip title={data.label} arrow>
                                    <Typography variant="body2" className={classes.textSecondary}>
                                        {data.label}
                                    </Typography>
                                </Tooltip>
                            </ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} className={classes.infoSection} ref={infoContainerRef} >
                {bindDbtInfo()}
            </Grid>
        </Grid>
    );
}

// default props
DbtInformationComponent.defaultProps = {
    classes: {},
    versionDetail: {}
};

// prop types
DbtInformationComponent.propTypes = {
    classes: PropTypes.object,
    versionDetail: PropTypes.object
};


/**
 * Export Component
 */
export default withStyles(
    (theme) => ({
        ...DbtInfoStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DbtInformationComponent);