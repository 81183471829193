const HomeStyle = (theme) => ({
    landinPageContainer: {
        backgroundRepeat: 'no-repeat'
        // padding: '20px'
    },
    welcomeHeader: {
        padding: '0px 0px 45px',
        maxWidth: '615px !important',
        borderRadius: '7px',
        margin: 'auto !important',
        '& .exploreText': {
            letterSpacing: '0.38px'
        },
        '& .welcomeText': {
            letterSpacing: '1px',
            paddingBottom: 12
        },
        '& .welcomePageBtns': {
            marginTop: 25,
            textAlign: 'center',
            '& .MuiButton-root:nth-child(1)': {
                marginRight: 20
            }
        },
        '& .homeSearchBox': {
            marginTop: 18,
            minWidth: 480,
            position: 'relative',
            '& .MuiPaper-root': {
                boxShadow: '0px 2px 8px #0000000F !important',
                border: 'none !important',
                padding: '7px 10px !important'
            },
            '& .MuiFormControl-root, .MuiInputBase-root': {
                width: '100%'
            }
        },
        '& a': {
            color: `#2E384D !important`,
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`
            }
        },
        '& .calendarIcon': {
            backgroundColor: `${theme.palette.greyshades.lightgrey} !important`,
            marginLeft: 8,
            padding: '6px !important',
            '&:hover': {
                '& .CalendarIcon2': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        }
    },
    welcomeLogo: {
        width: 140,
        height: 140,
        objectFit: 'contain'
    },
    logoContainer: {
        maxWidth: '296px',
        background: '#fff',
        height: '140px',
        width: '140px',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '3px',
        position: 'relative',
        boxShadow: '0px 1px 4px #0000000A',
        marginRight: '40px !important'
    },
    landingBody: {
        height: 'calc(100vh - 150px)',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        '@media only screen and (min-width: 1600px)': {
            '&.MuiGrid-root': {
                // maxWidth: '1300px !important',
                margin: 'auto !important'
            }
        }
    },
    landingBodyMain: {
        maxWidth: '80% !important',
        margin: 'auto !important',
        '@media only screen and (max-width:1500px)': {
            '&.MuiGrid-root': {
                maxWidth: '90% !important'
            }
        }
    },
    welcomeDataCards: {
        paddingTop: '48px',
        '& .cardItem': {
            paddingLeft: '70px !important',
            textDecoration: 'none',
            '@media only screen and (max-width:1500px)': {
                '&.MuiGrid-root': {
                    paddingLeft: '60px !important'
                }
            },
            '&:hover': {
                '& .cardItemBody': {
                    outline: `2px solid ${theme.palette.secondary.main}40`
                }
            }
        },
        '& .cardItemBody': {
            background: '#fff',
            padding: '17px 20px 11px 40px',
            borderRadius: 4,
            position: 'relative',
            boxShadow: '0px 1px 4px #0000000A',
            cursor: 'pointer'
        },
        '& .cardText': {
            paddingBottom: 1
        },
        '& .cardIcon': {
            position: 'absolute',
            left: '-21px',
            top: '16px',
            height: '42px',
            width: '42px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 0px 16px #0000001A',
            '& .DangerIcon': {
                width: 23,
                height: 23,
                fill: '#fff'
            },
            '& .IssuesIcon2': {
                fill: '#fff',
                width: 23,
                height: 23
            }
        },
        '& .MuiGrid-grid-xs-3': {
            flexBasis: '20% !important',
            maxWidth: '20% !important'
        }
    },
    lastLoging: {
        position: 'absolute',
        right: 15,
        bottom: 0,
        display: 'flex',
        alignItems: 'center'
    },
    scrollToNext: {
        position: 'absolute !important',
        transform: 'translate(-50%, 0)',
        left: '50%',
        bottom: 0,
        width: 42,
        height: 42,
        '&:hover': {
            '& svg.ScrollArrowIcon path': {
                fill: theme.palette.secondary.main
            }
        }
    },
    tabHeaderContainer: {
        position: 'sticky',
        top: 0,
        borderTop: `1px solid #DBDEE0`,
        zIndex: 2,
        '& .tabsLink .MuiTypography-root': {
            padding: '15px 16px 12px'
        }
    },
    tabsSection: {
        marginTop: "17px !important",
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        maxWidth: 'calc(100% + 40px) !important',
        flexBasis: 'calc(100% + 40px) !important'
        // overflowX: 'hidden'
    },
    tabsBodySection: {
        padding: '12px 5px',
        zIndex: 1
        // maxHeight: 'calc(100vh - 136px)',

        // overflowY: 'auto'
    },
    tabsBody: {
        // background: theme.palette.primary.contrastText,
        padding: '24px 12px'
    },
    widgetBody: {
        marginTop: '15px !important'
    },
    widgetListIcon: {
        background: `${theme.palette.secondary.main}10`,
        width: '40px',
        height: '40px !important',
        flexShrink: 0,
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px !important'
    },
    dashboardCardsColumn: {
        padding: 16
    },
    personalizeidget: {
        display: 'flex',
        alignItems: "center",
        background: theme.palette.primary.contrastText,
        paddingRight: 20,
        height: 52,
        '& .MuiButton-root': {
            whiteSpace: "nowrap",
            textTransform: 'capitalize'
        },
        '& .MuiIconButton-root': {
            transform: 'rotate(-90deg)'
        }
    }
});

export default HomeStyle;