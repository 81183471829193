import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//import Componetns
import { TextBoxComponent, ChipComponent, StatusDropDownComponent, UsersComponent, ListUserComponent } from '../../../../components/index';

//import Styles
import IssueStatusStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//import Images
import SlackIcon from '../../../../assets/img/slackLogo.png';
import JiraIcon from '../../../../assets/img/apps/jira.png';
import BigpandaIcon from '../../../../assets/img/apps/bigpanda.png';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

// Import Actions
import { getApplicationsRequest } from '../../../../redux/reducer/applicationReducer';
import { getDomainListRequest } from '../../../../redux/reducer/semanticReducer.js';
import { getUsersThumbnailRequest } from '../../../../redux/reducer/userReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';


function IssueDetail(props) {
    /**
     * Define Props
     */
    const { classes, data, handleOnChange, containerWidth, issuePermission } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { thumbnail_list: userList } = useSelector((state) => state.user, shallowEqual);

    /**
     * Get Applications and Domains and Users
     */
    useEffect(() => {
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        if (!userList || userList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, [dispatch]);


    /**
     * Get Filter User List
     * @param {*} users
     * @param {*} data
     * @returns
     */
    const getUserList = (users, data) => {
        if (data) {
            users = users.filter((user) => user.id !== data.id);
        }
        return users;
    };

    const users = useMemo(() => getUserList(userList, data.created_by), [userList, data.created_by]);

    return (
        <Grid className={classes.IssuesStatusContaier}>
            <Grid
                container
                justifyContent={'space-between'}
                spacing={3}
                className={classes.issuesStatuscontainer}
            >
                <Grid item xs={12} className="description">
                    <Grid item xs={12} className="boxedLayout">
                        <Typography variant="h5" className="pb5">
                            {appConstants.labels.issues.description}
                        </Typography>
                        <TextBoxComponent
                            name="description"
                            fullWidth
                            multiline
                            value={data.description ? data.description : ""}
                            variant="standard"
                            minRows={3}
                            maxRows={8}
                            onChange={(event) => handleOnChange(event.target.name, event.target.value)}
                            disabled={!issuePermission?.is_edit}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        className="boxedLayout"
                    >
                        <Grid item xs={4}>
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.priority}
                            </Typography>
                            <StatusDropDownComponent
                                value={data.priority || "Low"}
                                onChange={(value) => handleOnChange("priority", value)}
                                dropdownValue={appConstants.priority}
                                priority
                                disabled={!issuePermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={4} className="pl-3">
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.status}
                            </Typography>
                            <StatusDropDownComponent
                                value={data.status || "New"}
                                onChange={(value) => handleOnChange("status", value)}
                                dropdownValue={appConstants.issueStatus}
                                disabled={!issuePermission?.is_edit}
                            />
                        </Grid>
                        <Grid item xs={4} className="pl-3">
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.owner}
                            </Typography>
                            {
                                data.created_by &&
                                <ListUserComponent user={data.created_by} />
                            }
                        </Grid>
                        <Grid item xs={4} className="pt-2">
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.domains}
                            </Typography>
                            <ValidatorForm onSubmit={() => { }}>
                                <ChipComponent
                                    data={data?.domains ?? []}
                                    labelKey="name"
                                    placeholder="Select Domains"
                                    add={issuePermission?.is_edit}
                                    availableList={searchableGlossaries}
                                    editable={issuePermission?.is_edit}
                                    className={'domainChips'}
                                    handleChipEvent={(value) => handleOnChange('domains', value)}
                                    enableDelete={(data) => Boolean(data.is_editable || !("is_editable" in data))}
                                    addType="autocomplete"
                                    limit={containerWidth < 1850 ? 1 : 2}
                                    deleteWithIndex
                                />
                            </ValidatorForm>
                        </Grid>
                        <Grid item xs={4} className="pt-2 pl-3">
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.application}
                            </Typography>
                            <ValidatorForm onSubmit={() => { }}>
                                <ChipComponent
                                    data={data?.application ?? []}
                                    labelKey="name"
                                    placeholder="Select Applications"
                                    add={issuePermission?.is_edit}
                                    availableList={searchableApplicationsList}
                                    editable={issuePermission?.is_edit}
                                    enableDelete={(data) => Boolean(data.is_editable || !("is_editable" in data))}
                                    handleChipEvent={(value) => handleOnChange('application', value)}
                                    addType="autocomplete"
                                    haveColor
                                    deleteWithIndex
                                    limit={containerWidth < 1850 ? 1 : 2}
                                />
                            </ValidatorForm>
                        </Grid>
                        <Grid item xs={4} className={`${classes.assigneeContainer} pt-2 pl-3`}>
                            <Typography
                                variant="h5"
                                className="pb5"
                            >
                                {appConstants.labels.issues.assignee}
                            </Typography>
                            <UsersComponent
                                isSteward={false}
                                list={users}
                                data={data.users || []}
                                handleStewardUser={(users) => handleOnChange("users", users)}
                                disabled={!issuePermission?.is_edit} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid className={classes.channelContainer}>
                        <img src={SlackIcon} alt="Channel Logo" className={classes.channelImg} />
                        {
                            data.slack_url &&
                            <a href={data.slack_url} className={classes.slack_url}>
                                <Grid>
                                    <Typography variant="h6" className="linkText">
                                        {data.slack_url}
                                    </Typography>
                                    <Typography variant="body1">
                                        Channel URL
                                    </Typography>
                                </Grid>
                            </a>
                        }
                        {
                            !data.slack_url &&
                            <Grid>
                                <Typography variant="h6">
                                    Slack Not Configured
                                </Typography>
                                <Typography variant="body1">
                                    Channel URL
                                </Typography>
                            </Grid>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid className={classes.channelContainer}>
                        <img src={JiraIcon} alt="Channel Logo" className={classes.channelImg} />
                        {
                            data.jira_endpoint &&
                            <a href={data.jira_endpoint} className={classes.jira_endpoint}>
                                <Grid>
                                    <Typography variant="h6" className="linkText">
                                        {data.jira_endpoint}
                                    </Typography>
                                    <Typography variant="body1">
                                        Endpoint URL
                                    </Typography>
                                </Grid>
                            </a>
                        }
                        {
                            !data.jira_endpoint &&
                            <Grid>
                                <Typography variant="h6">
                                    Jira Not Configured
                                </Typography>
                                <Typography variant="body1">
                                    Endpoint URL
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid className={classes.channelContainer}>
                        <img src={BigpandaIcon} alt="Channel Logo" className={classes.channelImg} />
                        {
                            data.bigpanda_url &&
                            <a href={data.bigpanda_url} className={classes.bigpanda_url}>
                                <Grid>
                                    <Typography variant="h6" className="linkText">
                                        {data.bigpanda_url}
                                    </Typography>
                                    <Typography variant="body1">
                                        External URL
                                    </Typography>
                                </Grid>
                            </a>
                        }
                        {
                            !data.bigpanda_url &&
                            <Grid>
                                <Typography variant="h6">
                                    BigPanda Not Configured
                                </Typography>
                                <Typography variant="body1">
                                    External URL
                                </Typography>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}

// default props
IssueDetail.defaultProps = {
    classes: {},
    data: {},
    issuePermission: {}
};

// prop types
IssueDetail.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleOnChange: PropTypes.func,
    containerWidth: PropTypes.number,
    issuePermission: PropTypes.object
};

    export default withStyles(
    (theme) => ({
        ...IssueStatusStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(IssueDetail);