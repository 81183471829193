const style = (theme) => ({
    PreviewContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '0px 30px 20px',
        height: 'calc(100vh - 0px)',
        overflowY: 'auto',
        '& .MuiBox-root': {
            padding: '0px'
        },
        '& .header': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            paddingTop: 30,
            zIndex: 99,
            '& .CloseIcon': {
                width: '22px',
                height: '22px',
                '&:hover': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    themeList: {
        marginBottom: 10,
        '& .MuiAccordion-root:before': {
            background: 'transparent'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px 20px 0px 32px !important',
            background: theme.palette.greyshades.tablegrey,
            '&.Mui-expanded': {
                minHeight: '48px'
            }
        },
        '& .Mui-expanded, .MuiAccordionSummary-content': {
            margin: '0px !important'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px !important'
        },
        '& .PlusIcon': {
            fill: theme.palette.secondary.main
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            left: 6
        }
       }
});
export default style;