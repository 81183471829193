import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { Grid, Dialog, IconButton, Typography } from '@mui/material';
import { TableComponent } from '../../../../../components/index.js';

// Import Images
import { CloseIcon } from '../../../../../assets/svg/index.js';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../../../layouts/private/styles.jsx';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { getUserActivityLogsRequest } from '../../../../../redux/reducer/userActivityReducer.js';


function UserActivityInformation(props) {
    const { classes, open, onClose } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });
    const [searchData, setSearchData] = useState({
        "name": "",
        "last_login": ""
    });

    // Redux Store
    const { logs, selectedUser, isLoading } = useSelector(({ userActivity }) => userActivity);

    /**
     * Get UserActivity Detail
     */
    useEffect(() => {
        const params = {
            user: selectedUser.user_id,
            session_start_time: selectedUser.session_start_time,
            session_end_time: selectedUser.session_end_time
        };
        dispatch(getUserActivityLogsRequest(params));
    }, []);

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };


    /**
     * Prepare Filter
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterData = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if ((!item[key]) || (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase()))) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Table data using UseMemo
     */
    const filterData = useMemo(() => prepareFilterData(logs || [], searchData, sorting.sortBy, sorting.orderBy), [logs || [], searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <Dialog fullScreen open={open}>
            <Grid container className={classes.informationContainer}>
                <Grid className="dflex alignCenter spaceBetween w-100">
                    <Typography variant="h5" className="">
                        {appConstants.labels.userActivity.title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <TableComponent
                    styleType="striped"
                    headers={appConstants.table.userActivityLogsHeader}
                    data={filterData || []}
                    options={appConstants.tableOptions.download}
                    NoResultText="No Results Found"
                    searchData={searchData}
                    sortBy={sorting?.sortBy ?? ''}
                    orderBy={sorting?.orderBy ?? 'asc'}
                    onClickSorting={onClickSorting}
                    onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                    isLoading={isLoading}
                    exportParams={
                        {
                            fileName: "User Activity Log.csv",
                            renameColumn: { "audit_text": "audit log" },
                            titleCaseHeader: true
                        }
                    }
                    stickyHeader
                />
            </Grid>
        </Dialog>
    );
}

// default props
UserActivityInformation.defaultProps = {
    classes: {},
    open: false,
    onClose: () => { },
    days: ""
};

// prop types
UserActivityInformation.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(UserActivityInformation);