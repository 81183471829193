import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

//  Import Styles
import RuleHeaderStyle from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import {
    AutoCompleteComponent,
    ChipComponent
} from '../../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../../constants/appConstants.js';


// Import Reducer
import { getSearchConnectionsRequest } from '../../../../../../redux/reducer/connectionReducer.js';
import { getApplicationsRequest } from '../../../../../../redux/reducer/applicationReducer.js';
import { getDomainListRequest } from '../../../../../../redux/reducer/semanticReducer.js';

function MeasureLevelRuleHeader(props) {

    /**
     * Define Rules
     */
    const { data, editMeasure, classes, isDisabled, connectionError } = props;
    const dispatch = useDispatch();

    /**
     * Get Dimension List
     */
    const searchableConnections = useSelector(({ connection }) => connection.searchableConnections);
    const { searchableApplicationsList } = useSelector((state) => state.applications);
    const { searchableGlossaries } = useSelector((state) => state.semantic);
    const sortedConnections = [...searchableConnections].sort((a, b) => a.name.localeCompare(b.name));

    const getConnectionName = (connectionId) => {
        const connection = searchableConnections.find((obj) => obj.id === connectionId);
        return connection?.name || "";
    };
    useEffect(() => {
        if (!searchableConnections.length) {
            dispatch(getSearchConnectionsRequest());
        }
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
    }, []);

    const containerRef = useRef();
    const containerWidth = containerRef.current ? containerRef.current.clientWidth : '100%';

    return (
        <Fragment>
            <Grid item xs={4} className="pl-7">
                <Typography
                    variant="h6"
                    className="pb5"
                >
                    {appConstants.labels.datasetOverview.domain}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.textSecondary} pb7`}
                >
                    {appConstants.labels.datasetOverview.domainDesc}
                </Typography>
                <Grid ref={containerRef}>
                <ChipComponent
                    data={data?.domains ?? []}
                    labelKey="name"
                    placeholder="Select Domains"
                    add={!isDisabled}
                    availableList={searchableGlossaries}
                    editable={!isDisabled}
                    className={'domainChips'}
                    handleChipEvent={(value) => editMeasure('domains', value)}
                    addType="autocomplete"
                    limit={containerWidth < 510 ? 1 : 2}
                    addText="Add Domain"
                />
                </Grid>
            </Grid>
            <Grid item xs={4} className="pl-6">
                <Typography
                    variant="h6"
                    className="pb5"
                >
                    {appConstants.labels.datasetOverview.applications}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.textSecondary} pb7`}
                >
                    {appConstants.labels.datasetOverview.measureDesc}
                </Typography>
                <ChipComponent
                    data={data?.application ?? []}
                    labelKey="name"
                    placeholder="Select Applications"
                    add={!isDisabled}
                    availableList={searchableApplicationsList}
                    editable={!isDisabled}
                    handleChipEvent={(value) => editMeasure('application', value)}
                    addType="autocomplete"
                    limit={containerWidth < 510 ? 1 : 2}
                    addText="Add Application"
                />
            </Grid>
            {
                data.category !== "comparison" &&
                <Grid item xs={4}>
                    <Typography
                        variant="h6"
                        className="pb-15"
                    >
                        {'Connection'}
                    </Typography>
                    <AutoCompleteComponent
                        freeSolo={false}
                        name="connection"
                        variant="standard"
                        fullWidth
                        selectedValue={getConnectionName(data?.connection) ?? 'null'}
                        data={sortedConnections || []}
                        placeholder="Select Connection"
                        onChange={(event, newValue) => editMeasure('connection', newValue?.id)}
                        disabled={isDisabled}
                    />
                    {
                        connectionError && !data?.connection &&
                        <FormHelperText error>
                            Connection is required
                        </FormHelperText>
                    }
                </Grid>
            }
        </Fragment>
    );
}


// default props
MeasureLevelRuleHeader.defaultProps = {
    classes: {},
    data: {},
    isDisabled: false,
    editMeasure: () => { },
    isFormSubmited: false
};

// prop types
MeasureLevelRuleHeader.propTypes = {
    data: PropTypes.object,
    editMeasure: PropTypes.func,
    classes: PropTypes.object,
    isDisabled: PropTypes.bool,
    connectionError: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.connectionError, nextProps.connectionError);
}

export default withStyles(
    (theme) => ({
        ...RuleHeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(MeasureLevelRuleHeader, areEqual));