import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

//  Import Components
import { Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material';

// Import Images
import { DeleteIcon } from '../../../assets/svg/index.js';

//  Import Styles
import ListViewStyle from '../style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';
import { numberWithCommas } from '../../../helpers/appHelpers.js';
import { Add } from '@mui/icons-material';
import LinearProgressBar from './LinearProgressBar.jsx';


function ListView(props) {

    /**
     * Define Props
     */
    const {
        classes, inputData, onItemClick, onItemToggle, enableDelete, chartType,
        onDelete, propertiesPermission, enableDrillDown, onDataDrilldown, showValue, small
    } = props;
    const defaultLimit = 20;

    /**
     * Define State
     */
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(defaultLimit);


    const loadData = (inputData, offset, limit) => {
        let chartData = [...inputData];
        chartData = _.orderBy(chartData, [chartType === "length" ? "name" : "count"], [chartType === "length" ? "asc" : "desc"]);
        const data = chartData.slice(offset, limit);
        setData([...data]);
    };

    /**
     * Load initial data
     */
    useEffect(() => {
        loadData(inputData, 0, limit);
    }, [inputData]);


    /**
     * Handle onscroll event
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if ((event.target.scrollTop + event.target.clientHeight + 2) >= event.target.scrollHeight && (inputData?.length > data?.length)) {
            setLimit(limit + defaultLimit);
            loadData(inputData, 0, limit + defaultLimit);
        }
    };

    /**
     * Handle on item click event
     * @param {*} event
     * @param {*} selectedItem
     */
    const onClick = (event, selectedItem, parent = null) => {
        const listData = parent ? parent.subInputData : inputData;
        const selectedIndex = listData.findIndex((item) => item.name === selectedItem?.name);
        selectedItem = listData[selectedIndex];
        onItemClick(event, selectedItem, selectedIndex);
    };

    /**
     * Handle on toggle value event
     * @param {*} event
     * @param {*} selectedItem
     */
    const onToggleValue = (event, selectedItem, parent) => {
        const listData = parent ? parent.subInputData : inputData;
        const selectedIndex = listData.findIndex((item) => item.name === selectedItem?.name);
        selectedItem = listData[selectedIndex];
        onItemToggle(event, selectedItem, selectedIndex);
    };

    /**
     * Handle on delete event
     * @param {*} item
     * @param {*} index
     */
    const handleDeleteEvent = (event, selectedItem, parent) => {
        let listData = parent ? parent.subInputData : inputData;
        if (!enableDrillDown) {
            listData = inputData;
        }
        const selectedIndex = listData.findIndex((item) => item.name === selectedItem?.name);
        selectedItem = listData[selectedIndex];
        onDelete(selectedItem, selectedIndex, parent);
    };

    /**
     * Get the tooltip text
     * @param {* the item to show} data
     * @returns
     */
    const getTooltipText = (data) => {
        const { name, count, percentage, sample_value } = data;
        return `${name} : ${numberWithCommas(
            count || 0
        )} (${percentage || 0}%)${showValue && sample_value ? `\nSample Value : ${sample_value}` : ""}
        `;
    };

    const getColor = (item) => {
        if ("isActive" in item && !item.isActive) {
            return "#B0BAC970";
        }
        return (!("is_valid" in item) || ("is_valid" in item && item.is_valid)) ? '#c8eeff' : '#ffcac2';
    };

    const getClassName = (item) => {
        if ("isActive" in item && !item.isActive) {
            return "disabled";
        }
        return (!("is_valid" in item) || ("is_valid" in item && item.is_valid)) ? 'valid' : 'invalid';
    };

    const renderDrillDownData = (parent) => {
        return parent?.subInputData?.map((item, index) => (
            <Grid container alignItems={'center'} key={`pattern-${index}`} columnSpacing={2} className="mb-1 subItemContainer" wrap="nowrap" >
                <Grid item xs={small ? 8 : 9} className={"itemContainer subItem"}>
                    {
                        enableDrillDown &&
                        <IconButton className={"expandIcon"} onClick={() => onDataDrilldown(item.name)}>
                            <Add />
                        </IconButton>
                    }
                    <Grid className={"drilldownView"} style={{ color: getColor(item) }}>
                        <LinearProgressBar value={item?.percentage || 0} />
                        <Tooltip title={getTooltipText(item)} classes={{ tooltip: classes.tooltip, popper: classes.tooltipPopper }} placement={"top"}>
                            <Typography variant="body1" className="title" onClick={(event) => propertiesPermission?.is_edit && onClick(event, item, parent)} noWrap>
                                {(item.name !== undefined && item.name !== null) && item.name.toString().length > 0 ? item.name.toString() : ""}
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={small ? 4 : 3} className={"actionContainer"}>
                    <Grid
                        className={`${classes.pattenValues} ${getClassName(item)}`}
                        onClick={(event) => onToggleValue(event, item, parent)}
                    >
                        {numberWithCommas(item.count || 0, false)}
                        <span style={{ paddingLeft: 2 }}>
                            {`(${item.percentage || 0}%)`}
                        </span>
                    </Grid>
                    {
                        enableDelete && item?.is_user_defined && propertiesPermission?.is_edit &&
                        <IconButton className={classes.deleteIcon} onClick={(event) => handleDeleteEvent(event, item, parent)}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </Grid>
            </Grid>
        ));
    };


    return (
        <Grid container justifyContent={'space-between'} alignItems="center">
            <Grid item xs={12} className={classes.valuesListView} onScroll={(event) => onScrollEvent(event)}>
                {
                    data.map((item, index) => (
                        <Fragment key={`pattern-${index}`}>
                            <Grid container alignItems={'center'} columnSpacing={2} className="mb-1" wrap="nowrap" >
                                <Grid item xs={small ? 8 : 9} className={"itemContainer"}>
                                    {
                                        enableDrillDown &&
                                        <IconButton className={`plusminus expandIcon ${item?.subInputData?.length <= 0 ? 'hide' : ''}`} onClick={() => onDataDrilldown(item.name)}>
                                            <Grid component={"div"} className={`plusminus${item?.isExpanded ? " active" : ""}`} />
                                        </IconButton>
                                    }
                                    <Grid className={"drilldownView"} style={{ color: getColor(item) }}>
                                        <LinearProgressBar value={item?.percentage || 0} />
                                        <Tooltip title={getTooltipText(item)} classes={{ tooltip: classes.tooltip, popper: classes.tooltipPopper }} placement={"top"}>
                                            <Typography variant="body1" className="title" onClick={(event) => propertiesPermission?.is_edit && onClick(event, item)} noWrap>
                                                {(item.name !== undefined && item.name !== null) && item.name.toString().length > 0 ? item.name.toString() : ""}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={small ? 4 : 3} className={"actionContainer"}>
                                    <Grid
                                        className={`${classes.pattenValues} ${getClassName(item)}`}
                                        onClick={(event) => onToggleValue(event, item)}
                                    >
                                        {numberWithCommas(item.count || 0, false)}
                                        <span style={{ paddingLeft: 2 }}>
                                            {`(${item.percentage || 0}%)`}
                                        </span>
                                    </Grid>
                                    {
                                        enableDelete && item?.is_user_defined && propertiesPermission?.is_edit &&
                                        <IconButton className={classes.deleteIcon} onClick={(event) => handleDeleteEvent(event, item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                            <Collapse in={item.isExpanded} timeout="auto" unmountOnExit>
                                {renderDrillDownData(item)}
                            </Collapse>
                        </Fragment>
                    ))
                }
            </Grid>
        </Grid>
    );
}

// default props
ListView.defaultProps = {
    classes: {},
    inputData: [],
    onItemClick: () => {},
    onItemToggle: () => {},
    onDelete: () => {},
    onDataDrilldown: () => {},
    propertiesPermission: {},
    enableDelete: false,
    chartType: "value",
    enableDrillDown: false,
    showValue: false,
    small: false
};

// prop types
ListView.propTypes = {
    classes: PropTypes.object,
    inputData: PropTypes.array,
    onItemClick: PropTypes.func,
    onItemToggle: PropTypes.func,
    onDelete: PropTypes.func,
    onDataDrilldown: PropTypes.func,
    propertiesPermission: PropTypes.object,
    enableDelete: PropTypes.bool,
    chartType: PropTypes.string,
    enableDrillDown: PropTypes.bool,
    showValue: PropTypes.bool,
    small: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.classes, nextProps.classes)
    && _.isEqual(prevProps.inputData, nextProps.inputData);
}

export default withStyles(
    (theme) => ({
        ...ListViewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(ListView, areEqual));