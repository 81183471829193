
const style = (theme) => ({
    extensionContainer: {
        width: '100%'
    },
    drawerBodyInner: {
        height: 'calc(100vh - 125px)',
        overflowY: 'auto',
        '& .headerContainer': {
            '& .textValue': {
                fontSize: '15px !important'
            }
        }
    },
    tableItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
        border: "1px solid #B2BCCA40",
        background: "#B2BCCA10",
        padding: "10px",
        borderRadius: "3px",
        marginBottom: "14px !important",
        fontFamily: 'Poppins, sans-serif',
        '& svg': {
            marginRight: "10px"
        }
    },
    drawerHeader: {
        padding: "24px 20px 16px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        BorderBottom: '1px solid #ECEFF2'
    },
    drawerBody: {
        paddingTop: '24px',
        padding: "16px 0px 16px 0px"
    },
    headerTitle: {
        fontSize: "24px",
        fontWeight: "600",
        display: "flex",
        alignItems: "center"
    },
    headerLogo: {
        height: '40px'
    },
    loginContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        justifyContent: 'center',
        paddingTop: '40px',
        paddingBottom: '40px',
        '& form': {
            width: '100%'
        }
    },
    drawerFooter: {
        padding: '10px'
    },
    assetHeader: {
        cursor: "pointer",
        padding: "12px 24px",
        '& .titleSection': {
            display: 'flex',
            alignItems: 'center',
            '& svg, path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .textValue': {
            fontSize: '11px !important'
        },
        '&:hover': {
            background: 'rgb(244 246 252)'
        }
    },

    tableRoot: {
        padding: '24px',
        '& .MuiTypography-h5': {
            fontSize: '15px !important'
        },
        '& .mb-1': {
            marginBottom: '0px !important'
        },
        '& .MuiTableCell-head': {
            padding: '6px 12px !important'
        },
        '& .NoResultIcon': {
            width: '80px !important',
            height: '65px !important'
        }
    },
    loginBox: {
        background: '#fff',
        position: 'fixed',
        zIndex: '1',
        boxShadow: '0px 15px 40px #7B848C33',
        padding: '30px',
        maxWidth: '435px',
        minHeight: '400px',
        top: '30%',
        left: '7%',
        '& .MuiGrid-root>.MuiGrid-item': {
            paddingTop: '40px',
            '&:first-child': {
                marginTop: '8px'
            }
        }
    }
});

export default style;