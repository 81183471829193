import React, { useCallback } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

// import styles
import profileStyles from "../style.jsx";
import { withStyles } from "@mui/styles";
import appConstants from "../../../../../constants/appConstants.js";
import { Grid, Typography } from "@mui/material";
import { ValueEditorComponent, SwitchComponent } from "../../../../../components/index.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import ProfileView from "../../../../../components/profileView/index.jsx";
import { useParams } from "react-router-dom";
import { updateAttributeMetadata, updateAttributeMetadataRequest } from "../../../../../redux/reducer/attributeReducer.js";
import { isNumeric } from "../../../../../helpers/appHelpers.js";


const Basic = (props) => {
    const { classes, propertiesPermission, onItemClick, onItemToggle, onDeleteItem, onClickTrending, onToggleRules } = props;
    const dispatch = useDispatch();
    const { attribute_id } = useParams();

    /**
     * Get data from redux store
     */
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);
    const { measures } = useSelector((state) => state.measure, shallowEqual);
    const patternFrequency = measures?.find((item) => item.type === "frequency" && item.technical_name === "pattern");

    /**
     * Handle item click
     */
    const handleItemClick = useCallback((event, item, index, visualType, type, propertyName) => {
        if (visualType === "list") {
            propertyName = item.type ? item.type : propertyName;
            let values = [];
            const selectedProperty = metadata[propertyName];
            if (selectedProperty) {
                values = JSON.parse(JSON.stringify(selectedProperty));
            }
            values = values?.length > 0 ? values : [];
            const selectedIndex = values.findIndex((value) => value.name === item.name);
            onItemClick(event, item, selectedIndex, visualType, type, propertyName);
        }
    }, [metadata]);

    /**
     * Handle item toggle event
     */
    const handleItemToggle = (event, item, index, type, propertyName) => {
        propertyName = (item?.type === "universal_patterns" && item?.type !== propertyName) ? item.type : propertyName;
        const selectedProperty = metadata[propertyName];
        let values = [];
        if (selectedProperty) {
            values = JSON.parse(JSON.stringify(selectedProperty));
        }
        values = values?.length > 0 ? values : [];
        const selectedIndex = values.findIndex((value) => value.name === item.name);
        onItemToggle(event, item, selectedIndex, type, propertyName);
    };

    /**
     * Handle value change events
     * @param {*} chartType
     * @param {*} key
     * @param {*} value
     */
    const handleValueChange = (chartType, key, value) => {
        if (chartType === "length") {
            key = key === "min" ? "min_length" : "max_length";
        } else {
            key = key === "min" ? "min_value" : "max_value";
        }
        const requestParams = {
            [key]: value,
            attribute_id
        };
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
    };


    const getBasicProfile = (profileData, totalRows, datatype) => {
        const basicProfile = [];
        if (!profileData) {
            return basicProfile;
        }
        const isnumericAttribute = isNumeric(datatype);
        const measureGroup = appConstants.profile.measureGroups.find((item) => item.group === "basic_profile");
        for (const measure of measureGroup.measures) {
            if (!(measure.technical_name in profileData)) {
                continue;
            }
            if (!isnumericAttribute && measure.technical_name === "zero_values") {
                continue;
            }
            if (datatype?.toLowerCase() !== "text" && measure.technical_name === "blank_count") {
                continue;
            }
            let valueCount = profileData[measure.technical_name];
            valueCount = valueCount ? parseInt(valueCount) : 0;
            let percentage = 0;
            if (valueCount && totalRows) {
                percentage = Math.round(((valueCount / totalRows) * 100), 2);
            }

            const selectedMeasure = measures.find((item) => item.technical_name === measure.technical_name);
            const isActive = (selectedMeasure && selectedMeasure.is_active);
            basicProfile.push({
                name: measure.name,
                count: valueCount,
                percentage,
                isActive
            });
        }
        return basicProfile;
    };

    const getRangeValues = (chartType) => {
        let minValue = 0;
        let maxValue = 0;
        let min_value = "";
        let max_value = "";
        if (chartType === "length") {
            minValue = parseInt(metadata?.min_length ?? 0) || 0;
            maxValue = parseInt(metadata?.max_length ?? 0) || 0;
            min_value = minValue?.toString() || "";
            max_value = maxValue?.toString() || "";
        } else if (chartType === "value") {
            minValue = metadata?.min_value || "";
            maxValue = metadata?.max_value || "";
            min_value = metadata?.min_value || "";
            max_value = metadata?.max_value || "";
            if (metadata?.derived_type?.toLowerCase() === "integer") {
                minValue = parseInt(minValue || 0);
                maxValue = parseInt(maxValue || 0);
                min_value = minValue?.toString() || "";
                max_value = maxValue?.toString() || "";
            } else if (metadata?.derived_type?.toLowerCase() === "numeric") {
                minValue = parseFloat(minValue || 0);
                maxValue = parseFloat(maxValue || 0);
                min_value = minValue?.toString() || "";
                max_value = maxValue?.toString() || "";
            } else if (metadata?.derived_type?.toLowerCase()?.includes("date")) {
                minValue = 0;
                maxValue = 0;
                min_value = min_value ? min_value : "";
                const isDate = (metadata?.derived_type?.toLowerCase() === "date");
                if (min_value && metadata?.derived_type?.toLowerCase() === "date") {
                    if (!min_value) {
                        min_value = `${min_value} 23:59:58`;
                    }
                }
                max_value = max_value ? max_value : "";
                if (max_value && metadata?.derived_type?.toLowerCase() === "date") {
                    if (!max_value) {
                        max_value = `${max_value} 23:59:58`;
                    }
                }
                if (isDate) {
                    min_value = min_value ? moment(min_value).utc() : "";
                    max_value = max_value ? moment(max_value).utc() : "";
                } else {
                    min_value = min_value ? moment(min_value) : "";
                    max_value = max_value ? moment(max_value) : "";
                }
            } else {
                if (minValue === "NULL") {
                    minValue = "NULL";
                    min_value = "NULL";
                } else if (minValue === "" && maxValue !== "") {
                    minValue = "E";
                    min_value = "E";
                }

                if (minValue === "" && maxValue === "") {
                    minValue = " ";
                    min_value = " ";
                    maxValue = " ";
                    max_value = " ";
                }
            }
        }
        return { minValue, maxValue, min_value, max_value };
    };

    const lengthRange = getRangeValues("length");
    const valueRange = getRangeValues("value");
    const basicProfile = getBasicProfile(metadata?.basic_profile, metadata?.total_rows, metadata?.derived_type?.toLowerCase() || "");

    return (
        <ValidatorForm onSubmit={() => {}}>
            <Grid container justifyContent={"flex-start"} columnSpacing={8} rowSpacing={4} className={classes.topSection}>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container direction={"column"}>
                                <Grid item xs={12}>
                                    <Grid item className={classes.titleContainer}>
                                        <Typography variant="h6" className="pb5">
                                            {appConstants.labels.datasetProperties.length}
                                        </Typography>
                                        <SwitchComponent size="small" checked={Boolean(metadata?.attribute_measures?.length > 0)} handleChange={(value) => onToggleRules("length", value)} />
                                    </Grid>
                                    <Typography
                                        variant="body1"
                                        className={`${classes.propertiesDescription}`}
                                    >
                                        {appConstants.labels.datasetProperties.lengthDesc}
                                    </Typography>
                                    <Grid item className="dflex alignCenter spaceBetween mt-2">
                                        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
                                            <Grid item className={classes.rangeFields}>
                                                <ValueEditorComponent placeholder={
                                                    appConstants.labels
                                                        .datasetProperties.min
                                                }
                                                    value={lengthRange?.min_value || 0}
                                                    variant="outlined"
                                                    onChange={(value) => handleValueChange("length", "min", typeof (value) === "string" ? value : value.target.value)}
                                                    type={"integer"}
                                                    size="small"
                                                    fullWidth={false}
                                                    disabled={!propertiesPermission?.is_edit}
                                                />
                                                {' '}
                                                <span className="rangeHypen">
                                                    -
                                                </span>
                                                <ValueEditorComponent placeholder={
                                                    appConstants.labels
                                                        .datasetProperties.max
                                                }
                                                    value={lengthRange?.max_value || 0}
                                                    onChange={(value) => handleValueChange("length", "max", typeof (value) === "string" ? value : value.target.value)}
                                                    type={"integer"}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth={false}
                                                    disabled={!propertiesPermission?.is_edit} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item className={classes.titleContainer}>
                                        <Typography variant="h6" className="pb5">
                                            {appConstants.labels.datasetProperties.range}
                                        </Typography>
                                        <SwitchComponent size="small" checked={Boolean(metadata?.attribute_measures?.value > 0)} handleChange={(value) => onToggleRules("value", value)} />
                                    </Grid>
                                    <Typography
                                        variant="body1"
                                        className={`${classes.propertiesDescription}`}
                                    >
                                        {appConstants.labels.datasetProperties.rangeDesc}
                                    </Typography>
                                    <Grid item className="dflex alignCenter spaceBetween mt-2">
                                        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
                                            <Grid item className={classes.rangeFields}>
                                                <ValueEditorComponent placeholder={
                                                    appConstants.labels
                                                        .datasetProperties.min
                                                }
                                                    value={valueRange?.min_value || 0}
                                                    variant="outlined"
                                                    onChange={(value) => handleValueChange("value", "min", typeof (value) === "string" ? value : value.target.value)}
                                                    type={metadata?.derived_type?.toLowerCase() || ""}
                                                    size="small"
                                                    fullWidth={false}
                                                    disabled={((metadata?.derived_type?.toLowerCase() === "text" || metadata?.derived_type?.toLowerCase() === "bit")) || !propertiesPermission?.is_edit}
                                                />
                                                {' '}
                                                <span className="rangeHypen">
                                                    -
                                                </span>
                                                <ValueEditorComponent placeholder={
                                                    appConstants.labels
                                                        .datasetProperties.max
                                                }
                                                    value={valueRange?.max_value || 0}
                                                    onChange={(value) => handleValueChange("value", "max", typeof (value) === "string" ? value : value.target.value)}
                                                    type={metadata?.derived_type?.toLowerCase() || ""}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth={false}
                                                    disabled={((metadata?.derived_type?.toLowerCase() === "text" || metadata?.derived_type?.toLowerCase() === "bit")) || !propertiesPermission?.is_edit} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} className="pl-4">
                            <ProfileView
                                title={appConstants.profile.basic}
                                description={appConstants.profile.basicDescription}
                                data={basicProfile || []}
                                propertiesPermission={propertiesPermission}
                                chartHeight={100}
                                showToggleViewIcon={false}
                                onToggleRules={(value) => onToggleRules("health", value)}
                                isMeasureEnabled={Boolean(metadata?.attribute_measures?.health > 0)}
                                small
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <ProfileView
                        title={appConstants.profile.regularExpressionPattern}
                        description={appConstants.profile.regularExpressionPatternDescription}
                        data={metadata.user_defined_patterns || []}
                        propertiesPermission={propertiesPermission}
                        chartType={"value"}
                        onItemClick={(...params) => handleItemClick(...params, "pattern", "user_defined_patterns")}
                        onItemToggle={(...params) => handleItemToggle(...params, "pattern", "user_defined_patterns")}
                        onAdd={(event) => onItemClick(event, null, -1, null, "pattern", "user_defined_patterns")}
                        onDelete={(...params) => onDeleteItem(...params, "pattern", "user_defined_patterns")}
                        onClickTrending={() => onClickTrending("user_defined_patterns", patternFrequency)}
                        onToggleRules={(value) => onToggleRules("user_defined_patterns", value)}
                        isMeasureEnabled={Boolean(metadata?.attribute_measures?.user_defined_patterns > 0)}
                        showAdd
                        sort
                        enableDelete
                        showMeasureLink
                    />
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

// define default props
Basic.defaultProps = {
    classes: {},
    propertiesPermission: {},
    attribute_id: "",
    onItemClick: () => { },
    onItemToggle: () => { },
    onDeleteItem: () => { },
    onClickTrending: () => { },
    onToggleRules: () => { }
};

// define proptypes
Basic.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object,
    onItemClick: PropTypes.func,
    onItemToggle: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onClickTrending: PropTypes.func,
    onToggleRules: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...profileStyles(theme)
    }),
    { withTheme: true }
) (Basic);