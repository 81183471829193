import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Button, InputAdornment } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import AddRuleStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Components
import { AutoCompleteComponent, SwitchComponent, ValueEditorComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from "../../../../../constants/appConstants";

function Threshold(props) {

    /**
     * Define Props
     */
    const { classes, data, is_auto, editMeasure, propertyPermission, isDisabled } = props;

    /**
     * Check and Set Default Value Type
     */
    useEffect(() => {
        if (!data?.type) {
            const uData = { ...data ?? {}, 'type': "value" };
            editMeasure('threshold_constraints', uData);
        }
    }, [data]);

    /**
     * Update Threshold
     * @param {*} key
     * @param {*} value
     */
    const updateThreshold = (key, value) => {
        const uData = { ...data ?? {}, [key]: value };
        editMeasure('threshold_constraints', uData);
    };

    /**
     * Handle Condition Value
     * @param {*} key
     * @param {*} value
     */
    const handleCondition = (key, value) => {
        if ((value.id === appConstants.drift_condition[5].id || value.id === appConstants.drift_condition[6].id) && !data.value2) {
            const uData = { ...data ?? {}, [key]: value.id, "value2": "" };
            editMeasure('threshold_constraints', uData);
        } else {
            const uData = { ...data, [key]: value.id };
            if (uData.value2) {
                delete uData.value2;
            }
            editMeasure('threshold_constraints', uData);
        }
    };

    return (
        <Grid item xs={12} className={classes.thresholdContainer}>
            <Grid
                container
                alignItems={"center"}
                wrap="nowrap"
                className={`thresholdType`}>
                <Grid item>
                    <SwitchComponent size="small"
                        label={'Manual Threshold'}
                        checked={!is_auto}
                        handleChange={(value) => editMeasure('is_auto', !value)}
                        disabled={(!propertyPermission?.is_edit || isDisabled)}
                    />
                </Grid>
                {
                    is_auto === false &&
                    <Grid className={classes.thresholdAddContainer}>
                        <Grid item xs={10}>
                            <Grid item className="dflex">
                                <Grid item className={classes.field}>
                                    <Typography variant="body1" className="pb5">
                                        Priority
                                    </Typography>
                                    <AutoCompleteComponent
                                        freeSolo={false}
                                        name="priority"
                                        variant="outlined"
                                        fullWidth
                                        selectedValue={data?.priority ?? null}
                                        data={appConstants.drift_priority}
                                        placeholder="Select Drift Priority"
                                        onChange={(event, newValue) => updateThreshold('priority', newValue.id)}
                                        validators={['required']}
                                        errorMessages={['priority required']}
                                        disabled={(!propertyPermission?.is_edit)}
                                    />
                                </Grid>
                                <Grid item className={classes.field}>
                                    <Typography variant="body1" className="pb5">
                                        Condition
                                    </Typography>
                                    <AutoCompleteComponent
                                        freeSolo={false}
                                        name="condition"
                                        variant="outlined"
                                        fullWidth
                                        selectedValue={data?.condition ?? null}
                                        data={appConstants.drift_condition}
                                        placeholder="Select Condition"
                                        onChange={(event, newValue) => handleCondition('condition', newValue)}
                                        validators={['required']}
                                        errorMessages={['Condition required']}
                                        disabled={(!propertyPermission?.is_edit)}
                                    />
                                </Grid>
                                <Grid item className={classes.field}>
                                    <Typography variant="body1" className="pb5">
                                        Value
                                    </Typography>
                                    <ValueEditorComponent
                                        variant="outlined"
                                        placeholder={`Enter a ${data?.type === "percentage" ? "Percentage" : "Value"}`}
                                        fullWidth
                                        value={data?.value ?? ''}
                                        onChange={(event) => updateThreshold('value', event?.target?.value ?? event)}
                                        name={"value"}
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.valueRequired]}
                                        disabled={(!propertyPermission?.is_edit)}
                                        InputProps={
                                            {
                                                endAdornment:
                                                    <InputAdornment position="end" >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            disableElevation
                                                            sx={{ height: "25px" }}
                                                            onClick={() => updateThreshold('type', data?.type === "value" ? "percentage" : "value")}
                                                        >
                                                            {data?.type === "percentage" ? "%" : "#"}
                                                        </Button>
                                                    </InputAdornment>
                                            }
                                        }
                                    />
                                </Grid>
                                {
                                    (data.condition === appConstants.drift_condition[5].id || data.condition === appConstants.drift_condition[6].id) &&
                                    <Grid item className={classes.field}>
                                        <Typography variant="body1" className="pb5">
                                            Value
                                        </Typography>
                                        <ValueEditorComponent
                                            variant="outlined"
                                            placeholder= {`Enter a ${data?.type === "percentage" ? "Percentage" : "Value"}`}
                                            type="numeric"
                                            fullWidth
                                            value={data?.value2 ?? ''}
                                            onChange={(event) => updateThreshold('value2', event?.target?.value ?? event)}
                                            name={"value2"}
                                            validators={['required']}
                                            errorMessages={[appConstants.errorMessages.valueRequired]}
                                            disabled={(!propertyPermission?.is_edit)}
                                            InputProps={
                                                {
                                                    endAdornment:
                                                        <InputAdornment position="end" >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                disableElevation
                                                                sx={{ height: "25px" }}
                                                                onClick={() => updateThreshold('type', data?.type === "value" ? "percentage" : "value")}
                                                            >
                                                                {data?.type === "percentage" ? "%" : "#"}
                                                            </Button>
                                                        </InputAdornment>
                                                }
                                            }
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

// default props
Threshold.defaultProps = {
    classes: {},
    data: {},
    is_auto: false,
    isDisabled: false,
    editMeasure: () => { },
    propertyPermission: {}
};

// prop types
Threshold.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    is_auto: PropTypes.bool,
    isDisabled: PropTypes.bool,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.is_auto, nextProps.is_auto);
}

export default withStyles(
    (theme) => ({
        ...AddRuleStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Threshold, areEqual));