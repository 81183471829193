// Import API Helper
import { getRequest, postRequest, putRequest, deleteRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Reports Assets
 * @returns
 */
async function get_report_assets() {
    const response = await getRequest(`${urlConstants.reports.assets}`);
    return response;
}

/**
 * Get Columns
 */
async function get_report_columns(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.reports.columns}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Reports Preview Data
 */
async function get_report_preview_data(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.reports.preview_data}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Reports
 * @returns
 */
async function get_reports(params, cancellationToken) {
    const response = await postRequest(urlConstants.reports.getReports, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Report By Id
 * @param {*} id
 * @returns
 */
async function get_report_id(id) {
    const response = await getRequest(`${urlConstants.reports.url}${id}/`);
    return response;
}

/**
 * Delete Report
 * @param {*} id
 * @returns
 */
async function create_report(params) {
    const response = await postRequest(`${urlConstants.reports.url}`, params);
    return response;
}

/**
 * Delete Report
 * @param {*} id
 * @returns
 */
async function update_report(id, params) {
    const response = await putRequest(`${urlConstants.reports.url}${id}/`, params);
    return response;
}


/**
 * Delete Report
 * @param {*} id
 * @returns
 */
async function delete_report(id) {
    const response = await deleteRequest(`${urlConstants.reports.url}${id}/`);
    return response;
}

/**
 * Validate Reports
 */
async function getReportunStatus() {
    const response = await getRequest(`${urlConstants.reports.getReportRunStatus}`);
    return response;
}

/**
 * Get Assets Filters
 * @returns
 */
async function assets_filter() {
    const response = await getRequest(`${urlConstants.reports.assets_filter}`);
    return response;
}

/**
 * Create / Update Dashboard Report
 * @returns
 */
async function dashboard_report(params) {
    const response = await postRequest(`${urlConstants.reports.dashboard}`, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const reportsService = {
    get_report_assets, get_report_columns, get_report_preview_data, get_reports, get_report_id, create_report,
    update_report, delete_report, getReportunStatus, assets_filter, dashboard_report
};