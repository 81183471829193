import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography, FormHelperText } from '@mui/material';

// Import Components
import { AutoCompleteComponent, ValueEditorComponent } from '../index.js';

//  Import Style
import ComparisonStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images
import { DeleteIcon } from '../../assets/svg/index.js';

// Import Constant
import appConstants from '../../constants/appConstants.js';


function ComparisonMeasureAddRow(props) {
    /**
     * Define Props
     */
    const { classes, rule_data, onChange, handleRuleActions, measuresList, freshnessField, dataRequired, rule_index } = props;

    /**
     * Define State
     */
    const [eventSubmit, setEventSubmit] = useState(false);
    const [placeholder1, setPlaceholder1] = useState("Enter value");
    const [placeholder2, setPlaceholder2] = useState("Enter value");

    /**
     * Trigger Event
     * @param {*} type
     * @returns
     */
    const onTriggerEvent = (type) => {
        setEventSubmit(true);
        if (type === "create" && (!rule_data.measure || !rule_data.condition || !rule_data.value1)) {
            return false;
        }
        handleRuleActions(type);
    };

    // Update placeholder1 based on condition selection
    const updatePlaceholder = (condition) => {
        if (condition === appConstants.drift_condition[5].id || condition === appConstants.drift_condition[6].id) {
            setPlaceholder1("Min Value");
            setPlaceholder2("Max Value");
        } else {
            setPlaceholder1("Enter Value");
            setPlaceholder2("Enter Value");
        }
    };

    // Update placeholder when rule data condition changes
    useEffect(() => {
        updatePlaceholder(rule_data?.condition);
    }, [rule_data?.condition]);

    return (
        <Grid container className={classes.addMeasureContainer} key={`rule_item_${rule_index}`}>
            <Grid item xs={12}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item className="dflex flexOne">
                        <Grid item className={classes.field}>
                            <Tooltip title={rule_data?.measure ?? ''}>
                                <AutoCompleteComponent
                                    freeSolo={false}
                                    name="Measure"
                                    variant="outlined"
                                    fullWidth
                                    selectedValue={rule_data?.measure ?? ''}
                                    data={measuresList}
                                    placeholder="Select Measure"
                                    renderType={'comparison'}
                                    size={'small'}
                                    onChange={(event, newValue) => onChange("measure", newValue)}
                                    validators={['required']}
                                    errorMessages={['Measure required']}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item align="left" className="pr-4 dflex alignCenter">
                            <Typography variant="body1" className={`pt0 `}>
                                Difference
                            </Typography>
                        </Grid>
                        <Grid item className={classes.field}>
                            <Tooltip arrow title={rule_data?.condition ?? ''}>
                                <AutoCompleteComponent
                                    freeSolo={false}
                                    name="Condition"
                                    variant="outlined"
                                    fullWidth
                                    selectedValue={rule_data?.condition ?? ''}
                                    data={appConstants.drift_condition}
                                    placeholder="Select Condition"
                                    size={'small'}
                                    compareKey={'id'}
                                    onChange={
                                        (event, newValue) => {
                                            onChange("condition", newValue.id);
                                            updatePlaceholder(newValue.id);
                                        }
                                    }
                                    validators={['required']}
                                    errorMessages={['Condition required']}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item className={classes.field}>
                            <ValueEditorComponent
                                variant="outlined"
                                placeholder={placeholder1}
                                fullWidth
                                type={'integer'}
                                value={rule_data?.value1 ?? ""}
                                onChange={(event) => onChange("value1", event.target.value)}
                                name={"value"}
                                validators={['required']}
                                errorMessages={[appConstants.errorMessages.valueRequired]}
                            />
                            {
                                !rule_data?.value1 && (eventSubmit || dataRequired) &&
                                <FormHelperText error>
                                    Value is required
                                </FormHelperText>
                            }
                        </Grid>
                        {
                            (rule_data?.condition === appConstants.drift_condition[5].id || rule_data?.condition === appConstants.drift_condition[6].id) &&
                            <Grid item className={classes.field}>
                                <ValueEditorComponent
                                    variant="outlined"
                                    placeholder={placeholder2}
                                    fullWidth
                                    value={rule_data?.value2 ?? ""}
                                    onChange={(event) => onChange("value2", event.target.value)}
                                    name={"value"}
                                    validators={['required']}
                                    errorMessages={[appConstants.errorMessages.valueRequired]}
                                />
                            </Grid>
                        }
                        {
                            (rule_data?.measure === 'Freshness') &&
                            <Grid item className="pt-0">
                                <AutoCompleteComponent
                                    freeSolo={false}
                                    name="Time"
                                    variant="outlined"
                                    selectedValue={rule_data?.time || freshnessField[0]}
                                    data={appConstants.sliceBy}
                                    size={'small'}
                                    onChange={(event, newValue) => onChange("time", newValue)}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid item align="right">
                        <Tooltip title={"Delete"} arrow onClick={() => onTriggerEvent("delete")}>
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
ComparisonMeasureAddRow.defaultProps = {
    classes: {},
    rule_data: {},
    onChange: () => { },
    handleRuleActions: () => { },
    measuresList: [],
    freshnessField: [],
    rule_index: 0
};

// prop types
ComparisonMeasureAddRow.propTypes = {
    classes: PropTypes.object,
    rule_data: PropTypes.object,
    onChange: PropTypes.func,
    handleRuleActions: PropTypes.func,
    measuresList: PropTypes.array,
    freshnessField: PropTypes.array,
    dataRequired: PropTypes.bool,
    rule_index: PropTypes.number
};

export default withStyles(
    (theme) => ({
        ...ComparisonStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ComparisonMeasureAddRow);