import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../../components/index';
import CreateEditIssueComponent from '../../../../../issues/createEdit/index.jsx';

//Constants
import appConstants from '../../../../../../constants/appConstants.js';
import featureConstants from '../../../../../../constants/featureConstants.js';

// Import Action
import { updateDriftAlertRequest } from '../../../../../../redux/reducer/driftReducer.js';
import { updateDriftMetricProperty, measureSorting, getComparisonMetricRequest, toggleMetricResult } from '../../../../../../redux/reducer/metricReducer.js';
import { selectIssue } from '../../../../../../redux/reducer/issuesReducer.js';

// Import Helpers
import { checkPermission, permissionHeaders } from '../../../../../../helpers/appHelpers.js';

function ComparisonResult(props) {

    /**
     * Define Props
     */
    const { classes, selectedAlert } = props;
    const dispatch = useDispatch();

    /**
     * Get Data From Redux store
     */
    const { result, sortBy, orderBy, isLoading, detail: { asset_id, attribute_id, version_id, category } } = useSelector((state) => state.metric);
    const { permission } = useSelector((state) => state.auth);
    const alertPermission = checkPermission(permission, featureConstants.home.alerts);
    const issuePermission = checkPermission(permission, featureConstants.home.issues);

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        source: "",
        date: "",
        value: "",
        technical_name: "",
        message: "",
        status: "",
        issues: ""
    });
    const [issueParams, setIssueParams] = useState(null);

    /**
     * Click Actions
     * @param {*} data
     */
    const onClickActions = (data) => {
        let markedAs = null;
        if (data.status === "Ok" || data.status === "") {
            markedAs = !data?.marked_as ? "outlier" : null;
        } else {
            markedAs = !data?.marked_as ? "normal" : null;
        }

        const requestParams = {
            id: data.id,
            marked_as: markedAs
        };
        dispatch(updateDriftAlertRequest(requestParams));
        dispatch(updateDriftMetricProperty(requestParams));
    };

    /**
     * Prepare Result Data
     * @param {*} data
     * @returns
     */
    const prepareFilterResult = (data, searchFilters) => {
        let filterData = JSON.parse(JSON.stringify([...data]));
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    let value = item[key];
                    if (key === "status") {
                        value = item[key] || "OK";
                    }
                    if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        filterData = filterData.map((item) => {
            item.created_date = moment(item.created_date);
            item.source_count = item.threshold.lower_threshold;
            item.target_count = item.threshold.upper_threshold;
            if (item.run_date) {
                item.run_date = moment(item.run_date);
            }
            return item;
        });

        filterData = _.orderBy(filterData, ['created_date'], ['desc']);
        if (category?.toLowerCase() === "behavioral") {
            filterData = _.orderBy(filterData, ['run_date', 'created_date'], ['desc', 'desc']);
        }

        const index = filterData.findIndex((item) => item.id === selectedAlert);
        if (index >= 0) {
            filterData.unshift(filterData.splice(index, 1)[0]);
        }
        return filterData;
    };

    /**
     * Prepare Data using UseMemo
     */
    const resultData = useMemo(() => prepareFilterResult(result, searchData), [result, searchData]);

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy
        };
        dispatch(measureSorting(requestData));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const status = item?.status ?? "Low";
        const issue_name = `The ${item.measure_name} value is ${item.value}`;
        if (value === "add" && key === "issues") {
            setIssueParams({
                asset: asset_id || '',
                attribute: attribute_id || '',
                version: version_id || '',
                name: item.message || issue_name,
                description: item.message || issue_name,
                priority: appConstants.general.priority.includes(status) ? status : 'Low',
                drift: item.id,
                measure: item.measure_id,
                status: item.status
            });
        } else {
            setIssueParams({
                asset: asset_id || '',
                attribute: attribute_id || '',
                version: version_id || '',
                name: item.message || issue_name,
                description: item.message || issue_name,
                priority: appConstants.general.priority.includes(status) ? status : 'Low',
                drift: item.id,
                measure: item.measure_id,
                id: item.issue_id
            });
            dispatch(selectIssue(item.issue_id));
        }
    };

    /**
     * OnClose Issue Dialog
     */
    const onCloseIssueDialog = () => {
        setIssueParams(null);
    };

    /**
     * Check Issue permission
     * @returns
     */
    const checkIssuePermission = () => {
        const alertHeaders = permissionHeaders(appConstants.table.comparisonResultTable, alertPermission);
        const index = alertHeaders?.findIndex((header) => header.key === "issue_number");
        if (index >= 0) {
            if (!issuePermission?.is_edit) {
                alertHeaders[index].isPermissionDisabled = true;
            } else {
                alertHeaders[index].isPermissionDisabled = false;
            }
        }
        return alertHeaders;
    };

    /**
     * Bind Accordian Table
     * @param {*} selectedItem
     * @param {*} rowIndex
     */
    const onClickAccordian = (selectedItem, isOpen) => {
        if (isOpen && !selectedItem.metrics) {
            const requestData = {
                measure_id: selectedItem.measure_id,
                run_id: selectedItem.run_id,
                id: selectedItem.id
            };
            dispatch(getComparisonMetricRequest(requestData));
        }
        dispatch(toggleMetricResult({ id: selectedItem.id, isOpen }));
    };

    return (
        <Grid item xs={12} className={classes.ResultContainer}>
            <TableComponent
                styleType="striped"
                headers={checkIssuePermission()}
                data={resultData || []}
                onHandleSearchEvent={onHandleSearchEvent}
                options={appConstants.tableOptions.download}
                haveSubTable
                subTable={{ "headers": appConstants.table.comparisonResultSubTable, "rows": "metrics", height: "200px" }}
                subTableColSpan={appConstants.table.comparisonResultTable.length + 3}
                onClickActions={onClickActions}
                onSubTableActions={onClickActions}
                onClickAccordian={onClickAccordian}
                NoResultText="No Results Found"
                isLoading={isLoading}
                sortBy={sortBy}
                orderBy={orderBy}
                onClickSorting={onClickSorting}
                onClickSubTableSorting={onClickSorting}
                onCompnentEvent={onCompnentEvent}
                height="calc(100vh - 300px)"
                highlightIndex={null}
                stickyHeader
            />
            {
                issueParams &&
                <CreateEditIssueComponent
                    issueParams={issueParams}
                    open={Boolean(issueParams)}
                    onClose={() => onCloseIssueDialog()}
                />
            }
        </Grid>
    );
}

// default props
ComparisonResult.defaultProps = {
    classes: {},
    selectedAlert: ""
};

// prop types
ComparisonResult.propTypes = {
    classes: PropTypes.object,
    selectedAlert: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ComparisonResult);