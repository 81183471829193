import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

// Import Images
import { PlusIcon } from '../../../../../assets/svg';

// Import Components
import AddRole from './addRole.jsx';
import { DialogComponent, MemoTextBoxComponent } from '../../../../../components';
import appConstants from '../../../../../constants/appConstants';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Icons
import { DeleteIcon } from '../../../../../assets/svg/index.js';


function ListHeader(props) {

    /**
     * Define Props
     */
    const { classes, roles, handleAddRule, handleUpdateUser, handleDeleteUser, securityPermission } = props;

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        id: null
    });

    /**
     * Handle Delete Event
     */
    const onDelete = (id) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.roleDeleteMessage,
            id
        });
    };

    /**
     * Open Add Role
     */
    const openAddRole = (event) => {
        setAnchorEl(event.target);
    };

    /**
     * Close Add Role Popover
     */
    const closeAddRole = () => {
        setAnchorEl(null);
    };

    /**
     * Handle New Role Event
     * @param {*} newRole
     */
    const handleAddRuleEvent = (newRole) => {
        handleAddRule(newRole);
        setAnchorEl(null);
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            id: null
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = () => {
        handleDeleteUser(showDialog.id);
        dialogCancelEventHandle();
    };

    return (
        <Grid className={classes.rolesListHeader}>
            <Grid container className="pb5" flexWrap={"nowrap"}>
                <Grid itemScope className="pl-3" sx={{ minWidth: '120px' }}>
                    <Typography variant="h6">
                        Features
                    </Typography>
                </Grid>
                {
                    roles.map((role, index) => (
                        <Grid className="roleHeader" item key={`role-header-${index}`}>
                            {
                                index <= 2 ?
                                    <Typography variant="h6">
                                        {role.name || ""}
                                    </Typography>
                                    :
                                    <Grid className={classes.newRole}>
                                        <ValidatorForm onSubmit={() => null}>
                                            <Tooltip title={role.name || ""} arrow>
                                                <Grid>
                                                    <MemoTextBoxComponent
                                                        name="name"
                                                        fullWidth
                                                        variant="standard"
                                                        size="medium"
                                                        value={role.name || ""}
                                                        validators={['required']}
                                                        errorMessages={[appConstants.errorMessages.nameRequired]}
                                                        onChange={(event) => handleUpdateUser(role.id, event.target.value)}
                                                        disabled={!securityPermission?.is_edit}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        </ValidatorForm>
                                        {
                                            securityPermission?.is_edit &&
                                            <IconButton className={classes.pattenDelete} onClick={() => onDelete(role.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                            }
                        </Grid>
                    ))
                }
                <Grid item xs={1}>
                    {
                        securityPermission?.is_edit &&
                        <IconButton sx={{ marginTop: '-5px' }} onClick={(event) => openAddRole(event)}>
                            <PlusIcon sx={{ fill: "red" }} />
                        </IconButton>
                    }
                    <AddRole
                        classes={classes}
                        currentList={roles}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        handleClose={() => closeAddRole()}
                        handleAddRule={(newRole) => handleAddRuleEvent(newRole)} />
                </Grid>
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </Grid>
    );
}

// default props
ListHeader.defaultProps = {
    classes: {},
    roles: [],
    handleAddRule: () => { },
    handleUpdateUser: () => { },
    handleDeleteUser: () => { },
    securityPermission: {}
};

// prop types
ListHeader.propTypes = {
    classes: PropTypes.object,
    roles: PropTypes.array,
    handleAddRule: PropTypes.func,
    handleUpdateUser: PropTypes.func,
    handleDeleteUser: PropTypes.func,
    securityPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.roles, nextProps.roles) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default React.memo(ListHeader, areEqual);