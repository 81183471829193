import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getGlossaryRequest, getGlossarySuccess, getGlossaryFailure, createGlossaryRequest, createGlossarySuccess, createGlossaryFailure, updateGlossaryRequest,
    updateGlossarySuccess, updateGlossaryFailure, deleteGlossaryRequest, deleteGlossarySuccess, deleteGlossaryFailure, updateGlossaryImageRequest, updateGlossaryImageSuccess, updateGlossaryImageFailure,
    getStatisticsRequest, getStatisticsSuccess, getStatisticsFailure, getGlossaryDetailRequest, getGlossaryDetailSuccess, getGlossaryDetailFailure,
    createCategoryRequest, createCategorySuccess, createCategoryFailure, createTermRequest, createTermSuccess, createTermFailure, deleteTermRequest, deleteTermSuccess, deleteTermFailure, deleteCategoryRequest, deleteCategorySuccess, deleteCategoryFailure, updateTermRequest, updateTermSuccess, updateTermFailure,
    updateCategoryRequest, updateCategorySuccess, updateCategoryFailure, getCategoryDetailRequest, getTreeRequest, getTreeSuccess, getTreeFailure,
    getDomainListRequest, getDomainListSuccess, getDomainListFailure, getDomainListFilterRequest, getDomainListFilterSuccess, getDomainListFilterFailure
} from '../reducer/semanticReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { semanticService } from '../service';

/**
 * Create Glossary
 * @param {*} action$
 * @returns
 */
const createGlossary = (action$) => action$.pipe(
    ofType(createGlossaryRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.createGlossary(payload)).pipe(
            concatMap((res) => { return of(createGlossarySuccess(res), getTreeRequest()); }),
            catchError((error) => { return of(createGlossaryFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


/**
 * Get Glossary
 * @param {*} action$
 * @returns
 */
const getGlossary = (action$) => action$.pipe(
    ofType(getGlossaryRequest),
    mergeMap(() => {
        return from(semanticService.getGlossary()).pipe(
            concatMap((res) => { return of(getGlossarySuccess(res)); }),
            catchError((error) => { return of(getGlossaryFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const updateGlossary = (action$) => action$.pipe(
    ofType(updateGlossaryRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(semanticService.updateGlossary(id, rest)).pipe(
            concatMap((res) => of(updateGlossarySuccess(res))),
            catchError((error) => of(updateGlossaryFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const deleteGlossary = (action$) => action$.pipe(
    ofType(deleteGlossaryRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.deleteGlossary(payload)).pipe(
            concatMap(() => of(deleteGlossarySuccess(payload), getTreeRequest())),
            catchError((error) => of(deleteGlossaryFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Glossary Image
 * @param {*} action$
 * @returns
 */
const updateGlossaryImage = (action$) => action$.pipe(
    ofType(updateGlossaryImageRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.updateGlossaryImage(payload)).pipe(
            concatMap((res) => of(updateGlossaryImageSuccess(res))),
            catchError((error) => of(updateGlossaryImageFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Statistics
 * @param {*} action$
 * @returns
 */
const getStatistics = (action$) => action$.pipe(
    ofType(getStatisticsRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getStatistics(payload)).pipe(
            concatMap((res) => of(getStatisticsSuccess(res))),
            catchError((error) => of(getStatisticsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Detail
 * @param {*} action$
 * @returns
 */
const getDetail = (action$) => action$.pipe(
    ofType(getGlossaryDetailRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getDetail(payload)).pipe(
            concatMap((res) => of(getGlossaryDetailSuccess(res))),
            catchError((error) => of(getGlossaryDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Category
 * @param {*} action$
 * @returns
 */
const createCategory = (action$) => action$.pipe(
    ofType(createCategoryRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.createGlossary(payload)).pipe(
            concatMap((res) => of(createCategorySuccess({ data: { ...res.data, isAdd: payload.isAdd } }), getTreeRequest())),
            catchError((error) => of(createCategoryFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

/**
 * Update Category
 * @param {*} action$
 * @returns
 */
const updateCategory = (action$) => action$.pipe(
    ofType(updateCategoryRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(semanticService.updateGlossary(id, rest)).pipe(
            concatMap((res) => of(updateCategorySuccess(res))),
            catchError((error) => of(updateCategoryFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Term
 * @param {*} action$
 * @returns
 */
const deleteCategory = (action$) => action$.pipe(
    ofType(deleteCategoryRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.deleteGlossary(payload)).pipe(
            concatMap(() => of(deleteCategorySuccess(payload), getTreeRequest())),
            catchError((error) => of(deleteCategoryFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Create Term
 * @param {*} action$
 * @returns
 */
const createTerm = (action$) => action$.pipe(
    ofType(createTermRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.createTerm(payload)).pipe(
            concatMap((res) => of(createTermSuccess({ data: { ...res.data, isAdd: payload.isAdd } }), getTreeRequest())),
            catchError((error) => of(createTermFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Term
 * @param {*} action$
 * @returns
 */
const updateTerm = (action$) => action$.pipe(
    ofType(updateTermRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(semanticService.updateTerm(id, rest)).pipe(
            concatMap((payload) => of(updateTermSuccess(payload))),
            catchError((error) => of(updateTermFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Term
 * @param {*} action$
 * @returns
 */
const deleteTerm = (action$) => action$.pipe(
    ofType(deleteTermRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.deleteTerm(payload)).pipe(
            concatMap(() => of(deleteTermSuccess(payload), getTreeRequest())),
            catchError((error) => of(deleteTermFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Detail
 * @param {*} action$
 * @returns
 */
const getCategoryDetail = (action$) => action$.pipe(
    ofType(getCategoryDetailRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getCategoryDetail(payload)).pipe(
            concatMap((res) => of(getGlossaryDetailSuccess(res))),
            catchError((error) => of(getGlossaryDetailFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Tree
 * @param {*} action$
 * @returns
 */
const getTree = (action$) => action$.pipe(
    ofType(getTreeRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getTree(payload)).pipe(
            concatMap((res) => of(getTreeSuccess(res))),
            catchError((error) => of(getTreeFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Domain List
 * @param {*} action$
 * @returns
 */
const getDomainList = (action$) => action$.pipe(
    ofType(getDomainListRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getDomainList(payload)).pipe(
            concatMap((res) => of(getDomainListSuccess(res))),
            catchError((error) => of(getDomainListFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);


/**
 * Get Domain List Filter
 * @param {*} action$
 * @returns
 */
const getDomainListFilter = (action$) => action$.pipe(
    ofType(getDomainListFilterRequest),
    mergeMap(({ payload }) => {
        return from(semanticService.getDomainListFilter(payload)).pipe(
            concatMap((res) => of(getDomainListFilterSuccess(res))),
            catchError((error) => of(getDomainListFilterFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

// Export All Auth Epic Functions
export const semanticEpic = [
    createGlossary, getGlossary, deleteGlossary, updateGlossary, updateGlossaryImage, getStatistics, getDetail, createCategory, createTerm,
    deleteTerm, deleteCategory, updateCategory, updateTerm, getCategoryDetail, getTree, getDomainList, getDomainListFilter
];