import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { withStyles } from '@mui/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

// Import Component
import { NoResultComponent, LoaderComponent } from '../../../../components/index.js';

// Import Styles
import style from './style.jsx';
import { CloseIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { getVersionHistoryRequest } from '../../../../redux/reducer/versionReducer';

// Import Helpers
import { stringAvatar, getVersionText } from '../../../../helpers/appHelpers';


function VersionComponent(props) {

    /**
     * Define props
     */
    const { classes, assetId, slideIn, closeVersion } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const { versionHistory, filter, historyLoadMore, versionLoading } = useSelector(({ version }) => version);

    /**
     * Get Version History Detail
     */
    useEffect(() => {
        const requestParams = {
            id: assetId,
            limit: filter.limit,
            offset: filter.offset
        };
        dispatch(getVersionHistoryRequest(requestParams));
    }, []);

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if (!versionLoading && historyLoadMore && event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            const requestData = {
                id: assetId,
                limit: filter.limit,
                offset: filter.offset + filter.limit
            };
            dispatch(getVersionHistoryRequest(requestData));

        }
    };

    return (
        <Grid className={classes.versionContainer}>
            <Slide direction="left" in={slideIn} mountOnEnter unmountOnExit>
                <Grid className="h-100">
                    <Grid className={classes.titleContainer}>
                        <Typography variant="body1" className="fs-17 fw-500">
                            Version History
                        </Typography>
                        <IconButton onClick={closeVersion} className="p0 closeBtn">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    {
                        versionHistory.length > 0 &&
                        <Grid className={classes.versionList} onScroll={(event) => onScrollEvent(event)}>
                            <Timeline>
                                {
                                    versionHistory.map((item, index) => (
                                        <TimelineItem className={item?.version_info?.is_active && !item.is_audit ? classes.selectedItem : ''} key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot variant="outlined" />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Grid className={`contentContainer`}>
                                                    <Typography variant="body1" className="fs-16 versionNumber">
                                                        {`v${item.version_info?.version}`}
                                                    </Typography>
                                                    <Grid className="alignCenter pt-1">
                                                        <Typography variant="body1" className="mr5">
                                                            {
                                                                item.attribute_name &&
                                                                <Fragment>
                                                                    <Typography component="span" variant="body1" className="fw-600">
                                                                        {item.attribute_name}
                                                                    </Typography>
                                                                    {" "}
                                                                    Attribute
                                                                    {" "}
                                                                </Fragment>
                                                            }
                                                            {
                                                                (item.measure_name || item.conversation_name || item.term_name || item.usage_name || item.value) &&
                                                                <Typography component="span" variant="body1" className="fw-600">
                                                                    {item.measure_name || item.conversation_name || item.term_name || item.usage_name || item.value}
                                                                    {" "}
                                                                </Typography>
                                                            }

                                                            {getVersionText(item)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid className="dflex alignCenter pt-1">
                                                        {
                                                            item.created_by &&
                                                            <Avatar {...stringAvatar(item.created_by?.name ?? "", item.created_by?.avatar ?? "")} className={classes.versionAvatar} sizes="small" />
                                                        }
                                                        <Typography variant="body1">
                                                            {`${item.created_by?.name ?? ""} `}
                                                            on
                                                            {" "}
                                                            {moment(item.created_date).calendar()}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))
                                }
                            </Timeline>
                        </Grid>
                    }
                    <Grid className="h-100">
                        {versionLoading && <LoaderComponent height="calc(100% - 40%)" />}
                        {
                            !versionLoading && !versionHistory.length &&
                            <NoResultComponent height="calc(100% - 40%)" />
                        }
                    </Grid>
                </Grid>
            </Slide>
        </Grid>
    );
}

// default props
VersionComponent.defaultProps = {
    classes: {},
    assetId: "",
    slideIn: false,
    closeVersion: () => { }
};

// prop types
VersionComponent.propTypes = {
    classes: PropTypes.object,
    assetId: PropTypes.string,
    slideIn: PropTypes.bool,
    closeVersion: PropTypes.func
};

export default withStyles(style)(VersionComponent);