
const style = (theme) => ({
    headerContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: 24,
        alignItems: "center",
        '& .measureFilter': {
            cursor: "pointer"
        },
        '& .measureTile': {
            display: "flex",
            alignItems: "end",
            '& p': {
                background: '#b6d9e970',
                color: '#000',
                borderRadius: 4,
                width: 40,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 0
            },
            "& .thunderIcon": {
                cursor: "pointer",
                height: "25px !important",
                marginLeft: 7
            }
        },
        '& .passedTitle': {
            background: `${theme.palette.colorThemes.low}30`,
            color: `${theme.palette.colorThemes.low}`,
            fontWeight: 500,
            borderRadius: '4px',
            width: '40px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 0
        },
        '& .failedTile': {
            background: `${theme.palette.colorThemes.high}30`,
            color: `${theme.palette.colorThemes.high}`,
            fontWeight: 500,
            borderRadius: '4px',
            width: '40px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 0,
            '& .ThunderIcon': {
                flexShrink: 0,
                height: '16.661px'
            }
        }
    },
    headerDTIcon: {
        color: `${theme.palette.secondary.main} !important`,
        background: theme.palette.greyshades.lightgrey,
        padding: '3px 6px',
        fontSize: '14px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textTransform: 'capitalize !important',
        flexShrink: 0,
        marginRight: '8px !important',
        textAlign: "center",
        minWidth: '15px'
    },
    alertsIcon: {
        position: 'absolute',
        top: 8,
        right: 8,
        '& .ThunderIcon': {
            width: '8px',
            height: '13px'
        }
    },
    alertColor: {
        color: `${theme.palette.colorThemes.high} !important`
    },
    circular: {
        '& .textValue ': {
            fontSize: '12px !important'
        }
    },
    invalidRowsContainerDialog: {
        maxWidth: "100% !important",
        '& .MuiPaper-root': {
            maxWidth: "100% !important"
        }
    },
    invalidRowsContainer: {
        flexWrap: "none",
        padding: "30px",
        minWidth: "800px !important",
        maxWidth: "800px !important"
    },
    invalidRecordCount: {
        cursor: "default"
    }
});
export default style;