const style = (theme) => ({
    PreviewContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '0px 30px 30px',
        height: 'calc(100vh - 0px)',
        overflowY: 'auto',
        '& .MuiBox-root': {
            padding: '0px'
        },
        '& .header': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            paddingTop: 30,
            zIndex: 99,
            '& .CloseIcon': {
                width: '22px',
                height: '22px',
                '&:hover': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    usagePageContainer: {
        border: `1px solid ${theme.palette.greyshades.lightgrey}`
    },
    queryContainer: {
        /*
         * marginBottom: '20px !important',
         * border: `1px solid ${theme.palette.greyshades.lightgrey}`,
         */
        '&.queryContainer': {
            // border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& textarea.MuiOutlinedInput-input': {
            // height: '100% !important',
            height: '60px !important',
            // background: `${theme.palette.greyshades.lightgrey}50`,
            padding: 15
        },
        '& .MuiOutlinedInput-root': {
            padding: '0px !important'
        },
        '& .textBoxContainer': {
            // height: 'calc(100% - 40px)',
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderBottom: 'none !important'
            },
            '&>div': {
                height: '100%'
            },
            '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                height: '100%'
            }
        },
        '& .PlayIcon2, .SaveIcon, .PlusIcon': {
            '&:hover ': {
                fill: theme.palette.secondary.main
            }
        }
    },
    queryListContainer: {
        padding: '15px !important'
    },
    queryNameContainer: {
        width: '100%',
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.h5.size}px !important`,
            fontFamily: theme.palette.headers.h5.fontFamily,
            fontWeight: theme.palette.headers.h5.weight
        }
    },
    headerContainer: {
        background: theme.palette.greyshades.lightgrey,
        height: '40px',
        padding: '6px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .SaveIcon': {
            width: 23,
            height: 24,
            '&:focus': {
                outline: 'none !important'
            }
        },
        '& .PlusIcon': {
            width: '23px',
            height: '23px'
        },
        '& .MuiButtonBase-root': {
            pointerEvents: "auto !important"
        },
        '&.nobg': {
            background: 'transparent !important',
            paddingBottom: '0px !important'
        },
        '& .MuiToggleButton-root': {
            padding: '2.2px 10px !important',
            background: '#fff',
            '&.Mui-selected .MuiSvgIcon-root': {
                fill: '#fff !important'
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& .rightSideSearch': {
            '& .searchBox ': {
                padding: '0px 10px !important'
            },
            '& .PlayIcon2': {
                width: '21px',
                height: '21px'
            }
        }
    },
    listContainer: {
        '& .searchBox': {
            padding: '2px 10px'
        }
    },
    tableWrapper: {
        // padding: '20px',
        '& .searchBox': {
            padding: '2px 10px !important'
        }
    },
    blueContainer: {
        // background: `${theme.palette.secondary.main}08`,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 4,
        padding: '15px',
        height: '100%',
        maxHeight: '350px',
        minHeight: '350px',
        '& .MuiTableCell-root': {
            padding: 6
        },
        '& .searchContainer': {
            '& .MuiPaper-root ': {
                width: '240px',
                padding: '0px 10px !important'
            },
            '& .CloseIcon': {
                width: 22,
                height: 22
            }
        }
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        padding: '8px 0'
    },
    savedQueriesWrapper: {
        height: 'calc(100% - 0px)',
        overflowY: 'auto',
        '& .tableHeaderOptions': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 1
        }
    },
    tabHeaderContainer: {
        '& .btnTabsInline': {
            width: '100%',
            minHeight: '42px'
        },
        '& .MuiTabs-flexContainer': {
            background: theme.palette.greyshades.lightgrey
        },
        '& .MuiBox-root': {
            padding: '0px'
        }
    },
    tabsSection: {
        paddingBottom: 20
    },
    popOverContainer: {
        minWidth: "300px",
        padding: 5,
        boxShadow: '0px 0px 4px rgb(237 237 237 / 90%) !important'
    }
});
export default style;