const style = (theme) => ({
    integrationPageContainer: {
        background: theme.palette.primary.contrastText,
        minHeight: '100%',
        alignContent: 'baseline'
    },
    configurecardBody: {
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    header: {
        padding: '24px 30px 0px 30px',
        paddingRight: 30,
        '& .searchBox, .MuiFormControl-root': {
            width: '100% !important',
            maxWidth: 320
        },
        '& .searchBox': {
            padding: '2px 10px !important'
        }
    },
    cardBody: {
        height: 'calc(100vh - 305px)',
        overflowY: 'auto',
        padding: '12px 30px 24px 30px',
        '& .MuiBox-root': {
            padding: '0px !important'
        },
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    innerBody: {
        '& .restapi': {
            maxWidth: '80% !important'
        },
        '& .EditIcon': {
            width: '18px',
            height: '18px',
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            },
            '& path': {
                fill: `${theme.palette.greyshades.darkgrey} !important`
            }
        }
    },
    referenceListContainer: {
        '& .MuiTableContainer-root': {
            height: 'auto !important',
            maxHeight: 'calc(100vh - 388px) !important',
            overflowY: 'auto !important'
        }
    }

});
export default style;