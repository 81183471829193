
const pathConstants = {
    auth: {
        login: '/'
    },
    home: {
        root: "/home",
        alert: "/home/alerts",
        issues: "home/issues",
        dashboard: "home/dashboard",
        measure: "home/measures",
        reports: "home/reports",
        update_reports: "reports/param1"
    },
    connector: {
        root: "/connector",
        asset: "/connector/param1/param2/assets",
        new: "/connector/param1",
        edit: "/connector/param1/param2"
    },
    connections: {
        root: "/connections"
    },
    assets: {
        root: "/asset/param1",
        properties: "/asset/param1/properties",
        attributeProperties: "/asset/param1/properties/param2",
        conversations: "/asset/param1/conversation",
        conversationDetail: "/asset/param1/conversation/param2",
        usage: "/asset/param1/usage"
    },
    catalog: {
        root: "/catalog"
    },
    settings: {
        semantics: {
            domains: {
                root: "settings/semantics/domains",
                detail: "settings/semantics/domains/param1",
                category: "settings/semantics/domains/param1/category/param2",
                term: "settings/semantics/domains/term/param1"
            },
            terms: {
                root: "settings/semantics/terms",
                detail: "settings/semantics/terms/param1"
            },
            tags: {
                root: "settings/semantics/tags"
            },
            applications: {
                root: "settings/semantics/applications"
            },
            dimension: {
                root: "settings/semantics/dimension"
            },
            security: {
                root: "/settings/security"
            }
        },
        quality: {
            measure: "settings/quality/measure",
            rules: "settings/quality/rules"
        },
        integration: {
            root: "settings/integration",
            new: "settings/integration/param1",
            detail: "settings/integration/param1/param2"
        },
        connections: {
            root: "settings/connections"
        }
    },
    issues: {
        root: '/issue'
    },
    logs: {
        executionLogs: '/executionlogs/connector/param1'
    },
    domain: {
        root: '/domains',
        detail: '/semantics/domains/param1'
    },
    widget: {
        root: '/widget',
        detail: '/widget/param1'
    },
    notfound: {
        root: '/notfound'
    },
    extension: {
        login: '/extension',
        domain: '/extension/domain',
        tableau: '/extension/tableau'
    },
    license: {
        root: '/license'
    },
    reports: {
        root: "/reports/param1"
    },
    measure: {
        detail: "/measure/param1/detail",
        add: "/measure",
        edit: "/measure/param1"
    }
};

export default pathConstants;