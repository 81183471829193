const style = (theme) => ({
    attachmentImg: {
        marginTop: 10,
        border: `1px solid ${theme.palette.greyshades.grey}`,
        height: 'calc(100% - 44px)',
        background: '#fff',
        borderRadius: 4,
        position: 'relative',
        '&:hover': {
            background: theme.palette.greyshades.lightestgrey,
            cursor: 'pointer'
        },
        '& .sliderArrows': {
            position: 'absolute',
            right: '38px',
            top: '-42px'
        },
        '& .attachmentImg': {
            maxWidth: '100%',
            // padding: '20px 15px 20px 20px',
            cursor: 'pointer'
        },
        '& .slick-track': {
            display: 'flex',
            height: '100%'
        },
        '& .sliderGrid, .slick-slider, .slick-list, .slick-slide': {
            height: '100%'
        },
        '& .slick-slide div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        '& .imgWrapper': {
            display: 'flex !important',
            alignItems: 'center !important',
            padding: 20,
            position: 'relative'
        },
        '& .slick-slide': {
            height: '405px !important',
            padding: '0px'
        },
        '& .ChevLeftIcon, .ChevRightIcon': {
            '&:hover path': {
                fill: theme.palette.secondary.main
            }
        }
    },
    attachmentContaier: {
        // height: '90%',
        height: '100%',
        background: theme.palette.greyshades.lightbackground,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 4,
        padding: '24px',
        '& .PhotoAdd': {
            '&:hover': {
                fill: theme.palette.secondary.main
            }
        }
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .MuiBox-root': {
            marginBottom: '-40px'
        }
    },
    deleteIcon: {
        position: 'absolute !important',
        right: 5,
        top: 5,
        background: `${theme.palette.greyshades.lightgrey} !important`,
        '&:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    }
});
export default style;