import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Drawer, Grid, IconButton, Typography } from '@mui/material';


//  Import Styles
import ReportStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

//  Import Icons
import { CloseIcon } from '../../../assets/svg/index.js';

//  Import Components
import { SwitchComponent, TextBoxComponent, AutoCompleteComponent, LoaderButtonComponent } from '../../../components/index.js';
import Scheduler from './scheduler.jsx';

// Import Constants
import appConstants from '../../../constants/appConstants.js';

// Import Actions
import { updateProperties, clearState, getReportByIdRequest, dashboardReportRequest } from '../../../redux/reducer/reportsReducer.js';
import { displyAlert } from '../../../redux/reducer/alertReducer';

// Import Helpers
import { validateEmails } from '../../../helpers/appHelpers.js';

function WidgetReports(props) {
    /**
     * Define Props
     */
    const { classes, open, toggleDrawer, report_id, source_data } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */
    const { detail, isLoading, closeWidgetReport, saveLoading } = useSelector(({ reports }) => reports);
    const { users } = useSelector((state) => state.user, shallowEqual);

    /**
     * Local Variables
     */
    const users_emails = users?.map(({ email }) => email) ?? [];

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('validateEmails', (value) => {
            return validateEmails(value, true);
        });
        return () => {
            dispatch(clearState());
        };
    }, [dispatch]);

    /**
     * Close Popup Window
     */
    useEffect(() => {
        if (closeWidgetReport && !saveLoading) {
            toggleDrawer();
        }
    }, [closeWidgetReport, saveLoading]);

    /**
     * Regex Custom Validation
     */
    useEffect(() => {
        if (source_data && source_data?.id && !report_id) {
            dispatch(updateProperties(
                {
                    ...detail,
                    "source_id": source_data.id,
                    "name": source_data.name || '',
                    "description": source_data.description || ''
                }
            ));
        }
    }, [source_data]);

    /**
     * Get Report by Id
     */
    useEffect(() => {
        if (report_id) {
            dispatch(getReportByIdRequest(report_id));
        }
    }, [report_id]);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const requestParams = {
            [property]: value
        };
        dispatch(updateProperties(requestParams));
    };

    /**
     * Update Scheduler
     */
    const updateScheduler = (schedule) => {
        const is_event_schedule = schedule?.is_event_schedule ?? false;
        const schedule_props = {
            ...schedule,
            "duration": is_event_schedule ? "1" : schedule.duration,
            "configuration": is_event_schedule ? { time: '' } : schedule.configuration,
            "multiTime": is_event_schedule ? [] : schedule.multiTime,
            "target_type": is_event_schedule ? schedule.target_type : "",
            "schedule_method": is_event_schedule ? "event" : "time"
        };
        if (schedule?.target_type?.toLowerCase() === "measure") {
            schedule_props.target_measure = is_event_schedule ? schedule_props?.target_measure?.id : "";
        } else {
            schedule_props.target_asset = is_event_schedule ? schedule_props?.target_asset?.id : "";
        }

        dispatch(updateProperties({ 'schedule': schedule_props }));
    };

    /**
     * Save Report
     */
    const saveReport = () => {
        const schedule = detail?.schedule ?? {};
        const schedule_type = schedule?.type;
        const is_event_schedule = schedule?.is_event_schedule ?? false;
        const multiTime = schedule?.multiTime ?? [];

        if (detail.recurring && schedule_type !== 'Hours' && schedule_type !== 'Minutes' && !is_event_schedule && multiTime?.length === 0) {
            dispatch(displyAlert({ 'type': 'error', 'message': 'Please add schedule occurance time' }));
            return;
        }
        dispatch(dashboardReportRequest({ ...detail, "type": 'Custom Widget' }));
    };


    return (
        <Grid className={classes.drawerContainer}>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={toggleDrawer}
                className={`${classes.drawerContainer} drawerContainer`}
            >
                <Grid container className={classes.reportsContainer}>
                    <Grid item xs={12} className={classes.header}>
                        <Grid className="dflex alignCenter spaceBetween pb5">
                            <Typography variant="h2">
                                Email this Dashboard
                            </Typography>
                            <IconButton onClick={toggleDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Typography variant="body1">
                            {`${report_id ? 'Edit' : 'Create'} reports from dashboard and send it to users in the platform`}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className={classes.bodyContainer}>
                        <ValidatorForm noValidate onSubmit={() => saveReport()}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextBoxComponent
                                        label={
                                            <span className="requiredLabel">
                                                Report Name
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        variant="standard"
                                        className="mt-1"
                                        value={detail.name || ''}
                                        onChange={(event) => onChange("name", event.target.value)}
                                        validators={['required']}
                                        errorMessages={[`Report name ${appConstants.errorMessages.requriedField}`]}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBoxComponent
                                        label={'Description'}
                                        variant="standard"
                                        className="mt-1"
                                        multiline
                                        rows={2}
                                        value={detail.description || ""}
                                        onChange={(event) => onChange("description", event.target.value)}
                                        valOnChange
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteComponent
                                        label={
                                            <span className="requiredLabel">
                                                Recipients
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        variant="standard"
                                        className="mt-1"
                                        data={users_emails || []}
                                        selectedValue={detail.recipients || ""}
                                        freeSolo
                                        multiple
                                        placeholder=""
                                        limitTags={10}
                                        validators={['required', 'validateEmails']}
                                        errorMessages={[`Recipients ${appConstants.errorMessages.requriedField}`, appConstants.errorMessages.invalidMail]}
                                        onChange={(event, newValue) => onChange("recipients", newValue)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBoxComponent
                                        label={
                                            <span className="requiredLabel">
                                                Subject
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        variant="standard"
                                        className="mt-1"
                                        value={detail.subject || ""}
                                        onChange={(event) => onChange("subject", event.target.value)}
                                        validators={['required']}
                                        valOnChange
                                        errorMessages={[`Subject ${appConstants.errorMessages.requriedField}`]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBoxComponent
                                        label={
                                            <span className="requiredLabel">
                                                Message
                                                <span className="requiredstar">
                                                    *
                                                </span>
                                            </span>
                                        }
                                        variant="standard"
                                        className="mt-1"
                                        multiline
                                        rows={3}
                                        value={detail.message || ""}
                                        onChange={(event) => onChange("message", event.target.value)}
                                        validators={['required']}
                                        valOnChange
                                        errorMessages={[`Message ${appConstants.errorMessages.requriedField}`]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className="mb-1">
                                        Attach files as a
                                    </Typography>
                                    <SwitchComponent size="small"
                                        handleChange={(value) => onChange('attach_as', value ? 'csv' : '')}
                                        checked={detail.attach_as?.indexOf('csv') > -1 || false}
                                        label="CSV"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className="mb-1">
                                        Dashboard Context
                                    </Typography>
                                    <Grid container>
                                        <Grid className="mr-3">
                                            <SwitchComponent size="small"
                                                handleChange={(value) => onChange('with_filters', value)}
                                                checked={detail.with_filters}
                                                label="Send Dashboard with Filters"
                                            />
                                        </Grid>
                                        <Grid>
                                            <SwitchComponent size="small"
                                                handleChange={(value) => onChange('with_filters', !value)}
                                                checked={!detail.with_filters}
                                                label="Send Dashboard without Filters"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className="mb-1">
                                        Make this email recurring
                                    </Typography>
                                    <Grid container>
                                        <Grid className="mr-3">
                                            <SwitchComponent size="small"
                                                handleChange={(value) => onChange('recurring', value)}
                                                checked={detail.recurring}
                                                label={'Yes'}
                                            />
                                        </Grid>
                                        <Grid>
                                            <SwitchComponent size="small"
                                                handleChange={(value) => onChange('recurring', !value)}
                                                checked={!detail.recurring}
                                                label={'No'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    detail.recurring &&
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className="mb-1">
                                            Schedule
                                        </Typography>
                                        <Scheduler
                                            data={detail.schedule}
                                            updateScheduler={(schedule) => updateScheduler(schedule)}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} className={`${classes.footer} dflex alignCenter flexEnd pt-4`}>
                                    <Button variant="outlined" color="primary" size="small" className="mr-2" onClick={toggleDrawer}>
                                        Cancel
                                    </Button>
                                    <LoaderButtonComponent variant="contained" type="submit" color="primary" size="small" isLoading={isLoading || saveLoading}>
                                        Send
                                    </LoaderButtonComponent>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Drawer >
        </Grid >

    );
}

// default props
WidgetReports.defaultProps = {
    classes: {},
    open: false,
    toggleDrawer: () => { },
    source_data: '',
    report_id: ''
};

// prop types
WidgetReports.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func,
    source_data: PropTypes.object,
    report_id: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...ReportStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(WidgetReports);