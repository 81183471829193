import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import Components
import { TextBoxComponent } from '../../../textBox';
import { withStyles } from '@mui/styles';
import style from '../style.jsx';

const DateTimePickerComponent = (props) => {

    /**
     * Props definition
     */
    const { value, onChange, format, variant, validators, errorMessages, classes, onAccept, ...rest } = props;
    const dateTimeFormat = format || "MM-DD-YYYY HH:mm";

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                PopperProps={
                    {
                        className: classes.datePickerDialog
                    }
                }
                fullWidth
                ampm={false}
                value={value ? moment(value, dateTimeFormat).format(dateTimeFormat) : null}
                format={format}
                onChange={
                    (date) => {
                        onChange(date ? moment(date).format(dateTimeFormat) : date);
                    }
                }
                onAccept={
                    (date) => {
                        onAccept(date ? moment(date).format(dateTimeFormat) : date);
                    }
                }
                renderInput={(params) => <TextBoxComponent {...params} value={value} variant={variant} validators={validators} errorMessages={errorMessages} />}
                {...rest}
            />
        </LocalizationProvider>
    );
};


// default props
DateTimePickerComponent.defaultProps = {
    value: "",
    onChange: () => { },
    label: "",
    format: "MM-DD-YYYY HH:mm",
    variant: "standard",
    validators: [],
    errorMessages: [],
    onAccept: () => { }
};

// prop types
DateTimePickerComponent.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    format: PropTypes.string,
    variant: PropTypes.string,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    classes: PropTypes.object,
    onAccept: PropTypes.func
};

export default withStyles(style)(DateTimePickerComponent);