import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isLoading: false,
    dimension: [],
    searchableDimensionList: [],
    filterList: []
};

const dimensionSlice = createSlice({
    name: 'dimension',
    initialState: { ...initialState },
    reducers: {
        getDimensionRequest(state) {
            state.isLoading = true;
        },
        getDimensionSuccess(state, action) {
            state.isLoading = false;
            state.dimension = action.payload || [];
            state.searchableDimensionList = state.dimension.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                    color: item.color
                };
            });
        },
        getDimensionFailure() { },
        createDimensionRequest() { },
        createDimensionSuccess(state, action) {
            const { data } = action.payload;
            state.dimension = [...state.dimension, data];
            state.searchableDimensionList = state.dimension.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                    color: item.color
                };
            });
        },
        createDimensionFailure() { },
        updateDimensionRequest() { },
        updateDimensionSuccess(state, action) {
            const data = action.payload;
            const index = state.dimension.findIndex((applicaiton) => applicaiton.id === data.id);
            state.dimension[index] = data;
            state.searchableDimensionList = state.dimension.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                    color: item.color
                };
            });
        },
        updateDimensionFailure() { },
        deleteDimensionRequest() { },
        deleteDimensionSuccess(state, action) {
            state.dimension = state.dimension.filter((applicaiton) => applicaiton.id !== action.payload);
            state.searchableDimensionList = state.dimension.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                    color: item.color
                };
            });
        },
        deleteDimensionFailure() { }
    }
});

export const { getDimensionRequest, getDimensionSuccess, getDimensionFailure, createDimensionRequest, createDimensionSuccess,
    createDimensionFailure, updateDimension, deleteDimensionRequest, deleteDimensionSuccess, deleteDimensionFailure,
    updateDimensionRequest, updateDimensionSuccess, updateDimensionFailure
} = dimensionSlice.actions;
export default dimensionSlice.reducer;