import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';

// Import Component
import FieldGroupCard from './fieldGroupCard.jsx';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Actions
import { getFieldPropertyRequest, orderGroupData, updateGroupOrderRequest } from '../../../redux/reducer/fieldPropertyReducer';
import { ValidatorForm } from 'react-material-ui-form-validator';


function Fields(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: asset, tab } = useParams();

    /**
     * Redux Store
     */
    const { groups } = useSelector(({ fieldProperty }) => fieldProperty, shallowEqual);

    /**
     * Get Filter Property
     */
    useEffect(() => {
        const requestParams = {
            asset,
            tab
        };
        dispatch(getFieldPropertyRequest(requestParams));
    }, [tab]);


    /**
     * Order Item
     * @param {*} list
     * @param {*} startPosition
     * @param {*} endPosition
     * @returns
     */
    const orderItem = (list, startPosition, endPosition) => {
        const result = Array.from(list);
        const [removed] = result.splice(startPosition, 1);
        result.splice(endPosition, 0, removed);
        return result;
    };

    /**
     * Order Group
     * @param {*} startPosition
     * @param {*} endPosition
     */
    const orderGroup = (startPosition, endPosition) => {
        let reOrderData = orderItem(groups, startPosition, endPosition);
        reOrderData = reOrderData.map((group, index) => {
            return {
                ...group,
                position: index
            };
        });
        dispatch(orderGroupData(reOrderData));
        dispatch(updateGroupOrderRequest(reOrderData));
    };


    return (

        <ValidatorForm onSubmit={() => { }}>
            <DndProvider backend={HTML5Backend}>
                <Grid container spacing={4}>
                    {
                        groups.map((group, index) =>
                            <Grid key={`group_${index}`} item xs={12} className={classNames("", classes.fieldContainer)}>
                                <FieldGroupCard
                                    index={index}
                                    item={group}
                                    moveCard={orderGroup} />
                            </Grid>
                        )
                    }
                </Grid>
            </DndProvider>
        </ValidatorForm>
    );
}

// default props
Fields.defaultProps = {
    classes: {}
};

// prop types
Fields.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Fields);