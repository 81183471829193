import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { Grid } from '@mui/material';
import { TableComponent } from '../../../components/index.js';
import UserActivityInformation from './components/userActivityInformation/index.jsx';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../layouts/private/styles.jsx';

// Import Helpers
import { checkPermission, getUserPreference, prepareUpdateUserPreference } from '../../../helpers/appHelpers';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import Scheduler from '../../../components/scheduler/index.jsx';
import featureConstants from '../../../constants/featureConstants.js';

// Import Actions
import { getUserActivityRequest, selectedUser, collapseItem, getUserActivityJobsRequest } from '../../../redux/reducer/userActivityReducer';
import { updateUserPreference } from '../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../redux/reducer/userReducer';

function UserActivity(props) {
    const { classes } = props;
    const dispatch = useDispatch();

    // Redux Store
    const { list, isLoading } = useSelector(({ userActivity }) => userActivity);
    const user = useSelector(({ auth }) => auth.user);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "activity", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "activity", "sorting");
    const { permission } = useSelector((state) => state.auth);
    const reportsPermission = checkPermission(permission, featureConstants.home.reports);
    const [scheduler, setScheduler] = useState(null);

    /**
     * Define State
     */
    const [sorting, setSorting] = useState({
        sortBy: tableSorting?.sortBy ?? "", orderBy: tableSorting?.orderBy ?? "asc"
    });
    const [searchData, setSearchData] = useState({
        "name": "",
        "last_login": ""
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [daysFilter, setDaysFilter] = useState({
        value: "Last 3 days",
        options: ["Last 3 days", "Last 7 days", "Last 15 days", "Last 30 days", "All"]
    });

    const getActivity = (days = 3) => {
        dispatch(getUserActivityRequest(days));
    };

    const getUserActivityJobs = () => {
        dispatch(getUserActivityJobsRequest());
    };
    /**
     * Get UserActivity Detail
     */
    useEffect(() => {
        getActivity();
        getUserActivityJobs();
    }, []);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "activity", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        sortBy = sortBy === "user" ? "name" : sortBy;
        setSorting({
            sortBy,
            orderBy
        });
        updatePreference({ sorting: { orderBy, sortBy } });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Action Event
     * @param {*} selectedItem
     * @param {*} actionName
     */
    const onClickActions = (selectedItem) => {
        dispatch(selectedUser(selectedItem));
        setOpenDialog(true);
    };

    /**
     * Bind Accordian Table
     * @param {*} selectedItem
     * @param {*} isOpen
     */
    const onClickAccordian = (selectedItem, isOpen) => {
        const requestParams = {
            id: selectedItem.user_id,
            isOpen
        };
        dispatch(collapseItem(requestParams));
    };

    /**
     * OnClose Dialog
     */
    const onCloseDialog = () => {
        setOpenDialog(false);
        dispatch(selectedUser(null));
    };

    /**
     * Get Selection Date
     * @param {*} value
     * @returns
     */
    const getSelectionDate = (value) => {
        const days = value.replace(/[^0-9]/g, '');
        return days ? parseInt(days) : "";
    };

    /**
     * Handle Days Range Change
     * @param {*} value
     */
    const onDaysFilterChanges = (value) => {
        const selectedDays = { ...daysFilter };
        selectedDays.value = value;
        setDaysFilter({ ...selectedDays });
        const days = getSelectionDate(value);
        getActivity(days);
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Prepare Filter
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterData = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    } else if (typeof (item[key]) === 'number' && item[key] !== parseInt(searchFilters[key])) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Table data using UseMemo
     */
    const filterData = useMemo(() => prepareFilterData(list || [], searchData, sorting.sortBy, sorting.orderBy), [list || [], searchData, sorting.sortBy, sorting.orderBy]);
    const tableOptions = [
        { type: 'search', customFunction: null },
        { type: 'download', customFunction: null },
        { type: 'columns', customFunction: null }
    ];
    return (
        <Grid container className={classes.container}>
            <TableComponent
                title={appConstants.labels.userActivity.title}
                description={appConstants.labels.userActivity.description}
                headers={appConstants.table.userActivityHeader}
                data={filterData || []}
                options={tableOptions}
                NoResultText="No Results Found"
                searchData={searchData}
                sortBy={sorting?.sortBy ?? ''}
                orderBy={sorting?.orderBy ?? 'asc'}
                onClickSorting={onClickSorting}
                onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                onSubTableActions={onClickActions}
                onClickAccordian={onClickAccordian}
                filters={[{ ...daysFilter, onChange: onDaysFilterChanges }]}
                isLoading={isLoading}
                exportParams={
                    {
                        fileName: "User Activity.csv",
                        headers: ["name", "last_login", "no_of_logs", "login_count", "average_duration", "minimum_duration", "maximum_duration"],
                        renameColumn: { "name": "User", "last_login": "Last Logged In", "no_of_logs": "No Of Audit Logs", "average_duration": "Average Session Duration (mins)", "minimum_duration": "Minimum Session Duration (mins)", "maximum_duration": "Maximum Session Duration (mins)" },
                        titleCaseHeader: true
                    }
                }
                haveSubTable
                subTable={{ headers: appConstants.table.userActivitySubHeader, rows: "sessions", height: "200px" }}
                subTableColSpan={appConstants.table.userActivityHeader.length + 3}
                onColumnChange={(columns) => onColumnsChange(columns)}
                userPreferenceColumns={columns || []}
                height="calc(100vh - 300px)"
                styleType="striped"
                stickyHeader
            />
            {
                openDialog &&
                <UserActivityInformation
                    open={openDialog}
                    onClose={onCloseDialog}
                />
            }
            {
                scheduler?.open &&
                <Scheduler
                    scheduleProps={scheduler}
                    onClose={() => setScheduler({ open: false, anchorElement: null, isAsset: false, isMeasure: false, isReport: true, reportId: scheduler.reportId })}
                    disabled={!reportsPermission?.is_edit}
                    isEventSchedule={false}
                    category="user_activity"
                />
            }
        </Grid>
    );
}

// default props
UserActivity.defaultProps = {
    classes: {}
};

// prop types
UserActivity.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(UserActivity);