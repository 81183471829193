import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, FormHelperText } from '@mui/material';

// Import Components
import AllComparison from './allComparison.jsx';
import { SelectComponent } from '../index.js';

//  Import Style
import ComparisonStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images
import { PlusIcon } from '../../assets/svg/index.js';

// Import Constants
import appConstants from '../../constants/appConstants.js';


function Comparison(props) {
    /**
     * Define Props
     */
    const { data, classes, editMeasure, propertyPermission, dataRequired } = props;
    const defaultMeasureGroup = [
        {
            "properties": {
                "source": "",
                "target": "",
                "type": ""
            },
            "rules": [
                {
                    "measure": "",
                    "condition": "",
                    "value1": "",
                    "value2": "",
                    "time": "Minutes"
                }
            ]
        }
    ];

    /**
     * Handle On Comparison Priority Change
     * @param {*} property
     * @param {*} value
     */
    const onChangeProperty = (property, value) => {
        let properties = data?.properties ? { ...data.properties } : {};
        properties = { ...properties, [property]: value };
        editMeasure('properties', properties);
    };

    /**
     * On Action Events
     * @param {*} value
     * @param {*} index
     */
    const handleEditMeasureProperties = (g_index, value, action) => {
        const comparison = data?.properties?.comparison ? [...data.properties.comparison] : [];
        if (action === 'delete') {
            comparison.splice(g_index, 1);
        } else {
            comparison[g_index] = value;
        }
        editMeasure('properties', { ...data.properties, "comparison": comparison });
    };

    /**
     * On Add New Comparson Group
     */
    const onAddNewComparisonGroup = () => {
        const properties = data?.properties ?? {};
        const comparison = properties?.comparison ?? [];
        editMeasure('properties', { ...properties, "comparison": [...comparison, ...defaultMeasureGroup] });
    };

    /**
     * Add Default Comparison Measure if is Empty
     */
    useEffect(() => {
        if (!data?.properties?.comparison?.length) {
            onAddNewComparisonGroup();
        }
    }, [data]);


    return (
        <React.Fragment>
            <Grid container justifyContent="flex-end" className="pt-3">
                <Grid item xs={1.3}>
                    <SelectComponent
                        fullWidth={false}
                        label={appConstants.labels.general.alertsPriority}
                        variant="standard"
                        value={data?.properties?.priority || ''}
                        onSelectChange={(value) => onChangeProperty('priority', value)}
                        list={appConstants.priority}
                        name="priority"
                        isDisabled={!propertyPermission?.is_edit}
                        validators={['required']}
                    />
                    {
                        !data?.properties?.priority && dataRequired &&
                        <FormHelperText error style={{ fontSize: 12, textAlign: 'right', paddingBottom: 3 }}>
                            Priority is required
                        </FormHelperText>
                    }
                </Grid>
            </Grid>
            {
                data?.properties?.comparison?.map((c_group, index) =>
                    <AllComparison
                        key={`compare_group_${index}`}
                        data={c_group}
                        handleEditMeasureProperties={(g_index, value, action) => handleEditMeasureProperties(g_index, value, action)}
                        propertyPermission={propertyPermission}
                        comparison_group_index={index}
                        group_length={data?.properties?.comparison?.length ?? 0}
                        editMeasure={editMeasure}
                    />
                )
            }
            <Grid item className="dflex alignCenter pt-2">
                <Button className={classes.compareBtn} onClick={onAddNewComparisonGroup} startIcon={<PlusIcon />} variant="text" size="small" color="secondary">
                    Compare More
                </Button>
            </Grid>
        </React.Fragment>
    );
}

// default props
Comparison.defaultProps = {
    classes: {},
    data: {},
    isNew: false,
    editMeasure: () => { },
    propertyPermission: {},
    dataRequired: false
};

// prop types
Comparison.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object,
    dataRequired: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...ComparisonStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Comparison);