// Default Imports
import React from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';


// Import Styles
import style from "./style.jsx";
import classNames from "classnames";

// Import Icons
import { Close, Done } from '@mui/icons-material';

const AcceptRejectIcon = (props) => {
    /**
     * Define Props
     */
    const { classes, className, onHandleAction, size } = props;

    /**
     * Handle accept and reject actions
     * @param {*} event
     * @param {*} status
     */
    const handleAction = (event, status) => {
        event.stopPropagation();
        onHandleAction(status);
    };

    return (
        <Stack direction="row" spacing={1} className={`acceptIcon ${className} ${classNames(classes.acceptIcon, size === "normal" ? classes.defaultIcon : null)}`} onMouseOver={(event) => event.stopPropagation()}>
            <Tooltip title={"Reject"} placement="top" arrow>
                <IconButton className={classNames("icon", "reject")} onClick={(event) => handleAction(event, false)}>
                    <Close />
                </IconButton>
            </Tooltip>
            <Tooltip title={"Accept"} placement="top" arrow>
                <IconButton className={classNames("icon", "accept")} onClick={(event) => handleAction(event, true)}>
                    <Done />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};


/**
 * Define Prop Types
 */
AcceptRejectIcon.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.object,
    size: PropTypes.string,
    onHandleAction: PropTypes.func
};

/**
 * Set Default Values
 */
AcceptRejectIcon.defaultProps = {
    classes: {},
    className: {},
    size: "small",
    onHandleAction: () => { }
};

export default withStyles((theme) => ({
    ...style(theme)
}))(AcceptRejectIcon);