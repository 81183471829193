import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import { MemoTextBoxComponent, NoResultComponent } from '../../../../../../../components/index.js';
import PreviewComponent from '../../../../../../asset/properties/previewComponent/index.jsx';

//  Import Styles
import enumerationListStyle from './style.jsx';
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Import Images
import { DeleteIcon, PlusIcon } from '../../../../../../../assets/svg/index.js';
import { Close, Done, BarChart } from '@mui/icons-material';

// Constants
import appConstants from '../../../../../../../constants/appConstants.js';

function EnumerationList(props) {
    const { classes, enumList, onUpdateEnum, semanticsPermission } = props;

    /**
     * Define State
     */

    const [enums, setEnums] = useState([]);
    const [openPreview, setOpenPreview] = useState(false);

    /**
     * On Update Enum Data
     * @param {*} enumData
     */
    const onSaveEnum = (enumData) => {
        const enums = enumData.map((obj) => {
            const enumValue = { ...obj };
            delete enumValue.count;
            return {
                ...enumValue
            };
        });
        onUpdateEnum(enums);
    };

    /**
     * Define Use Effect
     * @param {*} enumList
     * @description set enum values to localstate
     */
    useEffect(() => {
        setEnums([...enumList]);
    }, [enumList]);


    /**
     * Handle onChange
     * @param {*} value
     * @param {*} index
     */
    const onChange = (property, value, index, enumValue) => {
        const enumData = Object.assign({}, enumValue);
        enumData[property] = value;
        enums[index] = { ...enumData };
        setEnums([...enums]);
        onSaveEnum([...enums]);
    };

    /**
     * Handle Add New Enum
     */
    const onHandleAddNewEnum = () => {
        enums.push({ value: "", is_accept: true, is_decline: false });
        setEnums([...enums]);
    };

    /**
     * Handle Delete Enum
     * @param {*} index
     */
    const onHandleDeleteEnum = (index) => {
        enums.splice(index, 1);
        setEnums([...enums]);
        onSaveEnum([...enums]);
    };

    /**
     * Handle Chart Preview
     */
    const handlePreview = () => {
        const enumList = [...enums].map((obj) => {
            return {
                ...obj,
                name: obj.name || obj.value,
                is_valid: "is_positive" in obj ? obj.is_positive : true,
                count: obj.count || 0
            };
        }).filter((obj) => obj.is_accept);
        setOpenPreview({ open: !openPreview?.open ?? false, data: enumList });
    };


    return (
        <React.Fragment>
            <Grid container justifyContent={'space-between'} alignItems="baseline">
                <Typography variant="h5" className="pb5" align="left">
                    {appConstants.labels.datasetProperties.enumeration}
                </Typography>
                <Grid className="dflex spaceBetween">
                    {
                        enums && enums.length > 0 &&
                        <IconButton
                            onClick={handlePreview}
                            className={classes.chartIconClass}
                        >
                            <BarChart className="chartIcon" />
                        </IconButton>
                    }
                    {
                        semanticsPermission?.is_edit &&
                        <IconButton className={`${classes.PlusIcon}`} onClick={() => onHandleAddNewEnum()}>
                            <PlusIcon />
                        </IconButton>
                    }
                </Grid>
            </Grid>
            <Typography
                align="left"
                variant="body1"
            >
                {appConstants.labels.datasetProperties.enumerationDesc}
            </Typography>
            <Grid item xs={12} className={classes.valuesPatternList}>
                {
                    enums && enums.map((item, index) => (
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            key={`enum_${index}`}
                            className={`${classes.textBoxBg} ${classes.enumTextBox} mb-1 textBoxBg`}
                        >
                            <MemoTextBoxComponent
                                placeholder={"Value"}
                                value={item.value || ""}
                                onChange={(event) => onChange("value", event.target.value, index, item)}
                                fullWidth
                                disabled={!semanticsPermission?.is_edit}
                            />
                            {
                                semanticsPermission?.is_edit && item.is_accept &&
                                <Grid item className={classes.patternValuesRt}>
                                    <IconButton className={classes.pattenDelete} onClick={() => onHandleDeleteEnum(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            }
                            {
                                semanticsPermission?.is_edit && !item.is_accept &&
                                <Grid item>
                                    <Tooltip title={"Accept"} placement="top" arrow>
                                        <IconButton className={classNames("icon", "accept")} onClick={() => onChange("is_accept", true, index, item)}>
                                            <Done />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Reject"} placement="top" arrow>
                                        <IconButton className={classNames("icon", "reject")} onClick={() => onHandleDeleteEnum(index)}>
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        </Grid>
                    ))
                }
                {
                    openPreview && openPreview.open &&
                    <PreviewComponent
                        open={openPreview.open}
                        handleClosePreview={() => handlePreview()}
                        type={'Enum'}
                        data={openPreview.data}
                        updateChartEditChanges={(uEnum) => onSaveEnum(uEnum)}
                        removeFailedRows
                    />
                }
                {!enums.length && <NoResultComponent height="200px" title="No Enumerations Discovered" />}
            </Grid>
        </React.Fragment>
    );
}

// default props
EnumerationList.defaultProps = {
    classes: {},
    enumList: [],
    onUpdateEnum: () => { },
    semanticsPermission: {}
};

// prop types
EnumerationList.propTypes = {
    classes: PropTypes.object,
    enumList: PropTypes.array,
    onUpdateEnum: PropTypes.func,
    semanticsPermission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.enumList, nextProps.enumList) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...enumerationListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(EnumerationList, areEqual));