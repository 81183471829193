const styles = (theme) => ({
    customWidgetContainer: {
        height: 'calc(100% - 0px)',
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'center',
        padding: '30px',
        '& .errorText': {
            paddingBottom: 20,
            color: `#F38080`
        }
    },
    customWidgetContainerInner: {
        height: '100%',
        // minHeight: '550px',
        position: 'relative',
        padding: '20px',
        background: '#fafcff',
        '& .noResultIcon': {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            '& svg': {
                height: '140px',
                width: '140px',
                fill: theme.palette.greyshades.darkgrey
            },
            '& .textSecondary': {
                color: `${theme.palette.greyshades.darkgrey} !important`,
                textAlign: 'center'
            }
        }
    }
});
export default styles;