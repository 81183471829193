import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Typography } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import _ from 'lodash';
import moment from 'moment';

// Import Styles
import QueryStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constants
import appConstants from "../../../../constants/appConstants.js";
import { LoaderButtonComponent, NumberInputComponent, TableComponent } from '../../../../components/index.js';

// Import Actions
import { measureService } from '../../../../redux/service/measureService.js';
import { getDurationText } from '../../../../helpers/appHelpers.js';
import { useSelector } from 'react-redux';


function ValidateMeasure(props) {
    /**
     * Define Props
     */
    const { classes, data, asset_id, type, isAggregateQuery, onCheckAggregateQuery,
        removeAggregateQuery, measureProperties, editMeasure, connection_id, showLimitConfig, validateResult, isMeasureLevel,
        assetName, validateDuplicate, isConnectedAsset, isValidate
    } = props;

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [validatedData, setValidatedData] = useState({
        data: [],
        headers: [],
        queryError: ""
    });
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });
    const [limit, setLimit] = useState(20);
    const tableRef = useRef();
    const { general } = useSelector((state) => state.configurations);
    const { reporting } = general;
    let columnLimit = reporting.export_column_limit ? parseInt(reporting?.export_column_limit || 20) : 20;
    let hasMeasureLimit = false;
    if (measureProperties?.limitConfig?.override) {
        const measureLevelCount = measureProperties?.limitConfig?.column_count;
        hasMeasureLimit = Boolean(measureLevelCount);
        columnLimit = measureLevelCount ? parseInt(measureLevelCount) : columnLimit;
        columnLimit = columnLimit ? columnLimit : 20;
    }


    /**
     * Validate Query
     */
    const validateQuery = () => {
        setIsLoading(true);
        setValidatedData({ ...validatedData, queryError: "" });
        let startTime = moment();
        let timer = setInterval(() => {
            if (!startTime) {
                return;
            }
            const duration = moment.duration(moment().diff(startTime));
            setDuration(duration);
        }, 100);
        const requestParams = {
            asset_id, data, type, connection_id,
            level: isMeasureLevel ? "connection" : "asset",
            asset_name: assetName ? assetName : "",
            is_connected: Boolean(isConnectedAsset),
            validate_duplicate: Boolean(validateDuplicate)
        };
        measureService.validateMeasure(requestParams).then((response) => {
            if (response) {
                setLimit(20);
                const data = response?.data || [];
                const headers = [];
                let queryError = "";
                if (data && data.length) {
                    for (const key in data[0]) {
                        const header = {
                            key: key,
                            name: key || 'Results',
                            sorting: true,
                            isSearch: true,
                            searchComponent: "text",
                            searchKey: key,
                            removeCasing: true
                        };
                        headers.push(header);
                    }
                }
                if (validateResult && headers.length > columnLimit) {
                    const configMessage = hasMeasureLimit ? "override limit" : "reporting";
                    queryError = `The query defined has more columns than the limit specified in the ${configMessage} settings. Please check.`;
                }
                setValidatedData({ queryError, data, headers });
            }
            setIsLoading(false);
        }).catch((error) => {
            setValidatedData({ queryError: error?.response.data ?? "Failed to execute the query", data: [], headers: [] });
            setIsLoading(false);
        }).finally(() => {
            startTime = null;
            clearInterval(timer);
            timer = null;
        });
    };

    const getDisabled = () => {
        if (type === "query") {
            return !data.length;
        }
        return !Object.keys(data);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    useEffect(() => {
        if (data) {
            setValidatedData({ ...validatedData, queryError: "" });
        }
    }, [data, assetName, isConnectedAsset]);


    /**
     * Set Search Data
     */
    useEffect(() => {
        const headers = validatedData?.headers ?? [];
        if (headers && headers.length) {
            const searchHeaders = {};
            for (const header of headers) {
                searchHeaders[header.name] = "";
            }
            setSearchData({ ...searchHeaders });
        }
    }, [validatedData]);

    /**
     * Prepare Filter
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    const onUpdateLimitConfig = (property, value) => {
        const properties = JSON.parse(JSON.stringify(measureProperties));
        const limitConfig = properties?.limitConfig ?? {};
        limitConfig[property] = value;
        properties.limitConfig = limitConfig;
        if (editMeasure) {
            editMeasure("properties", properties);
        }
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const loadMoreRows = () => {
        if (tableRef.current && tableRef.current.scrollHeight - Math.floor(tableRef.current.scrollTop) === tableRef.current.clientHeight) {
            setLimit(limit + 20);
        }
    };

    /**
     * Handle Lazy Load
     */
    useEffect(() => {
        if (tableRef && tableRef.current) {
            const element = tableRef.current;
            element.addEventListener("scroll", loadMoreRows);
            return () => element.removeEventListener("scroll", loadMoreRows);
        }
    }, [tableRef, loadMoreRows]);

    /**
     * Filter Data using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(validatedData?.data ?? [], searchData, sorting.sortBy, sorting.orderBy), [validatedData?.data ?? [], searchData, sorting.sortBy, sorting.orderBy]);
    const durationText = getDurationText(duration, false);

    /**
     * Load Data
     * @param {*} data
     * @param {*} config
     * @returns
     */
    const prepareLoadData = (data, limit) => {
        return data.slice(0, limit);
    };

    const resultData = useMemo(() => prepareLoadData(filterData, limit), [filterData, limit]);


    return (
        <Grid className="mt-2 w-100" style={{ background: 'transparent' }}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}>
                <Grid item className={classes.validateButtonContainer}>
                    {
                        isValidate &&
                        <LoaderButtonComponent isLoading={isLoading} type="button" disabled={getDisabled()} size="small" onClick={() => validateQuery()}>
                            {appConstants.labels.usage.validate}
                        </LoaderButtonComponent>
                    }
                    {
                        durationText &&
                        <Typography>
                            {`Time Taken: ${durationText}`}
                        </Typography>
                    }
                    {
                        !isLoading && validatedData?.queryError &&
                        <FormHelperText error>
                            {validatedData?.queryError ?? ""}
                        </FormHelperText>
                    }
                </Grid>
                <Grid item className={classes.queryActionContainer}>
                    {
                        type !== "behavioral" && showLimitConfig &&
                        <Grid>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={measureProperties?.limitConfig?.override || false}
                                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                        checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                        onChange={() => onUpdateLimitConfig("override", !measureProperties?.limitConfig?.override)}
                                    />
                                }
                                label={
                                    <Typography variant="body1">
                                        {"Override Limit"}
                                    </Typography>
                                }
                            />
                        </Grid>
                    }
                    {
                        type !== "behavioral" && showLimitConfig && measureProperties?.limitConfig?.override &&
                        <Grid className={"limitConfig ml-1 mr-1"}>
                            <NumberInputComponent
                                name="row_count"
                                label={appConstants.labels.general.exportRowLimit}
                                placeholder={appConstants.labels.general.exportRowLimit}
                                variant="standard"
                                allowNegative={false}
                                size="medium"
                                value={measureProperties?.limitConfig?.row_count || ""}
                                onChange={(value) => onUpdateLimitConfig("row_count", value)}
                            />
                            <NumberInputComponent
                                name="column_count"
                                label={appConstants.labels.general.exportColumnLimit}
                                placeholder={appConstants.labels.general.exportColumnLimit}
                                variant="standard"
                                allowNegative={false}
                                size="medium"
                                value={measureProperties?.limitConfig?.column_count || ""}
                                onChange={(value) => onUpdateLimitConfig("column_count", value)}
                            />
                        </Grid>
                    }
                    {
                        type === "query" && !removeAggregateQuery &&
                        <Grid className="ml-1">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAggregateQuery}
                                        icon={<CheckBoxOutlineBlankOutlinedIcon />}
                                        checkedIcon={<CheckBoxOutlinedIcon className="checkedIcon" />}
                                        onChange={() => onCheckAggregateQuery(!isAggregateQuery)}
                                    />
                                }
                                label={
                                    <Typography variant="body1">
                                        {"Aggregate Query"}
                                    </Typography>
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container className={"validationResultContainer"}>
                {
                    !isLoading && validatedData?.data?.length > 0 &&
                    <Grid item xs={12} className="pt-2">
                        <TableComponent
                            headers={validatedData?.headers ?? []}
                            data={resultData}
                            title={appConstants.labels.usage.preview}
                            options={[]}
                            styleType="striped"
                            NoResultText="No Records Found"
                            height="200px"
                            stickyHeader
                            searchData={searchData}
                            sortBy={sorting.sortBy}
                            orderBy={sorting.orderBy}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickSorting={onClickSorting}
                            exportParams={
                                {
                                    data: filterData || [],
                                    fileName: "Preview.csv",
                                    headers: validatedData?.headers?.map((obj) => obj.key) || []
                                }
                            }
                            tableRef={tableRef}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

// default props
ValidateMeasure.defaultProps = {
    classes: {},
    data: "",
    asset_id: null,
    connection_id: null,
    type: "query",
    isAggregateQuery: false,
    removeAggregateQuery: false,
    validateResult: false,
    showLimitConfig: false,
    onCheckAggregateQuery: () => { },
    measureProperties: {},
    editMeasure: () => { },
    isMeasureLevel: false,
    assetName: "",
    isConnectedAsset: false,
    validateDuplicate: false,
    isValidate: false
};

// prop types
ValidateMeasure.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.string,
    asset_id: PropTypes.string,
    type: PropTypes.string,
    isAggregateQuery: PropTypes.bool,
    removeAggregateQuery: PropTypes.bool,
    showLimitConfig: PropTypes.bool,
    validateResult: PropTypes.bool,
    onCheckAggregateQuery: PropTypes.func,
    measureProperties: PropTypes.object,
    editMeasure: PropTypes.func,
    connection_id: PropTypes.string,
    isMeasureLevel: PropTypes.bool,
    assetName: PropTypes.string,
    isConnectedAsset: PropTypes.bool,
    validateDuplicate: PropTypes.bool,
    isValidate: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.assetName, nextProps.assetName)
        && _.isEqual(prevProps.isConnectedAsset, nextProps.isConnectedAsset)
        && _.isEqual(prevProps.classes, nextProps.classes)
        && _.isEqual(prevProps.isAggregateQuery, nextProps.isAggregateQuery)
        && _.isEqual(prevProps.measureProperties, nextProps.measureProperties)
        && _.isEqual(prevProps.connection_id, nextProps.connection_id);
}

export default withStyles(
    (theme) => ({
        ...QueryStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(ValidateMeasure, areEqual));