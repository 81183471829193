const palette = {
  primary: {
    main: "#F38080",
    contrastText: "#fff"
  },
  secondary: {
    main: "#64AAEF",
    contrastText: "#fff"
  },
  greyshades: {
    darkestgrey: "#122740",
    darkgrey: "#B0BAC9",
    grey: "#E5E7EB",
    lightgrey: "#ECEFF2",
    lightestgrey: "#F4F6FC",
    lightbackground: '#F5F7F8',
    tablegrey: '#F4F7FA'
  },
  headers: {
    h1: {
      fontFamily: "Poppins, sans-serif",
      size: 26,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    h2: {
      fontFamily: "Poppins, sans-serif",
      size: 22,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    h3: {
      fontFamily: "Poppins, sans-serif",
      size: 18,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    h4: {
      fontFamily: "Poppins, sans-serif",
      size: 17,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    h5: {
      fontFamily: "Poppins, sans-serif",
      size: 16,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    h6: {
      fontFamily: "Poppins, sans-serif",
      size: 15,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    body1: {
      fontFamily: "Lato, sans-serif",
      size: 14,
      weight: 400,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    body2: {
      fontFamily: "Lato, sans-serif",
      size: 13,
      weight: 400,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    subtitle1: {
      fontFamily: "Lato, sans-serif",
      size: 14,
      weight: 400,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    subtitle2: {
      fontFamily: "Lato, sans-serif",
      size: 12,
      weight: 400,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    primaryButton: {
      fontFamily: "Poppins, sans-serif",
      size: 14,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    secondaryButton: {
      fontFamily: "Poppins, sans-serif",
      size: 14,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    textLink: {
      fontFamily: "Poppins, sans-serif",
      size: 14,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    caption: {
      fontFamily: "Poppins, sans-serif",
      size: 15,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    },
    overline: {
      fontFamily: "Lato, sans-serif",
      size: 15,
      weight: 500,
      style: "normal",
      textDecoration: "auto",
      transform: "unset",
      color: '#2E384D'
    }
  },
  componentColors: {
    default: "#B2BCCA",
    secondary: "#B2DBF7",
    success: "#54DF9A",
    warning: "#FDAC42",
    info: "#64AAEF",
    danger: "#F48A8A",
    shade1: "#DE628E",
    //red
    shade2: "#FFB8A3",
    //orange
    shade3: "#FFD778"
    //yellow
  },
  colorThemes: {
    high: '#F38080',
    medium: '#FDAC42',
    low: '#54DF9A',
    tags: '#64AAEF',
    dimension: '#FFAF97',
    measuresOne: '#FFD778',
    measuresTwo: '#F295B6',
    ok: "#B2BCCA",
    isAuto: '#F38080',
    behavioral: '#FDAC42',
    comparison: '#54DF9A',
    conditional: '#64AAEF',
    query: '#FFAF97'
  },
  chartColors: {
    score25: '#FF5C5C',
    score50: '#FDAC42',
    score100: '#54DF9A'
  },
  randomColors: ["#FF685C", "#E8DAB2", "#C0D6DF", "#77BA99", "#F68E5F", "#F7C59F", "#B5D99C", "#A8E6DF", "#798E56", "#F6867F", "#464C8B", "#EA8CC0", "#FFE08E", "#C1AA5D", "#BAC7B1", "#75A2C4", "#6A7890", "#C897A2", "#D1C6B1", "#EFEFD0", "#B5D99C", "#586BA4", "#F5DD90", "#D7C0D0"],
  titleFont: "Poppins, sans-serif",
  FontBold: "Nunito",
  FontMedium: "Nunito",
  FontRegular: "Nunito",
  FontLight: "Nunito",
  textFont: "Lato, sans-serif",
  boxShadow: '0px 0px 4px rgb(237 237 237 / 90%) !important',
  boxShadow1: '0px 0px 14px rgb(237 237 237 / 90%) !important',
  boxShadow2: '0px 0px 8px rgb(237 237 237 / 78%) !important',
  greyText: "#9FAEC3"
};
export default palette;