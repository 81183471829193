import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import {
    themeDetailRequest,
    themeDetailSuccess,
    themeDetailFailure,
    themeUpdateRequest,
    themeUpdateSuccess,
    themeUpdateFailure,
    generalSettingsRequest,
    generalSettingsSuccess,
    generalSettingsFailure,
    generalUpdateRequest,
    generalUpdateSuccess,
    generalUpdateFailure,
    createBannerRequest,
    createBannerSuccess,
    createBannerFailure,
    getBannerRequest,
    getBannerSuccess,
    getBannerFailure,
    deleteBannerRequest,
    deleteBannerSuccess,
    deleteBannerFailure,
    generalScheduleRequest,
    generalScheduleSucess,
    generalScheduleFailure,
    getGeneralThemeRequest,
    getGeneralThemeSucess,
    getGeneralThemeFailure
} from '../reducer/configurationsReducer';

import { displyAlert } from "../reducer/alertReducer";

import { configurationsService } from '../service';


// Import Constants
import appConstants from '../../constants/appConstants';


/**
 * Theme Detail actions
 * @param {*} action$
 * @returns
 */
const themeDetail = (action$) =>
    action$.pipe(
        ofType(themeDetailRequest),
        mergeMap(({ payload }) => {
            return from(configurationsService.get_theme_detail(payload)).pipe(
                concatMap((res) => of(themeDetailSuccess(res))),
                catchError(() => of(themeDetailFailure()))
            );
        })
    );

/**
 * Theme Update Actions
 * @param {*} action$
 * @returns
 */
const themeUpdate = (action$) =>
    action$.pipe(
        ofType(themeUpdateRequest),
        mergeMap(({ payload }) => {
            return from(configurationsService.update_theme(payload)).pipe(
                concatMap((res) => of(themeUpdateSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
                catchError(() => of(themeUpdateFailure()))
            );
        })
    );

/**
 * General Detail actions
 * @param {*} action$
 * @returns
 */
const generalSettings = (action$) =>
    action$.pipe(
        ofType(generalSettingsRequest),
        mergeMap(({ payload }) => {
            return from(configurationsService.get_general_settings(payload)).pipe(
                concatMap((res) => of(generalSettingsSuccess(res))),
                catchError(() => of(generalSettingsFailure()))
            );
        })
    );

/**
 * General Settings Update Actions
 * @param {*} action$
 * @returns
 */
const generalUpdate = (action$) =>
    action$.pipe(
        ofType(generalUpdateRequest),
        mergeMap(({ payload }) => {
            return from(configurationsService.update_general(payload)).pipe(
                concatMap((res) => of(generalUpdateSuccess(res))),
                catchError(() => of(generalUpdateFailure()))
            );
        })
    );

/**
 * Create Application
 * @param {*} action$
 * @returns
 */
 const createBanner = (action$) => action$.pipe(
    ofType(createBannerRequest),
    mergeMap(({ payload }) => {
        return from(configurationsService.create_banner(payload)).pipe(
            concatMap((res) => {return of(createBannerSuccess(res)); }),
            catchError((error) => { return of(createBannerFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
  );


/**
 * Get Banners List
 * @param {*} action$
 * @returns
 */
 const getBanners = (action$) =>
 action$.pipe(
     ofType(getBannerRequest),
     mergeMap(({ payload }) => {
         return from(configurationsService.get_banners(payload)).pipe(
             concatMap((res) => of(getBannerSuccess(res))),
             catchError(() => of(getBannerFailure()))
         );
     })
 );

 /**
  * Delete Banner
  * @param {*} action$
  * @returns
  */
const deleteBanner = (action$) => action$.pipe(
    ofType(deleteBannerRequest),
    mergeMap(({ payload }) => {
        return from(configurationsService.delete_banner(payload)).pipe(
            concatMap(() => of(deleteBannerSuccess(payload))),
            catchError((error) => of(deleteBannerFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
  );


/**
 * Semantics Schedule Next Run
 * @param {*} action$
 * @returns
 */
const generalSchedule = (action$) =>
    action$.pipe(
        ofType(generalScheduleRequest),
        mergeMap(({ payload }) => {
            return from(configurationsService.get_general_schedule(payload)).pipe(
                concatMap((res) => of(generalScheduleSucess(res))),
                catchError(() => of(generalScheduleFailure()))
            );
        })
    );


/**
 * Get selected banners
 * @param {*} action$
 * @returns
 */
 const getGeneralTheme = (action$) => action$.pipe(
    ofType(getGeneralThemeRequest),
    mergeMap(({ payload }) => {
        return from(configurationsService.get_selected_banners(payload)).pipe(
            concatMap((res) => {return of(getGeneralThemeSucess(res?.data?.banners || [])); }),
            catchError((error) => { return of(getGeneralThemeFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
  );

// Export All Epic Functions
export const configurationsEpic = [themeDetail, themeUpdate, generalSettings, generalUpdate, createBanner, getBanners, deleteBanner, generalSchedule, getGeneralTheme];