import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, InputBase, Paper, IconButton, ClickAwayListener } from '@mui/material';
import { withStyles } from "@mui/styles";

// Import Style
import style from './style.jsx';

// Import Icons
import SearchIcon from '@mui/icons-material/Search';
import Logo from '../../assets/img/dq_logo.png';

import { SearchSuggestionsComponent } from "../index";

const SearchComponent = (props) => {
	/**
	 * Define Props
	 */
	const { classes, className, placeholder, onChange, value, onBlur, fullWidth, searchPosition, showDqLogo, delayTimeOut, onEnterKeyPress, handleOnFocus,
		showSuggestion, suggestions, suggestionClickEvent, isLoading, clearSearchEvent } = props;

	/**
	 * Define States
	 */
	const [classname, setClassname] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [displaySuggestionBox, setDispalySuggestionBox] = useState(false);

	/**
	 * Set Search Value
	 */
	useEffect(() => {
		setSearchText(value);
	}, [value]);

	/**
	 * Handle Search
	 */
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchText !== value) {
				onChange(searchText);
			}
		}, delayTimeOut);

		return () => clearTimeout(delayDebounceFn);
	}, [searchText, value, delayTimeOut]);

	/**
	 * Define action on Blur
	 */
	const inputBlur = () => {
		if (onBlur) {
			onBlur();
		}
		setClassname(null);
		const timer = setTimeout(() => { setDispalySuggestionBox(false); clearTimeout(timer); }, 200);
	};

	/**
	 * Handle Search Event
	 * @param {*} val
	 * @param {*} isClear
	 */
	const handleSearchChangeEvent = (val, isClear = false) => {
		setSearchText(val);
		if (isClear) {
			onChange(val);
		}
	};

	/**
	 * Handle Key Down Event
	 * @param {*} event
	 */
	const handleOnKeyDown = (event) => {
		if (event.key === 'Enter') {
			onEnterKeyPress({ "search_key": searchText }, 'suggestion');
		}
	};

	return (
		<React.Fragment>
			<FormControl>
				<Paper className={`${"searchBox"} ${classname} ${classNames(classes.searchContainer)} ${classname ? "activeSearchBox" : ""}`}>
					{
						searchPosition === 'left' &&
						<SearchIcon className="searchIcon" />
					}

					{
						showDqLogo &&
						<img src={Logo} className={`${classes.appLogo} appLogo`} alt="app logo" />
					}
					{/* To avoid issue DQL-1091 -- Trending search is not working when clicking on the any one of the asset. -- onBlur is replaced with ClickAwayListener */}
					<ClickAwayListener onClickAway={inputBlur}>
						<InputBase
							autoComplete={"off"}
							className={`${classes.input} ${className}`}
							placeholder={placeholder}
							inputProps={{ 'aria-label': placeholder, maxLength: 100 }}
							onChange={
								(event) => {
									handleSearchChangeEvent(event.target.value);
								}
							}
							// onBlur={() => inputBlur()}
							onFocus={() => { setClassname("active"); setDispalySuggestionBox(true); handleOnFocus(); }}
							onKeyDown={(event) => handleOnKeyDown(event)}
							value={searchText ? searchText : ''}
							fullWidth={fullWidth}
							size="large"
						/>
					</ClickAwayListener>
					{/* {
						!isLoading && */}
						<IconButton
							className={`${searchText.length > 0 || classname === "active" ? 'visibleClear' : 'hiddenClear'} ${searchPosition === 'right' && 'searchRtWithClear'} searchClearIcon`}
							style={{ width: '30px', padding: 5 }}
							onClick={() => { handleSearchChangeEvent('', true); clearSearchEvent(); }}
						>
							<svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
								<path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
								<path className="fillPath" id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
							</svg>
						</IconButton>
					{/* } */}

					{
						!isLoading && searchPosition === 'right' && searchText.length === 0 && classname !== "active" &&
						<SearchIcon className="searchIconRt" />
					}

					{/* {
						isLoading &&
						<CircularProgress size={24} color="primary" />
					} */}

				</Paper>
			</FormControl>

			{
				showSuggestion && displaySuggestionBox &&
				<SearchSuggestionsComponent
					suggestion={suggestions?.suggestion ?? []}
					tracking={suggestions?.tracking ?? []}
					showTracking={!searchText}
					suggestionClickEvent={(value, type) => suggestionClickEvent(value, type)}
					search_key={searchText}
				/>
			}
		</React.Fragment >
	);
};

/**
 * Define Props
 */
SearchComponent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	fullWidth: PropTypes.bool,
	searchPosition: PropTypes.string,
	showDqLogo: PropTypes.bool,
	delayTimeOut: PropTypes.number,
	onEnterKeyPress: PropTypes.func,
	handleOnFocus: PropTypes.func,
	showSuggestion: PropTypes.bool,
	suggestions: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
	suggestionClickEvent: PropTypes.func,
	isLoading: PropTypes.bool,
	clearSearchEvent: PropTypes.func
};

/**
 * Set Default Values
 */
SearchComponent.defaultProps = {
	classes: {},
	className: "",
	value: "",
	placeholder: "",
	onChange: () => { },
	onBlur: () => { },
	fullWidth: false,
	searchPosition: 'left',
	showDqLogo: false,
	delayTimeOut: 0,
	onEnterKeyPress: () => { },
	handleOnFocus: () => { },
	showSuggestion: false,
	suggestions: [],
	suggestionClickEvent: () => { },
	isLoading: false,
	clearSearchEvent: () => { }
};


export default withStyles(style)(SearchComponent);