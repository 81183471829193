import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent } from '../../../../../../components/index';

//Constants

function FailedRows(props) {

    /**
     * Define Props
     */
    const { classes } = props;

    return (
        <Grid item xs={12} className={classes.FailedRowsContainer}>
            <TableComponent
                styleType="striped"
                NoResultText="No Items Found"
                height="calc(100vh - 300px)"
                headers={
                    [
                        { key: 'dynamic header', name: 'Name' },
                        { key: 'dynamic header', name: 'Name' }
                    ]
                }
                /*
                 * data={Object.keys(data)}
                 * tableData={data}
                 */
                stickyHeader
            />
        </Grid>
    );
}

// default props
FailedRows.defaultProps = {
    classes: {}
};

// prop types
FailedRows.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FailedRows);