import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

//import Componetns
import { ChipComponent, MemoTextBoxComponent, SwitchComponent } from '../../../../components/index.js';

//import Styles
import HomeStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';
import appConstants from '../../../../constants/appConstants.js';

// Import Actions
import { updateAttributeMetadataRequest, updateAttributeMetadata } from '../../../../redux/reducer/attributeReducer';
import { getTagsRequest } from '../../../../redux/reducer/tagsReducer';
import { getTermsRequest } from '../../../../redux/reducer/termReducer';
import { termApprovalRequest } from '../../../../redux/reducer/versionReducer.js';
import { checkPermission } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';


function AttributeInfo(props) {
    const { classes, propertiesPermission } = props;
    const dispatch = useDispatch();
    const { attribute_id } = useParams();

    /**
     * Get data from redux store
     */
    const { detail: selectedAttribute } = useSelector((state) => state.attribute, shallowEqual);
    const { searchableTagsList } = useSelector((state) => state.tags, shallowEqual);
    const { searchableTermsList } = useSelector((state) => state.term, shallowEqual);
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     *
     * Get Tags
     */
    useEffect(() => {
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }

        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
    }, [dispatch]);

    /**
     * Handle On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const requestParams = {
            [property]: value,
            attribute_id
        };
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
    };

    /**
     * Hangle Tag Change
     * @param {*} property
     * @param {*} value
     */
    const handleTagChange = (property, value) => {
        const requestParams = {
            [property]: value,
            attribute_id
        };
        const ids = value?.map((item) => item.id);
        dispatch(updateAttributeMetadata(requestParams));
        dispatch(updateAttributeMetadataRequest({ [property]: ids, attribute_id }));
    };

    /**
     * Hangle Term Change
     * @param {*} property
     * @param {*} value
     */
    const handleTermChange = (property, value) => {
        const requestParams = {
            attribute_id
        };
        if (property === "term") {
            let termId = '';
            if (value.length) {
                termId = value[0].id;
            }
            requestParams.term = termId;
            requestParams.term_approval_id = termId;
            value = termId;
        } else {
            requestParams[[property]] = value;
        }
        dispatch(updateAttributeMetadata(requestParams));
        dispatch(updateAttributeMetadataRequest({ [property]: value, attribute_id }));
    };

    /**
     * Handle Term approval
     * @param {*} isApproved
     * @param {*} term
     */
    const handleTermApproval = (isApproved, term) => {
        const requestParams = {
            asset_id: selectedAttribute.asset_id,
            version_id: selectedAttribute.version_id,
            term_id: term.id,
            attribute_id: selectedAttribute.id,
            is_approved: isApproved
        };
        dispatch(termApprovalRequest(requestParams));
        const metadataRequestParams = {
            term: isApproved ? term.id : null,
            term_approval_id: isApproved ? term.id : null,
            attribute_id
        };
        dispatch(updateAttributeMetadata(metadataRequestParams));
    };

    /**
     * Check the term is approved or not
     */
    const checkIsApprovedTerm = (selectedAttribute) => {
        const isApprovedTerm = Boolean(selectedAttribute && selectedAttribute.term && selectedAttribute.term_approval_id);
        return isApprovedTerm;
    };

    /**
     * Get Terms Values
     */
    const getTermValue = (list, value) => {
        const data = list.find((obj) => obj.id === value);
        return data;
    };
    let selectedTerm = useMemo(() => getTermValue(searchableTermsList, selectedAttribute?.term), [searchableTermsList, selectedAttribute?.term]);
    if (selectedTerm) {
        selectedTerm = { ...selectedTerm, approval_id: selectedAttribute?.term_approval_id ?? null };
    }
    const isApprovedTerm = checkIsApprovedTerm(selectedTerm, selectedAttribute);

    return (
        <Grid container className={classes.topSection} spacing={4}>
            <Grid item xs={6} className={classes.description}>
                <Typography variant="h6" className="pb5">
                    {appConstants.labels.datasetProperties.description}
                </Typography>
                <MemoTextBoxComponent
                    name="description"
                    placeholder="Enter description"
                    fullWidth
                    multiline
                    variant="standard"
                    rows={2}
                    value={selectedAttribute?.description ?? ""}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    disabled={!propertiesPermission?.is_edit}
                />
            </Grid>
            <Grid item xs={6} className="pl-5">
                <Typography variant="h6" className="pb5">
                    {appConstants.labels.datasetProperties.primary}
                </Typography>
                <Typography
                    variant="body1"
                    className={`${classes.propertiesDescription}`}
                >
                    {appConstants.labels.datasetProperties.primaryDesc}
                </Typography>
                <Grid item className="dflex alignCenter spaceBetween mt-2">
                    <SwitchComponent size="small"
                        checked={selectedAttribute.is_primary_key || false}
                        handleChange={(value) => onChange("is_primary_key", value)}
                        label={selectedAttribute.is_primary_key ? appConstants.labels.datasetProperties.on : appConstants.labels.datasetProperties.off}
                        disabled={selectedAttribute?.default_primary || Boolean(!propertiesPermission?.is_edit)} />
                </Grid>
            </Grid>
            <Grid item xs={6} className="pr-3">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h6" className="pb5">
                        {appConstants.labels.datasetProperties.terms}
                    </Typography>
                    <Tooltip title={appConstants.labels.tooltips.enableSemanticDiscovery}>
                        <Grid className={"ml-1 mb5"}>
                            <SwitchComponent size="small"
                                checked={Boolean(selectedAttribute && selectedAttribute.is_semantic_enabled) || false}
                                handleChange={(value) => handleTermChange("is_semantic_enabled", value)}
                                disabled={Boolean(!propertiesPermission?.is_edit)} />
                        </Grid>
                    </Tooltip>
                </Stack>
                <Typography variant="body1" className="pb-1">
                    {appConstants.labels.datasetProperties.termsDesc}
                </Typography>
                <Grid item className={`${classes.muiChip} dflex alignCenter mt-1`}>
                    <ChipComponent
                        labelKey="name"
                        data={selectedTerm ? [selectedTerm] : []}
                        limit={2}
                        editable={Boolean(semanticsPermission?.is_edit)}
                        add={!selectedAttribute.term && Boolean(semanticsPermission?.is_edit)}
                        disableInput={false}
                        addType="autocomplete"
                        className={'termsChips'}
                        availableList={searchableTermsList}
                        addLimit={1}
                        addText="Add Terms"
                        handleChipEvent={(value) => handleTermChange('term', value)}
                        acceptActions={!isApprovedTerm && Boolean(semanticsPermission?.is_edit)}
                        onHandleAcceptAction={(status) => handleTermApproval(status, selectedTerm)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} className="pl-5">
                <Typography variant="h6" className="pb5">
                    {appConstants.labels.datasetProperties.tags}
                </Typography>
                <Typography variant="body1" className="pb-1">
                    {appConstants.labels.datasetProperties.tagsDesc}
                </Typography>
                <Grid item className={`${classes.muiChip} dflex alignCenter mt-1`}>
                    <ChipComponent
                        data={selectedAttribute?.tags ?? []}
                        labelKey="name"
                        placeholder="Select Tags"
                        add={Boolean(propertiesPermission?.is_edit)}
                        fullWidth
                        availableList={searchableTagsList}
                        editable={Boolean(propertiesPermission?.is_edit)}
                        handleChipEvent={(value) => handleTagChange('tags', value)}
                        addType="autocomplete"
                        haveColor
                        limit={2}
                        addText="Add Tags"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AttributeInfo.defaultProps = {
    classes: {},
    propertiesPermission: {}
};

// prop types
AttributeInfo.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...HomeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AttributeInfo);