const style = (theme) => ({
    labelContainer: {
        zIndex: 0,
        background: "#fff",
        border: `1px solid #E5E7EB`,
        borderRadius: 6,
        position: "relative",
        padding: 25,
        '& form': {
            width: "100% !important"
        }
    },
    label: {
        color: `${theme.palette.greyshades.darkgrey} !important`,
        fontWeight: 600,
        position: "absolute",
        top: -17,
        left: 15,
        background: "#fff",
        padding: "5px 26px 5px 10px"
    },
    profileText: {
        color: `${theme.palette.greyshades.darkgrey} !important`,
        fontWeight: 600,
        position: "absolute",
        bottom: 5,
        right: 25,
        background: "#fff",
        cursor: "pointer",
        '& span': {
            color: `${theme.palette.secondary.main} !important`
        }
    }
});
export default style;