const style = () => ({
    widgetItemDetail: {
    },
    widgetListIcon: {
        marginRight: '15px !important'
    },
    progressBar: {
        minWidth: '100px'
    }
});

export default style;