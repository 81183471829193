import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import styles
import style from './style.jsx';

// Import Images
import { VersionIcon, AttributesIcon, AssetIcon, SheetIcon, DashboardIcon, ViewIcon, QueryIcon, PipelineIcon, DocumentIcon } from '../../assets/svg/index.js';

function AssetTypeIcon(props) {

	/**
	 * Define Props
	 */
	const { asset_type } = props;

	/**
	 * Connectors Data
	 */
	const assetIcons = [
		{
			icon: <AssetIcon />,
			asset_type: ['asset', 'base table', 'table', 'external table']
		},
		{
			icon: <ViewIcon />,
			asset_type: ['view']
		},
		{
			icon: <QueryIcon />,
			asset_type: ['query']
		},
		{
			icon: <SheetIcon />,
			asset_type: ['sheet', 'reports']
		},
		{
			icon: <DashboardIcon />,
			asset_type: ['dashboard']
		},
		{
			icon: <VersionIcon />,
			asset_type: ['version']
		},
		{
			icon: <PipelineIcon />,
			asset_type: ['pipeline']
		},
		{
			icon: <AttributesIcon />,
			asset_type: ['attribute']
		},
		{
			icon: <DocumentIcon />,
			asset_type: ['measure']
		}
	];


	/**
	 * Get Icon
	 * @returns
	 */
	const bindIcon = () => {
		const data = assetIcons.find((data) => data.asset_type.indexOf(asset_type.toLowerCase()) >= 0);

		if (data) { return data.icon; }

		return null;
	};


	return (
		bindIcon()
	);
}

/**
 * Define Component Props
 */
AssetTypeIcon.propTypes = {
	classes: PropTypes.object,
	size: PropTypes.string,
	asset_type: PropTypes.string
};

/**
 * Define Default Values
 */
AssetTypeIcon.defaultProps = {
	classes: {},
	size: "small",
	asset_type: '',
	connection_type: ''
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.asset_type, nextProps.asset_type) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(style)(React.memo(AssetTypeIcon, areEqual));