import * as React from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

export default function PopOverComponent(props) {

    /**
     * Define Props
     */
    const { anchorEl, onClose, children, className, ...rest } = props;

    /**
     * Define Values
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                className={className}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }
                {...rest}
            >
                {children}
            </Popover>
        </div>
    );
}

// default props
PopOverComponent.defaultProps = {
};

// prop types
PopOverComponent.propTypes = {
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};