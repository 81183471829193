const style = (theme) => ({
    chartContainer: {
        padding: '30px',
        background: '#FCFDFD',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textAlign: 'right',
        marginTop: '28px',
        marginBottom: 30
    },
    failedText: {
        background: '#FEF5F5',
        // border: '1px solid #F38080',
        borderRadius: '4px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        '& .failedTextDesc': {
            marginTop: '-2px !important'
        },
        '& .MuiButton-root': {
            textTransform: 'capitalize',
            fontWeight: 400
        },
        '& .whitBg': {
            background: '#fff !important',
            color: '#222'
        },
        '& .DangerIcon': {
            // fill: '#F38080',
            marginRight: 10
        },
        '&.high': {
            background: `${theme.palette.colorThemes.high}10`,
            border: `1px solid ${theme.palette.colorThemes.high}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.high
            }
        },
        '&.medium': {
            background: `${theme.palette.colorThemes.medium}10`,
            border: `1px solid ${theme.palette.colorThemes.medium}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.medium
            }
        },
        '&.low': {
            background: `${theme.palette.colorThemes.low}10`,
            border: `1px solid ${theme.palette.colorThemes.low}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.low
            }
        },
        '&.ok': {
            background: `${theme.palette.colorThemes.ok}10`,
            border: `1px solid ${theme.palette.colorThemes.ok}40`,
            '& .DangerIcon': {
                fill: theme.palette.colorThemes.ok
            }
        }
    },
    daysFilter: {
        background: '#fff',
        marginLeft: 20,
        '& .MuiToggleButton-root': {
            padding: '5px 10px',
            textTransform: 'unset',
            fontWeight: 500
        },
        '& .MuiTypography-root': {
            fontSize: 13
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: `${theme.palette.secondary.main} !important`
            },
            '& .MuiTypography-root': {
                fontSize: 13,
                color: `#fff !important`
            }
        }
    },
    pageContainer: {
        '& .MuiTableCell-root': {
            '& .MuiChip-root': {
                '&:active': {
                    boxShadow: 'none'
                },
                '& .MuiTouchRipple-root': {
                    display: 'none'
                }
            }
        }
    }
});
export default style;