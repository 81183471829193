import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

//  Import Components
import DetailCard from '../../components/detailCard/index.jsx';
import { NavigationTabHeaderComponent, UsersComponent, LoaderComponent } from '../../../../components/index.js';


//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { DocumentIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { getDomainDetailRequest, clearDomainDetails } from '../../../../redux/reducer/domainsReducer.js';
import { getUsersThumbnailRequest } from '../../../../redux/reducer/userReducer.js';
import { updateGlossaryRequest } from '../../../../redux/reducer/semanticReducer';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Helpers
import { checkPermission } from '../../../../helpers/appHelpers.js';

function DomainDetail(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * get Domain ID from URL
     */
    const { id: domain_id } = useParams();

    /**
     * Get Domain Details from Reducer
     */
    const { detail, isLoading } = useSelector((state) => state.domains);
    const { thumbnail_list: usersList } = useSelector((state) => state.user);
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     * Get Domain Asset Request
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        if (domain_id) {
            dispatch(getDomainDetailRequest(domain_id));
        }
        return () => {
            dispatch(clearDomainDetails());
        };
    }, [domain_id]);


    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);

    /**
     * Handle Add User
     * @param {*} property
     * @param {*} value
     */
    const handleAddUserChange = (value) => {
        value = value.map((user) => user.id);
        dispatch(updateGlossaryRequest({ ...detail, "users": value }));
    };

    /**
     * Filter Mapped Users
     * @param {*} users
     * @param {*} userList
     * @returns
     */
    const getUsers = (users, userList) => {
        const usersData = typeof (userList) === "string" ? JSON.parse(userList) : userList;
        return users.filter((user) => usersData.includes(user.id));
    };
    const users = useMemo(() => getUsers(usersList, detail.users_list || []), [usersList, detail.users_list || []]);


    return (
        <Grid container spacing={6} className={classes.DomainDetailPageContainer}>
            {
                isLoading ?
                    <LoaderComponent /> :
                    <Fragment>
                        <Grid item xs={5} className={classes.leftContent}>
                            <Grid>
                                <Typography variant="h3" className="domainDetailTitle">
                                    {detail?.name || ""}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {detail?.description || ""}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Grid container>
                                    <Grid className="dflex alignCenter mt-2 mr-2">
                                        <DocumentIcon />
                                        <Typography variant="h6" className="ml-1">
                                            {`${detail?.assets || 0} Assets`}
                                        </Typography>
                                    </Grid>
                                    <Grid className="dflex alignCenter mt-2">
                                        <DocumentIcon />
                                        <Typography variant="h6" className="ml-1">
                                            {`${detail?.measures || 0} Measures`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid className="mt-2">
                                    <UsersComponent
                                        list={usersList}
                                        data={users || []}
                                        isSteward={false}
                                        isTableComponent
                                        handleStewardUser={(users) => handleAddUserChange(users)}
                                        disabled={!semanticsPermission?.is_edit}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7}>
                            <DetailCard data={detail} type={'domain'} />
                        </Grid>

                        <Grid item xs={12} className={classes.tabsSection}>
                            <Grid className={classes.tabsHeaderSection} container wrap="nowrap">
                                <NavigationTabHeaderComponent
                                    tabList={appConstants.tabs.domainDetailTabs}
                                />
                            </Grid>
                            <Grid className={classes.tabsBodySection}>
                                <Grid className={classes.tabsBody}>
                                    <Outlet />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
            }
        </Grid>
    );
}

// default props
DomainDetail.defaultProps = {
    classes: {}
};

// prop types
DomainDetail.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DomainDetail);