import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

//  Import Components
import StyleGuideList from './list/index.jsx';
import Preview from './preview/index.jsx';

//  Import Styles
import StyleGuideStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//constant
import appConstants from '../../../../constants/appConstants.js';

// Import Images

function StyleGuide(props) {

    /**
     * Define Props
     */
    const { classes, data, handleThemeChange, handleThemeSave, handleThemeReset, editPermission } = props;

    return (
        <Grid item xs={12} className={classes.styleGuidePageContainer}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.theme.styleGuide}
                </Typography>
            </Grid>
            <Grid className={classes.bodySection}>
                <Typography variant="body1" className="mb-3">
                    {appConstants.labels.theme.styleGuideDesc}
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={7} xl={8}>
                        <StyleGuideList
                            data={data}
                            handleThemeChangeEvent={handleThemeChange}
                            handleThemeSave={handleThemeSave}
                            handleThemeReset={handleThemeReset}
                            editPermission={editPermission}
                        />
                    </Grid>
                    <Grid item xs={5} xl={4}>
                        <Preview theme={data} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
StyleGuide.defaultProps = {
    classes: {},
    data: {},
    handleThemeChange: () => { },
    handleThemeSave: () => { },
    handleThemeReset: () => { },
    editPermission: false
};

// prop types
StyleGuide.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleThemeChange: PropTypes.func,
    handleThemeSave: PropTypes.func,
    handleThemeReset: PropTypes.func,
    editPermission: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...StyleGuideStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StyleGuide);