import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { SelectComponent, SwitchComponent, NumberInputComponent } from '../../../../components/index.js';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

//Import actions
import { updateGeneralInReducer, generalUpdateRequest } from '../../../../redux/reducer/configurationsReducer';
import { checkPermission } from '../../../../helpers/appHelpers';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';


function Notifications(props) {

    /**
     * Define Props
     */
    const { classes, onChange } = props;
    const dispatch = useDispatch();

    /**
     * Redux selector function to get General Settings from redux store
     */
    const { general } = useSelector((state) => state.configurations);
    const { permission } = useSelector((state) => state.auth);
    const generalPermission = checkPermission(permission, featureConstants.settings.general);
    const { notifications } = general;

    /**
     * Update Notifications Settings
     * @param {*} property
     * @param {*} key
     * @param {*} value
     */
    const onChangeNotifications = (property, key, value) => {
        let updatedConfig = {};
        if (key === 'priority') {
            const exiting_priority = notifications[property] && notifications[property][key] ? [...notifications[property][key]] : [];
            if (!exiting_priority.includes(value)) {
                exiting_priority.push(value);
            } else {
                exiting_priority.splice(exiting_priority.indexOf(value), 1);
            }
            updatedConfig = {
                category: 'notifications',
                data: {
                    [property]: {
                        ...notifications[property],
                        [key]: exiting_priority
                    }
                }
            };
        } else {
            updatedConfig = {
                category: 'notifications',
                data: {
                    [property]: {
                        ...notifications[property],
                        [key]: value
                    }
                }
            };
        }

        dispatch(updateGeneralInReducer(updatedConfig));
        const requestParams = { ...general.notifications, ...updatedConfig.data };
        dispatch(generalUpdateRequest({ id: general.id, 'notifications': requestParams }));
    };

    return (
        <Grid item xs={12}>
            <Grid container justifyContent={"space-between"} className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.notifications}
                </Typography>
                <SwitchComponent size="small"
                    handleChange={(value) => onChange('notifications', 'is_active', value)}
                    checked={notifications.is_active || false}
                    disabled={!generalPermission?.is_edit} />
            </Grid>
            {
                notifications.is_active &&
                <Grid className={classes.bodyContainer}>
                    <Typography variant="body1">
                        {appConstants.labels.general.notificationsDesc}
                    </Typography>
                    <Grid item xs={12} className={classes.subContent}>
                        <Grid container spacing={6} sx={{ width: '100% !important', marginLeft: '0px !important' }} className="mt-1">
                            <Grid item xs={5} className="dflex alignCenter pl-0 pt-3">
                                <NumberInputComponent
                                    name={'limit'}
                                    value={notifications.limit || 0}
                                    onChange={(value) => onChange('notifications', 'limit', value)}
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    id="length-min-ntb"
                                    label={appConstants.labels.general.numberOfAlerts}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!generalPermission?.is_edit}
                                />
                            </Grid>
                            <Grid item xs={5} className="dflex alignCenter pt-3">
                                <SelectComponent
                                    fullWidth={false}
                                    label={appConstants.labels.general.timePeriod}
                                    variant="standard"
                                    value={notifications.frequency || 0}
                                    onSelectChange={(value) => onChange('notifications', 'frequency', value)}
                                    list={appConstants.general.frequency}
                                    name="frequency"
                                    isDisabled={!generalPermission?.is_edit}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6} className={`${classes.notifciationItems} pt-4`} sx={{ width: '100% !important', marginLeft: '0px !important' }}>
                            <Grid item xs={4} className="pl-0">
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('alerts', 'is_active', value)}
                                    checked={notifications.alerts?.is_active ?? false}
                                    label={appConstants.labels.general.alerts}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.alerts?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('alerts', 'priority', value)}
                                    aria-label={appConstants.labels.general.alerts}
                                    disabled={!notifications.alerts?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('issues', 'is_active', value)}
                                    checked={notifications.issues?.is_active ?? false}
                                    label={appConstants.labels.general.issues}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.issues?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('issues', 'priority', value)}
                                    label={appConstants.labels.general.issues}
                                    disabled={!notifications.issues?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('schedule', 'is_active', value)}
                                    checked={notifications.schedule?.is_active ?? false}
                                    label={appConstants.labels.general.schedule}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.schedule?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('schedule', 'priority', value)}
                                    label={appConstants.labels.general.schedule}
                                    disabled={!notifications.schedule?.is_active ?? true}
                                >
                                    <ToggleButton value="Failed">
                                        <Tooltip title="Failed">
                                            <Grid>
                                                F
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="Started">
                                        <Tooltip title="Started">
                                            <Grid>
                                                S
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="Completed">
                                        <Tooltip title="Completed">
                                            <Grid>
                                                C
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {/* <Grid item xs={4}>
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('collabration', 'is_active', value)}
                                    checked={notifications.collabration?.is_active ?? false}
                                    label={appConstants.labels.general.collaboration}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.collabration?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('collabration', 'priority', value)}
                                    aria-label={appConstants.labels.general.collaboration}
                                    disabled={!notifications.collabration?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4} className="pl-0">
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('schedule', 'is_active', value)}
                                    checked={notifications?.schedule?.is_active ?? false}
                                    label={appConstants.labels.general.alertScheduleHeading}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.schedule?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('schedule', 'priority', value)}
                                    aria-label={appConstants.labels.general.alertScheduleHeading}
                                    disabled={!notifications.schedule?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('execution', 'is_active', value)}
                                    checked={notifications.execution?.is_active ?? false}
                                    label={appConstants.labels.general.alertExecutionHeading}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.execution?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('execution', 'priority', value)}
                                    aria-label={appConstants.labels.general.alertExecutionHeading}
                                    disabled={!notifications.execution?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <SwitchComponent size="small"
                                    handleChange={(value) => onChangeNotifications('permission', 'is_active', value)}
                                    checked={notifications.permission?.is_active ?? false}
                                    label={appConstants.labels.general.permissions}
                                    disabled={!generalPermission?.is_edit} />
                                <ToggleButtonGroup
                                    className={classes.notifyToggleBtn}
                                    color="primary"
                                    value={notifications?.permission?.priority ?? []}
                                    exclusive
                                    onChange={(event, value) => onChangeNotifications('permission', 'priority', value)}
                                    aria-label={appConstants.labels.general.permissions}
                                    disabled={!notifications.permission?.is_active ?? true}
                                >
                                    <ToggleButton value="high">
                                        <Tooltip title="High">
                                            <Grid>
                                                H
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="medium">
                                        <Tooltip title="Medium">
                                            <Grid>
                                                M
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="low">
                                        <Tooltip title="Low">
                                            <Grid>
                                                L
                                            </Grid>
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

// default props
Notifications.defaultProps = {
    classes: {},
    onChange: () => { }
};

// prop types
Notifications.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Notifications);