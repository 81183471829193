import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import moment from "moment";
import { useScreenshot } from 'use-react-screenshot';

//import Componetns
import { MeasureQualityHistory, TableComponent } from '../../../../../components/index';

//import Styles
import MonitorStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Reducer
import { getMetricDetailRequest, getLengthEnumValueDetail, measureSorting, updateDriftMetricProperty, getComparisonMetricRequest, toggleMetricResult } from '../../../../../redux/reducer/metricReducer';
import { updateDriftAlertRequest } from '../../../../../redux/reducer/driftReducer';

//import Constant
import appConstants from '../../../../../constants/appConstants';

// Import Actions
import { createAttachmentRequest } from '../../../../../redux/reducer/issuesReducer';
import { checkPermission, permissionHeaders } from '../../../../../helpers';
import featureConstants from '../../../../../constants/featureConstants';

function Results(props) {
    /**
     * Define Props
     */
    const { classes, measureId, selectedAlert, measure_name } = props;
    const dispatch = useDispatch();
    const alert_div_ref = useRef();
    const [alertImageScreenShot, takeAlertImageScreenShot] = useScreenshot(
        {
            type: "image/jpeg",
            quality: 0.5
        }
    );

    // Get Alert URL Link
    let link = window.location.protocol + '//' + window.location.host;
    link = `${link}/home/issues`;

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        day: "",
        expected: "",
        value: "",
        message: "",
        status: ""
    });
    const [dayFilter, setDayFilter] = useState(7);

    /**
     * Redux Store
     */
    const { detail, sortBy, orderBy, length_enum_dropdown, length_enum_history, result, isLoading } = useSelector((state) => state.metric);
    const { id: issueId, addedScreenShot, detail: { issue_id: issue_title } } = useSelector((state) => state.issues);
    const { user } = useSelector((state) => state.auth);
    const { permission } = useSelector((state) => state.auth);
    const alertPermission = checkPermission(permission, featureConstants.home.alerts);

    /**
     * Define Metric Detail
     */
    useEffect(() => {
        if (measureId) {
            const params = {
                id: measureId,
                days: dayFilter,
                name: measure_name
            };
            dispatch(getMetricDetailRequest(params));
        }
    }, [measureId, dayFilter]);

    /**
     * On Change Filter
     * @param {*} value
     */
    const onChangeFilter = (value) => {
        setDayFilter(value);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        const requestData = {
            sortBy, orderBy
        };
        dispatch(measureSorting(requestData));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Hangle Length Enum Value Change Event
     * @param {*} value
     */
    const onHandleValueChange = (value) => {
        if (value) {
            dispatch(getLengthEnumValueDetail(value.id));
        }
    };

    /**
     * Click Actions
     * @param {*} data
     */
    const onClickActions = (data) => {
        let markedAs = null;
        if (data.status === "Ok" || data.status === "") {
            markedAs = !data?.marked_as ? "outlier" : null;
        } else {
            markedAs = !data?.marked_as ? "normal" : null;
        }
        const requestParams = {
            id: data.id,
            marked_as: markedAs
        };
        dispatch(updateDriftAlertRequest(requestParams));
        dispatch(updateDriftMetricProperty(requestParams));
    };

    /**
     * Prepare Result Data
     * @param {*} data
     * @returns
     */
    const prepareFilterResult = (data, searchFilters) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    let value = item[key];
                    if (key === "status") {
                        value = item[key] || "OK";
                    }
                    if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        filterData = filterData.sort((a, b) => {
            return moment(a.created_date) > moment(b.created_date) ? -1 : 1;
        });

        const index = filterData.findIndex((item) => item.id === selectedAlert);
        if (index >= 0) {
            filterData.unshift(filterData.splice(index, 1)[0]);
        }

        return filterData;
    };

    /**
     * Upload Alert Screenshot
     * @param {*} dataurl
     */
    const uploadScreenShot = (dataurl) => {
        const arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], 'issue.jpg', { type: mime });
        const requestParams = {
            issue: issueId,
            url: croppedImage,
            organization: user.organization.id,
            created_by: user.id,
            issue_image: true,
            link: `${link}?key1=${issueId}`,
            title: issue_title
        };
        if (issueId && issue_title) {
            dispatch(createAttachmentRequest(requestParams));
        }
    };

    /**
     * Handle Screenshot After Dom Loaded
     */
    useEffect(() => {
        let timer = null;
        if (!addedScreenShot && !alertImageScreenShot && issue_title) {
            timer = setTimeout(() => {
                takeAlertImageScreenShot(alert_div_ref.current).then(uploadScreenShot);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [addedScreenShot, alert_div_ref, issue_title]);


    /**
     * Prepare Data using UseMemo
     */
    const resultData = useMemo(() => prepareFilterResult(result, searchData), [result, searchData]);

    /**
     * Get Selected Alert Index Using UseMemo
     */
    const getSelectedAlertIndex = (result) => {
        const index = result.findIndex((item) => item.id === selectedAlert);
        return index;
    };
    const highlightIndex = useMemo(() => getSelectedAlertIndex(result), [result]);

    /**
     * Bind Accordian Table
     * @param {*} selectedItem
     * @param {*} rowIndex
     */
    const onClickAccordian = (selectedItem, isOpen) => {
        if (isOpen && !selectedItem.metrics) {
            const requestData = {
                measure_id: selectedItem.measure_id,
                run_id: selectedItem.run_id,
                id: selectedItem.id
            };
            dispatch(getComparisonMetricRequest(requestData));
        }
        dispatch(toggleMetricResult({ id: selectedItem.id, isOpen }));
    };

    const checkBehavioral = () => {
        const issueHeader = permissionHeaders(appConstants.table.issueQualityTable, alertPermission);
        if (detail?.is_default || (detail?.category && detail?.category?.toLowerCase() !== "behavioral")) {
            const deviation_obj = { key: 'deviation', name: 'Deviation', width: "auto", sorting: true };
            const percent_obj = { key: 'percent_change', name: '% Change', width: "auto", sorting: true, hideDefault: false };
            const valueIndex = issueHeader.findIndex((item) => item.key === "value");
            if (valueIndex > -1) {
                issueHeader.splice(valueIndex, 0, deviation_obj, percent_obj);
            }
        }
        return issueHeader;
    };

    return (
        <Grid className={classes.pageContainer} ref={alert_div_ref}>
            <Grid className={classes.chartContainer} >
                <MeasureQualityHistory
                    data={{ ...detail, dayFilter } || {}}
                    onChangeFilter={onChangeFilter}
                    onHandleValueChange={onHandleValueChange}
                    length_enum_history={length_enum_history}
                    length_enum_dropdown={length_enum_dropdown}
                    highlightIndex={highlightIndex}
                />
            </Grid>
            {
                detail?.category !== "comparison" ?
                    <TableComponent
                        styleType="striped"
                        headers={checkBehavioral()}
                        data={resultData || []}
                        onClickActions={onClickActions}
                        options={appConstants.tableOptions.download}
                        sortBy={sortBy}
                        orderBy={orderBy}
                        onClickSorting={onClickSorting}
                        onHandleSearchEvent={onHandleSearchEvent}
                        NoResultText="No Items Found"
                        highlightIndex={highlightIndex ? 0 : -1}
                        exportParams={
                            {
                                fileName: "result.csv",
                                renameColumn: { "day": "date time", "value": "actual", "message": "Alert", "status": "priority" },
                                titleCaseHeader: true
                            }
                        }
                        stickyHeader
                    /> :
                    <TableComponent
                        styleType="striped"
                        headers={permissionHeaders(appConstants.table.comparisonIssueQualityTable, alertPermission)}
                        data={resultData || []}
                        onClickActions={onClickActions}
                        onHandleSearchEvent={onHandleSearchEvent}
                        options={appConstants.tableOptions.download}
                        haveSubTable
                        subTable={{ "headers": appConstants.table.comparisonResultSubTable, "rows": "metrics" }}
                        subTableColSpan={appConstants.table.comparisonResultTable.length + 3}
                        onSubTableActions={onClickActions}
                        onClickAccordian={onClickAccordian}
                        NoResultText="No Results Found"
                        isLoading={isLoading}
                        sortBy={sortBy}
                        orderBy={orderBy}
                        onClickSorting={onClickSorting}
                        onClickSubTableSorting={onClickSorting}
                        highlightIndex={highlightIndex ? 0 : -1}
                        exportParams={
                            {
                                fileName: "result.csv",
                                renameColumn: { "day": "date time", "value": "actual", "message": "Alert", "status": "priority" },
                                titleCaseHeader: true
                            }
                        }
                        stickyHeader
                    />
            }
        </Grid>
    );
}

// default props
Results.defaultProps = {
    classes: {},
    measureId: "",
    selectedAlert: "",
    measure_name: ""
};

// prop types
Results.propTypes = {
    classes: PropTypes.object,
    measureId: PropTypes.string,
    selectedAlert: PropTypes.string,
    measure_name: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...MonitorStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Results);