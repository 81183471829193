// Import API Helper
import { getRequest, putRequest, postRequest, deleteRequest } from '../../helpers';

// Import Constants
import urlConstants from '../../constants/urlConstants';

/**
 * Fetch Theme Detail
 */
async function get_theme_detail() {
    const response = await getRequest(urlConstants.configurations.theme.url);
    return response;
}

/**
 * Update Theme
 */
async function update_theme(params) {
    const new_params = { ...params };
    new_params.theme = JSON.stringify(new_params.theme);
    const form_data = new FormData();
    Object.keys(new_params).map((param) =>
        form_data.append(param, new_params[param])
    );
    const response = await putRequest(
        urlConstants.configurations.theme.url + new_params.id + '/',
        form_data,
        true
    );
    return response;
}

/**
 * Fetch Theme Detail
 */
 async function get_general_settings() {
    const response = await getRequest(urlConstants.configurations.general.url);
    return response;
}

/**
 * Update General Settings
 */
 async function update_general(params) {
    const response = await putRequest(`${urlConstants.configurations.general.url}${params.id}/`, params);
    return response;
}

/**
 * Create Application
 * @param {*} params
 * @returns
 */
 async function create_banner(params) {
    const form_data = new FormData();
    form_data.append("file_to_upload", params.file_to_upload);
    const response = await postRequest(`${urlConstants.configurations.banner.url}`, form_data, true);
    return response;
  }

/**
 * Fetch Theme Detail
 */
 async function get_banners() {
    const response = await getRequest(urlConstants.configurations.banner.url);
    return response;
}

/**
 * Delete Application
 * @param {*} applicationId
 * @returns
 */
 async function delete_banner(bannerId) {
    const response = await deleteRequest(`${urlConstants.configurations.banner.url}${bannerId}/`);
    return response;
  }

/**
 * Fetch general Schedule
 */
async function get_general_schedule() {
    const response = await getRequest(urlConstants.configurations.general.general_schedule);
    return response;
}

/**
 * Fetch Theme Detail
 */
async function get_selected_banners() {
    const response = await getRequest(urlConstants.configurations.banner.getGeneralTheme);
    return response;
}

/**
 * Export All Configurations Service Functions
 */
export const configurationsService = {
    get_theme_detail,
    update_theme,
    get_general_settings,
    update_general,
    create_banner,
    get_banners,
    delete_banner,
    get_general_schedule,
    get_selected_banners
};