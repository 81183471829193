const style = () => ({
    appContainer: {
        padding: '20px 0px 0px',
        '& .MuiTableCell-head:first-child': {
            paddingLeft: '12px !important'
        },
        '& .MuiTableCell-head:last-child': {
            // paddingRight: '0px !important'
        }
    }
});
export default style;