const style = (theme) => ({
    editContainer: {
        zIndex: 11,
        background: '#fff',
        alignContent: 'baseline',
        padding: '0px 30px 0px',
        height: 'calc(100vh - 90px)',
        overflowY: 'auto',
        '& .ruleInlineTextfield': {
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #ffffff00'
            },
            '& .MuiFormControl-root': {
                minWidth: 400
            },
            '& .textSecondary .MuiInput-input': {
                color: theme.palette.greyshades.darkgrey,
                marginTop: 5
            },
            '& .MuiInput-input': {
                fontSize: '15px !important',
                boxSizing: 'border-box'
            }
        },
        '& .descriptionContainer': {
            '& .MuiInput-input': {
                textOverflow: 'ellipsis'
            }
        }
    },
    header: {
        paddingTop: 20
    },
    ruleGreyContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: 24,
        marginTop: '15px !important',
        minHeight: '144px'
    },
    semanticTermlink: {
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
        margin: 0,
        padding: "0 !important",
        paddingLeft: "5px !important"
    },
    validateButtonContainer: {
        display: "flex",
        alignItems: "center",
        "& p": {
            "&.MuiFormHelperText-root.Mui-error": {
                height: "100%",
                marginTop: 5
            },
            marginLeft: 10
        }
    },
    queryActionContainer: {
        display: "flex",
        alignItems: "end",
        '& .MuiCheckbox-root': {
            padding: '0px',
            marginRight: '5px'
        },
        "& .limitConfig": {
            display: "flex",
            alignItems: "center",
            '& .textBoxContainer': {
                minWidth: "160px !important",
                maxWidth: "160px !important",
                '&:first-child': {
                    marginRight: 20
                }
            }
        }
    }
});
export default style;