const style = (theme) => ({
    bredcrumbContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px !important',
        '& .item': {
            cursor: 'pointer',
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`,
                // textDecoration: 'underline',
                '&:not(:first-child):before': {
                    color: `${theme.palette.headers.body1.color} !important`
                }
            },
            '&:not(:first-child):before': {
                content: "' /' !important",
                padding: '0 10px'
            }
        }
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        '& .PlusIcon, .MenuVerticleIcon': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    termfilterContainer: {
        display: 'flex',
        alignItems: 'center'
    }
});
export default style;