import { createSlice } from '@reduxjs/toolkit';
import appConstants from '../../constants/appConstants';


const initialState = {
    search: {
        data: [],
        count: 0,
        tabIndex: 6,
        search_by: appConstants.tabs.catalogTabs[6]?.toLowerCase(),
        sortBy: "created_date",
        orderBy: "desc",
        limit: 20,
        offset: 0,
        search_key: "",
        filters: {
            rating: 5,
            score: [0, 100],
            applications: [],
            domains: [],
            tags: [],
            terms: [],
            connection_type: [],
            filter_by: [],
            asset_status: [...appConstants.status.slice(0, 3)]
        },
        isLoading: false,
        isLoadMore: true
    },
    stats: {
        assets: 0,
        attributes: 0,
        alerts: 0,
        issues: 0,
        conversations: 0
    },
    suggestions: {
        suggestion: [],
        tracking: []
    }
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: { ...initialState },
    reducers: {
        catalogListRequest(state, action) {
            state.search.isLoading = true;

            const { params, clear } = action.payload;
            if (clear) {
                state.search.data = [];
                state.search.offset = 0;
                state.search.count = 0;
                state.search.isLoadMore = true;
            }

            state.search = {
                ...state.search,
                ...params
            };
        },
        catalogListSuccess(state, action) {
            const { data } = action.payload;
            const { result, count } = data;
            state.search.data = result ? [...state.search.data, ...result] : state.search.data;
            state.search.count = count || 0;
            if (result && (!result.length || result.length < state.search.limit)) {
                state.search.isLoadMore = false;
            }
            state.search.isLoading = false;
        },
        catalogListFailure(state) {
            state.search.data = [];
            state.search.count = 0;
            state.search.isLoading = false;
        },
        clearCatalogState(state) {
            state.search.data = [];
            state.search.count = 0;
            state.search.tabIndex = 6;
            state.search.search_by = appConstants.tabs.catalogTabs[6]?.toLowerCase();
            state.search.sortBy = "created_date";
            state.search.orderBy = "desc";
            state.search.limit = 20;
            state.search.offset = 0;
            state.search.search_key = "";
            state.search.filters = {
                rating: 5,
                score: [0, 100],
                applications: [],
                domains: [],
                tags: [],
                terms: [],
                connection_type: [],
                filter_by: [],
                asset_status: [...appConstants.status.slice(0, 3)]
            };
            state.search.isLoading = false;
            state.search.isLoadMore = true;
        },
        catalogStatisticsRequest() { },
        catalogStatisticsSuccess(state, action) {
            if (action.payload?.data) {
                const data = { ...action.payload?.data };
                state.suggestions = data?.suggestions ?? {
                    suggestion: [],
                    tracking: []
                };
                delete data?.notifications;
                delete data?.suggestions;
                state.stats = { ...state.stats, ...data };
            }
        },
        catalogStatisticsFailure() { },
        catalogSearchSuggestionsRequest() { },
        catalogSearchSuggestionsSuccess(state, action) {
            state.suggestions = action.payload?.data ?? {
                suggestion: [],
                tracking: []
            };
        },
        catalogSearchSuggestionsFailure() { },
        catalogSearchTrackingRequest() { },
        catalogSearchTrackingSuccess() { },
        catalogSearchTrackingFailure() { },
        catalogSearchUpdate(state, action) {
            state.search = {
                ...state.search,
                ...action?.payload ?? {}
            };
        }
    }
});

export const { catalogListRequest, catalogListSuccess, catalogListFailure, clearCatalogState, catalogStatisticsRequest, catalogStatisticsSuccess,
    catalogStatisticsFailure, catalogSearchSuggestionsRequest, catalogSearchSuggestionsSuccess, catalogSearchSuggestionsFailure,
    catalogSearchTrackingRequest, catalogSearchTrackingSuccess, catalogSearchTrackingFailure, catalogSearchUpdate } = catalogSlice.actions;
export default catalogSlice.reducer;