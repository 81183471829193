import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import EqualizerIcon from '@mui/icons-material/Equalizer';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { AreaChartComponent } from '../../../../../components/index';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Images

function Trending(props) {

    const { classes, data } = props;

    /**
     * Get Sum Count
     * @param {*} report
     * @returns
     */
    const getTotalCount = (report = []) => {
        return report.reduce((a, b) => a + b.count, 0);
    };

    return (
        <Grid className={classes.alertsCard}>
            <Grid>
                <Typography variant="h2">
                    {getTotalCount(data.report.default)}
                </Typography>
                <Typography variant="body1">
                    {data.name || ""}
                </Typography>
            </Grid>
            <Grid className={classes.chart} item xs={12}>
                {
                    data.report.default.length ?
                        <AreaChartComponent
                            containerId={`${data.id}_chart_container`}
                            size="small"
                            lineColor={data?.properties?.color ?? "#fff"}
                            chartData={data.report.default || []}
                        />
                        :
                        <Grid className={classes.nodataCardWrapper}>
                            <Grid className={classes.nodataCard}>
                                <EqualizerIcon />
                                <Typography variant="body1" className={classes.textSecondary}>
                                    No Data Found
                                </Typography>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid>
    );
}

// default props
Trending.defaultProps = {
    classes: {},
    data: {}
};

// prop types
Trending.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Trending);