const style = (theme) => ({
    catalogPageContainer: {
        cursor: "pointer",
        background: theme.palette.primary.contrastText,
        boxShadow: '0px 2px 6px #0000001f',
        // boxShadow: '0px 6px 6px #0000000F',
        borderRadius: '6px',
        height: '100%',
        '&:hover': {
            outline: `2px solid #64aaef45`,
            boxShadow: '0px 5px 16px #0000000f',
            background: 'rgb(244 246 252)'
        },
        '& .connectorCardImg': {
            width: 20,
            height: 20,
            objectFit: 'scale-down'
        },
        '& .error': {
            color: "red"
        }
    },
    cardTop: {
        padding: '20px',
        '@media only screen and (min-width: 1500px)': {
            '&': {
                padding: '24px'
            }
        }
    },
    cardHeader: {
        paddingBottom: 20,
        '& .dataNameIcon ': {
            marginBottom: 5
        },
        '& .dataNameIcon svg, .dataNameIcon svg path': {
            width: 17,
            fill: theme.palette.secondary.main,
            flexShrink: '0'
        },
        '& .starIcon svg': {
            paddingRight: 1
        },
        '& .catalog-cardTitle': {
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        '& .catalog-subTitle': {
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingTop: '5px',
            marginLeft: 8
        }

    },
    emptyStar: {
        fill: `${theme.palette.greyshades.lightgrey} !important`,
        fontSize: 'inherit !important'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .assets': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginLeft: 10
        },
        '& .dataNameIcon': {
            // maxWidth: 'calc(100% - 32px)'
        },
        '& .teradata': {
            '& .connectorCardImg': {
                width: "auto !important",
                height: "12px !important"
            }
        }
    },
    subTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .starIcon': {
            paddingTop: 2
        }
    },
    cardBody: {
        padding: '0px 0px 24px',
        maxHeight: '87px',
        marginBottom: 6,
        '& .detail': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical'
        }
    },
    alertSection: {
        alignItems: 'end'
    },
    thumbnail: {
        width: 50,
        height: 50,
        objectFit: 'contain',
        borderRadius: '5px',
        border: '1px solid #dddddd7d'
    },
    tagSection: {
        paddingBottom: '20px',
        cursor: 'text',
        '& .MuiChip-root': {
            display: 'inline-grid',
            maxWidth: 'calc(60% - 45px)'
        },
        '& .moreCount': {
            maxWidth: '100%',
            width: 'max-content'
        }
    }
});

export default style;