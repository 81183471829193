import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, IconButton, Box, Skeleton, Tooltip } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import ConnectionStyle from './style.jsx';
import styles from '../../../layouts/private/styles.jsx';

// Import Constants
import appConstants from '../../../constants/appConstants.js';
import featureConstants from '../../../constants/featureConstants.js';

// Import Components
import { NoResultComponent, SearchComponent, AutoCompleteComponent } from '../../../components/index.js';
import CardComponent from './components/card/index.jsx';
import DialogComponent from '../../../components/dialogBox/index.jsx';

// Import Actions
import {
    getConnectionsRequest,
    connectionUpdateRequest,
    connectionDeleteRequest,
    connectionCopyRequest
} from '../../../redux/reducer/connectionReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer.js';

// Import Images
import { PlusFilledIcon, AscendingIcon, DescendingIcon } from '../../../assets/svg/index.js';


// Import Helpers
import { checkPermission } from '../../../helpers/appHelpers.js';

function ConnectionList(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const searchControllerRef = useRef();

    /**
     * Define State
     */
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { connections, isLoading, copyLoading, connectionFilters, connectionCount } = useSelector(
        (state) => state.connection,
        shallowEqual
    );

    /**
     * Get Permission
     */
    const { permission } = useSelector((state) => state.auth, shallowEqual);
    const connectionPermission = checkPermission(permission, featureConstants.settings.connections);


    /**
     * Get Connections
     */
    const getConnections = (params = {}, clear = false) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        const requestParams = {
            ...connectionFilters,
            ...params,
            clear
        };
        setIsPageLoaded(true);
        dispatch(getConnectionsRequest({ requestParams, token }));
    };
    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (!isPageLoaded && connections?.length === 0) {
            getConnections({ offset: 0 }, true);
        }
    }, [isPageLoaded, connections]);

    /**
     * Handle Search Text Box
     * @param {*} value
     */
    const handleSearchOnChange = useCallback((value) => {
        if (searchText === value) {
            return;
        }
        setSearchText(value);
        getConnections({ search: value, offset: 0 }, true);
    }, [searchText]);

    /**
     * Handle Event Change
     * @param {*} event
     */
    const handleToggleChange = (event, data) => {
        dispatch(
            connectionUpdateRequest({
                page_type: 'list',
                id: data.id,
                is_active: event.target.checked
            })
        );
    };

    /**
     * Navigate to Connection Detail View
     */
    const navigateToAssetPage = (data) => {
        if (data.is_active) {
            dispatch(navigate({ path: 'connector.asset', state: {}, params: [data.type, data.id] }));
        }
    };

    /**
     * Navigate to New Connector View
     */
    const navigateConnectorPage = () => {
        dispatch(navigate({ path: 'connector.root', state: {}, params: [] }));
    };

    /**
     * Handle Copy Event
     * @param {*} data
     */
    const handleCopyClick = (data) => {
        dispatch(connectionCopyRequest({ ...data, 'connection_id': data.id }));
    };

    /**
     * Handle Delete Event
     */
    const handleDeleteClick = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.connectionDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge", data) => {
        dispatch(connectionDeleteRequest({ id: data.id, purge: (type === "purge") }));
        dialogCancelEventHandle();
    };

    /**
     * Handle Edit Event
     */
    const handleEditClick = (data) => {
        dispatch(navigate({ path: 'connector.edit', state: {}, params: [data.type, data.id] }));
    };

    /**
     * Handle View Logs
     */
    const handleViewLogs = (event, data) => {
        event.stopPropagation();
        dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data.id] }));
    };

    /**
     * Handle Sortby Event
     * @param {*} type
     * @param {*} value
     */
    const handleSortByEvent = (type, value) => {
        getConnections({ offset: 0, [type]: value }, true);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        const loadMore = (connectionCount > (connections?.length || 0));
        if (!isLoading && loadMore && (event.target.scrollHeight - Math.floor(event.target.scrollTop) - 50) <= event.target.clientHeight) {
            getConnections({ offset: connectionFilters.offset + connectionFilters.limit }, false);
        }
    };

    return (
        <Grid item xs={12} className={classes.connectPageContainer}>
            <Grid container justifyContent={"space-between"} alignItems="center" className={classes.connectionHeader}>
                <Typography variant="body1">
                    {appConstants.labels.connection.configureDescription}
                </Typography>
                <Typography variant="body1">
                    {`Total ${connectionCount} connections configured. `}
                </Typography>
            </Grid>
            <Grid className={classes.configurecardBody}>
                <Grid container justifyContent={"space-between"} alignItems="center">
                    <Grid>
                        <SearchComponent
                            value={searchText}
                            onChange={(value) => handleSearchOnChange(value)}
                            placeholder={appConstants.labels.connection.searchConnection} />
                    </Grid>
                    <Grid>
                        <Grid container wrap="nowrap" alignItems={"center"} spacing={1} >
                            <Grid item className={`${classes.autoCompleteMaxWidth} pl-0`}>
                                <ValidatorForm onSubmit={() => { }} noValidate >
                                    <AutoCompleteComponent
                                        name="sortBy"
                                        selectedValue={appConstants.connections_sort_by.filter((a) => a.id === connectionFilters?.sortBy ?? 'name')[0]?.name ?? 'name'}
                                        data={appConstants.connections_sort_by}
                                        size={'small'}
                                        variant="outlined"
                                        onChange={
                                            (event, newValue) =>
                                                handleSortByEvent('sortBy', newValue?.id ?? 'created_date')
                                        }
                                        placeholder="Select Sort By"
                                    />
                                </ValidatorForm>
                            </Grid>
                            <Grid item className="dflex sortIcons">
                                <Tooltip
                                    arrow
                                    title={connectionFilters.orderBy === 'asc' ? "Ascending" : "Descending"}
                                >
                                    <IconButton onClick={() => handleSortByEvent('orderBy', connectionFilters.orderBy === 'asc' ? 'desc' : 'asc')} className={'active p5'}>
                                        {connectionFilters.orderBy === 'asc' ? <AscendingIcon /> : <DescendingIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={5} className={classes.configurecardBodyInner} onScroll={(event) => onScrollEvent(event)}>
                    {
                        connectionPermission?.is_edit &&
                        <Grid item xs={3} xl={2}>
                            <Grid item className={classes.connectionCardNew} onClick={navigateConnectorPage}>
                                <IconButton>
                                    <PlusFilledIcon />
                                </IconButton>
                                <Typography variant="h5" className="pt-1">
                                    Add a new Connection
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        connections.map((item, index) => (
                            <Grid item xs={3} xl={2} key={index}>
                                <CardComponent
                                    item={item}
                                    index={index}
                                    connectionPermission={connectionPermission}
                                    handleToggleChange={handleToggleChange}
                                    navigateToAssetPage={navigateToAssetPage}
                                    handleDeleteClick={handleDeleteClick}
                                    handleCopyClick={handleCopyClick}
                                    handleEditClick={handleEditClick}
                                    handleViewLogs={handleViewLogs}
                                />
                            </Grid>
                        ))
                    }
                    {
                        (isLoading || copyLoading) &&
                        [...Array(copyLoading ? 1 : 5)].map((elementInArray, index) => (
                            <Grid item xs={3} xl={2} key={`loader-card-${index}`}>
                                <Skeleton
                                    variant="rectangular"
                                    width={'100%'}
                                    height={"210px"}
                                />
                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton />
                                    <Skeleton width="60%" />
                                </Box>
                            </Grid>
                        ))
                    }
                    {!isLoading && connections.length < 1 && <NoResultComponent height="auto" />}
                </Grid>
            </Grid>
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </Grid>
    );
}

// default props
ConnectionList.defaultProps = {
    classes: {}
};

// prop types
ConnectionList.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ConnectionStyle(theme),
        ...styles(theme)
    }),
    { withTheme: true }
)(ConnectionList);