const style = (theme) => ({
    issueNumber: {
        textDecorationLine: "underline !important",
        cursor: "pointer",
        color: `${theme.palette.secondary.main} !important`,
        '&.resolved': {
            color: `${theme.palette.colorThemes.low} !important`
        }
    },
    issuesIcon: {
        '& svg:hover .fillPath': {
            fill: theme.palette.secondary.main
        }

    }

});
export default style;