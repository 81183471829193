const style = () => ({
    securityPageContainer: {
        padding: '24px 30px 24px',
        '& .searchBox': {
            padding: '2px 10px !important'
        },
        '& .MuiInputBase-input': {
            minWidth: 80,
            paddingLeft: '0px'
        },
        '& .toggleContainer': {
            marginBottom: '12px'
        }
    }
});
export default style;