import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';

//  Import Styles
import Style from "./style.jsx";

function RuleAdd(props) {

    /**
     * Define Props
     */
    const { classes, addRule, id, isDisabled } = props;

    return (
        <Grid item className={`${classes.actionContainer} actionContainer`}>
            <Button variant="contained" size="small" color="secondary" className="mr5" disableElevation onClick={() => addRule('rule', id)} disabled={isDisabled}>
                Add Rule
            </Button>
            <Button variant="contained" size="small" color="secondary" disableElevation onClick={() => addRule('group', id)} disabled={isDisabled}>
                Add Group
            </Button>
        </Grid>
    );
}

// default props
RuleAdd.defaultProps = {
    classes: {},
    id: '',
    isDisabled: false,
    addRule: () => { }
};

// prop types
RuleAdd.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    addRule: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme)
    }),
    { withTheme: true }
)(RuleAdd);