import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

//  Import Components
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import DragField from './dragField.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { DeleteIcon, EditIcon } from '../../../../../assets/svg/index.js';

import appConstants from '../../../../../constants/appConstants.js';

function Fields(props) {
    /**
     * Define Props
     */
    const { classes, item, index, onDelete, accept, onDrop, sorting, onEdit, widgetType } = props;

    /**
     * OnDrop Event
     * @param {*} data
     * @param {*} index
     */
    const onDropEvent = (data, index) => {
        const properties = [...appConstants.dimensions, ...appConstants.measures];
        const item = properties.find((obj) => obj.name === data.name);
        onDrop(item, index);
    };

    /**
     * Drop
     * @returns
     */
    const Drop = (item, index) => {
        const [{ isOverCurrent }, drop] = useDrop({
            accept,
            drop: (data) => onDropEvent(data, index),
            collect: (monitor) => ({
                isOverCurrent: monitor.isOver({ shallow: true })
            })
        });

        const getItemName = () => {
            if (item.isHeatmapSupport && widgetType === appConstants.dashboardWigetTypes.heatmap) {
                return `${item.aggregation.toUpperCase()}(${item.name})`;
            }
            return item.name;
        };

        return (
            <Grid ref={drop} item className={classes.dragBox}>
                <Grid item className={classNames(classes.selectedItem, isOverCurrent ? "active" : "")}>
                    {
                        !isOverCurrent ?
                            <Fragment>
                                {
                                    !sorting ?
                                        <Tooltip title={item.name}>
                                            <Typography className={classes.text} variant="body1" noWrap>
                                                {getItemName()}
                                            </Typography>
                                        </Tooltip> :
                                        <DragField data={item} type="sorting" />
                                }
                                <Grid className={classes.listAction}>
                                    {
                                        widgetType === appConstants.dashboardWigetTypes.heatmap && item?.isHeatmapSupport &&
                                        <IconButton onClick={() => onEdit(item)}>
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    <IconButton onClick={() => onDelete(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Fragment> :
                            <Typography variant="body1" className={classes.textSecondary} align="center">
                                Replace
                            </Typography>
                    }
                </Grid>
            </Grid>
        );
    };
    return (
        Drop(item, index)
    );
}

// default props
Fields.defaultProps = {
    classes: {},
    item: {},
    onDelete: () => { },
    accept: ["dimension"],
    onDrop: () => { },
    index: 0,
    sorting: false,
    onEdit: () => { },
    widgetType: ''
};

// prop types
Fields.propTypes = {
    classes: PropTypes.object,
    item: PropTypes.object,
    onDelete: PropTypes.func,
    accept: PropTypes.array,
    onDrop: PropTypes.func,
    index: PropTypes.number,
    sorting: PropTypes.bool,
    onEdit: PropTypes.func,
    widgetType: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Fields);