// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from '../../assets/theme/palette.js';


// Import Styles
import style from "./style.jsx";

const ScatterPunchChartComponent = (props) => {
    /**
     * Define Props
     */
    const { chartData, color } = props;

    const option = {
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 14,
            fontFamily: palette.headers.body1.fontFamily
        },
        grid: {
            top: 0,
            left: 50,
            right: 50,
            bottom: 50,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            boundaryGap: false,
            splitLine: {
                show: true
            },
            axisLine: {
                show: false
            }
        },
        yAxis: {
            type: 'category',
            data: chartData.yAxis,
            axisLine: {
                show: false
            }
        },
        series: [
            {
                name: 'Punch Card',
                type: 'scatter',
                symbolSize: (val) => {
                    return val[2] * 2;
                },
                data: chartData.data,
                animationDelay: (idx) => {
                    return idx * 5;
                },
                itemStyle: {
                    color: color
                }
            }
        ],
        tooltip: {
            position: 'top',
            formatter: (params) => {
                return (
                    params.value[2] +
                    ' Count in ' +
                    chartData.xAxis[params.value[0]] +
                    ' of ' +
                    chartData.yAxis[params.value[1]]
                );
            }
        }
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={{ height: '100%' }}
        />
    );
};


/**
 * Define Prop Types
 */
ScatterPunchChartComponent.propTypes = {
    chartData: {},
    color: PropTypes.string
};

/**
 * Set Default Values
 */
ScatterPunchChartComponent.defaultProps = {
    chartData: {},
    color: ""
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(ScatterPunchChartComponent, areEqual));