const style = (theme) => ({
    queryContainer: {
        background: '#fff',
        display: "flex",
        flexDirection: "row",
        position: "relative",
        '& div.MuiInputBase-root': {
            height: '200px !important',
            '& textarea': {
                height: 'inherit !important'
            }
        },
        '& .MuiFormHelperText-root': {
            paddingTop: 5,
            color: '#d32f2f',
            fontSize: 13
        },
        '& .queryContainer': {
            flex: 1,
            margin: "0",
            height: "inherit !important",
            borderRadius: "0",
            resize: "none",
            outline: "none",
            fontSize: "16px",
            lineHeight: "1.2",
            padding: "10px 10px 10px 0px",
            paddingLeft: "5px",
            border: "none",
            width: "100%",
            "& .textBoxContainer:hover .MuiOutlinedInput-notchedOutline": {
                borderBottom: "0px !important"
            },
            '& .MuiOutlinedInput-root': {
                padding: 0
            }
        },
        '& .lineNumberContainer': {
            margin: "0",
            minWidth: 30,
            height: "220px",
            borderRadius: "0",
            resize: "none",
            outline: "none",
            fontFamily: theme.palette.textFont,
            fontSize: "16px",
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
            textAlign: "end",
            boxShadow: "none",
            color: "grey",
            border: "none",
            backgroundColor: "#ECEFF2",
            lineHeight: "20.125px",
            padding: 10,
            '& .lineNumber': {
                textAlign: "right",
                marginTop: 0,
                marginBottom: 0,
                '&:first-child': {
                    marginTop: -1
                }
            },
            "& .textBoxContainer:hover .MuiOutlinedInput-notchedOutline": {
                borderBottom: "0px !important"
            }
        }
    }
});
export default style;