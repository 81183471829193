import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import attributesListStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

function DbtColumnList(props) {

    /**
     * Define Props
     */
    const { classes, data, filterTestsByColumns } = props;

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Handle Cell Click
     * @param {*} key
     * @param {*} item
     */
    const handleCellClickEvent = (key, item) => {
        if (key === 'tests') {
            filterTestsByColumns(item);
        }
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }

        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], searchData, sorting.sortBy, sorting.orderBy), [data || [], searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <Grid item xs={12} className={classes.attributeListContainer}>
            <TableComponent
                title="Attributes"
                stickyHeader
                headers={appConstants.table.dbtColumnsListHeader}
                data={filterData}
                options={appConstants.tableOptions.common}
                searchData={searchData}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                onHandleSearchEvent={onHandleSearchEvent}
                onClickSorting={onClickSorting}
                styleType="striped"
                NoResultText="No Records Found"
                height="450px"
                onCellClick={handleCellClickEvent}
            />
        </Grid>
    );
}

// default props
DbtColumnList.defaultProps = {
    classes: {},
    data: [],
    filterTestsByColumns: () => { }
};

// prop types
DbtColumnList.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
    filterTestsByColumns: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...attributesListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(DbtColumnList, areEqual));