// Import API Helper
import { getRequest, putRequest, deleteRequest, postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";
import appConstants from "../../constants/appConstants";

/**
 * User Thumnail List
 */
async function thumbnail_list() {
    const response = await getRequest(urlConstants.user.thumbnail_list);
    return response;
}

/**
 * Detail
 */
async function detail(params) {
    const response = await getRequest(urlConstants.user.url + params + '/');
    return response;
}

/**
 * Update
 */
async function update(params) {
    const form_data = new FormData();
    Object.keys(params).map((param) =>
        form_data.append(param, params[param])
    );
    const response = await putRequest(urlConstants.user.url + params.id + '/', form_data, true);
    return response;
}

/**
 * Get Users
 * @returns {object} response
 */
async function getUsers(searchString) {
    const response = await getRequest(`${urlConstants.user.url}?search=${searchString}`);
    return response;
}

/**
 * Delete User
 * @params {*} id
 * @returns {object} response
 */
async function deleteUser(id, purge) {
    const response = await deleteRequest(`${urlConstants.user.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Get Users Statistics
 * @returns
 */
async function get_users_stats() {
    const response = await getRequest(`${urlConstants.user.users_stats}`);
    return response;
}

/**
 * Invite New User
 * @param {*} params
 * @returns
 */
async function invite_new_user(params) {
    params.type = appConstants.notifications_type.invite_user;
    const response = await postRequest(`${urlConstants.notifications.url}`, params);
    return response;
}

/**
 * Update UserPreference
 * @returns {object} response
 */
async function updateUserPreference(params) {
    const response = await postRequest(urlConstants.user.userPreference, params);
    return response;
}


/**
 * Export All Service Functions
 */
export const userService = {
    detail, update, thumbnail_list, get_users_stats, invite_new_user, getUsers, deleteUser, updateUserPreference
};