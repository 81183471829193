import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

//  Import Styles
import containerStyle from './style.jsx';


function LabelContainer(props) {

    /**
     * Define Props
     */
    const { classes, label, children, showProfileText, isAdvanced, onProfileViewChange, profileRunDate } = props;
    const profileRunText = profileRunDate ? ` Last updated on ${profileRunDate.format("MM-DD-YYYY hh:mm A")}.` : "";


    return (
        <Grid container justifyContent={"center"} alignItems={"flex-start"} className={classes.labelContainer}>
            <Typography variant="h6" className={classes.label}>
                {label.toUpperCase()}
            </Typography>
            {children}
            {
                showProfileText &&
                <Typography className={classes.profileText} onClick={(event) => onProfileViewChange(event)}>
                    <span>
                        {` ${isAdvanced ? 'Disable' : 'Enable'} advanced profile.`}
                    </span>
                    {profileRunText}
                </Typography>
            }
        </Grid >
    );
}

// default props
LabelContainer.defaultProps = {
    classes: {},
    children: {},
    label: "",
    showProfileText: false,
    isAdvanced: false,
    profileRunDate: null,
    onProfileViewChange: () => {}
};

// prop types
LabelContainer.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    showProfileText: PropTypes.bool,
    isAdvanced: PropTypes.bool,
    profileRunDate: PropTypes.object,
    onProfileViewChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...containerStyle(theme)
    }),
    { withTheme: true }
)(LabelContainer);