const style = (theme) => ({
    domainCardContainer: {
        marginTop: '6px !important',
        paddingBottom: 10
    },
    domainCard: {
        boxShadow: '0px 2px 8px #00000014',
        borderRadius: '6px',
        height: '100%',
        '& .descriptionText': {
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .detail .MuiInput-underline': {
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            }
        },
        '& .DeleteIcon': {
            width: 20,
            height: 19,
            '&:hover ': {
                '& path': {
                    fill: `${theme.palette.secondary.main} !important`,
                    stroke: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& .cameraIcon': {
            visibility: 'hidden',
            opacity: 0,
            transition: 'all 0.5s',
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '&:hover': {
            '& .cameraIcon': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.5s'
            }
        }
    },
    imgContainer: {
        '& img': {
            height: '145px',
            objectFit: 'fill',
            width: '100%',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px'
        }
    },
    detail: {
        padding: '16px 20px 18px !important'
    },
    domainImgInput: {
        display: 'none'
    },
    title: {
        width: 'calc(100% - 45px)',
        '& .MuiInput-input': {
            fontFamily: `${theme.palette.titleFont} !important`,
            fontSize: `${theme.palette.headers.h5.size} !important`,
            fontWeight: 500
        }
    },
    description: {
        '& .MuiInput-input': {
            fontFamily: theme.palette.textFont,
            fontSize: '15px !important',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            color: `${theme.palette.greyshades.darkgrey} !important`
        }
    }
});
export default style;