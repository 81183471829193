import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography, Button, FormLabel, Tooltip } from '@mui/material';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { generateTokenRequest, getTokenRequest } from '../../../../redux/reducer/organizationReducer.js';
import { displyAlert } from '../../../../redux/reducer/alertReducer';

// Import Icons
import { CopyIcon } from '../../../../assets/svg/index.js';


function APIIntegration(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { REACT_APP_API_ENDPOINT } = process.env;

    /**
     * Redux Store
     */
    const token = useSelector(({ organization }) => organization.token);

    /**
     * Generate Token
     * @param {*} type
     */
    const generateToken = (type = "generate") => {
        dispatch(generateTokenRequest(type));
    };

    useEffect(() => {
        dispatch(getTokenRequest());
    }, []);

    const copyToClipBoard = (type) => {
        const text = type === "client_id" ? token?.client_id : token?.client_secret;
        navigator.clipboard.writeText(text);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    return (
        <Grid item xs={12}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.partnerAPI}
                </Typography>
            </Grid>
            <Grid className={classes.bodyContainer}>
                <Typography variant="body1">
                    {appConstants.labels.general.exportDesc}
                </Typography>
                {
                    !token.client_id ?
                        <Button onClick={() => generateToken("generate")}>
                            {appConstants.labels.general.generateToken}
                        </Button> :
                        <Grid container spacing={6} className="mt-3">

                            <Grid item xs={3} className="pt-0">
                                <FormLabel>
                                    Client ID
                                </FormLabel>
                                <Grid className={classes.copyData}>
                                    <Typography variant="body1" className="pr-15" noWrap>
                                        {token?.client_id ?? ""}
                                    </Typography>
                                    <Tooltip title={"Copy Client ID"}>
                                        <Grid className="copyIcon" onClick={() => copyToClipBoard("client_id")}>
                                            <CopyIcon />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} className="pt-0">
                                <FormLabel>
                                    Client Secret
                                </FormLabel>
                                <Grid className={classes.copyData}>
                                    <Typography variant="body1" className="pr-15 password" noWrap>
                                        {token?.client_secret ?? ""}
                                    </Typography>
                                    <Tooltip title={"Copy Client Secret"}>
                                        <Grid className="copyIcon" onClick={() => copyToClipBoard("client_secret")}>
                                            <CopyIcon />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className="pt-0 dflex apiTextBtns">
                                <Button color="secondary" variant="text" size="small" className="mr-2" onClick={() => window.open(`${REACT_APP_API_ENDPOINT}/swagger`, '_blank')}>
                                    Swagger URL
                                </Button>
                                <Button color="secondary" variant="text" size="small" onClick={() => window.open(`${REACT_APP_API_ENDPOINT}/doc`, '_blank')}>
                                    API Documentation
                                </Button>
                            </Grid>
                            <Grid item xs={12} className="pt-4">
                                <Button
                                    className="mr-1"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    disabled={token?.client_secret !== ""}
                                    onClick={() => generateToken("generate")}>
                                    {appConstants.labels.general.regenerateToken}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    size="small"
                                    disabled={token?.client_secret === ""}
                                    onClick={() => generateToken("revoke")}>
                                    {appConstants.labels.general.revokeToken}
                                </Button>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid >
    );
}

// default props
APIIntegration.defaultProps = {
    classes: {}
};

// prop types
APIIntegration.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(APIIntegration);