import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

//import Componetns
import { Grid, Typography } from '@mui/material';
import { TableComponent, LicenseComponent } from '../../../../components/index.js';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Helpers
import { getLicenseRequest } from '../../../../redux/reducer/licenseReducer.js';

// Import Helpers
import { checkPermission } from '../../../../helpers/appHelpers.js';


function LicenseDetail(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });

    /**
     * Redux Store
     */
    const licenses = useSelector(({ license }) => license.licenses);
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     * Get License Data
     */
    useEffect(() => {
        dispatch(getLicenseRequest());
    }, []);

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(licenses || [], searchData, sorting.sortBy, sorting.orderBy), [licenses || [], searchData, sorting.sortBy, sorting.orderBy]);
    return (
        <Grid item xs={12}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.license}
                </Typography>
            </Grid>
            <Grid className={classes.bodyContainer}>
                {
                    semanticsPermission?.is_edit &&
                    <LicenseComponent type="general" />
                }
                <Grid className="mt-15">
                    <TableComponent
                        title="Activated License"
                        headers={appConstants.table.licenseHeader}
                        data={filterData}
                        options={appConstants.tableOptions.common}
                        searchData={searchData}
                        sortBy={sorting.sortBy}
                        orderBy={sorting.orderBy}
                        onHandleSearchEvent={onHandleSearchEvent}
                        onClickSorting={onClickSorting}
                        styleType="striped"
                        NoResultText="No Records Found"
                        stickyHeader
                    />
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
LicenseDetail.defaultProps = {
    classes: {}
};

// prop types
LicenseDetail.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LicenseDetail);