import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import FilterItems from '../../../../containers/catalog/filterBar/filters.jsx';
import { SelectComponent, AutoCompleteComponent } from '../../../../components';

// Import Contantns
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { orderList } from '../../../../helpers';

// Import Reducers
import { getDomainListRequest } from '../../../../redux/reducer/semanticReducer.js';
import { getApplicationsRequest } from '../../../../redux/reducer/applicationReducer';
import { getMeasureNameListRequest } from '../../../../redux/reducer/baseMeasureReducer.js';
import { getReportsAssetsFilterRequest } from '../../../../redux/reducer/reportsReducer.js';

function Filters(props) {

    /**
     * Define Props
     */
    const { classes, detail, onChange, reportsPermission } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { searchableMeasuresList, measureLoading } = useSelector((state) => state.baseMeasure, shallowEqual);
    const { assets_filter, assetsFilterLoading } = useSelector((state) => state.reports, shallowEqual);

    /**
     * Get Applications and Glossary List
     */
    useEffect(() => {
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
        if (!assets_filter || assets_filter.length === 0) {
            dispatch(getReportsAssetsFilterRequest());
        }
    }, [dispatch]);

    /**
     * Get Custom Measures Based on Asset Selected
     */
    useEffect(() => {
        dispatch(getMeasureNameListRequest({ "assets": detail?.assets ?? [] }));
    }, [detail.assets]);


    /**
     * Filter Domains Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = domains.filter((item) => item && (item.count || item.measure_count));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter Applications Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = applications.filter((item) => item && (item.count || item.measure_count));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter Measures
     * @param {*} listData
     * @returns
     */
    const filterMeasures = (listData) => {
        let measures = JSON.parse(JSON.stringify(listData));
        measures = orderList(measures, 'name', 'asc');
        return measures;
    };
    const measuresList = useMemo(() => filterMeasures(searchableMeasuresList), [searchableMeasuresList]);

    /**
     * Filter Assets
     * @param {*} listData
     * @returns
     */
    const filterAssets = (listData) => {
        let assets = JSON.parse(JSON.stringify(listData));
        assets = orderList(assets, 'name', 'asc');
        return assets;
    };
    const assetsList = useMemo(() => filterAssets(assets_filter), [assets_filter]);

    /**
     * Handle Filters
     * @param {*} key
     * @param {*} value
     */
    const handleFilters = (key, value) => {
        const filters_data = { ...detail?.filters };
        filters_data[key] = value;
        onChange('filters', filters_data);
    };

    return (
        <Grid item xs={12} className={classes.FiltersContainer}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FilterItems header={'Date Range'} default_open>
                        <SelectComponent
                            fullWidth={false}
                            variant="outlined"
                            size="small"
                            propertyName={"value"}
                            list={appConstants.dropdowns.reportDateRange}
                            value={detail?.filters?.date_range ?? appConstants.dropdowns.reportDateRange[3]}
                            displayPropertyName={'name'}
                            onSelectChange={(value) => handleFilters('date_range', value)}
                            disabled={!reportsPermission?.is_edit}
                        />
                    </FilterItems>
                </Grid>
                {
                    domainsList && domainsList.length > 0 &&
                    <Grid item xs={12}>
                        <FilterItems header={'Domains'} default_open>
                            <AutoCompleteComponent
                                id="form_text_domains"
                                name={'domains'}
                                variant="outlined"
                                fullWidth
                                placeholder={"Select Domains"}
                                disableCloseOnSelect
                                disableClearable
                                compareKey={'id'}
                                data={domainsList}
                                multiple
                                selectedValue={detail?.filters?.domains ?? []}
                                onChange={
                                    (event, value) =>
                                        handleFilters('domains', value)
                                }
                                limitTags={1}
                                select_all
                            />
                        </FilterItems>
                    </Grid>
                }
                {
                    applicationsList && applicationsList.length > 0 &&
                    <Grid item xs={12}>
                        <FilterItems header={'Applications'} default_open>
                            <AutoCompleteComponent
                                id="form_text_applications"
                                name={'applications'}
                                variant="outlined"
                                fullWidth
                                placeholder={"Select Applications"}
                                disableCloseOnSelect
                                disableClearable
                                compareKey={'id'}
                                data={applicationsList}
                                multiple
                                selectedValue={detail?.filters?.applications ?? []}
                                onChange={
                                    (event, value) =>
                                        handleFilters('applications', value)
                                }
                                limitTags={1}
                                select_all
                            />
                        </FilterItems>
                    </Grid>
                }
                {
                    assetsList && assetsList.length > 0 &&
                    <Grid item xs={12}>
                        <FilterItems header={'Assets'} default_open>
                            <AutoCompleteComponent
                                id="form_text_columns"
                                name={'Assets'}
                                variant="outlined"
                                fullWidth
                                placeholder={"Select Assets"}
                                disableCloseOnSelect
                                disableClearable
                                compareKey={'id'}
                                data={assetsList}
                                multiple
                                renderType={'reportMap'}
                                selectedValue={detail?.filters?.assets ?? []}
                                onChange={
                                    (event, value) =>
                                        handleFilters('assets', value)
                                }
                                loading={assetsFilterLoading}
                                limitTags={1}
                                select_all
                                disabled={assetsFilterLoading}
                            />
                        </FilterItems>
                    </Grid>
                }
                {
                    measuresList && measuresList.length > 0 &&
                    <Grid item xs={12}>
                        <FilterItems header={'Custom Measure Name'} default_open>
                            <AutoCompleteComponent
                                id="form_text_columns"
                                name={'Measures'}
                                variant="outlined"
                                fullWidth
                                placeholder={"Select Measure"}
                                disableCloseOnSelect
                                disableClearable
                                compareKey={'id'}
                                data={measuresList}
                                multiple
                                renderType={'reportMap'}
                                selectedValue={detail?.filters?.measures ?? []}
                                onChange={
                                    (event, value) =>
                                        handleFilters('measures', value)
                                }
                                loading={measureLoading}
                                limitTags={1}
                                select_all
                                disabled={measureLoading}
                            />
                        </FilterItems>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

// default props
Filters.defaultProps = {
    classes: {},
    detail: {},
    reportsPermission: {},
    onChange: () => { }
};

// prop types
Filters.propTypes = {
    classes: PropTypes.object,
    detail: PropTypes.object,
    reportsPermission: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Filters);