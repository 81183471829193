import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrag } from 'react-dnd';

//  Import Components
import { Grid } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';


function DragItem(props) {
    const { data, children } = props;

    /**
     * Drop
     * @returns
     */
    const Drag = () => {
        const [, drag] = useDrag({
            type: "card",
            item: data,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        });
        return (
            <Grid ref={drag}>
                {children}
            </Grid>
        );
    };

    return (
        Drag()
    );
}

// default props
DragItem.defaultProps = {
    classes: {},
    data: {}
};

// prop types
DragItem.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    children: PropTypes.node
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DragItem);