import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import { conversationCreateFailure, conversationCreateRequest, conversationCreateSuccess, conversationListFailure, conversationListRequest, conversationListSuccess, conversationUpdateFailure, conversationUpdateRequest, conversationUpdateSuccess, conversationDeleteFailure, conversationDeleteRequest, conversationDeleteSuccess, getFeaturedCommentRequest, getFeaturedCommentSuccess, getFeaturedCommentFailure, getAttributesTagsListRequest, getAttributesTagsListSuccess, getAttributesTagsListFailure } from '../reducer/conversationReducer';
import { conversationService, assetService } from '../service';
import { updateStarRating } from '../reducer/versionReducer';


/**
 * Create Actions
 * @param {*} action$
 * @returns
 */

const create = (action$) => action$.pipe(
    ofType(conversationCreateRequest),
    mergeMap(({ payload }) => {
        return from(conversationService.create(payload)).pipe(
            concatMap((res) => of(conversationCreateSuccess({ ...res, requestData: { ...payload } }), updateStarRating(res))),
            catchError(() => of(conversationCreateFailure()))
        );
    })
);

/**
 * Connection List
 * @param {*} action$
 * @returns
 */
const list = (action$) => action$.pipe(
    ofType(conversationListRequest),
    mergeMap(({ payload: { type, id, limit, offset } }) => {
        return from(conversationService.list(type, id, limit, offset)).pipe(
            concatMap((res) => of(conversationListSuccess({ ...res, offset }))),
            catchError(() => of(conversationListFailure()))
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(conversationUpdateRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(conversationService.update(id, rest)).pipe(
            concatMap((res) => of(conversationUpdateSuccess({ ...res, requestData: { ...rest } }), updateStarRating(res))),
            catchError(() => of(conversationUpdateFailure()))
        );
    })
);

/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
    ofType(conversationDeleteRequest),
    mergeMap(({ payload }) => {
        return from(conversationService.remove(payload.message_id)).pipe(
            concatMap((res) => of(conversationDeleteSuccess(payload), updateStarRating(res))),
            catchError(() => of(conversationDeleteFailure()))
        );
    })
);

/**
 * Featured Comment
 * @param {*} action$
 * @returns
 */
const getFeaturedComment = (action$) => action$.pipe(
    ofType(getFeaturedCommentRequest),
    mergeMap(({ payload: { type, id } }) => {
        return from(conversationService.get_featured(type, id)).pipe(
            concatMap((res) => of(getFeaturedCommentSuccess(res))),
            catchError(() => of(getFeaturedCommentFailure()))
        );
    })
);

/**
 * Get All Assets and Attributes
 * @param {*} action$
 * @returns
 */
const getAttributesTagsList = (action$) => action$.pipe(
    ofType(getAttributesTagsListRequest),
    mergeMap(({ payload }) => {
        return from(assetService.getAllAssetsAndAttributes(payload)).pipe(
            concatMap((res) => of(getAttributesTagsListSuccess({ ...res }))),
            catchError(() => of(getAttributesTagsListFailure()))
        );
    })
);

// Export All Epic Functions
export const conversationEpic = [create, list, update, remove, getFeaturedComment, getAttributesTagsList];