const style = (theme) => ({
  usersList: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAvatar-root': {
      height: 24,
      width: 24,
      fontSize: 12,
      border: `1px solid ${theme.palette.greyshades.darkgrey}40 !important`,
      marginLeft: '6px !important',
      textTransform: 'uppercase'
    },
    '& .UserAddIcon2': {
      cursor: 'pointer',
      width: '23px',
      height: '23px',
      marginLeft: '2px'
    },
    '& .addUserGrid': {
      position: 'relative',
      width: '100%',
      background: '#fff'
    }
  },
  addUserInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 14px !important'
    }
  },
  addUserBox: {
    position: 'absolute',
    left: 0,
    top: '-18px',
    zIndex: 10,
    background: theme.palette.primary.contrastText,
    borderRadius: 3
  },
  inlineUserBox: {
    background: theme.palette.primary.contrastText,
    borderRadius: 3
  },
  addUserContainer: {
    position: 'relative',
    width: '100%',
    '& .MuiAutocomplete-popper': {
      background: '#fff !important'
    }
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`
    },
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`
    }
  },
  tag: {
    height: 26
  },
  popoverDiv: {
    padding: 5
  },
  avatarMedium: {
    width: 24,
    height: 24
  },
  avatarLarge: {
    width: '28px !important',
    height: '28px !important'
  },
  ListUser: {
    minWidth: 250
  },
  chip: {
    height: `26px !important`,
    width: `26px !important`,
    '& .MuiChip-label': {
      padding: 0
    }
  }
});
export default style;