const LayoutStyle = (theme) => ({
    // margin &padding
    mt1: { marginTop: '10px !important' },
    mt2: { marginTop: '20px !important' },
    //Button
    linkBtn: {
        display: 'block !important',
        padding: '0px !important',
        minWidth: 'auto !important',
        color: theme.palette.headers.body1.color,
        /*
         * fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
         * fontSize: '15px !important',
         * fontWeight: '400 !important',
         * textTransform: 'none !important',
         */
        '&:hover': {
            background: 'transparent !important'
        },
        '& a': {
            textDecoration: 'none !important',
            color: theme.palette.headers.body1.color,
            '&:hover': {
                color: theme.palette.secondary.main
            }
        }
    },
    navigateText: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    textSecondary: {
        color: `${theme.palette.greyText} !important`,
        '&.textSecondary': {
            color: `${theme.palette.greyText} !important`
        }
    },
    muiChip: {
        '& .MuiChip-root': {
            '&.sm-r': {
                height: 24
            },
            '&.sm': {
                height: 24,
                borderRadius: 4
            },
            '&.success': {
                color: theme.palette.componentColors.success,
                background: `${theme.palette.componentColors.success}25`
            },
            '&.success-txt': {
                /*
                 * color: theme.palette.colors.success,
                 * background: theme.palette.primary.contrastText
                 */
                background: '#54DF9A',
                color: '#ffffff'
            },
            '&.danger': {
                color: theme.palette.componentColors.danger,
                background: `${theme.palette.componentColors.danger}25`
            },
            '&.dangerRed': {
                color: theme.palette.componentColors.shade1,
                background: `${theme.palette.componentColors.shade1}25`
            },
            '&.warning': {
                color: theme.palette.componentColors.warning,
                background: `${theme.palette.componentColors.warning}25`
            },
            '&.info': {
                color: theme.palette.componentColors.info,
                background: `${theme.palette.componentColors.info}25`
            },
            '&.orange': {
                color: theme.palette.componentColors.shade2,
                background: `${theme.palette.componentColors.shade2}25`
            },
            '&.grey': {
                color: theme.palette.componentColors.default,
                background: `${theme.palette.componentColors.default}30`
            },
            '&.secondaryChip': {
                background: `${theme.palette.componentColors.secondary}25`,
                border: `1px solid ${theme.palette.componentColors.secondary}`
            },
            '&.default': {
                background: theme.palette.greyshades.lightestgrey,
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            },
            '&.yellow': {
                background: `${theme.palette.componentColors.shade3}25`,
                border: `1px solid ${theme.palette.componentColors.shade3}`
            }
        }
    },
    textBoxBg: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '& .MuiTextField-root': {
            '& .MuiInputBase-input': {
                paddingLeft: 0,
                paddingRight: 0,
                transition: 'all 0.5s',
                fontSize: '14px'
            },
            '&:hover': {
                '& .MuiInputBase-input': {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    background: theme.palette.greyshades.lightestgrey,
                    transition: 'all 0.5s'
                }
            }
        }
    },
    outLineNone: {
        '& .MuiSelect-select': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
            paddingRight: '32px !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '& .MuiOutlinedInput-input:hover': {
            background: theme.palette.greyshades.lightestgrey
        }
    },
    linkText: {
        color: `${theme.palette.secondary.main} !important`
    },
    starIcon: {
        '& .MuiRating-decimal': {
            marginRight: '1px !important'
        }
    },
    adornment: {
        "& .MuiSvgIcon-root:hover": {
            color: theme.palette.secondary.main
        }
    }
});

export default LayoutStyle;