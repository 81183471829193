// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import _ from 'lodash';

//Import Components
import { SelectComponent, IntegerInputComponent } from '../../../../components/index.js';

//Import Styles
import style from "./style.jsx";

const DepthComponent = (props) => {

    /**
     * Define Props
     */
    const { classes, options, onDepthChage, seletedValue } = props;

    const onChange = (value, type) => {
        const depthValues = { ...seletedValue };
        depthValues[type] = value;
        onDepthChage(depthValues);
    };

    return (
        <ValidatorForm onSubmit={() => { }}>
            <Grid container className={classes.depthBlock}>
                <IntegerInputComponent
                    size="small"
                    noOutline
                    value={seletedValue.value}
                    onChange={(event) => onChange(event.target.value, "value")}
                    inputProps={{ maxLength: 3 }}
                />
                <SelectComponent
                    list={options}
                    value={seletedValue.type}
                    label="Select List"
                    noOutline
                    onSelectChange={(value) => onChange(value, "type")}
                />
            </Grid>
        </ValidatorForm>
    );
};


/**
 * Define Component Props
 */
DepthComponent.propTypes = {
    classes: PropTypes.object,
    options: PropTypes.array,
    onDepthChage: PropTypes.func,
    seletedValue: PropTypes.object
};

/**
 * Set Default Values
 */
DepthComponent.defaultProps = {
    classes: {},
    options: ['Days', 'Percentage'],
    seletedValue: {
        type: 'Days',
        value: '1'
    },
    onDepthChage: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.seletedValue, nextProps.seletedValue) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(DepthComponent, areEqual));