import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

// Import Components
import { LoaderComponent, NoResultComponent } from '../../../../../components/index.js';

//  Import Styles
import RolesStyle from "../style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';

// import Actions
import { getQueryHistoryRequest } from '../../../../../redux/reducer/usageReducer';


function Roles(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const asset_id = useSelector((state) => state.version.asset_id, shallowEqual);
    const { data, isLoading } = useSelector((state) => state.usage.top_5_roles, shallowEqual);

    /**
     *
     * Get Usage
     */
    useEffect(() => {
        if (asset_id && data.length <= 0) {
            dispatch(getQueryHistoryRequest({ asset_id, type: 'top_5_roles' }));
        }
    }, [dispatch, asset_id]);


    return (
        <Grid>
            {
                isLoading &&
                <LoaderComponent loaderType="circular" height="150" />
            }
            {
                !isLoading && data.length > 0 &&
                <Grid className={classes.queryTabsContanier}>
                    {
                        data.map((item, index) => (
                            <Grid container alignItems={"center"} wrap="nowrap" className="mb8 listItem" justifyContent={"space-between"} key={`role-${index}`}>
                                <Grid item sx={{ overflow: 'hidden' }}>
                                    <Typography variant="body1" noWrap>
                                        {item.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" className={classes.countValue}>
                                        {item.count}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            }
            {
                !isLoading && data.length === 0 &&
                <NoResultComponent height="150" title="No Roles Found" />
            }
        </Grid>
    );
}

// default props
Roles.defaultProps = {
    classes: {}
};

// prop types
Roles.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...RolesStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Roles);