import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { TableComponent } from '../../../../../components/index';

//import Styles
import WorkLogStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../constants/appConstants.js';

// Import Actions
import { getWorkLogRequest } from '../../../../../redux/reducer/issuesReducer';

function WorkLog(props) {
    const { classes } = props;
    const dispatch = useDispatch();

    const { id: issueId, worklog, isLoading } = useSelector((state) => state.issues);

    useEffect(() => {
        if (issueId) {
            dispatch(getWorkLogRequest(issueId));
        }
    }, []);

    return (
        <Grid className={classes.pageContainer}>
            <TableComponent
                styleType="striped"
                headers={appConstants.table.issuesWorkLogHeader}
                data={worklog || []}
                NoResultText="No Work Logs Found"
                isLoading={isLoading}
                height="200px"
                stickyHeader
            />
        </Grid>
    );
}

// default props
WorkLog.defaultProps = {
    classes: {}
};

// prop types
WorkLog.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...WorkLogStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(WorkLog);