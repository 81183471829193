import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import DetailCardStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';
import { CircularScoreComponent } from '../../../../components/index.js';
import { useDispatch } from 'react-redux';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';
import { resetAssetMeasureSearch } from '../../../../redux/reducer/measureReducer.js';

function DetailCard(props) {

    const { classes, data, type } = props;
    const reactNavigation = useNavigate();
    const dispatch = useDispatch();

    /**
     * Navigate to Catalog Based on Tile Click
     * @param {*} selected_type
     */
    const navigateCatalog = (selected_type) => {
        if (!data.id) {
            return;
        }
        switch (selected_type) {
            case "tables":
            case "views":
            case "attributes":
            case "reports":
            case "pipelines":
                dispatch(navigate({ path: 'catalog.root', state: { filter: true, type: type, type_value: type === 'domain' ? data.sub_domains : data.id, search_by: selected_type } }));
                break;
            case "alerts":
                reactNavigation(`/home/alerts?${type}=${data.id}`, { replace: true });
                break;
            case "issues":
                reactNavigation(`/home/issues?${type}=${data.id}`, { replace: true });
                break;
            case "measures":
                dispatch(resetAssetMeasureSearch());
                reactNavigation(`/home/measures?${type}=${data.id}`, { replace: true });
                break;
            default:
                break;
        }
    };

    return (
        <Grid container className={classes.detailCard} spacing={7} sx={{ backgroundImage: `url('${data.image || null}')` }}>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('tables')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.tables || 0}
                    </Typography>
                    <Typography variant="h4">
                        Tables
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('views')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.views || 0}
                    </Typography>
                    <Typography variant="h4">
                        Views
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('attributes')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.attributes || 0}
                    </Typography>
                    <Typography variant="h4">
                        Attributes
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('reports')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.reports || 0}
                    </Typography>
                    <Typography variant="h4">
                        Reports
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('pipelines')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.pipeline || 0}
                    </Typography>
                    <Typography variant="h4">
                        Pipeline
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('alerts')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.alerts || 0}
                    </Typography>
                    <Typography variant="h4">
                        Alerts
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('issues')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.issues || 0}
                    </Typography>
                    <Typography variant="h4">
                        Issues
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid className="pointer" onClick={() => navigateCatalog('measures')}>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data.measures || 0}
                    </Typography>
                    <Typography variant="h4">
                        Measures
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="cards" item xs={3}>
                <Grid>
                    <Typography variant="h1" className="fw-600 pb-1">
                        {data?.users_count ?? data?.users?.length ?? 0}
                    </Typography>
                    <Typography variant="h4">
                        Users
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <CircularScoreComponent
                    size={40}
                    value={data.score || 0}
                    showPercentage
                    noLink
                    showValue
                />
            </Grid>
        </Grid>
    );
}

// default props
DetailCard.defaultProps = {
    classes: {},
    data: {},
    type: ''
};

// prop types
DetailCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...DetailCardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DetailCard);