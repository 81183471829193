import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

//Style
import style from './style.jsx';

function AccessDenied(props) {

    const { classes } = props;

    return (
        <Grid className={classes.pageContainer}>
            <Grid item align="center">
                <svg xmlns="http://www.w3.org/2000/svg" width="751.98" height="315.975" viewBox="0 0 751.98 315.975">
                    <g id="Group_169253" data-name="Group 169253" transform="translate(-323 -134.025)">
                        <g id="Group_169200" data-name="Group 169200" transform="matrix(-0.766, 0.643, -0.643, -0.766, 994.186, 371.342)">
                            <path id="Path_5847" data-name="Path 5847" d="M47.747,33.006a13.433,13.433,0,0,1-5.871-5.124,127.2,127.2,0,0,1-19.106,4.3,13,13,0,0,0,2,5.028,23.443,23.443,0,0,1-6.655-4.469A62.674,62.674,0,0,1,0,32.255C5.43,43.378,14.743,51.628,27.4,52.837a18.606,18.606,0,0,0,2.787-5.6,9.932,9.932,0,0,1,.46,5.735c7.446,0,15.945-2.352,25.4-7.8a28.685,28.685,0,0,0,.757-8.314,24.974,24.974,0,0,1,3.8,5.408C74.52,32.065,84.229,12.019,81.258,0,78.644,11.039,63.284,20.624,45.779,26.613A13.556,13.556,0,0,0,47.747,33.006Z" transform="translate(0 0)" fill="#9faec3" />
                            <g id="Group_169199" data-name="Group 169199" transform="translate(14.194 0)">
                                <path id="Path_5848" data-name="Path 5848" d="M44.123,34.184a45.456,45.456,0,0,0,11.026-9.13,49.735,49.735,0,0,0,6.843-9.887A45.915,45.915,0,0,0,67.063,0a64.9,64.9,0,0,1-6.276,14.753A50.376,50.376,0,0,1,43.161,32.891,55.033,55.033,0,0,1,17.63,40.086,114.708,114.708,0,0,1,0,39.355a74.99,74.99,0,0,0,17.555,1.888A53.339,53.339,0,0,0,44.123,34.184Z" transform="translate(0 0)" fill="#6f7e92" />
                            </g>
                        </g>
                        <path id="Path_5891" data-name="Path 5891" d="M0,0H746V5H0Z" transform="translate(323 445)" fill="#9faec3" />
                        <g id="Group_169229" data-name="Group 169229" transform="translate(682.998 170.037)">
                            <rect id="Rectangle_753" data-name="Rectangle 753" width="28.621" height="8.384" rx="1.5" transform="translate(0.001)" fill="#c5cedb" />
                            <rect id="Rectangle_754" data-name="Rectangle 754" width="28.621" height="8.384" rx="1.5" transform="translate(15.613 10.693)" fill="#c5cedb" />
                        </g>
                        <g id="Group_169249" data-name="Group 169249" transform="translate(954.914 170.037)">
                            <rect id="Rectangle_754-2" data-name="Rectangle 754" width="28.621" height="8.384" rx="1.5" transform="translate(-0.001 0)" fill="#c5cedb" />
                            <rect id="Rectangle_763" data-name="Rectangle 763" width="28.621" height="8.384" rx="1.5" transform="translate(10.652 10.692)" fill="#c5cedb" />
                        </g>
                        <g id="Group_169250" data-name="Group 169250" transform="translate(342.393 311.712)">
                            <rect id="Rectangle_753-2" data-name="Rectangle 753" width="28.621" height="8.384" rx="1.5" transform="translate(-0.001)" fill="#c5cedb" />
                            <rect id="Rectangle_761" data-name="Rectangle 761" width="28.621" height="8.384" rx="1.5" transform="translate(36.523)" fill="#c5cedb" />
                            <rect id="Rectangle_754-3" data-name="Rectangle 754" width="28.621" height="8.384" rx="1.5" transform="translate(15.61 10.693)" fill="#c5cedb" />
                        </g>
                        <g id="Group_169251" data-name="Group 169251" transform="translate(420.393 134.025)">
                            <rect id="Rectangle_753-3" data-name="Rectangle 753" width="28.621" height="8.384" rx="1.5" transform="translate(-0.001)" fill="#c5cedb" />
                            <rect id="Rectangle_761-2" data-name="Rectangle 761" width="28.621" height="8.384" rx="1.5" transform="translate(36.523)" fill="#c5cedb" />
                            <rect id="Rectangle_754-4" data-name="Rectangle 754" width="28.621" height="8.384" rx="1.5" transform="translate(15.61 10.693)" fill="#c5cedb" />
                        </g>
                        <path id="Path_5892" data-name="Path 5892" d="M247.944,72.631a.771.771,0,0,0,.795-.71.74.74,0,0,0-.432-.879.691.691,0,0,0-.183-.061c-2.6-.635-4.662-3.777-7.368-3.817-2.314-.033-4.035-.433-5.147-2.65a10.532,10.532,0,0,0-1.764-2.653,5.483,5.483,0,0,0-5.064-1.368c-1.331.192-2.593.16-3.922.238-.738.042-2.281-3.14-2.622-3.69-2.851-4.607-5.579-9.549-11.564-10.184a16.415,16.415,0,0,0-13.514,5.078c-2.618-2.444-4.82-5.059-8.832-3.111a9.338,9.338,0,0,0-4.612,4.707,12.4,12.4,0,0,1-2.067,3.371c-1.278,1.36-3.83.568-5.419.553-2.079-.022-3.444,1.659-4.621,3.126-1.444,1.8-2.775,1.934-4.9,1.264a3.645,3.645,0,0,0-2.416.108c-3.845,2.067-4.753,8.589-9.759,9.009a.835.835,0,0,0,0,1.669h93.409Z" transform="translate(389.24 144.736)" fill="#9faec3" />
                        <path id="Path_5893" data-name="Path 5893" d="M351.6,192.125a.831.831,0,0,0,.855.81h80.567a.9.9,0,0,0,.893-.891c-.234-3.882-4.862-5.216-8.287-5-1.579-6.357-7.84-7.277-12.731-3.751-1.9-3.964-3.244-7.61-8.157-8.512-3.874-.712-6.567,1.506-8.44,4.517-2.81-3.557-5.612-7.45-10.435-8.159-4-.587-8.493.465-10.545,4.364-1.177,2.244-1.586,4.869-2.865,7.049-1.035,1.761-3.341,1.189-5.075,1.936-2.43,1.048-3.9,3.347-6.3,4.4a34.176,34.176,0,0,1-8.726,2.269,1.113,1.113,0,0,0-.146.024A.72.72,0,0,0,351.6,192.125Z" transform="translate(641.064 140.72)" fill="#9faec3" />
                        <path id="Path_5894" data-name="Path 5894" d="M210.733,280.116c.66-3.32-4.25-6.371-8.034-5.147.617-2.276-.086-4.341-3.439-5.36a8.545,8.545,0,0,0-4.552-.02c2.645-5.274.183-11.279-7.992-12.52-8.89-1.35-18.756,3.012-20.52,10.032-7.229-7.1-22.547,1.408-18.025,9.583-2.793-.444-5.671,1.137-5.84,3.488a2.842,2.842,0,0,0-3.312,4.4.807.807,0,0,0,.648.3h75.45a.835.835,0,0,0,.459-.123.573.573,0,0,0,.265-.314C216.7,281.987,213.534,279.666,210.733,280.116Z" transform="translate(627.715 -53.481)" fill="#c5cedb" />
                        <g id="Group_169198" data-name="Group 169198" transform="matrix(-0.766, 0.643, -0.643, -0.766, 1013.326, 382.68)">
                            <path id="Path_5845" data-name="Path 5845" d="M49.629,27.275a18.553,18.553,0,0,1-4.211-8.947,117.973,117.973,0,0,1-21.587-2.72,20.278,20.278,0,0,0,.194,7.178,30.223,30.223,0,0,1-5.272-8.455C11.36,12.255,4.845,9.451,0,6.011,1.5,22.321,8.126,36.662,20.9,43.563a24.09,24.09,0,0,0,5.017-5.855,15.387,15.387,0,0,1-1.68,7.413c7.786,3.168,17.553,3.824,29.488.986a42.89,42.89,0,0,0,3.92-10.137,37.64,37.64,0,0,1,1.927,8.416c18.4-6.909,36.09-28,37.5-44.387-6.885,12.78-26.544,18.3-47.1,18.39A21.511,21.511,0,0,0,49.629,27.275Z" transform="translate(0 0)" fill="#afbdcf" />
                            <g id="Group_169197" data-name="Group 169197" transform="translate(12.17 0)">
                                <path id="Path_5846" data-name="Path 5846" d="M48.067,33.249a46.76,46.76,0,0,0,14.956-6.8,58.639,58.639,0,0,0,10.869-9.528A62.681,62.681,0,0,0,84.9,0,85.281,85.281,0,0,1,72.787,15.89c-6,6.119-14.489,12.638-25.239,15.326-10.731,2.711-21.306,1.048-29.4-1.81A113.709,113.709,0,0,1,0,20.987a76.31,76.31,0,0,0,17.636,9.841A49.836,49.836,0,0,0,48.067,33.249Z" transform="translate(0 0)" fill="#6f7e92" />
                            </g>
                        </g>
                        <path id="Path_5895" data-name="Path 5895" d="M18184.7,7476.28s85.178-13.285,94.557,22.663-162.74,68.964-154.73,100.028,190.678-25.789,190.678-25.789" transform="translate(-17744 -7205)" fill="none" stroke="#9faec3" strokeWidth="1" strokeDasharray="4" />
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M-17116.537-7089.107v0h-150.574l14.689-28.915a83.971,83.971,0,0,0,12.961,7.989,99.269,99.269,0,0,0,14.7,5.915,108.753,108.753,0,0,0,32.145,4.933c11.953,0,21.867-2.443,28.668-7.064a23.067,23.067,0,0,0,10.5-19.846c0-8.17-3.121-14.5-9.271-18.8-6.555-4.587-16.615-6.913-29.9-6.913h-22.129v-32l43.059-48.735h-89.1v-38.872h146.514v31.395l-47.244,53.821a86.36,86.36,0,0,1,24.77,7.583,59.47,59.47,0,0,1,17.762,13.161,52.919,52.919,0,0,1,10.7,17.813,63.335,63.335,0,0,1,3.582,21.543,62.016,62.016,0,0,1-11.824,36.988Zm-186.869,0v0h-132.34a99.986,99.986,0,0,1-17.34-33.3,149.312,149.312,0,0,1-6.244-44.353c0-16.835,2.283-32.33,6.785-46.053a97.909,97.909,0,0,1,18.781-33.969,80,80,0,0,1,28.422-21.018,88.248,88.248,0,0,1,35.713-7.2,88.652,88.652,0,0,1,35.838,7.2,79.673,79.673,0,0,1,28.41,21.018,97.7,97.7,0,0,1,18.711,33.969c4.473,13.707,6.742,29.2,6.742,46.053a150.062,150.062,0,0,1-6.2,44.353,99.859,99.859,0,0,1-17.273,33.3Zm-66.223-144.931c-12.523,0-22.383,5.32-29.3,15.811-7.539,11.429-11.361,28.745-11.361,51.467s3.822,40.035,11.361,51.464c6.922,10.491,16.781,15.81,29.3,15.81,12.66,0,22.607-5.319,29.566-15.81,7.564-11.406,11.4-28.721,11.4-51.464s-3.836-40.062-11.4-51.467C-17347.021-7228.718-17356.969-7234.038-17369.629-7234.038Zm-138.135,144.928h-47.246v-16.953h-112.723l0-32.593,98.672-132.757h50.828l-91.494,125.882h56.213l0-39.17h45.744l0,39.17h34.082v39.468h-34.084l0,16.952Z" transform="translate(18054 7534.111)" fill="#c8d1dd" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
                        <path id="Path_5896" data-name="Path 5896" d="M18124.527,7598.97c8.01,31.063,190.678-25.789,190.678-25.789" transform="translate(-17744 -7205)" fill="none" stroke="#9faec3" strokeWidth="1" strokeDasharray="4" />
                        <image id="Rectangle_764" data-name="Rectangle 764" width="106" height="155" transform="translate(638 294)" />
                        <g id="e" transform="translate(408.383 259.343) rotate(-21)">
                            <path id="Path_5914" data-name="Path 5914" d="M2.2,17.613c8.446,2.635,17.458,6.238,26.521,9.581a1.191,1.191,0,0,1-.017,2.283c-2.634.773-5.278,1.584-7.922,2.406a.616.616,0,0,1-.509-.065c-4.228-2.646-8.489-5.365-12.623-7.935a.216.216,0,0,0-.262.338c3.359,3.083,6.852,6.29,10.293,9.447a.61.61,0,0,1,.1.786c-2.217,3.372-4.426,6.76-6.494,10.088a1.2,1.2,0,0,1-2.143-.294C5.905,35.872,2.5,27.581.065,19.742A1.78,1.78,0,0,1,2.2,17.613Z" transform="translate(-0.001 -17.548)" fill="#9faec3" />
                            <path id="Path_5915" data-name="Path 5915" d="M149.622,299.786q-.031,3.516-.128,7.031a1.166,1.166,0,0,1-1.919.89q-1.112-1.043-2.248-2.1a1.047,1.047,0,0,1-.155-1.334c1.053-1.62,2.119-3.241,3.182-4.854a.688.688,0,0,1,1.269.368Z" transform="translate(-128.431 -282.843)" fill="#9faec3" />
                        </g>
                    </g>
                </svg>
                {/* <Typography variant="h1" className="pt-2" align="center">
                    Access Denied..,
                </Typography> */}
                <Typography variant="body1" className="pt-4" align="center">
                    You don't have permission to access this page
                </Typography>
                <Grid align="center">
                    <Button variant="contained" color="primary" disableElevation className="mt-3">
                        Go to home
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AccessDenied.defaultProps = {
    classes: {}
};

// prop types
AccessDenied.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(AccessDenied);