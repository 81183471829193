import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Typography } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import attributesListStyle from "./style.jsx";
import ColumnDialog from "./columnDialog.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//  Import Components
import { TableComponent } from '../../../../../components/index.js';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';

function AccordionCard(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;

    /**
     * Define State
     */
    const [expanded, setExpanded] = React.useState(false);
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });
    const [columnPreview, setColumnPreview] = React.useState(null);

    /**
     * Handle Change
     * @param {*} panel
     * @returns
     */
    const handleChange = () => () => {
        setExpanded(!expanded);
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Handle Action Event
     * @param {*} selectedItem
     * @param {*} actionName
     */
    const onHandleActions = (selectedItem) => {
        setColumnPreview(selectedItem);
    };

    /**
     * Close Dialog
     */
    const handleCloseDialog = () => {
        setColumnPreview(null);
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data.table || [], searchData, sorting.sortBy, sorting.orderBy), [data.table || [], searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <Grid item xs={12} className={classes.themeList}>
            <Accordion
                expanded={expanded}
                onChange={handleChange()}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        {data.name_in_source || ""}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12} className={`pt-2 pb-2 ${classes.attributeListContainer}`}>
                        <TableComponent
                            title="Tables"
                            headers={appConstants.table.fivetranTableListHeader}
                            data={filterData}
                            options={appConstants.tableOptions.common}
                            searchData={searchData}
                            sortBy={sorting.sortBy}
                            orderBy={sorting.orderBy}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickSorting={onClickSorting}
                            onClickActions={onHandleActions}
                            styleType="striped"
                            NoResultText="No Records Found"
                            height="calc(100vh - 450px)"
                            stickyHeader
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={Boolean(columnPreview)}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <ColumnDialog data={columnPreview?.column} closeDialog={handleCloseDialog} />
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
AccordionCard.defaultProps = {
    classes: {},
    data: []
};

// prop types
AccordionCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...attributesListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(AccordionCard, areEqual));