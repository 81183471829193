import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Components
import { TableComponent, DialogComponent } from '../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Styles
import SemanticTagsStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';

// Actions
import {
    getTagsRequest, createTagRequest, deleteTagRequest, updateTagRequest, toggleTag, updateTagOrderRequest, updateSubTagOrderRequest
} from '../../../redux/reducer/tagsReducer.js';
import { checkPermission, permissionHeaders, prepareUpdateUserPreference, getUserPreference } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';
import { navigate } from '../../../redux/reducer/navigationReducer.js';
import { updateUserPreference } from '../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest, getUsersThumbnailRequest } from '../../../redux/reducer/userReducer';

function SemanticTags(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        "name": "",
        "description": "",
        "linked_attribute": ""
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { tags, isLoading } = useSelector((state) => state.tags, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "tags", "columns");
    const { thumbnail_list: usersList } = useSelector((state) => state.user, shallowEqual);

    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(getTagsRequest());
    }, [dispatch]);

    /**
     * Get user info
     */
    const tagsData = (tags, usersList) => {
        const usersMap = new Map();
        usersList.forEach((user) => {
            usersMap.set(user.id, user);
        });

        const updatedTags = tags.map((tag) => {
            const updatedUsers = Array.isArray(tag.users) ? tag.users.map((userId) =>
                usersMap.get(userId)).filter((user) => user) : [];
            return { ...tag, users: updatedUsers };
        });
        return updatedTags;
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestData = {
            ...item, [key]: value
        };
        if (key === "name") {
            requestData.display_name = value;
        }
        if (key === "users") {
            value = value.map((user) => user.id);
            requestData.users = value;
        } else {
            requestData.users = (requestData.users && requestData.users.length > 0) ? requestData.users.map((user) => user.id) : [];
        }
        dispatch(updateTagRequest(requestData));
    };

    /**
     * Handle Adding or Deleting Tag
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = (item, actionType) => {
        if (actionType === 'delete') {
            setShowDeleteDialog({
                open: true,
                title: appConstants.dialogBox.delete,
                message: appConstants.dialogBox.tagsDeleteMessage,
                data: { id: item?.id }
            });
        }
        if (actionType === 'create') {
            dispatch(createTagRequest({ ...item, display_name: item.name }));
        }
    };

    /**
     * Handle drag and drop tag order
     * @param {*} data
     * @param {*} actionType
     */

    const handleRowDragAction = (data) => {
        dispatch(updateTagOrderRequest(data));

    };

    /**
     * Handle drag and drop tag order
     * @param {*} data
     * @param {*} actionType
     */
    const handleSubRowDragAction = (data) => {
        dispatch(updateSubTagOrderRequest(data));
    };

    /**
     * Handle Actions of the SubTable
     * @param {*} item
     * @param {*} actionType
     * @param {*} parentItemId
     */
    const handleSubTableActions = (item, actionType, event, parentItemId, type = "purge") => {
        if (actionType === 'delete') {
            dispatch(deleteTagRequest({ id: item.id, type: (type === "purge"), parent_id: parentItemId }));
        }
        if (actionType === 'create') {
            const parentDetails = tags.filter((tag) => tag.id === parentItemId);
            const display_name = `${parentDetails[0].name}.${item.name}`;
            const parent_color = parentDetails[0].color;
            dispatch(createTagRequest({ ...item, parent: parentItemId, display_name: display_name, color: parent_color, associated_tags: item.associated_tags ? item.associated_tags.map((tagItem) => { return { id: tagItem.id, name: tagItem.name, color: tagItem.color }; }) : [] }));
        }
    };

    /**
     * Handle SubTable Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onSubTableCompnentEvent = (key, event, item, fakeEvent, parentTagId) => {
        const parentDetails = tags.filter((tag) => tag.id === parentTagId);
        const display_name = `${parentDetails[0].name}.${item.name}`;
        const requestData = {
            ...item,
            display_name: display_name,
            [key]: key === 'associated_tags' ? event.map((tag) => tag) : event?.target?.value ?? event,
            parent: parentTagId,
            associated_tags: key !== 'associated_tags' ? item.associated_tags.map((tag) => { return { id: tag.id, name: tag.name, color: tag.color }; }) : event.map((tag) => { return { id: tag.id, name: tag.name, color: tag.color }; })
        };
        if (key === "name") {
            const parentDetails = tags.filter((tag) => tag.id === parentTagId);
            requestData.display_name = `${parentDetails[0].name}.${event}`;
        }
        dispatch(updateTagRequest(requestData));
    };

    /**
     * Handle Adding the new associated tag in the Chip Component of the SubTable
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     * @param {*} parentTagId
     */
    const handleSubTableChipAdd = (value, fieldValue, item, key, parentTagId) => {
        const requestData = {
            ...item, [key]: [...fieldValue, value], tagId: parentTagId
        };
        dispatch(updateTagRequest(requestData));
    };

    /**
     * Handle Deleting associated tag in the Chip Component of the SubTable
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     * @param {*} parentTagId
     */
    const handleSubTableChipDelete = (selectedChip, fieldValue, item, key, parentTagId) => {
        const requestData = {
            ...item, [key]: fieldValue.filter((chip) => selectedChip !== chip), tagId: parentTagId
        };
        dispatch(updateTagRequest(requestData));
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDeleteDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge", params) => {
        dispatch(deleteTagRequest({ id: params.id, type: (type === "purge") }));
        dialogCancelEventHandle();
    };

    /**
     * Bind Accordian Table
     * @param {*} selectedItem
     * @param {*} rowIndex
     */
    const onClickAccordian = (selectedItem, isOpen) => {
        dispatch(toggleTag({ id: selectedItem.id, isOpen }));
    };

    /**
     * Handle Chip Redirection
     * @param {*} selectedChip
     * @param {*} fieldValue
     */
    const onHandleChipSelect = (selectedChip, fieldValue) => {
        if (fieldValue === "linked_attribute") {
            dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [selectedChip.asset_id, selectedChip.attribute_id] }));
        }
    };


    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "tags", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };


    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Prepare Filter Tags
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterTags = (data, searchFilters) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = data.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key]?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase())) {
                        return false;
                    }
                    if (typeof (item[key]) === "object") {
                        const values = item?.[key]?.map((obj) => obj?.name) || [];
                        return values.some((obj) => obj?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase()));
                    }
                }
                return true;
            });
        }
        return tagsData(filterData, usersList);
    };

    /**
     * Filter Tags using UseMemo
     */
    const filterTags = useMemo(() => prepareFilterTags(tags, searchData), [tags, searchData]);
    return (
        <Grid container className={classes.tagsPageContainer} justifyContent="space-between">
            <Grid item xs={12} className={classes.tagsTableContainer}>
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        headers={permissionHeaders(appConstants.table.TagsTabHeader, semanticsPermission)}
                        isAdd={semanticsPermission?.is_edit}
                        data={filterTags || []}
                        haveSubTable
                        subTable={{ "headers": permissionHeaders(appConstants.table.TagsSubTableHeader, semanticsPermission), "rows": "subtags" }}
                        subTableColSpan={appConstants.table.TagsTabHeader.length + 3}
                        subTableActions={['cancel', 'save', 'delete']}
                        subTableIsAddData={semanticsPermission?.is_edit}
                        onClickActions={handleTableActions}
                        onCompnentEvent={onCompnentEvent}
                        onClickAccordian={onClickAccordian}
                        onHandleChipSelect={onHandleChipSelect}
                        onSubTableActions={handleSubTableActions}
                        onSubTableCompnentEvent={onSubTableCompnentEvent}
                        onSubTableHandleChipAdd={handleSubTableChipAdd}
                        onSubTableHandleChipDelete={handleSubTableChipDelete}
                        selectComponentList={{ 'associated_tags': tags, 'users': usersList }}
                        options={appConstants.tableOptions.common}
                        title={appConstants.labels.securityTab.tags}
                        description={appConstants.labels.securityTab.tagsTabDesc}
                        height="calc(100vh - 300px)"
                        NoResultText="No Tags Found"
                        isLoading={isLoading}
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                        searchData={searchData}
                        onHandleSearchEvent={onHandleSearchEvent}
                        rowDraggable
                        handleRowDragAction={handleRowDragAction}
                        stickyHeader
                        styleType="striped"
                        handleSubRowDragAction={handleSubRowDragAction}
                    />
                </ValidatorForm>
            </Grid>
            {
                showDeleteDialog.open && (
                    <DialogComponent
                        open={showDeleteDialog.open}
                        title={showDeleteDialog.title}
                        message={showDeleteDialog.message}
                        data={showDeleteDialog.data}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle}
                    />
                )
            }
        </Grid>
    );
}

// default props
SemanticTags.defaultProps = {
    classes: {}
};

// prop types
SemanticTags.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticTagsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SemanticTags);