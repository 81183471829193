/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@mui/styles";
import { TextField, Autocomplete, Box, Avatar } from '@mui/material';
import Chip from '@mui/material/Chip';

// Import Helpers
import { stringAvatar } from '../../helpers/appHelpers';

// import Styles
import TableStyles from './style.jsx';

const AddUser = (props) => {
    /**
     * Define Props
     */
    const { available_users, data, classes, onChange, handleChipSelectionClose, inline } = props;

    return (
        <Autocomplete
            id="users-autocomplete"
            sx={{ width: 300 }}
            options={available_users}
            className={`${inline ? classes.inlineUserBox : classes.addUserBox} addUserBox`}
            autoHighlight
            multiple
            value={data}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.name} ${option.id}`}
            renderTags={
                (value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={index} label={option.name} {...getTagProps({ index })} />
                ))
            }
            onChange={(event, newValue) => onChange(newValue)}
            onClose={() => handleChipSelectionClose()}
            renderOption={
                (props, option) => (
                    <Box component="li" sx={{ '& > img': { marginRight: '8px', flexShrink: 0 } }} {...props}>
                        <Avatar {...stringAvatar(option.name, option?.avatar ?? '')} sx={{ width: 26, height: 26, marginRight: '10px', textTransform: 'uppercase' }} onClick={(event) => { event.stopPropagation(); }} />
                        {option.name}
                    </Box>
                )
            }
            renderInput={
                (params) => (
                    <TextField
                        {...params}
                        className={classes.addUserInput}
                        autoFocus
                        variant="standard"
                        size="small"
                        inputProps={
                            {
                                ...params.inputProps
                            }
                        }
                    />
                )
            }
            classes={
                {
                    tag: classes.tag,
                    option: classes.option
                }
            }
        />
    );
};

// Set props Type
AddUser.propTypes = {
    available_users: PropTypes.array,
    data: PropTypes.array,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    handleChipSelectionClose: PropTypes.func,
    inline: PropTypes.bool
};

/**
 * Set Default Values
 */
AddUser.defaultProps = {
    classes: {},
    available_users: [],
    data: [],
    onChange: () => { },
    handleChipSelectionClose: () => { },
    inline: false
};

export default withStyles(TableStyles)(AddUser);