import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import _ from 'lodash';

//  Import Styles
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Components
import { TableComponent, DialogComponent } from '../../../../../../components/index.js';
import Scheduler from '../../../../../../components/scheduler/index.jsx';
import EditMeasureDialog from '../../../../../measure/components/editDialog/index.jsx';

// Import Actions
import {
    getAssetMeasureRequest, getMeasureRunStatusSuccess, updateStandAloneMeasureRequest, updateAssetMeasureProperty, updateAssetMeasureRequest,
    deleteAssetMeasureRequest
} from '../../../../../../redux/reducer/measureReducer';
import { getDomainListRequest } from '../../../../../../redux/reducer/semanticReducer';
import { getApplicationsRequest } from '../../../../../../redux/reducer/applicationReducer';
import { getDimensionRequest } from '../../../../../../redux/reducer/dimensionReducer';
import { navigate } from '../../../../../../redux/reducer/navigationReducer';
import { updateUserPreference } from '../../../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../../../redux/reducer/userReducer';

// Import Service
import { scheduleService } from '../../../../../../redux/service/scheduleService.js';

// Import Helpers
import { orderList, checkPermission, permissionHeaders, disableFlags, getUserPreference, prepareUpdateUserPreference } from '../../../../../../helpers/appHelpers';

// Import constant
import appConstants from '../../../../../../constants/appConstants.js';
import featureConstants from '../../../../../../constants/featureConstants.js';


function StandaloneMeasure(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const { id: domain_id } = useParams();
    const searchControllerRef = useRef();
    const dispatch = useDispatch();

    /**
     * Redux Store get Domain Asset,Domains
     */
    const { assetMeasures: measureList, assetLevelLoading } = useSelector(({ measure }) => measure);
    const searchableDimensionList = useSelector(({ dimension }) => dimension.searchableDimensionList);
    const { searchableApplicationsList } = useSelector((state) => state.applications);
    const { searchableGlossaries } = useSelector((state) => state.semantic);
    const { permission, user } = useSelector((state) => state.auth);

    /**
     * Permissions
     */
    const measurePermission = checkPermission(permission, featureConstants.home.measures);
    const propertiesPermission = checkPermission(permission, featureConstants.assets.properties);
    const publishPermission = checkPermission(permission, featureConstants.assets.publish);
    const approvePermission = checkPermission(permission, featureConstants.assets.approve);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "domain_measure", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "domain_measure", "sorting");

    /**
     * Define State
     */
    const defaultSearch = {
        "name": "",
        "description": "",
        "connection": "",
        "type": "",
        "level": "measure",
        "dimension": "",
        "allow_score": "All",
        "is_drift_enabled": "All",
        "is_active": "All",
        "is_positive": "All",
        "threshold_type": "All",
        "schema": "",
        "domain": domain_id,
        "domains": "",
        "status": "All"
    };
    const [searchData, setSearchData] = useState({ ...defaultSearch });
    const [sorting, setSorting] = useState({ sortBy: "", orderBy: "desc" });
    const [openEditDialog, setOpeEditDialog] = useState(false);
    const [scheduler, setScheduler] = useState(null);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "domain_measure", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Fetches the list of Measures
     * @param {*} params
     */
    const getMeasures = (params) => {
        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };
        const requestParams = {
            search_by: {
                ...searchData,
                ...params
            },
            orderBy: "desc",
            sortBy: "default",
            offset: 0
            // limit: "all"
        };
        dispatch(getAssetMeasureRequest({ params: requestParams, token, clear: true }));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        getMeasures(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
        updatePreference({ sorting: { sortBy, orderBy } });
    };

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, item) => {
        switch (key) {
            case "name":
                const state = {
                    measure_id: item?.id ?? null,
                    name: item?.name ?? '',
                    measureName: item?.name ?? '',
                    isAssetLevel: false,
                    isMeasureLevel: true,
                    attribute_id: item?.attribute_id,
                    asset_id: item?.asset_id,
                    type: "measure",
                    showEdit: true,
                    prevUrl: true
                };
                dispatch(navigate({ path: 'measure.detail', state: state, params: [item?.id ?? null] }));
                break;
            case 'connection_name':
                dispatch(navigate({ path: 'connector.asset', state: {}, params: [item.connection_type, item.connection_id] }));
                break;
            default:
                break;
        }
    };

    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.measureDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: "",
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} type
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        dispatch(deleteAssetMeasureRequest({ id: showDialog?.data?.id, type: (type === "purge") }));
        dialogCancelEventHandle();
    };
    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (data) => {
        if (!data?.run_status || data?.run_status === 'Completed') {
            scheduleService.trigger({ "measure_id": data.id, job_type: "measure" }).then(() => {
                const measureStatus = [{ id: data.id, status: "Pending" }];
                dispatch(getMeasureRunStatusSuccess({ data: measureStatus }));
            }).catch(() => { });
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data?.connection_id || data?.connection] }));
        }
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestParams = {
            id: item.id,
            [key]: value
        };
        let changedProperties = [key];
        dispatch(updateAssetMeasureProperty(requestParams));
        if (key === "dimension") {
            value = value.length ? value[0].id : null;
            requestParams[key] = value;
        } else if (key === "upper_threshold" || key === "lower_threshold") {
            value = value !== "" ? value : 0;
            requestParams.drift_threshold = {
                upper_threshold: key === "upper_threshold" ? value : item.upper_threshold || 0,
                lower_threshold: key === "lower_threshold" ? value : item.lower_threshold || 0
            };
            changedProperties = ["drift_threshold"];
        }
        else if (key === "applications" || key === "domains") {
            delete requestParams[key];
            key = (key === "applications") ? "application" : key;
            value = value?.map((item) => item.id);
            requestParams[key] = value;
        }
        requestParams.updated_properties = [...new Set([...changedProperties])];
        dispatch(updateStandAloneMeasureRequest(requestParams));
    };


    /**
     * Handle Actions
     * @param {*} data
     * @param {*} actionName
     */
    const onClickActions = (data, actionName, event) => {
        switch (actionName) {
            case 'schedule':
                setScheduler({ open: true, anchorElement: event.target, isAsset: false, isMeasure: true, connectionId: data?.connection || data?.connection_id, measureId: data.id });
                break;
            case 'delete':
                if (measurePermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit) {
                    onDelete(data);
                }
                break;
            case 'run':
                if ((measurePermission?.is_edit || publishPermission?.is_edit || approvePermission?.is_edit)) {
                    handleJobTrigger(data);
                }
                break;
            default:
                break;
        }
    };


    /**
     * On Edit Measure
     * @param {*} params
     */
    const onEditMeasure = (params) => {
        const requestParams = {
            search_params: searchData,
            update_params: params
        };
        const changedProperties = Object.keys(params);
        const dimensionIndex = changedProperties.indexOf("dimension_id");
        if (dimensionIndex > -1) {
            changedProperties.splice(dimensionIndex, 1, "dimension");
        }
        requestParams.updated_properties = [...new Set([...changedProperties])];
        dispatch(updateAssetMeasureRequest(requestParams));
    };


    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };


    /**
     * Call Measures
     */
    useEffect(() => {
        getMeasures();
    }, []);

    useEffect(() => {
        if (tableSorting) {
            setSorting(tableSorting);
        }
    }, []);


    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Dimension List
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
        // Get Domains List
        if (!searchableGlossaries || !searchableGlossaries.length) {
            dispatch(getDomainListRequest());
        }
        // Get Applications List
        if (!searchableApplicationsList || !searchableApplicationsList.length) {
            dispatch(getApplicationsRequest());
        }
    }, [dispatch]);

    /**
     * Filter applications
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);

    /**
     * Filter domains
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    const validatingSortingData = (data) => {
        let processData = "";
        if (Number.isInteger(data) || Number.isFinite(data) || Array.isArray(data)) {
            processData = data;
        } else {
            if (data === "NA" || !data) {
                processData = -1;
            } else {
                if (Number.isFinite(Math.floor(data))) {
                    processData = Math.floor(data);
                } else {
                    processData = data.toLowerCase();
                }
            }
        }
        return processData;
    };

    /**
     * Prepare Filter
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareSorting = (data, sortBy, orderBy) => {
        let filterData = [...data];
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [(data) => validatingSortingData(data[sortBy])], [orderBy]);
        }
        return filterData;
    };
    const measures = useMemo(() => prepareSorting(measureList, sorting.sortBy, sorting.orderBy), [measureList, sorting.sortBy, sorting.orderBy]);
    const options = [{ type: "search", customFunction: null }, { type: "columns", customFunction: null }];

    /**
     * Measure Table Headers
     */
    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: false,
            width: '15%',
            minWidth: "200px",
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name',
            clickable: true,
            showAlert: true,
            alertKey: "recent_alerts_count",
            classes: "name",
            showIssues: true,
            issueKey: "issues"
        },
        {
            key: 'domains',
            name: 'Domains',
            placeholder: "Select Domain",
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: domainsList || [],
            searchKey: "domains",
            showEmptyOption: false,
            component: 'chips',
            valueKey: 'name',
            limit: 1,
            isNotEditable: true,
            isAdd: false,
            chipAddType: 'autocomplete',
            haveColor: true,
            addText: "Add Domain",
            showNA: true
        },
        {
            key: 'applications',
            name: 'Applications',
            placeholder: "Select Application",
            width: "25%",
            align: "center",
            isSearch: true,
            searchComponent: "autocomplete",
            list: applicationsList || [],
            searchKey: "applications",
            component: 'chips',
            valueKey: 'name',
            limit: 1,
            isNotEditable: true,
            isAdd: false,
            chipAddType: 'autocomplete',
            haveColor: true,
            addText: "Add Application",
            showNA: true
        },
        {
            key: 'dimension',
            name: 'Dimension',
            sorting: true,
            tooltip: false,
            width: '10%',
            component: 'chips',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'dimension',
            isNotEditable: true,
            disableInput: true,
            isAdd: false,
            chipAddType: 'autocomplete',
            addLimitCount: 1,
            valueKey: 'name',
            haveColor: true,
            downloadArrayKey: "name"
        },
        {
            key: 'status',
            name: 'Status',
            width: "10%",
            align: "center",
            sorting: true,
            component: "status",
            isSearch: true,
            searchComponent: "select",
            list: ["All", "Pending", "Ready For Review", "Verified"],
            renderList: ["Pending", "Ready For Review", "Verified"],
            searchKey: "status",
            defaultDisabled: true,
            isPermissionDisabled: true,
            isDisabled: (data, header) => disableFlags(data, header) || !measurePermission?.is_edit || !propertiesPermission?.is_edit,
            showRenderList: true
        },
        {
            key: 'score',
            name: 'Score',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: false,
            clickable: false,
            component: "score",
            allowScoreKey: "allow_score"
        },
        {
            key: 'description',
            name: 'Description',
            sorting: true,
            tooltip: true,
            width: '25%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'description',
            hideDefault: true
        },
        {
            key: 'connection_name',
            name: 'Connection',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'connection',
            clickable: true,
            hideDefault: true
        },
        {
            key: 'connection_schema',
            name: 'Schema',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'schema'
        },
        {
            key: 'connection_database',
            name: 'Database',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'database'
        },
        {
            key: 'connection_account',
            name: 'Account',
            sorting: true,
            tooltip: false,
            width: '8%',
            hideDefault: true,
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'account'
        },
        {
            key: 'alerts',
            name: 'Alerts',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            hideDefault: true
        },
        {
            key: 'issues',
            name: 'Issues',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'issues',
            hideDefault: true
        },
        {
            key: 'type',
            name: 'Type',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'type',
            hideDefault: true
        },
        {
            key: 'category',
            name: 'Category',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'category',
            hideDefault: true
        },
        {
            key: 'threshold_type',
            name: 'Threshold Type',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'select',
            searchKey: 'threshold_type',
            list: ['All', 'Auto', 'Manual'],
            hideDefault: true
        },
        { key: 'weightage', name: 'Weightage', width: "5%", sorting: true, tooltip: true, defaultValue: 100, datatype: "numeric", component: "textbox", isSearch: false, searchComponent: "text", searchKey: "weightage", hideDefault: true, validators: ['required', 'minNumber:1', 'maxNumber:100'], errorMessages: ['Weightage is required', 'Minimum Weigtage is 1', 'Maxiumum Weigtage is 100'] },
        {
            key: 'upper_threshold',
            name: 'Upper Threshold',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'textbox',
            datatype: "integer",
            isSearch: false,
            placeholder: "Upper Threshold",
            hideDefault: true
        },
        {
            key: 'lower_threshold',
            name: 'Lower Threshold',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'textbox',
            datatype: "integer",
            isSearch: false,
            placeholder: "Lower Threshold",
            hideDefault: true
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_active',
            hideDefault: true
        },
        {
            key: 'is_positive',
            name: 'Valid',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Valid', 'Invalid'],
            searchKey: 'is_positive',
            hideDefault: true
        },
        {
            key: 'allow_score',
            name: 'Scoring',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'allow_score',
            hideDefault: true
        },
        {
            key: 'is_drift_enabled',
            name: 'Monitor',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_drift_enabled',
            hideDefault: true
        },
        {
            key: 'next_run',
            name: 'Next Run',
            type: 'date',
            sorting: true,
            tooltip: true,
            width: '10%',
            isSearch: false,
            hideDefault: true
        },
        { key: 'last_runs', name: 'Last Result', width: "6%", showNA: true, component: "drift runs", hideDefault: true },
        {
            key: 'invalid_rows',
            name: 'Invalid Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'invalid_rows',
            hideDefault: true
        },
        {
            key: 'valid_rows',
            name: 'Valid Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'valid_rows',
            hideDefault: true
        },
        {
            key: 'row_count',
            name: 'Total Records',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: false,
            searchComponent: 'text',
            searchKey: 'row_count',
            hideDefault: true
        },
        {
            key: 'actions',
            name: 'Actions',
            width: '8%',
            align: 'center',
            showNA: true,
            actions: [
                { type: 'run', tooltip: true, tooltipText: 'Run Now', renderAction: (data) => data?.level === "measure", getToolTipText: (data) => { return (data?.data?.run_status && data?.data?.run_status !== 'Completed') ? data?.data?.run_status : 'Run Now'; } },
                { type: 'schedule', tooltip: true, tooltipText: 'Schedule', renderAction: (data) => (data?.level === "measure") },
                { type: 'delete', tooltip: true, tooltipText: 'Delete', renderAction: (data) => (data?.level === "measure" || (data?.level !== "measure" && data?.type === "custom")) }
            ]
        }
    ];

    return (
        <ValidatorForm onSubmit={() => null}>
            <Grid container className={classes.measureContainer}>
                <TableComponent
                    stickyHeader
                    title={`Standalone Measures (${measures.length})`}
                    styleType="striped"
                    headers={permissionHeaders(tableHeaders, measurePermission)}
                    data={measures || []}
                    sortBy={sorting?.sortBy ?? ''}
                    orderBy={sorting?.orderBy ?? 'asc'}
                    searchData={searchData}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onClickSorting={onClickSorting}
                    onCellClick={handleCellClickEvent}
                    onClickActions={onClickActions}
                    onCompnentEvent={onCompnentEvent}
                    selectComponentList={
                        {
                            dimension: searchableDimensionList,
                            applications: applicationsList,
                            domains: domainsList
                        }
                    }
                    options={options}
                    NoResultText="No Items Found"
                    isLoading={assetLevelLoading}
                    onColumnChange={(columns) => onColumnsChange(columns)}
                    userPreferenceColumns={columns || []}
                />
            </Grid>
            {
                openEditDialog &&
                <EditMeasureDialog
                    open={openEditDialog}
                    onEditMeasure={onEditMeasure}
                    onClose={() => setOpeEditDialog(false)}

                />
            }
            {
                scheduler &&
                <Scheduler scheduleProps={scheduler} onClose={() => setScheduler(null)} />
            }
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </ValidatorForm>
    );
}

// default props
StandaloneMeasure.defaultProps = {
    classes: {}
};

// prop types
StandaloneMeasure.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StandaloneMeasure);