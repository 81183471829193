import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import _ from 'lodash';

//  Import Components
import { MemoSimpleTabHeaderComponent, TableComponent } from '../../components/index.js';
import SubMeasures from './subMeasures.jsx';

//  Import Styles
import QualityStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Constants
import appConstants from '../../constants/appConstants.js';
import { getMeasureRequest, updateMeasureRequest, updateMeasureProperty } from '../../redux/reducer/baseMeasureReducer';
import { getDimensionRequest } from '../../redux/reducer/dimensionReducer';
import featureConstants from '../../constants/featureConstants.js';
import { updateUserPreference } from '../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../redux/reducer/userReducer';

// Import Helpers
import { checkPermission, disableFlags, permissionHeaders, getUserPreference, prepareUpdateUserPreference } from '../../helpers/appHelpers.js';


function Quality(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { permission, user } = useSelector((state) => state.auth);
    const qualityPermission = checkPermission(permission, featureConstants.settings.quality);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "quality", "columns");
    const tableSorting = getUserPreference(user?.user_preference ?? {}, "table", "quality", "sorting");
    const defaultSearchData = {
        "name": "",
        "description": "",
        "type": "",
        "level": "",
        "dimension": "",
        "allow_score": "All",
        "is_drift_enabled": "All",
        "is_active": "All",
        "is_positive": "All"
    };
    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: false,
            width: '15%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name',
            component: 'text_with_icon',
            // showIcon: (data) => data.type === "frequency" && data.category === "pattern",
            iconTooltip: "View Patterns"
        },
        {
            key: 'description',
            name: 'Description',
            sorting: true,
            tooltip: true,
            width: '25%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'description'
        },
        {
            key: 'level',
            name: 'Level',
            sorting: true,
            tooltip: false,
            width: '8%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'level',
            initalCaps: true
        },
        {
            key: 'dimension',
            name: 'Dimension',
            sorting: true,
            tooltip: false,
            width: '10%',
            component: 'chips',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'dimension',
            isNotEditable: false,
            disableInput: false,
            isAdd: true,
            chipAddType: 'autocomplete',
            addLimitCount: 1,
            valueKey: 'name',
            haveColor: true,
            downloadArrayKey: "name",
            showCaps: true
        },
        {
            key: 'type',
            name: 'Category',
            sorting: true,
            tooltip: false,
            width: '10%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'type',
            hideDefault: true,
            initalCaps: true
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_active',
            isDisabled: (data, header) => disableFlags(data, header)
        },
        {
            key: 'is_positive',
            name: 'Valid',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Valid', 'Invalid'],
            searchKey: 'is_positive',
            isDisabled: (data, header) => disableFlags(data, header)
        },
        {
            key: 'allow_score',
            name: 'Scoring',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'allow_score',
            isDisabled: (data, header) => disableFlags(data, header)
        },
        {
            key: 'is_drift_enabled',
            name: 'Monitoring',
            sorting: true,
            tooltip: false,
            width: '8%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'Inactive'],
            searchKey: 'is_drift_enabled',
            isDisabled: (data, header) => disableFlags(data, header)
        }
    ];

    /**
     * Redux Store to get values
     */
    const { measures, isLoading } = useSelector((state) => state.baseMeasure);
    const searchableDimensionList = useSelector((state) => state.dimension.searchableDimensionList);

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [showPatterns, setShowPatterns] = useState(false);
    const [selectedTableHeaders, setSelectedTableHeaders] = useState([...tableHeaders]);
    const [searchData, setSearchData] = useState({ ...defaultSearchData });
    const [sorting, setSorting] = useState({
        sortBy: tableSorting?.sortBy ?? "",
        orderBy: tableSorting?.orderBy ?? "asc"
    });
    const { sortBy, orderBy } = sorting;

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickActions = useCallback((data, key, event) => {
        event.stopPropagation();
        if (data && data.category !== "pattern" && key !== "pattern") {
            return;
        }
        setShowPatterns(true);
    }, []);

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "quality", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Update headers
     * @param {*} value
     */
    const updateTableColumns = (columns, tabIndex) => {
        const unScorableCategories = ["Statistics"];

        let categoryIndex = -1;
        for (const category of unScorableCategories) {
            const index = appConstants.tabs.settingQualityTabs.findIndex((item) => item === category);
            if (index === tabIndex) {
                categoryIndex = index;
                break;
            }
        }
        if (categoryIndex > -1) {
            const columnIndex = columns.findIndex((column) => column.name === "Scoring" || column.name === "Valid");
            if (columnIndex > -1) {
                columns.splice(columnIndex, 1);
            }
        }

        return columns;
    };


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = useCallback((sortBy, orderBy) => {
        setSorting({
            sortBy, orderBy
        });
        updatePreference({ sorting: { sortBy, orderBy } });
    }, [sorting]);

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const params = {
            id: item.id,
            [key]: value
        };
        dispatch(updateMeasureProperty(params));
        if (key === "dimension") {
            value = value.length ? value[0].id : null;
            params[key] = value;
        }
        dispatch(updateMeasureRequest(params));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    const updateTableHeaders = (filterType) => {
        const selectedTableHeaders = [...tableHeaders];
        if (filterType.toLowerCase() === "pattern") {
            const columnIndex = tableHeaders.findIndex((item) => item.key === "dimension");
            selectedTableHeaders.splice(columnIndex, 1);
        }
        setSelectedTableHeaders([...selectedTableHeaders]);
    };

    /**
     * Get Measures
     */
    useEffect(() => {
        const filterType = appConstants.tabs.settingQualityTabs[tabIndex];
        const requestParams = {
            type: filterType.toLowerCase(),
            list_type: "quality",
            default_type: "default"
        };
        updateTableHeaders(filterType);
        dispatch(getMeasureRequest(requestParams));
    }, [tabIndex]);

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Dimention List
        if (!searchableDimensionList || !searchableDimensionList.length) {
            dispatch(getDimensionRequest());
        }
    }, [dispatch]);

    /**
     * Prepare Filter Measures
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterMeasures = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data].map((obj) => {
            const dimension = obj.dimension || [];
            return {
                ...obj,
                is_custom: !obj.is_default,
                dimension: typeof (dimension) === "string" ? JSON.parse(dimension) : dimension
            };
        });
        const measureDropDownFilters = ["allow_score", "is_drift_enabled", "is_active", "is_positive"];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if ((measureDropDownFilters.includes(key) && searchFilters[key] !== "All") || (searchFilters[key] !== "" && !measureDropDownFilters.includes(key))) {
                filters.push(key);
            }
        }
        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    if (measureDropDownFilters.includes(key)) {
                        const value = Boolean(searchFilters[key] === "Active") || Boolean(searchFilters[key] === "Valid");
                        if (item[key] !== value) {
                            return false;
                        }
                    } else {
                        let value = item[key];
                        if (key === "dimension") {
                            value = value.length ? value[0].name : "";
                        }
                        if (!value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };


    /**
     * Filter Measures using UseMemo
     */
    const filterMeasures = useMemo(() => prepareFilterMeasures(measures, searchData, sorting.sortBy, sorting.orderBy), [measures, searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <ValidatorForm onSubmit={() => null}>
            <Grid container className={classes.qualityPageContainer}>
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        justifyContent={"flex-end"}
                        alignItems="center"
                        className={classes.tabHeaderContainer}
                    >
                        <MemoSimpleTabHeaderComponent
                            tabList={appConstants.tabs.settingQualityTabs}
                            className={classes.tabsHeader}
                            tabStyle={'blue'}
                            tabIndex={tabIndex}
                            onTabChange={(newValue) => setTabIndex(newValue)}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <TableComponent
                            stickyHeader
                            title={`Measures - ${appConstants.tabs.settingQualityTabs[tabIndex]}`}
                            description={appConstants.tabs.settingQualityTabsDesc[tabIndex]}
                            styleType="striped"
                            headers={permissionHeaders(updateTableColumns(selectedTableHeaders, tabIndex), qualityPermission)}
                            data={filterMeasures || []}
                            sortBy={sortBy}
                            orderBy={orderBy}
                            searchData={searchData}
                            onClickSorting={onClickSorting}
                            onCompnentEvent={onCompnentEvent}
                            onHandleSearchEvent={onHandleSearchEvent}
                            onClickActions={onClickActions}
                            selectComponentList={{ dimension: searchableDimensionList }}
                            options={appConstants.tableOptions.download}
                            onColumnChange={(columns) => onColumnsChange(columns)}
                            height="calc(100vh - 285px)"
                            NoResultText="No Items Found"
                            isLoading={isLoading}
                            exportParams={
                                {
                                    fileName: "export_default_measure.csv",
                                    titleCaseHeader: true,
                                    renameColumn: { "is_active": "Active", "is_positive": "Valid", "type": "category", "allow_score": "Scoring", "is_drift_enabled": "Monitor" }
                                }
                            }
                            userPreferenceColumns={columns || []}
                        />
                    </Grid>
                </Grid>
                {
                    showPatterns &&
                    <SubMeasures type="pattern" open={showPatterns} onClose={() => setShowPatterns(false)} />
                }
            </Grid>
        </ValidatorForm>
    );
}

// default props
Quality.defaultProps = {
    classes: {}
};

// prop types
Quality.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...QualityStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Quality);