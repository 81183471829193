// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import _ from 'lodash';

// Import Component
import AssetTypeIconComponent from '../../../assetTypeIcon/index.jsx';

const AssetComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, data, onHandleCellClickEvent } = props;

    return (
        <Grid container justifyContent={"space-between"} className={classes.attributeBlock} wrap="nowrap">
            <Grid item className="dflex alignCenter mr-1">
                <Grid item>
                    <AssetTypeIconComponent asset_type={data.asset_type} />
                </Grid>
                <Grid item className="ml-1 mr-3 pointer" onClick={onHandleCellClickEvent}>
                    {data.name || ''}
                </Grid>
            </Grid>
        </Grid>
    );
};

/**
 * Define Component Props
 */
AssetComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onHandleCellClickEvent: PropTypes.func
};

/**
 * Set Default Values
 */
AssetComponent.defaultProps = {
    classes: {},
    data: {},
    onHandleCellClickEvent: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default (React.memo(AssetComponent, areEqual));