// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Applications
 * @returns
 */
async function getApplications() {
  const response = await getRequest(`${urlConstants.applications.url}`);
  return response;
}

/**
 * Get Application Detail
 */
async function getApplicationById(id) {
  const response = await getRequest(`${urlConstants.applications.url}${id}/`);
  return response;
}


/**
 * Create Application
 * @param {*} params
 * @returns
 */
async function createApplication(params) {
  const response = await postRequest(`${urlConstants.applications.url}`, params);
  return response;
}

/**
 * update Application
 * @param {*} applicationId
 * @param {*} params
 * @returns
 */
async function updateApplication(applicationId, params) {
  const response = await putRequest(`${urlConstants.applications.url}${applicationId}/`, params);
  return response;
}

/**
 * Delete Application
 * @param {*} applicationId
 * @returns
 */
async function deleteApplication(applicationId, purge) {
  const response = await deleteRequest(`${urlConstants.applications.url}${applicationId}/?purge=${purge}`);
  return response;
}

/**
 * Get Application Assets
 */
async function assets(id) {
  const response = await getRequest(`${urlConstants.applications.url}${id}/${urlConstants.applications.assets}`);
  return response;
}

/**
 * Get Quality
 */
async function quality(params) {
  const response = await postRequest(`${urlConstants.applications.url}${urlConstants.applications.quality}`, params);
  return response;
}

/**
 * Get Applications Filter
 * @returns
 */
async function getApplicationsFilter() {
  const response = await getRequest(`${urlConstants.applications.filterApplications}`);
  return response;
}

/**
 * Export All Service Functions
 */
export const applicationService = {
  getApplications, getApplicationById, createApplication, deleteApplication, updateApplication, assets, quality, getApplicationsFilter
};