const style = (theme) => ({
    loadingBtn: {
'&.Mui-disabled': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`
},
'&.MuiLoadingButton-loading': {
    '& span.btnLabel': {
        display: 'none !important'
    },
    '& .MuiLoadingButton-loadingIndicator': {
        color: '#fff !important'
    }
}
    }
});
export default style;