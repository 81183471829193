// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

// Import Component
import { SearchComponent } from '../../../textBox/index.js';
import { SelectComponent, AutoCompleteComponent } from '../../../index';

//Import Styles
import style from './style.jsx';


const TableSearchComponent = (props) => {
    /**
     * Define Props
     */
    const { headers, searchData, onChange, classes, haveCheckBox, rowDraggable } = props;

    /**
     * @param {*} value
     */
    const constructAutocompleteValue = (propertyKey, value, componentType) => {
        const data = value.map((a) => a.id);
        onChange(propertyKey, data.filter((item) => item), componentType);
    };

    /**
     * Render header component based on type
     * @param {*} header
     * @returns
     */
    const renderSearchElement = (header) => {
        const propertyKey = header.searchKey;
        const componentType = header.searchComponent;
        const defaultValue = header.defaultValue || "";
        const list = header.list || [];
        const searchValue = searchData[propertyKey] || "";

        switch (componentType) {
            case 'autocomplete':
                const requiredEmptyOption = header?.showEmptyOption ?? true;
                const sValue = searchValue || [];
                let listData = [...list];
                if (requiredEmptyOption) {
                    listData = [{ 'id': '_show_blank_select_', 'name': '(Blank)' }, ...list];
                }
                let selectedValue = list.filter((item) => sValue.indexOf(item.id) >= 0);
                selectedValue = selectedValue || [];
                if (sValue.indexOf('_show_blank_select_') >= 0) {
                    selectedValue = [{ 'id': '_show_blank_select_', 'name': '(Blank)' }, ...selectedValue];
                }

                return (
                    <AutoCompleteComponent
                        variant="outlined"
                        data={listData}
                        value={selectedValue || ""}
                        selectedValue={selectedValue || []}
                        multiple
                        limitTags={1}
                        placeholder={header.placeholder || 'Search'}
                        onChange={(event, value) => constructAutocompleteValue(propertyKey, value, componentType)}
                        compareKey={'id'}
                        showTooltip
                        select_all
                        disableClearable
                    />
                );
            case 'select':
                return (
                    <SelectComponent
                        value={searchValue || defaultValue}
                        list={list}
                        onSelectChange={(value) => onChange(propertyKey, value)}
                    />
                );
            default:
                return (
                    <SearchComponent
                        searchPosition="right"
                        placeholder="Search"
                        value={searchValue}
                        onChange={(value) => onChange(propertyKey, value)}
                        delayTimeOut={500}
                    />
                );
        }
    };

    return (
        <TableRow className={classes.searchHeader}>
            {
                haveCheckBox &&
                <TableCell />
            }
            {
                rowDraggable &&
                <TableCell />
            }
            {
                headers.map((header, index) =>
                    <TableCell key={`search_cell_${index}`} className={`searchColumn ${header.sticky ? ' stickyColumn' : ''}`}>
                        {
                            header.isSearch &&
                            <Grid>
                                {renderSearchElement(header)}
                            </Grid>
                        }
                    </TableCell>
                )
            }
        </TableRow>
    );
};

/**
 * Define Component Props
 */
TableSearchComponent.propTypes = {
    headers: PropTypes.array,
    searchData: PropTypes.object,
    onChange: PropTypes.func,
    classes: PropTypes.object,
    haveCheckBox: PropTypes.bool,
    rowDraggable: PropTypes.bool
};

/**
 * Set Default Values
 */
TableSearchComponent.defaultProps = {
    headers: [],
    searchData: {},
    onChange: () => { },
    haveCheckBox: false,
    rowDraggable: false
};

export default withStyles((theme) => ({
    ...style(theme)
}))(TableSearchComponent);