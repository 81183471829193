import PropTypes from 'prop-types';
import React from 'react';
import { Grid, IconButton } from '@mui/material';

// Import Styles and Images
import { DeleteIcon } from '../../assets/svg/index.js';

// Import Components
import RuleConnectors from './components/ruleConnector/index.jsx';
import RuleAdd from './components/ruleAdd/index.jsx';

function RuleGroupComponent(props) {
    /**
     * Define Props
     */
    const { classes, data, isNotEnable, enableOrCondition, children, addRule, editRule, deleteRule, propertyPermission, isDisabled } = props;

    return (
        <Grid className={classes.ruleGroup}>
            <Grid container justifyContent={'space-between'}>
                <RuleConnectors
                    isNotEnable={isNotEnable}
                    enableOrCondition={enableOrCondition}
                    selectedCondition={data.connector}
                    id={data.id || ''}
                    editRule={editRule}
                    isDisabled={isDisabled}
                    propertyPermission={propertyPermission} />
                {
                    propertyPermission?.is_edit &&
                    <Grid className="dflex alignCenter">
                        <RuleAdd addRule={addRule} id={data.id || ''} isDisabled={!propertyPermission?.is_edit || isDisabled} />
                        <IconButton className="p0 ml-1" onClick={() => deleteRule(data.id || '')} disableRipple disabled={!propertyPermission?.is_edit || isDisabled}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                }
            </Grid>
            {children}
        </Grid>
    );
}

/**
 * Define Component Props
 */
RuleGroupComponent.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    data: PropTypes.object,
    isNotEnable: PropTypes.bool,
    enableOrCondition: PropTypes.bool,
    isDisabled: PropTypes.bool,
    addRule: PropTypes.func,
    editRule: PropTypes.func,
    deleteRule: PropTypes.func,
    propertyPermission: PropTypes.object
};

/**
 * Set Default Values
 */
RuleGroupComponent.defaultProps = {
    classes: {},
    data: {},
    isNotEnable: false,
    enableOrCondition: false,
    isDisabled: false,
    addRule: () => { },
    editRule: () => { },
    deleteRule: () => { },
    propertyPermission: {}
};

export default RuleGroupComponent;