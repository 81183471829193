import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, Tooltip, IconButton, Grid, Checkbox, Typography, Button, Avatar } from '@mui/material';
import _ from 'lodash';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { InfoOutlined, PauseCircleOutlineRounded } from '@mui/icons-material';

// Import Image
import {
    CalendarIcon, DeleteIcon, LinkIcon, ThunderIcon, MenuVerticleIcon, PlayIcon2, SettingsIcon, EyeIcon, EditIcon, SyncIcon, DBTQueryIcon, DownloadIcon, PasswordIcon, IssuesIcon2, PasswordMailIcon,
    ChevDownIcon, ChevUpIcon
} from '../../assets/svg/index';

// Import Components
import { ChipComponent, CircularScoreComponent, SelectComponent, ValueEditorComponent, SwitchComponent, DriftRunStatusComponent, StatusDropDownComponent, IssueActionComponent, DriftActionsComponent, ListUserComponent, AutoCompleteComponent, ColorPickerComponent, RecentRuns, UsersComponent } from '../index';
import AttributeComponent from './components/attribute/index.jsx';
import DepthComponent from './components/depth/index.jsx';
import AcceptRejectIcon from '../chips/components/acceptRejectIcon.jsx';
import AssetComponent from './components/asset/index.jsx';

// Import Helpers
import { numberWithCommas, convertUTCtoLocalDateTime, toRound, getDurationText } from '../../helpers/appHelpers';


const TableBodyCell = (props) => {

    /**
     * Define Props
     */
    const { classes, header, data, rowIndex, handleTableCell, parentDetail, chipClassName, onCompnentEvent, onClickActions, selectComponentList, onHandleChipAdd, onHandleChipDelete, onHandleChipSelect, onCellClick, onHandleChipAcceptActions, onAccordianOnChange } = props;

    /**
     * Handle cell click events
     * @param {*} key
     * @param {*} value
     */
    const onHandleCellClickEvent = (key, event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (!(header && event)) {
            return;
        }

        let componentType = header.component;
        componentType = header.getComponentType ? header.getComponentType(data, header) : componentType;
        if (componentType === "button") {
            if (header?.onClick) {
                header?.onClick(event, header, data, rowIndex);
            }
            return;
        }

        if (header.isAccordian) {
            handleTableCell(event, header);
        } else if (header.customFunction) {
            header.customFunction(data);
        } else if (header.clickable) {
            onCellClick(key, data, event, parentDetail);
        }
    };

    /**
     * Handle Component Events
     * @param {*} key
     * @param {*} value
     */
    const onHandleComponentEvent = (key, value, item, event) => {
        if (!item) {
            item = data;
        }
        if (event && typeof (event) !== "string") {
            event.stopPropagation();
            event.preventDefault();
        }
        onCompnentEvent(key, value, item, event, parentDetail);
    };

    /**
     * Handle Delete Event
     */
    const handleActionsClick = (data, actionName, event, value) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        onClickActions(data, actionName, event, parentDetail, value);
    };

    /**
     * Toggle Accordian
     * @param {*} event
     * @param {*} toggle
     */
    const toggleAccordian = (event, toggle) => {
        event.preventDefault();
        if (toggle) {
            onAccordianOnChange(!data.isOpen, data, data.type);
        }
    };

    /**
     * Bind Action for Cell
     * @param {*} actions
     * @returns
     */
    const bindCellActions = (actions = []) => {
        const getToolTipText = (item) => {
            if (item.type === 'drift_mark_resolved') {
                if (data && data.status === 'Ok') {
                    return 'Mark as Outlier';
                }
                return 'Mark as Normal';
            }
            if (item.getToolTipText) {
                return item.getToolTipText({ data, header: item });
            }
            return item.tooltipText ? item.tooltipText : item.type;
        };
        let actionComponents = (
            actions && actions.map((item, index) => {
                let actionIcon = null;
                switch (item.type) {
                    case 'schedule':
                        let showScheduleAction = item.conditionalRenderColumn ? data[item.conditionalRenderColumn] : true;
                        if (item.renderAction) {
                            showScheduleAction = item.renderAction(data);
                        }
                        actionIcon = showScheduleAction ? <CalendarIcon key={`${item.type}-${index}`} active={data?.is_schedule} /> : null;
                        break;
                    case 'delete':
                        let showDeleteAction = item.conditionalRenderColumn ? data[item.conditionalRenderColumn] : true;
                        if (item.renderAction) {
                            showDeleteAction = item.renderAction(data);
                        }
                        actionIcon = showDeleteAction ? <DeleteIcon key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'link':
                        actionIcon = <LinkIcon key={`${item.type}-${index}`} />;
                        if (data && data.isTermApproval && data.term_id && !data.term_approval_id) {
                            return (
                                <AcceptRejectIcon className={classes.termApprovalIconContainer} key={`${item.type}-${index}`} onHandleAction={(status) => handleActionsClick(data, "accept", null, status)} />
                            );
                        }
                        break;
                    case 'more':
                        actionIcon = <MenuVerticleIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'drift_mark_resolved':
                        actionIcon = <DriftActionsComponent key={`${item.type}-${index}`} status={data?.status} markedValue={data?.marked_as} handleEvent={(event) => handleActionsClick(data, item.type, event)} />;
                        return actionIcon;
                    case 'run':
                        if (item.renderAction && !item.renderAction(data)) {
                            return null;
                        }
                        actionIcon = <PlayIcon2 key={`${item.type}-${index}`} />;
                        if (data?.run_status === "Pending" || data?.run_status === "Running" || data?.run_status === "UpForRetry") {
                            item.tooltipText = data?.run_status || item.tooltipText;
                            actionIcon = <PauseCircleOutlineRounded className={classes.errorlogIcon} />;
                        }
                        break;
                    case 'view':
                        actionIcon = <EyeIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'error_log':
                        const hasError = ((data?.status === "Failed" || data?.status === "UpForRetry") && data?.error_message) || (item.showError && item.showError(data));
                        actionIcon = hasError ? <InfoOutlined className={classes.errorlogIcon} key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'edit':
                        let renderAction = true;
                        if (item.renderAction) {
                            renderAction = item.renderAction(data);
                        }
                        actionIcon = renderAction ? <EditIcon className="EditIcon" key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'convert':
                        actionIcon = <SyncIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'query_view':
                        actionIcon = <DBTQueryIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'download':
                        let showDownloadIcon = true;
                        if (item.renderAction) {
                            showDownloadIcon = item.renderAction(data);
                        }
                        actionIcon = showDownloadIcon ? <DownloadIcon key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'password_reset':
                        let showResetIcon = true;
                        if (item.renderAction) {
                            showResetIcon = item.renderAction(data);
                        }
                        actionIcon = showResetIcon ? <PasswordIcon key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'password_reset_email':
                        let showResetEmailIcon = true;
                        if (item.renderAction) {
                            showResetEmailIcon = item.renderAction(data);
                        }
                        actionIcon = showResetEmailIcon ? <PasswordMailIcon key={`${item.type}-${index}`} /> : null;
                        break;
                    case 'resend_invite_email':
                        let showResendEmailIcon = true;
                        if (item.renderAction) {
                            showResendEmailIcon = item.renderAction(data);
                        }
                        actionIcon = showResendEmailIcon ? <PasswordMailIcon key={`${item.type}-${index}`} /> : null;
                        break;
                    default:
                        actionIcon = null;
                        break;
                }
                if (!actionIcon) {
                    return null;
                }
                let isDisabled = false;
                if (item?.isDisabled) {
                    isDisabled = item.isDisabled(data);
                }
                const action = (
                    <IconButton
                        key={`icon-${index}`}
                        aria-label="Link"
                        color="inherit"
                        className={'p5'}
                        disabled={isDisabled}
                        onClick={(event) => handleActionsClick(data, item.type, event)}
                    >
                        {actionIcon}
                    </IconButton>
                );
                return item.tooltip ? (
                    <Tooltip key={`tooltip-${index}`} title={getToolTipText(item)} arrow>
                        {action}
                    </Tooltip>
                ) : action;
            })
        );
        actionComponents = actionComponents.filter((component) => component);
        if (header.showNA && actionComponents?.length === 0) {
            return (
                <Typography>
                    {"NA"}
                </Typography>
            );
        }
        return actionComponents;
    };

    /**
     * Format Cell Value
     * @param {*} data
     * @param {*} header
     * @returns
     */
    const formatCellData = (data, header) => {
        let value = "";
        switch (header.type) {
            case 'number':
                value = numberWithCommas(data[header.key]);
                break;
            case 'date':
                value = convertUTCtoLocalDateTime(data[header.key], header?.dateOnly ?? false);
                break;
            case 'duration':
                value = getDurationText(data[header.key]);
                break;
            case 'boolean':
                value = data[header.key]?.toString();
                break;
            case 'float':
            case 'score':
                value = data[header.key] ? toRound(data[header.key]) : 0;
                if (header.showNAmeasure && header.renderAction) {
                    const showNA = header.renderAction(data);
                    value = showNA ? "NA" : value;
                }
                break;
            default:
                value = data[header.key];
                break;
        }
        if (value) {
            if (header.formatValue) {
                value = header.formatValue(data);
            }
            value = `${value}${header.endAdornment ? `${header.endAdornment}` : ''}`;
        } else {
            if (header.showNA) {
                value = "NA";
            }
        }
        return value;
    };

    /*
     * Returns the tooltip text for cell values
     * @param {*} data
     * @param {*} header
     * @returns
     */
    const getCellTooltipText = (data, header) => {
        const tooltipText = (_.isString(data[header.key]) || _.isNumber(data[header.key])) ? data[header.key] : '';
        if (header.getTooltipText) {
            return header.getTooltipText(data);
        }
        if (header.showNAmeasure && header.renderAction) {
            const showNA = header.renderAction(data);
            return showNA ? "NA" : tooltipText;
        }
        return tooltipText;
    };

    /**
     * Bind Cell Component
     * @returns
     */
    const bindCellComponent = () => {
        let cellComponent = null;
        let isDisabled = header.disableKey ? data[header.disableKey] : false;
        if (header.isDisabled) {
            isDisabled = header.isDisabled(data, header);
        }
        if (header.isPermissionDisabled) {
            isDisabled = true;
        }
        if (header.datatypeRestrict) {
            const dataValue = data && header.dataTypeKey in data ? data[header.dataTypeKey] : "";
            if (dataValue !== header.allowDatatype) {
                return (
                    <Typography>
                        NA
                    </Typography>
                );
            }
        }
        let componentType = header.component;
        componentType = header.getComponentType ? header.getComponentType(data, header) : componentType;
        switch (componentType) {
            case 'list':
                if (data[header.key]) {
                    cellComponent = <SelectComponent className={`${classes.outlineNone} outlineNone noPaddingLeft`} isDisabled={isDisabled} value={data[header.componentKey]} onSelectChange={(value) => onHandleComponentEvent(header.componentKey, value, data)} list={data[header.key] && data[header.key].split(/,\s*/) || []} />;
                }
                break;
            case 'depth':
                cellComponent = <DepthComponent seletedValue={data[header.componentKey]} onDepthChage={(value) => onHandleComponentEvent(header.componentKey, value, data)} />;
                break;
            case 'asset':
                cellComponent = <AssetComponent data={data || {}} onHandleCellClickEvent={onHandleCellClickEvent} />;
                break;
            case 'attribute':
                cellComponent = <AttributeComponent data={data || {}} clickable={header.clickable} hidePrimaryKey={header.hidePrimaryKey || false} onHandleCellClickEvent={onHandleCellClickEvent} />;
                break;
            case 'switch':
                cellComponent = <SwitchComponent size="small" disabled={isDisabled || (header.disabledColumn && header.disabledColumn(data))} checked={data[header.key] || false} handleChange={(value) => onHandleComponentEvent(header.key, value)} />;
                break;
            case 'rulePassed':
                cellComponent = <ChipComponent chipClassName={chipClassName} chipClass={`numberChip ${header.className}`} data={[data[header.key] || 0] || []} limit={header.limit || 1} />;
                break;
            case 'alert':
                if (data[header.key] && Boolean(data[header.key])) {
                    cellComponent = <ThunderIcon />;
                }
                break;
            case 'chips':
                let listData = selectComponentList[header.key] || [];
                if (header.listInputColumn) {
                    listData = data[header.listInputColumn] || [];
                }
                let addType = header.chipAddType || "text";
                if (header?.getAddType) {
                    addType = header?.getAddType(data);
                }
                listData = listData || [];
                addType = addType || "text";

                cellComponent = <ChipComponent chipClass={`${header.className}`}
                    data={data[header.key] ? data[header.key] : []}
                    labelKey={header.valueKey || ""}
                    limit={header.limit || 1}
                    editable={!header.isNotEditable && !header.isPermissionDisabled && !(header.disabledColumn && header.disabledColumn(data))}
                    onChipAdd={(value) => onHandleChipAdd(value, data[header.key], data, header.key, parentDetail)}
                    onChipRemove={(selectedChip) => onHandleChipDelete(selectedChip, data[header.key], data, header.key, parentDetail)}
                    onChipSelect={(selectedChip, event) => onHandleChipSelect(selectedChip, header.key, data, event)}
                    add={!header.isPermissionDisabled && (header.addLimitCount && header.isAdd ? header.addLimitCount !== data[header.key]?.length : header.isAdd) && !(header.disabledColumn && header.disabledColumn(data))}
                    disableInput={header.disableInput || (header.isDisabled && header.isDisabled(data, header))}
                    availableList={header.getListInputData ? header.getListInputData(data) : listData}
                    addType={addType}
                    showNA={header.showNA}
                    handleChipEvent={(value, event) => onHandleComponentEvent(header.key, value, data, event)}
                    onHandleAcceptAction={(status, item) => onHandleChipAcceptActions(status, item, data)}
                    className={header.className}
                    haveColor={header.haveColor}
                    addLimit={header.addLimitCount || null}
                    addText={header.addText || null}
                    isChipDelete={header.chipDelete}
                    enableLink={header.enableLink}
                    acceptActions={header.enableAcceptActions && header.enableAcceptActions(data)}
                    noId={Boolean(header?.chipProps?.noId || false)}
                />;
                if (header.showNA && header.renderComponent && !header.renderComponent(data)) {
                    cellComponent = null;
                }
                break;
            case 'subtagchips':
                const subChips = [...selectComponentList[header.key]];
                const index = subChips.findIndex((data) => data.id === parentDetail);
                if (index >= 0) {
                    subChips.splice(index, 1);
                }
                cellComponent = <ChipComponent chipClass={`${header.className}`}
                    data={data[header.key] ? data[header.key] : []}
                    labelKey={header.valueKey || ""}
                    limit={header.limit || 1}
                    editable={!header.isPermissionDisabled && !header.isNotEditable}
                    onChipAdd={(value) => onHandleChipAdd(value, data[header.key], data, header.key, parentDetail)}
                    onChipRemove={(selectedChip) => onHandleChipDelete(selectedChip, data[header.key], data, header.key, parentDetail)}
                    onChipSelect={(selectedChip, event) => onHandleChipSelect(selectedChip, header.key, data, event)}
                    add={!header.isPermissionDisabled && (header.addLimitCount && header.isAdd ? header.addLimitCount !== data[header.key]?.length : header.isAdd)}
                    disableInput={header.disableInput}
                    availableList={subChips || []}
                    addType={header.chipAddType || "text"}
                    handleChipEvent={(value, event) => onHandleComponentEvent(header.key, value, data, event)}
                    className={header.className}
                    haveColor={header.haveColor}
                    addLimit={header.addLimitCount || null}
                    isChipDelete={header.chipDelete}
                    placeholder={header.placeholder}
                />;
                break;
            case 'score':
                cellComponent = <CircularScoreComponent value={data[header.key] || 0} showPercentage noLink showValue />;
                if (header.allowScoreKey && !data[header.allowScoreKey]) {
                    cellComponent = (
                        <Typography variant="h7" className={classes.scoreText}>
                            {"NA"}
                        </Typography>
                    );
                }
                break;
            case 'textbox':
                cellComponent = <ValueEditorComponent
                    variant="standard"
                    label={header.componentLabel}
                    value={data[header.key] || header.defaultValue || ""}
                    type={header.datatype || data[header.componentKey] || "integer"}
                    onChange={(event) => onHandleComponentEvent(header.key, typeof (event) === "string" ? event : event.target.value, data, event)}
                    noOutline={header.inlineEdit}
                    validators={header.validators && header.validators.length > 0 ? header.validators : []}
                    errorMessages={header.errorMessages && header.errorMessages.length > 0 ? header.errorMessages : []}
                    placeholder={header.placeholder}
                    renderOnBlur={header.renderOnBlur || false}
                    applyDisableCss={header.applyDisableCss || false}
                    disabled={header.isPermissionDisabled || isDisabled || (header.disabledColumn && header.disabledColumn(data))} />;
                if (header.isVisible && !header.isVisible(data)) {
                    cellComponent = null;
                }
                break;
            case 'range':
                const min_key = `min_${header.label.toLowerCase()}`;
                const max_key = `max_${header.label.toLowerCase()}`;
                let datatype = header.datatype;
                if (header.getDataType) {
                    datatype = header.getDataType(data);
                }
                cellComponent = (
                    <Grid item className={classes.rangeFields}>
                        <ValueEditorComponent placeholder={"Min"}
                            value={data[min_key]}
                            style={{ width: header.inputWidth ? header.inputWidth : null }}
                            onChange={(event) => onHandleComponentEvent(min_key, typeof (event) === "string" ? event : event.target.value, data, event)}
                            type={datatype || "numeric"}
                            size="small"
                            fullWidth={false}
                            disabled={header.isDisabled(data, header)}
                        />
                        {' '}
                        <span className="rangeHypen">
                            -
                        </span>
                        <ValueEditorComponent placeholder={"Max"}
                            value={data[max_key]}
                            onChange={(event) => onHandleComponentEvent(max_key, typeof (event) === "string" ? event : event.target.value, data, event)}
                            type={datatype || "numeric"}
                            size="small"
                            fullWidth={false}
                            style={{ width: header.inputWidth ? header.inputWidth : null }}
                            disabled={header.isDisabled(data, header)} />
                    </Grid>
                );
                break;
            case 'checkbox':
                cellComponent = <Checkbox disabled={isDisabled} checked={data[header.key] || false} onClick={(event) => onHandleComponentEvent(header.key, !data[header.key], null, event)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />;
                break;
            case 'color':
                cellComponent = <ColorPickerComponent value={data[header.key]} disabled={header.isPermissionDisabled} onChange={(value) => onHandleComponentEvent(header.key, value)} />;
                break;
            case 'select':
                cellComponent = <SelectComponent className={`${classes.outlineNone} outlineNone ${header?.className ?? null}`} value={data[header.key]} onSelectChange={(value) => onHandleComponentEvent(header.key, value, data)} list={selectComponentList[header.key] || []} applyDisableArrow={header.applyDisableArrow || false} propertyName={header.componentKey} displayPropertyName={header.selectComponentKey} isDisabled={header.isPermissionDisabled || (header.disabledColumn && header.disabledColumn(data)) || (header.isDisabled && header.isDisabled(data, header))} />;
                if (header.isVisible && !header.isVisible(data)) {
                    cellComponent = null;
                }
                break;
            case 'drift runs':
                cellComponent = <DriftRunStatusComponent data={data[header.key] && typeof (data[header.key]) === "string" ? JSON.parse(data[header.key]) : data[header.key] || []} />;
                break;
            case 'status':
                cellComponent = <StatusDropDownComponent value={data[header.key]} disable={header.disableEvent} noBackground={header.noBackground} onChange={(value) => onHandleComponentEvent(header.key, value)} dropdownValue={header.showRenderList ? header.renderList : header.list} disabled={isDisabled || header.isPermissionDisabled} />;
                break;
            case 'issues':
                cellComponent = <IssueActionComponent issueId={data[header.key]} driftId={data.id} isDisabled={header.isPermissionDisabled} issueNumber={data.issue_number} status={data?.issue_status ?? ""} onActionPerform={(value) => onHandleComponentEvent(header.key, value)} />;
                break;
            case 'alert_status':
                cellComponent = <StatusDropDownComponent value={data[header.key]}
                    onChange={(value) => onHandleComponentEvent(header.key, value)}
                    dropdownValue={header.list}
                    noBackground
                    priority
                    disabled={header.isPermissionDisabled}
                />;
                break;
            case 'user':
                cellComponent = <ListUserComponent user={data[header.key]} showName={header.showUserName} />;
                break;
            case 'users':
                cellComponent = <ListUserComponent users={data[header.key] || []} limit={header.limit} />;
                break;
            case 'add_user':
                cellComponent = <UsersComponent list={selectComponentList[header.key]}
                    data={data[header.key] || []}
                    isSteward={false}
                    // disabled={!isEdited}
                    inline
                    isTableComponent
                    handleStewardUser={(value) => onCompnentEvent(header.key, value, data)}
                />;
                break;
            case 'text_with_icon':
                cellComponent = (
                    <Grid className={classes.textWithIcon}>
                        <Typography>
                            {formatCellData(data, header)}
                        </Typography>
                        {
                            header.showIcon && header.showIcon(data, header) &&
                            (
                                <Tooltip title={header.iconTooltip || ''} arrow>
                                    <IconButton onClick={() => handleActionsClick(data, "pattern", event)}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    </Grid>
                );
                break;
            case 'text_with_image':
                cellComponent = (
                    <Grid className={classes.textWithImage}>
                        {
                            header.withImage && data[header.img_key] &&
                            (
                                <IconButton disabled>
                                    <Avatar
                                        src={data[header.img_key]}
                                        sx={{ width: 21, height: 21 }}
                                    />
                                </IconButton>
                            )
                        }
                        <Typography>
                            {formatCellData(data, header)}
                        </Typography>
                    </Grid>
                );
                break;
            case 'html_text':
                cellComponent = (
                    <Typography>
                        {data[header.key]}
                    </Typography>
                );
                break;
            case 'autocomplete':
                cellComponent =
                    <AutoCompleteComponent
                        noOutline
                        selectedValue={data?.[header.key] || ""}
                        data={selectComponentList[header.key] || []}
                        fullWidth
                        variant="standard"
                        onChange={
                            (event, newValue) => {
                                let value = newValue;
                                if (value) {
                                    value = newValue[header.componentKey] || newValue[header.key] || newValue;
                                }
                                onHandleComponentEvent(header.key, value);
                            }
                        }
                        placeholder={header.placeholder}
                        create={header.createable}
                        customOption
                        labelKey={header.componentKey}
                        disabled={header.isPermissionDisabled}
                    />;
                break;
            case 'recent_runs':
                cellComponent = <RecentRuns data={data[header.key] && typeof (data[header.key]) === "string" ? JSON.parse(data[header.key]) : data[header.key]} restData={data} />;
                break;
            case 'button':
                cellComponent = (
                    <Button
                        className={header.className || ""}
                        variant="outlined"
                        size="small"
                        style={{ width: header.isFullWidth ? `100%` : null }}
                        onClick={(event) => onHandleCellClickEvent(header.key, event)}
                        disabled={(header.isDisabled && header.isDisabled(data, header))}
                    >
                        {header.showValue && data && header.key in data && data[header.key] ? data[header.key] : header.label}
                    </Button>
                );
                if (header.isVisible && !header.isVisible(data)) {
                    cellComponent = null;
                }
                break;
            case 'accordian':
                cellComponent = (
                    <IconButton
                        key={`icon-up-${data.id}`}
                        aria-label={data.isOpen ? "Up" : "Down"}
                        color="inherit"
                        className={'p-1 collapseIcon'}
                        onClick={(event) => toggleAccordian(event, true)}
                    >
                        {
                            data.isOpen ? <ChevUpIcon key={`up-${data.id}`} />
                                :
                                <ChevDownIcon key={`down-${data.id}`} />
                        }
                    </IconButton>
                );
                if (header.conditionalRender && !header.renderComponent(data)) {
                    cellComponent = null;
                }
                break;
            default:
                cellComponent = (
                    <Grid className={classNames(classes.textContainer, header.clickable ? 'clickableItem' : '')} style={{ cursor: header.clickable ? "pointer" : "" }}>
                        {
                            (header.showTypeLabel && header.typeLabelKey && data[header.typeLabelKey])
                                ?
                                <Typography className={classes.typeLabel} style={{ backgroundColor: header.colorKey && data[header.colorKey] ? data[header.colorKey] : null }}>
                                    {data[header.typeLabelKey][0]}
                                </Typography>
                                : null
                        }
                        {formatCellData(data, header)}
                        {
                            (header.showAlert && header.alertKey && data[header.alertKey] > 0)
                                ?
                                <Grid className="thunderIcon" onClick={() => { }}>
                                    <ThunderIcon />
                                </Grid>
                                : null
                        }
                        {
                            (header.showIssues && header.issueKey && data[header.issueKey] > 0)
                                ?
                                <Grid className="issueIcon" onClick={() => { }}>
                                    <IssuesIcon2 />
                                </Grid>
                                : null
                        }
                    </Grid>
                );
                break;
        }
        if (!cellComponent && !header.hideNA) {
            cellComponent = (
                <Typography>
                    {"NA"}
                </Typography>
            );
        }
        return header.tooltip ? (
            <Grid container>
                <Tooltip title={getCellTooltipText(data, header)} arrow style={{ cursor: header.clickable ? "pointer" : "" }}>
                    {cellComponent}
                </Tooltip>
            </Grid>
        ) : cellComponent;
    };

    const bindTableCell = () => {
        return header.key === "actions" && header.actions ? bindCellActions(header.actions) : bindCellComponent();
    };

    return (
        <TableCell className={classNames(header.sticky ? classes.stickyColumn : null, header.classes ?? null)} onClick={(event) => !header.disable_link && (!header.isPermissionDisabled || header.isViewLink) && onHandleCellClickEvent(header.key, event)} style={{ minWidth: header.minWidth || null, width: header.width || null, textAlign: header.align || 'left' }}>
            <Grid className={`dflex tableCell ${header.removeCasing ? "removeCaseTransform" : ""} ${header.align && "justifyCenter"} ${header.showCaps ? "showCaps" : ""} ${header.initalCaps ? "initalCaps" : ""}`}>
                {bindTableCell()}
            </Grid>
        </TableCell>
    );
};


/**
 * Set Component Props
 */
TableBodyCell.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    rowIndex: PropTypes.number,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    onHandleChipAcceptActions: PropTypes.func,
    onClickActions: PropTypes.func,
    selectComponentList: PropTypes.object,
    header: PropTypes.object,
    onCompnentEvent: PropTypes.func,
    handleTableCell: PropTypes.func,
    onCellClick: PropTypes.func,
    chipClassName: PropTypes.string,
    parentDetail: PropTypes.string,
    onAccordianOnChange: PropTypes.func
};


/**
 * Set Default Values
 */
TableBodyCell.defaultProps = {
    classes: {},
    data: {},
    rowIndex: 0,
    parentDetail: "",
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    onHandleChipAcceptActions: () => { },
    onClickActions: () => { },
    selectComponentList: {},
    header: {},
    onCompnentEvent: () => { },
    handleTableCell: () => { },
    onCellClick: () => { },
    chipClassName: "",
    onAccordianOnChange: () => { }
};

export default TableBodyCell;