import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment-timezone';
import classNames from 'classnames';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

//  Import Components
import { Grid, Typography, Button, ButtonGroup, Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

//  Import Styles
import ReportStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Components
import { AutoCompleteComponent, ChipComponent, DateTimePickerComponent, NumberInputComponent, SelectComponent } from '../../../components/index.js';

// Import Constants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';

// Import Redux
import { getTargetAssetRequest, getTargetMeasureRequest } from '../../../redux/reducer/scheduleReducer';
import { connectionListRequest } from '../../../redux/reducer/connectionReducer.js';

function Scheduler(props) {
    /**
     * Define Props
     */
    const { classes, data, disabled, isEventSchedule, updateScheduler, selectedMeasureIdString } = props;
    const dispatch = useDispatch();

    /**
     * Handle Redux
     */
    const { list: connection_list } = useSelector((state) => state.connection, shallowEqual);
    const { target_asset_list, target_measure_list, select_asset_id, select_measure_id } = useSelector((state) => state.schedule, shallowEqual);


    /**
     * Set Default Variables
     */
    const timeZones = [
        { name: moment.tz.guess() },
        ...appConstants.schedule.TimeZones
    ];
    const [days] = useState([...Array.from({ length: 31 }, (i, index) => (index + 1).toString())]);
    const scheduleTypes = [...appConstants.schedule.scheduleTypes];

    /**
     * Define State
     */
    const [schedule, setSchedule] = useState({ ...data });

    /**
     * Define Use Effects
     */
    useEffect(() => {
        if (connection_list.length <= 0) {
            dispatch(connectionListRequest());
        }
        if (target_asset_list.length <= 0) {
            dispatch(getTargetAssetRequest());
        }
        if (target_asset_list.length <= 0) {
            dispatch(getTargetMeasureRequest());
        }
    }, []);

    /**
     * Get configurations for each schedule type
     * @param {*} scheduleType the schedule type
     * @returns schedule configuration
     */
    const getConfiguration = (scheduleType) => {
        let configuration = {};
        switch (scheduleType) {
            case appConstants.schedule.scheduleTypes[2].name:
                configuration = {
                    time: ''
                };
                break;
            case appConstants.schedule.scheduleTypes[3].name:
                configuration = {
                    weekdays: [0],
                    time: ''
                };
                break;
            case appConstants.schedule.scheduleTypes[4].name:
                configuration = {
                    dates: ['1'],
                    time: ''
                };
                break;
            default:
                configuration = {};
                break;
        }
        return configuration;
    };

    /**
     * Changes the schedule properties
     */
    const changeSchedule = useCallback((property, value) => {
        let skipPropertyUpdate = false;
        if (property === 'type') {
            schedule.configuration = getConfiguration(value);
        }
        else if (property === 'weekdays') {
            skipPropertyUpdate = true;
            const weekdays = schedule.configuration?.weekdays?.length > 0 ? JSON.parse(JSON.stringify(schedule.configuration?.weekdays)) : [];
            const weekdayIndex = weekdays.indexOf(value);
            if (weekdayIndex > -1) {
                weekdays.splice(weekdayIndex, 1);
            } else {
                weekdays.push(value);
            }
            schedule.configuration = { ...schedule.configuration, weekdays };
        }
        else if (property === 'time') {
            schedule.configuration.time = value;
            skipPropertyUpdate = true;
        } else if (property === 'dates') {
            skipPropertyUpdate = true;
            const dates = schedule.configuration?.dates?.length > 0 ? JSON.parse(JSON.stringify(schedule.configuration?.dates)) : [];
            const dateIndex = dates.indexOf(value);
            if (dateIndex > -1) {
                dates.splice(dateIndex, 1);
            } else {
                dates.push(value);
            }
            schedule.configuration = { ...schedule.configuration, dates };
        }
        if (!skipPropertyUpdate) {
            schedule[property] = value;
        }
        setSchedule(JSON.parse(JSON.stringify(schedule)));
        updateScheduler(schedule);
    }, [schedule]);

    /**
     * Handle Schedule Time Chip Add
     * @param {*} time
     */
    const onHandleChipAdd = (time) => {
        const tempsetSchedule = [...schedule.multiTime];
        if (!tempsetSchedule.includes(time)) {
            tempsetSchedule.push(time);
            setSchedule({
                ...schedule,
                multiTime: tempsetSchedule
            });
            updateScheduler({
                ...schedule,
                multiTime: tempsetSchedule
            });
        }
    };

    /**
     * Handle Schedule Time Chip Remove
     * @param {*} time
     */
    const onHandleChipRemove = (time) => {
        setSchedule({
            ...schedule,
            multiTime: time
        });
        updateScheduler({
            ...schedule,
            multiTime: time
        });
    };


    const filteredTargetAsset = target_asset_list.filter((asset) => asset.id !== select_asset_id).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });
    const filteredTargetMeasure = () => {
        if (selectedMeasureIdString) {
            return target_measure_list.filter((measure) => !selectedMeasureIdString.includes(measure.id)).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });
        }
            return target_measure_list.filter((measure) => measure.id !== select_measure_id).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });

    };

    /**
     * Get Selected Asset / Measure
     * @param {*} type
     * @param {*} value
     * @returns
     */
    const get_selected_target = (type, value) => {
        let s_value = '';
        if (typeof value === 'string') {
            if (type === 'asset') {
                s_value = target_asset_list.filter((asset) => asset.id === value).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });
            } else {
                s_value = target_measure_list.filter((asset) => asset.id === value).map((item) => { return { id: item.id, name: `${item.connection__name}.${item.name}` }; });
            }
            if (s_value.length) {
                return s_value[0] || '';
            }
        }
        return value || '';
    };

    return (
        <Grid xs={12} className={classes.scheduleContainer}>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={'mt-2'}
            >
                <Grid item xs={3} className="dflex alignCenter">
                    <Typography variant="body1" className={'fw-500'}>
                        Start Date
                    </Typography>
                </Grid>
                <Grid item xs={9} className={classes.inputStyling}>
                    <DateTimePickerComponent
                        autoOk
                        variant="standard"
                        color="secondary"
                        value={schedule.start_date ?? ''}
                        minDate={new Date()}
                        onChange={
                            (date) =>
                                changeSchedule('start_date', date)
                        }
                        validators={['required']}
                        errorMessages={[setRequiredErrorMessage('Start Date')]}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                className={`${classes.repeatSection} mt-2`}
                sx={{ marginTop: '20px !important' }}
                alignItems="center"
            >
                <Grid item xs={3}>
                    <Typography variant="body1" className={'fw-500'}>
                        Repeat Every
                    </Typography>
                </Grid>
                <Grid item xs={9} className="">
                    <Grid className="dflex alignCenter">
                        <Grid item className="numberSection mr-2">
                            <NumberInputComponent
                                name="duration"
                                noOutline
                                value={schedule?.duration || ''}
                                onChange={
                                    (value) =>
                                        changeSchedule('duration', value)
                                }
                                disabled={disabled || schedule?.is_event_schedule}
                                retrictZero
                                valOnChange
                            />
                        </Grid>
                        <Grid item className="selectDays">
                            <SelectComponent
                                name="type"
                                noOutline
                                list={scheduleTypes}
                                propertyName={'name'}
                                value={schedule?.type || ''}
                                onSelectChange={
                                    (value) =>
                                        changeSchedule('type', value)
                                }
                                isDisabled={disabled || schedule?.is_event_schedule}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            schedule.type !==
                            appConstants.schedule.scheduleTypes[0]
                                .name &&
                            schedule.type !==
                            appConstants.schedule.scheduleTypes[1]
                                .name &&
                            schedule.type !==
                            appConstants.schedule.scheduleTypes[2]
                                .name && (
                                <Grid
                                    className={`${classes.schedulerSection} mt-3`}
                                >
                                    <Grid
                                        className={classes.inputStyling}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="baseline"
                                            justify="center"
                                            wrap="nowrap"
                                        >
                                            {
                                                appConstants.schedule
                                                    .scheduleTypes[3]
                                                    .name ===
                                                schedule.type && (
                                                    <Grid
                                                        item
                                                        className={classes.sheduleDays}
                                                    >
                                                        <ButtonGroup className="mb5">
                                                            {
                                                                appConstants.schedule.weekDays.map(
                                                                    (
                                                                        weekday,
                                                                        index
                                                                    ) => (
                                                                        <Button
                                                                            disableElevation
                                                                            onClick={
                                                                                () =>
                                                                                    changeSchedule(
                                                                                        'weekdays',
                                                                                        weekday.indexValue
                                                                                    )
                                                                            }
                                                                            variant="contained"
                                                                            key={`weekday_${index}`}
                                                                            className={
                                                                                classNames(
                                                                                    classes.dayBtn,
                                                                                    schedule.configuration?.weekdays?.indexOf(
                                                                                        weekday.indexValue
                                                                                    ) !==
                                                                                        -1
                                                                                        ? 'active'
                                                                                        : ''
                                                                                )
                                                                            }
                                                                            disabled={schedule?.is_event_schedule}
                                                                        >
                                                                            {
                                                                                weekday
                                                                                    .name[0]
                                                                            }
                                                                        </Button>
                                                                    )
                                                                )
                                                            }
                                                        </ButtonGroup>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                appConstants.schedule
                                                    .scheduleTypes[4]
                                                    .name ===
                                                schedule.type && (
                                                    <Grid
                                                        item
                                                        className="mr-2 dflex alignCenter"
                                                    >
                                                        <Grid
                                                            className={classes.calenderContainer}
                                                        >
                                                            {
                                                                days.map(
                                                                    (
                                                                        day,
                                                                        index
                                                                    ) => (
                                                                        <Typography
                                                                            onClick={
                                                                                () =>
                                                                                    changeSchedule(
                                                                                        'dates',
                                                                                        day
                                                                                    )
                                                                            }
                                                                            key={`${day}-${index}`}
                                                                            component="div"
                                                                            className={
                                                                                `${classes.dayContainer
                                                                                } 
                                                            ${schedule?.configuration?.dates?.indexOf(
                                                                                    day.toString()
                                                                                ) !== -1
                                                                                    ? classes.daySelected
                                                                                    : ''
                                                                                }`
                                                                            }
                                                                        >
                                                                            {day}
                                                                        </Typography>
                                                                    )
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                !(schedule.type === 'Hours' || schedule.type === 'Minutes') &&
                <Grid
                    container
                    spacing={3}
                    className={classes.repeatSection}
                    alignItems="baseline"
                >
                    <Grid item xs={3} className="dflex alignCenter">
                        <Typography variant="body1" className={'fw-500'}>
                            Occurance
                        </Typography>
                    </Grid>
                    <Grid item xs={9} className="pl-2 pr-2">
                        <Grid item xs={12} className="mt-1">
                            <ChipComponent
                                data={schedule?.multiTime ?? []}
                                placeholder="Select Attribute"
                                add={!(schedule?.multiTime?.length >= 5)}
                                availableList={[]}
                                editable={!schedule?.is_event_schedule && !disabled}
                                handleChipEvent={(value) => onHandleChipRemove(value)}
                                addType="timepicker"
                                haveDataType
                                addText="Add Time"
                                stringChip
                                onChipAdd={(value) => onHandleChipAdd(value)}
                                noId
                                limit={3}
                                maxLimit={5}
                                disabled={schedule?.is_event_schedule || disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                isEventSchedule &&
                <Grid
                    container
                    spacing={3}
                    className={`${classes.repeatSection} mt-2`}
                    alignItems="baseline"
                    direction="row"
                    justify="space-between"
                >
                    <Grid item xs={3} className="dflex alignCenter" />
                    <Grid item xs={9} container>
                        <Grid item xs={6} className={"EventCheckbox"}>
                            <FormControlLabel
                                size="small"
                                onChange={(event) => { changeSchedule('is_event_schedule', event.target.checked); }}
                                control={<Checkbox defaultChecked={false} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label="Event Schedule"
                                checked={schedule?.is_event_schedule}
                                disabled={disabled}
                            />
                        </Grid>
                        {
                            schedule?.is_event_schedule &&
                            <Grid item xs={6}>
                                <SelectComponent
                                    name="target_type"
                                    noOutline
                                    list={[{ name: 'Asset' }, { name: 'Measure' }]}
                                    propertyName={'name'}
                                    value={schedule?.target_type || 'Asset'}
                                    onSelectChange={
                                        (value) =>
                                            changeSchedule('target_type', value)
                                    }
                                    isDisabled={disabled}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
            {
                schedule?.is_event_schedule &&
                <Grid>
                    <Grid container className={'mt-1 mb-2'} alignItems="center">
                        <Grid item xs={3} className="dflex alignCenter">
                            <Typography variant="body1" className={'fw-500'}>
                                {schedule?.target_type === "Measure" ? "Measure" : "Asset"}
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={`${classes.inputStyling} timeZone`}>
                            {
                                schedule?.target_type === "Measure" ?
                                    <AutoCompleteComponent
                                        name="target_measure"
                                        variant="standard"
                                        selectedValue={get_selected_target('measure', schedule?.target_measure ?? '')}
                                        data={filteredTargetMeasure()}
                                        onChange={
                                            (event, newValue) =>
                                                changeSchedule('target_measure', newValue)
                                        }
                                        compareKey={'id'}
                                        placeholder="Select Measure"
                                        disabled={disabled}
                                        showTooltip
                                    />

                                    :
                                    <AutoCompleteComponent
                                        name="target_asset"
                                        variant="standard"
                                        selectedValue={get_selected_target('asset', schedule?.target_asset ?? '')}
                                        data={filteredTargetAsset}
                                        onChange={
                                            (event, newValue) =>
                                                changeSchedule('target_asset', newValue)
                                        }
                                        placeholder="Select Asset"
                                        disabled={disabled}
                                        showTooltip
                                        compareKey={'id'}
                                    />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid container className={'mt-2'}>
                <Grid item xs={3} className="dflex alignCenter">
                    <Typography variant="body1" className={'fw-500'}>
                        Time Zone
                    </Typography>
                </Grid>
                <Grid item xs={9} className={`${classes.inputStyling} timeZone`}>
                    <AutoCompleteComponent
                        name="timezone"
                        variant="standard"
                        selectedValue={schedule?.timezone ?? ''}
                        data={timeZones}
                        onChange={
                            (event, newValue) =>
                                changeSchedule('timezone', newValue.label)
                        }
                        placeholder="Select Timezone"
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Scheduler.defaultProps = {
    classes: {},
    data: {},
    disabled: false,
    isEventSchedule: true,
    updateScheduler: () => { },
    selectedMeasureIdString: ""
};

// prop types
Scheduler.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    isEventSchedule: PropTypes.bool,
    updateScheduler: PropTypes.func,
    selectedMeasureIdString: PropTypes.string
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}


export default withStyles(
    (theme) => ({
        ...ReportStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(Scheduler, areEqual));