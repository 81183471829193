const style = (theme) => ({
    container: {
        padding: 30,
        paddingTop: '0px !important',
        background: '#fff',
        height: '100vh',
        overflowY: 'auto',
        width: '100%',
        zIndex: 11,
        alignContent: 'baseline',
        '& .executionLogsTable': {
            "& table > tbody": {
                textTransform: "capitalize",
                '& .assetId': {
                    textTransform: "none !important",
                    whiteSpace: "nowrap !important"
                }
            }
        }
    },
    dialogHeader: {
        position: 'sticky',
        top: 0,
        paddingBottom: 16,
        background: '#fff',
        zIndex: 99,
        paddingTop: 30,
        '& .EditIcon': {
            '&:hover path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .NoNotificationIcon, .CloseIcon': {
            '&:hover': {
                fill: theme.palette.secondary.main
            }
        },
        '& .NotificationIcon': {
            '&:hover #Path_1191': {
                fill: theme.palette.secondary.main
            }
        },
        '& svg.DeleteIcon2:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    actions: {
        '& .CloseIcon': {
            width: '24px',
            height: '24px'
        },
        '& .EditIcon': {
            width: '18px',
            height: '18px',
            '& path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    usersCard: {
        padding: '24px 36px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            minWidth: '130px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid rgb(229 231 235)'
        },
        '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
            fontSize: '1rem'
        }
    },
    cardTitle: {
        position: 'absolute',
        background: '#fff',
        top: '-14px',
        left: '16px',
        padding: '2px 15px',
        fontWeight: 400
    },
    cardText: {
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        color: `${theme.palette.secondary.main} !important`,
        cursor: "pointer"
    }
});
export default style;