import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import moment from 'moment';


// Import Styles
import Styles from './style.jsx';

// Import Component
import { SliderComponent } from '../../components/index.js';
import LoginSlider from './banner.jsx';

// Import Action
import { ssoValidateRequest } from '../../redux/reducer/authReducer';
import { displyAlert } from '../../redux/reducer/alertReducer';

// Import Helpers
import { setCookie } from "../../helpers/appHelpers.js";
import { getGeneralThemeRequest } from '../../redux/reducer/configurationsReducer.js';


function AuthLayoutComponent({ component: Component, ...props }) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    // Slider Data
    const defaultSliderData = [
        {
            image: require('../../assets/img/banner/banner1.png')
        }
    ];


    /**
     * Get Query Params Values
     */
    const [sliderImages, setSliderImages] = useState(defaultSliderData);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedBanners = useSelector((state) => state.configurations.banner.selectedBanners, shallowEqual);
    const { theme: { copyright }, version } = useSelector((state) => state.configurations);
    const query_error_msg = searchParams.get("errormsg");
    const query_token = searchParams.get("token");

    /**
     * Use Effect for Initial Load
     */
    useEffect(() => {
        if (query_error_msg) {
            dispatch(displyAlert({ 'type': 'error', 'message': { "message": `${query_error_msg}` } }));
        }


        if (query_token) {
            setCookie('token', query_token);
            dispatch(ssoValidateRequest());
        }
        setSearchParams({});
    }, [dispatch, query_error_msg, query_token]);


    /**
     * Use effect to load banners
     */
    useEffect(() => {
        if (dispatch) {
            dispatch(getGeneralThemeRequest());
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedBanners?.length > 0) {
            const banners = JSON.parse(JSON.stringify(selectedBanners));
            for (const banner of banners) {
                banner.isLoading = true;
            }
            setSliderImages([...banners]);
        }
    }, [selectedBanners]);

    /**
     * Handle on load image event
     */
    const onLoadBannerImage = (event, item, index) => {
        const banners = JSON.parse(JSON.stringify(sliderImages));
        item.isLoading = false;
        banners.splice(index, 1, { ...item });
        setSliderImages([...banners]);
    };

    const getVersionText = (version) => {
        let versionText = "";
        if (!version || (version && Object.keys(version).length === 0)) {
            return versionText;
        }

        const clientVersion = version?.client?.version ?? "";
        const serverVersion = version?.server?.version ?? "";
        const airflowVersion = version?.airflow?.version ?? "";
        let lastReleaseDate = version?.last_updated ?? null;
        lastReleaseDate = lastReleaseDate ? moment(lastReleaseDate).format("YYYY-MM-DD") : "";

        if (
            clientVersion?.length > 0 && serverVersion?.length > 0 &&
            airflowVersion?.length > 0 && lastReleaseDate?.length > 0
        ) {
            versionText = `Version: ${airflowVersion}(A) ${clientVersion}(C) ${serverVersion}(S) ${lastReleaseDate}`;
        }
        return versionText;
    };


    return (
        /* Authentication container */
        <Grid container>
            <Grid container className={classes.loginContainer}>
                <Grid item xs={8} className={classes.loginSlider}>
                    <Grid className={`${classes.slider} slider`}>
                        <SliderComponent slide_settings={{ dots: true, fade: true, autoplay: true }}>
                            {
                                sliderImages?.length > 0 &&
                                sliderImages.map((slide_item, index) => (
                                    <LoginSlider
                                        key={index}
                                        index={index}
                                        slide_item={slide_item}
                                        onImageLoad={onLoadBannerImage}
                                    />
                                ))
                            }
                        </SliderComponent>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.loginFormContainer}>
                    <Component />
                    <Grid container className={"versionContainer"}>
                        {
                            copyright?.length > 0 &&
                            <Typography variant="body2" className={classes.versiontext}>
                                {`©${moment().format("YYYY").toString()} ${copyright} All rights reserved.`}
                                <br />
                                {getVersionText(version)}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

AuthLayoutComponent.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.elementType
};

export const AuthLayout = withStyles(Styles)(AuthLayoutComponent);