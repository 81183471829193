import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//import Componetns
import { Grid, Typography, InputAdornment, IconButton, Button } from '@mui/material';
import { SelectComponent, SwitchComponent, NumberInputComponent, TextBoxComponent, LazyLoadAutoComplete } from '../../../../components/index.js';
import Scheduler from '../../../../components/scheduler/index.jsx';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';

// Import Images
import { CalendarIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { connectionService } from '../../../../redux/service/connectionService';
import { generalScheduleRequest, updateGeneralInReducer, generalUpdateRequest } from '../../../../redux/reducer/configurationsReducer';
import { triggerJobRequest } from '../../../../redux/reducer/scheduleReducer.js';
// import { navigate } from '../../../../redux/reducer/navigationReducer';


function Reports(props) {

    /**
     * Define Props
     */
    const { classes, reporting, generalPermission, onChange } = props;
    const dispatch = useDispatch();
    const pageLimit = 20;
    const pagination = useRef({
        hasMore: true,
        offset: 0,
        loading: false,
        search: ""
    });

    /**
     * Define State
     */
    const [connections, setConnections] = useState([]);
    const [scheduler, setScheduler] = useState({
        type: "",
        open: false,
        anchorElement: null,
        connectionId: "",
        isAsset: false,
        data: null
    });

    /**
     * Redux selector function to get General Settings from redux store
     */
    const { general } = useSelector((state) => state.configurations);
    const reportingSchedule = general.reporting.schedule ? JSON.parse(JSON.stringify(general.reporting.schedule)) : null;
    const reporting_next_run = general?.reporting_next_run || null;

    /**
     * Get Connection List
     * @param {*} params
     * @returns
     */
    const getConnectionList = async (params) => {
        pagination.current.loading = true;
        const response = await connectionService.getConnectionItems(params);
        return {
            data: response.data,
            hasMore: response.data.length >= pageLimit
        };
    };

    /**
     * Get Connections
     */
    const getConnections = async () => {
        pagination.current.loading = true;
        const requestParams = {
            offset: 0,
            limit: pageLimit,
            search: pagination.current.search || ""
        };
        const { data, hasMore } = await getConnectionList(requestParams);
        setConnections([...data]);
        pagination.current.loading = false;
        pagination.current.hasMore = hasMore;
    };

    /**
     * Load Connections
     */
    useEffect(() => {
        getConnections();
    }, []);


    /**
     * Handle on value change for numerical input
     * @param {*} property
     * @param {*} key
     * @param {*} value
     */
    const onChangeValue = (property, key, value, periodType = null) => {
        if (key === "max_store_period_limit") {
            const inputValue = value ? parseInt(value) : 0;
            if (inputValue <= 1) {
                value = "1";
            } else if (inputValue >= 730 && periodType !== "months") {
                value = "730";
            } else if (inputValue >= 24 && periodType === "months") {
                value = "24";
            }
        } else if (key === "export_row_limit") {
            const inputValue = value ? parseInt(value) : 0;
            if (inputValue <= 1) {
                value = "1";
            } else if (inputValue >= 10000) {
                value = "10000";
            }
        } else if (key === "export_column_limit") {
            const inputValue = value ? parseInt(value) : 0;
            if (inputValue <= 1) {
                value = "1";
            } else if (inputValue >= 100) {
                value = "100";
            }
        }
        onChange(property, key, value);
    };

    const togglePeriodType = (currentPeriodType) => {
        let newPeriodType;

        switch (currentPeriodType) {
            case "runs":
                newPeriodType = "days";
                break;
            case "days":
                newPeriodType = "months";
                break;
            case "months":
            default:
                newPeriodType = "runs";
                break;
        }

        onChange('reporting', 'max_store_period_type', newPeriodType);
    };

    const getPeriodLabel = (periodType) => {
        switch (periodType) {
            case "days":
                return "Days";
            case "months":
                return "Months";
            case "runs":
            default:
                return "Runs";
        }
    };

    /**
     * On Change Search
     * @param {*} value
     */
    const onChangeSearch = (value) => {
        pagination.current.search = value;
        getConnections();
    };

    /**
     * Update Values in Reducer On Change
     * @param {*} category
     * @param {*} property
     * @param {*} value
     */
    const onChangeSchedule = (category, property, value, subProperty) => {
        setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null });
        const updatedConfig = {
            category: category,
            data: {
                [property]: value
            },
            subProperty
        };
        dispatch(updateGeneralInReducer(updatedConfig));
        const requestParams = { ...general[category], [property]: value };
        dispatch(generalUpdateRequest({ id: general.id, [category]: requestParams }));

    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = () => {
        dispatch(triggerJobRequest({ job_type: "metadata" }));
        // dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [reporting?.connection?.id] }));
    };

    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = useCallback((event, type) => {
        event.stopPropagation();
        let scheduleData = null;
        switch (type) {
            case "reporting":
                scheduleData = reportingSchedule ? { ...reportingSchedule } : null;
                dispatch(generalScheduleRequest());
                break;
            default:
                scheduleData = null;
                break;
        }
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, connectionId: "", data: scheduleData, type });
    }, [reportingSchedule]);

    /**
     * Delete semantic schedule
     */
    const deleteSchedule = (type) => {
        const updatedConfig = {
            category: type,
            data: {
                schedule: {}
            }
        };
        dispatch(updateGeneralInReducer(updatedConfig));
        setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null });
    };

    /**
     * Prepare Connection List
     * @param {*} data
     * @returns
     */
    const relationalConnections = [
        appConstants.connectionTypes.snowflake.value,
        appConstants.connectionTypes.mssql.value,
        appConstants.connectionTypes.redshift.value,
        appConstants.connectionTypes.databricks.value,
        appConstants.connectionTypes.db2.value,
        appConstants.connectionTypes.redshift_spectrum.value,
        appConstants.connectionTypes.saphana.value,
        appConstants.connectionTypes.athena.value,
        appConstants.connectionTypes.oracle.value,
        appConstants.connectionTypes.teradata.value,
        appConstants.connectionTypes.synapse.value,
        appConstants.connectionTypes.emr_spark.value,
        appConstants.connectionTypes.bigquery.value
    ];
    const prepareConnectionList = (data) => {
        return [{ 'id': '', 'name': 'None' }, ...data.filter((i) => relationalConnections.indexOf(i.type.toLowerCase()) > -1)];
    };
    const connectionData = useMemo(() => prepareConnectionList(connections), [connections]);


    return (
        <Grid item xs={12}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.reporting}
                </Typography>
            </Grid>
            <Grid className={classes.bodyContainer}>
                <Typography variant="body1">
                    {appConstants.labels.general.reportingdesc}
                </Typography>
                <Grid container wrap="wrap" rowSpacing={8} columnSpacing={8} className="reporting">
                    <Grid item xs={3}>
                        <SwitchComponent size="small"
                            handleChange={(value) => onChange('reporting', 'is_active', value)}
                            checked={reporting.is_active || false}
                            label={appConstants.labels.general.enabled}
                            disabled={!generalPermission?.is_edit} />

                    </Grid>
                    <Grid item xs={3}>
                        <SelectComponent
                            fullWidth={false}
                            label={appConstants.labels.general.exportCategory}
                            variant="standard"
                            displayPropertyName="name"
                            propertyName="value"
                            value={reporting.export_category || appConstants.exportCategory[2].value}
                            onSelectChange={(value) => onChange('reporting', 'export_category', value)}
                            list={appConstants.exportCategory}
                            name="export_category"
                            isDisabled={!generalPermission?.is_edit || !reporting?.is_active}
                        />
                    </Grid>
                    <Grid item xs={3} className={'autocompleteTB'}>
                        <LazyLoadAutoComplete
                            id="form_text_connection"
                            name={'connection'}
                            fullWidth
                            variant="standard"
                            placeholder={"Select Connection"}
                            options={connectionData || []}
                            selectedValue={reporting?.connection ?? ""}
                            label={appConstants.labels.general.reportingConnection}
                            hasMore={pagination.current.hasMore}
                            loading={pagination.current.loading}
                            onChange={(event, newValue) => onChange('reporting', 'connection', newValue)}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                            disableClearable
                            onChangeSearch={(value) => onChangeSearch(value)}
                            onFetchMore={
                                async () => {
                                    if (!pagination.current.hasMore) {
                                        return false;
                                    }
                                    pagination.current.loading = true;
                                    const requestParams = {
                                        offset: pagination.current.offset + pageLimit,
                                        limit: pageLimit,
                                        search: pagination.current.search || ""
                                    };
                                    pagination.current.offset = requestParams.offset;
                                    const { data, hasMore } = await getConnectionList(requestParams);
                                    setConnections((prev) => [
                                        ...prev,
                                        ...data
                                    ]);
                                    pagination.current.loading = false;
                                    pagination.current.hasMore = hasMore;
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBoxComponent
                            name={'reporting_database'}
                            value={reporting?.database || ""}
                            onChange={(event) => onChange('reporting', 'database', event.target.value)}
                            fullWidth
                            variant="standard"
                            size="small"
                            id="length-min-ntb"
                            label={appConstants.labels.general.reportingDatabase}
                            InputLabelProps={{ shrink: true }}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBoxComponent
                            name={'reporting_schema'}
                            value={reporting?.schema || ""}
                            onChange={(event) => onChange('reporting', 'schema', event.target.value)}
                            fullWidth
                            variant="standard"
                            size="small"
                            id="reporting_schema"
                            label={
                                <span className="requiredLabel">
                                    {appConstants.labels.general.reportingSchema}
                                    <span className="requiredstar">
                                        *
                                    </span>
                                </span>
                            }
                            InputLabelProps={{ shrink: true }}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ width: "230px", '& .MuiButton-root': { marginBottom: '5px' } }}>
                        <NumberInputComponent
                            name={'reporting'}
                            value={reporting.max_store_period_limit || 7}
                            onChange={(value) => onChangeValue('reporting', 'max_store_period_limit', value, reporting?.max_store_period_type)}
                            fullWidth
                            variant="standard"
                            size="small"
                            id="report-max_store_period_limit"
                            label={appConstants.labels.general.retentionPeriod}
                            InputLabelProps={{ shrink: true }}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                            InputProps={
                                {
                                    inputProps: {
                                        min: 1,
                                        max: reporting?.max_store_period_type === "months" ? 24 : 730
                                    },
                                    endAdornment:
                                        <InputAdornment position="end" >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                disableElevation
                                                sx={{ height: "25px" }}
                                                onClick={() => togglePeriodType(reporting?.max_store_period_type)}
                                                disabled={!generalPermission?.is_edit || !reporting?.is_active}
                                            >
                                                {getPeriodLabel(reporting?.max_store_period_type)}
                                            </Button>
                                        </InputAdornment>
                                }
                            }
                            validators={['minNumber:1', `maxNumber:${reporting?.max_store_period_type === "months" ? 24 : 730}`]}
                            errorMessages={
                                [
                                    `${appConstants.errorMessages.minValue} 1`,
                                    `${appConstants.errorMessages.maxValue} ${reporting?.max_store_period_type === "months" ? 24 : 730}`
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInputComponent
                            name={'export_row_limit'}
                            value={reporting.export_row_limit || 10000}
                            onChange={(value) => onChangeValue('reporting', 'export_row_limit', value)}
                            fullWidth
                            variant="standard"
                            size="small"
                            id="report-export_row_limit"
                            label={appConstants.labels.general.exportRowLimit}
                            InputLabelProps={{ shrink: true }}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                            InputProps={{ inputProps: { max: 10000 } }}
                            validators={['minNumber:1', 'maxNumber:10000']}
                            valOnChange
                            errorMessages={
                                [
                                    `${appConstants.errorMessages.minValue} 1`,
                                    `${appConstants.errorMessages.maxValue} 10000`
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInputComponent
                            name={'export_column_limit'}
                            value={reporting.export_column_limit || 100}
                            onChange={(value) => onChangeValue('reporting', 'export_column_limit', value)}
                            fullWidth
                            variant="standard"
                            size="small"
                            id="report-export_column_limit"
                            label={appConstants.labels.general.exportColumnLimit}
                            InputLabelProps={{ shrink: true }}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                            InputProps={{ inputProps: { max: 100 } }}
                            validators={['minNumber:1', 'maxNumber:100']}
                            valOnChange
                            errorMessages={
                                [
                                    `${appConstants.errorMessages.minValue} 1`,
                                    `${appConstants.errorMessages.maxValue} 100`
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={3} className={"previewType"}>
                        <SelectComponent
                            fullWidth={false}
                            label={appConstants.labels.general.exportGroup}
                            variant="standard"
                            displayPropertyName="name"
                            propertyName="value"
                            value={reporting.export_group || appConstants.exportGroups[0].value}
                            onSelectChange={(value) => onChange('reporting', 'export_group', value)}
                            list={appConstants.exportGroups}
                            name="preview"
                            isDisabled={!generalPermission?.is_edit || !reporting?.is_active}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {appConstants.labels.general.exportMeasureMetadata}
                        </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant="contained"
                            color="primary"
                            size={'small'}
                            disableElevation
                            onClick={() => handleJobTrigger()}
                            disabled={!generalPermission?.is_edit || !reporting?.is_active}
                        >
                            {appConstants.labels.general.runNow}
                        </Button>
                    </Grid>
                    <Grid item xs={1.5}>
                        <IconButton disabled={!reporting?.is_active} className={classes.CalendarIcon} onClick={(event) => openScheduler(event, "reporting")}>
                            <CalendarIcon />
                        </IconButton>
                        <Typography variant="body1" className={`pl5`}>
                            Schedule
                        </Typography>
                        <Scheduler
                            scheduleProps={scheduler}
                            generalSchedule
                            onClose={() => setScheduler({ type: "", open: false, anchorElement: null, connectionId: "", isAsset: false, data: null })}
                            onScheduledData={(property, value) => onChangeSchedule('reporting', property, value)}
                            savedProps={scheduler?.data}
                            onDelete={() => deleteSchedule("reporting")}
                            isEventSchedule={false}
                            semantic_next_run={reporting_next_run}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
Reports.defaultProps = {
    classes: {},
    reporting: {},
    generalPermission: {},
    onChange: () => { }
};

// prop types
Reports.propTypes = {
    classes: PropTypes.object,
    reporting: PropTypes.object,
    generalPermission: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Reports);