import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';

// Import Components
import { LazyLoadAutoComplete } from '../index.js';
import ComparisonMeasureAddRow from './measureAddRow.jsx';

//  Import Style
import ComparisonStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Actions
import { getComparisonMeasureRequest } from '../../redux/reducer/measureReducer.js';
import { assetService } from '../../redux/service/assetService.js';

// Import Images
import { PlusIcon, DeleteIcon } from '../../assets/svg/index.js';

function AllComparison(props) {
    /**
     * Define Props
     */
    const { classes, data, handleEditMeasureProperties, editMeasure, propertyPermission, comparison_group_index, group_length } = props;
    const dispatch = useDispatch();
    const pageLimit = 20;
    const defaultRule = { measure: "", condition: "", value1: "", value2: "", time: "Minutes" };
    const [sourceAsset, setSourceAsset] = useState([]);
    const [targetAsset, setTargetAsset] = useState([]);
    const sourcePagination = useRef({
        hasMore: true,
        offset: 0,
        loading: true,
        search: ""
    });
    const targetPagination = useRef({
        hasMore: true,
        offset: 0,
        loading: true,
        search: ""
    });

    /**
     * Redux Store
     */
    const comparisonMeasures = useSelector(({ measure }) => measure.comparisonMeasures);

    /**
     * Get Asset List
     * @param {*} params
     * @returns
     */
    const getAssetList = async (params, type) => {
        if (type === "source") {
            sourcePagination.current.loading = true;
            sourcePagination.current.offset = params.offset;
        } else {
            targetPagination.current.loading = true;
            targetPagination.current.offset = params.offset;
        }
        const response = await assetService.getAllAssetsAndAttributes({ type: data?.properties?.type ?? "", ...params });
        return {
            data: response.data,
            hasMore: response.data.length >= pageLimit
        };
    };

    /**
     * Get Source Assets
     * @param {*} params
     * @param {*} clear
     */
    const getSourceAssets = async (params = {}) => {
        sourcePagination.current.loading = true;
        const requestParams = {
            offset: 0,
            limit: pageLimit,
            search: sourcePagination.current.search || "",
            ...params
        };
        const { data, hasMore } = await getAssetList(requestParams, "source");
        setSourceAsset([...data]);
        sourcePagination.current.loading = false;
        sourcePagination.current.hasMore = hasMore;
    };

    /**
     * Get Target Assets
     * @param {*} params
     * @param {*} clear
     */
    const getTargetAssets = async (params = {}) => {
        targetPagination.current.loading = true;
        const requestParams = {
            offset: 0,
            limit: pageLimit,
            search: targetPagination.current.search || "",
            ...params
        };
        const { data, hasMore } = await getAssetList(requestParams, "target");
        setTargetAsset([...data]);
        targetPagination.current.loading = false;
        targetPagination.current.hasMore = hasMore;
    };

    /**
     * On Change Search
     * @param {*} type
     * @param {*} value
     */
    const onChangeSearch = (type, value) => {
        if (type === "source") {
            sourcePagination.current.search = value;
            getSourceAssets();
        } else {
            targetPagination.current.search = value;
            getTargetAssets();
        }
    };

    /**
     * Load Assets
     */
    useEffect(() => {
        getSourceAssets();
        getTargetAssets();
    }, []);

    /**
     * Handle On Change
     * @param {*} property
     * @param {*} value
     */
    const onChangeProperty = (property, value) => {
        let properties = data?.properties ? { ...data.properties } : {};
        if (property === "source" || property === "target") {
            if (!value?.type || (value?.type !== data?.properties?.type)) {
                if (property === "source") {
                    getTargetAssets({ type: value?.type });
                } else {
                    getSourceAssets({ type: value?.type });
                }
            }
        }
        if (value?.id) {
            properties = {
                ...properties,
                [property]: value,
                "type": value.type
            };
        } else {
            properties[property] = value;
        }
        if (property === 'source' && value !== null && comparison_group_index === 0) {
            const connectionId = value?.connection_id;
            editMeasure('connection', connectionId);
        }
        const source = properties?.source ?? '';
        const target = properties?.target ?? '';
        if (!source && !target) {
            properties.type = '';
        }
        handleEditMeasureProperties(comparison_group_index, { ...data, "properties": properties });
    };

    /**
     * Get Measures Based on Source and Target
     */
    useEffect(() => {
        const source = data?.properties?.source;
        const target = data?.properties?.target;
        const type = data?.properties?.type;
        if (source && target) {
            const requestParams = {
                source_asset: source.id,
                target_asset: target.id,
                type: type
            };
            dispatch(getComparisonMeasureRequest(requestParams));
        }
    }, [data?.properties]);

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     * @param {*}   item
     * @param {*} index
     */
    const onChange = (property, value, item = {}, index) => {
        const updatedItem = Object.assign({}, item);
        if (property === "measure") {
            updatedItem.measure = value;
        }
        else if (property === "condition") {
            updatedItem.condition = value;
        }
        else if (property === "value1") {
            updatedItem.value1 = value;
        }
        else if (property === "value2") {
            updatedItem.value2 = value;
        }
        else if (property === "time") {
            updatedItem.time = value.label;
        }

        const measureRules = [...data.rules];
        measureRules[index] = { ...measureRules[index], ...updatedItem };
        handleEditMeasureProperties(comparison_group_index, { ...data, "rules": measureRules });
    };

    /**
     * On Add New Item
     */
    const onAddNewRule = () => {
        const measureRules = [...data.rules, defaultRule];
        handleEditMeasureProperties(comparison_group_index, { ...data, "rules": measureRules });
    };

    /**
     * On Action Events
     * @param {*} action
     * @param {*} index
     */
    const handleRuleActions = (action, index) => {
        const rules = [...data.rules] || [];
        if (action === "create") {
            rules.push({ ...defaultRule });
        } else if (action === "delete") {
            rules.splice(index, 1);
        }
        handleEditMeasureProperties(comparison_group_index, { ...data, "rules": rules });
    };


    /**
     * Measures By Source and Destination Asset
     * @param {*} assets_list
     * @param {*} group_item
     * @returns
     */
    const prepareMeasures = (m_list, group_item) => {
        const g_key = `${group_item?.properties?.source?.id}_${group_item?.properties?.target?.id}`;
        const configureMeasures = group_item?.rules?.map((measure) => measure.measure);
        const list = m_list[g_key] || [];
        const measureList = list.filter((obj) => !configureMeasures.includes(obj));
        return measureList;
    };
    const measures = useMemo(() => prepareMeasures(comparisonMeasures, data), [comparisonMeasures, data]);

    return (
        <Grid container className={classes.ruleGreyContainer} key={`compare_group_item_${comparison_group_index}`}>
            <Grid container spacing={8} sx={{ pb: 3, marginTop: '-2px' }} className="pl-3">
                <Grid item xs={5} className="pt-0 pr-4">
                    <Tooltip title={data?.properties?.source?.label || ''}>
                        <LazyLoadAutoComplete
                            options={sourceAsset}
                            selectedValue={data?.properties?.source || ''}
                            disabled={!propertyPermission?.is_edit}
                            validators={['required']}
                            errorMessages={['Source is required']}
                            freeSolo={false}
                            variant="standard"
                            name="Source"
                            placeholder="Select Source Asset / Attribute"
                            hasMore={sourcePagination.current.hasMore}
                            loading={sourcePagination.current.loading}
                            onChange={(event, value) => onChangeProperty('source', value)}
                            onChangeSearch={(value) => onChangeSearch('source', value)}
                            onFetchMore={
                                async () => {
                                    if (!sourcePagination.current.hasMore || sourcePagination.current.loading) {
                                        return false;
                                    }
                                    sourcePagination.current.loading = true;
                                    const requestParams = {
                                        offset: sourcePagination.current.offset + pageLimit,
                                        limit: pageLimit,
                                        search: sourcePagination.current.search || ""
                                    };
                                    const { data, hasMore } = await getAssetList(requestParams, "source");
                                    setSourceAsset((prev) => [
                                        ...prev,
                                        ...data
                                    ]);
                                    sourcePagination.current.loading = false;
                                    sourcePagination.current.hasMore = hasMore;
                                }
                            }
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={5} className="pt-0">
                    <Tooltip title={data?.properties?.target?.label || ''}>
                        <LazyLoadAutoComplete
                            options={targetAsset}
                            selectedValue={data?.properties?.target || ''}
                            disabled={!propertyPermission?.is_edit}
                            validators={['required']}
                            errorMessages={['Target is required']}
                            freeSolo={false}
                            variant="standard"
                            name="Target"
                            placeholder="Select Target Asset / Attribute"
                            hasMore={targetPagination.current.hasMore}
                            loading={targetPagination.current.loading}
                            onChange={(event, value) => onChangeProperty('target', value)}
                            onChangeSearch={(value) => onChangeSearch('target', value)}
                            onFetchMore={
                                async () => {
                                    if (!targetPagination.current.hasMore || targetPagination.current.loading) {
                                        return false;
                                    }
                                    targetPagination.current.loading = true;
                                    const requestParams = {
                                        offset: targetPagination.current.offset + pageLimit,
                                        limit: pageLimit,
                                        search: targetPagination.current.search || ""
                                    };
                                    const { data, hasMore } = await getAssetList(requestParams, "target");
                                    setTargetAsset((prev) => [
                                        ...prev,
                                        ...data
                                    ]);
                                    targetPagination.current.loading = false;
                                    targetPagination.current.hasMore = hasMore;
                                }
                            }
                        />
                    </Tooltip>
                </Grid>
                {
                    group_length > 1 &&
                    <Grid item xs={2} align="right" className="pt-0">
                        <Tooltip title={"Delete"} arrow onClick={() => handleEditMeasureProperties(comparison_group_index, data, 'delete')}>
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
            <Grid container className={classes.comparisonContainer}>
                <Grid item xs={9} className="dflex alignCenter">
                    <Typography variant="h6">
                        Configure Comparison Measure
                    </Typography>
                </Grid>
                {
                    data?.rules?.map((rule_data, index) =>
                        <ComparisonMeasureAddRow
                            key={`measureDetail_${index}`}
                            rule_data={rule_data}
                            handleRuleActions={(action) => handleRuleActions(action, index)}
                            onChange={(property, value) => onChange(property, value, rule_data, index)}
                            measuresList={measures}
                            rule_index={index}
                        />
                    )
                }
                <Grid item className={`dflex alignCenter pt-2 addMeasureBtn ${classes.addMeasureBtn}`}>
                    <Tooltip title={"Add Measure"} arrow onClick={onAddNewRule} className="p0 plusIcon">
                        <IconButton>
                            <PlusIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="body1" onClick={onAddNewRule} className={`${classes.textSecondary} pt1 pl-1`}>
                        Add Measure
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
AllComparison.defaultProps = {
    classes: {},
    data: {},
    handleEditMeasureProperties: () => { },
    editMeasure: () => { },
    propertyPermission: {},
    comparison_group_index: 0,
    group_length: 0
};

// prop types
AllComparison.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleEditMeasureProperties: PropTypes.func,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object,
    comparison_group_index: PropTypes.number,
    group_length: PropTypes.number
};

export default withStyles(
    (theme) => ({
        ...ComparisonStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AllComparison);