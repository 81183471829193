const config = {
    connectors: [
        {
            name: "AND",
            value: "and"
        },
        {
            name: "OR",
            value: "or"
        }
    ],
    Operators: [
        {
            name: 'is equal to',
            id: "isEqualTo",
            types: ['numeric', 'integer', 'text', 'datetime', 'date', 'datetimeoffset', 'time', 'bool'],
            valueRequired: true
        }, {
            name: 'is not equal to',
            id: "isNotEqualTo",
            types: ['numeric', 'integer', 'text', 'datetime', 'date', 'datetimeoffset', 'time', 'bool'],
            valueRequired: true
        }, {
            name: 'is null',
            id: "isNull",
            types: ['numeric', 'integer', 'text', 'datetime', 'date', 'datetimeoffset', 'time', 'bool'],
            valueRequired: false
        }, {
            name: 'is not null',
            id: "isNotNull",
            types: ['numeric', 'integer', 'text', 'datetime', 'date', 'datetimeoffset', 'time', 'bool'],
            valueRequired: false
        }, {
            name: 'is empty',
            id: "isEmpty",
            types: ['text'],
            valueRequired: false
        }, {
            name: 'is not empty',
            id: "isNotEmpty",
            types: ['text'],
            valueRequired: false
        }, {
            name: 'contains',
            id: "contains",
            types: ['text'],
            valueRequired: true
        }, {
            name: 'length of character',
            id: "length",
            types: ['text'],
            conditionOperator: 'isEqualTo',
            valueRequired: true
        }, {
            name: 'starts with',
            id: "startWith",
            types: ['text'],
            valueRequired: true
        }, {
            name: 'ends with',
            id: "endWith",
            types: ['text'],
            valueRequired: true
        }, {
            name: 'is greater than',
            id: "isGreaterThen",
            types: ['numeric', 'integer', 'text_numerics', 'datetime', 'date', 'datetimeoffset', 'time'],
            valueRequired: true
        }, {
            name: 'is less than',
            id: "isLessThen",
            types: ['numeric', 'integer', 'text_numerics', 'datetime', 'date', 'datetimeoffset', 'time'],
            valueRequired: true
        }, {
            name: 'is greater than or equal to',
            id: "isGreaterThenOrEqualTo",
            types: ['numeric', 'integer', 'text_numerics', 'datetime', 'date', 'datetimeoffset', 'time'],
            valueRequired: true
        }, {
            name: 'is less than or equal to',
            id: "isLessThenOrEqualTo",
            types: ['numeric', 'integer', 'text_numerics', 'datetime', 'date', 'datetimeoffset', 'time'],
            valueRequired: true
        }, {
            name: 'is between',
            id: "isBetween",
            types: ['numeric', 'integer', 'text_numerics', 'datetime', 'date', 'datetimeoffset', 'time'],
            valueRequired: true,
            values: ['', '']
        },
        {
            name: 'matches',
            id: "match",
            types: ['numeric', 'integer', 'text', 'pattern'],
            valueRequired: true
        },
        {
            name: 'not matches',
            id: "notMatch",
            valueRequired: true,
            types: ['numeric', 'integer', 'text', 'pattern']
        },
        {
            name: 'length is less than or equal to',
            id: "lengthLessThenOrEqualTo",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'length is greater than or equal to',
            id: "lengthGreaterThenOrEqualTo",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'length is equal to',
            id: "lengthEqualTo",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'length is not equal to',
            id: "lengthNotEqualTo",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'length is greater than',
            id: "lengthGreaterThen",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'length is less than',
            id: "lengthLessThen",
            types: ['numeric', 'integer', 'text'],
            valueRequired: true
        }, {
            name: 'is today',
            id: "isToday",
            types: ['datetime', 'date', 'datetimeoffset'],
            valueRequired: false
        }
    ]
};

export default config;