import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Masonry } from '@mui/lab';
import _ from 'lodash';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

//  Import Components
import FilterBar from './filterBar/index.jsx';
import { NoResultComponent, SimpleTabHeaderComponent, LoaderComponent, AssetCardComponent } from '../../components/index.js';

//  Import Styles
import CardStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Reducer
import { catalogListRequest, catalogSearchTrackingRequest } from '../../redux/reducer/catalogReducer';
import { navigate } from '../../redux/reducer/navigationReducer';

// Import Helpers
import appConstants from '../../constants/appConstants.js';
import { permissionTabs } from '../../helpers/appHelpers.js';

function Catalog(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const scrollRef = useRef();
    const searchControllerRef = useRef();

    /**
     * Get Query Params Values
     */
    const [searchParams, setSearchParams] = useSearchParams();
    let query_search_by = searchParams.get("search_by");
    const query_search_key = searchParams.get("search_key");
    let query_filter_by_domains = searchParams.get("domain");
    let query_filter_by_applications = searchParams.get("application");
    let query_filter_by_tags = searchParams.get("tag");
    const query_filter_by_terms = searchParams.get("term");
    const query_filter_by_conversations = searchParams.get("conversations");
    const query_filter_by_issues = searchParams.get("issues");
    const query_filter_by_alerts = searchParams.get("alerts");

    if (location?.state?.filter) {
        if (location?.state?.type === 'tag') {
            query_filter_by_tags = location?.state?.type_value;
        }
        else if (location?.state?.type === 'domain') {
            query_filter_by_domains = location?.state?.type_value;
        }
        else if (location?.state?.type === 'application') {
            query_filter_by_applications = location?.state?.type_value;
        }
        query_search_by = location?.state?.search_by;
    }


    /**
     * Redux Select Action
     * @param {*} event
     */
    const { data: catalogData, count, tabIndex, search_by, sortBy, orderBy, limit, offset, search_key, filters, isLoading, isLoadMore } = useSelector((state) => state.catalog.search, shallowEqual);
    const { permission, user: userDetails } = useSelector((state) => state.auth, shallowEqual);

    /**
     * Define State
     */
    const defaultFilter = {
        rating: 5,
        score: [0, 100],
        applications: [],
        domains: [],
        tags: [],
        terms: [],
        connection_type: [],
        filter_by: [],
        asset_status: [...appConstants.status.slice(0, 3)]
    };

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [showClearFilter, setShowClearFilter] = useState(false);

    /**
     * Remove Search Params
     */
    const removeSearchParam = () => {
        if (location?.state?.filter) {
            return;
        }
        setSearchParams({});
    };

    /**
     * Validate and Enable Clear Filter
     */
    const isFilterAdded = (request_data) => {
        const filter_params = request_data?.filters ?? {};
        if (!_.isEmpty(filter_params) && !_.isEqual(defaultFilter, filter_params)) {
            setShowClearFilter(true);
        } else {
            setShowClearFilter(false);
        }
    };

    /**
     * Load Catalogs
     * @param {*} params
     * @param {*} clear
     */
    const loadCatalogs = (params, clear = false) => {

        if (searchControllerRef && searchControllerRef.current) {
            searchControllerRef.current.abort();
        }
        searchControllerRef.current = new AbortController();
        const token = { signal: searchControllerRef?.current?.signal };

        const requestParams = {
            search_by,
            tabIndex,
            sortBy,
            orderBy,
            limit,
            offset: clear ? 0 : offset,
            search_key,
            filters,
            domainMapped: userDetails?.domains?.length > 0,
            ...params
        };
        dispatch(catalogListRequest({ params: requestParams, token, clear }));
        isFilterAdded(requestParams);
    };

    /**
     * Prepare Filters From Query  Params
     */
    const prepareFitlers = (requestData) => {

        let filter_params = {
            ...filters,
            ...requestData.filters
        };
        if (query_filter_by_domains) {
            filter_params = {
                ...filter_params,
                "domains": query_filter_by_domains.constructor === Array ? query_filter_by_domains : [query_filter_by_domains?.trim()]
            };
        }
        if (query_filter_by_applications && query_filter_by_applications?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "applications": [query_filter_by_applications?.trim()]
            };
        }
        if (query_filter_by_tags && query_filter_by_tags?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "tags": [query_filter_by_tags?.trim()]
            };
        }
        if (query_filter_by_terms && query_filter_by_terms?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "terms": [query_filter_by_terms?.trim()]
            };
        }
        if (query_filter_by_conversations && query_filter_by_conversations?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "filter_by": ["conversations"]
            };
        }
        if (query_filter_by_issues && query_filter_by_issues?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "filter_by": ["issues"]
            };
        }
        if (query_filter_by_alerts && query_filter_by_alerts?.trim()?.length) {
            filter_params = {
                ...filter_params,
                "filter_by": ["alerts"]
            };
        }

        if (!_.isEqual(defaultFilter, filter_params)) {
            setCollapseOpen(true);
        }

        return filter_params;
    };

    /**
     * Define Use Effects
     */
    useEffect(() => {
        const requestType = query_search_by ? query_search_by : search_by;
        let requestData = {
            search_by: search_by
        };
        if (query_search_by) {
            const catalogTabList = appConstants.tabs.catalogTabs.map((item) => item.toLowerCase());
            requestData = {
                ...requestData,
                "search_by": query_search_by,
                "tabIndex": catalogTabList.indexOf(requestType.toLowerCase())
            };
        }

        if (query_search_key) {
            requestData = {
                ...requestData,
                "search_key": query_search_key
            };
        }

        requestData.filters = prepareFitlers(requestData);

        removeSearchParam();
        loadCatalogs(requestData, true);
    }, [dispatch]);

    /**
     * Scroll Top
     */
    useEffect(() => {
        scrollRef.current.scrollTop = 0;
    }, [tabIndex, search_key]);

    /**
     * Handle Tab Change Event
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (newValue) => {
        if (newValue !== tabIndex) {
            const requestData = {
                tabIndex: newValue,
                search_by: appConstants.tabs.catalogTabs[newValue]?.toLowerCase()
            };
            loadCatalogs(requestData, true);
        }
    };

    /**
     * Handle Collapse Event
     */
    const onCollapseChange = () => {
        setCollapseOpen((collapseOpen) => !collapseOpen);
    };

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if (!isLoading && isLoadMore && Math.ceil(event.target.scrollTop + event.target.clientHeight + 1) >= event.target.scrollHeight) {
            const requestData = {
                offset: offset + limit
            };
            loadCatalogs(requestData);
        }
    };

    /**
     * Handle Naviagation
     * @param {*} selectedItem
     */
    const handleNavigation = (selectedItem) => {
        const filteredCondition = filters?.filter_by;
        const assetInitialRoute = permissionTabs(permission, appConstants.tabs.datasetOverviewTabs);
        switch (selectedItem.type) {
            case "attribute":
                if (filteredCondition.length === 1 && filteredCondition[0] === "issues") {
                    dispatch(navigate({ path: 'assets.attributeProperties', state: { 'section': 'measure', "selectedAsset": selectedItem }, params: [selectedItem.asset_id, selectedItem.source_id] }));
                } else {
                    dispatch(navigate({ path: 'assets.attributeProperties', state: { "selectedAsset": selectedItem }, params: [selectedItem.asset_id, selectedItem.source_id] }));
                }
                dispatch(catalogSearchTrackingRequest({ 'type': selectedItem.type.toLowerCase(), 'redirect_id': selectedItem.source_id, 'name': selectedItem.name, params: { 'asset_id': selectedItem.asset_id } }));
                break;
            default:
                if ((assetInitialRoute.length > 0 && assetInitialRoute[0].path === 'conversation') || ((filteredCondition.length === 1 && filteredCondition[0] === "conversations") && (assetInitialRoute.some((x) => x.featureName === "Assets" && x.subFeatureName === "Conversations")))) {
                    dispatch(navigate({ path: 'assets.conversations', state: { "selectedAsset": selectedItem }, params: [selectedItem.asset_id] }));
                } else if ((assetInitialRoute.length > 0 && assetInitialRoute[0].path === 'properties') || ((filteredCondition.length === 1 && filteredCondition[0] === "issues") && (assetInitialRoute.some((x) => x.featureName === "Assets" && x.subFeatureName === "Properties")))) {
                    dispatch(navigate({ path: 'assets.properties', state: (assetInitialRoute.length > 0 && assetInitialRoute[0].path === 'properties') ? { "selectedAsset": selectedItem } : { 'section': 'measure', "selectedAsset": selectedItem }, params: [selectedItem.asset_id] }));
                } else if (assetInitialRoute.length > 0 && assetInitialRoute[0].path === 'usage') {
                    dispatch(navigate({ path: 'assets.usage', state: { "selectedAsset": selectedItem }, params: [selectedItem.asset_id] }));
                } else {
                    dispatch(navigate({ path: 'assets.root', state: { "selectedAsset": selectedItem }, params: [selectedItem.asset_id] }));
                }
                dispatch(catalogSearchTrackingRequest({ 'type': selectedItem.type.toLowerCase(), 'redirect_id': selectedItem.asset_id, 'name': selectedItem.name }));
                break;
        }
    };

    /**
     * On Filter Changes
     * @param {*} key
     * @param {*} value
     */
    const onFilterChange = (key, value) => {
        const updateFilter = { ...filters };
        updateFilter[key] = value;
        const requestData = {
            filters: updateFilter
        };
        loadCatalogs(requestData, true);
    };


    /**
     * Sorting / Order By Changes
     * @param {*} key
     * @param {*} value
     */
    const onSortingChange = (key, value) => {
        loadCatalogs({ [key]: value }, true);
    };

    /**
     * Merge Sorting and Filters Data
     */
    const filters_sidebar = { ...filters, sortBy, orderBy };
    const filter_header = appConstants.tabs.catalogTabs.filter((tab) => {
        if (tab === "Attributes" || tab === "All") {
            return permission.find((x) => x.feature.name === "Assets" && x.feature.sub_feature === "Properties" && x.is_view);
        }
        return true;
    });

    /**
     * Clear Filter
     */
    const clearFilters = () => {
        const requestData = {
            filters: defaultFilter
        };
        loadCatalogs(requestData, true);
    };

    return (
        <React.Fragment>
            <Grid container className={classes.cardContainer}>
                <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid className="dflex alignCenter pl-1 pb-1">
                            <Typography variant={'h6'} className={`${classes.textSecondary} mr-2`}>
                                Total Search Result
                                <span className="fw-600 pl5 countText">
                                    Count
                                    {" "}
                                    {count}
                                </span>
                            </Typography>
                            {
                                showClearFilter &&
                                <Button variant="text" color="primary" size="small" onClick={clearFilters} startIcon={<FilterAltOffIcon />}>
                                    Clear Filter
                                </Button>
                            }
                        </Grid>
                        <Grid item className={classes.catalogFilter}>
                            <SimpleTabHeaderComponent
                                tabList={filter_header}
                                tabIndex={tabIndex}
                                tabStyle={'blue'}
                                onTabChange={(newValue) => handleTabChange(newValue)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3} className={`${classes.catalogListContainer} `}>
                <Grid item sx={{ flex: '0 0 auto', width: '300px' }}>
                    <FilterBar
                        tabIndex={tabIndex}
                        key={'filter-slider'}
                        collapseChange={onCollapseChange}
                        collapseState={collapseOpen}
                        filters={filters_sidebar}
                        onFilterChange={onFilterChange}
                        onSortingChange={onSortingChange}
                        clearFilters={clearFilters}
                        disableFilterButton={!showClearFilter} />
                </Grid>
                <Grid item ref={scrollRef} className={`${classes.catalogList} ${!collapseOpen && "sideBarClose"}`} onScroll={(event) => onScrollEvent(event)}>
                    <Grid container className="pb-2">
                        {
                            catalogData?.length > 0 &&
                            <Masonry columns={{ xs: 6, sm: 3, md: 3, xl: 4 }} spacing={3}>
                                {
                                    catalogData && catalogData.map((catalog, index) =>
                                        <Grid item key={index}>
                                            <AssetCardComponent data={catalog} handleNavigation={(selectedItem) => handleNavigation(selectedItem)} />
                                        </Grid>
                                    )
                                }
                            </Masonry>
                        }
                        {!isLoading && catalogData.length <= 0 && <NoResultComponent title="No Items Found" noresultSize="140px" height="calc(100vh - 178px)" />}
                        {isLoading && <LoaderComponent xlCard={2} sx={{ paddingLeft: "24px" }} cardHeight="250px" loaderType="card" />}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
Catalog.defaultProps = {
    classes: {}
};

// prop types
Catalog.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...CardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Catalog);