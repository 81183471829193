import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import PreviewStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// import Actions
import { removeUsageRequest, runQueryRequest } from '../../../../redux/reducer/usageReducer';

// import Actions
import { convertUTCtoLocalDateTime } from '../../../../helpers/appHelpers';
import { TableComponent } from '../../../../components/index.js';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';

function Queries(props) {

    /**
     * Define Props
     */
    const { classes, asset_id, searchKey, handleCellClickEvent, usagePermission, convertMeasure } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { saved_queries } = useSelector((state) => state.usage);

    /**
     * Delete Query
     */
    const deleteQuery = (id) => {
        dispatch(removeUsageRequest(id));
    };

    /**
     * Run Query
     */
    const runQuery = (query) => {
        if (query) {
            dispatch(runQueryRequest({ asset_id, query }));
        }
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchKey) => {
        let filterData = data.map((datum) => { return { ...datum, created_date: convertUTCtoLocalDateTime(datum.created_date) }; });
        if (searchKey) {
            filterData = filterData.filter((item) => item?.name?.toLowerCase().includes(searchKey.toLowerCase()) || item?.query?.toLowerCase().includes(searchKey.toLowerCase()));
        }
        return filterData;
    };

    /**
     * Handle Adding or Deleting Application
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = (item, actionType, event) => {
        if (actionType === 'delete') {
            deleteQuery(item.id);
        }
        if (actionType === 'run') {
            runQuery(item?.query ?? '');
        }
        if (actionType === 'convert') {
            convertMeasure(item, event);
        }
    };

    const prepareActions = (data, permission) => {
        const newActions = data.map((datum) => {
            if (datum.key === 'actions' && !permission?.is_edit) {
                const index = datum.actions.indexOf('delete');
                if (index !== -1) {
                    datum.actions.splice(index, 1);
                }
            }
            if (datum.key === 'name' && !permission?.is_edit) {
                datum.disable_link = true;
            }
            return datum;
        });
        return newActions;
    };


    /**
     * Filter Applications using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(saved_queries ?? [], searchKey), [saved_queries ?? [], searchKey]);
    const restrictActions = useMemo(() => prepareActions(appConstants.table.UsageSavedQueries, usagePermission), [appConstants.table.UsageSavedQueries, usagePermission]);


    return (
        <Grid>
            <Grid className={classes.savedQueriesWrapper}>
                <TableComponent
                    styleType="striped"
                    headers={restrictActions}
                    NoResultText="No Queries Found"
                    data={filterData}
                    sortBy={'name'}
                    orderBy={'asc'}
                    onClickActions={handleTableActions}
                    onCellClick={handleCellClickEvent}
                    height="210px"
                    showHeader={false}
                    stickyHeader
                />
            </Grid>
        </Grid>
    );
}

// default props
Queries.defaultProps = {
    classes: {},
    asset_id: "",
    searchKey: "",
    handleCellClickEvent: () => { },
    usagePermission: {},
    convertMeasure: () => { }
};

// prop types
Queries.propTypes = {
    classes: PropTypes.object,
    asset_id: PropTypes.string,
    searchKey: PropTypes.string,
    handleCellClickEvent: PropTypes.func,
    usagePermission: PropTypes.object,
    convertMeasure: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...PreviewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Queries);