import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, ToggleButtonGroup, ToggleButton, Tooltip, Typography } from '@mui/material';

//  Import Styles
import DbtInfoStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

//  Import Components
import { AdfPipelineRunsComponent } from '../adfPipelineRuns/index.jsx';
import appConstants from '../../../../../constants/appConstants.js';


function AdfInformationComponent(props) {

    /**
     * Define Props
     */
    const { classes, versionDetail } = props;
    const adfInfoTabs = [
        { label: "Runs", description: "Run details of the Pipeline for last 30 days" },
        { label: "Activities", description: "Activity details of the Pipeline" }
    ];

    /**
     * Define State
     */
    const [template, setTemplate] = useState("Runs");

    /**
     * Handle Filter OnChange
     * @param {*} value
     */
    const handleFilterOnChange = (value) => {
        if (!value || value === template) {
            return;
        }
        setTemplate(value);
    };

    /**
     * Get Adf Info
     */
    const bindAdfPipelineInfo = () => {
        switch (template) {
            case "Activities":
                return <AdfPipelineRunsComponent data={versionDetail?.pipeline_info?.activities ?? []} headers={appConstants.table.adfPipelineActivityListHeader} title={template} />;
            default:
                return <AdfPipelineRunsComponent data={versionDetail?.pipeline_info?.pipeline_runs ?? []} headers={appConstants.table.adfPipelineRunsListHeader} title={template} />;
        }
    };

    // Get Selected Filter
    const selectedFilter = adfInfoTabs
    .find((item) => item.label === template);

    return (
        <Grid item xs={12} >
            <Grid container alignItems="center" justifyContent="space-between" className={"mb-1"}>
                <Grid item className={classes.filter}>
                    <Typography variant="body1" className={classes.textSecondary}>
                        {selectedFilter?.description || ""}
                    </Typography>
                </Grid>
                    <ToggleButtonGroup
                        color="secondary"
                        value={template || 'All'}
                        exclusive
                        aria-label="text alignment"
                        onChange={(event, value) => handleFilterOnChange(value)}
                    >
                        {
                            adfInfoTabs.map((data, index) =>
                                <ToggleButton className={classes.toggleButton} key={`toggle_${index}`} value={data.label}>
                                    <Tooltip title={data.label} arrow>
                                        <Typography variant="body2" className={classes.textSecondary}>
                                            {data.label}
                                        </Typography>
                                    </Tooltip>
                                </ToggleButton>
                            )
                        }
                    </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} className={classes.infoSection} >
                {bindAdfPipelineInfo()}
            </Grid>
        </Grid>
    );
}

// default props
AdfInformationComponent.defaultProps = {
    classes: {},
    versionDetail: {}
};

// prop types
AdfInformationComponent.propTypes = {
    classes: PropTypes.object,
    versionDetail: PropTypes.object
};


/**
 * Export Component
 */
export default withStyles(
    (theme) => ({
        ...DbtInfoStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AdfInformationComponent);