const style = () => ({
    alertsCard: {
        maxHeight: 120,
        height: 120,
        background: '#fff',
        padding: 10
    },
    chartContainer: {
        position: 'relative',
        right: '25px',
        top: '-36px',
        // width: '100%',
        '& circle': {
            strokeWidth: '6 !important'
        },
        '& .circularContainer': {
            margin: 'auto'
        },
        '& .textValue': {
            fontSize: '14px !important'
        }
    }
});

export default style;