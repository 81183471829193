import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//  Import Styles
import SemanticAppStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Constants
import appConstants from '../../../constants/appConstants.js';
import { TableComponent, DialogComponent } from '../../../components/index.js';

// Import Component

import { ValidatorForm } from 'react-material-ui-form-validator';
import { navigate } from '../../../redux/reducer/navigationReducer.js';

// Actions
import { getApplicationsRequest, updateApplicationRequest, deleteApplicationRequest, createApplicationRequest } from '../../../redux/reducer/applicationReducer';
import { updateUserPreference } from '../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest, getUsersThumbnailRequest } from '../../../redux/reducer/userReducer';

// Import Helpers
import { checkPermission, permissionHeaders, prepareUpdateUserPreference, getUserPreference } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';

function Applications(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({
        "name": "",
        "description": "",
        "type": "",
        "linked_asset": ""
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(getApplicationsRequest());
    }, [dispatch]);


    /**
     * Redux Select Action
     * @param {*} event
     */
    const { applications, isLoading } = useSelector((state) => state.applications, shallowEqual);
    const { permission, user } = useSelector((state) => state.auth, shallowEqual);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const columns = getUserPreference(user?.user_preference ?? {}, "table", "application", "columns");
    const { thumbnail_list: usersList } = useSelector((state) => state.user, shallowEqual);

    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const requestData = {
            ...item, [key]: value
        };
        if (key === "users") {
            value = value.map((user) => user.id);
            requestData.users = value;
        } else {
            requestData.users = (requestData.users && requestData.users.length > 0) ? requestData.users.map((user) => user.id) : [];
        }
        dispatch(updateApplicationRequest(requestData));
    };

    /**
     * Handle Deleting Chip in the Chip Component
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     */
    const handleChipDelete = (selectedChip, fieldValue, item, key) => {
        const requestData = {
            ...item, [key]: fieldValue.filter((chip) => selectedChip !== chip)
        };
        dispatch(updateApplicationRequest(requestData));
    };

    /**
     * Handle Chip Redirection
     * @param {*} selectedChip
     * @param {*} fieldValue
     */
    const onHandleChipSelect = (selectedChip, fieldValue) => {
        if (user.domains.length > 0) {
            if (fieldValue === "linked_asset" && selectedChip.domains !== null && user.domains.some((value) => selectedChip.domains.includes((value)))) {
                dispatch(navigate({ path: 'assets.root', state: {}, params: [selectedChip.asset_id] }));
            }
        } else {
            if (fieldValue === "linked_asset") {
                dispatch(navigate({ path: 'assets.root', state: {}, params: [selectedChip.asset_id] }));
            }
        }
    };

    /**
     * Handle Adding the new Chip in the Chip Component
     * @param {*} selectedChip
     * @param {*} fieldValue
     * @param {*} item
     * @param {*} key
     */
    const handleChipAdd = (value, fieldValue, item, key) => {
        const requestData = {
            ...item, [key]: [...fieldValue, value]
        };
        dispatch(updateApplicationRequest(requestData));
    };

    /**
     * Handle Adding or Deleting Application
     * @param {*} item
     * @param {*} actionType
     */
    const handleTableActions = (item, actionType) => {
        if (actionType === 'delete') {
            setShowDeleteDialog({
                open: true,
                title: appConstants.dialogBox.delete,
                message: appConstants.dialogBox.applicationDeleteMessage,
                data: { id: item?.id }
            });
        }
        if (actionType === 'create') {
            dispatch(createApplicationRequest(item));
        }
    };

    /**
     * Get user info
     */
    const applicationsData = (applications, usersList) => {
        const usersMap = new Map();
        usersList.forEach((user) => {
            usersMap.set(user.id, user);
        });

        const updatedApplications = applications.map((application) => {
            const updatedUsers = Array.isArray(application.users) ? application.users.map((userId) =>
                usersMap.get(userId)).filter((user) => user) : [];
            return { ...application, users: updatedUsers };
        });
        return updatedApplications;
    };

    /**
     * Prepare Filter Applications
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterApplications = (data, searchFilters) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = data.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key]?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase())) {
                        return false;
                    }
                    if (key === 'type') {
                        for (const typeItem of item[key]) {
                            if (typeItem?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }
                    if (typeof (item[key]) === "object") {
                        const values = item?.[key]?.map((obj) => obj?.name) || [];
                        return values.some((obj) => obj?.toLowerCase()?.includes(searchFilters[key]?.toLowerCase()));
                    }
                }
                return true;
            });
        }
        return applicationsData(filterData, usersList);
    };

    /**
     * Filter Applications using UseMemo
     */
    const filterApplications = useMemo(() => prepareFilterApplications(applications, searchData), [applications, searchData]);

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDeleteDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge", params) => {
        dispatch(deleteApplicationRequest({ id: params.id, type: (type === "purge") }));
        dialogCancelEventHandle();
    };

    /**
     * Update UserPreference
     * @param {*} value
     */
    const updatePreference = (value) => {
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "table", "application", value);
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * On Column Change
     * @param {*} columns
     */
    const onColumnsChange = (columns) => {
        columns = columns.filter((column) => column.showColumn && column.key).map((column) => column.key);
        updatePreference({ columns });
    };

    return (
        <Grid container className={classes.appContainer} justifyContent="space-between">
            <Grid item xs={12}>
                <ValidatorForm onSubmit={() => { }}>
                    <TableComponent
                        stickyHeader
                        styleType="striped"
                        headers={permissionHeaders(appConstants.table.ApplicationTabHeader, semanticsPermission)}
                        isAdd={semanticsPermission?.is_edit}
                        data={filterApplications || []}
                        onCompnentEvent={onCompnentEvent}
                        onClickActions={handleTableActions}
                        onHandleChipAdd={handleChipAdd}
                        onHandleChipDelete={handleChipDelete}
                        onHandleChipSelect={onHandleChipSelect}
                        searchData={searchData}
                        onHandleSearchEvent={onHandleSearchEvent}
                        selectComponentList={{ 'users': usersList }}
                        options={appConstants.tableOptions.common}
                        title={appConstants.labels.securityTab.applications}
                        description={appConstants.labels.securityTab.applicationsDesc}
                        NoResultText="No Applications Found"
                        isLoading={isLoading}
                        height="calc(100vh - 296px)"
                        onColumnChange={(columns) => onColumnsChange(columns)}
                        userPreferenceColumns={columns || []}
                    />
                </ValidatorForm>
            </Grid>
            {
                showDeleteDialog.open && (
                    <DialogComponent
                        open={showDeleteDialog.open}
                        title={showDeleteDialog.title}
                        message={showDeleteDialog.message}
                        data={showDeleteDialog.data}
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle}
                    />
                )
            }
        </Grid>
    );
}

// default props
Applications.defaultProps = {
    classes: {}
};

// prop types
Applications.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SemanticAppStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Applications);