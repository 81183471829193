const style = (theme) => ({
    pattenDelete: {
        padding: '5px !important',
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    acceptIcon: {
        top: '-11px',
        right: '0x',
        position: 'absolute',
        "& .icon": {
            opacity: 1,
            background: '#fff',
            padding: '2px',
            boxShadow: '0px 0px 5px #00000026',
            transition: '0.5s all',
            visibility: 'visible',
            borderRadius: '30px'
        },
        "& svg": {
            width: '15px',
            height: '15px'
        },
        "& .accept": {
            background: `#fff !important`,
            '& svg': {
                fill: `#00cd08 !important`
            }
        },
        '& .reject': {
            background: `#fff !important`,
            '& svg': {
                fill: `#f5655a  !important`
            }
        }
    },
    defaultIcon: {
        top: 'unset !important',
        right: 'unset !important',
        position: 'relative !important',
        marginLeft: 10,
        "& .icon": {
            opacity: 1,
            background: 'transparent',
            padding: '6px',
            visibility: 'visible',
            borderRadius: '18px',
            boxShadow: 'none'
        },
        "& svg": {
            width: '20px',
            height: '20px'
        }
    }
});
export default style;