const style = (theme) => ({
    default: {
        '& svg': {
            fill: `${theme.palette.colorThemes.ok} !important`,
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    outlier: {
        '& svg': {
            fill: `${theme.palette.colorThemes.high} !important`
        }
    },
    normal: {
        '& svg': {
            fill: `${theme.palette.colorThemes.high} !important`
        }
    }
});

export default style;