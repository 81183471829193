import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withStyles } from '@mui/styles';

//  Import Styles
import ConnectorStyle from './style.jsx';
import styles from '../../layouts/private/styles.jsx';

// Import Components
import Connector from './connector.jsx';
import Asset from './asset/index.jsx';
import Snowflake from './snowflake/index.jsx';
import Mssql from './mssql/index.jsx';
import Tableau from './tableau/index.jsx';
import Redshift from './redshift/index.jsx';
import RedshiftSpectrum from './redshift_spectrum/index.jsx';
import Denodo from './denodo/index.jsx';
import Dbt from './dbt/index.jsx';
import Oracle from './oracle/index.jsx';
import PostgreSql from './postgreSql/index.jsx';
import Databricks from './databricks/index.jsx';
import Bigquery from './bigquery/index.jsx';
import DB2 from './db2/index.jsx';
import Fivetran from './fivetran/index.jsx';
import SapHana from './saphana/index.jsx';
import Athena from './athena/index.jsx';
import Teradata from './teradata/index.jsx';
import Synapse from './synapse/index.jsx';
import Airflow from './airflow/index.jsx';
import Talend from './talend/index.jsx';
import EmrSpark from './emr_spark/index.jsx';
import PowerBI from './powerbi/index.jsx';
import Hive from './hive/index.jsx';
import Adf from './adf/index.jsx';


function ConnectorRouter() {
    return (
        <Routes>
            <Route index element={<Connector />} />
            <Route exact path=":type/:id/assets" element={<Asset />} />
            <Route exact path="snowflake" element={<Snowflake />}>
                <Route exact path=":id" element={<Snowflake />} />
                <Route index element={<Snowflake />} />
            </Route>
            <Route exact path="mssql" element={<Mssql />}>
                <Route exact path=":id" element={<Mssql />} />
                <Route index element={<Mssql />} />
            </Route>
            <Route exact path="redshift" element={<Redshift />}>
                <Route exact path=":id" element={<Redshift />} />
                <Route index element={<Redshift />} />
            </Route>
            <Route exact path="redshift_spectrum" element={<RedshiftSpectrum />}>
                <Route exact path=":id" element={<RedshiftSpectrum />} />
                <Route index element={<RedshiftSpectrum />} />
            </Route>
            <Route exact path="tableau" element={<Tableau />}>
                <Route exact path=":id" element={<Tableau />} />
                <Route index element={<Tableau />} />
            </Route>
            <Route exact path="denodo" element={<Denodo />}>
                <Route exact path=":id" element={<Denodo />} />
                <Route index element={<Denodo />} />
            </Route>
            <Route exact path="dbt" element={<Dbt />}>
                <Route exact path=":id" element={<Dbt />} />
                <Route index element={<Dbt />} />
            </Route>
            <Route exact path="adf" element={<Adf />}>
                <Route exact path=":id" element={<Adf />} />
                <Route index element={<Adf />} />
            </Route>
            <Route exact path="oracle" element={<Oracle />}>
                <Route exact path=":id" element={<Oracle />} />
                <Route index element={<Oracle />} />
            </Route>
            <Route exact path="postgresql" element={<PostgreSql />}>
                <Route exact path=":id" element={<PostgreSql />} />
                <Route index element={<PostgreSql />} />
            </Route>
            <Route exact path="databricks" element={<Databricks />}>
                <Route exact path=":id" element={<Databricks />} />
                <Route index element={<Databricks />} />
            </Route>
            <Route exact path="bigquery" element={<Bigquery />}>
                <Route exact path=":id" element={<Bigquery />} />
                <Route index element={<Bigquery />} />
            </Route>
            <Route exact path="fivetran" element={<Fivetran />}>
                <Route exact path=":id" element={<Fivetran />} />
                <Route index element={<Fivetran />} />
            </Route>
            <Route exact path="saphana" element={<SapHana />}>
                <Route exact path=":id" element={<SapHana />} />
                <Route index element={<SapHana />} />
            </Route>
            <Route exact path="db2" element={<DB2 />}>
                <Route exact path=":id" element={<DB2 />} />
                <Route index element={<DB2 />} />
            </Route>
            <Route exact path="athena" element={<Athena />}>
                <Route exact path=":id" element={<Athena />} />
                <Route index element={<Athena />} />
            </Route>
            <Route exact path="teradata" element={<Teradata />}>
                <Route exact path=":id" element={<Teradata />} />
                <Route index element={<Teradata />} />
            </Route>
            <Route exact path="synapse" element={<Synapse />}>
                <Route exact path=":id" element={<Synapse />} />
                <Route index element={<Synapse />} />
            </Route>
            <Route exact path="airflow" element={<Airflow />}>
                <Route exact path=":id" element={<Airflow />} />
                <Route index element={<Airflow />} />
            </Route>
            <Route exact path="talend" element={<Talend />}>
                <Route exact path=":id" element={<Talend />} />
                <Route index element={<Talend />} />
            </Route>
            <Route exact path="emr_spark" element={<EmrSpark />}>
                <Route exact path=":id" element={<EmrSpark />} />
                <Route index element={<EmrSpark />} />
            </Route>
            <Route exact path="powerbi" element={<PowerBI />}>
                <Route exact path=":id" element={<PowerBI />} />
                <Route index element={<PowerBI />} />
            </Route>
            <Route exact path="hive" element={<Hive />}>
                <Route exact path=":id" element={<Hive />} />
                <Route index element={<Hive />} />
            </Route>
        </Routes>
    );
}

export default withStyles(
    (theme) => ({
        ...ConnectorStyle(theme),
        ...styles(theme)
    }),
    { withTheme: true }
)(ConnectorRouter);