import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid } from '@mui/material';
import Fields from './fields.jsx';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

function SelectedFields(props) {
    /**
     * Define Props
     */
    const { data, onDelete, accept, onDrop, sorting, onEdit, widgetType } = props;

    return (
        <Grid>
            {
                data.map((item, index) =>
                    <Grid key={`${item.name}_${index}`}>
                        <Fields
                            item={item}
                            index={index}
                            onDelete={onDelete}
                            accept={accept}
                            onDrop={onDrop}
                            sorting={sorting}
                            onEdit={onEdit}
                            widgetType={widgetType}
                        />
                    </Grid>
                )
            }

        </Grid>
    );
}

// default props
SelectedFields.defaultProps = {
    classes: {},
    data: [],
    onDelete: () => { },
    accept: "dimension",
    onDrop: () => { },
    sorting: false,
    onEdit: () => {},
    widgetType: ''
};

// prop types
SelectedFields.propTypes = {
    data: PropTypes.array,
    onDelete: PropTypes.func,
    accept: PropTypes.string,
    onDrop: PropTypes.func,
    sorting: PropTypes.bool,
    onEdit: PropTypes.func,
    widgetType: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SelectedFields);