import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

//  Import Components
import { Button, Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { SearchIcon } from '../../../../../assets/svg/index.js';

// Import Actions
import { catalogSearchUpdate } from '../../../../../redux/reducer/catalogReducer';
import { navigate } from '../../../../../redux/reducer/navigationReducer';

function MostSearches(props) {

    const { classes, data } = props;
    const dispatch = useDispatch();

    /**
     * Update Search Key
     * @param {*} value
     */
    const updateSearchKey = (value) => {
        dispatch(catalogSearchUpdate({ "search_key": value }));
        dispatch(navigate({ path: 'catalog.root', state: {}, params: [] }));
    };

    return (
        <Grid item xs={12} className={classes.searchListContainer}>
            {
                data && data.map((item, index) =>
                    <Button onClick={() => updateSearchKey(item.name)} key={`${index}_search_widget`} className={classes.searchedBtns} variant="outlined" color="secondary" size="large" startIcon={<SearchIcon />}>
                        <Typography variant="body1">
                            {item.name || ""}
                        </Typography>
                    </Button>
                )
            }

        </Grid>
    );
}

// default props
MostSearches.defaultProps = {
    classes: {},
    data: []
};

// prop types
MostSearches.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(MostSearches, areEqual));