import React from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//  Import Styles
import RightPanelInfoStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import { CircularScoreComponent } from '../../../../components/index.js';
import ConnectorsIconComponent from '../../../../components/connectorsIcon/index.jsx';

function RightPanelInfo(props) {

    /**
     * Define Props
     */
    const { classes, impacts, data, models_assets, handleNavigation } = props;

    /**
     * Bind Up and Down Stream Content
     * @param {*} values
     * @param {*} type
     */
    const bindDownandUpstreamContent = (values, type) => {
        return values.map((value, index) => {
            const asset = models_assets.find((asset) => asset.model_id === value);
            let fValue = value.split('.');
            const fType = fValue[0];
            fValue = fValue[fValue.length - 1];
            fValue = `${fValue} (${fType})`;
            return (
                <Grid
                    item
                    xs={12}
                    key={`${type}-${index}`}
                    className={`p5 ${asset && asset.asset_id ? classes.linkModel : ''}`}
                    onClick={() => handleNavigation(asset && asset.asset_id ? { ...asset, type: 'table' } : null)}>
                    <Typography variant="body1">
                        {fValue}
                    </Typography>
                </Grid>
            );
        });
    };

    return (
        <Grid item className={classes.rightPanel}>
            <Grid item className={classes.rightPanelInner}>
                {
                    data?.upstream?.length > 0 &&
                    <React.Fragment>
                        <Grid item xs={12} className="pt-1">
                            <Typography variant="h5">
                                Depends On
                            </Typography>
                        </Grid>
                        {bindDownandUpstreamContent(data.upstream, 'upstream')}
                    </React.Fragment>
                }
                {
                    data?.downstream?.length > 0 &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="pt-1">
                                Referenced By
                            </Typography>
                        </Grid>
                        {bindDownandUpstreamContent(data.downstream, 'downstream')}
                    </React.Fragment>
                }
                {
                    impacts?.length > 0 &&
                    <React.Fragment>
                        <Grid item xs={12} className="pt-1 pb-1">
                            <Typography variant="h5">
                                Impacted Assets
                            </Typography>
                        </Grid>
                        {
                            impacts?.map((item, index) =>
                                <Grid className={`dflex spaceBetween ${classes.impactedAssets}`} key={`key_${index}`}>
                                    <Grid className="dflex assetsItem">
                                        <ConnectorsIconComponent size={'small'} connection_type={item?.connection_type ?? ''} />
                                        <Grid
                                            className={`assetTitle  ${item.asset_id ? classes.linkModel : ''}`}
                                            onClick={() => handleNavigation(item.asset_id ? { ...item, type: 'table' } : null)}
                                        >
                                            <Typography variant="h6" className={'table_message'} noWrap>
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" className={`${classes.textSecondary}`} noWrap>
                                                {item.connection_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <CircularScoreComponent
                                        size={40}
                                        value={item?.dqscore ?? 0}
                                        showPercentage
                                        showValue
                                    />
                                </Grid>
                            )
                        }
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
}

// default props
RightPanelInfo.defaultProps = {
    classes: {},
    data: {},
    models_assets: [],
    impacts: [],
    handleNavigation: () => { }
};

RightPanelInfo.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    models_assets: PropTypes.array,
    impacts: PropTypes.array,
    handleNavigation: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...RightPanelInfoStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(RightPanelInfo);