import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getReportsAssetsRequest, getReportsAssetsSuccess, getReportsAssetsFailure, getReportsColumnsRequest, getReportsColumnsFailure,
  getReportsColumnsSuccess,
  getReportsRequest,
  getReportsFailure,
  getReportsSuccess,
  getReportByIdRequest,
  getReportByIdFailure,
  getReportByIdSuccess,
  createReportRequest,
  createReportFailure,
  createReportSuccess,
  updateReportRequest,
  updateReportFailure,
  updateReportSuccess,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
  getReportsPreviewDataRequest,
  getReportsPreviewDataFailure,
  getReportsPreviewDataSuccess,
  getReportsRunStatusRequest,
  getReportsRunStatusSuccess,
  getReportsRunStatusFailure,
  getReportsAssetsFilterRequest,
  getReportsAssetsFilterSuccess,
  getReportsAssetsFilterFailure,
  dashboardReportRequest,
  dashboardReportSuccess,
  dashboardReportFailure

} from '../reducer/reportsReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';


// Import Services
import { reportsService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';


/**
 * Get Reports Asset
 * @returns
 */
const get_report_assets = (action$) => action$.pipe(
  ofType(getReportsAssetsRequest),
  mergeMap(() => {
    return from(reportsService.get_report_assets()).pipe(
      concatMap((res) => { return of(getReportsAssetsSuccess(res)); }),
      catchError((error) => { return of(getReportsAssetsFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Get Reports Columns
 * @returns
 */
const get_report_columns = (action$) => action$.pipe(
  ofType(getReportsColumnsRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.get_report_columns(payload.params, payload.token)).pipe(
      concatMap((res) => { return of(getReportsColumnsSuccess(res)); }),
      catchError(() => { return of(getReportsColumnsFailure()); })
    );
  })
);

/**
 * Get Reports Preview Data
 * @returns
 */
const get_report_preview_data = (action$) => action$.pipe(
  ofType(getReportsPreviewDataRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.get_report_preview_data(payload.params, payload.token)).pipe(
      concatMap((res) => { return of(getReportsPreviewDataSuccess(res)); }),
      catchError(() => {
        return of(getReportsPreviewDataFailure());
      })
    );
  })
);


/**
 * Get All Reports
 * @returns
 */
const get_reports = (action$) => action$.pipe(
  ofType(getReportsRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.get_reports(payload.params, payload.token)).pipe(
      concatMap((res) => { return of(getReportsSuccess({ params: payload.params, data: res?.data ?? [] })); }),
      catchError(() => { return of(getReportsFailure()); })
    );
  })
);

/**
 * Get Report By Id
 * @returns
 */
const get_report_id = (action$) => action$.pipe(
  ofType(getReportByIdRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.get_report_id(payload)).pipe(
      concatMap((res) => { return of(getReportByIdSuccess(res)); }),
      catchError((error) => { return of(getReportByIdFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Create Report
 * @returns
 */
const create_report = (action$) => action$.pipe(
  ofType(createReportRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.create_report(payload)).pipe(
      concatMap((res) => { return of(createReportSuccess(res), navigate({ path: 'home.reports', state: {}, params: [] })); }),
      catchError((error) => { return of(createReportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update Report
 * @returns
 */
const update_report = (action$) => action$.pipe(
  ofType(updateReportRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(reportsService.update_report(id, rest)).pipe(
      concatMap((res) => { return of(updateReportSuccess(res), navigate({ path: 'home.reports', state: {}, params: [] })); }),
      catchError((error) => { return of(updateReportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Delete Report
 * @returns
 */
const delete_report = (action$) => action$.pipe(
  ofType(deleteReportRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.delete_report(payload)).pipe(
      concatMap(() => { return of(deleteReportSuccess(payload)); }),
      catchError((error) => { return of(deleteReportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

const getReportsRunStatus = (action$) => action$.pipe(
  ofType(getReportsRunStatusRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.getReportunStatus(payload)).pipe(
      concatMap((res) => { return of(getReportsRunStatusSuccess({ data: res?.data ?? [] })); }),
      catchError(() => {
        return of(getReportsRunStatusFailure(payload));
      })
    );
  })
);

/**
 * Get Assets Filters List
 * @returns
 */
const assets_filter = (action$) => action$.pipe(
  ofType(getReportsAssetsFilterRequest),
  mergeMap(() => {
    return from(reportsService.assets_filter()).pipe(
      concatMap((res) => { return of(getReportsAssetsFilterSuccess(res)); }),
      catchError((error) => { return of(getReportsAssetsFilterFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Create / Update Dashboard Report
 * @returns
 */
const dashboard_report = (action$) => action$.pipe(
  ofType(dashboardReportRequest),
  mergeMap(({ payload }) => {
    return from(reportsService.dashboard_report(payload)).pipe(
      concatMap((res) => { return of(dashboardReportSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.saved })); }),
      catchError((error) => { return of(dashboardReportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

// Export All Reports Functions
export const reportsEpic = [
  get_report_assets, get_report_columns, get_report_preview_data, get_reports, get_report_id,
  create_report, update_report, delete_report, getReportsRunStatus, assets_filter, dashboard_report
];