// Default Imports
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Import Components
import { Grid } from '@mui/material';
import { TableComponent } from '../tables/index.js';

// Import Styles
import style from "./style.jsx";

// Import Action
import { navigate } from '../../redux/reducer/navigationReducer';

// Import Helpers
import { dFormatter, getDayFilterDuration, toRound } from '../../helpers/appHelpers';

const TableChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, classes, onChangeProperties, filterProperties, showTableOptions, removeIdFieldsFromData, replaceUnderscore } = props;
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const tableRef = useRef();

    // Define State
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "", orderBy: "asc"
    });

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
        onChangeProperties("sorting", { sort: sortBy, order_by: orderBy });
    };


    /**
     * Get Headers
     * @param {*} data
     * @returns
     */
    const getHeaders = (data) => {

        let header_keys = data.length ? Object.keys(data[0]) : [];
        if (removeIdFieldsFromData) {
            header_keys = header_keys.filter((header) => !header.includes("_id") && !header.includes("allow_score"));
        }

        return header_keys.map((header) => {
            return {
                name: replaceUnderscore ? header.replace(/_/g, " ") : header,
                key: header,
                isSearch: true,
                sorting: true,
                clickable: !showTableOptions,
                width: "20%",
                searchComponent: 'text',
                searchKey: header,
                tooltip: true,
                type: header === "score" || header === "measure_score" ? "score" : "text",
                showNAmeasure: (header === "score" || header === "measure_score") && ['measure_name', 'attribute_name', 'asset_name'].some((key) => header_keys.includes(key)),
                renderAction: (data) => (data?.allow_score === false)
            };
        });
    };

    const headers = useMemo(() => getHeaders(data), [data]);

    /**
     * Set Restore User Sorting
     */

    useEffect(() => {
        if (Object.keys(filterProperties?.sorting ?? {}).length) {
            setSorting({
                sortBy: filterProperties?.sorting.sort,
                orderBy: filterProperties.sorting.order_by
            });
        } else if (sorting.sortBy === "" && headers.length) {
            setSorting({
                sortBy: headers[0]?.key, orderBy: "asc"
            });
        }
    }, []);

    /**
     * Redirect
     * @param {*} key
     * @param {*} item
     */
    const redirect = (key, item) => {
        switch (key) {
            case 'asset_name':
                dispatch(navigate({ path: 'assets.root', state: {}, params: [item.asset_id] }));
                break;
            case 'attribute_name':
                dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [item.asset_id, item.attribute_id] }));
                break;
            case 'domain_name':
                dispatch(navigate({ path: 'domain.detail', state: {}, params: [item.domain_id] }));
                break;
            case 'connection_name':
                dispatch(navigate({ path: 'connector.asset', state: {}, params: [item.connection_type, item.connection_id] }));
                break;
            case 'application_name':
                navigation(`/catalog?application=${item.application_id}`, { replace: true });
                break;
            case 'dimension_name':
                dispatch(navigate({ path: 'settings.semantics.dimension.root', state: {}, params: [] }));
                break;
            case 'tag_name':
                navigation(`/catalog?tag=${item.tag_id}`, { replace: true });
                break;
            case 'term_name':
                navigation(`/catalog?term=${item.term_id}`, { replace: true });
                break;
            case 'alerts':
            case 'alert_priority':
                const alertParams = new URLSearchParams({
                    asset: item.asset_name,
                    measure_name: item.measure_name,
                    status: item.alert_priority,
                    day: item.date_by_alert ? getDayFilterDuration(item.date_by_alert) : ""
                });
                navigation(`/home/alerts?${alertParams.toString()}`, { replace: true });
                break;
            case 'issues':
            case 'issue_status':
            case 'issue_priority':
                const issueParams = new URLSearchParams({
                    asset: item.asset_name,
                    priority: item.issue_priority,
                    status: item.issue_status
                });
                navigation(`/home/issues?${issueParams.toString()}`, { replace: true });
                break;
            default:
                break;
        }
    };


    /**
     * Prepare Filter
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilter = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = filterData.filter((item) => {
                for (const key of filters) {
                    let value = item[key] || "";
                    if (key === "score" || key === "measure_score") {
                        value = item[key] ? toRound(item[key]) : 0;
                        if ((searchFilters[key] === '0' && item.allow_score === false)) {
                            return false;
                        } else if (("na".includes(searchFilters[key].toLowerCase()) && item.allow_score === false)) {
                            return true;
                        }
                    }
                    value = typeof (item[key]) === "number" ? value.toString() : value;
                    if (typeof (value) === 'string' && !value.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            // for sting and number sort in order

            // filterData = filterData.map((elem) => (!isNaN(elem[sortBy]) ? { ...elem, [sortBy]: Number(elem[sortBy]) } : elem));
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
            if (filterData.length > 0 && sortBy === "score" && (Object.keys(filterData[0]).includes("allow_score"))) {
                const isAllowScore = filterData.filter((item) => item.allow_score === true);
                const isNotAllowScore = filterData.filter((item) => item.allow_score === false);
                filterData = orderBy === "asc" ? [..._.orderBy(isAllowScore, [sortBy], [orderBy]), ...isNotAllowScore] : [...isNotAllowScore, ..._.orderBy(isAllowScore, [sortBy], [orderBy])];
            }
        }
        filterData.forEach((item, index) => {
            if (item?.measure_name === 'Freshness' && item?.actual) {
                filterData[index] = Object.assign({}, item, { actual: dFormatter(item?.actual) });
            }
        });
        return filterData;
    };

    /**
     * Filter Table data using UseMemo
     */
    const filterData = useMemo(() => prepareFilter(data || [], searchData, sorting.sortBy, sorting.orderBy), [data || [], searchData, sorting.sortBy, sorting.orderBy]);

    const tableOptions = [{ type: "search", customFunction: null }, { type: "download", customFunction: null }, { type: "columns", customFunction: null }];

    const height = tableRef?.current?.parentElement?.offsetHeight ?? "400";
    return (
        <Grid className={classes.stripedTable} ref={tableRef}>
            <TableComponent
                headers={headers}
                options={showTableOptions ? tableOptions : []}
                data={filterData || []}
                styleType="rowStriped"
                height={height - 100}
                searchData={searchData}
                NoResultText="No Data Found"
                pagination
                onCellClick={redirect}
                sortBy={sorting?.sortBy ?? ''}
                orderBy={sorting?.orderBy ?? 'asc'}
                onClickSorting={onClickSorting}
                onHandleSearchEvent={(key, value) => onHandleSearchEvent(key, value)}
                exportParams={
                    {
                        "fileName": "table.csv"
                    }
                }
                stickyHeader
            />
        </Grid>
    );
};


/**
 * Define Prop Types
 */
TableChartComponent.propTypes = {
    data: PropTypes.array,
    classes: PropTypes.object,
    onChangeProperties: PropTypes.func,
    filterProperties: PropTypes.object,
    showTableOptions: PropTypes.bool,
    removeIdFieldsFromData: PropTypes.bool,
    replaceUnderscore: PropTypes.bool
};

/**
 * Set Default Values
 */
TableChartComponent.defaultProps = {
    data: [],
    classes: {},
    onChangeProperties: () => { },
    filterProperties: {},
    showTableOptions: true,
    removeIdFieldsFromData: true,
    replaceUnderscore: true
};


export default withStyles((theme) => ({
    ...style(theme)
}))(TableChartComponent);