import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//  Import Components
import { Grid } from '@mui/material';
import Trending from './trending/index.jsx';
import Domain from './domain/index.jsx';
import MostLastestTopics from './mostLatestTopics/index.jsx';
import LatestComments from './latestComments/index.jsx';
import TopUsers from './topUsers/index.jsx';
import MostSearches from './mostSearches/index.jsx';
import MostRatedAssets from './mostRatedAssets/index.jsx';
import DQScore from './dqscore/index.jsx';
import Organization from './organization/index.jsx';
import LastAlerts from './lastAlerts/index.jsx';
import IssuesMetric from './issuesMetric/index.jsx';
import AlertsMetric from './alertsMetric/index.jsx';
import LastIssues from './lastIssues/index.jsx';
import Historical from './historical/index.jsx';
import {
    DynamicBarChartComponent, LineChartComponent, PolarChartComponent, RadarChartComponent, HeatMapChartComponent, SunBurstChartComponent,
    DynamicLineChartComponent, ScatterPunchChartComponent, NoResultComponent
} from '../../../../components/index';

// Import Helpers
import { getDayFilterDuration, prepareHierarchy, toRound } from '../../../../helpers/appHelpers';
import palette from '../../../../assets/theme/palette';


function WidgetComponents(props) {

    const { data } = props;
    const navigation = useNavigate();

    const { scoring } = useSelector((state) => state.configurations.general);
    const dashboard = useSelector(({ dashboard }) => dashboard.detail);

    /**
     * Define the color based on the score
     * @param {*} value
     * @returns
     */
    const getColor = (value) => {
        const { values, quadrant } = scoring;
        const QuadSettig = values[quadrant - 2];
        for (let i = 0; i < QuadSettig?.length; i++) {
            if (Math.ceil(value) >= QuadSettig[i].from && Math.ceil(value) <= QuadSettig[i].to) {
                return QuadSettig[i].color;
            }
        }
        return palette.secondary.main;
    };

    /**
     * Get Days Filter
     * @param {*} date
     * @returns
     */
    const getDaysFilter = (date) => {
        if (date.includes("between")) {
            return "All";
        } else if (date.includes("30")) {
            return "Last 30 days";
        } else if (date.includes("14")) {
            return "Last 14 days";
        } else if (date.includes("7")) {
            return "Last 7 days";
        } else if (date.includes("3")) {
            return "Last 3 days";
        }
        return "All";
    };

    /**
     * On Chart Event
     * @param {*} data
     * @param {*} type
     */
    const onChartEvent = (data, type) => {
        if (type === "Health Alerts by Type") {
            const day = getDayFilterDuration(data.name);
            navigation(`/home/alerts?measure_name=${data.seriesName}&day=${day}`, { replace: true });
        } else if (type === "Alerts by category over status") {
            const days = getDaysFilter(dashboard?.filters?.date ?? "all");
            navigation(`/home/alerts?priority=${data.seriesName}&day=${days}&measure_type=${data.name}`, { replace: true });
        } else if (type === "Issue by Status over priority") {
            const days = getDaysFilter(dashboard?.filters?.date ?? "all");
            navigation(`/home/issues?priority=${data.seriesName}&day=${days}&status=${data.name}`, { replace: true });
        } else if (type === "Dimensions") {
            navigation(`/asset/${data}/properties`, { replace: true });
        }
    };


    /**
     * Render Widget
     * @returns
     */
    const renderWidget = () => {
        const selectedValue = data?.filter_properties?.value ?? "default";
        let report = data?.report?.[selectedValue] ?? [];
        const chartProperties = data?.properties?.chartProperties ?? {};
        const statWidgets = ['trending', 'issues', 'alerts', 'historical'];
        if (!report.length && !statWidgets.includes(data.widget_type)) {
            return (
                <NoResultComponent title={data.disablePermission ? "Insufficient Permission" : "No results found"} />
            );
        }
        switch (data.widget_type) {
            case 'trending':
                return (<Trending data={data} />);
            case 'polar':
                return (<PolarChartComponent
                    data={report[0]}
                    color={data?.properties?.color}
                    containerId={`${data.name}_chart_container`}
                    labelFormatType={"percentage"}
                    showPercentage />
                );
            case 'bar':
                const barData = {
                    yAxis: report.map((data) => data.count || data.score),
                    xAxis: report.map((data) => data.name || data.date),
                    data: report.map((data) => data.count || toRound(data?.score ?? 0))
                };
                if (chartProperties.score) {
                    const colors = data?.properties?.color ?? [];
                    barData.data = report.map((data) => {
                        return {
                            value: data.count || toRound(data?.score ?? 0),
                            itemStyle: {
                                color: getColor((data.count || toRound(data.score ?? 0)), colors)
                            }
                        };
                    });
                }
                return (<DynamicBarChartComponent
                    containerId={`${data.name}_chart_container`}
                    chartData={barData}
                    color={data?.properties?.color}
                    showXAxis
                    showYAxis
                    xAxisType="category"
                    yAxisType="value"
                    showLegends
                    chartProperties={chartProperties} />);
            case 'horizontal_bar':
                const chartData = {
                    xAxis: report.map((data) => data.count),
                    yAxis: report.map((data) => data.name),
                    data: report.map((data) => data.count)
                };
                return (<DynamicBarChartComponent
                    containerId={`${data.name}_chart_container`}
                    chartData={chartData}
                    color={data?.properties?.color}
                    showXAxis={false}
                    showYAxis
                    xAxisType="value"
                    yAxisType="category"
                    showLegends={false}
                    chartProperties={chartProperties} />);
            case 'domain':
                return (<Domain data={data.report} />);
            case 'horizontal_stacked_bar':
            case 'vertical_stacked_bar':
                let stacked_data = {};
                if (report.length && report[0].data) {
                    report = report.map((obj) => {
                        const data = JSON.parse(obj.data);
                        const statis = Object.assign({}, ...data.map((item) => ({ [item.name]: item.count })));
                        return {
                            name: obj.name,
                            High: statis.High || 0,
                            Medium: statis.Medium || 0,
                            Low: statis.Low || 0
                        };
                    });
                }
                for (const item of report) {
                    const keys = Object.keys(item).filter((key) => key !== "name" && key !== "status" && key !== "date" && key !== "total_count");
                    for (const key of keys) {
                        if (stacked_data[key]) {
                            stacked_data[key].series.push(item[key]);
                        } else {
                            stacked_data[key] = {
                                series: [item[key]]
                            };
                        }
                    }
                }
                stacked_data = Object.keys(stacked_data).map((a) => {
                    return {
                        name: a,
                        ...stacked_data[a]
                    };
                });
                const domains = report.map((item) => item.name || item.status);
                return (
                    <DynamicBarChartComponent
                        containerId={`${data.name}_chart_container`}
                        chartData={
                            {
                                xAxis: data.widget_type !== "horizontal_stacked_bar" ? domains : [],
                                yAxis: report.map((a) => a.name || a.status),
                                data: stacked_data
                            }
                        }
                        color={data?.properties?.color}
                        chartProperties={chartProperties}
                        showXAxis={data.widget_type !== "horizontal_stacked_bar"}
                        showYAxis
                        xAxisType={data.widget_type === "horizontal_stacked_bar" ? "value" : "category"}
                        yAxisType={data.widget_type === "horizontal_stacked_bar" ? "category" : "value"}
                        type="stacked_bar"
                        onCallback={(params) => onChartEvent(params, data.name)}
                        showLegends />
                );
            case 'line':
                let lineData = [];
                let multiLine = false;
                if (Object.keys(report[0]).length > 2) {
                    multiLine = true;
                    const uniqueNames = [...new Set(report.map((obj) => obj.date))];
                    const groupData = report.reduce((r, a) => {
                        r[a.name] = r[a.name] || [];
                        r[a.name].push(a);
                        return r;
                    }, Object.create(null));
                    const stackedData = [];
                    for (const key of Object.keys(groupData)) {
                        const data = groupData[key];
                        const stack = { name: key, series: [] };
                        for (const name of uniqueNames) {
                            const item = data.find((obj) => obj.date === name);
                            stack.series.push(item ? toRound(item?.score ?? 0) : 0);
                        }
                        stackedData.push({ ...stack });
                    }
                    lineData = {
                        xAxis: uniqueNames,
                        data: stackedData
                    };
                } else {
                    lineData = report.map((obj) => {
                        return {
                            x: obj.created_date || obj.date,
                            y: obj.count || toRound(obj?.score ?? 0)
                        };
                    });
                }
                return (!multiLine ? <LineChartComponent
                    data={lineData}
                    isSmooth
                    color={data?.properties?.color}
                    formatType={chartProperties.formatType || "Count"}
                    showXAxis
                    showYAxis
                    dateFormat={"MMM DD YYYY"} /> :
                    <DynamicLineChartComponent
                        chartData={lineData}
                        isStacked
                        type="stacked"
                    />);
            case 'radar':
                const radarData = {
                    indicator: report.map((obj) => {
                        return {
                            name: obj.name,
                            max: 100
                        };
                    }),
                    data: report.map((obj) => (obj.score ? toRound(obj?.score ?? 0) : 0)),
                    name: data.name || ""
                };
                const score = data?.report?.score ?? [];
                return (
                    <RadarChartComponent
                        data={radarData}
                        color={data?.properties?.color}
                        score={score.length ? score[0]?.avg ?? 0 : 0}
                        chartProperties={chartProperties}
                    />
                );
            case 'heat_map':
                const heatMapData = report.map((data) => {
                    return {
                        name: data.name,
                        id: data.asset_id,
                        metrics: JSON.parse(data.metrics),
                        t_score: data.t_score
                    };
                });
                return (
                    <HeatMapChartComponent data={heatMapData} type="asset" handleOnClick={(value) => onChartEvent(value, data.name)} />
                );
            case 'latest_topics':
                return (<MostLastestTopics data={report} />);
            case 'latest_comments':
                return (<LatestComments data={report} />);
            case 'users':
                return (<TopUsers data={report || []} />);
            case 'most_search':
                return (<MostSearches data={report} />);
            case 'most_rate_asset':
                return (<MostRatedAssets data={report || []} />);
            case 'dq_score':
                return (<DQScore data={report || []} selectedTab={selectedValue} />);
            case 'organization_chart':
                const hirerchyData = prepareHierarchy(report);
                return (<Organization data={hirerchyData || []} />);
            case 'distribution':
                const distributeData = [];
                const colors = data?.properties?.color ?? [];
                const uniqueDistribute = _.uniqBy(
                    _.flatMap(report, 'application_name')
                );
                for (const application of uniqueDistribute) {
                    const appData = report.filter((data) => data.application_name === application);
                    const groupAsset = _.uniqBy(
                        _.flatMap(appData, 'asset_type')
                    );
                    const assetResult = [];
                    for (const asset of groupAsset) {
                        const data = appData.filter((obj) => obj.asset_type === asset);
                        const groupTags = _.uniqBy(
                            _.flatMap(data, 'tag_name')
                        ).filter((tag) => tag);
                        const tagResult = groupTags.map((tag) => {
                            const tagInfo = data.filter((obj) => obj.tag_name === tag);
                            return {
                                name: tag,
                                value: tagInfo.reduce((a, b) => a + b.tag_count, 0),
                                itemStyle: {
                                    color: colors[2]
                                }
                            };
                        });
                        assetResult.push({
                            name: asset,
                            value: data.reduce((a, b) => a + b.asset_type_count, 0),
                            children: tagResult,
                            itemStyle: {
                                color: colors[1]
                            }
                        });
                    }
                    distributeData.push({
                        name: application,
                        value: assetResult.reduce((a, b) => a + b.value, 0),
                        children: assetResult,
                        itemStyle: {
                            color: colors[0]
                        }
                    });
                }
                const legends = [{ name: "Application", color: colors[0] }, { name: "Asset Type", color: colors[1] }, { name: "Tag", color: colors[2] }];
                return (<SunBurstChartComponent data={distributeData || []} legends={legends} />);
            case 'last_alerts':
                return (<LastAlerts data={report || []} />);
            case 'last_issues':
                return (<LastIssues data={report || []} />);
            case 'issues':
                return (<IssuesMetric data={report.length ? report[0] : []} />);
            case 'alerts':
                return (<AlertsMetric data={report.length ? report[0] : []} color={data?.properties?.color ?? []} />);
            case 'multiline':
                const xAxis = report.map((item) => item.created_date);
                const result = _.uniqBy(
                    _.flatMap(report, 'metric'),
                    'name'
                );
                const resultData = result.map((obj) => { return { name: obj.name, count: 0 }; });
                const series = report.map((obj) => {
                    const metrics = _.uniqBy([...obj.metric, ...resultData], (obj) => {
                        return obj.name;
                    });
                    return [...metrics];
                });
                const seriesData = [];
                for (const key of result) {
                    const seriesObject = {
                        name: key.name,
                        data: []
                    };
                    for (const item of series) {
                        const itemData = item.find((a) => a.name === key.name);
                        seriesObject.data.push(itemData.count || 0);
                    }
                    seriesData.push(seriesObject);
                }
                return (<DynamicLineChartComponent chartData={
                    {
                        xAxis: xAxis,
                        data: seriesData
                    }
                }
                    isStacked
                    type="stacked"
                    color={data?.properties?.color}
                    onCallback={(params) => onChartEvent(params, data.name)}
                />);
            case 'scatter':
                const resultColumns = _.uniqBy(
                    _.flatMap(report, 'data'),
                    'name'
                ).map((data) => data.name).sort();
                const scatterResultData = resultColumns.map((obj) => { return { name: obj, count: 0 }; });
                const scatterSeries = [];
                const columns = Object.assign({}, ...resultColumns.map((a, index) => {
                    return {
                        [a]: index
                    };
                }));
                for (const index in report) {
                    const scatterData = _.uniqBy([...report[index].data, ...scatterResultData], (obj) => {
                        return obj.name;
                    });
                    for (const obj of scatterData) {
                        scatterSeries.push([columns[obj.name], parseInt(index), obj.count]);
                    }
                }
                return (
                    <ScatterPunchChartComponent
                        chartData={
                            {
                                xAxis: resultColumns,
                                yAxis: report.map((obj) => obj.name),
                                data: scatterSeries
                            }
                        }
                        color={data?.properties?.color}
                    />
                );
            case 'historical':
                return (
                    <Historical data={report || []} type={selectedValue} />
                );
            default:
                break;
        }
    };
    return (
        <Grid style={{ width: '100%', height: '100%' }} id={`${data.name}_chart_container`} >
            {renderWidget()}
        </Grid>
    );
}

// default props
WidgetComponents.defaultProps = {
    data: {}
};

// prop types
WidgetComponents.propTypes = {
    data: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default (React.memo(WidgetComponents, areEqual));