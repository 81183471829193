const style = (theme) => ({
    issuesPageContainer: {
        background: theme.palette.greyshades.lightbackground,
        padding: '0px 24px 24px',
        '& .priorityChip': {
            background: 'transparent !important',
            padding: '0px !important',
            '& .MuiChip-icon': {
                marginLeft: '0px !important',
                marginRight: '0px !important'
            }
        },
        '& .MuiChip-label': {
            fontSize: 15
        },
        '& .chipContainer': {
            height: 28,
            '& .MuiInput-input': {
                padding: '0px 4px 4px 0px !important'
            }
        },
        '@media only screen and (min-width: 1500px)': {
            '& .MuiGrid-grid-xs-3': {
                flexBasis: '30%',
                maxWidth: '30%'
            },
            '& .MuiGrid-grid-xs-9': {
                flexBasis: '70%',
                maxWidth: '70%'
            }
        },
        '@media only screen and (max-width: 1500px)': {
            '& .MuiGrid-grid-xs-3': {
                flexBasis: '365px',
                maxWidth: '365px'
            },
            '& .MuiGrid-grid-xs-9': {
                flexBasis: 'auto',
                maxWidth: 'auto',
                flex: 1
            }
        }
    },
    issuesBodyContainer: {
        background: '#fff',
        paddingTop: '24px !important'
    }
});
export default style;