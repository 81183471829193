const style = (theme) => ({
    featureCardContainer: {
        background: theme.palette.greyshades.lightbackground,
        borderRadius: '4px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        padding: '20px 24px',
        marginBottom: 15,
        textDecoration: 'none',
        '& .commentText p': {
            marginBottom: '0px',
            '& asset': {
                color: `${theme.palette.secondary.main} !important`
            },
            '& mention': {
                color: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiAvatar-root': {
            width: 36,
            height: 36
        }
    }
});
export default style;