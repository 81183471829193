import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import StyleGuide from './styleGuide/index.jsx';
import Features from './features/index.jsx';

//  Import Styles
import ThemeStyle from "./style.jsx";
import LayoutStyles from '../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../constants/appConstants.js';
import palette from "../../../../src/assets/theme/palette";

//Import actions
import { themeDetailRequest, updateThemeInReducer, createBannerRequest, getBannerRequest, deleteBannerRequest, themeUpdateRequest } from '../../../redux/reducer/configurationsReducer';
import { checkPermission } from '../../../helpers/appHelpers.js';
import featureConstants from '../../../constants/featureConstants.js';

function Theme(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [styleChange, setStyleChange] = useState({});

    /**
     * Redux selector function to get configurations detail from redux store
     */
    const { theme, banner: { list, isLoaded } } = useSelector((state) => state.configurations);
    const { permission } = useSelector((state) => state.auth);
    const themePermission = checkPermission(permission, featureConstants.settings.theme);


    /**
     * Set Initial Theme State from reducer
     */
    useEffect(() => {
        setStyleChange({ ...theme });
    }, [theme]);


    /**
     * Dispatch theme detail request
     */
    useEffect(() => {
        dispatch(themeDetailRequest());
        if (!isLoaded) {
            dispatch(getBannerRequest());
        }
    }, [dispatch]);

    const filterTheme = (uTheme = {}) => {
        const fTheme = {
            features: {
                id: uTheme?.id ?? '',
                copyright: uTheme?.copyright ?? '',
                tagline: uTheme?.tagline ?? '',
                app_title: uTheme?.app_title ?? '',
                logo: uTheme?.logo ?? '',
                banner: uTheme?.banner ?? []
            },
            styleGuide: {
                colors: {
                    primary: {
                        main: uTheme?.theme?.primary?.main
                    },
                    secondary: {
                        main: uTheme?.theme?.secondary?.main
                    }
                },
                headers: uTheme?.theme?.headers ?? {},
                buttons: {
                    primaryButton: uTheme?.theme?.headers?.primaryButton ?? {},
                    secondaryButton: uTheme?.theme?.headers?.secondaryButton ?? {},
                    textLink: uTheme?.theme?.headers?.textLink ?? {}
                },
                greyshades: uTheme?.theme?.greyshades ?? {}
            }
        };
        return fTheme;
    };

    /**
     * Filter Using UseMemo
     */
    const update_theme = useMemo(() => filterTheme(styleChange), [styleChange]);

    /**
     * Update Theme
     * @param {*} property
     * @param {*} value
     */
    const handleThemeChange = (uData, type) => {
        let uTheme = {};
        switch (type) {
            case 'headers':
                uTheme = {
                    ...styleChange,
                    theme: {
                        ...styleChange.theme,
                        headers: { ...uData }
                    }
                };
                break;
            case 'buttons':
                uTheme = {
                    ...styleChange,
                    theme: {
                        ...styleChange.theme,
                        headers: {
                            ...styleChange.theme.headers,
                            ...uData
                        }
                    }
                };
                break;
            case 'greyshades':
                uTheme = {
                    ...styleChange,
                    theme: {
                        ...styleChange.theme,
                        greyshades: { ...uData }
                    }
                };
                break;
            case 'colors':
                uTheme = {
                    ...styleChange,
                    theme: {
                        ...styleChange.theme,
                        primary: {
                            ...styleChange.theme.primary,
                            main: uData.primary.main
                        },
                        secondary: {
                            ...styleChange.theme.secondary,
                            main: uData.secondary.main
                        }
                    }
                };
                break;
            default:
                uTheme = { ...styleChange, ...uData };
                break;
        }
        setStyleChange({ ...uTheme });
    };

    /**
     * Logo Image Upload
     * @param {*} croppedImage
     * @param {*} croppedImageUrl
     */
    const handleImageUpload = (croppedImage, croppedImageUrl, action) => {
        const updatedTheme = { ...theme, 'file_to_upload': croppedImage, 'logo': croppedImageUrl, logoAction: action };
        dispatch(updateThemeInReducer(updatedTheme));
    };

    /**
     * Banner Images Upload
     * @param {*} croppedImage
     */
    const handleBannerImages = (croppedImage) => {
        dispatch(createBannerRequest({ 'file_to_upload': croppedImage }));
    };

    /**
     * Delete Banner Images
     * @param {*} bannerId
     */
    const onDeleteBanner = (bannerId) => {
        dispatch(deleteBannerRequest(bannerId));
    };

    /**
     * Dispatch theme update request
     */
    const handleThemeSave = () => {
        const new_details = { ...styleChange };
        dispatch(updateThemeInReducer({ ...new_details }));
        dispatch(themeUpdateRequest({ ...new_details }));
    };

    /**
     * Dispatch theme update request for its default values
     */
    const handleThemeReset = () => {
        dispatch(updateThemeInReducer({ ...styleChange, theme: { ...palette } }));
        dispatch(themeUpdateRequest({ ...styleChange, theme: { ...palette } }));
    };

    return (
        <Grid container className={classes.themePageContainer}>
            <Grid item xs={12} className={classes.muiChip}>
                <Typography variant="h5" className="pb-1 header">
                    {appConstants.labels.theme.editTheme}
                </Typography>
                <Typography variant="body1" className="pb-2">
                    {appConstants.labels.theme.editThemeDesc}
                </Typography>
            </Grid>
            <Features data={update_theme.features || {}} handleThemeChange={handleThemeChange} handleImageUpload={handleImageUpload} handleBannerImages={handleBannerImages} banner_list={list} delete_banner={onDeleteBanner} editPermission={themePermission?.is_edit} />
            <StyleGuide data={update_theme.styleGuide || {}} handleThemeChange={handleThemeChange} handleThemeSave={handleThemeSave} handleThemeReset={handleThemeReset} editPermission={themePermission?.is_edit} />
        </Grid>
    );
}

// default props
Theme.defaultProps = {
    classes: {}
};

// prop types
Theme.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ThemeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Theme);