const style = (theme) => ({
    viewContainer: {
        display: "flex",
        padding: 10,
        width: '100%'
    },
    dargField: {
        marginTop: '20px !important'
    },
    dragBox: {
        background: `${theme.palette.greyshades.tablegrey}80`,
        minWidth: '25%',
        marginRight: '20px !important'
    },
    termsStripeCard: {
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: `${theme.palette.secondary.main}30`,
        '&.disabled': {
            background: `${theme.palette.greyshades.grey} !important`
        }
    },
    termsCard: {
        borderRadius: 2,
        boxShadow: '0px 1px 8px #00000021',
        background: '#fff',
        height: '100%',
        padding: '18px',
        borderLeft: '5px solid',
        marginTop: '15px !important'
    },
    assetsText: {
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.greyshades.darkgrey} !important`,
        '& span.assetDot': {
            background: theme.palette.greyshades.lightgrey,
            height: '6px',
            width: '6px',
            borderRadius: '30px',
            margin: '5px 10px'
        }
    },
    typeLabel: {
        float: "left !important",
        color: `${theme.palette.secondary.main} !important`,
        background: theme.palette.greyshades.lightgrey,
        padding: '3px 6px',
        fontSize: '14px !important',
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        textTransform: 'capitalize !important',
        flexShrink: 0,
        textAlign: "center",
        minWidth: '15px'
    }
});

export default style;