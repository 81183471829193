// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Dimension
 * @returns
 */
async function getDimension() {
  const response = await getRequest(`${urlConstants.dimension.url}`);
  return response;
}

/**
 * Create Dimension
 * @param {*} params
 * @returns
 */
async function createDimension(params) {
  const response = await postRequest(`${urlConstants.dimension.url}`, params);
  return response;
}

/**
 * update Dimension
 * @param {*} dimensionId
 * @param {*} params
 * @returns
 */
async function updateDimension(dimensionId, params) {
  const response = await putRequest(`${urlConstants.dimension.url}${dimensionId}/`, params);
  return response;
}

/**
 * Delete Dimension
 * @param {*} dimensionId
 * @returns
 */
async function deleteDimension(dimensionId, purge) {
  const response = await deleteRequest(`${urlConstants.dimension.url}${dimensionId}/?purge=${purge}`);
  return response;
}

/**
 * Export All Service Functions
 */
export const dimensionService = {
  getDimension, createDimension, deleteDimension, updateDimension
};