const style = (theme) => ({
    closeIcon: {
        marginLeft: '20px !important',
        '& svg': {
            width: '24px',
            height: '24px'
        }
    },
    addEditHeader: {
        position: 'sticky',
        top: 0,
        background: '#fff',
        paddingTop: 30,
        zIndex: 99,
        '& .CloseIcon': {
            '&:hover': {
                fill: theme.palette.secondary.main
            }
        },
        '& svg.CalendarIcon': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.greyshades.darkgrey} !important`
            },
            '&:hover': {
                '& path, circle, ellipse': {
                    fill: `${theme.palette.secondary.main} !important`
                }
            }
        },
        '& p::first-letter ': {
            textTransform: 'capitalize'
        }
    },
    label: {
        color: `${theme.palette.greyshades.darkgrey} !important`
    },
    sliderContainer: {
        width: '60%'
    },
    weightageContainer: {
        '& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-input': {
            height: '10px !important',
            width: '60px'
        }
        /*
         * '& .MuiFormHelperText-root': {
         *     textAlign: 'left',
         *     marginRight: '0px',
         *     fontSize: '12px',
         *     marginLeft: '5px'
         * }
         */
    },
    weightageContainerExpand: {
        width: 240,
        '& .textBoxContainer .MuiTextField-root': {
            alignItems: 'flex-start'
        },
        '& .textBoxContainer .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    scoringContainer: {
        display: 'flex',
        '& .MuiFormControlLabel-root': {
            // marginRight: '-25px !important'
        },
        '& .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-input': {
            padding: '10.5px 12px 10.5px 3px !important',
            width: '50px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiTypography-root': {
            color: `${theme.palette.greyshades.darkgrey} !important`,
            fontSize: '16px',
            fontWeight: 600
        },
        '& .MuiInputAdornment-root': {
            opacity: '0'
        },
        '& .selectComponent': {
            position: 'relative',
            right: '-47px',
            top: '4px',
            zIndex: 9,
            '& .MuiSelect-select': {
                paddingRight: '10px !important'
            },
            '& .ChevDownIcon': {
                display: 'none'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderBottom: 'none !important'
            },
            '& .MuiOutlinedInput-root': {
                fontSize: '18px'
            }
        },
        '& .textBoxContainer .MuiTextField-root': {
            alignItems: 'flex-start'
        },
        '& .textBoxContainer .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    measuresAction: {
        '& .Mui-disabled': {
            opacity: '0.4'
        }
    },
    switchesContainer: {
        '@media only screen and (max-width: 1500px)': {
            '& .pr-4': {
                paddingRight: '0px !important'
            }
        }
    },
    customFields: {
        '& .fieldItems': {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }
    }
});
export default style;