const style = () => ({
    tagsPageContainer: {
        padding: '20px 0px 0px',
        '& .MuiTableCell-head:first-child': {
            // paddingLeft: '0px !important'
        },
        '& .MuiTableCell-head:last-child': {
            paddingRight: '0px !important'
        }
    },
    tagsTableContainer: {
        // height: 'calc(100vh - 312px)',
        overflowY: 'auto'
    }
});
export default style;