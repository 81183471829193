const style = (theme) => ({
    templateCard: {
        border: `2px solid ${theme.palette.greyshades.lightgrey}`,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px !important',
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}95`,
            cursor: 'pointer',
            '& .MuiTypography-h6': {
                color: theme.palette.secondary.main
            }
        },
        '& .leftSection': {
            display: 'flex',
            alignItems: 'center',
            padding: 15,
            height: '150px',
            background: theme.palette.greyshades.lightbackground
        },
        '& .rightSection': {
            padding: 24,
            '& .cardDesc': {
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
            }
        },
        '& .dashboardImg': {
            width: 140
        }
    }
});

export default style;