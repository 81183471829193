import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';

//  Import Components
import { Grid } from '@mui/material';
import { NavigationTabHeaderComponent } from '../../components';

//  Import Styles
import SettingsStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Helper
import appConstants from '../../constants/appConstants.js';
import { useSelector } from 'react-redux';
import { permissionTabs } from '../../helpers';

// Import Images

function Settings(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const { permission } = useSelector((state) => state.auth);

    const scrollRef = useRef();
    return (
        <Grid container className={classes.settingsPageContainer}>
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid container>
                    <NavigationTabHeaderComponent tabList={permissionTabs(permission, appConstants.tabs.settingsTabs)} />
                </Grid>
                <Grid item xs={12} className={classes.tabsBody} ref={scrollRef}>
                    <Outlet context={scrollRef.current} />
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Settings.defaultProps = {
    classes: {}
};

// prop types
Settings.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SettingsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Settings);