/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment-timezone';

// Import Components
import { SelectComponent, DialogComponent, AutoCompleteComponent } from '../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Constnant
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { orderList } from '../../../../helpers';

// Import Reducers
import { getDomainListRequest } from '../../../../redux/reducer/semanticReducer.js';
import { getApplicationsRequest } from '../../../../redux/reducer/applicationReducer';
import Scheduler from '../../../../containers/dashboard/reports/scheduler.jsx';
import { DeleteIcon } from '../../../../assets/svg/index.js';


function EditMeasureDialog(props) {

    /**
     * Define Props
     */
    const { classes, open, onClose, onEditMeasure, selectedTemplate } = props;
    const dispatch = useDispatch();

    const timeZones = [
        { name: moment.tz.guess() },
        ...appConstants.schedule.TimeZones
    ];

    const default_schdule = {
        level: 'measure',
        schedule_type: 'measure',
        type: appConstants.schedule.scheduleTypes[2].name,
        duration: '1',
        start_date: moment().format('MM-DD-YYYY HH:mm'),
        configuration: {
            time: ''
        },
        multiTime: [],
        timezone: timeZones && timeZones.length > 0 ? timeZones[0].name : '',
        schedule_method: 'time',
        is_event_schedule: false,
        target_type: "Asset"
    };

    /**
     * Define State
     */
    const [measureDetail, setMeasureDetail] = useState({ key: "", value: "" });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


    /** Redux Store */
    const dimensions = useSelector(({ dimension }) => dimension.searchableDimensionList);
    const { searchableGlossaries } = useSelector((state) => state.semantic, shallowEqual);
    const { searchableApplicationsList } = useSelector((state) => state.applications, shallowEqual);
    const { assetMeasures } = useSelector(({ measure }) => measure);

    /**
     * Get Applications and Glossary List
     */
    useEffect(() => {
        if (!searchableGlossaries || searchableGlossaries.length === 0) {
            dispatch(getDomainListRequest());
        }
        if (!searchableApplicationsList || searchableApplicationsList.length === 0) {
            dispatch(getApplicationsRequest());
        }
    }, [dispatch]);


    /**
     * Filter Domains Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossaries), [searchableGlossaries]);

    /**
     * Filter Applications Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsList), [searchableApplicationsList]);


    /**
     * Handle On Change Event
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const detail = { ...measureDetail };
        if (property === "key") {
            detail.value = "";
            if (value === "schedule") {
                detail.value = default_schdule;
            }
        }
        detail[property] = value;
        setMeasureDetail({ ...detail });
    };

    /**
     * Handle Submit
     */
    const onSubmit = () => {
        setOpenConfirmDialog(true);
    };

    /**
     * Handle Close Dialog
     */
    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        onClose();
    };

    /**
     * Handle Delete Schedule
     */
    const onDeleteSchedule = () => {
        const detail = { ...measureDetail };
        detail.value = "delete";
        setMeasureDetail({ ...detail });
        setOpenConfirmDialog(true);
    };

    /**
     * Handle Confirm Action
     */
    const confirmAction = () => {
        let params = [];
        if (measureDetail.key === 'domains' || measureDetail.key === 'applications' || measureDetail.key === 'status') {
            params = {
                [measureDetail.key]: measureDetail.value
            };
        } else if (measureDetail.key === 'schedule') {
            const schedule = measureDetail.value;
            if (schedule === "delete") {
                params = {
                    [measureDetail.key]: measureDetail.value
                };
            } else {
                params = {
                    [measureDetail.key]: {
                        "type": schedule.type,
                        "duration": schedule.is_event_schedule ? "1" : schedule.duration,
                        "start_date": schedule.start_date,
                        "configuration": schedule.is_event_schedule ? { time: '' } : schedule.configuration,
                        "timezone": schedule.timezone,
                        "multiTime": schedule.is_event_schedule ? [] : schedule.multiTime,
                        "target_type": schedule.is_event_schedule ? schedule.target_type : "",
                        "target_measure": schedule.is_event_schedule ? schedule.target_measure?.id : "",
                        "schedule_method": schedule.is_event_schedule ? "event" : "time",
                        "level": "measure",
                        "schedule_type": "measure",
                        "category": ""
                    }
                };
                if (schedule.target_type === "Measure") {
                    params.schedule.target_measure = schedule.is_event_schedule ? schedule.target_measure.id : "";
                } else {
                    params.schedule.target_asset = schedule.is_event_schedule ? schedule.target_asset.id : "";
                }
            }
        } else {
            params = {
                [measureDetail.key]: measureDetail.key !== "dimension_id" ? (measureDetail.value === "Active" || measureDetail.value === "Valid") : measureDetail.value
            };
        }
        onEditMeasure(params);
        onCloseConfirmDialog();
    };

    const updateScheduler = (schedule) => {
        const detail = { ...measureDetail };
        detail.value = schedule;
        setMeasureDetail({ ...detail });
    };

    const selectedMeasureId = assetMeasures.map((measure) => measure.id);
    const selectedMeasureIdString = selectedMeasureId.join(',');


    /**
     * Render Filter Options
     * @param {*} property
     * @returns
     */
    const renderOptions = (property) => {
        switch (property) {
            case 'domains':
                return (
                    <AutoCompleteComponent
                        id="form_domains"
                        name={'domsins'}
                        variant="standard"
                        fullWidth
                        placeholder={"Select Domains"}
                        disableCloseOnSelect
                        disableClearable
                        compareKey={'id'}
                        data={domainsList || []}
                        multiple
                        customOption
                        selectedValue={measureDetail.value || []}
                        onChange={
                            (event, value) =>
                                onChange('value', value)
                        }
                        limitTags={1}
                        select_all
                    />
                );
            case 'applications':
                return (
                    <AutoCompleteComponent
                        id="form_applications"
                        name={'applications'}
                        variant="standard"
                        fullWidth
                        placeholder={"Select Applications"}
                        disableCloseOnSelect
                        disableClearable
                        compareKey={'id'}
                        customOption
                        data={applicationsList || []}
                        multiple
                        selectedValue={measureDetail.value || []}
                        onChange={
                            (event, value) =>
                                onChange('value', value)
                        }
                        limitTags={1}
                        select_all
                    />

                );
            case 'dimension_id':
                return (
                    <SelectComponent
                        value={measureDetail.value || ""}
                        onSelectChange={(value) => onChange("value", value)}
                        label="Select Dimension"
                        variant="standard"
                        list={dimensions}
                        name="dimension"
                        propertyName="id"
                        displayPropertyName="name"
                        noOutline />

                );
            case 'status':
                return (
                    <SelectComponent
                        value={measureDetail.value || ""}
                        onSelectChange={(value) => onChange("value", value)}
                        label="Select Status"
                        variant="standard"
                        list={["Pending", "Ready For Review", "Verified"]}
                        name="status"
                        noOutline />

                );
            case 'schedule':
                return (
                    <Scheduler
                        data={default_schdule}
                        updateScheduler={(schedule) => updateScheduler(schedule)}
                        selectedMeasureIdString={selectedMeasureIdString}
                    />

                );
            default:
                let options = ["Active", "Inactive"];
                if (property?.toLowerCase() === "is_positive") {
                    options = ["Valid", "Invalid"];
                }
                return (
                    <SelectComponent
                        value={measureDetail.value || ""}
                        onSelectChange={(value) => onChange("value", value)}
                        label="Select Value"
                        variant="standard"
                        list={options}
                        name={property}
                        noOutline />
                );
        }
    };


    const is_standalone = () => {
        const existingObjectIndex = appConstants.measureEditOptions.findIndex((option) => option.value === "schedule");
        if (selectedTemplate === "Standalone" && existingObjectIndex === -1) {
            appConstants.measureEditOptions.push({ name: "Schedule", value: "schedule" });
        } else if (selectedTemplate !== "Standalone" && existingObjectIndex !== -1) {
            appConstants.measureEditOptions.splice(existingObjectIndex, 1);
        }
        return appConstants.measureEditOptions;
    };

    const check_schedule_disable = () => {
        if (measureDetail.key === "schedule") {
            return (measureDetail?.value?.is_event_schedule && ((measureDetail?.value?.target_type === "Asset" && !measureDetail?.value?.target_asset?.id)
                || (measureDetail?.value?.target_type === "Measure" && !measureDetail?.value?.target_measure?.id)) || (!measureDetail?.value?.is_event_schedule && measureDetail?.value?.type !== "Hours" && measureDetail?.value?.type !== "Minutes" && measureDetail?.value?.multiTime?.length === 0) || (!measureDetail?.value?.is_event_schedule && (measureDetail?.value?.type === "Hours" || measureDetail?.value?.type !== "Minutes") && !measureDetail?.value?.duration));
        } return false;
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.selectDialog}
        >
            <ValidatorForm onSubmit={() => onSubmit()}>
                <Grid container spacing={5} className={classes.selectContainer}>
                    <Grid item xs={12} className="dflex spaceBetween w-100">
                        <Grid>
                            <Typography variant="h6">
                                Edit Measure
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="pt-2">
                        <Grid className="mt-1">
                            <SelectComponent
                                value={measureDetail.key || ""}
                                onSelectChange={(value) => onChange("key", value)}
                                label="Select Option"
                                variant="standard"
                                list={orderList(is_standalone(), 'name', 'asc')}
                                name="key"
                                propertyName="value"
                                displayPropertyName="name"
                                noOutline />
                        </Grid>
                        {
                            measureDetail.key &&
                            <Grid className="mt-3">
                                {renderOptions(measureDetail.key, event)}
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} className={`${measureDetail?.key === "schedule" ? "spaceBetween" : "flexEnd"} dflex`} align="right">
                        {
                            measureDetail?.key === "schedule" &&
                            <IconButton onClick={() => onDeleteSchedule()}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        <Grid>
                            <Button disableElevation color="primary" variant="outlined" size="small" className="mr-1 cancelBtn" onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button disableElevation disabled={(measureDetail?.key !== "schedule" && !measureDetail.value) || (measureDetail?.key === "schedule" && check_schedule_disable())} color="primary" variant="contained" size="small" type="submit">
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                openConfirmDialog &&
                <DialogComponent
                    open={openConfirmDialog}
                    message={appConstants.dialogBox.updateMeasureMessage}
                    onCancel={onCloseConfirmDialog}
                    onConfirm={confirmAction}
                />
            }
        </Dialog>
    );
}

// default props
EditMeasureDialog.defaultProps = {
    classes: {},
    open: {},
    onClose: () => { },
    onEditMeasure: () => { },
    selectedTemplate: "All"
};

// prop types
EditMeasureDialog.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onEditMeasure: PropTypes.func,
    selectedTemplate: PropTypes.string
};


export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(EditMeasureDialog);