import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

// Import styles
import Styles from './style.jsx';

// Import Actions
import { DoubleTick, DoubleTickCross } from '../../../../assets/svg/index.js';

function DriftActions(props) {

    /**
     * Define Props
     */
    const { classes, status, markedValue, handleEvent } = props;

    if (!status) {
        return null;
    }

    return (
        <Grid>
            {
                status && status.toLowerCase() === "ok" ?
                    <Tooltip title={markedValue ? "Unmark as outlier" : "Mark as outlier"}>
                        <IconButton className={`p5 ${classNames(classes.default, markedValue ? classes.outlier : null)}`} onClick={(event) => handleEvent(event)}>
                            <DoubleTickCross />
                        </IconButton>
                    </Tooltip> :
                    <Tooltip title={markedValue ? "Unmark as normal" : "Mark as normal"}>
                        <IconButton className={`p5 ${classNames(classes.default, markedValue ? classes.normal : null)}`} onClick={(event) => handleEvent(event)}>
                            <DoubleTick />
                        </IconButton>
                    </Tooltip>
            }
        </Grid>
    );
}

DriftActions.propTypes = {
    classes: PropTypes.object,
    status: PropTypes.string,
    markedValue: PropTypes.string,
    handleEvent: PropTypes.func
};

DriftActions.defaultProps = {
    classes: {},
    status: '',
    markedValue: '',
    handleEvent: () => { }
};


export default withStyles(Styles)(DriftActions);