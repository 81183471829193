import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import { getAssetByExtensionFailure, getAssetByExtensionRequest, getAssetByExtensionSuccess } from '../reducer/extensionReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { extensionService } from '../service';

/**
 * Get Widget
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getAssetByExtensionRequest),
    mergeMap(({ payload }) => {
        return from(extensionService.detail(payload)).pipe(
            concatMap((res) => { return of(getAssetByExtensionSuccess(res)); }),
            catchError((error) => { return of(getAssetByExtensionFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


// Export All Functions
export const extensionEpic = [detail];