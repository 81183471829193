//Default Imports
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Rating, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import _ from 'lodash';

// Import Components
import { CircularScoreComponent, ChipComponent } from '../../components/index.js';
import ConnectorsIconComponent from '../../components/connectorsIcon/index.jsx';
import AssetTypeIconComponent from '../../components/assetTypeIcon/index.jsx';

//  Import Styles
import CatalogStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helper Functions
import { toRound, nFormatter, get_connection_type_access, isRelationalAssetType } from '../../helpers/appHelpers.js';

function AssetCard(props) {
    const { classes, data, handleNavigation } = props;
    let source_properties = data?.source_properties ?? {};
    source_properties = source_properties && typeof (source_properties) === "string" ? JSON.parse(source_properties) : source_properties;
    let properties_stats = data?.v_stats ?? {};
    properties_stats = properties_stats && typeof (properties_stats) === "string" ? JSON.parse(properties_stats) : properties_stats;
    let tags_info = data?.tags_info ?? [];
    tags_info = tags_info && typeof (tags_info) === "string" ? JSON.parse(tags_info) : tags_info;

    const connection_access = get_connection_type_access(data?.connection_type?.toLowerCase(), data?.type ?? '');
    const [width, setWidth] = useState(0);

    const connectorIconRef = useRef(null);

    useEffect(() => {
        setWidth(connectorIconRef.current.clientWidth);
    }, []);

    return (
        <Grid className={`${classes.catalogPageContainer} catalogPageContainer`} onClick={() => handleNavigation(data)}>
            <Grid container className={`${classes.cardTop} h-100`}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent={'space-between'}
                        className={classes.cardHeader}
                    >
                        <Grid item xs={12}>
                            <Grid item className={classes.titleContainer}>
                                <Grid item xs={12} className="dataNameIcon" sx={{ maxWidth: `calc(100% - ${width + 10}px) !important` }}>
                                    <Grid className="dflex alignCenter">
                                        <AssetTypeIconComponent asset_type={data.type} connection_type={data.connection_type} />
                                        <Tooltip title={data.name} arrow>
                                            <Typography variant="h5" className="pl-1 catalog-cardTitle">
                                                {data.name || ''}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    {
                                        data.type === 'attribute' &&
                                        <Tooltip title={data.asset_name} arrow>
                                            <Typography variant="body2" className="catalog-subTitle">
                                                {data.asset_name || ''}
                                            </Typography>
                                        </Tooltip>
                                    }
                                    {
                                        isRelationalAssetType(data.type) && connection_access?.attributes &&
                                        <Typography variant="body2" className="catalog-subTitle">
                                            <span className="fw-500">
                                                {data.column_count || 0}
                                            </span>
                                            {' '}
                                            Attributes
                                        </Typography>
                                    }
                                    {
                                        connection_access?.report && connection_access?.report_type?.tableau && Object.keys(source_properties).length &&
                                        <Typography variant="body2" className="catalog-subTitle">
                                            {source_properties?.site_name?.toString().replace(/"/g, '') ?? ''}
                                            {' / '}
                                            {source_properties?.project_name?.toString().replace(/"/g, '') ?? ''}
                                            {' / '}
                                            {source_properties?.workbook_name?.toString().replace(/"/g, '') ?? ''}
                                        </Typography>
                                    }
                                    {
                                        connection_access?.report && connection_access?.report_type?.dbt && Object.keys(source_properties).length &&
                                        <Typography variant="body2" className="catalog-subTitle">
                                            {source_properties?.project_name?.toString().replace(/"/g, '') ?? ''}
                                            {' / '}
                                            {source_properties?.job_name?.toString().replace(/"/g, '') ?? ''}
                                        </Typography>
                                    }
                                    {
                                        connection_access?.report && connection_access?.report_type?.databricks && Object.keys(source_properties).length &&
                                        <Typography variant="body2" className="catalog-subTitle">
                                            {source_properties?.name?.toString().replace(/"/g, '') ?? 'NA'}
                                        </Typography>
                                    }
                                    {
                                        connection_access?.report && connection_access?.report_type?.powerbi && Object.keys(source_properties).length &&
                                        <Typography variant="body2" className="catalog-subTitle">
                                            {source_properties?.workspace ?? 'NA'}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid align="right" className="mb6">
                                    <Tooltip title={
                                        <Grid className="p5">
                                            {
                                                data.connection_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Connection Name :
                                                    {' '}
                                                    {data?.connection_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                data.source_warehouse &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Warehouse :
                                                    {' '}
                                                    {data?.source_warehouse.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                data.source_db &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Database :
                                                    {' '}
                                                    {data?.source_db.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.job_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Job Name :
                                                    {' '}
                                                    {source_properties?.job_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.database &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Database :
                                                    {' '}
                                                    {source_properties?.database.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.schema &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Schema :
                                                    {' '}
                                                    {source_properties?.schema.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.site_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Site Name :
                                                    {' '}
                                                    {source_properties?.site_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.project_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Project :
                                                    {' '}
                                                    {source_properties?.project_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.workbook_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Workbook :
                                                    {' '}
                                                    {source_properties?.workbook_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                            {
                                                source_properties.owner_name &&
                                                <Typography variant="body1" className="pb5" sx={{ color: '#fff !important' }}>
                                                    Owner :
                                                    {' '}
                                                    {source_properties?.owner_name.toString().replace(/"/g, '') ?? ''}
                                                </Typography>
                                            }
                                        </Grid>
                                    }
                                        arrow>
                                        <Grid className={`${data.connection_type || ''}`} ref={connectorIconRef}>
                                            <ConnectorsIconComponent size={'small'} connection_type={data.connection_type || ''} />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item className={classes.subTitleContainer}>
                                <Grid className="dflex alignCenter">
                                    <Grid item className="starIcon">
                                        <Rating
                                            className={classes.starIcon}
                                            name={`ratings-${data.name}-${data.id}`}
                                            value={toRound(data.ratings)}
                                            precision={0.1}
                                            size="small"
                                            readOnly
                                            emptyIcon={<StarIcon className={classes.emptyStar} />}
                                        />
                                    </Grid>
                                    <Typography
                                        variant="body1"
                                        className="fw-500 pl5"
                                    >
                                        {data.ratings ? toRound(data.ratings) : ''}
                                    </Typography>
                                </Grid>
                                {
                                    (isRelationalAssetType(data.type) || data.type === 'attribute') && connection_access?.attributes &&
                                    <Tooltip title={nFormatter(data.row_count || 0)} arrow>
                                        <Typography variant="body1" className={`catalog-subTitle ${classes.textSecondary} pt-0`}>
                                            <span className="fw-500 pr5">
                                                {nFormatter(data.row_count || 0)}
                                            </span>
                                            Rows
                                        </Typography>
                                    </Tooltip>
                                }

                                {
                                    connection_access?.stats && properties_stats &&
                                    <Typography variant="body1" className={`catalog-subTitle ${classes.textSecondary} pt-0`}>
                                        <span className={`fw-500 pr5 ${properties_stats?.run_failed ? 'error' : ''}`}>
                                            {nFormatter(properties_stats?.runs ?? 0)}
                                        </span>
                                        Runs
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} onClick={(event) => event.stopPropagation()}>
                    {
                        tags_info?.length > 0 ? (
                            <ChipComponent
                                data={tags_info}
                                labelKey="name"
                                className={classes.tagSection}
                                limit={2}
                                noLink
                                haveColor
                            />
                        ) : null
                    }
                </Grid>
                {
                    data.description ?
                        <Grid container className={`${classes.cardBody} cardBody`}>
                            <Tooltip title={data.description} arrow>
                                <Typography
                                    variant="body1"
                                    className={`${classes.textSecondary} detail`}
                                >
                                    {data.description || ''}
                                </Typography>
                            </Tooltip>
                        </Grid> : null
                }
                <Grid container justifyContent={"space-between"} className={classes.alertSection}>
                    <CircularScoreComponent
                        size={40}
                        value={data.dqscore}
                        showPercentage
                        noLink
                        showValue />
                    {
                        connection_access?.alerts &&
                        <Grid item>
                            <Typography variant="body1" className={classes.textSecondary} align="center">
                                Alerts
                            </Typography>
                            <Typography variant="body1" align="center">
                                {data.alerts || 0}
                            </Typography>
                        </Grid>
                    }
                    {
                        connection_access?.stats && properties_stats && connection_access?.report_type?.dbt &&
                        <Grid item>
                            <Typography variant="body1" className={classes.textSecondary} align="center">
                                Tests
                            </Typography>
                            <Typography variant="body1" align="center" className={`${properties_stats?.tests_failed ? 'error' : ''}`}>
                                {properties_stats?.tests ?? 0}
                            </Typography>
                        </Grid>
                    }
                    {
                        connection_access?.stats && properties_stats && (connection_access?.report_type?.airflow || connection_access?.report_type?.talend) &&
                        <Grid item>
                            <Typography variant="body1" className={classes.textSecondary} align="center">
                                Tasks
                            </Typography>
                            <Typography variant="body1" align="center">
                                {properties_stats?.tasks ?? 0}
                            </Typography>
                        </Grid>
                    }
                    {
                        connection_access?.issues &&
                        <Grid item>
                            <Typography variant="body1" className={classes.textSecondary} align="center">
                                Issues
                            </Typography>
                            <Typography variant="body1" align="center">
                                {data.issues || 0}
                            </Typography>
                        </Grid>
                    }
                    {
                        source_properties.owner_name && data.img?.length > 0 && data.img?.toString().replace(/"/g, '') &&
                        <img className={classes.thumbnail} src={data.img.toString().replace(/"/g, '')} alt="Report" />
                    }
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
AssetCard.defaultProps = {
    classes: {},
    data: {},
    handleNavigation: () => { }
};

// prop types
AssetCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleNavigation: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data.source_id, nextProps.data.source_id) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...CatalogStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(AssetCard, areEqual));