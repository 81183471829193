const style = (theme) => ({
    chartTooltip: {
        '& .chartToolTip': {
            fontFamily: theme.palette.headers.body1.fontFamily,
            minWidth: '200px !important',
            fontSize: '15px !important',
            margin: '-10px !important',
            '& .chartToolTipHeader': {
                padding: 15
            },
            '& .chartToolTipFooter': {
                padding: '12px 15px',
                borderTop: `1px solid ${theme.palette.greyshades.grey}`,
                display: 'flex',
                justifyContent: 'center'
            },
            '& .nulldot': {
                width: '10px',
                height: '10px',
                borderRadius: 30,
                marginRight: 5,
                '&.high': {
                    background: `${theme.palette.colorThemes.high}`
                },
                '&.medium': {
                    background: `${theme.palette.colorThemes.medium}`
                },
                '&.low': {
                    background: `${theme.palette.colorThemes.low}`
                },
                '&.ok': {
                    background: `${theme.palette.colorThemes.ok}`
                }
            },

            '& .expectedBG': {
                '& .expectedSqure': {
                    width: '10px',
                    height: '10px',
                    marginRight: 5
                },
                '& .high': {
                    background: `${theme.palette.colorThemes.high}`
                },
                '& .medium': {
                    background: `${theme.palette.colorThemes.medium}`
                },
                '& .low': {
                    background: `${theme.palette.colorThemes.low}`
                },
                '& .ok': {
                    background: `${theme.palette.colorThemes.ok}`
                }
            },
            '& .tooltipchip': {
                borderRadius: 4,
                fontSize: '14px !important',
                minWidth: 50,
                textAlign: 'center',
                '&.high': {
                    background: `${theme.palette.colorThemes.high}25`,
                    border: `1px solid ${theme.palette.colorThemes.high}25`,
                    // border: `1px solid ${theme.palette.colorThemes.high}`,
                    color: theme.palette.colorThemes.high,
                    padding: '0 4px'
                },
                '&.medium': {
                    background: `${theme.palette.colorThemes.medium}25`,
                    border: `1px solid ${theme.palette.colorThemes.medium}25`,
                    // border: `1px solid ${theme.palette.colorThemes.medium}`,
                    color: theme.palette.colorThemes.medium,
                    padding: '0 4px'
                },
                '&.low': {
                    background: `${theme.palette.colorThemes.low}25`,
                    border: `1px solid ${theme.palette.colorThemes.low}25`,
                    // border: `1px solid ${theme.palette.colorThemes.low}`,
                    color: theme.palette.colorThemes.low,
                    padding: '0 4px'
                },
                '&.ok': {
                    background: `${theme.palette.colorThemes.ok}25`,
                    border: `1px solid ${theme.palette.colorThemes.ok}25`,
                    // border: `1px solid ${theme.palette.colorThemes.low}`,
                    color: theme.palette.colorThemes.low,
                    padding: '0 4px'
                }
            }
        }
    },
    stripedTable: {
        width: '100%',
        '& .MuiTableContainer-root': {
            // maxHeight: 'calc(100vh - 270px)'
        },
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&::first-letter': {
                textTransform: 'capitalize !important'
            }
        },
        '& .MuiTable-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            borderBottom: 'none'
        },
        '& .MuiTableCell-root': {
            padding: '10px 12px',
            verticalAlign: 'middle !important'
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey
        },
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: `${theme.palette.greyshades.lightgrey} !important`
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                // background: theme.palette.greyshades.tablegrey
                background: '#fff',
                borderBottom: `1px solid #F1F4F7 !important`,
                '&:last-child': {
                    borderBottom: 'none !important'
                },
                '&:hover': {
                    background: '#fff'
                }
            }
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            textTransform: 'capitalize',
            padding: '10px 12px',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            }
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        }
    },
    customLegendContainer: {
        display: "flex",
        justifyContent: "center",
        "& .legend": {
            minWidth: 150,
            alignItems: "center",
            display: "flex"
        },
        "& .legend:last-child": {
            minWidth: 'auto'
        },
        "& .rect": {
            width: 20,
            height: 8,
            marginRight: 8
        },
        '& .legendText': {
            color: "#9faec3",
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontSize: 14
        }
    },
    widgetListIcon: {
        marginRight: '15px !important',
        '& .textValue': {
            fontSize: '13px !important',
            visibility: 'hidden'
        }
    },
    progressBar: {
        minWidth: '100px'
    },
    alertsCard: {
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        height: '100%'
        // height: 82
    },
    nodataCardWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end !important'
    },
    nodataCard: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    overlap: {
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '20px !important'
    },
    multiDimChartContainer: {
        height: '92%',
        overflow: 'auto',
        background: '#FFF',
        '& td, th': {
            cursor: 'default',
            borderBottom: '1px solid #FFFFFF',
            borderLeft: '1px solid #e0e0e0',
            '&:first-child': {
                borderLeft: 'unset'
            }
        }
    },
    multiDimHeatmapContainer: {
        '& td, th': {
            cursor: 'default',
            borderBottom: '1px solid #e0e0e0',
            borderLeft: '1px solid #e0e0e0',
            '&:first-child': {
                borderLeft: 'unset'
            }
        }
    }
});
export default style;