// Import API Helper
import { postRequest, getRequest, putRequest, deleteRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";


/**
 * List
 */
async function list(params) {
    const response = await getRequest(`${urlConstants.connection.url}?${new URLSearchParams(params).toString()}`);
    return response;
}

/**
 * Create
 */
async function create(params) {
    const response = await postRequest(urlConstants.connection.url, params);
    return response;
}

/**
 * Detail
 */
async function detail(id) {
    const response = await getRequest(`${urlConstants.connection.url}${id}/`);
    return response;
}

/**
 * Update
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.connection.url}${id}/`, params);
    return response;
}

/**
 * Remove
 * @returns
 */
async function remove(id, purge) {
    const response = await deleteRequest(`${urlConstants.connection.url}${id}/?purge=${purge}`);
    return response;
}

/**
 * Copy
 */
async function copy(params) {
    const response = await postRequest(`${urlConstants.connection.url}copy/`, params);
    return response;
}

/**
 * Get Catalog Connection List Details
 */
async function getCatalogConnectionList() {
    const response = await getRequest(urlConstants.connection.catalogList);
    return response;
}

/**
 * Get Database and Schema List
 */
async function dbs_schemas(id) {
    const response = await getRequest(`${urlConstants.connection.getSchemas}${id}/`);
    return response;
}

/**
 * Remove Schema
 * @returns
 */
async function remove_schema(params) {
    const response = await deleteRequest(`${urlConstants.connection.removeSchemas}`, params);
    return response;
}

/**
 * Get Search Connection List
 */
async function getConnectionList() {
    const response = await getRequest(urlConstants.connection.search);
    return response;
}

/**
 * Get DBT Project and Env
 */
async function pipelineInfo(id) {
    const response = await getRequest(`${urlConstants.connection.pipelineInfo}${id}/`);
    return response;
}

/**
 * Get Connections
 * @param {*} params
 * @param {*} cancellationToken
 * @returns
 */
async function getConnections(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.connection.connections}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get Connection Items
 * @param {*} params
 * @returns
 */
async function getConnectionItems(params) {
    const response = await postRequest(urlConstants.connection.connectionList, params);
    return response;
}
/**
 * Get ADF Factories, resource Groups and Subscriptions
 */
async function adf_factories(id) {
    const response = await getRequest(`${urlConstants.connection.adfFactories}${id}/`);
    return response;
}

/**
 * Export All Service Functions
 */
export const connectionService = {
    list, create, detail, update, remove, copy, getCatalogConnectionList, dbs_schemas, remove_schema, getConnectionList, pipelineInfo,
    getConnections, getConnectionItems, adf_factories
};