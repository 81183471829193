import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

// import styles
import profileStyles from "../style.jsx";
import { withStyles } from "@mui/styles";
import appConstants from "../../../../../constants/appConstants.js";
import { Grid } from "@mui/material";
import ProfileView from "../../../../../components/profileView/index.jsx";
import { updateAttributeMetadataRequest, updateAttributeMetadata } from '../../../../../redux/reducer/attributeReducer';


const Frequency = (props) => {
    const { classes, propertiesPermission, onItemClick, onItemToggle, onDeleteItem, onClickTrending, onToggleRules } = props;
    const dispatch = useDispatch();
    const { attribute_id } = useParams();

    /**
     * Get data from redux store
     */
    const { detail: metadata } = useSelector((state) => state.attribute, shallowEqual);
    const { measures } = useSelector((state) => state.measure, shallowEqual);
    const lengthFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Length");
    const valueFrequency = measures?.find((item) => item.type === "frequency" && item.name === "Enum");

    /**
     * Handle On Change
     * @param {*} property
     * @param {*} value
     */
     const onChange = (property, value) => {
        const requestParams = {
            [property]: value,
            attribute_id
        };
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
    };

    return (
        <Grid container justifyContent={"flex-start"} rowSpacing={4} className={classes.topSection}>
            <Grid item xs={12}>
                <ProfileView
                    title={appConstants.profile.length}
                    description={appConstants.profile.lengthDescription}
                    data={metadata.length_distribution || []}
                    metadata={metadata}
                    propertiesPermission={propertiesPermission}
                    chartType={"length"}
                    onChange={onChange}
                    onClickTrending={() => onClickTrending("length", lengthFrequency)}
                    onToggleRules={(value) => onToggleRules("length_frequency", value)}
                    isMeasureEnabled={Boolean(metadata?.attribute_measures?.length_frequency > 0)}
                    draggable
                    showRangeValues
                    showMeasureLink
                />
            </Grid>
            {
                metadata?.value_distribution?.length > 0 &&
                <Grid item xs={12}>
                    <ProfileView
                        title={appConstants.profile.value}
                        description={appConstants.profile.valueDescription}
                        data={metadata.value_distribution || []}
                        metadata={metadata}
                        propertiesPermission={propertiesPermission}
                        chartType={"value"}
                        onChange={onChange}
                        onItemClick={(...params) => onItemClick(...params, "value", "value_distribution")}
                        onItemToggle={(...params) => onItemToggle(...params, "value", "value_distribution")}
                        onAdd={(event) => onItemClick(event, null, -1, null, "value", "value_distribution")}
                        onDelete={(...params) => onDeleteItem(...params, "value", "value_distribution")}
                        onClickTrending={() => onClickTrending("value", valueFrequency)}
                        onToggleRules={(value) => onToggleRules("value_frequency", value)}
                        isMeasureEnabled={Boolean(metadata?.attribute_measures?.value_frequency > 0)}
                        showAdd
                        sort
                        showRangeValues
                        enableDelete
                        showMeasureLink
                    />
                </Grid>
            }
        </Grid>
    );
};

// define default props
Frequency.defaultProps = {
    classes: {},
    propertiesPermission: {},
    onItemClick: () => { },
    onItemToggle: () => { },
    onDeleteItem: () => { },
    onClickTrending: () => { },
    onToggleRules: () => { }
};

// define proptypes
Frequency.propTypes = {
    classes: PropTypes.object,
    propertiesPermission: PropTypes.object,
    onItemClick: PropTypes.func,
    onItemToggle: PropTypes.func,
    onDeleteItem: PropTypes.func,
    onClickTrending: PropTypes.func,
    onToggleRules: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...profileStyles(theme)
    }),
    { withTheme: true }
) (Frequency);