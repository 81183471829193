import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Import Routes
import { PrivateRoute, AuthRoute, DialogueRoute, PublicRoute, ExtensionRoute } from "../authRoutes";

// Import Containers
import AppNavigator from "../components/appNavigator/index.jsx";
import Login from "../containers/authentication/login.jsx";
import ForgotPassword from "../containers/authentication/forgotpassword.jsx";
import ChangePassword from "../containers/authentication/changepassword.jsx";
import ResetPassword from "../containers/authentication/resetPassword.jsx";
import Home from "../containers/home/index.jsx";
import Dashboard from "../containers/dashboard/index.jsx";
import Spotlight from "../containers/spotlight/spotlight.jsx";
import Settings from "../containers/settings/index.jsx";
import General from "../containers/general/index.jsx";
import Connections from "../containers/connections/index.jsx";
import ConnectionList from "../containers/connections/connectionList/index.jsx";
import ScheduleList from "../components/scheduleList/index.jsx";
import Connector from "../containers/connector/index.jsx";
import Catalog from "../containers/catalog/index.jsx";
import Assets from "../containers/asset/index.jsx";
import AssetsOverview from "../containers/asset/overview/index.jsx";
import AssetsProperties from "../containers/asset/properties/index.jsx";
import AssetsConversation from "../containers/asset/conversation/index.jsx";
import AssetsUsage from "../containers/asset/usage/index.jsx";
import Lineage from "../containers/asset/lineage/index.jsx";
import Semantics from "../containers/semantics/index.jsx";
import Glossary from "../containers/semantics/glossary/index.jsx";
import GlossaryDetail from "../containers/semantics/glossary/detail/index.jsx";
import Term from "../containers/semantics/glossary/term/index.jsx";
import Security from "../containers/security/index.jsx";
import SemanticApplications from "../containers/semantics/applications/index.jsx";
import SemanticTags from "../containers/semantics/tags/index.jsx";
import SemanticTerms from "../containers/semantics/terms/index.jsx";
import Dimension from "../containers/semantics/dimension/index.jsx";
import SettingsQuality from "../containers/quality/index.jsx";
import ExecutionLogs from "../containers/executionLogs/index.jsx";
import MyProfile from "../containers/profile/index.jsx";
import EditProfile from "../containers/profile/editProfile/index.jsx";
import Theme from "../containers/profile/theme/index.jsx";
import Alerts from "../containers/alerts/index.jsx";
import Issues from "../containers/issues/index.jsx";
import Integration from "../containers/integration/index.jsx";
import Logs from "../containers/logs/index.jsx";
import CustomWidget from "../containers/dashboard/customWidget/index.jsx";
import Measure from '../containers/measure/index.jsx';
import SemanticsOverview from "../containers/domains/index.jsx";
import Domains from "../containers/domains/domains/index.jsx";
import Tags from "../containers/domains/tags/index.jsx";
import Applications from "../containers/domains/applications/index.jsx";
import DomainsDetail from "../containers/domains/domains/detail/index.jsx";
import DomainsSummary from "../containers/domains/domains/detail/summary.jsx";
import DomainsTerms from "../containers/domains/domains/detail/terms.jsx";
import DomainsQuality from "../containers/domains/domains/detail/quality.jsx";
import TagsDetail from "../containers/domains/tags/detail/index.jsx";
import TagsQuality from "../containers/domains/tags/detail/quality.jsx";
import TagsSummary from "../containers/domains/tags/detail/summary.jsx";
import ApplicationsDetail from "../containers/domains/applications/detail/index.jsx";
import ApplicationsQuality from "../containers/domains/applications/detail/quality.jsx";
import ApplicationsSummary from "../containers/domains/applications/detail/summary.jsx";
import NotFound from "../containers/errorPages/notFound.jsx";
import AccessDenied from "../containers/errorPages/accessDenied.jsx";
import Fields from "../containers/semantics/fields/index.jsx";
import AssetFields from "../containers/asset/fields/index.jsx";
import Reports from "../containers/reports/index.jsx";
import CustomReports from "../containers/reports/customReports/index.jsx";
import ExtensionLogin from "../containers/extension/login.jsx";
import ExtensionDetail from "../containers/extension/details.jsx";
import License from "../components/license/index.jsx";
import MeasureQuality from "../containers/measure/components/quality/index.jsx";
import MeasureEdit from "../containers/measure/components/add/index.jsx";


// Import Helpers
import { permissionTabs } from "../helpers";

// Import Constants
import appConstants from "../constants/appConstants";


const routes = () => {

  /**
   * Get Redux State
   */
  const { permission } = useSelector((state) => state.auth);

  /**
   * Set Home Initial route
   */
  const getHomeState = () => {
    const homeInitialRoute = permissionTabs(permission, appConstants.tabs.homeTabs);
    if (homeInitialRoute.length > 0 && homeInitialRoute[0].path === "dashboard") {
      return <Dashboard />;
    } else if (homeInitialRoute.length > 0 && homeInitialRoute[0].path === "issues") {
      return <Issues />;
    } else if (homeInitialRoute.length > 0 && homeInitialRoute[0].path === "alerts") {
      return <Alerts />;
    } else if (homeInitialRoute.length > 0 && homeInitialRoute[0].path === "measures") {
      return <Measure />;
    }
    return <Dashboard />;
  };

  /**
   * Set Settings initial route
   * @returns
   */
  const getSettingsState = () => {
    const settingsInitialRoute = permissionTabs(permission, appConstants.tabs.settingsTabs);
    if (settingsInitialRoute.length > 0) {
      return settingsInitialRoute[0].path;
    }
    return "general";
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<AuthRoute component={Login} />} />
        <Route exact path="login" element={<AuthRoute component={Login} />} />
        <Route exact path="forgotpassword" element={<AuthRoute component={ForgotPassword} />} />
        <Route exact path="resetpassword/:hash" element={<AuthRoute component={ResetPassword} />} />
        <Route exact path="changepassword/:hash" element={<AuthRoute component={ChangePassword} />} />
        <Route path="home" element={<PrivateRoute component={Home} />} >
          <Route index element={getHomeState()} />
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="alerts" element={<Alerts />} />
          <Route exact path="issues" element={<Issues />} />
          <Route exact path="measures" element={<Measure />} />
          <Route exact path="reports" element={<Reports />} />
        </Route>
        <Route exact path="reports/:id" element={<CustomReports />} />
        <Route exact path="/spotlight" element={<PrivateRoute component={Spotlight} />} />
        <Route path="connector/*" element={<PrivateRoute component={Connector} />} />
        <Route path="settings" element={<PrivateRoute component={Settings} />}>
          <Route index element={<Navigate to={getSettingsState()} replace />} />
          <Route path="general" element={<General />} />
          <Route path="connections" element={<Connections />}>
            <Route index element={<Navigate to="connection" replace />} />
            <Route exact path="connection" element={<ConnectionList />} />
            <Route exact path="schedule" element={<ScheduleList />} />
          </Route>
          <Route path="semantics" element={<Semantics />}>
            <Route index element={<Navigate to="domains" replace />} />
            <Route exact path="domains" element={<Glossary />} />
            <Route exact path="domains/:id" element={<GlossaryDetail />} />
            <Route exact path="domains/:id/category/:categoryId" element={<GlossaryDetail />} />
            <Route path="domains/term/:termId" element={<Term />} />
            <Route path="terms" element={<SemanticTerms />} />
            <Route path="terms/:termId" element={<Term />} />
            <Route path="tags" element={<SemanticTags />} />
            <Route path="applications" element={<SemanticApplications />} />
            <Route path="dimension" element={<Dimension />} />
            <Route path="fields" element={<Fields />} />
          </Route>
          <Route path="quality" element={<SettingsQuality />} />
          <Route path="security" element={<Security />} />
          <Route path="integration/*" element={<Integration />} />
          <Route path="theme" element={<Theme />} />
        </Route>
        <Route exact path="/catalog" element={<PrivateRoute component={Catalog} />} />
        <Route exact path="/asset/:id" element={<PrivateRoute component={Assets} />}>
          <Route index element={<AssetsOverview />} />
          <Route path="properties" element={<AssetsProperties />} />
          <Route path="properties/:attribute_id" element={<AssetsProperties />} />
          <Route path="usage" element={<AssetsUsage />} />
          <Route path="conversation" element={<AssetsConversation />} />
          <Route path="conversation/:conversation_id" element={<AssetsConversation />} />
          <Route path="custom-tab/:tab" element={<AssetFields />} />
          <Route path="lineage" element={<Lineage />} />
        </Route>
        <Route path="myprofile" element={<PrivateRoute component={MyProfile} />}>
          <Route index element={<Navigate to="editprofile" replace />} />
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="theme" element={<Theme />} />
        </Route>
        <Route path="semantics" element={<PrivateRoute component={SemanticsOverview} />}>
          <Route index element={<Navigate to="domains" replace />} />
          <Route exact path="domains" element={<Domains />} />
          <Route exact path="domains/:id" element={<DomainsDetail />}>
            <Route index element={<Navigate to="summary" replace />} />
            <Route path="summary" element={<DomainsSummary />} />
            <Route exact path="terms" element={<DomainsTerms />} />
            <Route exact path="quality" element={<DomainsQuality />} />
          </Route>
          <Route exact path="tags" element={<Tags />} />
          <Route exact path="tags/:id" element={<TagsDetail />}>
            <Route index element={<Navigate to="summary" replace />} />
            <Route index path="summary" element={<TagsSummary />} />
            <Route exact path="quality" element={<TagsQuality />} />
          </Route>
          <Route exact path="applications" element={<Applications />} />
          <Route exact path="applications/:id" element={<ApplicationsDetail />}>
            <Route index element={<Navigate to="summary" replace />} />
            <Route index path="summary" element={<ApplicationsSummary />} />
            <Route exact path="quality" element={<ApplicationsQuality />} />
          </Route>
        </Route>
        <Route path="executionLogs" element={<PrivateRoute component={ExecutionLogs} />} />
        <Route path="executionlogs/connector/:connection_id" element={<DialogueRoute component={Logs} />} />
        <Route path="widget" element={<DialogueRoute component={CustomWidget} />} />
        <Route path="widget/:id" element={<DialogueRoute component={CustomWidget} />} />
        <Route path="extension" element={<PublicRoute component={ExtensionLogin} />} />
        <Route path="extension/tableau" element={<ExtensionRoute component={ExtensionDetail} />} />

        <Route exact path="notfound" element={<PrivateRoute component={NotFound} />} />
        <Route exact path="accessdenied" element={<PrivateRoute component={AccessDenied} />} />
        <Route path="*" element={<PrivateRoute component={NotFound} />} />
        <Route path="license" element={<DialogueRoute component={License} />} />
        <Route path="measure/:id/detail" element={<DialogueRoute component={MeasureQuality} />} />
        <Route path="measure/:id" element={<DialogueRoute component={MeasureEdit} />} />
        <Route path="measure" element={<DialogueRoute component={MeasureEdit} />} />
      </Routes>
      <AppNavigator />
    </BrowserRouter>
  );
};

export default routes;