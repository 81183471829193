import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';


// import Styles
import style from './style.jsx';

const NavigationTabHeaderComponent = (props) => {

    /**
     * Define Props and State
     */
    const { tabList, classes, onNavigationChange } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    /**
     * Redirect Page
     * @param {*} path
     */
    const redirectFunc = (tab) => {
        onNavigationChange(tab);
        navigate(tab.path, { replace: true });
    };

    /**
     * Check Active Tab
     * @param {*} path
     * @param {*} activePath
     * @returns
     */
    const isTabSelected = (tab) => {
        return currentPath.match(tab.active_path);
    };

    return (
        <Grid className={`${classes.tabHeader} tabHeader`}>
            {
                tabList && tabList.map((tab, index) =>
                    <Grid key={index} onClick={() => redirectFunc(tab)} className={`${classes.tabsLink} tabsLink`}>
                        <Typography variant="h4" sx={{ display: 'inline-flex' }} className={isTabSelected(tab || []) ? "active" : null}>
                            {tab.name}
                        </Typography>
                    </Grid>
                )
            }
        </Grid>
    );
};

// prop types
NavigationTabHeaderComponent.propTypes = {
    tabList: PropTypes.array,
    classes: PropTypes.object,
    onNavigationChange: PropTypes.func
};

// default props
NavigationTabHeaderComponent.defaultProps = {
    classes: {},
    tabList: [],
    onNavigationChange: () => { }
};


/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.tabList, nextProps.tabList) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(style)(React.memo(NavigationTabHeaderComponent, areEqual));