// Import API Helper
import { getRequest, postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Attribute list by Asset
 */
async function list(versionId) {
    const response = await getRequest(`${urlConstants.attribute.by_verions}${versionId}/`);
    return response;
}

/**
 * Get Attribute Detail
 */
async function detail(id, cancellationToken) {
    const response = await getRequest(`${urlConstants.attribute.url}${id}/`, cancellationToken);
    return response;
}

/**
 * Update Attribute
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function update(id, params) {
    const response = await putRequest(`${urlConstants.attribute.url}${id}/`, params);
    return response;
}


/**
 * Update Attribute Metadata
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function updateMetadata(id, params) {
    const response = await putRequest(`${urlConstants.attribute.metadata}${id}/`, params);
    return response;
}

/**
 * Get All Attributes
 * @returns
 */
async function getAllAttributes() {
    const response = await getRequest(urlConstants.attribute.url);
    return response;
}

/**
 * Add / Update Attribute Level Pattern
 */
async function pattern(params) {
    const response = await postRequest(`${urlConstants.measure.pattern}`, params);
    return response;
}

/**
 * Get Failed Rows
 */
async function getInvalidRows(assetId, attributeId) {
    const response = await getRequest(`${urlConstants.attribute.getInvalidRows}${assetId}/${attributeId}/`);
    return response;
}


/**
 * Toggle active measure
 */
async function toggleActiveMeasure(payload) {
    const response = await postRequest(`${urlConstants.measure.toggle_measures}`, payload);
    return response;
}

/**
 * Toggle active measure
 */
async function getActiveMeasureCategories(attribute_id) {
    const response = await getRequest(`${urlConstants.attribute.get_active_measure_categories}/${attribute_id}/`);
    return response;
}

/**
 * Toggle active measure
 */
async function getAllAttributesMetadata(params, token) {
    const response = await putRequest(urlConstants.attribute.get_all, params, false, token);
    return response;
}

/**
 * Toggle Asset Semantics
 */
async function toggleAssetSemantics(params) {
    const response = await putRequest(urlConstants.attribute.toggle_semantics, params);
    return response;
}

/**
 * Toggle Asset Advanced Profile
 */
async function toggleAssetAdvancedProfile(params) {
    const response = await putRequest(urlConstants.attribute.toggle_advanced_profile, params);
    return response;
}

/**
 * Get Attribute List
 */
async function getAttributeList(params) {
    const response = await postRequest(`${urlConstants.attribute.attributeList}`, params);
    return response;
}

/**
 * Get Attribute Statistics preview
 * @param {*} params
 * @returns
 */
async function getAttributeStatisticsPreview(params) {
    const response = await postRequest(urlConstants.attribute.attributeStatisticsPreview, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const attributeService = {
    list, detail, update, updateMetadata, getAllAttributes, pattern,
    getInvalidRows, toggleActiveMeasure, getActiveMeasureCategories, getAllAttributesMetadata,
    toggleAssetSemantics, toggleAssetAdvancedProfile, getAttributeList, getAttributeStatisticsPreview
};