import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { NoResultComponent, SearchComponent, MemoSimpleTabHeaderComponent, LoaderComponent } from '../../components/index.js';
import ConnectorsIcon from "../../components/connectorsIcon/index.jsx";

// Import Helpers
import appConstants from '../../constants/appConstants.js';

//  Import Styles
import ConnectorStyle from './style.jsx';
import LayoutStyles from '../../layouts/private/styles.jsx';

// Import Reducer
import { navigate } from '../../redux/reducer/navigationReducer';


function Connectors(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [tabIndex, setTabIndex] = useState(0);
    const [searchText, setSearchText] = useState('');

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { connection_type, isLoading } = useSelector((state) => state.default, shallowEqual);

    /**
     * Filter Data
     * @param {*} data
     * @param {*} tab
     * @returns
     */
    const filterConnectionTypes = (data, tab, searchText) => {
        let connectorsTabContent = data;
        if (tab !== 0) {
            connectorsTabContent = connectorsTabContent.filter((obj) => obj.tag.toLowerCase() === appConstants.tabs.connectorTabs[tab].toLowerCase());
        }
        if (searchText.trim()) {
            connectorsTabContent = connectorsTabContent.filter((obj) => obj.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        return connectorsTabContent;
    };

    /**
     * Filter Data using useMemo
     */
    const connection_types = useMemo(() => filterConnectionTypes(connection_type, tabIndex, searchText), [connection_type, tabIndex, searchText]);

    /**
     * Handle Search Text Box
     * @param {*} value
     */
    const handleSearchOnChange = (value) => {
        setSearchText(value);
    };

    /**
     * Navigate to New Connection Page
     */
    const navigateNewConnectionPage = (item) => {
        if (item.is_active) {
            dispatch(navigate({ path: 'connector.new', state: {}, params: [item.type] }));
        }
    };

    return (
        <Grid container className={classes.connectPageContainer}>
            <Grid item xs={12} className={classes.connectionHeader}>
                <Grid container justifyContent={'space-between'} wrap="nowrap">
                    <Grid item>
                        <SearchComponent
                            fullWidth
                            placeholder={appConstants.labels.connector.searchConnector}
                            value={searchText}
                            onChange={(value) => handleSearchOnChange(value)}
                        />
                    </Grid>
                    <Grid item>
                        <MemoSimpleTabHeaderComponent
                            tabList={appConstants.tabs.connectorTabs}
                            tabIndex={tabIndex}
                            onTabChange={(newValue) => setTabIndex(newValue)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.connectioncardBody}>
                <Grid container spacing={3} className={classes.connectorsInnerBody}>
                    {
                        connection_types.map((data) => (
                            <Grid item xs={3} xl={2} key={data.type}>
                                <Grid
                                    onClick={() => navigateNewConnectionPage(data)}
                                    className={`${classes.connectionCard} connectorcard ${data.is_active ? '' : 'deactive'}`}
                                >
                                    <Grid className={classNames(classes.imgContainer, data.type)}>
                                        <ConnectorsIcon connection_type={data.type} />
                                    </Grid>
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        className="mb-1 mt5"
                                    >
                                        {data.name}
                                    </Typography>
                                    <Typography variant="body1" align="center" className={classes.cardDesc}>
                                        {data.long_description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item sx={{ flex: 1 }} />
                {!isLoading && connection_types.length < 1 && <NoResultComponent height="calc(100vh - 178px)" />}
                {isLoading && <LoaderComponent sx={{ paddingTop: '24px' }} xsCard={3} xlCard={2} cardHeight="175px" loaderType="card" height="auto" />}
            </Grid>
        </Grid>
    );
}

// default props
Connectors.defaultProps = {
    classes: {}
};

// prop types
Connectors.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ConnectorStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Connectors);