import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions
import {
    connectorAssetRequest, connectorAssetSuccess, connectorAssetFailure, connectorAttributeRequest,
    connectorAttributeSuccess, connectorAttributeFailure, assetCreateRequest, assetCreateSuccess, asetCreateFailure,
    assetDeleteRquest, assetDeleteSuccess, assetDeleteFailure, selectAttributeRequest, selectAttributeSuccess, selectAttributeFailure,
    syncConnectorAssetRequest, syncConnectorAssetSuccess, syncConnectorAssetFailure
} from '../reducer/connectorReducer';

// Import Service
import { connectorService } from '../service';

// Import Reducers
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Asset List
 * @param {*} action$
 * @returns
 */
const getAsset = (action$) => action$.pipe(
    ofType(connectorAssetRequest),
    mergeMap(({ payload }) => {
        return from(connectorService.getAsset(payload)).pipe(
            concatMap((res) => of(connectorAssetSuccess({ ...res, connection_id: payload.connection_id, view: payload.view }))),
            catchError((error) => {
                return of(connectorAssetFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Get Attributes
 * @param {*} action$
 * @returns
 */
const getAttribute = (action$) => action$.pipe(
    ofType(connectorAttributeRequest),
    mergeMap(({ payload }) => {
        return from(connectorService.getAttribute(payload)).pipe(
            concatMap((res) => of(connectorAttributeSuccess({ ...res, requestData: { ...payload } }))),
            catchError((error) => of(connectorAttributeFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Asset
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(assetCreateRequest),
    mergeMap(({ payload: { connection_id, assets, selectedAsset } }) => {
        return from(connectorService.saveAsset({ connection_id, assets })).pipe(
            concatMap((res) => {
                if (assets.length === 1) {
                    return of(
                        assetCreateSuccess({ ...res, request_data: { connection_id, selectedAsset } }),
                        navigate({ path: 'assets.root', state: {}, params: [res.data.asset_id] })
                    );
                }
                return of(
                    assetCreateSuccess({ ...res, request_data: { connection_id, selectedAsset } }),
                    navigate({ path: 'catalog.root', state: {}, params: [] })
                );
            }),
            catchError((error) => of(asetCreateFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const removeAsset = (action$) => action$.pipe(
    ofType(assetDeleteRquest),
    mergeMap(({ payload: { id, type, connection_id } }) => {
        return from(connectorService.removeAsset(id, type)).pipe(
            concatMap(() => of(assetDeleteSuccess({ id, connection_id }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted, connection_id: connection_id }))),
            catchError((error) => of(assetDeleteFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Failed Rows
 * @param {*} action$
 * @returns
 */
const selectAttribute = (action$) => action$.pipe(
    ofType(selectAttributeRequest),
    mergeMap(({ payload }) => {
        return from(connectorService.selectAttribute(payload)).pipe(
            concatMap((res) => of(selectAttributeSuccess(res?.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(selectAttributeFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Sync Asset List
 * @param {*} action$
 * @returns
 */
const syncAsset = (action$) => action$.pipe(
    ofType(syncConnectorAssetRequest),
    mergeMap(({ payload }) => {
        return from(connectorService.syncAsset(payload)).pipe(
            concatMap((res) => of(syncConnectorAssetSuccess({ ...res, connection_id: payload.connection_id, view: payload.view }))),
            catchError((error) => {
                return of(syncConnectorAssetFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

// Export All Epic Functions
export const connectorEpic = [getAsset, getAttribute, create, removeAsset, selectAttribute, syncAsset];