/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import DOMPurify from 'dompurify';


//  Import Styles
import StringToHtmlStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';


function StringToHTML(props) {
    const { data } = props;
    const cData = data.replace(new RegExp('\\n', 'g'), "<br />");

    /**
     * Snitized HTML to Data
     * @returns
     */
    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(cData, { USE_PROFILES: { html: true }, WHOLE_DOCUMENT: true })
    });

    return (
        <div dangerouslySetInnerHTML={sanitizedData()} />
    );
}

// default props
StringToHTML.defaultProps = {
    data: ""
};

// prop types
StringToHTML.propTypes = {
    data: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...StringToHtmlStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(StringToHTML);