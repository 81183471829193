const style = (theme) => ({
    depthBlock: {
        alignItems: 'center',
        flexWrap: 'nowrap !important',
        '& .MuiOutlinedInput-input:hover': {
            background: theme.palette.greyshades.lightestgrey
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '& .MuiOutlinedInput-input': {
            paddingTop: '5.5px',
            paddingBottom: '5.5px'
        },
        '& .MuiTextField-root': {
            '& .MuiOutlinedInput-input': {
            maxWidth: 30,
                paddingRight: '0px !important'
            }
        }
    }
});
export default style;