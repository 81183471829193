import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import Constraints from './components/constraints/index.jsx';
import LengthAndRange from './components/lengthAndRange/index.jsx';
import TopSection from './components/topSection/index.jsx';
import EnumerationList from './components/enumeration/list.jsx';
import PatternList from './components/pattern/list.jsx';

//  Import Styles
import TermDetailAssetsStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Actions
import { updateProperty, updateDetailRequest } from '../../../../../redux/reducer/termReducer';

function TermDefinition(props) {
    /**
     * Define Props
     */
    const { classes, semanticsPermission } = props;
    const dispatch = useDispatch();
    const { termId } = useParams();

    const detail = useSelector(({ term }) => term.detail);

    /**
     * Handle OnChange
     * @param {*} property
     * @param {*} value
     * @description OnChange the Value and Update Value aganist the record
     */
    const onChange = (property, value) => {
        const requestParams = {
            id: termId,
            [property]: value
        };
        dispatch(updateProperty(requestParams));
        dispatch(updateDetailRequest(requestParams));
    };

    /**
     * Prepare Component Data
     * @param {*} type
     * @returns
     */
    const prepareData = (type) => {
        switch (type) {
            case 'constraint':
                return {
                    is_null: detail.is_null || false,
                    is_blank: detail.is_blank || false,
                    is_unique: detail.is_unique || false,
                    is_primary_key: detail.is_primary_key || false
                };
            case 'length':
                return {
                    min_length: detail.min_length || "",
                    max_length: detail.max_length || "",
                    min_value: detail.min_value || "",
                    max_value: detail.max_value || ""
                };
            case 'enum':
                let enumList = detail?.enum ?? [];
                enumList = typeof (enumList) === "string" ? JSON.parse(enumList) : enumList;
                return enumList.length ? enumList : [];
            case 'pattern':
                let pattern = detail?.pattern ?? [];
                pattern = typeof (pattern) === "string" ? JSON.parse(pattern) : pattern;
                return pattern.length ? pattern : [];
            default:
                return {
                    tags: detail?.tags ?? [],
                    threshold: detail.threshold || 0
                };
        }

    };


    return (
        <Grid className={classes.attributesDetailContainer}>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <ValidatorForm onSubmit={() => { }}>
                        <TopSection data={prepareData('section')} onChange={(property, value) => onChange(property, value)} semanticsPermission={semanticsPermission} />
                    </ValidatorForm>
                </Grid>
                <Grid item xs={12}>
                    <ValidatorForm onSubmit={() => { }}>
                        <Constraints data={prepareData('constraint')} onChange={(property, value) => onChange(property, value)} semanticsPermission={semanticsPermission} />
                    </ValidatorForm>
                </Grid>
                <Grid item xs={4}>
                    <ValidatorForm onSubmit={() => { }}>
                        <PatternList patterns={prepareData('pattern')} semanticsPermission={semanticsPermission} />
                    </ValidatorForm>
                </Grid>
                <Grid item xs={4}>
                    <ValidatorForm onSubmit={() => { }}>
                        <LengthAndRange data={prepareData('length')} onChange={(property, value) => onChange(property, value)} semanticsPermission={semanticsPermission} />
                    </ValidatorForm>
                </Grid>
                <Grid item xs={4}>
                    <ValidatorForm onSubmit={() => { }}>
                        <EnumerationList enumList={prepareData('enum')} onUpdateEnum={(value) => onChange("enum", value)} semanticsPermission={semanticsPermission} />
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Grid >
    );
}

// default props
TermDefinition.defaultProps = {
    classes: {},
    semanticsPermission: {}
};

// prop types
TermDefinition.propTypes = {
    classes: PropTypes.object,
    semanticsPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...TermDetailAssetsStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TermDefinition);