import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import _ from 'lodash';

//  Import Styles
import attributesListStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';
import AccordionCard from './accordionCard.jsx';

//  Import Components

// Import Constants

function FivetranMetadata(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;

    return (
        <Grid item xs={12} className={classes.themeList}>
            {
                data?.map((schema, index) =>
                    <AccordionCard data={schema} key={index} />
                )
            }
        </Grid>
    );
}

// default props
FivetranMetadata.defaultProps = {
    classes: {},
    data: []
};

// prop types
FivetranMetadata.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...attributesListStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(FivetranMetadata, areEqual));