const style = (theme) => ({
    chart: {
        paddingLeft: 20,
        width: "100%"
    },
    alertsContainer: {
        minHeight: 'calc(100vh - 205px)',
        alignContent: 'baseline',
        '& .searchBox': {
            padding: '0px 10px !important',
            background: '#fdfdfdbf'
        },
        '& .MuiTableContainer-root ': {
            background: 'transparent !important'
        }
    },
    filterContainer: {
        padding: 24,
        maxWidth: 300
    },
    alertsCard: {
        maxHeight: 100,
        height: 100,
        boxShadow: '0px 2px 8px #00000014',
        borderRadius: 4,
        background: '#fff',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        '&:hover': {
            outline: `2px solid ${theme.palette.secondary.main}40`
        },
        '&.selected': {
            outline: `2px solid ${theme.palette.secondary.main}40`
        }
    },
    alertsTable: {
        marginTop: '40px !important',
        '& tbody': {
            '& .MuiTableRow-root:nth-child(even)': {
                background: '#fff'
            },
            '& .MuiTableCell-body:first-child': {
                minWidth: '210px'
            }
        },
        '& .tableContainer': {
            height: 'auto !important',
            maxHeight: 'calc(100vh - 170px)'
        }
    },
    nodataCardWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end !important'
    },
    nodataCard: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        }
    }
});
export default style;