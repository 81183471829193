const PrivateStyles = (theme) => ({
    privateLayout: {
        background: theme.palette.greyshades.lightbackground
    },
    privateLayoutContainer: {
        /*
         * height: "calc(100vh - 80px)",
         * overflowY: 'auto',
         * padding: "0px 20px 0px 20px",
         */
        scrollBehavior: 'smooth'
    }
});
export default PrivateStyles;