import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

//  Import Components
import { MemoTextBoxComponent, LoaderButtonComponent } from '../../../components/index.js';
import SchemaSelector from '../components/schemaSelector/index.jsx';


//  Import Styles
import NewStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import DbtIcon from '../../../assets/img/connectors/dbt.png';

// Import Contants
import appConstants from '../../../constants/appConstants.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../../helpers/appHelpers.js';
import { Crypto } from '../../../helpers/index.js';


// Import Actions
import {
    connectionCreateRequest,
    connectionDetailRequest,
    connectionUpdateRequest,
    clearConnectionState,
    getPipelineInfoRequest
} from '../../../redux/reducer/connectionReducer.js';
import { connectorAssetResetByConnectionId } from '../../../redux/reducer/connectorReducer.js';
import { navigate } from '../../../redux/reducer/navigationReducer';


function Dbt(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const isAddMode = !connection_id;
    const encryptDecrypt = new Crypto();

    /**
     * Define States
     */
    const [isAuthenticationChanged, setIsAuthenticationChanged] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        type: 'dbt',
        description: '',
        api_key: '',
        account_id: '',
        web_hook_enabled: false,
        projects: [],
        environments: []
    });
    const {
        id,
        type,
        name,
        description,
        api_key,
        account_id,
        web_hook_enabled,
        projects,
        environments
    } = formData;

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(clearConnectionState());
        if (!isAddMode) {
            dispatch(connectionDetailRequest(connection_id));
        }
        return () => {
            dispatch(clearConnectionState());
        };
    }, [dispatch, connection_id, isAddMode]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { id: userId, organization: { id: organizationId } } = useSelector((state) => state.auth.user);
    const { detailById, saveIsLoading, pipelineLoading, pipelineInfo } = useSelector((state) => state.connection);

    /**
     * Get Schema and Databases
     */
    useEffect(() => {
        if (detailById.is_valid && connection_id) {
            dispatch(getPipelineInfoRequest(connection_id));
        }
    }, [connection_id, detailById.is_valid]);


    /**
     * Bind Existing Data
     */
    useEffect(() => {
        if (!isAddMode) {
            const { id, name, type, description, credentials } = detailById;
            const credentialDetails = { ...credentials };
            credentialDetails.api_key = encryptDecrypt.decrypt(credentialDetails?.api_key ?? "");
            credentialDetails.account_id = encryptDecrypt.decrypt(credentialDetails?.account_id ?? "");
            const obj = {
                id,
                name,
                type,
                description,
                ...credentialDetails
            };
            if (obj.id) {
                setFormData(obj);
            }
        }
    }, [isAddMode, detailById]);

    /**
     * Handle Textbox Changes
     */
    const handleTextBoxChanges = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
        if (property !== "name" && property !== "description") {
            setIsAuthenticationChanged(true);
        }
    };

    /**
     * Handle Form Submit
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const postData = {
            id,
            name,
            type,
            description,
            user: userId,
            organization: organizationId,
            credentials: {
                api_key: encryptDecrypt.encrypt(api_key),
                account_id: encryptDecrypt.encrypt(account_id),
                web_hook_enabled: web_hook_enabled,
                web_hook_name: name.replace(/ /g, "_"),
                projects: projects,
                environments: environments
            }
        };
        if (isAddMode) {
            dispatch(connectionCreateRequest(postData));
        } else {
            dispatch(
                connectionUpdateRequest({
                    ...postData,
                    validation_required: detailById.is_valid === false || Boolean(isAuthenticationChanged),
                    show_assets: detailById.is_valid && !isAuthenticationChanged
                })
            );
        }
        setIsAuthenticationChanged(false);
    };

    /**
     * Navigate To Connections List Page
     */
    const navigateToConnections = () => {
        dispatch(navigate({ path: 'settings.connections.root', state: {}, params: [] }));
    };

    /**
     * Handle DBT Project Value Selection
     * @param {*} newValue
     */
    const handleProjectSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, projects: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Handle DBT Project Value Selection
     * @param {*} newValue
     */
    const handleEnvSelection = (newValue) => {
        setFormData((formData) => ({ ...formData, environments: newValue }));
        dispatch(connectorAssetResetByConnectionId(connection_id));
    };

    /**
     * Filter Env by Project
     * @param {*} dbt_data
     */
    const getEnvsByProject = (dbt_data, s_projects) => {
        let envs_data = [];
        if (s_projects) {
            const dbt_envs = dbt_data?.environments ?? [];
            envs_data = dbt_envs.filter((array_el) => {
                return s_projects.filter((anotherOne_el) => {
                    return anotherOne_el.id === array_el.project_id;
                }).length > 0;
            });
        }
        return envs_data || [];
    };
    const envs = useMemo(() => getEnvsByProject(pipelineInfo, projects), [projects]);


    return (
        <Grid className={classes.connectorForm}>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} wrap={'nowrap'}>
                    <Grid item className={classes.connectorIcon}>
                        <img src={DbtIcon} alt="Icon" />
                    </Grid>

                    <Grid item className={classes.connectorFormTile}>
                        <Typography variant="h3" className="mb5">
                            {appConstants.labels.connector.dbt.title}
                        </Typography>

                        <Typography variant="body1">
                            {
                                appConstants.labels.connector.dbt
                                    .description
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formContainer}>
                <ValidatorForm
                    noValidate
                    onSubmit={handleSubmit}
                    className="w-100"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="form_text_name"
                                name={'name'}
                                value={name}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.connectionName}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector
                                                .connectionName
                                        )
                                    ]
                                }
                                inputProps={{ maxLength: 100 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_description"
                                name={'description'}
                                value={description}
                                fullWidth
                                multiline
                                variant="standard"
                                label={appConstants.labels.connector.description}
                                inputProps={{ maxLength: 200 }}
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <MemoTextBoxComponent
                                id="form_text_account_id"
                                name={'account_id'}
                                value={account_id}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.account_id}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.account_id
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                                disabled={detailById?.is_valid ?? false}
                            />
                        </Grid>
                        <Grid item xs={5} className="pl-10">
                            <MemoTextBoxComponent
                                id="form_text_api_key"
                                name={'api_key'}
                                value={api_key}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {appConstants.labels.connector.apiKey}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={
                                    [
                                        setRequiredErrorMessage(
                                            appConstants.labels.connector.apiKey
                                        )
                                    ]
                                }
                                onChange={(event) => handleTextBoxChanges(event.target.name, event.target.value)}
                                valOnChange
                            />
                        </Grid>
                        {
                            detailById?.is_valid &&
                            <SchemaSelector
                                dbs_schema={pipelineInfo?.projects ?? []}
                                schema={projects}
                                handleSchemaSelection={handleProjectSelection}
                                schemaLoading={pipelineLoading}
                                detailById={detailById}
                                divSpan={5}
                                customLabel={'Projects'}
                                data_type={'dbt_projects'}
                            />
                        }
                        {
                            detailById?.is_valid && pipelineInfo?.environments &&
                            <SchemaSelector
                                dbs_schema={envs || []}
                                schema={environments}
                                handleSchemaSelection={handleEnvSelection}
                                detailById={detailById}
                                divSpan={5}
                                userClass={'pl-10'}
                                customLabel={'Environments'}
                                data_type={'dbt_environments'}
                                schemaLoading={pipelineLoading}
                            />
                        }
                        <Grid item xs={5} className={classes.checkBox}>
                            <FormControlLabel
                                control={<Checkbox checked={web_hook_enabled} onChange={(event) => handleTextBoxChanges("web_hook_enabled", event.target.checked)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
                                label={appConstants.labels.connector.enable_web_hook}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} className="mt-3">
                        <Grid item xs={12} align="right">
                            <Button
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                onClick={() => navigateToConnections()}
                            >
                                {appConstants.labels.connector.Cancel}
                            </Button>
                            {
                                detailById.is_valid ?
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {isAuthenticationChanged ? appConstants.labels.connector.validate : appConstants.labels.connector.connect}
                                    </LoaderButtonComponent>
                                    :
                                    <LoaderButtonComponent
                                        size={'small'}
                                        isLoading={saveIsLoading}>
                                        {appConstants.labels.connector.validate}
                                    </LoaderButtonComponent>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
}

// default props
Dbt.defaultProps = {
    classes: {}
};

// prop types
Dbt.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...NewStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Dbt);