import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getTabListRequest, getTabListSuccess, getTabListFailure, getFieldPropertyRequest, getFieldPropertySuccess, getFieldPropertyFailure,
    createFieldPropertyRequest, createFieldPropertySuccess, createFieldPropertyFailure, updateFieldPropertyRequest, updateFieldPropertySuccess, updateFieldPropertyFailure,
    updateGroupCollapseRequest, updateGroupCollapseSuccess, updateGroupCollapseFailure, updateGroupOrderRequest, updateGroupOrderSuccess, updateGroupOrderFailure
} from '../reducer/fieldPropertyReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { fieldPropertyService } from '../service';


/**
 * Get Fields
 * @returns
 */
const getTabs = (action$) => action$.pipe(
    ofType(getTabListRequest),
    mergeMap(({ payload }) => {
        return from(fieldPropertyService.list(payload)).pipe(
            concatMap((res) => { return of(getTabListSuccess(res)); }),
            catchError((error) => { return of(getTabListFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Get Fields
 * @returns
 */
const getProperty = (action$) => action$.pipe(
    ofType(getFieldPropertyRequest),
    mergeMap(({ payload }) => {
        return from(fieldPropertyService.getPropertyValues(payload)).pipe(
            concatMap((res) => { return of(getFieldPropertySuccess(res)); }),
            catchError((error) => { return of(getFieldPropertyFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Create Fields
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(createFieldPropertyRequest),
    mergeMap(({ payload }) => {
        return from(fieldPropertyService.create(payload)).pipe(
            concatMap((res) => { return of(createFieldPropertySuccess(res)); }),
            catchError((error) => { return of(createFieldPropertyFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Fields
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateFieldPropertyRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(fieldPropertyService.update(id, rest)).pipe(
            concatMap((res) => { return of(updateFieldPropertySuccess(res.data)); }),
            catchError((error) => of(updateFieldPropertyFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Collapse Group
 * @param {*} action$
 * @returns
 */
const collapseGroup = (action$) => action$.pipe(
    ofType(updateGroupCollapseRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(fieldPropertyService.collapseGroup(id, rest)).pipe(
            concatMap((res) => { return of(updateGroupCollapseSuccess(res)); }),
            catchError((error) => { return of(updateGroupCollapseFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Order Group
 * @param {*} action$
 * @returns
 */
const orderGroup = (action$) => action$.pipe(
    ofType(updateGroupOrderRequest),
    mergeMap(({ payload }) => {
        return from(fieldPropertyService.orderGroup(payload)).pipe(
            concatMap((res) => { return of(updateGroupOrderSuccess(res)); }),
            catchError((error) => { return of(updateGroupOrderFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);


// Export All Fields Functions
export const fieldPropertyEpic = [getTabs, getProperty, create, update, collapseGroup, orderGroup];