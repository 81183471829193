const style = () => ({
    fieldContainer: {
        '& .MuiAccordion-root': {
            boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important;',
            padding: '16px 30px',
            borderRadius: '3px',
            '&.Mui-expanded': {
                padding: '30px',
                '& .MuiAccordionSummary-expandIconWrapper': {
                    marginTop: '-30px'
                }
            }
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '32px !important',
            padding: '0px'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
            paddingBottom: '10px'
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px !important'
        },
        '& .selectField': {
            marginTop: '5px !important'
        },
        '& .MuiInputAdornment-root .MuiIconButton-edgeEnd svg': {
            fontSize: '1.3rem'
        },
        '& .fieldItems': {
            flex: 1,
            maxWidth: '50%',
            minWidth: '25%'
        }
    }
});
export default style;