// Default Imports
import React from 'react';
import { Grid, Typography, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Import Styles
import style from "./style.jsx";

// Import Component
import { PopOverComponent } from '../index';

// Import Helper
import { stringAvatar } from '../../helpers/index';

const ListChipItems = (props) => {
    /**
     * Define Props
     */
    const { open, anchorEl, onClose, data, classes } = props;

    return (
        <PopOverComponent
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            className="pt-1 pb-1"
        >
            <Grid className="" sx={{ px: 2, pt: 2 }}>
                {
                    data && data.map((item, index) =>
                        <Grid
                            container
                            alignItems={'center'}
                            key={`data_${index}`}
                            sx={{ pb: 2 }}
                        >
                            <Avatar {...stringAvatar(item.name && item.name.trim().length > 0 ? item.name : item.email, item?.avatar ?? '')} className={classes.avatarLarge} />
                            <Typography variant="subtitle1" className="pl-1" align="left">
                                {item.name && item.name.trim().length > 0 ? item.name : item.email}
                            </Typography>
                        </Grid>
                    )
                }
            </Grid>
        </PopOverComponent>
    );
};


/**
 * Define Prop Types
 */
ListChipItems.propTypes = {
    data: PropTypes.array,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    classes: PropTypes.object
};

/**
 * Set Default Values
 */
ListChipItems.defaultProps = {
    data: [],
    open: false,
    anchorEl: null,
    onClose: () => { },
    classes: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(ListChipItems, areEqual));