import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

//  Import Components
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Drawer from './component/drawer/index.jsx';
import CreateDashboard from './component/createDashboard/index.jsx';
import ManageWidgets from './component/manageWidget/index.jsx';
import SelectDashboard from './component/selectDashboard/index.jsx';
import DashboardFilter from './component/filter/index.jsx';
import { DialogComponent } from '../../../../components/index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { DashboardDownload, DeleteIcon, MagicPen, WidgetIcon, DefaultDashboard } from '../../../../assets/svg/index.js';

import RefreshIcon from '@mui/icons-material/Refresh';

// Import Helpers
import { checkPermission, prepareUpdateUserPreference, getUserPreference } from '../../../../helpers/appHelpers.js';

// Import Constant
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Actions
import { deleteDashboardRequest, getDashboardDetailRequest } from '../../../../redux/reducer/dashboardReducer';
import { updateUserPreference } from '../../../../redux/reducer/authReducer';
import { updateUserPreferenceRequest } from '../../../../redux/reducer/userReducer';


function Header(props) {
    /**
     * Define Props
     */
    const { classes, downloadDashboard } = props;
    const dispatch = useDispatch();
    const requestAbortControllerRef = useRef();

    /**
     * Define State
     */
    const [createDashboard, openCreateDashboard] = useState(false);
    const [manageDashboard, openManageDashboard] = useState(false);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: ''
    });

    /**
     * Get Redux State
     */
    const { permission, user } = useSelector((state) => state.auth);
    const dashboardPermission = checkPermission(permission, featureConstants.home.dashboard);
    const { id: dashboardId, detail } = useSelector(({ dashboard }) => dashboard);

    /**
     * Handle Dashaboard
     */
    const handleCreateDashboard = () => {
        openCreateDashboard(!createDashboard);
    };

    /**
     * Manage Dashboard
     */
    const handleManageDashboard = () => {
        openManageDashboard(!manageDashboard);
    };

    const deleteDashboard = () => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.dashboardDeleteMessage
        });
    };

    /**
     * Refresh Dashboard
     */
    const refreshData = () => {
        if (dashboardId) {
            if (requestAbortControllerRef && requestAbortControllerRef.current) {
                requestAbortControllerRef.current.abort();
            }
            requestAbortControllerRef.current = new AbortController();
            const token = { signal: requestAbortControllerRef?.current?.signal };
            dispatch(getDashboardDetailRequest({ dashboardId, token }));
        }
    };

    /**
     * Set Default Dashboard
     */
    const setDefaultDashboard = (type = 'select') => {
        const selectedId = getUserPreference(user?.user_preference ?? {}, "dashboard", "selected", "id");
        if (type === "delete" && selectedId !== dashboardId) {
            return;
        }
        const value = selectedId !== dashboardId ? dashboardId : "";
        const userPreference = prepareUpdateUserPreference(user?.user_preference ?? {}, "dashboard", "selected", { id: value });
        dispatch(updateUserPreference(userPreference));
        const requestParams = {
            id: user.id,
            user_preference: userPreference
        };
        dispatch(updateUserPreferenceRequest(requestParams));
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: "",
            message: ""
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = () => {
        dispatch(deleteDashboardRequest(dashboardId));
        dialogCancelEventHandle();
        setDefaultDashboard("delete");
    };

    const selectedDashboard = getUserPreference(user?.user_preference ?? {}, "dashboard", "selected", "id");

    return (
        <Grid item xs={12}>
            <ValidatorForm onSubmit={() => null}>
                <Grid
                    container
                    className={classes.widgetContainer}
                    justifyContent="space-between"
                    alignItems={'center'}
                >
                    <Grid item className={classes.dashboardSelect}>
                        <SelectDashboard />
                    </Grid>
                    <Grid item className="dflex alignCenter">
                        <DashboardFilter />
                        <Tooltip title={"Refresh"} arrow>
                            <IconButton className="ml-1 p6" onClick={refreshData}>
                                <RefreshIcon className="refreshIcon" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Download"} arrow>
                            <IconButton onClick={downloadDashboard}>
                                <DashboardDownload />
                            </IconButton>
                        </Tooltip>
                        {
                            dashboardPermission?.is_edit &&
                            <Tooltip title={"Create Dashboard"} arrow>
                                <IconButton className="" onClick={handleCreateDashboard}>
                                    <MagicPen />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            dashboardPermission?.is_edit &&
                            <Tooltip title={"Manage Widgets"} arrow>
                                <IconButton className="" onClick={handleManageDashboard}>
                                    <WidgetIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={"Set Default Dashboard"} arrow>
                            <IconButton className="dashboardIcon" onClick={setDefaultDashboard} >
                                <DefaultDashboard active={dashboardId === selectedDashboard} />
                            </IconButton>
                        </Tooltip>

                        {
                            !detail.is_default &&
                            <Tooltip title={"Delete"} arrow>
                                <IconButton onClick={() => deleteDashboard()}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }

                    </Grid>
                </Grid>
            </ValidatorForm>

            {/* Create */}
            <Drawer
                open={createDashboard}
                toggleDrawer={handleCreateDashboard}
            >
                <CreateDashboard closeDrawer={handleCreateDashboard} />
            </Drawer>
            {/* Create */}
            {/* Manage */}
            <Drawer
                open={manageDashboard}
                toggleDrawer={handleManageDashboard}>
                <Grid item xs={12}>
                    <ManageWidgets closeDrawer={handleManageDashboard} />
                </Grid>
            </Drawer>
            {/* Manage */}
            {
                showDialog.open &&
                <DialogComponent
                    open={showDialog.open}
                    title={showDialog.title}
                    message={showDialog.message}
                    data={showDialog.data}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                />
            }
        </Grid >
    );
}

// default props
Header.defaultProps = {
    classes: {},
    downloadDashboard: () => { }
};

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    downloadDashboard: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Header);