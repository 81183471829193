const style = (theme) => ({
    widgetItem: {
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        paddingBottom: 10,
        marginBottom: '15px !important',
        '& .commentText p': {
            margin: '0px !important',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            color: theme.palette.headers.body1.color,
            fontFamily: theme.palette.headers.body1.fontFamily
        },
        '& mention a': {
            color: `${theme.palette.secondary.main} !important`
        }
    },
    widgetListIcon: {
        background: `${theme.palette.secondary.main}10`,
        width: '40px',
        height: '40px !important',
        flexShrink: 0,
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px !important',
        '& svg': {
            width: '22px',
            height: '22px',
            '& path': {
                fill: `${theme.palette.secondary.main}`
            }
        }
    },
    latestComments: {
        height: '100%',
        overflowY: 'auto',
        paddingRight: 8
    }
});

export default style;