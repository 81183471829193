// Import API Helper
import { postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get drift alerts
 * @param {*} id
 * @returns
 */
async function getDriftAlerts(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.drift.getAlerts}`, params, false, "json", cancellationToken);
    return response;
}

/**
 * Get drift alerts count
 * @param {*} id
 * @returns
 */
async function getDriftAlertsCount(params, cancellationToken) {
    const response = await postRequest(`${urlConstants.drift.getAlertsCount}`, params, false, "json", cancellationToken);
    return response;
}


/**
 * Update Drift Alert
 * @param {*} id
 * @param {*} params
 * @returns
 */
async function updateDriftAlert(id, params) {
    const response = await putRequest(`${urlConstants.dirftAlert.url}${id}/`, params);
    return response;
}

/**
 * Export
 */
async function exportAlerts(params) {
    const fileName = params.fileName || "alerts.csv";
    const response = await postRequest(urlConstants.drift.export, params, false, "blob", false, true, fileName);
    return response;
}

/**
 * Export All Drift Service Functions
 */
export const driftService = {
    getDriftAlerts, updateDriftAlert, getDriftAlertsCount, exportAlerts
};