const style = (theme) => ({
    overviewContainer: {
        '& .description .MuiInput-underline': {
            color: `${theme.palette.greyshades.darkgrey} !important`,
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '&:before': {
                borderBottom: '1px solid #ffffff00'
            },
            '& textarea': {
                fontSize: `${theme.palette.headers.body1.size}px !important`,
                '&:hover': {
                    resize: 'vertical'
                }
            }
        },
        '& .boxedLayout': {
            padding: 30,
            borderRadius: '3px',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important'
        }
    },
    overviewChipDivs: {
        '& .chipContainer': {
            minHeight: 28,
            '& .MuiInput-input': {
                padding: '0px 4px 4px 0px !important'
            }
        },
        '& .termsDiv': {
            '& .chips': {
                flexWrap: 'wrap'
            },
            '& .MuiChip-root': {
                margin: '5px !important',
                marginLeft: '0px !important',
                marginTop: '0px !important'
            },
            '& svg.semanticTriggerIcon': {
                '&:hover': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    previewImageContainer: {
        height: "100%",
        cursor: 'pointer',
        borderRadius: '3px',
        padding: 5,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important',
        '& img': {
            width: '100%',
            objectFit: 'cover',
            height: 'calc(100vh - 405px) !important',
            objectPosition: '0 0'
        }
    },
    semanticsToggle: {
        '& label': {
            marginRight: '0 !important'
        }
    }
});
export default style;