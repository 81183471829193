import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid } from '@mui/material';
import { NavigationTabHeaderComponent } from '../../components/index.js';

//  Import Styles
import ConnectionStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Cnstant
import appConstants from '../../constants/appConstants.js';
import { Outlet } from 'react-router-dom';

function Connections(props) {
    const { classes } = props;

    return (
        <Grid container justifyContent={"flex-end"} className={classes.SemanticsPageContainer}>
            <Grid item xs={12} align="right">
                <Grid item xs={12} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                    >
                        <NavigationTabHeaderComponent
                            tabList={appConstants.tabs.connectionTabs}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <Grid className={classes.tabsBody}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Connections.defaultProps = {
    classes: {}
};

// prop types
Connections.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ConnectionStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Connections);