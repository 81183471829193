import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    getIssuesRequest, getIssuesSuccess, getIssuesFailure, getIssuesDetailRequest, getIssuesDetailSuccess, getIssuesDetailFailure,
    createIssuesRequest, createIssuesSuccess, createIssuesFailure, updateIssuesRequest, updateIssuesSuccess, updateIssuesFailure, deleteIssuesRequest, deleteIssuesSuccess,
    deleteIssuesFailure, getWorkLogRequest, getWorkLogSuccess, getWorkLogFailure, createCommentRequest, createCommentSuccess, createCommentFailure, getCommentRequest, getCommentSuccess,
    getCommentFailure, deleteCommentRequest, deleteCommentSuccess, deleteCommentFailure, updateCommentRequest, updateCommentSuccess, updateCommentFailure, createAttachmentSuccess, createAttachmentFailure, createAttachmentRequest, getAttachmentRequest, getAttachmentSuccess, getAttachmentFailure,
    getStatisticsRequest, getStatisticsSuccess, getStatisticsFailure, shareRequest, shareSuccess, shareFailure, updateWatchRequest, updateWatchSuccess, updateWatchFailure, removeAttachmentRequest, removeAttachmentSuccess, removeAttachmentFailure,
    exportRequest, exportSuccess, exportFailure
} from '../reducer/issuesReducer';
import { displyAlert } from "../reducer/alertReducer";
import { deleteAlertIssueId, updateAlertIssueId } from '../reducer/driftReducer';
import { deleteMetricIssueId } from '../reducer/metricReducer';
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { issuesService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

// Import Helpers
import { validate404Error } from '../../helpers/appHelpers';


/**
 * Get Issues
 * @returns
 */
const getIssues = (action$) => action$.pipe(
    ofType(getIssuesRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.get(payload.params, payload.token)).pipe(
            concatMap((res) => { return of(getIssuesSuccess({ params: payload.params, data: res?.data ?? [] }), getStatisticsSuccess({ data: res?.more_data?.statistics ?? {} })); }),
            catchError(() => { return of(getIssuesFailure()); })
        );
    })
);

/**
 * Create Issues
 * @param {*} action$
 * @returns
 */
const createIssues = (action$) => action$.pipe(
    ofType(createIssuesRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.create(payload)).pipe(
            concatMap((res) => { return of(createIssuesSuccess(res), updateAlertIssueId(res)); }),
            catchError((error) => { return of(createIssuesFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Update Issues
 * @param {*} action$
 * @returns
 */
const updateIssues = (action$) => action$.pipe(
    ofType(updateIssuesRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(issuesService.update(id, rest)).pipe(
            concatMap((res) => { return of(updateIssuesSuccess(res.data)); }),
            catchError((error) => of(updateIssuesFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Issues
 * @param {*} action$
 * @returns
 */
const deleteIssues = (action$) => action$.pipe(
    ofType(deleteIssuesRequest),
    mergeMap(({ payload: { id, type } }) => {
        return from(issuesService.remove(id, type)).pipe(
            concatMap(() => of(deleteIssuesSuccess(id), deleteAlertIssueId(id), deleteMetricIssueId(id))),
            catchError((error) => of(deleteIssuesFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Issues Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getIssuesDetailRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.detail(payload)).pipe(
            concatMap((res) => of(getIssuesDetailSuccess(res))),
            catchError((error) => {
                const errorMsg = error;
                if (validate404Error(errorMsg)) {
                    return of(getIssuesDetailFailure(), navigate({ path: 'notfound.root', state: {}, params: [] }));
                }
                of(getIssuesDetailFailure(), displyAlert({ 'type': 'error', 'message': error }));
            })
        );
    })
);

/**
 * Get WorkLog
 * @param {*} action$
 * @returns
 */
const getWorkLog = (action$) => action$.pipe(
    ofType(getWorkLogRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.getWorkLog(payload)).pipe(
            concatMap((res) => of(getWorkLogSuccess(res))),
            catchError((error) => of(getWorkLogFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Comment
 * @param {*} action$
 * @returns
 */
const createComment = (action$) => action$.pipe(
    ofType(createCommentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.createComment(payload)).pipe(
            concatMap((res) => of(createCommentSuccess({ ...res, requestData: { ...payload } }))),
            catchError((error) => of(createCommentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Comment
 * @param {*} action$
 * @returns
 */
const getComment = (action$) => action$.pipe(
    ofType(getCommentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.getComment(payload)).pipe(
            concatMap((res) => of(getCommentSuccess(res))),
            catchError((error) => of(getCommentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Comment
 * @param {*} action$
 * @returns
 */
const updateComment = (action$) => action$.pipe(
    ofType(updateCommentRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(issuesService.updateComment(id, rest)).pipe(
            concatMap((res) => of(updateCommentSuccess(res))),
            catchError((error) => of(updateCommentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Comment
 * @param {*} action$
 * @returns
 */
const deleteComment = (action$) => action$.pipe(
    ofType(deleteCommentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.deleteComment(payload)).pipe(
            concatMap(() => of(deleteCommentSuccess(payload))),
            catchError((error) => of(deleteCommentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create Attachment
 * @param {*} action$
 * @returns
 */
const createAttachment = (action$) => action$.pipe(
    ofType(createAttachmentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.createAttachment(payload)).pipe(
            concatMap((res) => of(createAttachmentSuccess(res))),
            catchError((error) => of(createAttachmentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Attachment
 * @param {*} action$
 * @returns
 */
const getAttachment = (action$) => action$.pipe(
    ofType(getAttachmentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.getAttachment(payload)).pipe(
            concatMap((res) => of(getAttachmentSuccess(res))),
            catchError((error) => of(getAttachmentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Statistics
 * @returns
 */
const getStatistics = (action$) => action$.pipe(
    ofType(getStatisticsRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.getStatistics(payload.params, payload.token)).pipe(
            concatMap((res) => of(getStatisticsSuccess(res))),
            catchError((error) => of(getStatisticsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Share Actions
 * @param {*} action$
 * @returns
 */
const share = (action$) => action$.pipe(
    ofType(shareRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.share(payload)).pipe(
            concatMap((res) => of(shareSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.shared }))),
            catchError((error) => of(shareFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Watch Actions
 * @param {*} action$
 * @returns
 */
const watch = (action$) => action$.pipe(
    ofType(updateWatchRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.watch(payload)).pipe(
            concatMap((res) => of(updateWatchSuccess(res))),
            catchError((error) => of(updateWatchFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Delete Attachment
 * @param {*} action$
 * @returns
 */
const removeAttachment = (action$) => action$.pipe(
    ofType(removeAttachmentRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.removeAttachment(payload)).pipe(
            concatMap(() => of(removeAttachmentSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deleted }))),
            catchError((error) => of(removeAttachmentFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Export
 * @returns
 */
const exportIssues = (action$) => action$.pipe(
    ofType(exportRequest),
    mergeMap(({ payload }) => {
        return from(issuesService.exportIssues(payload)).pipe(
            concatMap((res) => { return of(exportSuccess(res.data)); }),
            catchError((error) => { return of(exportFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

// Export All Issues Functions
export const issuesEpic = [
    getIssues, createIssues, updateIssues, deleteIssues, detail, getWorkLog, createComment, getComment, updateComment, deleteComment,
    createAttachment, getAttachment, getStatistics, share, watch, removeAttachment, exportIssues
];