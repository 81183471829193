import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
    isLoading: false,
    channels: [],
    connection_type: [],
    features: [],
    roles_feature: {},
    feature_search_key: '',
    datatypes: []
};

// Get Initial State for connection_type, datatypes, features and channels
const defaultSlice = createSlice({
    name: 'default',
    initialState: { ...initialState },
    reducers: {
        searchFeaturesList(state, action) {
            state.feature_search_key = action.payload;
            if (action.payload) {
                const features = state.features.filter((obj) => obj.name.toLowerCase().includes(action.payload.toLowerCase()) || obj.sub_feature.toLowerCase().includes(action.payload.toLowerCase()));
                state.roles_feature = _.groupBy(features, 'name');
            } else {
                state.roles_feature = _.groupBy(state.features, 'name');
            }
        },
        defaultDataRequest(state) {
            state.isLoading = true;
        },
        defaultDataSuccess(state, action) {
            const { channels, connection_type, datatypes, features } = action.payload.data;
            state.channels = channels ? channels : [];
            state.connection_type = connection_type ? connection_type : [];
            state.datatypes = datatypes ? datatypes : [];
            state.features = features ? features : [];
            state.roles_feature = _.groupBy(state.features, 'name');
            state.isLoading = false;
        },
        defaultDataFailure(state) {
            state.isLoading = false;
        },
        updateChannel(state, action) {
            const { channel, ...rest } = action.payload;
            const index = state.channels.findIndex((data) => data.id === channel);
            state.channels[index] = {
                ...state.channels[index],
                channel: { integration_id: rest.id, is_active: rest.is_active }
            };
        },
        deleteChannelConfig(state, action) {
            const { id } = action.payload;
            const index = state.channels.findIndex((data) => data?.channel?.integration_id === id);
            state.channels[index] = {
                ...state.channels[index],
                channel: { is_active: true }
            };
        }
    }
});

export const { searchFeaturesList, defaultDataRequest, defaultDataSuccess, defaultDataFailure, updateChannel, deleteChannelConfig } = defaultSlice.actions;
export default defaultSlice.reducer;