import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrop } from 'react-dnd';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../constants/appConstants';

function DropBox(props) {
    const { classes, accept, onDrop } = props;

    /**
     * OnDrop Event
     * @param {*} data
     */
    const onDropEvent = (data) => {
        const properties = [...appConstants.dimensions, ...appConstants.measures];
        const item = properties.find((obj) => obj.name === data.name);
        onDrop(item);
    };

    /**
     * Drop
     * @returns
     */
    const Drop = () => {
        const [{ isOver, canDrop }, drop] = useDrop({
            accept,
            drop: onDropEvent,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        });
        return (
            <Grid ref={drop} item className={`${classes.dragBox} ${isOver || canDrop ? "active" : ""}`}>
                <Typography variant="body1" className={classes.textSecondary} align="center">
                    {canDrop ? "Drag columns here" : " Drag fields here"}
                </Typography>
            </Grid>
        );
    };

    return (
        Drop()
    );
}

// default props
DropBox.defaultProps = {
    classes: {}
};

// prop types
DropBox.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    datatype: PropTypes.string,
    accept: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DropBox);