import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';

//  Import Components
import { Grid } from '@mui/material';

//  Import Styles
import ProfileStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Images

function Profile(props) {
    const { classes } = props;

    return (
        <Grid container className={classes.profilePageContainer}>
           <Grid item xs={12} className={classes.tabsSection}>
                <Grid item xs={12} className={classes.tabsBody}>
                    <Outlet />
                </Grid>
           </Grid>
        </Grid>
    );
}

// default props
Profile.defaultProps = {
    classes: {}
};

// prop types
Profile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...ProfileStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Profile);