import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';

// Constants
import appConstants from '../../../../constants/appConstants.js';

function UsersCard(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;

    /**
     * Get Users Statistics Count
     * @param {*} stats
     * @returns
     */
    const getUsersStats = (stats) => {
        const statistics = {
            total: _.sumBy(stats, 'count'),
            active: stats.find((o) => o.is_active === true)?.count ?? 0,
            inActive: stats.find((o) => o.is_active === false)?.count ?? 0
        };
        return statistics;
    };

    /**
     * Get Users Count Stats using useMemo
     */
    const user_stats = useMemo(() => getUsersStats(data), [data]);


    return (
        <Grid className={classes.usersCard}>
            <Typography variant="body1" className={classes.cardTitle}>
                {appConstants.labels.security.users}
            </Typography>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                    <Typography variant="body1" className={classes.cardText}>
                        {user_stats.total || 0}
                    </Typography>
                    <Typography variant="body1" className={classes.cardValue}>
                        {appConstants.labels.security.totalUsers}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={classes.cardText}>
                        {user_stats.active || 0}
                    </Typography>
                    <Typography variant="body1" className={classes.cardValue}>
                        {appConstants.labels.security.activeUsers}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={classes.cardText}>
                        {user_stats.inActive || 0}
                    </Typography>
                    <Typography variant="body1" className={classes.cardValue}>
                        {appConstants.labels.security.inActiveUsers}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
UsersCard.defaultProps = {
    classes: {},
    data: []
};

// prop types
UsersCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};


export default UsersCard;