import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//import Styles
import HomeStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';
import Distribution from './components/distribution.jsx';
import Pattern from './components/pattern.jsx';
import Frequency from './components/frequency.jsx';
import PatternDialog from '../../../../components/patternDialog/index.jsx';
import Statistics from './components/statistics.jsx';
import { DialogComponent } from '../../../../components/index.js';
import Basic from './components/basic.jsx';

// Import Actions
import { updateAttributeMetadataRequest, updateAttributeMetadata, toggleActiveMeasureRequest } from '../../../../redux/reducer/attributeReducer';
import { navigate } from '../../../../redux/reducer/navigationReducer.js';

// Import Constant
import { checkPermission } from '../../../../helpers/appHelpers.js';
import featureConstants from '../../../../constants/featureConstants.js';
import appConstants from '../../../../constants/appConstants.js';


function AttributeProfileInfo(props) {
    const { classes, tabIndex, asset_id, attribute_id } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedValueType, setSelectedValueType] = useState(null);

    const versionDetail = useSelector((state) => state.version.detail, shallowEqual);
    const metadata = useSelector((state) => state.attribute.detail, shallowEqual);
    const { measures } = useSelector((state) => state.measure, shallowEqual);
    const { permission } = useSelector((state) => state.auth);
    const propertiesPermission = checkPermission(permission, featureConstants.assets.properties);

    /**
     * Handle Close Dialog
     */
    const handleClose = () => {
        setSelectedItem(null);
        setSelectedValueType(null);
    };

    /**
     * Handle Update Pattern
     * @description Update Pattern Edit/Delete
     * @param {*} updatedPattern
     */
    const onSavePattern = useCallback((item) => {
        if (!selectedValueType?.propertyName) {
            return;
        }

        const selectedValue = metadata[selectedValueType?.propertyName];
        let values = [];
        if (selectedValue) {
            values = JSON.parse(JSON.stringify(selectedValue));
        }
        values = values?.length > 0 ? values : [];
        if (selectedItem?.index > -1) {
            values.splice(selectedItem.index, 1, { ...item });
        } else {
            values.push({ ...item });
        }
        const requestParams = {
            [selectedValueType?.propertyName]: values,
            attribute_id
        };
        if (selectedValueType?.propertyName === "short_universal_patterns" && item?.short_universal_pattern) {
            let universal_patterns = metadata.universal_patterns ? metadata.universal_patterns : [];
            if (universal_patterns?.length) {
                universal_patterns = JSON.parse(JSON.stringify(universal_patterns));
                for (const pattern of universal_patterns) {
                    if (pattern.short_universal_pattern !== item?.name) {
                        continue;
                    }
                    pattern.is_valid = item.is_valid;
                }
                requestParams.universal_patterns = universal_patterns;
            }
        }
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
        handleClose();
    }, [selectedValueType, selectedItem]);

    /**
     * Handle item click event
     */
    const onItemClick = (event, item, index, visualType, type, propertyName) => {
        setSelectedValueType({
            type,
            propertyName,
            visualType
        });
        setSelectedItem({
            anchorEl: (type === "value" || type === "pattern") ? event.currentTarget : event.target,
            item,
            index
        });
    };

    /**
     * Open Close Measure Quality / Detail Dialog
     */
    const handleMeasureQualityDialog = (type, measure, category) => {
        let selectedMeasures = [];
        let measureFilters = [];
        if (category) {
            const measureFilter = appConstants.profile.measureGroups.find((item) => item.group === category);
            measureFilters = measureFilter?.measures || [];
            measureFilters = measureFilters.map((item) => item.technical_name);
        }
        if (type?.toLowerCase() === "distribution" && measureFilters?.length > 0) {
            selectedMeasures = measures.filter((item) => measureFilters?.indexOf(item.technical_name) > -1);
            if (selectedMeasures?.length > 0) {
                measure = selectedMeasures[0];
            }
        }

        if (type?.toLowerCase() === "short_universal_patterns") {
            let patternMeasures = ['short_pattern', 'long_pattern'];
            if (metadata?.universal_patterns?.length === 0) {
                patternMeasures = ['short_pattern'];
            }
            selectedMeasures = measures.filter((item) => patternMeasures?.indexOf(item.technical_name) > -1);
            if (selectedMeasures?.length > 0) {
                measure = selectedMeasures[0];
            }
        }
        const state = {
            measureId: measure?.id,
            measureCategory: category || "",
            attribute_id,
            asset_id,
            selectedMeasures,
            showEdit: true,
            prevUrl: true
        };
        dispatch(navigate({ path: 'measure.detail', state: state, params: [measure?.id ?? null] }));
    };


    /**
     * Handle toggle is_valid flag
     */
    const onItemToggle = (event, item, index, type, propertyName) => {
        setSelectedValueType({
            type,
            propertyName
        });
        const selectedProperty = metadata[propertyName];
        let values = [];
        if (selectedProperty) {
            values = JSON.parse(JSON.stringify(selectedProperty));
        }
        values = values?.length > 0 ? values : [];
        let isValid = null;
        let selectedItem = null;
        if (index > -1) {
            selectedItem = values[index];
            selectedItem.is_valid = !selectedItem.is_valid;
            isValid = selectedItem.is_valid;
            values.splice(index, 1, { ...selectedItem });
        }
        const requestParams = {
            [propertyName]: values,
            attribute_id
        };
        if (propertyName === "short_universal_patterns" && isValid !== null && selectedItem) {
            let universal_patterns = metadata.universal_patterns ? metadata.universal_patterns : [];
            if (universal_patterns?.length) {
                universal_patterns = JSON.parse(JSON.stringify(universal_patterns));
                for (const pattern of universal_patterns) {
                    if (pattern.short_universal_pattern !== selectedItem.name) {
                        continue;
                    }
                    pattern.is_valid = isValid;
                }
                requestParams.universal_patterns = universal_patterns;
            }
        }
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
        setTimeout(() => setSelectedValueType(null), 100);
    };

    /**
     * Get popover title
     * @param {*} selectedValueType
     * @param {*} selectedItem
     * @returns The title
     */
    const getTitle = (selectedValueType, selectedItem) => {
        let title = "";
        if (selectedValueType?.type?.toLowerCase() === "pattern") {
            title = selectedItem?.item ? "Edit Pattern" : "Add Pattern";
        } else {
            title = selectedItem?.item ? "Edit Enum Value" : "Add Enum Value";
        }
        return title;
    };

    /**
     * Handle delete cancel event
     */
    const onDeleteItem = (item, index, parentItem, type, propertyName) => {
        setSelectedValueType({
            type,
            propertyName
        });
        setShowDeleteDialog({
            title: `Delete ${type === "pattern" ? "Pattern" : "Value"}`,
            message: `Are you sure to delete the ${type === "pattern" ? "Pattern" : "Value"}?`,
            data: item,
            parentItem,
            index
        });
    };


    /**
     * Handle delete cancel event
     */
    const onCancelDelete = () => {
        setShowDeleteDialog(null);
        setSelectedValueType(null);
    };

    /**
     * Handle delete confirm event
     */
    const onConfirmDelete = useCallback(() => {
        if (!selectedValueType?.propertyName || showDeleteDialog?.index <= -1) {
            return;
        }

        const selectedValue = metadata[selectedValueType?.propertyName];
        let values = [];
        if (selectedValue) {
            values = JSON.parse(JSON.stringify(selectedValue));
        }
        values = values?.length > 0 ? values : [];
        if (values?.length > 0 && showDeleteDialog?.index > -1) {
            values.splice(showDeleteDialog.index, 1);
        }
        const requestParams = {
            [selectedValueType?.propertyName]: values,
            attribute_id
        };
        dispatch(updateAttributeMetadataRequest(requestParams));
        dispatch(updateAttributeMetadata(requestParams));
        onCancelDelete();
    }, [showDeleteDialog, selectedValueType]);


    /**
     * Handle rules on/off event
     */
    const onToggleRules = useCallback((name, value) => {
        const requestParams = {
            attribute_id,
            type: name,
            is_active: value
        };
        dispatch(toggleActiveMeasureRequest(requestParams));
    }, [attribute_id]);


    return (
        <Grid container className={classes.topSection}>
            <Grid item xs={12}>
                {
                    metadata?.active_advanced_measures <= 0 &&
                    <Basic propertiesPermission={propertiesPermission}
                        onItemClick={onItemClick}
                        onItemToggle={onItemToggle}
                        onDeleteItem={onDeleteItem}
                        onClickTrending={handleMeasureQualityDialog}
                        onToggleRules={onToggleRules}
                    />
                }
                {
                    metadata?.active_advanced_measures > 0 && tabIndex === 0 &&
                    <Distribution
                        propertiesPermission={propertiesPermission}
                        onClickTrending={handleMeasureQualityDialog}
                        onToggleRules={onToggleRules}
                    />
                }
                {
                    metadata?.active_advanced_measures > 0 && tabIndex === 1 &&
                    <Frequency
                        propertiesPermission={propertiesPermission}
                        onItemClick={onItemClick}
                        onItemToggle={onItemToggle}
                        onDeleteItem={onDeleteItem}
                        onClickTrending={handleMeasureQualityDialog}
                        onToggleRules={onToggleRules}
                    />
                }
                {
                    metadata?.active_advanced_measures > 0 && tabIndex === 2 &&
                    <Pattern
                        propertiesPermission={propertiesPermission}
                        onItemClick={onItemClick}
                        onItemToggle={onItemToggle}
                        onDeleteItem={onDeleteItem}
                        onClickTrending={handleMeasureQualityDialog}
                        onToggleRules={onToggleRules}
                    />
                }
                {
                    metadata?.active_advanced_measures > 0 && tabIndex === 3 &&
                    <Statistics onToggleRules={onToggleRules} />
                }
            </Grid>
            {
                selectedItem &&
                <PatternDialog
                    title={getTitle(selectedValueType, selectedItem)}
                    anchorEl={selectedItem?.anchorEl ?? null}
                    selectedItem={selectedItem?.item || {}}
                    valueType={selectedValueType?.type || ""}
                    connectionType={versionDetail?.type || ""}
                    onClose={() => handleClose()}
                    onSave={(pattern) => onSavePattern(pattern)} />
            }
            {
                showDeleteDialog &&
                <DialogComponent
                    open={Boolean(showDeleteDialog)}
                    title={showDeleteDialog?.title || ""}
                    message={showDeleteDialog?.message}
                    data={showDeleteDialog?.data}
                    onCancel={onCancelDelete}
                    onConfirm={onConfirmDelete}
                />
            }
        </Grid>
    );
}

// default props
AttributeProfileInfo.defaultProps = {
    classes: {},
    tabIndex: 0,
    asset_id: "",
    attribute_id: ""
};

// prop types
AttributeProfileInfo.propTypes = {
    classes: PropTypes.object,
    tabIndex: PropTypes.number,
    asset_id: PropTypes.string,
    attribute_id: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...HomeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AttributeProfileInfo);