import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Tooltip, Typography, IconButton } from '@mui/material';
import LineageDag from './butterfly_dag/index.tsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Provider } from 'react-redux';

//  Import Styles
import './index.css';
import LineageStyle from './style.jsx';

// Import Images
import { AssetIcon, DatabaseIcon } from '../../assets/svg/index';
import { CalculateOutlined, LinkSharp, LinkOffOutlined, ExpandOutlined } from '@mui/icons-material';

// Import Constants
import appConstants from '../../constants/appConstants';

// Import Components
import { AutoCompleteComponent, PopOverComponent, CircularScoreComponent } from '../index';
import ConnectorsIconComponent from '../../components/connectorsIcon/index.jsx';

// Import Store
import store from '../../redux/store.js';


function LineageComponent(props) {

    /**
     * Define Props
     */
    const { classes, config, assets, assetMappingOnSelect, removeLink, handleNavigation, sx, resetLineageCanvas, handleShowColumns, assetType } = props;


    /**
     * Define State
     */
    const [lineageConfig, setLineageConfig] = useState({
        canvas: null,
        actionMenu: [
            {
                key: 'zoom-in',
                icon: 'table-build-icon table-build-icon-zoom-in',
                title: 'Zoom in',
                onClick: (canvas) => {
                    /* eslint no-underscore-dangle: ["error", { "allow": ["_zoomData"] }]*/
                    canvas.zoom(canvas._zoomData + 0.1);
                }
            },
            {
                key: 'zoom-out',
                icon: 'table-build-icon table-build-icon-zoom-out',
                title: 'Zoom out',
                onClick: (canvas) => {
                    /* eslint no-underscore-dangle: ["error", { "allow": ["_zoomData"] }]*/
                    canvas.zoom(canvas._zoomData - 0.1);
                }
            },
            {
                key: 'fit',
                icon: 'table-build-icon table-build-icon-quanping2',
                title: 'Fit',
                onClick: (canvas) => {
                    canvas.focusCenterWithAnimate(undefined, () => { });
                }
            }
        ]
    });
    const [assetListAnchor, setAssetListAnchor] = useState(null);
    const [selectedAsset, setSeletedAsset] = useState(null);
    const [assetsList, setAssetList] = useState([]);

    /**
     * Handle Lineage Canvas Panel Changes
     */
    useEffect(() => {
        if (lineageConfig.canvas) {
            lineageConfig.canvas.focusCenterWithAnimate(undefined, () => { });
        }
    }, [resetLineageCanvas, lineageConfig, config]);

    /**
     * Show Asset Mapping Auto Complete Popover Click
     */
    const showAssetMappingList = (event, value) => {
        let aList = assets.filter((asset) => asset?.database?.toLowerCase() === value?.database?.toLowerCase() && asset?.schema?.toLowerCase() === value?.schema?.toLowerCase() && asset?.assetName?.toLowerCase() === value?.name?.toLowerCase());
        if (!aList || aList.length === 0) {
            aList = assets.filter((asset) => asset?.assetName?.toLowerCase() === value?.name?.toLowerCase());
        }
        setAssetList(aList || []);
        setAssetListAnchor(event.currentTarget);
        setSeletedAsset(value);
    };

    /**
     * Hide Asset Mapping Auto Complete Popover Click
     */
    const hideAssetMappingList = () => {
        setAssetListAnchor(null);
        setSeletedAsset(null);
        setAssetList([]);
    };

    /**
     * Get Column Icon
     * @param {*} type
     * @returns
     */
    const getColumnTypeIcon = (node) => {
        const type = node?.type?.toLowerCase();
        switch (type) {
            case 'database':
                return (
                    <Grid className="assetIcon">
                        <DatabaseIcon />
                    </Grid>
                );
            case 'table':
                return (
                    <Grid className="assetIcon">
                        <AssetIcon />
                    </Grid>
                );
            default:
                let d_type = 'Column';
                if (node.field_type === 'CalculatedField') {
                    return (
                        <Grid className="datatypeSvg">
                            <CalculateOutlined />
                        </Grid>
                    );
                }
                else if (node.source_type || node.datatype) {
                    d_type = node.source_type || node.datatype;
                    d_type = d_type || 'Column';
                    const f_type = appConstants.tableauDataTypes.filter((i) => i.values.indexOf(d_type.toLowerCase()) > -1);
                    if (f_type.length) {
                        d_type = f_type[0].type;
                    }
                }
                return (
                    <Grid className="datatypeIcon">
                        {d_type.charAt(0) || ''}
                    </Grid>
                );
        }
    };

    /**
     *
     * @param {*} node
     */
    const bindActionButton = (node) => {
        const type = node?.type?.toLowerCase();
        const is_asset = node?.is_asset ?? false;

        if ((type === 'table' || type === 'pipeline' || type === 'view' || type === 'base table') && !is_asset && (assetType === node?.connection_type)) {
            return (
                <Grid className="ml-1">
                    {
                        node?.mapped_id &&
                        <IconButton
                            key="link"
                            aria-label="Link"
                            color="inherit"
                            onClick={() => removeLink(node)}
                            disableRipple
                        >
                            <LinkOffOutlined />
                        </IconButton>
                    }
                    {
                        !node?.mapped_id &&
                        <IconButton
                            key="link"
                            aria-label="Link"
                            color="inherit"
                            onClick={(event) => showAssetMappingList(event, node)}
                            disableRipple
                        >
                            <LinkSharp />
                        </IconButton>
                    }
                </Grid>
            );
        }
        return null;
    };

    /**
     * Bind DQScore
     * @param {*} node
     * @returns
     */
    const bindDQscore = (node) => {
        const type = node?.type?.toLowerCase();
        const obj = {
            ...node,
            "asset_id": node.is_asset ? node.asset_id : node?.mapped_asset_id ?? ''
        };
        if ((type === 'table' || type === 'column' || type === 'pipeline' || type === 'view' || type === 'base table') && (node.mapped_asset_id || node.is_asset)) {
            return (
                <Grid className="circluarRoot" onClick={() => handleNavigation(obj)}>
                    <Provider store={store} >
                        <CircularScoreComponent
                            size={24}
                            value={node?.mapped_dqscore ?? node?.dqscore ?? 0}
                            showPercentage={false}
                            noLink={false}
                            circularFont={8}
                            showValue />
                    </Provider>
                </Grid>
            );
        }
        return null;
    };

    /**
     * Mapped Connection ToolTip
     * @param {*} node
     * @returns
     */
    const mappedConnectionToolTip = (node) => {
        return (
            <Grid className="p5">
                {
                    node?.mapped_asset_ct &&
                    <React.Fragment>
                        <Typography variant="body1" className="infoToolTip">
                            Connection Type :
                            {' '}
                            {node?.mapped_asset_ct.toString().replace(/"/g, '') ?? ''}
                        </Typography>
                        <Typography variant="body1" className="infoToolTip">
                            Connection Name :
                            {' '}
                            {node?.mapped_asset_cn.toString().replace(/"/g, '') ?? ''}
                        </Typography>

                        <Typography variant="body1" className="infoToolTip">
                            DQ Score :
                            {' '}
                            {node?.mapped_dqscore ?? 0}
                        </Typography>
                        <Typography variant="body1" className="infoToolTip">
                            Alerts :
                            {' '}
                            {node?.mapped_alerts ?? 0}
                        </Typography>
                    </React.Fragment>
                }
            </Grid>
        );
    };

    /**
     * Bind Connection Icon
     * @param {*} node
     * @returns
     */
    const bindMappedConnectionIcon = (node) => {
        if (node?.mapped_asset_id) {
            const connection_type = node?.mapped_asset_ct?.toLowerCase() ?? '';

            return (
                <Tooltip title={mappedConnectionToolTip(node)} arrow placement="top">
                    <Grid className={`connectorIcon mr-1 ${connection_type || ''}`}>
                        <ConnectorsIconComponent size={'small'} connection_type={connection_type || ''} />
                    </Grid>
                </Tooltip>
            );
        }
        return null;
    };

    /**
     * Bind Connection Icon
     * @param {*} node
     * @returns
     */
    const bindConnectorIcon = (node) => {
        const connection_type = node?.connection_type?.toLowerCase() ?? '';
        const obj = {
            "asset_id": node?.asset_id ?? '',
            "type": 'table'
        };
        if (connection_type) {
            return (
                <Grid className={`connectorIcon mr-1 ${connection_type || ''} ${node.asset_id ? 'pointer' : ''}`} onClick={() => handleNavigation(obj)}>
                    <ConnectorsIconComponent size={'small'} connection_type={connection_type || ''} />
                </Grid>
            );
        }
        return null;
    };

    /**
     * Bind Expand and Collaspe Icon
     * @param {*} node
     * @returns
     */
    const bindExpandCollaspeIcon = (node) => {
        const fields = node?.fields ?? [];
        if (fields.length > 0) {
            return (
                <Grid className="ml-1">
                    <IconButton
                        key="expand_more"
                        aria-label="Expand"
                        color="inherit"
                        disableRipple
                        onClick={() => handleShowColumns(node)}
                    >
                        <ExpandOutlined />
                    </IconButton>
                </Grid>
            );
        }
        return null;
    };

    /**
     * Load Tooltip Content from Object
     * @param {*} tooltip_content
     * @returns
     */
    const getTooltipFromObject = (tooltip_content) => {
        if (!tooltip_content) {
            return null;
        }

        const tooltip_div = [];
        Object.keys(tooltip_content).forEach((item, index) => {
            tooltip_div.push(
                <Typography key={`datasource_${index}`} variant="body1" className="infoToolTip">
                    {item}
                    {' : '}
                    {tooltip_content[item] || ''}
                </Typography>
            );
        });

        if (tooltip_div.length) {
            return (
                <Grid>
                    {tooltip_div}
                </Grid>
            );
        }
        return null;
    };

    /**
     * Bind ToplTip Content
     * @param {*} node
     * @returns
     */
    const getToolTipContent = (node) => {
        const database = node?.database ?? '';
        const datatype = node?.moreInfo?.datatype ?? node?.datatype ?? '';
        const schema = node?.schema ?? '';
        return (
            <Grid className="p5">
                {
                    node?.connection_name &&
                    <Typography variant="body1" className="infoToolTip">
                        Connection Name :
                        {' '}
                        {node?.connection_name.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    <Typography variant="body1" className="infoToolTip">
                        Name :
                        {' '}
                        {node?.name.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {node?.connectionDetails && getTooltipFromObject(node?.connectionDetails)}
                {
                    database &&
                    <Typography variant="body1" className="infoToolTip">
                        Database :
                        {' '}
                        {database.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    schema &&
                    <Typography variant="body1" className="infoToolTip">
                        Schema :
                        {' '}
                        {schema.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.full_name &&
                    <Typography variant="body1" className="infoToolTip">
                        Full Name :
                        {' '}
                        {node?.full_name.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.connectionType &&
                    <Typography variant="body1" className="infoToolTip">
                        Connection Type :
                        {' '}
                        {node?.connectionType.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.isEmbedded &&
                    <Typography variant="body1" className="infoToolTip">
                        isEmbedded :
                        {' '}
                        {node.isEmbedded.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.remoteType &&
                    <Typography variant="body1" className="infoToolTip">
                        Remote Type :
                        {' '}
                        {node.remoteType.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.calculationFields && node.calculationFields.length > 0 &&
                    <Typography variant="body1" className="infoToolTip">
                        Referenced Fields for Calculations :
                        {' '}
                        {node.calculationFields.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.description &&
                    <Typography variant="body1" className="infoToolTip">
                        Description :
                        {' '}
                        {node.description.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.updatedAt &&
                    <Typography variant="body1" className="infoToolTip">
                        UpdatedAt :
                        {' '}
                        {node.updatedAt}
                    </Typography>
                }
                {
                    node?.isHidden?.toString() &&
                    <Typography variant="body1" className="infoToolTip">
                        Hidden :
                        {' '}
                        {node?.isHidden?.toString()}
                    </Typography>
                }
                {
                    node?.isNullable?.toString() &&
                    <Typography variant="body1" className="infoToolTip">
                        Nullable :
                        {' '}
                        {node?.isNullable?.toString()}
                    </Typography>
                }
                {
                    node?.moreInfo?.dataCategory &&
                    <Typography variant="body1" className="infoToolTip">
                        Data category :
                        {' '}
                        {node.moreInfo.dataCategory.toString()}
                    </Typography>
                }
                {
                    datatype &&
                    <Typography variant="body1" className="infoToolTip">
                        Data type :
                        {' '}
                        {datatype?.toString()}
                    </Typography>
                }
                {
                    node?.moreInfo?.role &&
                    <Typography variant="body1" className="infoToolTip">
                        Role :
                        {' '}
                        {node.moreInfo.role?.toString()}
                    </Typography>
                }
                {
                    node?.moreInfo?.semanticRole &&
                    <Typography variant="body1" className="infoToolTip">
                        Semantic role :
                        {' '}
                        {node.moreInfo.semanticRole?.toString()}
                    </Typography>
                }
                {
                    node?.moreInfo?.aggregation &&
                    <Typography variant="body1" className="infoToolTip">
                        Aggregation :
                        {' '}
                        {node.moreInfo.aggregation?.toString()}
                    </Typography>
                }
                {
                    node?.moreInfo?.formula &&
                    <Typography variant="body1" className="infoToolTip">
                        Formula :
                        {' '}
                        {node.moreInfo.formula?.toString()}
                    </Typography>
                }
                {
                    node.source_type &&
                    <Typography variant="body1" className="infoToolTip">
                        Source Type :
                        {' '}
                        {node?.source_type.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.field_type &&
                    <Typography variant="body1" className="infoToolTip">
                        Field Type :
                        {' '}
                        {node.field_type.toString().replace(/"/g, '') ?? ''}
                    </Typography>
                }
                {
                    node.owner &&
                    <Typography variant="body1" className="infoToolTip">
                        Onwer :
                        {' '}
                        {node.owner?.toString()}
                    </Typography>
                }
                {
                    node?.task_id &&
                    <Typography variant="body1" className="infoToolTip">
                        Task ID :
                        {' '}
                        {node.task_id}
                    </Typography>
                }
                {
                    node?.dag_id &&
                    <Typography variant="body1" className="infoToolTip">
                        DAG ID :
                        {' '}
                        {node.dag_id}
                    </Typography>
                }
                {
                    node.status &&
                    <Typography variant="body1" className="infoToolTip">
                        Status :
                        {' '}
                        {node.status.toString() ?? 'Pending'}
                    </Typography>
                }
            </Grid>
        );

    };

    /**
     * Get color class for the given node
     * @param {*} node
     * @returns Class Name
     */
    const getColorClass = (node) => {
        let className = '';
        if (node?.status?.toLowerCase() === 'deprecated') {
            className = 'deprecatedNode';
        } else if (node?.overall_status?.toLowerCase() === 'failed') {
            className = 'failedNode';
        }
        return className;
    };

    /**
     * Return Title Content
     * @param {*} value
     * @returns
     */
    const titleRender = (value, node) => {
        return (
            <Grid className={`titleIcon ${getColorClass(node?.options)}`}>
                <Tooltip title={getToolTipContent(node?.options)} arrow placement="top">
                    <Grid className="titleIconInner">
                        <Grid className="assetTypeIcon">
                            {bindConnectorIcon(node?.options)}
                        </Grid>
                        <span className="titleText">
                            {value}
                        </span>
                    </Grid>
                </Tooltip>
                <Grid className="items">
                    {bindMappedConnectionIcon(node?.options)}
                    {bindDQscore(node?.options)}
                    {bindActionButton(node?.options)}
                    {bindExpandCollaspeIcon(node?.options)}
                </Grid>
            </Grid>
        );
    };

    /**
     * Return Columns Configs
     * @returns
     */
    const getColumns = () => {
        return [
            {
                key: 'name',
                render: (value, node) => {
                    const alerts = node?.mapped_alerts ?? node?.alerts ?? 0;
                    return (
                        <Grid className="titleIcon titleconColumns">
                            <Tooltip title={getToolTipContent(node)} arrow placement="left-end">
                                <Grid className="titleIconInner">
                                    {getColumnTypeIcon(node)}
                                    {/* for errortest class name "error" */}
                                    <span className={`titleText ${alerts ? 'error' : ''}`}>
                                        {value}
                                    </span>
                                </Grid>
                            </Tooltip>
                            <Grid className="items">
                                {bindDQscore(node)}
                                {bindActionButton(node)}
                            </Grid>
                        </Grid>
                    );
                }
            }
        ];
    };

    /**
     * Asset Mapping Select Event
     * @param {*} value
     */
    const assetMappingOnSelectEvent = (value) => {
        assetMappingOnSelect(value, selectedAsset);
        setAssetListAnchor(null);
        setSeletedAsset(null);
    };

    /**
     * Render Lineage
     */
    const renderLineage = () => {
        return (
            <LineageDag
                tables={config.tables}
                relations={config.relations}
                columns={getColumns()}
                centerId={lineageConfig.centerId}
                enableHoverChain
                onLoaded={
                    (canvas) => {
                        setLineageConfig({
                            ...lineageConfig,
                            canvas
                        });
                    }
                }
                config={
                    {
                        titleRender: (value, node) => titleRender(value, node),
                        minimap: {
                            enable: true,
                            config: {
                                nodeColor: '#64AAEF'
                            }
                        }
                    }
                }
                actionMenu={lineageConfig.actionMenu}
            />
        );
    };

    return (
        <Grid className={classes.lineageContainer} sx={sx}>
            {
                config && config.tables &&
                renderLineage()
            }
            {
                Boolean(assetListAnchor) &&
                <PopOverComponent
                    anchorEl={assetListAnchor}
                    open={Boolean(assetListAnchor)}
                    onClose={hideAssetMappingList}
                >
                    <ValidatorForm onSubmit={() => { }}>
                        <Grid className={classes.mappingListPopover}>
                            <AutoCompleteComponent
                                variant="standard"
                                data={assetsList || []}
                                customOption
                                labelKey={'name'}
                                fullWidth
                                style={{ zIndex: 1 }}
                                placeholder={'Select Asset'}
                                onChange={(event, newValue) => assetMappingOnSelectEvent(newValue)}
                                renderType={'reportMap'}
                            />
                        </Grid>
                    </ValidatorForm>
                </PopOverComponent>
            }
        </Grid >
    );
}

// default props
LineageComponent.defaultProps = {
    classes: {},
    config: {},
    assets: [],
    resetLineageCanvas: false,
    assetType: '',
    assetMappingOnSelect: () => { },
    removeLink: () => { },
    handleNavigation: () => { },
    handleShowColumns: () => { }
};

// prop types
LineageComponent.propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
    assets: PropTypes.array,
    resetLineageCanvas: PropTypes.bool,
    assetMappingOnSelect: PropTypes.func,
    removeLink: PropTypes.func,
    handleNavigation: PropTypes.func,
    handleShowColumns: PropTypes.func,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
        ),
        PropTypes.func,
        PropTypes.object
    ]),
    assetType: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...LineageStyle(theme)
    }),
    { withTheme: true }
)(LineageComponent);