import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

// Import Styles
import LayoutStyles from '../../../../layouts/style.jsx';
import style from "./style.jsx";
import { CloseIcon, EditIcon, TickIcon } from '../../../../assets/svg/index.js';

// Import Component
import { BarChartComponent, DraggableBarChartComponent } from '../../../../components/index.js';
import PreviewTabs from './components/tabs.jsx';

function PreviewComponent(props) {

    /**
     * Define Props
     */
    const { classes, open, handleClosePreview, type, data, updateChartEditChanges, validKey, lengthChartValues, propertiesPermission, removeFailedRows } = props;

    /**
     * Get Attribute Name
     */
    const attribute_name = useSelector((state) => state.attribute.name);


    /**
     * Define State
     */
    const [showEdit, setShowEdit] = useState(false);
    const [chartData, setChartData] = useState(type === 'Length' ? lengthChartValues : data);

    /**
     * Hangle Edit
     */
    const handleEdit = () => {
        setShowEdit(!showEdit);
    };

    /**
     * Hangle Edit Data
     * @param {*} cData
     */
    const handleEditEvent = (cData) => {
        setChartData(cData);
    };

    return (
        <Dialog
            open={open}
            fullScreen
            onClose={() => handleClosePreview()}
        >
            <Grid className={`${classes.prevContainer} prevContainer`}>
                <Grid className="dflex alignCenter spaceBetween headerContainer">
                    <Grid>
                        <Typography variant="body1" className="capitalize mr-2">
                            {attribute_name}
                        </Typography>
                        <Typography variant="h4" className="">
                            {`${type} Metrics`}
                        </Typography>
                    </Grid>
                    <Grid className="dflex alignCenter">
                        {
                            showEdit &&
                            <IconButton className="tickIconBtn" onClick={() => { updateChartEditChanges(chartData); handleEdit(); }}>
                                <TickIcon />
                            </IconButton>
                        }
                        {
                            !showEdit && propertiesPermission?.is_edit &&
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                        }
                        <IconButton onClick={() => handleClosePreview()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid className={classes.previmageConatiner}>
                <Grid className={classes.chartConatiner}>
                    {
                        type === 'Length'
                            ? <DraggableBarChartComponent
                                chartData={data || []}
                                editable={showEdit}
                                validKey={validKey}
                                handleEditEvent={(data) => handleEditEvent(data)}
                                min_max={chartData || {}}
                            />
                            : <BarChartComponent
                                chartData={chartData || []}
                                editable={showEdit}
                                validKey={validKey}
                                handleEditEvent={(data) => handleEditEvent(data)}
                            />
                    }
                </Grid>
                    <PreviewTabs data={data} type={type} removeFailedRows={removeFailedRows} />
                </Grid>
            </Grid>
        </Dialog>
    );
}


/**
 * Define Component Props
 */
PreviewComponent.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    type: PropTypes.string,
    data: PropTypes.array,
    validKey: PropTypes.string,
    lengthChartValues: PropTypes.object,
    handleClosePreview: PropTypes.func,
    updateChartEditChanges: PropTypes.func,
    propertiesPermission: PropTypes.object,
    removeFailedRows: PropTypes.bool
};


/**
 * Set Default Values
 */
PreviewComponent.defaultProps = {
    classes: {},
    open: false,
    type: "",
    data: [],
    validKey: 'is_valid',
    lengthChartValues: {},
    handleClosePreview: () => { },
    updateChartEditChanges: () => { },
    propertiesPermission: {},
    removeFailedRows: false
};

export default withStyles((theme) => ({
    ...LayoutStyles(theme),
    ...style(theme)
}))(PreviewComponent);