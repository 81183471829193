import React from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails, Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { styled } from '@mui/system';

const StyledToggleButton = styled(ToggleButton)(() => ({
    "&.tooltip-enabled-disabled-button": {
      pointerEvents: "auto"
    }
  }));

function AccordionBody(props) {

    const { classes, data, handleCheckBox, securityPermission, permission } = props;

    const { isUpdating } = useSelector((state) => state.security);

    function checkPermission(oldperm, newPermission) {
        if (newPermission === 'is_none') {
          return true;
        } else if (newPermission === 'is_view') {
          return oldperm.is_view === true || oldperm.is_edit === true;
        } else if (newPermission === 'is_edit') {
          return oldperm.is_edit === true;
        }
          return false;
      }

    const changeChildState = (data, role, newPermission) => {
        const check_feat = permission[role?.id];
        const selected_feat = check_feat?.find((val) => val.feature === data.dependency_child);
        const updated_id = { ...selected_feat, id: selected_feat?.feature };
        const updated_role_permission_id = { ...role, role_permission_id: selected_feat.id };
        let isNew = true;
        const newData = { "is_edit": true, "is_view": true, "is_none": true };
        if (updated_id.is_edit || updated_id.is_view || updated_id.is_none) {
            isNew = false;
        }
        if (newPermission === "is_view" && updated_id.is_edit) {
            newData.is_none = false;
            newData.is_edit = false;
            handleCheckBox(updated_id, updated_role_permission_id, isNew, newData);
        } else if (newPermission === "is_none" && (updated_id.is_edit || updated_id.is_view)) {
            newData.is_edit = false;
            newData.is_view = false;
            handleCheckBox(updated_id, updated_role_permission_id, isNew, newData);
        }
    };


    const handleChange = (data, role, newPermission) => {
        if (data.dependency_parent) {
            const check_perm = permission[role?.id];
            const selected_perm = check_perm?.find((val) => val.feature === data.dependency_parent);
            const perm_check = checkPermission(selected_perm, newPermission);
            if (!perm_check) {
                return false;
            }
        }
        let isNew = true;
        const newData = { "is_edit": true, "is_view": true, "is_none": true };
        if (newPermission === "is_edit") {
            newData.is_none = false;
        } else if (newPermission === "is_view") {
            newData.is_none = false;
            newData.is_edit = false;
        } else {
            newData.is_edit = false;
            newData.is_view = false;
        }
        if (role.is_edit || role.is_view || role.is_none) {
            isNew = false;
        }
        handleCheckBox(data, role, isNew, newData);
        if (data.dependency_child) {
            changeChildState(data, role, newPermission);
        }

    };

    const getValue = (role) => {
        if (role.is_edit) {
            return "is_edit";
        } else if (role.is_view) {
            return "is_view";
        }
            return "is_none";

    };

    const checkRole = (role) => {
        const defaultRoles = ["194efe2d-62f8-4fea-8a7c-6264c5814fc0", "26c0b3c6-a763-475d-ae2c-5f5a75040e5f", "265f9b57-95ae-4230-a72e-efdd8332d818"];
        if (defaultRoles.indexOf(role?.id) > -1) {
            return false;
        }
        return true;
    };

    const handleButtonChange = (data, role, newPermission) => {
        if (!isUpdating && checkRole(role)) {
            handleChange(data, role, newPermission);
        }
    };

    return (
        <AccordionDetails>
            <Grid container className={classes.accordionBody} flexWrap={"nowrap"}>
                <Grid itemScope className="pl-3 dflex alignCenter" sx={{ minWidth: '120px' }}>
                    <Typography variant="body1">
                        {data.sub_feature}
                    </Typography>
                </Grid>
                {
                    data.roles.map((role, index) => (
                        <Grid item className="permissionCell" key={`check-circle-${index}`}>
                            <ToggleButtonGroup
                                color="primary"
                                value={getValue(role)}
                                exclusive
                                onChange={(event, newPermission) => handleChange(data, role, newPermission)}
                                aria-label="Platform"
                                disabled={!securityPermission?.is_edit || isUpdating || !checkRole(role)}>
                                <StyledToggleButton value="is_none"
                                className="tooltip-enabled-disabled-button"
                                onClick={() => handleButtonChange(data, role, "is_none")}>
                                    <Tooltip title="None">
                                        <Grid>
                                            N
                                        </Grid>
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="is_view"
                                className="tooltip-enabled-disabled-button"
                                onClick={() => handleButtonChange(data, role, "is_view")}>
                                    <Tooltip title="View Only">
                                        <Grid>
                                            V
                                        </Grid>
                                    </Tooltip>
                                </StyledToggleButton>
                                <StyledToggleButton value="is_edit"
                                className="tooltip-enabled-disabled-button"
                                onClick={() => handleButtonChange(data, role, "is_edit")}>
                                    <Tooltip title="Edit">
                                        <Grid>
                                            E
                                        </Grid>
                                    </Tooltip>
                                </StyledToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    ))
                }
                <Grid item xs={1} />
            </Grid>
        </AccordionDetails>
    );
}

// default props
AccordionBody.defaultProps = {
    classes: {},
    data: {},
    handleCheckBox: () => { },
    securityPermission: {},
    permission: {}
};

// prop types
AccordionBody.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleCheckBox: PropTypes.func,
    securityPermission: PropTypes.object,
    permission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.permission, nextProps.permission);
}

export default React.memo(AccordionBody, areEqual);