import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import classNames from 'classnames';

//  Import Components
import {
    Grid,
    IconButton,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';

//  Import Styles
import ConfigureStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import {
    CopyIcon,
    DeleteIcon,
    EditIcon
} from '../../../../../assets/svg/index.js';

// Import Components
import ConnectorsIcon from '../../../../../components/connectorsIcon/index.jsx';

// App Constats
import appConstants from '../../../../../constants/appConstants.js';

function ConnectionCard(props) {
    /**
     * Define Props
     */
    const {
        classes,
        item,
        index,
        navigateToAssetPage,
        handleToggleChange,
        handleDeleteClick,
        handleCopyClick,
        connectionPermission,
        handleEditClick,
        handleViewLogs
    } = props;

    const getDescription = (item) => {
        let description = "Connect and upload flat files from";
        if (item.description?.length > 0) {
            description = item.description;
            return description;
        }
        description = item.long_description?.length > 0 ? item.long_description : description;
        return description;
    };

    return (
        <Grid
            className={
                `${classes.connectionCard} ${!item.is_active ? 'deactive' : ''
                }`
            }
        >
            <Grid
                className={'connectionCardHeader'}
                container
                justifyContent={'space-between'}
                alignItems="center"
                sx={{ height: 33 }}
            >
                {
                    connectionPermission?.is_edit && item.has_license &&
                    <Switch
                        size="small"
                        checked={item.is_active}
                        onChange={(event) => handleToggleChange(event, item, index)}
                        inputProps={
                            {
                                'aria-label': 'controlled'
                            }
                        }
                    />
                }
                <Grid className={classes.action}>
                    {
                        connectionPermission?.is_edit && item.has_license && item.is_active &&
                        <Tooltip
                            arrow
                            title={appConstants.labels.tooltips.edit}
                        >
                            <IconButton className="" onClick={() => handleEditClick(item)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        connectionPermission?.is_edit && item.has_license && item.is_active &&
                        <Tooltip
                            arrow
                            title={appConstants.labels.tooltips.copy}
                        >
                            <IconButton className="" onClick={() => handleCopyClick(item)}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        connectionPermission?.is_edit &&
                        <Tooltip
                            arrow
                            title={appConstants.labels.tooltips.delete}
                        >
                            <IconButton onClick={() => handleDeleteClick(item)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
            </Grid>

            <Grid onClick={() => navigateToAssetPage(item)}>
                <Grid className={classNames(classes.imgContainer, item.type)}>
                    <ConnectorsIcon connection_type={item.type} />
                </Grid>
                {
                    !item.is_valid ?
                        (
                            <Grid className="failedTextContainer">
                                <Typography variant="body1" className={classes.failedText}>
                                    Connection Failed
                                    {' '}
                                    <span>
                                        Retry
                                    </span>
                                </Typography>
                            </Grid>
                        ) :
                        (
                            <Grid className="connectorTileDesc">
                                <Tooltip
                                    arrow
                                    title={item.name}
                                >
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        className="mb5 mt5 connectorsTitle"
                                    >
                                        {item.name}
                                    </Typography>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    title={getDescription(item)}
                                >
                                    <Typography
                                        variant="body1 "
                                        align="center"
                                        className="mb5 mt-1 connectorsTitledesc"
                                    >
                                        {getDescription(item)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        )
                }
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={`${classes.muiChip} cardFooter`}
                >
                    <Typography variant="body1" className={`${classes.linkText} pointer`} onClick={(event) => handleViewLogs(event, item)}>
                        View Logs
                    </Typography>
                    <Typography variant="body1" align="right">
                        <b>
                            {item.total_assets}
                        </b>
                        {' '}
                        {appConstants.labels.connection.assets}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
ConnectionCard.defaultProps = {
    classes: {},
    item: {},
    index: 0,
    connectionPermission: {},
    navigateToAssetPage: () => { },
    handleToggleChange: () => { },
    handleDeleteClick: () => { },
    handleCopyClick: () => { },
    handleEditClick: () => { },
    handleViewLogs: () => { }
};

// prop types
ConnectionCard.propTypes = {
    classes: PropTypes.object,
    item: PropTypes.object,
    index: PropTypes.number,
    connectionPermission: PropTypes.object,
    navigateToAssetPage: PropTypes.func,
    handleToggleChange: PropTypes.func,
    handleDeleteClick: PropTypes.func,
    handleCopyClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    handleViewLogs: PropTypes.func
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.item, nextProps.item) && _.isEqual(prevProps.permission, nextProps.permission) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...ConfigureStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(ConnectionCard, areEqual));