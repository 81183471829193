const style = (theme) => ({
    btnTabsGrey: {
        '& .MuiTab-root.Mui-selected': {
            background: theme.palette.secondary.main,
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiTab-root': {
            background: theme.palette.greyshades.lightestgrey,
            color: `${theme.palette.greyshades.darkgrey} !important`,
            opcaity: 0.5,
            height: 30,
            minHeight: 30,
            marginRight: 8,
            borderRadius: '30px',
            textTransform: 'capitalize',
            minWidth: 40,
            fontWeight: 500
        },
        '& .MuiTabs-indicator': {
            display: 'none !important'
        }
    },
    btnTabsBlue: {
        '& .MuiTab-root.MuiButtonBase-root.Mui-selected': {
            background: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.primary.contrastText} !important`
        },
        '& .MuiTab-root': {
            background: theme.palette.greyshades.grey,
            color: `${theme.palette.headers.body1.color} !important`,
            opcaity: 0.5,
            height: 30,
            minHeight: 30,
            marginRight: 8,
            borderRadius: '30px',
            textTransform: 'capitalize',
            fontFamily: `${theme.palette.headers.body1.fontFamily} !important`,
            minWidth: 40,
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTabs-indicator': {
            display: 'none !important'
        }
    },
    btnTabsInline: {
        '& .MuiTab-root.Mui-selected': {
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
            // color: `${theme.palette.headers.body1.color} !important`,
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiTabs-flexContainer': {
            borderBottom: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .MuiTab-root': {
            color: `${theme.palette.headers.body1.color} !important`,
            height: 42,
            minHeight: 42,
            marginRight: 8,
            borderRadius: '0px',
            textTransform: 'capitalize',
            minWidth: 40,
            padding: '15px 20px 10px',
            borderBottom: '3px solid transparent',
            fontSize: `${theme.palette.headers.h4.size}px !important`
        },
        '& .MuiTabs-indicator': {
            display: 'none !important'
        }
    }
});
export default style;