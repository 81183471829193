import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions and Reducer
import {
    deleteMeasureRequest, deleteMeasureSuccess, deleteMeasureFailure, createMeasureRequest, createMeasureSuccess, createMeasureFailure,
    updateMeasureRequest, updateMeasureSuccess, updateMeasureFailure, getMeasureRequest, getMeasureSuccess, getMeasureFailure,
    getMeasureDetailRequest, getMeasureDetailSuccess, getMeasureDetailFailure, updateAttributeEnumMeasureRequest, updateAttributeEnumMeasureSuccess,
    updateAttributeEnumMeasureFailure, getAssetMeasureRequest, getAssetMeasureSuccess, getAssetMeasureFailure, updateAssetMeasureRequest, updateAssetMeasureSuccess,
    updateAssetMeasureFailure, getMeasurePreviewRequest, getMeasurePreviewSuccess, getMeasurePreviewFailure, exportAssetMeasureRequest, exportAssetMeasureSuccess, exportAssetMeasureFailure,
    syncProfileMeasuresRequest, syncProfileMeasuresSuccess, syncProfileMeasuresFailure, updateCustomMeasuresRequest, updateCustomMeasuresSuccess, updateCustomMeasuresFailure, createStandaloneMeasureSuccess, createStandaloneMeasureFailure, deleteAssetMeasureRequest, deleteAssetMeasureSuccess, deleteAssetMeasureFailure, updateStandAloneMeasureFailure,
    updateStandAloneMeasureSuccess, updateStandAloneMeasureRequest, createStandaloneMeasureRequest, getMeasureStatisticsRequest, getMeasureStatisticsSuccess, getMeasureStatisticsFailure,
    getMeasureStatusRequest, getMeasureStatusSuccess, getMeasureStatusFailure, exportFailedRowRequest, exportFailedRowSuccess, exportFailedRowFailure, getComparisonMeasureRequest, getComparisonMeasureSuccess,
    getComparisonMeasureFailure, getMeasureRunStatusRequest, getMeasureRunStatusSuccess, getMeasureRunStatusFailure, getUpdatedAssetMeasureRequest
} from '../reducer/measureReducer';
import { displyAlert } from "../reducer/alertReducer";
import { getActiveMeasureCategoriesRequest, updateAttributeMetadata, updateDistributionMetadata } from "../reducer/attributeReducer";
import { updateVersionDetail } from '../reducer/versionReducer';

// Import Service
import { measureService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';

/**
 * Create
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
    ofType(createMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.create(payload)).pipe(
            concatMap((res) => of(createMeasureSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }))),
            catchError((error) => of(createMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get
 * @param {*} action$
 * @returns
 */
const get = (action$) => action$.pipe(
    ofType(getMeasureRequest),
    mergeMap(({ payload: { query, token } }) => {
        return from(measureService.get(query, token)).pipe(
            concatMap((res) => of(getMeasureSuccess(res))),
            catchError(() => of(getMeasureFailure()))
        );
    })
);

/**
 * Get Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getMeasureDetailRequest),
    mergeMap(({ payload }) => {
        return from(measureService.detail(payload)).pipe(
            concatMap((res) => of(getMeasureDetailSuccess(res))),
            catchError(() => of(getMeasureDetailFailure()))
        );
    })
);


/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
    ofType(deleteMeasureRequest),
    mergeMap(({ payload: { id, type } }) => {
        return from(measureService.remove(id, type)).pipe(
            concatMap(() => of(deleteMeasureSuccess(id))),
            catchError((error) => of(deleteMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateMeasureRequest),
    mergeMap(({ payload: { id, ...rest } }) => {
        return from(measureService.update(id, rest)).pipe(
            concatMap((res) => {
                if (res.attribute_id || res.attribute) {
                    return of(getActiveMeasureCategoriesRequest(res.attribute_id || res.attribute), updateMeasureSuccess({ "response": res?.data ?? {}, "req_data": rest }), updateDistributionMetadata(res?.data ?? {}), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
                }
                return of(
                    updateMeasureSuccess({ "response": res?.data ?? {}, "req_data": rest }),
                    updateDistributionMetadata(res?.data ?? {}),
                    updateVersionDetail({ monitoring: res?.data?.monitoring_detail ?? {} }),
                    displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated })
                );
            }),
            catchError((error) => of(updateMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Add / Update Attribute Level Pattern Measure
 * @param {*} action$
 * @returns
 */
const enums = (action$) => action$.pipe(
    ofType(updateAttributeEnumMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.enums(payload)).pipe(
            concatMap((res) => of(updateAttributeEnumMeasureSuccess(res), updateVersionDetail(res.data?.version_info ?? {}), updateAttributeMetadata({ "enums": res?.data?.data ?? [] }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateAttributeEnumMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Asset Measures
 * @returns
 */
const getAssetMeasures = (action$) => action$.pipe(
    ofType(getAssetMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getAssetMeasures(payload.params, payload.token)).pipe(
            concatMap((res) => { return of(getAssetMeasureSuccess({ params: payload.params, data: res?.data ?? [] }), getMeasureStatisticsSuccess({ data: res?.data?.statistics ?? {} })); }),
            catchError(() => { return of(getAssetMeasureFailure()); })
        );
    })
);

/**
 * Update Asset Measure Property
 * @param {*} action$
 * @returns
 */
const updateAssetMeasure = (action$) => action$.pipe(
    ofType(updateAssetMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.updateAssetMeasure(payload)).pipe(
            concatMap((res) => of(updateAssetMeasureSuccess(res), getUpdatedAssetMeasureRequest(), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateAssetMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Create StandAlone Measure
 * @param {*} action$
 * @returns
 */
const createStandAloneMeasure = (action$) => action$.pipe(
    ofType(createStandaloneMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.create(payload)).pipe(
            concatMap((res) => of(createStandaloneMeasureSuccess({ data: { ...payload, ...res.data } }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }))),
            catchError((error) => of(createStandaloneMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Failed Rows
 * @param {*} action$
 * @returns
 */
const getMeasurePreview = (action$) => action$.pipe(
    ofType(getMeasurePreviewRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getMeasurePreview(payload)).pipe(
            concatMap((res) => of(getMeasurePreviewSuccess(res))),
            catchError((error) => of(getMeasurePreviewFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Export Metadata
 * @returns
 */
const exportAssetMeasure = (action$) => action$.pipe(
    ofType(exportAssetMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.exportAssetMeasure(payload)).pipe(
            concatMap((res) => { return of(exportAssetMeasureSuccess(res.data)); }),
            catchError((error) => { return of(exportAssetMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })); })
        );
    })
);

/**
 * Sync Profile Measures
 * @returns
 */
const syncProfileMeasures = (action$) => action$.pipe(
    ofType(syncProfileMeasuresRequest),
    mergeMap(() => {
        return from(measureService.syncProfileMeasures()).pipe(
            concatMap((res) => { return of(syncProfileMeasuresSuccess(res.data)); }),
            catchError(() => { return of(syncProfileMeasuresFailure()); })
        );
    })
);

/**
 * Sync Profile Measures
 * @returns
 */
const updateCustomMeasures = (action$) => action$.pipe(
    ofType(updateCustomMeasuresRequest),
    mergeMap(() => {
        return from(measureService.update_custom_measure()).pipe(
            concatMap((res) => { return of(updateCustomMeasuresSuccess(res.data)); }),
            catchError(() => { return of(updateCustomMeasuresFailure()); })
        );
    })
);

/**
 * Delete Actions
 * @param {*} action$
 * @returns
 */
const deleteStandAloneMeasure = (action$) => action$.pipe(
    ofType(deleteAssetMeasureRequest),
    mergeMap(({ payload: { id, type } }) => {
        return from(measureService.remove(id, type)).pipe(
            concatMap(() => of(deleteAssetMeasureSuccess(id))),
            catchError((error) => of(deleteAssetMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Update Actions
 * @param {*} action$
 * @returns
 */
const updateStandAloneMeasure = (action$) => action$.pipe(
    ofType(updateStandAloneMeasureRequest),
    mergeMap(({ payload: { id, isUpdate, ...rest } }) => {
        return from(measureService.update(id, rest)).pipe(
            concatMap((res) => {
                if (isUpdate) {
                    return of(updateStandAloneMeasureSuccess({ "response": res?.data ?? {}, "req_data": rest }), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
                }
                return of(displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }));
            }),
            catchError((error) => of(updateStandAloneMeasureFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Measure Statistics
 * @returns
 */
const getMeasureStatistics = (action$) => action$.pipe(
    ofType(getMeasureStatisticsRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getMeasureStatistics(payload.params, payload.token)).pipe(
            concatMap((res) => { return of(getMeasureStatisticsSuccess({ params: payload.params, data: res?.data ?? [] })); }),
            catchError(() => { return of(getMeasureStatisticsFailure()); })
        );
    })
);

/**
 * Get Measure Dag Running Status
 * @param {*} action$
 * @returns
 */
const getMeasureRunningStatus = (action$) => action$.pipe(
    ofType(getMeasureStatusRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getMeasureStatus(payload)).pipe(
            concatMap((res) => of(getMeasureStatusSuccess(res.data))),
            catchError(() => of(getMeasureStatusFailure()))
        );
    })
);

/**
 * Export Failed Rows
 * @param {*} action$
 * @returns
 */
const exportFailedRows = (action$) => action$.pipe(
    ofType(exportFailedRowRequest),
    mergeMap(({ payload }) => {
        return from(measureService.exportFailedRows(payload)).pipe(
            concatMap((res) => of(exportFailedRowSuccess(res))),
            catchError((error) => of(exportFailedRowFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get comparison asset measures
 */
const getComparisonMeasure = (action$) => action$.pipe(
    ofType(getComparisonMeasureRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getComparisonMeasure(payload)).pipe(
            concatMap((res) => { return of(getComparisonMeasureSuccess({ "response": res, "request": payload })); }),
            catchError(() => {
                return of(getComparisonMeasureFailure(payload));
            })
        );
    })
);

/**
 * Get comparison measures
 */
const getMeasuresRunStatus = (action$) => action$.pipe(
    ofType(getMeasureRunStatusRequest),
    mergeMap(({ payload }) => {
        return from(measureService.getMeasureRunStatus(payload)).pipe(
            concatMap((res) => { return of(getMeasureRunStatusSuccess({ data: res?.data ?? [] })); }),
            catchError(() => {
                return of(getMeasureRunStatusFailure(payload));
            })
        );
    })
);


// Export All Epic Functions
export const measureEpic = [
    remove, update, create, get, detail, enums,
    getAssetMeasures, updateAssetMeasure, getMeasurePreview, exportAssetMeasure, syncProfileMeasures, updateCustomMeasures, createStandAloneMeasure,
    deleteStandAloneMeasure, updateStandAloneMeasure, getMeasureStatistics, getMeasureRunningStatus, exportFailedRows, getComparisonMeasure,
    getMeasuresRunStatus
];