import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, InputAdornment } from '@mui/material';

//import Componetns
import { ChipComponent, SelectComponent, IntegerInputComponent, NumberInputComponent } from '../../../index.js';

//import Styles
import BehavioralStyle from "./style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Image
import appConstants from '../../../../constants/appConstants.js';

// Import Helpers
import { getBehavioralType } from '../../../../helpers/appHelpers';
import { ValidatorForm } from 'react-material-ui-form-validator';


function BehavioralComponent(props) {
    /**
     * Define Props
     */
    const { classes, data, attributes, isNew, attribute_id, editMeasure, propertyPermission } = props;

    /**
     * Define State
     */
    const [measure, setMeasure] = useState({});

    /**
     * Handle On Change
     * @param {*} property
     * @param {*} value
     */
    const onChangeProperty = (property, value) => {
        setMeasure({ ...measure, [property]: value });
    };


    /**
     * Prepare Attributes
     * @param {*} attributes
     * @returns attributes
     */
    const prepareBehavioralAttributes = (attributes) => {
        return attributes.map((attribute) => {
            return {
                name: attribute.name,
                derived_type: attribute.derived_type || "text",
                data_type: attribute.derived_type || "text",
                id: attribute.id,
                is_default: false
            };
        });
    };

    /**
     * Manipulate the attributes using UseMemo
     */
    const behavioralAttributes = useMemo(() => prepareBehavioralAttributes(attributes), [attributes]);

    /**
     * Get New Behavioral Rule
     */
    const getNewBehavioralRule = useCallback(() => {
        const defaultAttribute = behavioralAttributes.filter((data) => data.id === attribute_id).map((data) => {
            return {
                ...data,
                is_default: true
            };
        });
        const newRule = {
            aggregation_type: appConstants.behavioralAggrecationTypes[0],
            day_interval: appConstants.dayInterval[0].value,
            slice: 1,
            slice_by: "day",
            attributes: [...defaultAttribute]
        };
        return newRule;
    }, [behavioralAttributes]);

    /**
     * Assign Rules To State
     */
    useEffect(() => {
        if (isNew) {
            const newRule = getNewBehavioralRule();
            setMeasure(newRule);
        } else {
            setMeasure(data);
        }
    }, []);

    /**
     * Update Measure
     */
    useEffect(() => {
        editMeasure('properties', measure);
    }, [measure]);

    return (
        <Grid className={classes.behavioralContainer}>
            <Grid container spacing={4} sx={{ marginTop: '-22px' }}>
                <Grid item xs={12} className="dflex">
                    {
                        getBehavioralType(measure.attributes).length ?
                            <Grid item className="greyInput mr-3">
                                <Typography variant="body1">
                                    {getBehavioralType(measure.attributes)}
                                </Typography>
                            </Grid> : null
                    }
                    <Grid item xs={6} className="mr-2">
                        <Typography variant="h6" className="pb5">
                            Select Category Attributes
                        </Typography>
                        <ValidatorForm onSubmit={() => { }}>
                            <ChipComponent
                                data={measure.attributes || []}
                                labelKey={"name"}
                                placeholder="Select Category Attribute"
                                add={propertyPermission?.is_edit}
                                filterCondition
                                availableList={behavioralAttributes}
                                editable={propertyPermission?.is_edit}
                                handleChipEvent={(value) => onChangeProperty("attributes", value)}
                                addType="autocomplete"
                                autoCompleteDataTypeOption
                                limit={3}
                                chipClass="info"
                            />
                        </ValidatorForm>
                    </Grid>
                    {
                        getBehavioralType(measure.aggregator_attributes).length ?
                            <Grid item className="greyInput mr-3">
                                <Typography variant="body1">
                                    {getBehavioralType(measure.aggregator_attributes)}
                                </Typography>
                            </Grid> : null
                    }
                    <Grid item xs={2} className="mr-2">
                        <Typography variant="h6" className="pb5">
                            Select Aggregator Attribute
                        </Typography>
                        <ValidatorForm onSubmit={() => { }}>
                            <ChipComponent
                                data={measure.aggregator_attributes || []}
                                labelKey={"name"}
                                placeholder="Select Aggregator Attribute"
                                add={propertyPermission?.is_edit}
                                filterCondition
                                availableList={behavioralAttributes.filter((item) => item.data_type === "Numeric" || item.data_type === "Integer")}
                                editable={propertyPermission?.is_edit}
                                handleChipEvent={(value) => onChangeProperty("aggregator_attributes", value)}
                                addType="autocomplete"
                                autoCompleteDataTypeOption
                                limit={1}
                                chipClass="info"
                                disabled={measure.aggregator_attributes?.length >= 1}
                                className={measure.aggregator_attributes?.length >= 1 ? classes.displayAdd : null}
                            />
                        </ValidatorForm>
                    </Grid>
                    <Grid item xs={2} className="mr-3">
                        <IntegerInputComponent
                            label="Slice"
                            variant="standard"
                            value={measure.slice || ""}
                            onChange={(event) => onChangeProperty("slice", event.target.value)}
                            fullWidth
                            disabled={!propertyPermission?.is_edit}
                            validators={measure.slice_by ? appConstants.sliceByErrorMessages[measure.slice_by].validators : appConstants.sliceByErrorMessages.day.validators}
                            errorMessages={measure.slice_by ? appConstants.sliceByErrorMessages[measure.slice_by].errorMessages : appConstants.sliceByErrorMessages.day.errorMessages}
                        />
                    </Grid>
                    <Grid item xs={2} className="mr-2">
                        <SelectComponent
                            label="Slice By"
                            variant="standard"
                            value={measure.slice_by || appConstants.sliceBy[2].value}
                            displayPropertyName="name"
                            propertyName="value"
                            list={appConstants.sliceBy}
                            onSelectChange={(value) => onChangeProperty("slice_by", value)}
                            isDisabled={!propertyPermission?.is_edit}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item sx={{ flex: 1 }}>
                            <SelectComponent
                            value={measure.aggregation_type || appConstants.behavioralAggrecationTypes[0]}
                            label="Filter By"
                            variant="standard"
                            list={appConstants.behavioralAggrecationTypes}
                            onSelectChange={(value) => onChangeProperty("aggregation_type", value)}
                            isDisabled={!propertyPermission?.is_edit}
                        />
                        </Grid>
                        <Grid item sx={{ flex: 1 }}>
                            <NumberInputComponent
                                className={classes.intervalText}
                                label="Interval"
                                variant="standard"
                                integeronly={false}
                                value={measure.day_interval || "1"}
                                onChange={(value) => onChangeProperty('day_interval', value)}
                                InputLabelProps={{ shrink: true }}
                                InputProps={
                                    {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <SelectComponent className={classes.interval}
                                                noOutline
                                                value={measure.interval || "Days"}
                                                list={["Days", "Months", "Years"]}
                                                onSelectChange={(value) => onChangeProperty("interval", value)}
                                                isDisabled={!propertyPermission?.is_edit}
                                            />
                                        </InputAdornment>
                                    }
                                }
                                disabled={!propertyPermission?.is_edit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
BehavioralComponent.defaultProps = {
    classes: {},
    data: {},
    attributes: [],
    isNew: false,
    attribute_id: "",
    editMeasure: () => { },
    propertyPermission: {}
};

// prop types
BehavioralComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    attributes: PropTypes.array,
    isNew: PropTypes.bool,
    attribute_id: PropTypes.string,
    editMeasure: PropTypes.func,
    propertyPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...BehavioralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(BehavioralComponent);