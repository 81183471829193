// Import API Helper
import { postRequest } from "../../helpers";

// Import Constants
import urlConstants from '../../constants/urlConstants';


/**
 * Detail
 * @param {*} params
 * @returns
 */
async function detail(params) {
    const response = await postRequest(urlConstants.asset.extension, params);
    return response;
}

/**
 * Export All Service Functions
 */
export const extensionService = {
    detail
};