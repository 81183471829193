import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
// import { useDispatch } from 'react-redux';

//import Componetns
import { Grid, Typography, Button } from '@mui/material';
import { SelectComponent } from '../../../../components/index.js';
import ImportDialog from './components/importDialog/index.jsx';

//import Styles
import GeneralStyle from "../../style.jsx";
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions

// Import Constant
import appConstants from '../../../../constants/appConstants.js';


function Import(props) {

    const { classes } = props;

    //Define State
    const [importType, setImportType] = useState("Metadata");
    const [openDialog, setOpenDialog] = useState(false);

    /**
     * onCloseDialog
     */
    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid item xs={12}>
            <Grid className={classes.headerConatiner}>
                <Typography variant="h5" className="">
                    {appConstants.labels.general.import}
                </Typography>
            </Grid>
            <Grid className={classes.bodyContainer}>
                <Typography variant="body1">
                    {appConstants.labels.general.exportDesc}
                </Typography>
                <Grid container spacing={6} className="mt-3">
                    <Grid item xs={3} className="pt-0">
                        <SelectComponent
                            fullWidth={false}
                            label={appConstants.labels.general.importType}
                            variant="standard"
                            list={["Metadata", "Measure", "Users"]}
                            value={importType || ""}
                            onSelectChange={(value) => setImportType(value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            size="small"
                            onClick={() => setOpenDialog(!openDialog)}
                        >
                            {appConstants.labels.general.import}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* Import */}
            <ImportDialog
                open={openDialog}
                onClose={onCloseDialog}
                type={importType}
            />
        </Grid>
    );
}

// default props
Import.defaultProps = {
    classes: {}
};

// prop types
Import.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Import);