import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    scheduleConfig: null,
    target_asset_list: [],
    target_measure_list: [],
    schedule_list: [],
    search: { orderBy: "desc", offset: 0, limit: 20, search_by: {} },
    loadMore: true,
    listLoading: true,
    assetScheduleJobs: [],
    select_asset_id: null,
    select_measure_id: null,
    run_details: [],
    run_details_loading: true
};

const scheduleSlice = createSlice({
    name: 'alert',
    initialState: { ...initialState },
    reducers: {
        createScheduleRequest() { },
        createScheduleSuccess(state, action) {
            state.scheduleConfig = action.payload && Object.keys(action.payload).length ? action.payload : null;
        },
        createScheduleFailure() { },
        updateScheduleRequest() { },
        updateScheduleSuccess(state, action) {
            state.scheduleConfig = action.payload && Object.keys(action.payload).length ? action.payload : null;
            const updatedData = state.schedule_list?.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    next_run: action.payload.next_schedule_date
                  };
                }
                return item;
              });
              state.schedule_list = updatedData;
        },
        updateScheduleFailure() { },
        deleteScheduleRequest() { },
        deleteScheduleSuccess(state, action) {
            state.scheduleConfig = null;
            const deletedList = [...state.schedule_list];
            const index = deletedList.findIndex((item) => item.id === action.payload);
            deletedList.splice(index, 1);
            state.schedule_list = [...deletedList];
        },
        deleteScheduleFailure() { },
        getScheduleRequest() { },
        getScheduleSuccess(state, action) {
            state.scheduleConfig = action.payload.data && Object.keys(action.payload.data).length ? action.payload.data : null;
            state.select_asset_id = action?.payload?.request?.asset_id;
            state.select_measure_id = action?.payload?.request?.measure_id;
        },
        getScheduleFailure() { },
        clearSchedule(state) {
            state.scheduleConfig = null;
            state.select_asset_id = null;
        },
        triggerJobRequest() { },
        triggerJobSuccess() { },
        triggerJobFailure() { },
        killJobRequest() { },
        killJobSuccess() { },
        killJobFailure() { },
        getTargetAssetRequest() { },
        getTargetAssetSuccess(state, action) {
            state.target_asset_list = action.payload.data;
        },
        getTargetAssetFailure() { },
        getTargetMeasureRequest() { },
        getTargetMeasureSuccess(state, action) {
            state.target_measure_list = action.payload.data;
        },
        getTargetMeasureFailure() { },
        getScheduleListRequest(state, action) {
            const clear = action.payload?.clear ?? false;
            state.listLoading = true;
            if (clear) {
                state.schedule_list = [];
                state.loadMore = true;
            }
        },
        getScheduleListSuccess(state, action) {
            const list = action.payload.data;
            const search_params = action.payload.params;
            state.schedule_list = [...state.schedule_list, ...list];
            state.search = { ...state.search, ...search_params };
            if ((list.length < state.search.limit)) {
                state.loadMore = false;
            }
            state.listLoading = false;
        },
        getScheduleListFailure(state) {
            state.listLoading = false;
        },
        getAssetJobListRequest(state) {
            state.assetScheduleJobs = [];
        },
        getAssetJobListSuccess(state, action) {
            state.assetScheduleJobs = action.payload.data || [];
        },
        getAssetJobListFailure() { },
        getRunDetailRequest() { },
        getRunDetailSuccess(state, action) {
            state.run_details = action.payload.data || [];
            state.run_details_loading = false;
        },
        getRunDetailFailure(state) {
            state.run_details_loading = false;
        },
        clearRunDetails(state) {
            state.run_details = [];
            state.run_details_loading = true;
        },
        getMeasureScheduleListRequest(state, action) {
            const clear = action.payload?.clear ?? false;
            if (clear) {
                state.schedule_list = [];
            }
            state.listLoading = true;
        },
        getMeasureScheduleListSuccess(state, action) {
            const list = action.payload.data;
            state.schedule_list = [...state.schedule_list, ...list];
            if ((list.length < state.search.limit)) {
                state.loadMore = false;
            }
            state.listLoading = false;
        },
        getMeasureScheduleListFailure(state) {
            state.listLoading = false;
        },
        getReportScheduleListRequest(state, action) {
            const clear = action.payload?.clear ?? false;
            if (clear) {
                state.schedule_list = [];
            }
            state.listLoading = true;
        },
        getReportScheduleListSuccess(state, action) {
            const list = action.payload.data;
            state.schedule_list = [...state.schedule_list, ...list];
            if ((list.length < state.search.limit)) {
                state.loadMore = false;
            }
            state.listLoading = false;
        },
        getReportScheduleListFailure(state) {
            state.listLoading = false;
        }
    }
});

export const {
    createScheduleRequest, createScheduleSuccess, createScheduleFailure,
    updateScheduleRequest, updateScheduleSuccess, updateScheduleFailure,
    deleteScheduleRequest, deleteScheduleSuccess, deleteScheduleFailure,
    getScheduleRequest, getScheduleSuccess, getScheduleFailure, clearSchedule,
    triggerJobRequest, triggerJobSuccess, triggerJobFailure,
    killJobRequest, killJobSuccess, killJobFailure, getTargetAssetRequest, getTargetAssetSuccess, getTargetAssetFailure, getScheduleListRequest, getScheduleListSuccess, getScheduleListFailure,
    getAssetJobListRequest, getAssetJobListSuccess, getAssetJobListFailure, getRunDetailRequest, getRunDetailSuccess, getRunDetailFailure, clearRunDetails, getMeasureScheduleListRequest, getMeasureScheduleListSuccess, getMeasureScheduleListFailure, getReportScheduleListRequest, getReportScheduleListSuccess, getReportScheduleListFailure, getTargetMeasureRequest, getTargetMeasureSuccess, getTargetMeasureFailure
} = scheduleSlice.actions;
export default scheduleSlice.reducer;