// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import _ from 'lodash';
import moment from 'moment';

// Import Helpers
import { nFormatter, numberWithCommas } from '../../helpers/appHelpers';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Styles
import style from "./style.jsx";

const LineChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, color, isSmooth, showXAxis, showYAxis, formatType, chartHeight, dateFormat } = props;

    const toolTipFormat = formatType === "execution" ? {
        formatter: (params) => {
            const dataObj = params[0];
            const dataValue = data[dataObj.dataIndex];
            return `
                Date : ${moment(dataValue?.x).format(dateFormat || 'MMM DD')}<br />
                Execution Time (ms) : ${numberWithCommas(dataValue?.y)}<br />
                Number Of Queries : ${numberWithCommas(dataValue?.count)}<br />
                `;
        }
    } : {
        formatter: (params) => {
            const dataObj = params[0];
            return `
                Date : ${dataObj.name}<br />
                ${dataObj.marker} ${formatType} : ${dataObj.value}
                `;
        }
    };
    const option = {
        xAxis:
        {
            type: 'category',
            data: data.map((value) => moment(value.x).format(dateFormat || 'MMM DD')),
            boundaryGap: false,
            axisLine: { onZero: false },
            show: showXAxis
        },
        yAxis: {
            type: 'value',
            show: showYAxis,
            axisLabel: {
                formatter: (value) => {
                    return nFormatter(value);
                }
            }
        },
        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },
        series: [
            {
                data: data.map((item) => {
                    return item.y;
                }),
                type: 'line',
                showSymbol: true,
                color: color !== "" ? color : "#FCE09A",
                smooth: isSmooth,
                areaStyle: {
                    opacity: 1,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: color || 'rgba(250,243,222, 1)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(255, 255, 255, 0)'
                        }
                    ])
                }
            }
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                animation: false,
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            ...toolTipFormat
        },
        grid: {
            top: 10,
            bottom: 30,
            right: showYAxis ? 40 : 20,
            left: 50
        }
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={
                {
                    height: chartHeight,
                    width: '100%'
                }
            } />
    );
};


/**
 * Define Prop Types
 */
LineChartComponent.propTypes = {
    data: PropTypes.array,
    color: PropTypes.string,
    isSmooth: PropTypes.bool,
    showXAxis: PropTypes.bool,
    showYAxis: PropTypes.bool,
    formatType: PropTypes.string,
    chartHeight: PropTypes.string,
    dateFormat: PropTypes.string
};

/**
 * Set Default Values
 */
LineChartComponent.defaultProps = {
    data: [],
    color: "",
    isSmooth: false,
    showXAxis: true,
    showYAxis: true,
    formatType: "execution",
    chartHeight: "100%",
    dateFormat: 'MMM DD'
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(LineChartComponent, areEqual));