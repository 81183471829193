import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';


function CheckboxComponent(props) {

    /**
     * Define Props
     */
    const { label, checked, disabled, onChange, ...rest } = props;

    return (
        <FormControlLabel
            size="small"
            onChange={(event) => onChange(event)}
            control={<Checkbox defaultChecked={false} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />}
            label={label}
            checked={checked}
            disabled={disabled}
            {...rest}
        />
    );
}


// default props
CheckboxComponent.defaultProps = {
    classes: {},
    name: "",
    label: "",
    checked: false,
    disabled: false,
    onChange: () => {}
};

// prop types
CheckboxComponent.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(CheckboxComponent);