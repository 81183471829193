import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

//  Import Components
import { Grid, Typography, Button } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextBoxComponent, LoaderButtonComponent } from '../index.js';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Import Constant
import appConstants from '../../constants/appConstants.js';

// Import Reducer
import { createLicenseRequest, syncLicenseRequest } from '../../redux/reducer/licenseReducer.js';
import { logoutRequest } from '../../redux/reducer/authReducer.js';

// Import Image
import welcomeLogo from '../../assets/img/dq_logo.png';


function License(props) {

    const { classes, type } = props;
    const dispatch = useDispatch();

    /**
     * Redux Store
     */

    const { isLoading, isSyncing, licenses } = useSelector(({ license }) => license);
    const { logo } = useSelector((state) => state.configurations.theme);
    const user = useSelector(({ auth }) => auth.user);

    /**
     * Define State
     */
    const [key, setKey] = useState("");

    /**
     * Activate License
     */
    const activateLicense = (event) => {
        event.preventDefault();
        const requestParams = {
            license_key: key,
            activation: (type === "activation")
        };
        dispatch(createLicenseRequest(requestParams));
        setKey("");
    };
    return (
        <Grid container className={type === "activation" ? classes.licensePageContainer : null}>
            <ValidatorForm noValidate className={type === "activation" ? classes.licenseContainer : "w-100"} onSubmit={activateLicense}>
                <Grid container spacing={4} justifyContent={type === "activation" ? "center" : ""} alignItems={"center"}>
                    {
                        type === "activation" &&
                        <Grid item xs={12}>
                            <Grid item className="mt-1">
                                <img
                                    src={logo?.length > 0 ? logo : welcomeLogo}
                                    alt="Logo"
                                    className={classes.logo}
                                />
                            </Grid>
                            <Typography variant="h4" component="h4" className="mt-1">
                                {"Activate License"}
                            </Typography>
                            {
                                user?.license?.status !== "Inactive" &&
                                <Typography variant="body1" className="mt-15">
                                    {
                                        user?.license?.status === "Deactive" ?
                                            appConstants.labels.general.deactiveLicense
                                            :
                                            appConstants.labels.general.expireLicense
                                    }
                                </Typography>
                            }
                        </Grid>
                    }
                    <Grid item xs={type === "activation" ? 8 : 6}>
                        <TextBoxComponent
                            variant="standard"
                            noOutline
                            value={key || ""}
                            placeholder="License Key"
                            validators={['required']}
                            errorMessages={[appConstants.errorMessages.licenseKey]}
                            onChange={(event) => setKey(event.target.value)}
                            valOnChange
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={type === "activation" ? 12 : 3} className={classes.btnContainer}>
                        <LoaderButtonComponent type="submit" size={'small'} isLoading={isLoading} className="mr-1">
                            {"Activate License"}
                        </LoaderButtonComponent>
                        {
                            licenses?.length > 0 &&
                            <LoaderButtonComponent onClick={() => dispatch(syncLicenseRequest())} size={'small'} isLoading={isSyncing} className="mr-1">
                                {"Sync"}
                            </LoaderButtonComponent>
                        }
                        {
                            type === "activation" &&
                            <Button onClick={() => dispatch(logoutRequest())} color="primary" variant="outlined" size="small" className="ml-1">
                                Logout
                            </Button>
                        }
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
}

// default props
License.defaultProps = {
    classes: {},
    type: "activation"
};

// prop types
License.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(License);