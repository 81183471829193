import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, Typography } from '@mui/material';
import { NumberInputComponent } from '../../../../../../../components';
import BarChartConfiguration from './components/barChart.jsx';
import PolarConfiguration from './components/polar.jsx';
import TableConfiguration from './components/table.jsx';
import LineConfiguration from './components/line.jsx';
import Hierachy from './components/hierachy.jsx';
import Spider from './components/spider.jsx';
import CountConfiguration from './components/count.jsx';
import GaugeConfiguration from './components/gauge.jsx';
import SunBurstConfiguration from './components/sunburst.jsx';
import HeatMap from './components/heatMap.jsx';

//  Import Styles
import LayoutStyles from '../../../../../../../layouts/style.jsx';

// Import Constant
import appConstants from '../../../../../../../constants/appConstants.js';


function WidgetConfiguration(props) {

    const { classes, type, data, onChange, filter_properties } = props;
    const noLimitChartTypes = [appConstants.dashboardWigetTypes.table, appConstants.dashboardWigetTypes.count, appConstants.dashboardWigetTypes.sun_burst];

    const renderConfiguration = () => {
        switch (type.toLowerCase()) {
            case appConstants.dashboardWigetTypes.polar:
                return (<PolarConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.table:
                return (<TableConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.line:
            case appConstants.dashboardWigetTypes.area:
                return (<LineConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.hierachy:
                return (<Hierachy data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.spider:
                return (<Spider data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.count:
                return (<CountConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.gauge:
                return (<GaugeConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.sun_burst:
                return (<SunBurstConfiguration data={data} onChange={(value) => onChange("properties", value)} />);
            case appConstants.dashboardWigetTypes.heatmap:
                return (<HeatMap data={data} onChange={(value) => onChange("properties", value)} />);
            default:
                return (<BarChartConfiguration data={data} widgetType={type} onChange={(value) => onChange("properties", value)} />);

        }
    };

    /**
     * on FilterChange
     * @param {*} property
     * @param {*} value
     */
    const onFilterChange = (property, value) => {
        const filters = filter_properties?.filters ?? {};
        const filterData = Object.assign({}, { ...filters });
        filterData[property] = value;
        onChange("filter_properties",
            {
                ...filter_properties,
                filters: filterData
            }
        );
    };

    return (
        <Grid>
            {renderConfiguration()}
            {
                !noLimitChartTypes.includes(type.toLowerCase()) &&
                <Grid className="mt-15">
                    <Grid item className={classes.dargField}>
                        <Typography variant="h6" className="pb5">
                            Limit
                        </Typography>
                    </Grid>
                    <NumberInputComponent
                        name={'preview_limit'}
                        value={filter_properties?.filters?.preview_limit || 10}
                        onChange={(value) => onFilterChange('preview_limit', value)}
                        fullWidth
                        variant="standard"
                        size="small"
                        id="limit"
                    />
                </Grid>
            }
        </Grid>
    );
}

// default props
WidgetConfiguration.defaultProps = {
    type: "vertical_bar",
    data: {},
    onChange: () => { },
    classes: {},
    filter_properties: {}
};

// prop types
WidgetConfiguration.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    filter_properties: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(WidgetConfiguration);