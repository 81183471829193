/* eslint no-underscore-dangle: 0 */

import { Node } from 'butterfly-dag';
import { createRoot } from 'react-dom/client';
import _ from 'lodash';
import $ from 'jquery';
import emptyDom from './empty';

export default class TableNode extends Node {
  constructor(opts) {
    super(opts);
    this.TITLE_HEIGHT = 34;
    this.ROW_HEIGHT = 26;
    this.COLUMN_WIDTH = 60;
    this.fieldsList = [];
    this.titlesList = [];
    this._renderPromise = Promise.resolve();
    this._isRendering = false;
  }

  mounted() {
    this._createNodeEndpoint(true);
    this.width = $(this.dom).width();
    this.options.width = $(this.dom).width();
    this.height = $(this.dom).height();
    this.options.height = $(this.dom).height();
  }

  draw(obj) {
    let _dom = obj.dom;
    if (!_dom) {
      _dom = $('<div></div>')
        .attr('class', 'node table-node')
        .attr('id', obj.name);
    }

    const node = $(_dom);

    const classname = _.get(this, 'options.classname');
    if (classname) {
      node.addClass(classname);
    }

    // Calculate node coordinates
    if (obj.top !== undefined) {
      node.css('top', obj.top);
    }
    if (obj.left !== undefined) {
      node.css('left', obj.left);
    }

    // Table Name
    this._createTableName(node);

    // Field
    this._createFields(node);

    return node[0];
  }

  collapse(state) {
    if (state !== this.options.isCollapse) {
      this.options.isCollapse = state;
      if (!state) {
        this._createFields();
        this._createNodeEndpoint();
      } else {
        this.fieldsList.forEach((item) => {
          $(item.dom).off();
        });
        const rmPointIds = this.endpoints.filter((item) => {
          return !item.options._isNodeSelf;
        }).map((item) => {
          return item.id;
        });
        rmPointIds.forEach((item) => {
          this.removeEndpoint(item);
        });
        $(this.dom).find('.field').remove();
        this.fieldsList = [];
      }
      this.width = $(this.dom).width();
      this.options.width = $(this.dom).width();
      this.height = $(this.dom).height();
      this.options.height = $(this.dom).height();
    }
  }

  focus() {
    $(this.dom).addClass('focus');
    this.options.minimapActive = true;
  }

  unfocus() {
    $(this.dom).removeClass('focus');
    this.options.minimapActive = false;
  }

  redrawTitle() {
    $(this.dom).find('.operator').remove();
    this._createTableName($(this.dom), true);
  }

  _addEventListener() {
    $(this.dom).on('mousedown', (event) => {
      const LEFT_KEY = 0;
      if (event.button !== LEFT_KEY) {
        return;
      }
      if (!['SELECT', 'INPUT', 'RADIO', 'CHECKBOX', 'TEXTAREA'].includes(event.target.nodeName)) {
        event.preventDefault();
      }
      if (this.draggable) {
        this._isMoving = true;
        this.emit('InnerEvents', {
          type: 'node:dragBegin',
          data: this
        });
      } else {
        // Simply to throw an error event to the canvas, and to ensure that the dragtype of the canvas is not empty, the canvas:click event will not be triggered.
        this.emit('InnerEvents', {
          type: 'node:mouseDown',
          data: this
        });
      }
    });

    $(this.dom).on('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.emit('system.node.click', {
        node: this
      });
      this.emit('events', {
        type: 'node:click',
        node: this
      });
    });

    this.setDraggable(this.draggable);
  }

  _createTableName(container = $(this.dom), isUpdate) {
    const title = _.get(this, 'options.name');
    const titleRender = _.get(this, 'options._titleRender');
    const operator = _.get(this, 'options._operator');
    const titleCom = isUpdate ? $(this.dom).find('.title-con') : $('<div class="title-con"></div>');
    const titleDom = isUpdate ? $(this.dom).find('.title') : $('<div class="title"></div>');

    if (this._isRendering) {
      return false;
    }

    // Render title
    if (titleRender) {
      this._isRendering = true;
      (this._canvas ? this._canvas._renderPromise : Promise.resolve()).then(() => {
        this._renderPromise = new Promise((resolve) => {
          createRoot(titleDom[0]).render(titleRender(title, this));
          if (this.height === 0 || this.width === 0) {
            this.width = $(this.dom).width();
            this.options.width = $(this.dom).width();
            this.height = $(this.dom).height();
            this.options.height = $(this.dom).height();
            this.endpoints.forEach((item) => item.updatePos());
            this.emit('custom.edge.redraw', {
              node: this
            });
          } else {
            const points = [];
            this.endpoints.forEach((item) => {
              if (item.options._isNodeSelf) {
                item.updatePos();
                points.push(item);
              }
            });
            this.emit('custom.edge.redraw', {
              node: this,
              points
            });
          }
          resolve();
          this._isRendering = false;
        });
      });
    } else if (title) {
      titleDom.css({
        'height': this.TITLE_HEIGHT + 'px',
        'line-height': this.TITLE_HEIGHT + 'px'
      });
    }

    if (!isUpdate) {
      titleCom.append(titleDom);
    }

    // Render action button
    let operatorDom = null;
    if (operator) {
      operatorDom = $(`<div class="operator"></div>`);
      operator.forEach((item) => {
        const operatorItemDom = $(`<div class="operator-item"></div>`);
        createRoot(operatorItemDom[0]).render(item.icon);
        if (item.onClick) {
          operatorItemDom.on('click', item.onClick.bind(this, this.options, this));
        }
        operatorDom.append(operatorItemDom);
      });
      titleCom.append(operatorDom);
    }

    if (!isUpdate) {
      const leftPoint = $('<div class="point left-point"></div>');
      const rightPoint = $('<div class="point right-point"></div>');
      titleCom.append(leftPoint).append(rightPoint);

      this.titlesList = this.titlesList.concat([
        {
          id: `${this.id}-left`,
          dom: leftPoint[0],
          type: 'target'
        }, {
          id: `${this.id}-right`,
          dom: rightPoint[0],
          type: 'source'
        }
      ]);

      $(container).append(titleCom);
    }
  }

  _createFields(container = $(this.dom)) {
    const fields = _.get(this, 'options.fields');
    const columns = _.get(this, 'options._columns');
    const isCollapse = _.get(this, 'options.isCollapse');
    let _primaryKey = columns[0].key;
    const result = [];

    if (fields && fields.length) {

      if (isCollapse) {
        return;
      }

      fields.forEach((_field, index) => {
        const fieldDom = $('<div class="field"></div>');
        fieldDom.css({
          height: this.ROW_HEIGHT + 'px',
          'line-height': this.ROW_HEIGHT + 'px'
        });
        columns.forEach((_col) => {
          if (_col.render) {
            const fieldItemDom = $(`<span class="field-item"></span>`);
            fieldItemDom.css('width', (_col.width || this.COLUMN_WIDTH) + 'px');
            createRoot(fieldItemDom[0]).render(_col.render(_field[_col.key], _field, index));
            fieldDom.append(fieldItemDom);
          } else {
            const fieldItemDom = $(`<span class="field-item">${_field[_col.key]}</span>`);
            fieldItemDom.css('width', (_col.width || this.COLUMN_WIDTH) + 'px');
            fieldDom.append(fieldItemDom);
          }
          if (_col.primaryKey) {
            _primaryKey = _col.key;
          }
        });

        const leftPoint = $('<div class="point left-point hidden"></div>');
        const rightPoint = $('<div class="point right-point hidden"></div>');
        fieldDom.append(leftPoint).append(rightPoint);

        if (this.options._enableHoverChain) {
          $(fieldDom).on('mouseover', () => {
            this.emit('custom.field.hover', {
              node: this,
              fieldId: _field[_primaryKey]
            });
          });

          $(fieldDom).on('mouseout', () => {
            this.emit('custom.field.unHover', {
              node: this,
              fieldId: _field[_primaryKey]
            });
          });
        }

        container.append(fieldDom);

        result.push({
          id: _field[_primaryKey],
          dom: fieldDom
        });
      });

      this.fieldsList = this.fieldsList.concat(result);
    } else {
      const _emptyContent = _.get(this.options, '_emptyContent');
      if (_emptyContent) {
        const noDataTree = emptyDom({
          content: _emptyContent,
          width: this.options._emptyWidth
        });
        container.append(noDataTree);
        this.height = $(container).outerHeight();
      }
    }

    return result;
  }

  _createNodeEndpoint(isInit) {
    // Add endpoint to node
    if (isInit) {
      this.titlesList.forEach((item) => {
        this.addEndpoint({
          id: item.id,
          orientation: item.type === 'target' ? [-1, 0] : [1, 0],
          dom: item.dom,
          originId: this.id,
          type: item.type,
          _isNodeSelf: true
        });
      });
    }
    // Add endpoint to the field
    this.fieldsList.forEach((item) => {
      this.addEndpoint({
        id: `${item.id}-left`,
        orientation: [-1, 0],
        dom: $(item.dom).find('.left-point')[0],
        originId: this.id,
        type: 'target'
      });
      this.addEndpoint({
        id: `${item.id}-right`,
        orientation: [1, 0],
        dom: $(item.dom).find('.right-point')[0],
        originId: this.id,
        type: 'source'
      });
      if (this.options.isCollapse) {
        $(item.dom).css({
          'visibility': 'visible',
          'display': 'none'
        });
      }
    });
  }
}