// Import API Helper
import { getRequest, postRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Execution Logs
 * @returns
 */
async function get_execution_logs(params, cancellationToken) {
  const response = await postRequest(`${urlConstants.connection.executionLogs}`, params, false, "json", cancellationToken);
  return response;
}

/**
 * Get Notification Logs
 * @returns
 */
async function get_notification_logs(is_viewed, limit, offset) {
  const response = await getRequest(`${urlConstants.logs.notificationLogs}?is_viewed=${is_viewed}&limit=${limit}&offset=${offset}`);
  return response;
}

/**
 * Update Notification Logs
 * @returns
 */
async function update_notification_logs(notificationId, params) {
  const response = await putRequest(`${urlConstants.logs.notificationLogs}${notificationId}/`, params);
  return response;
}

/**
 * Get Notifications Stats
 * @returns
 */
async function getNotificationStats(params) {
  const response = await getRequest(`${urlConstants.logs.notificationStats}`, params);
  return response;
}

/**
 * Get Notifications Stats
 * @returns
 */
async function triggerTask(params) {
  const response = await postRequest(`${urlConstants.schedule.triggerTask}`, params);
  return response;
}

/**
 * Get Task Logs
 * @returns
 */
async function get_detailed_log(task_id, cancellationToken) {
  const response = await getRequest(`${urlConstants.connection.getDetailedLog}${task_id}/`, cancellationToken);
  return response;
}


/**
 * Export All Service Functions
 */
export const logsService = {
  get_execution_logs, get_notification_logs, update_notification_logs, getNotificationStats,
  triggerTask, get_detailed_log
};