import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, Typography, Dialog, Button } from '@mui/material';


// Import Component
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MemoTextBoxComponent } from '../../../../../components/index.js';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// import constant
import appConstants from '../../../../../constants/appConstants.js';


function MeasureFilterSaveTemplate(props) {

    /**
     * Define Props
     */
    const { classes, open, onClose, onSaveTemplate } = props;

    /**
     * Define State
     */
    const [config, setConfig] = useState({ name: "", icon: null });

    /**
     * OnChange
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        setConfig((previousState) => ({ ...previousState, [property]: value }));
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}>
            <ValidatorForm onSubmit={() => onSaveTemplate(config)}>
                <Grid container spacing={5} className={classes.selectContainer}>
                    <Grid item xs={12} className="dflex spaceBetween w-100">
                        <Grid>
                            <Typography variant="h4">
                                {"Save View"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="pt-2">
                        <Grid className="mt-1">
                            <MemoTextBoxComponent
                                name="name"
                                value={config.name || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {"Name"}
                                        <span className="requiredstar">
                                            *
                                        </span>
                                    </span>
                                }
                                validators={['required']}
                                errorMessages={[`Name ${appConstants.errorMessages.requriedField}`]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="pt-2">
                        <Grid className="mt-1">
                            <MemoTextBoxComponent
                                name="description"
                                value={config.name || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                multiline
                                rows={2}
                                variant="standard"
                                label={
                                    <span className="requiredLabel">
                                        {"Description"}
                                    </span>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button disableElevation color="primary" variant="outlined" size="small" className="mr-1 cancelBtn" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button disableElevation color="primary" variant="contained" size="small" type="submit">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Dialog>
    );
}

// default props
MeasureFilterSaveTemplate.defaultProps = {
    classes: {},
    open: false,
    onClose: () => { },
    onSaveTemplate: () => { }
};

// prop types
MeasureFilterSaveTemplate.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSaveTemplate: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(MeasureFilterSaveTemplate);