import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { NestedAccordian } from '../../../../components/index.js';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { getTagsQualityRequest, collapseAccordian, getTagsRequest } from '../../../../redux/reducer/tagsReducer.js';
import { navigate } from '../../../../redux/reducer/navigationReducer';
import { getTermsRequest } from '../../../../redux/reducer/termReducer';

/**
 * Import Helpers
 */
import { orderList } from '../../../../helpers/appHelpers.js';


function TagsQuality(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const { id: tag_id } = useParams();
    const dispatch = useDispatch();

    /**
     * Reducer
     */
    const { quality, qualityIsLoading, searchableTagsList } = useSelector((state) => state.tags);
    const { searchableTermsList } = useSelector((state) => state.term);

    /**
     * Define State
     */
    const [searchData, setSearchData] = useState({});
    const [sorting, setSorting] = useState({
        sortBy: "name", orderBy: "asc"
    });


    /**
     * Handle Cele Click Event
     * @param {*} key
     */
    const handleCellClickEvent = (key, data) => {
        if (data.type === "attribute") {
            dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [data.asset_id, data.id] }));
        } else {
            dispatch(navigate({ path: 'assets.attributeProperties', state: { section: "measure", measureView: "All" }, params: [data.asset_id, data.attribute_id] }));
        }
    };

    /**
     * Get Tags Data
     * @param {*} params
     * @param {*} clear
     */
    const getTagsQuality = (params, clear = false) => {
        const requestParams = {
            ...params,
            tag_id,
            clear
        };
        dispatch(getTagsQualityRequest(requestParams));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
        const params = {
            search_by,
            level: "attribute"
        };
        getTagsQuality(params, true);
    };

    /**
     * Handle Accordion Collapse Event
     * @param {*} value
     * @param {*} data
     * @param {*} type
     */
    const onCollapseEvent = (value, data, type) => {
        const requestParams = {
            type,
            value,
            attribute_id: data.id
        };
        dispatch(collapseAccordian(requestParams));
        if (!data?.children?.length) {
            const params = {
                accordian: requestParams,
                search_by: searchData,
                level: "measure"
            };
            params.attribute_id = data.id;
            getTagsQuality(params);
        }
    };

    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy,
            orderBy
        });
        const params = {
            level: "attribute",
            sortBy: sorting.sortBy,
            orderBy: sorting.orderBy,
            search_by: searchData
        };
        getTagsQuality(params, true);
    };

    /**
     * Load Tags Quality Data
     */
    useEffect(() => {
        if (tag_id) {
            getTagsQuality({ level: "attribute" }, true);
        }
    }, [tag_id]);

    /**
     * Get Default Data
     */
    useEffect(() => {
        // Get Tags List
        if (!searchableTagsList || searchableTagsList.length === 0) {
            dispatch(getTagsRequest());
        }
        // Get Terms List
        if (!searchableTermsList || searchableTermsList.length === 0) {
            dispatch(getTermsRequest({ 'status': 'Verified' }));
        }
    }, [dispatch]);

    /**
     * Filter terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const termsList = useMemo(() => filterTerms(searchableTermsList), [searchableTermsList]);

    /**
     * Filter tags
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        let termsList = JSON.parse(JSON.stringify(data));
        termsList = orderList(termsList, 'name', 'asc');
        return termsList;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsList), [searchableTagsList]);

    /**
     * Accordian Data
     */
    const accordianMenus = {
        "attribute": {
            showHeader: true,
            headers: [
                { key: 'name', name: 'Name', component: "attribute", sorting: true, tooltip: true, hidePrimaryKey: true, width: '20%', isSearch: true, searchKey: 'name', searchComponent: "text", clickable: true },
                { key: 'alerts', name: 'Alerts', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'issues', name: 'Issues', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'tags', name: 'Tags', width: '20%', isNotEditable: true, showNA: true, component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'terms', name: 'Terms', width: '20%', isNotEditable: true, showNA: true, isSearch: true, searchComponent: "autocomplete", list: termsList || [], searchKey: "terms", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'score', name: 'Score', width: '8%', isSearch: false, component: "score" },
                { key: 'accordian', component: 'accordian', name: 'Actions', width: '8%', align: 'center' }
            ],
            accordian: true,
            childLevel: "measure"
        },
        "measure": {
            showHeader: false,
            headers: [
                { key: 'name', name: 'Name', component: "text", width: '20%', sorting: true, tooltip: true, isSearch: true, searchKey: 'name', searchComponent: "text", clickable: true },
                { key: 'alerts', name: 'Alerts', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'issues', name: 'Issues', width: '8%', sorting: true, tooltip: true, isSearch: false },
                { key: 'tags', name: 'Tags', width: '20%', isNotEditable: true, showNA: true, component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'terms', name: 'Terms', width: '20%', isNotEditable: true, showNA: true, searchComponent: "autocomplete", list: termsList || [], searchKey: "terms", component: 'chips', valueKey: 'name', limit: 1, chipAddType: 'autocomplete', haveColor: true },
                { key: 'score', name: 'Score', width: '8%', isSearch: false, component: "score" },
                { key: 'accordian', name: 'Actions', width: '8%', align: 'center' }
            ],
            childLevel: "measure"
        }
    };

    return (
        <Grid container className={classes.tabsRootContainer}>
            <ValidatorForm onSubmit={() => null} style={{ width: '100%' }}>
                <NestedAccordian
                    options={[{ type: "search", customFunction: null }, { type: "columns", customFunction: null }]}
                    accordian={accordianMenus}
                    data={quality}
                    headerProperty={accordianMenus.attribute}
                    onAccordianOnChange={onCollapseEvent}
                    defaultShowSearch
                    searchData={searchData}
                    sortBy={sorting.sortBy}
                    orderBy={sorting.orderBy}
                    onHandleSearchEvent={onHandleSearchEvent}
                    onCellClick={handleCellClickEvent}
                    onClickSorting={onClickSorting}
                    isLoading={qualityIsLoading}
                    selectComponentList={
                        {
                            tags: tagsList,
                            term: termsList
                        }
                    }

                />
            </ValidatorForm>
        </Grid>
    );
}

// default props
TagsQuality.defaultProps = {
    classes: {}
};

// prop types
TagsQuality.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TagsQuality);