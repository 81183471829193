const style = (theme) => ({
    overviewPageContainer: {
        boxShadow: '0px 2px 16px #0000000A',
        paddingTop: 5
        // padding: 24
    },
    tabsSection: {
    },
    tabsBodySection: {
        padding: '24px 30px',
        background: theme.palette.primary.contrastText,
        minHeight: 'calc(100vh - 344px)'
    },
    tabsBody: {},
    tabsHeaderSection: {
        position: 'sticky',
        top: '-1px',
        zIndex: 1
    }
});
export default style;