import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

// Import styles
import style from './style.jsx';

// Import Images
import AthenaIcon from '../../assets/img/connectors/athena.png';
import FileIcon from '../../assets/img/connectors/file.png';
import OracleIcon from '../../assets/img/connectors/oracle.png';
import SalesforceIcon from '../../assets/img/connectors/salesforce.png';
import QuickBaseIcon from '../../assets/img/connectors/quickbase.png';
import RestApiIcon from '../../assets/img/connectors/restapi.png';
import S3Icon from '../../assets/img/connectors/s3.png';
import SFTPIcon from '../../assets/img/connectors/sftp.png';
import PostgreSQLIcon from '../../assets/img/connectors/posgress.png';
import MongoDBIcon from '../../assets/img/connectors/mongodb.png';
import SnowflakeIcon from '../../assets/img/connectors/snowflake.png';
import MySQLIcon from '../../assets/img/connectors/mysql.png';
import FTPIcon from '../../assets/img/connectors/ftp.png';
import MSSQLIcon from '../../assets/img/connectors/mssql.png';
import HiveIcon from '../../assets/img/connectors/hive.png';
import DBFSIcon from '../../assets/img/connectors/dbfs.png';
import DeltaIcon from '../../assets/img/connectors/deltalake.png';
import TableauIcon from '../../assets/img/connectors/tableau.png';
import RedshiftIcon from '../../assets/img/connectors/redshift.png';
import DenodoIcon from '../../assets/img/connectors/denodo.png';
import DatabricksIcon from '../../assets/img/connectors/databricks.png';
import DbtIcon from '../../assets/img/connectors/dbt.png';
import RedshiftSpectrumIcon from '../../assets/img/connectors/redshift_spectrum.png';
import BigqueryIcon from '../../assets/img/connectors/bigquery.png';
import DB2Icon from '../../assets/img/connectors/db2.jpg';
import FivertranIcon from '../../assets/img/connectors/fivetran.png';
import SapHanaIcon from '../../assets/img/connectors/saphana.png';
import TeradataIcon from '../../assets/img/connectors/teradata.svg';
import ADFIcon from '../../assets/img/connectors/adf.png';
import SynapseIcon from '../../assets/img/connectors/synapse.png';
import AirflowIcon from '../../assets/img/connectors/airflow.png';
import TalendIcon from '../../assets/img/connectors/talend.png';
import EMRIcon from '../../assets/img/connectors/emr.png';
import PowerBiIcon from '../../assets/img/connectors/powerbi.png';

function ConnectorsIcon(props) {

	/**
	 * Define Props
	 */
	const { connection_type, size } = props;

	let clsName = "connectorCardImg";
	if (size === 'small') {
		clsName = "connectorCardImg";
	}

	/**
	 * Connectors Data
	 */
	const connectersIcons = [
		{
			icon: <img alt="connector" src={AthenaIcon} className={clsName} />,
			connector_type: 'athena'
		},
		{
			icon: <img alt="connector" src={FileIcon} className={clsName} />,
			connector_type: 'file'
		},
		{
			icon: <img alt="connector" src={OracleIcon} className={clsName} />,
			connector_type: 'oracle'
		},
		{
			icon: <img alt="connector" src={SalesforceIcon} className={clsName} />,
			connector_type: 'salesforce'
		},
		{
			icon: <img alt="connector" src={QuickBaseIcon} className={clsName} />,
			connector_type: 'quickbase'
		},
		{
			icon: <img alt="connector" src={RestApiIcon} className={`${clsName} restapi`} />,
			connector_type: 'restapi'
		},
		{
			icon: <img alt="connector" src={S3Icon} className={clsName} />,
			connector_type: 's3'
		},
		{
			icon: <img alt="connector" src={SFTPIcon} className={clsName} />,
			connector_type: 'sftp'
		},
		{
			icon: <img alt="connector" src={PostgreSQLIcon} className={clsName} />,
			connector_type: 'postgresql'
		},
		{
			icon: <img alt="connector" src={MongoDBIcon} className={clsName} />,
			connector_type: 'mongodb'
		},
		{
			icon: <img alt="connector" src={SnowflakeIcon} className={clsName} />,
			connector_type: 'snowflake'
		},
		{
			icon: <img alt="connector" src={MySQLIcon} className={clsName} />,
			connector_type: 'mysql'
		},
		{
			icon: <img alt="connector" src={FTPIcon} className={clsName} />,
			connector_type: 'ftp'
		},
		{
			icon: <img alt="connector" src={MSSQLIcon} className={clsName} />,
			connector_type: 'mssql'
		},
		{
			icon: <img alt="connector" src={HiveIcon} className={clsName} />,
			connector_type: 'hive'
		},
		{
			icon: <img alt="connector" src={DBFSIcon} className={clsName} />,
			connector_type: 'dbfs'
		},
		{
			icon: <img alt="connector" src={DeltaIcon} className={clsName} />,
			connector_type: 'deltalake'
		},
		{
			icon: <img alt="connector" src={TableauIcon} className={clsName} />,
			connector_type: 'tableau'
		},
		{
			icon: <img alt="connector" src={RedshiftIcon} className={clsName} />,
			connector_type: 'redshift'
		},
		{
			icon: <img alt="connector" src={RedshiftSpectrumIcon} className={clsName} />,
			connector_type: 'redshift_spectrum'
		},
		{
			icon: <img alt="connector" src={DenodoIcon} className={clsName} />,
			connector_type: 'denodo'
		},
		{
			icon: <img alt="connector" src={DbtIcon} className={clsName} />,
			connector_type: 'dbt'
		},
		{
			icon: <img alt="connector" src={DatabricksIcon} className={clsName} />,
			connector_type: 'databricks'
		},
		{
			icon: <img alt="connector" src={BigqueryIcon} className={clsName} />,
			connector_type: 'bigquery'
		},
		{
			icon: <img alt="connector" src={DB2Icon} className={clsName} />,
			connector_type: 'db2'
		},
		{
			icon: <img alt="connector" src={FivertranIcon} className={clsName} />,
			connector_type: 'fivetran'
		},
		{
			icon: <img alt="connector" src={SapHanaIcon} className={clsName} />,
			connector_type: 'saphana'
		},
		{
			icon: <img alt="connector" src={TeradataIcon} className={clsName} />,
			connector_type: 'teradata'
		},
		{
			icon: <img alt="connector" src={SynapseIcon} className={clsName} />,
			connector_type: 'synapse'
		},
		{
			icon: <img alt="airflow" src={AirflowIcon} className={clsName} />,
			connector_type: 'airflow'
		},
		{
			icon: <img alt="talend" src={TalendIcon} className={clsName} />,
			connector_type: 'talend'
		},
		{
			icon: <img alt="connector" src={EMRIcon} className={clsName} />,
			connector_type: 'emr_spark'
		},
		{
			icon: <img alt="powerbi" src={PowerBiIcon} className={clsName} />,
			connector_type: 'powerbi'
		},
		{
			icon: <img alt="connector" src={ADFIcon} className={clsName} />,
			connector_type: 'adf'
		}
	];

	/**
	 *
	 * @returns
	 */
	const bindIcon = () => {
		const data = connectersIcons.find((data) => data.connector_type === connection_type.toLowerCase());

		if (data) { return data.icon; }

		return null;
	};


	return (
		bindIcon()
	);
}

/**
 * Define Component Props
 */
ConnectorsIcon.propTypes = {
	classes: PropTypes.object,
	size: PropTypes.string,
	connection_type: PropTypes.string
};

/**
 * Define Default Values
 */
ConnectorsIcon.defaultProps = {
	classes: {},
	size: "large",
	connection_type: ''
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.connection_type, nextProps.connection_type) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(style)(React.memo(ConnectorsIcon, areEqual));