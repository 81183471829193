import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { CircularScoreComponent } from '../../../../components/index.js';

//  Import Styles
import DomainCardStyle from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';
import { MoreIcon } from '../../../../assets/svg/index.js';

// Import Images
import Placeholder from '../../../../assets/img/placeholder.png';

function DomainCard(props) {
    /**
     * Define Props
     */
    const { classes, data, onClick, type } = props;

    return (
        <Grid className={classes.domainCard}>
            <Link underline="none" variant="subtitle1" component={RouterLink} to={type === "terms" ? `/settings/semantics/domains/term/${data.id}` : `/semantics/${type}/${data.glossary_id || data.id}`}>
                {
                    type === 'domains' &&
                    <Grid className={classes.header}>
                        <IconButton onClick={onClick} className={classes.more}>
                            <MoreIcon />
                        </IconButton>
                        <img
                            className={classes.domainCardImg}
                            src={data.image || Placeholder}
                            alt={'images'}
                        />
                    </Grid>
                }
                <Grid className={classes.body}>
                    <Grid container justifyContent={'space-between'} wrap="nowrap">
                        <Grid>
                            <Typography variant="h4" className="pb5" align="left">
                                {data?.name ?? ''}
                            </Typography>
                            {
                                type === 'domains' &&
                                <Typography variant="body1" className={classes.assetsText}>
                                    {data?.assets ?? 0}
                                    {' '}
                                    Assets
                                    <span className="assetDot" />
                                    {data?.alerts ?? 0}
                                    {' '}
                                    Alerts
                                    <span className="assetDot" />
                                    {data?.issues ?? 0}
                                    {' '}
                                    Issues
                                </Typography>
                            }
                            {
                                type === 'applications' &&
                                <Typography variant="body1" className={classes.assetsText}>
                                    {data?.count ?? 0}
                                    {' '}
                                    Assets
                                    <span className="assetDot" />
                                    {data?.alerts ?? 0}
                                    {' '}
                                    Alerts
                                    <span className="assetDot" />
                                    {data?.issues ?? 0}
                                    {' '}
                                    Issues
                                </Typography>
                            }
                            {
                                type === 'tags' &&
                                <Typography variant="body1" className={classes.assetsText}>
                                    {data?.count ?? 0}
                                    {' '}
                                    Attributes
                                    <span className="assetDot" />
                                    {data?.alerts ?? 0}
                                    {' '}
                                    Alerts
                                    <span className="assetDot" />
                                    {data?.issues ?? 0}
                                    {' '}
                                    Issues
                                </Typography>
                            }
                            {
                                type === "terms" &&
                                <Typography variant="body1" className={classes.assetsText}>
                                    {data?.alerts ?? 0}
                                    {' '}
                                    Alerts
                                    <span className="assetDot" />
                                    {data?.issues ?? 0}
                                    {' '}
                                    Issues
                                </Typography>
                            }
                        </Grid>
                        <Grid>
                            <CircularScoreComponent
                                size={50}
                                value={data.dqscore || data.score || 0}
                                showPercentage
                                noLink
                                showValue />
                        </Grid>
                    </Grid>
                </Grid>
            </Link>
        </Grid>
    );
}

// default props
DomainCard.defaultProps = {
    classes: {},
    data: {},
    onClick: () => { },
    type: ''
};

// prop types
DomainCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    onClick: PropTypes.func,
    type: ''
};

export default withStyles(
    (theme) => ({
        ...DomainCardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DomainCard);