const style = (theme) => ({
    description: {
        marginBottom: "10px !important",
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #ffffff00 !important'
        }
    },
    topSection: {
        '& .chipContainer': {
            width: '100%'
        }
    },
    statisticalContainer: {
        "& .measure": {
            '& td': {
                padding: "0 0 10px 0 !important",
                verticalAlign: "baseline !important",
                border: "none !important",
                '&.left': {
                    padding: "0 0 15px 0 !important"
                }
            }
        }
    },
    measureValue: {
        "& .value": {
            background: "#B0BAC970",
            fontSize: 14,
            color: "#000",
            padding: "2px 20px 2px 6px",
            marginRight: 16,
            minWidth: 40,
            "&.is_active": {
                background: "#c8eeff"
            }
        }
    },
    measureDescription: {
        width: "90%"
    },
    measureName: {
        fontSize: 14,
        "& .title": {
            fontWeight: 500,
            "& .description": {
                fontSize: 14,
                fontWeight: 400,
                textAlign: "justify !important"
            }
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '22px !important',
        '& .MuiOutlinedInput-root': {
            maxWidth: 130,
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '6.5px 8px',
            textAlign: 'center',
            fontSize: 15
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        },
        '& .rangeLabel': {
            textTransform: "capitalize",
            marginRight: 12,
            color: "rgba(0, 0, 0, 0.38)",
            fontWeight: 400,
            fontFamily: theme.palette.headers.body1.fontFamily,
            fontSize: theme.palette.headers.body1.size,
            fontStyle: theme.palette.headers.body1.style,
            textDecoration: theme.palette.headers.body1.textDecoration
        }
    },
    titleContainer: {
        backgroundColor: "#fff !important",
        display: "flex",
        "& label.MuiFormControlLabel-root": {
            marginLeft: "5px !important",
            marginRight: "0px !important",
            marginBottom: "2px !important"

        }
    }
});
export default style;