import React from "react";
import PropTypes from 'prop-types';
import { TableRow, TableCell } from "@mui/material";

// Import Con
import { LoaderComponent } from '../index';


const LazyLoadingBodyRowComponent = (props) => {

    /**
     * Define Props
     */
    const { headers, haveSubTable, height } = props;

    return (
        <TableRow>
            <TableCell colSpan={haveSubTable ? headers.length + 1 : headers.length + 1} sx={{ padding: '0 !important', '& .loaderContainer': { marginTop: '0 !important' } }}>
                <LoaderComponent loaderType={"circular"} height={height} />
            </TableCell>
        </TableRow>
    );
};

/**
 * Set Component Props
 */
LazyLoadingBodyRowComponent.propTypes = {
    headers: PropTypes.array,
    haveSubTable: PropTypes.bool,
    height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

/**
 * Set Default Values
 */
LazyLoadingBodyRowComponent.defaultProps = {
    headers: [],
    haveSubTable: false,
    height: 80
};

export default LazyLoadingBodyRowComponent;