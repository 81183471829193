// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import _ from 'lodash';

//Import Styles
import style from './style.jsx';

// Import Images
import { KeyIcon } from '../../../../assets/svg/index.js';

const AttributeComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, data, hidePrimaryKey, clickable, onHandleCellClickEvent } = props;

    return (
        <Grid container justifyContent={"space-between"} className={classes.attributeBlock} wrap="nowrap">
            <Grid item className="dflex alignCenter mr-1">
                {
                    data?.derived_type &&
                    <Tooltip title={data?.derived_type ?? data?.datatype ?? ''} arrow>
                        <Grid item className={classes.derivedType}>
                            {(data.derived_type && data.derived_type.charAt(0)) || (data.datatype && data.datatype.charAt(0)) || ''}
                        </Grid>
                    </Tooltip>
                }
                <Grid item className={`ml-1 mr-3 ${clickable ? "pointer" : ""}`} onClick={(event) => clickable && onHandleCellClickEvent(event)}>
                    {data.column_name || data.name || ''}
                </Grid>
                {
                    !hidePrimaryKey &&
                    <Tooltip arrow title="Primary key">
                        <Grid className="dflex">
                            <KeyIcon inactive={!data.is_primary_key} />
                        </Grid>
                    </Tooltip>
                }
            </Grid>
        </Grid>
    );
};

/**
 * Define Component Props
 */
AttributeComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    hidePrimaryKey: PropTypes.bool,
    clickable: PropTypes.bool,
    onHandleCellClickEvent: PropTypes.func
};

/**
 * Set Default Values
 */
AttributeComponent.defaultProps = {
    classes: {},
    data: {},
    hidePrimaryKey: false,
    clickable: false,
    onHandleCellClickEvent: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(AttributeComponent, areEqual));