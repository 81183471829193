const style = (theme) => ({
    alertsCard: {
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        height: '100%'
        // height: 82
    },
    nodataCardWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end !important'
    },
    nodataCard: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column !important',
        '& svg': {
            fill: theme.palette.greyshades.darkgrey
        }
    }
});

export default style;