import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

// Import styles
import Style from './style.jsx';

function DriftRunStatus(props) {

    /**
     * Define Props
     */
    const { classes, data } = props;

    /**
     * Render Class
     * @param {*} item
     * @returns
     */
    const renderClass = (item) => {
        switch (item?.status?.toLowerCase()) {
            case 'high':
                return classes.high;
            case 'medium':
                return classes.medium;
            case 'low':
                return classes.low;
            case 'ok':
                return classes.ok;
            default:
                return null;
        }
    };

    const lastRun = typeof (data) === "string" ? JSON.parse(data) : data ?? [];
    return (
        <Grid container wrap="nowrap">
            {
                lastRun && lastRun.map((item, index) =>
                    <Tooltip
                        key={`status_${index}`}
                        arrow
                        title={item?.status ?? ''}
                    >
                        <Grid className={classNames(classes.statusContainer, renderClass(item))} />
                    </Tooltip>
                )
            }
            {
                lastRun?.length === 0 ?
                <Typography>
                    {"NA"}
                </Typography>
                : null
            }
        </Grid>
    );
}

DriftRunStatus.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

DriftRunStatus.defaultProps = {
    classes: {},
    data: []

};


export default withStyles(Style, { withTheme: true })(DriftRunStatus);