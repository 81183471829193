const style = (theme) => ({
    rootComponent: {
        position: 'relative',
        width: '100%',
        '& .actionContainer': {
        }
    },
    ruleContainer: {
        marginTop: 8,
        marginLeft: 12,
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            minWidth: 200,
            padding: '0px 9px !important',
            height: '33.59px',
            borderRadius: '2px'
        },
        '& .MuiInputBase-input': {
            padding: '7.5px 0px !important',
            fontSize: 14
        },
        '& .MuiAutocomplete-clearIndicator svg': {
            width: '18px',
            height: '18px',
            fill: theme.palette.greyshades.darkgrey,
            marginTop: '2px'
        },
        '& .MuiAutocomplete-popupIndicator': {
            marginTop: 2
        },
        '& .ChevDownIcon': {
            width: '13px',
            height: '13px'
        },
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& .MuiAutocomplete-input': {
            paddingRight: '40px !important'
        }
    },
    ruleContainerForm: {
        display: 'flex',
        alignItems: 'center',
        justifyComponent: 'space-between'
    },
    ruleField: {
        marginRight: '15px !important'
    },
    ruleGroup: {
        marginLeft: 12,
        background: theme.palette.greyshades.lightgrey,
        padding: 15,
        border: `0.5px solid #E1E5E6`,
        marginTop: '7px',
        borderRadius: 4,
        overflow: 'auto',
        '& svg.DeleteIcon:hover ': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        }
    },
    rule: {
        // border: `1px solid ${theme.palette.greyshades.darkgrey}`,
        padding: '8px 4px 8px 8px',
        borderRadius: 0,
        marginTop: 0,
        marginLeft: 40,
        position: 'relative',
        "&:first-child": {
            "&::before": {
                top: -2,
                height: 'calc(50% + 7px)'
            }
        },
        "&:last-child": {
            "&::before": {
                borderRadius: 0
            },
            "&::after": {
                borderWidth: 0
            }
        },
        "&::before, &::after": {
            content: "''",
            position: 'absolute',
            left: -15,
            width: 42,
            height: 'calc(50% + 5px)',
            top: -2,
            borderWidth: '0 0 1px 1px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.greyshades.darkgrey}70`
        },
        "&::after": {
            top: '50%',
            borderWidth: '0 0 0 1px'
        }
    },
    group: {
        // border: `1px solid ${theme.palette.greyshades.darkgrey}`,
        padding: '8px 4px 8px 8px',
        marginTop: 0,
        marginLeft: 40,
        position: 'relative',
        "&:first-child": {
            padding: 0,
            borderWidth: 0,
            margin: 0,
            width: 'calc(100% - 5px)',
            "&::before": {
                top: -13,
                height: 'calc(50% + 14px)'
            }
        },
        "&:last-child": {
            "&::before": {
                borderRadius: 0,
                borderWidth: '0 0 1px 1px'
            },
            "&::after": {
                borderWidth: 0
            }
        },
        "&::before, &::after": {
            content: "''",
            position: 'absolute',
            left: -15,
            width: 35,
            height: 'calc(50% + 5px)',
            top: -2,
            borderWidth: '0 0 1px 1px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.greyshades.darkgrey}70`
        },
        "&::after": {
            top: '50%',
            borderWidth: '0 0 0 1px'
        }
    }
});

export default style;