// Default Imports
import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Styles
import style from "./style.jsx";

const HeatMapChartComponent = (props) => {
    /**
     * Define Props
     */
    const { data, fixedHeight, handleOnClick, type } = props;

    /**
     * Redux selector function to get Quadrant Settings from redux store
     */
    const { scoring } = useSelector((state) => state.configurations.general);
    const { values: quad_values, quadrant } = scoring;
    const selectedQuad = quad_values[quadrant - 2];

    const rangeColor = selectedQuad ? selectedQuad.map((quad) => quad.color) : [];
    const rangeColorBg = selectedQuad ? selectedQuad.map((quad) => { return `${quad.color}19`; }) : [];

    const total_data = [];
    const s_data = _.reverse([...data]);
    let chart_data = [];
    for (const index in s_data) {
        const item = s_data[index];
        const name = `${item.name}<>${index}`;
        total_data.push({
            name: name,
            dimension: "Total Score",
            score: item.t_score
        });
        const metrics = item.metrics;
        const result = metrics.map((obj) => {
            return {
                name,
                ...obj
            };
        });
        chart_data = [...chart_data, ...result];
    }
    chart_data = [...total_data, ...chart_data];

    // Extract X and Y axis
    const dimensions = _.uniq(_.map(chart_data, 'dimension'));
    const yName = type === "attribute" ? _.uniq(_.map(chart_data, 'name')) : total_data.map((obj) => obj.name);

    // Define Chart Grid and Dimension
    const gridSpace = { left: 50, top: 80, right: 50, bottom: 60 };
    const chartHeight = yName.length * 50 + gridSpace.top + gridSpace.bottom;


    /**
     * Define Chart Options
     */
    const option = {

        // ToolTip Style and content
        tooltip: {
            position: 'top',
            formatter: (params) => {
                const name = params.value[1].split("<>")[0];
                return (
                    `<div class="chartToolTip">
                        <div class="dflex alignCenter spaceBetween mb-1">
                            ${name}
                        </div>
                        <div class="dflex alignCenter spaceBetween">
                            <div class="dflex alignCenter">${params.value[0]} : </div>
                            <div>${params.value[2]} %</div>
                        </div>                    
                    </div>`
                );
            }
        },

        // Set position of the canvas
        grid: {
            top: gridSpace.top,
            bottom: gridSpace.bottom,
            height: yName.length * 45,
            left: 250
        },

        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },

        // X Axis Label Style and content
        xAxis: {
            type: 'category',
            data: dimensions.map((item) => item),
            position: 'top',
            axisLabel: {
                interval: 0,
                rotate: -35
            },
            axisTick: {
                show: false
            }
        },

        // Y Axis Label Style and content
        yAxis: {
            type: 'category',
            data: yName,
            axisTick: {
                show: false
            },
            axisLabel: {
                formatter: (value) => {
                    value = value.split("<>")[0];
                    return value;
                }
            }
        },

        // Visual Map of colors over chart
        visualMap: {
            min: 0,
            max: 100,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: 0,
            itemHeight: 10,
            type: 'piecewise',
            pieces: selectedQuad?.map((quad) => { return { min: quad.from, max: quad.to }; }) ?? [],
            inRange: {
                color: rangeColorBg
            }
        },
        // Set Type and Data of the chart
        series: [
            {
                name: 'Dimension',
                type: 'heatmap',
                data: chart_data.map((item) => {
                    const colorIndex = Math.floor(item.score / (100.1 / quadrant));
                    return {
                        value: [item.dimension, item.name, item.score],
                        label: {
                            show: true,
                            color: rangeColor[colorIndex],
                            formatter: ((content) => {
                                return content.value[2] + '%';
                            })
                        }
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    borderWidth: 2,
                    borderColor: "#FFF"
                }
            }
        ]
    };

    /**
     * Handle Tooltip Click Event
     * @param {*} params
     */
    const onChartClick = (params) => {
        if (params?.data?.value.length) {
            const dimension = params?.data?.value[0];
            let value = params?.data?.value[1];
            if (value) {
                value = value.split("<>")[1];
                value = s_data[value];
                if (type === "asset") {
                    handleOnClick(value.id);
                } else {
                    handleOnClick(dimension, value.attribute_id);
                }
            }
        }
    };

    /**
     * Handle Chart Event
     */
    const onEvents = {
        click: onChartClick
    };

    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            onEvents={onEvents}
            style={
                {
                    height: fixedHeight ? chartHeight : '100%',
                    width: '100%'
                }
            } />
    );
};


/**
 * Define Prop Types
 */
HeatMapChartComponent.propTypes = {
    data: PropTypes.array,
    fixedHeight: PropTypes.bool,
    handleOnClick: PropTypes.func,
    type: PropTypes.string
};

/**
 * Set Default Values
 */
HeatMapChartComponent.defaultProps = {
    data: [],
    fixedHeight: false,
    handleOnClick: () => { },
    type: "attribute"
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(HeatMapChartComponent, areEqual));