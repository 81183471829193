const style = () => ({
    informationContainer: {
        height: 'calc(100vh - 30px)',
        padding: '30px 30px 0px',
        zIndex: 11,
        background: "#fff",
        overflowY: "auto",
        alignContent: "baseline"
    }
});
export default style;