const style = (theme) => ({
    newWidgetContainer: {
        padding: '22px 24px',
        background: theme.palette.greyshades.lightestgrey
    },
    titleContainer: {
        maxWidth: '70%',
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.h3.size}px !important`,
            fontFamily: theme.palette.headers.h3.fontFamily,
            fontWeight: theme.palette.headers.h3.weight
        }
    },
    newWidgetHeader: {
        flex: 1,
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        '& .MuiTypography-h6': {
            textTransform: 'capitalize'
        }
    },
    bodyContainer: {
        flexWrap: 'nowrap !important',
        background: '#fff',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        // marginTop: 24,
        height: 'calc(100vh - 121px)',
        marginTop: '10px'
    },
    assetConatiner: {
        padding: 0,
        borderRight: `1px solid ${theme.palette.greyshades.grey}`,
        flex: 1,
        maxWidth: 320,
        height: 'calc(100% - 0px)',
        '& .tabBodyContainer': {
            height: 'calc(100% - 48px)',
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        '& .search': {
            paddingTop: 20,
            '& .searchBox': {
                padding: '1px 10px !important'
            }
        },
        '& .assetList': {
            paddingTop: 0,
            paddingBottom: 20,
            '& .MuiListItemButton-root': {
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: '5px'
            },
            '& .assetTypeIcon': {
                background: theme.palette.greyshades.lightgrey,
                width: 20,
                height: 20,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 10
            },
            '& .MuiList-root': {
                paddingTop: '0px',
                paddingBottom: '0px'
            },
            '& .dragItem': {
                cursor: 'move',
                '& .MuiTypography-root:hover': {
                    color: theme.palette.secondary.main
                }
            }
        }
    },
    filterSection: {
        padding: 12,
        flex: 1,
        maxWidth: 70,
        borderRight: `1px solid ${theme.palette.greyshades.grey}`
    },
    chartContainer: {
        flex: 1,
        borderRight: `1px solid ${theme.palette.greyshades.grey}`,
        borderLeft: `1px solid ${theme.palette.greyshades.grey}`,
        maxWidth: 'calc(100% - 600px)'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    querySection: {
        height: '50%',
        padding: 20,
        borderBottom: `1px solid ${theme.palette.greyshades.grey}`,
        '& textarea.MuiOutlinedInput-input': {
            height: '100% !important'
        },
        '& .textBoxContainer': {
            height: 'calc(100% - 40px)',
            background: theme.palette.greyshades.lightestgrey,
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderBottom: 'none !important'
            },
            '&>div': {
                height: '100%'
            },
            '& .MuiOutlinedInput-root, .MuiFormControl-root': {
                height: '100%'
            }
        },
        '& .PlayIcon2, .SaveIcon, .PlusIcon': {
            '&:hover ': {
                fill: theme.palette.secondary.main
            }
        }
    },
    chartSection: {
        height: '50%',
        padding: 20
    },
    selectChart: {
        flex: 1,
        maxWidth: 55,
        flexDirection: 'column !important',
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            marginBottom: '12px',
            marginTop: '12px',
            width: '40px',
            height: '40px',
            '&.selected': {
                '& .MuiSvgIcon-root': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .MuiSvgIcon-root': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    tabHeaderContainer: {
        '& .btnTabsInline': {
            width: '100%'
        }
    },
    sectionHeader: {
        height: 42,
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px'
    }
});

export default style;