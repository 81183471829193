import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';
import _ from 'lodash';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import { AutoCompleteComponent, DialogComponent } from '../../../../components/index.js';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';

// Import Actions
import { connectionSchemaRemoveRequest } from '../../../../redux/reducer/connectionReducer.js';

function SchemaSelector(props) {

    /**
     * Define Props
     */
    const { detailById, dbs_schema, schema, handleSchemaSelection, schemaLoading, userClass, divSpan, customLabel, data_type, validators, errorMessages } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [showConfirmationDialog, SetShowConfirmationDialog] = useState({
        show: false,
        data: []
    });

    /**
     * Handle Database Value Selection
     * @param {*} newValue
     */
    const handleSchemaChangeEvent = (newValue) => {
        if (newValue?.length < schema.length && detailById?.total_assets > 0) {
            SetShowConfirmationDialog({ show: true, data: newValue });
        }
        else {
            handleSchemaSelection(newValue);
        }
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        SetShowConfirmationDialog({ show: false, data: [] });
    };

    /**
     * Delete Schema After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge", data) => {
        dispatch(connectionSchemaRemoveRequest({ "id": detailById.id, type, data_type, "schema": _.difference(schema, data) }));
        handleSchemaSelection(data, type);
        dialogCancelEventHandle();
    };

    return (
        <React.Fragment>
            <Grid item xs={divSpan} className={userClass}>
                <AutoCompleteComponent
                    id="form_text_schema"
                    name={'schema'}
                    fullWidth
                    variant="standard"
                    placeholder={customLabel ? `Select ${customLabel}` : "Select Schema"}
                    multiple
                    data={dbs_schema || []}
                    selectedValue={schema || []}
                    label={customLabel ? customLabel : appConstants.labels.connector.schema}
                    onChange={(event, newValue) => handleSchemaChangeEvent(newValue)}
                    disabled={schemaLoading}
                    limitTags={5}
                    disableCloseOnSelect
                    disableClearable
                    validators={validators}
                    errorMessages={errorMessages}
                />
            </Grid>
            {
                schemaLoading &&
                <Grid className="mt-5 ml-2">
                    <CircularProgress size={30} />
                </Grid>
            }
            {
                showConfirmationDialog && showConfirmationDialog.show &&
                <DialogComponent
                    open={showConfirmationDialog.show}
                    title={appConstants.dialogBox.purge}
                    message={appConstants.dialogBox.schemaRemoveMessage}
                    data={showConfirmationDialog.data}
                    optionButtonContent={{ show: true, title: appConstants.dialogBox.purge, color: 'secondary' }}
                    onCancel={dialogCancelEventHandle}
                    onConfirm={dialogConfirmEventHandle}
                    onOptionDialogButtonClick={dialogConfirmEventHandle} />
            }
        </React.Fragment>
    );
}

// default props
SchemaSelector.defaultProps = {
    classes: {},
    dbs_schema: [],
    schema: [],
    schemaLoading: false,
    detailById: {},
    userClass: "",
    divSpan: 6,
    handleSchemaSelection: () => { },
    customLabel: "",
    data_type: "schema",
    validators: [],
    errorMessages: []
};

// prop types
SchemaSelector.propTypes = {
    dbs_schema: PropTypes.array,
    schema: PropTypes.array,
    schemaLoading: PropTypes.bool,
    detailById: PropTypes.object,
    userClass: PropTypes.string,
    divSpan: PropTypes.number,
    handleSchemaSelection: PropTypes.func,
    customLabel: PropTypes.string,
    data_type: PropTypes.string,
    validators: PropTypes.array,
    errorMessages: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SchemaSelector);