
const style = () => ({
    pdfContainer: {
        height: "100%",
        padding: 30,
        '& iframe': {
            width: "100%"
        }
    }
});

export default style;