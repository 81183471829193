import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { FormLabel, Grid, IconButton, Typography, Tooltip, InputAdornment } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

//  Import Styles
import RuleHeaderStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Components
import {
    TextBoxComponent,
    SwitchComponent,
    StatusDropDownComponent,
    NumberInputComponent,
    AutoCompleteComponent,
    SelectComponent,
    UsersComponent
} from '../../../../../components/index.js';
import Scheduler from '../../../../../components/scheduler/index.jsx';
import MeasureLevelRuleHeader from './measureLevelHeader.jsx';

// Import Constants
import appConstants from '../../../../../constants/appConstants';

// Import Images
import { PauseCircleOutlineRounded } from '@mui/icons-material';
import { CloseIcon, CalendarIcon, PlayIcon2 } from '../../../../../assets/svg/index.js';
import { getApprovalStatus, orderList } from '../../../../../helpers/appHelpers.js';

// Import Reducer
import { triggerJobRequest } from '../../../../../redux/reducer/scheduleReducer.js';
import { getMeasureStatusRequest } from '../../../../../redux/reducer/measureReducer.js';
import { navigate } from '../../../../../redux/reducer/navigationReducer';
import { getUsersThumbnailRequest } from '../../../../../redux/reducer/userReducer';
// eslint-disable-next-line no-unused-vars
import { createFieldPropertyRequest, getFieldPropertyRequest, updateFieldProperty, updateFieldPropertyRequest } from '../../../../../redux/reducer/fieldPropertyReducer.js';
import FieldComponents from '../../../../asset/fields/fieldComponents.jsx';
import { getFieldsRequest } from '../../../../../redux/reducer/fieldsReducer.js';

function RuleHeader(props) {

    /**
     * Define Rules
     */
    const { data, handleMeaureAddEditDialog, editMeasure, classes, propertyPermission, publishPermission, approvePermission, isDisabled, isMeasureLevel, theme, connectionError } = props;
    const dispatch = useDispatch();

    const default_valid_measures = ["length", "pattern", "enum", "regular expressions", "long_pattern", "short_pattern"];

    const default_valid_category = ["behavioral", "comparison", "lookup"];

    /**
     * Define State
     */
    const [scheduler, setScheduler] = useState(null);
    let statusChecker = null;

    /**
     * Get Dimension List
     */
    const { searchableDimensionList } = useSelector((state) => state.dimension);
    const { thumbnail_list: users_list } = useSelector((state) => state.user);
    const { list: fields } = useSelector(({ fields }) => fields);


    const sorted_dimension = [...searchableDimensionList].sort((a, b) => a.name.localeCompare(b.name));


    const createDimensionName = (dimension_id) => {
        const selected_dimension = searchableDimensionList.find((obj) => obj.id === dimension_id);
        return selected_dimension?.name || '';
    };


    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = (event) => {
        event.stopPropagation();
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, isMeasure: true, connectionId: data?.connection, measureId: data.id });
    };

    /**
     * Handles the job trigger related events
     * @param {*} status
     */
    const handleJobTrigger = (status) => {
        if (status === 'Completed') {
            dispatch(triggerJobRequest({ "measure_id": data.id, job_type: "measure" }));
        } else {
            dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [data.connection_id || data.connection] }));
        }
    };

    /**
     * Check measure dag running status
     * @param {*} data
     * @returns
     */
    const checkStatus = (data) => {
        if (data.run_status === 'Completed') {
            return;
        }
        dispatch(getMeasureStatusRequest(data.id));
    };

    /**
     *
     * Define Use Effects for dag run status checker
     */
    useEffect(() => {
        if (data.run_status && !statusChecker && isMeasureLevel) {
            statusChecker = setInterval(() => checkStatus(data), appConstants.statusCheckInterval);
        }
        return () => {
            if (statusChecker) {
                clearInterval(statusChecker);
                statusChecker = null;
            }
        };
    }, [data.run_status]);

    // disabling default valid, scoring, monitor for comparison
    if (data.category === "comparison") {
        data.is_positive = false;
        data.is_drift_enabled = false;
        data.allow_score = false;
    }

    /**
     * Filter users
     * @param {*} listData
     * @returns
     */
    const filterUsers = (listData) => {
        const data = listData?.length > 0 ? listData : [];
        const users = JSON.parse(JSON.stringify(data));
        return orderList(users, 'name', 'asc');
    };
    const usersList = useMemo(() => filterUsers(users_list), [users_list]);

    /**
     * Get Organization Users List
     */
    useEffect(() => {
        if (!users_list.length && isMeasureLevel) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);


    /**
     * Get Filter Property
     */
    useEffect(() => {
        dispatch(getFieldsRequest());
    }, []);

    useEffect(() => {
        if (!data.id && fields.length > 0) {
            const newFields = [...fields].map((item) => ({ ...item, "id": null, "custom_field": item.id })).filter((item) => item.level === "Measure").sort((a, b) => a.order - b.order);
            editMeasure("custom_fields", newFields);
        }
    }, [fields]);


    const MeasureFields = data.custom_fields ? [...data.custom_fields].sort((a, b) => a.order - b.order) : [];


    /**
     * Handle OnChange
     * @param {*} field
     * @param {*} value
     */
    const onChange = (field, value) => {
        const newFields = data.custom_fields ? [...data.custom_fields] : [];
        if (data.id) {
            const indexToUpdate = newFields.findIndex((item) => item.custom_field === field.custom_field);
            if (indexToUpdate !== -1) {
                newFields[indexToUpdate] = { ...newFields[indexToUpdate], value: value };
            }
        } else {
            const existingFieldIndex = newFields.findIndex((item) => item.custom_field === field.custom_field);
            if (existingFieldIndex !== -1) {
                newFields[existingFieldIndex] = { ...newFields[existingFieldIndex], value: value };
            } else {
                newFields.push({ ...field, value });
            }
        }
        editMeasure("custom_fields", newFields);
    };

    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.addEditHeader} key={`rule-header-component-${data?.id ?? 'empty'}`}>
                <Grid container justifyContent={'space-between'} className="mb-2">
                    <Grid item className="dflex alignCenter">
                        <Grid>
                            <Typography variant="h2" className="pb5">
                                {data.id ? 'Edit a Measure' : 'Create a Measure'}
                            </Typography>
                            <Typography variant="body1" className={classes.initalCaps}>
                                {`${data.category ?? 'Conditional'} Measure`}
                            </Typography>
                        </Grid>
                        {
                            data.id && isMeasureLevel &&
                            <Grid className="ml-15">
                                <UsersComponent
                                    list={usersList}
                                    isSteward={false}
                                    data={data.stewards || []}
                                    disabled={!publishPermission?.is_edit && !approvePermission?.is_edit}
                                    handleStewardUser={(stewards) => editMeasure("stewards", stewards)} />
                            </Grid>
                        }
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item className="mr-2">
                                {
                                    (!(publishPermission?.is_none && approvePermission?.is_none)) &&
                                    <StatusDropDownComponent
                                        className={'mr-1'}
                                        disabled={data.is_default || data?.status === appConstants.status[3] || (!publishPermission?.is_edit && !approvePermission?.is_edit)}
                                        value={data?.status || "Pending"}
                                        onChange={(value) => editMeasure("status", value)}
                                        dropdownValue={getApprovalStatus(publishPermission, approvePermission, data?.status || "Pending")}
                                        isReview
                                    />
                                }
                            </Grid>
                            <Grid item className={classes.measuresAction}>
                                {
                                    (!approvePermission?.is_none) &&
                                    <SwitchComponent disabled={(data.default_active || !approvePermission?.is_edit)} size="small" label={'Active'} checked={data.is_active || false} handleChange={(value) => editMeasure('is_active', value)} />
                                }
                                {
                                    isMeasureLevel &&
                                    <Fragment>
                                        <Tooltip title="Schedule">
                                            <span>
                                                <IconButton className="CalendarIcon" onClick={(event) => openScheduler(event, data)} disabled={!data.id}>
                                                    <CalendarIcon active={data?.is_schedule} />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={data?.run_status === 'Completed' ? "Run Now" : data?.run_status ?? "Pending"}>
                                            <span>
                                                <IconButton onClick={() => handleJobTrigger(data?.run_status)} disabled={!data.id}>
                                                    {
                                                        data?.run_status === 'Completed' ?
                                                            <PlayIcon2 />
                                                            : <PauseCircleOutlineRounded style={{ fill: theme.palette.greyshades.darkgrey }} />
                                                    }
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Fragment>
                                }
                                <IconButton
                                    className={classes.closeIcon}
                                    onClick={handleMeaureAddEditDialog}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} key={'rule-header-component'}>
                <Grid container spacing={6} sx={{ marginTop: '-45px' }}>
                    <Grid item xs={4}>
                        <TextBoxComponent
                            name="name"
                            fullWidth
                            variant="standard"
                            label={
                                <span className="requiredLabel">
                                    Measure Name
                                    <span className="requiredstar">
                                        *
                                    </span>
                                </span>
                            }
                            value={data.name}
                            onChange={(event) => editMeasure(event.target.name, event.target.value)}
                            validators={['required']}
                            errorMessages={[appConstants.errorMessages.nameRequired]}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={8} className="pl-6 descriptionContainer">
                        <Tooltip title={data.description} arrow>
                            <Grid>
                                <TextBoxComponent
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    variant="standard"
                                    value={data.description}
                                    onChange={(event) => editMeasure(event.target.name, event.target.value)}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <AutoCompleteComponent
                            freeSolo={false}
                            name="dimension"
                            variant="standard"
                            fullWidth
                            selectedValue={createDimensionName(data?.dimension) ?? 'null'}
                            data={sorted_dimension || []}
                            placeholder="Select Dimension"
                            onChange={(event, newValue) => editMeasure('dimension', newValue?.id || null)}
                        />
                    </Grid>
                    <Grid item xs={8} className={`dflex pl-6 alignCenter ${classes.switchesContainer}`}>
                        <Grid container alignItems={"center"}>
                            <Grid item className="pr-4">
                                <SwitchComponent size="small" label={'Valid'} disabled={(data && (data.disable_scoring || default_valid_category.includes(data?.category) || default_valid_measures.includes(data?.technical_name))) || !propertyPermission?.is_edit || (data.name && appConstants.frequencyMeasures.indexOf(data.name.toLowerCase()) > -1)} checked={data.is_positive && !(data.category === "behavioral")} handleChange={(value) => editMeasure('is_positive', value)} />
                            </Grid>

                            <Grid item className="pr-4">
                                <SwitchComponent
                                    size="small"
                                    label={'Monitor'}
                                    disabled={!propertyPermission?.is_edit || data?.category === "comparison"}
                                    checked={data.is_drift_enabled || !data.category === "comparison" || false}
                                    handleChange={(value) => editMeasure('is_drift_enabled', value)} />
                            </Grid>
                            <Grid item className={`${classes.scoringContainer} pr-4`}>
                                <SwitchComponent
                                    size="small"
                                    label={'Scoring'}
                                    disabled={(data && (data.disable_scoring || data.category === "behavioral" || data.category === "comparison")) || !propertyPermission?.is_edit}
                                    checked={data.allow_score && !(data.category === "behavioral")}
                                    handleChange={(value) => editMeasure('allow_score', value)} />


                            </Grid>
                            {
                                (data.category !== "behavioral" || data.category !== "comparison") &&
                                <Grid item className={`dflex alignCenter pr-2 ${classes.weightageContainer} ${classes.weightageContainerExpand}`}>
                                    <FormLabel className="pr-1" sx={{ color: `${(!propertyPermission?.is_edit || !data.allow_score || data.category === "comparison") && "#00000061"} ` }}>
                                        Weightage
                                    </FormLabel>
                                    <NumberInputComponent
                                        value={data?.weightage ?? 100}
                                        valOnChange
                                        onChange={(value) => editMeasure('weightage', value)}
                                        validators={['required', 'minNumber:1', 'maxNumber:100']}
                                        errorMessages={['Required', 'Min Weigtage is 1', 'Max Weigtage is 100']}
                                        min="1"
                                        max="100"
                                        integeronly={false}
                                        disabled={!propertyPermission?.is_edit || !data.allow_score || data.category === "comparison"}
                                    />
                                </Grid>
                            }

                            <Grid item className="passCriteria">
                                <SwitchComponent
                                    size="small"
                                    label={'Pass Criteria'}
                                    checked={data.enable_pass_criteria || false}
                                    handleChange={(value) => editMeasure('enable_pass_criteria', value)}
                                    disabled={(data && (data.disable_scoring || !data.allow_score || data.category === "behavioral" || data.category === "comparison" || data.category === "lookup")) || !propertyPermission?.is_edit}
                                />
                            </Grid>

                            <Grid className={classes.scoringContainer} sx={{ marginLeft: '-42px' }}>
                                <SelectComponent
                                    name="type"
                                    noOutline
                                    list={["==", "!=", ">", "<", ">=", "<="]}
                                    value={data.pass_criteria_condition || '>='}
                                    onSelectChange={
                                        (value) =>
                                            editMeasure('pass_criteria_condition', value)
                                    }
                                    isDisabled
                                />
                                <NumberInputComponent
                                    value={data?.pass_criteria_threshold ?? 100}
                                    valOnChange
                                    onChange={(value) => editMeasure('pass_criteria_threshold', value)}
                                    validators={['required', 'minNumber:1', 'maxNumber:100']}
                                    errorMessages={['Threshold is required', 'Min Threshold is 1', 'Max Threshold is 100']}
                                    min="1"
                                    max="100"
                                    integeronly={false}
                                    disabled={!propertyPermission?.is_edit || !data.enable_pass_criteria || !data.allow_score || data.category === "comparison"}
                                    InputProps={
                                        {
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    &gt;=
                                                </InputAdornment>
                                        }
                                    }
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={8} className={classes.customFields}>
                            {
                                MeasureFields.map((field, index) =>
                                    <Grid key={`field_${index}`} item xs={4} className="fieldItems">
                                        <Typography variant="h6" className="capitalize">
                                            {field.name}
                                        </Typography>
                                        <Tooltip title={field.description} arrow>
                                            <Typography variant="body1" className="pt-1 mb-1 oneLineEllip">
                                                {field.description}
                                            </Typography>
                                        </Tooltip>
                                        <FieldComponents field={field} onChangeValue={(value) => onChange(field, value, data)} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>

                    {
                        isMeasureLevel &&
                        <MeasureLevelRuleHeader
                            data={data}
                            editMeasure={editMeasure}
                            isDisabled={isDisabled}
                            connectionError={connectionError}
                        />
                    }
                </Grid>
                {
                    scheduler?.open &&
                    <Scheduler scheduleProps={scheduler} onClose={() => setScheduler({ open: false, anchorElement: null, isAsset: false, isMeasure: true, connectionId: data.connection, measureId: data.id })} />
                }
            </Grid>
        </React.Fragment>
    );
}

// default props
RuleHeader.defaultProps = {
    classes: {},
    data: {},
    isDisabled: false,
    handleMeaureAddEditDialog: () => { },
    editMeasure: () => { },
    propertyPermission: {},
    publishPermission: {},
    approvePermission: {},
    isMeasureLevel: false,
    theme: {},
    connectionError: false
};

// prop types
RuleHeader.propTypes = {
    data: PropTypes.object,
    handleMeaureAddEditDialog: PropTypes.func,
    editMeasure: PropTypes.func,
    classes: PropTypes.object,
    propertyPermission: PropTypes.object,
    publishPermission: PropTypes.object,
    approvePermission: PropTypes.object,
    isDisabled: PropTypes.bool,
    isMeasureLevel: PropTypes.bool,
    theme: PropTypes.object,
    connectionError: PropTypes.bool
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes) && _.isEqual(prevProps.connectionError, nextProps.connectionError);
}

export default withStyles(
    (theme) => ({
        ...RuleHeaderStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(RuleHeader, areEqual));