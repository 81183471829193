// Default Imports
import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import palette from './../../assets/theme/palette.js';

// Import Helpers
import { nFormatter, numberWithCommas } from '../../helpers/appHelpers';

// Import Styles
import style from "./style.jsx";


const DraggableBarChartComponent = (props) => {
    /**
     * Define Props
     */
    const {
        theme, inputData, editable, minValue, maxValue, chartType, onDragChange,
        onItemClick, enableDrillDown, onDataDrilldown, showValue
    } = props;
    const chartHeight = 400;
    const chartWidth = 500;
    const minVal = Number(minValue || 0);
    const maxVal = Number(maxValue || 0);
    const avgVal = Math.floor((maxVal + minVal) / 2);

    /**
     * Order by Length Value
     */
    let orderedData = _.orderBy(inputData, [chartType === "length" ? "name" : "count"], [chartType === "length" ? "asc" : "desc"]);
    if (orderedData && enableDrillDown) {
        for (const item of orderedData) {
            if (item.isExpanded) {
                orderedData = item.subInputData;
                break;
            }
        }
        orderedData = _.orderBy(orderedData, [chartType === "length" ? "name" : "count"], [chartType === "length" ? "asc" : "desc"]);
    }
    let minValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === minVal?.toString());
    let maxValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === maxVal?.toString());
    let avgValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === avgVal?.toString());
    if (chartType === "length" && avgValueGridIndex <= 0) {
        orderedData.push({
            "name": avgVal?.toString(),
            "count": 0,
            "percentage": 0
        });
        orderedData = _.orderBy(orderedData, ["name"], ["asc"]);
        minValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === minVal?.toString());
        maxValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === maxVal?.toString());
        avgValueGridIndex = _.findIndex(orderedData, (cData) => cData.name?.toString() === avgVal?.toString());
    }

    /**
     * Define State
     */
    const [chartInstance, setChartInstance] = useState(null);

    /**
     * Define Chart Options
     */
    const option = {

        // Chart Overall Font Style
        textStyle: {
            color: appConstants.chartColors.labelColor,
            fontSize: 13,
            fontFamily: palette.headers.body1.fontFamily
        },

        // X Axis Label Style and content
        xAxis: {
            type: 'category',
            splitLine: {
                show: false
            },
            axisLine: {
                show: true,
                onZero: false,
                lineStyle: {
                    color: theme.palette.greyshades.grey
                }
            },
            axisTick: {
                show: false,
                alignWithLabel: true
            },
            axisLabel: {
                show: true,
                rotate: chartType === "value" ? 30 : 0,
                margin: 10
            }
        },

        // Y Axis Label Style and content
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                margin: 5,
                formatter: (value) => {
                    return nFormatter(value, 'default');
                }
            }
        },

        // Tooltip Label Style and content
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params) => {
                const [name, count, percentage, sample_value] = params[0].value;
                return `
                    ${chartType === "value" ? "Value" : "Length"}: ${name}<br />
                    Count: ${numberWithCommas(count || 0, false)}<br />
                    Percentage: ${percentage || 0}%<br />
                    ${showValue && sample_value ? `Sample Value : ${sample_value} <br />` : ""}
                `;
            }
        },

        /**
         * Set position of the canvas
         */
        grid: {
            left: 20,
            right: 40,
            bottom: 30,
            top: chartType === "length" ? 50 : 10,
            containLabel: true
        },

        // Set zoom inside the chart
        dataZoom: [
            {
              type: 'inside'
            }
        ],

        // Set Type and Data of the chart
        series: [
            {
                type: 'bar',
                barWidth: 28,
                progressiveThreshold: 100,
                emphasis: {
                    itemStyle: {
                        opacity: 'none'
                    }
                },
                data: orderedData.map((item) => {
                    const itemColor = ((item.name < (minVal) || item.name > (maxVal)) || !item.is_valid) ? '#ffcac2' : '#c8eeff';
                    return {
                        value: [item.name?.toString(), item.count || 0, item.percentage || 0, item?.sample_value || "", item],
                        itemStyle: {
                            color: itemColor
                        }
                    };
                })
            }
        ]
    };

    if (chartType === "length") {
        let markLines = [];
        if (minValue !== maxValue) {
            markLines = [
                {
                    name: `Min Length is ${chartType === "length" ? minVal : minVal.toFixed(1)}`,
                    xAxis: minValueGridIndex
                },
                {
                    name: `Avg Length is ${avgVal.toFixed(1)}`,
                    xAxis: avgValueGridIndex
                },
                {
                    name: `Max Length is ${chartType === "length" ? maxVal : maxVal.toFixed(1)}`,
                    xAxis: maxValueGridIndex
                }
            ];
        } else {
            markLines = [
                {
                    name: `Min-Max Length is ${minVal}`,
                    xAxis: minValueGridIndex
                }
            ];
        }
        option.series[0].markLine = {
            symbol: "none",
            label: {
                formatter: '{b}',
                padding: [0, 0, 10, 0]
            },
            lineStyle: {
                color: theme.palette.greyshades.darkestgrey
            },
            data: [...markLines]
        };
    } else {
        option.grid.bottom = 0;
        option.grid.right = 0;
    }


    /**
     * Handle drag end event
     */
    const onDragEnd = (event, chart, type) => {
        if (!chart) {
            return;
        }
        const handlerPosition = chart?.convertFromPixel('grid', [event.offsetX, event.offsetY]);
        const value = orderedData[handlerPosition[0]]?.name ?? "";
        if (value) {
            onDragChange(type, value?.toString());
        }
    };

    /**
     * Create a draggable Handler for edit
     */
    const createDraggableHandler = (chart) => {
        if (!editable) {
            return;
        }
        const minPosition = chart?.convertToPixel('grid', [minValueGridIndex, 0]);
        const maxPosition = chart?.convertToPixel('grid', [maxValueGridIndex, 0]);
        if (!minPosition || !maxPosition) {
            return;
        }

        chart?.setOption({
            graphic: [
                {
                    type: 'rect',
                    z: 100,
                    shape: {
                        width: 0,
                        height: (chartHeight - 50)
                    },
                    x: minPosition[0],
                    y: 0,
                    draggable: true,
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                        stroke: 'rgba(0,0,0,0.1)',
                        lineWidth: 28
                    },
                    cursor: 'move',
                    ondragend: (event) => onDragEnd(event, chart, "min")
                },
                {
                    type: 'rect',
                    z: 100,
                    shape: {
                        width: 0,
                        height: (chartHeight - 50)
                    },
                    x: maxPosition[0],
                    y: 0,
                    draggable: true,
                    style: {
                        fill: 'rgba(0,0,0,0.1)',
                        stroke: 'rgba(0,0,0,0.1)',
                        lineWidth: 28
                    },
                    cursor: 'move',
                    ondragend: (event) => onDragEnd(event, chart, "max")
                }
            ]
        });
    };

    /**
     * Handle click event in chart
     */
    const onClickChart = useCallback((params) => {
        const { event, value } = params;
        const name = value[0];
        const selectedItem = value[value.length - 1];
        if (selectedItem) {
            if (enableDrillDown) {
                onDataDrilldown(selectedItem.subInputData?.length > 1 ? selectedItem.name : "");
                return;
            }
            const selectedIndex = inputData.findIndex((item) => item.name?.toString() === name?.toString());
            onItemClick(event.event, selectedItem, selectedIndex);
        }
    }, [inputData]);

    /**
     * Compute chart width based on parent element
     * @param {* The chart instace} chart
     */
    const computeChartWidth = (chart) => {
        const parentNode = chart?.getDom()?.parentElement;
        if (parentNode) {
            const chartWidth = parentNode?.clientWidth || 0;
            chart?.resize({
                width: chartWidth,
                height: chartHeight,
                animation: {
                    duration: 100,
                    easing: "linear"
                }
            });
        }
    };

    /**
     * Refresh the chart when data changes
     */
    useEffect (() => {
        if (chartInstance) {
            chartInstance?.getZr()?.on("click", (params) => {
                if (params?.target) {
                    return;
                }
                const pointInPixel = [params.offsetX, params.offsetY];
                const pointInGrid = chartInstance?.convertFromPixel('grid', pointInPixel);
                const selectedItem = orderedData[pointInGrid[0]];
                if (selectedItem) {
                    if (enableDrillDown) {
                        onDataDrilldown(selectedItem.subInputData?.length > 1 ? selectedItem.name : "");
                        return;
                    }
                    const selectedIndex = inputData.findIndex((item) => item.name?.toString() === selectedItem.name?.toString());
                    onItemClick(params.event, selectedItem, selectedIndex);
                }
            });
            computeChartWidth(chartInstance);
            if (!editable) {
                return;
            }
            createDraggableHandler(chartInstance);
        }
    }, [inputData, chartInstance]);

    /**
     * Update the drag handler based on min/max values
     */
    useEffect (() => {
        if (chartInstance && editable) {
           setTimeout(() => createDraggableHandler(chartInstance), 100);
        }
    }, [minValue, maxValue]);

    /**
     * Handle on chart ready event
     * @param {*} chart
     * @returns
     */
    const onChartReady = (chart) => {
        chart.on('datazoom', () => {
            createDraggableHandler(chart);
        });
        setChartInstance(chart);
    };

    /**
     * Define chart events
     */
    const chartEvents = {
        click: onClickChart
    };


    return (
        <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            id="draggableBarChartId"
            className={"profileChart"}
            style={{ height: chartHeight, width: chartWidth }}
            notMerge={Boolean(!editable)}
            onChartReady={(chart) => onChartReady(chart)}
            onEvents={chartEvents}
        />
    );
};


/**
 * Set Default Values
 */
DraggableBarChartComponent.defaultProps = {
    theme: {},
    inputData: [],
    editable: false,
    enableDrillDown: false,
    showValue: false,
    minValue: 0,
    maxValue: 0,
    chartType: "value",
    onDragChange: () => { },
    onItemClick: () => { },
    onDataDrilldown: () => { }
};

/**
 * Define Prop Types
 */
 DraggableBarChartComponent.propTypes = {
    theme: PropTypes.object,
    inputData: PropTypes.array,
    editable: PropTypes.bool,
    enableDrillDown: PropTypes.bool,
    showValue: PropTypes.bool,
    minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    chartType: PropTypes.string,
    onDragChange: PropTypes.func,
    onItemClick: PropTypes.func,
    onDataDrilldown: PropTypes.func
};

export default withStyles((theme) => ({
    ...style(theme)
}), { withTheme: true })(DraggableBarChartComponent);