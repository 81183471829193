import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Grid } from '@mui/material';

//  Import Styles
import SpotlightStyle from './style.jsx';
import styles from '../../layouts/private/styles.jsx';


function Spotlight(props) {
    const { classes } = props;

    return (
        <Grid container className={classes.spotlightContainer}>
            <Grid item xs={12} className={classes.carousal} />
        </Grid>
    );
}

// default props
Spotlight.defaultProps = {
    classes: {}
};

// prop types
Spotlight.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SpotlightStyle(theme),
        ...styles(theme)
    }),
    { withTheme: true }
)(Spotlight);