import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid, Rating, Typography, Button } from '@mui/material';
import _ from 'lodash';


//  Import Components
import { ContinuousSliderComponent } from '../../../../../components';
import FilterItems from './filters.jsx';
import SearchableFilterItems from './searchableFilters.jsx';

//  Import Styles
import CatalogStyle from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';

// Import Reducers
import { getCatalogConnectionsListRequest } from '../../../../../redux/reducer/connectionReducer';
import { getApplicationsFilterRequest } from '../../../../../redux/reducer/applicationReducer';
import { getDomainListFilterRequest } from '../../../../../redux/reducer/semanticReducer.js';
import { getTagsFilterRequest } from '../../../../../redux/reducer/tagsReducer';
import { getMappedTermsListFilterRequest } from '../../../../../redux/reducer/termReducer';

// Import Constants
import appConstants from '../../../../../constants/appConstants.js';
import { getDashboardFilterRequest } from '../../../../../redux/reducer/baseMeasureReducer';
import { getDimensionRequest } from '../../../../../redux/reducer/dimensionReducer';
import { orderList } from '../../../../../helpers';
import { getAssetsSearchableListRequest } from '../../../../../redux/reducer/assetReducer';

function FilterBar(props) {

    /**
     * Define Props
     */
    const { classes, onChange } = props;
    const dispatch = useDispatch();

    /**
     * Define State
     */
    const [customAccordionSelected, setCustomAccordionSelected] = useState(null);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { searchableApplicationsListFilter } = useSelector((state) => state.applications, shallowEqual);
    const { searchableGlossariesFilter } = useSelector((state) => state.semantic, shallowEqual);
    const { catalogConnectionsList } = useSelector((state) => state.connection, shallowEqual);
    const { dashboardFilter } = useSelector((state) => state.baseMeasure, shallowEqual);
    const { searchableDimensionList } = useSelector((state) => state.dimension, shallowEqual);
    const { searchableTagsFilterList } = useSelector((state) => state.tags, shallowEqual);
    const { mappedTermsListFilter } = useSelector((state) => state.term, shallowEqual);
    const detail = useSelector(({ customWidget }) => customWidget.detail);
    const { assets_searchable_list: assetList } = useSelector((state) => state.asset, shallowEqual);

    /**
     * Get Filter Data
     * @param {*} widgetDetail
     * @returns
     */
    const getFilters = (widgetDetail) => {
        const filters = JSON.parse(JSON.stringify(widgetDetail?.filter_properties?.filters ?? {}));
        if (!filters?.asset_status || (!filters?.asset_status?.length === 0)) {
            filters.asset_status = [...appConstants.status.slice(0, 3)];
        }
        return filters;
    };
    const filters = useMemo(() => getFilters(detail), [detail]);


    /**
     * Get Applications and Glossary List
     */
    useEffect(() => {
        if (!searchableApplicationsListFilter || searchableApplicationsListFilter.length === 0) {
            dispatch(getApplicationsFilterRequest());
        }
        if (!searchableGlossariesFilter || searchableGlossariesFilter.length === 0) {
            dispatch(getDomainListFilterRequest());
        }
        if (!searchableTagsFilterList || searchableTagsFilterList.length === 0) {
            dispatch(getTagsFilterRequest());
        }
        if (!mappedTermsListFilter || mappedTermsListFilter.length === 0) {
            dispatch(getMappedTermsListFilterRequest());
        }
        if (!catalogConnectionsList || catalogConnectionsList.length === 0) {
            dispatch(getCatalogConnectionsListRequest());
        }
        if (!dashboardFilter) {
            dispatch(getDashboardFilterRequest());
        }
        if (!searchableDimensionList || searchableDimensionList.length === 0) {
            dispatch(getDimensionRequest());
        }
        dispatch(getAssetsSearchableListRequest());
    }, [dispatch]);

    /**
     * on FilterChange
     * @param {*} property
     * @param {*} value
     */
    const onFilterChange = (property, value) => {
        const filterData = Object.assign({}, { ...filters });
        filterData[property] = value;
        onChange("filter_properties",
            {
                ...detail.filter_properties,
                filters: filterData
            }
        );
    };

    /**
     * Handle Opposite Values
     * @param {*} value
     */
    const getOppositeValue = (value) => {
        const withoutPrefix = 'without_';
        return value && value.startsWith(withoutPrefix) ? value.substring(withoutPrefix.length) : withoutPrefix + value;
    };

    /**
     * Handle CheckBox Changes
     * @param {*} type
     * @param {*} item
     */
    const handleCheckBoxClick = (type, item, key = 'id') => {
        const newFilter = { ...filters };
        if (!newFilter[type]) {
            newFilter[type] = [];
        }
        let copyData = [...newFilter[type]];
        const fValue = key === 'name' ? item.name : item.id;
        if (_.findIndex(copyData, (value) => value === fValue) === -1) {
            copyData = [...copyData, fValue !== null ? fValue : "_show_blank_select_"];
        } else {
            copyData.splice(_.findIndex(copyData, (value) => value === fValue), 1);
        }
        const oppositeValue = getOppositeValue(fValue);
        const indexOfOppositeValue = _.findIndex(copyData, (value) => value === oppositeValue);

        if (indexOfOppositeValue !== -1) {
            copyData.splice(indexOfOppositeValue, 1);
        }

        onFilterChange(type, copyData);
    };


    /**
     * Control Custom Accordion
     * @param {*} selectedAccordion
     */
    const handleCustomChange = (selectedAccordion) => {
        const panel = customAccordionSelected !== selectedAccordion ? selectedAccordion : null;
        setCustomAccordionSelected(panel);
    };

    /**
     * Filter Tags Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterTags = (listData) => {
        let tags = JSON.parse(JSON.stringify(listData));
        tags = orderList(tags, 'name', 'asc');
        return tags;
    };
    const tagsList = useMemo(() => filterTags(searchableTagsFilterList), [searchableTagsFilterList]);

    /**
     * Filter Applications Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterApplications = (listData) => {
        let applications = JSON.parse(JSON.stringify(listData));
        applications = orderList(applications, 'name', 'asc');
        return applications;
    };
    const applicationsList = useMemo(() => filterApplications(searchableApplicationsListFilter), [searchableApplicationsListFilter]);


    /**
     * Filter Domains Which Have Count Values
     * @param {*} listData
     * @returns
     */
    const filterDomains = (listData) => {
        let domains = JSON.parse(JSON.stringify(listData));
        domains = orderList(domains, 'name', 'asc');
        return domains;
    };
    const domainsList = useMemo(() => filterDomains(searchableGlossariesFilter), [searchableGlossariesFilter]);


    /**
     * Filter Terms
     * @param {*} listData
     * @returns
     */
    const filterTerms = (listData) => {
        let terms = JSON.parse(JSON.stringify(listData));
        terms = orderList(terms, 'name', 'asc');
        return terms;
    };
    const termsList = useMemo(() => filterTerms(mappedTermsListFilter), [mappedTermsListFilter]);


    /**
     * Filter Connections
     * @param {*} listData
     * @returns
     */
    const filterConnections = (listData) => {
        let connections = JSON.parse(JSON.stringify(listData));
        connections = orderList(connections, 'name', 'asc');
        return connections;
    };
    const connectionsList = useMemo(() => filterConnections(catalogConnectionsList), [catalogConnectionsList]);


    /**
     * Filter Dimensions
     * @param {*} listData
     * @returns
     */
    const filterDimensions = (listData) => {
        let connections = JSON.parse(JSON.stringify(listData));
        connections = orderList(connections, 'name', 'asc');
        return connections;
    };
    const dimensionsList = useMemo(() => filterDimensions(searchableDimensionList), [searchableDimensionList]);

    /**
     * Get dashbord filters list
     * @param {*} key
     * @returns
     */
    const getDashboardFilterList = (key) => {
        let filtersList = JSON.parse(JSON.stringify(dashboardFilter[key]));
        filtersList = orderList(filtersList, 'name', 'asc');
        return filtersList;
    };

    const onClear = (type) => {
        const filterData = Object.assign({}, { ...filters });
        if (filterData[type]) {
            filterData[type] = [];
        }
        onChange("filter_properties",
            {
                ...detail.filter_properties,
                filters: filterData
            }
        );
    };

    return (
        <Grid className={classes.sidebarContainer}>
            <Grid>
                <Grid className={`${classes.resetFilter}`}>
                    <Button variant="outlined" color="primary" size="small" className="mb-1">
                        Clear Filter
                    </Button>
                </Grid>
            </Grid>
            <FilterItems
                classes={classes}
                checkboxList
                type={'filter_by'}
                default_open
                header={'Filter By'}
                options={appConstants.dashobard_filter_by}
                data={filters?.filter_by ?? []}
                field_key={'id'}
                handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'id')}
            />

            <FilterItems header={'Ratings'} default_open>
                <Grid className="dflex alignCenter">
                    <Grid item>
                        <Rating
                            className={classes.starIcon}
                            name="rating"
                            value={filters.rating || 0}
                            precision={0.5}
                            size="small"
                            onChange={
                                (event, newValue) => {
                                    onFilterChange('rating', newValue);
                                }
                            } />
                    </Grid>
                    <Typography
                        variant="body1"
                        className="fw-500 pl5"
                    >
                        {filters.rating || ''}
                    </Typography>
                </Grid>
            </FilterItems>
            <FilterItems header={'DQ Score'} default_open>
                <ContinuousSliderComponent
                    value={filters.score || [0, 100]}
                    onSliderValueChange={(value) => onFilterChange('score', value)}
                />
            </FilterItems>
            {
                assetList && assetList.length > 0 &&
                <SearchableFilterItems
                    classes={classes}
                    checkboxList
                    type={`asset_name`}
                    header={`Asset Name`}
                    options={assetList}
                    data={filters?.[`asset_name`] ?? []}
                    field_key={'id'}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'id')}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    onClear={(type) => onClear(type)}
                />
            }
            {
                applicationsList && applicationsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'applications'}
                    header={'Application'}
                    options={applicationsList}
                    data={filters?.applications ?? []}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
            {
                connectionsList && connectionsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'connection_type'}
                    header={'Connections'}
                    options={connectionsList}
                    data={filters?.connection_type ?? []}
                    field_key={'name'}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'name')}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
            {
                dimensionsList && dimensionsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'dimension'}
                    header={'Dimension'}
                    options={dimensionsList}
                    data={filters?.dimension ?? []}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
            {
                domainsList && domainsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'domains'}
                    header={'Domains'}
                    options={domainsList}
                    data={filters?.domains ?? []}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
            {
                dashboardFilter && Object.keys(dashboardFilter).length > 0 &&
                Object.keys(dashboardFilter).map((key) =>
                (key === 'name' ?
                    <SearchableFilterItems
                        key={key}
                        classes={classes}
                        checkboxList
                        type={`measure_${key}`}
                        header={`Measure ${key[0].toUpperCase()}${key.slice(1).toString()}`}
                        options={getDashboardFilterList(key)}
                        data={filters?.[`measure_${key}`] ?? []}
                        field_key={'id'}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'id')}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                        onClear={(type) => onClear(type)}
                    /> :
                    <FilterItems
                        key={key}
                        classes={classes}
                        checkboxList
                        type={`measure_${key}`}
                        header={`Measure ${key[0].toUpperCase()}${key.slice(1).toString()}`}
                        options={getDashboardFilterList(key)}
                        data={filters?.[`measure_${key}`] ?? []}
                        field_key={'id'}
                        handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'id')}
                        customAccordionSelected={customAccordionSelected}
                        handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                    />))
            }
            <FilterItems
                    classes={classes}
                    checkboxList
                    type={'asset_status'}
                    header={'Status'}
                    options={appConstants.assetStatusFilters}
                    data={filters?.asset_status ?? []}
                    field_key={'name'}
                    default_open={filters?.asset_status?.length > 0}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item, 'name')}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            {
                tagsList && tagsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'tags'}
                    header={'Tags'}
                    options={tagsList}
                    data={filters?.tags ?? []}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
            {
                termsList && termsList.length > 0 &&
                <FilterItems
                    classes={classes}
                    checkboxList
                    type={'terms'}
                    header={'Terms'}
                    options={termsList}
                    data={filters?.terms ?? []}
                    handleCheckBoxClick={(item, type) => handleCheckBoxClick(type, item)}
                    customAccordionSelected={customAccordionSelected}
                    handleCustomChange={(selectedFilter) => handleCustomChange(selectedFilter)}
                />
            }
        </Grid>
    );
}

// default props
FilterBar.defaultProps = {
    classes: {},
    onChange: () => { }
};

// prop types
FilterBar.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...CatalogStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FilterBar);