import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, IconButton, ToggleButtonGroup, Typography, ToggleButton } from '@mui/material';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import AccountTree from '@mui/icons-material/AccountTree';
import TocIcon from '@mui/icons-material/Toc';


//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Components
import {
    TableChartComponent, LoaderComponent, NoResultComponent, PdfViewerComponent
} from '../../../../components/index';

// Import Action
import { getReportsPreviewDataRequest } from '../../../../redux/reducer/reportsReducer';

function Preview(props) {

    /**
     * Define Props
     */
    const { classes, detail, onChange, reportsPermission } = props;
    const dispatch = useDispatch();
    const propertyControllerRef = useRef();

    /**
     * Redux Store
     */
    const { preiview_data, previewLoading, reports_columns } = useSelector(({ reports }) => reports);

    /**
     * Get Reports Preview Data by Assets and Columns
     */
    useEffect(() => {
        if (detail?.assets && detail?.columns && detail?.columns?.length && reports_columns.length) {
            if (propertyControllerRef && propertyControllerRef.current) {
                propertyControllerRef.current.abort();
            }
            propertyControllerRef.current = new AbortController();
            const token = { signal: propertyControllerRef?.current?.signal };

            dispatch(getReportsPreviewDataRequest({ "params": detail, token }));
        }
    }, [detail.columns, detail.group_by, detail.filters, detail.properties]);

    /**
     * Handle Filters
     * @param {*} key
     * @param {*} value
     */
    const handleFilters = (key, value) => {
        const filters_data = { ...detail?.filters };
        filters_data[key] = value;
        onChange('filters', filters_data);
    };

    /**
     * Bind Preview Component Data
     * @returns
     */
    const preparePreviewData = () => {
        if (preiview_data && preiview_data.length && detail?.columns && detail?.columns?.length) {
            switch (detail.format) {
                case 'PDF':
                    return (<PdfViewerComponent detail={detail} data={preiview_data} hierarchyView={detail?.hierarchy_view ?? false} />);
                default:
                    return (
                        <TableChartComponent data={preiview_data || []} removeIdFieldsFromData={false} replaceUnderscore={false} />
                    );
            }
        } else {
            return null;
        }
    };

    /**
     * Ger Error Msg
     */
    const getErrorMsg = () => {
        let msg = "No preview records avilable";

        if (!detail?.assets?.length) {
            msg = "Please select assets and columns to view the records.";
        } else if (!detail?.columns?.length) {
            msg = "Please select columns to view the records.";
        }
        return msg;
    };


    return (
        <Grid container spacing={2} className={classes.PreviewContainer}>
            <Grid item xs={4}>
                <Typography variant="h6">
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={8} className="dflex alignCenter flexEnd">
                {
                    detail.format === 'PDF' &&
                    <IconButton
                        className={`${classes.chartIconClass} chartIconClass mr-2`}
                        key="displayType"
                        aria-label="Display Type"
                        color="inherit"
                        onClick={() => onChange("hierarchy_view", !detail?.hierarchy_view ?? false)}
                        disabled={!reportsPermission?.is_edit}
                    >
                        {detail?.hierarchy_view ? <AccountTree className="chartIcon" /> : <TocIcon className="chartIcon" />}
                    </IconButton>
                }
                <IconButton
                    className={`${classes.chartIconClass} chartIconClass mr-2`}
                    key="chartType"
                    aria-label="Chart Type"
                    color="inherit"
                    disabled
                >
                    {detail.format === 'PDF' ? <PictureAsPdf className="chartIcon" /> : <CalendarViewMonthIcon className="chartIcon" />}
                </IconButton>
                <ToggleButtonGroup
                    value={detail?.filters?.date_range ?? "last_run"}
                    exclusive
                    onChange={(event, value) => handleFilters("date_range", value)}
                    aria-label="text alignment"
                    className={classes.daysFilter}
                    disabled={!reportsPermission?.is_edit}
                >
                    <ToggleButton
                        value={"today"}
                        aria-label="left aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            Today
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"1"}
                        aria-label="left aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            Yesterday
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"3"}
                        aria-label="left aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            3d
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"7"}
                        aria-label="centered"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            7d
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"14"}
                        aria-label="right aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            14d
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"30"}
                        aria-label="right aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            30d
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"last_run"}
                        aria-label="right aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            Last Run
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        value={"all"}
                        aria-label="right aligned"
                    >
                        <Typography variant="body2" className={classes.textSecondary}>
                            All
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} style={{ height: "100%" }}>
                {previewLoading && <LoaderComponent height={"100%"} />}
                {
                    !previewLoading && (!preiview_data?.length || !detail?.columns?.length) &&
                    <NoResultComponent title={getErrorMsg()} height={"100%"} />
                }
                {
                    !previewLoading &&
                    preparePreviewData()
                }
            </Grid>
        </Grid >
    );
}

// default props
Preview.defaultProps = {
    classes: {},
    detail: {},
    reportsPermission: {},
    onChange: () => { }
};

// prop types
Preview.propTypes = {
    classes: PropTypes.object,
    detail: PropTypes.object,
    reportsPermission: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Preview);