const style = (theme) => ({
    sidebarContainer: {
        /*
         * paddingRight: '30px',
         * paddingBottom: '40px',
         * paddingLeft: 10,
         */
        borderRight: `1px solid ${theme.palette.greyshades.darkgrey}40`,
        position: 'relative',
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        width: 300,
        boxSizing: 'border-box',
        height: '100%',
        '& .ArrowUp path': {
            fill: `${theme.palette.greyshades.darkgrey}90`
        },
        '& .ArrowDown path': {
            fill: `${theme.palette.greyshades.darkgrey}90`
        },
        '& .sortIcons .MuiIconButton-root': {
            width: 26,
            height: 26,
            '&:hover': {
                '& svg': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .active': {
            '& svg': {
                // fill: theme.palette.secondary.main
            }
        },
        '&.collapsedSidebar': {
            transform: 'translateX(-288px)',
            '& .sideBarItem': {
                visibility: 'hidden'
            }
        },
        '& .sideBarItem': {
            marginBottom: 20,
            '& .title': {
                paddingBottom: 6,
                borderBottom: `1px solid ${theme.palette.greyshades.darkgrey}40`
            },
            '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
                color: theme.palette.greyshades.darkgrey,
                fontSize: '1rem'
            },
            '& .MuiRating-icon svg.MuiSvgIcon-root': {
                fontSize: '1.4rem !important'
            },
            '& .MuiRating-root': {
                color: '#F6C831 !important'
            },
            '& .MuiPaper-root': {
                marginTop: 0,
                background: 'transparent !important',
                boxShadow: 'none !important',
                '&:before': {
                    background: 'transparent'
                },
                '&.Mui-expanded': {
                    margin: '0px !important'
                }
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                height: '32.5px !important',
                minHeight: '32.5px !important',
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '0px !important'
            },
            '& .MuiAccordionSummary-root': {
                padding: '0px !important',
                minHeight: '32.5px !important',
                height: '32.5px !important'
            },
            '& .MuiAccordionDetails-root': {
                padding: '0px !important'
            },
            '& .MuiAccordionSummary-expandIconWrapper:hover': {
                '& svg': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .sidebarItemBody': {
            padding: '13px 8px 0',
            '& .MuiCheckbox-root .MuiSvgIcon-root': {
                fontSize: '1.3rem'
            },
            '& .rangeSlider': {
                alignItems: 'center'
            },
            '& .MuiCheckbox-root': {
                padding: '6px !important'
            },
            '& span.MuiTypography-root': {
                width: 'calc(100% - 40px)'
            },
            '& .MuiFormControlLabel-root': {
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                '& .checkBoxText': {
                    maxWidth: 'calc(100% - 38px)'
                },
                '& .MuiTypography-root': {
                    '&:hover': {
                        '&.MuiTypography-root': {
                            color: `${theme.palette.secondary.main} !important`
                        }
                    }
                }
            },
            '& .checkedIcon': {
                color: `${theme.palette.secondary.main} !important`,
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    sideBarInner: {
        padding: '0px 30px 40px 10px',
        maxWidth: '300px',
        maxHeight: 'calc(100vh - 144px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar-thumb': {
            background: `${theme.palette.greyshades.darkgrey}40 !important`,
            '&:hover': {
                background: `${theme.palette.greyshades.darkgrey}40 !important`
            }
        },
        '&::-webkit-scrollbar': {
            width: '3px'
        },
        '&.collapsedSidebar': {
            visibility: 'hidden'
        }
    },
    iconBg: {
        backgroundColor: '#ffffff',
        right: '-13px',
        padding: 5,
        top: 15,
        width: 26,
        height: 26,
        borderRadius: '50%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 8px #0000001a',
        cursor: 'pointer',
        transition: 'all 0.5s',
        '&.collapsed': {
            right: '-14px',
            transform: 'rotate(180deg)'
        },
        '&:hover .ChevLeftIcon path': {
            fill: theme.palette.secondary.main
        }
    },
    filterText: {
        '& input': {
            textTransform: 'capitalize'
        }
    },
    resetFilter: {
        // position: 'fixed',
        display: 'none',
        bottom: 0,
        width: '260px',
        textAlign: 'right',
        position: 'sticky',
        top: 0,
        background: theme.palette.greyshades.lightbackground,
        zIndex: 99,
        '&.active': {
            display: 'flex'
        },
        '& .MuiButton-root': {
            zIndex: 9999,
            opacity: 0,
            transition: 'all 0.5s'
        },
        '&.active .MuiButton-root': {
            display: 'flex',
            opacity: 1,
            transition: 'all 0.5s',
            marginTop: '0px',
            marginRight: 'auto'
            // width: 'calc(100% - 22px)'
        }
    }
});

export default style;