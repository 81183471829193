import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from "react-router-dom";
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

//  Import Components
import { Grid, IconButton, Tooltip, MenuItem } from '@mui/material';

//  Import Styles
import AssetStyle from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';

// Import Images
import { CalendarIcon, EditIcon, ViewLogsIcon, SyncIcon2 } from '../../../assets/svg/index.js';

// Import Components
import ConnectorsIcon from "../../../components/connectorsIcon/index.jsx";

// Import Reducer
import {
    connectionDetailRequest,
    connectionUpdateRequest
} from '../../../redux/reducer/connectionReducer';
import { navigate } from '../../../redux/reducer/navigationReducer';
import Scheduler from '../../../components/scheduler/index.jsx';
import { TextBoxComponent, PopOverComponent } from '../../../components/index.js';
import { syncConnectorAssetRequest } from '../../../redux/reducer/connectorReducer';


function Header(props) {
    /**
     * Define Props
     */
    const { classes, connectionPermission } = props;
    const dispatch = useDispatch();
    const { id: connection_id } = useParams();
    const [scheduler, setScheduler] = useState({ open: false, anchorElement: null, isAsset: false, connectionId: connection_id });

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     * Handle Popup
     * @param {*} event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close Popup
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Set Variables
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { detailById } = useSelector((state) => state.connection, shallowEqual);

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(connectionDetailRequest(connection_id));
    }, [dispatch, connection_id]);


    /**
     * Navigate to Edit Connection Page
     */
    const navigateEditConnectionPage = () => {
        dispatch(navigate({ path: 'connector.edit', state: {}, params: [detailById.type, connection_id] }));
    };

    /**
     * Opens the connection level scheduler
     * @param {*} event
     */
    const openScheduler = useCallback((event, level) => {
        event.stopPropagation();
        setScheduler({ open: true, anchorElement: event.target, isAsset: false, connectionId: connection_id, level: level });
    }, []);

    /**
     * On Change Basic Details
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        const newData = { ...detailById, [property]: value };
        dispatch(
            connectionUpdateRequest({
                ...newData,
                id: connection_id,
                validation_required: false,
                no_redirection: true
            })
        );
    };

    /**
     * Define Sync Assets condition
     */
    const syncAssets = () => {
        const requestData = {
            connection_id,
            view: "table"
        };
        dispatch(syncConnectorAssetRequest(requestData));
    };

    return (
        <Grid item xs={12} className={classes.connectorDetailTop}>
            <Grid container alignItems={'center'} justifyContent="space-between">
                <Grid item className="dflex alignCenter flexOne">
                    <Grid item className={classNames(classes.connectorIcon, detailById?.type || "")}>
                        <ConnectorsIcon connection_type={detailById && detailById.type || ''} />
                    </Grid>
                    <Grid item className={classes.connectorFormTile}>
                        <Grid className="mb5" sx={{ maxWidth: '50%' }}>
                            <TextBoxComponent
                                className={classes.title}
                                noOutline
                                placeholder="Enter Name"
                                variant="standard"
                                name="name"
                                value={detailById.name || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                validators={['required']}
                                errorMessages={["Name is required"]}
                                fullWidth
                                disabled={!connectionPermission?.is_edit}
                            />
                        </Grid>
                        <Grid sx={{ maxWidth: '70%' }}>
                            <TextBoxComponent
                                className={classes.title}
                                noOutline
                                placeholder="Enter Description"
                                variant="standard"
                                name="description"
                                value={detailById.description || ""}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                                disabled={!connectionPermission?.is_edit}
                                inputProps={{ maxLength: 60 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={`${classes.connectorDetailAction} dflex alignCenter}`}>
                    <Tooltip title="Sync">
                        <IconButton onClick={syncAssets} className="syncicon">
                            <SyncIcon2 />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Logs">
                        <IconButton className="viewLogsBtn" onClick={() => dispatch(navigate({ path: 'logs.executionLogs', state: {}, params: [connection_id] }))}>
                            <ViewLogsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Schedule">
                        <IconButton onClick={handleClick}>
                            <CalendarIcon />
                        </IconButton>
                    </Tooltip>
                    <PopOverComponent
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={`${classes.popoverContainer} popoverContainer`}
                    >
                        <Grid className={classes.popoverContainer}>
                            <MenuItem onClick={(event) => openScheduler(event, "sync")} disabled={!detailById.id}>
                                <CalendarIcon />
                                Sync Schedule
                            </MenuItem>
                            <MenuItem onClick={(event) => openScheduler(event, "asset")} disabled={!detailById.id}>
                                <CalendarIcon />
                                Asset Schedule
                            </MenuItem>
                        </Grid>
                    </PopOverComponent>
                    {
                        connectionPermission?.is_edit &&
                        <Tooltip title="Edit Connection">
                            <IconButton onClick={navigateEditConnectionPage} disabled={!detailById.id}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            {
                scheduler?.open &&
                <Scheduler scheduleProps={scheduler} onClose={() => setScheduler({ open: false, anchorElement: null, isAsset: false, connectionId: connection_id })} disabled={!connectionPermission?.is_edit} isEventSchedule={false} category={scheduler.level === "sync" ? "sync" : "connection"} />
            }
        </Grid>
    );
}

// default props
Header.defaultProps = {
    classes: {},
    connectionPermission: {}
};

// prop types
Header.propTypes = {
    classes: PropTypes.object,
    connectionPermission: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AssetStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Header);