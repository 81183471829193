import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

//  Import Components
import { PopOverComponent } from '../index';

//  Import Styles
import PatternAddStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

// Helpers
import appConstants from '../../constants/appConstants.js';
import { connectionListRequest } from '../../redux/reducer/connectionReducer';
import { connectorAssetRequest, connectorAttributeRequest } from '../../redux/reducer/connectorReducer';
import { TableComponent } from '../../components/index.js';


function LookupTableConfig(props) {
    const { classes, open, anchorElement, onClose, onSave, properties } = props;
    const dispatch = useDispatch();

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { list: connectionList } = useSelector(
        (state) => state.connection,
        shallowEqual
    );
    /**
     * Redux Select Action
     * @param {*} event
     */
    const { filterData: filterAssets, assetAttributes } = useSelector((state) => state.connector.asset, shallowEqual);

    const assets = filterAssets.map((item) => {
        return {
            ...item,
            customToolTipName: item.schema ? `${item.schema}.${item.name}` : item.name
        };
    });

    const attributes = assetAttributes.map((item) => {
        return {
            name: item.column_name,
            ...item
        };
    });


    /**
     * Prepare Connection List
     * @param {*} data
     * @returns
     */
    const relationalConnections = [
        appConstants.connectionTypes.snowflake.value,
        appConstants.connectionTypes.mssql.value,
        appConstants.connectionTypes.redshift.value,
        appConstants.connectionTypes.databricks.value,
        appConstants.connectionTypes.db2.value,
        appConstants.connectionTypes.redshift_spectrum.value,
        appConstants.connectionTypes.saphana.value,
        appConstants.connectionTypes.athena.value,
        appConstants.connectionTypes.oracle.value,
        appConstants.connectionTypes.teradata.value,
        appConstants.connectionTypes.synapse.value,
        appConstants.connectionTypes.denodo.value,
        appConstants.connectionTypes.bigquery.value,
        appConstants.connectionTypes.emr_spark.value
    ];
    const prepareConnectionList = (data) => {
        return [{ 'id': '', 'name': 'None' }, ...data.filter((i) => i.is_valid && i.is_active && relationalConnections.indexOf(i.type.toLowerCase()) > -1)];
    };

    /**
     * Define Use Effects
     */
    useEffect(() => {
        dispatch(connectionListRequest({}));
    }, [dispatch]);

    const connectionData = useMemo(() => prepareConnectionList(connectionList), [connectionList]);
    const defaultConfig = [{ "connection": [], "assets": [], "attributes": [] }];
    const [tableData, setInputColumn] = useState([...properties.tableConfig || defaultConfig]);

    const handleCellClickEvent = (key, value) => {
        const updateObj = JSON.parse(JSON.stringify(tableData));
        updateObj[0][key] = value;
        if (key === "connection" && value && value.length > 0) {
            const requestData = {
                connection_id: value[0].id
            };
            dispatch(connectorAssetRequest(requestData));
        }
        if (key === "connection" && value.length === 0) {
            updateObj[0].assets = [];
            updateObj[0].attributes = [];
        }
        if (key === "assets" && value && value.length > 0) {
            const requestData = {
                connection_id: updateObj[0].connection[0].id,
                name: value[0].name,
                schema: value[0].schema,
                asset_id: value[0].id
            };
            dispatch(connectorAttributeRequest(requestData));
        }
        if (key === "assets" && value.length === 0) {
            updateObj[0].attributes = [];
            const requestData = {
                connection_id: updateObj[0].connection[0].id
            };
            dispatch(connectorAssetRequest(requestData));
        }

        if (key === "attributes" && value.length === 0) {
            const requestData = {
                connection_id: updateObj[0].connection[0].id,
                name: updateObj[0].assets[0].name,
                schema: updateObj[0].assets[0].schema,
                asset_id: updateObj[0].assets[0].id
            };
            dispatch(connectorAttributeRequest(requestData));
        }

        setInputColumn([...updateObj]);
    };

    const lookupTableHeaders = [
        { key: 'connection', name: 'Connections', width: "25%", component: 'chips', limit: 1, addLimitCount: 1, datatype: 'array', valueKey: 'name', defaultValue: [], isAdd: true, addType: "text", chipAddType: 'autocomplete', showNA: true, addText: 'Add Connection', isChipDelete: true, applyHeaderStyle: true },
        { key: 'assets', name: 'Table', width: "25%", component: 'chips', limit: 1, addLimitCount: 1, datatype: 'array', valueKey: 'name', defaultValue: [], isAdd: true, addType: "text", chipAddType: 'autocomplete', showNA: true, addText: 'Add Asset', isChipDelete: true, applyHeaderStyle: true },
        { key: 'attributes', name: 'attributes', width: "25%", component: 'chips', limit: 1, datatype: 'array', valueKey: 'name', defaultValue: [], isAdd: true, addType: "text", chipAddType: 'autocomplete', showNA: true, addText: 'Add Attributes', isChipDelete: true, applyHeaderStyle: true }
    ];

    useEffect(() => {
        if (properties && properties?.tableConfig) {
            setInputColumn(properties?.tableConfig);
            const requestData = {
                connection_id: properties.tableConfig[0].connection[0].id,
                name: properties.tableConfig[0].assets[0].name,
                schema: properties.tableConfig[0].assets[0].schema,
                asset_id: properties.tableConfig[0].assets[0].id
            };
            dispatch(connectorAttributeRequest(requestData));
        } else {
            setInputColumn(defaultConfig);
        }
    }, [properties]);

    return (
        <PopOverComponent
            anchorEl={anchorElement}
            open={open}
        >
            <Grid container className={classes.modalContainer} >
                <ValidatorForm onSubmit={() => onSave(tableData)}>
                    <Grid item xs={12} className={classes.tableContainer}>
                        <TableComponent
                            headers={lookupTableHeaders}
                            data={tableData || []}
                            selectComponentList={
                                {
                                    "connection": connectionData,
                                    "assets": assets,
                                    "attributes": attributes
                                }
                            }
                            styleType={null}
                            stickyHeader
                            onCompnentEvent={(key, value) => handleCellClickEvent(key, value)}
                        />
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} align="right">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="small"
                                className="mr-1"
                            >
                                {appConstants.labels.datasetProperties.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                size="small"
                                onClick={onClose}
                            >
                                {appConstants.labels.datasetProperties.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </PopOverComponent>
    );
}

// default props
LookupTableConfig.defaultProps = {
    classes: {},
    properties: {},
    open: false,
    anchorElement: null,
    headers: [],
    onClose: () => { },
    onSave: () => { }
};

// prop types
LookupTableConfig.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorElement: PropTypes.object,
    onClose: PropTypes.func,
    properties: PropTypes.object,
    onSave: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...PatternAddStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LookupTableConfig);