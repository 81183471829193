const style = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    logViewerContainer: {
        maxWidth: 450,
        minWidth: 450,
        padding: '15px 25px',
        backgroundColor: `${theme.palette.background.paper} !important`,
        '& .copyIcon': {
            marginLeft: 5,
            marginTop: 2,
            cursor: 'pointer'
        }
    },
    errorMessage: {
        fontSize: 14,
        maxHeight: 400,
        textAlign: "justify",
        overflow: "hidden",
        overflowY: "auto"
    },
    loader: {
        position: 'relative',
        width: "100%",
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .loaderContainer': {
            width: 'inherit',
            height: 'inherit',
            margin: 0
        }
    }
});

export default style;