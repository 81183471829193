const style = (theme) => ({
    rolesListContainer: {
        paddingTop: 10,
        maxWidth: '100%',
        overflow: 'auto',
        '& .MuiGrid-root': {
            flex: 1
        },
        '& .MuiAccordion-root:before': {
            background: 'transparent'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important',
            marginBottom: '10px !important',
            marginTop: '0px !important',
            width: 'fit-content',
            minWidth: '100%'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px !important'
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px 20px 0px 32px !important',
            background: theme.palette.greyshades.tablegrey,
            '&.Mui-expanded': {
                minHeight: '48px'
            }
        },
        '& .MuiAccordionDetails-root': {
            // padding: '0px !important',
            padding: '10px 0px 10px',
            '&:nth-child(even)': {
                background: theme.palette.greyshades.tablegrey
            }
        },
        '& .PlusIcon': {
            fill: theme.palette.secondary.main
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            left: 6
        },
        '& .MuiToggleButtonGroup-root': {
            height: '36px'
        },
        '& .roleHeader': {
            padding: '0px 0px',
            minWidth: '110px'
        },
        '& .permissionCell': {
            minWidth: '110px'
        }
    },
    accordionBody: {
        // padding: '10px 0px !important',
        '&:nth-child(even)': {
            background: theme.palette.greyshades.lightestgrey
        },
        '& .MuiGrid-root': {
            display: 'flex',
            alignItems: 'center',
            flex: 1
        },
        '& .permissionCell ': {
            padding: '0px 10px'
        }
    },
    checkMarkIcon: {
        fill: `${theme.palette.componentColors.success} !important`,
        cursor: "pointer"
    },
    checkUnMarkIcon: {
        fill: `${theme.palette.greyshades.grey} !important`,
        cursor: "pointer"
    },
    addRoleContainer: {
        padding: 24
    },
    usersCard: {
        padding: '24px 36px',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            minWidth: '130px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid rgb(229 231 235)'
        }
    },
    cardTitle: {
        position: 'absolute',
        background: '#fff',
        top: '-14px',
        left: '16px',
        padding: '2px 15px'
    },
    cardText: {
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        color: `${theme.palette.secondary.main} !important`
    },
    newRole: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: 5,
            marginRight: 5
        },
        '& .MuiInput-root.MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none !important'
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            fontFamily: `${theme.palette.headers.h6.fontFamily} !important`,
            fontWeight: `${theme.palette.headers.h6.weight} !important`,
            maxWidth: '100%',
            textOverflow: 'ellipsis'
        },
        '& .DeleteIcon': {
            visibility: 'hidden',
            opacity: 0,
            transition: 'all 0.5s',
            width: 18,
            height: 18
        },
        '&:hover': {
            '& .DeleteIcon': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.5s'
            }
        }
    },
    rolesListRoot: {
        maxWidth: '100%',
        overflow: 'auto'
    }
});
export default style;