import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

//  Import Components
import { Avatar, Grid, Typography } from '@mui/material';

//  Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../../layouts/style.jsx';
import { LinearProgressComponent, NoResultComponent } from '../../../../../components/index.js';

// Import Helpers
import { stringAvatar } from '../../../../../helpers/appHelpers';

function TopUsers(props) {

    const { classes, data } = props;

    /**
     * Get Total Count
     */
    const getTotalCount = (items) => {
        return items.reduce((a, b) => a + b.count, 0);
    };

    const totalCount = useMemo(() => getTotalCount(data), [data]);

    /**
     * Get Percentage
     * @param {*} count
     * @returns
     */
    const getPercentage = (count = 0) => {
        return ((count / totalCount) * 100).toFixed(1);
    };


    return (
        <Grid item xs={12} className="h-100">
            {
                data.length ?
                    <Grid>
                        {
                            data.map((item, index) =>
                                <Grid key={`user_${index}`} container className={`${classes.widgetItem} mb-2`} alignItems={'center'} wrap="nowrap">
                                    <Grid item className={classes.widgetListIcon}>
                                        <Avatar alt={item.name} {...stringAvatar(item.name, item?.avatar ?? '')} sx={{ width: 35, height: 35, textTransform: 'uppercase' }} onClick={(event) => { event.stopPropagation(); }} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.widgetItemDetail}>
                                        <Grid item className="dflex alignCenter">
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {item.name || ""}
                                                </Typography>
                                                <Typography variant="body1" className={classes.textSecondary}>
                                                    {item.role}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid className={classes.progressBar}>
                                                    <LinearProgressComponent value={getPercentage(item.count)} percentage />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Grid> :
                    <NoResultComponent height="150" title="No Users Found" />
            }
        </Grid>
    );
}

// default props
TopUsers.defaultProps = {
    classes: {},
    data: []
};

// prop types
TopUsers.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TopUsers);