import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';


//  Import Components
import { Grid, IconButton, Tooltip } from '@mui/material';
import { TextComponent } from '../../../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ImageCropper from '../../../../../../components/imageCrop/ImageCropper.jsx';

//  Import Styles
import Styles from '../../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import { DeleteIcon, CameraIcon } from '../../../../../../assets/svg/index.js';
import Placeholder from '../../../../../../assets/img/placeholder.png';

// Reducer
import { toggleAddGlossary, createCategoryRequest, createTermRequest } from '../../../../../../redux/reducer/semanticReducer.js';


function DetailAdd(props) {
    /**
     * Define Props
     */
    const { classes, addType, onClose, selectedItem } = props;
    const dispatch = useDispatch();
    const { id: glossaryId, categoryId } = useParams();
    const { state } = useLocation();
    const stateCategory = state?.category || {};
    const selectedGlossary = state?.glossary || {};
    const formRef = useRef();

    /**
     * Define State
     */
    const [category, setCategory] = useState({});
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);

    /**
     * Get Data from Redux Store
     */
    const { selectedCategory, type } = useSelector(({ semantic }) => semantic);


    /**
     * On Change
     * @param {*} property
     * @param {*} value
     */

    const onChange = useCallback((property, value) => {
        setCategory((category) => ({ ...category, [property]: value }));
    }, [category]);

    /**
     * On Close Glossary
     */
    const onCloseGlossary = () => {
        dispatch(toggleAddGlossary());
    };


    /**
     * Get Technical Name
     * @param {*} data
     * @returns
     */
    const getTechnicalName = (data) => {
        let displayName = data.technical_name || "";
        if (displayName.includes("(")) {
            displayName = displayName.split("(")[1].replace(")", "");
            displayName = `${displayName}.${data.name}`;
        }
        return `${category.name}(${displayName})`;
    };

    /**
     * on Save Glossary Category or Term
     */
    const onSaveGlossaryItem = () => {
        const requestParams = {
            ...category,
            glossary: glossaryId || selectedCategory.glossary_id,
            parent: glossaryId || selectedCategory.glossary_id,
            level: 1,
            technical_name: getTechnicalName(selectedGlossary),
            isAdd: true
        };
        let selectedData = addType === "sidebar" ? selectedItem : stateCategory || selectedCategory;
        if (type === "category" || type === "category_detail") {
            if (selectedData && Object.keys(selectedData).length > 0) {
                requestParams.parent = selectedData.id || selectedData.glossary_id;
                requestParams.level = selectedData.level + 1;
                requestParams.technical_name = getTechnicalName(selectedData);
                requestParams.isAdd = categoryId ? (selectedCategory.id === categoryId || selectedData?.id === categoryId) : (selectedCategory.glossary_id === glossaryId || selectedData?.glossary_id === glossaryId);
            }
            dispatch(createCategoryRequest(requestParams));
        } else {
            if (addType !== "sidebar" && type !== "term_detail") {
                requestParams.domain_id = glossaryId || categoryId;
                requestParams.glossary = selectedData?.id ?? glossaryId;
            } else {
                requestParams.domain_id = selectedCategory?.glossary_id || selectedCategory?.id;
                requestParams.glossary = selectedCategory?.id || selectedCategory?.glossary_id;
                selectedData = selectedCategory;
                requestParams.isAdd = selectedCategory?.type === "category" ? (selectedCategory.id === categoryId || selectedData?.id === categoryId) : (selectedCategory.glossaryId === glossaryId || selectedData?.glossary_id === glossaryId);
            }
            if (selectedData && Object.keys(selectedData).length > 0) {
                requestParams.technical_name = getTechnicalName(selectedData);
            }
            dispatch(createTermRequest(requestParams));
        }
        if (onClose) {
            onClose();
        }
    };

    /**
     * Trigger Submit
     */
    const onTriggerSubmit = () => {
        formRef.current.submit();
    };

    //img upload
    const handleImgUpload = () => {
        document.getElementById('domainImage').click();
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, croppedImageUrl) => {
        category.image = croppedImage;
        category.image_blob = croppedImageUrl;
        setCategory({ ...category });
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Set Category Data
     */
    useEffect(() => {
        setCategory({
            name: "",
            description: "",
            type
        });
    }, []);

    return (
        <Grid item xs={3} xl={2}>
            <Grid className={`${type === "category" ? classes.subDomainCard : classes.domainCard} domainCard`} onMouseLeave={() => onTriggerSubmit()}>
                {
                    type === "category" &&
                    <Grid item className={type === "category" ? classes.subDomainImgContainer : classes.imgContainer}>
                        <img src={category.image_blob || Placeholder} alt="DomainCard img" />
                    </Grid>
                }
                <Grid item className={`${type === "category" ? classes.subDomaindetail : classes.detail} detail`}>
                    <ValidatorForm onSubmit={() => onSaveGlossaryItem()} ref={formRef}>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="pb-1"
                            wrap="noWrap"
                        >
                            <TextComponent
                                className={type === "category" ? classes.subDomainTitle : classes.addtitle}
                                noOutline
                                placeholder="Enter Name"
                                variant="standard"
                                value={category.name || ""}
                                onChange={(event) => onChange("name", event.target.value)}
                                validators={['required']}
                                errorMessages={["Name is required"]}
                            />

                            <Grid className="dflex alignCenter">
                                <input
                                    accept="image/*"
                                    className={classes.domainImgInput}
                                    id="domainImage"
                                    type="file"
                                    onChange={handleSelectFile}
                                />
                                {
                                    openImageDialog && (
                                        <ImageCropper
                                            open={openImageDialog}
                                            onClose={() => setImageDialog(false)}
                                            imageToCrop={imageToCrop}
                                            aspect={16 / 9}
                                            onImageCropped={
                                                (croppedImage, croppedImageUrl) =>
                                                    updateCropImage(croppedImage, croppedImageUrl)
                                            }
                                        />
                                    )
                                }
                                {
                                    type === "category" &&
                                    <Tooltip title="Upload image" arrow>
                                        <IconButton
                                            className="p2 mr5 cameraIcon"
                                            onClick={handleImgUpload} >
                                            <CameraIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    addType !== "sidebar" &&
                                    <Tooltip title="Delete" arrow>
                                        <IconButton
                                            className="p2"
                                            onClick={() => onCloseGlossary()}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }

                            </Grid>
                        </Grid>
                        <TextComponent
                            className={`${type === "category" ? classes.subDomainDescription : classes.description} mt-1`}
                            placeholder="Enter Description"
                            name="description"
                            fullWidth
                            valOnChange
                            multiline
                            variant="standard"
                            rows={4}
                            value={category.description || ""}
                            onChange={(event) => onChange("description", event.target.value)}
                        />
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
DetailAdd.defaultProps = {
    classes: {},
    addType: "detail",
    onClose: () => { },
    selectedItem: {}
};

// prop types
DetailAdd.propTypes = {
    classes: PropTypes.object,
    addType: PropTypes.string,
    onClose: PropTypes.func,
    selectedItem: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DetailAdd);