import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography, Dialog } from '@mui/material';
import _ from 'lodash';

// Import Components
import ConnectorsIcon from '../../../../../components/connectorsIcon/index.jsx';
import Preview from '../../../usage/preview/index.jsx';
import { PreviewQuery } from '../previewQuery/index.jsx';
import { PreviewRunResults } from '../previewRunResults/index.jsx';
import { PreviewCode } from '../previewSourceCode/index.jsx';

//  Import Styles
import connectionCardStyle from "./style.jsx";
import LayoutStyles from '../../../../../layouts/style.jsx';

// Constants
import appConstants from '../../../../../constants/appConstants.js';
import featureConstants from '../../../../../constants/featureConstants.js';

// Import Helpers
import { bytesToSize, checkPermission, numberWithCommas, convertUTCtoLocalDateTime } from '../../../../../helpers/appHelpers.js';

// Import Images
import { DBTQueryIcon } from '../../../../../assets/svg/index.js';

function connectionCard(props) {

    /**
     * Define Props
     */
    const { classes, data, navigateToAssetPage, permission, accessByConnection } = props;

    // Preview Dialog
    const [preview, openPreview] = React.useState(false);
    const [previewQueryShow, setPreviewQueryShow] = React.useState(false);
    const [runResultShow, setRunResultShow] = React.useState(false);
    const [previewSourceCode, setPreviewSourceCode] = React.useState(false);


    /**
     * Handle Open Dialog
     */
    const handleClickDialog = () => {
        openPreview(true);
    };

    /**
     * Handle DBT Preview Dialog
     */
    const handleDBTPreviewQueryDialog = () => {
        setPreviewQueryShow(true);
    };

    /**
     * Handle Airflow Source Code Preview Dialog
     */
    const handleSourceCodePreviewDialog = () => {
        setPreviewSourceCode(true);
    };


    /**
     * Close Dialog
     */
    const handleCloseDialog = () => {
        openPreview(false);
        setPreviewQueryShow(false);
        setRunResultShow(false);
        setPreviewSourceCode(false);
    };

    /**
     * Monitioried value check
     * @param {*} value
     */
    const monitioriedValue = (column_count, selected_attributes, monitored) => {
        let monitioriedvalue = monitored;
        if (!monitioriedvalue) {
            monitioriedvalue = Math.round(((parseInt(selected_attributes) / parseInt(column_count)) * 100));
        }
        monitioriedvalue = Math.round(monitioriedvalue);

        if (parseInt(monitioriedvalue) > 100) {
            monitioriedvalue = "100";
        }
        return monitioriedvalue;
    };

    return (
        <Grid item xs={12}>
            <Grid
                container
                justifyContent={'space-between'}
                wrap="nowrap"
                item
                xs={12}
                className={classes.overviewInfo}
            >
                <Grid item className={`leftSection`} >
                    <Grid className="infoSection infoSectionPB">
                        <Grid className={`dflex alignCenter ${data.type || ''}`}>
                            <ConnectorsIcon connection_type={data.type} />
                            <Grid className="ml-15">
                                <Typography variant="h6" className="capitalize dflex alignCenter ">
                                    {data.type}
                                </Typography>
                                <Tooltip title={data.connection_name} arrow>
                                    <Typography
                                        variant="body1"
                                        className={`capitalize ellipsisText ${data?.connection_has_license && data?.connection_is_active ? 'pointer' : ''}`}
                                        onClick={() => checkPermission(permission, featureConstants.settings.connections)?.is_view && data?.connection_has_license && data?.connection_is_active && navigateToAssetPage(data || null)}
                                    >
                                        {data.connection_name}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        !accessByConnection?.report_type?.dbt && !accessByConnection?.report_type?.databricks &&
                        <Grid className="infoSection infoSectionPB">
                            {
                                accessByConnection?.report_type?.talend ?
                                    <Typography variant="h6">
                                        {data.properties?.type || "NA"}
                                    </Typography> :
                                    <Typography variant="h6">
                                        {data.asset_type || "NA"}
                                    </Typography>
                            }
                            <Typography
                                variant="body1"
                            >
                                {appConstants.labels.datasetOverview.type}
                            </Typography>
                        </Grid>
                    }
                    {
                        accessByConnection && accessByConnection.table_view_query &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data.data_size ? bytesToSize(data.data_size) : "NA"}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.datasize}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data.column_count ? data.column_count : 'NA'}
                                    {
                                        data.column_count && data.selected_attributes ?
                                            <Tooltip title={"Processed Attributes"} arrow>
                                                <span>
                                                    {data.selected_attributes ? ` (${data.selected_attributes})` : ''}
                                                </span>
                                            </Tooltip>
                                            : null
                                    }
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.columns}
                                </Typography>
                            </Grid>
                            <Tooltip title="Preview Query" arrow>
                                <Grid onClick={checkPermission(permission, featureConstants.assets.preview)?.is_view && handleClickDialog} className={`${classes.rowsContainer} infoSection`}>
                                    <Typography variant="h6">
                                        {numberWithCommas(data.row_count) || "NA"}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {appConstants.labels.datasetOverview.rows}
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data.monitored ? `${monitioriedValue(data.column_count, data.selected_attributes ? data.selected_attributes : 0, data.monitored)}%` : "NA"}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.monitored}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.tableau &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.tot_databases ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.databases}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.tot_tables ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tables}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.tot_columns ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.columns}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {convertUTCtoLocalDateTime(data?.properties?.updatedAt ?? null)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.updatedAt}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.dbt &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.model_type ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.model_type}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.database ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.database}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.schema ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.schema}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.columns?.length ?? 0}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.columns}
                                </Typography>
                            </Grid>
                            <Grid className={'infoSection'} >
                                <Typography variant="h6" className={`${data?.properties?.stats?.run_failed ? 'error' : ''}`}>
                                    {(data?.properties?.runResults?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                            <Grid className={'infoSection'}>
                                <Typography variant="h6" className={`${data?.properties?.stats?.tests_failed ? 'error' : ''}`}>
                                    {(data?.properties?.tests?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tests}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties && data?.properties?.tags && data?.properties?.tags?.length > 0 ? data?.properties?.tags : 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tags}
                                </Typography>
                            </Grid>
                            <Tooltip title="Query Preview" arrow>
                                <IconButton onClick={handleDBTPreviewQueryDialog}>
                                    <DBTQueryIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.fivetran &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.service ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.service}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.schema_count ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.schema}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.table_count ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tables}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.column_count ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.columns}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.databricks && data?.asset_detail?.properties?.type === "PIPELINE" &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.asset_detail?.properties?.type ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.type}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workflow_info?.target_db ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.database}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workflow_info?.target_schema ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.schema}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workflow_info?.state ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.status}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.created_by ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.owner}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6" className={`${data?.properties?.workflow_info?.pipeline_failed ? 'error' : ''}`}>
                                    {(data?.properties?.workflow_info?.last_runs?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6" className={`${data?.properties?.workflow_info?.pipeline_failed ? 'error' : ''}`}>
                                    {(data?.properties?.workflow_info?.workflow_events?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.latest_events}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.databricks && data?.asset_detail?.properties?.type === "JOB" &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.asset_detail?.properties?.type ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.type}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workflow_info?.format ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.format}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.total_tasks ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tasks}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.created_by ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.owner}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6" className={`${data?.properties?.workflow_info?.job_failed ? 'error' : ''}`}>
                                    {(data?.properties?.workflow_info?.workflow_events?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.airflow &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.is_active || 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.active}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.is_paused || 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.paused}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.schedule_interval_value || 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.schedule}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {convertUTCtoLocalDateTime(data?.properties?.next_dagrun ?? null)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.next_run}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.tags || 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tags}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {numberWithCommas(data?.properties?.stats?.runs ?? null)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {numberWithCommas(data?.properties?.stats?.tasks ?? '')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tasks}
                                </Typography>
                            </Grid>
                            <Tooltip title="Source Code" arrow>
                                <IconButton onClick={handleSourceCodePreviewDialog}>
                                    <DBTQueryIcon />
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.talend &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.pipeline_detail?.details?.type ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.pipleline_type}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.publisher ? `${data?.properties?.publisher?.firstName} ${data?.properties?.publisher?.lastName}` : "NA"}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.publisher}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.environment_name ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.environment_name}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workspace_name ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.workspace_name}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.datasets?.length ?? 0}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.datasets}
                                </Typography>
                            </Grid>
                            <Grid className={'infoSection'}>
                                <Typography variant="h6" className={`${data?.properties?.stats?.run_failed ? 'error' : ''}`}>
                                    {(data?.properties?.runs?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                            <Grid className={'infoSection'}>
                                <Typography variant="h6" className={`${data?.properties?.stats?.tests_failed ? 'error' : ''}`}>
                                    {(data?.properties?.tasks?.length ?? 0)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tasks}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.adf &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.pipeline_info?.activity_count ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.activity}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6" className={`${data?.properties?.pipeline_info?.pipeline_failed ? 'error' : ''}`}>
                                    {data?.properties?.pipeline_info?.pipeline_runs?.length ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.job_runs}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {convertUTCtoLocalDateTime(data?.properties?.pipeline_info?.pipeline_runs?.slice(-1)[0].runStart)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.last_run_at}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        accessByConnection && accessByConnection?.report && accessByConnection?.report_type?.powerbi &&
                        <React.Fragment>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.workspace ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.workspace_name}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.total_datasources ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.datasoure}
                                </Typography>
                            </Grid>
                            <Grid className={`infoSection`}>
                                <Typography variant="h6">
                                    {data?.properties?.total_dataset ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.dataset}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.total_tables ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.tables}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.total_columns ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.columns}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {data?.properties?.page_count ?? 'NA'}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.pages}
                                </Typography>
                            </Grid>
                            <Grid className="infoSection">
                                <Typography variant="h6">
                                    {convertUTCtoLocalDateTime(data?.properties?.modifiedDateTime ?? null)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {appConstants.labels.datasetOverview.updatedAt}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>

            {/* DBT preview */}
            <Dialog
                open={previewQueryShow}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <PreviewQuery closeDialog={handleCloseDialog} rawSql={data?.properties?.rawSql ?? ''} compiledSql={data?.properties?.compiledSql ?? ''} />
                </Grid>
            </Dialog>
            <Dialog
                open={runResultShow}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <PreviewRunResults closeDialog={handleCloseDialog} data={data?.properties?.runResults ?? []} report_type={accessByConnection?.report_type} />
                </Grid>
            </Dialog>

            {/* Rows Preview */}
            <Dialog
                open={preview}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <Preview closeDialog={handleCloseDialog} />
                </Grid>
            </Dialog>

            {/* DBT preview */}
            <Dialog
                open={previewQueryShow}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <PreviewQuery closeDialog={handleCloseDialog} rawSql={data?.properties?.rawSql ?? ''} compiledSql={data?.properties?.compiledSql ?? ''} />
                </Grid>
            </Dialog>

            {/* Source Code preview */}
            <Dialog
                open={previewSourceCode}
                onClose={handleCloseDialog}
                fullScreen
            >
                <Grid className={classes.previewContainer}>
                    <PreviewCode closeDialog={handleCloseDialog} source_code={data?.properties?.source_code ?? ''} />
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
connectionCard.defaultProps = {
    classes: {},
    data: {},
    accessByConnection: null,
    navigateToAssetPage: () => { },
    permission: []
};

// prop types
connectionCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    accessByConnection: PropTypes.object,
    navigateToAssetPage: PropTypes.func,
    permission: PropTypes.array
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data)
        && _.isEqual(prevProps.accessByConnection, nextProps.accessByConnection)
        && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...connectionCardStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(React.memo(connectionCard, areEqual));