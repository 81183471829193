const style = (theme) => ({
    channelContainer: {
        background: theme.palette.greyshades.lightbackground,
        border: `1px solid ${theme.palette.greyshades.lightgrey}`,
        padding: '27px 20px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .linkText': {
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`
            }
        }
    },
    channelImg: {
        height: 30,
        marginRight: 26,
        flexShrink: 0
    },
    issuesStatuscontainer: {
        '& .description': {
            '& .MuiInput-underline': {
                '&:before': {
                    borderBottom: '1px solid #ffffff00'
                },
                '& textarea': {
                    fontSize: `${theme.palette.headers.body1.size}px !important`,
                    '&:hover': {
                        resize: 'vertical'
                    },
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        borderRadius: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '8px'
                    }
                }
            }
        },
        '& .boxedLayout': {
            padding: 30,
            borderRadius: '3px',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px !important'
        },
        '& .chipContainer': {
            '& .dflex': {
                marginLeft: '0px !important'
            },
            '& .MuiAutocomplete-root': {
                minWidth: 'auto'
            }
        }
    },
    assigneeContainer: {
        '& .stewardUser': {
            visibility: 'hidden'
        },
        '& .avatarGroup': {
            marginLeft: '-55px'
        }
    },
    slack_url: {
        textDecoration: 'auto !important'
    },
    jira_endpoint: {
        textDecoration: 'auto !important'
    },
    bigpanda_url: {
        textDecoration: 'auto !important'
    }
});
export default style;