import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

// Import Components
import {
    TextBoxComponent, IntegerInputComponent, NumberInputComponent, SelectComponent, TimePickerComponent, DateTimePickerComponent, DatePickerComponent, SwitchComponent
} from '../../../components/index.js';

//  Import Styles
import Styles from './style.jsx';
import LayoutStyles from '../../../layouts/style.jsx';
import { isValidTimeFormat, isValidDateTimeFormat } from '../../../helpers/appHelpers.js';


function FieldComponents(props) {

    /**
     * Define Props
     */
    const { classes, field, onChangeValue } = props;
    /**
     * Define State
     */
    const [value, setValue] = useState(field.value || "");

    /**
     * Handle OnChange
     * @param {*} value
     */
    const onChange = (value) => {
        if (value === null) {
            value = "";
        }
        setValue(value);
        onChangeValue(value);
    };

    /**
     * Handle Manual Enter Event
     * @param {*} event
     */
    const ManualTimeEnter = (event, property) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (property === "time" && isValidTimeFormat(value)) {
                onChangeValue(value);
            } else if (property === "datetime" && isValidDateTimeFormat(value)) {
                onChangeValue(value);
            }
        }
    };

    /**
     * Handle Manual Time Change
     * @param {*} event
     */
    const handleManualTimeChange = (time) => {
        setValue(time);
    };

    /**
     * Get FieldComponent
     * @returns
     */
    const getFieldComponent = () => {
        const dataType = field.data_type?.toLowerCase() || 'text';
        switch (dataType) {
            case 'boolean':
                return (
                    <SwitchComponent
                        size="small"
                        checked={typeof (field.value) === "string" ? Boolean(field.value === "yes") : field.value}
                        handleChange={(value) => onChangeValue(value ? "yes" : "no")}
                    />
                );
            case 'date':
                return (
                    <DatePickerComponent
                        autoOk
                        fullWidth
                        variant="standard"
                        color="secondary"
                        value={field.value || ""}
                        onChange={(date) => onChange(date)} />
                );
            case 'datetime':
                return (
                    <Grid onKeyDown={(event) => ManualTimeEnter(event, 'datetime')}>
                        <DateTimePickerComponent
                            autoOk
                            variant="standard"
                            color="secondary"
                            value={field.value || ''}
                            onChange={(date) => handleManualTimeChange(date)}
                            onAccept={(date) => onChange(date)}
                        // onClose={() => onChangeValue(value)}
                        />
                    </Grid>
                );
            case 'time':
                return (
                    <Grid onKeyDown={(event) => ManualTimeEnter(event, 'time')}>
                    <TimePickerComponent
                        variant="standard"
                        placeholder="Select a Time"
                        color="secondary"
                        value={value || ""}
                        onAccept={(time) => onChange(time)}
                        onChange={(time) => handleManualTimeChange(time)}
                    />
                    </Grid>
                );
            case 'dropdown':
                const options = typeof (field.options) === "string" ? JSON.parse(field.options) : field.options;
                return (
                    <SelectComponent
                        variant={"standard"}
                        className={`${classes.selectField} selectField`}
                        value={field.value || ""}
                        onSelectChange={(value) => onChangeValue(value)}
                        list={options || []}
                        noOutline />
                );
            case 'numeric':
                return (
                    <NumberInputComponent
                        placeholder={field.name || ''}
                        fullWidth
                        variant="standard"
                        value={field.value || ""}
                        integeronly={false}
                        onChange={(value) => onChangeValue(value)}
                    />
                );
            case 'integer':
                return (
                    <IntegerInputComponent
                        placeholder={field.name || ''}
                        fullWidth
                        variant="standard"
                        value={field.value || ""}
                        onChange={(event) => onChangeValue(event.target.value)}
                    />
                );
            default:
                return (
                    <TextBoxComponent
                        placeholder={field.name || ''}
                        fullWidth
                        variant="standard"
                        value={field.value || ""}
                        multiline
                        onChange={(event) => onChangeValue(event.target.value)}
                    />
                );
        }
    };

    return (
        <Grid>
            {getFieldComponent()}
        </Grid>
    );
}

// default props
FieldComponents.defaultProps = {
    classes: {},
    field: {},
    onChangeValue: () => { }
};

// prop types
FieldComponents.propTypes = {
    classes: PropTypes.object,
    field: PropTypes.object,
    onChangeValue: PropTypes.func
};


export default withStyles(
    (theme) => ({
        ...Styles(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(FieldComponents);