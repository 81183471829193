import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Actions and Reducer
import {
    getAttributesByVersionRequest, getAttributesByVersionSuccess, getAttributesByVersionFailure, getAttributesDetailRequest,
    getAttributesDetailSuccess, getAttributesDetailFailure, updateAttributeRequest, updateAttributeSuccess, updateAttributeFailure,
    updateAttributeMetadataRequest, updateAttributeMetadataSuccess, updateAttributeMetadataFailure,
    getAllAttributesRequest, getAllAttributesSuccess, getAllAttributesFailure, updateAttributePatternRequest, updateAttributePatternSuccess, updateAttributePatternFailure,
    getInvalidRowsRequest, getInvalidRowsSuccess, getInvalidRowsFailure, toggleActiveMeasureRequest, toggleActiveMeasureSuccess, toggleActiveMeasureFailure,
    getActiveMeasureCategoriesRequest, getActiveMeasureCategoriesSuccess, getActiveMeasureCategoriesFailure,
    getAllAttributesMetadataRequest, getAllAttributesMetadataSuccess, getAllAttributesMetadataFailure, updateAllAttributeMetadata,
    toggleAssetSemanticsRequest, toggleAssetSemanticsSuccess, toggleAssetSemanticsFailure, toggleAssetAdvancedProfileRequest, toggleAssetAdvancedProfileSuccess, toggleAssetAdvancedProfileFailure,
    updateAttributeTermRequest, updateAttributeTermSuccess, updateAttributeTermFailure,
    getAttributeStatisticsPreviewRequest, getAttributeStatisticsPreviewSuccess, getAttributeStatisticsPreviewFailure
} from '../reducer/attributeReducer';
import { navigate } from '../reducer/navigationReducer';
import { updateVersionDetail, updatePrimaryColumns, updateAssetSemanticEnabled } from '../reducer/versionReducer';
import { getTagsRequest } from '../reducer/tagsReducer';
import { getMappedTermsListRequest } from '../reducer/termReducer';

// Import Reducers
import { displyAlert } from "../reducer/alertReducer";

// Import Service
import { attributeService } from '../service';
import appConstants from '../../constants/appConstants';
import { toggleMeasures, updateMeasuresMetadata } from '../reducer/measureReducer';


/**
 * Get Attribute list by Asset Id
 * @param {*} action$
 * @returns
 */
const list = (action$) => action$.pipe(
    ofType(getAttributesByVersionRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.list(payload)).pipe(
            concatMap((res) => of(getAttributesByVersionSuccess({ ...res }))),
            catchError((error) => of(getAttributesByVersionFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Get Attribute Detail
 * @param {*} action$
 * @returns
 */
const detail = (action$) => action$.pipe(
    ofType(getAttributesDetailRequest),
    mergeMap(({ payload: { attribute_id, token } }) => {
        return from(attributeService.detail(attribute_id, token)).pipe(
            concatMap((res) => of(getAttributesDetailSuccess(res))),
            catchError(() => of(getAttributesDetailFailure()))
        );
    })
);


/**
 * Update Attribute
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
    ofType(updateAttributeRequest),
    mergeMap(({ payload: { id, type } }) => {
        return from(attributeService.update(id, type)).pipe(
            concatMap((res) => of(updateAttributeSuccess(res), navigate({ path: 'catalog.root', state: {}, params: [] }))),
            catchError((error) => of(updateAttributeFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);


/**
 * Update Attribute Metadata
 * @param {*} action$
 * @returns
 */
const updateMetadata = (action$) => action$.pipe(
    ofType(updateAttributeMetadataRequest),
    mergeMap(({ payload: { attribute_id, ...rest } }) => {
        return from(attributeService.updateMetadata(attribute_id, rest)).pipe(
            concatMap((res) => {
                return of(
                    getActiveMeasureCategoriesRequest(attribute_id),
                    updateAttributeMetadataSuccess({ data: res.data.data, requestParams: rest }),
                    updateAllAttributeMetadata({ data: res.data.data, requestParams: { attribute_id, ...rest } }),
                    updatePrimaryColumns({ data: res.data.data, requestParams: rest }),
                    toggleMeasures(res.data),
                    updateVersionDetail({ version_info: res.data?.version_info ?? {}, monitoring: res?.data?.monitoring_detail ?? {} }),
                    ('tags' in rest) ? getTagsRequest() : { type: "" },
                    ('term' in rest) ? getMappedTermsListRequest() : { type: "" },
                    updateMeasuresMetadata({ attribute_id, ...rest })
                );
            }),
            catchError((error) => of(updateAttributeMetadataFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get All Attributes
 * @returns
 */
const getAllAttributes = (action$) => action$.pipe(
    ofType(getAllAttributesRequest),
    mergeMap(() => {
        return from(attributeService.getAllAttributes()).pipe(
            concatMap((res) => of(getAllAttributesSuccess(res))),
            catchError(() => of(getAllAttributesFailure()))
        );
    })
);

/**
 * Add / Update Attribute Level Pattern Measure
 * @param {*} action$
 * @returns
 */
const managePatterns = (action$) => action$.pipe(
    ofType(updateAttributePatternRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.pattern(payload)).pipe(
            concatMap((res) => of(updateAttributePatternSuccess({ "patterns": res?.data?.data ?? [] }), updateVersionDetail(res.data?.version_info ?? {}), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }))),
            catchError((error) => of(updateAttributePatternFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Failed Rows
 * @param {*} action$
 * @returns
 */
const getInvalidRows = (action$) => action$.pipe(
    ofType(getInvalidRowsRequest),
    mergeMap(({ payload: { assetId, attributeId } }) => {
        return from(attributeService.getInvalidRows(assetId, attributeId)).pipe(
            concatMap((res) => of(getInvalidRowsSuccess(res?.data ?? []))),
            catchError((error) => of(getInvalidRowsFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Toggle active measures
 * @returns
 */
const toggleActiveMeasure = (action$) => action$.pipe(
    ofType(toggleActiveMeasureRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.toggleActiveMeasure(payload)).pipe(
            concatMap((res) => of(toggleActiveMeasureSuccess(res?.data ?? []), toggleMeasures(res.data))),
            catchError(() => of(toggleActiveMeasureFailure()))
        );
    })
);

/**
 * Toggle active measures
 * @returns
 */
const getActiveMeasureCategories = (action$) => action$.pipe(
    ofType(getActiveMeasureCategoriesRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.getActiveMeasureCategories(payload)).pipe(
            concatMap((res) => of(getActiveMeasureCategoriesSuccess(res?.data ?? []))),
            catchError(() => of(getActiveMeasureCategoriesFailure()))
        );
    })
);

/**
 * Get all attributes metadata
 */
const getAllAttributesMetadata = (action$) => action$.pipe(
    ofType(getAllAttributesMetadataRequest),
    mergeMap(({ payload: { params, token } }) => {
        return from(attributeService.getAllAttributesMetadata(params, token)).pipe(
            concatMap((res) => of(getAllAttributesMetadataSuccess({ data: res?.data?.data ?? [], inputParams: params }))),
            catchError(() => of(getAllAttributesMetadataFailure()))
        );
    })
);


/**
 * Toggle asset semantics
 */
const toggleAssetSemantics = (action$) => action$.pipe(
    ofType(toggleAssetSemanticsRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.toggleAssetSemantics(payload)).pipe(
            concatMap((res) => of(
                toggleAssetSemanticsSuccess({ data: res?.data?.data ?? {} }),
                updateAssetSemanticEnabled({ data: res?.data?.data ?? {} })
            )),
            catchError(() => of(toggleAssetSemanticsFailure()))
        );
    })
);


/**
 * Toggle asset advanced profiling
 */
const toggleAssetAdvancedProfiling = (action$) => action$.pipe(
    ofType(toggleAssetAdvancedProfileRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.toggleAssetAdvancedProfile(payload)).pipe(
            concatMap((res) => of(
                toggleAssetAdvancedProfileSuccess({ data: res?.data?.data ?? {} })
            )),
            catchError(() => of(toggleAssetAdvancedProfileFailure()))
        );
    })
);

/**
 * Update Attribute Metadata
 * @param {*} action$
 * @returns
 */
const updateTermMetadata = (action$) => action$.pipe(
    ofType(updateAttributeTermRequest),
    mergeMap(({ payload: { attribute_id, ...rest } }) => {
        return from(attributeService.updateMetadata(attribute_id, rest)).pipe(
            concatMap(() => {
                return of(updateAttributeTermSuccess());
            }),
            catchError((error) => of(updateAttributeTermFailure(), displyAlert({ 'type': 'error', 'message': error })))
        );
    })
);

/**
 * Get Attribute Statistics Preview
 * @returns
 */
const getAttributeStatisticsPreview = (action$) => action$.pipe(
    ofType(getAttributeStatisticsPreviewRequest),
    mergeMap(({ payload }) => {
        return from(attributeService.getAttributeStatisticsPreview(payload)).pipe(
            concatMap((res) => { return of(getAttributeStatisticsPreviewSuccess({ data: res?.data ?? {} })); }),
            catchError(() => { return of(getAttributeStatisticsPreviewFailure()); })
        );
    })
);


// Export All Epic Functions
export const attributeEpic = [
    list, detail, update, updateMetadata, getAllAttributes, managePatterns, getInvalidRows,
    toggleActiveMeasure, getActiveMeasureCategories, getAllAttributesMetadata, toggleAssetSemantics,
    toggleAssetAdvancedProfiling, updateTermMetadata, getAttributeStatisticsPreview
];