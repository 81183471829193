import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

//  Import Components
import { ToggleComponent } from '../../components/index.js';
import Users from './users/index.jsx';
import Roles from './roles/index.jsx';
import UserActivity from './userActivity/index.jsx';

//  Import Styles
import SecurityStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Constants
import appConstants from '../../constants/appConstants.js';

// Import Reducer
import { getRolesListRequest } from '../../redux/reducer/securityReducer';

function Security(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [userActiveTab, setUserActiveTab] = useState(appConstants.toggle.usersAndRoles[0].value);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { roles } = useSelector((state) => state.security, shallowEqual);

    /**
     * Get Roles List
     */
    useEffect(() => {
        if (!roles || roles.length === 0) {
            dispatch(getRolesListRequest());
        }
    }, [dispatch]);

    /**
     * Get UserActivity Detail
     */
    useEffect(() => {
        if (location?.state?.tabname) {
            setUserActiveTab(location?.state?.tabname);
        }
    }, [location]);

    return (
        <Grid container className={classes.securityPageContainer}>
            <Grid item xs={12} className={classes.header} align="right">
                <ToggleComponent
                    data={appConstants.toggle.usersAndRoles}
                    selectedValue={userActiveTab}
                    handleChange={(selectedValue) => (selectedValue && selectedValue !== userActiveTab) && setUserActiveTab(selectedValue)} />
            </Grid>
            <Grid item xs={12}>
                {
                    userActiveTab === appConstants.toggle.usersAndRoles[0].value &&
                    <Users />
                }
                {
                    userActiveTab === appConstants.toggle.usersAndRoles[1].value &&
                    <Roles />
                }
                {
                    userActiveTab === appConstants.toggle.usersAndRoles[2].value &&
                    <UserActivity />
                }
            </Grid>
        </Grid>
    );
}

// default props
Security.defaultProps = {
    classes: {}
};

// prop types
Security.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...SecurityStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Security);