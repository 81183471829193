import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getUsageRequest, getUsageSuccess, getUsageFailure, runQueryRequest, runQuerySuccess, runQueryFailure, createUsageRequest,
  createUsageSuccess, createUsageFailure, updateUsageRequest, updateUsageSuccess, updateUsageFailure, removeUsageRequest,
  removeUsageSuccess, removeUsageFailure, getQueryHistoryRequest, getQueryHistorySuccess, getQueryHistoryFailure
} from '../reducer/usageReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { usageService } from '../service';

// Import Constants
import appConstants from '../../constants/appConstants';


/**
 * Get Usage Details
 * @returns
 */
const getUsage = (action$) => action$.pipe(
  ofType(getUsageRequest),
  mergeMap(({ payload }) => {
    return from(usageService.get_usage(payload)).pipe(
      concatMap((res) => { return of(getUsageSuccess(res)); }),
      catchError((error) => { return of(getUsageFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Run Query
 * @returns
 */
const run_query = (action$) => action$.pipe(
  ofType(runQueryRequest),
  mergeMap(({ payload }) => {
    return from(usageService.run_query(payload)).pipe(
      concatMap((res) => { return of(runQuerySuccess(res)); }),
      catchError((error) => { return of(runQueryFailure(error?.response?.data ?? "")); })
    );
  })
);

/**
 * Create
 * @param {*} action$
 * @returns
 */
const create = (action$) => action$.pipe(
  ofType(createUsageRequest),
  mergeMap(({ payload }) => {
    return from(usageService.create(payload)).pipe(
      concatMap((res) => { return of(createUsageSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.saved })); }),
      catchError((error) => { return of(createUsageFailure(), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

/**
 * Update
 * @param {*} action$
 * @returns
 */
const update = (action$) => action$.pipe(
  ofType(updateUsageRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(usageService.update(id, rest)).pipe(
      concatMap((res) => { return of(updateUsageSuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.saved })); }),
      catchError((error) => of(updateUsageFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Delete
 * @param {*} action$
 * @returns
 */
const remove = (action$) => action$.pipe(
  ofType(removeUsageRequest),
  mergeMap(({ payload }) => {
    return from(usageService.remove(payload)).pipe(
      concatMap(() => of(removeUsageSuccess(payload))),
      catchError((error) => of(removeUsageFailure(), displyAlert({ 'type': 'error', 'message': error })))
    );
  })
);

/**
 * Get Query History Based On Type
 * @returns
 */
const query_history = (action$) => action$.pipe(
  ofType(getQueryHistoryRequest),
  mergeMap(({ payload }) => {
    return from(usageService.query_history(payload)).pipe(
      concatMap((res) => { return of(getQueryHistorySuccess({ ...res, request_data: payload })); }),
      catchError((error) => { return of(getQueryHistoryFailure(payload), displyAlert({ 'type': 'error', 'message': error })); })
    );
  })
);

// Export All Usage Functions
export const usageEpic = [getUsage, run_query, create, update, remove, query_history];