import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assets: [],
    alerts: [],
    issues: [],
    isLoading: false
};

const extensionSlice = createSlice({
    name: 'extension',
    initialState: { ...initialState },
    reducers: {
        getAssetByExtensionRequest(state) {
            state.isLoading = true;
            state.assets = [];
            state.alerts = [];
            state.issues = [];
        },
        getAssetByExtensionSuccess(state, action) {
            state.assets = action.payload?.data?.assets ?? [];
            state.alerts = action.payload?.data?.alerts ?? [];
            state.issues = action.payload?.data?.issues ?? [];
            state.isLoading = false;
        },
        getAssetByExtensionFailure(state) {
            state.assets = [];
            state.alerts = [];
            state.issues = [];
            state.isLoading = false;
        },
        clearAssetByExtentionData(state) {
            state.assets = [];
            state.alerts = [];
            state.issues = [];
            state.isLoading = false;
        }
    }
});

export const { getAssetByExtensionRequest, getAssetByExtensionSuccess, getAssetByExtensionFailure, clearAssetByExtentionData } = extensionSlice.actions;
export default extensionSlice.reducer;