import React, { useRef, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';

//  Import Components
import LayoutStyles from '../../layouts/style.jsx';
import { SearchComponent, NavigationTabHeaderComponent } from '../../components/index.js';

//  Import Styles
import HomeStyle from './style.jsx';

// Import Images
import {
    AttributesIcon, CalendarIcon2, CommentsIcon, DangerIcon, HexgonIcon, IssuesIcon2, PipelineIcon, ReportsIcon, ScrollArrowIcon, TopicsIcon
} from '../../assets/svg/index.js';
import welcomeLogo from '../../assets/img/dq_logo.png';

// Import Constants
import appConstants from '../../constants/appConstants.js';
import featureConstants from '../../constants/featureConstants.js';

// Import Helpers
import { checkPermission, convertUTCtoLocalDateTime, permissionTabs } from '../../helpers';

// Import Reducer
import { catalogStatisticsRequest, catalogSearchUpdate, clearCatalogState } from '../../redux/reducer/catalogReducer';
import { navigate } from '../../redux/reducer/navigationReducer';


/**
 * Define Widget In Home Page
 */
const tileData = {
    "assets": {
        icon: <HexgonIcon />,
        color: '#B2DBF7',
        name: "Assets"
    },
    "attributes": {
        icon: <AttributesIcon />,
        color: '#FFD778',
        name: "Attributes"
    },
    "topics": {
        icon: <TopicsIcon />,
        color: '#F295B6',
        name: 'Topics'
    },
    "pipeline": {
        icon: <PipelineIcon />,
        color: '#85E8EF',
        name: 'Pipeline'
    },
    "reports": {
        icon: <ReportsIcon />,
        color: '#74DEA8',
        name: 'Reports'
    },
    "alerts": {
        icon: <DangerIcon />,
        color: '#FFAF97',
        name: 'Alerts'
    },
    "conversations": {
        icon: <CommentsIcon />,
        color: '#A691DE',
        name: 'Conversations'
    },
    "issues": {
        icon: <IssuesIcon2 />,
        color: '#A5DE91',
        name: 'Issues'
    }
};

function Home(props) {

    /**
     * Define Props
     */
    const { classes, scrollRef } = props;
    const tabContainerRef = useRef();
    const homContainerRef = useRef();
    const dispatch = useDispatch();
    const reactNavigate = useNavigate();
    const location = useLocation();
    const routeName = location.pathname.split('/');

    /**
     * Define State
     */
    const [searchKey, setSearchKey] = useState('');


    /**
     * Define UseEffect
     */
    useEffect(() => {
        dispatch(clearCatalogState());
        dispatch(catalogStatisticsRequest());
        if (tabContainerRef && tabContainerRef.current) {
            if (routeName.length >= 3) {
                tabContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
            } else {
                homContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
            }
        }
    }, [dispatch]);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const { stats, suggestions } = useSelector((state) => state.catalog, shallowEqual);
    const { logo } = useSelector((state) => state.configurations.theme, shallowEqual);
    const { permission } = useSelector((state) => state.auth);

    /**
     * Scroll To Page
     */
    const scrollToPage = () => {
        tabContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
    };

    /**
     * Handle Home Page Navigations
     * @param {*} key
     */
    const handleHomeNavigation = (key) => {
        const tileKey = key?.toLowerCase();
        if (tileKey === 'assets' || tileKey === 'attributes') {
            reactNavigate(`/catalog?search_by=${key}`, { replace: true });
        } else {
            reactNavigate(`/catalog?${key}=true`, { replace: true });
        }
    };

    /**
     * Handle Navigation Position
     */
    const onTabNavigationChange = () => {
        tabContainerRef.current.scrollIntoView({ behaviour: 'smooth' });
    };

    /**
     * Handle Search
     * @param {*} value
     */
    const hanldeSearchEvent = (value) => {
        setSearchKey(value);
    };

    /**
     * Handle Suggestion Click
     * @param {*} suggestion
     */
    const suggestionClickEvent = (value, type) => {
        if (type === 'suggestion') {
            dispatch(catalogSearchUpdate({ "search_key": value?.search_key ?? '' }));
            reactNavigate(`/catalog`, { replace: true });
        } else {
            switch (value?.type?.toLowerCase()) {
                case "asset":
                case "base table":
                case "external table":
                case "table":
                case "sheet":
                case "dashboard":
                case "report":
                case "reports":
                case "view":
                case "query":
                case "topic":
                case "pipeline":
                    dispatch(navigate({ path: 'assets.root', state: {}, params: [value.redirect_id] }));
                    break;
                default:
                    // Remove Version Id for Future
                    dispatch(navigate({ path: 'assets.attributeProperties', state: {}, params: [value.params?.asset_id || value.params?.version_id, value.redirect_id] }));
                    break;
            }
        }
    };

    const validateAction = (card) => {
        if (card === "attributes" && !checkPermission(permission, featureConstants.assets.properties)?.is_view) {
            return false;
        }
        return true;
    };

    return (
        <Grid container className={classes.landinPageContainer} ref={homContainerRef}>
            <Grid item xs={12} className={classes.landingBody}>
                <Grid container className={classes.landingBodyMain}>
                    <Grid item xs={12} className={classes.welcomeHeader}>
                        <Grid
                            container
                            justifyContent={'center'}
                            alignItems="center"
                            flexWrap={"nowrap"}
                        >
                            <Grid item className={classes.logoContainer}>
                                <img
                                    src={logo?.length > 0 ? logo : welcomeLogo}
                                    alt="Logo"
                                    className={classes.welcomeLogo}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h1"
                                    className="welcomeText"
                                >
                                    <span className="">
                                        {appConstants.labels.home.welcome}
                                    </span>
                                    {' '}
                                    <span className="">
                                        {user.first_name || ''}
                                        {' '}
                                        {user.last_name || ''}
                                    </span>
                                    <Tooltip title={`Last Login: ${user && convertUTCtoLocalDateTime(user.last_login)}`} arrow>
                                        <IconButton className="calendarIcon">
                                            <CalendarIcon2 />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className={`fs-16 fw-500 exploreText`}
                                >
                                    Explore
                                    {' '}
                                    <Link to="/catalog">
                                        assets
                                    </Link>
                                    {' '}
                                    or
                                    {' '}
                                    <Link to="/semantics">
                                        semantics
                                    </Link>
                                </Typography>
                                <Grid item className="homeSearchBox">
                                    <SearchComponent
                                        placeholder={appConstants.labels.home.typeAnyKeywords}
                                        value={searchKey}
                                        showSuggestion
                                        suggestions={suggestions}
                                        onEnterKeyPress={(value, type) => suggestionClickEvent(value, type)}
                                        suggestionClickEvent={(value, type) => suggestionClickEvent(value, type)}
                                        onChange={(value) => hanldeSearchEvent(value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.welcomeDataCards}>
                        <Grid item container spacing={6}>
                            {
                                Object.keys(stats).map((key) => (
                                    <Grid
                                        item
                                        xs={3}
                                        key={key}
                                        className="cardItem"
                                    >
                                        <Grid className={`cardItemBody ${validateAction(key) ? "" : "eventNone"}`} onClick={() => validateAction(key) && handleHomeNavigation(key)}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    className="cardIcon"
                                                    sx={{ background: `${tileData[key]?.color}` }}
                                                >
                                                    {tileData[key]?.icon}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className="textSection"
                                                >
                                                    <Typography
                                                        variant="h4"
                                                        className="cardText fs-600"
                                                    >
                                                        {stats[key] || 0}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="fw-400"
                                                    >
                                                        {tileData[key]?.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <IconButton className={classes.scrollToNext} onClick={scrollToPage}>
                    <ScrollArrowIcon />
                </IconButton>
            </Grid>
            {
                permissionTabs(permission, appConstants.tabs.homeTabs)?.length > 0 &&
                <Grid item xs={12} ref={tabContainerRef} className={classes.tabsSection}>
                    <Grid
                        container
                        wrap="nowrap"
                        className={classes.tabHeaderContainer}
                        id="tabs"
                    >
                        <NavigationTabHeaderComponent
                            tabList={permissionTabs(permission, appConstants.tabs.homeTabs)}
                            onNavigationChange={() => onTabNavigationChange()}
                        />
                    </Grid>
                    <Grid className={classes.tabsBodySection}>
                        <Grid className={classes.tabsBody}>
                            <Outlet context={scrollRef} />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

// default props
Home.defaultProps = {
    classes: {}
};

// prop types
Home.propTypes = {
    classes: PropTypes.object,
    scrollRef: PropTypes.instanceOf(HTMLDivElement)
};

export default withStyles(
    (theme) => ({
        ...HomeStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Home);