import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

//  Import Components
import DetailCard from '../../components/detailCard/index.jsx';
import { NavigationTabHeaderComponent, UsersComponent } from '../../../../components/index.js';

//  Import Styles
import DomainDetailStyle from '../style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Images
import { DocumentIcon } from '../../../../assets/svg/index.js';

// Import Actions
import { getTagByIdRequest, updateTagRequest, clearTagDetails } from '../../../../redux/reducer/tagsReducer.js';
import { getUsersThumbnailRequest } from '../../../../redux/reducer/userReducer.js';

// Import Constants
import appConstants from '../../../../constants/appConstants.js';
import featureConstants from '../../../../constants/featureConstants.js';

// Import Helpers
import { checkPermission } from '../../../../helpers/appHelpers.js';


function TagDetail(props) {

    /**
     * Destructure Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * get Domain ID from URL
     */
    const { id: tag_id } = useParams();

    /**
     * Get Domain Details from Reducer
     */
    const { detail } = useSelector((state) => state.tags);
    const { thumbnail_list: usersList } = useSelector((state) => state.user);
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);

    /**
     * Get Tag Attributes Request
     * @params {uuid} attribute_id
     */
    useEffect(() => {
        if (tag_id) {
            dispatch(getTagByIdRequest(tag_id));
        }
        return () => {
            dispatch(clearTagDetails());
        };
    }, [tag_id]);

    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, [dispatch]);

    /**
     * Handle Add User
     * @param {*} property
     * @param {*} value
     */
    const handleAddUserChange = (value) => {
        value = value.map((user) => user.id);
        dispatch(updateTagRequest({ ...detail, "users": value }));
    };

    /**
     * Filter Mapped Users
     * @param {*} usersList
     * @param {*} users
     * @returns
     */
    const getUsers = (usersList, users) => {
        return usersList.filter((user) => users.includes(user.id));
    };
    const users = useMemo(() => getUsers(usersList, detail.users || []), [usersList, detail.users || []]);


    return (
        <Grid container spacing={6} className={classes.DomainDetailPageContainer}>
            <Grid item xs={5} className={classes.leftContent}>
                <Grid>
                    <Typography variant="h3" className="domainDetailTitle">
                        {detail?.name || ""}
                    </Typography>
                    <Typography variant="subtitle1">
                        {detail?.description || ""}
                    </Typography>
                </Grid>
                <Grid>
                    <Grid container>
                        <Grid className="dflex alignCenter mt-2 mr-2">
                            <DocumentIcon />
                            <Typography variant="h6" className="ml-1">
                                {`${detail?.attributes || 0} Attributes`}
                            </Typography>
                        </Grid>
                        <Grid className="dflex alignCenter mt-2">
                            <DocumentIcon />
                            <Typography variant="h6" className="ml-1">
                                {`${detail?.measures || 0} Measures`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="mt-2">
                        <UsersComponent
                            list={usersList}
                            data={users || []}
                            isSteward={false}
                            isTableComponent
                            handleStewardUser={(users) => handleAddUserChange(users)}
                            disabled={!semanticsPermission?.is_edit}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={7}>
                <DetailCard data={detail} type="tag" />
            </Grid>
            <Grid item xs={12} className={classes.tabsSection}>
                <Grid className={classes.tabsHeaderSection} container wrap="nowrap">
                    <NavigationTabHeaderComponent
                        tabList={appConstants.tabs.tagsDetailTabs}
                    />
                </Grid>
                <Grid className={classes.tabsBodySection}>
                    <Grid className={classes.tabsBody}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
TagDetail.defaultProps = {
    classes: {}
};

// prop types
TagDetail.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...DomainDetailStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(TagDetail);