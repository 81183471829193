// Import API Helper
import { postRequest, getRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Login
 */
async function login(params) {
    const response = await postRequest(urlConstants.auth.login, params);
    return response;
}

/**
 * Get SSO Validate
 */
async function get_user_info() {
    const response = await getRequest(`${urlConstants.security.get_user_info}`, null);
    return response;
}

/**
 * Forgot Password
 */
async function forgotPassword(params) {
    const response = await postRequest(urlConstants.auth.forgotPassword, params);
    return response;
}

/**
 * Reset Password
 */
async function resetPassword(params) {
    const response = await postRequest(urlConstants.auth.resetPassword, params);
    return response;
}

/**
 * Change Password
 */
async function changePassword(params) {
    const response = await postRequest(urlConstants.auth.changePassword, params);
    return response;
}

/**
 * Logout
 * @returns
 */
async function logout() {
    const response = await getRequest(urlConstants.user.logout);
    return response;
}

/**
 * Get User Permission
 */
async function permission(id) {
    const response = await getRequest(`${urlConstants.security.user_permission}${id}/`);
    return response;
}

/**
 * Export All Authentication Service Functions
 */
export const authService = {
    login, get_user_info, forgotPassword, resetPassword, logout, permission, changePassword
};