const style = () => ({
    DomainDetailPageContainer: {
        paddingTop: 10,
        '& .domainDetailTitle': {
            paddingBottom: 15
        },
        '@media (min-width: 1536px)': {
            '& .MuiGrid-grid-xl-2': {
                maxWidth: '20%',
                flexBasis: '20%'
            }
        }
    },
    leftContent: {
        paddingRight: 50,
        display: 'flex',
        flexDirection: 'column !important',
        // justifyContent: 'space-between',
        textAlign: 'left'
    },
    domainsPageContainer: {
        paddingBotton: '32px !important',
        '& .loaderContainer': {
            paddingLeft: '32px'
        }
    },
    tabsRootContainer: {
        background: '#fff',
        padding: '24px 30px 30px',
        '& .btnTabsInline  .MuiTab-root': {
            marginRight: '1px',
            borderRadius: '0px'
        },
        '& .btnTabsInline.MuiTabs-root': {
            minHeight: '30px'
        },
        '& .viewContainer': {
            padding: '0px !important'
        },
        '& .cardsSection': {
            textAlign: 'left',
            '& .loaderContainer': {
                paddingLeft: '24px'
            }
        },
        '& .collapseTableContainer': {
            maxHeight: 'calc(100vh - 160px)',
            overflowY: 'auto',
            '& .collapseTableContainer': {
                height: 'auto !important',
                overflowY: 'auto'
            }
        },
        '& .tableHeaderGrid': {
            marginBottom: '0px !important'
        }
    },
    tabsHeaderSection: {
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    kanbanViewRoot: {
        height: 'calc(100vh - 200px)',
        overflow: 'auto'
    }
});
export default style;