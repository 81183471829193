/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Grid, Typography, IconButton, MenuItem, Rating } from '@mui/material';
import _ from 'lodash';
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

// Import Helpers
import { stringAvatar } from '../../../helpers/appHelpers.js';

// Import Components
import { PopOverComponent, RichTextEditorComponent } from '../../../components/index.js';

// Import Images
import { MoreIcon } from '../../../assets/svg/index.js';

//  Import Styles
import LayoutStyles from '../../../layouts/style.jsx';
import Style from './style.jsx';


function ReplyComponent(props) {

    /**
     * Define Props
     */
    const { classes, reply, handleDeleteMessage, attributes, users_list, handleCreateMessage, permission } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [editCommentState, setEditCommentState] = useState(false);


    /**
     * Handle Pop Over Open
     * @param {*} event
     */
    const onPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Handle Pop Over Close
     */
    const onPopoverClose = () => {
        setAnchorEl(null);
    };


    /**
     * Handle Conver to Html Event
     */
    const convertHtml = (content) => {
        const contentState = convertFromRaw(JSON.parse(content));
        const options = {
            entityStyleFn: (entity) => {
                const entityType = entity.get("type").toLowerCase();
                if (entityType === "mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "mention",
                        content: mention.id,
                        attributes: {
                            userid: mention.id
                        }
                    };
                }
                if (entityType === "#mention") {
                    const { mention } = entity.get("data");
                    return {
                        element: "asset",
                        attributes: {
                            userid: mention.userId
                        }
                    };
                }
            }
        };
        return stateToHTML(contentState, options);
    };

    /**
     * Handle Comment Event
     * @param {*} id
     */
    const deleteComment = (id, parentId) => {
        setAnchorEl(null);
        handleDeleteMessage(id, parentId);
    };

    /**
     * Handle Edit Pop
     */
    const editComment = () => {
        setAnchorEl(null);
        setEditCommentState(true);
    };

    /**
     * on cancel edit
     */
    const onCancelEdit = () => {
        setEditCommentState(false);
    };

    /**
     * Handle Form Submit Event
     * @param {*} thread_id
     */
    const handleSubmit = (formData) => {
        formData.thread = reply.thread;
        formData.isReplyEdit = true;
        setEditCommentState(false);
        handleCreateMessage(formData);
    };

    return (
        <Grid className={classes.replyRoot}>
            {
                editCommentState ?
                    <RichTextEditorComponent key={reply.id}
                        formSubmit={handleSubmit}
                        attributes={attributes}
                        users_list={users_list}
                        isCancel
                        current_data={reply}
                        onCancel={onCancelEdit}
                        formFields={{ showRating: true }} />
                    :
                    <Grid className="replyContainer">
                        <Grid className="dflex spaceBetween alignBaseline">
                            <Typography variant="body1" className="replycommentText">
                                <div className="commentText" dangerouslySetInnerHTML={{ __html: convertHtml(reply.comment) }} />
                            </Typography>
                            {
                                permission?.is_edit &&
                                <IconButton className="p5 ml-1" onClick={onPopoverOpen}>
                                    <MoreIcon />
                                </IconButton>
                            }
                        </Grid>
                        <Grid className="dflex">
                            <Grid item className={`${classes.nameAvatar} nameAvatarReply mt0`}>
                                <Avatar src={reply.created_by.avatar || null}
                                    {...stringAvatar(reply?.updated_by?.name ?? reply.created_by.name)} />
                                <Typography variant="body1" className={`${classes.nameText} ml-2`}>
                                    {`${reply.created_by.name || ""}`}
                                </Typography>
                                <Typography variant="body1" className={`${classes.textSecondary} ml-3 mr-3`}>
                                    {(moment(reply.created_date).fromNow())}
                                </Typography>
                                <Rating className={`${classes.starIcon}`} name="half-rating-read" value={reply.rating || 0} precision={0.5} size="small" readOnly />
                            </Grid>
                        </Grid>
                        <PopOverComponent anchorEl={anchorEl} onClose={onPopoverClose}>
                            <Grid className={classes.popoverDiv}>
                                {
                                    permission?.is_edit &&
                                    <MenuItem onClick={() => editComment(reply.id)}>
                                        Edit
                                    </MenuItem>
                                }
                                {
                                    permission?.is_edit &&
                                    <MenuItem onClick={() => deleteComment(reply.id, reply.thread)}>
                                        Delete
                                    </MenuItem>
                                }
                            </Grid>
                        </PopOverComponent>
                    </Grid>
            }
        </Grid>

    );
}

// default props
ReplyComponent.defaultProps = {
    classes: {},
    reply: {},
    handleDeleteMessage: () => { },
    attributes: [],
    users_list: [],
    handleCreateMessage: () => { },
    permission: {}
};

// prop types
ReplyComponent.propTypes = {
    classes: PropTypes.object,
    reply: PropTypes.object,
    handleDeleteMessage: PropTypes.func,
    attributes: PropTypes.array,
    users_list: PropTypes.array,
    handleCreateMessage: PropTypes.func,
    permission: PropTypes.object
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.reply, nextProps.reply) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme),
        ...Style(theme)
    }),
    { withTheme: true }
)(React.memo(ReplyComponent, areEqual));