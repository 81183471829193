
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrag } from 'react-dnd';

//  Import Components
import { Grid, Typography } from '@mui/material';

//  Import Styles
import LayoutStyles from '../../../../../layouts/style.jsx';


function DragBox(props) {
    const { name, type } = props;
    const Box = () => {
        const [, drag] = useDrag(
            () => ({
                type,
                item: { name },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging()
                })
            }),
            [name, type]
        );
        return (
            <Grid className="p-1 dragItem" disablePadding ref={drag} data-testid={type}>
                <Typography variant="body1">
                    <span className={"assetTypeIcon"}>
                        #
                    </span>
                    {name}
                </Typography>
            </Grid>
        );
    };

    return (
        Box()
    );
}

// default props
DragBox.defaultProps = {
    classes: {}
};

// prop types
DragBox.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(DragBox);