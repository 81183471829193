const style = (theme) => ({
    lookupContainer: {
        background: '#fff',
        padding: 24,
        '& .greyInput .MuiOutlinedInput-input, .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '6px 10px'
        },
        '& .greyInput .MuiTypography-root': {
            background: theme.palette.greyshades.lightbackground,
            padding: '2px 10px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '7.5px 14px'
        },
        '& .chipContainer': {
            '& .MuiInput-input': {
                padding: '0px 4px 0px 0px !important'
            }
        }
    },
    boxLabelContainer: {
        position: 'relative',
        background: '#efe9e9',
        padding: 10,
        top: 3,
        borderRadius: 10,
        marginRight: 10,
        fontSize: 12
    },
    boxHiddenLabelContainer: {
        width: 50
    }
});
export default style;