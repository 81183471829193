import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Import Components
import { SwitchComponent, TextBoxComponent, LoaderComponent } from '../../../../components/index.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ConnectorsIcon from '../../../../components/connectorsIcon/index.jsx';

// Image Images
import { CloseIcon, CopyIcon, ShareIcon } from '../../../../assets/svg/index.js';

//Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../../../layouts/style.jsx';

// Import Actions
import { shareRequest, updateShareDetails, clearShareDetails } from '../../../../redux/reducer/versionReducer';
import { displyAlert } from '../../../../redux/reducer/alertReducer';

// Import Constants
import appConstants from '../../../../constants/appConstants';

function AssetShare(props) {

    /**
     * Define Props
     */
    const { classes, data, takeScreenshot } = props;
    const dispatch = useDispatch();
    const link = window.location.href;

    /**
     * Redux
     */
    const shareDetails = useSelector((state) => state.version.shareDetails, shallowEqual);

    /**
     * Define state
     */
    const [shareContainer, openShareContainer] = useState(false);

    /**
     * Update Share Details
     */
    useEffect(() => {
        dispatch(updateShareDetails({ link }));
    }, [dispatch, link]);

    /**
     * Handle Share
     */
    const handleShare = () => {
        takeScreenshot();
        openShareContainer(true);
    };

    /**
     * Handle Close
     */
    const handleClose = () => {
        openShareContainer(false);
        dispatch(clearShareDetails());
    };

    /**
     * On Change
     * @param {*} property
     * @param {*} value
     */
    const onChange = (property, value) => {
        dispatch(updateShareDetails({ [property]: value }));
    };

    /**
     * Channels On Change
     * @param {*} property
     * @param {*} value
     */
    const onChennalChange = (type) => {
        let channels = [...shareDetails.channels];
        if (channels.indexOf(type) > -1) {
            channels = channels.filter((item) => item !== type);
        } else {
            channels.push(type);
        }

        dispatch(updateShareDetails({ "channels": channels }));
    };

    /**
     * Share Link
     */
    const share = () => {
        const requestData = { ...shareDetails };
        if (requestData.base64) {
            delete requestData.base64;
        }
        dispatch(shareRequest({ ...requestData, 'asset': data.asset_id }));
        handleClose();
    };

    /**
     * Copy Connection Path to Keyboard
     */
    const copyToClipBoard = () => {
        navigator.clipboard.writeText(shareDetails.link);
        dispatch(displyAlert({ type: 'success', message: 'copied!' }));
    };

    return (
        <Fragment>
            <Tooltip title="Share">
                <span>
                <IconButton className="p5 ml-1" onClick={handleShare} disabled={!data.asset_id}>
                    <ShareIcon />
                </IconButton>
                </span>
            </Tooltip>
            <Dialog
                open={shareContainer}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.shareDialog}
            >
                <ValidatorForm onSubmit={() => share()}>
                    <Grid container spacing={5} className={classes.shareContainer}>
                        <Grid item xs={12} className="dflex spaceBetween w-100">
                            <Grid>
                                <Typography variant="h6">
                                    Share Asset
                                </Typography>
                                <Typography variant="body1" className={classes.textSecondary}>
                                    {data.name || ""}
                                </Typography>
                            </Grid>
                            <IconButton className="closeButton" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className="pt-1">
                            {
                                shareDetails.base64?.length > 0
                                    ? <img src={shareDetails?.base64} className={classes.shareContainerImg} alt="shareimg" />
                                    : <LoaderComponent loaderType="attachment" height={200} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.textSecondary}>
                                To
                            </Typography>
                            <Grid className="mt-1">
                                <TextBoxComponent
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Name or Email
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    validators={['required']}
                                    value={shareDetails?.name ?? ''}
                                    errorMessages={[appConstants.errorMessages.valueRequired]}
                                    onChange={(event) => onChange("email", event.target.value)}
                                />
                            </Grid>
                            <Grid className="mt-2">
                                <TextBoxComponent
                                    variant="standard"
                                    label={
                                        <span className="requiredLabel">
                                            Message
                                            <span className="requiredstar">
                                                *
                                            </span>
                                        </span>
                                    }
                                    multiline
                                    value={shareDetails?.message ?? ''}
                                    validators={['required']}
                                    errorMessages={[appConstants.errorMessages.messageRequired]}
                                    onChange={(event) => onChange("message", event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="dflex">
                            <Grid>
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('email')}
                                    checked={shareDetails.channels?.indexOf('email') > -1 || false}
                                    label="Email"
                                />
                            </Grid>
                            <Grid className="ml-4">
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('slack')}
                                    checked={shareDetails.channels?.indexOf('slack') > -1 || false}
                                    label="Slack"
                                />
                            </Grid>
                            <Grid className="ml-4">
                                <SwitchComponent size="small"
                                    handleChange={() => onChennalChange('teams')}
                                    checked={shareDetails.channels?.indexOf('teams') > -1 || false}
                                    label="Team"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="dflex spaceBetween alignCenter">
                            <Grid item className={classes.copyDatasetShare}>
                                <Grid className={`datasetIcon ${data.type || ''}`}>
                                    <ConnectorsIcon
                                        connection_type={data.type}
                                    />
                                </Grid>
                                <Typography variant="body1" className="pr-15" noWrap>
                                    {shareDetails.link}
                                </Typography>
                                <Grid className="copyIcon" onClick={() => copyToClipBoard()}>
                                    <CopyIcon />
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                type="submit"
                                disabled={!shareDetails.base64 || !shareDetails.channels.length}
                                disableElevation
                            >
                                Share
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Dialog>
        </Fragment>
    );
}

// default props
AssetShare.defaultProps = {
    classes: {},
    data: {},
    takeScreenshot: () => { }
};

// prop types
AssetShare.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    takeScreenshot: PropTypes.func
};


export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AssetShare);