/* eslint no-underscore-dangle: 0 */

'use strict';
import TableNode from './canvas/node';
import Edge from './canvas/edge';
import _ from 'lodash';

// Initialize data conversion
export const transformInitData = (data) => {
  const {
    tables, relations, columns, operator,
    _titleRender, _enableHoverChain, _emptyContent, _emptyWidth
  } = data;

  const result = {
    nodes: tables.map((item) => {
      return _.assign({
        Class: TableNode,
        _columns: columns,
        _emptyContent: _emptyContent,
        _operator: operator,
        _titleRender,
        _enableHoverChain,
        _emptyWidth
      }, item);
    }),
    edges: relations.map((item) => {
      return {
        id: item.id || `${item.srcTableId}-${item.tgtTableId}-${item.srcTableColName}-${item.tgtTableColName}`,
        type: 'endpoint',
        sourceNode: item.srcTableId,
        targetNode: item.tgtTableId,
        source: (item.srcTableColName !== undefined && item.srcTableColName !== null) ? item.srcTableColName : item.srcTableId + '-right',
        target: (item.tgtTableColName !== undefined && item.tgtTableColName !== null) ? item.tgtTableColName : item.tgtTableId + '-left',
        _isNodeEdge: (item.srcTableColName === undefined || item.srcTableColName === null) && (item.tgtTableColName === undefined || item.tgtTableColName === null),
        Class: Edge
      };
    })
  };
  return result;
};

// Since expansion and contraction will affect the line segments, the line segments need to be converted.
export const transformEdges = (nodes, edges) => {

  edges.forEach((item) => {
    if (!item._isNodeEdge) {
      item.source += '-right';
      item.target += '-left';
    }
  });

  nodes.forEach((node) => {
    if (node.isCollapse) {
      const sourceEdges = edges.filter((item) => {
        return node.id === item.sourceNode;
      });
      sourceEdges.forEach((item) => {
        item.source = `${node.id}-right`;
        item.sourceCollaps = true;
      });
      const targetEdges = edges.filter((item) => {
        return node.id === item.targetNode;
      });
      targetEdges.forEach((item) => {
        item.target = `${node.id}-left`;
        item.targetCollaps = true;
      });
    }
  });

  const edgesObj = {};
  const realEdges = [];

  edges.forEach((item) => {

    const existObj = edgesObj[`${item.sourceNode}-${item.source}-${item.targetNode}-${item.target}`];

    if (existObj) {
      _.assign(existObj, item);
    } else {
      edgesObj[`${item.sourceNode}-${item.source}-${item.targetNode}-${item.target}`] = item;
    }
  });

  for (const key in edgesObj) {
    realEdges.push(edgesObj[key]);
  }

  return {
    nodes,
    edges: realEdges
  };
};

export const diffPropsData = (newData, oldData) => {
  const isSameNode = (a, b) => a.id === b.id;
  const addNodes = _.differenceWith(newData.nodes, oldData.nodes, isSameNode);
  const rmNodes = _.differenceWith(oldData.nodes, newData.nodes, isSameNode);

  const isSameEdge = (a, b) => {
    return (
      a.sourceNode === b.sourceNode &&
      a.targetNode === b.targetNode &&
      a.source === b.source &&
      a.target === b.target
    );
  };

  const addEdges = _.differenceWith(newData.edges, oldData.edges, isSameEdge);
  const rmEdges = _.differenceWith(oldData.edges, newData.edges, isSameEdge);

  const isCollapseNodesChange = (a, b) => a.id === b.id && a.isCollapse === b.isCollapse;
  let collapseNodes = _.differenceWith(newData.nodes, oldData.nodes, isCollapseNodesChange);
  collapseNodes = _.differenceWith(collapseNodes, addNodes, isSameNode);

  return {
    addNodes,
    rmNodes,
    addEdges,
    rmEdges,
    collapseNodes
  };
};

export const updateCanvasData = (newNodes, oldNodes) => {
  oldNodes.forEach((item) => {
    const newNode = _.find(newNodes, (_item) => _item.id === item.id);
    _.assign(item.options, newNode);
  });
};

export const diffActionMenuData = (newMenu, oldMenu) => {
  const isSameMenu = (a, b) => a.key === b.key;
  const addMenu = _.differenceWith(newMenu, oldMenu, isSameMenu);
  const rmMenu = _.differenceWith(oldMenu, newMenu, isSameMenu);
  return addMenu.length !== 0 || rmMenu.length !== 0;
};