import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//  Import Components
import { Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextComponent, UsersComponent } from '../../../../../../components/index.js';
import ImageCropper from '../../../../../../components/imageCrop/ImageCropper.jsx';

//  Import Styles
import Style from '../../style.jsx';
import LayoutStyles from '../../../../../../layouts/style.jsx';

// Import Images
import { MoreIcon, TermIcon, CameraIcon } from '../../../../../../assets/svg/index.js';
import PlaceholderImg from '../../../../../../assets/img/placeholder.png';

// Reducer
import { deleteCategoryRequest, deleteTermRequest, toggleAddGlossary, selectCategory, updateCategoryRequest, updateTermRequest, updateGlossaryImageRequest } from '../../../../../../redux/reducer/semanticReducer';
import { navigate } from '../../../../../../redux/reducer/navigationReducer.js';
import { getUsersThumbnailRequest } from '../../../../../../redux/reducer/userReducer.js';

// Import Constant
import { checkPermission } from '../../../../../../helpers/appHelpers.js';
import featureConstants from '../../../../../../constants/featureConstants.js';


function GlossaryDetailCard(props) {
    /**
     * Define Props
     */
    const { classes, data, index } = props;
    const dispatch = useDispatch();
    const formRef = useRef();
    const { state } = useLocation();
    const glossary = state.glossary || {};
    const category = state.category || {};

    // Initial State
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEdited, setEdited] = useState(false);
    const [glossaryDetail, setGlossaryDetail] = useState({});
    const [imageToCrop, setImageToCrop] = useState('');
    const [openImageDialog, setImageDialog] = useState(null);
    const [uploadImageUrl, setUploadImageUrl] = useState(null);

    /**
     * Redux Store
     */
    const { permission } = useSelector((state) => state.auth);
    const semanticsPermission = checkPermission(permission, featureConstants.settings.semantics);
    const { thumbnail_list: usersList } = useSelector((state) => state.user);


    /**
     * Handle Click
     * @description Handle More Menu Click Event
     * @param {*} event
     */
    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //img upload
    const handleImgUpload = () => {
        document.getElementById(`domainImage_${index}`).click();
    };


    /**
     * Handle More Menu Close
     */
    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    const onEditGlossary = () => {
        setEdited(true);
        handleMoreClose();
    };

    /**
     * Delete Category
     */

    const onDelete = () => {
        handleMoreClose();
        if (data.type === "category") {
            dispatch(deleteCategoryRequest(data.id));
        } else {
            dispatch(deleteTermRequest(data.id));
        }

    };

    /**
     * Trigger Submit
     */
    const onTriggerSubmit = () => {
        formRef.current.submit();
    };

    /**
     * Handle image selection
     * @param {*} event
     */
    const handleSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => setImageToCrop(reader.result.toString() || ''),
                setImageDialog(true)
            );
            reader.readAsDataURL(event.target.files[0]);
        }
        if (event?.target?.value) {
            event.target.value = null;
        }
    };

    /**
     * Update Crop Image
     * @param {*} croppedImage
     */
    const updateCropImage = (croppedImage, upload_image_url) => {
        dispatch(updateGlossaryImageRequest({ id: data.id, image: croppedImage }));
        setUploadImageUrl(upload_image_url);
    };


    /**
     * Save Glossary
     */
    const onSaveGlossary = (data) => {
        const requestData = data || glossaryDetail;
        const requestParams = {
            ...requestData
        };
        if (requestParams.type === "category") {
            dispatch(updateCategoryRequest(requestParams));
        } else {
            const name = requestParams.technical_name.split('(').slice(1).join('.');
            requestParams.technical_name = `${requestParams.name}(${name}`;
            dispatch(updateTermRequest(requestParams));
        }
        if (!data) {
            setEdited(false);
        }
    };

    /**
     * Handle OnChange
     * @param {*} name
     * @param {*} value
     */
    const onChange = (name, value) => {
        if (name === "users") {
            value = value.map((user) => user.id);
            onSaveGlossary({ ...glossaryDetail, users: value });
        }
        setGlossaryDetail((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Add New Term
     */
    const addNewTerm = () => {
        dispatch(toggleAddGlossary("term_detail"));
        handleMoreClose();
        dispatch(selectCategory(data));
    };

    /**
     * Handle Navigation
     */
    const navigation = () => {
        if (data.type === "category") {
            dispatch(navigate({ path: 'settings.semantics.domains.category', state: { category: data, glossary }, params: [data.glossary_id, data.id] }));
        } else {
            dispatch(navigate({ path: 'settings.semantics.domains.term', state: { category: category, glossary: glossary }, params: [data.id] }));
        }
    };

    /**
     * Set Default Data
     */
    useEffect(() => {
        setGlossaryDetail({ ...data });
    }, []);

    /**
     * Get List of Users
     */
    useEffect(() => {
        if (!usersList || usersList.length === 0) {
            dispatch(getUsersThumbnailRequest());
        }
    }, []);

    /**
     * Filter Mapped Users
     * @param {*} users
     * @param {*} userList
     * @returns
     */
    const getUsers = (users, userList = []) => {
        const usersData = typeof (userList) === "string" ? JSON.parse(userList) : userList;
        return users.filter((user) => usersData.includes(user.id));
    };
    const users = useMemo(() => getUsers(usersList, data.users || []), [usersList, data.users || []]);

    return (
        <Grid item xs={4} lg={4} xl={2} onMouseLeave={() => isEdited && onTriggerSubmit()}>
            <ValidatorForm className="h-100" onSubmit={() => onSaveGlossary()} ref={formRef}>
                <Grid className={data.type === "category" ? classes.subDomainCard : classes.domainCard}>
                    {
                        data.type === "category" ?
                            <Grid item className={`${classes.subDomainImgContainer} ${data.image ? "uploadedImg" : "defaultImg"}`} onClick={() => navigation()}>
                                <img src={uploadImageUrl || data.image ? `${data.image}?ts=${Date.now()}` : PlaceholderImg} alt="DomainCard img" />
                            </Grid> :
                            <Grid item className={classes.imgContainer} align="center">
                                <TermIcon />
                            </Grid>
                    }
                    <Grid item className={data.type === "category" ? classes.subDomaindetail : classes.detail}>
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={data.type === "category" ? 'space-between' : 'center'}
                            className="pb-1"
                            wrap="nowrap"
                        >
                            {
                                isEdited ?
                                    <TextComponent
                                        className={data.type === "category" ? classes.subDomainTitle : classes.title}
                                        noOutline
                                        placeholder="Enter Name"
                                        variant="standard"
                                        name="name"
                                        value={glossaryDetail.name || ""}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        validators={['required']}
                                        errorMessages={["Name is required"]}
                                    /> :
                                    <Typography variant="h5" className={classes.navigateText} onClick={() => navigation()}>
                                        {data.name || ""}
                                    </Typography>
                            }
                            <Grid className="dflex alignCenter">
                                <input
                                    accept="image/*"
                                    className={classes.domainImgInput}
                                    id={`domainImage_${index}`}
                                    type="file"
                                    onChange={handleSelectFile}
                                />
                                {
                                    openImageDialog && (
                                        <ImageCropper
                                            open={openImageDialog}
                                            onClose={() => setImageDialog(false)}
                                            imageToCrop={imageToCrop}
                                            aspect={16 / 9}
                                            onImageCropped={
                                                (croppedImage, croppedImageUrl) =>
                                                    updateCropImage(croppedImage, croppedImageUrl)
                                            }
                                        />
                                    )
                                }
                                {
                                    semanticsPermission?.is_edit && data.type === "category" &&
                                    <Tooltip title="Upload image" arrow>
                                        <IconButton
                                            className="p2 mr5 cameraIcon"
                                            onClick={() => handleImgUpload()}
                                        >
                                            <CameraIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    !isEdited && semanticsPermission?.is_edit && data.type === "category" &&
                                    <Tooltip title="More" arrow>
                                        <IconButton className="p0" onClick={(event) => handleMoreClick(event)}>
                                            <MoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    data.type !== "category" &&
                                    <Grid className="dflex alignCenter moreIcon">
                                        {
                                            !isEdited && semanticsPermission?.is_edit &&
                                            <Tooltip title="More" arrow>
                                                <IconButton className="p0" onClick={(event) => handleMoreClick(event)}>
                                                    <MoreIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                        {
                            isEdited ?
                                <TextComponent
                                    className={data.type === "category" ? classes.subDomainDescription : classes.description}
                                    placeholder="Enter Description"
                                    name="description"
                                    fullWidth
                                    multiline
                                    variant="standard"
                                    rows={3}
                                    value={glossaryDetail.description || ""}
                                    onChange={(event) => onChange(event.target.name, event.target.value)} />
                                :
                                <Typography
                                    onClick={() => navigation()}
                                    variant="body1"
                                    // align="center"
                                    className={`descriptionText`}
                                >
                                    {data.description}
                                </Typography>
                        }
                        {
                            data.type === "category" &&
                            <Grid className="mt8">
                                <UsersComponent
                                    list={usersList}
                                    data={users || []}
                                    // disabled={!isEdited}
                                    isSteward={false}
                                    inline
                                    handleStewardUser={(stewards) => onChange("users", stewards)}
                                />
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                Boolean(anchorEl) &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMoreClose}
                    MenuListProps={
                        {
                            'aria-labelledby': 'basic-button'
                        }
                    }
                >
                    {
                        data.type === "category" && semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => addNewTerm()}>
                            Add Term
                        </MenuItem>
                    }
                    {
                        semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => onEditGlossary()}>
                            Edit
                        </MenuItem>
                    }
                    {
                        semanticsPermission?.is_edit &&
                        <MenuItem onClick={() => onDelete()}>
                            Delete
                        </MenuItem>
                    }
                </Menu>
            }
        </Grid>
    );
}

// default props
GlossaryDetailCard.defaultProps = {
    classes: {},
    data: {},
    index: 0
};

// prop types
GlossaryDetailCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    index: PropTypes.number
};


export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(GlossaryDetailCard);