import React, { useRef, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { debounce } from "lodash";
import { Box, Tooltip, Typography, Grid, CircularProgress } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from "@mui/lab/LoadingButton";

// Import Images
import { ChevDownIcon } from '../../assets/svg/index.js';


// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';


function LazyLoadAutoCompleteComponent(props) {

    /**
     * Define Props
     */
    const { classes, name, label, placeholder, options, loading, onFetchMore, disabled, hasMore, fullWidth, variant, validators,
        errorMessages, labelKey, noOutline, freeSolo, selectedValue, onChangeSearch, disableClearable, customOption, ...rest } = props;
    const observer = useRef();

    /**
     * Handle Last Option Element Ref
     */
    const lastOptionElementRef = useCallback(async (node) => {
        if (observer.current) { observer.current.disconnect(); }
        observer.current = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && hasMore) {
                await onFetchMore();
            }
        });
        if (node) { observer.current.observe(node); }
    }, []);

    /**
     * Get Option Label
     * @param {*} option
     * @returns
     */
    const getOptionLabel = (option) => {
        if (option instanceof Object) {
            if (labelKey && option[labelKey]) {
                return option[labelKey];
            }
            else if (option.name) {
                return option.name;
            }
        }
        return option;
    };

    /**
     * Render Options
     */
    const RenderOption = forwardRef(
        ({ option, showLoadingBelow, isLastItem, hasMore, ...props }, ref) => {
            const optionValue = getOptionLabel(option);
            return (
                <Box component="li" key={option.id} ref={ref} {...props} style={{ position: 'relative' }}>
                    <Tooltip title={optionValue} arrow placement="right">
                        <Typography
                            variant="body1"
                            noWrap
                            className={classes.listText}
                        >
                            {optionValue}
                        </Typography>
                    </Tooltip>
                    {
                        isLastItem && showLoadingBelow && hasMore && (
                            <Grid className=" p-1 alignCenter">
                                <LoadingButton loading />
                            </Grid>
                        )
                    }
                </Box>
            );
        }
    );

    /**
     * Check Selected Value and Options are Equal
     * @param {*} option
     * @param {*} value
     * @returns
     */
    const isOptionEqualToValue = (option, value) => {
        let optionValue = option;
        let selValue = value;
        const key = labelKey;

        if (optionValue instanceof Object && key) {
            optionValue = labelKey ? option[key] : option?.label ?? '';
        }

        if (selValue instanceof Object) {
            selValue = value[key];
        }

        optionValue = optionValue || '';
        selValue = selValue || '';

        return optionValue === selValue;
    };

    /**
     * Get Option Value
     * @param {*} option
     * @returns
     */
    const getOptionValues = (option) => {
        if (option.name) {
            return {
                label: option?.name ?? '',
                id: option.id,
                ...option
            };
        }
        return option;
    };

    return (
        <Autocomplete
            options={
                customOption ? options : options.map((option) =>
                    getOptionValues(option)
                )
            }
            loading={loading}
            popupIcon={<ChevDownIcon />}
            classes={
                {
                    option: classes.option,
                    paper: classes.customPaper
                }
            }
            className={classes.autoCompleteContainer}
            clearOnEscape
            clearOnBlur
            freeSolo={freeSolo}
            value={selectedValue || null}
            selectOnFocus
            handleHomeEndKeys
            isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderOption={
                (props, option, { index }) => {
                    return (
                        <RenderOption
                            {...props}
                            isLastItem={index === options.length - 1}
                            showLoadingBelow={loading}
                            option={option}
                            hasMore={hasMore}
                            labelKey={labelKey}
                            ref={index === options.length - 1 ? lastOptionElementRef : null}
                        />
                    );
                }
            }
            renderInput={
                (params) => (
                    <TextValidator
                        {...params}
                        name={name}
                        label={label}
                        fullWidth={fullWidth}
                        variant={variant}
                        value={selectedValue}
                        validators={validators}
                        errorMessages={errorMessages}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={
                            debounce((event) => {
                                onChangeSearch(event.target.value);
                            }, 1000)
                        }
                        className={`noOutline ${noOutline ? classes.outLineNone : null}`}
                        InputProps={
                            {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }
                        }
                    />
                )
            }
            disabled={disabled}
            disableClearable={disableClearable}
            {...rest}
        />
    );
}


// default props
LazyLoadAutoCompleteComponent.defaultProps = {
    classes: {},
    options: [],
    loading: false,
    hasMore: true,
    name: "",
    label: "",
    placeholder: "",
    fullWidth: true,
    variant: "standard",
    validators: [],
    errorMessages: [],
    freeSolo: true,
    disabled: false,
    option: {},
    labelKey: "label",
    selectedValue: "",
    disableClearable: false,
    onFetchMore: () => { },
    customOption: false
};

// prop types
LazyLoadAutoCompleteComponent.propTypes = {
    classes: PropTypes.object,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    options: PropTypes.array,
    loading: PropTypes.bool,
    hasMore: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    freeSolo: PropTypes.bool,
    disabled: PropTypes.bool,
    option: PropTypes.object,
    labelKey: PropTypes.string,
    onFetchMore: PropTypes.func,
    noOutline: PropTypes.bool,
    onChangeSearch: PropTypes.func,
    showLoadingBelow: PropTypes.bool,
    isLastItem: PropTypes.bool,
    disableClearable: PropTypes.bool,
    customOption: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LazyLoadAutoCompleteComponent);